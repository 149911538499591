import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Avatar, Modal, Button, Column } from '@noon/atom';

import { translationText, getObjectByProperty } from '../../helpers';
import { translationType, reasonListType } from '../../types';
import { OTHER_PROBLEMS } from '../../constants';
import { CONFIG_REASON } from '../../redux/constants';

export class ReportAbuseModal extends Component {
  static propTypes = {
    noonText: translationType.isRequired,
    reasons: reasonListType.isRequired,
    player: PropTypes.shape().isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      noChooseReason: false,
      noWriteReason: false,
      showSuccess: false,
      selectedReasonId: null,
      otherReason: '',
    };
  }

  componentDidMount() {
    if (!this.props.reasons.length) {
      this.props.getReasons();
    }
  }

  checkReason = (reason) => {
    this.setState({ selectedReasonId: reason.id });
  };

  handleUserInput = (ev) => {
    const { value } = ev.target;
    this.setState({ otherReason: value });
  };

  submitReason = () => {
    const { reasons, player } = this.props;
    const { selectedReasonId, otherReason } = this.state;
    if (!selectedReasonId) {
      this.setState({ noChooseReason: true });
      return;
    }
    if (selectedReasonId === 'other' && !otherReason.length) {
      this.setState({ noWriteReason: true });
      return;
    }
    this.setState({ showSuccess: true });
    this.props.onSubmit({
      reason:
        otherReason.length && selectedReasonId === 'other'
          ? { id: 0, message: otherReason }
          : getObjectByProperty(reasons, 'id', selectedReasonId),
      player,
    });
  };

  render() {
    const { noonText, reasons, onClose, player } = this.props;
    const { noChooseReason, selectedReasonId, showSuccess, noWriteReason } = this.state;
    return (
      <Modal onClose={onClose}>
        {!showSuccess && (
          <Column nowrap align="center" className="rating-modal">
            <Avatar url={player.profile_pic} size="72px" className="header-icon" />
            <h3>
              {translationText(noonText, 'heading.reportAbuseTitle')}
              {' '}
              {`, ${player.name} ?`}
            </h3>
            <p className="subtitle">{translationText(noonText, 'heading.reportAbuseSubTitle')}</p>
            <div className="modal-body">
              <div className="reason-container">
                <div className="comments-container">
                  {reasons
                    .filter((o) => o.sub_type === 'abuse_report' || o.sub_type === 'abuse-report')
                    .map((reason, index) => (
                      <div className="comment-item" key={index}>
                        <input
                          type="checkbox"
                          id={`tcp-${index}`}
                          name="abuse-problem[]"
                          checked={selectedReasonId === reason.id}
                          onChange={() => this.checkReason(reason)}
                        />
                        <label htmlFor={`tcp-${index}`}>{reason.message}</label>
                      </div>
                    ))}
                  <div className="comment-item">
                    <input
                      type="checkbox"
                      id="other-reason"
                      name="abuse-problem[]"
                      checked={selectedReasonId === 'other'}
                      onChange={() => this.checkReason({ id: 'other' })}
                    />
                    <label htmlFor="other-reason">{OTHER_PROBLEMS}</label>
                  </div>
                </div>
                {noChooseReason && <p className="errorMessage">{translationText(noonText, 'tutoring.chooseReason')}</p>}
                {selectedReasonId === 'other' && (
                  <textarea
                    className={classNames({ error: noWriteReason })}
                    name="other-reasons"
                    id="other-reasons1"
                    rows="10"
                    value={this.state.otherReason}
                    onChange={this.handleUserInput}
                    placeholder={translationText(noonText, 'tutoring.commentHere')}
                  />
                )}
                {noWriteReason && <p className="errorMessage">{translationText(noonText, 'tutoring.writeReason')}</p>}
              </div>
            </div>
            <footer className="modal-footer">
              <Button size="lg" depth="2" type="red" onClick={this.submitReason}>
                {translationText(noonText, 'button.submit')}
              </Button>
              <Button size="lg" depth="1" onClick={onClose}>
                {translationText(noonText, 'button.skip')}
              </Button>
            </footer>
          </Column>
        )}
        {showSuccess && (
          <Column nowrap align="center" className="mt-2">
            <h3>{translationText(noonText, 'heading.reportAbuseSuccessTitle')}</h3>
            <p className="subtitl">{translationText(noonText, 'heading.reportAbuseSuccessSubTitle')}</p>
            <footer className="modal-footer mt-2">
              <Button size="lg" onClick={onClose}>
                {translationText(noonText, 'button.close')}
              </Button>
            </footer>
          </Column>
        )}
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getReasons: () => dispatch({ type: CONFIG_REASON.REQUEST }),
});

const mapStateToProps = (state) => ({
  noonText: state.toJS().translation.noonText,
  reasons: state.toJS().tutoring.reasons,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportAbuseModal);

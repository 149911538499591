import React from 'react';
import PropTypes from 'prop-types';
import { Column, Row, Checkbox, Card } from '@noon/atom';
import classNames from 'classnames';
import { get, isEmpty } from 'lodash-es';
import FilterGroupSubject from './FilterGroupSubject';
import { translationText } from '../../../helpers';
import { translationType } from '../../../types';

function FilterGroupList({ noonText, groupsList = [], onFilterSelection, filters }) {
  const getProductDetail = (group) => get(group, 'curriculum_tags.subject')
  || get(group, 'curriculum_tags.test')
  || get(group, 'curriculum_tags.skill')
  || get(group, 'curriculum_tags.course') || { color: '#F5B700' };

  return (
    <Column className="general-planner__filters-by-groups" gap>
      <Row className="general-planner__filters-by-groups-all-item">
        <Checkbox
          checked={isEmpty(filters.group_id)}
          onChange={() => onFilterSelection({ group_id: '' })}
          type="checkbox"
          label={`${translationText(noonText, 'planner.allGroups')} (${groupsList.length})`}
        />
      </Row>
      <Column justify="start" align="center" className="general-planner__filters-by-groups-list" nowrap gap="sm">
        {groupsList.map((group) => (
          <Card key={group.id} className={classNames('full-width general-planner__filters-by-groups-list-item', { selected: get(filters, 'group_id.value') === group.id })} onClick={() => { onFilterSelection({ group_id: { value: group.id, title: group.title } }); }}>
            <FilterGroupSubject product={getProductDetail(group)} />
            <h3 className="name" title={group.title}>{group.title}</h3>
          </Card>
        ))}
      </Column>
    </Column>
  );
}

FilterGroupList.propTypes = {
  groupsList: PropTypes.arrayOf(PropTypes.shape({})),
  onFilterSelection: PropTypes.func.isRequired,
  filters: PropTypes.shape({}),
  noonText: translationType.isRequired,
};

FilterGroupList.defaultProps = {
  groupsList: [],
  filters: {},
};
export default FilterGroupList;

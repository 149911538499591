import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { IconArrowDown } from '@noon/quark/dist';
import { isEmpty } from 'lodash';
import { COLORS } from '../../constants';
import { useOnClickOutside } from '../../hooks';

function TimePicker({ placeholder, steps, ms, onToggle, defaultValue }) {
  const [selectedDate, setSelectedDate] = useState(ms ? DateTime.fromMillis(ms) : DateTime.local().set({ minute: new Date().getMinutes() + 15 }));
  const [userInteracted, setUserInteracted] = useState(defaultValue);
  // const [isInvalid, setIsInvalid] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [inputTime, setInputTime] = useState({});
  const wrapperRef = useRef(null);

  const updateTime = (time) => {
    const updatedTime = selectedDate.set(time);
    setSelectedDate(updatedTime);
    onToggle(updatedTime.toMillis());
  };

  const handleOnClickOutSide = useCallback(() => {
    setIsOpen(false);
  });

  useOnClickOutside(wrapperRef, handleOnClickOutSide);

  const handleOnInputChange = (event, type) => {
    if (event.key === 'Enter') {
      setIsOpen(false);
    } else {
      const value = parseInt(event.target.innerText, 10);
      if (type === 'minute') {
        if (!isNaN(value) && value >= 0 && value <= 60) {
          setInputTime((prevValue) => ({ ...prevValue, ...{ minute: value } }));
        }
      } else if (type === 'hour') {
        if (!isNaN(value) && value >= 0 && value <= 24) {
          if (selectedDate.toFormat('a') === 'AM') {
            if (value < 12) {
              setInputTime((prevValue) => ({ ...prevValue, ...{ hour: value } }));
            } else {
              setInputTime((prevValue) => ({ ...prevValue, ...{ hour: value - 12 } }));
            }
          } else if (value < 12) {
            setInputTime((prevValue) => ({ ...prevValue, ...{ hour: value + 12 } }));
          } else {
            setInputTime((prevValue) => ({ ...prevValue, ...{ hour: value } }));
          }
        }
      }
    }
  };

  const handleOnArrowButtonClick = (type, isUpArrow) => {
    setInputTime({});
    if (type === 'minute') {
      if (isUpArrow) {
        let minute = selectedDate.get('minute');
        minute = (minute - (minute % steps)) + steps;
        updateTime({ minute: minute > 45 ? 0 : minute });
      } else {
        let minute = selectedDate.get('minute');
        minute = (minute - (minute % steps)) - steps;
        updateTime({ minute: minute < 0 ? 45 : minute });
      }
    } else if (type === 'hour') {
      if (isUpArrow) {
        updateTime({ hour: selectedDate.get('hour') + 1 });
      } else {
        updateTime({ hour: selectedDate.get('hour') - 1 });
      }
    }
  };

  const handleOnChangeAm = (value) => {
    const hour = inputTime.hour || selectedDate.get('hour');
    const minute = inputTime.minute || selectedDate.get('minute');
    const updatedTime = selectedDate.set({ hour: value === 'AM' ? hour + 12 : hour - 12, minute });

    setSelectedDate(updatedTime);
    setInputTime((prevValue) => ({ ...prevValue, ...{ hour: updatedTime.get('hour'), minute: updatedTime.get('minute') } }));
    onToggle(updatedTime.toMillis());
  };

  useEffect(() => {
    if (!ms) {
      onToggle(selectedDate.toMillis());
    }
  }, []);

  useEffect(() => {
    if (ms && ms !== selectedDate.toMillis()) {
      setSelectedDate(DateTime.fromMillis(ms));
    }
  }, [ms]);

  useEffect(() => {
    if (!isEmpty(inputTime)) {
      updateTime(inputTime);
    }
  }, [isOpen]);

  return (
    <div ref={wrapperRef}>
      <input
        dir="ltr"
        onClick={() => {
          setUserInteracted(true);
          setIsOpen(!isOpen);
        }}
        type="text"
        placeholder={placeholder}
        className="form-control"
        maxLength="2"
        value={userInteracted ? selectedDate.toFormat('hh:mm a') : ''}
      />
      {isOpen && (
        <div className="time-selector">
          <ul>
            <li>
              <button type="button">
                <IconArrowDown
                  onClick={() => handleOnArrowButtonClick('hour', true)}
                  flipY
                  stroke={COLORS.coolGrey[1]}
                />
              </button>
              <span
                tabIndex="6"
                contentEditable
                onKeyPress={handleOnInputChange}
                onInput={(event) => handleOnInputChange(event, 'hour')}
                >
                {selectedDate.toFormat('hh')}

              </span>
              <button type="button">
                <IconArrowDown
                  onClick={() => handleOnArrowButtonClick('hour', false)}
                  stroke={COLORS.coolGrey[1]}
                />
              </button>
            </li>
            <li>
              <button type="button">
                <IconArrowDown
                  onClick={() => handleOnArrowButtonClick('minute', true)}
                  flipY
                  stroke={COLORS.coolGrey[1]}
                />
              </button>
              <span
                tabIndex="7"
                contentEditable
                onKeyPress={handleOnInputChange}
                onInput={(event) => handleOnInputChange(event, 'minute')}
                >
                {selectedDate.toFormat('mm')}

              </span>
              <button type="button">
                <IconArrowDown
                  onClick={() => handleOnArrowButtonClick('minute', false)}
                  stroke={COLORS.coolGrey[1]}
                />
              </button>
            </li>
            <li>
              <button type="button">
                <IconArrowDown
                  onClick={() => handleOnChangeAm(selectedDate.toFormat('a'))}
                  flipY
                />
              </button>
              <span>{selectedDate.toFormat('a')}</span>
              <button type="button">
                <IconArrowDown
                  onClick={() => handleOnChangeAm(selectedDate.toFormat('a'))}
                />
              </button>
            </li>
          </ul>
        </div>
      )}

      {/* {isInvalid && <p className="errorMessage text-left">Invalid time</p>} */}
    </div>
  );
}

TimePicker.propTypes = {
  label: PropTypes.string.isRequired,
  ms: PropTypes.number.isRequired,
  onToggle: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  steps: PropTypes.number,
  defaultValue: PropTypes.bool,
};

TimePicker.defaultProps = {
  placeholder: '',
  steps: 15,
  defaultValue: false,
};

export default TimePicker;

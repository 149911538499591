import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row, Column, NoonDate, Time } from '@noon/atom';
import classNames from 'classnames';
import { IconClock, IconReplayPlay, IconStar } from '@noon/quark';
import { COLORS } from '../../constants';
import { translationText } from '../../helpers';

const Playbackcard = (prop) => {
  const { meta } = prop.data;
  const sessionData = meta;
  sessionData.id = prop.data.id;
  sessionData.group_id = prop.data.group_id;
  const history = useHistory();
  const noonText = useSelector((state) => state.toJS().translation.noonText);

  const takeAction = () => {
    if (!sessionData.id) return false;
    history.push(`/in-en/play/${sessionData.id}`);
  };

  const SessionTypeBlock = () => (
    <Row nowrap align="center">
      <span>
        {sessionData.playback_count}
        {' '}
        {translationText(noonText, 'session.views')}
        .
        {' '}
        {' '}
      </span>
      <span>
        <IconStar height="14px" width="15px" fill="#fbc05f" />
        {' '}
        {' '}
        {sessionData.creator.average_rating}
      </span>
    </Row>
  );

  const SessionTimeBlock = () => (
    <Row align="center" className={classNames('time-block')} gap="sm">
      {sessionData.start_time && (
        <React.Fragment>
          <IconClock height="15px" width="15px" fill={COLORS.orange.tint} />
          <span><Time unit="epoch" humanize suffix value={sessionData.start_time} /></span>
        </React.Fragment>
      )}
    </Row>
  );

  const ViewsBlock = () => (
    <Column align="center" justify="center" flex="1" className="views_block">
      <div className="video-wrapper no-margin no-padding">
        <Row align="center" justify="center" className="play-button">
          <IconReplayPlay fill="#000" stroke="#fff" />
        </Row>
      </div>
    </Column>
  );

  const PlannerPageScheduledSessionsInfo = () => (
    <React.Fragment>
      <li className="card-section card-section--time" onClick={() => takeAction()}>
        <Column align="center" justify="center" flex="1" className="time-wrapper">
          <React.Fragment>
            <li className="card-section card-section--time" onClick={() => takeAction()}>
              <ViewsBlock />
            </li>
            {sessionData.start_time && (
              <NoonDate humanize value={sessionData.start_time} format="dd LLL yyyy" />
            )}
            <SessionTimeBlock />
          </React.Fragment>
        </Column>
      </li>
      <li className="card-section card-section--main" onClick={() => takeAction()}>
        <Column justify="center" flex="1">
          <SessionTypeBlock />
          {sessionData.curriculum_tags && (
            <Row className="main-content main-content--name-area w-full">
              <h5>{sessionData.curriculum_tags.chapter.name}</h5>
            </Row>
          )}
          {sessionData.curriculum_tags && (
            <span>{sessionData.curriculum_tags.chapter.name_header}</span>
          )}
        </Column>
      </li>
    </React.Fragment>
  );

  return (
    <Row
      nowrap
      className={classNames('activity-card', 'activity-card--list-view', 'activity-card--grid-view')}
      style={{ height: '130px' }}
    >
      <PlannerPageScheduledSessionsInfo />
    </Row>
  );
};

export default Playbackcard;

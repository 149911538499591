import { Card, Column, Row } from '@noon/atom';
import React from 'react';
import { DateTime } from 'luxon';
import t from '../../../helpers/translate';

function ArchivedGroupMessage({ archivedDate = Date.now() }) {

  return (
    <>
      <Card>
        <Row align="center" justify="space-between" flex="1" className="card-header">
          <h3 className="card-header__title">{t('groups', 'teacherArchiveGroupTitle')}</h3>
        </Row>
        <Column align="start" justify="center" className="plr-2 mb-2 card-body" gap="sm">
          <p className="child">
            {
              t('groups', 'teacherArchiveGroupMessage').replace('{archiveDate}', `${DateTime.fromMillis(archivedDate).toFormat('dd LLL yyyy')}`,
              )
            }
          </p>
        </Column>
      </Card>
    </>
  );
}

export default ArchivedGroupMessage;

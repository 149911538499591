import React from 'react';
import { Button } from '@noon/atom';
import { IconLogo } from '@noon/quark';

export default function HeroSection(prop) {
  const { history } = prop;

  return (
    <div className="section-outer-container hero-outer">
      <div className="section landing-hero">
        <div className="landing-hero__content">
          <div className="text">
            <h1 className="content-heading">Online Education with the top teachers worldwide</h1>
            <p className="content-subheading">Empowering teachers to give their best and reach students worldwide.</p>
            <div className="CTA">
              <Button onClick={() => history.push(`/${localStorage.updatedLocale}/login`)} className="noon-btn" size="lg" type="primary">Sign Up For Free</Button>
            </div>
          </div>
          <div className="image">
            <div className="image-wrapper"><img src="/assets/images/new-landing/laptop2.png" alt="frame" /></div>
            <div className="teacher"><img src="/assets/images/new-landing/saad.png" alt="teacher" /></div>
            <div className="icon-wrapper"><IconLogo width="30rem" height="30rem" /></div>
          </div>
        </div>
      </div>
    </div>
  );
}

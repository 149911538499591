import React from 'react';
import { Row, Select } from '@noon/atom';
import { useSelector } from 'react-redux';
import { translationText } from '../../helpers';

const ChapterAndTopicChoices = (props) => {
  const { chapters, topics, setChapterAndRequestTopics, setSelectedTopic, selectedTopic, setSelectedChapter, selectedChapter } = props;

  const { noonText } = useSelector(state => state.toJS().translation);

  const removeMiscellaneousFromChapters = () => {
    let cleanChapters = [];
    chapters.forEach(chapter => {
      if (chapter.name !== "Miscellaneous") cleanChapters.push(chapter);
    })
    return cleanChapters;
  }

  return (
    <Row justify="space-between" className="prepare-assignment--chapterAndTopics">
      <Select
        list={removeMiscellaneousFromChapters()}
        onSelect={setSelectedChapter}
        label={translationText(noonText, 'label.selectChapter')}
        title={translationText(noonText, 'placeholder.selectChapter')}
        selectedItem={selectedChapter}
      />
      <Select
        list={topics}
        onSelect={setSelectedTopic}
        label={translationText(noonText, 'library.topic')}
        title={translationText(noonText, 'library.topic')}
        selectedItem={selectedTopic}
      />
    </Row>
  )
}

export default ChapterAndTopicChoices;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { Column, Avatar, Row } from '@noon/atom';
import { userSelector } from 'redux/selectors/users';
import { IconChat, IconPlus } from '@noon/quark';
import { GET_EMOJI } from '../../redux/constants';
import { translationType } from '../../types';
import { translationText } from '../../helpers';

class PlayerChat extends Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    emojis: PropTypes.shape({
      value: PropTypes.arrayOf(),
    }).isRequired,
    noonText: translationType.isRequired,
    chats: PropTypes.arrayOf(PropTypes.object).isRequired,
    toggleChat: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.chatInput = React.createRef();
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.chats.length) {
      setTimeout(() => this.scrollToBottom(), 100);
    }
    return true;
  }

  scrollToBottom() {
    if (this.chatbox) {
      const { scrollHeight } = this.chatbox;
      const height = this.chatbox.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.chatbox.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }

  render() {
    const { chats, noonText, toggleChat, active } = this.props;
    return (
      <div className={classNames('playback__sidebar', { active })}>
        <Row nowrap className="chat-header" align="center" justify="space">
          <IconChat />
          <span className="chat-header-title">{translationText(noonText, 'classroom.chatTab')}</span>
          <IconPlus rotate="45" onClick={toggleChat} />
        </Row>
        <div
          ref={(container) => {
            this.chatbox = container;
          }}
          className="chat-body"
        >
          <div className="">
            {!!chats
              && chats.map((chat, index) => (
                <div className="chat-item" key={index}>
                  <Avatar rounded noShadow url={chat.pic} />
                  <Column align="start" className="chat-container">
                    <React.Fragment>
                      <div className="chat-title">{chat.name}</div>
                      {chat.messages.map((message, index) => (
                        <div className="message-wrapper" key={index}>
                          {message.image_thumbnail_uri ? (
                            <img
                              className={classNames('chat-image', {
                                emoji: message && message.image_type && message.image_type === 'emojiImage',
                              })}
                              src={message.image_thumbnail_uri}
                              alt={message.msg}
                            />
                          ) : (
                            <div
                              key={index}
                              className={classNames('chat-message', {
                                emoji: message.msg.indexOf('only') !== -1,
                              })}
                            >
                              {message.for_user && message.for_user.image_thumbnail_uri && (
                                <img src={message.for_user.image_thumbnail_uri} alt="" />
                              )}
                              {ReactHtmlParser(message.msg)}
                            </div>
                          )}
                        </div>
                      ))}
                    </React.Fragment>
                  </Column>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  emojis: state.toJS().file.emojis,
  userData: userSelector(state),
  sessionData: state.toJS().tutoring.sessionData,
  fileError: state.toJS().file.error,
  selectedCountry: state.toJS().folder.countries.selectedCountry,
});

const mapDispatchToProps = (dispatch) => ({
  getEmojis: () => dispatch({ type: GET_EMOJI.REQUEST }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerChat);

import React from 'react';
import { Modal, Button } from '@noon/atom';
import withNoonText from 'helpers/withNoonText';

const DeleteSlideConfirmationModal = ({ text, noonText, cancelConfirmationModal, confirmDelete }) =>
  (
    <Modal
      onClose={cancelConfirmationModal}
      className="delete-slide-confirmation-modal"
          >
      <div className="modal-body">
        {text(noonText, 'classroom.deleteSlideConfirmationText')}
      </div>
      <div className="modal-footer">
        <Button
          type="primary"
          value={text(noonText, 'classroom.deleteSlideConfirm')}
          onClick={confirmDelete}
              />
        <Button
          type="default"
          value={text(noonText, 'classroom.deleteSlideCancel')}
          onClick={cancelConfirmationModal}
              />
      </div>
    </Modal>);


export default withNoonText(DeleteSlideConfirmationModal);

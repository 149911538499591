import React from 'react';

import { Column, Modal } from '@noon/atom';

import t from '../../../helpers/translate';

function PlaybackLoadingModal(props : { hasSections: boolean }) {
  const { hasSections } = props;
  return (
    <Modal className="playback-onboarding">
      <Column className="modal-body">
        <div className="top-area">
          <img src="../../../assets/images/onboarding/playback-onboarding.png" alt="Playback onboarding" />
        </div>
        <div className="bottom-area">
          <div className="texts">
            <h3>{t('playback', 'preparingEditorTitle')}</h3>
            {hasSections && <p>{t('playback', 'preparingEditorSubTitle')}</p>}
          </div>
        </div>
      </Column>
    </Modal>
  );
}

export default PlaybackLoadingModal;

import React, { useState } from 'react';
import { Row, Avatar } from '@noon/atom';
import Popover from 'react-popover';

const TeamMessage = ({ message }) => {
  const [zoomed, zoom] = useState(false);
  return (
    <div className="team-message-container">
      <Row className="team-info">
        <img alt="team" className="team-icon" src={message.team_image} />
        <span className="team-name">{message.team_name}</span>
      </Row>
      <div className="team-message">
        <Row className="user-info">
          <div className="user">
            <Avatar url={message.profile_pic} size="24px" rounded noShadow />
            <span className="name">{message.name}</span>
          </div>
          <div className="time-ago">
            {message.timeAgo}
          </div>
        </Row>
        <p>{message.msg}</p>
        {message.image_url && message.slide_number
        && (
        <React.Fragment>
          <br />
          <Popover
            isOpen={zoomed}
            onOuterAction={() => zoom(false)}
            preferPlace="right"
            body={
              <div className="slide-attachment zoomed" style={{ background: `url(/assets/images/slide-bg.svg),url(${message.image_url})` }} onClick={() => zoom(false)} />
          }>
            <div className="slide-attachment" style={{ background: `url(/assets/images/slide-bg.svg),url(${message.image_url})` }} onClick={() => zoom(true)} />
          </Popover>
        </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default TeamMessage;

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Badge, Button, Column, Row } from '@noon/atom';

import { COLORS, isRTL } from '../../../constants';
import { convertLengthToTimeStamp, translationText } from '../../../helpers';
import { TSlideEditView } from '../types';

function SlideEditView(props : TSlideEditView) {
  const { slide, goBack, saveSlideState, updatePlaybackArea } = props;
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [startPoint, setStartPoint] = useState<number>(0);
  const [endPoint, setEndPoint] = useState<number>(0);
  const [handleOn, setHandleOn] = useState<string>('');
  const [canvasWidth, setCanvasWidth] = useState<number>(0);

  const canvasRef = useRef(null);
  const contextRef = useRef(null);

  const canvasHeight : number = 56;
  const closeEnough: number = 10;

  const { noonText = {} } = useSelector((state) => state.toJS().translation);

  // logic
  const prepareCanvas = () : void => {
    const canvas = canvasRef.current;
    const canvasParentWidth = document.getElementById('editSlide').clientWidth;
    setCanvasWidth(canvasParentWidth);
    canvas.width = canvasParentWidth;
    canvas.style.width = `${canvasParentWidth}px`;

    const context = canvas.getContext('2d');
    // eslint-disable-next-line prefer-destructuring
    context.fillStyle = COLORS.coolGrey[4];
    context.lineCap = 'round';
    contextRef.current = context;

    const currentSlideIntervals = slide?.payload?.intervals.filter((interval) => interval.end_time - interval.start_time !== 0);
    const originalSlideIntervals = slide?.payload?.originalIntervals;

    if ((currentSlideIntervals && originalSlideIntervals) && (currentSlideIntervals.length !== originalSlideIntervals.length
      || currentSlideIntervals[0].start_time !== originalSlideIntervals[0].start_time
      || currentSlideIntervals[0].end_time !== originalSlideIntervals[0].end_time
    )) {
      let startTime; let
        endTime;
      if (currentSlideIntervals[0].start_time === originalSlideIntervals[0].start_time) {
        startTime = currentSlideIntervals[0].end_time;
        if (currentSlideIntervals[1]) {
          endTime = currentSlideIntervals[1].start_time;
        } else {
          endTime = originalSlideIntervals[0].end_time;
        }
      } else {
        startTime = originalSlideIntervals[0].start_time;
        endTime = currentSlideIntervals[0].start_time;
      }
      const startTimeSec = (startTime - originalSlideIntervals[0].start_time) / 1000;
      const startTimePoint = (canvasParentWidth / slide?.originalLength) * startTimeSec;
      const endTimeSec = (endTime - originalSlideIntervals[0].start_time) / 1000;
      const endTimePoint = (canvasParentWidth / slide?.originalLength) * endTimeSec;
      setStartPoint(startTimePoint);
      setEndPoint(endTimePoint);
      contextRef.current.beginPath();
      contextRef.current.fillStyle = COLORS.error.light;
      contextRef.current.clearRect(0, 0, canvasParentWidth, canvasHeight);
      contextRef.current.strokeRect(startTimePoint, 2, endTimePoint - startTimePoint, canvasHeight - 4);
      contextRef.current.fillRect(startTimePoint, 2, endTimePoint - startTimePoint, canvasHeight - 4);
      contextRef.current.fillStyle = COLORS.error.base;
      contextRef.current.font = '16px sans-serif';
      contextRef.current.textBaseline = 'top';
      contextRef.current.textAlign = isRTL() ? 'start' : 'end';
      contextRef.current.fillText(translationText(noonText, 'playback.selectedPartIsRemoved'), (startTimePoint + ((endTimePoint - startTimePoint) / 2) + 106), ((canvasHeight / 2) - 10));
      contextRef.current.closePath();
    }
  };

  function isCanvasBlank() : boolean {
    return (!(startPoint && endPoint));
  }

  function checkCloseEnough(p1 : number, p2 : number) : boolean {
    return Math.abs(p1 - p2) < closeEnough;
  }

  const startDrawing = ({ nativeEvent }) : void => {
    const { offsetX } = nativeEvent;
    if (!isCanvasBlank()) {
      const leftBorderHandle = checkCloseEnough(startPoint, offsetX);
      const rightBorderHandle = checkCloseEnough(offsetX, endPoint);
      if (!leftBorderHandle && !rightBorderHandle) {
        canvasRef.current.style.cursor = 'not-allowed';
        return;
      }
      setHandleOn(leftBorderHandle ? 'left' : rightBorderHandle ? 'right' : '');
    } else {
      setStartPoint(offsetX);
    }
    contextRef.current.fillStyle = '#FDEAEF02';
    contextRef.current.strokeStyle = COLORS.error.base;
    contextRef.current.lineWidth = 4;
    setIsDrawing(true);
  };

  const finishDrawing = () : void => {
    canvasRef.current.style.cursor = 'default';
    setIsDrawing(false);
    updatePlaybackArea([{ startPoint, endPoint }], canvasWidth);
  };

  const draw = ({ nativeEvent }) : void => {
    if (!isDrawing) {
      return;
    }

    contextRef.current.beginPath();
    const { offsetX } = nativeEvent;
    contextRef.current.fillStyle = COLORS.error.light;
    if (handleOn) {
      canvasRef.current.style.cursor = 'col-resize';
      if (handleOn === 'left' && (offsetX + 50) < endPoint && offsetX >= 0) {
        contextRef.current.clearRect(0, 0, canvasWidth, canvasHeight);
        contextRef.current.strokeRect(offsetX, 2, endPoint - offsetX, canvasHeight - 4);
        contextRef.current.fillRect(offsetX, 2, endPoint - offsetX, canvasHeight - 4);
        setStartPoint(Math.abs(offsetX));
      } else if (handleOn === 'right' && (offsetX - 50) > startPoint && offsetX <= canvasWidth) {
        contextRef.current.clearRect(0, 0, canvasWidth, canvasHeight);
        contextRef.current.strokeRect(startPoint, 2, offsetX - startPoint, canvasHeight - 4);
        contextRef.current.fillRect(startPoint, 2, offsetX - startPoint, canvasHeight - 4);
        setEndPoint(offsetX);
      } else {
        return;
      }
    } else {
      contextRef.current.clearRect(0, 0, canvasWidth, canvasHeight);
      contextRef.current.strokeRect(startPoint, 2, offsetX - startPoint, canvasHeight - 4);
      contextRef.current.fillRect(startPoint, 2, offsetX - startPoint, canvasHeight - 4);
      setEndPoint(offsetX);
    }
    contextRef.current.fillStyle = COLORS.error.base;
    contextRef.current.font = '16px sans-serif';
    contextRef.current.textBaseline = 'top';
    contextRef.current.textAlign = isRTL() ? 'start' : 'end';
    contextRef.current.fillText(translationText(noonText, 'playback.selectedPartWillBeRemoved'), (startPoint + ((endPoint - startPoint) / 2) + 106), ((canvasHeight / 2) - 10));
    contextRef.current.closePath();
  };

  const clearSelection = () : void => {
    contextRef.current.clearRect(0, 0, canvasWidth, canvasHeight);
    setStartPoint(0);
    setEndPoint(0);
  };

  useEffect(() => {
    prepareCanvas();
  }, [slide]);

  return (
    <Column className="slide" id="editSlide">
      <Row className="meta" justify="space-between" dir="ltr">
        <span>00:00</span>
        {!endPoint && <span>{translationText(noonText, 'playback.selectPartToTrim')}</span>}
        <span>{convertLengthToTimeStamp(slide?.originalLength)}</span>
      </Row>
      <Row className="action-buttons" gap="sm">
        {!!endPoint && <Button size="sm" className="child" type="orange" outlined onClick={() => clearSelection()}>{translationText(noonText, 'playback.resetSelection')}</Button>}
        <Button size="sm" className="child" type="primary" outlined onClick={() => goBack()}>{translationText(noonText, `playback.${endPoint ? 'back' : 'cancel'}`)}</Button>
        <Button size="sm" className="child" type={endPoint ? 'red' : 'primary'} onClick={() => saveSlideState([{ startPoint: startPoint > endPoint ? endPoint : startPoint, endPoint: startPoint < endPoint ? endPoint : startPoint }], canvasWidth)}>{translationText(noonText, `playback.${endPoint ? 'confirmRemoving' : 'save'}`)}</Button>
      </Row>
      <Row className="canvas-parent">
        {!!endPoint && (
          <Badge color="red" size="xs" style={{ left: `calc(${startPoint}px - 25px)` }}>
            {translationText(noonText, 'navigation.from')}
            {' '}
            {convertLengthToTimeStamp(slide?.originalLength * (startPoint / canvasWidth))}
          </Badge>
        )}
        <canvas
          className="edit-canvas"
          height={canvasHeight}
          ref={canvasRef}
          onMouseDown={startDrawing}
          onMouseUp={finishDrawing}
          onMouseMove={draw}
        />
        {!!endPoint && (
          <Badge color="red" size="xs" style={{ left: `calc(${endPoint}px - 25px)` }}>
            {translationText(noonText, 'navigation.to')}
            {' '}
            {convertLengthToTimeStamp(slide?.originalLength * (endPoint / canvasWidth))}
          </Badge>
        )}
      </Row>
    </Column>
  );
}

export default SlideEditView;

import { CLASSROOM_STUDENT_JOIN, CLASSROOM_STUDENT_LEFT } from '../../../redux/constants';

export default function handleMsg(dispatch, e) {
  const { action, state, channel } = e;
  if (action && state && channel && !channel.includes('breakout')) {
    switch (action) {
      case 'state-change': dispatch({ type: CLASSROOM_STUDENT_JOIN, payload: state }); break;
      case 'leave': dispatch({ type: CLASSROOM_STUDENT_LEFT, payload: state }); break;
      case 'timeout': dispatch({ type: CLASSROOM_STUDENT_LEFT, payload: state }); break;
      default:
    }
  }
}

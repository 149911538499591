const AGORA_RTM_ERRORS = {
  101: 'RTM:LoginError',
  102: 'RTM:ChannelNotCreated',
  103: 'RTM:ChannelNotFound',
  104: 'RTM:ErrorSendingMessage',
  105: 'RTM:ErrorSendingPeerMessage',
  106: 'RTM:ErrorInLogout',
  107: 'RTM:AlreadyDisconnected',
  108: 'RTM:messageLimitExceeded',
};

export default AGORA_RTM_ERRORS;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconLoader, IconUserUpload } from '@noon/quark';
import { Avatar, Button, Row, Input, Column } from '@noon/atom';
import classNames from 'classnames';
import isEmpty from 'lodash-es/isEmpty';
import {
  UPLOAD_FILE,
  GET_TEACHER_PROFILE,
  UPDATE_TEACHER_PROFILE,
} from '../../redux/constants';
import { addToast, TOAST_TYPE } from '../Toast';
import { translationText } from '../../helpers';
import { translationType, errorType, userType } from '../../types';
import { IMAGE_ACCEPT_TYPES } from '../../constants';

export class CompleteProfileForm extends Component {
  static propTypes = {
    noonText: translationType.isRequired,
    user: userType.isRequired,
    userError: errorType.isRequired,
    updateUser: PropTypes.func.isRequired,
    uploadImage: PropTypes.func.isRequired,
    uploadImageUrls: PropTypes.shape(),
    profile: PropTypes.shape(),
  };

  static defaultProps = {
    uploadImageUrls: {},
    profile: {},
  };

  constructor(props) {
    super(props);
    /* eslint-disable react/prop-types */
    const { user } = props;
    this.state = {
      user: {
        id: user.id || 0,
        profile_pic: user.profile_pic || '',
        region: user.region || '',
        region_id: user.region_id || 0,
        email: '',
      },
      formErrors: {
        region: '',
        email: '',
      },
      showModalFor: '',
      isUploading: false,
    };
  }

  componentWillReceiveProps({
    userError,
    user,
    uploadImageUrls,
    fileError,
    noonText,
    history,
  }) {
    if (
      !isEmpty(user)
      && user !== this.props.user
      && !user.get_email
      && history.location.pathname.indexOf('/complete-profile') !== -1
    ) {
      this.props.history.push(`/${localStorage.updatedLocale}/home`);
      localStorage.newUser = true;
    }
    if (
      !isEmpty(user)
      && user !== this.props.user
      && history.location.pathname.indexOf('/profile') !== -1
    ) {
      addToast(translationText(noonText, 'notify.profileUpdated'), TOAST_TYPE.SUCCESS);
      this.props.history.push(`/${localStorage.updatedLocale}/home`);
    }
    if (!this.state.user.id) {
      // populate data once
      this.setState({
        user: {
          id: user.id,
          email: user.get_email ? '' : user.email,
          profile_pic: user.profile_pic,
          update_email: user.get_email,
          city: user.city,
          city_id: user.city_id,
        },
      });
    }
    if (!isEmpty(userError) && userError.profile !== this.props.userError.profile) {
      // console.log(userError.profile);
    }
    if (!isEmpty(uploadImageUrls)) {
      const updatedUser = this.state.user;
      updatedUser.profile_pic = uploadImageUrls.thumbnail_url || uploadImageUrls.small_url;
      this.setState({ user: updatedUser, isUploading: false });
    }
    if (!isEmpty(fileError) && fileError.uploadImageUrls !== this.props.fileError.uploadImageUrls) {
      this.setState({ isUploading: false });
      addToast('Network Error', TOAST_TYPE.ERROR);
    }
  }

  componentWillUnmount() {
    this.removeEventListener();
  }

  handleClick = (e) => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };

  handleClickOutside() {
    this.removeEventListener();
  }

  fileChangedHandler = (e) => {
    // User cancelled
    const { user } = this.state;
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const fd = new FormData();
    fd.append('destination', 'user_profile_pic');
    fd.append('user_id', user.id);
    const nameSplit = file.name.split('.');
    const ext = nameSplit[nameSplit.length - 1];
    const originalName = nameSplit[0];
    fd.append('file_name', `${originalName}_${user.email}.${ext}`);
    fd.append('fileUpl', file, file.name);
    this.setState({ isUploading: true });
    this.props.uploadImage(fd);
    e.preventDefault();
  };

  handleUserInput = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({ user: { ...prevState.user, [name]: value } }));
    e.preventDefault();
  };

  submitProfile = (ev) => {
    ev.preventDefault();
    const { user } = this.state;
    user.stage = 6;
    this.props.updateUser(user);
  };

  addEventListener() {
    document.addEventListener('mousedown', this.handleClick);
  }

  removeEventListener() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  render() {
    const {
      user,
      formErrors,
      showModalFor,
      isUploading,
    } = this.state;
    const { noonText, history } = this.props;
    return (
      <div className="login-form complete-profile-form">
        <form noValidate onSubmit={this.submitProfile}>
          <div className="outer-form-wrapper">
            <Column nowrap align="center" className={classNames('main-form-view', { active: showModalFor === '' })}>
              <div className="upload-image">
                <input id="uploadPic" accept={IMAGE_ACCEPT_TYPES} type="file" onChange={this.fileChangedHandler} />
                <label htmlFor="uploadPic">
                  {user.profile_pic ? (
                    <Avatar teacher url={user.profile_pic} size="80px" />
                  ) : (
                    <div className="upload-icon" title="upload">
                      {isUploading ? <IconLoader fill="#696969" height="50px" width="50px" /> : <IconUserUpload fill="#696969" height="50px" width="50px" />}
                    </div>
                  )}
                </label>
              </div>

              {this.props.user.get_email && (
                <Input
                  name="email"
                  defaultValue={user.email}
                  type="email"
                  autoComplete="off"
                  label={translationText(noonText, 'form.email')}
                  placeholder={translationText(noonText, 'placeholder.email')}
                  error={formErrors.email}
                  onChange={this.handleUserInput}
                />
              )}

              <Row align="center" className="submit-button-block mt-4">
                <Button
                  htmlType="submit"
                  type="primary"
                  size="xl"
                  value={translationText(
                    noonText,
                    history.location.pathname === '/profile/edit' ? 'button.updateProfile' : 'button.signup',
                  )}
                />
              </Row>
            </Column>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  noonText: state.toJS().translation.noonText,
  user: state.toJS().user.loggedUser,
  userError: state.toJS().user.error,
  uploadImageUrls: state.toJS().file.uploadImageUrls,
  profile: state.toJS().user.profile,
  fileError: state.toJS().file.error,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data) => dispatch({ type: UPDATE_TEACHER_PROFILE.REQUEST, payload: data }),
  uploadImage: (data) => dispatch({ type: UPLOAD_FILE.REQUEST, payload: data }),
  getProfile: () => dispatch({ type: GET_TEACHER_PROFILE.REQUEST }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompleteProfileForm);

import React, { useEffect, useRef, useState } from 'react';
import { ProgressSpinner, Time } from '@noon/atom';
import { COLORS } from '../../../constants';

type IQuestionTimerProp = {
    questionTime: number;
    questionTimeLeft: number,
    onQuestionTimeEnded: Function,
    className?:string
}

const WARNING_TIME_THRESHOLD = 20; // in seconds

const QuestionTimer = (prop: IQuestionTimerProp) => {
  const { questionTimeLeft, onQuestionTimeEnded, questionTime, className } = prop;
  const timerRef = useRef(null);

  const [time, setTime] = useState(questionTimeLeft);

  useEffect(() => {
    if (questionTimeLeft) {
      setTime(questionTimeLeft);
      timerRef.current = setInterval(() => {
        setTime((time: number) => {
          if (!time) {
            clearTimeout(timerRef.current);
            onQuestionTimeEnded();
          }
          return time - 1;
        });
      }, 1000);
    }
  }, [questionTimeLeft]);

  useEffect(() => () => {
    if (timerRef.current) clearTimeout(timerRef.current);
  }, []);

  if (!questionTimeLeft) return null;

  const warnTimeout = time < WARNING_TIME_THRESHOLD;
  const progress = (time * 100) / questionTime;

  return (
    Boolean(time) && (
    <ProgressSpinner
      noPointer
      radius={24}
      fill="#fff"
      className={className}
      progress={progress}
      text={<Time value={time} />}
      activeColor={warnTimeout ? COLORS.red.base : COLORS.brand.base}
      baseColor={warnTimeout ? '#ff3b3033' : '#01C3F94d'}
      color={warnTimeout ? COLORS.red.base : COLORS.brand.base}
      />
    )
  );
};

export default QuestionTimer;

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import { Row, Column, Select, Textarea, ContentOnTop, Button, Input, MultiSelect } from '@noon/atom';
import { IconBack, IconClose, IconMyGroups } from '@noon/quark';
import { head, isEmpty, omit } from 'lodash-es';
import { CurriculumSelection } from '../Curriculum';
import { GET_TEACHER_TAGS_GROUP, CREATE_GROUP_V2 } from '../../redux/constants';
import Mixpanel from '../Mixpanel';
import { translationText } from '../../helpers';
import curriculumTypes from '../../helpers/curriculumTypes';

function CreateGroup({ onClose, teacherId, totalGroups }) {
  const { loggedUser: user } = useSelector((state) => state.toJS().user);
  const { noonText } = useSelector((state) => state.toJS().translation);
  const ref = useRef(null);
  const isRtl = document.body.dir === 'rtl';
  const logger = useRef(LoggingManager.mount({ moduleName: 'group' })).current;
  const { createGroup } = useSelector(
    (state) => state.toJS().groupsV2,
  );

  const privacy = [
    {
      id: 1,
      name: translationText(noonText, 'groups.createGroupClosedGroupType'),
      value: 'private',
    },
    {
      id: 2,
      name: translationText(noonText, 'groups.createGroupOpenGroupType'),
      value: 'public',
    },
  ];

  const [selectedPage, setSelectedPage] = useState(1);
  const [inputCharCount, setInputCharCount] = useState({});
  const [selectedCurriculum, setSelectedCurriculum] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const [scrollHeight, setScrollHeight] = useState(0);
  // const onCreateGroupClose = () => {
  //   setEnableCreateGroup(!enableCreateGroup);
  //   history.push(`/${localStorage.updatedLocale}/groups/${id}`);
  // };

  useEffect(() => {
    if (scrollHeight) {
      ref.current.scrollTop += scrollHeight;
    }
  }, [scrollHeight]);

  useEffect(() => {
    if (createGroup.newGroup) {
      const data = {
        user_id: teacherId,
        group_id: createGroup.newGroup.id,
        source: 'sidebar',
        group_name: selectedCurriculum.fieldsState.title,
        group_country: selectedCurriculum.fieldsState.country,
        group_subject: selectedCurriculum.fieldsState.subject,
        group_grades: selectedCurriculum.fieldsState.grade,
        group_curriculum_type: selectedCurriculum.fieldsState.curriculum_type,
        group_type: selectedCurriculum.fieldsState.type,
        group_index: totalGroups + 1,
        teacher_age: Date.now() - new Date(user.publish_date).getTime(),
        timestamp: Date.now(),
      };
      const type = 'group_created';
      Mixpanel.track(type, data);
      logger.track(type, { group: data });

      dispatch({
        type: CREATE_GROUP_V2.LOADING,
        payload: { loading: false },
      });
      history.push(`/${localStorage.updatedLocale}/groups/${createGroup.newGroup.id}`);
      onClose();
    }
  }, [createGroup.newGroup]);

  const handleCreateGroup = (data) => {
    dispatch({
      type: CREATE_GROUP_V2.REQUEST,
      payload: data,
    });
  };

  // performs any operation on data coming out of curriculum selection
  const formatCurriculum = ({ fieldsState, targetIds, currentQueryParams, renderField }) => {

    setSelectedCurriculum({
      fieldsState, targetIds, currentQueryParams, renderField,

    });

  };

  const handleChange = (field, value) => {
    setSelectedCurriculum({ ...selectedCurriculum, fieldsState: { ...selectedCurriculum.fieldsState, [field]: value } });
  };

  const handleCreateGroupClick = () => {
    let data = {
      ...Object.assign({}, ...selectedCurriculum.currentQueryParams),
      title: selectedCurriculum.fieldsState.title,
      type: selectedCurriculum.fieldsState.privacy.value,
      description: selectedCurriculum.fieldsState.description,
      creator_type: 'teacher',
      age_group: selectedCurriculum.fieldsState.ageGroup && selectedCurriculum.fieldsState.ageGroup.name,
      curriculum_name: selectedCurriculum.fieldsState.curriculum && selectedCurriculum.fieldsState.curriculum.name,
      test_name: selectedCurriculum.fieldsState.testNameOther,
      skill_name: selectedCurriculum.fieldsState.skill && selectedCurriculum.fieldsState.skill.name,
      curriculum_type: selectedCurriculum.fieldsState.courseType.curriculumType,
    };

    if (!isEmpty(selectedCurriculum.targetIds)) {
      Object.keys(selectedCurriculum.targetIds).forEach((key) => {
        data = {
          ...data,
          [`target_${key}_ids`]: selectedCurriculum.targetIds[key],
        };

      });
    }

    handleCreateGroup(data);
  };

  const { fieldsState: { courseType: { targetFields } = {} } = {}, targetIds = {} } = selectedCurriculum;

  const targetFieldsCompletionCheck = targetFields ? targetFields.length === Object.keys(targetIds).length : true;

  return (
    <ContentOnTop position="sidebar">
      <Column nowrap className="create-group">
        <Row className="create-group__header border">
          <Row align="center" justify="space-between" className="header-inner">
            <Row align="center" justify="space-between" className="primary">
              <IconMyGroups fill="#fff" stroke="#fff" height="35px" width="35px" className="mlr-1" />
              <h4 className="title_text mlr-1">{translationText(noonText, 'groups.createGroupTitle')}</h4>
            </Row>
            <Row className="secondary">
              <IconClose fill="#fff" stroke="#fff" height="14px" width="14px" onClick={onClose} />
            </Row>
          </Row>
        </Row>
        <Row align="start" className="create-group__body">
          {selectedPage === 1 && (
            <Column align="start" justify="space-between" className="flex-1 form-area" nowrap>
              <div
                ref={ref}
                onClick={() => setScrollHeight(ref.current.scrollHeight)}
                className="create-group__form-fields"
              >

                <CurriculumSelection
                  selectedCurriculum={selectedCurriculum}
                  formatCurriculum={formatCurriculum}
                  parentType="group"
                          />

              </div>
              <Row
                className="create-group__button-section"
                flex
                align="center"
                justify="end"
                style={{ width: '100%' }}
              >
                <Button
                  {...{
                    disabled:
selectedCurriculum.fieldsState && selectedCurriculum.fieldsState.courseType && selectedCurriculum.fieldsState.courseType.fields.length < selectedCurriculum.renderField.length && targetFieldsCompletionCheck
  ? ''
  : 'disabled',
                  }}
                  rounded
                  size="md"
                  depth="1"
                  type="secondary"
                  className="plr-1"
                  onClick={() => setSelectedPage(2)}
                >
                  <span>{translationText(noonText, 'form.next')}</span>
                  <IconBack flipX={!isRtl} />
                </Button>
              </Row>
            </Column>
          )}
          {selectedPage === 2 && (
            <Column align="start" justify="space-between" className="flex-1 form-area" nowrap>
              <div
                className={classNames('create-group__form-fields')}
              >
                <Column nowrap justify="start" className="flex-1">
                  <Input
                    maxLength="60"
                    onChange={(event) => {
                      handleChange('title', event.target.value);
                      setInputCharCount({ ...inputCharCount, groupName: event.target.value.length });
                    }}
                    onBlur={(event) => handleChange('title', event.target.value)}
                    value={selectedCurriculum.fieldsState.title}
                    type="text"
                    label={translationText(noonText, 'groups.createGroupGroupTitleLabel')}
                    placeholder={translationText(noonText, 'groups.createGroupGroupTitlePlaceholder')}
                    dark
                  >
                    <span>
                      {inputCharCount.groupName || 0}
                      /60
                    </span>
                  </Input>
                  <Textarea
                    maxLength="250"
                    label={translationText(noonText, 'groups.description')}
                    placeholder={translationText(noonText, 'placeholder.groupDescription')}
                    className="mt-2"
                    dark
                    rows="5"
                    value={selectedCurriculum.fieldsState.description}
                    onChange={(event) => {
                      handleChange('description', event.target.value);
                      setInputCharCount({ ...inputCharCount, groupDescription: event.target.value.length });
                    }}
                    onBlur={(event) => {
                      handleChange('description', event.target.value);
                    }}
                  >
                    <span>
                      {inputCharCount.groupDescription || 0}
                      /250
                    </span>
                  </Textarea>
                  <Select
                    selectedItem={selectedCurriculum.fieldsState.privacy}
                    label={translationText(noonText, 'groups.createGroupGroupTypeLabel')}
                    title={translationText(noonText, 'groups.createGroupGroupTypeLabel')}
                    dark
                    className="mt-5"
                    onSelect={(value) => handleChange('privacy', value)}
                    list={!user.verified ? [selectedCurriculum.fieldsState.privacy] : privacy}
                  />
                  {selectedCurriculum.fieldsState.privacy && (
                    <span className="notes mt-1">
                      {translationText(
                        noonText,
                        selectedCurriculum.fieldsState.privacy.value === 'public'
                          ? 'groups.createGroupOpenGroupTypeDescription'
                          : 'groups.createGroupClosedGroupTypeDescription',
                      )}
                    </span>
                  )}
                </Column>
              </div>
              <Row
                nowrap
                className={classNames('create-group__button-section')}
                align="center"
                justify="end"
                style={{ width: '100%' }}
              >
                <Button
                  rounded
                  size="md"
                  depth="1"
                  type="secondary"
                  className="plr-1 mlr-2"
                  onClick={() => setSelectedPage(1)}
                >
                  <IconBack flipX={isRtl} />
                  <span>{translationText(noonText, 'navigation.back')}</span>
                </Button>
                <Button
                  size="md"
                  depth="1"
                  type="primary"
                  className={isMobileOnly ? 'plr-2' : 'plr-4'}
                  onClick={handleCreateGroupClick}
                  loading={createGroup.loading}
                  disabled={createGroup.loading || !(selectedCurriculum.fieldsState.title && selectedCurriculum.fieldsState.description)}
                >
                  {translationText(noonText, 'groups.createGroupCTA')}
                </Button>
              </Row>
            </Column>
          )}
        </Row>
      </Column>
    </ContentOnTop>
  );
}

CreateGroup.propTypes = {
  onClose: PropTypes.func.isRequired,
  teacherId: PropTypes.string.isRequired,
  totalGroups: PropTypes.number.isRequired,
};
CreateGroup.defaultProp = {
  onClose: () => {},
  teacherId: '',
  totalGroups: '',
};

export default CreateGroup;

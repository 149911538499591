import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Slider } from 'react-player-controls';

import { COLORS } from '../../../constants';
import { IProgressSliderItem, TProgressBarWithSlider } from '../types';

const ProgressBarWithSlider = (props : TProgressBarWithSlider) => {
  const { style, enabled, currentTime, amountBuffered, duration, onSeek } = props;

  const SliderBar = ({ value, style, className }: IProgressSliderItem) => (
    <span className={classNames('bar', 'is-horizontal', className)} style={{ ...style, width: `${value}%` }} />
  );

  const SliderHandle = ({ value, style } : IProgressSliderItem) => (
    <span className="handle is-horizontal" style={{ ...style, left: `${value}%` }} />
  );

  const [lastIntent, setLastIntent] = useState<number>(0);

  const [lastIntentEndCount, setLastIntentEndCount] = useState<number>(0);
  const [value, setValue] = useState<number>(0);
  const [seeking, setSeeking] = useState<boolean>(false);
  const [seekProgress, setSeekProgressing] = useState<boolean>(false);

  const [sync, setSync] = useState<boolean>(true);

  useEffect(() => {
    if (seeking) {
      if (seekProgress) {
        setSync(false);
        setValue(value);
      } else {
        setValue(value);
        setSeeking(false);
      }
    } else {
      if (sync) {
        setValue(currentTime);
      }
      if (!sync) {
        if (Math.abs(currentTime - value) < 2) {
          setSync(true);
        }
        setValue(value);
        onSeek(value);
      }
    }
  }, [seeking, currentTime, seekProgress, sync]);

  return (
    <Slider
      className="slider"
      isEnabled={enabled}
      onChange={(newValue) => setValue((newValue / 100) * duration * 100)}
      onChangeStart={() => {
        setSeeking(true);
        setSeekProgressing(true);
      }}
      onChangeEnd={() => {
        setSeekProgressing(false);
      }}
      onIntent={(intent) => setLastIntent(intent)}
      onIntentEnd={() => setLastIntentEndCount(lastIntentEndCount + 1)}
      style={style}
    >
      <SliderBar className="" value={100} style={{ background: '#E4ECF8' }} />
      <SliderBar className="buffered" value={amountBuffered * 100} style={{ background: COLORS.brand.light }} />
      <SliderBar className="played" value={(value / duration) * 100} style={{ background: enabled ? COLORS.brand.base : '#878c88' }} />
      <SliderBar className="hovered" value={lastIntent * 100} style={{ background: 'rgba(0, 0, 0, 0.05)' }} />
      <SliderHandle value={(value / duration) * 100} style={{ background: enabled ? COLORS.brand.base : '#878c88', transition: 'left 0.2s ease-in' }} />
    </Slider>
  );
};

export default ProgressBarWithSlider;

import React, { useEffect, useRef, useState } from 'react';
import { Card, Badge } from '@noon/atom';
import t from '../../../helpers/translate';

type IGetReadyCardProp = {
    questionTime: number,
    questionNumber: number,
    getReadyTimeLeft: number,
    onGetReadyTimeEnded: Function,
}

const GetReadyCard = (prop: IGetReadyCardProp) => {
  const { questionTime, questionNumber, getReadyTimeLeft, onGetReadyTimeEnded } = prop;
  const timerRef = useRef(null);

  const [time, setTime] = useState(getReadyTimeLeft);

  useEffect(() => {
    if (getReadyTimeLeft) {
      timerRef.current = setInterval(() => {
        setTime((time: number) => {
          if (!time) {
            clearTimeout(timerRef.current);
            onGetReadyTimeEnded();
          }
          return time - 1;
        });
      }, 1000);
    }
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [getReadyTimeLeft]);

  const progress = (100 * (getReadyTimeLeft - time)) / getReadyTimeLeft;

  return (
    <Card className="preparation-timer-wrapper">
      <div className="pt-spinner" style={{ width: `${progress}%` }} />
      <div className="pt-quest">{t('question', 'question')}</div>
      <div className="pt-number">{questionNumber}</div>
      <div>{t('label', 'timeLimit')}</div>
      <div className="pt-time" dir="ltr">
        {questionTime}
        s
      </div>
    </Card>
  );
};

export default GetReadyCard;

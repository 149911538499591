import React, { useEffect, useState, useMemo } from 'react';
import get from 'lodash-es/get';
import { Row } from '@noon/atom';
import { IconShare } from '@noon/quark';
import classNames from 'classnames';
import { isMobileOnly, isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import Popover from 'react-popover';
import ShareButton from '../ShareButton';
import { translationText } from '../../../helpers';

const PopoverBody = ({ shareInfo, hasMembers, noonText, trigger }) => (
  <div className="plr-2 share-popover">
    <Row align="center" justify="space-between" flex="1" className="card-header">
      <h3 className="card-header__title no-margin child">{translationText(noonText, 'groups.share')}</h3>
    </Row>
    <ShareButton trigger={trigger} referralEnabled="true" groupInfo={shareInfo} hasMembers={hasMembers} className="share-button" />
  </div>
);

function SharePopover(prop) {
  const { membersInfo, shareInfo, height = '2rem', width = '2rem', className, fill, trigger } = prop;
  const [hasMembers, setHasMembers] = useState(false);
  const [showSharePopover, setShowSharePopover] = useState(false);
  const { noonText } = useSelector((state) => state.toJS().translation);
  const isRtl = document.body.dir === 'rtl';

  useEffect(() => {
    setHasMembers(get(membersInfo, 'total_members') > 0);
  }, [membersInfo, shareInfo]);

  const popoverProps = useMemo(
    () => ({
      isOpen: showSharePopover,
      preferPlace: isMobileOnly && isRtl ? 'right' : isMobileOnly && !isRtl ? 'left' : 'right',
      onOuterAction: () => setShowSharePopover(false),
      body: <PopoverBody membersInfo={membersInfo} trigger={trigger} shareInfo={shareInfo} hasMembers={hasMembers} noonText={noonText} />,
    }),
    [showSharePopover, hasMembers],
  );

  const handleShare = () => {
    if (navigator.share) {
      // const { group_info = {}} = group;
      navigator.share({
        title: 'Noon Academy',
        text: `${translationText(noonText, 'groups.joinMyGroup')} ${shareInfo.title}`,
        url: `${process.env.STUDENT_URL}${localStorage.updatedLocale}/groups/${shareInfo.id}`,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  };

  return (
    navigator.share && isMobile ? (
      <IconShare
        width={width}
        height={height}
        // fill={fill || COLORS.coolGrey.base}
        className={classNames('child', className, { active: showSharePopover })}
        onClick={handleShare}
      />
    ) : (
      <Popover {...popoverProps}>
        <IconShare
          width="20px"
          height="20px"
          // fill={fill || COLORS.coolGrey.base}
          className={classNames('child', className, { active: showSharePopover })}
          onClick={() => setShowSharePopover(!showSharePopover)}
        />
      </Popover>
    )
  );
}

export default SharePopover;

import React from 'react';
import { Column, Row } from '@noon/atom';
import { IconLoader } from '@noon/quark';
import EmptyState from '../../emptyState';
import FlashcardItem, { IFlashcard } from './flashcardItem';
import t from '../../../../helpers/translate';

type IBooksProp = {
    flashcards: {
        list: IFlashcard[],
        isLoading: Boolean,
    },
    onSelectFlashcard: (flashcard: IFlashcard) => void,
}

const Flashcards = ({ flashcards, onSelectFlashcard }: IBooksProp) => (
  <Column nowrap className="folder-flashcard simplified full-height">

    {!flashcards.isLoading && !flashcards?.list?.length && (
      <EmptyState
        title={t('content', 'empty_flashcard_title')}
        subtitle={t('content', 'empty_flashcard_subtitle')}
      />
    )}

    {flashcards.isLoading && !flashcards?.list?.length && (
      <Column nowrap align="center" justify="center" className="flex-1 full-width">
        <IconLoader />
      </Column>
    )}

    {!flashcards.isLoading && !!flashcards?.list && (
      <Row className="folder-flashcard__list full-width">
        {flashcards?.list.map((flashcard) => <FlashcardItem key={flashcard.id} flashcard={flashcard} selectFlashcard={onSelectFlashcard} />)}
      </Row>
    )}
  </Column>
);

export default Flashcards;

import {
  SEND_OTP,
  VERIFY_PHONE_OTP,
  RESET_VERIFY_OTP,
  UPDATE_ONBOARDING_COUNTRY,
  SEND_OTP_VIA_EMAIL,
} from '../constants';
import { getCountryId } from '../../constants';

const initialState = {
  verification_id: null,
  otp_type: null,
  type: null,
  access_token: null,
  refresh_token: null,
  otpVerified: false,
  phone_number: null,
  dialing_code: null,
  loading: null,
  error: null,
  email: null,
  emailOtpSent: null,
  onboardingCountry: getCountryId(),
};

export default function onboarding(state = initialState, action) {
  switch (action.type) {
    case SEND_OTP.REQUEST:
      return {
        ...state,
        phone_number: action.payload.identity_value,
        dialing_code: action.payload.dialing_code,
        loading: true,
        resent: action.payload.resent,
        error: null,
      };
    case SEND_OTP.SUCCESS:
      localStorage.setItem('verification_id', action.payload.verification_id);
      localStorage.setItem('otp_type', action.payload.otp_type);
      return {
        ...state,
        verification_id: action.payload.verification_id,
        otp_type: action.payload.otp_type,
        retry_time: action.payload.retry_time,
        loading: false,
        error: null,
      };
    case SEND_OTP.FAILURE:
      return {
        ...state,
        // If user is first time sending request then value is null already, and if sending second time then to set null is wrong
        // verification_id: null,
        // otp_type: null,
        loading: false,
        error: action.payload,
      };
    case SEND_OTP_VIA_EMAIL.REQUEST:
      return {
        ...state,
        email: action.payload.email,
        loading: true,
        error: null,
      };
    case SEND_OTP_VIA_EMAIL.SUCCESS:
      return {
        ...state,
        emailOtpSent: true,
        loading: false,
        error: null,
      };
    case SEND_OTP_VIA_EMAIL.FAILURE:
      return {
        ...state,
        emailOtpSent: false,
        loading: false,
        error: action.payload,
      };
    case VERIFY_PHONE_OTP.REQUEST:
      return {
        ...state,
        otp: action.payload.otp,
        loading: true,
        error: null,
        resent: false,
      };
    case VERIFY_PHONE_OTP.SUCCESS:
      localStorage.setItem('access_token', action.payload.access_token);
      localStorage.setItem('refresh_token', action.payload.refresh_token);
      return {
        ...state,
        access_token: action.payload.access_token,
        refresh_token: action.payload.refresh_token,
        type: action.payload.type,
        otpVerified: true,
        loading: false,
        error: null,
      };
    case VERIFY_PHONE_OTP.FAILURE:
      return {
        ...state,
        access_token: null,
        refresh_token: null,
        loading: false,
        error: action.payload,
      };
    case UPDATE_ONBOARDING_COUNTRY:
      return {
        ...state,
        onboardingCountry: action.payload,
      };
    case RESET_VERIFY_OTP:
      return {
        ...state,
        verification_id: null,
        otp_type: null,
        type: null,
        access_token: null,
        refresh_token: null,
        otpVerified: false,
        loading: false,
        onboardingCountry: 1,
        emailOtpSent: false,
      };
    default:
      return state;
  }
}


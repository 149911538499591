import React from 'react';
import PropTypes from 'prop-types';
import { Card, Checkbox } from '@noon/atom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { translationText } from '../../../helpers';

const ChecklistComponent = ({ crops }) => {

  const { noonText } = useSelector((state) => state.toJS().translation);
  const questionBodySelected = crops.filter((c) => c.isQuestion).length > 0;
  const answersSelected = crops.filter((a) => a.isAnswer).length >= 1;
  const correctAnswerSelected = crops.filter((a) => a.isCorrectAnswer).length > 0;

  return (
    <Card className="checklist">
      <p className="title">{translationText(noonText, 'image2question.ChecklistTitle')}</p>
      <ul>
        <li className={classNames('item', { done: questionBodySelected })}>
          <Checkbox
            label={translationText(noonText, 'image2question.ChecklistStepOne')}
            type="checkmark"
            checked={questionBodySelected}
          />
        </li>
        <li className={classNames('item', { done: answersSelected })}>
          <Checkbox
            label={translationText(noonText, 'image2question.ChecklistStepTwo')}
            type="checkmark"
            checked={answersSelected}
          />
        </li>
        <li className={classNames('item', { done: correctAnswerSelected })}>
          <Checkbox
            label={translationText(noonText, 'image2question.ChecklistStepThree')}
            type="checkmark"
            checked={correctAnswerSelected}
          />
        </li>
      </ul>
    </Card>
  );
};

ChecklistComponent.propTypes = {
  crops: PropTypes.arrayOf(PropTypes.shape({
    isQuestion: PropTypes.bool,
    isAnswer: PropTypes.bool,
    isCorrectAnswer: PropTypes.bool,
  })),
};

ChecklistComponent.defaultProps = {
  crops: [],
};

export default ChecklistComponent;

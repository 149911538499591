import React from 'react';
import { Button, Row, Column } from '@noon/atom';
import classNames from 'classnames';
import { IconLoaderDotted, IconMic } from '@noon/quark';
import t from '../../../helpers/translate';
import VideoCard from '../Video/VideoCard';
import { COLORS } from '../../../constants';

type Props = {
    step: number,
    isMicEnabled: boolean,
    sessionData: {
        id: string,
        is_video_enabled: boolean,
        isCameraWorking: boolean,
            webrtc_details: {
                webrtc_api: string,
                webrtc_token: string
            }
    },
    micTimer: number,
    volume: number,
    initAgoraRtc: () => void,
    handleOnClickEnableMic: () => void,
    onSkip: () => void
}

const precallStep = [
  { step: 1,
    image: true,
    heading: 'compatibilityCheck',
    content: 'contentPrecallStep1',
    contentTwo: 'content2PrecallStep1',
    button: 'buttonStep1',
    mic: false,
    loader: false,
  },
  { step: 2,
    image: false,
    heading: 'headingStep2',
    content: 'contentPrecallStep2',
    button: false,
    loader: true,
    mic: false,
  },
  { step: 3,
    image: false,
    heading: 'headingStep3',
    content: 'contentPrecallStep3',
    mic: true,
    button: false,
    loader: false,
  },
  { step: 4,
    image: false,
    heading: false,
    content: 'contentPrecallStep4',
    mic: false,
    // button: 'buttonStep4',
    loader: false,
  },
];

const PreCallTestSteps = ({ step, isMicEnabled, sessionData, micTimer, volume, initAgoraRtc, handleOnClickEnableMic, onSkip }: Props) => (
  <div className={classNames('background', 'precallTest')}>
    <Row align="center" className={classNames('precallTest--carousel', { stepOne: step === 1 })}>
      {precallStep.filter((x) => x.step === step).map((x, i) => (
        <React.Fragment key={i}>
          {x.image && (
            <Row className="precallTest--carousel__image">
              <img src="/assets/images/precallCaraousel.png" alt="precall" />
            </Row>
          )}

          <Column flex={1} className="precallTest--carousel__content">
            <Row flex={1} align="center">
              <Row flex={2}>
                <Column>
                  {x.heading && (
                    <div className="headingOne">
                      {t('classroom', x.heading.toString())}
                    </div>
                  )}
                  <p>
                    {t('classroom', x.content)}
                    {/* {x.content} */}
                  </p>
                  {x.contentTwo && (
                    <React.Fragment>
                      <br />
                      <p>
                        {t('classroom', x.contentTwo)}
                      </p>
                    </React.Fragment>
                  )}
                </Column>
              </Row>

              {(x.mic || x.loader) && (
                <Row justify="center" align="center" flex={1}>

                  {x.loader
                    ? <IconLoaderDotted height="50px" width="50px" fill={COLORS.green.base} stroke={COLORS.green.base} />
                    : (!!x.mic
                      && (
                      <div onClick={handleOnClickEnableMic} className={classNames('mic', { active: isMicEnabled })}>
                        {isMicEnabled && (
                        <div className="timer">
                          <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg">
                            <g>
                              <circle id="circle" style={{ strokeDashoffset: `${(252 + micTimer) * (252 / 7)}` }} className="circle_animation" r="36" cy="40" cx="40" strokeWidth="4" stroke={COLORS.primary.dark} fill="none" />
                            </g>
                          </svg>
                        </div>
                        )}
                        <IconMic className="micSvg" width="24px" fill={isMicEnabled ? COLORS.green.base : COLORS.text.base} />
                        {isMicEnabled && (
                        <div className="volumeIndicator">
                          <span style={{ height: `${volume}%` }} />
                        </div>
                        )}
                        {sessionData.is_video_enabled && sessionData.isCameraWorking && isMicEnabled && <VideoCard videoStatus />}
                      </div>
                      )
                    )}

                </Row>
              )}
            </Row>

            <Row>
              <Row flex={1} style={{ position: 'relative' }} align="center">
                <Row className="dots-Carousel" justify="center">
                  {precallStep.map((x, i) => <span key={i} className={classNames('false', x.step === step && ('active'))} />)}
                </Row>
                {x.button && (
                  <Row flex={1} gap="sm" justify="end">
                    <Button
                      onClick={x.step === 1 ? initAgoraRtc : false}
                      className="button">
                      {t('classroom', x.button.toString())}
                    </Button>
                    <Button
                      outlined
                      type="red"
                      onClick={onSkip}
                    >
                      {t('home', 'skip')}
                    </Button>
                  </Row>
                )}
              </Row>
            </Row>
          </Column>
        </React.Fragment>
      ),
      )}
    </Row>
  </div>
);

export default PreCallTestSteps;

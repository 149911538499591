import React, { useEffect, useState } from 'react';
import { Row, Column } from '@noon/atom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import get from 'lodash-es/get';
import ReportPageBar from '../../components/ReportPage/ReportPageBar';
import ReportPageHeader from '../../components/ReportPage/ReportPageHeader';
import { GET_HOMEWORK_DETAILS_SPARTA, GET_NOT_SUBMITTED_USERS, GET_QUESTIONS_BY_IDS_SPARTA, GET_SUBMITTED_USERS, GET_TEACHER_GROUP_SELECTED } from '../../redux/constants';
import ReportQuestions from '../../components/ReportPage/ReportQuestions';
import ReportPageMobileStats from '../../components/ReportPage/ReportPageMobileStats';
import { checkIsMobile, checkIsTablet } from '../../helpers/prepareAssignment';
import ReportPageMobileUserModal from '../../components/ReportPage/ReportPageMobileUserModal';
import { addToast, TOAST_TYPE } from '../../components/Toast';
import { translationText } from '../../helpers';

const ReportPage = (props) => {
  const [isBarOpen, setIsBarOpen] = useState(false);
  const [isUserAnswersOpen, setIsUserAnswersOpen] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const { hw_details, deleteHomework, currentQuestions, updateHomework } = useSelector((state) => state.toJS().homeworkCreation);
  const { submittedUsers, notSubmittedUsers, studentProfile } = useSelector((state) => state.toJS().homeworkReport);
  const { noonText } = useSelector((state) => state.toJS().translation);
  const dispatch = useDispatch();
  const history = useHistory();
  const { question_ids = [], id: hw_id, group_id: hw_group_id } = hw_details;

  useEffect(() => {
    if (question_ids && question_ids.length) {
      dispatch({
        type: GET_QUESTIONS_BY_IDS_SPARTA.REQUEST,
        hw_id: props.match.params.id,
        hw_ids: question_ids,
      });
    }
  }, [question_ids]);
  useEffect(() => {
    if (updateHomework.success) {
      addToast(translationText(noonText, 'success.userUpdated'), TOAST_TYPE.SUCCESS);
      dispatch({
        type: GET_HOMEWORK_DETAILS_SPARTA.REQUEST,
        hw_id,
      });
    }
  }, [updateHomework]);
  useEffect(() => {
    if (!deleteHomework.loading && deleteHomework.success) {
      addToast(translationText(noonText, 'createHomework.deletedNotify', TOAST_TYPE.SUCCESS));
      history.push(`/groups/${hw_group_id}/content`);
    }
  }, [deleteHomework]);

  const getSubmittedStudentsList = () => {
    dispatch({
      type: GET_SUBMITTED_USERS.REQUEST,
      hw_id: props.match.params.id,
      payload: submittedUsers.meta.last_tag_id !== undefined ? {
        limit: 10,
        last_tag_id: submittedUsers.meta.last_tag_id,
      } : {
        limit: 10,
      },
    });
  };
  const getNotSumbittedStudentsList = () => {
    dispatch({
      type: GET_NOT_SUBMITTED_USERS.REQUEST,
      hw_id: props.match.params.id,
      payload: notSubmittedUsers.meta.last_tag_id !== undefined ? {
        limit: 10,
        last_tag_id: notSubmittedUsers.meta.last_tag_id,
      } : {
        limit: 10,
      },
    });
  };

  useEffect(() => {
    if (get(props, ['match', 'params', 'id'])) {
      dispatch({
        type: GET_HOMEWORK_DETAILS_SPARTA.REQUEST,
        hw_id: props.match.params.id,
      });
    }
    if (get(props, ['match', 'params', 'groupId'])) {
      dispatch({
        type: GET_TEACHER_GROUP_SELECTED.REQUEST,
        payload: props.match.params.groupId,
      });
    }
    getSubmittedStudentsList();
    getNotSumbittedStudentsList();
  }, []);
  useEffect(() => {
    if (loadMore === 'submitted') {
      getSubmittedStudentsList();
      setLoadMore('');
    }
    if (loadMore === 'notSubmitted') {
      getNotSumbittedStudentsList();
      setLoadMore('');
    }
  }, [loadMore]);

  return (
    <div className="report">
      <ReportPageHeader
        noonText={noonText}
        isBarOpen={isBarOpen}
        setIsBarOpen={() => setIsBarOpen(false)}
      />
      <Row
        justify="center"
        className="report--wrapper"
        style={{ opacity: isUserAnswersOpen ? '1' : '.9' }}
      >
        <ReportPageBar
          details={hw_details}
          noonText={noonText}
          isBarOpen={isBarOpen}
          setLoadMore={setLoadMore}
          setIsBarOpen={() => setIsBarOpen(false)}
          setIsUserAnswersOpen={() => setIsUserAnswersOpen(true)}
        />
        <Column style={{ display: isBarOpen ? 'none' : 'flex', width: checkIsMobile() ? '100%' : 'initial' }}>
          <ReportPageMobileStats details={hw_details} noonText={noonText} setIsOpen={() => setIsBarOpen(true)} />
          <ReportQuestions
            questions={currentQuestions.data}
            loading={currentQuestions.loading}
            noonText={noonText}
            profile={studentProfile}
          />
        </Column>
        <ReportPageMobileUserModal
          details={hw_details}
          noonText={noonText}
          questions={currentQuestions.data}
          loading={currentQuestions.loading}
          profile={studentProfile}
          isOpen={isUserAnswersOpen}
          setIsOpen={() => setIsUserAnswersOpen(false)}
        />
      </Row>
    </div>
  );
};

export default ReportPage;

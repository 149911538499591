import {
  SET_CHAPTER_AND_TOPIC,
  CREATE_QUESTION_MODAL
} from '../constants';

export const updateImage2QuestionChapterAndTopic = (payload) => ({
  type: SET_CHAPTER_AND_TOPIC,
  payload,
});

export const toggleCreateQuestionModal = (payload) => ({
  type: CREATE_QUESTION_MODAL,
  payload
});
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Badge, Card, Row, Column } from '@noon/atom';
import { IconPlus } from '@noon/quark';
import { differenceBy, findIndex, get, isEmpty, map, reject, toLower } from 'lodash-es';
import { isMobileOnly } from 'react-device-detect';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Draggable } from 'react-smooth-dnd';
import { IconArrowDown, IconCorrectCircle, IconDelete, IconDrag, IconEditO, IconSession, IconWrong } from '@noon/quark';
import TopicCard from './topicCard';
import { COLORS } from '../../constants';
import Autocomplete from './autocomplete';
import { ADD_TOPIC, REORDER_TOPIC, UPDATE_TOPIC, DELETE_TOPIC, SET_TOPIC } from '../../redux/constants';
import { ConfirmationModal } from '../Modals';
import { translationText } from '../../helpers';
import { addToast, TOAST_TYPE } from '../Toast';

function ChapterCard({
  index,
  chapter,
  handleUpdateChapter,
  suggestedChaptersList,
  handleGetSuggestedChapters,
  suggestedTopics,
  handleGetSuggestedTopics,
  handleDeleteNewChapterOrRevision,
  publishedOrCloseButtonClicked,
  handleCheckDuplicateChapter,
}) {
  const [accordoinState, setAccordionState] = useState(() => chapter.state === 'ongoing');
  const [expectedSessionsCount, setExpectedSessionsCount] = useState(
    chapter.expected_session_count || get(chapter, ['topic_info', 'length']) || 1,
  );
  const [enableEditChapterName, setEnableEditChapterName] = useState(!chapter.name);
  const [showEditDeleteIcon, setShowEditDeleteIcon] = useState(isMobileOnly);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [autoCompleteUserInput, setAutoCompleteUserInput] = useState('');
  const { noonText } = useSelector((state) => state.toJS().translation);
  const { is_unified_group_teacher } = useSelector((state) => state.toJS().experiment.featureFlags);
  const [isDuplicateChapter, setIsDuplicateChapter] = useState(false);
  const [isPlusChapter, setIsPlusChapter] = useState(false);
  const [freeTopicCount, setFreeTopicCount] = useState(0);
  const [paidTopicCount, setPaidTopicCount] = useState(0);

  const isChapterEmpty = useMemo(() => {
    const topicsList = reject(chapter.topic_info || [], (topic) => topic.is_deleted || topic.name === '');
    return chapter.type === 'chapter' && topicsList.length === 0;
  }, [chapter]);

  const dispatch = useDispatch();
  const tag = useMemo(() => get(chapter, 'curriculum') || get(chapter, 'section'), [chapter]);

  const filteredSuggestedTopics = useMemo(() => {
    const topicsList = reject(chapter.topic_info || [], (topic) => topic.is_deleted);
    if (isEmpty(chapter.topic_info)) {
      const mappedTopics = map(suggestedTopics, (topic, index) => ({
        ...topic,
        id: undefined,
        topic_id: topic.id,
        name_header: `Topic ${index + 1}`,
        name_header_key: `topic${index + 1}`,
      }));
      if (!isEmpty(mappedTopics)) {
        handleUpdateChapter({ topic_info: mappedTopics }, index);
      }
    }
    return differenceBy(suggestedTopics, topicsList, 'name');
  }, [suggestedTopics]);

  const moveTopic = ({ removedIndex, addedIndex }) => {
    dispatch({
      type: REORDER_TOPIC,
      payload: {
        chapterIndex: index,
        removedIndex,
        addedIndex,
        topic: chapter.topic_info[removedIndex],
      },
    });
  };

  useEffect(() => {
    if (!chapter.topic_info && chapter.type !== 'revision') {
      dispatch({
        type: SET_TOPIC,
        payload: {
          chapterIndex: index,
        },
      });
    }
  }, []);

  useEffect(() => {
    let plusChapter = true;
    setFreeTopicCount(0);
    setPaidTopicCount(0);
    if (!isEmpty(chapter.topic_info)) {
      chapter.topic_info.forEach((singleTopic) => {
        if (singleTopic.is_free) {
          plusChapter = false;
          setFreeTopicCount((prev) => prev + 1);
        } else {
          setPaidTopicCount((prev) => prev + 1);
        }
      });
    }
    setIsPlusChapter(plusChapter);
  }, [JSON.stringify(chapter.topic_info)]);

  useEffect(() => {
    if (enableEditChapterName && chapter.type !== 'revision') {
      handleGetSuggestedChapters(chapter);
    }
  }, [enableEditChapterName]);

  useEffect(() => {
    handleUpdateChapter({ expected_session_count: parseInt(expectedSessionsCount, 10) }, index);
  }, [expectedSessionsCount]);

  const handleUpdateAccordionState = () => {
    setAccordionState(!accordoinState);
  };

  const handleEditChapterNameClick = () => {
    setEnableEditChapterName(true);
  };
  const handleDeleteChapterConfirmation = () => {
    if (chapter.upcoming_activities > 0) {
      addToast(translationText(noonText, 'groupCurriculum.cantDeleteEntityWithActivity'), TOAST_TYPE.WARNING);
    } else if (chapter.topic_info && chapter.topic_info.length > 0) {
      setShowDeleteConfirmation(true);
    } else {
      handleDeleteNewChapterOrRevision(index);
    }
  };

  const handleCloseEditChapterNameClick = () => {
    setEnableEditChapterName(false);
    if (chapter.name === '') {
      handleDeleteNewChapterOrRevision(index);
    }
  };

  // const handleExpectedSessionsCountChange = () => {
  //   handleUpdateChapter('expected_session_count', parseInt(expectedSessionsCount, 10), index);
  // };

  const handleAddNewTopic = () => {
    if (chapter.type !== 'revision') {
      dispatch({
        type: ADD_TOPIC,
        payload: {
          chapterIndex: index,
        },
      });
    }
  };

  const handleCheckDuplicateTopic = (topicName, topicIndex) => {
    const duplicateTopicIndex = findIndex(
      chapter.topic_info,
      (topic) => toLower(topic.name) === toLower(topicName) && !topic.is_deleted,
    );
    return topicIndex !== duplicateTopicIndex && duplicateTopicIndex > -1;
  };

  const handleUpdateTopic = (topic, topicIndex) => {
    // const selectedTopic = suggestedTopics ? suggestedTopics.find(t => t.name === topic) || {} : {};
    const currentTopic = chapter.topic_info[topicIndex];
    // const isNotMapped = !!(!selectedTopic.id && suggestedTopics && suggestedTopics.length > 0);
    dispatch({
      type: UPDATE_TOPIC,
      payload: {
        chapterIndex: index,
        index: topicIndex,
        topic: {
          ...currentTopic,
          name: topic,
          // topic_id: selectedTopic.id || currentTopic.topic_id,
          state: 'scheduled',
          // isNotMapped: isNotMapped || undefined,
        },
      },
    });
  };

  const handleUpdateTopicStatus = (topicIndex, isFree) => {
    const currentTopic = chapter.topic_info[topicIndex];
    // const isNotMapped = !!(!selectedTopic.id && suggestedTopics && suggestedTopics.length > 0);
    dispatch({
      type: UPDATE_TOPIC,
      payload: {
        chapterIndex: index,
        index: topicIndex,
        topic: {
          ...currentTopic,
          is_free: isFree,
        },
      },
    });
  };

  const handleMapUpdateTopic = (selectedTopic, topicIndex) => {
    const currentTopic = chapter.topic_info[topicIndex];
    dispatch({
      type: UPDATE_TOPIC,
      payload: {
        chapterIndex: index,
        index: topicIndex,
        topic: {
          ...currentTopic,
          topic_id: selectedTopic.id,
        },
      },
    });
  };

  const handleDeleteTopic = (removedIndex) => {
    dispatch({
      type: DELETE_TOPIC,
      payload: {
        chapterIndex: index,
        index: removedIndex,
        topic: { ...chapter.topic_info[removedIndex] },
      },
    });
  };

  const handleUpdateEditChapterNameClick = (value) => {
    if (isEmpty(value)) return false;
    const isDuplicate = handleCheckDuplicateChapter(value, index);
    setIsDuplicateChapter(isDuplicate);
    if (isDuplicate) return false;

    const selectedChapter = suggestedChaptersList ? suggestedChaptersList.find((t) => t.name === value) || {} : {};
    setEnableEditChapterName(false);
    const updatedChapter = isEmpty(selectedChapter)
      ? {}
      : { ...selectedChapter, id: undefined, chapter_id: selectedChapter.id };
    handleUpdateChapter({ ...updatedChapter, name: value }, index);
    if (isEmpty(chapter.topic_info)) {
      handleGetSuggestedTopics({ ...chapter, chapter_id: selectedChapter.id });
    }
  };

  const handleChapterTopicSuggestions = () => {
    handleGetSuggestedTopics(chapter);
  };

  return (
    <Card className="curriculum-page__chapter-card mtb-2 accordion">
      {enableEditChapterName && (
        <Card className="full-width">
          <Column align="start" className="flex-1 plr-2 ptb-2 chapter-header-mobile" gap="sm">
            <Row align="center" justify="space-between" style={{ width: '100%' }}>
              <Badge
                style={{ background: '#EDEDED' }}
                outlined
                type="default"
                value={translationText(noonText, `groups.${chapter.name_header_key}`)}
                size="medium"
              />
              <Row align="center" gap="sm">
                <IconCorrectCircle
                  height="40px"
                  width="40px"
                  stroke="#fff"
                  fill={COLORS.primary.base}
                  className="child"
                />
                <IconWrong
                  height="40px"
                  width="40px"
                  fill="#707070"
                  stroke="#fff"
                  className="child"
                  onClick={handleCloseEditChapterNameClick}
                />
              </Row>
            </Row>
            <Autocomplete
              style={{ width: '100%' }}
              onSelect={handleUpdateEditChapterNameClick}
              onChange={(inputValue) => {
                setAutoCompleteUserInput(inputValue);
              }}
              value={chapter.name}
              suggestions={suggestedChaptersList || []}
              emptyMessage={translationText(noonText, 'groupCurriculum.noChapter')}
              error={isDuplicateChapter}
              errorMessage={translationText(noonText, 'groupCurriculum.chapterNameExists')}
            />
          </Column>
          <Row align="start" className="flex-1 plr-2 ptb-2 chapter-header" gap="sm">
            <Badge
              style={{ background: '#EDEDED' }}
              outlined
              type="default"
              value={translationText(noonText, `groups.${chapter.name_header_key}`)}
              size="medium"
            />
            <Autocomplete
              style={{ width: '100%' }}
              onSelect={handleUpdateEditChapterNameClick}
              onChange={(inputValue) => {
                setAutoCompleteUserInput(inputValue);
              }}
              value={chapter.name}
              suggestions={suggestedChaptersList || []}
              emptyMessage={translationText(noonText, 'groupCurriculum.noChapter')}
              error={isDuplicateChapter}
              errorMessage={translationText(noonText, 'groupCurriculum.chapterNameExists')}
            />
            <IconCorrectCircle
              height="40px"
              width="40px"
              stroke="#fff"
              fill={COLORS.primary.base}
              className="child"
              onClick={() => handleUpdateEditChapterNameClick(autoCompleteUserInput)}
            />
            <IconWrong
              height="40px"
              width="40px"
              fill="#707070"
              stroke="#fff"
              className="child"
              onClick={handleCloseEditChapterNameClick}
            />
          </Row>
        </Card>
      )}
      <Row nowrap align="start" justify="space-between" className="chapter-card--inner" gap="sm">
        {!enableEditChapterName && (
          <React.Fragment>
            {chapter.state === 'ongoing' ? (
              <IconCorrectCircle width="24px" className="edit-profile" fill={COLORS.brand.base} stroke="#fff" />
            ) : chapter.state === 'completed' ? (
              <IconCorrectCircle width="24px" className="edit-profile" fill={COLORS.coolGrey[1]} stroke="#fff" />
            ) : (
              <IconDrag
                height="13px"
                className={classNames('icon-drag edit-profile', { 'icon-hover': showEditDeleteIcon })}
              />
            )}
          </React.Fragment>
        )}
        <Column justify="start" align="start" flex="1" className="plr-2" gap>
          {!enableEditChapterName && (
            <Row
              nowrap
              align="start"
              justify="space-between"
              flex="1"
              className="full-width accordion__header"
              onMouseEnter={() => setShowEditDeleteIcon(true)}
              onMouseLeave={() => setShowEditDeleteIcon(isMobileOnly)}
            >
              <Column align="start" justify="center" gap="sm">
                <Row nowrap align="center" gap="sm">
                  {is_unified_group_teacher && is_unified_group_teacher.isEnabled && isPlusChapter && (
                    <Row>
                      <img
                        alt="noon plus"
                        src="/assets/images/plustag.png"
                        style={{ width: '40px' }}
                      />
                    </Row>
                  )}

                  <div className="flex-row flex-1" style={{ minWidth: 0 }}>
                    <h3 className="no-margin heading child" style={{ lineHeight: '1' }}>
                      <span>{translationText(noonText, `groups.${chapter.name_header_key}`)}</span> - {chapter.name}
                    </h3>
                  </div>
                  {chapter.type === 'revision' && (
                    <Button
                      style={{
                        borderRadius: '1.5rem',
                        fontSize: '1.2rem',
                        textTransform: 'capitalize',
                        height: '2rem',
                        textAlign: 'center',
                        lineHeight: '1px',
                      }}
                      type="secondary"
                      rounded
                      size="md"
                    >
                      Revision
                    </Button>
                  )}
                  {tag && (
                    <Badge
                      style={{ background: '#E4ECF8' }}
                      className="mlr-1 tag"
                      outlined
                      type="default"
                      value={tag.name}
                      size="medium"
                    />
                  )}
                  {showEditDeleteIcon && (
                    <React.Fragment>
                      <IconEditO
                        width="12px"
                        height="12px"
                        className="edit-profile mlr-1"
                        onClick={handleEditChapterNameClick}
                      />
                      <IconDelete
                        width="12px"
                        height="12px"
                        className="edit-profile mlr-1"
                        onClick={handleDeleteChapterConfirmation}
                      />
                      {showDeleteConfirmation && (
                        <ConfirmationModal
                          modalType="delete"
                          successBtn={translationText(noonText, 'activity.delete')}
                          closeBtn={translationText(noonText, 'button.cancel')}
                          onClose={() => {
                            setShowDeleteConfirmation(false);
                          }}
                          onSuccess={() => {
                            setShowDeleteConfirmation(false);
                            handleDeleteNewChapterOrRevision(index);
                          }}
                          text={translationText(noonText, 'groupCurriculum.deleteChapterTitle')}
                          subText={translationText(noonText, 'groupCurriculum.deleteChapterSubTitle')}
                        />
                      )}
                    </React.Fragment>
                  )}
                </Row>
                <Row>
                  <IconSession height="12px" width="16px" stroke="#6C63FF" fill="#6C63FF" className="edit-profile" />
                  <label className="plr-1">{translationText(noonText, 'groupCurriculum.estimatedSessions')}</label>
                  <Button
                    tooltip={translationText(noonText, 'groupCurriculum.estimatedSessionsHint')}
                    tooltipPos="bottom"
                    className="mlr-1"
                    style={{
                      border: 0,
                      background: 'transparent',
                      padding: 0,
                      minWidth: '20px',
                      height: '20px',
                      lineHeight: '0',
                    }}
                  >
                    <input
                      className="estinamted-session-input"
                      type="text"
                      placeholder="3"
                      disabled={chapter.type === 'misc' ? true : false}
                      onChange={(event) => setExpectedSessionsCount(event.target.value)}
                      value={expectedSessionsCount}
                      maxLength={2}
                    />
                  </Button>
                </Row>
                {publishedOrCloseButtonClicked && isChapterEmpty && (
                  <span className="errorMessage">{translationText(noonText, 'groupCurriculum.addMinimumTopics')}</span>
                )}
              </Column>
              {tag && (
                <Badge
                  style={{ background: '#E4ECF8' }}
                  className="mtb-2 tag-mobile"
                  outlined
                  type="default"
                  value={tag.name}
                  size="medium"
                />
              )}
              <Row nowrap align="center">
                <Row className="mr-3" justify="center">
                  {is_unified_group_teacher && is_unified_group_teacher.isEnabled && !isPlusChapter && (
                    <Row >
                      <Column>
                        <Row className="mb-1">
                          <span>{translationText(noonText, 'groupCurriculum.chapterContains')}</span>
                        </Row>
                        <Row align="center" justify="space-between" className="status-summary">
                          <img src="/assets/images/freecircle.svg" alt="" />
                          <span className='ml-1 mr-1'>{freeTopicCount} {translationText(noonText, 'groupCurriculum.freeTopics')} </span>
                          <img src="/assets/images/pluscircle.svg" alt="" />
                          <span className='ml-1 mr-1'>  {paidTopicCount} {translationText(noonText, 'groupCurriculum.paidTopics')}</span>
                        </Row>
                      </Column>
                    </Row>
                  )}
                </Row>
                {chapter.type !== 'revision' && (
                  <Button
                    onClick={handleUpdateAccordionState}
                    size="sm"
                    className="no-padding accordion__toggle"
                    style={{
                      minWidth: '36px',
                    }}
                    outlined
                  >
                    <IconArrowDown width="1rem" fill="#C7D0EC" flipY={accordoinState} />
                  </Button>
                )}
              </Row>
            </Row>
          )}
          {accordoinState && chapter.type !== 'revision' && (
            <div className={classNames('full-width accordion-content', { 'plr-2': enableEditChapterName })}>
              <Container orientation="vertical" groupName={chapter.name + index} lockAxis="y" onDrop={moveTopic}>
                {chapter.topic_info &&
                  chapter.topic_info.map((topic, index) => {
                    const topicCardProps = {
                      key: topic.id || index,
                      index,
                      topic,
                      chapterIndex: index,
                      chapterType: chapter.type,
                      handleUpdateTopic,
                      handleUpdateTopicStatus,
                      handleDeleteTopic,
                      handleChapterTopicSuggestions,
                      suggestedTopics: filteredSuggestedTopics,
                      handleMapUpdateTopic,
                      handleCheckDuplicateTopic,
                    };
                    if (!topic.is_deleted) {
                      return (
                        <Draggable key={topic.id || index}>
                          <TopicCard {...topicCardProps} />
                        </Draggable>
                      );
                    }
                    return <div />;
                  })}
              </Container>
              <Button outlined type="secondary" size="md" className="mb-2" onClick={handleAddNewTopic}>
                <IconPlus />
                {translationText(noonText, 'button.addTopic')}
              </Button>
            </div>
          )}
        </Column>
      </Row>
    </Card>
  );
}

ChapterCard.propTypes = {
  index: PropTypes.number.isRequired,
  chapter: PropTypes.shape({}).isRequired,
  handleUpdateChapter: PropTypes.func.isRequired,
  suggestedChaptersList: PropTypes.arrayOf(PropTypes.object),
  suggestedTopics: PropTypes.arrayOf(PropTypes.object),
  publishedOrCloseButtonClicked: PropTypes.bool.isRequired,
  handleGetSuggestedChapters: PropTypes.func.isRequired,
  handleGetSuggestedTopics: PropTypes.func.isRequired,
  handleDeleteNewChapterOrRevision: PropTypes.func.isRequired,
  handleCheckDuplicateChapter: PropTypes.func.isRequired,
};

ChapterCard.defaultProps = {
  suggestedTopics: [],
  suggestedChaptersList: [],
};

export default ChapterCard;

import { findIndex, isEmpty } from 'lodash-es';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SlideList from '../../../helpers/slideList';
import t from '../../../helpers/translate';
import { useLocalStorage } from '../../../hooks';
import { useClassroomAnalytics } from '../../../providers/ClassroomAnalytics';
import { useEventDispatch } from '../../../providers/Event';
import {
    addSlide, addSlideSuccess, deleteSlide, getSlides, markSlideAsLoading, moveSlide, takeScreenShot
} from '../../../redux/actions/whiteboard';
import { athenaApi } from '../../../redux/restApi';
import ClassroomWaitingCard from '../../Class/classroomWaitingCard';
import { addToast } from '../../Toast';
import { IQuestion } from '../Content/Questions/questionContainer';
import { CLASSROOM_EVENT } from '../events';
import {
    CLASSROOM_STATE, IMoveSlideData, ISlide, ISlideOptions, ISlideParams, ISlidePayload,
    ISlideQuestionPayload
} from '../types';
import ClassroomSlides from './classroomSlides';
import QuestionPreviewModal from './questionPreviewModal';
import SlideSectionsIntroModal from './slideSectionsIntroModal';

const uuidv4 = require('uuid/v4');

// width for slide is fixed, to calculate slide ratio on student side, teacher send all the sketch event mapped to this width.
// for example : if teacher canvas width is 1120 and SLIDE_WIDTH is 560 than canvas ration will be 560 / 1120 = 0.5, if we send rectangl3 of width 100, it will become 100 * 0.5 = 50
export const SLIDE_WIDTH = 560;

// 9/16 slide ratio
export const SLIDE_HEIGHT = SLIDE_WIDTH * (9 / 16);

const updateMovedSlides = (slides, movedSlideInfo, removedIndex, addedIndex) => {
  const updatedSlides = [...slides];
  const [toSlideInfo = {}, fromSlideInfo] = movedSlideInfo;

  // handle to slide part
  updatedSlides[removedIndex].prev_canvas_id = toSlideInfo.prev_canvas_id;
  if (toSlideInfo.next_canvas_id) {
    updatedSlides[addedIndex + 1].prev_canvas_id = toSlideInfo.canvas_id;
  }

  // handle from slide part
  if (fromSlideInfo) {
    updatedSlides[removedIndex + 1].prev_canvas_id = fromSlideInfo.prev_canvas_id;
  }

  return updatedSlides;
};

const orderedSlideList = (slides) => {
  const slideList = new SlideList();
  slideList.fromArray(slides);
  return slideList.toArray();
};

const moveCanvasApi = (payload) => athenaApi.post('rearrange_canvas', payload);

const SlideContainer : React.FC<{}> = () => {

  const sendMessage = useEventDispatch();
  const dispatchAnalytics = useClassroomAnalytics();
  const dispatch = useDispatch();
  const lastQuestionData = useRef(null);
  const lastSelectedSlide = useRef(null);
  const lastAddedSlide = useRef(null);
  const paramsForPdfUpload = useRef(null);

  const sessionDetails = useSelector((state) => state.toJS().myClassroom.sessionDetails);
  const isClassInitiated = useSelector((state) => state.toJS().myClassroom.isClassInitiated);
  const topicId = useSelector((state) => state.toJS().content.topicId);
  const selectedQuestion = useSelector((state) => state.toJS().content.selectedQuestion);
  const slides = useSelector((state) => state.toJS().whiteboard.slides);
  const isSlideAdding = useSelector((state) => state.toJS().whiteboard.isSlideAdding);
  const isSlideLoading = useSelector((state) => state.toJS().whiteboard.isSlideLoading);
  const activeSlideIndex = useSelector((state) => state.toJS().whiteboard.activeSlideIndex);
  const config = useSelector((state) => state.toJS().whiteboard.slideConfig);
  const maxSlideNumber = useSelector((state) => state.toJS().whiteboard.maxSlideNumber);
  const selectedSlide = useSelector((state) => state.toJS().whiteboard.selectedSlide);
  const slideToBeAdded = useSelector((state) => state.toJS().whiteboard.slideToBeAdded);
  const slideToBeDeleted = useSelector((state) => state.toJS().whiteboard.slideToBeDeleted);
  const result = useSelector((state) => state.toJS().whiteboard.result);

  const [previewQuestion, setPreviewQuestion] = useState<null | IQuestion>(null);
  const [addingSlideFromPdf, setAddingSlideFromPdf] = useState<boolean>(false);
  const [sectionIntroShown, setSectionIntroValue] = useLocalStorage('slide-sections-intro-shown', { normal: false, pdf: true });
  const [precallSessionId, setPrecallSessionId] = useLocalStorage('classroom_precall', {});

  const isClassStarted: boolean = sessionDetails.state === CLASSROOM_STATE.started;
  const isCompetition: boolean = sessionDetails.class_type === 'competition';
  const showWaitingScreen = !slides.length || (isCompetition && isClassStarted && activeSlideIndex === -1 && !result?.length);

  const findSlideIndexById = (slideId) => findIndex(slides, { canvas_id: slideId });

  const handleClosePreviewModal = () => {
    setPreviewQuestion(null);
  };

  const sendSlideEvent = (slidePayload: ISlidePayload | ISlideQuestionPayload, options: ISlideOptions) : void => {
    const width = !options.activate && !options.broadcast && slidePayload.resource_type === 'question' ? 0 : SLIDE_WIDTH;
    const height = !options.activate && !options.broadcast && slidePayload.resource_type === 'question' ? 0 : SLIDE_HEIGHT;

    const payload = {
      ...slidePayload,
      isTeacher: 1,
      width,
      height,
      broadcast: options.broadcast,
      activate: options.activate,
      setReview: isClassStarted ? options.reviewed : false,
    };

    // CLASSROOM_EVENT.addSlideUnicast event for adding Question or any slide without braodcasting, i.e where you don't want to send event to student

    sendMessage(payload.broadcast ? CLASSROOM_EVENT.addSlide : CLASSROOM_EVENT.addSlideUnicast, payload)
      .then(() => {
        dispatchAnalytics('slide_action', {
          slide_no: slidePayload?.canvas_id || slidePayload?.number,
          action: slidePayload?.canvas_id ? 'change' : 'add',
          status: 'success',
        });
      }).catch(() => {
        dispatchAnalytics('slide_action', {
          slide_no: slidePayload?.canvas_id || slidePayload?.number,
          action: slidePayload?.canvas_id ? 'change' : 'add',
          status: 'failure',
        });
      });
  };

  const handleSelectSlide = (activeSlideIndex: number): void => {
    if (isSlideLoading || isSlideAdding || isEmpty(slides) || isEmpty(slides[activeSlideIndex])) {
      return;
    }

    const activeSlide = { ...slides[activeSlideIndex], session_state: isClassStarted ? CLASSROOM_STATE.started : CLASSROOM_STATE.scheduled } || {};

    // if slide type is question and teacher didn't select interactive/breakout/static option
    if (activeSlide.resource_type === 'question' && !activeSlide.reviewed) {
      dispatch(addSlideSuccess(activeSlide));
    } else {
      dispatch(markSlideAsLoading(activeSlide));
      dispatch(takeScreenShot());
      sendSlideEvent(activeSlide, { activate: true, broadcast: true, reviewed: false });
    }
  };

  const handleAddSlide = (prevCanvasIndex: (number | null), slideParams?: ISlideParams): void => {
    if (isSlideLoading || isSlideAdding) return;
    const { resourceType = 'none', resource = {}, resourceId = 0, topicId = 0, activate = true, broadcast = true, label_name = '', label_id = '' } = slideParams || {};

    const slidePayload: ISlidePayload = {
      number: maxSlideNumber + 1,
      resource_type: resourceType,
      resource,
      resource_id: resourceId,
      topic_id: topicId,
      group_id: sessionDetails.id,
      prev_canvas_id: slides[prevCanvasIndex]?.canvas_id,
      next_canvas_id: slides[prevCanvasIndex + 1]?.canvas_id,
      label_name,
      label_id: label_id || uuidv4(),
      session_state: isClassStarted ? CLASSROOM_STATE.started : CLASSROOM_STATE.scheduled,
    };
    dispatch(takeScreenShot(addSlide(slidePayload)));
    sendSlideEvent(slidePayload, { activate, broadcast, reviewed: false });
  };

  const handleAddQuestion = (question: IQuestion, time? : number) => {
    if (isSlideLoading || isSlideAdding) return;

    if (isCompetition && isEmpty(previewQuestion)) {
      const isDuplicate = slides.findIndex((item) => item.resource_type === 'question' && item.resource.id === question.id) !== -1;
      if (isDuplicate) {
        addToast(t('classroom', 'questionAlreadyAdded'));
        return;
      }
      setPreviewQuestion(question);
      return;
    }

    if (isCompetition && !isEmpty(previewQuestion)) handleClosePreviewModal();

    const lastCanvasId = slides[slides.length - 1]?.canvas_id;
    const activeSlide = slides[activeSlideIndex] || {};

    const { label_name = '', label_id = '' } = activeSlide || {};

    const slidePayload: ISlideQuestionPayload = {
      number: maxSlideNumber + 1,
      resource_type: 'question',
      resource: question,
      resource_id: question.id,
      group_id: sessionDetails.id,
      topic_id: topicId,
      prev_canvas_id: lastCanvasId,
      label_name: question.labelName || label_name,
      label_id: question.labelId || label_id || uuidv4(),
      session_state: isClassStarted ? CLASSROOM_STATE.started : CLASSROOM_STATE.scheduled,
    };

    if (time) slidePayload.resource.time = time;

    dispatch(takeScreenShot(addSlide(slidePayload)));
    sendSlideEvent(slidePayload, { activate: false, broadcast: false, reviewed: false });
  };

  const rollbackMove = (slides: Array<ISlidePayload>, activeSlideIndex: number): void => {
    dispatch(moveSlide('FAILURE', { slides, activeSlideIndex }));
  };

  const dispatchSlideMove = (activeSlideIndex: number, payload: any, type: string): string => {

    // added by usama for ts
    console.log('payload in dispatch slide move', payload);

    // for rollback
    const lastSlides = [...slides];
    const lastActiveSlide = activeSlideIndex;
    const sectionToRollbackTo = slides[findSlideIndexById(payload?.data?.[0].slide_id)]?.payload?.label_id;
    let actionSuccess = '';

    moveCanvasApi(payload)
      .then((res) => {
        if (!res.ok) {
          if (type !== 'section') rollbackMove(lastSlides, lastActiveSlide);
        }
        dispatch(moveSlide('SUCCESS'));
        actionSuccess = res.ok ? 'success' : 'failure';
      })
      .catch((err) => {
        console.log(err);
        actionSuccess = 'failure';
        // on error - rollback move action
        if (type !== 'section') rollbackMove(lastSlides, lastActiveSlide);
      });
    return actionSuccess;
  };

  // TODO: later move it to redux saga
  const handleMoveSlide = (removedIndex: number, addedIndex: number): void => {
    const fromSlideCanvasId = slides[removedIndex].canvas_id;
    const fromSlidePrevCanvasId = slides[removedIndex - 1]?.canvas_id;
    const fromSlideNextCanvasId = slides[removedIndex + 1]?.canvas_id;
    const toSlidePrevCanvasId = slides[addedIndex]?.canvas_id;
    const toSlideNextCanvasId = slides[addedIndex + 1]?.canvas_id;
    const currentActiveCanvasId = activeSlideIndex !== -1 ? slides[activeSlideIndex].canvas_id : 0;

    const data: IMoveSlideData[] = [
      {
        canvas_id: fromSlideCanvasId,
        prev_canvas_id: toSlidePrevCanvasId,
        next_canvas_id: toSlideNextCanvasId,
      },
    ];

    if (fromSlideNextCanvasId) {
      data.push({
        canvas_id: fromSlideNextCanvasId,
        prev_canvas_id: fromSlidePrevCanvasId,
      });
    }
    const payload = {
      session_id: sessionDetails.id,
      data,
    };

    const updatedSlides = updateMovedSlides(slides, data, removedIndex, addedIndex);
    const orderedSlides = orderedSlideList(updatedSlides);

    const active = orderedSlides.findIndex((item) => item.canvas_id === currentActiveCanvasId);
    dispatch(moveSlide('REQUEST', { slides: orderedSlides, activeSlideIndex: active }));

    const slideActionResult = dispatchSlideMove(active, payload, 'slide');

    dispatchAnalytics('slide_action', {
      slide_no: fromSlideCanvasId,
      action: 'move',
      status: slideActionResult,
    });
  };

  // handle send data for movement of a whole section
  const handleMoveSection = (data: Array<IMoveSlideData>): void => {
    const payload = {
      session_id: sessionDetails.id,
      data,
    };
    dispatchSlideMove(activeSlideIndex || 0, payload, 'section');
  };

  const handleDeleteSlide = (slideIndex: number): void => {
    const { canvas_id, number } = slides[slideIndex] || {};

    const prev_canvas_id = slides[slideIndex - 1]?.canvas_id;
    const next_canvas_id = slides[slideIndex + 1]?.canvas_id;
    const payload = {
      number,
      delete_type: 'new',
      prev_canvas_id,
      canvas_id,
      next_canvas_id,
    };
    dispatch(deleteSlide(slides[slideIndex]));
    sendMessage(CLASSROOM_EVENT.deleteSlide, payload);
  };

  const fetchSlides = (): void => {
    dispatch(getSlides(sessionDetails.id));
  };

  const proceedToUpload = (): void => {
    // handleAddSlide(paramsForPdfUpload.current.index, paramsForPdfUpload.current.params);
    setSectionIntroValue({ ...sectionIntroShown, pdf: true });
  };

  const handleCloseIntro = (): void => {
    setSectionIntroValue(addingSlideFromPdf ? { ...sectionIntroShown, pdf: true } : { ...sectionIntroShown, normal: true });
    if (addingSlideFromPdf) setAddingSlideFromPdf(false);
  };

  // EFFECTS
  useEffect(() => {
    if (selectedQuestion && selectedQuestion !== lastQuestionData.current) {
      lastQuestionData.current = selectedQuestion;
      handleAddQuestion(selectedQuestion);
    }
  }, [selectedQuestion, isClassStarted]);

  // listen select command from any component triggering dispatch({ type: SELECT_SLIDE }) or selectSlide action;
  useEffect(() => {
    if (selectedSlide !== -1 && selectedSlide !== lastSelectedSlide.current) {
      lastSelectedSlide.current = selectedSlide;
      handleSelectSlide(selectedSlide);
    }
  }, [selectedSlide, isClassStarted]);

  // listen select command from any component triggering dispatch({ type: SLIDE_TO_BE_ADDED });
  useEffect(() => {
    if (slideToBeAdded?.slideIndex !== -1 && slideToBeAdded?.slideIndex !== lastAddedSlide.current?.slideIndex) {
      lastAddedSlide.current = slideToBeAdded;
      const { slideIndex, ...params } = slideToBeAdded;

      // Uncomment this when section resizing is implemented
      // setAddingSlideFromPdf(true);
      // paramsForPdfUpload.current = { index: slideIndex - 1, params };
      handleAddSlide(slideIndex - 1, params);
    }
  }, [slideToBeAdded, isClassStarted]);

  useEffect(() => {
    if (isClassStarted && !isCompetition && !slides.length) {
      handleAddSlide(-1);
    }
  }, [isClassStarted]);

  useEffect(() => {
    if (slideToBeDeleted?.slideIndex !== -1) {
      const { slideIndex } = slideToBeDeleted;
      handleDeleteSlide(slideIndex);
    }
  }, [slideToBeDeleted]);

  return (
    <>
      {!isEmpty(previewQuestion) && (
        <QuestionPreviewModal
          questionDetails={previewQuestion}
          onAddQuestion={handleAddQuestion}
          onClose={handleClosePreviewModal}
        />
      )}
      {showWaitingScreen ? (
        <ClassroomWaitingCard
          sessionData={sessionDetails}
          onAddSlide={handleAddSlide}
          isClassReady={isClassInitiated && !isSlideLoading}
          isSlideAdding={Boolean(isSlideAdding)}
        />
      ) : (
        <ClassroomSlides
          slides={slides}
          isClassStarted={isClassStarted}
          activeSlideIndex={activeSlideIndex}
          isSlideAdding={isSlideAdding}
          isSlideLoading={isSlideLoading}
          config={config}
          onSelectSlide={handleSelectSlide}
          onAddSlide={handleAddSlide}
          onMoveSlide={handleMoveSlide}
          onDeleteSlide={handleDeleteSlide}
          onMoveSection={handleMoveSection}
          getSlides={fetchSlides}
        />
      )}
      {!!precallSessionId && ((!sectionIntroShown.normal && slides.length && !addingSlideFromPdf) || (!sectionIntroShown.pdf && addingSlideFromPdf && slides.length)) ? (
        <SlideSectionsIntroModal
          onProceed={() => proceedToUpload()}
          onClose={() => handleCloseIntro()}
          type={addingSlideFromPdf ? 'pdf' : 'normal'} />
      ) : null}
    </>
  );
};

export default SlideContainer;

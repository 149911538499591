import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Column, Button, Card, Radio } from '@noon/atom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { translationText } from '../../../helpers';

const PopUpDialog = ({
  isQuestion,
  isAnswer,
  isCorrectAnswer,
  cropIndex,
  onMarkAsQuestion,
  onMarkAsAnswer,
  onMarkAsCorrectAnswer,
  onDelete,
  onDone,
  onPopupExpanded,
  onPopupCollapsed,
  isActive,
  questionSelected,
  correctAnswerSelected,
}) => {

  const { noonText } = useSelector((state) => state.toJS().translation);
  const [toggleDialog, onToggleDialog] = useState(isActive);

  const [isNewCrop, setNewCrop] = useState(!isQuestion && !isAnswer && !isCorrectAnswer);

  const handleToggleDialog = (val) => {
    if (val === true) {
      onToggleDialog(true);
      onPopupExpanded(cropIndex);
      onDone(cropIndex);
    } else {
      onToggleDialog(false);
      onPopupCollapsed(cropIndex);
      onDone(cropIndex);
      setNewCrop(false);
    }

  };

  useEffect(() => {
    if (isActive) {
      onPopupExpanded(cropIndex);
      if (questionSelected && !isQuestion && !isCorrectAnswer) {
        onMarkAsAnswer(cropIndex);
      }
    }
  }, [questionSelected, isQuestion, isCorrectAnswer]);
  // let lable;
  // let lableStyle;
  // switch (true) {
  //   case isQuestion:
  //     lable = 'Q';
  //     lableStyle = { color: 'white', background: '#4b46b3' };
  //     break;
  //   case isAnswer || isCorrectAnswer:
  //     lable = 'A';
  //     lableStyle = isCorrectAnswer ? { color: 'white', background: '#52C419' } : { color: 'white', background: '#64D8AE' };
  //     break;
  //   default:
  //     lable = '?';
  //     lableStyle = { color: 'white', background: '#333' };
  //     break;
  // }

  return (
    <>
      {/* { !toggleDialog && <span className="lable" style={lableStyle}>{ lable }</span>} */}
      { toggleDialog && isActive && (
        <Card className="popup-dialog">
          <Row className="heading-wrapper">
            <span className="heading">{translationText(noonText, 'image2question.PopupTitle')}</span>
          </Row>
          {/* <button type="button" onClick={() => onMarkAsCorrectAnswer(cropIndex)}>Mark Ans</button> */}
          <Row className="options">
            <Column gap="lg">
              <Radio
                label={translationText(noonText, 'image2question.PopupSelectQuestion')}
                checked={isQuestion}
                onClick={() => onMarkAsQuestion(cropIndex)}
                className={classNames({ done: isQuestion })}
              />
              <Radio
                label={translationText(noonText, 'image2question.PopupSelectAnswer')}
                checked={isAnswer}
                onClick={() => onMarkAsAnswer(cropIndex)}
                className={classNames({ done: isAnswer })}
              />
              <Radio
                label={translationText(noonText, 'image2question.PopupCorrectAnswer')}
                checked={isCorrectAnswer}
                onClick={() => onMarkAsCorrectAnswer(cropIndex)}
                className={classNames({ done: isCorrectAnswer })}
                disabled={correctAnswerSelected && !isCorrectAnswer}
              />
            </Column>
          </Row>
          <Row nowrap justify="center" align="center" className="actions" gap="xl">
            <Button link className="delete-hotspot" onClick={() => onDelete(cropIndex)}>{ isNewCrop ? translationText(noonText, 'image2question.PopupCancel') : translationText(noonText, 'image2question.PopupDelete')}</Button>
            <Button onClick={() => handleToggleDialog(false)} type="primary">{ isNewCrop ? translationText(noonText, 'image2question.PopupSave') : translationText(noonText, 'image2question.PopupDone') }</Button>
          </Row>
        </Card>
      ) }
    </>
  );
};

PopUpDialog.propTypes = {
  isQuestion: PropTypes.bool,
  isAnswer: PropTypes.bool,
  isCorrectAnswer: PropTypes.bool,
  cropIndex: PropTypes.number,
  onMarkAsAnswer: PropTypes.func,
  onMarkAsQuestion: PropTypes.func,
  onMarkAsCorrectAnswer: PropTypes.func,
  onDelete: PropTypes.func,
  onDone: PropTypes.func,
  onPopupExpanded: PropTypes.func,
  onPopupCollapsed: PropTypes.func,
  isActive: PropTypes.bool,
  questionSelected: PropTypes.bool,
  correctAnswerSelected: PropTypes.bool,
};

PopUpDialog.defaultProps = {
  isQuestion: false,
  isAnswer: false,
  isCorrectAnswer: false,
  cropIndex: -1,
  onMarkAsAnswer: null,
  onMarkAsQuestion: null,
  onMarkAsCorrectAnswer: null,
  onDelete: null,
  onDone: () => {},
  onPopupExpanded: null,
  onPopupCollapsed: null,
  isActive: false,
  questionSelected: false,
  correctAnswerSelected: false,
};

export default PopUpDialog;

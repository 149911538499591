/* eslint-disable no-undef */
import isEmpty from 'lodash-es/isEmpty';
import { browserName, browserVersion, mobileModel } from 'react-device-detect';
import { getCountryId } from '../constants';
import { remoteConfig } from '../components/Firebase';
import { statsLoggerApi } from '../redux/restApi';
import getDeviceProfile from './getDeviceProfile';

const uuidv4 = require('uuid/v4');

const BATCH_INTERVAL = 5 * 1000; // 5 sec
const MAX_BATCH_SIZE = 10;

class NoonLogger {
  constructor(props) {
    const { moduleName, batchInterval = BATCH_INTERVAL, maxBatchSize = MAX_BATCH_SIZE, clientSessionId } = props || {};
    this.moduleName = moduleName;
    this.url = 'injest_multiple';
    this.type = 'NOON_LOGGER_V2';
    this.clientSessionId = clientSessionId || uuidv4();
    this.payloadBatch = [];
    this.batchInterval = batchInterval;
    this.maxBatchSize = maxBatchSize;
    this.user = localStorage.loggedUser ? JSON.parse(localStorage.loggedUser) : {};
    this.deviceId = localStorage.temporaryTokenPayload ? JSON.parse(localStorage.temporaryTokenPayload).device_id : 0;
    this.countryId = getCountryId();

    remoteConfig.fetchAndActivate()
      .then(() => {
        // eslint-disable-next-line no-underscore-dangle
        this.analyticsConfig = JSON.parse(remoteConfig.getValue(`${this.moduleName}_config_teacher_web`)._value || '{}');
        // overide module setting based on remote config data
        if (!isEmpty(this.analyticsConfig) && (this.analyticsConfig.batchInterval || this.analyticsConfig.maxBatchSize)) {
          this.batchInterval = this.analyticsConfig.batchInterval || this.batchInterval;
          this.maxBatchSize = this.analyticsConfig.maxBatchSize || this.maxBatchSize;
        }
      })
      .catch((err) => {
        console.log('logger error', err);
      });
  }

  checkEventSupport(eventConfig) {
    if (eventConfig.disabled) {
      return false;
    }
    if (eventConfig.disabled_in_version.includes(process.env.VERSION)) {
      return false;
    }
    return true;
  }

  eventValidation(event_name) {
    if (this.analyticsConfig && !isEmpty(this.analyticsConfig[event_name])) {
      return this.checkEventSupport(this.analyticsConfig[event_name]);
    }
    return true;
  }

  get deviceDetails() {
    return {
      client_session_id: this.clientSessionId,
      device_id: this.deviceId,
      device_profile: getDeviceProfile(),
      platform: 'web',
      browser: browserName,
      browser_version: browserVersion,
      app_version: process.env.VERSION,
      screen_width: window.innerWidth,
      screen_height: window.innerHeight,
      device_model: mobileModel,
      device_ram: navigator.deviceMemory,
    };
  }

  get userDetails() {
    return {
      user_id: this.user.id || 0,
      gender: this.user.gender,
    };
  }

  get networkDetails() {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    const networkType = connection && connection.effectiveType ? connection.effectiveType.replace('g', '') : 'U';
    const networkDeviceType = connection && connection.type ? connection.type : '';
    return {
      network_type: networkDeviceType === 'wifi' ? 'W' : networkType,
    };
  }

  async track(event_name, event_attributes) {

    // exception for audio stats data
    if (event_name === 'stats') {
      const payload = {
        ...event_attributes,
        u: this.user.id || 0,
        dp: getDeviceProfile(),
        nt: this.networkDetails.network_type,
      };
      this.sendStatsLogs(payload);
      return;
    }

    const payload = {
      id: uuidv4(),
      data: {
        ...event_attributes,
        user: this.userDetails,
        device: this.deviceDetails,
        network: this.networkDetails,
      },
      metadata: {
        tenant: 'teacher',
        timestamp: Date.now(),
        schema_name: `client.teacher.${this.moduleName}.${event_name}`,
        schema_version: '1.0.0',
        timezone: Intl && Intl.DateTimeFormat ? Intl.DateTimeFormat().resolvedOptions().timeZone : '',
        // description: 'When leaving a classroom',
      },
    };

    // check for event validation from config
    if (this.eventValidation(event_name)) {
      if (process.env.ENV !== 'production') console.log('kafka event =====', payload);
      this.payloadBatch = this.payloadBatch.concat(payload);
      this.startSendingLogs();
    }
  }

  startSendingLogs() {
    if (this.batchInterval > 0 && (!this.maxBatchSize || this.maxBatchSize > this.payloadBatch.length)) {
      if (!this.interval) {
        this.interval = setInterval(() => {
          this.sendLogs();
          this.stopInterval();
        }, this.batchInterval);
      }
    } else {
      this.sendLogs();
      this.stopInterval();
    }
  }

  stopInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  sendLogs() {
    if (!this.payloadBatch.length) return;
    const authHeaders = {
      country: this.countryId,
      locale: localStorage.language || 'en',
      platform: 'web',
      'x-device-id': this.deviceId,
      Authorization: `Bearer ${localStorage.token}`,
      'api-version': 2,
      'x-client-time': new Date().getTime(),
      'x-tenant': 'teacher',
    };
    NoonLoggingWorker.postMessage({ authHeaders, payloadToSend: this.payloadBatch, url: this.url, type: this.type });
    this.payloadBatch = [];
  }

  sendStatsLogs(payload) {
    const authHeaders = {
      country: this.countryId,
      locale: localStorage.language || 'en',
      platform: 'web',
      'x-device-id': this.deviceId,
      Authorization: `Bearer ${localStorage.token}`,
      'api-version': 2,
      'x-client-time': new Date().getTime(),
      'x-tenant': 'teacher',
    };
    NoonLoggingWorker.postMessage({ authHeaders, payloadToSend: [payload], url: statsLoggerApi, type: 'CLASSROOM_STATS_V2' });
  }

  async oldTrack({ event_attributes, event_name }) {
    const payloadToSend = {
      schema: {
        name: 'events_version_2',
        version: '2.0',
      },
      event_namespace: event_name,
      country: 1, // default
      app_version: process.env.VERSION,
      role_type: 'teacher',
      city: 0,
      app_os: 'web',
      os_version: 'na',
      user_type: 'registered',
      user_id: 1756129,
      school: 'N/A',
      event_name,
      event_attributes,
      timestamp: Date.now(),
    };
    if (this.deviceId) {
      payloadToSend.country = this.countryId;
      payloadToSend.device_id = this.deviceId;
    }
    if (this.user) {
      payloadToSend.user_id = this.user.id;
    }
    const authHeaders = {
      country: getCountryId(),
      locale: localStorage.getItem('language') || 'ar',
      platform: 'web',
    };
    authHeaders['x-device-id'] = this.deviceId;
    authHeaders.Authorization = `Bearer ${localStorage.token}`;
    authHeaders['api-version'] = 2;
    authHeaders['x-client-time'] = new Date().getTime();
    authHeaders['x-tenant'] = 'teacher';
    NoonLoggingWorker.postMessage({ authHeaders, payloadToSend: [payloadToSend] });
  }
}

export default NoonLogger;

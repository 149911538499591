import React, { Component } from 'react';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import uniqueId from 'lodash-es/uniqueId';
import { isMobileOnly, isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { IconCorrectCircle, IconPicture, IconPlus, IconWrong } from '@noon/quark';
import { Row, Button, ProgressSpinner, Column } from '@noon/atom';
import { HtmlInput } from './index';
import { translationText } from '../../helpers';
import { COLORS } from '../../constants';
import { addToast, TOAST_TYPE } from '../Toast';
import { ConfirmationModal } from '../Modals';

const mobileImgWidth = '75px';
const normalImgWidth = '100px';
export default class CreateAnswer extends Component {
  static propTypes = {
    noonText: PropTypes.shape().isRequired,
    uploadImageProgress: PropTypes.func.isRequired,
    bulkFileUpload: PropTypes.func.isRequired,
    imageUploadProgressStatus: PropTypes.arrayOf(PropTypes.object),
    isBulkFileUploading: PropTypes.bool,
    emojis: PropTypes.shape().isRequired,
    user: PropTypes.shape().isRequired,
    isRtl: PropTypes.bool,
    handlePost: PropTypes.func.isRequired,
    hideFocus: PropTypes.func.isRequired,
    isResolved: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    imageUploadProgressStatus: {},
    isBulkFileUploading: false,
    isRtl: true,
  };

  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.fileRef = React.createRef();
    this._node = {};
    this.state = {
      imagePreviewUrls: [],
      fileType: '',
      isHovering: 0,
      textData: '',
      markAsCorrect: false,
      markAsResolved: props.isResolved,
    };

    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.handleMouseHover = this.handleMouseHover.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleSent = this.handleSent.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !!this.state.imagePreviewUrls.length &&
      prevState.imagePreviewUrls.length !== this.state.imagePreviewUrls.length
    ) {
      if (this.state.fileType === 'image') {
        this.scrollLeft();
      } else {
        this.scrollBottom();
      }
    }
    // if (announcementAndQuestion.includes(this.state.postType) && prevState.postType !== this.state.postType) {
    //   this.postInput.current.focus();
    // }
  }

  filterCondition = (file, type) => {
    if (type === 'image' && file.file.size / (1024 * 1024) >= 6) {
      addToast(translationText(this.props.noonText, 'warning.image_size_warning'), TOAST_TYPE.ERROR);
      return false;
    } else if (type === 'file' && file.file.size / (1024 * 1024) >= 24) {
      addToast(translationText(this.props.noonText, 'warning.image_size_warning'), TOAST_TYPE.ERROR);
      return false;
    } else if (!this.getFileType(file.file.name)) {
      addToast(`${translationText(this.props.noonText, 'post.warning_unsupported_file')} file name:${file.file.name}`, TOAST_TYPE.ERROR);
      return false;
    }
    return true;
  };

  // eslint-disable-next-line react/sort-comp
  handleSent() {
    this.props.handlePost({
      imagePreviewUrls: this.state.imagePreviewUrls,
      textData: this.state.textData,
      markAsResolved: this.state.markAsResolved,
    });
    this._node.innerText = '';
    this._node.focus();
    this.setState({ imagePreviewUrls: [], textData: '' });
  }

  markAsCorrect=() => {
    if (this.state.markAsResolved) {
      this.setState({ markAsCorrect: false, markAsResolved: false });
    } else {
      this.setState({ markAsCorrect: true });
    }
  }

  handleMouseHover(id) {
    this.setState({ isHovering: id });
  }

  handleMouseOut(e) {
    if (Array.from(e.relatedTarget.classList).some(item => ['individual-image--cross', 'icon-plus'].includes(item))) {
      return;
    }
    this.setState({ isHovering: 0 });
  }

  toggleHoverState(state) {
    return {
      isHovering: !state.isHovering,
    };
  }

  getFileType = (fileName) => {
    const fileLen = fileName.split('.');
    const fileExt = fileLen[fileLen.length - 1].toLowerCase();
    const acceptedExts = ['jpg', 'png', 'jpeg'];
    if (acceptedExts.includes(fileExt)) {
      return true;
    }
    return false;
  };

  handleFileUpload(e, type) {
    const {
      target: { files },
    } = e;
    const fileList = Array.from(files);
    const uploaders = fileList.map(file => ({ file, id: uniqueId() }));
    const uploadersCopy = uploaders.filter(file => this.filterCondition(file, type));
    if (this.state.imagePreviewUrls && this.state.imagePreviewUrls.length + uploadersCopy.length > 4) {
      addToast(translationText(this.props.noonText, 'warning.tooManyAttachments'), TOAST_TYPE.ERROR);
    } else {
      this.setState(prevState => ({
        imagePreviewUrls: [...prevState.imagePreviewUrls, ...uploadersCopy],
        ...([...prevState.imagePreviewUrls, ...uploadersCopy].length && { fileType: type }),
      }));
      if (uploadersCopy.length) {
        this.uploadFiles(uploadersCopy);
      }
    }
  }

  progressEventHandler = id => (progressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    this.props.uploadImageProgress(id, percentCompleted);
  };

  uploadFiles = (fileList) => {
    const attachmentList = fileList;
    const filesBatch = attachmentList.map((fileObj) => {
      const { file, id } = fileObj;
      const fd = new FormData();
      fd.append('destination', 'posts');
      const nameSplit = file.name.split('.');
      const ext = nameSplit[nameSplit.length - 1];
      nameSplit.splice(nameSplit.length - 1, 1);
      const originalName = nameSplit.join('.');
      fd.append('fileUpl', file, file.name);
      fd.append('file_name', `${originalName}##${id}.${ext}`);
      fd.append('id', id);
      return { fd, progressEvent: this.progressEventHandler(id) };
    });
    this.props.bulkFileUpload(filesBatch);
  };

  removeImage(id) {
    const removedIndex = this.state.imagePreviewUrls.findIndex(item => item.id === id);
    const updatedBulkFileList = this.props.bulkFilesUploaded.filter(fileData => fileData.name.split('##')[1].split('.')[0] !== id);
    this.props.bulkFileUploadSuccess(updatedBulkFileList);
    this.setState((prevState) => {
      const newImagePreviewUrl = prevState.imagePreviewUrls;
      newImagePreviewUrl.splice(removedIndex, 1);
      return {
        imagePreviewUrls: newImagePreviewUrl,
        ...(isEmpty(newImagePreviewUrl) && { fileType: '' }),
      };
    });
  }

  setTextDataInState = (data) => {
    this.setState({ textData: data });
  };

  scrollLeft() {
    this.imageRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  }

  scrollBottom() {
    this.fileRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }

  hideFocusOperation = () => {
    this.setState({ imagePreviewUrls: [] });
    this.props.hideFocus();
  };

  render() {
    const {
      noonText,
      imageUploadProgressStatus,
      isBulkFileUploading,
      emojis,
      user,
      isRtl,
      isResolved,
    } = this.props;
    const { imagePreviewUrls, fileType, textData } = this.state;

    const btnListType = [
      {
        icon: IconPicture,
        text: translationText(noonText, 'post.picture'),
        func: 'handleImageUpload',
        accept: '.jpg, .png',
        type: 'image',
      },
    ];

    const fileActions = fileType ? btnListType.filter(btn => btn.type === fileType) : btnListType;

    return (
      <Row align="center" justify="center" className="no-marign create-answer">
        <HtmlInput
          noonText={noonText}
          isRtl={isRtl}
          emojis={emojis}
          user={user}
          placeholder="post.writeAnswer"
          type="post"
          value={translationText(noonText, 'post.writeAnswer')}
          textData={this.setTextDataInState}
          inputRef={(input) => {
            this._node = input;
          }}
          node={this._node}
        />
        <Row align="start" justify="space-between" flex="1" className="full-width">
          <Row className="file-attachment__image-preview no-margin">
            {!isEmpty(imagePreviewUrls) && (
            <React.Fragment>
              {fileType === 'image' && (
              <React.Fragment>
                {imagePreviewUrls.map((item) => {
                      const { file, id } = item;
                      const imageFound = imageUploadProgressStatus && imageUploadProgressStatus.find(x => x.id === id);
                      return (
                        <Row align="center" justify="center" className={classNames('individual-image mlr-2', {})} key={id}>
                          <img
                            src={URL.createObjectURL(file)}
                            className={classNames('', {
                              'individual-image--opaque-more': imageFound && imageFound.percentCompleted < 100 && isBulkFileUploading,
                              'individual-image--opaque-less': this.state.isHovering === id,
                            })}
                            alt="comment"
                            width={isMobileOnly ? mobileImgWidth : normalImgWidth}
                            height={isMobileOnly ? mobileImgWidth : normalImgWidth}
                            onMouseEnter={() => this.handleMouseHover(id)}
                            onMouseLeave={e => this.handleMouseOut(e, 0)}
                          />
                          {((imageFound && imageFound.percentCompleted < 100) && isBulkFileUploading) && (
                          <ProgressSpinner
                            progress={imageFound && imageFound.percentCompleted ? imageFound.percentCompleted - 0.01 : 0}
                            text={`${imageFound && imageFound.percentCompleted ? imageFound.percentCompleted : 0}%`}
                            radius={isMobile ? 25 : 35}
                            thickness={7}
                            noPointer
                            noShadow
                            activeColor="#b6dd8a"
                            color="#b6dd8a"
                            baseColor="white"
                            fill="none"
                            />
                            )}
                          <IconWrong
                            className="individual-image--cross"
                            name="wrong"
                            width="18px"
                            height="18px"
                            onClick={() => this.removeImage(id)}
                            fill={COLORS.coolGrey[5]}
                            stroke={COLORS.brand.base}
                         />
                        </Row>
                      );
                    })}

                <label htmlFor="addFile" className="file-attachment__image-preview--add" ref={this.imageRef}>
                  <IconPlus rotate="90" />
                  <input
                    id="addFile"
                    className="btn-upload--attach"
                    type="file"
                    multiple="true"
                    onChange={e => this.handleFileUpload(e, 'image')}
                    accept=".jpg, .png"
                      />
                </label>
              </React.Fragment>
                )}
            </React.Fragment>
            )}
          </Row>

          {!isResolved && (this.state.textData || !!this.state.imagePreviewUrl) &&
          <Row align="start" gap="sm" className="plr-2 mtb-1" style={{ cursor: 'pointer' }} onClick={this.markAsCorrect}>
            <IconCorrectCircle
              fill={this.state.markAsResolved ? '#31C173' : '#fff'}
              stroke={this.state.markAsResolved ? '#fff' : COLORS.grey[3]}
              width="18px"
              height="18px"
              className="child"
                />
            <Column align="start">
              <span className="text-capitalize child">{translationText(noonText, 'post.markQuestionAnswered')}</span>
              {this.state.markAsResolved && (
              <span className="text-capitalize child errorMessage">{translationText(noonText, 'post.markQuestionAnsweredText')}</span>
                )}
            </Column>
          </Row>
              }
        </Row>
        {textData &&
        <Row className="file-attachment__button-attachment">
          <Button
            loading={!!isBulkFileUploading}
            className={`text-capitalize ${!textData && isEmpty(imagePreviewUrls) ? 'mlr-2' : 'mlr-2'}`}
            type="primary"
            depth="2"
            onClick={this.handleSent}
            disabled={isEmpty(this.state.imagePreviewUrls) && isEmpty(this.state.textData)}
              >
            {translationText(noonText, 'poll.publish')}
          </Button>
          <Row className="create-question-options--select" gap="sm">
            {fileActions.map((btn, idx) => (
              <div className="btn-upload">
                <label htmlFor={`uploadPic-${idx}`} role="button" className="noon-btn-fab-lg noon-btn-fab noon-btn noon-btn-secondary no-padding child" style={{ minWidth: 'auto' }}>
                  <input
                    id={`uploadPic-${idx}`}
                    className="btn-upload--attach"
                    type="file"
                    multiple="true"
                    onChange={e => this.handleFileUpload(e, btn.type)}
                    accept={btn.accept}
                    style={{
                            opacity: '0',
                            position: 'absolute',
                            pointerEvents: 'none',
                            width: '1px',
                            height: '1px',
                          }}
                        />
                  <btn.icon fill={COLORS.brand.base} />
                </label>
              </div>
              ))}
          </Row>
        </Row>}
        {this.state.markAsCorrect &&
          <ConfirmationModal
            modalType="info"
            successBtn={translationText(noonText, 'session.confirm')}
            closeBtn={translationText(noonText, 'session.cancel')}
            onClose={() => { this.setState({ markAsCorrect: false, markAsResolved: false }); }}
            onSuccess={() => { this.setState({ markAsCorrect: false, markAsResolved: true }); }}
            text={translationText(noonText, 'post.markQuestionAnswered')}
            subText={translationText(noonText, 'post.markQuestionAnsweredText')}
          />
        }
      </Row>
    );
  }
}

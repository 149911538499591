import { put, call, takeEvery, select, all } from 'redux-saga/effects';
import isEmpty from 'lodash-es/isEmpty';
import { usersApi } from '../restApi';
import { getObjectByProperty } from '../../helpers';
import {
  LIST_TEACHERS,
  TIMELINE,
  PROFILE,
  GET_TEACHER_DETAIL,
  TIMELINE_UPDATE,
  GET_TEACHER_REVIEWS,
  LIST_COUNTRY,
  GET_TEACHER_PROFILE,
  SERVER_TIME,
} from '../constants';

const getProfileAPI = () => usersApi.get('teacherProfile');
const getServerTimeAPI = () => usersApi.get('currentTime');
const getTeacherDetailAPI = id => usersApi.get(`teachers/${id}`);
const listTeacherAPI = () => usersApi.get('pinnedTeacher');
const timelineAPI = ({ feed_type, offset = 0 }) => usersApi.get(`timeline?feed_type=${feed_type}&start=${offset}`);
const timelineUpdateAPI = data => usersApi.put('timeline', data);
const updateProfileAPI = data => usersApi.put('teacherProfile', { ...data, locale: localStorage.language || 'ar' });
const getTeacherReviewsApi = payload => usersApi.get('myReviews', { start: payload.start, limit: payload.limit });

const getUser = state => state.toJS().user;
const getFolder = state => state.toJS().folder;
const getBiFrost = state => state.toJS().biFrost;

function* updateCountryOnLogin(user) {
  const { countries } = yield select(getFolder);
  if (countries.selectedCountry.id !== user.country_id) {
    const selectedCountry = getObjectByProperty(countries.countryList, 'id', user.country_id);
    localStorage.country = JSON.stringify({ countryList: countries.countryList, selectedCountry });
    yield put({
      type: LIST_COUNTRY.SUCCESS,
      payload: { countryList: countries.countryList, selectedCountry },
    });
  }
  return true;
}

function* getServerTime() {
  try {
    const nowTime = new Date().getTime();
    const response = yield call(getServerTimeAPI);
    if (response.data.success) {
      yield put({ type: SERVER_TIME.SUCCESS, payload: isEmpty(response.data.data) ? { current_time: new Date().getTime() } : response.data.data[0], delta: new Date().getTime() - nowTime });
    } else {
      yield put({ type: SERVER_TIME.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: SERVER_TIME.FAILURE, payload: 'server error' });
  }
}

function* listTeachers() {
  try {
    const response = yield call(listTeacherAPI);
    yield put({
      type: LIST_TEACHERS.SUCCESS,
      payload: isEmpty(response.data.data) ? [] : response.data.data,
    });
  } catch (err) {
    yield put({ type: LIST_TEACHERS.FAILURE, payload: err });
  }
}

function* getTeacherDetail({ payload: id }) {
  try {
    const response = yield call(getTeacherDetailAPI, id);
    if (response.data.success) {
      yield put({
        type: GET_TEACHER_DETAIL.SUCCESS,
        payload: isEmpty(response.data.data) ? {} : response.data.data[0],
      });
    } else {
      yield put({ type: GET_TEACHER_DETAIL.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: GET_TEACHER_DETAIL.FAILURE, payload: err });
  }
}

function* getTeacherReviews(data) {
  try {
    // call public teacher profile if not logged in
    const response = yield call(getTeacherReviewsApi, data.payload);
    if (response.data.success) {
      yield put({
        type: GET_TEACHER_REVIEWS.SUCCESS,
        payload: isEmpty(response.data)
          ? { meta: {}, list: [] }
          : { meta: response.data.meta, list: response.data.data, start: data.payload.start },
      });
    } else {
      yield put({ type: GET_TEACHER_REVIEWS.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: GET_TEACHER_REVIEWS.FAILURE, payload: err });
  }
}

function* timeline({ payload }) {
  try {
    const { refreshToken } = yield select(getBiFrost);
    if (refreshToken && refreshToken.isLoading) return;
    const response = yield call(timelineAPI, payload);
    if (response.data.success) {
      const { timeline } = yield select(getUser);
      if (!isEmpty(response.data.data) && payload.offset === 0 && timeline.count > 0) {
        const index = response.data.data.findIndex(
          o => o.notification_feed.UUID === timeline.list[0].notification_feed.UUID,
        );
        const topNotification = index > -1 ? response.data.data.slice(0, index) : [];
        yield put({
          type: TIMELINE_UPDATE.SUCCESS,
          payload: {
            list: [...topNotification, ...timeline.list],
            count: response.data.meta.paging.count,
            offset: response.data.meta.paging.next_offset,
          },
        });
      } else {
        yield put({
          type: TIMELINE.SUCCESS,
          payload: isEmpty(response.data.data)
            ? { list: [], count: 0, offset: 0 }
            : {
              list: response.data.data,
              count: response.data.meta.paging.count,
              offset: response.data.meta.paging.next_offset,
            },
        });
      }
    } else {
      yield put({
        type: TIMELINE.FAILURE,
        payload: response.data.message,
      });
    }
  } catch (err) {
    yield put({
      type: TIMELINE.FAILURE,
      payload: err,
    });
  }
}

function* timelineUpdate({ payload }) {
  try {
    const response = yield call(timelineUpdateAPI, payload);
    if (response.ok) {
      const { timeline } = yield select(getUser);
      const notifications = timeline;
      const index = timeline.list.findIndex(o => o.notification_feed.UUID === payload.notification_uid);
      if (index >= 0 && timeline.list && timeline.list.length) {
        notifications.list[index].notification_feed.notification_viewed = true;
        yield put({
          type: TIMELINE_UPDATE.SUCCESS,
          payload: { ...notifications },
        });
      }
    } else {
      yield put({
        type: TIMELINE_UPDATE.FAILURE,
        payload: response.data.message,
      });
    }
  } catch (err) {
    yield put({
      type: TIMELINE_UPDATE.FAILURE,
      payload: err,
    });
  }
}

function* updateProfile({ payload }) {
  try {
    const response = yield call(updateProfileAPI, payload);
    if (response.ok) {
      yield put({ type: GET_TEACHER_PROFILE.REQUEST });
      yield put({
        type: PROFILE.SUCCESS,
        payload: { success: response.status === 204 },
      });
    } else {
      yield put({
        type: PROFILE.FAILURE,
        payload: 'server error',
      });
    }
  } catch (err) {
    yield put({
      type: PROFILE.FAILURE,
      payload: 'server error',
    });
  }
}

export function* userSaga() {
  yield all([
    takeEvery(GET_TEACHER_REVIEWS.REQUEST, getTeacherReviews),
    takeEvery(LIST_TEACHERS.REQUEST, listTeachers),
    takeEvery(TIMELINE.REQUEST, timeline),
    takeEvery(TIMELINE_UPDATE.REQUEST, timelineUpdate),
    takeEvery(PROFILE.REQUEST, updateProfile),
    takeEvery(GET_TEACHER_DETAIL.REQUEST, getTeacherDetail),
    takeEvery(SERVER_TIME.REQUEST, getServerTime),
  ]);
}

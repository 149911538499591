import { put, call, takeEvery, all, select } from 'redux-saga/effects';
import isEmpty from 'lodash-es/isEmpty';
import differenceBy from 'lodash-es/differenceBy';
import get from 'lodash-es/get';
import isEqual from 'lodash-es/isEqual';
import keyBy from 'lodash-es/keyBy';
// eslint-disable-next-line import/no-cycle
import { questionApi, questionApiv2 } from '../restApi';
import {
  GET_QUESTION,
  QUESTION_LIST_WITH_CHOICES,
  CREATE_QUESTION,
  TEACHER_QUESTION_LIST,
  DELETE_TEACHER_QUESTION,
  LIBRARY_QUESTION_LIST,
  QUESTIONS_LIST,
} from '../constants';

const getQuestionAPI = (id) => questionApi.get(`teacher/questions/${id}`);
const getLibraryQuestionAPIV2 = (payload) => questionApiv2.get('teacher/libraryQuestionListWithFilter', payload);
const questionListWithChoicesAPI = ({ topicId, contributor = 'all', page = 1, limit = 10, text, sort = 'DESC' }) => questionApiv2.get(`teacher/questionListWithChoices/${topicId}`, { contributor, page, limit, text, sort });
const getTeacherQuestionAPIV2 = (payload) => questionApiv2.get('teacher/questionListWithFilter', payload);
const createQuestionAPI = (payload) => questionApi.post('teacher/questions', payload);
const updateQuestionAPI = (payload) => questionApi.put(`teacher/questions/${payload.id}`, payload);
const addChoiceAPI = (choice) => questionApi.post('choices', choice);
const updateChoiceAPI = ({ choice, id }) => questionApi.put(`teacher/choices/${id}`, choice);
const deleteTeacherQuestionAPI = (id) => questionApi.put(`teacher/questions/delete/${id}`);
const removeQuestionCurriculumAPI = (payload) => questionApiv2.post('teacher/remove_curriculum', payload);
const assignQuestionCurriculumAPI = (payload) => questionApiv2.post('teacher/assign_question', payload);
const deleteChoiceAPI = ({ id, questionId }) => questionApi.put(`teacher/questions/${questionId}/choices/delete/${id}`);

const getQuestionData = (state) => state.toJS().question;

function* getQuestion({ payload: id }) {
  try {
    const response = yield call(getQuestionAPI, id);
    if (response.data.success) {
      yield put({
        type: GET_QUESTION.SUCCESS,
        payload: isEmpty(response.data.data) ? {} : response.data.data[0],
      });
    } else {
      yield put({ type: GET_QUESTION.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: GET_QUESTION.FAILURE, payload: err });
  }
}

function* questionListWithChoices({ payload }) {
  try {
    const response = yield call(questionListWithChoicesAPI, payload);
    if (response.data.success) {
      yield put({
        type: QUESTION_LIST_WITH_CHOICES.SUCCESS,
        payload: {
          list: isEmpty(response.data.data) ? [] : response.data.data,
          nextPage: response.data.next_page > 0 ? response.data.next_page : -1,
          page: payload.page || 1,
        },
      });
    } else {
      yield put({ type: QUESTION_LIST_WITH_CHOICES.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: QUESTION_LIST_WITH_CHOICES.FAILURE, payload: err });
  }
}

function* updateQuestion({ question, choices, tags, country_id, chapter_id, topic_id }) {
  const { questionData } = yield select(getQuestionData);

  let response = { data: [{ question_id: question.id }] };
  if (question.difficulty !== questionData.difficulty || questionData.question !== question.question) {
    response = yield call(updateQuestionAPI, question);
  }

  for (let i = 0; i < choices.length; i++) {
    if (!isEqual(get(questionData.choices, [i]), choices[i])) {
      if (choices[i].id) {
        response = yield call(updateChoiceAPI, { id: choices[i].id, choice: choices[i] });
      } else {
        response = yield call(addChoiceAPI, { ...choices[i], question_id: question.id });
      }
    }
  }

  const removeChoices = differenceBy(questionData.choices, choices, 'id');
  for (let i = 0; i < removeChoices.length; i++) {
    // TODO - Allow user to delete choice if another one is marked correct
    // response = yield call(updateChoiceAPI, { id: removeChoices[i].id, choice: { ...removeChoices[i], is_correct: false } });
    response = yield call(deleteChoiceAPI, { id: removeChoices[i].id, questionId: question.id });
  }

  if (questionData.all_tag_details[0].tags !== tags) {
    const oldTagDetails = keyBy(get(questionData, ['all_tag_details', 0, 'tag_details']), 'type');
    yield call(removeQuestionCurriculumAPI, {
      question_id: question.id,
      country_id: oldTagDetails.country.id,
      chapter_id: oldTagDetails.chapter.id,
      topic_id: oldTagDetails.topic.id,
      index: 0,
    });
    response = yield call(assignQuestionCurriculumAPI, [{
      question_id: question.id,
      country_id,
      chapter_id,
      topic_id,
      tags,
    }]);
  }
  return response;
}

function* createQuestion({ payload }) {
  try {
    const { question, choices, tags, country_id, chapter_id, topic_id } = payload;
    let response;

    if (question.id) {
      response = yield updateQuestion({ question, choices, tags, country_id, chapter_id, topic_id });
    } else {
      response = yield call(createQuestionAPI, {
        question,
        choices,
        tags: [{ country_id, chapter_id, topic_id, tags }],
      });
    }
    if (response.ok) {
      const data = response.data ? response.data.data[0] : {};
      const questionId = question.id || (data && data.id ? data.id : question.id);
      const questionResponse = yield call(getQuestionAPI, questionId);

      const questionData = questionResponse.data ? questionResponse.data.data[0] : {};
      yield put({
        type: CREATE_QUESTION.SUCCESS,
        payload: { question_id: questionId, questionData, country_id, chapter_id, topic_id, tags },
      });
    } else {
      yield put({ type: CREATE_QUESTION.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: CREATE_QUESTION.FAILURE, payload: err });
  }
}

function* getTeacherQuestion({ payload }) {
  try {
    yield put({ type: TEACHER_QUESTION_LIST.LOADING });
    const response = yield call(getTeacherQuestionAPIV2, payload);
    if (response.data.success) {
      yield put({
        type: TEACHER_QUESTION_LIST.SUCCESS,
        payload: isEmpty(response.data.data) ? { list: [], page: 1 } : { list: response.data.data, page: payload.page, nextPage: response.data.next_page ? response.data.next_page : -1, tab: 'My Library' },
      });
    } else {
      yield put({ type: TEACHER_QUESTION_LIST.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: TEACHER_QUESTION_LIST.FAILURE, payload: err });
  }
}

function* getLibraryQuestion({ payload }) {
  try {
    yield put({ type: QUESTIONS_LIST.LOADING });
    const response = yield call(getLibraryQuestionAPIV2, payload);
    if (response.data.success) {
      yield put({
        type: QUESTIONS_LIST.SUCCESS,
        payload: isEmpty(response.data.data) ? { list: [], page: 1 } : { list: response.data.data, page: payload.page, nextPage: response.data.next_page ? response.data.next_page : -1, tab: 'Noon Library' },
      });
    } else {
      yield put({ type: QUESTIONS_LIST.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: QUESTIONS_LIST.FAILURE, payload: err });
  }
}

function* deleteTeacherQuestion({ payload }) {
  try {
    const response = yield call(deleteTeacherQuestionAPI, payload);
    if (response.data.success) {
      yield put({
        type: DELETE_TEACHER_QUESTION.SUCCESS,
        payload,
      });
    } else {
      yield put({ type: DELETE_TEACHER_QUESTION.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: DELETE_TEACHER_QUESTION.FAILURE, payload: err });
  }
}

export function* questionSaga() {
  yield all([
    takeEvery(GET_QUESTION.REQUEST, getQuestion),
    takeEvery(QUESTION_LIST_WITH_CHOICES.REQUEST, questionListWithChoices),
    takeEvery(TEACHER_QUESTION_LIST.REQUEST, getTeacherQuestion),
    takeEvery(LIBRARY_QUESTION_LIST.REQUEST, getLibraryQuestion),
    takeEvery(CREATE_QUESTION.REQUEST, createQuestion),
    takeEvery(DELETE_TEACHER_QUESTION.REQUEST, deleteTeacherQuestion),
  ]);
}

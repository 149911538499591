import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash-es/isEmpty';
import { IconArrowDown, IconLoader } from '@noon/quark';
import { Row } from '@noon/atom';
// import "./_select.scss";

const { arrayOf, oneOfType, string, number, object, func, shape, bool } = PropTypes;

export default class CurriculumSelect extends Component {
  static propTypes = {
    list: arrayOf(oneOfType([string, number, object])).isRequired,
    title: string,
    options: shape({
      closeOnEsc: bool,
      closeOnOutsideClick: bool,
      closeOnSelect: bool,
    }),
    onSelect: func,
    selectedItem: shape(),
    listItem: string,
    extraLevelItem: string,
    style: shape(),
    className: string,
    extraLevel: string,
    label: string,
    disabled: bool,
    error: bool,
    errorMessage: string,
    listItemComponent: oneOfType([object, func]),
    titleComponent: oneOfType([object, func]),
    outlined: bool,
    dark: bool,
    isLoading: bool,
  };

  static defaultProps = {
    title: 'Select Option',
    options: {
      closeOnEsc: true,
      closeOnOutsideClick: true,
      closeOnSelect: true,
    },
    onSelect: null,
    selectedItem: {},
    listItem: 'name',
    extraLevelItem: 'name',
    extraLevel: '',
    style: {},
    className: '',
    label: '',
    disabled: false,
    error: false,
    errorMessage: null,
    listItemComponent: null,
    titleComponent: null,
    outlined: false,
    dark: false,
    isLoading: false,
  };

  state = {
    listOpen: false,
    headerTitle: this.props.title,
    showExtraLevelFor: -1,
    selected: this.props.selectedItem,
  };

  componentWillReceiveProps({ selectedItem }) {
    if (selectedItem !== this.props.selectedItem) {
      this.setState({ selected: selectedItem });
    }
  }

  componentWillUnmount() {
    this.removeEventListener();
  }

  handleClick = (e) => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.handleClickOutside();
  };

  handleKey = (e) => {
    if (e.key === 'Escape') {
      this.handleClickOutside();
    }
  };

  addEventListener() {
    if (this.props.options && this.props.options.closeOnOutsideClick) {
      document.addEventListener('mousedown', this.handleClick);
    }
    if (this.props.options && this.props.options.closeOnEsc) {
      document.addEventListener('keyup', this.handleKey);
    }
  }

  removeEventListener() {
    if (this.props.options && this.props.options.closeOnOutsideClick) {
      document.removeEventListener('mousedown', this.handleClick);
    }
    if (this.props.options && this.props.options.closeOnEsc) {
      document.removeEventListener('keyup', this.handleKey);
    }
  }

  itemSelected = (item) => {
    const { onSelect, options } = this.props;

    if (onSelect) onSelect(item);
    this.setState({ selected: item });

    if (options && options.closeOnSelect) {
      this.handleClickOutside();
    }
  };

  handleClickOutside() {
    this.setState({
      listOpen: false,
    });
    this.removeEventListener();
  }

  toggleList() {
    if (!this.props.disabled) {
      if (this.state.listOpen) {
        this.removeEventListener();
      } else {
        this.addEventListener();
        if (this.state.selected && this.state.selected.id && this.props.extraLevel) {
          this.toggleExtraLevel(parseInt(this.state.selected.parent.split('.')[0], 10));
        }
      }
      this.setState((prevState) => ({
        listOpen: !prevState.listOpen,
      }));
    }
  }

  toggleExtraLevel(itemId) {
    if (itemId === this.state.showExtraLevelFor) {
      this.setState({ showExtraLevelFor: -1 });
    } else {
      this.setState({ showExtraLevelFor: itemId });
    }
  }

  render() {
    const { listOpen, headerTitle, selected, showExtraLevelFor } = this.state;
    const {
      list,
      listItem,
      style,
      className,
      label,
      disabled,
      error,
      extraLevel,
      errorMessage,
      extraLevelItem,
      listItemComponent: ListItemComponent,
      titleComponent: TitleComponent,
      outlined,
      dark,
      isLoading,
    } = this.props;

    return (
      <div
        className={classNames('noon-select', className, { active: listOpen, 'noon-select--rounded': outlined, disabled: !!disabled, dark: !!dark, error })}
        ref={(node) => {
          this.node = node;
        }}
        style={style}
      >
        <label className="select-header__label">{label}</label>
        <div
          className={classNames('select-header', {
            disabled: !!disabled,
          })}
          onClick={() => this.toggleList()}
        >
          <div className={classNames('select-header__title', { selected: !isEmpty(selected) })}>
            {!isEmpty(selected) ? (TitleComponent ? <TitleComponent {...selected} /> : !list.length ? (
              <span className="select-label">
                No
                {' '}
                {label}
                {' '}
                found
              </span>
            ) : selected[listItem]) : (<span className="select-label">{headerTitle}</span>)}
          </div>
          {isLoading ? <IconLoader className="select-header__icon" /> : <IconArrowDown className="select-header__icon" rotate={listOpen ? 180 : 0} />}
        </div>

        {listOpen && (
          <ul className="select-list">
            {list.map((item) => (
              <React.Fragment key={item.id}>
                {extraLevel === '' ? (
                  <li
                    className={classNames('select-list__item', {
                      selected: selected.id === item.id,
                    })}
                    onClick={() => this.itemSelected(item)}
                  >
                    {ListItemComponent ? <ListItemComponent {...item} selectedItem={selected} /> : <span>{item[listItem]}</span>}
                  </li>
                ) : (
                  <li key={item.id} className="select-list__item extra-level">
                    <Row
                      justify="space-between"
                      align="center"
                      className={classNames('extra-level__dropdown', {
                        open: showExtraLevelFor === item.id,
                      })}
                      onClick={() => this.toggleExtraLevel(item.id)}
                    >
                      {ListItemComponent ? (
                        <ListItemComponent {...item} key={item.id} />
                      ) : (
                        <span>{item[listItem]}</span>
                      )}
                      <IconArrowDown
                        className="select-header__icon"
                        rotate={showExtraLevelFor === item.id ? '180' : '0'}
                      />
                    </Row>
                    {showExtraLevelFor === item.id && (
                      <div className="extra-level__container">
                        <ul className="extra-level-list ptb-05">
                          {item[extraLevel].map((subItem) => (
                            <li
                              key={subItem.id}
                              className={classNames('select-list__item--subItem', {
                                selected: selected.id === subItem.id,
                              })}
                              onClick={() => this.itemSelected(subItem)}
                            >
                              {subItem[extraLevelItem]}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </li>
                )}
              </React.Fragment>
            ))}
          </ul>
        )}
        {error && errorMessage && <span>{errorMessage}</span>}
      </div>
    );
  }
}

import React from 'react';
import { Column, Button } from '@noon/atom';
import t from '../../../../helpers/translate';

export type IFlashcard = {
    id: number,
    name: string,
    image_uri: string,
    image_medium_uri: string,
    image_thumbnail_uri: string,
    image_large_uri: string,
}

type IFlashcardItemProp = {
    flashcard: IFlashcard,
    selectFlashcard: (flashcard: IFlashcard) => void
}

const FlashcardItem = ({ flashcard, selectFlashcard }: IFlashcardItemProp) => {
  const { id, name, image_medium_uri } = flashcard;
  return (
    <Column
      key={id}
      className="folder-flashcard__list-item"
    >
      <img
        alt={name}
        src={image_medium_uri}
        data-resource-id={id}
        data-resource-type="flashcard_image"
        // onClick={() => showFlashcardEnlarged(flashcard)}
      />
      <Button
        className="animate slideInUp"
        rounded
        type="primary"
        size="sm"
        onClick={() => selectFlashcard(flashcard)}
       >
        {t('classroom', 'addToCanvas')}
      </Button>
    </Column>
  );
};

export default FlashcardItem;

import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash-es/isEmpty';
import {
  LOCALES_SEO,
} from '../../constants';
import Meta from '../../meta';

const SeoMeta = () => {
  const location = useLocation();
  const localizedMainPaths = localStorage.language === 'ar' ? '/sa-ar' : '/sa-en';
  const pathname = location.pathname.replace(/\/$/, '');
  const pathnameWithoutSlash = pathname.replace(/^\//, '');
  const locale = localStorage.language || 'en';
  return (!isEmpty(Meta[pathname]) ? (
    <Helmet>
      <html lang={locale} />
      {!!Meta[pathname].title && <title>{Meta[pathname].title}</title>}
      {!!Meta[pathname].description && <meta name="description" content={Meta[pathname].description} />}
      {!!Meta[pathname].keywords && <meta name="keywords" content={Meta[pathname].keywords} />}
      {!!Meta[pathname].canonical && <meta name="canonical" href={`${process.env.TEACHER_URL}${Meta[pathname].canonical === '/' ? '' : Meta[pathname].canonical}`} />}
      {!!Meta[pathname].title && <meta property="og:title" content={Meta[pathname].title} />}
      {!!Meta[pathname].description && (
        <meta property="og:description" content={Meta[pathname].description} />
      )}
      <meta property="og:url" content={`${process.env.TEACHER_URL}${pathnameWithoutSlash}`} />
      <meta property="og:image" content={`${process.env.TEACHER_URL}assets/images/logo-${locale === 'en' ? 'en' : 'ar'}.png`} />
      <meta property="og:site_name" content={locale === 'en' ? 'Noon Academy' : 'نون أكاديمي'} />
      <meta property="og:type" content="website" />
      {LOCALES_SEO.map(locale => (
        <link rel="alternate" href={`${process.env.TEACHER_URL}${Meta[pathname].alternate ? pathnameWithoutSlash : locale.value}`} hrefLang={locale.key} key={locale.key} />
      ))}
    </Helmet>
  ) : (
    <Helmet>
      <html lang={locale} />
      {!!Meta[localizedMainPaths].title && <title>{Meta[localizedMainPaths].title}</title>}
      {!!Meta[localizedMainPaths].description && <meta name="description" content={Meta[localizedMainPaths].description} />}
      {!!Meta[localizedMainPaths].keywords && <meta name="keywords" content={Meta[localizedMainPaths].keywords} />}
      {/* {!!Meta[localizedMainPaths].canonical && <meta name="canonical" href={`${process.env.TEACHER_URL}`} />} */}
      {!!Meta[localizedMainPaths].title && <meta property="og:title" content={Meta[localizedMainPaths].title} />}
      {!!Meta[localizedMainPaths].description && (
        <meta property="og:description" content={Meta[localizedMainPaths].description} />
      )}
      <meta property="og:url" content={`${process.env.TEACHER_URL}${pathnameWithoutSlash}`} />
      <meta property="og:image" content={`${process.env.TEACHER_URL}assets/images/logo-${locale === 'en' ? 'en' : 'ar'}.png`} />
      <meta property="og:site_name" content={locale === 'en' ? 'Noon Academy' : 'نون أكاديمي'} />
      <meta property="og:type" content="website" />
      {LOCALES_SEO.map(locale => (
        <link rel="alternate" href={`${process.env.TEACHER_URL}/${locale.value}`} hrefLang={locale.key} key={locale.key} />
      ))}
    </Helmet>
  ));
};

export default SeoMeta;

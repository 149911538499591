import { putInfoAboutSpecificStudent } from "../../helpers/homeworkReport";
import { CLEAR_REPORT_PAGE_DATA, GET_NOT_SUBMITTED_USERS, GET_SUBMITTED_USERS, GET_USER_RESPONSE, SORT_STUDENTS, STUDENT_RESPONSE_VIEWED } from "../constants";

const initialState = {
  submittedUsers: {
    data: [],
    meta: {
      total: 0
    }
  },
  notSubmittedUsers: {
    data: [],
    meta: {
      total: 0
    }
  },
  studentsLoading: false,
  studentProfile: {
    loading: false,
    position: -1,
    questions: [],
    score: 0,
    state: ''
  },
  students_response_viewed: 0
};

export default function homeworkReport(state = initialState, action = {}) {
  switch(action.type) {
    case GET_SUBMITTED_USERS.LOADING: {
      return {
        ...state,
        studentsLoading: true
      }
    }

    case GET_NOT_SUBMITTED_USERS.LOADING: {
      return {
        ...state,
        studentsLoading: true
      }
    }

    case GET_SUBMITTED_USERS.SUCCESS: {
      return {
        ...state,
        studentsLoading: false,
        submittedUsers: {
          data: JSON.stringify(action.payload.data) === JSON.stringify(state.submittedUsers.data) ? state.submittedUsers.data :  state.submittedUsers.data.concat(action.payload.data),
          meta: action.payload.meta
        }
      }
    }

    case GET_NOT_SUBMITTED_USERS.SUCCESS: {
      return {
        ...state,
        studentsLoading: false,
        notSubmittedUsers: {
          data: JSON.stringify(action.payload.data) === JSON.stringify(state.notSubmittedUsers.data) ? state.notSubmittedUsers.data : state.notSubmittedUsers.data.concat(action.payload.data),
          meta: action.payload.meta
        }
      }
    }

    case GET_SUBMITTED_USERS.FAILURE: {
      return {
        ...state,
        studentsLoading: false
      }
    }

    case GET_NOT_SUBMITTED_USERS.LOADING: {
      return {
        ...state,
        studentsLoading: false
      }
    }

    case GET_NOT_SUBMITTED_USERS.FAILURE: {
      return {
        ...state,
        studentsLoading: false
      }
    }

    case SORT_STUDENTS: {
      return {
        ...state,
        submittedUsers: {
          ...state.submittedUsers,
          data: state.submittedUsers.data.reverse()
        }
      }
    }

    case GET_USER_RESPONSE.LOADING: {
      return {
        ...state,
        studentProfile: {
          ...state.studentProfile,
          loading: true
        }
      }
    }

    case GET_USER_RESPONSE.SUCCESS: {
      return {
        ...state,
        studentProfile: {
          loading: false,
          ...putInfoAboutSpecificStudent(action.payload, action.user_id, state.submittedUsers.data)
        } 
      }
    }

    case GET_USER_RESPONSE.FAILURE: {
      return {
        ...state,
        studentProfile: initialState.studentProfile
      }
    }

    case CLEAR_REPORT_PAGE_DATA: {
      return initialState;
    }

    case STUDENT_RESPONSE_VIEWED: {
      return {
        ...state,
        students_response_viewed: state.students_response_viewed + 1
      }
    }

    default: {
      return state;
    }
  }
}
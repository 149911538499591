import React from 'react';
import { Button } from '@noon/atom';
import { IconAdd } from '@noon/quark';
import { DateTime } from 'luxon';
import { translationText } from '../../../helpers';

function NoActivityOnDayCard({ noonText, date, toggleCreateActivity, isFilterApplied }) {
  const today = DateTime.local();
  return (
    <div className="activity-card--grid-view light_gray_bg pdb_none no-activity-card">
      <div className="content-small">
        {isFilterApplied
          ? <p>{translationText(noonText, 'planner.calendarFilterEmptyCard')}</p>
          : today.hasSame(date, 'day') || date.ts > today.ts
            ? <p>{translationText(noonText, 'planner.calendarFutureEmptyCard')}</p>
            : <p>{translationText(noonText, 'planner.calendarEmptyCard')}</p>}
      </div>
      {!isFilterApplied && (today.hasSame(date, 'day') || date.ts > today.ts)
      && (
      <div className="button_block">
        <Button type="secondary" size="md" icon={IconAdd} onClick={() => toggleCreateActivity({ type: 'session', source: 'planner-grid-no-activity-card', sessionData: { start_time: date.set({ hour: today.get('hour'), minute: today.get('minute') }).toMillis() } })}>
          {translationText(noonText, 'button.addActivity')}
        </Button>
      </div>
      )}
    </div>
  );
}

export default NoActivityOnDayCard;

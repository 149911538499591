import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Column, Button, Badge } from '@noon/atom';
import { IconClock, IconCompetition, IconSession } from '@noon/quark';
import { DateTime } from 'luxon';
import { COLORS } from '../../../constants';
import { translationText } from '../../../helpers';

function ActivityReservedCard({ activity, toggleCreateActivity, noonText }) {
  const slot = useMemo(() => `${DateTime.fromMillis(activity.start_time).toFormat('hh:mm a')} - ${DateTime.fromMillis(activity.end_time || 0).toFormat('hh:mm a')}`, [activity]);
  return (
    <Row align="center" justify="space-between" className="general-planner__reserve-card">
      <Column>
        <Row align="center" justify="start" gap="sm">
          <IconClock fill={COLORS.coolGrey[1]} width="12px" height="12px" className="child" />
          <span className="child">{slot}</span>
          <Badge type="primary" value={translationText(noonText, 'activity.reserved')} size="medium" className="reserved-badge" />
        </Row>
        <h3 className="group-name">{activity.name}</h3>
      </Column>
      <Row gap>
        <Button type="secondary" value={translationText(noonText, 'button.addSession')} icon={IconSession} className="text-capitalize" onClick={() => toggleCreateActivity({ type: 'session', source: 'planner-list-reserve-card', hideCompetition: true, sessionData: activity })} />
        <Button type="secondary" value={translationText(noonText, 'button.addCompetition')} icon={IconCompetition} className="text-capitalize" onClick={() => toggleCreateActivity({ type: 'competition', source: 'planner-list-reserve-card', hideSession: true, sessionData: activity })} />
      </Row>
    </Row>
  );
}

ActivityReservedCard.propTypes = {
  activity: PropTypes.shape({}).isRequired,
  toggleCreateActivity: PropTypes.func.isRequired,
};
export default ActivityReservedCard;

import React from 'react';
import { Row } from '@noon/atom';
import { IconLogo } from '@noon/quark';

export default function CountriesSection(prop) {
  const { countries } = prop;
  return (
    <div className="section-outer-container countries-outer">
      <div className="section landing-countries">
        <Row className="text landing-countries__heading">
          <h1 className="title-heading">Worldwide leaders in e-learning</h1>
          <p className="content-subheading">
            We are continuously expanding, and currently providing our services in
            {countries.length || 7}
            countries. To many more to come.
          </p>
          <div className="icon-wrapper"><IconLogo width="40rem" height="40rem" /></div>
        </Row>
        <Row className="landing-countries__content">
          {countries.reverse().map((country) => (
            <div key={country.id} className="country-item">
              <img src={country.flag} alt={country.full_name} />
              <p>{country.full_name}</p>
            </div>
          ))}
        </Row>
      </div>
    </div>
  );
}

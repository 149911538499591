import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useHistory, useParams } from 'react-router-dom';
import { IconMenu } from '@noon/quark';
import { Row, Column } from '@noon/atom';
import classNames from 'classnames';
import Popover from 'react-popover';
import { TOGGLE_NOTIFICATION_BAR, TOGGLE_SIDEBAR } from '../../redux/constants';
import NotificationBar from './NotificationBar';
import { COLORS } from '../../constants';
import t from '../../helpers/translate';

function PageHeader({ title, children, hideToggle, hideNotificationBar }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    groups: { inActiveGroups },
  } = useSelector((state) => state.toJS().groupsV2);
  const {
    notificationBar: { show: showNotificationBar, text },
  } = useSelector((state) => state.toJS().notification);
  const [showInActiveGroups, setShowInActiveGroups] = useState(false);

  const { groupId } = useParams();
  const handleToggleSidebar = () => {
    dispatch({
      type: TOGGLE_SIDEBAR,
      payload: false,
    });
  };

  const openGroup = (group) => {
    history.push(`/${localStorage.updatedLocale}/groups/${group.id}?archive=true`);
    setShowInActiveGroups(false);
  };

  const handleOnCloseNotificationBar = () => {
    dispatch({
      type: TOGGLE_NOTIFICATION_BAR,
      payload: {
        show: !showNotificationBar,
      },
    });
  };

  useEffect(() => {
    if (inActiveGroups.length > 0) {
      dispatch({
        type: TOGGLE_NOTIFICATION_BAR,
        payload: {
          show: !hideNotificationBar,
        },
      });
    } else {
      dispatch({
        type: TOGGLE_NOTIFICATION_BAR,
        payload: {
          show: false,
        },
      });
    }
  }, [inActiveGroups, hideNotificationBar]);

  const popoverPropsForSort = {
    isOpen: showInActiveGroups,
    place: 'below',
    onOuterAction: () => setShowInActiveGroups(false),
    style: { marginTop: '-0.5rem' },
    body: (
      <Column className="filter-item-list">
        {inActiveGroups.map((group) => (
          <Row
            align="center"
            justify="space-between"
            className="ptb-1 plr-1 select-item"
            onClick={() => openGroup(group)}
          >
            {group.title}
          </Row>
        ))}
      </Column>
    ),
  };

  return (
    <Column
      align="center"
      justify="start"
      className={classNames('page-header-container', { 'show-notification-bar': showNotificationBar })}
      nowrap
    >
      {showNotificationBar && (
        <NotificationBar
          onClose={handleOnCloseNotificationBar}
          text={t('notification', 'inActiveGroups').replace('{inActiveGroups}', inActiveGroups.length)}
          // text={text}
        >
          <Popover {...popoverPropsForSort} className="questionList-popover feed-filter-popover">
            <Row
              justify="start"
              align="center"
              style={{ position: 'relative', color: COLORS.brand.base, cursor: 'pointer' }}
              onClick={() => setShowInActiveGroups(true)}
            >
              <span>View Groups</span>
            </Row>
          </Popover>
        </NotificationBar>
      )}
      <div className="container page-header">
        <Row nowrap justify="start" align="center" gap="sm" className="page-header__title-section">
          <div className="sidebar-menu-toggle-button">
            {!hideToggle && (
              <IconMenu onClick={handleToggleSidebar} className="sidebar-toggle" height="22px" width="22px" />
            )}
          </div>
          {title && groupId ? (
            <Link to={`/${localStorage.updatedLocale}/groups/${groupId}`} className="page-header__title-link">
              <h1 className="page-header__title">{title}</h1>
            </Link>
          ) : title && !groupId ? (
            <h1 className="page-header__title">{title}</h1>
          ) : null}
        </Row>
        <Row flex="1" align="center" justify="end" gap="lg">
          {children}
        </Row>
      </div>
    </Column>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.shape({}).isRequired,
};
export default PageHeader;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectImageContent } from '../../../../redux/actions/classroom';
import { GENERATE_PDF_SLIDE } from '../../../../redux/constants';
import PdfUpload from '../../../Class/PdfUpload';
import CreatePdfSlideModal from './createPdfSlideModal';

type IPdfUrl = {
  id: number,
  src: string
}

const PdfContainer = () => {

  const dispatch = useDispatch();
  const sessionDetails = useSelector((state) => state.toJS().myClassroom.sessionDetails);
  const isGeneratingPdfSlide = useSelector((state) => state.toJS().whiteboard.pdfSlide.isGeneratingPdfSlide);

  const addResourceToCanvas = (url: string, isDataUrl: Boolean) => {
    const payload = {
      url,
      isDataUrl,
      resourceType: 'pdf',
    };
    dispatch(selectImageContent(payload));
  };

  const handleAddPdfSlide = (pdfList: IPdfUrl[]) => {
    dispatch({ type: GENERATE_PDF_SLIDE.REQUEST, payload: { images: pdfList } });
  };

  return (
    <>
      {isGeneratingPdfSlide && <CreatePdfSlideModal title="pdfToSlideTitle" />}
      <PdfUpload sessionData={sessionDetails} onAddPdfSlide={handleAddPdfSlide} addResourceToCanvas={addResourceToCanvas} />
    </>
  );
};

export default PdfContainer;

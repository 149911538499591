import { NETWORK_PROVIDER } from '../../../providers/Event/provider';

import handleMsg from './handleMsg';
import handleAddSlide from './handleAddSlide';
import handlePubnubPresence from './handlePubnubPresence';
import handleInteractiveQuestion from './handleInteractiveQuestion';
import handleBreakoutQuestion from './handleBreakoutQuestion';
import handleDisableStudentChat from './handleDisableStudentChat';
import handleStudentFeedback from './handleStudentFeedback';
import { addToast, TOAST_TYPE } from '../../Toast';
import t from '../../../helpers/translate';

import {
  NEW_TEAM_FORMED,
  BREAKOUT_TEAM_FORMED,
  RAISE_HAND_REQUEST,
  INITIALIZE_RAISE_HAND,
  UPDATE_VOTE_CHOICE,
  DELETE_SLIDE,
  BLOCK_CHAT,
  CLASSROOM_NEED_TO_DISCONNECT,
  SIDEBAR_TAB_COUNT,
  RAISE_HAND_ACTION,
  EXIT_CLASSROOM,
  QNA_MESSAGE,
  QNA_BREAKOUT_START_EVENT_SERVER,
} from '../../../redux/constants';
import { takeScreenShot } from '../../../redux/actions/whiteboard';

const handleClientData = (dispatch, e) => {
  const { data } = e;
  const payload = data ? JSON.parse(data) : {};
  switch (payload.event) {
    case 'understood':
      handleStudentFeedback(dispatch, { message: payload });
      break;
    default:
  }
};

const eventListener = {
  [NETWORK_PROVIDER.PUBNUB]: {
    chat: handleMsg,
    disableStudentChat: handleDisableStudentChat,
    addCube: handleAddSlide,
    add_cube_server: handleAddSlide,
    presence: handlePubnubPresence,
    ask_students: handleInteractiveQuestion,
    ask_students_in_breakout: handleBreakoutQuestion,
    deleteCube: (dispatch, e) => dispatch({ type: DELETE_SLIDE.SUCCESS, payload: e.message }),
    team_formation_complete: (dispatch) => dispatch({ type: BREAKOUT_TEAM_FORMED }),
    new_team_formed: (dispatch) => dispatch({ type: NEW_TEAM_FORMED }),
    getVoteChoice: (dispatch, e) => dispatch({ type: UPDATE_VOTE_CHOICE, payload: e.message }),
    result_screen_teacher: (dispatch, e) => dispatch({ type: UPDATE_VOTE_CHOICE, payload: e.message }),
    audio_mode_event: (dispatch, e) => dispatch({ type: INITIALIZE_RAISE_HAND, payload: e.message }),
    raise_hand_server: (dispatch, e) => dispatch({ type: RAISE_HAND_REQUEST, payload: e.message }),
    student_audio_event: (dispatch, e) => dispatch({ type: RAISE_HAND_ACTION.SUCCESS, payload: { type: e.message.type, payload: { user_id: e.message.user_id } } }),
    blockChat: (dispatch, e) => {
      if (!e?.message?.chat_time) {
        addToast(t('classroom', 'userIsBlocked'), TOAST_TYPE.INFO);
      }
      dispatch({ type: BLOCK_CHAT, payload: e.message });
    },
    need_to_disconnect: (dispatch) => dispatch({ type: CLASSROOM_NEED_TO_DISCONNECT }),
    exitClass: (dispatch) => dispatch({ type: EXIT_CLASSROOM }),
    // start_breakout
    // exit_breakout
    team_qna: (dispatch, e) => dispatch({ type: QNA_MESSAGE.BEFORE, payload: e.message }),
    start_teamqna_breakout: (dispatch, e) => dispatch({ type: QNA_BREAKOUT_START_EVENT_SERVER, payload: e.message }),
    nudge: (dispatch, e) => {
      switch (e.message.type) {
        case 'question':
          dispatch(takeScreenShot());
          break;

        default:
          break;
      }
    },
  },
  [NETWORK_PROVIDER.AGORA]: {
    // canvasSketch: handleCanvasSketch,
    client_data: handleClientData,
    kickout: (dispatch) => dispatch({ type: CLASSROOM_NEED_TO_DISCONNECT }),
  },
};

export default eventListener;

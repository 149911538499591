import { browserName, browserVersion, osName } from 'react-device-detect';

import { IQuestion } from '../../components/Classroom/Content/Questions/questionContainer';
import {
  ADD_CHAT, CHAT_HISTORY, CLASSROOM_LEADERBOARD, CLEAR_CLASSROOM, CONTENT_FLASHCARD,
  CONTENT_QUESTION, CONTENT_SET_CHAPTER_TOPIC_ID, CONTENT_SET_TAG_ID, GENERATE_PDF_SLIDE,
  GET_SLIDES, INIT_CLASSROOM, QNA_LOAD_DATA, RESET_LAST_SESSION_DATA, SELECTED_TAB,
  SET_DISABLE_WEBRTC, SET_SELECTED_IMAGE_CONTENT, SET_SELECTED_QUESTION_CONTENT, START_CLASSROOM,
  START_CLASSROOM_HEARTBEAT, START_SESSION_RECORDING, STOP_CLASSROOM_HEARTBEAT, UPDATE_CHAT_STATUS,
} from '../constants';

type IAddImageToCanvas = {
  url: string,
  resourceType? : string,
  resourceId?: string | number,
  isDataUrl?: Boolean
};

export const joinClassroom = (session_id: string) => ({
  type: INIT_CLASSROOM.REQUEST,
  payload: { session_id },
});

export const loadTeamQna = () => ({
  type: QNA_LOAD_DATA.BEFORE,
});

export const startClassroom = (session_id: string) => ({
  type: START_CLASSROOM.REQUEST,
  payload: { id: session_id, teacher_device: `${browserName}:${osName}:${browserVersion}` },
});

export const startHeartbeat = (session_id: string, heartbeat_duration: number) => ({
  type: START_CLASSROOM_HEARTBEAT,
  payload: { session_id, heartbeat_duration },
});

export const stopHeartbeat = () => ({
  type: STOP_CLASSROOM_HEARTBEAT,
});

export const getSlides = (session_id: string) => ({
  type: GET_SLIDES.REQUEST,
  payload: { session_id },
});

export const getChatHistory = (session_id: string) => ({
  type: CHAT_HISTORY.REQUEST,
  payload: { session_id },
});

export const clearClassroom = () => ({
  type: CLEAR_CLASSROOM,
});

export const toggleChatStatus = (payload?: Boolean) => ({
  type: UPDATE_CHAT_STATUS,
  payload,
});

export const addLocalChat = (payload) => ({
  type: ADD_CHAT,
  payload: { ...payload, isLocal: true },
});

export const selectTab = (payload: string) => ({
  type: SELECTED_TAB,
  payload,
});

export const getQuestionContent = (payload) => ({
  type: CONTENT_QUESTION.REQUEST,
  payload,
});

export const getFlashcardContent = (payload) => ({
  type: CONTENT_FLASHCARD.REQUEST,
  payload,
});

export const setChapterTopicId = (payload: { chapterId: string, topicId: string }) => ({
  type: CONTENT_SET_CHAPTER_TOPIC_ID,
  payload,
});

export const setTagId = (tagId: string) => ({
  type: CONTENT_SET_TAG_ID,
  payload: tagId,
});

export const resetLastSessionData = () => ({
  type: RESET_LAST_SESSION_DATA,
});

export const selectImageContent = (payload: IAddImageToCanvas) => ({
  type: SET_SELECTED_IMAGE_CONTENT,
  payload,
});

export const selectQuestionContent = (payload: IQuestion) => ({
  type: SET_SELECTED_QUESTION_CONTENT,
  payload,
});

export const getLeaderboard = (payload) => ({
  type: CLASSROOM_LEADERBOARD.REQUEST,
  payload,
});

export const setDisableWebrtc = (payload: Boolean) => ({
  type: SET_DISABLE_WEBRTC,
  payload,
});

export const startSessionRecording = (payload) => ({
  type: START_SESSION_RECORDING.REQUEST,
  payload,
});

export const addSnapshotsToCanvas = (payload) => ({
  type: GENERATE_PDF_SLIDE.REQUEST,
  payload,
});

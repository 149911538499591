import { orderBy, sortBy, unionBy } from 'lodash-es';
import {
  DELETE_SESSION,
  GENERAL_PLANNER_GET_ACTIVITIES_COUNTS,
  GENERAL_PLANNER_GET_ACTIVITIES_DAYS,
  GENERAL_PLANNER_GET_ACTIVITIES_GRID,
  GENERAL_PLANNER_GET_ACTIVITIES_LIST,
} from '../constants';

const initialState = {
  list: [],
  grid: [],
  meta: {},
  count: {},
  days: {},
  loading: false,
  sort: 'asc',
  error: {},
};

export default function generalPlanner(state = initialState, action = {}) {
  switch (action.type) {
    case GENERAL_PLANNER_GET_ACTIVITIES_LIST.REQUEST:
      return {
        ...state,
        loading: true,
        sort: action.payload.filters.sort,
        list: action.payload.reload && !action.payload.update ? [] : state.list,
      };
    case DELETE_SESSION.REQUEST:
      return {
        ...state,
        list: state.list.filter((item) => item.id !== action.payload.id),
        grid: state.grid.filter((item) => item.id !== action.payload.id),
      };
    case GENERAL_PLANNER_GET_ACTIVITIES_LIST.SUCCESS:
      return {
        ...state,
        list: action.payload.update ? orderBy(unionBy(action.payload.data.data, state.list, 'id'), 'start_time', state.sort) : action.payload.reload ? action.payload.data.data : [...state.list, ...action.payload.data.data],
        meta: action.payload.update ? state.meta : action.payload.data.meta,
        loading: false,
      };
    case GENERAL_PLANNER_GET_ACTIVITIES_LIST.FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GENERAL_PLANNER_GET_ACTIVITIES_GRID.REQUEST:
      return {
        ...state,
        loading: !action.payload.update,
      };
    case GENERAL_PLANNER_GET_ACTIVITIES_GRID.SUCCESS:
      return {
        ...state,
        grid: action.payload.reload ? action.payload.data.data : [...state.grid, ...action.payload.data.data],
        meta: action.payload.data.meta,
        loading: false,
      };
    case GENERAL_PLANNER_GET_ACTIVITIES_GRID.FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GENERAL_PLANNER_GET_ACTIVITIES_COUNTS.SUCCESS:
      return {
        ...state,
        count: action.payload.reload ? action.payload.data : { ...state.count, ...action.payload.data },
      };
    case GENERAL_PLANNER_GET_ACTIVITIES_COUNTS.FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GENERAL_PLANNER_GET_ACTIVITIES_DAYS.SUCCESS:
      return {
        ...state,
        days: action.payload.reload ? action.payload.data : [...state.days, ...action.payload.data],
      };
    case GENERAL_PLANNER_GET_ACTIVITIES_DAYS.FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Column, Row, Select } from '@noon/atom';
import { IconClose, IconExclamationCircle, IconPostO } from '@noon/quark';
import { translationType } from '../../types';
import { COLORS, CURRICULUM_TEMPLATE } from '../../constants';
import { translationText } from '../../helpers';

const SelectCurriculumModal = ({ noonText, similarGroups, onClose, selectedCurriculumTemplate, handleOnSelectCurriculum }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [newSelectedCurriculumTempalte, setNewSelectedCurriculumTemplate] = useState(selectedCurriculumTemplate);
  const handleSaveChange = () => {
    if (newSelectedCurriculumTempalte.id === CURRICULUM_TEMPLATE.previousGroup.id) {
      if (!similarGroups || !similarGroups.list || !similarGroups.list.length) {
        onClose();
        return false;
      }
    }
    return setShowConfirmationModal(true);
  };

  const handleUpdateChanges = () => {
    handleOnSelectCurriculum(newSelectedCurriculumTempalte);
    onClose();
  };

  const handleDontUpdateChanges = () => {
    onClose();
  };

  const handleOnSelect = (value) => {
    setNewSelectedCurriculumTemplate(value);
  };

  const handleOnSelectPreviousGroup = (value) => {
    setNewSelectedCurriculumTemplate({
      ...newSelectedCurriculumTempalte,
      group_id: value.id,
      previousGroupTemplate: value.name,
    });
  };
  return (
    <React.Fragment>
      {!showConfirmationModal &&
      <Modal onClose={onClose} size="md" className="curriculum-page__modal-wrapper">
        <Column nowrap align="start">
          <header className="modal-header">
            <Row align="center">
              <IconPostO height="30px" width="30px" className="mr-1" />
              <h3>{translationText(noonText, 'groupCurriculum.curriculumOptions')}</h3>
            </Row>
          </header>
          <div className="modal-body">
            <Column align="start" flex="1" className="mt-5">
              <Select
                onSelect={handleOnSelect}
                list={[
                  {
                    name: translationText(noonText, 'groupCurriculum.noonSuggested'),
                    id: 1,
                  }, {
                    name: translationText(noonText, 'groupCurriculum.customTemplate'),
                    id: 2,
                  }, {
                    name: translationText(noonText, 'groupCurriculum.prevGroups'),
                    id: 3,
                  },
                ]}
                title={translationText(noonText, 'placeholder.selectCurriculum')}
                label={translationText(noonText, 'label.selectCurriculum')}
            />
              {
                newSelectedCurriculumTempalte.id !== CURRICULUM_TEMPLATE.previousGroup.id &&
                <p style={{ color: `${COLORS.text.light}` }}>
                    {translationText(noonText, 'groupCurriculum.warnChange')}
                </p>
            }
            </Column>
            {
              (newSelectedCurriculumTempalte.id === CURRICULUM_TEMPLATE.previousGroup.id) &&
              (similarGroups && similarGroups.list && similarGroups.list.length ?
                <Column align="start" flex="1" className="mt-5">
                  <Select
                    onSelect={handleOnSelectPreviousGroup}
                    list={similarGroups.list}
                    listItem="title"
                    title={translationText(noonText, 'groupCurriculum.selectGroup')}
                    label={translationText(noonText, 'groupCurriculum.prevGroups')}
                  />
                </Column>
              : translationText(noonText, 'groupCurriculum.noPrevSimilarGroups'))
            }
            <footer className="modal-footer mt-4 mb-2">
              <Button size="lg" depth="2" type="primary" onClick={handleSaveChange}>
                {translationText(noonText, 'groupCurriculum.saveChanges')}
              </Button>
            </footer>
          </div>

        </Column>
      </Modal>
    }
      {showConfirmationModal &&
      <Modal className="generic-modal-container curriculum-page__confirm-modal-container">
        <Column nowrap className="generic-modal">
          <Column align="center" className="generic-modal--body">
            <IconExclamationCircle className="header-icon" flipY fill="#f0bc00" stroke="#f0bc00" />
            <div className="generic-modal--body--wrapper">
              <Column className="generic-modal--body--text" align="center">
                <div className="text-1">{translationText(noonText, 'groupCurriculum.changeTitle')}</div>
                <div className="text-2">{translationText(noonText, 'groupCurriculum.confirmChange')}</div>
              </Column>
              <Row nowrap align="center" justify="center" gap="lg" className="generic-modal--body--btn">
                <Button
                  type="secondary"
                  size="large"
                  className="btn-modal"
                  onClick={handleUpdateChanges}
                >
                  {translationText(noonText, 'groupCurriculum.changeYes')}
                </Button>
                <Button
                  type="primary"
                  size="large"
                  onClick={handleDontUpdateChanges}
                  className="btn-modal">
                  {translationText(noonText, 'groupCurriculum.changeNo')}
                </Button>
              </Row>
            </div>
          </Column>
          <IconClose className="modal-close-btn" onClick={onClose} />
        </Column>
      </Modal>
      }
    </React.Fragment>
  );
};

SelectCurriculumModal.propTypes = {
  noonText: translationType.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedCurriculumTemplate: PropTypes.shape().isRequired,
  handleOnSelectCurriculum: PropTypes.func.isRequired,
};
SelectCurriculumModal.defaultProps = {};

export default SelectCurriculumModal;

import React, { useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Row, Column, Rating, LinearPercentageIndicator, Button, MoreActions, Avatar } from '@noon/atom';
import { IconDelete, IconPinPost, IconPublish, IconUnpinPost } from '@noon/quark';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import classNames from 'classnames';
import { translationText } from 'helpers';
import { GET_TEACHER_REVIEWS } from 'redux/constants';
import ShareButton from '../../ShareButton';

const ratingArray = [5, 4, 3, 2, 1];

function GroupReviewWidget(prop) {
  const { group, user } = prop;

  const dispatch = useDispatch();
  const teacherReviews = useSelector((state) => state.toJS().user.teacherReviews);
  const hasMembers = useMemo(() => get(group, 'membersInfo.total_members') > 0, [group]);
  const noonText = useSelector((state) => state.toJS().translation.noonText);

  useEffect(() => {
    dispatch({ type: GET_TEACHER_REVIEWS.REQUEST, payload: { start: 0, limit: 10 } });
  }, []);

  const handleLoadMoreReviews = () => {
    dispatch({
      type: GET_TEACHER_REVIEWS.REQUEST,
      payload: { start: teacherReviews.meta.paging.next_offset, limit: 10 },
    });
  };

  const getMax = useCallback(() => {
    const { session_ratings } = user;
    let max = 0;
    ratingArray.forEach((value) => {
      if (session_ratings[value]) {
        max += session_ratings[value];
      }
    });
    return max;
  }, []);

  const max = useMemo(() => (!isEmpty(user.session_ratings) && user.session_ratings.count > 0 ? getMax() : 0), [user]);

  const takeAction = () => true;

  return (
    <Card className="review-widget">
      <Row align="center" className="card-header" gap="sm">
        <h4 className="card-header__title">
          {translationText(noonText, 'groups.reviewTitle')}
          {!!get(teacherReviews, 'list.length') && (
            <React.Fragment>
              <span className="dot" />
              {get(teacherReviews, 'list.length')}
            </React.Fragment>
          )}
        </h4>
      </Row>
      <Column className="card-body" gap>
        {!isEmpty(user.session_ratings) ? (
          <React.Fragment>
            {user.rating_count > 0 && (
              <Row align="center" className="rating-card" gap>
                <Column className="rating-card__values" nowrap align="center" gap="sm">
                  <Rating width="15px" value={user.rating_count ? user.rating_total / user.rating_count : 0} />
                  <div className="rating-count">
                    {user.rating_count ? Number((user.rating_total / user.rating_count).toFixed(1)) : 0}
                  </div>
                </Column>
                {!isEmpty(user.session_ratings) && (
                <Column className="rating-card__percentage">
                  {ratingArray.map((rating, index) => (
                    <Row key={index} nowrap>
                      <span>{rating}</span>
                      <LinearPercentageIndicator
                        total={max}
                        current={
                            user.session_ratings && user.session_ratings[rating]
                              ? user.session_ratings[rating]
                              : 0
                          }
                        />
                    </Row>
                  ))}
                </Column>
                )}
              </Row>
            )}
            {teacherReviews.list.map((teacher, index) => (
              <Card
                key={index}
                className={
                classNames('review-card', {
                  pending: teacher.review.state === 'pending',
                  pinned: teacher.review.pinned === 1,
                })
              }>
                <Row align="start" gap="sm">
                  <Avatar
                    url={teacher.user.profile_pic}
                    gender={teacher.user.gender}
                    noShadow
                    rounded
                    size="36px"
                    />
                  <Column flex="1">
                    {teacher.user && teacher.user.name && (
                    <div className="review-name">{teacher.user.name}</div>
                    )}
                  </Column>
                  <MoreActions
                    type="dots"
                    position="right"
                    className="activity rating-menu-button"
                    listActions={[
                      {
                        name: 'delete',
                        icon: IconDelete,
                        text: translationText(noonText, 'activity.delete'),
                      }, {
                        name: 'approve',
                        icon: IconPublish,
                        text: translationText(noonText, 'button.approve'),
                      }, {
                        name: (teacher.review.pinned === 1) ? 'unpin-post' : 'pin-post',
                        icon: (teacher.review.pinned === 1) ? IconUnpinPost : IconPinPost,
                        text: (teacher.review.pinned === 1) ? translationText(noonText, 'post.unpin') : translationText(noonText, 'post.pin'),
                      },
                    ]}
                    cardId={teacher.review.id}
                    onSelect={(actionName) => takeAction(actionName)}
                    vertical
                  />
                  {/* TODO: need to show pubblish/delete menu option */}
                </Row>
                <div className="review-text">{teacher.review.text}</div>
              </Card>
            ))}
            {get(teacherReviews, 'meta.paging.next_offset') > -1 && (
            <Row flex="1" align="center" className="mt-1">
              <Button
                type="secondary"
                value={translationText(noonText, 'navigate.loadMore')}
                className="plr-4"
                onClick={handleLoadMoreReviews}
                />
            </Row>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span className="child card-body__note">
              {translationText(noonText, 'teacherReview.emptyTitle')}
            </span>
            <ShareButton trigger="group-info" referralEnabled="true" native groupInfo={group.group_info} hasMembers={hasMembers} className="ptb-1" />
          </React.Fragment>
        )}
      </Column>
    </Card>
  );
}

export default React.memo(GroupReviewWidget);

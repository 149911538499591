import React, { useEffect, useState } from 'react';
import { Row, Column, ProgressSpinner } from '@noon/atom';
import { IconSeeMore, IconCorrect, IconCanvasDiscard, IconCrossHeavy, IconCheckHeavy, IconHandPointer } from '@noon/quark';
import { useSelector } from 'react-redux';
import NoonQuestion from '../NoonQuestion/NoonQuestion';
import QuestionChoice from '../NoonQuestion/QuestionChoice';
import { translationText } from '../../helpers';
import { COLORS } from '../../constants';

const ReportQuestion = ({ question, index, noonText }) => {
  const [isUserAnswered, setIsUserAnswered] = useState(true);

  const { studentProfile } = useSelector((state) => state.toJS().homeworkReport);
  const isRtl = question.text_direction === 'rtl';

  useEffect(() => {
    if (studentProfile.questions[index] === undefined) {
      setIsUserAnswered(false);
    } else {
      setIsUserAnswered(true);
    }
  }, [studentProfile]);

  const returnStudentAnswer = (choice) => {
    if (studentProfile.questions.length > 0 && studentProfile.questions[index] !== undefined && studentProfile.questions[index].choice_id === choice.id) {
      return (
        <div className="student-answer">
          <IconHandPointer width="20px" height="20px" fill={COLORS.text.base} />
        </div>
      );
    }
    return true;

  };

  const returnBorderColorForChoice = (choice) => {
    if (studentProfile.position >= 0 && studentProfile.questions.length > 0 && studentProfile.questions[index] !== undefined && studentProfile.questions[index].choice_id === choice.id) {
      if (studentProfile.questions[index].is_correct) {
        return '52c419';
      }
      return 'ffbdc6';

    }
    if (choice.is_correct) {
      return '52c419';
    }
    return 'dce3fa';

  };

  const returnResultOfStudent = () => {
    if (studentProfile.questions[index] === undefined) {
      return (
        <Row className="student-result" align="center">
          <span>{`${studentProfile.name} ${translationText(noonText, 'homeworkReport.notAnswered')}`}</span>
          <IconSeeMore width="14px" height="14px" fill="#A2A7BD" stroke="#A2A7BD" />
        </Row>
      );
    }
    if (studentProfile.questions[index].is_correct) {
      return (
        <Row className="student-result" align="center">
          <span>{`${studentProfile.name} ${translationText(noonText, 'homeworkReport.gotItRight')}`}</span>
          <IconCheckHeavy fill={COLORS.green.base} stroke={COLORS.green.base} width="20px" height="20px" />
        </Row>
      );
    }
    return (
      <Row className="student-result" align="center">
        <span>{`${studentProfile.name} ${translationText(noonText, 'homeworkReport.gotItWrong')}`}</span>
        <IconCrossHeavy fill={COLORS.red.light} />

        {/* Use handpointer red when the answer is wrong */}
        {/* <IconHandPointer fill={COLORS.red.tint} /> */}

      </Row>
    );

  };

  return (
    <div className="report--question">
      <div className="description">
        <span className="prepare-assignment--singleQuestion--header--question">
          {index + 1}
          .
        </span>
        <span>
          {question.chapter_info && question.chapter_info.name}
          ,
          {' '}
          {question.topic_info && question.topic_info.name}
          ,
          {' '}
        </span>
        <span>
          {translationText(noonText, 'createHomework.hardness')}
          :
          {' '}
          {question.difficulty}
        </span>
      </div>
      {studentProfile.position >= 0 && returnResultOfStudent()}
      {question.question_json && question.question_json.length ? (
        <NoonQuestion dir={question.text_direction} content={question.question_json} />
      ) : (
        <div
          dir={question.text_direction}
          dangerouslySetInnerHTML={{ __html: question.question }}
        />
      )}
      <div className="prepare-assignment--singleQuestion--choices">
        {question.choices.map((choice) => (
          <Column key={choice.id} style={{ position: 'relative' }}>
            {choice.is_correct && <span className="correct-answer">{translationText(noonText, 'homeworkReport.correctAnswer')}</span>}
            {/* {studentProfile.position >= 0 && returnStudentAnswer(choice)} */}
            <div
              key={choice.id}
              className={`${choice.is_correct ? 'choice right-answer' : 'choice'}`}
              style={{ border: `1px solid #${returnBorderColorForChoice(choice)}` }}
            >
              <Row
                align="center"
                justify="space-between"
                key={choice.id}
                style={{ direction: question.text_direction === 'rtl' ? 'ltr' : 'rtl' }}
                className={`${choice.is_correct ? 'report--question--choice right-answer' : 'report--question--choice'}`}
              >
                <ProgressSpinner
                  thickness={3}
                  antiClockwise={isRtl}
                  noPointer
                  noShadow
                  activeColor={choice.is_correct ? '#52c419' : '#a6acc2'}
                  baseColor="#eeeeee"
                  color="#62687a"
                  progress={isNaN(choice.selected_user_count * 100 / question.attempted_count) ? 0 : choice.selected_user_count * 100 / question.attempted_count}
                  text={`${isNaN(choice.selected_user_count * 100 / question.attempted_count) ? 0 : Math.round(choice.selected_user_count * 100 / question.attempted_count)}%`}
                  radius={20}
                  fill="none"
                />
                {choice.answer_json && choice.answer_json.length ? (
                  <QuestionChoice question={question} choice={choice} />
                ) : (
                  <div className="choiceBox">
                    <p className="choiceMatter" dangerouslySetInnerHTML={{ __html: choice.answer }} />
                  </div>
                )}
              </Row>
            </div>
          </Column>
        ))}
      </div>
    </div>
  );
};

export default ReportQuestion;

import React from 'react';
import { Modal, Button, Row, Column } from '@noon/atom';
import { IconCorrectCircle, IconDelete, IconExclamationCircle, IconWrong } from '@noon/quark';
import classnames from 'classnames';
import ErrorBoundry from '../ErrorBoundry';

export default function ConfirmationModal(prop) {
  const {
    successBtn,
    closeBtn,
    onClose,
    onSuccess,
    isLoading,
    text,
    subText,
    className,
    children,
    modalType = 'info',
    style,
    hideTextIcon,
  } = prop;
  return (
    <ErrorBoundry>
      <Modal
        className={classnames('generic-modal-container', modalType, { [className]: className })}
        onClose={onClose}
        style={style}
      >
        <Column nowrap className="generic-modal">
          <Column align="center" className="generic-modal--body">
            <div className={classnames('header-icon', { [modalType]: modalType })}>
              {modalType === 'info' && <IconCorrectCircle width="80" height="80" fill="#31c173" stroke="#fff" />}
              {modalType === 'warning' && (
                <IconExclamationCircle width="28" height="28" flipY fill="#E31748" stroke="#E31748" />
              )}
              {modalType === 'error' && <IconWrong width="80" height="80" flipY fill="#ff3b30" stroke="#ff3b30" />}
              {modalType === 'delete' && (
                <div className="icon-delete-div">
                  <IconDelete width="80" height="80" fill="#ff3b30" stroke="#ff3b30" />
                </div>
              )}
            </div>
            <div className="generic-modal--body--wrapper">
              <Column className="generic-modal--body--text" align="center">
                <div className="text-1">{text}</div>
                {subText && (
                  <div className="text-2">
                    {!hideTextIcon && (
                      <IconExclamationCircle
                        height="20px"
                        width="20px"
                        fill="#e22012"
                        stroke="#e22012"
                        className="mlr-1"
                      />
                    )}
                    {subText}
                  </div>
                )}
                {children}
              </Column>
              <Row nowrap align="center" justify="center" gap="lg" className="generic-modal--body--btn">
                {!!successBtn && (
                  <Button
                    type={modalType === 'delete' ? 'red' : modalType === 'error' ? 'secondary' : 'primary'}
                    size="lg"
                    className="btn-modal"
                    style={{ textTransform: 'capitalize' }}
                    loading={isLoading}
                    onClick={onSuccess}
                  >
                    {successBtn}
                  </Button>
                )}
                {!!closeBtn && (
                  <Button
                    id="btn-close-modal"
                    type="secondary"
                    size="lg"
                    className="btn-modal"
                    style={{
                      // width: '159px',
                      textTransform: 'capitalize',
                    }}
                    disabled={isLoading}
                    onClick={onClose}
                  >
                    {closeBtn}
                  </Button>
                )}
              </Row>
            </div>
          </Column>
        </Column>
      </Modal>
    </ErrorBoundry>
  );
}

import { get } from 'lodash-es';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SET_LOCAL_DEVICE_DETAILS } from '../../../redux/constants';
import AgoraRtcWrapper from '../../../sdk/agora/agoraRtc';
import AGORA_RTC_ERRORS from '../../../sdk/agora/agoraRtcErrors';
import { SentryType, TrackSentryError } from '../../../sdk/sentry';
import Mixpanel from '../../Mixpanel';
import { IWebrtcConnectionDetails, WEBRTC_PROVIDER } from '../WebrtcContainer';
import TestNetworkStatusOfflineModal from './network-status-modal';
import TestFailedModal from './pre-call-test-failed-modal';
import PreCallTestSteps from './pre-call-test-steps';
import TestAgoraServiceFailedModal from './test-agora-service-failed-modal';
import TestMicPermissionFailedModal from './test-mic-permission-failed-modal';

import('../../../sdk/agora/AgoraRTC_N-4.6.2.100');

type Props = {
    sessionData: {
        id: string,
        is_video_enabled: boolean,
        isCameraWorking: boolean,
        webrtc_details: {
            webrtc_api: string,
            webrtc_token: string
        }
    },
    user: {
        user_id: string
    },
    endTheTest: () => void,
    onExit: () => void,
    connectionDetails: IWebrtcConnectionDetails,
    setLocalDeviceDetails: () => void
}
const PreCall = ({ sessionData, user, endTheTest, onExit, connectionDetails, setLocalDeviceDetails }: Props) => {
  const dispatch = useDispatch();
  const localDevices = useSelector((state) => state.toJS().myClassroom.localDevices);

  const [networkConnectionStatus, setNetworkConnectionStatus] = useState(true);
  const [step, setStep] = useState(1);
  const isCompatible = useMemo(() => AgoraRTC.checkSystemRequirements(), [AgoraRTC]);
  const [volume, setVolume] = useState(0);
  const [audioStats, setAudioStats] = useState(0);
  const [micTimer, setMicTimer] = useState(0);
  const [uplinkNetworkStats, setUplinkNetworkStats] = useState({});
  const [downlinkNetworkStats, setDownlinkNetworkStats] = useState({});

  // const [agoraConnectionStatus, setAgoraConnectionStatus] = useState('');

  const [isNetworkIssue, setIsNetworkIssue] = useState(false);
  const [isAgoraTestPassed, setIsAgoraTestPassed] = useState(true);
  const [isTestOnGoing, setIsTestOnGoing] = useState(true);
  const [isMicEnabled, setIsMicEnabled] = useState(false);
  const [isMicPermmissionIssue, setIsMicPermissionIssue] = useState(false);

  const [testStatus, setTestStatus] = useState('');

  const rtcRef = useRef(null);
  const micTimerRef = useRef(null);
  const volumeRef = useRef(null);

  const updateLocalDeviceDetails = (payload) => {
    dispatch({ type: SET_LOCAL_DEVICE_DETAILS, payload });
  };

  const runQualityTest = () => {
    if (networkConnectionStatus) {
      const uplinkNetworkQuality = parseInt(Object.keys(uplinkNetworkStats).reduce((a, b) => (uplinkNetworkStats[a] > uplinkNetworkStats[b] ? a : b)), 10);
      const downlinkNetworkQuality = parseInt(Object.keys(downlinkNetworkStats).reduce((a, b) => (downlinkNetworkStats[a] > downlinkNetworkStats[b] ? a : b)), 10);

      const isNetworkGood = uplinkNetworkQuality <= 4 && downlinkNetworkQuality <= 4 && uplinkNetworkQuality !== 0 && downlinkNetworkQuality !== 0;

      if (!isNetworkGood) {
        setIsNetworkIssue(true);
      }

      const eventsData = {
        uid: user.user_id,
        channel: `${sessionData.id}`,
        browserCompatible: isCompatible ? 'Yes' : 'No',
        networkResult: !isNetworkGood ? 'Bad' : 'Good',
        audioResult: audioStats > 60 ? 'Good' : (audioStats < 60 && audioStats > 0) ? 'Bad' : 'No Audio',
        downloadNetwork: downlinkNetworkQuality,
        uploadNetwork: uplinkNetworkQuality,
        audioData: audioStats,
      };

      let precallTestEventData;
      if (audioStats > 60 && isCompatible && isNetworkGood) {
        setStep(4);
        setTimeout(() => {
          endTheTest();
        }, 2000);
        precallTestEventData = { ...eventsData, precallResult: 'Passed' };
      } else {
        setIsAgoraTestPassed(false);
        setIsTestOnGoing(false);
        precallTestEventData = { ...eventsData, precallResult: 'Failed' };
      }
      // add logger
      Mixpanel.track('teacher_precall_test', precallTestEventData);
    }
  };

  const updateNetworkStats = (data) => {
    setUplinkNetworkStats((prevNetworkStats) => ({
      [data.uplinkNetworkQuality]: (prevNetworkStats[data.uplinkNetworkQuality] || 0) + 1,
    }));
    setDownlinkNetworkStats((prevNetworkStats) => ({
      [data.downlinkNetworkQuality]: (prevNetworkStats[data.downlinkNetworkQuality] || 0) + 1,
    }));
  };

  const updateAgoraConnectionStatus = (state) => {
    if (state.curState === 'CONNECTED' && state.prevState === 'INITIATED') {
      setStep(3);
    }
    // setAgoraConnectionStatus(state.curState);
  };

  const onError = (msg: string, err: any) => {
    TrackSentryError(msg, err, SentryType.ERROR);
    if (msg === AGORA_RTC_ERRORS[110] || msg === AGORA_RTC_ERRORS[111] || msg === 'DEVICE_NOT_FOUND') {
      updateLocalDeviceDetails({
        isCameraWorking: false,
      });
    } else if (msg === AGORA_RTC_ERRORS[109]) {
      setIsMicPermissionIssue(true);
    } else {
      console.error(msg, err);
    }
  };

  const onChangeMicStatus = (state) => {
    if (state) {
      const microphoneId = get(localDevices, ['selected', 'microphone', 'deviceId']);
      if (microphoneId) {
        rtcRef.current.switchDevice('audio', microphoneId);
      }
      micTimerRef.current = setInterval(() => {
        setMicTimer((prevMicTimer) => prevMicTimer + 1);
      }, 1000);
    }
  };

  const onChangeCameraStatus = (state) => {
    if (state) {
      const cameraId = get(localDevices, ['selected', 'camera', 'deviceId']);
      if (cameraId) {
        rtcRef.current.switchDevice('video', cameraId);
      }
    }
  };

  const handleOnMicrophoneChanged = (event) => {
    setLocalDeviceDetails();
  }

  const initAgoraRtc = () => {
    setStep(2);

    const { webrtc_token, webrtc_api } = sessionData.webrtc_details || {};
    const config = {
      appID: webrtc_api,
      channel: `${sessionData.id}`,
      uid: `${user.user_id}`,
      rtc_token: `${webrtc_token}-Precall-Test`,
      isStatsLoggingEnabled: false,
      isVideoEnabled: sessionData.is_video_enabled && sessionData.isCameraWorking,
      enableStats: false,
    };
    const connection = connectionDetails[WEBRTC_PROVIDER.AGORA];

    rtcRef.current = new AgoraRtcWrapper({
      connectionDetails: connection,
      connectionStatus: updateAgoraConnectionStatus,
      onStreamUpdated: () => {},
      changeMicStatus: onChangeMicStatus,
      changeVideoStatus: onChangeCameraStatus,
      onError,
      updateNetworkStats,
      handleOnMicrophoneChanged,
      options: config,
    });
    rtcRef.current.init();
  };

  const initAgoraStream = () => {
    rtcRef.current.enableAudioStream();
    if (sessionData.is_video_enabled) rtcRef.current.enableVideoStream();

    volumeRef.current = setInterval(() => {
      if (rtcRef.current) {
        const inputVolume = Math.floor(rtcRef.current.getAudioVolumeLevel() * 100);
        setAudioStats((prevAudioStats) => prevAudioStats + inputVolume);
        setVolume(inputVolume);
      }
    }, 100);
  };

  const handleOnClickEnableMic = () => {
    setIsMicEnabled((prevIsMicEnabled): boolean => {
      if (!prevIsMicEnabled) {
        initAgoraStream();
        return !prevIsMicEnabled;
      }
      return prevIsMicEnabled;
    });
  };

  const handleOnSelectCamera = (device) => {
    if (get(localDevices, ['selected', 'camera', 'deviceId']) !== get(device, 'deviceId')) {
      updateLocalDeviceDetails({
        selected: {
          camera: device,
          microphone: get(localDevices, ['selected', 'microphone']),
        },
      });
    }
  };

  const handleOnSelectMicrophone = (device) => {
    if (get(localDevices, ['selected', 'microphone', 'deviceId']) !== get(device, 'deviceId')) {
      updateLocalDeviceDetails({
        selected: {
          camera: get(localDevices, ['selected', 'camera']),
          microphone: device,
        },
      });
    }
  };

  const handleOnClickRetry = () => {
    setIsAgoraTestPassed(true);
    setIsTestOnGoing(true);
    setIsNetworkIssue(false);
    setAudioStats(0);
    setUplinkNetworkStats({});
    setDownlinkNetworkStats({});
    setTestStatus('');
    setIsMicEnabled(false);
    setMicTimer(0);
    initAgoraRtc();
  };

  useEffect(() => {
    window.addEventListener('online', () => {
      setNetworkConnectionStatus(true);
      handleOnClickRetry();
    });
    window.addEventListener('offline', () => {
      setNetworkConnectionStatus(false);
      if (rtcRef.current) rtcRef.current.disconnect();
    });
    // checkMicAndCamera();
    return () => {
      if (micTimerRef.current) clearInterval(micTimerRef.current);
      if (volumeRef.current) clearInterval(volumeRef.current);
      if (rtcRef.current) rtcRef.current.disconnect();
    };
  }, []);

  useEffect(() => {
    if (micTimer > 0 && micTimer === 7) {
      clearInterval(micTimerRef.current);
      clearInterval(volumeRef.current);
      if (rtcRef.current) rtcRef.current.disconnect();
    }
  }, [micTimer]);

  useEffect(() => {
    if (micTimer >= 7) {
      setMicTimer(0);
      runQualityTest();
    }
  }, [audioStats, uplinkNetworkStats, micTimer]);

  useEffect(() => {
    if (!isAgoraTestPassed && get(localDevices, ['selected', 'microphone', 'length']) <= 0) {
      setLocalDeviceDetails();
    }
  }, [isAgoraTestPassed]);

  return (
    <React.Fragment>
      {isTestOnGoing && (
        <PreCallTestSteps
          step={step}
          initAgoraRtc={initAgoraRtc}
          handleOnClickEnableMic={handleOnClickEnableMic}
          isMicEnabled={isMicEnabled}
          micTimer={micTimer}
          sessionData={sessionData}
          volume={volume}
          onSkip={endTheTest}
        />
      )}

      {testStatus === 'failed' && (
      <TestFailedModal handleOnClickRetry={handleOnClickRetry} onExit={onExit} />
      )}

      {isMicPermmissionIssue && (
        <TestMicPermissionFailedModal onExit={onExit} />
      )}

      {!isAgoraTestPassed && (
        <TestAgoraServiceFailedModal
          sessionData={sessionData}
          localDevices={localDevices}
          audioStats={audioStats}
          handleOnClickRetry={handleOnClickRetry}
          handleOnSelectCamera={handleOnSelectCamera}
          handleOnSelectMicrophone={handleOnSelectMicrophone}
          isCompatible={isCompatible}
          isNetworkIssue={isNetworkIssue}
          onExit={onExit}
        />
      )}

      {!networkConnectionStatus && (
        <TestNetworkStatusOfflineModal />
      )}

    </React.Fragment>
  );
};

export default PreCall;

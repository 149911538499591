import React, { useCallback, useState, useEffect } from 'react';
import isEmpty from 'lodash-es/isEmpty';
import findIndex from 'lodash-es/findIndex';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { filter } from 'lodash-es';
import { Column, Button } from '@noon/atom';
import Select from '../Layout/selectCopy';
import { translationText } from '../../helpers';
import { GET_CHAPTERS_FROM_GROUP, GET_TOPICS_FROM_GROUP } from '../../redux/constants';
import t from '../../helpers/translate';

export default function ChapterTopicMultiSelect(prop) {
  const { groupId, onTopicSelect, preChapterId, preTopicId, disabled = false, className = '', showNote = false } = prop;
  const dispatch = useDispatch();
  const history = useHistory();

  const groupChapters = useSelector((state) => state.toJS().groupsV2.groupChapters);
  const groupTopics = useSelector((state) => state.toJS().groupsV2.groupTopics);
  const noonText = useSelector((state) => state.toJS().translation.noonText);
  const { list: chapters, loading: chapterLoading } = groupChapters;
  const { list: topics, loading: topicLoading, chapterId: topicForChapter } = groupTopics;

  const [selectedTopic, updateSelectedTopic] = useState({});
  const [selectedChapter, setSelectedChapter] = useState({});
  const [curriculum, updateCurriculum] = useState([]);
  const getGroupChapters = useCallback((payload) => dispatch({ type: GET_CHAPTERS_FROM_GROUP.REQUEST, payload }), []);
  const getGroupTopics = useCallback((payload) => dispatch({ type: GET_TOPICS_FROM_GROUP.REQUEST, payload }), []);

  const handleCurriculumSelection = (item) => {
    if (!selectedTopic || (selectedTopic && selectedTopic.id !== item.id)) {
      updateSelectedTopic(item);
      onTopicSelect(item);
    }
  };

  const handleTopicCall = (chapter) => {
    if (chapter.type === 'misc') {
      return;
    }
    const selectedChapter = curriculum[findIndex(curriculum, ['chapter_id', chapter.chapter_id])];
    setSelectedChapter(selectedChapter);
    if (isEmpty(selectedChapter.topics)) {
      getGroupTopics({ chapterId: chapter.chapter_id, groupId });
    }
  };

  // get chapters for group id
  useEffect(() => {
    if (groupId) getGroupChapters(groupId);
  }, [getGroupChapters, groupId]);

  // get chapters for group id
  useEffect(() => {
    if (preChapterId && isEmpty(selectedTopic)) {
      curriculum.map((item) => {
        if (item.chapter_id === preChapterId && isEmpty(item.topics)) {
          handleTopicCall(item);
        }
      });
    }
  }, [curriculum]);

  // add empty topics array to each chapter item to support multilevel select
  useEffect(() => {
    if (!isEmpty(chapters) && chapters[0].topics === undefined) {
      const chapterList = filter(chapters.map((chapter) => ({ ...chapter, topics: [] })), (o) => o.type === 'chapter' || (o.type === 'misc'));
      updateCurriculum(chapterList);
    }
  }, [chapters]);

  // once topics come, add them to the specific chapter
  useEffect(() => {
    const selectedChapterIndex = findIndex(curriculum, ['chapter_id', topicForChapter]);
    if (!isEmpty(topics) && curriculum[selectedChapterIndex] && isEmpty(curriculum[selectedChapterIndex].topics)) {
      curriculum[selectedChapterIndex].topics = topics;
      updateCurriculum([...curriculum]);
    }
    if (preTopicId) {
      const topicIndex = findIndex(topics, ['topic_id', preTopicId]);
      if (topicIndex > -1) {
        handleCurriculumSelection(topics[topicIndex]);
      }
    }
  }, [topics, topicForChapter]);

  const EmptyComponent = () => (
    <Column className="empty-curriculum mlr-1" align="center" justify="center">
      <p>{translationText(noonText, 'groupCurriculum.addCurriculum')}</p>
      <Button type="secondary" style={{ textTransform: 'initial' }} size="sm" value={translationText(noonText, 'groupCurriculum.fillCurriculum')} onClick={() => history.push(`/curriculum/${groupId}`)} />
    </Column>
  );

  return (
    <Column nowrap className={`no-shrink ${className}`}>
      <Select
        disabled={disabled}
        list={curriculum}
        onSelect={handleCurriculumSelection}
        selectedItem={selectedTopic}
        fetchExtraLevelDetails={handleTopicCall}
        extraLevel="topics"
        listItem="name"
        emptyMessage={EmptyComponent || translationText(noonText, 'groupCurriculum.addCurriculum')}
        isLoading={chapterLoading || topicLoading}
        title={translationText(noonText, 'createHomework.chapterPlaceholder')}
        label={translationText(noonText, 'form.chapterAndLesson')}
        outlined
      />
      {showNote
      && (
      <p className="chapter-topic-note flex-1">
        {t('library', 'createQuestionChapterMessage')}
        {' '}
        {selectedChapter.name}
        {selectedTopic.name && `, ${selectedTopic.name}`}
      </p>
      )}
    </Column>
  );
}

import { all } from 'redux-saga/effects';
import { fileSaga } from './file';
import { flashcardSaga } from './flashcard';
import { folderSaga } from './folder';
import { questionSaga } from './question';
import { notificationSaga } from './notification';
import { toastSaga } from './toast';
import { translationSaga } from './translation';
import { tutoringSaga } from './tutoring';
import { userSaga } from './user';
import { groupsSaga } from './groups';
import { postSaga } from './posts';
import { analyticSaga } from './analytic';
import { userv2Saga } from './userv2';
import { biFrostSaga } from './biFrost';
import { onboardingSaga } from './onboarding';
import { teamsSaga } from './teams';
import { classroom } from './classroom';
import { curriculumSaga } from './curriculum';
import { groupsV2Saga } from './groupsV2';
import { feedQuestion } from './feedQuestion';
import { spartaSaga } from './sparta';
import { generalPlannerSagas } from './generalPlanner';
import { schedulerSagas } from './scheduler';
import { addQuestionFromImageSaga } from './addQuestionFromImage';
import { promotionSaga } from './promotion';
import { packagesSaga } from './packages';
import { myClassroomSaga } from './myClassroom';
import { contentSaga } from './content';
import { whiteBoardSaga } from './whiteboard';
import { TeamQnaSaga } from '../../components/Classroom/TeamQna/saga';
import { playbackSaga } from './playback';
import { experimentSaga } from './expermient';

export default function* rootSaga() {
  yield all([
    fileSaga(),
    flashcardSaga(),
    folderSaga(),
    questionSaga(),
    toastSaga(),
    translationSaga(),
    tutoringSaga(),
    userSaga(),
    notificationSaga(),
    groupsSaga(),
    postSaga(),
    analyticSaga(),
    userv2Saga(),
    biFrostSaga(),
    onboardingSaga(),
    teamsSaga(),
    classroom(),
    groupsV2Saga(),
    feedQuestion(),
    curriculumSaga(),
    spartaSaga(),
    generalPlannerSagas(),
    schedulerSagas(),
    addQuestionFromImageSaga(),
    promotionSaga(),
    packagesSaga(),
    myClassroomSaga(),
    contentSaga(),
    whiteBoardSaga(),
    TeamQnaSaga(),
    playbackSaga(),
    experimentSaga(),
  ]);
}

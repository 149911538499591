import { put, call, takeEvery, all, select } from 'redux-saga/effects';
import isEmpty from 'lodash-es/isEmpty';
import { noonTextSelector } from 'redux/selectors/translations';
import { promotionApi } from '../restApi';
import { addToast, TOAST_TYPE } from '../../components/Toast';
import { GET_CAMPAIGN_BANNER, GET_REFERRAL_LINK } from '../constants';
import { translationText } from '../../helpers';

const getReferralLinkAPI = (payload) => promotionApi.post('referral', payload);
const getCampaignBannerAPI = (payload) => promotionApi.get('/teacher/banner', payload);

function generateProductsFromTags(items) {
  return items.map((item) => {
    const copyOFItem = item;
    if (copyOFItem.curriculum_tags) {
      if (copyOFItem.curriculum_tags.type === 'k12') {
        copyOFItem.product = copyOFItem.curriculum_tags.subject;
      } else if (copyOFItem.curriculum_tags.type === 'k12_test_prep') {
        copyOFItem.product = copyOFItem.curriculum_tags.test;
      } else if (copyOFItem.curriculum_tags.type === 'k12_skill') {
        copyOFItem.product = copyOFItem.curriculum_tags.skill;
      } else if (copyOFItem.curriculum_tags.type === 'university') {
        copyOFItem.product = copyOFItem.curriculum_tags.course;
      } else if (copyOFItem.curriculum_tags.type === 'university_test_prep') {
        copyOFItem.product = copyOFItem.curriculum_tags.test;
      } else if (copyOFItem.curriculum_tags.type === 'university_skill') {
        copyOFItem.product = copyOFItem.curriculum_tags.skill;
      } else {
        copyOFItem.product = { name: copyOFItem.curriculum_tags.type };
      }
    }
    return copyOFItem;
  });
}

function* getReferralLink({ payload }) {
  try {
    const noonText = yield select(noonTextSelector);
    const response = yield call(getReferralLinkAPI, payload);
    if (response.ok) {
      yield put({
        type: GET_REFERRAL_LINK.SUCCESS,
        payload: isEmpty(response.data.data) ? {} : response.data.data,
      });
    } else {
      yield put({ type: GET_REFERRAL_LINK.FAILURE, payload: 'NETWORK_ERROR' });
      addToast(translationText(noonText, 'error.cantCreateShareableLink'), TOAST_TYPE.ERROR);
    }
  } catch (err) {
    addToast(err, TOAST_TYPE.ERROR);
    yield put({ type: GET_REFERRAL_LINK.FAILURE, payload: err });
  }
}

function* getCampaignBanner({ payload }) {
  try {
    const response = yield call(getCampaignBannerAPI, payload);
    if (response.ok) {
      yield put({
        type: GET_CAMPAIGN_BANNER.SUCCESS,
        payload: generateProductsFromTags(response.data.data.groups || []),
      });
    } else {
      yield put({ type: GET_CAMPAIGN_BANNER.FAILURE, payload: response.data.message });
    }
  } catch (error) {
    yield put({ type: GET_CAMPAIGN_BANNER.FAILURE, payload: error });
  }
}

export function* promotionSaga() {
  yield all([
    takeEvery(GET_REFERRAL_LINK.REQUEST, getReferralLink),
    takeEvery(GET_CAMPAIGN_BANNER.REQUEST, getCampaignBanner),
  ]);
}

import React from 'react';
import { Row, Column } from '@noon/atom';

export default function CovidSection() {
  return (
    <div className="section-outer-container covid-outer">
      <div className="section landing-covid">
        <Row className="landing-covid__content">
          <div className="text">
            <h1 className="content-heading">About COVID-19</h1>
            <p className="content-subheading">How to protect your kids.<br />Whilst it is understandable to feel anxious about the outbreak, WHO emphasizes the fact that, if you are not in an area where COVID-19 is spreading, or have not travelled from an area where the virus is spreading, or have not been in contact with an infected patient, your risk of infection is low.</p>
            <div className="other-links">
              <p>Other online chat tools</p>
              <a target="_blank" href="https://www.who.int">WHO</a>
              <a target="_blank" href="https://www.cdc.gov">The CDC</a>
            </div>
          </div>
          <Column className="video">
            <iframe width="500" height="282" src="https://www.youtube.com/embed/3PmVJQUCm4E" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </Column>
        </Row>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Button } from '@noon/atom';
import { IconCopy, IconCorrectCircle, IconFacebook, IconLoader, IconTwitter } from '@noon/quark';
import classNames from 'classnames';
import copyToClipboard from '../../../helpers/copyToClipboard';
import { translationText } from '../../../helpers';
import SocialShare from '../../SocialShare';
import { GET_REFERRAL_LINK } from '../../../redux/constants';
import Mixpanel from '../../Mixpanel';
import { COLORS } from '../../../constants';

function ShareButton({ hasMembers, trigger, referralEnabled = false, groupInfo = {}, teacher = {}, className, native, ...props }) {
  const dispatch = useDispatch();
  const [isGroupUrlCopied, setIsGroupUrlCopied] = useState(false);
  const [waiting, toggleWaiting] = useState(false);
  const [shareWith, setShareWith] = useState('');
  const [sharedForGroup, setSharedGroupId] = useState(null);
  const [failedToCopy, toggleFailedToCopy] = useState(false);
  const { referralLinkData = {} } = useSelector((state) => state.toJS().promotion);
  const { noonText } = useSelector((state) => state.toJS().translation);
  // const logger = useRef(LoggingManager.registerLogger({ moduleName: 'group' })).current;
  const { id: groupId, type, is_premium, title } = groupInfo;
  const shareUrl = `${process.env.STUDENT_URL}${localStorage.updatedLocale}/groups/${groupId}`;
  const shareText = `${translationText(noonText, 'groups.joinMyGroup')} ${title}`;

  const trackEvent = (eventName, eventPayload) => {
    Mixpanel.track(eventName, eventPayload);
    // logger.track({
    //   event_attributes: { ...eventPayload, source: 'refer' },
    //   event_name: eventName,
    // });
  };

  // First data event, when the button is clicked to fetch url
  const trackReferralInitiated = () => {
    const eventPayload = {
      teacher_id: teacher.id,
      group_privacy: is_premium ? 'paid' : type,
      group_id: `${groupId}`,
      trigger_point: trigger,
      referrer_type: 'teacher',
    };
    trackEvent('invite_dialog_initiated', eventPayload);
  };

  // Second data event, when the api returns with link data
  const trackReferralLinkGeneration = () => {
    const eventPayload = {
      teacher_id: teacher.id,
      group_privacy: is_premium ? 'paid' : type,
      trigger_point: trigger,
      group_id: `${groupId}`,
      referrer_type: 'teacher',
    };
    trackEvent('referral_link_generated', eventPayload);
  };

  // Third data event, when the sharing is finally triggered
  const trackReferralTrigger = () => {
    const { referral_code, url: referred_url } = referralLinkData;
    const eventPayload = {
      entity: 'group',
      entity_id: `${groupId}`,
      referral_code,
      referred_url,
      sharing_app: shareWith,
      referrer_type: 'teacher',
    };
    trackEvent('referral_triggered', eventPayload);
  };

  const handleClickCopyGroupUrl = () => {
    const text = referralEnabled ? `${referralLinkData.message}\n${referralLinkData.url}` : shareUrl;
    copyToClipboard(text).then(() => {
      setIsGroupUrlCopied(true);
      toggleFailedToCopy(false);
      setTimeout(() => {
        setIsGroupUrlCopied(false);
      }, 6000);
    }).catch((err) => {
      toggleFailedToCopy(true);
      console.log('Error while copying: ', err);
    });
  };

  // Commenting this until we create a custom bottom action sheet

  // const handleShare = () => {
  //   if (navigator.share) {
  //     navigator.share({
  //       title: 'Noon Academy',
  //       text: referralLinkData.message,
  //       url: referralLinkData.url,
  //     })
  //       .then(() => {
  //         console.log('Successful share');
  //         // trackReferralTrigger();
  //       })
  //       .catch(error => console.log('Error sharing', error));
  //   }
  // };

  const handleReferralLink = () => {
    trackReferralLinkGeneration();
    toggleWaiting(false);
    setSharedGroupId(groupId);
    if (shareWith === 'copied') {
      handleClickCopyGroupUrl();
    }
    trackReferralTrigger();
    // Commenting this until we create a custom bottom action sheet

    // else if (shareWith === 'native') {
    //   handleShare();
    // }
  };

  const getShareableLink = (target) => {
    if (waiting) return;
    setShareWith(target);
    if (!referralEnabled) {
      if (target === 'copied') handleClickCopyGroupUrl();
      return;
    }
    trackReferralInitiated();
    if (sharedForGroup === groupId && referralLinkData.url) {
      handleReferralLink();
    } else {
      const payload = {
        entity_type: 'group',
        entity_id: String(groupId),
        action: 'group_join',
      };
      dispatch({
        type: GET_REFERRAL_LINK.REQUEST,
        payload,
      });
      toggleWaiting(true);
    }
  };

  useEffect(() => {
    if (referralLinkData.url && waiting) {
      handleReferralLink();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralLinkData]);

  return (
  // Commenting this until we create a custom bottom action sheet

  // navigator.share && isMobile && native ? (
  //   <Share width="20px" height="20px" onClick={() => getShareableLink('native')} />
  // ) : (
    <Row align="center" justify="start" gap="lg" className={className} nowrap {...props}>
      <Button
        id="btn-copy-group-url"
        type={hasMembers ? 'secondary' : 'primary'}
        className={classNames(isGroupUrlCopied ? 'plr-1 btn-copied-url' : 'plr-2')}
        style={{ textTransform: 'capitalize' }}
        onClick={() => getShareableLink('copied')}
      >
        {isGroupUrlCopied ? <IconCorrectCircle fill="#fff" style={{ stroke: COLORS.brand.base }} /> : !waiting ? <IconCopy fill="#fff" stroke={COLORS.brand.base} /> : <IconLoader fill="#fff" stroke={COLORS.brand.base} />}
        {translationText(noonText, `groups.${isGroupUrlCopied ? 'groupURLCopied' : 'copyGroupURL'}`)}
        {/* {translationText(noonText, 'groupMembers.groupURLCopied')} */}
      </Button>
      <Row align="center" justify="start" gap="sm" nowrap className="social-links">
        <SocialShare
          provider="facebook"
          text={referralEnabled ? referralLinkData.message : shareText}
          url={referralEnabled ? referralLinkData.url : shareUrl}
          onAction={() => getShareableLink('facebook')}
          externalActionStatus={((referralLinkData && referralLinkData.url && !waiting) || !referralEnabled) && shareWith === 'facebook' ? 'finished' : 'expected'}
          >
          <IconFacebook height="36px" width="36px" fill="#2350B3" stroke="#fff" />
        </SocialShare>
        <SocialShare
          provider="twitter"
          text={referralEnabled ? referralLinkData.message : shareText}
          url={referralEnabled ? referralLinkData.url : shareUrl}
          onAction={() => getShareableLink('twitter')}
          externalActionStatus={((referralLinkData && referralLinkData.url && !waiting) || !referralEnabled) && shareWith === 'twitter' ? 'finished' : 'expected'}
          >
          <IconTwitter height="36px" width="36px" fill="#40A9FF" stroke="#fff" />
        </SocialShare>
      </Row>
    </Row>
  // )
  );
}

ShareButton.propTypes = {};
ShareButton.defaultProps = {};
export default ShareButton;

// TODO: Why this component is part of studygroup
import React, { useEffect, useState, useRef } from 'react';
import { Column, Row, Checkbox, Button, Card, Input, Avatar } from '@noon/atom';
import { IconAddUser, IconArrowDown, IconClose, IconLoader, IconSearchO, IconShare, IconStudent } from '@noon/quark';
import classnames from 'classnames';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  GROUP_MEMBERS,
  GROUP_PENDING_REQUEST,
  GROUP_ACCEPT_REQUEST,
  GROUP_REMOVE_MEMBERS,
  SEARCH_GROUP_MEMBERS,
  BLOCK_USER_LIST,
  GET_TEACHER_GROUP_SELECTED,
  CLEAR_GROUP_MEMBERS,
  LIST_PENDING_CASH_PAYMENT,
} from '../../redux/constants';
import SharePopover from '../GroupDetails/SharePopover';
import GroupMemberCard from './groupMemberCard';
import { translationText } from '../../helpers';
import Breadcrumbs from '../Breadcrumbs';
import ActivityFilter from '../Session/ActivityFilter';
import Mixpanel from '../Mixpanel';
import GroupHeader from '../GroupDetails/GroupHeader';
import ShareButton from '../GroupDetails/ShareButton';
import { COLORS } from '../../constants';
import AcceptPaymentConfirm from '../Payments/acceptPaymentConfirm';
import NotAllowedOverlay from '../GroupDetails/NotAllowedOverlay';

const contents = [
  { id: 1, name: 'active', label: 'groupMembers.members' },
  { id: 2, name: 'blocked', label: 'groupMembers.blockedUsers' },
];

export default function MemberList({ hasMembers, shareInfo, groupId }) {
  const [selectedMembers, toggleSelectedMembers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const { groupId: id } = useParams();
  const { noonText } = useSelector((state) => state.toJS().translation);
  const { groupMembers, pendingRequest, blockUserList } = useSelector((state) => state.toJS().groups);
  const [selectedContent, setSelectedContent] = useState(contents[0]);
  const [showAcceptPaymentConfirm, setShowAcceptPaymentConfirm] = useState(false);
  const [selectedMemberRequest, setSelectedMemberRequest] = useState({});

  const { transactions, updatePaymentState } = useSelector((state) => state.toJS().packages);
  const { selectedGroup: group } = useSelector((state) => state.toJS().groupsV2);
  const user = useSelector((state) => state.toJS().userv2.getTeacher.response);
  const logger = useRef(LoggingManager.mount({ moduleName: 'group' })).current;
  const isPremium = group && group.group_info && group.group_info.is_premium;
  const isArchived = get(group, ['archive', 'is_archived']);

  const handleMixpanelCurriculumEvents = ({ data = {}, type, mixPanel = true }) => {
    const dataConst = {
      user_id: user.user_id,
      group_id: id,
      group_name: get(group, ['group_info', 'title']),
      group_country: get(group, ['country', 'name']),
      group_subject: get(group, ['curriculum_tags', 'subject', 'name']),
      group_grades: get(group, ['curriculum_tags', 'grade', 'name']),
      group_curriculum_type: get(group, ['curriculum_tags', 'TYPE']),
      group_type: get(group, ['group_info', 'TYPE']),
      group_age: '',
      no_of_students: get(group, ['membersInfo', 'total_members']),
      timestamp: Date.now(),
      ...data,
    };
    if (mixPanel) {
      Mixpanel.track(type, dataConst);
    }
    logger.track(type,
      { group: dataConst },
    );
  };

  const getGroupPendingRequests = (start) => {
    if (group.group_info.is_premium) {
      dispatch({
        type: LIST_PENDING_CASH_PAYMENT.REQUEST,
        payload: {
          group_id: groupId,
          start,
          limit: 10,
        },
      });
    } else {
      dispatch({
        type: GROUP_PENDING_REQUEST.REQUEST,
        payload: { id: groupId, start, limit: 10 },
      });
    }
  };

  const getGroupMembers = (start = 0) => {
    dispatch({ type: GROUP_MEMBERS.REQUEST, payload: { group_id: groupId, start } });
  };

  useEffect(() => {
    if (group && group.group_info && !transactions.loading && isEmpty(transactions.list)) {
      getGroupPendingRequests(0);
    }
  }, [group]);

  useEffect(() => {
    dispatch({
      type: GET_TEACHER_GROUP_SELECTED.REQUEST,
      payload: groupId,
    });
    return () => {
      LoggingManager.unmount(logger.id);
      dispatch({ type: CLEAR_GROUP_MEMBERS });
    };
  }, []);

  useEffect(() => {
    if (selectedContent.id === 1) {
      getGroupMembers(0);
    } else if (selectedContent.id === 2) {
      dispatch({ type: BLOCK_USER_LIST.REQUEST, payload: { group_id: groupId, start: 0 } });
    }
  }, [selectedContent, groupId]);

  useEffect(() => {
    if (searchText === '') {
      getGroupMembers(0);
    }
  }, [searchText]);

  const acceptMembers = () => {
    dispatch({ type: GROUP_ACCEPT_REQUEST.REQUEST, payload: { group_id: groupId, users_array: [...selectedMembers] } });
  };

  const acceptMember = (memberId) => {
    if (isPremium) return false;
    dispatch({ type: GROUP_ACCEPT_REQUEST.REQUEST, payload: { group_id: groupId, users_array: [memberId] } });
  };

  const removeMember = (memberId) => {
    if (isPremium) return false;
    dispatch({ type: GROUP_REMOVE_MEMBERS.REQUEST, payload: { group_id: groupId, users_array: [memberId] } });
  };
  const allSelected = pendingRequest && pendingRequest.list && selectedMembers && pendingRequest.list.length === selectedMembers.length;

  const processSelectedMembers = (id) => {
    if (selectedMembers.indexOf(id) === -1) {
      toggleSelectedMembers((previousMembers) => [...previousMembers, id]);
    } else {
      const remaining = selectedMembers.filter((memberId) => memberId !== id);
      toggleSelectedMembers(remaining);
    }
  };

  const processAllMembers = () => {
    if (allSelected) {
      toggleSelectedMembers([]);
    } else {
      const allMembers = pendingRequest.list.map(({ id }) => id);
      toggleSelectedMembers(allMembers);
    }
  };

  const foundInSelected = (id) => selectedMembers.indexOf(id) !== -1;

  const searchGroupMembers = (e) => {
    const inputValue = e.target.value;
    setSearchText(e.target.value);
    if (inputValue.length > 3) {
      dispatch({ type: SEARCH_GROUP_MEMBERS.REQUEST, payload: { group_id: groupId, searchText: inputValue } });
    }
  };

  const loadMoreMembers = (name) => {
    const { list, isLoading } = groupMembers;
    const { response: blockedList = [], isLoading: isBlockedLoading } = blockUserList;
    if (isLoading || isBlockedLoading) {
      return false;
    }
    const listLength = name === 'active' ? list.length || 0 : blockedList.length || 0;
    const payload = {
      start: listLength && selectedContent.name === name ? listLength : 0,
      group_id: groupId,
    };
    dispatch({
      type: name === 'active' ? GROUP_MEMBERS.REQUEST : BLOCK_USER_LIST.REQUEST,
      payload,
    });
    return true;
  };

  const goBack = () => {
    history.push(`/${localStorage.updatedLocale}/groups/${groupId}`);
  };

  const handleOnClickAcceptPaymentConfirm = (request) => {
    setSelectedMemberRequest(request);
    setShowAcceptPaymentConfirm(!showAcceptPaymentConfirm);
  };

  return (
    <React.Fragment>
      <GroupHeader group={group} handleMixpanelCurriculumEvents={handleMixpanelCurriculumEvents} />
      <Breadcrumbs onItemClick={goBack} pageTitle={translationText(noonText, 'groupMembers.title')} />
      {(groupMembers.loading || pendingRequest.loading || transactions.loading) ? (
        <React.Fragment>
          <Row className="members-page container">
            <IconLoader />
          </Row>
        </React.Fragment>
      ) : (!isEmpty(groupMembers.list) || !isEmpty(pendingRequest.list) || !isEmpty(transactions.list) || searchText) ? (
        <React.Fragment>
          <Row className="members-page container">
            <Card className="member-page-section request">
              {isArchived && <NotAllowedOverlay />}
              <Row className="memberlist-header add-requests" align="center" justify="space-between">
                <Row className="left-content">
                  <Row className="title-icon" align="center">
                    <IconAddUser height="13px" width="14px" fill="#23294E" />
                    <h3>{translationText(noonText, 'groupMembers.addRequests')}</h3>
                  </Row>
                  {!isEmpty(pendingRequest.list) && (
                    <React.Fragment>
                      <span className="noon-dot" />
                      <p className="with_dot">{pendingRequest.total || 0}</p>
                    </React.Fragment>
                  )}
                </Row>
                <Row className="tablet-block">
                  <SharePopover trigger="members-page" shareInfo={shareInfo} hasMembers={hasMembers} fill="#23294E" />
                </Row>
              </Row>
              <Row className="all-selection" justify="space-between">
                {isEmpty(pendingRequest.list) && isEmpty(transactions.list)
                  ? <p className="text-capitalize">{translationText(noonText, 'groupMembers.onPendingReq')}</p>
                  : !isPremium && (
                  <React.Fragment>
                    <Column className="left-content">
                      <Checkbox type="checkbox" checked={allSelected} onChange={processAllMembers} label={translationText(noonText, 'label.selectAll')} />
                    </Column>
                    <Column className="right-content">
                      {!!selectedMembers && selectedMembers.length > 1 && (
                      <Button type="link" onClick={acceptMembers}>
                        {translationText(noonText, 'button.acceptSelected')}
                      </Button>
                      )}
                    </Column>
                  </React.Fragment>
                  )}
              </Row>
              <div className="scroll-bar">
                {(!isEmpty(pendingRequest.list) || !isEmpty(transactions.list))
                  && (isPremium ? transactions.list : pendingRequest.list).map((request, idx) => (
                    <Row onClick={isPremium ? () => handleOnClickAcceptPaymentConfirm(request) : undefined} align="center" key={`${request.id || request.user.id}-${idx}`} className="single-request-card request">
                      <Row nowrap gap="sm" align="center" className="left-content pending-request" flex="1">
                        {!isPremium
                        && (
                        <Checkbox
                          type="checkbox"
                          checked={foundInSelected(request.id)}
                          onChange={() => processSelectedMembers(request.id)}
                          className="child"
                        />
                        )}
                        <div className={classnames('media mlr-05', { 'plr-1': isPremium })}>
                          <div className="media-left">
                            <Avatar url={request.profile_pic || get(request, ['user', 'profile_pic'])} noShadow rounded gender={request.gender || get(request, ['user', 'gender'])} size="42px" />
                          </div>
                          <div className="media-body">
                            <p>{request.name || get(request, ['user', 'name'])}</p>
                          </div>
                        </div>
                      </Row>

                      {
                         !(request.vendor === 'cash' && user.country_id === 6) && (
                         <Row align="center" className="right-content mlr-05">

                           <Button type="link" onClick={() => acceptMember(request.id || request.user.id)}>
                             {translationText(noonText, 'button.accept')}
                           </Button>
                           <Button type="link" onClick={() => removeMember(request.id || request.user.id)} className="close">
                             <IconClose fill={COLORS.coolGrey[3]} height="16px" width="16px" />
                           </Button>
                         </Row>
                         )
}
                    </Row>
                  ))}
                {((!isEmpty(pendingRequest.list) && pendingRequest.list.length < pendingRequest.total) || (isPremium && transactions.meta.page)) && (
                  <div className="show-more-button text-center">
                    <Button
                      size="sm"
                      className="mtb-2"
                      disabled={pendingRequest.loading}
                      style={{ minWidth: '27px', border: '0', textTransform: 'capitalize' }}
                      type="secondary"
                      onClick={() => getGroupPendingRequests(pendingRequest.list.length)}
                    >
                      {translationText(noonText, 'post.showMore')}
                      {(pendingRequest.loading || transactions.loading) ? <IconLoader fill={COLORS.brand.base} stroke={COLORS.brand.base} height="15px" width="15px" className="mlr-1" /> : <IconArrowDown fill={COLORS.brand.base} stroke={COLORS.brand.base} height="15px" width="15px" className="mlr-1" />}
                    </Button>
                  </div>
                )}
              </div>
            </Card>
            <Card className="member-page-section members">
              <Row className="memberlist-header members" justify="space-between">
                <Row className="left-content">
                  <Row className="title-icon" align="center">
                    <IconStudent height="14px" width="14px" fill="#23294E" />
                    <h3>
                      {translationText(noonText, selectedContent.id === 1 ? 'groupMembers.members' : 'groupMembers.blockedUsers')}
                    </h3>
                  </Row>
                  <span className="noon-dot" />
                  <p className="with_dot">{groupMembers.allUserIds ? groupMembers.allUserIds.length : 0}</p>
                </Row>
                <Column className="desktop-block">
                  <div className="right-content share-button">
                    <Button type="link" className="share-btn">
                      <IconShare fill="#23294E" height="18px" width="16px" />
                    </Button>
                  </div>
                </Column>
              </Row>
              <Row className="memberlist-filter-block" justify="space-between" align="center">
                <Column className="left-content">
                  <ActivityFilter
                    items={contents}
                    selectedItem={selectedContent}
                    onChange={(item) => {
                      setSelectedContent(item);
                    }}
                  />
                </Column>
                {selectedContent.id === 1 && (
                  <Column className="right-content">
                    <div className="search-outer">
                      <IconSearchO height="11px" width="11px" fill="#62687A" />
                      <Input onChange={searchGroupMembers} type="text" placeholder={translationText(noonText, 'groupMembers.searchMembers')} value={searchText} />
                    </div>
                  </Column>
                )}
              </Row>
              <div className="outer">
                {selectedContent.name === 'active' ? (
                  <React.Fragment>
                    {!isEmpty(groupMembers.list) ? (
                      groupMembers.list.map(({ id, name, profile_pic, gender, subscription, joined_at }, idx) => (
                        <GroupMemberCard
                          key={`${id}-${idx}`}
                          id={id}
                          joined_at={joined_at}
                          name={name}
                          gender={gender}
                          profile_pic={profile_pic}
                          groupId={groupId}
                          subscription={subscription}
                          isArchived={isArchived}
                          />
                      ))) : (
                        <Row className="empty-state mtb-2" justify="center">
                          <span>{translationText(noonText, 'groups.listEmpty')}</span>
                        </Row>
                    )}
                    {groupMembers
                      && groupMembers.allUserIds
                      && groupMembers.list
                      && groupMembers.allUserIds.length > groupMembers.list.length && (
                        <div className="show-more-button text-center">
                          <Button
                            size="sm"
                            className="mtb-2"
                            disabled={!!groupMembers.isLoading && groupMembers.list}
                            style={{ minWidth: '27px', border: '0', textTransform: 'capitalize' }}
                            type="secondary"
                            onClick={() => loadMoreMembers(selectedContent.name)}
                          >
                            {translationText(noonText, 'post.showMore')}
                            {!!groupMembers.isLoading && groupMembers.list ? <IconLoader fill={COLORS.brand.base} stroke={COLORS.brand.base} height="15px" width="15px" className="mlr-1" /> : <IconArrowDown fill={COLORS.brand.base} stroke={COLORS.brand.base} height="15px" width="15px" className="mlr-1" />}
                          </Button>
                        </div>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {!!blockUserList && !!blockUserList.response
                        && !!blockUserList.response.length ? (
                        blockUserList.response.map(({ id, name, profile_pic, gender }, idx) => (
                          <GroupMemberCard
                            key={`${id}-${idx}`}
                            id={id}
                            name={name}
                            gender={gender}
                            profile_pic={profile_pic}
                            groupId={groupId}
                            blockList
                            isArchived={isArchived}
                            />
                        ))) : (
                          <Row className="empty-state mtb-2" justify="center">
                            <span>{translationText(noonText, 'groups.listEmpty')}</span>
                          </Row>
                      )}
                    {!!blockUserList
                        && !blockUserList.isLoading
                        && !!blockUserList.response
                        && !!blockUserList.response.length && (
                          <div className="show-more-button text-center">
                            <Button
                              size="sm"
                              className="mtb-2"
                              disabled={!!blockUserList.isLoading && blockUserList.response}
                              style={{ minWidth: '27px', border: '0', textTransform: 'capitalize' }}
                              type="secondary"
                              onClick={() => loadMoreMembers(selectedContent.name)}
                            >
                              {translationText(noonText, 'post.showMore')}
                              {!!blockUserList.isLoading && blockUserList.response ? <IconLoader fill={COLORS.brand.base} stroke={COLORS.brand.base} height="15px" width="15px" className="mlr-1" /> : <IconArrowDown fill={COLORS.brand.base} stroke={COLORS.brand.base} height="15px" width="15px" className="mlr-1" />}
                            </Button>
                          </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </Card>
          </Row>
        </React.Fragment>
      ) : isEmpty(groupMembers.list) && isEmpty(pendingRequest.list) && !pendingRequest.isLoading && !groupMembers.isLoading && !searchText ? (
        <React.Fragment>
          <Row className="members-page container" justify="space-between" gap>
            <Column>
              <h3>{translationText(noonText, 'groups.noMemberHead')}</h3>
              <p className="subtitle">{translationText(noonText, 'groups.noMemberText')}</p>
              <ShareButton trigger="members-page" referralEnabled="true" native groupInfo={get(group, ['group_info'])} hasMembers={false} />
            </Column>
            <Column className="empty-slate">
              <img src="/assets/images/empty-state/no-members.svg" alt="no Members" />
            </Column>
          </Row>
        </React.Fragment>
      ) : null}
      {showAcceptPaymentConfirm && (
      <AcceptPaymentConfirm
        updatePaymentState={updatePaymentState}
        transaction={selectedMemberRequest}
        noonText={noonText}
        onAccept={() => getGroupMembers(0)}
        viewDetails={selectedMemberRequest.vendor === 'cash' && user.country_id === 6}
        onClose={() => setShowAcceptPaymentConfirm(false)} />
      )}
    </React.Fragment>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Button } from '@noon/atom';
import { IconAdd } from '@noon/quark';
import { translationText } from '../../helpers';

export default function EmptyListActivityCard(prop) {
  const { actionTake, pastActivity } = prop;
  const { noonText } = useSelector(state => state.toJS().translation);
  return (
    <Row className="actiivy_card_empty">
      {/* Upcoming Activities  */}
      <div className="content">
        <p>{translationText(noonText, pastActivity ? 'activity.emptyPreviousHeading' : 'activity.emptyUpcomingHeading')}</p>
        <Button
          type="primary"
          size="md"
          icon={IconAdd}
          onClick={() => {
            actionTake('activity');
          }}
          className="text-capitalize"
        >
          {translationText(noonText, 'button.newActivity')}
        </Button>
      </div>
    </Row>
  );
}

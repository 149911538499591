import { put, takeEvery, call, select, all } from 'redux-saga/effects';
import isEmpty from 'lodash-es/isEmpty';
import { noonTextSelector } from 'redux/selectors/translations';
import {
  groupDetailsSelector,
  blockListMapSelector,
  groupMembersSelector,
  blockedUsersSelector,
  groupMembersListMapSelector,
} from 'redux/selectors/groups';
import { excaliburApi } from '../restApi';
import {
  CREATE_GROUP,
  GET_GROUP_DETAILS,
  GET_GROUPS,
  GROUP_ACCEPT_REQUEST,
  GROUP_REMOVE_MEMBERS,
  GROUP_PENDING_REQUEST,
  GROUP_MEMBERS,
  UPDATE_GROUP,
  GROUP_ALL_PENDING_REQUEST,
  BLOCK_USER,
  UN_BLOCK_USER,
  BLOCK_USER_LIST,
  BLOCK_USER_LIST_MAP,
  GROUP_MEMBERS_MAP,
  GROUP_ACCEPT_REQUEST_FROM_NOTIFICATIONS,
  GROUP_REMOVE_REQUEST_FROM_NOTIFICATIONS,
  GROUP_METRICS,
  GET_RECOMMENDED_USERS_FOR_GROUP,
  SEARCH_GROUP_MEMBERS,
} from '../constants';
import { addToast, TOAST_TYPE } from '../../components/Toast';
import { translationText } from '../../helpers';

const createGroupAPI = (payload) => excaliburApi.post('group', payload);
const updateGroupAPI = (payload) => excaliburApi.put('group', payload);
const getGroupsAPI = (payload) => excaliburApi.get('myGroupsForCreator', payload);
const getGroupDetailsAPI = (id) => excaliburApi.get('group', { id });
// const getGroupMembersAPI = id => excaliburApi.get('membersOfGroup', { id });
const getGroupMembersForTeacherAPI = ({ group_id, start }) => excaliburApi.get('membersOfGroupForTeacher', { id: group_id, start });
const acceptRequestAPI = ({ group_id, users_array = [] }) => excaliburApi.post('acceptRequest', { group_id, users_array });
const removeMemberAPI = ({ group_id, users_array = [] }) => excaliburApi.post('removeUsers', { group_id, users_array });
const pendingRequestAPI = ({ id, start, limit }) => excaliburApi.get(`pendingRequests/${id}`, { start, limit });
const allPendingRequestAPI = (payload) => excaliburApi.get('pendingRequests', { start: payload.start, limit: payload.limit });
const blockUserAPI = ({ group_id, user_id }) => excaliburApi.post('blockUser', { group_id, user_id });
const unBlockUserAPI = ({ group_id, user_id, join_group }) => excaliburApi.post('unblockUser', { group_id, user_id, join_group });
const blockUserListAPI = ({ group_id, start }) => excaliburApi.get(`blockedUsers/${group_id}`, { start });
const groupMetricsApi = (group_id) => excaliburApi.get(`metric/${group_id}`);
const getStudentRecommendationAPI = ({ group_id, start, limit = 10 }) => excaliburApi.get(`getRecommendedUserForGroup/${group_id}`, { start, limit });
const GroupMemberSelector = (state) => state.toJS().groups.groupMembers;
const PendingMemberSelector = (state) => state.toJS().groups.allPendingRequest;
const PendingMemberOfGroupSelector = (state) => state.toJS().groups.pendingRequest;
const searchGroupMembersAPI = ({ group_id, searchText, start, limit = 10 }) => excaliburApi.get(`searchGroupMembers/${group_id}?qs=${searchText}`, { start, limit });

function* createGroup({ payload }) {
  try {
    const response = yield call(createGroupAPI, payload);
    const noonText = yield select(noonTextSelector);
    if (response.data.success) {
      yield put({
        type: CREATE_GROUP.SUCCESS,
        payload: isEmpty(response.data.data) ? {} : response.data.data[0],
      });
      if (response.data.message) {
        addToast(translationText(noonText, `success.${response.data.message}`), TOAST_TYPE.SUCCESS);
      }
    } else {
      yield put({ type: CREATE_GROUP.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: CREATE_GROUP.FAILURE, payload: err });
  }
}

function* groupMetrics({ payload }) {
  try {
    const response = yield call(groupMetricsApi, payload);
    if (response.ok) {
      yield put({
        type: GROUP_METRICS.SUCCESS,
        payload: isEmpty(response.data.data) ? {} : response.data.data,
      });
    } else {
      yield put({ type: GROUP_METRICS.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: GROUP_METRICS.FAILURE, payload: err });
  }
}

function* updateGroup({ payload }) {
  try {
    const response = yield call(updateGroupAPI, payload);
    if (response.ok) {
      yield put({
        type: UPDATE_GROUP.SUCCESS,
        payload,
      });
    } else {
      yield put({ type: UPDATE_GROUP.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: UPDATE_GROUP.FAILURE, payload: err });
  }
}

function* getStudentRecommendation({ payload }) {
  try {
    const response = yield call(getStudentRecommendationAPI, payload);
    if (response.data.success) {
      yield put({
        type: GET_RECOMMENDED_USERS_FOR_GROUP.SUCCESS,
        payload: isEmpty(response.data.data)
          ? { list: [], start: -1, count: 0 }
          : {
            list: response.data.data,
            start: payload.start + payload.limit || 10,
            total: response.data.meta ? response.data.meta.total : 0,
          },
      });
    } else {
      yield put({ type: GET_RECOMMENDED_USERS_FOR_GROUP.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: GET_RECOMMENDED_USERS_FOR_GROUP.FAILURE, payload: err });
  }
}

function* getGroups({ payload }) {
  try {
    const response = yield call(getGroupsAPI, payload);
    if (response.data.success) {
      yield put({
        type: GET_GROUPS.SUCCESS,
        payload: isEmpty(response.data.data)
          ? { list: [], meta: { total: 0, start: 0 }, isLoading: false, start: -1 }
          : {
            list: payload.product_id ? response.data.data.filter((item) => item.publish) : response.data.data,
            meta: { total: response.data.meta.total, start: payload.start + 10, limit: 10 },
            isLoading: false,
            start: payload.start,
          },
      });
    } else {
      yield put({ type: GET_GROUPS.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: GET_GROUPS.FAILURE, payload: err });
  }
}

function* getGroupDetails({ payload }) {
  try {
    const response = yield call(getGroupDetailsAPI, payload);
    if (response.data.success) {
      yield put({
        type: GET_GROUP_DETAILS.SUCCESS,
        payload: isEmpty(response.data.data) ? {} : response.data.data[0],
      });
    } else {
      yield put({ type: GET_GROUP_DETAILS.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: GET_GROUP_DETAILS.FAILURE, payload: err });
  }
}

function* acceptRequest({ payload }) {
  const noonText = yield select(noonTextSelector);
  const { allUserIds } = yield select(GroupMemberSelector);
  const groupMembers = yield select(groupMembersSelector);
  const pendingMembers = yield select(PendingMemberSelector);
  const pendingMembersOfGroup = yield select(PendingMemberOfGroupSelector);
  try {
    const response = yield call(acceptRequestAPI, payload);
    if (response.data.success) {
      addToast(translationText(noonText, 'label.requestAccepted'), TOAST_TYPE.SUCCESS);
      yield put({
        type: GROUP_ACCEPT_REQUEST.SUCCESS,
        payload: payload.users_array[0],
      });
      const groupMembersIdMap = yield select(groupMembersListMapSelector);
      payload.users_array.forEach((id) => {
        Object.assign(groupMembersIdMap, { [id]: true });
      });
      yield put({
        type: GROUP_MEMBERS_MAP,
        payload: groupMembersIdMap,
      });

      const remainingMembers = pendingMembers.list.filter((member) => payload.users_array.indexOf(member.id) === -1);
      const remainingMembersOfGroup = pendingMembersOfGroup.list.filter((member) => payload.users_array.indexOf(member.id) === -1);
      const addedMembers = pendingMembersOfGroup.list.filter((member) => payload.users_array.indexOf(member.id) !== -1);
      const ids = addedMembers.map(({ id }) => id);
      yield put({
        type: GROUP_MEMBERS.SUCCESS,
        payload: { list: [...groupMembers, ...addedMembers], allUserIds: [...allUserIds, ...ids], start: 0 },
      });
      yield put({
        type: GROUP_ALL_PENDING_REQUEST.SUCCESS,
        payload: {
          ...pendingMembers, list: remainingMembers },
      });
      yield put({
        type: GROUP_PENDING_REQUEST.SUCCESS,
        payload: {
          data: remainingMembersOfGroup,
          meta: pendingMembersOfGroup.meta,
          start: 0,
        },
      });
    } else {
      yield put({ type: GROUP_ACCEPT_REQUEST.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    console.log(err);
    yield put({ type: GROUP_ACCEPT_REQUEST.FAILURE, payload: err });
  }
}

function* acceptRequestFromNotifications({ payload }) {
  try {
    const response = yield call(acceptRequestAPI, payload);
    if (response.data.success) {
      yield put({
        type: GROUP_ACCEPT_REQUEST_FROM_NOTIFICATIONS.SUCCESS,
        payload: { user_id: payload.users_array[0], group_id: payload.group_id },
      });
    } else {
      yield put({ type: GROUP_ACCEPT_REQUEST_FROM_NOTIFICATIONS.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: GROUP_ACCEPT_REQUEST_FROM_NOTIFICATIONS.FAILURE, payload: err });
  }
}

function* rejectRequestFromNotifications({ payload }) {
  try {
    const response = yield call(removeMemberAPI, payload);
    if (response.data.success) {
      yield put({
        type: GROUP_REMOVE_REQUEST_FROM_NOTIFICATIONS.SUCCESS,
        payload: { user_id: payload.users_array[0], group_id: payload.group_id },
      });
    } else {
      yield put({ type: GROUP_REMOVE_REQUEST_FROM_NOTIFICATIONS.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: GROUP_REMOVE_REQUEST_FROM_NOTIFICATIONS.FAILURE, payload: err });
  }
}

function* getGroupMembers({ payload }) {
  try {
    const response = yield call(getGroupMembersForTeacherAPI, payload);
    if (response.data.success) {
      const { data, meta } = response.data;
      if (meta && meta.total <= 0) {
        yield put({
          type: GROUP_MEMBERS.SUCCESS,
          payload: {
            list: data,
            meta,
          },
        });
      } else {
        const { usersList, joinedUsers } = data[0];
        yield put({
          type: GROUP_MEMBERS.SUCCESS,
          payload: {
            list: usersList || [],
            allUserIds: joinedUsers.map((o) => o.user_id),
            start: payload.start,
            meta,
          },
        });
        const groupMembersMap = {};
        joinedUsers.forEach((list) => {
          groupMembersMap[list.user_id] = true;
        });
        yield put({
          type: GROUP_MEMBERS_MAP,
          payload: groupMembersMap,
        });
        const groupDetails = yield select(groupDetailsSelector);
        yield put({
          type: GET_GROUP_DETAILS.SUCCESS,
          payload: {
            ...groupDetails,
            joined_users: usersList,
            joined_users_count: meta && meta.total ? meta.total : 0,
          },
        });
      }
    } else {
      yield put({ type: GROUP_MEMBERS.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: GROUP_MEMBERS.FAILURE, payload: err });
  }
}

function* removeMember({ payload }) {
  try {
    const response = yield call(removeMemberAPI, payload);
    const noonText = yield select(noonTextSelector);
    if (response.data.success) {
      yield put({
        type: GROUP_REMOVE_MEMBERS.SUCCESS,
        payload: payload.users_array[0],
      });
      addToast(translationText(noonText, 'success.member_removed'), TOAST_TYPE.SUCCESS);
      const groupMembersIdMap = yield select(groupMembersListMapSelector);
      payload.users_array.forEach((id) => {
        delete groupMembersIdMap[id];
      });
      yield put({
        type: GROUP_MEMBERS_MAP,
        payload: groupMembersIdMap,
      });
      const { allUserIds, list } = yield select(GroupMemberSelector);
      yield put({
        type: GROUP_MEMBERS.SUCCESS,
        payload: {
          list: list.filter((item) => item.id !== payload.users_array[0]),
          allUserIds: allUserIds.filter((id) => id !== payload.users_array[0]),
          start: 0,
        },
      });
    } else {
      yield put({ type: GROUP_REMOVE_MEMBERS.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: GROUP_REMOVE_MEMBERS.FAILURE, payload: err });
  }
}

function* pendingRequest({ payload }) {
  try {
    const response = yield call(pendingRequestAPI, payload);
    if (response.data.success) {
      yield put({
        type: GROUP_PENDING_REQUEST.SUCCESS,
        payload: {
          data: isEmpty(response.data.data) ? [] : response.data.data,
          meta: response.data.meta,
          start: payload.start,
        },
      });
    } else {
      yield put({ type: GROUP_PENDING_REQUEST.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: GROUP_PENDING_REQUEST.FAILURE, payload: err });
  }
}

function* allPendingRequest({ payload }) {
  try {
    const response = yield call(allPendingRequestAPI, payload);
    if (response.data.success) {
      yield put({
        type: GROUP_ALL_PENDING_REQUEST.SUCCESS,
        payload: {
          list: isEmpty(response.data.data) ? [] : response.data.data,
          meta: response.data.meta,
          start: payload.start,
          next_start: payload.start + 20,
        },
      });
    } else {
      yield put({ type: GROUP_ALL_PENDING_REQUEST.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: GROUP_ALL_PENDING_REQUEST.FAILURE, payload: err });
  }
}

function* blockUserList({ payload }) {
  try {
    yield put({
      type: BLOCK_USER_LIST.LOADING,
      payload: { isLoading: payload.group_id },
    });
    const response = yield call(blockUserListAPI, payload);
    if (response.data.success) {
      yield put({
        type: BLOCK_USER_LIST.SUCCESS,
        payload: {
          data: isEmpty(response.data.data) ? [] : response.data.data,
          start: payload.start,
          group_id: payload.group_id,
        },
      });
      const blockListMap = {};
      response.data.data.forEach((list) => {
        blockListMap[list.id] = true;
      });
      yield put({
        type: BLOCK_USER_LIST_MAP,
        payload: blockListMap,
      });
    } else {
      yield put({ type: BLOCK_USER_LIST.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: BLOCK_USER_LIST.FAILURE, payload: err });
  } finally {
    yield put({
      type: BLOCK_USER_LIST.LOADING,
      payload: 0,
    });
  }
}

function* blockUser({ payload }) {
  try {
    const noonText = yield select(noonTextSelector);
    yield put({
      type: BLOCK_USER.LOADING,
      payload: { isLoading: payload.user_id },
    });
    const response = yield call(blockUserAPI, payload);
    const success = response.data && response.data.success;
    if (success) {
      yield put({
        type: BLOCK_USER.SUCCESS,
        payload: isEmpty(response.data.data) ? [] : response.data.data,
      });

      addToast(translationText(noonText, 'post.block_successfull'), TOAST_TYPE.SUCCESS);
      const blockList = yield select(blockListMapSelector);
      Object.assign(blockList, { [payload.user_id]: true });
      yield put({
        type: BLOCK_USER_LIST_MAP,
        payload: blockList,
      });
      const groupMembersIdMap = yield select(groupMembersListMapSelector);
      delete groupMembersIdMap[payload.user_id];
      yield put({
        type: GROUP_MEMBERS_MAP,
        payload: groupMembersIdMap,
      });

      yield put({ type: BLOCK_USER_LIST.REQUEST, payload });
      const { allUserIds, list } = yield select(GroupMemberSelector);
      yield put({
        type: GROUP_MEMBERS.SUCCESS,
        payload: {
          list: list.filter((item) => item.id !== payload.user_id),
          allUserIds: allUserIds.filter((id) => id !== payload.user_id),
          start: 0,
        },
      });
    } else {
      addToast(translationText(noonText, `${response.data.message}`), TOAST_TYPE.ERROR);
      yield put({ type: BLOCK_USER.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    addToast('Network Error', TOAST_TYPE.ERROR);
    yield put({ type: BLOCK_USER.FAILURE, payload: err });
  } finally {
    yield put({
      type: BLOCK_USER.LOADING,
      payload: 0,
    });
  }
}

function* unBlockUser({ payload }) {
  try {
    yield put({
      type: UN_BLOCK_USER.LOADING,
      payload: { isLoading: payload.user_id },
    });
    const response = yield call(unBlockUserAPI, payload);
    if (response.data.success) {
      const noonText = yield select(noonTextSelector);
      yield put({
        type: UN_BLOCK_USER.SUCCESS,
        payload: isEmpty(response.data.data) ? [] : response.data.data,
      });
      const blockList = yield select(blockListMapSelector);
      delete blockList[payload.user_id];
      yield put({
        type: BLOCK_USER_LIST_MAP,
        payload: blockList,
      });

      const groupMembers = yield select(groupMembersSelector);
      const blockedList = [...(yield select(blockedUsersSelector))];
      const indexOfUnblockedUser = blockedList.findIndex((member) => member.id === payload.user_id);
      const { allUserIds } = yield select(GroupMemberSelector);
      if (payload.join_group) {
        const groupMembersIdMap = yield select(groupMembersListMapSelector);
        Object.assign(groupMembersIdMap, { [payload.user_id]: true });
        yield put({
          type: GROUP_MEMBERS_MAP,
          payload: groupMembersIdMap,
        });
        const unBlockedMember = blockedList[indexOfUnblockedUser];
        yield put({
          type: GROUP_MEMBERS.SUCCESS,
          payload: { list: [...groupMembers, unBlockedMember], allUserIds: [...allUserIds, payload.user_id], start: 0 },
        });
        addToast(translationText(noonText, 'post.unblock_and_added_to_the_group'), TOAST_TYPE.SUCCESS);
      } else {
        addToast(translationText(noonText, 'post.unblock_successful'), TOAST_TYPE.SUCCESS);
      }
      blockedList.splice(indexOfUnblockedUser, 1);
      yield put({
        type: BLOCK_USER_LIST.SUCCESS,
        payload: { data: blockedList, start: 0 },
      });
    } else {
      yield put({ type: UN_BLOCK_USER.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: UN_BLOCK_USER.FAILURE, payload: err });
  } finally {
    yield put({
      type: UN_BLOCK_USER.LOADING,
      payload: 0,
    });
  }
}
function* searchGroupMembers({ payload }) {
  try {
    const response = yield call(searchGroupMembersAPI, payload);
    if (response.data.success) {
      yield put({
        type: SEARCH_GROUP_MEMBERS.SUCCESS,
        payload: isEmpty(response.data.data) ? {} : response.data.data,
      });
    } else {
      yield put({ type: SEARCH_GROUP_MEMBERS.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: SEARCH_GROUP_MEMBERS.FAILURE, payload: err });
  }
}

export function* groupsSaga() {
  yield all([
    takeEvery(GROUP_ACCEPT_REQUEST_FROM_NOTIFICATIONS.REQUEST, acceptRequestFromNotifications),
    takeEvery(GROUP_REMOVE_REQUEST_FROM_NOTIFICATIONS.REQUEST, rejectRequestFromNotifications),
    takeEvery(CREATE_GROUP.REQUEST, createGroup),
    takeEvery(UPDATE_GROUP.REQUEST, updateGroup),
    takeEvery(GET_GROUPS.REQUEST, getGroups),
    takeEvery(GET_GROUP_DETAILS.REQUEST, getGroupDetails),
    takeEvery(GROUP_MEMBERS.REQUEST, getGroupMembers),
    takeEvery(GROUP_ACCEPT_REQUEST.REQUEST, acceptRequest),
    takeEvery(GROUP_REMOVE_MEMBERS.REQUEST, removeMember),
    takeEvery(GROUP_PENDING_REQUEST.REQUEST, pendingRequest),
    takeEvery(GROUP_ALL_PENDING_REQUEST.REQUEST, allPendingRequest),
    takeEvery(BLOCK_USER.REQUEST, blockUser),
    takeEvery(UN_BLOCK_USER.REQUEST, unBlockUser),
    takeEvery(BLOCK_USER_LIST.REQUEST, blockUserList),
    takeEvery(GROUP_METRICS.REQUEST, groupMetrics),
    takeEvery(GET_RECOMMENDED_USERS_FOR_GROUP.REQUEST, getStudentRecommendation),
    takeEvery(SEARCH_GROUP_MEMBERS.REQUEST, searchGroupMembers),
  ]);
}

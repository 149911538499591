import React from 'react';
import { Card, Column } from '@noon/atom';
import { translationType } from '../../../types';
import { translationText } from '../../../helpers';

function EmptyStateNoUpcomingActivities({ noonText }) {
  return (
    <Card className="planner-empty-card">
      <Column flex="1" gap justify="center" align="center">
        <div className="planner-empty-card__image text-center">
          <img src="/assets/images/2020/empty-state-no-group.png" alt="Empty Screen" />
        </div>
        <span className="child">{translationText(noonText, 'planner.emptyNoUpcomingActivity')}</span>
      </Column>
    </Card>
  );
}

EmptyStateNoUpcomingActivities.propTypes = {
  noonText: translationType.isRequired,
};

EmptyStateNoUpcomingActivities.defaultProps = {
};
export default EmptyStateNoUpcomingActivities;

/* eslint-disable no-undef */
// import AgoraRTC from 'agora-rtc-sdk-ng';
import EventEmitter from 'events';
import { isIOS, isMobile, isSafari } from 'react-device-detect';
import EM from '../../providers/Event/EM';
import { CLASSROOM_EVENT } from '../../components/Classroom/events';
import AGORA_RTC_ERRORS from './agoraRtcErrors';

const AUDIO_STATS_INTERVAL = 2000; // in ms
const RETRY_PUBLISH_AUDIO_THRESHOLD = 3;

export default class AgoraRtcV4 extends EventEmitter {

    joined = false; // Joined Channel Flag

    localAudio = null; // localstream instance

    remoteStreams = []; // remote stream list

    isMute = true; // mute status

    client = null;

    downlinkQuality = 0;

    uplinkQuality = 0;

    localVideo = null;

    isVideoMute = true;

    constructor({ connectionDetails, connectionStatus, streamStatus, onStreamUpdated, changeMicStatus, changeVideoStatus, handleOnCameraChanged, handleOnMicrophoneChanged, onError, updateNetworkStats, options = {} }) {
      super();

      if (options.enableLogUpload) AgoraRTC.enableLogUpload();
      AgoraRTC.setLogLevel(3 || options.logLevel); // 3 : error

      // proxy setting change to force tcp
      if (options.enableProxy) {
        console.log('RTC: force tcp enabled');
        AgoraRTC.setParameter('FORCE_TURN_TCP', true);
      }

      const clientConfig = {
        mode: options.mode || 'rtc',
        codec: (isIOS || isSafari || isMobile) ? 'vp8' : (options.codec || 'h264'),
      };

      // set server region
      if (options.serverRegion) {
        console.log('RTC Area code : ', options.serverRegion);
        AgoraRTC.setArea({
          areaCode: options.serverRegion,
        });
      }

      this.client = AgoraRTC.createClient(clientConfig);

      // if (options.enableProxy) {
      //   console.log('RTC Proxy : Enabled');
      //   this.client.startProxyServer(3);
      // }
      console.log('RTC Version : ', AgoraRTC.VERSION);

      this.connectionDetails = connectionDetails;
      this.connectionStatus = connectionStatus;
      this.streamStatus = streamStatus;
      this.changeMicStatus = changeMicStatus;
      this.onError = onError;
      this.onStreamUpdated = onStreamUpdated;
      this.options = options;
      this.changeVideoStatus = changeVideoStatus;
      this.handleOnCameraChanged = handleOnCameraChanged;
      this.handleOnMicrophoneChanged = handleOnMicrophoneChanged;
      this.updateNetworkStats = updateNetworkStats;
    }

    init = async () => {
      const { channel } = this.connectionDetails;

      try {
        await this.joinChannel(channel);
        this.initializeListeners();
      } catch (e) {
        this.joinChannelFailure(e);
      }
    }

    joinChannel = async (channel) => {
      const { appId, token, uid } = this.connectionDetails;
      return this.client.join(appId, channel, token, Number(uid));
    }

    leaveChannel = async () => {
      if (this.joined) {
        await this.client.leave();
        this.joined = false;
      }
    }

    joinChannelFailure = (err) => {
      this.onError(AGORA_RTC_ERRORS[102], err);
    }

    initializeListeners = () => {
      this.joined = true;
      this.listenConnectionStateChange();
      this.listenUserPublished();
      this.listenUserUnpublished();
      this.listenUserInfo();
      this.listenNetworkQuality();
      this.listenAudioTrackEnded();
      this.listenVolumeChange();
      this.globalListners();
      if (this.options.enableStats) this.emitLocalStats();
      this.connectionStatus({ curState: 'CONNECTED', prevState: 'INITIATED' });
    }

    globalListners = () => {
      AgoraRTC.onMicrophoneChanged = (info) => {
        switch (info.state) {
          case 'ACTIVE':
            this.handleOnMicrophoneChanged(info);
            // this.enableAudioStream();
            break;
          case 'INACTIVE':
            this.handleOnMicrophoneChanged(info);
            // this.disableAudioStream();
            // if (this.localAudio) {
            //   this.localAudio.close();
            //   this.localAudio = null;
            // }
            break;
          default:
        }
        console.log('microphone changed!', info.state, info.device);
      };

      AgoraRTC.onCameraChanged = (info) => {
        switch (info.state) {
          case 'ACTIVE':
            this.handleOnMicrophoneChanged(info);
            // this.enableVideoStream();
            break;
          case 'INACTIVE':
            this.handleOnCameraChanged(info);
            // this.disableVideoStream();
            // if (this.localVideo) {
            //   this.localVideo.close();
            //   this.localVideo = null;
            // }
            break;
          default:
        }
        console.log('camera changed!', info.state, info.device);
      };
      AgoraRTC.onPlaybackDeviceChanged = (info) => {
        console.log('speaker changed!', info.state, info.device);
      };
      this.client.on('is-using-cloud-proxy', (isUsingProxy) => {
        console.log('is-using-cloud-proxy', isUsingProxy ? 'true' : 'false');
      });
      this.client.on('exception', (event) => {
        console.log('is-exception-cloud-proxy', event);
      });
    }

    emitLocalStats = async () => {
      const audioStatsKeysToManipulate = ['sendVolumeLevel', 'sendBitrate', 'sendVolumeLevel'];
      this.localAudioStatsInterval = setInterval(() => {
        const statsData = this.client.getLocalAudioStats();
        let data = [];
        data.push(Date.now());
        data.push(this.isMute ? 1 : 0);
        if (statsData) {
          data = [...data, ...audioStatsKeysToManipulate.map((key) => statsData[key])];
        } else {
          data = [...data, '', '', ''];
        }
        data.push(this.uplinkQuality);
        data.push(this.downlinkQuality);
        data.push(this.isVideoMute ? 0 : 1);
        this.emit('localAudioStats', data);
      }, AUDIO_STATS_INTERVAL);
    };

    listenUserInfo = () => {
      this.client.on('user-info-updated', (uid, msg) => {
        if (msg === 'mute-audio') {
          this.remoteStreams = [...this.remoteStreams.map((stream) => {
            if (stream.uid !== uid) {
              return { ...stream, mute: true };
            }
            return stream;
          })];
          this.onStreamUpdated(this.remoteStreams);
        }
        if (msg === 'unmute-audio') {
          this.remoteStreams = [...this.remoteStreams.map((stream) => {
            if (stream.uid !== uid) {
              return { ...stream, mute: false };
            }
            return stream;
          })];
          this.onStreamUpdated(this.remoteStreams);
        }
      });
    }

    listenNetworkQuality = () => {
      this.client.on('network-quality', (data) => {
        this.uplinkQuality = data.uplinkNetworkQuality;
        this.downlinkQuality = data.downlinkNetworkQuality;
        if (this.updateNetworkStats) this.updateNetworkStats(data);
      });
    }

    listenConnectionStateChange = () => {
      this.client.on('connection-state-change', (curState, prevState) => {
        this.connectionStatus({ curState, prevState });
      });
    }

    listenAudioTrackEnded = () => {
      this.client.on('audio-track-ended', (err) => {
        this.onError(AGORA_RTC_ERRORS[108], err);
      });
    }

    listenUserPublished = () => {
      this.client.on('user-published', async (user, mediaType) => {
        try {
          await this.client.subscribe(user, mediaType);
          this.remoteStreams = [...this.remoteStreams, { user, uid: user.uid, id: user.uid, mediaType, mute: false }];
          this.onStreamUpdated(this.remoteStreams);
          if (mediaType === 'video') {
            // user.videoTrack.play('element id');
          }
          if (mediaType === 'audio') {
            user.audioTrack.play();
          }
        } catch (err) {
          this.onError(AGORA_RTC_ERRORS[107], err);
        }
      });
    }

    listenUserUnpublished = () => {
      this.client.on('user-unpublished', async (user, mediaType) => {
        try {
          if (mediaType === 'video') {
            // user.videoTrack.stop('element id');
          }
          if (mediaType === 'audio') {
            await this.client.unsubscribe(user, mediaType);
            this.remoteStreams = [...this.remoteStreams.filter((stream) => stream.uid !== user.uid)];
          }
          this.onStreamUpdated(this.remoteStreams);
        } catch (err) {
          this.onError(AGORA_RTC_ERRORS[107], err);
        }
      });
    }

    publishAudioStream = async (retryCount) => {
      if (retryCount < 1) {
        this.changeMicStatus(false);
        this.isMute = true;
        this.onError(AGORA_RTC_ERRORS[105]);
        return;
      }
      // Publish the Local Audio stream
      try {
        await this.client.publish(this.localAudio);
        this.changeMicStatus(true);
        this.isMute = false;
      } catch (err) {
        this.onError(AGORA_RTC_ERRORS[104], err);
        this.publishAudioStream(retryCount - 1);
      }
    }

    enableAudioStream = async () => {
      if (this.localAudio) {
        this.localAudio.setMuted(false);
        this.changeMicStatus(true);
        this.isMute = false;
        return;
      }

      try {
        this.localAudio = await AgoraRTC.createMicrophoneAudioTrack();
        if (this.options && this.options.mode === 'live') {
          await this.client.setClientRole('host');
        }
        this.publishAudioStream(RETRY_PUBLISH_AUDIO_THRESHOLD);
      } catch (err) {
        if (err?.code === 'PERMISSION_DENIED') {
          this.onError(AGORA_RTC_ERRORS[109], err);
        } else {
          this.onError(AGORA_RTC_ERRORS[103], err);
        }
      }
    }

    disableAudioStream = () => {
      if (this.localAudio) {
        this.localAudio.setMuted(true);
      }
      this.changeMicStatus(false);
      this.isMute = true;
    }

    destroyAudioStream = () => {
      if (this.localAudio) {
        this.localAudio.close();
        this.localAudio.stop();
        this.localAudio = null;
      }
    }

    listenVolumeChange = () => {
      this.client.enableAudioVolumeIndicator();
      const volumeMap = {};
      this.client.on('volume-indicator', (volumes) => {
        if (this.isMute && !this.remoteStreams.length) return;
        volumes.forEach((volume) => {
          volumeMap[volume.uid] = volume.level;
        });
        this.emit('volume-indicator', volumeMap);
      });
    }

    getAudioVolumeLevel = () => {
      if (this.localAudio) {
        return this.localAudio.getVolumeLevel();
      }
      return 0;
    }

    publishVideoStream = async (retryCount) => {
      if (retryCount < 1) {
        this.changeVideoStatus(false);
        this.isVideoMute = true;
        this.onError(AGORA_RTC_ERRORS[105]);
        return;
      }
      // Publish the Local Video stream
      try {
        await this.client.publish(this.localVideo);
        this.changeVideoStatus(true);
        this.isVideoMute = false;
        this.localVideo.play('noon-agora-video');
      } catch (err) {
        this.onError(AGORA_RTC_ERRORS[104], err);
        this.publishVideoStream(retryCount - 1);
      }
    }

    enableVideoStream = async () => {
      if (this.localVideo) {
        this.localVideo.setEnabled(true);
        this.changeVideoStatus(true);
        this.isVideoMute = false;
        if (this.isMute) {
          EM.sendMessage(CLASSROOM_EVENT.videoUnmuteFallback, {}, { stringify: false });
        }
        return;
      }

      try {
        this.localVideo = await AgoraRTC.createCameraVideoTrack({
          encoderConfig: '240p_1',
        });
        if (this.options && this.options.mode === 'live') {
          await this.client.setClientRole('host');
        }
        this.publishVideoStream(RETRY_PUBLISH_AUDIO_THRESHOLD);
      } catch (err) {
        if (err?.code === 'PERMISSION_DENIED') {
          this.onError(AGORA_RTC_ERRORS[110], err);
        } else {
          this.onError(AGORA_RTC_ERRORS[111], err);
        }
      }
    }

    disableVideoStream = () => {
      if (this.localVideo) {
        // this.client.unpublish(this.localVideo);
        this.localVideo.setEnabled(false);
        // this.localVideo.close();
        //  this.localVideo = null;
        if (this.isMute) {
          EM.sendMessage(CLASSROOM_EVENT.videoMuteFallback, {}, { stringify: false });
        }
      }
      this.changeVideoStatus(false);
      this.isVideoMute = true;
    }

    destroyVideoStream = () => {
      if (this.localVideo) {
        this.localVideo.close();
        this.localVideo.stop();
        this.localVideo = null;
      }
    }

    switchDevice = (type, deviceId) => {
      if (this.localVideo && type === 'video') {
        this.localVideo.getMediaStreamTrack().stop();
        this.localVideo.setDevice(deviceId);
      } else if (this.localAudio && type === 'audio') {
        this.localAudio.getMediaStreamTrack().stop();
        this.localAudio.setDevice(deviceId);
      }
    }

    async disconnect() {

      if (this.localAudioStatsInterval) clearInterval(this.localAudioStatsInterval);
      this.destroyAudioStream();
      this.destroyVideoStream();
      this.leaveChannel();
      // if (this.options.enableProxy) {
      //   this.client.stopProxyServer();
      // }
    }
}

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Button, Row } from '@noon/atom';
import { IconCorrectCircle, IconLoader } from '@noon/quark';

import t from '../../../helpers/translate';
import {
  PUBLISH_LATER_EVENT,
  PUBLISHED_PLAYBACK_EVENT,
} from '../../../redux/constants';
import { TPlaybackHeader, TPlayPublishEventPayload } from '../types';

function PlaybackHeader(props: TPlaybackHeader) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: session_id } = useParams();

  const { playbackDetails, onClose, onPublish } = props;

  const { playerData, playback_sections, autosave } = useSelector((state) => state.toJS().playback);
  const { loggedUser } = useSelector((state) => state.toJS().user);

  const chapterName : string = playbackDetails?.curriculum_tags?.chapter?.name || '';
  const { data, sections } = playback_sections;
  const { fullDuration, trimmedDuration } = playerData;

  const [deletedSlides, setDeletedSlides] = useState<number>(0);

  const fireEvent = async (eventType: string) : Promise<void> => {
    let included_sections = 0;
    sections.forEach((section) => {
      const filteredSlidesInSection = section.filter((slide) => !slide.delete).length;
      if (filteredSlidesInSection > 0) included_sections += 1;
    });

    const payload : TPlayPublishEventPayload = {
      teacher_id: loggedUser.user_id,
      session_id,
      original_duration: Math.round(fullDuration),
      trimmed_duration: Math.round(trimmedDuration),
      included_slides_count: data.length - deletedSlides,
      excluded_slides_count: deletedSlides,
      included_sections_count: included_sections,
      excluded_sections_count: sections.length - included_sections,
      playback_edited: (Math.round(fullDuration) > Math.round(trimmedDuration)) || !!deletedSlides,
      action_taken_from: history.location.search === '?fromClass' ? 'post_session' : 'playback_section',
    };

    if (eventType === 'save_exit') {
      dispatch({ type: PUBLISH_LATER_EVENT, payload });
    } else {
      dispatch({ type: PUBLISHED_PLAYBACK_EVENT, payload });
    }
  };

  const takeAction = (action:string) : void => {
    fireEvent(action);
    if (action === 'save_exit') {
      onClose('exit');
    } else {
      onPublish();
    }
  };

  useEffect(() => {
    setDeletedSlides(data.filter((slide) => slide.delete).length);
  }, [data]);

  return (
    <Row nowrap align="center" data-testid="playback-header" className={classNames('playback__header', ' plr-2')}>
      <div className={classNames('session-info')}>
        <h5 className="session-title">{playbackDetails?.title}</h5>
        {chapterName ? <p>{`Chapter: ${chapterName}`}</p> : null}
      </div>
      <Row className="secondary-area" justify="center" align="center" gap>
        <Row className="auto-saved-message" gap="sm">
          {autosave
            ? <IconLoader data-testid="icon-loader" className="child" height="24px" width="24px" fill="#31C173" stroke="#31C173" />
            : <IconCorrectCircle data-testid="icon-correct-circle" className="child" height="24px" width="24px" fill="#31C173" stroke="#31C173" />}
          <span className="child">{autosave ? t('home', 'saving') : t('playback', 'autoSaved')}</span>
        </Row>
        <Button
          type="primary"
          onClick={() => takeAction('publish')}
          >
          {t('playback', 'publish')}
        </Button>
      </Row>
    </Row>
  );
}

export default PlaybackHeader;

import { put, call, takeEvery, all, select, takeLeading } from 'redux-saga/effects';
import { difference, isEmpty } from 'lodash-es';
import { TrackSentryError, SentryType } from 'SentryAlias';
import { athenaApi, realtimeApi } from '../restApi';
import { GET_SESSION_MESSAGE,
  GET_BREAKOUT_MESSAGE,
  POST_SESSION_MESSAGE,
  POST_BREAKOUT_MESSAGE,
  GET_LEADERBOARD_API,
  TEACHER_GRANT_ACCESS,
  GET_TOTAL_MEMBER_COUNT,
  GET_BREAKOUT_REPORT,
  GET_ALL_CANVAS,
  LOAD_MORE_TEAMS,
  LOAD_BREAKOUT_DATA,
} from '../constants';
import {
  breakoutReportSelector,
  leaderboardUserSelector,
} from '../selectors/classroom';
import SlideList from '../../helpers/slideList';

const getSessionMessagesAPI = ({ sessionId, offset_count }) => athenaApi.get(`session/messages/${sessionId}`, { offset_count });
const getBreakoutMessagesAPI = ({ sessionId, groupId, offset }) => athenaApi.get(`breakout/messages/${sessionId}/${groupId}`, { offset });

const postSessionMessagesAPI = ({ sessionId, payload }) => athenaApi.post(`session/insert_message/${sessionId}`, payload);
const postBreakoutMessagesAPI = ({ sessionId, groupId, payload }) => athenaApi.get(`breakout/insert_message/${sessionId}/${groupId}`, payload);
const getLeaderboardAPI = ({ sessionId, offset }) => athenaApi.get(`teacher/session/leaderboard/${sessionId}`, { offset });
const getTotalMemberAPI = ({ sessionId }) => athenaApi.get(`teacher/session/studentCount/${sessionId}`);
const getBreakoutReportAPI = ({ sessionId, team_type, offset }) => athenaApi.get(`breakout/reporting/session/${sessionId}/team/${team_type}?limit=10&offset=${offset || 0}`);
const teacherGrantAccessRequestAPI = ({ session_id }) => realtimeApi.post('pubnub/teacher/grant_access', { session_id });
const getCanvasAPI = (sessionId) => athenaApi.get(`all_canvas/${sessionId}`);
const migrateCanvasAPI = (payload) => athenaApi.post('rearrange_canvas', payload);

function* getTeacherPubnubTokens({ session_id }) {
  try {
    const response = yield call(teacherGrantAccessRequestAPI, { session_id });
    if (response.ok) {
      yield put({
        type: TEACHER_GRANT_ACCESS.SUCCESS,
        data: response.data.data[0],
      });
    } else {
      yield put({ type: TEACHER_GRANT_ACCESS.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: TEACHER_GRANT_ACCESS.FAILURE, payload: err });
  }
}

function* getSessionMessages({ payload }) {
  const { sessionId, offset_count } = payload;
  try {
    const response = yield call(getSessionMessagesAPI, {
      sessionId,
      offset_count,
    });
    if (response.ok) {
      yield put({
        type: GET_SESSION_MESSAGE.SUCCESS,
        data: response.data.data,
        offset: response.data.meta_data.offset_count,
        isReset: !offset_count,
      });
    } else {
      yield put({ type: GET_SESSION_MESSAGE.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: GET_SESSION_MESSAGE.FAILURE, payload: err });
  }
}

function* getBreakoutMessage({ sessionId, groupId, offset }) {
  try {
    const response = yield call(getBreakoutMessagesAPI, {
      sessionId,
      groupId,
      offset,
    });
    if (response.ok) {
      yield put({
        type: GET_BREAKOUT_MESSAGE.SUCCESS,
        data: response.data,
        offset: response.meta_data.offset_count,
      });
    } else {
      yield put({ type: GET_BREAKOUT_MESSAGE.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: GET_BREAKOUT_MESSAGE.FAILURE, payload: err });
  }
}

function* postSessionMessages(payload) {
  try {
    const response = yield call(postSessionMessagesAPI, payload);
    if (response.data.success) {
      yield put({ type: POST_SESSION_MESSAGE.SUCCESS, payload: response.data.message });
    } else {
      yield put({ type: POST_SESSION_MESSAGE.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: POST_SESSION_MESSAGE.FAILURE, payload: err });
  }
}

function* postBreakoutMessage(payload) {
  try {
    const response = yield call(postBreakoutMessagesAPI, payload);
    if (response.data.success) {
      yield put({ type: POST_BREAKOUT_MESSAGE.SUCCESS, payload: response.data.message });
    } else {
      yield put({ type: POST_BREAKOUT_MESSAGE.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: POST_BREAKOUT_MESSAGE.FAILURE, payload: err });
  }
}

function* getLeaderboard({ payload }) {
  const { sessionId, offset } = payload;
  try {
    const response = yield call(getLeaderboardAPI, {
      sessionId,
      offset,
    });
    if (response.ok) {
      yield put({
        type: GET_LEADERBOARD_API.SUCCESS,
        isReset: !offset,
        data: response.data.data,
        offset: response.data.meta.offset || 0,
        total: response.data.meta.total || 0,
        totalLiveUsers: response.data.meta.totalLiveUsers || 0,
      });
    } else {
      yield put({ type: GET_LEADERBOARD_API.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: GET_LEADERBOARD_API.FAILURE, payload: err });
  }
}

function* getTotalMemberCount({ payload }) {
  const { sessionId } = payload;
  const leaderBoard = yield select(leaderboardUserSelector);
  try {
    const response = yield call(getTotalMemberAPI, {
      sessionId,
    });
    if (response.ok) {
      yield put({
        type: GET_TOTAL_MEMBER_COUNT.SUCCESS,
        total: response.data.total || 0,
        totalLiveUsers: response.data.totalLiveUsers || 0,
      });
      if (leaderBoard.length < response.data.total && leaderBoard.length < 20) {
        yield put({
          type: GET_LEADERBOARD_API.REQUEST,
          payload: {
            sessionId,
          },
        });
      }
    } else {
      yield put({ type: GET_TOTAL_MEMBER_COUNT.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: GET_TOTAL_MEMBER_COUNT.FAILURE, payload: err });
  }
}

function* getBreakoutReport({ payload }) {
  try {

    // hydrate breakout report team count and breakout count from localstorage if the teacher refreshed tab
    const breakoutReportSate = yield select(breakoutReportSelector);
    const breakoutReportLS = JSON.parse(localStorage.getItem(`breakout${payload.sessionId}`) || '{}');

    if (breakoutReportLS) {
      if (breakoutReportLS.breakoutCount > breakoutReportSate.breakoutCount) {
        yield put({
          type: LOAD_BREAKOUT_DATA,
          data: breakoutReportLS,
        });
      }

    }

    // logic for featching breakout report
    const notEngagedTeams = yield call(getBreakoutReportAPI, { sessionId: payload.sessionId, team_type: 'nengaged' });
    const engagedTeams = yield call(getBreakoutReportAPI, { sessionId: payload.sessionId, team_type: 'engaged' });
    const superEngagedTeams = yield call(getBreakoutReportAPI, { sessionId: payload.sessionId, team_type: 'sengaged' });

    if (notEngagedTeams.ok || engagedTeams.ok || superEngagedTeams.ok) {

      localStorage.setItem(`breakout${payload.sessionId}`, JSON.stringify({ breakoutCount: breakoutReportSate.breakoutCount + 1 }));

      yield put({
        type: GET_BREAKOUT_REPORT.SUCCESS,
        reload: payload.reload,
        data: {
          notEngaged: notEngagedTeams.data,
          engaged: engagedTeams.data,
          superEngaged: superEngagedTeams.data,
        },
      });

    } else {
      yield put({ type: GET_BREAKOUT_REPORT.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    console.clear();
    console.error(err);
    yield put({ type: GET_BREAKOUT_REPORT.FAILURE, payload: err });
  }
}

function* loadMoreTeams({ payload }) {
  try {
    let teams = null;
    const breakoutReport = yield select(breakoutReportSelector);
    const { offset } = breakoutReport;
    switch (payload.team_type) {
      case 'nengaged': teams = yield call(getBreakoutReportAPI, { sessionId: payload.sessionId, team_type: 'nengaged', offset: offset.notEngaged + 1 }); break;
      case 'engaged': teams = yield call(getBreakoutReportAPI, { sessionId: payload.sessionId, team_type: 'engaged', offset: offset.engaged + 1 }); break;
      case 'sengaged': teams = yield call(getBreakoutReportAPI, { sessionId: payload.sessionId, team_type: 'sengaged', offset: offset.superEngaged + 1 }); break;
      default: return;
    }

    if (teams.ok) {
      yield put({
        type: LOAD_MORE_TEAMS.SUCCESS,
        data: {
          teams: teams.data,
          team_type: payload.team_type,

        },
      });

    } else {
      yield put({ type: LOAD_MORE_TEAMS.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    console.clear();
    console.error(err);
    yield put({ type: LOAD_MORE_TEAMS.FAILURE, payload: err });
  }
}

function* convertSlidesToNewFormat(slides, session_id) {
  // if at least 2 slides don't have prev_canvas_id property, it means slide is in old format
  if (slides.length > 1) {
    const a = [];
    const b = [];
    let isCanvasPontingToNull = false;
    slides.forEach((item) => {
      a.push(item.canvas_id);
      if (item.prev_canvas_id) {
        b.push(item.prev_canvas_id);
      } else {
        isCanvasPontingToNull = true;
      }
    });
    // Array difference should not be more than 1 for a perfect linked list (slides list)
    const arrayDiff = difference(a, b);

    // If more than one slide is pointing to null, that is a problem
    if (arrayDiff.length > 1) {
      TrackSentryError('CONVERTING SLIDES: Slides canvas id issue', { sessionId: session_id, slides: arrayDiff }, SentryType.ERROR);
    }

    // If no slide is pointing to null, that is also a problem
    if (!isCanvasPontingToNull) {
      TrackSentryError('CONVERTING SLIDES: Not pointing to null', { sessionId: session_id }, SentryType.ERROR);
    }
    const slideList = new SlideList();
    if (arrayDiff.length > 1 || (!slides[0].prev_canvas_id && !slides[1].prev_canvas_id) || !isCanvasPontingToNull) {
      // Resetting order of slides based on the order in which response is received
      for (const key in slides) {
        if (slides[key]) {
          const prevCanvasId = slides[Number(key) - 1] ? slides[Number(key) - 1].canvas_id : null;
          slideList.add({ ...slides[key], prev_canvas_id: prevCanvasId });
        }
      }
      const convertedSlides = slideList.toArray();
      const data = convertedSlides.map((slide) => ({ canvas_id: slide.canvas_id, prev_canvas_id: slide.prev_canvas_id }));
      const response = yield call(migrateCanvasAPI, { session_id, data });
      if (response.ok) {
        return convertedSlides;
      }
      return slides;
    }
    // This is sorting the slides according to their previous_canvas_id property
    slideList.fromArray(slides);
    return slideList.toArray();
  }
  return slides;
}

function* getAllCanvas({ session_id }) {
  try {
    const response = yield call(getCanvasAPI, session_id);
    if (response.ok) {
      const slides = yield convertSlidesToNewFormat(response.data.data, session_id);
      yield put({
        type: GET_ALL_CANVAS.SUCCESS,
        data: slides,
        maxSlideNumber: response.data.max_slide_number,
      });
    } else {
      yield put({ type: GET_ALL_CANVAS.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: GET_ALL_CANVAS.FAILURE, payload: err });
  }
}

export function* classroom() {
  yield all([
    takeEvery(GET_SESSION_MESSAGE.REQUEST, getSessionMessages),
    takeEvery(GET_BREAKOUT_MESSAGE.REQUEST, getBreakoutMessage),
    takeEvery(POST_SESSION_MESSAGE.REQUEST, postSessionMessages),
    takeEvery(POST_BREAKOUT_MESSAGE.REQUEST, postBreakoutMessage),
    takeEvery(GET_LEADERBOARD_API.REQUEST, getLeaderboard),
    takeEvery(TEACHER_GRANT_ACCESS.REQUEST, getTeacherPubnubTokens),
    takeEvery(GET_TOTAL_MEMBER_COUNT.REQUEST, getTotalMemberCount),
    takeEvery(GET_ALL_CANVAS.REQUEST, getAllCanvas),
    takeEvery(GET_BREAKOUT_REPORT.REQUEST, getBreakoutReport),
    takeLeading(LOAD_MORE_TEAMS.REQUEST, loadMoreTeams),
  ]);
}

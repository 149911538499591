import React from 'react';

import { Button, Modal } from '@noon/atom';

import { TPlaybackDetailsErrorModal } from '../types';

function PlaybackDetailsErrorModal(props : TPlaybackDetailsErrorModal) {
  const defaultMessage = 'We could not fetch details related to playback';
  const { retry, retries, onExit, customMessage = defaultMessage } = props;

  return (
    <Modal>
      <div className="modal-body mt-2 text-center">
        <span>{customMessage}</span>
      </div>
      <footer className="modal-footer mt-1">
        {retries < 4 && (
          <Button type="primary" onClick={retry}>
            Retry again
          </Button>
        )}
        <Button onClick={onExit}>Exit Playback</Button>
      </footer>
    </Modal>
  );
}

export default PlaybackDetailsErrorModal;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash-es/isEmpty';
import { IconEdit, IconLoader, IconUserUpload } from '@noon/quark';
import classNames from 'classnames';
import { Avatar, Column, Flex, Button, Row, Textarea, Input } from '@noon/atom';
import { PROFILE, UPLOAD_FILE } from '../../redux/constants';
import { translationText } from '../../helpers';
import { addToast, TOAST_TYPE } from '../../components/Toast';
import { IMAGE_ACCEPT_TYPES } from '../../constants';
import { userType } from '../../types';
import PageHeader from '../../components/Layout/pageHeader';

class Profile extends Component {
  static propTypes = {
    user: userType.isRequired,
    profile: PropTypes.shape(),
    uploadImage: PropTypes.func.isRequired,
    uploadImageUrls: PropTypes.shape(),
    noonText: PropTypes.shape().isRequired,
    fileError: PropTypes.shape().isRequired,
    updateUser: PropTypes.func.isRequired,
  };

  static defaultProps = {
    profile: {},
    uploadImageUrls: {},
  };

  constructor(props) {
    super(props);

    this.submitBtnRef = React.createRef();
    const { user } = props;

    const initialUserState = {
      id: user.id || 0,
      name: user.name || '',
      profile_pic: user.profile_pic || '',
      email: '',
      about_me: user.about_me || '',
    };
    this.initialUserState = { ...initialUserState };
    this.state = {
      user: { ...initialUserState },
      formErrors: {
        name: '',
        about_me: '',
      },
      isUploading: false,
      showModalFor: '',
      profileLoading: false,
    };
  }

  componentDidMount() {
    this.addEventListener();
  }

  componentWillReceiveProps({ user, profile, uploadImageUrls, fileError }) {
    if (!this.state.user.id) {
      // populate data once
      this.setState({
        user: {
          id: user.id,
          email: user.get_email ? '' : user.email,
          name: user.name,
          profile_pic: user.profile_pic,
          update_email: user.get_email,
          about_me: user.about_me,
        },
      });
    }

    if (!isEmpty(profile) && profile !== this.props.profile) {
      this.setState({
        profileLoading: profile.loading,
      });
      if (profile.success && !this.props.profile.success) {
        const { noonText } = this.props;
        addToast(translationText(noonText, 'success.userUpdated'), TOAST_TYPE.SUCCESS);
      }
    }

    if (!isEmpty(uploadImageUrls) && uploadImageUrls !== this.props.uploadImageUrls) {
      const updatedUser = this.state.user;
      updatedUser.profile_pic = uploadImageUrls.thumbnail_url || uploadImageUrls.small_url;
      this.setState({ user: updatedUser, isUploading: false });
    }
    if (!isEmpty(fileError) && fileError.uploadImageUrls !== this.props.fileError.uploadImageUrls) {
      this.setState({ isUploading: false });
      addToast('Network Error', TOAST_TYPE.ERROR);
    }
  }

  fileChangedHandler = (e) => {
    // User cancelled
    const { user } = this.state;
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const fd = new FormData();
    fd.append('destination', 'user_profile_pic');
    fd.append('user_id', user.id);
    const nameSplit = file.name.split('.');
    const ext = nameSplit[nameSplit.length - 1];
    const originalName = nameSplit[0];
    fd.append('file_name', `${originalName}_${user.email}.${ext}`);
    fd.append('fileUpl', file, file.name);
    this.setState({ isUploading: true });
    this.props.uploadImage(fd);
    e.preventDefault();
  };

  handleUserInput = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({ user: { ...prevState.user, [name]: value } }));
    e.preventDefault();
  };

  addEventListener() {
    document.addEventListener('mousedown', this.handleClick);
  }

  removeEventListener() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  saveProfile = (ev) => {
    ev.preventDefault();
    const { user } = this.state;

    this.props.updateUser({
      name: user.name,
      about_me: user.about_me,
      profile_pic: user.profile_pic,
    });
  };

  render() {
    const { user, showModalFor, formErrors, isUploading } = this.state;
    const { noonText } = this.props;
    return (
      <React.Fragment>
        <PageHeader title={translationText(noonText, 'profile.editHeading')} />
        <div className="container edit-profile">
          <Flex value="1" className="edit-profile__body">
            <div className={classNames('bio-edit-form')}>
              <form noValidate onSubmit={this.saveProfile}>
                <div className="outer-form-wrapper">
                  <Column
                    nowrap
                    align="center"
                    className={classNames('main-form-view', { active: showModalFor === '' })}
                    >
                    <div className="upload-image">
                      <input id="uploadPic" accept={IMAGE_ACCEPT_TYPES} type="file" onChange={this.fileChangedHandler} />
                      <label htmlFor="uploadPic">
                        {user.profile_pic ? (
                          <React.Fragment>
                            <div className="profile-icon pointer">
                              <Avatar
                                size="12rem"
                                noShadow
                                rounded
                                teacher
                                url={user.profile_pic}
                                gender={user.gender}
                                />
                              <div className="icon-wrapper">
                                <IconEdit
                                  width="12px"
                                  height="12px"
                                  gradientFill="blue"
                                  className="edit-profile"
                                />
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          <div className="upload-icon">
                            {isUploading ? <IconLoader fill="#696969" height="60px" width="60px" /> : <IconUserUpload fill="#696969" height="60px" width="60px" />}
                          </div>
                        )}
                      </label>
                    </div>

                    <Input
                      name="name"
                      defaultValue={user.name}
                      type="text"
                      autoComplete="off"
                      label={translationText(noonText, 'form.name')}
                      placeholder={translationText(noonText, 'placeholder.name')}
                      error={formErrors.name}
                      outlined
                      onChange={this.handleUserInput}
                      />
                    {!!formErrors.name && <p className="form-error">{translationText(noonText, formErrors.name)}</p>}

                    <Textarea
                      maxLength="450"
                      name="about_me"
                      defaultValue={user.about_me}
                      outlined
                      label={translationText(noonText, 'form.aboutMe')}
                      onChange={this.handleUserInput}
                      error={formErrors.about_me}
                      >
                      <span>
                        {(user.about_me) ? user.about_me.length : 0}
                        /450
                      </span>
                    </Textarea>
                    {!!formErrors.about_me && (
                      <p className="form-error">{translationText(noonText, formErrors.about_me)}</p>
                    )}

                    <Row align="center" className="submit-button-block mt-4" ref={this.submitBtnRef}>
                      <Button
                        htmlType="submit"
                        type="primary"
                        size="lg"
                        loading={this.state.profileLoading}
                        disabled={this.state.profileLoading}
                        value={translationText(noonText, 'button.updateProfile')}
                        />
                    </Row>
                    <div ref={this.submitBtnRef} />
                  </Column>
                </div>
              </form>
            </div>
          </Flex>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.toJS().user.loggedUser,
  profile: state.toJS().user.profile,
  noonText: state.toJS().translation.noonText,
  uploadImageUrls: state.toJS().file.uploadImageUrls,
  fileError: state.toJS().file.error,
});

const mapStateToDispatch = (dispatch) => ({
  updateUser: (data) => dispatch({ type: PROFILE.REQUEST, payload: data }),
  uploadImage: (data) => dispatch({ type: UPLOAD_FILE.REQUEST, payload: data }),
});

export default connect(
  mapStateToProps,
  mapStateToDispatch,
)(Profile);

export const CHECK_INVALIDATION_INTERVAL = 5; // in mins;
export const TOAST_DEFAULT_DISMISS_TIME = 5; // in seconds
export const ARCHIVE_RETRY_MAX_COUNT = 5;
export const DISABLE_CHAT_SUPPORT_PAGES = ['class', 'classroom', 'play'];
export const DO_NOT_REMOVE_TOKEN_PAGES = [];
export const LEADER_BOARD_THRESHOLD = 100;
export const CHAT_THRESHOLD = 2000;
export const STUDENT_RECORD_THRESHOLD = 50;
export const LOADING_TIMEOUT = 300; // in milli seconds
export const REQUEST_TIME = 30; // in seconds
export const ALLOWED_EVENT_GROUPS = ['classroom', 'group', 'connection'];
export const FB_ID = '1687890221530435';
export const ANDROID_APP_LINK = 'https://play.google.com/store/apps/details?id=com.noonEdu.k12App';
export const IOS_APP_LINK = 'https://itunes.apple.com/us/app/id1214874641';
export const OTHER_PROBLEMS = 'اسباب اخرى';
export const UNDERSTOOD_ANIMATION_THRESHOLD = 10;
export const NO_OF_RETRY_FOR_IMAGE_RELOAD = 2;
export const TECHNICAL_PROBLEMS = ['مساحة الرسم صغيرة', 'وقت غير كافي', 'اسباب اخرى'];
export const TEACHER_PROBLEMS = [
  'جودة الصوت',
  'يتحدث بسرعة أو ببطء/يتحدث في ضوضاء شديدة',
  'شرح سيئ او صعب /لم افهم شيئ',
  'استخدم الفاظ خارجة',
  'عصبي أوغير صبور',
  'طلب مني ان اعطه تقييم',
  'صوت المعلم غير واضح',
  'تحدث في الهاتف أثناء الشرح',
  'اسباب اخرى',
];
export const DEFAULT_SECTION_TITLE_TEXT = { ar: '--العنوان مفقود--', en: '--no title--' };
export const ALLOWED_LATEX_FOR_TEACHER_IDS = [1775947, 2561602, 2579325, 1418671, 853322, 24];
export const ALLOWED_LATEX_FOR_COUNTRY_IDS = [6];
export const ROUTE_CLASSROOM_EXIT_TO_PLAYBACK = true;
export const PLAYBACK_SLIDE_CONFIG = {
  // average_time_spent in a session is 141 secs(2.35 minutes) on prod as of 19.10.2021
  average_time_spent: 50,
  min_width: 64,
  max_width: 246,
};
export const MEAN_SLIDE_LENGTH_TO_PIXEL_RATIO = PLAYBACK_SLIDE_CONFIG.max_width / (2 * PLAYBACK_SLIDE_CONFIG.average_time_spent);
export const DELAY_PLAYBACK_CALL_BY = 6000; // 5 secs
export const SLIDE_REUSABILITY_TEACHERS = [1733162, 5519748, 3081966];
export const DEFAULT_PLAYBACK_CONFIG = {
  whitelistedTeachers: [],
  whitelistedCountries: ['SA', 'EG', 'IQ', 'IN', 'PK'],
};
export const CURRICULUM_TEMPLATE = {
  noonSuggested: {
    name: 'Noon Suggested Curriculum',
    id: 1,
  },
  custom: {
    name: 'Custom Template',
    id: 2,
  },
  previousGroup: {
    name: 'Previous Group',
    id: 3,
  },
};
export const GROUPS = [
  'poll',
  'studentLanding',
  'saudiLanding',
  'home',
  'mobileAd',
  'product',
  'tab',
  'tac',
  'button',
  'label',
  'form',
  'onboarding',
  'onboardingTeacher',
  'login',
  'mesaages',
  'error',
  'grade',
  'heading',
  'tutoring',
  'playback',
  'action',
  'navigate',
  'question',
  'groups',
  'studyGroup',
  'classroom',
  'toast',
  'notify',
  'competition',
  'difficulty',
  'social',
  'dashboard',
  'school',
  'profile',
  'teacherprofile',
  'placeholder',
  'practice',
  'navigation',
  'createHomework',
  'singup',
  'success',
  'post',
  'studyGroups',
  'activity',
  'homework',
  'teacherLanding',
  'validation',
  'tooltip',
  'teacherDashboard',
  'oneToMany',
  'metrics',
  'session',
  'emailOtpSentConfirmation',
  'leaderboard',
  'pwa',
  'searchAndFilterForm',
  'filter',
  'sessionReport',
  'teacherHomework',
  'teacherTos',
  'teacherTutoring',
  'warning',
  'whiteboard',
  'datetime',
  'agora',
  'ads',
  'tokbox',
  'teacherBreakout',
  'library',
  'coachmark',
  'groupStatus',
  'groupCurriculum',
  'tag',
  'groupMembers',
  'notification',
  'help',
  'planner',
  'scheduler',
  'createHomework',
  'homeworkReport',
  'image2question',
  'teacherRtcError',
  'teacherRtmError',
  'content',
  'breakoutReport',
  'teamQnaBreakout',
  'campaign',
];

export const LOCALES_SEO = [
  {
    key: 'ar-SA',
    value: 'sa-ar',
  },
  {
    key: 'en-SA',
    value: 'sa-en',
  },
  {
    key: 'ar-EG',
    value: 'eg-ar',
  },
  {
    key: 'en-EG',
    value: 'eg-en',
  },
  {
    key: 'ar-KW',
    value: 'kw-ar',
  },
  {
    key: 'en-KW',
    value: 'kw-en',
  },
  {
    key: 'ar-OM',
    value: 'om-ar',
  },
  {
    key: 'en-OM',
    value: 'om-en',
  },
  {
    key: 'ar-JO',
    value: 'jo-ar',
  },
  {
    key: 'en-JO',
    value: 'jo-en',
  },
  {
    key: 'ar-IQ',
    value: 'iq-ar',
  },
  {
    key: 'en-IQ',
    value: 'iq-en',
  },
  {
    key: 'en-IN',
    value: 'in-en',
  },
  {
    key: 'en-PK',
    value: 'pk-en',
  },
]; // for seo purpose

export const RTL_LOCALE = ['ar', 'ar_EG', 'ar_KW', 'ar_OM', 'ar_BH', 'ar_JO', 'ar_QA', 'ar_LB', 'ar_IQ', 'ar_AE'];
export const localesForTranslation = {
  en: 'en',
  'in-en': 'en_IN',
  'sa-en': 'en_SA',
  'eg-en': 'en_EG',
  'om-en': 'en_OM',
  'kw-en': 'en_KW',
  'jo-en': 'en_JO',
  'iq-en': 'en_IQ',
  'pk-en': 'en_PK',
  ar: 'ar',
  'sa-ar': 'ar_SA',
  'eg-ar': 'ar_EG',
  'om-ar': 'ar_OM',
  'kw-ar': 'ar_KW',
  'jo-ar': 'ar_JO',
  'iq-ar': 'ar_IQ',
}; // 'ar_YE', 'ar_LB',

export const upcomingSessionsBatchLimit = 10;
export const previousSessionsBatchLimit = 10;
export const sessionReportStudentsBatchLimit = 5;

export const DEFAULT_COUNTRY = {
  id: '1',
  name: 'Saudi Arabia',
  full_name: 'Saudi Arabia',
  locale: 'ar',
  iso_code: 'SA',
  calling_code: '+966',
  currency: 'riyal',
  flag: 'https://cdn.non.sa/images/flags/SA.gif',
  currency_sub_unit: 'halala',
  currency_symbol: '﷼',
  can_update_curriculum_country: false,
  onboarding: { sms: true, whatsapp: false, facebook: false },
  phone_validation: { start_values: ['5'], min_value: 9, max_value: 9 },
  allowed_locales: [
    { locale: 'ar', name: 'عربي' },
    { locale: 'en', name: 'EN' },
  ],
};
/* eslint-disable */
export const EMAIL_PATTERN = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
/* phont pattern by country id */
export const PHONE_PATTERN = {
  DEFAULT: /\d{9}/,
  1: /^5\d{8}$/,
};
export const COUNTRY_LOCALE_PATTERN = /^[a-z]{2}-[a-z]{2}$/;
export const ARABIC_TEXT_PATTERN = /[\u0600-\u06E0]/;
/* eslint-enable */

export const COLORS = {
  brand: {
    dark: '#2D6DD9',
    base: '#64D8AE',
    light: '#69C0FF',
    tint: '#BAE7FF',
  },
  primary: {
    dark: '#469979',
    base: '#64D8AE',
    light: '#83E0BE',
    tint: '#83E0BE',
  },
  red: {
    dark: '#B32922',
    base: '#FF3B30',
    light: '#FF6F83',
    tint: '#FFBDC6',
  },
  green: {
    dark: '#226D3C',
    base: '#31A359',
    light: '#47CC76',
    tint: '#47CC76',
  },
  orange: {
    base: '#FF703E',
    dark: '#BF542F',
    light: '#FF8D65',
    tint: '#FFE8B8',
  },
  session: {
    dark: '#4B46B3',
    base: '#6C63FF',
    light: '#96A3FF',
    tint: '#C9D9FF',
  },
  homework: {
    base: '#1BBC9C',
  },
  text: {
    base: '#74716C',
    light: '#74716C',
    dark: '#141414',
  },
  error: {
    base: '#ED2E5C',
    light: '#FDEAEF',
    dark: '#E31748',
  },
  grey: {
    base: '#333333',
    1: '#5F5F5F',
    2: '#AFAFAF',
    3: '#CCCCCC',
    4: '#DEDEDE',
    5: '#EEEEEE',
    6: '#F8F8F8',
  },
  coolGrey: {
    base: '#23294E',
    1: '#62687A',
    2: '#A6ACC2',
    3: '#C7D0EC',
    4: '#D7DEF5',
    5: '#E4ECF8',
    6: '#F2F7FF',
  },
};

export const BASIC_TRANSLATION = {
  en: {
    AM: 'AM',
    PM: 'PM',
    SHORT_AM: 'AM',
    SHORT_PM: 'PM',
    TODAY: 'Today',
    TOMMOROW: 'Tomorrow',
  },
  ar: {
    AM: 'صباحا',
    PM: 'مساءا',
    SHORT_AM: 'ص',
    SHORT_PM: 'م',
    TODAY: 'اليوم',
    TOMMOROW: 'غدا',
  },
  ar_EG: {
    AM: 'صباحا',
    PM: 'مساءا',
    SHORT_AM: 'ص',
    SHORT_PM: 'م',
    TODAY: 'اليوم',
    TOMMOROW: 'غدا',
  },
};

export const isRTL = () => document.body.dir === 'rtl';

export const BOOT_MODE = {
  DEFAULT: 'DESKTOP',
  MOBILE_APP: 'PWA',
};

export const timeParamters = {
  // structure
  y: 31536000,
  w: 604800, // uncomment row to ignore
  d: 86400, // feel free to add your own row
  h: 3600,
  m: 60,
  s: 1,
};

export const timeParamtersFull = {
  // structure
  years: 31536000,
  weeks: 604800, // uncomment row to ignore
  days: 86400, // feel free to add your own row
  hours: 3600,
  minutes: 60,
  seconds: 1,
};

export const tokboxStatsCalculation = {
  oneSprint: 15, // seconds
  afterEvery: process.env.ENV === 'production' ? 300 : 60, // seconds
  packetsLostRatio: {
    dying: 0.35,
    poor: 0.2,
    okay: 0.05,
  },
};

export const getSelectedCountry = () => {
  const { countryList, selectedCountry } = JSON.parse(localStorage.getItem('country')) || {};
  const isSelectedCountryValid = selectedCountry && selectedCountry.id && selectedCountry.allowed_locales && selectedCountry.allowed_locales.length;
  if (localStorage.loggedUser && countryList) {
    const user = JSON.parse(localStorage.loggedUser);
    const currentCountry = user.country_id
      ? countryList.filter((o) => String(o.id) === String(user.country_id))
      : countryList.filter((o) => String(o.id) === String(user.country.id));
    return currentCountry.length ? currentCountry[0] : isSelectedCountryValid ? selectedCountry : DEFAULT_COUNTRY;
  }
  return isSelectedCountryValid ? selectedCountry : DEFAULT_COUNTRY;
};

export const getCountryId = () => {
  const selectedCountry = getSelectedCountry();
  return selectedCountry.id;
};

export const getCurrentLocale = () => {
  const selectedCountry = getSelectedCountry();
  return selectedCountry.locale;
};

export const IMAGE_ACCEPT_TYPES = '.jpg, .png, .jpeg';

export const GROUP_DURATION = [
  { id: 1, value: { weeks: 1 }, display: ['1', 'weeks'] },
  { id: 2, value: { weeks: 2 }, display: ['2', 'weeks'] },
  { id: 3, value: { weeks: 4 }, display: ['4', 'weeks'] },
  { id: 4, value: { weeks: 6 }, display: ['6', 'weeks'] },
  { id: 5, value: { weeks: 8 }, display: ['2', 'months'] },
  { id: 6, value: { weeks: 12 }, display: ['3', 'months'] },
  { id: 7, value: { weeks: 16 }, display: ['4', 'months'] },
  { id: 8, value: { weeks: 20 }, display: ['5', 'months'] },
  { id: 9, value: { weeks: 24 }, display: ['6', 'months'] },
  { id: 10, value: { weeks: 28 }, display: ['7', 'months'] },
  { id: 11, value: { weeks: 32 }, display: ['8', 'months'] },
  { id: 12, value: { weeks: 36 }, display: ['9', 'months'] },
  { id: 13, value: { weeks: 40 }, display: ['10', 'months'] },
  { id: 14, value: { weeks: 44 }, display: ['11', 'months'] },
  { id: 15, value: { weeks: 48 }, display: ['12', 'months'] },
];

export const EXPERIMNET_ENTITY_TYPES = {
  USER: 'USER',
};

import React from 'react';
import Popover from 'react-popover';
import isEmpty from 'lodash-es/isEmpty';
import debounce from 'lodash-es/debounce';
import { IconBlock, IconDelete, IconLike, IconMoreOption, IconPlus, IconRemove, IconUnblock, IconUnblockAndAdd } from '@noon/quark';
import { isMobileOnly, isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Avatar, Column, Row, FormattedText, Modal, Button, NoonDate } from '@noon/atom';
import ImageWithOrientation from '../Layout/imageWithOrientation';
import { COLORS, timeParamters } from '../../constants';
import { translationText } from '../../helpers';
import Mixpanel from '../Mixpanel';

class Comment extends React.Component {
  static propTypes = {
    idx: PropTypes.number.isRequired,
    postId: PropTypes.string.isRequired,
    deleteComment: PropTypes.func.isRequired,
    comments: PropTypes.shape(),
    data: PropTypes.shape().isRequired,
    noonText: PropTypes.shape().isRequired,
    likeComment: PropTypes.func.isRequired,
  };

  static defaultProps = {
    comments: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      showCommentPopOver: false,
      popupIndex: null,
      showMore: false,
      showFullImage: false,
      likedComment: props.type === 'reply' ? props.likeReplyValue : props.data && props.data.is_liked_by_me,
      likeCount: props.data && props.data.interaction_count.likes,
      showHover: false,
    };

    this.commonActionItem = [
      { text: translationText(props.noonText, 'activity.delete'), name: 'delete', iconSize: '16px', icon: IconDelete },
    ];

    this.moreActionListBlockUser = [
      {
        name: 'block',
        text: translationText(props.noonText, 'post.block'),
        icon: IconBlock,
        fill: '#fff',
        stroke: COLORS.text.dark,
        iconSize: '20px',
      },
      {
        name: 'remove',
        text: translationText(props.noonText, 'activity.remove_user').replace('{}', props.data.user.name),
        notFromNoonText: true,
        icon: IconRemove,
        fill: '#fff',
        stroke: COLORS.text.dark,
        iconSize: '20px',
      },
    ];

    this.moreActionListForUnblockUser = [
      {
        name: 'unblock',
        text: translationText(props.noonText, 'post.unblock'),
        icon: IconUnblock,
        fill: '#fff',
        stroke: COLORS.text.dark,
        iconSize: '20px',
      },
      {
        name: 'unblockAndAdd',
        text: translationText(props.noonText, 'post.unblockAndAdd'),
        icon: IconUnblockAndAdd,
        fill: '#fff',
        stroke: COLORS.text.dark,
        iconSize: '20px',
      },
    ];

    this.showPopoverForComment = this.showPopoverForComment.bind(this);
    this.showMoreTrue = this.showMoreTrue.bind(this);
    this.hidePopOverForComment = this.hidePopOverForComment.bind(this);

    this.handleCall = debounce(this.handleCall, 1000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.likeReplyValue !== this.props.likeReplyValue) {
      this.setState({ likeComment: this.props.likeReplyValue });
    }
  }

  hidePopOverForComment() {
    this.setState({ showCommentPopOver: false });
  }

  showPopoverForComment() {
    this.setState({ showCommentPopOver: !this.state.showCommentPopOver });
  }

  handleMoreActions = (type) => {
    const {
      data: {
        id: commentId,
        user: { id: userId },
      },
      type: postType,
      deleteComment,
      blockUser,
      unblockUser,
      removeMember,
      postId,
    } = this.props;
    if (type === 'delete') {
      deleteComment(commentId, postId);
    } else if (type === 'block') {
      blockUser(userId);
    } else if (type === 'unblock' || type === 'unblockAndAdd') {
      unblockUser(userId, type === 'unblockAndAdd');
    } else if (type === 'remove') {
      removeMember(userId);
    }
    this.hidePopOverForComment();
  };

  likeComment = () => {
    this.setState((prevState) => {
      this.handleCall(this.props.data.id, this.props.postId, !prevState.likedComment);
      const likedCommentCopy = !prevState.likedComment;
      const likeCountCopy = !prevState.likedComment ? prevState.likeCount + 1 : prevState.likeCount - 1;
      /* [start] mixpanel event */
      try {
        Mixpanel.track('reaction_made', {
          reacted_to: 'comment',
          reaction_type: !prevState.likedComment ? 'upvote' : 'unvote',
          reacted_to_content: this.contentType(this.props.data),
          like_count: likeCountCopy,
          reacted_to_user: this.props.groupDetails.creator.id === this.props.data.user.id ? 'teacher' : 'student',
          post_type: this.props.data.type,
          reactor_user_id: this.props.user.id,
          reacted_to_user_id: this.props.data.user.id,
          reacted_to_content_id: this.props.data.id,
        });
      } catch (e) {
        console.log('error', e);
      }
      /* [end] mixpanel event */
      return {
        likedComment: likedCommentCopy,
        likeCount: likeCountCopy,
      };
    });
  };

  contentType = (data) => {
    const contentType = [];
    if (!isEmpty(data.files)) {
      contentType.push('images');
    }
    if (data.text) {
      contentType.push('text');
    }
    return contentType;
  };

  handleCall(id, postId, reaction) {
    this.props.likeComment(id, postId, reaction, this.props.type);
  }

  removeMember() {
    this.props.removeMember(this.props.data);
  }

  showMoreTrue() {
    this.setState({ showMore: !this.state.showMore });
  }

  showFullSizeImage = () => {
    this.setState({ showFullImage: true });
  };

  removeImage = (e) => {
    if (e.target.localName !== 'img') {
      this.setState({ showFullImage: false });
    }
  };

  getActionItemList(isSelf) {
    const {
      data: {
        user: { id },
      },
    } = this.props;
    const isGroupMember = this.props.groupMembersIdMap[id];
    const isBlockUser = this.props.blockListIdMap[id];
    if (isSelf) {
      return this.commonActionItem;
    } if (isBlockUser) {
      return [...this.commonActionItem, ...this.moreActionListForUnblockUser];
    } if (!isGroupMember) {
      return this.commonActionItem;
    }
    return [...this.commonActionItem, ...this.moreActionListBlockUser];
  }

  handleMouseEnter = (e) => {
    this.setState({ showHover: true });
    e.stopPropagation();
  };

  handleMouseLeave = (e) => {
    this.setState({ showHover: false });
    e.stopPropagation();
  };

  render() {
    const {
      data,
      idx,
      noonText,
      user,
      moreActionListBlockUser,
      moreActionListForUnblockUser,
      groupMembersIdMap,
      blockListIdMap,
      showBorder,
      correctAnswer,
      isArchived,
    } = this.props;
    const { popupIndex, showCommentPopOver, showHover } = this.state;

    const actionItems = this.getActionItemList(data.user.id === user.id);
    const popoverProps = {
      isOpen: showCommentPopOver,
      preferPlace: isMobileOnly ? 'right' : 'above',
      onOuterAction: () => this.hidePopOverForComment(),
      body: (
        <ul className="activity-popover-list vertical">
          {actionItems.map((item) => (
            <li key={item.text} onClick={() => this.handleMoreActions(item.name)} className="pointer">
              <item.icon height={item.iconSize || '16px'} fill={item.fill} stroke={item.stroke} />
              <span>{item.text}</span>
            </li>
          ))}
        </ul>
      ),
    };

    return (
      <div className="full-width incoming-comments fadeOut" key={data.id}>
        <Column
          className={classNames('full-width incoming-comments--head', {})}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          gap="sm">
          {this.state.showFullImage && (
            <React.Fragment>
              <Modal
                className="img-wrapper"
                backDropClassName="img-backdrop"
                onClick={this.removeImage}
                closeButtonCustom={(
                  <Button type="default" className="absolute" outlined fab onClick={this.removeImage}>
                    <IconPlus rotate="45" gradientFill="blue" className="no-margin" />
                  </Button>
                )}
              >
                <ImageWithOrientation className="post-image" url={data.files[0].original} />
              </Modal>
            </React.Fragment>
          )}
          <Row align="center" nowrap className="incoming-comments__inner" gap="sm">
            <Avatar
              className="incoming-comments__avatar"
              size="3rem"
              url={data.user && data.user.profile_pic}
              noShadow
              rounded
            />
            <Column align="start" justify="start" className="full-width">
              <div className="user__name">{data.user.name}</div>
              <Row gap="sm" align="start" className="full-width">
                <Column className="mt-05 full-width incoming-comments__content br-05 align-start relative">
                  <Row className="plr-2" align="center" justify="start">
                    {(data.text || !isEmpty(data.files)) && (
                    <FormattedText
                      className={classNames('comment-text fadeIn disp-flex', {
                        'flex-column': data.text.length >= 400,
                      })}
                      classNameForShowMore="mt-1"
                      text={data.text}
                      showMoreAt={400}
                      breakpoint={95}
                      largeFont="14px"
                      normalFont="14px"
                      slim
                      shorten
                      shortenMsg={
                      this.state.showMore
                        ? translationText(noonText, 'post.show_less')
                        : translationText(noonText, 'post.view_more')
                    }
                      showMoreTrue={this.showMoreTrue}
                      showMoreFlag={this.state.showMore}
                  />
                    )}
                  </Row>
                  {data.files && !!data.files.length && (
                  <div className="images" onClick={this.showFullSizeImage}>
                    <ImageWithOrientation
                      disableViewportBasedResize
                      className="post-image"
                      url={data.files[0].original}
                  />
                  </div>
                  )}
                  {!!this.state.likeCount && (
                  <React.Fragment>
                    <Row gap="sm" className="incoming-comments__content__likes" align="center">
                      <div className="incoming-comments__content__likes__info child">
                        <IconLike height="14px" width="14px" />
                        <span className="incoming-comments__content__likes__info--count">
                          {`${this.state.likeCount}`}
                        </span>
                      </div>
                      {!!this.state.likedComment && (
                      <Avatar
                        className="incoming-comments__content__likes--avatar"
                        size="28px"
                        url={user && user.profile_pic}
                        noShadow
                        rounded
                      />
                      )}
                    </Row>
                  </React.Fragment>
                  )}
                </Column>
                {((popupIndex !== null && idx === popupIndex) || !popupIndex) && (
                <Popover {...popoverProps} className="comment-popover">
                  <IconMoreOption
                    onClick={() => this.showPopoverForComment(idx, data)}
                    name="more-option"
                    width="16px"
                    height="16px"
                    fill={COLORS.text.light}
                    className={classNames('', {
                      invisible: !showHover && !isMobile,
                    })}
                />
                </Popover>
                )}
              </Row>
            </Column>
          </Row>
          <Row nowrap className="comment-reactions mlr-4" align="center">
            <span className="mlr-05">
              <NoonDate humanize value={data.created_at} format="dd LLL, hh:mm a" />
            </span>
            {!isArchived
            && (
            <React.Fragment>
              <span className="noon-dot" />
              <span
                onClick={this.likeComment}
                className={classNames('comment-reactions--like', {
                  liked: this.state.likedComment,
                })}
            >
                <IconLike fill={this.state.likedComment ? COLORS.brand.base : COLORS.text.light} height="14px" width="14px" />
                { this.state.likedComment ? 'Liked' : translationText(noonText, 'post.like')}
              </span>
            </React.Fragment>
            )}
          </Row>
        </Column>
      </div>
    );
  }
}

export default Comment;

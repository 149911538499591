import React from 'react';
import { Button, Modal, Row } from '@noon/atom';
import { browserName } from 'react-device-detect';
import t from '../../../helpers/translate';
import PrecallIcon from './pre-call-icon';

type Props = {
  onExit: () => void,
}

const TestMicPermissionFailedModal = ({ onExit }: Props) => (
  <Modal className="micPermission">
    <Row align="center">
      <PrecallIcon size="40" name="mic" />
      <Row flex={1} className="micPermission--heading">
        {t('classroom', 'permissionState1')}

        {(browserName === 'Chrome' || browserName === 'Safari') && (
        <React.Fragment>
            &nbsp;
          {browserName}
            &nbsp;
          {t('classroom', 'permissionState2')}
        </React.Fragment>
        )}
      </Row>
    </Row>

    {(browserName === 'Chrome' || browserName === 'Safari')
      ? (
        <React.Fragment>
          <Row className="micPermission--step" align="center" justify="center">
            <Row flex={1}>
              <div className="micPermission--step__stepText">
                <span>1.</span>
                {browserName === 'Chrome'
                  ? t('classroom', 'chromeBrowserStepOne')
                  : browserName === 'Safari'
                    ? t('classroom', 'safariBrowserStepOne')
                    : ''}
              </div>
            </Row>

            <Row flex={1}>
              <img
                width="100%"
                src={browserName === 'Chrome' ? '/assets/images/browser/chromeStepOne.png' : browserName === 'Safari' ? '/assets/images/browser/safariOne.png' : ''}
                alt="noon chrome" />
            </Row>
          </Row>

          <Row className="micPermission--step" align="center" justify="center">
            <Row flex={1}>
              <div className="micPermission--step__stepText">
                <span>2.</span>
                {browserName === 'Chrome'
                  ? t('classroom', 'chromeBrowserStepTwo')
                  : browserName === 'Safari'
                    ? t('classroom', 'safariBrowserStepTwo')
                    : ''}
              </div>

            </Row>
            <Row flex={1}>
              <img
                width="100%"
                src={
              browserName === 'Chrome' ? '/assets/images/browser/chromeStepTwo.png' : browserName === 'Safari' ? '/assets/images/browser/safariTwo.png' : ''
        }
                alt="noon chrome" />
            </Row>
          </Row>
        </React.Fragment>
      )
      : (
        <div className="micPermission--permissionMessage">
          {t('classroom', 'allowPermissionPrecall')}
        </div>
      )}

    <footer className="modal-footer">
      <Button size="lg" depth="2" type="secondary" onClick={() => onExit()}>
        {t('classroom', 'exitClass')}
      </Button>
    </footer>
  </Modal>
);

export default TestMicPermissionFailedModal;

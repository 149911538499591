/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Popover from 'react-popover';
import { Card, Column, Row, Button, InfiniteScroll } from '@noon/atom';
import { IconBack, IconCorrect, IconCorrectCircle, IconFilterSearch, IconLoader, IconLoaderDotted, IconMediaPlayO, IconPlus, IconQuestionFeed } from '@noon/quark';
import { get, isEmpty } from 'lodash-es';
import { groupMembersListMapSelector, blockListMapSelector } from '../../../redux/selectors/groups';
import { TOAST_TYPE, addToast, updateToast } from '../../Toast';
import QuestionAndAnswerContainer from './questionAndAnswerContainer';
import { CreateQuestion } from '.';
import ViewMoreButton from '../../Layout/viewMoreButton';
import { COLORS } from '../../../constants';
import QuestionFilter from './questionFilters';
import { translationText } from '../../../helpers';
import Shimmer from '../../Layout/shimmer';
import {
  GET_EMOJI,
  DELETE_POST,
  GROUP_REMOVE_MEMBERS,
  BLOCK_USER,
  UN_BLOCK_USER,
  UPLOAD_FILE,
  NOTIFICATION_POST_DATA,
  BULK_FILE_UPLOAD,
  UPLOAD_IMAGE_PROGRESS,
  RESET_UPLOAD_IMAGE_PROGRESS,
  CREATE_QUESTIONS_POST,
  GET_ALL_QUESTIONS_POSTS,
  GET_QUESTION_ANSWERS,
  CREATE_ANSWER,
  GET_COUNT_FOR_QUESTION_FILTER,
  DELETE_QUESTION,
  CREATE_REPLY,
  DELETE_ANSWER,
  UPVOTE_QUESTION,
  THANK_ANSWER,
  GET_ANSWERS_REPLIES,
  DELETE_REPLY,
  LIKE_REPLY,
} from '../../../redux/constants';

let filtersApplied = [
  { title: 'Most Upvotes', id: 'most_upvotes' },
  { title: 'Most Answered', id: 'most_answered' },
  { title: 'recent', id: 'recent' },
];

class QuestionAndAnswer extends Component {
  constructor(props) {
    super(props);

    filtersApplied = [
      { title: translationText(props.noonText, 'post.mostUpvoted'), id: 'most_upvotes' },
      { title: translationText(props.noonText, 'post.mostAnswered'), id: 'most_answered' },
      { title: translationText(props.noonText, 'post.recent'), id: 'recent' },
    ];
    this.questionFilters = [
      { title: translationText(props.noonText, 'tab.allQuestions'), id: 'all', filterCountId: 'total_questions' },
      { title: translationText(props.noonText, 'tab.studentQuestions'), id: 'student', filterCountId: 'student_questions' },
      { title: translationText(props.noonText, 'tab.teacherQuestions'), id: 'teacher', filterCountId: 'teacher_questions' },
      { title: translationText(props.noonText, 'tab.answeredQuestions'), id: 'answered', filterCountId: 'answered_questions' },
      { title: translationText(props.noonText, 'tab.unansweredQuestions'), id: 'unanswered', filterCountId: 'unanswered_questions' },
    ];
    this.state = {
      isUploading: 0,
      filter: filtersApplied[0],
      showFilter: false,
      createQuestionModal: false,
      filterApplied: { type: this.questionFilters[0] },
      payloadForComment: null,
      isLoadMore: false,
    };
    props.getQuestion({ group_id: props.groupId, start: 0, limit: 3 });
    props.getQuestionCounts({ groupId: props.groupId });
  }

  componentDidUpdate(preVProps, prevState) {
    const { uploadImageUrls } = this.props;
    if (
      !isEmpty(this.props.fileError)
      && this.props.fileError.uploadImageUrls !== preVProps.fileError.uploadImageUrls
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isUploading: 0 });
      addToast('Network Error', TOAST_TYPE.ERROR);
    }

    if (this.props.createQuestion.isLoading) {
      addToast(translationText(this.props.noonText, 'post.uploadingQuestion'), TOAST_TYPE.FROSTED, 8, 'center', {
        id: 591897,
        noCancelButton: true,
        icon: IconLoader,
      });
    }

    if (preVProps.createQuestion.isLoading && !this.props.createQuestion.isLoading) {
      updateToast(translationText(this.props.noonText, 'post.questionUploaded'), TOAST_TYPE.FROSTED, 3, 'center', {
        id: 591897,
        noCancelButton: true,
        icon: IconCorrectCircle,
        iconFill: '#1adc51',
        iconStroke: '#fff',
      });
    }

    if (!isEmpty(this.props.uploadImageUrls) && isEmpty(preVProps.uploadImageUrls) && this.state.isUploading) {
      this.props.sendAnswer({
        ...this.state.payloadForComment,
        files: [
          {
            file_type: 'image',
            original: uploadImageUrls.large_url,
            small: uploadImageUrls.small_url,
            medium: uploadImageUrls.medium_url,
            large: uploadImageUrls.large_url,
            thumbnail: uploadImageUrls.thumbnail_url,
          },
        ],
      });

      this.props.clearUploadFiles();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isUploading: 0,
      });
    }
  }

  handleRemoveMember = (memberId) => {
    const { groupId, removeMember } = this.props;
    removeMember({ group_id: groupId, users_array: [memberId] });
    // this.setState({ isMemberRemoving: memberId });
  };

  fileChangedHandler = (imageAdded, idForLoad) => {
    // User cancelled
    const file = imageAdded;
    if (!file) {
      return;
    }
    const fd = new FormData();
    fd.append('destination', 'posts');
    const nameSplit = file.name.split('.');
    const ext = nameSplit[nameSplit.length - 1];
    const originalName = nameSplit[0];
    fd.append('fileUpl', file, file.name);
    fd.append('file_name', `${originalName}.${ext}`);
    this.setState({ isUploading: idForLoad });
    this.props.uploadImage(fd);
  };

  handleCommentSend = (commentText, imagePreviewUrl, imageAdded, postData, markAsResolved) => {
    if (!isEmpty(commentText.trim()) && !imagePreviewUrl) {
      this.props.sendAnswer({
        text: commentText,
        questionId: postData.id,
        mark_as_resolved: markAsResolved,
      });
    } else if (imagePreviewUrl) {
      this.fileChangedHandler(imageAdded, postData.id);
    }

    this.setState({
      payloadForComment: {
        text: commentText,
        questionId: postData.id,
        mark_as_resolved: markAsResolved,
      },
    });
  };

  blockUser = (userId) => {
    this.props.blockUser({ user_id: userId, group_id: this.props.groupId });
  };

  unblockUser = (user_id, join_group) => {
    this.props.unBlockUser({ user_id, group_id: this.props.groupId, join_group });
  };

  showSinglePost = (postId) => {
    this.props.getPostDataForNotification({ id: postId });
    // eslint-disable-next-line react/no-did-update-set-state
    this.props.showSinglePost(postId);
  };

  getComments = (postId) => this.props.comments[postId];

  showAllQuestions = () => {
    this.props.handleMixpanelKafkaFeeds({
      type: 'questions_view_all',
      mixPanel: false,
    });
    this.props.showAllQuestions();
  };

  toggleFilter = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  sortOption = (filter) => {
    this.setState({ filter });
    this.setState({
      isLoadMore: false,
    });
    const chapter_id = get(this.state.filterApplied, ['chapter', 'chapter_id']);
    const topic_id = get(this.state.filterApplied, ['topic', 'topic_id']);
    const type = get(this.state.filterApplied, ['type', 'id']);
    this.props.getQuestion({
      type,
      chapter_id,
      topic_id,
      group_id: this.props.groupId,
      start: 0,
      limit: 5,
      order: filter.id,
    });
  };

  showQuestion = () => {
    this.setState({ createQuestionModal: true });
  };

  onRemoveFocus = (e) => {
    let clickedInside = false;
    let targetElement = e.target;
    do {
      if (
        (targetElement.classList && targetElement.classList.contains('create-question-post'))
        || this.clickOnPopoverBtn(e.target, 'smiley-popover')
        || this.clickOnPopoverBtn(e.target, 'questionList-popover')
      ) {
        clickedInside = true;
        break;
      }
      targetElement = targetElement.parentNode;
    } while (targetElement);
    if (clickedInside) {
      e.stopPropagation();
    } else if (this.state.createQuestionModal) {
      this.setState({ createQuestionModal: false });
    }
  };

  clickOnPopoverBtn = (target, popoverNames) => {
    let targetElement = target;
    do {
      if (targetElement.classList && targetElement.classList.contains(popoverNames)) {
        return true;
      }
      targetElement = targetElement.parentNode;
    } while (targetElement);
    return false;
  };

  filterPart = (payload) => {
    const chapter_id = get(payload, ['chapter', 'chapter_id']);
    const topic_id = get(payload, ['topic', 'topic_id']);
    const type = get(payload, ['type', 'id']);
    this.props.getQuestion({
      type,
      chapter_id,
      topic_id,
      group_id: this.props.groupId,
      start: 0,
      limit: 5,
      order: this.state.filter.id,
    });
    this.setState({ filterApplied: payload });
    this.setState({
      isLoadMore: false,
    });
  };

  loadMoreQuestions = () => {
    if (!isEmpty(this.props.question.response.list) && !this.props.question.isLoading && this.props.onlyQuestions) {
      const chapter_id = get(this.state.filterApplied, ['chapter', 'chapter_id']);
      const topic_id = get(this.state.filterApplied, ['topic', 'topic_id']);
      const type = get(this.state.filterApplied, ['type', 'id']);
      const order = get(this.state.filter, ['id']);

      const payload = {
        group_id: this.props.groupId,
        start: this.props.question.response.meta.paging.start,
        limit: this.props.question.response.meta.paging.limit,
        next_tag: this.props.question.response.meta.paging.next_tag,
        type,
        chapter_id,
        topic_id,
        order,
      };
      this.props.getQuestion(payload);
      this.setState({
        isLoadMore: true,
      });
    }
  };

  render() {
    const popoverPropsForQuestions = {
      isOpen: this.state.showFilter,
      place: 'below',
      onOuterAction: () => this.toggleFilter(),
      style: { marginTop: '-0.5rem' },
      body: (
        <Column className="question-filters filter-item-list">
          {filtersApplied.map((filter) => (
            <Row
              align="center"
              justify="space-between"
              className={classNames('ptb-05 plr-1 select-item', { selected: filter === this.state.filter })}
              onClick={() => { this.toggleFilter(); this.sortOption(filter); }}
            >
              {filter.title}
              {filter.title === this.state.filter.title
                && <IconCorrect fill={COLORS.brand.base} stroke={COLORS.brand.base} width="24px" height="24px" />}
            </Row>
          ))}
        </Column>
      ),
    };
    const {
      noonText,
      emojis,
      user,
      isRtl,
      isPostViewActive,
      groupDetails,
      history,
      singlePost,
      onlyQuestions,
      groupId,
      question,
      getAnswers,
      deleteQuestion,
      deleteAnswer,
      isArchived,
      questionCount,
    } = this.props;
    return (
      <React.Fragment>
        {onlyQuestions && <QuestionFilter groupId={this.props.groupId} filterPart={this.filterPart} questionFilters={this.questionFilters} filterApplied={this.state.filterApplied} />}
        <Card className="curriculum-content--content" onClick={this.onRemoveFocus}>
          <Column flex="1" className="full-width questions-list">
            {this.state.createQuestionModal && <div className="overlay" />}
            {this.state.createQuestionModal && (
              <CreateQuestion
                noonText={noonText}
                user={user}
                emojis={emojis}
                uploadImageProgress={this.props.uploadImageProgress}
                bulkFileUpload={this.props.bulkFileUpload}
                bulkFilesUploaded={this.props.bulkFilesUploaded}
                resetUploadImageProgress={this.props.resetUploadImageProgress}
                imageUploadProgressStatus={this.props.imageUploadProgressStatus}
                groupDetails={this.props.groupDetails}
                isBulkFileUploading={this.props.isBulkFileUploading}
                groupId={groupId}
                hideFocusOperation={() => this.setState({ createQuestionModal: false })}
                submitQuestion={this.props.submitQuestion}
                handleMixpanelKafkaFeeds={this.props.handleMixpanelKafkaFeeds}
                bulkFileUploadSuccess={this.props.bulkFileUploadSuccess}
                onlyQuestions
              />
            )}
            <Row align="center" justify="space-between" flex="1" className="card-header">
              <Row align="center" gap="sm">
                <h3 className="card-header__title">{translationText(noonText, 'groups.questions')}</h3>
                {questionCount && !questionCount.isLoading && questionCount.response.total_questions > 0
                  && (
                  <React.Fragment>
                    <span className="noon-dot" />
                    <div className="card-header__title--note">{questionCount.response.total_questions}</div>
                  </React.Fragment>
                  )}
              </Row>
              {!onlyQuestions ? (
                <ViewMoreButton handleClick={this.showAllQuestions} />
              ) : (
                !isArchived && (
                <Button type="primary" icon={IconQuestionFeed} onClick={this.showQuestion} className="plr-2 text-capitalize">
                  {translationText(noonText, 'post.newQuestion')}
                </Button>
                )
              )}
            </Row>
            {onlyQuestions && !isEmpty(this.state.filterApplied) && (this.state.filterApplied.chapter || this.state.filterApplied.type.id !== 'all') && (
              <div className="filtered--data br-0">
                <div className="left-data">
                  <p className="title">{translationText(noonText, 'filter.applied')}</p>
                </div>
                <div className="right-data">
                  {this.state.filterApplied.type.id !== 'all'
                    && (
                    <div className="list--selected-content">
                      <div className="list--selected-content--text">
                        {this.state.filterApplied.type.id}
                        <span>
                          <IconPlus
                            rotate="45"
                            width="12px"
                            height="12px"
                            fill={COLORS.coolGrey[1]}
                            stroke={COLORS.coolGrey[1]}
                            onClick={() => this.filterPart({ ...this.state.filterApplied, type: this.questionFilters[0] })}
                          />
                        </span>
                      </div>
                    </div>
                    )}
                  {this.state.filterApplied.chapter
                    && (
                    <div className="list--selected-content">
                      <div className="list--selected-content--text">
                        {this.state.filterApplied.chapter.name_header || this.state.filterApplied.chapter.name}
                        {this.state.filterApplied.topic ? ` , ${this.state.filterApplied.topic.name_header}` : ''}
                        <span>
                          <IconPlus
                            rotate="45"
                            width="12px"
                            height="12px"
                            fill="#A6ACC2"
                            onClick={() => this.filterPart({ type: this.state.filterApplied.type })}
                          />
                        </span>
                      </div>
                    </div>
                    )}
                </div>
              </div>
            )}
            {!isEmpty(question.response.list) && onlyQuestions
              && (
              <Row align="center" gap="sm" className="full-width plr-2 ptb-1" style={{ borderBottom: '1px solid #E4ECF8' }}>
                <IconFilterSearch className="child" width="18px" />
                <div>Sort by</div>
                <Popover {...popoverPropsForQuestions} className="questionList-popover feed-filter-popover">
                  <Row onClick={this.toggleFilter} justify="start" align="center" style={{ position: 'relative', color: COLORS.brand.base, cursor: 'pointer' }}>
                    <span>{this.state.filter.title}</span>
                    <IconMediaPlayO height="20px" width="20px" rotate={this.state.showFilter ? '-90' : '90'} fill={COLORS.brand.base} />
                  </Row>
                </Popover>
              </Row>
              )}
            {question.isLoading && !this.state.isLoadMore
              ? (
                <Shimmer obj={[
                  { heading: true },
                  { box: true, lines: 3 },
                  { box: true, lines: 3 },
                  { box: true, lines: 3 },
                  { box: true, lines: 3 },
                ]} />
              )
              : (
                <Column align="center" justify="center" className="plr-2 mt-2 card-body">
                  <InfiniteScroll
                    onLoad={this.loadMoreQuestions}
                    loadMore={!!question.response.meta.paging && onlyQuestions}
                    onWindow
                    context={this}
                    offset={200}
                    className="study-question__post-list full-width"
                >
                    <React.Fragment>
                      {!isEmpty(question.response.list)
                      && question.response.list.map((post, idx) => (
                        <QuestionAndAnswerContainer
                          animation="fadeIn"
                          user={user}
                          data={post}
                          currentPostIndex={post.id}
                          key={post.id}
                          index={idx}
                          noonText={noonText}
                          answer={this.props.answer.response[post.id] || []}
                          emojis={emojis}
                          isPostViewActive={isPostViewActive}
                          handleCommentSend={this.handleCommentSend}
                          isRtl={localStorage.language !== 'en'}
                          isUploading={this.state.isUploading}
                          removeMember={this.handleRemoveMember}
                          groupMembersIdMap={this.props.groupMembersIdMap}
                          blockListIdMap={this.props.blockListIdMap}
                          blockUser={this.blockUser}
                          unblockUser={this.unblockUser}
                          groupDetails={groupDetails}
                          history={history}
                          showSinglePost={this.showSinglePost}
                          singlePost={singlePost}
                          showAnswers={getAnswers}
                          deleteQuestion={deleteQuestion}
                          sendReply={this.props.sendReply}
                          deleteAnswer={deleteAnswer}
                          groupId={groupId}
                          upvoteAnswer={this.props.upvoteAnswer}
                          thankAnswer={this.props.thankAnswer}
                          replies={this.props.replies}
                          viewReplies={this.props.viewReplies}
                          deleteReply={this.props.deleteReply}
                          answerLoadingId={post.id === this.props.answer.isLoading}
                          upvoteQuestionValueFromMap={this.props.upvoteQuestionMap[post.id]}
                          thankAnswerMap={this.props.thankAnswerMap}
                          likeComment={this.props.likeReply}
                          likeReplyMap={this.props.likeReplyMap}
                          replyCountMap={this.props.replyCountMap}
                          answerCount={this.props.answerCountMap[post.id]}
                          thankCount={this.props.thankCountMap}
                          createAnswerLoading={this.props.createAnswer.isLoading === post.id}
                          createReplyLoading={this.props.createReply.isLoading}
                          handleMixpanelKafkaFeeds={this.props.handleMixpanelKafkaFeeds}
                          onlyQuestions={onlyQuestions}
                          uploadImageProgress={this.props.uploadImageProgress}
                          bulkFileUpload={this.props.bulkFileUpload}
                          bulkFilesUploaded={this.props.bulkFilesUploaded}
                          resetUploadImageProgress={this.props.resetUploadImageProgress}
                          imageUploadProgressStatus={this.props.imageUploadProgressStatus}
                          isBulkFileUploading={this.props.isBulkFileUploading}
                          bulkFileUploadSuccess={this.props.bulkFileUploadSuccess}
                          sendAnswer={this.props.sendAnswer}
                          isArchived={isArchived}
                        />
                      ))}
                    </React.Fragment>
                  </InfiniteScroll>
                  {!question.isLoading && isEmpty(question.response.list) && (
                  <div className="empty-slate flex-column flex-nowrap align-center">
                    <img src="/assets/images/empty-state/no-questions.svg" alt="no Questions" />
                    <p className="subtitle">{translationText(noonText, 'post.noQuestionEmptyMsgTeacher')}</p>
                  </div>
                  )}
                  {!onlyQuestions && (
                  <Row justify="center" className="full-width mb-2">
                    <Button onClick={this.showAllQuestions} type="secondary" className="plr-1 text-capitalize">
                      {translationText(noonText, 'post.seeAllQues')}
                      <IconBack flipX={!isRtl} />
                    </Button>
                  </Row>
                  )}
                  {question.isLoading && (
                  <Row className="plr-1 ptb-1 full-width" justify="center">
                    <IconLoaderDotted width="4rem" height="4rem" />
                  </Row>
                  )}
                </Column>
              )}
          </Column>
        </Card>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state, props) => ({
  emojis: state.toJS().file.emojis,
  noonText: state.toJS().translation.noonText,
  groupMembersIdMap: groupMembersListMapSelector(state),
  blockListIdMap: blockListMapSelector(state),
  groupError: state.toJS().groups.error,
  fileError: state.toJS().file.error,
  uploadImageUrls: state.toJS().file.uploadImageUrls,
  imageUploadProgressStatus: state.toJS().file.imageUploadProgressStatus,
  isBulkFileUploading: state.toJS().file.isBulkFileUploading,
  bulkFilesUploaded: state.toJS().file.bulkFilesUploaded,
  question: state.toJS().feedQuestion.allQuestions,
  questionCount: state.toJS().feedQuestion.getFilterCount,
  createQuestion: state.toJS().feedQuestion.createQuestion,
  answer: state.toJS().feedQuestion.answers,
  replies: state.toJS().feedQuestion.replies,
  upvoteQueue: state.toJS().feedQuestion.queueForQuestion,
  upvoteQuestionMap: state.toJS().feedQuestion.upvoteQuestion.response,
  ongoingRequestUpvote: state.toJS().feedQuestion.currentIdForUpvote,
  thankQueue: state.toJS().feedQuestion.queueForAnswer,
  thankAnswerMap: state.toJS().feedQuestion.thankAnswer.response,
  likeReplyMap: state.toJS().feedQuestion.likeReply.response,
  ongoingThankMap: state.toJS().feedQuestion.currentThankId,
  answerCountMap: state.toJS().feedQuestion.answerCountMap,
  replyCountMap: state.toJS().feedQuestion.replyCountMap,
  thankCountMap: state.toJS().feedQuestion.thankCountMap,
  createAnswer: state.toJS().feedQuestion.createAnswer,
  createReply: state.toJS().feedQuestion.createReply,
});

const mapDispatchToProps = (dispatch) => ({
  getEmojis: () => dispatch({ type: GET_EMOJI.REQUEST }),
  uploadImage: (data) => dispatch({ type: UPLOAD_FILE.REQUEST, payload: data }),
  sendAnswer: (data) => dispatch({ type: CREATE_ANSWER.REQUEST, payload: data }),
  sendReply: (data) => dispatch({ type: CREATE_REPLY.REQUEST, payload: data }),
  deletePost: (data) => dispatch({ type: DELETE_POST.REQUEST, payload: data }),
  removeMember: (payload) => dispatch({ type: GROUP_REMOVE_MEMBERS.REQUEST, payload }),
  blockUser: (payload) => dispatch({ type: BLOCK_USER.REQUEST, payload }),
  unBlockUser: (payload) => dispatch({ type: UN_BLOCK_USER.REQUEST, payload }),
  getPostDataForNotification: (payload) => dispatch({ type: NOTIFICATION_POST_DATA.REQUEST, payload }),
  bulkFileUpload: (filesBatch) => dispatch({ type: BULK_FILE_UPLOAD.REQUEST, filesBatch }),
  bulkFileUploadSuccess: (payload) => dispatch({ type: BULK_FILE_UPLOAD.SUCCESS, payload, remove: 1 }),
  uploadImageProgress: (id, percentCompleted) => dispatch({
    type: UPLOAD_IMAGE_PROGRESS,
    payload: { id, percentCompleted },
  }),
  resetUploadImageProgress: () => dispatch({ type: RESET_UPLOAD_IMAGE_PROGRESS }),
  submitQuestion: (payload) => dispatch({ type: CREATE_QUESTIONS_POST.REQUEST, payload }),
  getQuestion: (payload) => dispatch({ type: GET_ALL_QUESTIONS_POSTS.REQUEST, payload }),
  getAnswers: (payload) => dispatch({ type: GET_QUESTION_ANSWERS.REQUEST, payload }),
  getQuestionCounts: (payload) => dispatch({ type: GET_COUNT_FOR_QUESTION_FILTER.REQUEST, payload }),
  deleteQuestion: (payload) => dispatch({ type: DELETE_QUESTION.REQUEST, payload }),
  deleteAnswer: (payload) => dispatch({ type: DELETE_ANSWER.REQUEST, payload }),
  upvoteAnswer: (payload) => dispatch({ type: UPVOTE_QUESTION.REQUEST, payload }),
  thankAnswer: (payload) => dispatch({ type: THANK_ANSWER.REQUEST, payload }),
  likeReply: (payload) => dispatch({ type: LIKE_REPLY.REQUEST, payload }),
  viewReplies: (payload) => dispatch({ type: GET_ANSWERS_REPLIES.REQUEST, payload }),
  deleteReply: (payload) => dispatch({ type: DELETE_REPLY.REQUEST, payload }),
  clearUploadFiles: () => dispatch({ type: UPLOAD_FILE.SUCCESS, payload: {} }),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionAndAnswer);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal, Button, Row, Badge, Column } from '@noon/atom';
import ErrorBoundry from '../../ErrorBoundry';
import t from '../../../helpers/translate';

function SlideSectionsIntroModal(props) {
  const { onClose, type, onProceed } = props;
  const onboardingSteps = [
    {
      description: 'sectionOnboardingDescription1',
      image: '../../../assets/images/onboarding/slide_sections.gif',
    },
    {
      description: 'sectionOnboardingDescription2',
      image: '../../../assets/images/onboarding/why_sections.gif',
    },
    {
      description: 'sectionOnboardingDescription3',
      image: '../../../assets/images/onboarding/how_sections.gif',
    },
  ];

  const sections = [{ id: 1, title: 'Intro', active: false, slides: [1, 2] }, { id: 1, title: 'Section 2', active: true, slides: [1] }];

  // States
  const [onboardingIndex, setOnboardingIndex] = useState(0);

  // Logic
  const handleUserAction = () => {
    if (onboardingIndex < 2) setOnboardingIndex(onboardingIndex + 1);
    else onClose();
  };
  return (
    <ErrorBoundry>
      <Modal className="sections-intro" onClose={onClose}>
        {type === 'normal' ? (
          <Column className="modal-body">
            <div className="top-area">
              <Row className="heading" gap align="center">
                <h3 className="child">{t('classroom', 'sectionOnboardingTitle1')}</h3>
                <Badge className="child" type="green" value="New" size="md" />
              </Row>
              <div className="texts">
                <p>{t('playback', onboardingSteps[onboardingIndex].description)}</p>
              </div>
              <div className="image-wrapper">
                <img src={onboardingSteps[onboardingIndex].image} alt={onboardingSteps[onboardingIndex].description} />
              </div>
            </div>
            <div className="bottom-area">
              <div className="footer">
                <Row className="dots" gap="sm">
                  {onboardingSteps.map((step, index) => (
                    <span key={index} onClick={() => setOnboardingIndex(index)} className={classNames('child', onboardingIndex + 1, { active: index === onboardingIndex })} />
                  ))}
                </Row>
                <Button type="primary" value={onboardingIndex === 2 ? 'Got it' : 'Next'} onClick={() => handleUserAction()} />
              </div>
            </div>
          </Column>
        ) : (
          <Column className="modal-body">
            {/* This is not being used right now, will be added in release next week */}
            <div className="top-area">
              <div className="heading">
                <h3>Organize the pages into sections</h3>
              </div>
              <div className="texts">
                <p>
                  You can split the section into two or more by click and drag to include more / less slides in this section.
                  {' '}
                  <br />
                  Also you can create a new sections and drag slides inside it with one click.
                </p>
              </div>
              <div className="image-wrapper">
                <img src="../../../assets/images/onboarding/pdf-slide-crop.gif" alt="cropping sections" />
              </div>
            </div>
            <div className="bottom-area">
              <Row className="footer" justify="end">
                <Button type="primary" value="Got it" onClick={onProceed} />
              </Row>
            </div>
          </Column>
        )}
      </Modal>
    </ErrorBoundry>
  );
}

SlideSectionsIntroModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default SlideSectionsIntroModal;

import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.MIXPANEL_TOKEN, { cross_subdomain_cookie: false });

const env_check = true; // ['production', 'testing'].includes(process.env.ENV);
const disableEvents = ['resource_load_times', 'timetointeractive', 'contentful_paint'];

const Mixpanel = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  register: (id) => {
    if (env_check) mixpanel.register(id);
  },
  track: (name, props) => {
    if (disableEvents.includes(name)) return;
    if (process.env.ENV !== 'production') {
      console.log(name, JSON.stringify(props, null, 4));
    }
    if (env_check) mixpanel.track(name, { ...props });
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
  reset: () => {
    if (env_check) {
      mixpanel.reset();
    }
  },
};

export default Mixpanel;

import React from 'react';
import { Avatar } from '@noon/atom';
import withNoonText from 'helpers/withNoonText';

const getTeamMemberAnswerStyles = (answer) => {
  if (answer === null) {
    return ['notattempted', 'optionNotAttempted'];
  } else if (answer === false) {
    return ['wrong', 'optionWrong'];
  }
  return ['correct', 'optionCorrect'];
};
const TeamMemberListItem = ({ text, noonText, member }) => {
  const [style, translationKey] = getTeamMemberAnswerStyles(member.is_last_choice_correct);
  return (
    <div className="team-leader-board__memberslistitem">
      <Avatar url={member.profile_pic} className="pointer" noShadow rounded />
      <span>{member.name}</span>
      <span className={style}>{text(noonText, `teacherBreakout.${translationKey}`)}</span>
    </div>
  );
};

export default withNoonText(TeamMemberListItem);

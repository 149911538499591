import React from 'react';
import { IconWrong } from '@noon/quark';
import classNames from 'classnames';

export default function SessionCardPlaceholder(prop) {
  return (
    <div className="noon-placeholder flex-row flex-nowrap mt-1 mb-1">
      {!prop.noAvatar && <IconWrong height="72px" width="72px" />}
      <div className={classNames('flex-column', 'flex-nowrap', 'justify-center', { 'mlr-2': prop.noAvatar })}>
        <div className="placeholder-title placeholder-fade" />
        <div className="mt-1 placeholder-subtitle placeholder-fade" />
        <div className="mt-1 flex-row flex-nowrap align-center">
          <IconWrong height="18px" width="18px" />
          <span className="placeholder-text placeholder-fade" />
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Row } from '@noon/atom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import t from '../../../../helpers/translate';

const CreatePdfSlideModal = ({ title }) => {

  const lastSlideInProcess = useRef(null);

  const {
    totalSlides,
    slideInProcessIndex,
    slideInProcessUrl,
    slideInProcessFailed,
  } = useSelector((state) => state.toJS().whiteboard.pdfSlide);

  const [showSlideAnmationFor, setShowSlideAnimationFor] = useState(null);

  useEffect(() => {
    if (!slideInProcessUrl || lastSlideInProcess.current === slideInProcessIndex) return;
    const TIME_TO_START_NEXT_ANIMATION = 100; // in ms
    setShowSlideAnimationFor(null);
    setTimeout(() => {
      lastSlideInProcess.current = slideInProcessIndex;
      setShowSlideAnimationFor(slideInProcessUrl);
    }, TIME_TO_START_NEXT_ANIMATION);
  }, [slideInProcessIndex, slideInProcessUrl]);

  return (
    <Modal className="create-pdf-slide-modal flex-column flex-nowrap align-center justify-center">
      <h3>{t('classroom', title)}</h3>
      {slideInProcessIndex > -1 && <p>{t('classroom', 'pdfToSlideSubTitle').replace('{slideInProcess}', slideInProcessIndex + 1).replace('{totalSlides}', totalSlides)}</p>}

      {showSlideAnmationFor ? (
        <Row nowrap className={classNames('print-box', 'mt-2', { error: slideInProcessFailed })}>
          <div className="print-card">
            <img src={showSlideAnmationFor} alt="slide" />
          </div>
          <div className="print-card print-card__overlay" />
        </Row>
      ) : (
        <Row nowrap className="print-box mt-2">
          <div className="print-card" />
        </Row>
      )}

      {slideInProcessFailed && <p className="color-red mt-1">{`Failed to upload pdf page ${slideInProcessIndex + 1}. Moving to next page...`}</p>}
    </Modal>
  );

};

CreatePdfSlideModal.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CreatePdfSlideModal;

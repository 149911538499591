import { useContext } from 'react';
import { PlaybackTimeSeriesContext } from './context';

function usePlaybackTimeSeries(): { sketchData: Object, activeSlide: Number, updateCheckpoints: Function } {
  const context = useContext(PlaybackTimeSeriesContext);
  if (!context) {
    console.log('Please wrap your component inside timeseries provider');
  }
  return context;
}

export { usePlaybackTimeSeries };

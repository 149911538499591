import { useContext } from 'react';
import ClassroomAnalyticsContext from './context';

function useClassroomAnalytics() {
  const context = useContext(ClassroomAnalyticsContext);
  if (!context) {
    console.log('Please wrap your component inside ClassroomAnalyticsProvider');
  }
  return context;
}

export default useClassroomAnalytics;

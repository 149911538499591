const AGORA_RTC_ERRORS = {
  101: 'RTC:INIT_ERROR',
  102: 'RTC:JOIN_CHANNEL_ERROR',
  103: 'RTC:LOCAL_STREAM_INIT_ERROR',
  104: 'RTC:LOCAL_STREAM_PUBLISH_ERROR',
  105: 'RTC:LOCAL_STREAM_PUBLISH_RETRY_EXHAUSTED',
  106: 'RTC:LOCAL_AUDIO_STATS_ERROR',
  107: 'RTC:STREAM_SUBSCRIBE_ERROR',
  108: 'RTC:AUDIO_TRACK_ENDED',
  109: 'RTC:MIC_PERMISSION_DENIED',
  110: 'RTC:CAM_PERMISSION_DENIED',
  111: 'RTC:CAM_STREAM_INIT_ERROR',
};

export default AGORA_RTC_ERRORS;

import update from 'immutability-helper';
import { isEmpty, slice } from 'lodash-es';

import {
  SET_CURRICULUM_TEMPLATE,
  ADD_CHAPTER,
  UPDATE_CHAPTER,
  DELETE_CHAPTER,
  REORDER_CHAPTER,
  ADD_TOPIC,
  SET_TOPIC,
  EMPTY_CHAPTER,
  UPDATE_TOPIC,
  REORDER_TOPIC,
  DELETE_TOPIC,
  GET_PREVIOUS_GROUP_CURRICULUM,
  GET_NOON_SUGGESTED_CURRICULUM,
  GET_GROUP_CURRICULUM,
  GET_CURRICULUM_CHAPTER_TOPICS,
  GET_CURRICULUM_SIMILAR_GROUPS,
  GET_GRADES,
  GET_SUBJECTS,
  GET_DEGREES,
  GET_BOARDS,
  GET_CURRICULUM,
  GET_CHAPTERS,
  GET_TOPICS,
  GET_TESTS,
  GET_SKILLS,
  GET_COUNTRIES,
  GET_MAJORS,
  GET_COURSES,
  GET_SECTIONS,
  GET_CURRICULUM_SUGGESTED_CHAPTER,
  PUBLISH_CURRICULUM,
  DRAFT_CURRICULUM,
} from '../constants';

const defaultChapter = {
  name: '',
  type: 'chapter', // chapter/revision/misc
  expected_session_count: 0,
  topic_info: [],
};
const defaultTopic = {
  name: '',
  type: 'topic', // topic
};
const initialState = {
  selectedCurriculumTemplate: {},
  chapters: [],
  curriculumExist: false,
  hasDraft: false,
  suggestedTopicsList: {},
  suggestedChaptersList: {},
  similarGroups: {},
  countries: { list: [], loading: false, meta: {} },
  grades: { list: [], loading: false, meta: {}, lastId: null },
  boards: { list: [], loading: false, meta: {}, lastId: null },
  subjects: { list: [], loading: false, meta: {}, lastId: null },
  degrees: { list: [], loading: false, meta: {}, lastId: null },
  majors: { list: [], loading: false, meta: {}, lastId: null },
  curriculums: { list: [], loading: false, meta: {}, lastId: null },
  libraryChapters: { list: [], loading: false, meta: {}, lastId: null },
  topics: { list: [], loading: false, meta: {}, lastId: null },
  tests: { list: [], loading: false, meta: {}, lastId: null },
  skills: { list: [], loading: false, meta: {}, lastId: null },
  courses: { list: [], loading: false, meta: {}, lastId: null },
  sections: { list: [], loading: false, meta: {}, lastId: null },
  isCurriculumUpdated: false,
  curriculumPublishInProgress: false,
  error: {},
};

export default function curriculum(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CURRICULUM_SIMILAR_GROUPS.LOADING:
      return {
        ...state,
        similarGroups: {
          loading: true,
        },
      };
    case GET_CURRICULUM_SIMILAR_GROUPS.SUCCESS:
      return {
        ...state,
        similarGroups: {
          list: action.payload.data,
          loading: false,
        },
      };
    case GET_CURRICULUM_SIMILAR_GROUPS.FAILURE:
      return {
        ...state,
        error: { similarGroups: action.payload },
      };
    case GET_CURRICULUM_CHAPTER_TOPICS.LOADING:
      return {
        ...state,
        suggestedTopicsList: { loading: true },
      };
    case GET_CURRICULUM_CHAPTER_TOPICS.SUCCESS:
      return {
        ...state,
        suggestedTopicsList: { ...state.suggestedTopicsList, ...action.payload },
      };
    case GET_CURRICULUM_CHAPTER_TOPICS.FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_CURRICULUM_SUGGESTED_CHAPTER.LOADING:
      return {
        ...state,
        suggestedChaptersList: {
          loading: true,
        },
      };
    case GET_CURRICULUM_SUGGESTED_CHAPTER.SUCCESS:
      return {
        ...state,
        suggestedChaptersList: {
          list: action.payload,
          loading: false,
        },
      };
    case GET_CURRICULUM_SUGGESTED_CHAPTER.FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_GROUP_CURRICULUM.LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_GROUP_CURRICULUM.SUCCESS:
      return {
        ...state,
        isCurriculumUpdated: false,
        chapters: action.payload.content,
        curriculumExist: action.payload.curriculumExist,
        hasDraft: action.payload.has_draft,
        loading: false,
      };
    case GET_GROUP_CURRICULUM.FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_PREVIOUS_GROUP_CURRICULUM.LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_PREVIOUS_GROUP_CURRICULUM.SUCCESS:
      return {
        ...state,
        chapters: action.payload,
        loading: false,
      };
    case GET_PREVIOUS_GROUP_CURRICULUM.FAILURE:
      return {
        ...state,
        error: { chapters: action.payload },
        loading: false,
      };
    case GET_NOON_SUGGESTED_CURRICULUM.LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_NOON_SUGGESTED_CURRICULUM.SUCCESS:
      return {
        ...state,
        chapters: action.payload,
        loading: false,
      };
    case GET_NOON_SUGGESTED_CURRICULUM.FAILURE:
      return {
        ...state,
        error: { chapters: action.payload },
        loading: false,
      };
    case SET_CURRICULUM_TEMPLATE:
      return {
        ...state,
        isCurriculumUpdated: true,
        selectedCurriculumTemplate: action.payload,
      };
    case ADD_CHAPTER:
      if (action.payload.type === 'revision') delete defaultChapter.topic_info;
      return {
        ...state,
        isCurriculumUpdated: true,
        chapters: update(state.chapters, {
          $push: [{
            ...defaultChapter,
            ...action.payload,
            name_header: `Chapter ${state.chapters.length + 1}`,
            name_header_key: `chapter${state.chapters.length + 1}`,
          }],
        }),
      };
    case EMPTY_CHAPTER:
      return {
        ...state,
        isCurriculumUpdated: true,
        chapters: [],
      };
    case UPDATE_CHAPTER:
      return {
        ...state,
        isCurriculumUpdated: true,
        chapters: update(state.chapters, {
          [action.payload.index]: { $set: { ...action.payload.chapter, is_updated: !!action.payload.chapter.chapter_id } },
        }),
      };
    case REORDER_CHAPTER:
      return {
        ...state,
        isCurriculumUpdated: true,
        chapters: update(state.chapters, {
          is_updated: {
            $set: !!action.payload.chapter.chapter_id,
          },
          $splice: [
            [action.payload.removedIndex, 1],
            [action.payload.addedIndex, 0, action.payload.chapter],
          ],
        }).map((chapter, index) => ({ ...chapter, is_updated: chapter.is_updated || (index >= Math.min(action.payload.removedIndex, action.payload.addedIndex) ? !!chapter.chapter_id : false) })),
      };
    case DELETE_CHAPTER:
      if (action.payload.chapter.id) {
        return {
          ...state,
          isCurriculumUpdated: true,
          chapters: update(state.chapters, {
            $splice: [
              [action.payload.removedIndex, 1],
            ],
            $push: [{ ...action.payload.chapter, is_deleted: true }],
          }).map((chapter, index) => ({ ...chapter, is_updated: index >= action.payload.removedIndex && !!chapter.chapter_id })),
        };
      }
      return {
        ...state,
        isCurriculumUpdated: true,
        chapters: update(state.chapters, {
          $splice: [
            [action.payload.removedIndex, 1],
          ],
        }),
      };
    case SET_TOPIC:
      return {
        ...state,
        isCurriculumUpdated: true,
        chapters: update(state.chapters, {
          [action.payload.chapterIndex]: {
            topic_info: {
              $set: [],
            },
          },
        }),
      };
    case ADD_TOPIC:
      return {
        ...state,
        isCurriculumUpdated: true,
        chapters: update(state.chapters, {
          [action.payload.chapterIndex]: {
            is_updated: {
              $set: !!state.chapters[action.payload.chapterIndex].chapter_id,
            },
            topic_info: {
              $apply: (topicInfo) => {
                if (!topicInfo) {
                  return [{
                    ...defaultTopic,
                    chapter_id: state.chapters[action.payload.chapterIndex].chapter_id,
                    name_header: 'Topic 1',
                    name_header_key: 'topic1',
                  }];
                }
                topicInfo.push({
                  ...defaultTopic,
                  chapter_id: state.chapters[action.payload.chapterIndex].chapter_id,
                  name_header: `Topic ${state.chapters[action.payload.chapterIndex].topic_info.length + 1}`,
                  name_header_key: `topic${state.chapters[action.payload.chapterIndex].topic_info.length + 1}`,
                });
                return topicInfo;
              },
            },
          },
        }),
      };
    case UPDATE_TOPIC:
      return {
        ...state,
        isCurriculumUpdated: true,
        chapters: update(state.chapters, {
          [action.payload.chapterIndex]: {
            is_updated: {
              $set: !!state.chapters[action.payload.chapterIndex].chapter_id,
            },
            topic_info: {
              $splice: [
                [action.payload.index, 1, { ...action.payload.topic, is_updated: !!action.payload.topic.id }],
              ],
            },
          },
        }),
      };
    case REORDER_TOPIC:
      return {
        ...state,
        isCurriculumUpdated: true,
        chapters: update(state.chapters, {
          [action.payload.chapterIndex]: {
            is_updated: {
              $set: !!state.chapters[action.payload.chapterIndex].chapter_id,
            },
            topic_info: {
              $apply: (topics) => topics.map((topic, index) => ({ ...topic, is_updated: index >= Math.min(action.payload.removedIndex, action.payload.addedIndex) })),
              $splice: [
                [action.payload.removedIndex, 1],
                [action.payload.addedIndex, 0, { ...action.payload.topic, is_updated: true }],
              ],
            },

          },
        }),
      };
    case DELETE_TOPIC:
      if (action.payload.topic.id) {
        return {
          ...state,
          isCurriculumUpdated: true,
          chapters: update(state.chapters, {
            [action.payload.chapterIndex]: {
              is_updated: {
                $set: !!state.chapters[action.payload.chapterIndex].chapter_id,
              },
              topic_info: {
                $apply: (topics) => [...slice(topics, 0, action.payload.index), ...slice(topics, action.payload.index).map((topic) => ({ ...topic, is_updated: true }))],
                $splice: [
                  [action.payload.index, 1],
                ],
                $push: [{ ...action.payload.topic, is_deleted: true }],
              },
            },
          }),
        };
      }
      return {
        ...state,
        isCurriculumUpdated: true,
        chapters: update(state.chapters, {
          [action.payload.chapterIndex]: {
            topic_info: {
              $splice: [
                [action.payload.index, 1],
              ],
            },
          },
        }),
      };
    case GET_GRADES.LOADING:
      return { ...state, grades: { ...state.grades, loading: true } };
    case GET_GRADES.SUCCESS:
      return {
        ...state,
        grades: {
          list: !isEmpty(state.grades.list) && action.payload.lastId === state.grades.lastId ? [...state.grades.data, action.payload.data] : action.payload.data,
          loading: false,
          meta: action.payload.meta,
        },
      };
    case GET_GRADES.FAILURE:
      return { ...state, grades: { ...state.grades, loading: false }, error: { grades: action.payload } };
    case GET_SUBJECTS.LOADING:
      return { ...state, subjects: { ...state.subjects, loading: true } };
    case GET_SUBJECTS.SUCCESS:
      return {
        ...state,
        subjects: {
          list: !isEmpty(state.subjects.list) && action.payload.lastId === state.subjects.lastId ? [...state.subjects.data, action.payload.data] : action.payload.data,
          loading: false,
          meta: action.payload.meta,
        },
      };
    case GET_SUBJECTS.FAILURE:
      return { ...state, subjects: { ...state.subjects, loading: false }, error: { subjects: action.payload } };
    case GET_DEGREES.LOADING:
      return { ...state, degrees: { ...state.degrees, loading: true } };
    case GET_DEGREES.SUCCESS:
      return {
        ...state,
        degrees: {
          list: !isEmpty(state.degrees.list) && action.payload.lastId === state.degrees.lastId ? [...state.degrees.data, action.payload.data] : action.payload.data,
          loading: false,
          meta: action.payload.meta,
        },
      };
    case GET_DEGREES.FAILURE:
      return { ...state, degrees: { ...state.degrees, loading: false }, error: { degrees: action.payload } };
    case GET_BOARDS.LOADING:
      return { ...state, boards: { ...state.boards, loading: true } };
    case GET_BOARDS.SUCCESS:
      return {
        ...state,
        boards: {
          list: !isEmpty(state.boards.list) && action.payload.lastId === state.boards.lastId ? [...state.boards.data, action.payload.data] : action.payload.data,
          loading: false,
          meta: action.payload.meta,
        },
      };
    case GET_BOARDS.FAILURE:
      return { ...state, boards: { ...state.boards, loading: false }, error: { boards: action.payload } };
    case GET_CURRICULUM.LOADING:
      return { ...state, curriculums: { ...state.curriculums, loading: true } };
    case GET_CURRICULUM.SUCCESS:
      return {
        ...state,
        curriculums: {
          list: !isEmpty(state.curriculums.list) && action.payload.lastId === state.curriculums.lastId ? [...state.curriculums.data, action.payload.data] : action.payload.data,
          loading: false,
          meta: action.payload.meta,
        },
      };
    case GET_CURRICULUM.FAILURE:
      return { ...state, curriculums: { ...state.curriculums, loading: false }, error: { curriculums: action.payload } };
    case GET_CHAPTERS.LOADING:
      return { ...state, libraryChapters: { ...state.libraryChapters, loading: true } };
    case GET_CHAPTERS.SUCCESS:
      return {
        ...state,
        libraryChapters: {
          list: !isEmpty(state.chapters.list) && action.payload.lastId === state.chapters.lastId ? [...state.libraryChapters.data, action.payload.data] : action.payload.data,
          loading: false,
          meta: action.payload.meta,
        },
      };
    case GET_CHAPTERS.FAILURE:
      return { ...state, libraryChapters: { ...state.libraryChapters, loading: false }, error: { libraryChapters: action.payload } };
    case GET_TOPICS.LOADING:
      return { ...state, topics: { ...state.topics, loading: true } };
    case GET_TOPICS.SUCCESS:
      return {
        ...state,
        topics: {
          list: !isEmpty(state.topics.list) && action.payload.lastId === state.topics.lastId ? [...state.topics.data, action.payload.data] : action.payload.data,
          loading: false,
          meta: action.payload.meta,
        },
      };
    case GET_TOPICS.FAILURE:
      return { ...state, topics: { ...state.topics, loading: false }, error: { topics: action.payload } };
    case GET_TESTS.LOADING:
      return { ...state, tests: { ...state.tests, loading: true } };
    case GET_TESTS.SUCCESS:
      return {
        ...state,
        tests: {
          list: !isEmpty(state.tests.list) && action.payload.lastId === state.tests.lastId ? [...state.tests.data, action.payload.data] : action.payload.data,
          loading: false,
          meta: action.payload.meta,
        },
      };
    case GET_TESTS.FAILURE:
      return { ...state, tests: { ...state.tests, loading: false }, error: { tests: action.payload } };
    case GET_COUNTRIES.LOADING:
      return { ...state, countries: { ...state.countries, loading: true } };
    case GET_COUNTRIES.SUCCESS:
      return {
        ...state,
        countries: {
          list: !isEmpty(state.countries.list) ? [...state.tests.data, action.payload.data] : action.payload.data,
          loading: false,
          meta: action.payload.meta,
        },
      };
    case GET_COUNTRIES.FAILURE:
      return { ...state, countries: { ...state.countries, loading: false }, error: { countries: action.payload } };
    case GET_SKILLS.LOADING:
      return { ...state, skills: { ...state.skills, loading: true } };
    case GET_SKILLS.SUCCESS:
      return {
        ...state,
        skills: {
          list: !isEmpty(state.skills.list) && action.payload.lastId === state.skills.lastId ? [...state.skills.data, action.payload.data] : action.payload.data,
          loading: false,
          meta: action.payload.meta,
        },
      };
    case GET_SKILLS.FAILURE:
      return { ...state, skills: { ...state.skills, loading: false }, error: { skills: action.payload } };
    case GET_MAJORS.LOADING:
      return { ...state, majors: { ...state.majors, loading: true } };
    case GET_MAJORS.SUCCESS:
      return {
        ...state,
        majors: {
          list: !isEmpty(state.majors.list) && action.payload.lastId === state.majors.lastId ? [...state.majors.data, action.payload.data] : action.payload.data,
          loading: false,
          meta: action.payload.meta,
        },
      };
    case GET_MAJORS.FAILURE:
      return { ...state, majors: { ...state.majors, loading: false }, error: { majors: action.payload } };
    case GET_COURSES.LOADING:
      return { ...state, courses: { ...state.courses, loading: true } };
    case GET_COURSES.SUCCESS:
      return {
        ...state,
        courses: {
          list: !isEmpty(state.courses.list) && action.payload.lastId === state.courses.lastId ? [...state.courses.data, action.payload.data] : action.payload.data,
          loading: false,
          meta: action.payload.meta,
        },
      };
    case GET_COURSES.FAILURE:
      return { ...state, courses: { ...state.courses, loading: false }, error: { courses: action.payload } };
    case GET_SECTIONS.LOADING:
      return { ...state, sections: { ...state.sections, loading: true } };
    case GET_SECTIONS.SUCCESS:
      return {
        ...state,
        sections: {
          list: !isEmpty(state.sections.list) && action.payload.lastId === state.sections.lastId ? [...state.sections.data, action.payload.data] : action.payload.data,
          loading: false,
          meta: action.payload.meta,
        },
      };
    case GET_SECTIONS.FAILURE:
      return { ...state, sections: { ...state.sections, loading: false }, error: { sections: action.payload } };
    case DRAFT_CURRICULUM.REQUEST:
      return {
        ...state,
        curriculumDraftInProgress: true,
      };
    case DRAFT_CURRICULUM.SUCCESS:
      return {
        ...state,
        curriculumDraftInProgress: false,
      };
    case DRAFT_CURRICULUM.FAILURE:
      return {
        ...state,
        curriculumDraftInProgress: false,
        error: { draft: action.payload },
      };
    case PUBLISH_CURRICULUM.REQUEST:
      return {
        ...state,
        curriculumPublishInProgress: true,
      };
    case PUBLISH_CURRICULUM.SUCCESS:
      return {
        ...state,
        curriculumPublishInProgress: false,
      };
    case PUBLISH_CURRICULUM.FAILURE:
      return {
        ...state,
        curriculumPublishInProgress: false,
        error: { publish: action.payload },
      };
    default:
      return state;
  }
}

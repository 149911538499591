import React from 'react';
import { compose } from 'redux';
import { noonTextSelector } from 'redux/selectors/translations';
import { connect } from 'react-redux';
import translationText from './translationText';

const withNoonText = Component => ({ dispatch, ...rest }) => <Component {...rest} text={translationText} />;

const mapStateToProps = state => ({
  noonText: noonTextSelector(state),
});
export default compose(
  connect(
    mapStateToProps,
    null,
  ),
  withNoonText,
);

import React, { useEffect, useRef, useState } from 'react';
import { IconStarEmoji, IconSweatEmoji, IconInfoFilled } from '@noon/quark';
import { Card, Button } from '@noon/atom';
import { useDispatch, useSelector } from 'react-redux';
import BreakoutTeam from './breakoutTeam';
import { LOAD_MORE_TEAMS, BREAKOUT_ENDED } from '../../../redux/constants';
import { translationText } from '../../../helpers';
import store from '../../../store';
import Mixpanel from '../../Mixpanel';

declare var LoggingManager
export default function ReportingArea() {

  const dispatch = useDispatch();
  const [breakoutTeams, setBreakoutTeams] = useState(null);
  const dummyTeams = new Array(10).fill(null);
  const { noonText } = useSelector((state) => state.toJS().translation);
  const { sessionId } = useSelector((state) => state.toJS().tutoring.sessionDetails);
  const { breakoutReport } = useSelector((state) => state.toJS().classroom);
  const logger = useRef<any | null>(null);
  const breakoutReportSent = useRef<boolean | null>(null);
  let teams = null;

  // tracking events
  const sendBreakoutReportEvent = () => {

    if (!logger.current) logger.current = LoggingManager.mount({ moduleName: 'classroom' });
    const state = store.getState().toJS();
    const { user_id } = state.user.loggedUser;
    const session_id = state.tutoring.sessionDetails.id;
    const group_id = state.tutoring.sessionDetails.group.id;
    const total_teams_count = breakoutReport.totaTeams;
    const total_LIVE_users = breakoutReport.totalLiveUsers;
    const breakout_duration = breakoutReport.breakoutDuration;
    const least_engaged_teams_count = breakoutReport.notEngaged.meta.total_teams;
    const top_engaged_teams_count = breakoutReport.superEngaged.meta.total_teams;
    const engaged_teams_count = breakoutReport.engaged.meta.total_teams;
    const breakout_sequence_no = breakoutReport.breakoutCount;

    const eventData = {
      user_id,
      session_id,
      group_id,
      total_teams_count,
      total_LIVE_users,
      breakout_duration,
      top_engaged_teams_count,
      engaged_teams_count,
      least_engaged_teams_count,
      breakout_sequence_no,
    };

    Mixpanel.track('teams_live_report_displayed', eventData);
    logger.current.track('teams_live_report_displayed',
      { group: eventData },
    );
  };

  useEffect(() => {
    if (breakoutReport.onGoing === false) {
      breakoutReportSent.current = false;
    }
    if (breakoutReport.notEngaged) {
      teams = {};
      switch (breakoutReport.breakoutCount) {
        case 1:
          teams.teams = breakoutReport.notEngaged;
          break;
        default:
          teams.superEngaged = breakoutReport.superEngaged;
          teams.notEngaged = breakoutReport.notEngaged;
          teams.engaged = breakoutReport.engaged;
          break;
      }

      if (breakoutReport.onGoing && !breakoutReportSent.current) {
        sendBreakoutReportEvent();
        breakoutReportSent.current = true;
      }
      setBreakoutTeams(teams);
    }
  }, [breakoutReport]);

  // // TODO: delete mock data
  // useEffect(() => {

  //   setTimeout(() => {

  //     teams = {
  //       // engaged: { data: new Array(2).fill({
  //       //   team_id: 10130,
  //       //   team_name: 'Adrenaline',
  //       //   team_image: 'https://cdn.non.sa/teams/common/adrenaline.png',
  //       //   team_members: new Array(10).fill({
  //       //     gender: 'male',
  //       //     name: 'dsfdsB',
  //       //     color: '#faad14',
  //       //     user_id: 2279271,
  //       //     team_id: 10130,
  //       //     my_role: 'student',
  //       //     profile_pic: '/assets/images/ladka.png',
  //       //   }),
  //       // }),
  //       // meta: { total_teams: 5 } },
  //       engaged: {
  //         data: [],
  //         meta: { total_teams: 0 },
  //       },
  //       // notEngaged: [],
  //       notEngaged: { data: new Array(2).fill({
  //         team_id: 10130,
  //         team_name: 'Adrenaline',
  //         team_image: 'https://cdn.non.sa/teams/common/adrenaline.png',
  //         team_members: new Array(2).fill({
  //           gender: 'male',
  //           name: 'dsfdsB',
  //           color: '#faad14',
  //           user_id: 2279271,
  //           team_id: 10130,
  //           my_role: 'student',
  //           profile_pic: '',
  //         }),
  //       }),
  //       meta: { total_teams: 5 } },
  //       // superEngaged: { data: new Array(2).fill({
  //       //   team_id: 10130,
  //       //   team_name: 'Adrenaline',
  //       //   team_image: 'https://cdn.non.sa/teams/common/adrenaline.png',
  //       //   team_members: new Array(10).fill({
  //       //     gender: 'male',
  //       //     name: 'dsfdsB',
  //       //     color: '#faad14',
  //       //     user_id: 2279271,
  //       //     team_id: 10130,
  //       //     my_role: 'student',
  //       //     profile_pic: '/assets/images/ladka.png',
  //       //   }),
  //       // }),
  //       // meta: { total_teams: 5 } },
  //       superEngaged: {
  //         data: [],
  //         meta: { totalTeams: 0 },
  //       },
  //       teams: { data: new Array(10).fill({
  //         team_id: 10130,
  //         team_name: 'Adrenaline',
  //         team_image: 'https://cdn.non.sa/teams/common/adrenaline.png',
  //         team_members: new Array(2).fill({
  //           gender: 'male',
  //           name: 'dsfdsB',
  //           color: '#faad14',
  //           user_id: 2279271,
  //           team_id: 10130,
  //           my_role: 'student',
  //           profile_pic: null,
  //         }),
  //       }),
  //       meta: { total_teams: 5 } },

  //     };

  //     setBreakoutTeams(teams);
  //   }, 5000);
  // });

  const getBreakoutPerformanceMessage = (breakoutReport) => {

    const totalEngaged:number = breakoutReport.engaged.meta.total_teams + breakoutReport.superEngaged.meta.total_teams;
    const totalNotEngaged: Number = breakoutReport.notEngaged.meta.total_teams;

    switch (true) {
      case totalEngaged >= totalNotEngaged:
        return (
          <React.Fragment>
            <IconStarEmoji />
            {' '}
            <p>{translationText(noonText, 'breakoutReport.breakout_tip_good')}</p>
          </React.Fragment>
        );
      case totalNotEngaged > totalEngaged:
        return (
          <React.Fragment>
            <IconInfoFilled />
            {' '}
            <p>{translationText(noonText, 'breakoutReport.breakout_tip_bad')}</p>
          </React.Fragment>
        );
      default: return (<></>);
    }

  };

  const getBreakoutParticipation = (breakoutReport) => {
    const totalEngaged = breakoutReport.engaged.meta.total_teams + breakoutReport.superEngaged.meta.total_teams;
    const totalNotEngaged = breakoutReport.notEngaged.meta.total_teams;

    switch (true) {
      case totalEngaged >= totalNotEngaged:
        return (
          <p className="participation up">
            <img alt="participation" src="/assets/images/participationup.svg" />
            {((totalEngaged / breakoutReport.totalTeams) * 100).toFixed(2) }
            {'% '}
            {translationText(noonText, 'breakoutReport.participation_count_engaged')}
          </p>
        );
      case totalNotEngaged > totalEngaged:
        return (
          <p className="participation down">
            <img alt="participation" src="/assets/images/participationdown.svg" />
            {((totalNotEngaged / breakoutReport.totalTeams) * 100).toFixed(2)}
            {'% '}
            {translationText(noonText, 'breakoutReport.participation_count_not_engaged')}
          </p>
        );
      default: return (<></>);
    }

  };

  const handleLoadMore = (e: React.UIEvent<HTMLDivElement, UIEvent>, team_type:string, vertical:Boolean = false) => {

    if (vertical) {
      if (e.currentTarget.scrollTop >= e.currentTarget.scrollHeight / 2) {
        dispatch({ type: LOAD_MORE_TEAMS.REQUEST, payload: { sessionId, team_type } });
      }
    } else
    if (e.currentTarget.scrollLeft >= e.currentTarget.scrollWidth / 2) {
      dispatch({ type: LOAD_MORE_TEAMS.REQUEST, payload: { sessionId, team_type } });
    }
  };

  return (
    <div className="reporting-area">

      <Card className="breakout-teams">
        {breakoutTeams && (
        <React.Fragment>
          <div className="breakout-report">
            <div className="breakout-teams-summary">
              <h4>{translationText(noonText, 'breakoutReport.title')}</h4>
              {breakoutReport.breakoutCount > 2 && getBreakoutParticipation(breakoutReport)}
            </div>
            <div className="breakout-message-toolbar">
              {/* <Button type="primary" size="md" outlined> Send a message </Button>
            <Button type="primary" size="md" outlined> Talk to all </Button> */}

            </div>

          </div>

          {(breakoutReport.breakoutCount === 1 || breakoutReport.breakoutCount > 2) && (
          <div className="info">
            { breakoutReport.breakoutCount === 1 && (
            <React.Fragment>
              <img alt="info" src="/assets/images/info.svg" />
              {' '}
              <p>{translationText(noonText, 'breakoutReport.first_breakout_tip')}</p>
            </React.Fragment>
            )}
            { breakoutReport.breakoutCount > 2 && (
              (getBreakoutPerformanceMessage(breakoutReport))
            )}
          </div>
          )}

          { breakoutReport && breakoutReport.breakoutCount > 1 && breakoutTeams && (
          <>
            { breakoutTeams.notEngaged && (

            <div className="not-engaged">
              <h4>
                {' '}
                {translationText(noonText, 'breakoutReport.not_engaged_heading')}
                <span style={{ color: 'red' }}>{` (${breakoutTeams.notEngaged.meta.total_teams})`}</span>
              </h4>
              {breakoutTeams.notEngaged.meta.total_teams > 0 && (
              <React.Fragment>
                {' '}
                <p>
                  {translationText(noonText, 'breakoutReport.not_engaged_sub_heading')}
                </p>
                <div className="breakout-team-wrapper" onScroll={(e) => { handleLoadMore(e, 'notEngaged'); }}>
                  { breakoutTeams.notEngaged.data.map((team, index) => <BreakoutTeam key={index} notEngaged team={team} />) }
                </div>
              </React.Fragment>
              )}
              {breakoutTeams.notEngaged.meta.total_teams === 0 && (
              <div className="no-teams">
                <IconStarEmoji />
                {' '}
                <p>{translationText(noonText, 'breakoutReport.not_engaged_empty_message')}</p>
              </div>
              )}
            </div>

            )}

            { (breakoutReport.breakoutCount > 1 && (breakoutTeams.superEngaged || breakoutTeams.engaged)) && (

            <div className="super-engaged">
              <h4>
                {translationText(noonText, 'breakoutReport.super_engaged_heading')}
                <span style={{ color: 'rgb(39, 174, 96)' }}>{` (${breakoutTeams.superEngaged.meta.total_teams})`}</span>
              </h4>

              {breakoutTeams.superEngaged.meta.total_teams > 0 && (
              <React.Fragment>
                {' '}
                <p>
                  {translationText(noonText, 'breakoutReport.super_engaged_sub_heading')}
                </p>
                <div className="breakout-team-wrapper" onScroll={(e) => { handleLoadMore(e, 'superEngaged'); }}>
                  { breakoutTeams.superEngaged.data.map((team, index) => <BreakoutTeam key={index} superEngaged team={team} />) }
                </div>
              </React.Fragment>
              )}
              {(breakoutTeams.superEngaged.meta.total_teams === 0) && (
              <div className="no-teams">
                <IconSweatEmoji />
                {' '}
                <p>
                  {' '}
                  {translationText(noonText, 'breakoutReport.super_engaged_empty_message')}
                </p>
              </div>
              )}
            </div>

            )}

            {breakoutTeams.engaged && breakoutTeams.engaged.meta.total_teams > 0 && (
            <div className="engaged">
              <h4>
                {translationText(noonText, 'breakoutReport.engaged_heading')}
                {' '}
                <span style={{ color: 'rgb(242, 153, 74)' }}>{`(${breakoutTeams.engaged.meta.total_teams})`}</span>
              </h4>

              <React.Fragment>
                <p>
                  {translationText(noonText, 'breakoutReport.engaged_sub_heading')}
                </p>
                <div className="breakout-team-wrapper" onScroll={(e) => { handleLoadMore(e, 'engaged'); }}>
                  { breakoutTeams.engaged.data.map((team, index) => <BreakoutTeam key={index} engaged team={team} />) }
                </div>
              </React.Fragment>

              {/* {breakoutTeams.engaged.meta.total_teams === 0 && (
            <div className="no-teams">
              <img alt="info" src="/assets/images/sweatEmoji.svg" />
              {' '}
              <p>So far no teams have been highly engaged in this classroom!Try to motivate the silent teams by sending a message for them</p>
            </div>
            )} */}
            </div>
            )}
          </>
          )}

          {breakoutReport && breakoutReport.breakoutCount === 1 && breakoutTeams
          && (
          <div className="no-status">
            <div className="breakout-team-wrapper vert" onScroll={(e) => { handleLoadMore(e, 'engaged', true); }}>
              {breakoutTeams.teams.data.map((team, index) => <BreakoutTeam key={index} team={team} />) }
            </div>
          </div>
          )}
        </React.Fragment>
        )}

        {/* empty state */}
        {!breakoutTeams && (
        <div className="starting">
          <div className="breakout-team-wrapper">
            { dummyTeams.map((team, index) => <BreakoutTeam key={index} />) }
          </div>
        </div>
        )}

      </Card>

    </div>
  );

}

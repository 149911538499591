import React, { useMemo } from 'react';
import { IconAdd, IconClock } from '@noon/quark';
import { Badge, Button } from '@noon/atom';
import { DateTime } from 'luxon';
import { COLORS } from '../../../constants';
import { translationText } from '../../../helpers';

function CalendarActivityReservedCard({ noonText, activity, toggleCreateActivity }) {
  const slot = useMemo(() => `${DateTime.fromMillis(activity.start_time).toFormat('hh:mm a')} - ${DateTime.fromMillis(activity.end_time || 0).toFormat('hh:mm a')}`, [activity]);
  return (
    <div className="activity-card--grid-view activity-card--grid-view__reserve-card light_gray_bg pdb_none">
      {/* time */}
      <div className="icon_content time">
        <IconClock fill={COLORS.coolGrey[1]} />
        <span>{slot}</span>
      </div>
      {/* tags */}
      <div className="tags">
        <Badge type="primary" value={translationText(noonText, 'activity.reserved')} size="sm" />
      </div>
      {/* content */}
      <div className="content pdb">
        <p>{activity.name}</p>
      </div>

      {/* button blpck */}
      {
        <div className="button_block">
          <Button type="secondary" size="md" icon={IconAdd} onClick={() => toggleCreateActivity({ type: 'session', source: 'planner-grid-reserve-card', sessionData: activity })}>
            {translationText(noonText, 'button.addActivity')}
          </Button>
        </div>
    }
    </div>
  );
}

export default CalendarActivityReservedCard;

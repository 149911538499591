import React, { Component } from 'react';
import Popover from 'react-popover';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isMobileOnly, isMobile } from 'react-device-detect';
import { IconCorrectCircle, IconNoResult, IconPlus, IconWrong, IconPostWithPlus } from '@noon/quark';
import { Row, Avatar, Button, Column } from '@noon/atom';
import { Smileys } from './index';
import { translationText } from '../../helpers';
import { COLORS } from '../../constants';
import { addToast, TOAST_TYPE } from '../Toast';
import { translationType } from '../../types';
import ErrorBoundry from '../ErrorBoundry';
import { ConfirmationModal } from '../Modals';

export default class HtmlInput extends Component {
  static propTypes = {
    isUploading: PropTypes.number,
    idOfSrc: PropTypes.string,
    noonText: translationType.isRequired,
    inputRef: PropTypes.shape(),
    sendOperation: PropTypes.func,
    sendLoader: PropTypes.bool,
    isRtl: PropTypes.bool,
    emojis: PropTypes.shape().isRequired,
    user: PropTypes.shape().isRequired,
    hideFocus: PropTypes.func,
    placeholder: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    textData: PropTypes.func,
    node: PropTypes.arrayOf(PropTypes.object),
    currentPostIndex: PropTypes.number,
    crossNeeded: PropTypes.bool,
    typeOfAttachment: PropTypes.string.isRequired,
    maxChar: PropTypes.string,
  };

  static defaultProps = {
    inputRef: {},
    hideFocus: () => null,
    textData: () => null,
    node: [],
    crossNeeded: false,
    isUploading: 0,
    idOfSrc: '',
    sendOperation: () => null,
    sendLoader: false,
    isRtl: true,
    currentPostIndex: 0,
    maxChar: 400,
  };

  constructor(props) {
    super(props);

    this.state = {
      showEmoji: false,
      selectedCategory: 0,
      imagePreviewUrl: null,
      contentText: '',
      imageAdded: null,
      markAsCorrect: false,
      markAsResolved: false,
    };
    this.selectEmoji = this.selectEmoji.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isUploading === prevProps.idOfSrc && this.props.isUploading === 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ imageAdded: null, imagePreviewUrl: null });
    }

    // if (prevProps.sendLoader !== this.props.sendLoader && this.props.sendLoader && this.props.type !== 'comment') {
    //   // eslint-disable-next-line react/no-did-update-set-state
    //   this.props.hideFocus();
    // }
    // if (prevProps.sendLoader && prevProps.sendLoader !== this.props.sendLoader) {
    //   // eslint-disable-next-line react/no-did-update-set-state
    //   this.setState({ imageAdded: null, imagePreviewUrl: null });
    // }
  }

  componentDidMount() {
    if (this.props.inputRef.current) {
      this.props.inputRef.current.focus();
    }
  }

  getClassName = () => {
    const postClass = this.props.type === 'comment' ? '' : 'postClass';
    let placeholder = !!this.state.contentText || !!this.state.imagePreviewUrl ? '' : 'postTextContentEditable--has-placeholder';
    // Need to improve this code
    if (this.props.node && this.props.node.innerText === '') {
      placeholder = 'postTextContentEditable--has-placeholder';
    }

    return `postTextContentEditable ${placeholder} ${postClass}`;
  };

  handleInput = (e) => {
    const inputText = e.target.innerText;
    if (!inputText.trim()) return false;

    // const maxChar = (this.props.hideImage || this.props.type === 'post') ? this.props.maxChar : this.state.imagePreviewUrl ? 140 : 400;
    // if (this.props.inputRef.current) {
    //   this.props.inputRef.current.innerText = inputText;
    // } else {
    //   this.props.node[this.props.currentPostIndex].innerText = inputText;
    // }
    e.preventDefault();
    this.setState({ contentText: inputText });
    if (this.props.type === 'post') {
      this.props.textData(inputText);
    }
  };

  selectedCategoryIndex = (index) => {
    this.setState({ selectedCategory: index });
  };

  handleImageUpload = (e) => {
    if (e.target.files[0].size / (1024 * 1024) >= 5) {
      addToast(translationText(this.props.noonText, 'warning.image_size_warning'), TOAST_TYPE.ERROR);
      return;
    }
    this.setState({
      imagePreviewUrl: URL.createObjectURL(e.target.files[0]),
      imageAdded: e.target.files[0],
      showEmoji: false,
    });
    this.props.node[this.props.currentPostIndex].focus();
    e.stopPropagation();
  };

  toggleEmoji = () => {
    this.setState({ showEmoji: !this.state.showEmoji });
  };

  selectEmoji(emoji, e) {
    let inputSelector;
    if (this.props.type === 'comment') {
      inputSelector = this.props.node[this.props.currentPostIndex];
    } else {
      inputSelector = this.props.inputRef.current;
    }
    inputSelector.innerText += emoji;
    this.setState({ contentText: this.state.contentText + emoji });
    if (this.props.type === 'post') {
      this.props.textData(this.state.contentText + emoji);
    }
    inputSelector.focus();
    e.stopPropagation();
  }

  removeImage = () => {
    this.setState({ imagePreviewUrl: null });
  };

  handleKeyUp = (e) => {
    // const maxChar = (this.props.hideImage || this.props.type === 'post') ? this.props.maxChar : this.state.imagePreviewUrl ? 140 : 400;
    const inputText = e.target.innerText;
    if (this.props.type === 'post') {
      this.props.textData(inputText);
    }
    if (e.key === 'Enter' && !e.shiftKey && this.props.type === 'comment') {
      this.sendOperation();
      e.preventDefault();
    }
  };

  sendOperation() {
    if (!this.state.contentText && !this.state.imagePreviewUrl) return false;

    this.props.sendOperation(this.state.contentText, this.state.imagePreviewUrl, this.state.imageAdded, this.state.markAsResolved);
    this.setState({ contentText: '', imagePreviewUrl: null });
    let inputSelector;
    if (this.props.type === 'comment') {
      inputSelector = this.props.node[this.props.currentPostIndex];
    } else {
      inputSelector = this.props.inputRef.current;
    }
    inputSelector.innerText = '';
    return true;
  }

  markAsCorrect=() => {
    if (this.state.markAsResolved) {
      this.setState({ markAsCorrect: false, markAsResolved: false });
    } else {
      this.setState({ markAsCorrect: true });
    }
  }

  render() {
    const {
      inputRef,
      noonText,
      emojis,
      user,
      hideFocus,
      placeholder,
      crossNeeded,
      type,
      currentPostIndex,
      disabled,
      value,
      hideImage,
      markAnswer,
      className = '',
      showPublishButton,
    } = this.props;
    const popoverPropsForSmiley = {
      isOpen: this.state.showEmoji && !!emojis,
      preferPlace: 'below',
      onOuterAction: () => this.toggleEmoji(),
      body: (
        <Smileys
          emojis={emojis}
          showEmoji={this.state.showEmoji}
          selectedCategory={this.state.selectedCategory}
          selectEmoji={this.selectEmoji}
          selectedCategoryIndex={this.selectedCategoryIndex}
        />
      ),
    };
    return (
      <ErrorBoundry>
        {this.state.markAsCorrect
          && (
          <ConfirmationModal
            modalType="info"
            successBtn={translationText(noonText, 'session.confirm')}
            closeBtn={translationText(noonText, 'session.cancel')}
            onClose={() => { this.setState({ markAsCorrect: false, markAsResolved: false }); }}
            onSuccess={() => { this.setState({ markAsCorrect: false, markAsResolved: true }); }}
            text={translationText(noonText, 'post.markQuestionAnswered')}
            subText={translationText(noonText, 'post.markQuestionAnsweredText')}
          />
          )}
        <Column align="start" justify="center" gap="sm" className={`${className} full-width html-input`}>
          {crossNeeded && (
          <IconPlus
            onClick={() => hideFocus()}
            width="20px"
            height="20px"
            rotate="45"
            className="mlr-1 mtb-1"
            fill={COLORS.brand.base}
              />
          )}
          <Row
            align="center"
            justify="start"
            nowrap
            className={classNames('full-width html-input__box', {
              'mb-3': crossNeeded,
            })}
              >
            <Avatar size={type === 'comment' ? '32px' : isMobile ? '30px' : '50px'} url={user && user.profile_pic} noShadow rounded />
            <Row nowrap className={classNames('html-input__text', { 'full-width': type === 'post' || hideImage })}>
              <div
                contentEditable={!disabled ? 'true' : 'false'}
                onInput={this.handleInput}
                ref={inputRef}
                onKeyUp={this.handleKeyUp}
                className={this.getClassName()}
                placeholder={value || translationText(noonText, placeholder)}
                  />
              {/* <textarea className={this.getClassName()} ref={inputRef} onChange={this.handleInput} placeholder={value || translationText(noonText, placeholder)} name="" id="" cols="30" rows="1" /> */}
              <div className="html-input__text--side-panel">
                <React.Fragment>
                  {!disabled && !isMobileOnly
                    && (
                    <Popover {...popoverPropsForSmiley} className="smiley-popover">
                      <IconNoResult
                        onClick={this.toggleEmoji}
                        width={type === 'comment' ? (isMobileOnly ? '14px' : '20px') : '26px'}
                        height={type === 'comment' ? (isMobileOnly ? '14px' : '20px') : '26px'}
                        fill={COLORS.text.base}
                        />
                    </Popover>
                    )}
                </React.Fragment>
              </div>
            </Row>
            {!this.state.imagePreviewUrl && type !== 'post' && !hideImage && (
              <label htmlFor={`typeOfAttachment-${currentPostIndex}`}>
                <input
                  id={`typeOfAttachment-${currentPostIndex}`}
                  className="html-input__text--side-panel--attach"
                  type="file"
                  onChange={this.handleImageUpload}
                  accept=".jpg, .png"
                          />

                <IconPostWithPlus
                  className="icon"
                  width={type === 'comment' ? (isMobileOnly ? '14px' : '20px') : '26px'}
                  height={type === 'comment' ? (isMobileOnly ? '14px' : '20px') : '26px'}
                  fill={COLORS.text.light} />
              </label>
            )}
          </Row>
          {((markAnswer && (this.state.contentText || !!this.state.imagePreviewUrl)) || !!this.state.imagePreviewUrl) && (
            <Row align="start" justify="space-between" flex="1" className="full-width">
              {!!this.state.imagePreviewUrl && (
                <Row className="relative mlr-1">
                  <img src={this.state.imagePreviewUrl} alt={`${type}`} width="100px" height="100px" />
                  <IconWrong
                    className="individual-image--cross"
                    width="18px"
                    height="18px"
                    onClick={this.removeImage}
                    fill={COLORS.coolGrey[5]}
                    stroke={COLORS.brand.base}
                  />
                </Row>
              )}
              {markAnswer && (this.state.contentText || !!this.state.imagePreviewUrl)
                && (
                <Row align="start" gap="sm" className="plr-2 mtb-1" style={{ cursor: 'pointer' }} onClick={this.markAsCorrect}>
                  <IconCorrectCircle
                    fill={this.state.markAsResolved ? '#31C173' : '#fff'}
                    stroke={this.state.markAsResolved ? '#fff' : COLORS.grey[3]}
                    width="18px"
                    height="18px"
                    className="child"
                    />
                  <Column align="start">
                    <span className="text-capitalize child">{translationText(noonText, 'post.markQuestionAnswered')}</span>
                    {this.state.markAsResolved && (
                    <span className="text-capitalize child errorMessage">{translationText(noonText, 'post.markQuestionAnsweredText')}</span>
                    )}
                  </Column>
                </Row>
                )}
            </Row>
          )}
          {showPublishButton && (this.state.contentText || !!this.state.imagePreviewUrl)
            && (
            <Row flex="1" justify="end" align="center" className="full-width plr-2">
              <Button
                type="primary"
                size="large"
                className="text-capitalize prepare"
                value={translationText(noonText, 'poll.publish')}
                onClick={() => this.sendOperation()}
              />
            </Row>
            )}
        </Column>
      </ErrorBoundry>
    );
  }
}

const humanizeCount = (count) => {
  if (!count) return 0;
  if (Number(count) > 999 && Number(count) <= 999999) {
    return `${(count / 1000).toFixed(1)}K`;
  } else if (Number(count) > 999999) {
    return `${(count / 1000000).toFixed(1)}M`;
  }
  return count;
};

export default humanizeCount;

import React from 'react';
import { useSelector } from 'react-redux';
import { Row } from '@noon/atom';
import { translationText } from '../../helpers';

export default function Breadcrumbs(prop) {
  const { onItemClick, pageTitle } = prop;
  const { noonText } = useSelector((state) => state.toJS().translation);
  return (
    <div className="breadcrumbs-bar">
      <Row align="center" nowrap className="full-width container">
        <div className="parent" onClick={onItemClick}>
          <p>{translationText(noonText, 'tab.mainView')}</p>
        </div>
        <Row className="page-title">
          <span className="mlr-1"> &gt; </span>
          <span>{pageTitle}</span>
        </Row>
      </Row>
    </div>
  );
}

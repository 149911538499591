import { put, call, takeEvery, all, select } from 'redux-saga/effects';
import isEmpty from 'lodash-es/isEmpty';
import {
  likeQuestionSelector,
  likeAnswerSelector,
  likeRepliesSelector,
  allQuestionSelector,
  allAnswerSelector,
  allReplySelector,
  currentUpvoteId,
  questionLikeQueueSelector,
  thankAnswerSelector,
  currentThankId,
  thankQueueSelector,
  currentReplyId,
  answerCountSelector,
  replyCountSelector,
  thankCountSelector,
} from '../selectors/feedQuestions';
import { feedQuesionApi } from '../restApi';
import { addToast, TOAST_TYPE } from '../../components/Toast';

import {
  GET_ALL_QUESTIONS_POSTS,
  CREATE_QUESTIONS_POST,
  CREATE_ANSWER,
  CREATE_REPLY,
  GET_QUESTION_ANSWERS,
  GET_ANSWERS_REPLIES,
  DELETE_ANSWER,
  DELETE_QUESTION,
  DELETE_REPLY,
  UPVOTE_QUESTION,
  THANK_ANSWER,
  LIKE_REPLY,
  GET_COUNT_FOR_QUESTION_FILTER,
  QUEUE_FOR_QUESTION,
  QUEUE_FOR_ANSWER,
  QUEUE_FOR_REPLY,
  ANSWER_COUNT_MAP,
  REPLY_COUNT_MAP,
  THANK_COUNT_MAP,
} from '../constants';

import Mixpanel from '../../components/Mixpanel';

const getQuestionPostsApi = (payload) => feedQuesionApi.get(`teacher/group_post/${payload.group_id}`, { start: payload.start,
  limit: payload.limit,
  ...(!!payload.type && { type: payload.type }),
  ...(!!payload.chapter_id && { chapter_id: payload.chapter_id }),
  ...(!!payload.topic_id && { topic_id: payload.topic_id }),
  ...(!!payload.order && { order: payload.order }),
  ...(!!payload.next_tag && { next_tag: payload.next_tag }),
});

const createQuestionApi = (payload) => feedQuesionApi.post('post', {
  group_id: payload.group_id,
  text: payload.text,
  chapter_id: payload.chapter_id,
  topic_id: payload.topic_id,
  files: payload.files,
  is_free: payload.is_free,
});

const upvoteQuestionApi = (payload) => feedQuesionApi.put(`post/${payload.questionId}/upvote`, {
  group_id: payload.group_id,
});

const downVoteQuestionApi = (payload) => feedQuesionApi.put(`post/${payload.questionId}/remove_upvote`, {
  group_id: payload.group_id,
});

const deleteQuestionApi = (payload) => feedQuesionApi.delete(`post/${payload.questionId}`);

const questionFilterCountApi = (payload) => feedQuesionApi.get(`teacher/group_post/${payload.groupId}/count`);

const getAnswersApi = (payload) => feedQuesionApi.get(`teacher/post/${payload.questionId}/answer`, {
  start: payload.start,
  limit: payload.limit,
  next_tag: payload.next_tag,
});

const addAnswerApi = (payload) => feedQuesionApi.post(`post/${payload.questionId}/answer`, {
  text: payload.text,
  mark_as_resolved: payload.mark_as_resolved,
  files: payload.files,
});

const thankAnswerApi = (payload) => feedQuesionApi.put(`post/${payload.questionId}/answer/${payload.answerId}/say_thank`, {
  group_id: payload.group_id,
});

const removeThankApi = (payload) => feedQuesionApi.put(`post/${payload.questionId}/answer/${payload.answerId}/remove_thank`, {
  group_id: payload.group_id,
});

const deleteAnswerApi = (payload) => feedQuesionApi.delete(`post/${payload.questionId}/answer/${payload.answerId}`);

const addReplyApi = (payload) => feedQuesionApi.post(`answer/${payload.answerId}/comment`, {
  text: payload.text,
});

const getRepliesApi = (payload) => feedQuesionApi.get(`answer/${payload.answerId}/comment`, {
  start: payload.start,
  limit: payload.limit,
  next_tag: payload.next_tag,
});

const deleteReplyApi = (payload) => feedQuesionApi.delete(`answer/${payload.answerId}/comment/${payload.commentId}`);

const likeReplyApiApi = (payload) => feedQuesionApi.put(`answer/${payload.answerId}/comment/${payload.replyId}/like`, {
  qna_post_id: payload.questionId,
});

const unlikeReplyApiApi = (payload) => feedQuesionApi.put(`answer/${payload.answerId}/comment/${payload.replyId}/unlike`, {
  qna_post_id: payload.questionId,
});

function* genericGetList({ likeCountObj, likeVariable, likeAction, getAction, data, start, id }) {
  const likeObjCopy = { ...likeCountObj };
  data.data.forEach((post) => {
    likeObjCopy[post.id] = post[likeVariable];
  });
  yield put({
    type: likeAction.SUCCESS,
    payload: likeObjCopy,
  });
  yield put({
    type: getAction.SUCCESS,
    payload: !isEmpty(data.data)
      ? { list: data.data, start, meta: data.meta, id }
      : { list: [], start: 0, meta: {}, id },
  });
}

function* getQuestionPosts({ payload }) {
  try {
    const response = yield call(getQuestionPostsApi, payload);
    if (response.ok) {
      const likeCountObj = yield select(likeQuestionSelector);
      yield call(genericGetList, { likeCountObj, likeVariable: 'is_upvoted_by_me', likeAction: UPVOTE_QUESTION, getAction: GET_ALL_QUESTIONS_POSTS, data: response.data, start: payload.start });
      yield all(response.data.data.map((list) => put({ type: ANSWER_COUNT_MAP, payload: { id: list.id, count: list.interaction_count.answers } })));

    } else {
      yield put({ type: GET_ALL_QUESTIONS_POSTS.FAILURE, payload: response.data.message });
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
    }
  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: GET_ALL_QUESTIONS_POSTS.FAILURE, payload: err });
  }
}

function* getAnswers({ payload }) {
  try {
    const response = yield call(getAnswersApi, payload);
    if (response.ok) {
      const likeCountObj = yield select(likeAnswerSelector);
      yield call(genericGetList, { likeCountObj, likeVariable: 'is_thanked_by_me', likeAction: THANK_ANSWER, getAction: GET_QUESTION_ANSWERS, data: response.data, start: payload.start, id: payload.questionId });
      yield all(response.data.data.map((list) => put({ type: REPLY_COUNT_MAP, payload: { id: list.id, count: list.interaction_count.comments } })));
      yield all(response.data.data.map((list) => put({ type: THANK_COUNT_MAP, payload: { id: list.id, count: list.interaction_count.thanks } })));
    } else {
      yield put({ type: GET_QUESTION_ANSWERS.FAILURE, payload: response.data.message });
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
    }
  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: GET_QUESTION_ANSWERS.FAILURE, payload: err });
  }
}

function* getReplies({ payload }) {
  try {
    const response = yield call(getRepliesApi, payload);
    if (response.ok) {
      const likeCountObj = yield select(likeRepliesSelector);
      yield call(genericGetList, { likeCountObj, likeVariable: 'is_liked_by_me', likeAction: LIKE_REPLY, getAction: GET_ANSWERS_REPLIES, data: response.data, start: payload.start, id: payload.answerId });
    } else {
      yield put({ type: GET_ANSWERS_REPLIES.FAILURE, payload: response.data.message });
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
    }
  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: GET_ANSWERS_REPLIES.FAILURE, payload: err });
  }
}

function* sendQuestion({ payload }) {

  try {
    const response = yield call(createQuestionApi, payload);

    if (response.ok) {

      yield put({
        type: GET_ALL_QUESTIONS_POSTS.REQUEST,
        payload: { group_id: payload.group_id,
          start: 0,
          limit: 3 },
      });
      yield put({
        type: CREATE_QUESTIONS_POST.SUCCESS,
        payload: !isEmpty(response.data.data) ? response.data.data : null,
      });
    } else {
      yield put({ type: CREATE_QUESTIONS_POST.FAILURE, payload: response.data.message });
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
    }
  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: CREATE_QUESTIONS_POST.FAILURE, payload: err });
  }
}

function* sendAnswer({ payload }) {
  try {
    const response = yield call(addAnswerApi, payload);
    if (response.ok) {
      const oldAnswerCount = yield select(answerCountSelector);
      yield put({ type: ANSWER_COUNT_MAP, payload: { id: payload.questionId, count: oldAnswerCount[payload.questionId] + 1 } });
      yield put({
        type: GET_QUESTION_ANSWERS.REQUEST,
        payload: { questionId: payload.questionId,
          start: 0,
          limit: 3 },
      });
      yield put({
        type: CREATE_ANSWER.SUCCESS,
      });
    } else {
      yield put({ type: CREATE_ANSWER.FAILURE, payload: response.data.message });
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
    }
  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: CREATE_ANSWER.FAILURE, payload: err });
  }
}

function* sendReplies({ payload }) {
  try {
    const response = yield call(addReplyApi, payload);
    if (response.ok) {
      const oldReplyCount = yield select(replyCountSelector);
      yield put({ type: REPLY_COUNT_MAP, payload: { id: payload.answerId, count: oldReplyCount[payload.answerId] + 1 } });
      yield put({
        type: GET_ANSWERS_REPLIES.REQUEST,
        payload: { answerId: payload.answerId,
          start: 0,
          limit: 3 },
      });
      yield put({
        type: CREATE_REPLY.SUCCESS,
      });
    } else {
      yield put({ type: CREATE_REPLY.FAILURE, payload: response.data.message });
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
    }
  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: CREATE_REPLY.FAILURE, payload: err });
  }
}

function* deleteGenericCall({ data, listAction, deleteAction, payload, response, filterId, id }) {
  const reducerPostList = { ...data };
  const filteredList = reducerPostList.list.filter((value) => value.id !== payload[filterId]);
  const { meta } = reducerPostList;
  yield put({
    type: listAction.SUCCESS,
    payload: meta.total
      ? {
        list: filteredList,
        start: 0,
        meta: {
          ...meta,
          total: meta.total - 1,
          paging: meta.paging ? { ...meta.paging, start: meta.paging && meta.paging.start - 1 } : null,
        },
        id,
      }
      : { list: [], start: 0, meta: {}, id },
  });

  if ((meta.total && filteredList.length === 1) || isEmpty(filteredList)) {
    yield put({
      type: listAction.SUCCESS,
      payload: { list: [], start: 0, meta: {}, id },
    });
    yield put({ type: listAction.REQUEST, payload });
  }

  yield put({
    type: deleteAction.SUCCESS,
    payload: !isEmpty(response.data.data) ? response.data.data : null,
  });
}

function* deleteQuestion({ payload }) {
  try {
    const response = yield call(deleteQuestionApi, payload);
    if (response.ok) {
      const allQuestions = yield select(allQuestionSelector);
      yield call(deleteGenericCall, { data: allQuestions, listAction: GET_ALL_QUESTIONS_POSTS, deleteAction: DELETE_QUESTION, response, payload, filterId: 'questionId' });
    } else {
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
      yield put({ type: DELETE_QUESTION.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: DELETE_QUESTION.FAILURE, payload: err });
  }
}

function* deleteAnswer({ payload }) {
  try {
    const response = yield call(deleteAnswerApi, payload);
    if (response.ok) {
      let allAnswers = yield select(allAnswerSelector);
      allAnswers = allAnswers[payload.questionId] || [];
      yield call(deleteGenericCall, { data: allAnswers, listAction: GET_QUESTION_ANSWERS, deleteAction: DELETE_ANSWER, payload, response, filterId: 'answerId', id: payload.questionId });
      const oldAnswerCount = yield select(answerCountSelector);
      yield put({ type: ANSWER_COUNT_MAP, payload: { id: payload.questionId, count: oldAnswerCount[payload.questionId] - 1 } });
    } else {
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
      yield put({ type: DELETE_ANSWER.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: DELETE_ANSWER.FAILURE, payload: err });
  }
}

function* deleteReply({ payload }) {
  try {
    const response = yield call(deleteReplyApi, payload);
    if (response.ok) {
      let allReplies = yield select(allReplySelector);
      allReplies = allReplies[payload.answerId] || [];
      yield call(deleteGenericCall, { data: allReplies, listAction: GET_ANSWERS_REPLIES, deleteAction: DELETE_REPLY, payload, response, filterId: 'commentId', id: payload.answerId });
      const oldReplyCount = yield select(replyCountSelector);
      yield put({ type: REPLY_COUNT_MAP, payload: { id: payload.answerId, count: oldReplyCount[payload.answerId] + 1 } });
    } else {
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
      yield put({ type: DELETE_REPLY.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: DELETE_REPLY.FAILURE, payload: err });
  }
}

function* questionFilterCount({ payload }) {
  try {
    const response = yield call(questionFilterCountApi, payload);
    if (response.ok) {
      yield put({
        type: GET_COUNT_FOR_QUESTION_FILTER.SUCCESS,
        payload: response.data.data[0] || {},
      });
    } else {
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
      yield put({ type: GET_COUNT_FOR_QUESTION_FILTER.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: GET_COUNT_FOR_QUESTION_FILTER.FAILURE, payload: err });
  }
}

function* upvoteQuestion({ payload }) {

  try {
    const upvoteValueForQuestionId = yield select(likeQuestionSelector);
    upvoteValueForQuestionId[payload.questionId] = payload.reaction;
    yield put({
      type: UPVOTE_QUESTION.SUCCESS,
      payload: upvoteValueForQuestionId,
    });
    const currentQuestionIdInProgress = yield select(currentUpvoteId);
    const { questionId } = payload;
    if (currentQuestionIdInProgress.id === questionId) {
      yield put({
        type: QUEUE_FOR_QUESTION,
        payload: { id: payload.questionId, reaction: payload.reaction },
      });
    } else {
      yield put({
        type: 'CURRENT_ID_FOR_UPVOTE',
        payload: { id: payload.questionId },
      });
      const response = payload.reaction ? yield call(upvoteQuestionApi, payload) : yield call(downVoteQuestionApi, payload);
      if (response.ok) {
        const questionUpvoteQueue = yield select(questionLikeQueueSelector);
        if (questionUpvoteQueue[payload.questionId] !== undefined && (questionUpvoteQueue[payload.questionId] !== payload.reaction)) {
          yield put({
            type: 'CURRENT_ID_FOR_UPVOTE',
            payload: { id: payload.questionId },
          });
          yield put({
            type: UPVOTE_QUESTION.REQUEST,
            payload: { group_id: payload.groupId, questionId: payload.questionId, reaction: questionUpvoteQueue[payload.questionId] },
          });
        } else {
          yield put({
            type: 'CURRENT_ID_FOR_UPVOTE',
            payload: {},
          });
        }
      } else {

        addToast('something went wrong', TOAST_TYPE.ERROR);
        yield put({ type: UPVOTE_QUESTION.FAILURE, payload: 'Network Error' });
      }
    }

  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: UPVOTE_QUESTION.FAILURE, payload: err });
  }
}

function* thankAnswer({ payload }) {
  try {
    const upvoteValueForQuestionId = yield select(thankAnswerSelector);
    upvoteValueForQuestionId[payload.answerId] = payload.reaction;
    yield put({
      type: UPVOTE_QUESTION.SUCCESS,
      payload: upvoteValueForQuestionId,
    });
    const currentAnswerIdInProgress = yield select(currentThankId);

    const oldThankCount = yield select(thankCountSelector);
    yield put({ type: THANK_COUNT_MAP, payload: { id: payload.answerId, count: payload.reaction ? oldThankCount[payload.answerId] + 1 : oldThankCount[payload.answerId] - 1 } });
    const { answerId } = payload;
    if (currentAnswerIdInProgress.id === answerId) {
      yield put({
        type: QUEUE_FOR_QUESTION,
        payload: { id: answerId, reaction: payload.reaction },
      });
    } else {
      yield put({
        type: 'CURRENT_ID_FOR_THANK',
        payload: { id: answerId },
      });
      yield call(payload.reaction ? thankAnswerApi : removeThankApi, payload);
      const thankQueue = yield select(thankQueueSelector);
      if (thankQueue[answerId] !== undefined && (thankQueue[answerId] !== payload.reaction)) {
        yield put({
          type: 'CURRENT_ID_FOR_THANK',
          payload: { id: answerId },
        });
        yield put({
          type: THANK_ANSWER.REQUEST,
          payload: { group_id: payload.groupId, questionId: payload.questionId, reaction: thankQueue[answerId], answerId },
        });
      } else {
        yield put({
          type: 'CURRENT_ID_FOR_THANK',
          payload: {},
        });
      }

    }

  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: THANK_ANSWER.FAILURE, payload: err });
  }
}

function* likeReply({ payload }) {
  try {
    const likeValueForReplyId = yield select(thankAnswerSelector);
    likeValueForReplyId[payload.replyId] = payload.reaction;
    yield put({
      type: UPVOTE_QUESTION.SUCCESS,
      payload: likeValueForReplyId,
    });
    const currentReplyIdInProgress = yield select(currentReplyId);
    const { replyId } = payload;
    if (currentReplyIdInProgress.id === replyId) {
      yield put({
        type: QUEUE_FOR_REPLY,
        payload: { id: replyId, reaction: payload.reaction },
      });
    } else {
      yield put({
        type: 'CURRENT_ID_FOR_REPLY',
        payload: { id: replyId },
      });
      yield call(payload.reaction ? likeReplyApiApi : unlikeReplyApiApi, payload);
      const likeQueue = yield select(thankQueueSelector);
      if (likeQueue[replyId] !== undefined && (likeQueue[replyId] !== payload.reaction)) {
        yield put({
          type: QUEUE_FOR_REPLY,
          payload: { id: replyId },
        });
        yield put({
          type: LIKE_REPLY.REQUEST,
          payload: { questionId: payload.questionId, reaction: likeQueue[replyId], replyId, answerId: payload.answerId },
        });
      } else {
        yield put({
          type: 'CURRENT_ID_FOR_REPLY',
          payload: {},
        });
      }

    }

  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: THANK_ANSWER.FAILURE, payload: err });
  }
}

export function* feedQuestion() {
  yield all([
    takeEvery(GET_ALL_QUESTIONS_POSTS.REQUEST, getQuestionPosts),
    takeEvery(GET_QUESTION_ANSWERS.REQUEST, getAnswers),
    takeEvery(GET_ANSWERS_REPLIES.REQUEST, getReplies),
    takeEvery(CREATE_QUESTIONS_POST.REQUEST, sendQuestion),
    takeEvery(CREATE_ANSWER.REQUEST, sendAnswer),
    takeEvery(CREATE_REPLY.REQUEST, sendReplies),
    takeEvery(UPVOTE_QUESTION.REQUEST, upvoteQuestion),
    takeEvery(THANK_ANSWER.REQUEST, thankAnswer),
    takeEvery(LIKE_REPLY.REQUEST, likeReply),
    takeEvery(DELETE_QUESTION.REQUEST, deleteQuestion),
    takeEvery(DELETE_ANSWER.REQUEST, deleteAnswer),
    takeEvery(DELETE_REPLY.REQUEST, deleteReply),
    takeEvery(GET_COUNT_FOR_QUESTION_FILTER.REQUEST, questionFilterCount),
  ]);
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import isEmpty from 'lodash-es/isEmpty';
import { debounce } from 'lodash-es';
import { NoonLoader, Column } from '@noon/atom';
import { isMobileOnly } from 'react-device-detect';
import { WhiteBoard } from '@noon/molecule';
import PlaybackSocketContainer from './PlaybackSocketContainer';
import Fullscreen from '../Layout/fullscreen';
import { RotateToLandscapePlaceholder } from '../Placeholder';
import { matchType, classType, userType, translationType } from '../../types';
import { GetReadyScreen, ClassroomQuestion, VoteAnimation, ExitClassModal, RatingModal } from '../Class';
import { slugify } from '../../helpers';
import PlayerChat from './PlayerChat';
import VideoPlayer from './VideoPlayer';
import PlayerHeader from './PlayerHeader';
import { addToast, TOAST_TYPE } from '../Toast';
import Mixpanel from '../Mixpanel';

class Play extends Component {
  static propTypes = {
    sessionData: classType.isRequired,
    match: matchType.isRequired,
    user: userType.isRequired,
    noonText: translationType.isRequired,
    getSessionForReplay: PropTypes.func.isRequired,
    clearSession: PropTypes.func.isRequired,
    token: PropTypes.string,
    tutoringError: PropTypes.bool.isRequired,
    temporaryTokenPayload: PropTypes.shape().isRequired,
    history: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    token: null,
  };

  constructor(props) {
    super(props);
    const classId = props.match.params.id.split('-');
    const playerWidth = this.calculateWidth();
    this.state = {
      goBack: false,
      id: classId[classId.length - 1],
      fullscreen: false,
      playerWidth,
      playerHeight: playerWidth * (9 / 16),
      showRatingModal: false,
      teacher: {},
      startTime: new Date().getTime(),
    };
    this.whiteboardNode = null;
    this.initialTime = new Date().getTime();
  }

  componentDidMount() {
    const { getSessionForReplay } = this.props;
    window.addEventListener('resize', this.handleResize);
    getSessionForReplay(this.state.id);
    const { tenant } = localStorage.temporaryTokenPayload ? JSON.parse(localStorage.temporaryTokenPayload) : {};
    document.body.addEventListener('error', this.handleErrorCapture, true);
  }

  componentDidUpdate({ sessionData, match, history, tutoringError }) {
    if (!isEmpty(this.props.sessionData) && sessionData !== this.props.sessionData) {
      this.sendMixpanelEvent('teacher_enters_replay');
      const slug = slugify(sessionData.title, this.state.id);
      if (slug !== match.params.id) {
        history.replace(`/${localStorage.updatedLocale}/play/${slug}`);
      }
    }
    if (!isEmpty(this.props.tutoringError) && tutoringError !== this.props.tutoringError) {
      if (tutoringError.getClassReplay) {
        addToast(tutoringError.getClassReplay, TOAST_TYPE.ERROR, 5);
        this.close(tutoringError.getClassReplay);
      }
    }
  }

  handleErrorCapture = (event) => {
    const temporaryTokenPayload = JSON.parse(localStorage.temporaryTokenPayload || '{}');
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    document.body.removeEventListener('error', this.handleErrorCapture, true);
  }

  sendMixpanelEvent = (event, data) => {
    try {
      const { user, sessionData } = this.props;
      const { id } = this.state;
      const mixpanelData = {
        teacher_id: user.id,
        session_id: id,
        os: localStorage.multiTenantLogin,
        session_type: sessionData.class_type,
        subject: sessionData.product ? sessionData.product.name : '',
        group_id: localStorage.lastGroupForReplay ? JSON.parse(localStorage.getItem('lastGroupForReplay')).id : 0,
      };
      if (event === 'teacher_played_replay') {
        mixpanelData.session_duration = data.duration;
        mixpanelData.current_users_count = data.currentActiveUsersCount;
        mixpanelData.peak_users_count = (data.peakActiveUsersCount && data.peakActiveUsersCount.length) || 0;
      } else if (event === 'teacher_paused_replay') {
        mixpanelData.paused_duration = data.paused_duration / 1000;
      } else if (event === 'teacher_seeked_replay') {
        mixpanelData.time_seeked = data.time_seeked;
        mixpanelData.perc_total_duration_seeked = `${data.perc_total_duration_seeked * 100}%`;
      }
      Mixpanel.track(event, mixpanelData);
    } catch (e) {
      console.log(e);
    }
  };

  handleResize = () => {
    const playerWidth = this.calculateWidth(this.state.fullscreen);
    this.setState({
      playerWidth,
      playerHeight: playerWidth * (9 / 16),
    });
  };

  calculateWidth = (flag) => {
    const w = window.innerWidth > 991 ? (flag ? window.innerWidth : window.innerWidth - 350) : window.innerWidth;
    const h = window.innerWidth > 991 ? window.innerHeight - 130 : window.innerHeight;
    const playerRatio = 16 / 9;
    const widthToHeightRatio = w / h;
    let calculatedWidth = w;
    if (widthToHeightRatio >= playerRatio) {
      calculatedWidth = playerRatio * h;
    }
    return calculatedWidth;
  };

  onScrollContainer = (top, height) => {
    if (!!this.whiteboardNode && !!this.whiteboardNode.clientHeight) {
      this.whiteboardNode.scrollTop = top * (this.whiteboardNode.clientHeight / height);
    }
  };

  toggleFullScreen = (fullscreen) => {
    this.setState({ fullscreen }, () => {
      this.handleResize();
    });
  };

  ratingModal = () => {
    this.setState({
      showRatingModal: true,
    });
  };

  close = (err, data) => {
    const { user, sessionData } = this.props;
    const { peakActiveUsersCount, currentActiveUsersCount } = data || {};
    // ignore mixpanel , if close due to session error
    if (!isEmpty(sessionData)) {
      try {
        const mixpanelData = {
          student_id: user.id,
          session_id: sessionData.id,
          session_type: sessionData.class_type,
          subject: sessionData.product ? sessionData.product.name : '',
          group_id: localStorage.lastGroupForReplay ? JSON.parse(localStorage.getItem('lastGroupForReplay')).id : 0,
          time_spent: (new Date().getTime() - this.initialTime) / 1000,
          current_users_count: currentActiveUsersCount,
          peak_users_count: peakActiveUsersCount && peakActiveUsersCount.length,
        };
        Mixpanel.track('teacher_exits_replay', mixpanelData);
      } catch (e) {
        console.log(e);
      }
    }
    this.props.clearSession({});
    this.setState({ goBack: true });
  };

  handlePlayerClick = () => {
    if (this.state.fullscreen && window.innerWidth <= 991) {
      this.handleToggleFullScreen();
    }
  };

  handleToggleFullScreen = debounce(() => {
    this.setState(
      (prevState) => ({ fullscreen: !prevState.fullscreen }),
      () => {
        this.handleResize();
      },
    );
  }, 100);

  render() {
    const { user, sessionData, noonText, token, temporaryTokenPayload } = this.props;
    const { fullscreen, goBack, playerWidth, playerHeight } = this.state;
    if (goBack === true) {
      const groupUrl = localStorage.lastGroupUrl ? JSON.parse(localStorage.getItem('lastGroupUrl')) : {};
      return <Redirect to={!isEmpty(groupUrl) ? groupUrl : `/${localStorage.updatedLocale}/schedule`} />;
    }
    return (
      <React.Fragment>
        <RotateToLandscapePlaceholder />
        {!isEmpty(sessionData) && sessionData.id ? (
          <React.Fragment>
            <Helmet>
              <meta name="description" content={sessionData.title} />
              <meta property="og:description" content={sessionData.title} />
            </Helmet>
            <PlaybackSocketContainer
              sessionData={sessionData}
              user={user}
              playback
              token={token}
              noonText={noonText}
              onExit={this.close}
              toggleGuestSignupModal={this.toggleGuestSignupModal}
              temporaryTokenPayload={temporaryTokenPayload}
              onScrollContainer={this.onScrollContainer}
            >
              {({
                studentSupportArr,
                sketchData,
                activeSlideData,
                activeSlide,
                whiteboardRef,
                seekData,
                playerState,
                handlePlay,
                handlePause,
                chats,
                toggleChat,
                isChatVisible,
                setSessionDuration,
                questionTimer,
                getReadyTimer,
                showQuestion,
                recordTempSketch,
                playbackUsers,
                audioStartTime,
                peakActiveUsersCount,
                currentActiveUsersCount,
              }) => (
                <Fullscreen enabled={fullscreen} onChange={this.toggleFullScreen}>
                  <div>
                    {isChatVisible === true && isMobileOnly ? (
                      ''
                    ) : (
                      <PlayerHeader
                        noonText={noonText}
                        playbackUsers={playbackUsers}
                        sessionData={sessionData}
                        peakActiveUsersCount={peakActiveUsersCount}
                        currentActiveUsersCount={currentActiveUsersCount}
                        onClose={this.close}
                      />
                    )}

                    <div className="playback old">
                      <div className="playback__overlay" onClick={this.handlePlayerClick} />
                      <PlayerChat
                        noonText={noonText}
                        chats={chats}
                        active={isChatVisible}
                        fullscreen={fullscreen}
                        toggleChat={toggleChat}
                      />

                      {this.state.showRatingModal && (
                        <RatingModal
                          actionType="playback"
                          sessionValues={sessionData}
                          teacher={sessionData.creator}
                          exitClass={this.close}
                          startTime={this.state.startTime}
                          peakActiveUsersCount={peakActiveUsersCount}
                          currentActiveUsersCount={currentActiveUsersCount}
                        />
                      )}

                      <Column nowrap>
                        <div
                          ref={(node) => {
                            this.whiteboardNode = node;
                          }}
                          className="playback__body"
                        >
                          {showQuestion && (
                            <div
                              className="playback__question"
                              style={{ width: `${playerWidth}px`, height: `${playerHeight}px` }}
                            >
                              {getReadyTimer > 0 && (
                                <GetReadyScreen
                                  timer={getReadyTimer}
                                  questionData={activeSlideData.resource}
                                  noonText={noonText}
                                />
                              )}
                              {getReadyTimer <= 0 && (
                                <ClassroomQuestion
                                  slide={activeSlideData}
                                  timer={questionTimer}
                                  userData={user}
                                  showAnswer={!questionTimer}
                                  noonText={noonText}
                                />
                              )}
                            </div>
                          )}
                          {playerWidth > 0 && (
                            <WhiteBoard
                              ref={whiteboardRef}
                              slideNumber={activeSlide}
                              defaultEvents={sketchData}
                              disableToolBar
                              socketConnection={{
                                send: (ev) => {
                                  if (['MOVE', 'UP', 'DOWN'].includes(ev.e)) {
                                    recordTempSketch(ev.id);
                                  }
                                },
                              }}
                              drawOnlyMode={playerState === 'play'}
                              width={playerWidth}
                            />
                          )}
                        </div>
                        {sessionData.archive_url && (
                          <VideoPlayer
                            fullscreen={fullscreen}
                            state={playerState}
                            seekData={seekData}
                            chatCount={chats && chats.length}
                            toggleChat={toggleChat}
                            handlePause={handlePause}
                            audioStartTime={audioStartTime}
                            handlePlay={handlePlay}
                            setSessionDuration={setSessionDuration}
                            sendMixpanelEvent={this.sendMixpanelEvent}
                            url={sessionData.archive_url}
                            onToggleFullScreen={this.handleToggleFullScreen}
                            sessionData={sessionData}
                            user={user}
                            peakActiveUsersCount={peakActiveUsersCount}
                            currentActiveUsersCount={currentActiveUsersCount}
                            playbackUsers={playbackUsers}
                            showQuestion={showQuestion}
                          />
                        )}
                      </Column>
                    </div>
                    <VoteAnimation studentSupportArr={studentSupportArr} />
                  </div>
                </Fullscreen>
              )}
            </PlaybackSocketContainer>
          </React.Fragment>
        ) : (
          <NoonLoader />
        )}
      </React.Fragment>
    );
  }
}

export default Play;

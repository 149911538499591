import { put, call, takeEvery, all, select } from 'redux-saga/effects';
import isEmpty from 'lodash-es/isEmpty';
import { noonTextSelector } from 'redux/selectors/translations';
import { athenaApi, athenaApiV2 } from '../restApi';
import { getCountryId } from '../../constants';
import { addToast, TOAST_TYPE } from '../../components/Toast';
import {
  JOIN_CLASS,
  GET_CLASS_REPLAY,
  CREATE_GROUP_TUTORING_FOR_TEACHER,
  UPDATE_GROUP_TUTORING,
  UPCOMING_TEACHER_SESSION,
  MY_PREVIOUS_GROUP_TUTORING,
  GROUP_TUTORING_UNIQUE_VALUES,
  GROUP_TUTORING_REVIEWS,
  CONFIG_REASON,
  START_GROUP_TUTORING,
  VOTE_TEACHER,
  DELETE_SESSION,
  START_ARCHIVE,
  TOGGLE_PLAYBACK,
  TOKBOX_SESSION,
} from '../constants';
import { translationText } from '../../helpers';

const myPreviousGroupTutoringData = (state) => state.toJS().tutoring.myPreviousGroupTutoring;

const getClassForReplayAPI = (id) => athenaApiV2.get(`playback/teacher/class/${id}`);
const joinClassAPI = (payload) => athenaApiV2.get(`session/joinClass/${payload.tutoring_logger_id}`);
const sessionsForGroupAPI = (data) => athenaApiV2.get(`sessions/sessionsForGroupForTeacher/${data.group_id}`, data);
const nextEligibileSessionAPI = (data) => athenaApiV2.get(`sessions/nextEligibleSession/${data.group_id}`);
const sessionsForGroupWithFiltersAPI = (data) => athenaApiV2.get(`sessions/sessionsForGroupForTeacherWithFilters/${data.group_id}`, data);
const scheduledGroupTutoringForTeacherAPI = (data) => athenaApiV2.get('sessions/scheduledGroupTutoringForTeacher', data);
const previousScheduledGroupTutoringAPI = (data) => athenaApiV2.get('sessions/previousSessionsForTeacher', data);
const previousSessionsForGroupWithFiltersAPI = (data) => athenaApiV2.get(`sessions/previousSessionsForGroupForTeacherWithFilters/${data.group_id}`, data);
const getGroupTutoringUniqueValuesAPI = (sessionId) => athenaApi.get(`groupTutoringUniqueValues/${sessionId.sessionId}`);
const getGroupTutoringReviewsAPI = (data) => athenaApi.get('groupTutoringReviews', data);
const getReasonsAPI = () => athenaApi.get('configs/reason');
const createTokboxSessionAPI = () => athenaApi.post('create_session/tokbox');
const updateGroupTutoringAPI = (data) => athenaApi.put('/teacher/session', data);
const deleteSessionAPI = (id) => athenaApi.put('teacher/session/hide', { id });

const createGroupTutoringForTeacherAPI = (data) => athenaApi.post('teacher/session', data);
const startGroupTutoringAPI = (payload) => athenaApi.post('teacher/startSession', payload);
const voteTeacherAPI = (data) => athenaApi.put('rate/teacher', data);
const startArchiveAPI = (data) => athenaApi.put(`startArchive/${data.tutoring_logger_id}`);

const teacherPlaybackToggleAPI = (payload) => athenaApi.put('teacher/updateTeacherEnabledReplay', payload);

const getUpComingSessions = (state) => state.toJS().tutoring.upcomingTeacherSession;

function generateProductsFromTags(items) {
  items.map((item) => {
    const copyOFItem = item;
    if (copyOFItem.curriculum_tags) {
      if (copyOFItem.curriculum_tags.type === 'k12') {
        copyOFItem.product = copyOFItem.curriculum_tags.subject;
      } else if (copyOFItem.curriculum_tags.type === 'k12_test_prep') {
        copyOFItem.product = copyOFItem.curriculum_tags.test;
      } else if (copyOFItem.curriculum_tags.type === 'k12_skill') {
        copyOFItem.product = copyOFItem.curriculum_tags.skill;
      } else if (copyOFItem.curriculum_tags.type === 'university') {
        copyOFItem.product = copyOFItem.curriculum_tags.course;
      } else if (copyOFItem.curriculum_tags.type === 'university_test_prep') {
        copyOFItem.product = copyOFItem.curriculum_tags.test;
      } else if (copyOFItem.curriculum_tags.type === 'university_skill') {
        copyOFItem.product = copyOFItem.curriculum_tags.skill;
      } else {
        copyOFItem.product = { name: copyOFItem.curriculum_tags.type };
      }
    } else {
      copyOFItem.curriculum_tags = {};
    }
  });
  return items;
}

function* joinClass({ payload }) {
  try {
    const response = yield call(joinClassAPI, payload);
    if (response.ok) {
      yield put({
        type: JOIN_CLASS.SUCCESS,
        payload: isEmpty(response.data.data) ? {} : generateProductsFromTags(response.data.data)[0],
      });
    } else {
      yield put({ type: JOIN_CLASS.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: JOIN_CLASS.FAILURE, payload: err });
  }
}

function* startArchive({ payload }) {
  try {
    const response = yield call(startArchiveAPI, payload);
    if (response.ok) {
      yield put({
        type: START_ARCHIVE.SUCCESS,
        payload: { id: payload.tutoring_logger_id },
      });
    } else {
      yield put({ type: START_ARCHIVE.FAILURE, payload: response.problem });
    }
  } catch (err) {
    yield put({ type: START_ARCHIVE.FAILURE, payload: err });
  }
}

function* teacherPlaybackToggle({ payload }) {
  try {
    const response = yield call(teacherPlaybackToggleAPI, payload);
    if (response.ok) {
      const data = yield select(myPreviousGroupTutoringData);
      const responseData = data.response;
      const pageValue = data.page;

      const objIndex = responseData.findIndex(((obj) => obj.id === payload.session_id));
      const updatedValue = { ...responseData[objIndex], teacher_enabled: payload.teacher_enabled };
      const filterData = [
        ...responseData.slice(0, objIndex),
        updatedValue,
        ...responseData.slice(objIndex + 1),
      ];
      yield put({
        type: TOGGLE_PLAYBACK.SUCCESS,
      });
      yield put({
        type: MY_PREVIOUS_GROUP_TUTORING.SUCCESS,
        payload: { type: 'toggle', response: filterData, page: pageValue, isLoading: false },
      });
    } else {
      yield put({ type: TOGGLE_PLAYBACK.FAILURE, payload: response.problem });
    }
  } catch (err) {
    yield put({ type: TOGGLE_PLAYBACK.FAILURE, payload: err });
  }
}

function* getGroupTutoringUniqueValues({ payload }) {
  try {
    const response = yield call(getGroupTutoringUniqueValuesAPI, payload);
    if (response.ok) {
      yield put({
        type: GROUP_TUTORING_UNIQUE_VALUES.SUCCESS,
        payload: isEmpty(response.data) ? {} : response.data,
      });
    } else {
      yield put({ type: GROUP_TUTORING_UNIQUE_VALUES.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: GROUP_TUTORING_UNIQUE_VALUES.FAILURE, payload: err });
  }
}

function* getGroupTutoringReviews({ payload }) {
  try {
    const response = yield call(getGroupTutoringReviewsAPI, payload);
    if (response.ok) {
      yield put({
        type: GROUP_TUTORING_REVIEWS.SUCCESS,
        payload: isEmpty(response.data) && payload.page === 1
          ? { page: 1, list: [] }
          : { page: payload.page, list: response.data },
      });
    } else {
      yield put({ type: GROUP_TUTORING_REVIEWS.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: GROUP_TUTORING_REVIEWS.FAILURE, payload: err });
  }
}

function* myPreviousGroupTutoring({ payload }) {
  try {
    const apiToCall = payload.chapter_id ? previousSessionsForGroupWithFiltersAPI : previousScheduledGroupTutoringAPI;
    const response = yield call(apiToCall, payload);
    if (response.ok) {
      yield put({
        type: MY_PREVIOUS_GROUP_TUTORING.SUCCESS,
        payload:
          isEmpty(response.data.data) && payload.page === 1
            ? { page: 1, list: [], limit: 20 }
            : { page: payload.page, list: response.data.data, limit: payload.limit },
      });
    } else {
      yield put({ type: MY_PREVIOUS_GROUP_TUTORING.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: MY_PREVIOUS_GROUP_TUTORING.FAILURE, payload: err });
  }
}

function* upcomingTeacherSessions({ payload }) {
  try {
    const apiToCall = (() => {
      if (payload.insideGroup) {
        return nextEligibileSessionAPI;
      }
      if (payload.group_id) {
        return payload.chapter_id ? sessionsForGroupWithFiltersAPI : sessionsForGroupAPI;
      }
      return scheduledGroupTutoringForTeacherAPI;
    })();
    const response = yield call(apiToCall, payload);
    if (response.ok) {
      yield put({
        type: UPCOMING_TEACHER_SESSION.SUCCESS,
        payload:
          isEmpty(response.data.data) && payload.page === 1
            ? { page: 1, list: [], meta: response.data.meta, limit: 20 }
            : { page: payload.page,
              limit: payload.limit,
              list: response.data.data.map((item) => {
                if (!item.group && payload.group_id) {
                  return {
                    ...item,
                    group: { id: payload.group_id },
                  };
                }
                return item;
              }),
              meta: response.data.meta,
            },
      });
    } else {
      yield put({ type: UPCOMING_TEACHER_SESSION.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: UPCOMING_TEACHER_SESSION.FAILURE, payload: err });
  }
}

function* createGroupTutoringForTeacher({ payload }) {
  const noonText = yield select(noonTextSelector);
  try {
    const response = yield call(createGroupTutoringForTeacherAPI, {
      country_id: getCountryId(),
      ...payload,
    });
    if (response.ok) {
      yield put({
        type: CREATE_GROUP_TUTORING_FOR_TEACHER.SUCCESS,
        payload: { id: response.data.id },
      });
      addToast(translationText(noonText, 'tutoring.sessionCreated'), TOAST_TYPE.SUCCESS);
      // addToast('Session created', TOAST_TYPE.SUCCESS);
    } else {
      yield put({ type: CREATE_GROUP_TUTORING_FOR_TEACHER.FAILURE, payload: response.data.message });
      if (response.data && response.data.message) {
        addToast(response.data.message, TOAST_TYPE.ERROR);
      }
    }
  } catch (err) {
    yield put({ type: CREATE_GROUP_TUTORING_FOR_TEACHER.FAILURE, payload: err });
  }
}

function* updateGroupTutoring({ payload }) {
  try {
    const response = yield call(updateGroupTutoringAPI, payload);
    if (response.ok) {
      yield put({
        type: UPDATE_GROUP_TUTORING.SUCCESS,
        payload: { id: payload.id },
      });
      let upcomingSessions = yield select(getUpComingSessions);
      upcomingSessions = upcomingSessions.response;
      const updatedSessionData = upcomingSessions.map((session) => {
        if (session.id === payload.id) {
          return {
            ...session,
            ...payload,
          };
        }
        return session;
      });
      yield put({
        type: UPCOMING_TEACHER_SESSION.SUCCESS,
        payload: { page: 1, list: updatedSessionData, notPageUpdate: true },
      });
    } else {
      yield put({ type: UPDATE_GROUP_TUTORING.FAILURE, payload: response.data.message });
      if (response.data && response.data.message) {
        addToast(response.data.message, TOAST_TYPE.ERROR);
      }
    }
  } catch (err) {
    yield put({ type: UPDATE_GROUP_TUTORING.FAILURE, payload: err });
  }
}

function* deleteSession({ payload }) {
  try {
    const response = yield call(deleteSessionAPI, payload.id);
    if (response.ok) {
      yield put({
        type: DELETE_SESSION.SUCCESS,
        payload: { id: payload.id },
      });
      let upcomingSessions = yield select(getUpComingSessions);
      upcomingSessions = upcomingSessions.response;
      const updatedSessionData = upcomingSessions.filter((session) => session.id !== payload.id);
      yield put({
        type: UPCOMING_TEACHER_SESSION.SUCCESS,
        payload:
          isEmpty(updatedSessionData) && payload.page === 1
            ? { page: 1, list: [] }
            : { page: 1, list: updatedSessionData, notPageUpdate: true },
      });

    } else {
      yield put({ type: DELETE_SESSION.FAILURE, payload: response.data.message });
      if (response.data && response.data.message) {
        addToast(response.data.message, TOAST_TYPE.ERROR);
      }
    }
  } catch (err) {
    yield put({ type: DELETE_SESSION.FAILURE, payload: err });
  }
}

function* getReasons({ payload }) {
  try {
    const response = yield call(getReasonsAPI, payload);
    if (response.ok) {
      yield put({
        type: CONFIG_REASON.SUCCESS,
        payload: isEmpty(response.data.data) ? [] : response.data.data,
      });
    } else {
      yield put({
        type: CONFIG_REASON.FAILURE,
        payload: response.data.message,
      });
    }
  } catch (err) {
    yield put({
      type: CONFIG_REASON.FAILURE,
      payload: 'خطأ في الخادم',
    });
  }
}

function* createTokboxSession() {
  try {
    const response = yield call(createTokboxSessionAPI);
    if (response.ok) {
      yield put({
        type: TOKBOX_SESSION.SUCCESS,
        payload: isEmpty(response.data) ? [] : response.data,
      });
    } else {
      yield put({
        type: TOKBOX_SESSION.FAILURE,
        payload: response.data.message,
      });
    }
  } catch (err) {
    yield put({
      type: TOKBOX_SESSION.FAILURE,
      payload: 'خطأ في الخادم',
    });
  }
}

function* startGroupTutoring({ payload }) {
  try {
    const response = yield call(startGroupTutoringAPI, payload);
    if (response.ok) {
      yield put({
        type: START_GROUP_TUTORING.SUCCESS,
        payload: isEmpty(response.data.data) ? {} : response.data.data[0],
      });
    } else {
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
      yield put({ type: START_GROUP_TUTORING.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: START_GROUP_TUTORING.FAILURE, payload: err });
  }
}

function* getClassForReplay({ payload: id }) {
  try {
    const response = yield call(getClassForReplayAPI, id);
    if (response.ok) {
      yield put({
        type: GET_CLASS_REPLAY.SUCCESS,
        payload: isEmpty(response.data.data) ? {} : generateProductsFromTags(response.data.data)[0],
      });
    } else {
      yield put({ type: GET_CLASS_REPLAY.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: GET_CLASS_REPLAY.FAILURE, payload: err });
  }
}

function* voteTeacher({ payload }) {
  try {
    const response = yield call(voteTeacherAPI, payload);
    if (response.ok) {
      yield put({ type: VOTE_TEACHER.SUCCESS, payload: { success: response.status === 204 } });
    } else {
      yield put({ type: VOTE_TEACHER.FAILURE, payload: 'error' });
    }
  } catch (err) {
    yield put({ type: VOTE_TEACHER.FAILURE, payload: err });
  }
}

export function* tutoringSaga() {
  yield all([
    takeEvery(GET_CLASS_REPLAY.REQUEST, getClassForReplay),
    takeEvery(JOIN_CLASS.REQUEST, joinClass),
    takeEvery(START_ARCHIVE.REQUEST, startArchive),
    takeEvery(TOGGLE_PLAYBACK.REQUEST, teacherPlaybackToggle),
    takeEvery(MY_PREVIOUS_GROUP_TUTORING.REQUEST, myPreviousGroupTutoring),
    takeEvery(CREATE_GROUP_TUTORING_FOR_TEACHER.REQUEST, createGroupTutoringForTeacher),
    takeEvery(UPDATE_GROUP_TUTORING.REQUEST, updateGroupTutoring),
    takeEvery(CONFIG_REASON.REQUEST, getReasons),
    takeEvery(TOKBOX_SESSION.REQUEST, createTokboxSession),
    takeEvery(UPCOMING_TEACHER_SESSION.REQUEST, upcomingTeacherSessions),
    takeEvery(GROUP_TUTORING_UNIQUE_VALUES.REQUEST, getGroupTutoringUniqueValues),
    takeEvery(GROUP_TUTORING_REVIEWS.REQUEST, getGroupTutoringReviews),
    takeEvery(START_GROUP_TUTORING.REQUEST, startGroupTutoring),
    takeEvery(VOTE_TEACHER.REQUEST, voteTeacher),
    takeEvery(DELETE_SESSION.REQUEST, deleteSession),
  ]);
}

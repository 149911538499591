/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React, { PureComponent, createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep, findIndex, isEmpty } from 'lodash-es';
import { Row, Button, Modal, Column } from '@noon/atom';
import { IconClose, IconPublish, IconEyeO, IconPlus } from '@noon/quark';
import { isTablet, isIPad13 } from 'react-device-detect';
import QuestionBuilderComponent from './QuestionBuilderComponent';
import PopUpDialog from './PopUpdialog';
import PreviewQuestionModal from '../../MyLibrary/question-preview-modal';
import ChecklistComponent from './ChecklistComponent';
import QuestionBuilderCoacMark from './QuestionBuilderCoachMark';
import uploadFile from './uploadFile';
import { ADD_IMAGE_FROM_QUESTION_IMAGE_LOADED, ADD_IMAGE_FROM_QUESTION_ONBOARDING_COMPLETE, CREATE_QUESTION } from '../../../redux/constants';
import AddNewImageIcon from '../../../assets/icons/AddNewImageIcon';
import { translationText } from '../../../helpers';
import handleMixpanelKafkaActivityEvents from './eventsHelper';
import { addToast, TOAST_TYPE } from '../../Toast';
import { getTagIds } from '../../../helpers/curriculumTags';
import ChapterTopicMultiSelect from '../../Curriculum/chapterTopicMultiSelect';

class AddQuestionFromImage extends PureComponent {
  constructor() {
    super();
    this.state = {
      src: null,
      crops: [],
      crop: {},
      preview: false,
      selectedCrop: -1,
      popUpExpanded: false,
      isLoading: false,
      lessonData: null,
      scrollLeft: 0,
      scrollHeight: 0,
      startTime: Date.now(),
      previewQuestionData: {},
    };
    this.logger = LoggingManager.mount({ moduleName: 'classroom' });
    this.imageRef = null;
    this.containerRef = null;
    this.fileInputRef = createRef();
    this.textDirection = document.body.dir;
  }

  canSave() {
    const { crops } = this.state;
    const correctAnswerSelected = crops.filter((c) => c.isCorrectAnswer).length > 0;
    return correctAnswerSelected;
  }

  componentDidMount() {

    const ref = document.getElementById('addon-container');
    this.containerRef = ref;
  }

  handleError() {
    this.setState({
      isLoading: false,
    });
  }

  componentDidUpdate(prevProps) {
    const { createQuestionData, onDone, questionError, curriculumTags } = this.props;
    const { lessonData, startTime } = this.state;

    if (questionError !== prevProps.questionError && questionError.createQuestionData) {
      addToast('Something went wrong, please try again', TOAST_TYPE.ERROR);
      this.handleError();
    }

    if (this.canSave() && prevProps.createQuestionData !== createQuestionData) {
      handleMixpanelKafkaActivityEvents({ type: 'itq_ended' });
      const { chapter_id } = lessonData;
      const { topic_id } = lessonData;
      handleMixpanelKafkaActivityEvents({
        type: 'teacher_click_save_question',
        data: {
          time_spent: Math.floor((Date.now() - startTime) / 1000),
          country: curriculumTags?.country?.name,
          is_image: true,
          is_latex: false,
          chapter: chapter_id,
          topic: topic_id,
          is_clone: false,
          question_id: createQuestionData?.question_id[0],
          channel: 'direct image', // ‘library’; ‘direct image’; ‘direct text’
        },
      });
      onDone(createQuestionData);
    }
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {

    const { imageLoaded } = this.props;
    const aspect_ratio = image.width / image.height;
    this.imageRef = image;

    // mixpanel data
    const imageSrc = image.src;
    const img = imageSrc.substring(imageSrc.indexOf(',') + 1);
    const image_size = `${img.length * 0.001} KB`;
    handleMixpanelKafkaActivityEvents({ type: 'itq_image_loaded', data: { image_size, aspect_ratio } });
    imageLoaded();

  };

  onCropComplete = (cropIndex) => {

    // make sure that the crop is of minimum width and height
    const { crops } = cloneDeep(this.state);
    const crop = crops[cropIndex];
    crop.cropIndex = cropIndex;
    console.log(crop.cropIndex);
    if (crop.width <= 0) crop.width = 150;
    if (crop.height <= 0) crop.height = 50;

    crops[cropIndex] = crop;
    this.setState({
      showPopup: true,
      selectedCrop: cropIndex,
      crops,
    });

  };

  onCropStart = (crop, cropIndex) => {
    if (!this.state.crops[cropIndex]) {
      this.setState({
        crops: [...this.state.crops, crop],
        crop,
        showPopup: false,
        selectedCrop: cropIndex,
        popUpExpanded: false,
      });
      // this.makeClientCrop(crop, cropIndex);
    }
  };

  onCropChange = (crop, cropIndex) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    if (this.state.crops[cropIndex]) {
      const { crops } = this.state;
      crops[cropIndex] = cloneDeep(crop);
      this.setState({ crops, crop, showPopup: false, popUpExpanded: false, selectedCrop: cropIndex });
    }
  };

  onCropSlected = (crop, cropIndex) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    if (this.state.crops[cropIndex]) {
      const { crops } = this.state;
      crops[cropIndex] = cloneDeep(crop);
      this.setState({ crops, crop, showPopup: false, selectedCrop: cropIndex, popUpExpanded: false });
    }
  };

  async makeClientCrop(crop, fileName) {

    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        fileName || 'newFile.jpeg',
      );
      return croppedImageUrl;
    }
    return null;
  }

  getCroppedImg(image, crop, fileName) {
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const canvas = document.createElement('canvas');
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          // reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;

        if (crop.croppedImageUrl) window.URL.revokeObjectURL(crop.croppedImageUrl);
        const newCroppedImageUrl = window.URL.createObjectURL(blob);
        resolve(newCroppedImageUrl);
      }, 'image/jpeg');
    });
  }

  markAsAnswer(cropIndex) {
    const crops = cloneDeep(this.state.crops);
    const crop = crops[cropIndex];
    crop.isAnswer = true;
    crop.isQuestion = false;
    crop.isCorrectAnswer = false;
    this.setState({ crops, selectedCrop: cropIndex });
    handleMixpanelKafkaActivityEvents({ type: 'itq_answer_highlighted' });
  }

  markAsQuestion(cropIndex) {
    const crops = cloneDeep(this.state.crops);
    const crop = crops[cropIndex];
    crop.isAnswer = false;
    crop.isQuestion = true;
    crop.isCorrectAnswer = false;
    this.setState({ crops, selectedCrop: cropIndex });
    handleMixpanelKafkaActivityEvents({ type: 'itq_question_highlighted' });
  }

  markAsCorrectAnswer(cropIndex) {
    const crops = cloneDeep(this.state.crops);
    const crop = crops[cropIndex];
    crop.isAnswer = false;
    crop.isQuestion = false;
    crop.isCorrectAnswer = true;
    this.setState({ crops, selectedCrop: cropIndex });
    const correct_answer = findIndex(crops, (crop) => crop.isCorrectAnswer);
    handleMixpanelKafkaActivityEvents({ type: 'itq_correct_answer_marked', data: { correct_answer } });
  }

  delete(cropIndex) {
    const crops = cloneDeep(this.state.crops);
    crops.splice(cropIndex, 1);
    const crop = this.state.crops.length > 0 ? this.state.crops[this.state.crops.length - 1] : {};
    this.setState({
      crops,
      crop,
      selectedCrop: -1,
      showPopup: false,
    });
    handleMixpanelKafkaActivityEvents({ type: 'itq_highlight_deleted' });
  }

  popupCollapsed() {
    this.setState({
      popUpExpanded: false,
      showPopup: false,
    });
  }

  popUpExpanded() {
    this.setState({
      popUpExpanded: true,
      showPopup: true,
    });
  }

  onPreview(event) {
    // save the scrolling infor
    this.setState({
      preview: true,
    });
    this.handleOnAddQuestion(event, true);
    handleMixpanelKafkaActivityEvents({ type: 'itq_preview' });
  }

  onPreviewClosed() {
    this.setState({
      preview: false,
    });
  }

  handleOnAddQuestion(event, isPreview = false) {
    const crops = cloneDeep(this.state.crops);
    this.onAddQuestion(crops, isPreview);
  }

  async onAddQuestion(crops, isPreview) {
    const { lessonDetails } = this.props;
    try {
      // upload blobs to files
      const filesToUpload = [];
      for (const [index, crop] of crops.entries()) {
      // eslint-disable-next-line no-await-in-loop
        const croppedImageUrl = crop.croppedImageUrl ? crop.croppedImageUrl : await this.makeClientCrop(crop, `crop${index}.jpeg`);
        // eslint-disable-next-line no-await-in-loop
        const ObjfromURI = await fetch(croppedImageUrl);

        // eslint-disable-next-line no-await-in-loop
        const blobFromObj = await ObjfromURI.blob();
        blobFromObj.name = `${(crop.isQuestion ? 'QuestionBody' : 'Answer') + index}.${blobFromObj.type.split('/')[1]}`;
        filesToUpload.push(blobFromObj);
      }
      const bulkuploadRequests = filesToUpload.map((f) => uploadFile(f));
      const uploadedFiles = await Promise.all(bulkuploadRequests);
      const imagetoQuestionMap = crops.map((c, index) => {
        delete c.evData;
        delete c.croppedImageUrl;
        return {
          ...c,
          file_type: 'image',
          name: uploadedFiles[index].name,
          original: uploadedFiles[index].large_url,
        };
      });

      // if there is no lesson data ; skip saving to library and send it directly to the canvas
      if (isPreview) {
        this.previewQuestion(imagetoQuestionMap);
      } else if (!isEmpty(lessonDetails)) {
        this.setState({ isLoading: true });
        this.saveQuestion(imagetoQuestionMap);
      } else {
        this.setState({ isLoading: true });
        this.sendQuestionToCanvas(imagetoQuestionMap);
      }
    } catch (e) {
      addToast('Could not save question', TOAST_TYPE.ERROR);
      console.error(e);
      this.setState(
        {
          isLoading: false,
        },
      );
    }
  }

  handleLessonSelection(lessonData) {
    const { lessonDetails } = this.props;
    const chapter_remapped = lessonDetails.chapter_id !== lessonData.chapter_id;
    const topic_remapped = lessonDetails.topic_id !== lessonData.topic_id;
    this.setState({
      lessonData,
    });
    handleMixpanelKafkaActivityEvents({ type: 'itq_remapped', data: { chapter_remapped, topic_remapped } });
  }

  getQuestionPayload(imagetoQuestionMap) {
    const questions = imagetoQuestionMap.filter((c) => c.isQuestion);

    return {
      category_type: 'practice',
      question_type_id: 1,
      text_direction: this.textDirection,
      question: `
        <div className="image-to-text-question-body">
          ${questions.map((q, i) => `<img alt="${i}" className="image-to-text-question" src="${q.original}" width="${q.width}" />`)}
        </div>
      `,
      question_json: questions.map((q) => ({
        type: 'paragraph',
        value: [
          {
            type: 'image',
            height: String(Math.floor(q.height)),
            width: String(Math.floor(q.width)),
            value: q.original,
          },
        ],
      })),
      solution: '',
      verified: 1,
      publish: 1,
      all_tag_details: [],
      difficulty: 1,
    };
  }

  getChoicesPayload(imagetoQuestionMap) {
    const answers = imagetoQuestionMap.filter((c) => c.isAnswer || c.isCorrectAnswer);
    return answers.map((answer, answerIndex) => ({ ...answer,
      is_correct: answer.isCorrectAnswer ? 1 : 0,
      answer: `
        <div className="image-to-text-answer-body">
        <img alt="${answerIndex}" className="image-to-answer-question" src="${answer.original}" width="${answer.width}" />
        </div>
      `,
      answer_json: [{
        type: 'paragraph',
        value: [
          {
            type: 'image',
            height: String(Math.floor(answer.height)),
            width: String(Math.floor(answer.width)),
            value: answer.original,
          },
        ],
      }],
    }));
  }

  saveQuestion(imagetoQuestionMap) {
    // reducer -> tutoring.sessionDetails
    const { curriculumTags, createQuestion } = this.props;
    const { lessonData } = this.state;
    const { chapter_id, chapter_name, chapter_header } = lessonData;
    const { topic_id, topic_name, topic_header } = lessonData;
    const question = this.getQuestionPayload(imagetoQuestionMap);
    const choices = this.getChoicesPayload(imagetoQuestionMap);

    curriculumTags.chapter = { ...curriculumTags.chapter, id: chapter_id, name: chapter_name, name_header: chapter_header };
    curriculumTags.topic = { id: topic_id, name: topic_name, name_header: topic_header };

    curriculumTags.curriculum = curriculumTags.curriculum ? curriculumTags.curriculum[0] || curriculumTags.curriculum : null;

    const dottedTagIds = getTagIds(curriculumTags);
    if (dottedTagIds) {
      const payload = {
        question,
        choices,
        country_id: curriculumTags.country.id,
        chapter_id,
        topic_id,
        tags: dottedTagIds,
      };

      const question_body_count = question.question_json.length;
      const answers_count = choices.length;
      const { markOnboardingComplete } = this.props;
      markOnboardingComplete();
      handleMixpanelKafkaActivityEvents({ type: 'itq_question_created', data: { question_body_count, answers_count } });
      createQuestion(payload);

    }
  }

  sendQuestionToCanvas(imagetoQuestionMap) {
    const { onDone } = this.props;
    const questions = imagetoQuestionMap.filter((c) => c.isQuestion);
    const answers = imagetoQuestionMap.filter((c) => c.isAnswer || c.isCorrectAnswer)
      .sort((a, b) => a.cropIndex - b.cropIndex);

    const questionData = {
      question: `
      <div className="image-to-text-question-body">
        ${questions.map((q, i) => `<img alt="${i}" className="image-to-text-question" src="${q.original}" width="${q.width}" />`)}
      </div>
    `,
      question_json: questions.map((q) => ({
        type: 'paragraph',
        value: [
          {
            type: 'image',
            height: String(Math.floor(q.height)),
            width: String(Math.floor(q.width)),
            value: q.original,
          },
        ],
      })),
      text_direction: 'ltr',
      question_type_name: 'mcq',
      choices: answers.map((answer, answerIndex) => ({ ...answer,
        is_correct: answer.isCorrectAnswer ? 1 : 0,
        answer: `
            <div className="image-to-text-answer-body">
            <img alt="${answerIndex}" className="image-to-answer-question" src="${answer.original}" width="${answer.width}" />
            </div>
          `,
        answer_json: [{
          type: 'paragraph',
          value: [
            {
              type: 'image',
              height: String(Math.floor(answer.height)),
              width: String(Math.floor(answer.width)),
              value: answer.original,
            },
          ],
        }],
      })),
    };
    const question_body_count = questions.length;
    const answers_count = answers.length;
    handleMixpanelKafkaActivityEvents({ type: 'itq_question_created', data: { question_body_count, answers_count } });
    handleMixpanelKafkaActivityEvents({ type: 'itq_ended' });
    const { markOnboardingComplete } = this.props;
    markOnboardingComplete();
    setTimeout(() => {
      onDone({ questionData });
    }, 100);

  }

  handleOnclose() {
    const { onClose } = this.props;
    handleMixpanelKafkaActivityEvents({ type: 'itq_canceled ' });
    onClose();
  }

  handeCropsReorder(crops) {
    this.setState({
      crops,
    });
  }

  handleScroll(e) {
    const { scrollLeft, scrollTop } = e.target;
    this.setState({
      scrollLeft,
      scrollHeight: scrollTop,
    });
  }

  previewQuestion(imagetoQuestionMap) {
    this.setState({
      previewQuestionData: {
        ...this.getQuestionPayload(imagetoQuestionMap),
        choices: this.getChoicesPayload(imagetoQuestionMap),
      },
    });
  }

  render() {
    const { previewQuestionData, lessonData, crop, src, preview, crops, showPopup, selectedCrop, popUpExpanded, isLoading, scrollLeft, scrollHeight } = this.state;
    const { groupId, noonText, onBoarding, lessonDetails, curriculumContent } = this.props;
    const preChapterId = curriculumContent?.chapterId || lessonDetails.chapter_id;
    const preTopicId = curriculumContent?.topicId || lessonDetails.topic_id;
    const questionSelected = crops.filter((c) => c.isQuestion).length > 0;
    const answerSelected = crops.filter((c) => c.isAnswer).length >= 1;
    const correctAnswerSelected = crops.filter((c) => c.isCorrectAnswer).length > 0;
    const isTocuhScreen = isTablet || isIPad13 ? 'tablet' : '';
    console.log(crops);
    return (
      (src && preview && questionSelected && answerSelected && previewQuestionData.question_json) ? (
        <PreviewQuestionModal
          groupId={groupId}
          handleSelectedTopic={this.handleLessonSelection.bind(this)}
          selectedCurriculum={lessonData || lessonDetails}
          questionData={previewQuestionData}
          onClose={this.onPreviewClosed.bind(this)}
          isLoading={isLoading}
          saveQuestion={this.handleOnAddQuestion.bind(this)}
         />
      )
        : (
          <Modal className={`add-question-from-image-container ${isTocuhScreen}`} padding={10} style={{ zIndex: '1' }}>
            <Row className="header" align="center">
              <h1>Create question from image</h1>
              <IconClose className="close" height="18px" width="18px" onClick={this.handleOnclose.bind(this)} />
            </Row>
            <Row className="body">
              {src && (
              <Row justify="space-between" align="center" gap className="info-container">
                <div className="flex-2">
                  <ChecklistComponent crops={crops} />
                </div>
                <div className="flex-1">
                  <ChapterTopicMultiSelect
                    groupId={groupId}
                    onTopicSelect={this.handleLessonSelection.bind(this)}
                    preChapterId={preChapterId}
                    preTopicId={preTopicId}
                    className="flex-1"
                    showNote
            />
                </div>
              </Row>
              )}
              {!src && (
              <div className="add-image-container">
                <input style={{ display: 'none' }} ref={this.fileInputRef} type="file" accept="image/*" onChange={this.onSelectFile} />
                <Column>
                  <AddNewImageIcon className="add-image-icon" />
                  <Button className="add-image-button" type="primary" size="lg" onClick={() => this.fileInputRef.current.click()}>
                    <IconPublish rotate="90" />
                    {translationText(noonText, 'image2question.DialogUploadButton')}
                  </Button>
                  <p className="add-image-text">
                    Upload an image to start converting it into interactive multiple choice question
                    {/* {translationText(noonText, 'image2question.DialogUploadText')} */}
                  </p>
                </Column>
              </div>
              ) }
              <Row dir="ltr">
                <div id="addon-container" />
              </Row>
              {src && (
              <QuestionBuilderComponent
            // eslint-disable-next-line no-return-assign
                src={src}
                crop={crop}
                crops={crops}
                onImageLoaded={this.onImageLoaded}
                onStart={this.onCropStart}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
                onSelected={this.onCropSlected}
                showAddon={showPopup}
                addonContainer={this.containerRef}
                activeCrop={selectedCrop}
                scrollLeft={scrollLeft}
                scrollHeight={scrollHeight}
                onScroll={this.handleScroll.bind(this)}
                renderSelectionAddon={({ crop, cropIndex }) => {
                  const { isQuestion, isAnswer, isCorrectAnswer } = crop;
                  return (
                    <PopUpDialog
                      isQuestion={isQuestion}
                      isAnswer={isAnswer}
                      isCorrectAnswer={isCorrectAnswer}
                      cropIndex={cropIndex}
                      onMarkAsAnswer={this.markAsAnswer.bind(this)}
                      onMarkAsQuestion={this.markAsQuestion.bind(this)}
                      onMarkAsCorrectAnswer={this.markAsCorrectAnswer.bind(this)}
                      onDelete={this.delete.bind(this)}
                      canShow={!popUpExpanded}
                      isActive={cropIndex === selectedCrop}
                      onPopupExpanded={this.popUpExpanded.bind(this)}
                      onPopupCollapsed={this.popupCollapsed.bind(this)}
                      questionSelected={questionSelected}
                      correctAnswerSelected={correctAnswerSelected} />
                  );
                }}
          />
              )}
            </Row>
            {/* <p className='filename'>Uploaded image file {src}</p> */}
            <Row className="footer" gap>
              {(questionSelected && answerSelected && correctAnswerSelected)
                ? (
                  <Button className="preview" type="secondary" size="xl" onClick={this.onPreview.bind(this)}>
                    <IconEyeO height="24px" width="24px" />
                    {translationText(noonText, 'image2question.DialogPreview')}
                  </Button>
                )
                : <Button className="preview" type="secondary" size="xl" onClick={this.handleOnclose.bind(this)}>{translationText(noonText, 'image2question.PopupCancel')}</Button>}
              <Button
                type="primary"
                size="xl"
                loading={isLoading}
                disabled={!(questionSelected && answerSelected && correctAnswerSelected)}
                onClick={this.handleOnAddQuestion.bind(this)}>
                <IconPlus />
                {translationText(noonText, 'image2question.DialogSave')}
              </Button>
            </Row>
            {src && onBoarding && <QuestionBuilderCoacMark crops={crops} canRun={!popUpExpanded} /> }
          </Modal>
        )
    );
  }
}

AddQuestionFromImage.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  curriculumTags: PropTypes.shape({
    curriculum: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })),
    topic: PropTypes.shape({
      id: PropTypes.string,
    }),
    country: PropTypes.shape({
      id: PropTypes.string,
    }),
    topics: [],
    chapter: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  createQuestion: PropTypes.func.isRequired,
  noonText: PropTypes.shape([]).isRequired,
  createQuestionData: PropTypes.shape({}).isRequired,
  questionError: PropTypes.shape({}).isRequired,
  onBoarding: PropTypes.bool.isRequired,
  imageLoaded: PropTypes.func.isRequired,
  lessonDetails: PropTypes.shape({}),
  markOnboardingComplete: PropTypes.func.isRequired,
};

AddQuestionFromImage.defaultProps = {
  lessonDetails: {},
};

const mapDispatchToProps = (dispatch) => ({
  createQuestion: (payload) => dispatch({ type: CREATE_QUESTION.REQUEST, payload }),
  imageLoaded: () => dispatch({ type: ADD_IMAGE_FROM_QUESTION_IMAGE_LOADED }),
  markOnboardingComplete: () => dispatch({ type: ADD_IMAGE_FROM_QUESTION_ONBOARDING_COMPLETE }),

});

const mapStateToProps = (state) => ({
  createQuestionData: state.toJS().question.createQuestionData,
  questionError: state.toJS().question.error,
  noonText: state.toJS().translation.noonText,
  lessonDetails: state.toJS().addQuestionFromImage.lessonDetails,
  curriculumContent: state.toJS().content,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddQuestionFromImage);

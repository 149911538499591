import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Column, Card, Button } from '@noon/atom';
import { useSelector, useDispatch } from 'react-redux';
import List from 'react-virtualized/dist/es/List';
import AutoSizer from 'react-virtualized/dist/es/AutoSizer';
import CellMeasurer, { CellMeasurerCache } from 'react-virtualized/dist/es/CellMeasurer';
import HowItWorks from './HowItWorks';
import TeamMessage from './TeamMessage';
import { START_BREAKOUT, END_BREAKOUT, QNA_START_BREAKOUT, TAKE_SCREEN_SHOT, QNA_GET_MESSAGES, GET_BREAKOUT_TEAMS } from '../../../redux/constants.js';
import t from '../../../helpers/translate';

const DEFAULT_ROW_HEIGHT = 60;
const CHAT_LIST_WIDTH = 300;

const cache = new CellMeasurerCache({
  defaultHeight: DEFAULT_ROW_HEIGHT,
  fixedWidth: true,
});

export default function TeamQna() {

  const { messages, qnaBreakoutOngoing, qnaBreakoutStarted } = useSelector((state) => state.toJS().TeamQna);
  const { breakoutTeamFormed, breakoutState } = useSelector((state) => state.toJS().myClassroom);

  const dispatch = useDispatch();
  const [tooltip, showTooltip] = useState(false);

  const lastChatCount = useRef(0);
  const lastListHeightRef = useRef(0);
  const listRef = useRef();
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);

  const onScrollToBottom = () => {
    // @ts-ignore
    if (listRef.current && listRef.current.scrollToRow) listRef.current.scrollToRow(0);
  };

  const rowRenderer = useCallback(({ index, key, parent, style }) => {
    const message = messages[index];
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
        >
        {({ registerChild, measure }) => (
          <div key={key} ref={registerChild} style={style}>
            <TeamMessage message={message} />
          </div>
        )}
      </CellMeasurer>
    );
  }, [messages]);

  const onRowsRendered = useCallback(({ stopIndex }) => {
    if (stopIndex + 2 > lastChatCount.current) {
      setShowScrollToBottom(false);
      if (lastChatCount.current !== messages.length) {
        onScrollToBottom();
      }
    } else if (!showScrollToBottom) {
      setShowScrollToBottom(true);
    }
    lastChatCount.current = messages.length;
  }, [lastChatCount.current, messages, listRef, showScrollToBottom]);

  useEffect(() => {
    // if last chat send by self, than force scroll to bottom
    if (lastChatCount.current !== messages.length && messages[messages.length - 1]) {
      onScrollToBottom();

    }
    cache.clearAll();
  }, [lastChatCount.current, messages.length]);

  const startBreakout = () => {
    dispatch({ type: TAKE_SCREEN_SHOT,
      payload: {
        type: QNA_START_BREAKOUT,
      } });
    dispatch({
      type: START_BREAKOUT,
    });
  };

  useEffect(() => {
    if (breakoutState && !qnaBreakoutStarted) {
      dispatch({
        type: END_BREAKOUT,
      });
    }
  }, [qnaBreakoutStarted]);

  useEffect(() => {
    dispatch({ type: GET_BREAKOUT_TEAMS.REQUEST });
    dispatch({ type: QNA_GET_MESSAGES.REQUEST });

  }, []);

  return (
    <div className="team-qna-container">
      { tooltip && <HowItWorks onClose={() => showTooltip(false)} />}
      <Row className="info-container">
        <div className="question-count">{`${messages.length} ${t('teamQnaBreakout', 'questionsAsked')}`}</div>
        <div className="how-it-works" onClick={() => showTooltip(true)}>{t('teamQnaBreakout', 'howItWorks')}</div>
      </Row>
      <Column className="padding">
        <Card className="cta">
          <h4>{t('teamQnaBreakout', 'title')}</h4>
          <p>{t('teamQnaBreakout', 'description')}</p>
          <Button type="primary" size="lg" onClick={startBreakout} disabled={breakoutTeamFormed && !qnaBreakoutOngoing ? null : true}>{t('teamQnaBreakout', 'start')}</Button>
        </Card>
        { messages.length > 0 && (
        <AutoSizer disableWidth className="message-window">
          {({ height }) => {
            //  this is used to scroll down to last msg when user opens emoji container and list size changes
            if (!showScrollToBottom && lastListHeightRef.current && height !== lastListHeightRef.current) {
              setTimeout(() => onScrollToBottom(), 0);
            }
            lastListHeightRef.current = height;
            return (
              <List
                ref={listRef}
                deferredMeasurementCache={cache}
                height={height}
                width={CHAT_LIST_WIDTH}
                rowCount={messages.length}
                rowHeight={cache.rowHeight}
                onRowsRendered={onRowsRendered}
                rowRenderer={rowRenderer}
              />
            );
          }}
        </AutoSizer>
        )}

      </Column>
    </div>
  );
}

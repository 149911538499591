import React from 'react';
import { Row, Column } from '@noon/atom';
import { IconExclamationCircle, IconLoaderDotted } from '@noon/quark';
import t from '../../helpers/translate';

const ReconnectingModal = (prop) => {
  const { playerWidth } = prop;
  const playerHeight = playerWidth * (9 / 16);

  return (
    <Column style={{ width: `${playerWidth}px`, height: `${playerHeight}px` }} className="network-issue plr-4 ptb-4" nowrap justify="center">
      <Row justify="center">
        <Row className="network-issue--heading mb-4" flex={1}>
          {/* Connection Interrupted */}
          {t('classroom', 'connectionInterrupted')}
        </Row>
        <IconLoaderDotted height="40px" width="40px" fill="#000" stroke="#000" />
      </Row>
      <div className="mb-4">
        {t('classroom', 'brokenConnection')}
        {/* Your Voice/whiteboard Connection got broken, you're seeing this screen here so that none of
      drawing or voice gets lost. */}
        <br />
        <br />
        {t('classroom', 'connectingAutomatically')}
        {/* We're automatically reconnecting you. This whiteboard will be back soon. */}
      </div>

      <Row align="center" nowrap className="network-issue--tipBox mt-1">
        <div className="headingTip">
          <IconExclamationCircle height="25px" width="25px" fill="#fff" rotate="180" />
          <span>
            {t('classroom', 'connectionTip')}
            {/* Tip */}
          </span>
        </div>
        <Row className="contentTips" flex={1}>
          <span>
            {t('classroom', 'connectionTipOne')}
            {/* You can still chat with your students from the sidebar */}
          </span>
          <span>
            {t('classroom', 'connectionTipTwo')}
            {/* You can upload your PDF notes to use after reconnecting */}
          </span>
        </Row>
      </Row>
    </Column>
  );
};

export default ReconnectingModal;

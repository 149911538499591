import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { translationText } from '../../helpers';
import { Row, Vr, Flex } from '@noon/atom';
import { OnboardingMessages } from '../../components/LoginSignup';

class Approval extends Component {
  /* eslint-disable react/prop-types */
  componentDidMount() {
    if (this.props.user.publish) {
      this.props.history.push(`/${localStorage.updatedLocale}/home`);
    }
  }
  componentDidUpdate() {
    if (this.props.user.publish) {
      this.props.history.push(`/${localStorage.updatedLocale}/home`);
    }
  }

  render() {
    const { noonText } = this.props;
    return (
      <Row nowrap className="guest-route-wrapper approval">
        {!isMobile && (
          <React.Fragment>
            <Flex value="1">
              {/* <div className="approval__video" /> */}
              <OnboardingMessages />
            </Flex>
            <Vr />
          </React.Fragment>
        )}
        <Flex value="1" align="center" className="approval__message">
          <h2>{translationText(noonText, 'onboardingTeacher.approvalHeading')}</h2>
          <p>{translationText(noonText, 'onboardingTeacher.approvalDescription')}</p>
        </Flex>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  noonText: state.toJS().translation.noonText,
  user: state.toJS().user.loggedUser,
});

export default connect(mapStateToProps)(Approval);

import { DateTime } from 'luxon';

export function checkIfQuestionExist(payload, currentQuestions) {
  const { question, chapter, topic } = payload;
  if (currentQuestions.map((exQuestion) => exQuestion.id === question.id).includes(true) || question === undefined) {
    return currentQuestions;
  }
  return currentQuestions.concat({
    ...question,
    chapter_info: {
      id: chapter.chapter_id,
      name: chapter.name,
    },
    topic_info: {
      id: topic.topic_id,
      name: topic.name,
    },
  });

}

export function deleteQuestion(index, currentQuestions) {
  const changedCurrentQuestions = currentQuestions;
  changedCurrentQuestions.splice(index, 1);
  return changedCurrentQuestions;
}

export function changeOrderOfCurrentQuestions(moveInfo, currentQuestions) {
  const { removedIndex, addedIndex, payload } = moveInfo;
  const changedCurrentQuestions = currentQuestions;
  if (removedIndex !== null && addedIndex !== null && payload !== undefined) {
    changedCurrentQuestions.splice(removedIndex, 1);
    changedCurrentQuestions.splice(addedIndex, 0, payload);
  }

  return changedCurrentQuestions;
}

export function checkIsMobile() {
  return window.innerWidth <= 767;
}

export function checkIsTablet() {
  return window.innerWidth <= 991 && window.innerWidth >= 768;
}

export function prepareDateForPosting(time, dateValue) {
  if (time && dateValue) {
    const date = DateTime.fromISO(`${dateValue}T${DateTime.fromMillis(time).toFormat('HH:mm')}`);
    return date.toMillis();
  }
  return 0;
}

export const returnDuplicatePropsForMixpanelAndKafka = (group, user, hw_details) => {
  if (!hw_details) return;
  const { group_info = {}, curriculum_tags = {} } = group;
  const {
    title: homework_title = '',
    due_date: homework_due_date = null,
    state = '',
    id: homework_id = null,
  } = hw_details;
  const homework_state = state === 'ended' ? 'past_due_date' : state === 'scheduled' ? 'draft_scheduled' : state === 'active' ? 'published' : 'draft';

  // eslint-disable-next-line consistent-return
  return ({
    teacher_id: user.user_id,
    group_id: group_info.id,
    subject: curriculum_tags.subject ? curriculum_tags.subject.name : 'N/A',
    subject_id: curriculum_tags.subject ? curriculum_tags.subject.id : 'N/A',
    group_privacy: group_info.type,
    homework_state,
    homework_title,
    homework_due_date,
    homework_id,
    // group_grade: curriculum_tags.grade.name,
    // group_country: country.name,
    // group_subscription: group_info.is_premium ? 'paid' : 'free',
  });
};

export const setHWChapterAndTopicInLocalStorage = (hw, type, id) => {
  const hws_data = localStorage.lastChapterTopic ? JSON.parse(localStorage.lastChapterTopic) : undefined;
  localStorage.lastChapterTopic = JSON.stringify({
    ...hws_data,
    [hw.id]: hws_data && hws_data[hw.id]
      ? {
        ...hws_data[hw.id],
        [type]: id,
      }
      : {
        [type]: id,
      },
  });
  return true;
};

export const checkIfHWsExist = (existingQuestions, comingQuestions) => {
  const newList = existingQuestions;
  let isExist = 0;
  let withNewQuestions = false;

  newList.forEach((question) => {
    comingQuestions.forEach((newQuestion) => {
      if (newQuestion.id === question.id) {
        isExist += 1;
      }
    });
  });

  if (isExist !== existingQuestions.length) withNewQuestions = true;

  return isExist ? withNewQuestions ? comingQuestions : newList : newList.concat(comingQuestions);
};

export const setHowManyTimes = () => {
  localStorage.isTeacherShownTheTip = true;
};

export const returnSavedLastChapterTopic = () => (localStorage.lastChapterTopic ? JSON.parse(localStorage.lastChapterTopic) : undefined);

import React from 'react';
import classNames from 'classnames';
import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';
import { IconArrowDown, IconArrowDownRound, IconBlock, IconClockO, IconComment, IconCommentO, IconCorrectCircle, IconDelete, IconExclamationCircle, IconLoaderDotted, IconPlus, IconQuestionFeed, IconRemove, IconUnblock, IconUnblockAndAdd } from '@noon/quark';
import isEmpty from 'lodash-es/isEmpty';
import { Column, Row, FormattedText, NoonDate, Avatar, Modal, Button, MoreActions, Badge } from '@noon/atom';
import { translationText } from '../../../helpers';
import { MultipleImages } from '../index';
import ImageWithOrientation from '../../Layout/imageWithOrientation';
import { COLORS } from '../../../constants';
import PostModal from '../postModal';
import Answer from './answer';
import CreateAnswer from '../createAnswer';

class QuestionAndAnswerContainer extends React.Component {
  static propTypes = {
    currentPostIndex: PropTypes.number.isRequired,
    handleCommentSend: PropTypes.func.isRequired,
    noonText: PropTypes.shape().isRequired,
    emojis: PropTypes.shape().isRequired,
    user: PropTypes.shape().isRequired,
    sendCommentLoader: PropTypes.number.isRequired,
    comments: PropTypes.shape(),
    data: PropTypes.shape().isRequired,
    deleteComment: PropTypes.func.isRequired,
    showPostView: PropTypes.func,
    isPostViewActive: PropTypes.bool,
    loadForViewMoreCommentsId: PropTypes.number.isRequired,
    isRtl: PropTypes.bool.isRequired,
    animation: PropTypes.string,
    isUploading: PropTypes.number,
    removeMember: PropTypes.func.isRequired,
    deleteQuestion: PropTypes.func.isRequired,
    type: PropTypes.string,
    groupDetails: PropTypes.shape().isRequired,
    likeComment: PropTypes.func.isRequired,
    blockUser: PropTypes.func,
    unblockUser: PropTypes.func,
    groupMembersIdMap: PropTypes.shape(),
    blockListIdMap: PropTypes.shape(),
    singlePost: PropTypes.bool,
    groupId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    showPostView: null,
    postIndex: null,
    isPostViewActive: false,
    comments: {},
    animation: '',
    type: '',
    isUploading: 0,
    blockUser: () => null,
    unblockUser: () => null,
    groupMembersIdMap: {},
    blockListIdMap: {},
    singlePost: false,
  };

  constructor(props) {
    super(props);
    this._nodes = {};
    this.state = {
      showMore: false,
      // eslint-disable-next-line react/no-unused-state
      totalComments: props.data && props.data.interaction_count ? props.data.interaction_count.comments : 0,
      showFullImage: -1,
      postLiked: props.data && props.data.is_liked_by_me,
      showAnswer: false,
      markAsResolved: false,
      optionType: {},
    };

    if (props.data.type === 'poll') {
      const checkPollStatus = props.data.type_meta.minutes_left > 0 ? 'pollAvailable' : 'pollExpired';
      this.pollActionList = [
        {
          name: checkPollStatus,
          icon: IconClockO,
          text:
            checkPollStatus === 'pollAvailable'
              ? translationText(this.props.noonText, 'poll.end_poll')
              : translationText(this.props.noonText, 'poll.extend_poll'),
        },
      ];
    }

    this.moreActionListCommon = [
      {
        name: 'delete',
        icon: IconDelete,
        header: translationText(props.noonText, 'post.deleteContent'),
        text: translationText(props.noonText, 'post.deleteSubText'),
        confirmationText: translationText(props.noonText, 'post.deleteConfirmText'),
      },
    ];

    this.moreActionListBlockUser = [
      {
        name: 'remove',
        icon: IconRemove,
        header: translationText(props.noonText, 'post.removeMember'),
        text: translationText(props.noonText, 'post.removeSubText'),
        confirmationText: translationText(props.noonText, 'post.removeConfirmText'),
      },
      {
        name: 'block',
        icon: IconBlock,
        header: translationText(props.noonText, 'post.blockMember'),
        text: translationText(props.noonText, 'post.blockSubText'),
        confirmationText: translationText(props.noonText, 'post.blockConfirmText'),
      },
    ];

    this.moreActionListForUnblockUser = [
      {
        name: 'unblock',
        text: translationText(props.noonText, 'post.unblock'),
        icon: IconUnblock,
      },
      {
        name: 'unblockAndAdd',
        text: translationText(props.noonText, 'post.unblockAndAdd'),
        icon: IconUnblockAndAdd,
      },
    ];
    this.showMoreTrue = this.showMoreTrue.bind(this);
    this.onNextOrPreviousClick = this.onNextOrPreviousClick.bind(this);
    this.commentSend = this.commentSend.bind(this);
  }

  // eslint-disable-next-line react/sort-comp
  showMoreAnswers = () => {
    this.props.showAnswers({
      start: this.props.answer.meta.paging.start,
      limit: this.props.answer.meta.paging.limit,
      next_tag: this.props.answer.meta.paging.next_tag,
      questionId: this.props.data.id,
    });
  };

  showThisPost = () => {
    const { showPostView } = this.props;
    showPostView(this.props.data);
  };

  showMoreTrue() {
    this.setState({ showMore: !this.state.showMore });
  }

  showFullSizeImage = (idx) => {
    this.setState({ showFullImage: idx });
  };

  removeImage = (e) => {
    if (e.target.localName !== 'img') {
      this.setState({ showFullImage: -1 });
    }
  };

  handleMoreActions = (type) => {
    if (type === 'delete') {
      this.setState({ optionType: this.moreActionListCommon[0] }, () => {
        this.setState({ showConfirmationModal: true });
      });
    } else {
      this.moreActionListBlockUser.find((option) => {
        if (option.name === type) {
          this.setState({ optionType: option }, () => {
            this.setState({ showConfirmationModal: true });
          });
        }
      });
    }
  };

  handleConfirmation = () => {
    const {
      data: {
        id: questionId,
        user: { id: userId },
      },
      blockUser,
      removeMember,
      deleteQuestion,
      groupId,
      // unblockUser,
    } = this.props;
    const { optionType } = this.state;
    if (optionType.name === 'delete') {
      deleteQuestion({ questionId, group_id: groupId });
    } else if (optionType.name === 'block') {
      blockUser(userId);
    } else if (optionType.name === 'remove') {
      removeMember(userId);
    }
    this.removeConfirmation();

    /* Might bring back unblock functionality in future */
    // else if (optionType.name === 'unblock' || optionType.name === 'unblockAndAdd') {
    //   unblockUser(userId, optionType.name === 'unblockAndAdd');
    // }
  }

  increaseCount = () => {
    this.setState((prevState) => ({
      numbersToBeShown: prevState.numbersToBeShown + 4,
    }));
  };

  sortByDate = (a, b) => new Date(a.created_at) - new Date(b.created_at);

  actionListData(isSelf) {
    this.moreActionListCommon = [...[this.moreActionListCommon[0]]];
    if (isSelf) {
      if (this.pollActionList) {
        return [...this.moreActionListCommon, ...this.pollActionList];
      }
      return this.moreActionListCommon;
    }
    return [...this.moreActionListCommon, ...this.moreActionListBlockUser];
  }

  toggleMoreAction = (id) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ togglePopoverId: id });
  };

  sendAnswer(sendingInfo) {
    const { textData, imagePreviewUrls, markAsResolved } = sendingInfo;
    const commonPayload = {
      text: textData,
      questionId: this.props.data.id,
      mark_as_resolved: markAsResolved,
    };
    if (!imagePreviewUrls || imagePreviewUrls.length === 0) {
      this.props.sendAnswer({
        ...commonPayload,
      });
    } else {
      const { bulkFilesUploaded } = this.props;
      const filesExts = [];
      bulkFilesUploaded.forEach((file) => {
        const spittedFile = file.name.split('.');
        filesExts.concat(spittedFile[spittedFile.length - 1]);
      });
      const files = bulkFilesUploaded.map((file) => {
        const { meta, name } = file;
        return {
          original: meta.large_url,
          small: meta.small_url,
          medium: meta.medium_url,
          large: meta.large_url,
          thumbnail: meta.thumbnail_url,
          file_type: 'image',
        };
      });
      this.props.sendAnswer({
        ...commonPayload,
        files,
      });
      this.props.bulkFileUpload({ clear: true });
    }
    this.setState({
      markAsResolved,
      showAnswer: true,
    });
    this.props.handleMixpanelKafkaFeeds({
      type: 'answer_question',
      data: {
        no_of_attachments: 1,
        attachment_file_type: 'image',
        post_author: 'teacher',
        comment_type: markAsResolved ? 'answer' : 'follow-up',
        no_of_upvotes: this.props.data.interaction_count.upvotes,
        no_of_answers: this.props.answerCount,
        listing_source: this.props.onlyQuestions ? 'all_qanda' : 'group_home',
      },
    });
  }

  commentSend(text, imgPreviewUrl, imageFile, markAsResolved) {
    this.setState({
      markAsResolved,
      showAnswer: true,
    });
    this.props.handleCommentSend(text, imgPreviewUrl, imageFile, this.props.data, markAsResolved);
    this.props.handleMixpanelKafkaFeeds({
      type: 'answer_question',
      data: {
        no_of_attachments: 1,
        attachment_file_type: 'image',
        post_author: 'teacher',
        comment_type: markAsResolved ? 'answer' : 'follow-up',
        no_of_upvotes: this.props.data.interaction_count.upvotes,
        no_of_answers: this.props.answerCount,
        listing_source: this.props.onlyQuestions ? 'all_qanda' : 'group_home',
      },
    });
  }

  thankAnswer = ({ payload, thankCount, correctAnswer }) => {
    this.props.thankAnswer(payload);
    this.props.handleMixpanelKafkaFeeds({
      type: 'teacher_thank_answer',
      data: {
        no_of_attachments: 1,
        attachment_file_type: 'image',
        post_author: 'teacher',
        comment_type: correctAnswer ? 'answer' : 'follow-up',
        no_of_upvotes: this.props.data.interaction_count.upvotes,
        no_of_answers: this.props.answerCount,
        no_of_teacher_thanks_already: thankCount,
        listing_source: this.props.onlyQuestions ? 'all_qanda' : 'group_home',
      },
    });
  };

  contentType = (data) => {
    const contentType = [];
    if (!isEmpty(data.files)) {
      if (data.files[0].fileType === 'attachment') {
        contentType.push('files');
      } else {
        contentType.push('images');
      }
    }
    if (data.text) {
      contentType.push('text');
    }
    return contentType;
  };

  onNextOrPreviousClick(e, currentIndex, type, lastIndex) {
    if (type === 'next') {
      if (lastIndex !== currentIndex) {
        this.setState({ showFullImage: currentIndex + 1 });
      } else {
        this.setState({ showFullImage: 0 });
      }
    } else if (currentIndex === 0) {
      this.setState({ showFullImage: lastIndex });
    } else {
      this.setState({ showFullImage: currentIndex - 1 });
    }
    e.stopPropagation();
  }

  openFile = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  likeTranslation = (selfLike, likeCount) => {
    const { noonText } = this.props;
    if (selfLike) {
      if (likeCount === 1) {
        return translationText(noonText, 'post.you_liked');
      } if (likeCount === 2) {
        return translationText(noonText, 'post.you_and_person').replace('{}', likeCount - 1);
      }
      return translationText(noonText, 'post.you_and_person').replace('{}', likeCount - 1);
    }
    if (likeCount === 1) {
      return `${likeCount} ${translationText(noonText, 'post.one_person_like')}`;
    }
    return `${likeCount} ${translationText(noonText, 'post.more_than_more_person_like')}`;
  };

  checkIfLikedByTeacher = (usersData) => {
    const { liked_by_users_display } = usersData;
    if (liked_by_users_display.some((data) => data.roles.includes(4))) {
      let profilePic;
      liked_by_users_display.forEach((user) => {
        if (user.roles.includes(4)) {
          profilePic = user.profile_pic;
        }
      });
      return profilePic;
    }
    return false;
  };

  removeConfirmation = () => {
    console.log('');
    this.setState({ showConfirmationModal: false, optionType: {} });
  };

  showSinglePost = () => {
    if (!this.state.showComment) {
      this.props.showSinglePost(this.props.data.id);
    }
  };

  // deletePost = () => {
  //   const {
  //     data: { id: questionId },
  //     deleteQuestion,
  //   } = this.props;
  //   deleteQuestion({ questionId });
  //   this.setState({ showConfirmationModal: false });
  // };

  showAnswers = (e) => {
    this.setState({ showAnswer: !this.state.showAnswer });
    if (!this.state.showAnswer) {
      this.props.showAnswers({ start: 0, limit: 3, questionId: this.props.data.id });
    }
    e.stopPropagation();
  };

  render() {
    const {
      data,
      noonText,
      emojis,
      currentPostIndex,
      deleteComment,
      sendCommentLoader,
      loadForViewMoreCommentsId,
      user,
      isRtl,
      isPostViewActive,
      isUploading,
      removeMember,
      animation,
      type,
      singlePost,
      history,
      groupDetails,
      index,
      answer,
      deleteAnswer,
      groupId,
      answerLoadingId,
      createAnswerLoading,
      createReplyLoading,
      isArchived,
    } = this.props;

    const { showComment, postLiked, showFullImage, showEndPollModal, showConfirmationModal, optionType } = this.state;

    const postProperties = data.status === 'solved' || this.state.markAsResolved
      ? { text: translationText(noonText, 'post.answered'), icon: IconCorrectCircle, color: '#31C173' }
      : data.status === 'need_answer' || data.status === 'unsolved'
        ? { text: translationText(noonText, 'post.notAnswered'), icon: IconExclamationCircle, color: '#FF3B30' }
        : null;

    const iconSize = '1.6rem';
    const isTeacher = Number(data.user.id) === Number(user.id);
    return (
      <React.Fragment>
        {showConfirmationModal && (
          <PostModal
            text1={optionType.header}
            text2={optionType.confirmationText}
            btn1={translationText(noonText, 'session.confirm')}
            btn2={translationText(noonText, 'session.cancel')}
            type={optionType.name}
            onClick={this.handleConfirmation}
            onClose={this.removeConfirmation}
          />
        )}
        <Row className={classNames(`study-feed mtb-2 study-question__post animated ${animation}`, {})}>
          {showFullImage !== -1 && (
            <React.Fragment>
              <Modal
                className="img-wrapper"
                backDropClassName="img-backdrop"
                closeButtonCustom={(
                  <IconPlus
                    rotate="45"
                    fill="#fff"
                    className="mlr-2 mt-2"
                    size="20px"
                    onClick={this.removeImage}
                  />
                )}
                onClick={this.removeImage}
                onClose={this.removeImage}
              >
                <Row align="center" className="full-image">
                  <div style={{ width: '30px', height: '30px', cursor: 'pointer' }}>
                    <IconArrowDown
                      rotate={!isRtl ? 90 : 270}
                      size="20px"
                      fill="white"
                      className={classNames('next', { invisible: data.files.length === 1 })}
                      onClick={(e) => this.onNextOrPreviousClick(e, showFullImage, 'previous', data.files.length - 1)}
                    />
                  </div>
                  <ImageWithOrientation
                    url={data.files[showFullImage].original}
                    files={data.files[showFullImage]}
                    onNextOrPreviousClick={this.onNextOrPreviousClick}
                  />
                  <div style={{ width: '30px', height: '30px', cursor: 'pointer' }}>
                    <IconArrowDown
                      rotate={!isRtl ? 270 : 90}
                      size="20px"
                      fill="white"
                      className={classNames('next', { invisible: data.files.length === 1 })}
                      onClick={(e) => this.onNextOrPreviousClick(e, showFullImage, 'next', data.files.length - 1)}
                    />
                  </div>
                </Row>
              </Modal>
            </React.Fragment>
          )}

          <Row
            nowrap
            align="start"
            justify="space-between"
            felx="1"
            className={classNames('study-question__post__info relative', {})}
          >
            <Row className="main-info" align="start" flex="1">
              <IconQuestionFeed
                flipX={isRtl}
                name="question-feed"
                className="study-question__post__info__icons--question-icon"
                width={iconSize}
                height={iconSize}
                fill={postProperties ? postProperties.color : '#FF3B30'}
                stroke={postProperties ? postProperties.color : '#FF3B30'}
              />
              <Avatar rounded noShadow url={data.user.profile_pic} size="48px" showChild={data.user.is_plus_user}>
                <Button rounded size="sm" className="user-plus-tag">{translationText(noonText,'groups.plus')}</Button>
              </Avatar>
              <Column nowrap justify="start" className="user mlr-1">
                <Row align="center" gap="sm">
                  <h6 className="child user__name">{data.user.name}</h6>
                  {postProperties && (
                    <React.Fragment>
                      <postProperties.icon
                        className="answered-icon mlr-05"
                        width={iconSize}
                        height={iconSize}
                        fill={postProperties.color}
                        stroke="#fff"
                      />
                      <p className="answered-text text-capitalize">{postProperties.text}</p>
                    </React.Fragment>
                  )}
                </Row>

                <Row gap="sm" align="center" justify="start">
                  <p>
                    <NoonDate humanize value={data.created_at} format="dd LLL, hh:mm a" />
                  </p>
                </Row>
              </Column>
            </Row>
            <Column align="start" className="study-question__post__info__additional">
              <Row className="study-question__post__info__additional--action-wrapper" nowrap align="center">
                {!data.is_free && (
                  <Badge type="default" dir="ltr" className="plus-badge">
                    <span>{translationText(noonText, 'groups.plus')}</span>
                  </Badge>
                )}
                <MoreActions
                  type="dots"
                  className="activity"
                  listActions={this.actionListData(data.user.id === user.id)}
                  cardId={data.id}
                  onSelect={(e) => this.handleMoreActions(e)}
                  vertical
                  position={isRtl ? 'right' : 'left'}
                  noonText={noonText}
                  iconSize="20px"
                  fill="black"
                  toggleMoreAction={this.toggleMoreAction}
                />
              </Row>
            </Column>
          </Row>
          <Column className={classNames('study-question__post__content', { 'mt-1': !isMobileOnly })}>
            <Row gap="sm" align="center" className="mb-1">
              <p className="study-question__post__content--chapter-topic">
                {`${data.chapter && data.chapter.name_header ? data.chapter.name_header : data.chapter.name}`}
                {`${data.chapter && data.chapter.name_header && data.topic && data.topic.name_header ? ', ' : ''}`}
                {`${data.topic && data.topic.name_header ? data.topic.name_header : ''}`}
              </p>
            </Row>
            {!!data.text && (
              <React.Fragment>
                <FormattedText
                  className={classNames('mt-05 mb-2 study-question__post__content--text', isMobileOnly ? 'mb-1' : '', {
                    'flex-column': data.text.length > 400,
                  })}
                  classNameForShowMore="mt-1"
                  text={data.text}
                  showMoreAt={400}
                  breakpoint={95}
                  largeFont={isMobileOnly ? '14px' : '16px'}
                  normalFont={isMobileOnly ? '14px' : '16px'}
                  slim
                  shorten
                  shortenMsg={
                    this.state.showMore
                      ? translationText(noonText, 'post.show_less')
                      : translationText(noonText, 'post.view_more')
                  }
                  showMoreTrue={this.showMoreTrue}
                  showMoreFlag={this.state.showMore}
                />
              </React.Fragment>
            )}
            {!isEmpty(data.files) && data.files[0].file_type === 'image' && showFullImage === -1 && (
              <MultipleImages files={data.files} showFullSizeImage={this.showFullSizeImage} />
            )}
          </Column>

          <Row className="study-question__post__footer">
            <React.Fragment>
              <Column className="post-details">
                <Row className="post-attributes">
                  <div className={classNames('post-attributes__wrapper', { reacted: postLiked, disabled: isTeacher })}>
                    <Row className={classNames('post-attributes__wrapper__general', {})}>
                      <IconArrowDownRound
                        rotate="180"
                        height="24px"
                        width="24px"
                        fill="#fff"
                        stroke={COLORS.grey[1]}
                      />
                      <p className="mlr-05 post-attributes__wrapper__general--text" style={{ color: COLORS.grey[1] }}>
                        {`${translationText(noonText, 'post.upvotes')} (${data.interaction_count.upvotes}`}
                        )
                      </p>
                    </Row>
                  </div>
                  <div className={classNames('post-attributes__wrapper')} onClick={this.showAnswers}>
                    <Row className={classNames('post-attributes__wrapper__general', {})}>
                      {this.state.showAnswer ? <IconComment height="24px" width="24px" fill={COLORS.brand.base} /> : <IconCommentO height="24px" width="24px" fill={COLORS.grey[1]} />}
                      <p
                        className="mlr-05 post-attributes__wrapper__general--text"
                        style={{ color: this.state.showAnswer ? COLORS.brand.base : COLORS.grey[1] }}
                      >
                        {`${translationText(noonText, 'post.answers')} (${this.props.answerCount})`}
                      </p>
                    </Row>
                  </div>
                </Row>
              </Column>
            </React.Fragment>
          </Row>
          {!isArchived && (
          <div
            className={classNames('study-question__post--wrapper', {
              'non-empty-comments': !!this.state.totalComments,
            })}
            id={`comment-${data.id}`}
          >

            <CreateAnswer
              noonText={noonText}
              uploadImageProgress={this.props.uploadImageProgress}
              bulkFileUpload={this.props.bulkFileUpload}
              bulkFilesUploaded={this.props.bulkFilesUploaded}
              resetUploadImageProgress={this.props.resetUploadImageProgress}
              imageUploadProgressStatus={this.props.imageUploadProgressStatus}
              isBulkFileUploading={this.props.isBulkFileUploading}
              bulkFileUploadSuccess={this.props.bulkFileUploadSuccess}
              postInput={(input) => {
                this._nodes[currentPostIndex] = input;
              }}
              currentPostIndex={currentPostIndex}
              emojis={emojis}
              hideFocus={this.hideFocus}
              handlePost={(sendingInfo) => this.sendAnswer(sendingInfo)}
              postType="answer"
              user={user}
              groupDetails={groupDetails}
              groupId={this.props.groupId}
              isResolved={data.status === 'solved' || this.state.markAsResolved}
              handleMixpanelKafkaFeeds={this.handleMixpanelKafkaFeeds}
            />
          </div>
          )}
          {createAnswerLoading && <div className="loader-linear" />}

          {!!answer && answer.list && this.state.showAnswer && (
            <Column className="mlr-2 mtb-2 answers-container" flex="1" gap>
              {answer.list.map((value, idx) => (
                <Answer
                  idx={idx}
                  data={value}
                  replies={this.props.replies}
                  postId={data.id}
                  postData={data}
                  noonText={noonText}
                  key={value.id}
                  user={user}
                  removeMember={removeMember}
                  likeComment={this.props.likeComment}
                  type={type}
                  moreActionListBlockUser={this.moreActionListBlockUser}
                  moreActionListForUnblockUser={this.moreActionListForUnblockUser}
                  groupMembersIdMap={this.props.groupMembersIdMap}
                  blockListIdMap={this.props.blockListIdMap}
                  blockUser={this.props.blockUser}
                  unblockUser={this.props.unblockUser}
                  groupDetails={this.props.groupDetails}
                  showBorder
                  correctAnswer={Number(data.user.id) === Number(user.id)}
                  emojis={emojis}
                  sendReply={this.props.sendReply}
                  deleteAnswer={deleteAnswer}
                  thankAnswer={this.thankAnswer}
                  groupId={groupId}
                  viewReplies={this.props.viewReplies}
                  deleteReply={this.props.deleteReply}
                  replyLoadingId={value.id === this.props.replies.isLoading}
                  thankAnswerMap={this.props.thankAnswerMap}
                  likeReplyMap={this.props.likeReplyMap}
                  replyCount={this.props.replyCountMap[value.id]}
                  thankCount={this.props.thankCount[value.id]}
                  createReplyLoading={createReplyLoading === value.id}
                  isRtl={isRtl}
                  isArchived={isArchived}
                />
              ))}
              {!!answer && answer.meta && !!answer.meta.paging && (
                <Row alig="center" justify="start">
                  <Button
                    link
                    onClick={this.showMoreAnswers}
                    className={classNames('text-capitalize show-more mb-2', {
                      disable: answerLoadingId,
                    })}
                  >
                    {translationText(noonText, 'post.showMoreAnswer')}
                    <IconArrowDown fill={COLORS.grey[1]} stroke={COLORS.grey[1]} height="16px" width="16px" />
                  </Button>
                </Row>
              )}
            </Column>
          )}
          {answerLoadingId && (
            <Row className="plr-1 ptb-1 full-width" justify="center">
              <IconLoaderDotted width="4rem" height="4rem" />
            </Row>
          )}
        </Row>
      </React.Fragment>
    );
  }
}

export default QuestionAndAnswerContainer;

import { IconDollar, IconMyGroupsFilled, IconSchedule, IconUploadFile } from '@noon/quark';
import { getSelectedCountry } from '.';

const navigationRoutes = [
  {
    label: 'profile',
    icon: '',
    sidebarComp: 'profile',
    url: 'profile',
    visible: true,
  },
  {
    label: 'planner',
    icon: IconSchedule,
    url: 'schedule',
    visible: true,
  },
  {
    label: 'library',
    icon: IconUploadFile,
    url: 'library',
    visible: true,
  },
  {
    label: 'payments',
    icon: IconDollar,
    url: 'payments',
    visible: getSelectedCountry().payment_enabled,
  },
  {
    label: 'mygroups',
    icon: IconMyGroupsFilled,
    subMenu: true,
    visible: true,
    fetchUrl: 'fetchGroups',
  },
];

export default navigationRoutes;

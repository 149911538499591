import React, { Component } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Column, Row, Input, Select, Button, Checkbox } from '@noon/atom';
import isEmpty from 'lodash-es/isEmpty';
import classNames from 'classnames';
import { IconPlus } from '@noon/quark';
import { translationText } from '../../helpers';
import { HtmlInput } from './index';

export default class CreatePoll extends Component {
  constructor(props) {
    super(props);
    const { noonText } = props;
    const disable = !isEmpty(props.pollExpirePost);
    const pollOptions = disable ? props.pollExpirePost.choices.map(poll => (
      { input: poll.title }
    )) : [{ input: '' }, { input: '' }];
    this.state = {
      expiredPoll: !isEmpty(props.pollExpirePost),
      pollText: '',
      pollOptions,
      selectTimePeriod: {
        name: `1 ${translationText(noonText, 'poll.week')}`,
        id: 'week',
        time: 24 * 7,
      },
      isFree: true,
      moreOptionsAllowed: true,
      submitAllowed: 'add_title',
      listOfTimePeriod: [
        {
          name: `1 ${translationText(noonText, 'poll.week')}`,
          id: 'week',
          time: 24 * 7,
        },
        {
          name: `4 ${translationText(noonText, 'poll.day')}`,
          id: '4days',
          time: 24 * 4,
        },
        {
          name: `2 ${translationText(noonText, 'poll.day')}`,
          id: '2days',
          time: 24 * 2,
        },
        {
          name: `1 ${translationText(noonText, 'poll.day')}`,
          id: '1day',
          time: 24 * 1,
        },
      ],
    };
    this.changePostText = this.changePostText.bind(this);
    this.pollInput = React.createRef();
  }

  componentDidMount() {
    this.pollInput.current.focus();
  }

  handleChange = (item) => {
    this.setState({ selectTimePeriod: item });
  }

  toggleIsFree = (item) => {
    this.setState({ isFree: item });
  }

  changePostText(data) {
    const pollText = data.trim();
    this.setState({ pollText });
    const error_message = pollText ? this.checkPostOptions(this.state.pollOptions) ? '' : 'atleast_2_options_for_poll' : 'add_title';
    this.setState({ submitAllowed: error_message });
  }

  checkPostOptions(pollOptions) {
    const pollInputs = pollOptions.filter(pollData => !!pollData.input);
    return pollInputs.length >= 2;
  }

  addOptions = () => {
    this.setState((prevState) => {
      const morePollCheck = prevState.pollOptions.length < 4;
      return {
        pollOptions: prevState.pollOptions.concat({ input: '' }),
        moreOptionsAllowed: morePollCheck,
      };
    });
  };

  removeOption = (removedIndex) => {
    this.setState((prevState) => {
      const pollOption = prevState.pollOptions.filter((val, index) => index !== removedIndex);
      return {
        pollOptions: pollOption,
        moreOptionsAllowed: pollOption.length <= 5,
      };
    });
  }

  onFilled(e, pollIndex) {
    const trimmedValue = e.target.value.trim();
    const inputValue = e.target.value;
    this.setState((prevState) => {
      const updatedPollData = prevState.pollOptions.map((pollValue, index) => {
        if (index === pollIndex) {
          return {
            ...pollValue,
            input: trimmedValue.length ? inputValue : trimmedValue,
          };
        } return pollValue;
      });
      return {
        pollOptions: updatedPollData,
        submitAllowed: prevState.pollText ? this.checkPostOptions(updatedPollData) ? '' : 'atleast_2_options_for_poll' : 'add_title',
      };
    });
  }

  submitPoll = () => {
    if (this.state.expiredPoll) {
      const { pollExpirePost } = this.props;
      this.props.updatePollTime({ id: pollExpirePost.id, active_hours: this.state.selectTimePeriod.time });
    } else {
      const { pollText, pollOptions, selectTimePeriod, isFree } = this.state;
      this.props.submitPoll({ 
        text: pollText,
        choices: pollOptions.filter(poll => !!poll.input).map(poll => ({ title: poll.input })),
        active_hours: selectTimePeriod.time, 
        is_free: isFree
      });
    }
  }

  render() {
    const { user, noonText, isRtl, emojis, pollExpirePost, showPlusCheckbox } = this.props;
    const { pollOptions, selectTimePeriod, isFree, listOfTimePeriod, moreOptionsAllowed, submitAllowed, expiredPoll } = this.state;
    return (
      <div className="poll">

        {/* <PostModal text1="abcd" text2="abcd" btn1="btn1" btn2="btn2" type="delete" /> */}
        <Column className="poll--container">
          <Row nowrap justify="start" align="center" gap className="poll-options--select poll-options--select--expiry plr-2">
            {/* <span className="text-capitalize poll-options--select--expiry__text child">
                {translationText(noonText, 'poll.expiry_text')}
              </span> */}
            <Select
              selectedItem={selectTimePeriod}
              onSelect={item => this.handleChange(item)}
              label=""
              title="Choose poll duration"
              list={listOfTimePeriod}
              size="sm"
              style={{ minHeight: '3.8rem' }}
              className="mt-1"
            />

          </Row>
          <HtmlInput
            inputRef={this.pollInput}
            noonText={noonText}
            isRtl={isRtl}
            emojis={expiredPoll ? {} : emojis}
            user={user}
            placeholder="poll.poll_question"
            type="post"
            textData={this.changePostText}
            disabled={expiredPoll}
            value={expiredPoll ? pollExpirePost.text : ''}
            maxLength={280}
            className={isMobileOnly ? 'plr-1' : ''}
            maxChar={400}
        />
          <Row justify="end" className={classNames('poll--container--question', { 'plr-1': isMobileOnly })}>
            <Column className="poll-options">
              {pollOptions.map((option, index) => (
                <Row key={index} className="poll-options--choice">
                  <Input type="text" outlined onChange={e => this.onFilled(e, index)} disabled={expiredPoll} value={option.input} maxLength="60" placeholder={`${index + 1} ${translationText(noonText, 'poll.input')}`} />
                  <IconPlus
                    onClick={() => this.removeOption(index)}
                    className={classNames('mlr-1 mtb-1 poll-options--choice__icon', {
                      hidden: pollOptions.length <= 2,
                    })}
                    width="16px"
                    height="16px"
                    rotate="45"
                    fill="#DCE3FA"
                  />
                </Row>
              ))}
              { moreOptionsAllowed && !expiredPoll &&
                <p className="poll-options--option-label" onClick={this.addOptions}>
                  <span className="mlr-05 poll-options--option-label--plus">+</span>{translationText(noonText, 'poll.max_options')}
                </p>
  }
              <Row justify="end">
              {showPlusCheckbox && (
                <Row className="mlr-1" align="center" justify="end">
                  <Checkbox
                    value={isFree ? 'off' : 'on'}
                    bit
                    checked={!isFree}
                    label={translationText(noonText, 'post.showOnlyToPlus')}
                    onChange={() =>  this.toggleIsFree(!isFree)}
                    className={classNames('chat-status', isFree ? 'off' : 'on')}
                  />
                </Row>
              )}
                <Button
                  type="primary"
                  size="large"
                  onClick={this.submitPoll}
                  loading={false}
                  tooltip={!submitAllowed ? translationText(noonText, 'poll.submitPoll') : translationText(noonText, `poll.${submitAllowed}`)}
                  tooltipPos="top left"
                  className="prepare"
                  value={translationText(noonText, 'poll.publish')}
                  disabled={submitAllowed && !expiredPoll}
                />
              </Row>
            </Column>
          </Row>
        </Column>
      </div>
    );
  }
}

import { get } from 'lodash-es';
import {
  GET_GROUP_DETAILS,
  CREATE_GROUP,
  GET_GROUPS,
  SHOW_CREATE_GROUP_SIDEBAR,
  GROUP_PENDING_REQUEST,
  GROUP_ACCEPT_REQUEST,
  GROUP_REMOVE_MEMBERS,
  GROUP_MEMBERS,
  UPDATE_GROUP,
  GROUP_ALL_PENDING_REQUEST,
  BLOCK_USER,
  UN_BLOCK_USER,
  BLOCK_USER_LIST,
  BLOCK_USER_LIST_MAP,
  GROUP_MEMBERS_MAP,
  CLEAR_USER_SPECIFIC_GROUP_DATA,
  GROUP_ACCEPT_REQUEST_FROM_NOTIFICATIONS,
  GROUP_REMOVE_REQUEST_FROM_NOTIFICATIONS,
  GROUP_METRICS,
  GROUP_FOR_SESSION,
  GROUP_FOR_SESSION_CREATION,
  GET_RECOMMENDED_USERS_FOR_GROUP,
  SEARCH_GROUP_MEMBERS,
  CLEAR_GROUP_MEMBERS,
} from '../constants';

const initialState = {
  groupData: {
    list: [],
    meta: {
      total: 0,
    },
    isLoading: false,
  },
  isCreatingGroup: false,
  isUpdatingGroup: false,
  groupDetails: {},
  showCreatGroupSidebar: false,
  groupMembers: {
    list: [],
    allUserIds: [],
    isLoading: false,
    meta: 0,
  },
  addMember: {},
  removeMember: {},
  pendingRequest: {
    loading: false,
    list: [],
    total: 0,
  },
  allPendingRequest: { list: [], next_start: 0, isLoading: false, meta: {} },
  groupMetrics: { data: {}, isLoading: false },
  acceptRequest: {},
  error: {},
  blockUser: { response: {}, isLoading: 0 },
  unBlockUser: { response: {}, isLoading: 0 },
  blockUserList: { response: [], isLoading: 0 },
  blockListMap: {},
  groupMembersListMap: {},
  acceptGroupRequestFromNotifications: { response: {}, isLoading: -1 },
  rejectGroupRequestFromNotifications: { response: {}, isLoading: -1 },
  statusUpdate: { response: {}, isLoading: false },
  groupForSession: {},
  groupForReport: {},
  getGroupDetailsLoading: false,
  groupForSessionCreation: 0,
  recommendedUsers: {
    list: [],
    start: 0,
    count: 0,
    isLoading: false,
  },
};

export default function groups(state = initialState, action) {
  switch (action.type) {
    case CLEAR_USER_SPECIFIC_GROUP_DATA:
      return {
        ...initialState,
      };
    case GET_GROUPS.REQUEST:
      return {
        ...state,
        groupData: {
          ...state.groupData,
          isLoading: true,
        },
      };
    case GET_GROUPS.SUCCESS:
      return {
        ...state,
        groupData: {
          list: action.payload.start === 0 ? action.payload.list : [...state.groupData.list, ...action.payload.list],
          meta: action.payload.meta,
          isLoading: false,
        },
      };
    case GET_GROUPS.FAILURE:
      return {
        ...state,
        groupData: {
          ...state.groupData,
          isLoading: false,
        },
      };
    case GROUP_METRICS.REQUEST:
      return {
        ...state,
        groupMetrics: {
          ...state.groupMetrics,
          isLoading: true,
        },
      };
    case GROUP_METRICS.SUCCESS:
      return {
        ...state,
        groupMetrics: {
          ...state.groupMetrics,
          data: action.payload,
          isLoading: false,
        },
      };
    case GROUP_METRICS.FAILURE:
      return {
        ...state,
        groupMetrics: {
          ...state.groupMetrics,
          isLoading: false,
        },
      };
    case CREATE_GROUP.REQUEST:
      return {
        ...state,
        isCreatingGroup: true,
      };
    case CREATE_GROUP.SUCCESS:
      return {
        ...state,
        isCreatingGroup: false,
        groupDetails: action.payload,
        groupData: {
          ...state.groupData,
          list: [{ ...action.payload, is_new: true }, ...state.groupData.list],
          meta: {
            total: state.groupData.meta.total + 1,
          },
        },
      };
    case CREATE_GROUP.FAILURE:
      return {
        ...state,
        isCreatingGroup: false,
        error: {
          createGroup: action.payload,
        },
      };
    case UPDATE_GROUP.REQUEST:
      return {
        ...state,
        isUpdatingGroup: true,
      };
    case UPDATE_GROUP.SUCCESS:
      return {
        ...state,
        isUpdatingGroup: false,
        groupDetails: {
          ...state.groupDetails,
          publish: action.payload.publish,
          description: action.payload.description,
          is_preview_as_student: action.payload.is_preview_as_student,
          is_session_created: !!action.payload.is_session_created,
        },
      };
    case UPDATE_GROUP.FAILURE:
      return {
        ...state,
        isUpdatingGroup: false,
        error: {
          updateGroup: action.payload,
        },
      };
    case GET_GROUP_DETAILS.REQUEST:
      return {
        ...state,
        getGroupDetailsLoading: true,
      };
    case GET_GROUP_DETAILS.SUCCESS:
      return {
        ...state,
        groupDetails: { ...action.payload },
        getGroupDetailsLoading: false,
      };
    case GET_GROUP_DETAILS.FAILURE:
      return {
        ...state,
        error: {
          groupDetails: action.payload,
        },
        getGroupDetailsLoading: false,
      };
    case GROUP_REMOVE_MEMBERS.SUCCESS:
      return {
        ...state,
        removeMember: action.payload,
        pendingRequest: {
          ...state.pendingRequest,
          list: [...state.pendingRequest.list.filter((o) => o.id !== action.payload)],
          // total: state.pendingRequest.total - 1,
        },
        groupMembers: {
          ...state.groupMembers,
          list: [...state.groupMembers.list.filter((o) => o.id !== action.payload)],
          allUserIds: [...state.groupMembers.allUserIds.filter((id) => id !== action.payload)],
        },
      };
    case GROUP_REMOVE_MEMBERS.FAILURE:
      return {
        ...state,
        error: {
          removeMember: action.payload,
        },
      };
    case GROUP_ACCEPT_REQUEST.SUCCESS:
      return {
        ...state,
        acceptRequest: action.payload,
        pendingRequest: {
          ...state.pendingRequest,
          list: [...state.pendingRequest.list.filter((o) => o.id !== action.payload)],
          // total: state.pendingRequest.total - 1,
        },
      };
    case GROUP_ACCEPT_REQUEST.FAILURE:
      return {
        ...state,
        error: {
          acceptRequest: action.payload,
        },
      };
    case GROUP_PENDING_REQUEST.REQUEST:
      return {
        ...state,
        pendingRequest: {
          ...state.pendingRequest,
          loading: true,
        },
      };
    case GROUP_PENDING_REQUEST.SUCCESS:
      return {
        ...state,
        pendingRequest: {
          loading: false,
          list: action.payload.start > 0 ? [...state.pendingRequest.list, ...action.payload.data] : action.payload.data,
          total: get(action, ['payload', 'meta', 'total']),
        },
      };
    case GROUP_PENDING_REQUEST.FAILURE:
      return {
        ...state,
        pendingRequest: {
          loading: false,
          list: [],
          total: state.pendingRequest.total,
        },
        error: {
          pendingRequest: action.payload,
        },
      };
    case GROUP_MEMBERS.REQUEST:
      return {
        ...state,
        groupMembers: {
          ...state.groupMembers,
          isLoading: true,
        },
      };
    case GROUP_MEMBERS.SUCCESS:
      return {
        ...state,
        groupMembers: {
          list: action.payload.start === 0 ? action.payload.list : [...state.groupMembers.list, ...action.payload.list],
          allUserIds: action.payload.allUserIds,
          isLoading: false,
          meta: action.payload.meta,
        },
        groupDetails: {
          ...state.groupDetails,
          joined_users: action.payload.start === 0 ? action.payload.list : [...state.groupDetails.joined_users, ...action.payload.list],
          joined_users_count: action.payload.allUserIds.length,
        },
      };
    case GROUP_MEMBERS.FAILURE:
      return {
        ...state,
        groupMembers: {
          ...state.groupMembers,
          isLoading: false,
        },
        error: {
          groupMembers: action.payload,
        },
      };
    case SHOW_CREATE_GROUP_SIDEBAR:
      return {
        ...state,
        showCreatGroupSidebar: action.payload,
      };
    case GROUP_ALL_PENDING_REQUEST.REQUEST:
      return {
        ...state,
        allPendingRequest: {
          ...state.allPendingRequest,
          isLoading: true,
        },
      };
    case GROUP_ALL_PENDING_REQUEST.SUCCESS:
      return {
        ...state,
        allPendingRequest: {
          ...state.allPendingRequest,
          list: action.payload.start === 0 ? action.payload.list : [...state.allPendingRequest.list, ...action.payload.list],
          meta: action.payload.meta,
          isLoading: false,
          next_start: action.payload.next_start,
        },
      };
    case GROUP_ALL_PENDING_REQUEST.FAILURE:
      return {
        ...state,
        allPendingRequest: {
          ...state.allPendingRequest,
          isLoading: false,
        },
        error: {
          allPendingRequest: action.payload,
        },
      };
    case GROUP_ACCEPT_REQUEST_FROM_NOTIFICATIONS.REQUEST:
      return {
        ...state,
        acceptGroupRequestFromNotifications: {
          ...state.acceptGroupRequestFromNotifications,
          isLoading: action.payload.loadingIdx,
        },
      };
    case GROUP_ACCEPT_REQUEST_FROM_NOTIFICATIONS.SUCCESS:
      return {
        ...state,
        allPendingRequest: {
          ...state.allPendingRequest,
          list: [...state.allPendingRequest.list.filter((o) => !(o.id === action.payload.user_id && o.group_info.id === action.payload.group_id))],
          meta: {
            ...state.allPendingRequest.meta,
            total: state.allPendingRequest.meta.total - 1,
          },
          next_start: state.allPendingRequest.next_start - 1,
        },
        acceptGroupRequestFromNotifications: {
          ...state.acceptGroupRequestFromNotifications,
          isLoading: -1,
        },
      };
    case GROUP_ACCEPT_REQUEST_FROM_NOTIFICATIONS.FAILURE:
      return {
        ...state,
        acceptGroupRequestFromNotifications: {
          ...state.acceptGroupRequestFromNotifications,
          isLoading: -1,
        },
        error: {
          acceptGroupRequestFromNotifications: action.payload,
        },
      };
    case GROUP_REMOVE_REQUEST_FROM_NOTIFICATIONS.REQUEST:
      return {
        ...state,
        rejectGroupRequestFromNotifications: {
          ...state.rejectGroupRequestFromNotifications,
          isLoading: action.payload.loadingIdx,
        },
      };
    case GROUP_REMOVE_REQUEST_FROM_NOTIFICATIONS.SUCCESS:
      return {
        ...state,
        allPendingRequest: {
          ...state.allPendingRequest,
          list: [...state.allPendingRequest.list.filter((o) => !(o.id === action.payload.user_id && o.group_info.id === action.payload.group_id))],
          meta: {
            ...state.allPendingRequest.meta,
            total: state.allPendingRequest.meta.total - 1,
          },
          next_start: state.allPendingRequest.next_start - 1,
        },
        rejectGroupRequestFromNotifications: {
          ...state.rejectGroupRequestFromNotifications,
          isLoading: -1,
        },
      };
    case GROUP_REMOVE_REQUEST_FROM_NOTIFICATIONS.FAILURE:
      return {
        ...state,
        rejectGroupRequestFromNotifications: {
          ...state.rejectGroupRequestFromNotifications,
          isLoading: -1,
        },
        error: {
          rejectGroupRequestFromNotifications: action.payload,
        },
      };
    case BLOCK_USER.LOADING:
      return {
        ...state,
        blockUser: {
          ...state.blockUser,
          isLoading: action.payload.isLoading,
        },
      };
    case BLOCK_USER.SUCCESS:
      return {
        ...state,
        blockUser: {
          ...state.blockUser,
          response: action.payload,
        },
      };
    case BLOCK_USER.FAILURE:
      return {
        ...state,
        error: { blockUser: action.payload },
      };
    case BLOCK_USER_LIST_MAP:
      return {
        ...state,
        blockListMap: action.payload,
      };
    case GROUP_MEMBERS_MAP:
      return {
        ...state,
        groupMembersListMap: action.payload,
      };
    case UN_BLOCK_USER.LOADING:
      return {
        ...state,
        unBlockUser: {
          ...state.unBlockUser,
          isLoading: action.payload.isLoading,
        },
      };
    case UN_BLOCK_USER.SUCCESS:
      return {
        ...state,
        unBlockUser: {
          ...state.unBlockUser,
          response: action.payload,
        },
      };
    case UN_BLOCK_USER.FAILURE:
      return {
        ...state,
        error: { unBlockUser: action.payload },
      };
    case BLOCK_USER_LIST.LOADING:
      return {
        ...state,
        blockUserList: {
          ...state.blockUserList,
          isLoading: action.payload.isLoading,
        },
      };
    case BLOCK_USER_LIST.SUCCESS:
      console.log('start is', action.payload.start, typeof action.payload.start);
      return {
        ...state,
        blockUserList: {
          ...state.blockUserList,
          response: action.payload.start > 0 ? [...state.blockUserList.response, ...action.payload.data] : action.payload.data,
        },
      };
    case BLOCK_USER_LIST.FAILURE:
      return {
        ...state,
        error: { blockUserList: action.payload },
      };
    case GROUP_FOR_SESSION:
      return {
        ...state,
        groupForSession: action.payload,
      };
    case GROUP_FOR_SESSION_CREATION:
      return {
        ...state,
        groupForSessionCreation: action.payload,
      };
    case GET_RECOMMENDED_USERS_FOR_GROUP.REQUEST:
      return {
        ...state,
        recommendedUsers: {
          ...action.payload,
          list: state.recommendedUsers.start === 0 ? [] : state.recommendedUsers.list,
          isLoading: true,
        },
      };
    case GET_RECOMMENDED_USERS_FOR_GROUP.SUCCESS:
      return {
        ...state,
        recommendedUsers: {
          ...action.payload,
          list: action.payload.start === 0 ? action.payload.list : [...state.recommendedUsers.list, ...action.payload.list],
          isLoading: false,
        },
      };
    case GET_RECOMMENDED_USERS_FOR_GROUP.FAILURE:
      return {
        ...state,
        recommendedUsers: {
          ...action.payload,
          isLoading: false,
        },
        error: { recommendedUsers: action.payload },
      };
    case SEARCH_GROUP_MEMBERS.REQUEST:
      return {
        ...state,
        groupMembers: {
          ...state.groupMembers,
          isLoading: true,
        },
      };
    case SEARCH_GROUP_MEMBERS.SUCCESS:
      return {
        ...state,
        groupMembers: {
          list: action.payload.length === 0 ? action.payload : [...action.payload],
          isLoading: false,
        },
      };
    case SEARCH_GROUP_MEMBERS.FAILURE:
      return {
        ...state,
        groupMembers: {
          ...state.groupMembers,
          isLoading: false,
        },
        error: {
          groupMembers: action.payload,
        },
      };
    case CLEAR_GROUP_MEMBERS:
      return {
        ...state,
        groupMembers: {
          list: [],
          allUserIds: [],
          isLoading: false,
          meta: 0,
        },
        error: {
          groupMembers: null,
        },
      };
    default:
      if (action.type.indexOf('_REQUEST') !== -1) {
        return {
          ...state,
          error: {},
        };
      }
      return state;
  }
}

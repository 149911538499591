import { GET_TEACHER_TAGS_GROUP, GET_TEACHER_GROUP_COURSE_PREVIEW, GET_TEACHER_GROUPS, GET_TEACHER_GROUP_SELECTED, GET_TEACHER_GROUP_CHAPTERS, GET_TEACHER_GROUP_TOPICS, GET_TEACHER_GROUP_MEMBERS_INFO, GET_CHAPTERS_FROM_GROUP, GET_TOPICS_FROM_GROUP, CREATE_GROUP_V2, UPDATE_GROUP_V2, CLEAR_CHAPTERS_AND_TOPICS, ARCHIVE_GROUP, UNARCHIVE_GROUP, GET_TEACHER_ARCHIVE_GROUPS } from '../constants';

/*
schoolStudent -> school subject -> grade -> subject -> curriculum
schoolStudent -> test Prep -> testName -> subject
schoolStudent -> skills -> grade -> course -> section

universityStudent -> university courses -> degree -> majors -> course -> section
universityStudent -> test prep -> test -> section
universityStudent -> skills -> course -> section

others -> testprep -> test -> agegroup
others -> skills -> course -> section -> agegroup
*/
const initialState = {
  grades: {
    list: [],
    loading: false,
  },
  curriculumList: {
    chapters: [],
    miscChapter: {},
    preview: null,
    topics: {},
  },
  groups: {
    data: [],
    inActiveGroups: [],
  },
  selectedGroup: {},
  homeworkCreate: {},
  groupChapters: { list: [], loading: false, meta: {} },
  groupTopics: { list: [], loading: false, meta: {}, chapterId: null },
  createGroup: {},
  archiveGroup: {
    loading: false,
    error: '',
  },
  archivedGroups: {
    loading: false,
    success: false,
    list: [],
  },
  error: {},
};

export default function groupsV2(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TEACHER_GROUP_MEMBERS_INFO.LOADING:
      return {
        ...state,
        selectedGroup: {
          ...state.selectedGroup,
          membersInfo: { loading: true },
        },
      };
    case GET_TEACHER_GROUP_MEMBERS_INFO.SUCCESS:
      return {
        ...state,
        selectedGroup: {
          ...state.selectedGroup,
          membersInfo: action.payload,
        },
      };
    case GET_TEACHER_GROUP_MEMBERS_INFO.FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_TEACHER_GROUP_TOPICS.REQUEST:
      return {
        ...state,
        curriculumList: {
          ...state.curriculumList,
          topics: {
            ...state.curriculumList.topics,
            [action.payload.chapterId]: {
              data: [],
              meta: {},
              loading: true,
              chapterId: action.payload.chapterId,
            },
          },
        },
      };
    case GET_TEACHER_GROUP_TOPICS.SUCCESS:
      return {
        ...state,
        curriculumList: {
          ...state.curriculumList,
          topics: {
            ...state.curriculumList.topics,
            [action.payload.chapterId]: {
              data: action.payload.data,
              meta: action.payload.meta,
              loading: false,
              chapterId: action.payload.chapterId,
            },
          },
        },
      };
    // TODO: Not Sure why we are doing all this on failure and there is duplicate case for the same so commenting
    case GET_TEACHER_GROUP_TOPICS.FAILURE:
      return {
        ...state,
        curriculumList: {
          ...state.curriculumList,
          topics: {
            ...state.curriculumList.topics,
            [action.payload.chapterId]: {
              loading: false,
              meta: {},
              data: [],
              chapterId: action.payload.chapterId,
            },
          },
        },
        error: action.payload,
      };
    case GET_TEACHER_GROUP_COURSE_PREVIEW.LOADING:
      return {
        ...state,
        curriculumList: {
          ...state.curriculumList,
          preview: { loading: true },
        },
      };
    case GET_TEACHER_GROUP_COURSE_PREVIEW.SUCCESS:
      return {
        ...state,
        curriculumList: {
          ...state.curriculumList,
          preview: action.payload.content[0],
          totalTopics: action.payload.total_topics,
          percentCompleted: Math.ceil((100 * action.payload.total_completed_topics) / action.payload.total_topics, 10),
        },
      };
    case GET_TEACHER_GROUP_COURSE_PREVIEW.FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_TEACHER_GROUP_CHAPTERS.LOADING:
      return {
        ...state,
        curriculumList: {
          ...state.curriculumList,
          chapters: { loading: true },
        },
      };
    case GET_TEACHER_GROUP_CHAPTERS.SUCCESS:
      return {
        ...state,
        curriculumList: {
          ...state.curriculumList,
          chapters: action.payload.chapters,
          miscChapter: action.payload.miscChapter,
        },
      };
    case GET_TEACHER_GROUP_CHAPTERS.FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_TEACHER_GROUPS.LOADING:
      return {
        ...state,
        groups: {
          loading: true,
        },
      };
    case GET_TEACHER_GROUPS.SUCCESS:
      return {
        ...state,
        groups: {
          data: action.payload.groups,
          meta: action.payload.meta,
          inActiveGroups: action.payload.inActiveGroups,
          loading: false,
        },
      };
    case GET_TEACHER_GROUPS.FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_TEACHER_GROUP_SELECTED.REQUEST:
      return {
        ...state,
        archiveGroup: {},
        selectedGroup: {
          loading: true,
        },
        error: {},
      };
    case GET_TEACHER_GROUP_SELECTED.SUCCESS:
      return {
        ...state,
        selectedGroup: {
          ...state.selectedGroup,
          ...action.payload,
          loading: false,
        },
      };
    case GET_TEACHER_GROUP_SELECTED.FAILURE:
      return {
        ...state,
        selectedGroup: {
          ...state.selectedGroup,
          loading: false,
        },
        error: { selectedGroup: action.payload },
      };
    case UPDATE_GROUP_V2.REQUEST:
      return {
        ...state,
        selectedGroup: {
          ...state.selectedGroup,
          loading: true,
        },
      };
    case UPDATE_GROUP_V2.SUCCESS:
      return {
        ...state,
        selectedGroup: {
          ...state.selectedGroup,
          group_info: {
            ...state.selectedGroup.group_info,
            ...action.payload,
          },
          loading: false,
        },
      };
    case UPDATE_GROUP_V2.FAILURE:
      return {
        ...state,
        selectedGroup: {
          ...state.selectedGroup,
          loading: false,
        },
        error: {
          updateGroup: action.payload,
        },
      };
    case GET_TEACHER_TAGS_GROUP.LOADING:
      return {
        ...state,
        [action.payload.requestId]: {
          ...state.grades,
          loading: action.payload.data.loading,
        },
      };
    case GET_TEACHER_TAGS_GROUP.SUCCESS:
      return {
        ...state,
        [action.payload.requestId]: {
          list: action.payload.data.list,
          loading: action.payload.data.loading,
        },
      };
    case GET_TEACHER_TAGS_GROUP.FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_CHAPTERS_FROM_GROUP.LOADING:
      return { ...state, groupChapters: { ...state.groupChapters, loading: true } };
    case GET_CHAPTERS_FROM_GROUP.SUCCESS:
      return {
        ...state,
        groupChapters: {
          list: action.payload.chapters,
          loading: false,
          meta: action.payload.meta,
        },
      };
    case GET_CHAPTERS_FROM_GROUP.FAILURE:
      return { ...state, groupChapters: { ...state.topics, loading: false }, error: { groupChapters: action.payload } };
    case GET_TOPICS_FROM_GROUP.LOADING:
      return { ...state, groupTopics: { ...state.groupTopics, loading: true } };
    case GET_TOPICS_FROM_GROUP.SUCCESS:
      return {
        ...state,
        groupTopics: {
          list: action.payload.data,
          chapterId: action.payload.chapterId,
          loading: false,
          meta: action.payload.meta,
        },
      };
    case GET_TOPICS_FROM_GROUP.FAILURE:
      return { ...state, groupTopics: { ...state.groupTopics, loading: false }, error: { groupTopics: action.payload } };
    case CLEAR_CHAPTERS_AND_TOPICS: {
      return {
        ...state,
        groupTopics: initialState.groupTopics,
        groupChapters: initialState.groupChapters,
      };
    }
    case CREATE_GROUP_V2.SUCCESS:
      return {
        ...state,
        createGroup: action.payload,
      };
    case CREATE_GROUP_V2.LOADING:
      return {
        ...state,
        createGroup: action.payload,
      };
    case CREATE_GROUP_V2.FAILURE:
      return {
        ...state,
        createGroup: {
          ...state.createGroup,
          loading: false,
        },
        error: action.payload,
      };
    case ARCHIVE_GROUP.REQUEST:
      return {
        ...state,
        archiveGroup: {
          loading: true,
        },
      };
    case ARCHIVE_GROUP.SUCCESS:
      return {
        ...state,
        archiveGroup: {
          loading: false,
          archived: true,
        },
      };
    case ARCHIVE_GROUP.FAILURE:
      return {
        ...state,
        archiveGroup: {
          loading: false,
          error: action.payload,
        },
      };
    case UNARCHIVE_GROUP.REQUEST:
      return {
        ...state,
        archiveGroup: {
          loading: true,
        },
      };
    case UNARCHIVE_GROUP.SUCCESS:
      return {
        ...state,
        archiveGroup: {
          loading: false,
          archived: false,
          success: true,
        },
      };
    case UNARCHIVE_GROUP.FAILURE:
      return {
        ...state,
        archiveGroup: {
          loading: false,
          error: action.payload,
        },
      };
    case GET_TEACHER_ARCHIVE_GROUPS.REQUEST:
      return {
        ...state,
        archivedGroups: {
          loading: true,
          list: [],
        },
      };
    case GET_TEACHER_ARCHIVE_GROUPS.SUCCESS:
      return {
        ...state,
        archivedGroups: {
          loading: false,
          success: true,
          list: action.payload,
        },
      };
    case GET_TEACHER_ARCHIVE_GROUPS.FAILURE:
      return {
        ...state,
        archivedGroups: {
          loading: false,
          failure: true,
          list: [],
        },
      };
    default:
      return state;
  }

}

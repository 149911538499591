import { createSelector } from 'reselect';

const tokenDataSelectorRaw = state => state.toJS().biFrost.tokenData;
const temporaryTokenPayloadRaw = state => state.toJS().biFrost.temporaryTokenPayload;

const tokenDataSelector = createSelector(
  tokenDataSelectorRaw,
  item => item,
);

const temporaryTokenPayloadSelector = createSelector(
  temporaryTokenPayloadRaw,
  item => item,
);

export { tokenDataSelector, temporaryTokenPayloadSelector };

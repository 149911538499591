import React from 'react';
import { Column } from '@noon/atom';

type IEmptyStateProp = {
    title?: string,
    subtitle?: string,
    icon?: React.ReactNode,
};

const EmptyState = ({ title, subtitle, icon }: IEmptyStateProp) => (
  <Column data-testid="empty-state" nowrap className="plr-4 flex-1 text-center" align="center" justify="center">
    {!!icon && icon}
    {!!title && <h4>{title}</h4>}
    {!!subtitle && <span>{subtitle}</span>}
  </Column>
);

EmptyState.defaultProps = {
  title: '',
  subtitle: '',
  icon: null,
};

export default EmptyState;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Popover from 'react-popover';
import isEmpty from 'lodash-es/isEmpty';
import { IconBell, IconBellO, IconLoader, IconNoNotification } from '@noon/quark';
import { Badge, Column } from '@noon/atom';
import {
  TIMELINE,
  TIMELINE_UPDATE,
  GROUP_ALL_PENDING_REQUEST,
  GROUP_ACCEPT_REQUEST_FROM_NOTIFICATIONS,
  GROUP_REMOVE_REQUEST_FROM_NOTIFICATIONS,
} from '../../redux/constants';
import NotificationList from './notificationList';
import PendingNotificationList from './pendingNotificationList';
import { translationType } from '../../types';
import { translationText } from '../../helpers';

export class Notifications extends Component {
  static propTypes = {
    noonText: translationType.isRequired,
    getGroupRequest: PropTypes.func.isRequired,
    getTimeline: PropTypes.func.isRequired,
    pendingGroupRequest: PropTypes.shape().isRequired,
    notifications: PropTypes.shape().isRequired,
    readNotification: PropTypes.func.isRequired,
    rejectGroupRequest: PropTypes.func.isRequired,
    acceptGroupRequest: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showNotificationList: false,
    };
  }

  componentWillReceiveProps({ notifications }) {
    if (notifications !== this.props.notifications) {
      this.setState({
        isLoading: false,
      });
    }
  }

  toggleNotificationList = (flag) => {
    this.setState(
      (prevState) => ({
        showNotificationList: flag !== undefined ? flag : !prevState.showNotificationList,
      }),
      () => {
        if (this.state.showNotificationList) {
          this.props.getGroupRequest({ start: 0, limit: 20 });
          this.props.getTimeline(0);
        }
      },
    );
  };

  loadMore = () => {
    this.props.getTimeline(this.props.notifications.offset);
  };

  loadMoreForPendingRequest = () => {
    const { pendingGroupRequest } = this.props;
    if (!pendingGroupRequest.isLoading && (pendingGroupRequest.list.length < pendingGroupRequest.meta.total)) {
      this.props.getGroupRequest({ start: pendingGroupRequest.next_start, limit: 20 });
    }
  };

  handleAccpetMember = (member, idx) => {
    this.props.acceptGroupRequest({ group_id: member.group_info.id, users_array: [member.id], loadingIdx: idx });
  };

  handleRejectMember = (member, idx) => {
    this.props.rejectGroupRequest({ group_id: member.group_info.id, users_array: [member.id], loadingIdx: idx });
  };

  render() {
    const { showNotificationList } = this.state;
    const {
      noonText,
      readNotification,
      notifications,
      pendingGroupRequest,
      acceptBtnLoadingId,
      rejectBtnLoadingId,
    } = this.props;

    const { total: totalRequestCount } = pendingGroupRequest.meta;
    const { unreadCount = 0 } = notifications;

    const popoverProps = {
      isOpen: showNotificationList,
      preferPlace: 'below',
      onOuterAction: () => this.toggleNotificationList(false),
      body: (
        !pendingGroupRequest.list.length && !notifications.list.length ? (
          <Column nowrap align="center" className="empty-slate notification-empty">
            {pendingGroupRequest.isLoading && notifications.isLoading ? <IconLoader color="#919a9e" width="50px" height="50px" /> : <IconNoNotification color="#919a9e" width="50px" height="50px" />}
            {!pendingGroupRequest.isLoading && !notifications.isLoading && (
              <p className="no-data text-center">{translationText(noonText, 'social.noNotification')}</p>
            )}
          </Column>
        ) : (
          <div>
            {!isEmpty(pendingGroupRequest) && (
              <PendingNotificationList
                pendingRequest={pendingGroupRequest.list}
                acceptGroupRequest={this.handleAccpetMember}
                rejectGroupRequest={this.handleRejectMember}
                noonText={noonText}
                onClose={() => this.toggleNotificationList(false)}
                readNotification={readNotification}
                onLoad={this.loadMoreForPendingRequest}
                loadMore={pendingGroupRequest.meta.total > pendingGroupRequest.list.length}
                isLoading={pendingGroupRequest.isLoading}
                acceptBtnLoadingId={acceptBtnLoadingId}
                rejectBtnLoadingId={rejectBtnLoadingId}
              />
            )}
            {!isEmpty(notifications) && (
              <NotificationList
                noonText={noonText}
                notifications={notifications}
                onClose={() => this.toggleNotificationList(false)}
                readNotification={readNotification}
                loadMore={this.loadMore}
                isLoading={notifications.isLoading}
              />
            )}
          </div>
        )
      ),
    };

    return (
      <div className="notifications-wrapper">
        <Popover {...popoverProps}>
          <div className="nav-notification-icon" onClick={() => this.toggleNotificationList()}>
            {unreadCount || totalRequestCount ? <IconBell height="24px" /> : <IconBellO height="24px" />}
            {(!!unreadCount || !!totalRequestCount) && (
              <Badge
                rounded
                color="red"
                size="sm"
                value={
                  unreadCount + totalRequestCount > 10
                    ? '+10'
                    : unreadCount + totalRequestCount
                }
              />
            )}
          </div>
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  noonText: state.toJS().translation.noonText,
  pendingGroupRequest: state.toJS().groups.allPendingRequest,
  notifications: state.toJS().user.timeline,
  acceptBtnLoadingId: state.toJS().groups.acceptGroupRequestFromNotifications.isLoading,
  rejectBtnLoadingId: state.toJS().groups.rejectGroupRequestFromNotifications.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getGroupRequest: (payload) => dispatch({ type: GROUP_ALL_PENDING_REQUEST.REQUEST, payload }),
  getTimeline: (offset = 0) => dispatch({ type: TIMELINE.REQUEST, payload: { feed_type: 'notification', offset } }),
  acceptGroupRequest: (payload) => dispatch({ type: GROUP_ACCEPT_REQUEST_FROM_NOTIFICATIONS.REQUEST, payload }),
  rejectGroupRequest: (payload) => dispatch({ type: GROUP_REMOVE_REQUEST_FROM_NOTIFICATIONS.REQUEST, payload }),
  readNotification: (payload) => dispatch({ type: TIMELINE_UPDATE.REQUEST, payload }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notifications);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { isMobileOnly } from 'react-device-detect';
import isEmpty from 'lodash-es/isEmpty';
import { Card, Column, Row, Search, InfiniteScroll } from '@noon/atom';
import Popover from 'react-popover';
import { IconCorrect, IconMediaPlayO } from '@noon/quark';
import debounce from 'lodash-es/debounce';
import {
  GET_EMOJI,
  CREATE_POST,
  GET_COMMENTS,
  CREATE_COMMENT,
  DELETE_COMMENT,
  DELETE_POST,
  GROUP_REMOVE_MEMBERS,
  LIKE_POST,
  LIKE_COMMENT,
  BLOCK_USER,
  UN_BLOCK_USER,
  UPLOAD_FILE,
  POLL_EXPIRE_POST_DATA,
  UPDATE_POLL_POST,
  NOTIFICATION_POST_DATA,
  GET_ALL_POSTS,
} from '../../redux/constants';

import { groupMembersListMapSelector, blockListMapSelector } from '../../redux/selectors/groups';
import StudyGroupPostCard from './studyGroupPostCard';
import { TOAST_TYPE, addToast } from '../Toast';
import Mixpanel from '../Mixpanel';
import { commentsOfApostByIdSelector, loadingStateOfCommentSelector } from '../../redux/selectors/posts';
import { translationText } from '../../helpers';
import { COLORS } from '../../constants';

let filtersApplied = [
  { title: 'All posts', id: 'all' },
  { title: 'My posts', id: 'teacher' },
  { title: 'Student', id: 'student' },
];
class PostContainer extends Component {
  constructor(props) {
    super(props);

    filtersApplied = [
      { title: translationText(props.noonText, 'tab.allPosts'), id: 'all' },
      { title: translationText(props.noonText, 'filter.myPosts'), id: 'teacher' },
      { title: translationText(props.noonText, 'filter.studentPosts'), id: 'student' },
    ];

    this.state = {
      isUploading: 0,
      filter: filtersApplied[0],
      commentPayload: null,
      postText: '',
    };
  }

  componentDidMount() {
    if (!isEmpty(this.props.allPosts.response.list)) {
      this.props.allPosts.response.list.forEach((post) => {
        this.props.getComments({
          id: post.id,
          start: 0,
          limit: isMobileOnly ? 20 : 3,
        });
      });
    }
  }

  componentDidUpdate(preVProps, prevState) {
    const { uploadImageUrls } = this.props;
    if (
      !isEmpty(this.props.fileError)
      && this.props.fileError.uploadImageUrls !== preVProps.fileError.uploadImageUrls
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isUploading: 0 });
      addToast('Network Error', TOAST_TYPE.ERROR);
    }

    if (!isEmpty(this.props.uploadImageUrls) && isEmpty(preVProps.uploadImageUrls) && this.state.isUploading) {
      this.props.sendCommentForPost({
        ...this.state.commentPayload,
        files: [
          {
            file_type: 'image',
            original: uploadImageUrls.large_url,
            small: uploadImageUrls.small_url,
            medium: uploadImageUrls.medium_url,
            large: uploadImageUrls.large_url,
            thumbnail: uploadImageUrls.thumbnail_url,
          },
        ],
      });

      this.props.clearUploadFiles();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isUploading: 0,
      });
    }
  }

  deleteComment = (commentId, postId) => {
    this.props.deleteCommentById({ commentId, postId });
  };

  handleRemoveMember = (memberId) => {
    const { groupId, removeMember } = this.props;
    removeMember({ group_id: groupId, users_array: [memberId] });
    // this.setState({ isMemberRemoving: memberId });
  };

  fileChangedHandler = (imageAdded, idForLoad) => {
    // User cancelled
    const file = imageAdded;
    if (!file) {
      return;
    }
    const fd = new FormData();
    fd.append('destination', 'posts');
    const nameSplit = file.name.split('.');
    const ext = nameSplit[nameSplit.length - 1];
    const originalName = nameSplit[0];
    fd.append('fileUpl', file, file.name);
    fd.append('file_name', `${originalName}.${ext}`);
    this.setState({ isUploading: idForLoad });
    this.props.uploadImage(fd);
  };

  handleCommentSend = (commentText, imagePreviewUrl, imageAdded, postData) => {
    const payload = {
      text: commentText,
      postId: postData.id,
      groupId: this.props.groupId,
    };
    if (!isEmpty(commentText.trim()) && !imagePreviewUrl) {
      this.props.sendCommentForPost(payload);
    } else if (imagePreviewUrl) {
      this.fileChangedHandler(imageAdded, postData.id);
    }

    this.setState({
      commentPayload: payload,
    });
    this.props.handleMixpanelKafkaFeeds({
      type: 'comment_post',
      data: {
        no_of_attachments: postData.files.length,
        attachment_file_type: postData.files.length > 0 ? postData.files[0].fileType : 'NA',
        post_type: postData.type,
        post_author: 'teacher',
      },
    });
  };

  blockUser = (userId) => {
    this.props.blockUser({ user_id: userId, group_id: this.props.groupId });
  };

  unblockUser = (user_id, join_group) => {
    this.props.unBlockUser({ user_id, group_id: this.props.groupId, join_group });
  };

  likeComment = (commentId, postId, reaction, type) => {
    this.props.likeComment({ commentId, postId, reaction, type });
  };

  clearPostIndex() {
    this.setState({ postIndex: null });
  }

  pollOperation = (poll) => {
    if (poll.pollType === 'pollAvailable') {
      this.props.updatePollPost({ active_hours: 0, id: poll.pollData.id, type: poll.postType });
    } else {
      this.props.pollOperationOnParent(poll);
      this.props.pollExpirePostData(poll.pollData);
    }
  };

  showMoreComments = (id) => {
    if (!id) return;
    const { comments } = this.props;
    this.props.getComments({
      id,
      start: comments ? comments[id].meta.paging.start : 0,
      limit: isMobileOnly ? 20 : 5,
      next_tag: comments[id].meta.paging.next_tag,
    });
  };

  deletePost = (postId, type) => {
    this.props.deletePost({ postId });
  };

  likePost = (postId, reaction, type, postData) => {
    this.props.likePost({ postId, reaction, groupId: this.props.groupId });
    this.props.handleMixpanelKafkaFeeds({
      type: 'like_post',
      data: {
        no_of_attachments: postData.files.length,
        attachment_file_type: postData.files.length > 0 ? postData.files[0].fileType : 'NA',
        post_type: postData.type,
        post_author: 'teacher',
      },
    });
  };

  showSinglePost = (postId) => {
    this.props.getPostDataForNotification({ id: postId });
    // eslint-disable-next-line react/no-did-update-set-state
    this.props.showSinglePost(postId);
  };

  loadMorePosts = () => {
    if (!isEmpty(this.props.allPosts.response.list) && !this.props.allPosts.isLoading) {
      const payload = {
        id: this.props.groupId,
        start: this.props.allPosts.response.meta.paging.start,
        limit: 3,
        creator_type: this.state.filter ? this.state.filter.id : undefined,
        next_tag: this.props.allPosts.response.meta.paging.next_tag,
      };
      this.props.getPosts(payload);
    }
  };

  sortOption = (filter) => {
    this.setState({ filter });
    this.props.getPosts({
      id: this.props.groupId,
      start: 0,
      limit: 3,
      creator_type: filter.id,
      text: this.state.postText,
    });
  };

  toggleFilter = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  getPostDebounce = debounce((text) => {
    this.props.getPosts({
      id: this.props.groupId,
      start: 0,
      limit: 3,
      creator_type: this.state.filter ? this.state.filter.id : undefined,
      text,
    });
  }, 500)

  searchPost = (e) => {
    e.persist();
    this.getPostDebounce(e.target.value);
    this.setState({ postText: e.target.value });
  };

  render() {
    const popoverPropsForQuestions = {
      isOpen: this.state.showFilter,
      place: 'below',
      onOuterAction: () => this.toggleFilter(),
      style: { marginTop: '-0.5rem' },
      body: (
        <Column className="filter-item-list">
          {filtersApplied.map((filter) => (
            <Row
              align="center"
              justify="space-between"
              className={classNames('ptb-05 plr-1 select-item', { selected: filter.title === this.state.filter.title })}
              onClick={() => { this.toggleFilter(); this.sortOption(filter); }}
            >
              {filter.title}
              {filter.title === this.state.filter.title
                && <IconCorrect fill={COLORS.brand.base} stroke={COLORS.brand.base} width="24px" height="24px" />}
            </Row>
          ))}
        </Column>
      ),
    };
    const {
      post,
      noonText,
      emojis,
      type,
      user,
      isRtl,
      isPostViewActive,
      groupDetails,
      history,
      comments,
      loadingMoreComments,
      showSinglePost,
      singlePost,
      allPosts,
      isArchived,
    } = this.props;
    return (
      <React.Fragment>
        <Card>
          <Row className="card-header feed-header mtb-2" justify="space-between" align="center" gap="sm">
            <Row className="main-content">
              <h3 className="card-header__title">{translationText(noonText, 'groups.post')}</h3>
              <span className="noon-dot" />
              <Popover {...popoverPropsForQuestions} className="questionList-popover feed-filter-popover">
                <Row onClick={this.toggleFilter} justify="start" align="center" style={{ position: 'relative', color: COLORS.brand.base, cursor: 'pointer' }}>
                  <span>{this.state.filter.title}</span>
                  <IconMediaPlayO height="20px" width="20px" rotate={this.state.showFilter ? '-90' : '90'} fill={COLORS.brand.base} />
                </Row>
              </Popover>
            </Row>
            <Search
              className="feed-search-box"
              name="book-search"
              autoComplete="off"
              loading={false}
              onChange={this.searchPost}
              placeholder={translationText(noonText, 'post.searchAndFilterResultsLabel')}
            />
          </Row>
          <Column align="start" justify="center" className="card-body">
            <InfiniteScroll
              onLoad={this.loadMorePosts}
              loadMore={!!allPosts.response.meta.paging}
              loading={!!allPosts.isLoading && !isEmpty(allPosts.response.list)}
              onWindow
              className="feed-list"
              context={this}
              offset={200}
            >
              <React.Fragment>
                {!isEmpty(allPosts.response.list)
                  && allPosts.response.list.map((post) => (
                    <StudyGroupPostCard
                      key={post.id}
                      animation="fadeIn"
                      user={user}
                      data={post}
                      currentPostIndex={post.id}
                      noonText={noonText}
                      comments={comments[post.id]}
                      emojis={emojis}
                      isPostViewActive={isPostViewActive}
                      sendCommentLoader={this.props.sendComment.isLoading}
                      handleCommentSend={this.handleCommentSend}
                      deleteComment={this.deleteComment}
                      showMoreComments={this.showMoreComments}
                      loadForViewMoreCommentsId={loadingMoreComments}
                      isRtl={isRtl}
                      showPostView={this.showPostView}
                      deletePost={this.deletePost}
                      isUploading={this.state.isUploading}
                      removeMember={this.handleRemoveMember}
                      groupMembersIdMap={this.props.groupMembersIdMap}
                      blockListIdMap={this.props.blockListIdMap}
                      likeComment={this.likeComment}
                      likePost={this.likePost}
                      blockUser={this.blockUser}
                      unblockUser={this.unblockUser}
                      groupDetails={groupDetails}
                      history={history}
                      updateExpire={this.updateExpire}
                      pollOperation={this.pollOperation}
                      pollOperationOnParent={this.pollOperationOnParent}
                      showSinglePost={this.showSinglePost}
                      singlePost={singlePost}
                      getComments={this.props.getComments}
                      isArchived={isArchived}
                    />
                  ))}
                {!allPosts.isLoading && isEmpty(allPosts.response.list) && (
                  <div className="empty-slate flex-column flex-nowrap align-center">
                    <img src="/assets/images/empty-state/no-posts.svg" alt="no Posts" />
                    <p className="subtitle">{translationText(noonText, 'post.noPostEmptyMsgTeacher')}</p>
                  </div>
                )}
              </React.Fragment>
            </InfiniteScroll>
            {!isEmpty(allPosts.response.list) && !allPosts.response.meta.paging
            && (
            <Row
              alig="center"
              justify="center"
              className="plr-2 ptb-2 full-width"
              style={{ backgroundColor: COLORS.grey[4] }}
              >
              {translationText(noonText, 'post.noMore')}
            </Row>
            )}
          </Column>
          {/* </Column> */}
        </Card>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state, props) => ({
  emojis: state.toJS().file.emojis,
  comments: commentsOfApostByIdSelector(state),
  loadingMoreComments: loadingStateOfCommentSelector(state),
  sendComment: state.toJS().posts.sendComment,
  noonText: state.toJS().translation.noonText,
  groupMembersIdMap: groupMembersListMapSelector(state),
  blockListIdMap: blockListMapSelector(state),
  groupError: state.toJS().groups.error,
  likedPost: state.toJS().posts.likePost,
  fileError: state.toJS().file.error,
  uploadImageUrls: state.toJS().file.uploadImageUrls,
  allPosts: state.toJS().posts.allPosts,
});

const mapDispatchToProps = (dispatch) => {
  const commentsByType = GET_COMMENTS;
  return {
    getEmojis: () => dispatch({ type: GET_EMOJI.REQUEST }),
    uploadImage: (data) => dispatch({ type: UPLOAD_FILE.REQUEST, payload: data }),
    sendPost: (data) => dispatch({ type: CREATE_POST.REQUEST, payload: data }),
    getComments: (data) => dispatch({ type: commentsByType.REQUEST, payload: data }),
    sendCommentForPost: (data) => dispatch({ type: CREATE_COMMENT.REQUEST, payload: data }),
    deleteCommentById: (data) => dispatch({ type: DELETE_COMMENT.REQUEST, payload: data }),
    deletePost: (data) => dispatch({ type: DELETE_POST.REQUEST, payload: data }),
    removeMember: (payload) => dispatch({ type: GROUP_REMOVE_MEMBERS.REQUEST, payload }),
    likePost: (payload) => dispatch({ type: LIKE_POST.REQUEST, payload }),
    likeComment: (payload) => dispatch({ type: LIKE_COMMENT.REQUEST, payload }),
    blockUser: (payload) => dispatch({ type: BLOCK_USER.REQUEST, payload }),
    unBlockUser: (payload) => dispatch({ type: UN_BLOCK_USER.REQUEST, payload }),
    pollExpirePostData: (payload) => dispatch({ type: POLL_EXPIRE_POST_DATA, payload }),
    updatePollPost: (payload) => dispatch({ type: UPDATE_POLL_POST.REQUEST, payload }),
    getPostDataForNotification: (payload) => dispatch({ type: NOTIFICATION_POST_DATA.REQUEST, payload }),
    getPosts: (data) => dispatch({ type: GET_ALL_POSTS.REQUEST, payload: data }),

    clearUploadFiles: () => dispatch({ type: UPLOAD_FILE.SUCCESS, payload: {} }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostContainer);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Badge, InfiniteScroll } from '@noon/atom';
import {
  teamsSelector,
  teamsOffsetSelector,
  teamsIsLoadingSelector,
  totalTeamCountsetSelector,
} from 'redux/selectors/teams';
import { noonTextSelector } from 'redux/selectors/translations';
import { translationText } from '../../../helpers';
import { GET_BREAKOUT_TEAMS } from '../../../redux/constants';
import TeamLeaderBoardItem from './TeamLeaderBoardItem';
import NoTeams from './NoTeams';

const TeamLeaderBoard = (prop) => {
  const { teams, sessionId, getBreakoutTeams, offset, loading, totalTeams, noonText } = prop;

  useEffect(() => {
    getBreakoutTeams(sessionId, true);
  }, []);

  return (
    <React.Fragment>
      <Row align="center" className="sidebar-heading-wrapper">
        <h4 className="sidebar-heading">{translationText(noonText, 'teacherBreakout.totalTeams')}</h4>
        <Badge type="primary" size="md">{totalTeams}</Badge>
      </Row>
      {teams.length === 0 && <NoTeams />}
      {teams.length > 0 && (
        <InfiniteScroll
          onLoad={() => getBreakoutTeams(sessionId, false)}
          loadMore={!loading && offset !== -1}
          loading={loading}
          className="team-leader-board"
        >
          {teams.map((item) => (
            <TeamLeaderBoardItem key={item.team_id} team={item} />
          ))}
        </InfiniteScroll>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  teams: teamsSelector(state),
  offset: teamsOffsetSelector(state),
  loading: teamsIsLoadingSelector(state),
  totalTeams: totalTeamCountsetSelector(state),
  noonText: noonTextSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getBreakoutTeams: (sessionId, resetOffset) => dispatch({ type: GET_BREAKOUT_TEAMS.REQUEST, sessionId, resetOffset }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamLeaderBoard);

import { string } from 'prop-types';

export interface ISlideIntervals {
  start_time: number;
  end_time: number;
}

export interface ISlideSectionInfo {
  intervals?: Array<ISlideIntervals>,
  originalIntervals?: Array<ISlideIntervals>,
  label_id: string;
  label_name?: string,
  raise_hand: boolean;
  seek_info: null | number;
  slide_preview: string;
}

export interface ISlide {
  canvas_id: string;
  delete: boolean;
  payload: ISlideSectionInfo;
  session_id: number;
  type: string;
  index?: number;
  label_id?: string,
  resource?: string,
}

export interface ISlideWLength extends ISlide {
  originalLength: number,
  length: number,
  label_name?: string,
  intervals?: Array<ISlideIntervals>,
}

export type IEditedPLaybackDetails = {
  archive_stream_prefix?: string,
  archive_stream_url?: string,
  archive_url?: string,
  class_type: string,
  description: string,
  grade: number,
  id: string,
  is_video_enabled: Boolean,
  last_seek?: string | null,
  publish: Boolean,
  start_time: number,
  teacher_id: number,
  title: string,
  creator: Object,
  curriculum_tags: Object,
  playback_section: Object,
}

export type IAudioProvider = {
  handlePlayerView?: Function,
}

export type TSection = Array<ISlideWLength>;

export type ISectionsData = Array<TSection>;

export type IAreas = Array<{ startPoint: number, endPoint: number }>;

export type TslideStyling = {
  width: number,
  minWidth: number,
  maxWidth: number,
  backgroundImage?: string,
  backgroundPosition?: string,
}

export interface ICreator {
  name?: string,
  gender?: string,
  id: string,
  profile_pic?: string,
  average_rating?: null | number
}

export interface ITag {
  name?: string,
  id: string,
  name_header?: string,
  state?: string,
  type?: string,
}

export interface ISubjectTag extends ITag {
  pic?: string,
  negative_pic?: string,
  color?: string,
  background_pic?: string,
}

export type IPlaybackDetails = {
  id: string,
  grade: number,
  title: string,
  description: string,
  publish: boolean,
  teacher_id: number,
  class_type: string,
  teacher_enabled: true,
  archive_url: string,
  archive_stream_prefix: string,
  archive_stream_url: string,
  start_time: number,
  last_seek: null | number,
  creator: ICreator,
  curriculum_tags: {
    type: string,
    subject: ISubjectTag,
    country: ITag | null,
    course: ITag | null,
    test: ITag | null,
    skill: ITag | null,
    chapter: ITag | null,
    topics: Array<ITag> | null,
    grade: ITag | null,
    degree: ITag | null,
    board: ITag | null,
    major: ITag | null,
    section: ITag | null,
    sections: ITag | null,
    target_grades: ITag | null,
    curriculum: ITag | null
  },
  is_video_enabled: boolean,
  playback_section: Array<ISlide>
}

export type TPlayPublishEventPayload = {
  teacher_id: string,
  session_id: string,
  original_duration: number,
  trimmed_duration: number,
  included_slides_count: number,
  excluded_slides_count: number,
  included_sections_count: number,
  excluded_sections_count: number,
  playback_edited: boolean,
  action_taken_from: string,
}

// Components
export interface IPlaybackContainer {
  typeOfPlayback: string;
}

export interface ICombinedView {
  sections: ISectionsData;
  slides: Array<ISlide>;
  activeSlide: ISlide,
  updateSlideState: Function,
  updateSlideToTrim: Function,
  playerRef: any,
}

export interface ISections {
  currentSlide: { active: ISlideWLength, opened: ISlideWLength };
  updateSlideState: Function;
  playerRef: any;
}

export type TSlideEditView = {
  slide: ISlideWLength,
  goBack: Function,
  saveSlideState: Function,
  updatePlaybackArea: Function,
}

export type TPlaybackDetailsErrorModal = {
  retry: Function,
  retries: number,
  onExit: Function,
  customMessage?: string,
}

export type TPlaybackHeader = {
  playbackDetails: IPlaybackDetails,
  onClose: Function,
  onPublish: Function,
}

export type TPlaybackControls = {
  playerRef: any,
  currentSlide: { active: ISlideWLength, opened: ISlideWLength },
  updateSlideState: Function,
}

export type TProgressBarWithSlider = {
  style?: any,
  enabled: boolean,
  currentTime: number,
  amountBuffered?: number,
  duration: number,
  onSeek: Function,
  openedSlide?: ISlide,
  session_start_time?: number,
  playing?: boolean,
  totalDuration?: number,
}
export interface IProgressSliderItem {
  value: number, style: any, className?: string
}

export type TPlaybackCanvas = {
  playbackView?: string,
  getReadyTimer?: number,
  activeSlideData?: ISlide,
  playerWidth: number,
  playerHeight: number,
}

// Defaults

export const defaultSlide: ISlide = {
  canvas_id: '',
  delete: false,
  session_id: 0,
  type: '',
  payload: {
    intervals: [{ start_time: 0, end_time: 0 }],
    originalIntervals: [{ start_time: 0, end_time: 0 }],
    label_id: '',
    label_name: '',
    raise_hand: false,
    seek_info: null,
    slide_preview: '',
  },
};

export const defaultSlideWLength: ISlideWLength = {
  ...defaultSlide,
  length: 0,
  originalLength: 0,
};

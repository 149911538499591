import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Slider } from 'react-player-controls';
import { useDispatch } from 'react-redux';

import { COLORS } from '../../../constants';
import { PLAYBACK_PAUSE } from '../../../redux/constants';
import { IProgressSliderItem, TProgressBarWithSlider } from '../types';

const SlideProgressBarWithSlider = (props: TProgressBarWithSlider) => {
  const dispatch = useDispatch();
  const { style, enabled, currentTime, duration, onSeek, openedSlide, session_start_time, playing } = props;

  const SliderBar = ({ value, style, className } : IProgressSliderItem) => (
    <span className={classNames('bar', 'is-horizontal', className)} style={{ ...style, width: `${value}%` }} />
  );
  const SliderHandle = ({ value, style }: IProgressSliderItem) => (
    <span className="handle is-horizontal" style={{ ...style, left: `${value}%` }} />
  );

  const [value, setValue] = useState<number>(0);
  const [seeking, setSeeking] = useState<boolean>(false);
  const [seekProgress, setSeekProgressing] = useState<boolean>(false);

  const slideStartTime = ((openedSlide?.payload?.originalIntervals?.[0]?.start_time - session_start_time) / 1000);

  const seekBasedOnContext = (value: number) : void => {
    const newValue = Math.round(slideStartTime + value);
    if (value + 1 === duration) dispatch({ type: PLAYBACK_PAUSE });
    else onSeek(newValue);
  };

  useEffect(() => {
    if (seeking) {
      if (seekProgress) {
        setValue(value);
      } else {
        setValue(value);
        setSeeking(false);
      }
    } else {
      if (value > duration) {
        setSeeking(false);
        setValue(duration);
        dispatch({ type: PLAYBACK_PAUSE });
        return;
      }
      if (playing) {
        setValue(value + 1);
      } else {
        setValue(value);
        seekBasedOnContext(value);
      }
    }
  }, [seeking, currentTime, duration, seekProgress]);

  useEffect(() => {
    setValue(0);
  }, []);

  return (
    <Slider
      className="slider"
      isEnabled={enabled}
      onChange={(newValue) => {
        setValue((newValue / 100) * duration * 100);
        seekBasedOnContext((newValue / 100) * duration * 100);
      }}
      onChangeStart={() => {
        setSeeking(true);
        setSeekProgressing(true);
      }}
      onChangeEnd={() => {
        setSeekProgressing(false);
      }}
      style={style}
    >
      <SliderBar className="total" value={100} style={{ background: '#E4ECF8' }} />
      <SliderBar className="played" value={(value / duration) * 100 > 100 ? 100 : (value / duration) * 100} style={{ background: enabled ? COLORS.brand.base : '#878c88' }} />
      <SliderHandle value={(value / duration) * 100 > 100 ? 100 : (value / duration) * 100} style={{ background: enabled ? COLORS.brand.base : '#878c88', transition: 'left 1s ease-in' }} />
    </Slider>
  );
};

export default SlideProgressBarWithSlider;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Books from './books';
import { IBookPage } from './bookItem';
import { CONTENT_BOOK } from '../../../../redux/constants';
import { selectImageContent } from '../../../../redux/actions/classroom';

const BookContainer = () => {

  const dispatch = useDispatch();
  const bookPages = useSelector((state) => state.toJS().content.book);
  const tagId = useSelector((state) => state.toJS().content.tagId);

  const handleSelectpage = (bookPage: IBookPage) => {
    const payload = {
      resourceId: bookPage.id,
      resourceType: 'bookPage_image',
      url: bookPage.image_uri,
    };
    dispatch(selectImageContent(payload));
  };

  const getBookPages = (tagId: string, pageNumber?: number) => {
    dispatch({ type: CONTENT_BOOK.REQUEST, payload: { page_number: pageNumber, tag_id: `${tagId}.` } });
  };

  const handlePageSearch = (pageNumber?: number) => {
    getBookPages(tagId, pageNumber);
  };

  useEffect(() => {
    // length 1 means result from search, thats why adding check for less than 2 to get all book pages
    if (tagId && bookPages?.list?.length < 2) {
      getBookPages(tagId);
    }
  }, [tagId]);

  return (
    <Books
      bookPages={bookPages}
      onSelectPage={handleSelectpage}
      onSearchPage={handlePageSearch}
    />
  );
};

export default BookContainer;

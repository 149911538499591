import { put, call, takeEvery, all, select } from 'redux-saga/effects';
import isEmpty from 'lodash-es/isEmpty';
import toLower from 'lodash-es/toLower';
import CRC32 from 'crc-32';
import { onboardingSelector } from 'redux/selectors/onboarding';
import { onboardingApi } from '../restApi';
import { commonLogin } from './userv2';
import { SEND_OTP, VERIFY_PHONE_OTP, SEND_OTP_VIA_EMAIL } from '../constants';
import { initializePush } from '../../components/Firebase';
import { addToast, TOAST_TYPE } from '../../components/Toast';

const generateOtpApi = ({ payload, headers }) => onboardingApi.post('generate_otp', payload, { headers });
const verifyOtpApi = (json) => onboardingApi.post('verify_otp', json);

function* sendOtp({ payload }) {
  try {
    const deviceId = JSON.parse(localStorage.temporaryTokenPayload || '{}').device_id;
    const xClientTime = new Date().getTime();
    const paramsForCrcComputation = toLower(
      `${deviceId}${xClientTime}${payload.identity_type}${payload.dialing_code}${payload.identity_value}${process.env.GENERATE_OTP_SECRET}`,
    );

    const xClientCode = (CRC32.str(paramsForCrcComputation) >>> 0).toString(16);
    const headers = {
      'x-noon-client-code': xClientCode,
      'x-client-time': xClientTime,
    };

    const response = yield call(generateOtpApi, { payload, headers });
    if (response.ok) {
      yield put({ type: SEND_OTP.SUCCESS, payload: response.data });
    } else {
      yield put({ type: SEND_OTP.FAILURE, payload: response.data });
      if (response) {
        addToast(
          (response.data && response.data.message)
            || (response.originalError && response.originalError.message)
            || 'Error occured',
          TOAST_TYPE.ERROR,
        );
      }
    }
  } catch (err) {
    yield put({ type: SEND_OTP.FAILURE, payload: err });
  }
}
function* sendOtpViaEmail({ payload }) {
  try {
    const { email } = payload;
    const onboardingData = yield select(onboardingSelector);
    const { verification_id, otp, dialing_code } = onboardingData;
    const payloadPassed = {
      identity_type: 'EMAIL',
      identity_value: email,
      dialing_code,
      link_phone: { verification_id, otp },
    };
    const response = yield call(generateOtpApi, { ...payloadPassed });
    if (response.ok) {
      yield put({ type: SEND_OTP_VIA_EMAIL.SUCCESS, payload: response.data });
    } else {
      yield put({ type: SEND_OTP_VIA_EMAIL.FAILURE, payload: response.data });
    }
  } catch (err) {
    yield put({ type: SEND_OTP_VIA_EMAIL.FAILURE, payload: err });
  }
}
function* verifyOtp({ payload }) {
  try {
    const response = yield call(verifyOtpApi, { ...payload });
    if (response.ok) {
      if (response.data.type === 'login') {
        yield call(commonLogin, { payload: response.data.token_details ? response.data.token_details : {} });
        initializePush();
        yield put({ type: VERIFY_PHONE_OTP.SUCCESS, payload: response.data });
      } else {
        yield put({ type: VERIFY_PHONE_OTP.SUCCESS, payload: response.data });
      }
    } else {
      yield put({ type: VERIFY_PHONE_OTP.FAILURE, payload: response.data });
    }
  } catch (err) {
    yield put({ type: VERIFY_PHONE_OTP.FAILURE, payload: err });
  }
}

export function* onboardingSaga() {
  yield all([takeEvery(SEND_OTP.REQUEST, sendOtp)]);
  yield all([takeEvery(SEND_OTP_VIA_EMAIL.REQUEST, sendOtpViaEmail)]);
  yield all([takeEvery(VERIFY_PHONE_OTP.REQUEST, verifyOtp)]);
}

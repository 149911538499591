import {
  WEBRTC_TOGGLE_AUDIO,
  UPDATE_WEBRTC_STREAMS,
  SET_WEBRTC_STATUS,
  SET_DISABLE_WEBRTC,
  RESET_LAST_SESSION_DATA,
  UPDATE_MIC_VOLUME_INDICATOR,
  WEBRTC_TOGGLE_VIDEO,
} from '../constants';

const initialState = {
  streams: [],
  audioStatus: false,
  isAudioStatusLoading: false,
  disableWebrtc: false,
  micVolumeIndicator: {},
  videoStatus: false,
  isVideoStatusLoading: false,
  status: '',
  error: {},
};

export default function webrtc(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_LAST_SESSION_DATA:
      return {
        ...initialState,
      };
    case WEBRTC_TOGGLE_AUDIO.REQUEST:
      return {
        ...state,
        isAudioStatusLoading: true,
      };
    case WEBRTC_TOGGLE_AUDIO.SUCCESS:
      return {
        ...state,
        audioStatus: action.payload,
        isAudioStatusLoading: false,
      };
    case WEBRTC_TOGGLE_AUDIO.FAILURE:
      return {
        ...state,
        isAudioStatusLoading: false,
        error: {
          audioStatus: action.payload,
        },
      };
    case WEBRTC_TOGGLE_VIDEO.REQUEST:
      return {
        ...state,
        isVideoStatusLoading: true,
      };
    case WEBRTC_TOGGLE_VIDEO.SUCCESS:
      return {
        ...state,
        videoStatus: action.payload,
        isVideoStatusLoading: false,
      };
    case WEBRTC_TOGGLE_VIDEO.FAILURE:
      return {
        ...state,
        isVideoStatusLoading: false,
        error: {
          videoStatus: action.payload,
        },
      };
    case UPDATE_WEBRTC_STREAMS:
      return {
        ...state,
        streams: [...action.payload],
      };
    case SET_WEBRTC_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case SET_DISABLE_WEBRTC:
      return {
        ...state,
        disableWebrtc: action.payload,
      };
    case UPDATE_MIC_VOLUME_INDICATOR:
      return {
        ...state,
        micVolumeIndicator: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
}

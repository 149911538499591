import { GET_BREAKOUT_TEAMS, UPDATE_BREAKOUT_TEAMS_OFFSET, NEW_TEAM_FORMED } from '../constants';

const initialState = {
  teams: [],
  loading: false,
  total_teams: 0,
};

export default function breakoutTeams(state = initialState, action) {
  switch (action.type) {
    case GET_BREAKOUT_TEAMS.REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_BREAKOUT_TEAMS.SUCCESS:
      return {
        ...state,
        teams: action.resetOffset ? [...action.payload.data] : [...state.teams, ...action.payload.data],
        error: false,
        loading: false,
        ...action.payload.meta,
      };
    case NEW_TEAM_FORMED:
      return {
        ...state,
        total_teams: state.total_teams + 1,
      };
    case GET_BREAKOUT_TEAMS.ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case UPDATE_BREAKOUT_TEAMS_OFFSET:
      return {
        ...state,
        offset: action.payload,
      };
    default:
      return state;
  }
}

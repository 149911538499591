import React from 'react';
import { Column, Row } from '@noon/atom';
import NoonQuestion from '../../NoonQuestion/NoonQuestion';
import QuestionChoice from '../../NoonQuestion/QuestionChoice';
import { IQuestion } from '../Content/Questions/questionContainer';
import t from '../../../helpers/translate';
import { IconExclamationCircle } from '@noon/quark';

const ChoiceItem = (prop) => {
  const { dir, choice, showStats } = prop;
  return (
    <Column nowrap className="whiteboard-question__choice-wrapper">
      {showStats && !!choice.avg_vote && (
        <span className="choice-votes" style={{ width: `${choice.avg_vote}%` }}>
          {`${choice.total_votes || 0} Vote (${choice.avg_vote || 0}%)`}
        </span>
      )}
      <QuestionChoice dir={dir} choice={choice} correct={choice.is_correct} />
    </Column>
  );
};

const WhiteboardQuestion = (prop : { showStats?: Boolean, question: IQuestion, showSolution?: Boolean }) => {

  const { question, showSolution, showStats } = prop;
  const { text_direction, passage_json, question_json, choices = [], solution_json } = question;

  if (!question?.id) {
    return null;
  }

  return (
    <Column nowrap className="whiteboard-question" dir={text_direction}>
      {Boolean(passage_json?.length) && <NoonQuestion dir={text_direction} content={passage_json} />}
      {Boolean(question_json?.length) && <NoonQuestion dir={text_direction} content={question_json} />}

      <div className="whiteboard-question__choices">
        {choices.map((choice) => <ChoiceItem showStats={showStats} key={choice.id} choice={choice} dir={text_direction} />)}
      </div>

      {showSolution && Boolean(solution_json?.length) && (
        <>
          <h4 className="mt-2">{t('practice', 'solution')}</h4>
          <NoonQuestion className="mt-1" dir={text_direction} content={solution_json} />
        </>
      )}
      {
        showStats && 
          <Row align="center" nowrap className="network-issue--tipBox mt-1">
            <div className="headingTip">
              <IconExclamationCircle height="25px" width="25px" fill="#fff" rotate="180" />
            </div>
            
              <Row className="plr-1">
                <span>
                  {
                    question.total_votes > question.total_students ?
                    t("classroom", "questionAttemptedBy").replace("{NumberOfStudent}", `${question.total_votes || 0}/${question.total_votes || 0}`) :
                    t("classroom", "questionAttemptedBy").replace("{NumberOfStudent}", `${question.total_votes || 0}/${question.total_students || 0}`)
                  }
                </span>
              </Row>
          </Row>
      }
    </Column>
  );
};

export default WhiteboardQuestion;

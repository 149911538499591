import React from 'react';
import { InfiniteScroll } from '@noon/atom';
import FriendRequest from './friendRequest';

export default function PendingNotificationList(prop) {
  return (
    <InfiniteScroll
      onLoad={prop.onLoad}
      loadMore={prop.loadMore}
      loading={prop.isLoading}
      className="notification-list"
    >
      {!!prop.pendingRequest.length && <FriendRequest {...prop} />}
    </InfiniteScroll>
  );
}

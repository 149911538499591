import { put, call, takeEvery, all } from 'redux-saga/effects';
import merge from 'lodash-es/merge';
import isEmpty from 'lodash-es/isEmpty';
import { translationApi } from '../restApi';
import { GET_TRANSLATION, GET_TRANSLATION_ON_TIMESTAMP } from '../constants';
import { localesForTranslation } from '../../constants';

const getTranslationOnTimestampAPI = (json) => translationApi.post('translationByTimeStamp/', {
  groups: json.groups,
  locale: localesForTranslation[localStorage.getItem('updatedLocale')] || 'en',
  timeStamp: json.date,
});

// not in use
function* getTranslation({ payload }) {
  try {
    yield put({
      type: GET_TRANSLATION.LOADING,
      payload: { isLoading: true },
    });
    const response = yield call(getTranslationOnTimestampAPI, payload);
    if (response.data.success) {
      yield put({
        type: GET_TRANSLATION.SUCCESS,
        payload: isEmpty(response.data.data) ? {} : response.data.data[0],
      });
      delete localStorage.groupsNotPresent;
      const translationFromLocalStorage = JSON.parse(localStorage.getItem('newTranslations'));
      localStorage.setItem(
        'newTranslations',
        JSON.stringify({
          ...translationFromLocalStorage,
          ...response.data.data[0],
        }),
      );
    } else {
      yield put({
        type: GET_TRANSLATION.FAILURE,
        payload: response.data.message,
      });
    }
  } catch (err) {
    yield put({ type: GET_TRANSLATION.FAILURE, payload: err });
  } finally {
    yield put({
      type: GET_TRANSLATION.LOADING,
      payload: { isLoading: false },
    });
  }
}

function* getTranslationOnTimeStamp({ payload }) {
  try {
    const response = yield call(getTranslationOnTimestampAPI, payload);
    if (response.data.success) {
      yield put({
        type: GET_TRANSLATION_ON_TIMESTAMP.SUCCESS,
        payload: isEmpty(response.data.data) ? {} : response.data.data[0],
      });
      localStorage.setItem('isStaleTranslation');

      const localStorageData = JSON.parse(localStorage.getItem('translationData')).data;
      const lastUnChangedDate = response.data.timeStamp;
      const updatedData = merge({}, localStorageData, response.data.data[0]);
      localStorage.setItem(
        'translationData',
        JSON.stringify({
          data: updatedData,
          date: lastUnChangedDate,
          locale: localesForTranslation[localStorage.getItem('updatedLocale')] || 'en',
        }),
      );
    } else {
      yield put({
        type: GET_TRANSLATION_ON_TIMESTAMP.FAILURE,
        payload: response.data.message,
      });
    }
  } catch (err) {
    yield put({ type: GET_TRANSLATION_ON_TIMESTAMP.FAILURE, payload: err });
  }
}

export function* translationSaga() {
  yield all([
    takeEvery(GET_TRANSLATION.REQUEST, getTranslation),
    takeEvery(GET_TRANSLATION_ON_TIMESTAMP.REQUEST, getTranslationOnTimeStamp),
  ]);
}

import { findIndex } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Badge, Column } from '@noon/atom';
import {
  IconLoader,
  IconMediaPause,
  IconMediaPlay,
  IconMediaReplay,
} from '@noon/quark';

import { convertLengthToTimeStamp } from '../../../helpers';
import t from '../../../helpers/translate';
import { useOnPlaybackSeek } from '../../../hooks';
import { PLAYBACK_PAUSE, PLAYBACK_START } from '../../../redux/constants';
import {
  defaultSlideWLength,
  ISlideIntervals,
  TPlaybackControls,
} from '../types';
import ProgressBarWithSlider from './progressBar';
import SlideProgressBarWithSlider from './singleSlideProgressBar';

function PlaybackControls(props: TPlaybackControls) {
  const dispatch = useDispatch();
  const { playerRef, currentSlide, updateSlideState } = props;

  const { active = defaultSlideWLength, opened = defaultSlideWLength } = currentSlide;

  // Hooks and states
  const [, , onSeek] = useOnPlaybackSeek(playerRef);

  // Redux state
  const {
    editedPlaybackDetails,
    playerData,
    playback_sections,
  } = useSelector((state) => state.toJS().playback);

  const [deletedPortion, setDeletedPortion] = useState<boolean>(false);

  const { data: slides, original: originalSlides, session_start } = playback_sections;
  const { loading: playbackDetailsLoading } = editedPlaybackDetails;
  const { loadedSeconds, playedSeconds, state: playerState, ended, duration, fullDuration } = playerData;
  const slidePlayed : number = playedSeconds - ((opened?.payload?.originalIntervals?.[0]?.start_time - originalSlides?.[0]?.payload?.intervals?.[0].start_time) / 1000);

  // TODO implement these
  const replay = ended;

  const checkIfInBound = (currentIntervals : Array<ISlideIntervals>) => (currentIntervals
    && (currentIntervals?.[0]?.start_time - session_start) / 1000 <= playedSeconds
    && (currentIntervals?.[currentIntervals.length - 1]?.end_time - session_start) / 1000 >= playedSeconds);

  const findSlideIndexById = (slideId: string) => findIndex(slides, { canvas_id: slideId });

  useEffect(() => {
    const index = findSlideIndexById(currentSlide?.active?.canvas_id);
    updateSlideState({
      active: {
        index: findSlideIndexById(slides?.[index]?.canvas_id),
        label_name: slides?.[index]?.payload?.label_name,
        label_id: slides?.[index]?.payload?.label_id,
        intervals: slides?.[index]?.payload?.intervals,
        canvas_id: slides?.[index]?.canvas_id,
        delete: slides?.[index]?.delete,
      },
    });
  }, [slides]);

  // Effects
  useEffect(() => {
    // check if in bound of currentSlide
    if (checkIfInBound(currentSlide?.active?.intervals)) {
      // in bounds
      if (currentSlide?.active?.delete) {
        setDeletedPortion(true);
      } else if (currentSlide?.active?.intervals) {
        const inPortion = currentSlide?.active?.intervals?.find((item) => (item.start_time - session_start) / 1000 <= playedSeconds
          && (item.end_time - session_start) / 1000 >= playedSeconds);
        setDeletedPortion(!inPortion);
      } else {
        setDeletedPortion(false);
      }
    } else {
      // out of bound
      const updatedSlide = slides.find((item) => checkIfInBound(item.payload.intervals));
      if (updatedSlide?.canvas_id) {
        updateSlideState({
          active: {
            index: findSlideIndexById(updatedSlide?.canvas_id),
            label_name: updatedSlide?.payload?.label_name,
            label_id: updatedSlide?.payload?.label_id,
            intervals: updatedSlide?.payload?.intervals,
            canvas_id: updatedSlide?.canvas_id,
            delete: updatedSlide?.delete,
          },
        });
      }
    }
  }, [playedSeconds, session_start]);

  return (
    <Column>
      {active.canvas_id ? (
        <p className="active-slide-name text-left">
          <span>
            {active.label_name && <span>{active.label_name}</span>}
            <strong>{' > '}</strong>
            {`${t('playback', 'slide')} ${active.index + 1}`}
          </span>
          {deletedPortion && <Badge color="red" size="xs" className="mlr-1"> DELETED </Badge>}
          {/* {opened.canvas_id && <span>{`  ·  ${t('playback', 'currentDuration')}: ${Math.floor(trimmedDuration / 60)} ${t('label', 'minutes')}, ${Math.round(trimmedDuration % 60)} ${t('label', 'seconds')}`}</span>} */}
        </p>
      ) : null}
      <div className="playback__controls">
        {replay && playerState === 'paused' ? (
          <IconMediaReplay
            onClick={() => {
              dispatch({ type: PLAYBACK_START });
            }}
            className="session-class-type"
            title="Replay"
            fill="#000000"
            />
        ) : playbackDetailsLoading || playerState === 'buffering' ? (
          <IconLoader className="session-class-type" />
        ) : playerState === 'paused' || playerState === 'ready' ? (
          <IconMediaPlay
            title="play"
            className="session-class-type"
            onClick={() => {
              dispatch({ type: PLAYBACK_START });
            }}
            fill="#000000"
          />
        ) : playerState === 'playing' ? (
          <IconMediaPause
            title="pause"
            className="session-class-type"
            onClick={() => {
              dispatch({ type: PLAYBACK_PAUSE });
            }}
            fill="#000000"
          />
        ) : null}
        <div className="progress">
          {
            !opened.canvas_id
              ? convertLengthToTimeStamp(playedSeconds)
              : convertLengthToTimeStamp(slidePlayed > duration ? duration : slidePlayed)
          }
          {!opened.canvas_id
            ? (
              <ProgressBarWithSlider
                amountBuffered={loadedSeconds / duration}
                enabled={!playbackDetailsLoading}
                currentTime={playedSeconds}
                // openedSlide={opened}
                duration={duration}
                onSeek={onSeek}
                // playing={playerState === 'playing'}
                // session_start_time={session_start}
            />
            ) : (
              <SlideProgressBarWithSlider
                enabled={!playbackDetailsLoading}
                currentTime={playedSeconds}
                openedSlide={opened}
                duration={duration}
                // totalDuration={fullDuration}
                onSeek={onSeek}
                playing={playerState === 'playing'}
                session_start_time={session_start}
              />
            )}
          {!!duration && duration > 0 && (
            !opened.canvas_id
              ? convertLengthToTimeStamp(duration)
              : convertLengthToTimeStamp((opened?.payload?.originalIntervals?.[0]?.end_time - opened?.payload?.originalIntervals?.[0]?.start_time) / 1000)
          )}
          {!duration && <IconLoader fill="#000000" />}
        </div>
      </div>
    </Column>
  );
}

export default PlaybackControls;

import isNumber from 'lodash-es/isNumber';
import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_SNAPSHOT_DATA, UPDATE_PLAYBACK_LAST_SEEK, PLAYBACK_END } from '../redux/constants';

function useOnPlaybackSeek(playerRef) {
  const dispatch = useDispatch();
  const [seekPoint, updateSeekPoint] = useState(0);
  const [seeking, setSeeking] = useState(false);
  const [seekStartedOn, setSeekStarted] = useState(null);
  const currSeekPos = useRef(0);

  const { editedPlaybackDetails, snapShotData, playerData, timeSeriesData, playback_sections } = useSelector((state) => state.toJS().playback);
  const { state: playerState, playedSeconds } = playerData;

  const onSeek = (seekPosition) => {
    setSeeking(true);

    if (seekPosition && Number.isNaN(seekPosition)) {
      setSeeking(false);
      return;
    }

    const { id: session_id } = editedPlaybackDetails?.data?.[0];
    const delta = Math.round(seekPosition * 1000);
    const { start_time } = playback_sections.data[0].payload.intervals[0];
    const timestamp = start_time + delta || 0;

    if (currSeekPos.current === seekPosition === playedSeconds) {
      setSeeking(false);
      return;
    }

    const convertedSeekPosition = Math.round((timestamp - start_time) / 1000);
    const isEnd = Math.abs(Math.round(playerData.duration) - convertedSeekPosition) === 0;
    currSeekPos.current = (playedSeconds);
    playerRef.current.seekTo(seekPosition);

    if (isEnd) dispatch({ type: PLAYBACK_END });

    currSeekPos.current = seekPosition;
    playerRef.current.seekTo(seekPosition);

    // Fetch snapshot and timeseries data
    if (!snapShotData.loading) {
      dispatch({
        type: GET_SNAPSHOT_DATA.REQUEST,
        payload: { session_id, last_seek: timestamp, second: Math.round(seekPosition) },
      });
    }
    setSeekStarted(seekPosition);

    // Send new seek position
    dispatch({
      type: UPDATE_PLAYBACK_LAST_SEEK,
      payload: { session_id, timestamp, currentSeekSeconds: seekPosition },
    });
  };

  useEffect(() => {
    if (isNumber(seekStartedOn) && !timeSeriesData.loading && !snapShotData.loading) {
      updateSeekPoint(seekStartedOn);
      setSeekStarted(null);
      setSeeking(false);
    }
  }, [timeSeriesData.loading, snapShotData.loading]);
  return [seekPoint, seeking, onSeek];
}

export default useOnPlaybackSeek;

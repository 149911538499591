import React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash-es/isEmpty';
import { IconLoader } from '@noon/quark';
import { Column, Row } from '@noon/atom';
import { omit } from 'lodash-es';
import CalendarActivitySessionCard from './CalendarActivitySessionCard';
import CalendarActivityReservedCard from './CalendarActivityReservedCard';
import NoActivityOnDayCard from './NoActivityOnDayCard';
import { translationText } from '../../../helpers';

export default function CalendarActivityCardList({
  noonText,
  activitiesList,
  selectedWeekDays,
  style,
  className,
  toggleCreateActivity,
  handleDeleteActivity,
  handleTogglePlayback,
  deleteSession,
  filters,
  loading,
  selectedWeekStartedOn,
}) {

  return (
    <Column>
      <Row style={style} align="start" className={classNames('planner-calender-card', className)}>
        {/* main calender block */}
        {!loading
          ? (
            <div className="main-calender">
              <ul>
                {Object.keys(selectedWeekDays).map((weekDay, index) => (
                  <li className="item" key={index}>
                    <div className={classNames('day_block text-capitalize', { active: selectedWeekDays[weekDay] === translationText(noonText, 'datetime.today') })}>
                      <h4>{selectedWeekDays[weekDay]}</h4>
                    </div>
                  </li>
                ))}
              </ul>
              <ul className="activity-cards-block">
                {Object.keys(selectedWeekDays).map((weekDay, index) => (
                  <li className="item" key={index}>
                    {!isEmpty(activitiesList[weekDay]) ? activitiesList[weekDay].map((activity) => (
                      <React.Fragment key={activity.start_time}>
                        {(activity.type === 'group' || activity.type === 'competition')
                          ? (
                            <CalendarActivitySessionCard
                              handleDeleteActivity={handleDeleteActivity}
                              deleteSession={deleteSession}
                              handleTogglePlayback={handleTogglePlayback}
                              activity={activity}
                              noonText={noonText}
                              toggleCreateActivity={toggleCreateActivity}
                            />
                          )
                          : (
                            <CalendarActivityReservedCard
                              noonText={noonText}
                              activity={activity}
                              toggleCreateActivity={toggleCreateActivity} />
                          )}
                      </React.Fragment>
                    )) : (
                      <NoActivityOnDayCard
                        noonText={noonText}
                        date={selectedWeekStartedOn.plus({ days: index })}
                        toggleCreateActivity={toggleCreateActivity}
                        isFilterApplied={!isEmpty(omit(filters, ['week_offset', 'group_id']))} />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )
          : (
            <Row alig="center" justify="center" className="ptb-2 full-width">
              <IconLoader />
            </Row>
          )}
        {/* main calender block */}
      </Row>
    </Column>
  );
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get, isEmpty } from 'lodash-es';
import { IconBack, IconFilter } from '@noon/quark';
import Popover from 'react-popover';
import { DateTime } from 'luxon';
import { Column, Row, Button } from '@noon/atom';
import { COLORS } from '../../../constants';
import FilterGroupList from '../Common/FiltersGroupList';
import CheckboxFilters from '../Common/CheckboxFilters';
import FiltersApplied from '../Common/FiltersApplied';
import { translationType } from '../../../types';
import { translationText } from '../../../helpers';

function CalendarCardHeader({
  loggedUser,
  filterCount,
  disabled,
  filters,
  groupsList,
  onFilterSelection,
  noonText,
  selectedWeekStartedOn,
  handleOnWeekChange,
  style,
  className,
  lessBtn,
  moreBtn,
}) {
  const isRtl = document.body.dir === 'rtl';
  const weekOfMonth = (date) => {
    const currentWeek = DateTime.local().startOf('week').minus({ days: 1 });
    if (date.toFormat('yyyy-MM-dd') === currentWeek.toFormat('yyyy-MM-dd')) {
      return translationText(noonText, 'datetime.thisWeek');
    } if (date.toFormat('yyyy-MM-dd') === currentWeek.plus({ days: 7 }).toFormat('yyyy-MM-dd')) {
      return translationText(noonText, 'datetime.nextWeek');
    } if (date.toFormat('yyyy-MM-dd') === currentWeek.minus({ days: 7 }).toFormat('yyyy-MM-dd')) {
      return translationText(noonText, 'planner.lastWeek');
    }
    // eslint-disable-next-line no-bitwise
    return `${date.toFormat('dd ccc')} - ${date.plus({ days: 1 }).endOf('week').minus({ days: 1 }).toFormat('dd ccc')}`;
  };
  const [selectedWeek, setSelectedWeek] = useState(() => weekOfMonth(selectedWeekStartedOn));
  const [showAllGroupsFilter, setShowAllGroupsFilter] = useState(false);
  const [showAllActivitiesFilter, setShowAllActivitesFilter] = useState(false);
  const isSelectedDatePastDate = (date) => date.ts < new Date('01/01/2020').getTime() || date.ts < DateTime.fromISO(loggedUser.publish_date).ts;
  const [inActiveButton, setInActiveButton] = useState(() => (isSelectedDatePastDate(selectedWeekStartedOn) ? 'prevButton' : ''));

  const handleNextWeekClick = () => {
    const updatedDate = selectedWeekStartedOn.plus({ days: 7 });
    const selectedDateWeek = weekOfMonth(updatedDate);
    setSelectedWeek(selectedDateWeek);
    handleOnWeekChange(updatedDate);
    setInActiveButton(() => (isSelectedDatePastDate(updatedDate) ? 'prevButton' : ''));
  };

  const handlePreviousWeekClick = () => {
    const updatedDate = selectedWeekStartedOn.minus({ days: 7 });
    const selectedDateWeek = weekOfMonth(updatedDate);
    setSelectedWeek(selectedDateWeek);
    handleOnWeekChange(updatedDate);
    setInActiveButton(() => (isSelectedDatePastDate(updatedDate) ? 'prevButton' : ''));
  };
  useEffect(() => {
    const selectedDateWeek = weekOfMonth(selectedWeekStartedOn);
    setSelectedWeek(selectedDateWeek);
    setInActiveButton(() => (isSelectedDatePastDate(selectedWeekStartedOn) ? 'prevButton' : ''));
  }, [selectedWeekStartedOn]);

  const popoverPropsForGroups = {
    isOpen: showAllGroupsFilter,
    place: 'below',
    preferPlace: 'start',
    onOuterAction: () => setShowAllGroupsFilter(false),
    body: (
      <div className="general-planner__filters calendar-view__filters">
        <FilterGroupList noonText={noonText} onFilterSelection={onFilterSelection} groupsList={groupsList} filters={filters} />
      </div>
    ),
  };
  const popoverPropsForActivities = {
    isOpen: showAllActivitiesFilter,
    place: 'below',
    preferPlace: 'start',
    onOuterAction: () => setShowAllActivitesFilter(false),
    body: (
      <div className="general-planner__filters">
        <CheckboxFilters filterCount={filterCount} noonText={noonText} onFilterSelection={onFilterSelection} filters={filters} />
      </div>
    ),
  };
  return (
    <Column style={{ position: 'relative' }}>
      {disabled && <div className="disabled-overlay" />}
      <Row style={style} align="center" className={classNames('noon-calendar-card-header', className)}>
        <div className="left_content">
          {!!lessBtn && (
          <Button type="link" onClick={handlePreviousWeekClick} disabled={inActiveButton === 'prevButton'}>
            {lessBtn}
            {' '}
            <IconBack fill={COLORS.coolGrey.base} flipX={isRtl} />
          </Button>
          )}
          <div className="center_content">
            <h4 className="month">{selectedWeekStartedOn.toFormat('MMMM yyyy')}</h4>
            <span className="noon-dot" />
            <h4 className="week">{selectedWeek}</h4>
          </div>
          {!!moreBtn && (
          <Button type="link" onClick={handleNextWeekClick}>
            {moreBtn}
            {' '}
            <IconBack fill={COLORS.coolGrey.base} flipX={!isRtl} />
          </Button>
          )}
        </div>
        <Row align="center" justify="start" gap flex="1" className="right_content general-planner__filters">
          <Popover {...popoverPropsForGroups}>
            <Button type="link" onClick={() => setShowAllGroupsFilter(true)}>
              <IconFilter className="icon" fill={COLORS.brand.base} stroke={COLORS.brand.base} />
              <h6 className="name" title={get(filters, ['group_id', 'title']) || translationText(noonText, 'planner.allGroups')}>{get(filters, ['group_id', 'title']) || translationText(noonText, 'planner.allGroups')}</h6>
            </Button>
          </Popover>
          <Popover {...popoverPropsForActivities}>
            <Button type="link" onClick={() => setShowAllActivitesFilter(true)}>
              <IconFilter className="icon" fill={COLORS.brand.base} stroke={COLORS.brand.base} />
              <h6>{get(filters, 'filters') ? translationText(noonText, 'planner.someActivities') : translationText(noonText, 'planner.sortAllActivity')}</h6>
            </Button>
          </Popover>
        </Row>
      </Row>
      {!isEmpty(filters)
        && <FiltersApplied noonText={noonText} filters={filters} onFilterSelection={onFilterSelection} />}
    </Column>
  );
}

CalendarCardHeader.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.shape({}),
  groupsList: PropTypes.arrayOf(PropTypes.shape({})),
  onFilterSelection: PropTypes.func.isRequired,
  noonText: translationType.isRequired,
  selectedWeekStartedOn: PropTypes.shape({}).isRequired,
  handleOnWeekChange: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
  className: PropTypes.string,
  lessBtn: PropTypes.bool,
  moreBtn: PropTypes.bool,
  onClick: PropTypes.func,
  filterCount: PropTypes.shape({}),
  loggedUser: PropTypes.shape({}),
};

CalendarCardHeader.defaultProps = {
  disabled: false,
  filters: {},
  groupsList: [],
  style: {},
  className: '',
  lessBtn: true,
  moreBtn: true,
  filterCount: {},
  loggedUser: {},
  onClick: () => {},
};

export default CalendarCardHeader;

import React from 'react';
import { Row } from '@noon/atom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { IconSorting } from '@noon/quark/dist';
import { SORT_STUDENTS } from '../../redux/constants';

const ReportPageBarTabs = (props) => {
  const { tabs, setSelectedTab, selectedTab } = props;

  const dispatch = useDispatch();

  const sortStudentsList = () => {
    dispatch({
      type: SORT_STUDENTS,
    });
  };

  return (
    <Row justify="space-between" align="center" className="prepare-assignment--tabs">
      {tabs ? tabs.map((tab) => (
        <Row
          justify="center"
          align="center"
          className={`prepare-assignment--tabs-tab ${tab.value === selectedTab ? 'selected-tab' : ''}`}
          key={tab.value}
          onClick={() => setSelectedTab(tab.value)}
          >
          {tab.value === selectedTab && tab.value === 'Submitted' && (
          <Row
            justify="center"
            align="center"
            className="report--bar--sorting"
            onClick={() => sortStudentsList()}
              >
            <IconSorting width="17px" height="15px" />
          </Row>
          )}
          {tab.name}
        </Row>
      ),
      ) : null}
    </Row>
  );
};

ReportPageBarTabs.propTypes = {
  tabs: PropTypes.arrayOf({}).isRequired,
  selectedTab: PropTypes.string.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
};

export default ReportPageBarTabs;

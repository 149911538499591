import React from 'react';
import { translationText } from '../../../helpers';

export default function FeatureList(prop) {
  const { noonText, icons, group } = prop;
  return (
    <div className="feature-section">
      <div className="head-fade-line">
        <span className="fading-line" />
      </div>
      <h2 className="section-title">
        {translationText(noonText, `${group}.featureTitle`)}
      </h2>
      <p className="section-subtitle">
        {translationText(noonText, `${group}.featureSubtitle`)}
      </p>

      <div className="feature-table container">
        <div className="border-overlay" />
        <div className="feature-block">
          <div className="feature-item">
            <icons.one />
            <div className="feature-content">
              <div className="feature-name">
                {translationText(noonText, `${group}.featureOne`)}
              </div>
              <div className="feature-desc">
                {translationText(noonText, `${group}.featureOneDescription`)}
              </div>
            </div>
          </div>
          <div className="feature-item">
            <icons.two />
            <div className="feature-content">
              <div className="feature-name">
                {translationText(noonText, `${group}.featureTwo`)}
              </div>
              <div className="feature-desc">
                {translationText(noonText, `${group}.featureTwoDescription`)}
              </div>
            </div>
          </div>
        </div>
        <div className="feature-block">
          <div className="feature-item">
            <icons.three />
            <div className="feature-content">
              <div className="feature-name">
                {translationText(noonText, `${group}.featureThree`)}
              </div>
              <div className="feature-desc">
                {translationText(noonText, `${group}.featureThreeDescription`)}
              </div>
            </div>
          </div>
          <div className="feature-item">
            <icons.four />
            <div className="feature-content">
              <div className="feature-name">
                {translationText(noonText, `${group}.featureFour`)}
              </div>
              <div className="feature-desc">
                {translationText(noonText, `${group}.featureFourDescription`)}
              </div>
            </div>
          </div>
        </div>
        <div className="feature-block">
          <div className="feature-item">
            <icons.five />
            <div className="feature-content">
              <div className="feature-name">
                {translationText(noonText, `${group}.featureFive`)}
              </div>
              <div className="feature-desc">
                {translationText(noonText, `${group}.featureFiveDescription`)}
              </div>
            </div>
          </div>
          <div className="feature-item">
            <icons.six />
            <div className="feature-content">
              <div className="feature-name">
                {translationText(noonText, `${group}.featureSix`)}
              </div>
              <div className="feature-desc">
                {translationText(noonText, `${group}.featureSixDescription`)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

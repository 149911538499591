import {
  CONTENT_BOOK,
  CONTENT_FLASHCARD,
  CONTENT_QUESTION,
  CONTENT_SET_CHAPTER_TOPIC_ID,
  CONTENT_SET_TAG_ID,
  RESET_LAST_SESSION_DATA,
  SET_SELECTED_QUESTION_CONTENT,
  SET_SELECTED_IMAGE_CONTENT,
  CREATE_QUESTION_MODAL,
} from '../constants';

const initialState = {
  book: {
    list: [],
    isLoading: false,
  },
  flashcard: {
    list: [],
    isLoading: false,
    topicId: 0,
  },
  question: {
    list: [],
    isLoading: false,
    limit: 10,
    sort: 'DESC',
    nextPage: -1,
    topicId: 0,
  },
  pdf: {
    list: [],
    isLoading: false,
  },
  tagId: '',
  chapterId: '',
  topicId: '',
  selectedImage: null,
  selectedQuestion: null,
  error: {},
  createQuestion: {
    showCreateQuesitonModal: false,
  },
};

function clearLocalStorage() {
  localStorage.removeItem('content_chapter_id');
  localStorage.removeItem('content_topic_id');
  localStorage.removeItem('content_tag_id');
}

export default function content(state = initialState, action) {
  switch (action.type) {
    case RESET_LAST_SESSION_DATA:
      clearLocalStorage();
      return {
        ...initialState,
      };
    case CONTENT_BOOK.REQUEST:
      return {
        ...state,
        book: {
          ...state.book,
          isLoading: true,
        },
      };
    case CONTENT_BOOK.SUCCESS:
      return {
        ...state,
        book: {
          ...state.book,
          isLoading: false,
          list: action.payload,
        },
      };
    case CONTENT_BOOK.FAILURE:
      return {
        ...state,
        book: {
          ...state.book,
          isLoading: false,
        },
        error: {
          book: action.payload,
        },
      };
    case CONTENT_FLASHCARD.REQUEST:
      return {
        ...state,
        flashcard: {
          ...state.flashcard,
          isLoading: true,
        },
      };
    case CONTENT_FLASHCARD.SUCCESS:
      return {
        ...state,
        flashcard: {
          ...state.flashcard,
          isLoading: false,
          list: action.payload,
          topicId: action.meta.topic_id,
        },
      };
    case CONTENT_FLASHCARD.FAILURE:
      return {
        ...state,
        flashcard: {
          ...state.flashcard,
          isLoading: false,
        },
        error: {
          flashcard: action.payload,
        },
      };
    case CONTENT_QUESTION.REQUEST:
      return {
        ...state,
        question: {
          ...state.question,
          isLoading: true,
        },
      };
    case CONTENT_QUESTION.SUCCESS:
      return {
        ...state,
        question: {
          ...state.question,
          isLoading: false,
          list: action.meta.page === 1 ? action.payload : [...state.question.list, ...action.payload],
          nextPage: action.meta.next_page,
          topicId: action.meta.topic_id,
        },
      };
    case CONTENT_QUESTION.FAILURE:
      return {
        ...state,
        question: {
          ...state.question,
          isLoading: false,
        },
        error: {
          question: action.payload,
        },
      };
    case CONTENT_SET_CHAPTER_TOPIC_ID:
      // save in localstorage to retain chapter topic after refresh
      localStorage.setItem('content_chapter_id', action.payload.chapterId || state.chapterId);
      localStorage.setItem('content_topic_id', action.payload.topicId || state.topicId);
      return {
        ...state,
        chapterId: action.payload.chapterId || state.chapterId,
        topicId: action.payload.topicId || state.topicId,
      };
    case CONTENT_SET_TAG_ID:
      localStorage.setItem('content_tag_id', action.payload || state.tagId);
      return {
        ...state,
        tagId: action.payload || state.tagId,
      };
    case SET_SELECTED_IMAGE_CONTENT:
      return {
        ...state,
        selectedImage: action.payload,
      };
    case SET_SELECTED_QUESTION_CONTENT:
      return {
        ...state,
        selectedQuestion: action.payload,
      };
    case CREATE_QUESTION_MODAL:
      return {
        ...state,
        createQuestion: {
          showCreateQuesitonModal: action.payload,
        },
      };
    default:
      if (action.type.indexOf('_REQUEST') !== -1) {
        return {
          ...state,
          error: {},
        };
      }
      return state;
  }
}

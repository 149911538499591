import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash-es/isEmpty';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import { LOGGED_OUT_TEACHER } from '../../redux/constants';
import { userType } from '../../types';

class Logout extends Component {
  static propTypes = {
    loggedOut: PropTypes.func.isRequired,
    user: userType.isRequired,
  };

  componentDidMount() {
    this.logout();
  }

  componentWillReceiveProps({ user }) {
    if (user !== this.props.user && isEmpty(user)) {
      /* eslint-disable react/prop-types */
      this.props.history.push('/');
    }
  }

  logout = () => {
    this.props.loggedOut();
  };

  render() {
    return <div />;
  }
}

const mapStateToProps = state => ({
  user: state.toJS().user.loggedUser,
});

const mapDispatchToProps = dispatch => ({
  loggedOut: () => dispatch({ type: LOGGED_OUT_TEACHER.REQUEST, payload: {} }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Logout);

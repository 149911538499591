import React, { useState, useEffect } from 'react';
import { Badge, Row, Vr } from '@noon/atom';
import { IconCrown, IconExclamationCircle, IconEye, IconLock } from '@noon/quark';
import isEmpty from 'lodash-es/isEmpty';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash-es/get';
import { isMobileOnly } from 'react-device-detect';
import classNames from 'classnames';
import PageHeader from '../../Layout/pageHeader';
import SharePopover from '../SharePopover';
import GroupInfoModal from '../GroupInfo';
import { COLORS } from '../../../constants';
import { translationText } from '../../../helpers';

const GroupHeader = ({ toggleArchiveModal, group = {}, isArchived, openGroupInfo, onClose, handleMixpanelCurriculumEvents, showCreateSchedule, handleOpenCreateSchedule, curriculumExist }) => {
  const user = useSelector((state) => state.toJS().userv2.getTeacher.response);
  const { noonText } = useSelector((state) => state.toJS().translation);
  const [showGroupInfoModal, setShowGroupInfoModal] = useState(false);

  useEffect(() => {
    setShowGroupInfoModal(openGroupInfo);
  }, [openGroupInfo]);

  const handleGroupInfoModalClose = () => {
    if (!showGroupInfoModal) {
      handleMixpanelCurriculumEvents({ type: 'group_info_viewed', mixPanel: false });
    }
    setShowGroupInfoModal(!showGroupInfoModal);
    onClose();
  };

  const getProductDetail = () => get(group, 'curriculum_tags.subject')
    || get(group, 'curriculum_tags.test')
    || get(group, 'curriculum_tags.skill')
    || get(group, 'curriculum_tags.course') || { color: '#F5B700' };

  const isPremium = group && group.group_info && group.group_info.is_premium;
  return (
    !isEmpty(group) && (
      <React.Fragment>
        <PageHeader hideNotificationBar title={get(group, 'group_info.title')} className="page-header">
          <Row nowrap align="center" gap="sm" className="page-header__curriculum-details">
            {isPremium && (
            <span className="premium child">{translationText(noonText, 'groups.plus')}</span>
            )}
            <Row
              nowrap
              align="center"
              className="child page-header__subject"
              style={{ color: getProductDetail().color }}
            >
              {getProductDetail().pic && <img src={getProductDetail().pic} alt="" />}
              {' '}
&nbsp;
              {getProductDetail().name}
            </Row>
            <Vr height="10px" className="child" />
            <span className="child">
              {get(group, 'curriculum_tags.grade.name') || get(group, 'curriculum_tags.sections[0].name')}
            </span>
            <Vr height="10px" className="child" />
            <Row nowrap align="center" className="child" gap="sm">
              {get(group, 'group_info.type') === 'private' ? <IconLock width="12px" height="15px" fill={COLORS.coolGrey[1]} stroke={COLORS.coolGrey[1]} className="child" /> : <IconEye width="12px" height="15px" fill={COLORS.coolGrey[1]} stroke={COLORS.coolGrey[1]} className="child" />}
              <span className="child" style={{ whiteSpace: 'nowrap' }}>
                {translationText(
                  noonText,
                  get(group, 'group_info.type') === 'private' ? 'groups.private' : 'groups.public',
                )}
              </span>
            </Row>
          </Row>
          {isPremium && isMobileOnly && (
            <Row className="premium" align="center">
              <IconCrown height="24px" width="24px" />
            </Row>
          )}
          <IconExclamationCircle
            onClick={handleGroupInfoModalClose}
            flipY
            width="20px"
            height="20px"
            className={classNames('child', { active: showGroupInfoModal })}
          />
          {!isArchived && (
          <SharePopover
            membersInfo={group.membersInfo}
            shareInfo={group.group_info}
            trigger="group-header"
          />
          )}
        </PageHeader>
        <GroupInfoModal
          user={user}
          group={group}
          onClose={handleGroupInfoModalClose}
          show={showGroupInfoModal}
          showCreateSchedule={showCreateSchedule}
          handleOpenCreateSchedule={handleOpenCreateSchedule}
          curriculumExist={curriculumExist}
          toggleArchiveModal={toggleArchiveModal}
          isArchived={isArchived}
        />

      </React.Fragment>
    )
  );
};

GroupHeader.propTypes = {
  onClose: PropTypes.func,
  handleMixpanelCurriculumEvents: PropTypes.func,
  showCreateSchedule: PropTypes.bool.isRequired,
  handleOpenCreateSchedule: PropTypes.func.isRequired,
  curriculumExist: PropTypes.bool.isRequired,
};

GroupHeader.defaultProps = {
  onClose: () => {},
  handleMixpanelCurriculumEvents: () => {},
};
export default GroupHeader;

import React, { useState } from 'react';
import classNames from 'classnames';
import { Badge } from '@noon/atom';
import { IconCommentO, IconMembersO, IconPdf, IconStudy, IconTeam } from '@noon/quark';
import ContentSidebar from './contentSidebar';
import ClassLeaderboard from './classLeaderboard';
import { COLORS } from '../../constants';
import ChatOldContainer from './chatOldContainer';
import ErrorBoundry from '../ErrorBoundry';
import RaiseHandWidget from './raiseHandWidget';
import PdfUpload from './PdfUpload';
import t from '../../helpers/translate';

export default function ClassSidebar(prop) {
  const [showClassSidebar, setShowClassSidebar] = useState(false);
  const {
    raiseHandQueue,
    raisedHandUser,
    sendRaiseHand,
    noonText,
    selectedTab,
    sessionData,
    topicContent,
    showRelatedContent,
    getContent,
    addResourceToCanvas,
    isSearchingBook,
    searchBookByNumber,
    showEnlarged,
    sendStudentImageToCanvas,
    addQuestion,
    unreadChats,
    teamFormationComplete,
    fullscreen,
    onSelect,
    quizMode,
    chats,
    sendSocketSignal,
    handleIncomingChatMsg,
    chatBlockedByTeacher,
    handleLoadMoreQuestion,
    raiseHandLoadingFor,
    removeAllActiveMic,
    agoraStreams,
  } = prop;

  const isStarted = sessionData.state === 'started';
  const leaderboardDisabled = !isStarted;
  const contentDisabled = sessionData.class_type === 'competition' && isStarted;
  const chatDisabled = !isStarted;
  const handleTabs = (type) => {
    onSelect(type);
    if (fullscreen) {
      setShowClassSidebar(true);
    }
  };

  const disbaleRaiseHand = quizMode && teamFormationComplete;

  return (
    <ErrorBoundry msg={t('error', 'loadingSidebar')}>
      <React.Fragment>
        {showClassSidebar && <div className="class-sidebar--overlay" onClick={() => setShowClassSidebar(false)} />}
        <div className={classNames('class-sidebar', 'flex-column', { active: showClassSidebar })}>
          <header className="class-sidebar--header">
            {!chatDisabled && (
              <div
                className={classNames('sidebar-tab', {
                  active: selectedTab === 'chat',
                  'single-tab': leaderboardDisabled && contentDisabled,
                })}
                onClick={() => handleTabs('chat')}
              >
                {selectedTab !== 'chat' && !!unreadChats && <Badge rounded type="red" size="xs" value={unreadChats} />}
                <IconCommentO
                  height="22px"
                  color={selectedTab === 'chat' ? COLORS.brand.base : COLORS.text.light}
                />
                <span>{t('classroom', 'chatTab')}</span>
              </div>
            )}
            {!chatDisabled && <div className="hr" />}
            {!leaderboardDisabled && (
              <div
                className={classNames('sidebar-tab', {
                  active: selectedTab === 'leaderboard',
                  'single-tab': leaderboardDisabled || chatDisabled,
                })}
                onClick={() => handleTabs('leaderboard')}
              >
                {teamFormationComplete ? (
                  <IconTeam
                    height="22px"
                    color={selectedTab === 'leaderboard' ? COLORS.brand.base : COLORS.text.light}
                    stroke={selectedTab === 'leaderboard' ? COLORS.brand.base : COLORS.text.light}
                  />
                ) : (
                  <IconMembersO
                    height="22px"
                    color={selectedTab === 'leaderboard' ? COLORS.brand.base : COLORS.text.light}
                    stroke={selectedTab === 'leaderboard' ? COLORS.brand.base : COLORS.text.light}
                   />
                )}
                <span>{t('classroom', 'leaderboardTab')}</span>
              </div>
            )}
            {!leaderboardDisabled && <div className="hr" />}
            {!contentDisabled && (
              <div
                className={classNames('sidebar-tab', {
                  active: selectedTab === 'content',
                  'single-tab': leaderboardDisabled && chatDisabled,
                })}
                onClick={() => handleTabs('content')}
              >
                <IconStudy
                  height="22px"
                  color={selectedTab === 'content' ? COLORS.brand.base : COLORS.text.light}
                  stroke={selectedTab === 'content' ? COLORS.brand.base : COLORS.text.light}
                />
                <span>{t('classroom', 'contentTab')}</span>
              </div>
            )}
            {!leaderboardDisabled && <div className="hr" />}
            {sessionData.class_type !== 'competition' && (
              <div
                className={classNames('sidebar-tab', {
                  active: selectedTab === 'pdf',
                  'single-tab': leaderboardDisabled && chatDisabled,
                })}
                onClick={() => handleTabs('pdf')}
              >
                <IconPdf
                  height="22px"
                  color={selectedTab === 'pdf' ? COLORS.brand.base : COLORS.text.light}
                  stroke={selectedTab === 'pdf' ? COLORS.brand.base : COLORS.text.light}
                />
                <span>{t('classroom', 'contentTab')}</span>
              </div>
            )}
          </header>
          {/* <div className="hr" /> */}
          <div className="sidebar-body">
            {selectedTab === 'chat' ? (
              <React.Fragment>
                {!disbaleRaiseHand && (
                  <RaiseHandWidget
                    noonText={noonText}
                    raiseHandLoadingFor={raiseHandLoadingFor}
                    removeAllActiveMic={removeAllActiveMic}
                    raiseHandQueue={raiseHandQueue}
                    raisedHandUser={raisedHandUser}
                    sendRaiseHand={sendRaiseHand}
                    agoraStreams={agoraStreams}
                  />
                )}
                <div className={classNames('chat-wrapper', { 'raise-hand-close': disbaleRaiseHand })}>
                  <ChatOldContainer
                    sendStudentImageToCanvas={sendStudentImageToCanvas}
                    chats={chats}
                    sendSocketSignal={sendSocketSignal}
                    handleIncomingChatMsg={handleIncomingChatMsg}
                    chatBlockedByTeacher={chatBlockedByTeacher}
                  />
                </div>
              </React.Fragment>
            ) : selectedTab === 'content' ? (
              <ErrorBoundry msg={t('error', 'sidebarContent')}>
                <ContentSidebar
                  addResourceToCanvas={addResourceToCanvas}
                  sessionData={sessionData}
                  showEnlarged={showEnlarged}
                  topicContent={topicContent}
                  showRelatedContent={showRelatedContent}
                  getContentForTopic={getContent}
                  noonText={noonText}
                  isSearchingBook={isSearchingBook}
                  searchBookByNumber={searchBookByNumber}
                  addQuestion={addQuestion}
                  handleLoadMoreQuestion={handleLoadMoreQuestion}
                />
              </ErrorBoundry>
            ) : selectedTab === 'pdf' ? (
              <PdfUpload sessionData={sessionData} addResourceToCanvas={addResourceToCanvas} />
            ) : (
              <ClassLeaderboard {...prop} />
            )}
          </div>
        </div>
      </React.Fragment>
    </ErrorBoundry>
  );
}

import React from 'react';
import classNames from 'classnames';

export default function Checkbox(prop) {
  const { label, style, className, bit, children, ...rest } = prop;
  return (
    <label style={prop.style} className={classNames('noon-form-checkbox', prop.className)}>
      <input type="checkbox" {...rest} />
      <span className="plr-1">{label}</span>
      {children}
      <span className={classNames('custom-checkbox', { bit })} />
    </label>
  );
}

import React from 'react';
import { NoonLink, Row, Column } from '@noon/atom';
import { IconMenu } from '@noon/quark';

export default function LandingNavbar() {
  const locale = localStorage.language;
  return (
    <Row className="hero-section" justify="space-between">
      <NoonLink
        type="link"
      >
        {locale === 'en' ? 'العربية' : 'English'}
      </NoonLink>
      <Column><IconMenu height="16px" /></Column>
    </Row>
  );
}

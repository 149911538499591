import React from 'react';
import { Button, Modal } from '@noon/atom';
import t from '../../../helpers/translate';

type Props = {
  onExit: () => void,
  handleOnClickRetry: () => void
}

const TestFailedModal = ({ handleOnClickRetry, onExit }: Props) => (
  <Modal align="center" className="network-test network-test--fail">
    <h1>{t('classroom', 'networkHeadingError')}</h1>
    <footer className="modal-footer">
      <Button type="secondary" onClick={() => onExit()}>
        {t('classroom', 'exitClass')}
      </Button>
      <Button type="primary" onClick={() => handleOnClickRetry()}>
        {t('classroom', 'retry')}
      </Button>
    </footer>
  </Modal>
);

export default TestFailedModal;

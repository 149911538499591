import React from 'react';
import { useParams } from 'react-router-dom';
import ClassroomContainer from '../../components/Classroom/classroomContainer';
import { ClassroomAnalyticsProvider } from '../../providers/ClassroomAnalytics';

const Classroom = () => {

  const params = useParams();
  return (
    <ClassroomAnalyticsProvider>
      <ClassroomContainer id={params.id} />
    </ClassroomAnalyticsProvider>
  );
};

export default Classroom;

import merge from 'lodash-es/merge';
import { GET_TRANSLATION, GET_TRANSLATION_ON_TIMESTAMP } from '../constants';

const initialState = {
  translationRequesting: true,
  noonText: {},
  error: {},
  loading: false,
};

export default function translation(state = initialState, action) {
  switch (action.type) {
    case GET_TRANSLATION.REQUEST:
      return {
        ...state,
        error: {},
      };
    case GET_TRANSLATION.SUCCESS:
      return {
        ...state,
        noonText: merge({}, state.noonText, action.payload),
      };
    case GET_TRANSLATION.FAILURE:
      return {
        ...state,
        error: { noonText: action.payload },
      };
    case GET_TRANSLATION.LOADING:
      return {
        ...state,
        loading: action.payload.isLoading,
      };
    case GET_TRANSLATION_ON_TIMESTAMP.REQUEST:
      return {
        ...state,
        error: {},
      };
    case GET_TRANSLATION_ON_TIMESTAMP.SUCCESS:
      return {
        ...state,
        noonText: merge({}, state.noonText, action.payload),
      };
    case GET_TRANSLATION_ON_TIMESTAMP.FAILURE:
      return {
        ...state,
        error: { noonText: action.payload },
      };
    case GET_TRANSLATION_ON_TIMESTAMP.LOADING:
      return {
        ...state,
        loading: action.payload.isLoading,
      };
    default:
      return state;
  }
}

import { all, call, put, takeEvery } from 'redux-saga/effects';
import { LIST_APPROVED_PAYMENT, LIST_PENDING_CASH_PAYMENT, UPDATE_CASH_PAYMENT_STATE } from '../constants';
import { addToast, TOAST_TYPE } from '../../components/Toast';
import { packageApi } from '../restApi';

const listPendingCashPaymentAPI = ({ page = 1, limit = 10, ...payload }) => packageApi.get('/payment/list/cash/pending', { page, limit, ...payload });
const listApprovedPaymentsAPI = ({ page = 1, limit = 10, ...payload }) => packageApi.get('/payment/list/purchased', { page, limit, ...payload });
const updateCashPaymentStateAPI = (payload) => packageApi.put('/payment/cash/change_state', payload);

function* listPendingCashPayment({ payload = {} }) {
  try {
    const response = yield call(listPendingCashPaymentAPI, payload);
    if (response.ok) {
      yield put({
        type: LIST_PENDING_CASH_PAYMENT.SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: LIST_PENDING_CASH_PAYMENT.FAILURE,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: LIST_PENDING_CASH_PAYMENT.FAILURE,
      payload: error,
    });
  }
}

function* listApprovedPayments({ payload = {} }) {
  try {
    const response = yield call(listApprovedPaymentsAPI, payload);
    if (response.ok) {
      yield put({
        type: LIST_APPROVED_PAYMENT.SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: LIST_APPROVED_PAYMENT.FAILURE,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: LIST_APPROVED_PAYMENT.FAILURE,
      payload: error,
    });
  }
}

function* updateCashPaymentState({ payload = {} }) {
  try {
    const response = yield call(updateCashPaymentStateAPI, payload);
    if (response.ok) {
      yield put({
        type: UPDATE_CASH_PAYMENT_STATE.SUCCESS,
        payload,
      });
    } else {
      yield put({
        type: UPDATE_CASH_PAYMENT_STATE.FAILURE,
        payload: response.data.message,
      });
      addToast(response.data.message, TOAST_TYPE.ERROR);
    }
  } catch (error) {
    yield put({
      type: UPDATE_CASH_PAYMENT_STATE.FAILURE,
      payload: error,
    });
  }
}

export function* packagesSaga() {
  yield all([
    takeEvery(LIST_PENDING_CASH_PAYMENT.REQUEST, listPendingCashPayment),
    takeEvery(LIST_APPROVED_PAYMENT.REQUEST, listApprovedPayments),
    takeEvery(UPDATE_CASH_PAYMENT_STATE.REQUEST, updateCashPaymentState),
  ]);
}

function generateConstants(service, base, loading) {
  if (loading) {
    return {
      REQUEST: `[${service}] ${base} REQUEST`,
      SUCCESS: `[${service}] ${base} SUCCESS`,
      FAILURE: `[${service}] ${base} FAILURE`,
      LOADING: `[${service}] ${base} LOADING`,
    };
  }
  return {
    REQUEST: `[${service}] ${base} REQUEST`,
    SUCCESS: `[${service}] ${base} SUCCESS`,
    FAILURE: `[${service}] ${base} FAILURE`,
  };
}

// ## FILE SERVICE ##
export const GET_EMOJI = generateConstants('file', 'GET_EMOJI');
export const UPLOAD_FILE = generateConstants('file', 'UPLOAD_FILE');
export const COMPETITION_EMOJI = generateConstants('file', 'COMPETITION_EMOJI');
export const BULK_FILE_UPLOAD = generateConstants('file', 'BULK_FILE_UPLOAD');
export const RESET_UPLOAD_IMAGE_PROGRESS = 'RESET_UPLOAD_IMAGE_PROGRESS';
export const UPLOAD_IMAGE_PROGRESS = 'UPLOAD_IMAGE_PROGRESS';
export const UPLOAD_VIDEO = generateConstants('file', 'UPLOAD_VIDEO');

// ## FLASHCARD SERVICE ##
export const GET_FLASHCARD = generateConstants('flashcard', 'GET_FLASHCARD');

// ## FOLDER SERVICE ##
export const LIST_COUNTRY = generateConstants('folder', 'LIST_COUNTRY');
export const SEARCH_PAGE = generateConstants('folder', 'SEARCH_PAGE');
// ## QUESTION SERVICE ##
export const GET_QUESTION = generateConstants('question', 'GET_QUESTION');
export const QUESTION_LIST_WITH_CHOICES = generateConstants('question', 'QUESTION_LIST_WITH_CHOICES');
export const ASSIGN_QUESTION = generateConstants('question', 'ASSIGN_QUESTION');
export const CREATE_QUESTION = generateConstants('question', 'CREATE_QUESTION');
export const ADD_CHOICE_QUESTION = generateConstants('question', 'ADD_CHOICE_QUESTION');
export const TEACHER_QUESTION_LIST = generateConstants('question', 'TEACHER_QUESTION_LIST', true);
export const LIBRARY_QUESTION_LIST = generateConstants('question', 'LIBRARY_QUESTION_LIST');
export const DELETE_TEACHER_QUESTION = generateConstants('question', 'DELETE_TEACHER_QUESTION');
export const QUESTIONS_LIST = generateConstants('question', 'QUESTIONS_LIST', true);
export const CREATE_QUESTION_MODAL = 'CREATE_QUESTION_MODAL';

// ## TRANSLATION SERVICE ##
export const GET_TRANSLATION = generateConstants('translate', 'GET_TRANSLATION');
export const GET_TRANSLATION_ON_TIMESTAMP = generateConstants('translate', 'GET_TRANSLATION_ON_TIMESTAMP', true);

// ## TUTORING SERVICE ##
export const JOIN_CLASS = generateConstants('tutoring', 'JOIN_CLASS');
export const CLEAR_WEBRTC_DETAILS = 'CLEAR_WEBRTC_DETAILS';
export const START_ARCHIVE = generateConstants('tutoring', 'START_ARCHIVE');
export const TOGGLE_PLAYBACK = generateConstants('tutoring', 'TOGGLE_PLAYBACK');
export const CREATE_GROUP_TUTORING_FOR_TEACHER = generateConstants('tutoring', 'CREATE_GROUP_TUTORING_FOR_TEACHER');
export const UPDATE_GROUP_TUTORING = generateConstants('tutoring', 'UPDATE_GROUP_TUTORING');
export const UPCOMING_TEACHER_SESSION = generateConstants('tutoring', 'UPCOMING_TEACHER_SESSION');
export const GET_TEACHER_DETAIL = generateConstants('tutoring', 'GET_TEACHER_DEATIL');
export const MY_PREVIOUS_GROUP_TUTORING = generateConstants('tutoring', 'MY_PREVIOUS_GROUP_TUTORING');
export const GROUP_TUTORING_UNIQUE_VALUES = generateConstants('tutoring', 'GROUP_TUTORING_UNIQUE_VALUES');
export const GROUP_TUTORING_REVIEWS = generateConstants('tutoring', 'GROUP_TUTORING_REVIEWS');
export const CONFIG_REASON = generateConstants('tutoring', 'CONFIG_REASON');
export const TOKBOX_SESSION = generateConstants('tutoring', 'TOKBOX_SESSION');
export const START_GROUP_TUTORING = generateConstants('tutoring', 'START_GROUP_TUTORING');
export const VOTE_TEACHER = generateConstants('tutoring', 'VOTE_TEACHER');
export const DELETE_SESSION = generateConstants('tutoring', 'DELETE_SESSION');
export const GET_CLASS_REPLAY = generateConstants('tutoring', 'GET_CLASS_REPLAY');
export const SET_SESSION_CONTAINER_STATE_TO_PREVIOUS = 'SET_SESSION_CONTAINER_STATE_TO_PREVIOUS';
export const CLEAR_TUTORING_DETAILS = 'CLEAR_TUTORING_DETAILS';

// ## USER SERVICE ##
export const GET_PROFILE = generateConstants('user', 'GET_PROFILE');
export const LIST_TEACHERS = generateConstants('user', 'LIST_TEACHERS');
export const TIMELINE = generateConstants('user', 'TIMELINE');
export const PROFILE = generateConstants('user', 'PROFILE');
export const GET_TEACHER_REVIEWS = generateConstants('user', 'GET_TEACHER_REVIEWS');
export const TIMELINE_UPDATE = generateConstants('user', 'TIMELINE_UPDATE');
export const SERVER_TIME = generateConstants('user', 'SERVER_TIME');

// ## Toast ##
export const ADD_TOAST = 'ADD_TOAST';
export const UPDATE_TOAST = 'UPDATE_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

// ## groups ##
export const GET_GROUP_DETAILS = generateConstants('groups', 'GET_GROUP_DETAILS');
export const GET_GROUPS = generateConstants('groups', 'GET_GROUPS');
export const CREATE_GROUP = generateConstants('groups', 'CREATE_GROUP');
export const UPDATE_GROUP = generateConstants('groups', 'UPDATE_GROUP');
export const DELETE_GROUP = generateConstants('groups', 'DELETE_GROUP');
export const GROUP_MEMBERS = generateConstants('groups', 'GROUP_MEMBERS');
export const GROUP_PENDING_REQUEST = generateConstants('groups', 'GROUP_PENDING_REQUEST');
export const GROUP_ACCEPT_REQUEST = generateConstants('groups', 'GROUP_ACCEPT_REQUEST');
export const GROUP_REJECT_REQUEST = generateConstants('groups', 'GROUP_REJECT_REQUEST');
export const GROUP_REMOVE_MEMBERS = generateConstants('groups', 'GROUP_REMOVE_MEMBERS');
export const GROUP_ALL_PENDING_REQUEST = generateConstants('groups', 'GROUP_ALL_PENDING_REQUEST');
export const GROUP_REMOVE_REQUEST_FROM_NOTIFICATIONS = generateConstants('groups', 'GROUP_REMOVE_REQUEST_FROM_NOTIFICATIONS');
export const GROUP_ACCEPT_REQUEST_FROM_NOTIFICATIONS = generateConstants('groups', 'GROUP_ACCEPT_REQUEST_FROM_NOTIFICATIONS');
export const GROUP_METRICS = generateConstants('groups', 'GROUP_METRICS');
export const BLOCK_USER = generateConstants('groups', 'BLOCK_USER', true);
export const UN_BLOCK_USER = generateConstants('groups', 'UN_BLOCK_USER', true);
export const BLOCK_USER_LIST = generateConstants('groups', 'BLOCK_USER_LIST', true);
export const SHOW_CREATE_GROUP_SIDEBAR = 'SHOW_CREATE_GROUP_SIDEBAR';
export const BLOCK_USER_LIST_MAP = 'BLOCK_USER_LIST_MAP';
export const GROUP_MEMBERS_MAP = 'GROUP_MEMBERS_MAP';
export const GROUP_FOR_SESSION = 'GROUP_FOR_SESSION';
export const GROUP_FOR_SESSION_CREATION = 'GROUP_FOR_SESSION_CREATION';
export const GET_RECOMMENDED_USERS_FOR_GROUP = generateConstants('groups', 'GET_RECOMMENDED_USERS_FOR_GROUP');
export const SEARCH_GROUP_MEMBERS = generateConstants('groups', 'SEARCH_GROUP_MEMBERS');
export const CLEAR_GROUP_MEMBERS = 'CLEAR_GROUP_MEMBERS';
export const ARCHIVE_GROUP = generateConstants('groups', 'ARCHIVE_GROUP');
export const UNARCHIVE_GROUP = generateConstants('groups', 'UNARCHIVE_GROUP');
export const GET_TEACHER_ARCHIVE_GROUPS = generateConstants('groups', 'GET_TEACHER_ARCHIVE_GROUPS');

// ## posts ##
export const GET_ALL_POSTS = generateConstants('posts', 'GET_ALL_POSTS', true);
export const GET_POST_BY_ID = generateConstants('posts', 'GET_POST_BY_ID', true);
export const CREATE_POST = generateConstants('posts', 'CREATE_POST', true);
export const GET_COMMENTS = generateConstants('posts', 'GET_COMMENTS', true);
export const CREATE_COMMENT = generateConstants('posts', 'CREATE_COMMENT', true);
export const DELETE_COMMENT = generateConstants('posts', 'DELETE_COMMENT', true);
export const DELETE_POST = generateConstants('posts', 'DELETE_POST', true);
export const LIKE_POST = generateConstants('posts', 'LIKE_POST', true);
export const SEARCH_AND_FILTER = generateConstants('posts', 'SEARCH_AND_FILTER');
export const CLEAR_SEARCH_AND_FILTER = generateConstants('posts', 'CLEAR_SEARCH_AND_FILTER');
export const LIKE_COMMENT = generateConstants('posts', 'LIKE_COMMENT', true);
export const QUEUE_FOR_POST = 'QUEUE_FOR_POST';
export const QUEUE_FOR_COMMENT = 'QUEUE_FOR_COMMENT';
export const CREATE_POLL_POST = generateConstants('posts', 'CREATE_POLL_POST');
export const UPDATE_POLL_POST = generateConstants('posts', 'UPDATE_POLL_POST');
export const GET_COMMENT_POSITION = generateConstants('posts', 'GET_COMMENT_POSITION', true);
export const NOTIFICATION_POST_DATA = generateConstants('posts', 'NOTIFICATION_POST_DATA', true);
export const NOTIFICATION_COMMENT_DATA = generateConstants('posts', 'NOTIFICATION_COMMENT_DATA', true);
export const POLL_EXPIRE_POST_DATA = 'POLL_EXPIRE_POST_DATA';

// # dashboard ##
export const CREATE_CLASS_SIDEBAR = {
  OPEN: 'CREATE_CLASS_SIDEBAR_OPEN',
  CLOSE: 'CREATE_CLASS_SIDEBAR_CLOSE',
};
export const SAVE_CURRENT_RESOURCE = 'SAVE_CURRENT_RESOURCE';
export const SELECTED_PRODUCT = 'SELECTED_PRODUCT';
export const TEACHER_PRODUCT = 'TEACHER_PRODUCT';
export const REDIRECT_URL = 'REDIRECT_URL';
export const UPDATE_DATA_POINTS = 'UPDATE_DATA_POINTS';
export const UPDATE_TEMP_AUTH_DATA = 'UPDATE_TEMP_AUTH_DATA';
export const GO_TO_LOGIN = 'GO_TO_LOGIN';
export const INVALID_TIME_MODAL = 'INVALID_TIME_MODAL';
export const SET_SESSION_COACHMARK = 'SET_SESSION_COACHMARK';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const TOGGLE_HOVER_SIDEBAR = 'TOGGLE_HOVER_SIDEBAR';
export const TOGGLE_TAB_STATUS = 'TOGGLE_TAB_STATUS';

// # Notification ##
export const USER_PUSH_TOKEN = generateConstants('notification', 'USER_PUSH_TOKEN');
export const USER_PUSH_TOKEN_DELETE = generateConstants('notification', 'USER_PUSH_TOKEN_DELETE');
export const TOGGLE_NOTIFICATION_BAR = 'TOGGLE_NOTIFICATION_BAR';

// ## USER SERVICE version 2 ##
export const NOONERS_LOGIN = generateConstants('userv2', 'NOONERS_LOGIN', true);
export const SIGNUP_TEACHER = generateConstants('userv2', 'SIGNUP_TEACHER', true);
export const GET_TEACHER_PROFILE = generateConstants('userv2', 'GET_TEACHER_PROFILE', true);
export const UPDATE_TEACHER_PROFILE = generateConstants('userv2', 'UPDATE_TEACHER_PROFILE', true);
export const LOGGED_OUT_TEACHER = generateConstants('userv2', 'LOGGED_OUT_TEACHER', true);
export const TWITTER_LOGIN = generateConstants('userv2', 'TWITTER_LOGIN', true);

// ## BI FROST ##
export const CREATE_TEMPORARY_ACCESS_TOKEN = generateConstants('biFrost', 'CREATE_TEMPORARY_ACCESS_TOKEN', true);
export const REFRESH_TOKEN = generateConstants('biFrost', 'REFRESH_TOKEN', true);
export const TEMPORARY_TOKEN_PAYLOAD = 'TEMPORARY_TOKEN_PAYLOAD';
export const TEMPORARY_TOKEN_DATA = 'TEMPORARY_TOKEN_DATA';
export const TOKEN_DATA = 'TOKEN_DATA';
export const TOKEN_MIGRATION = generateConstants('biFrost', 'TOKEN_MIGRATION', true);
export const EXPIRE_TOKEN = generateConstants('biFrost', 'EXPIRE_TOKEN', true);
export const COMMON_LOGOUT = 'COMMON_LOGOUT';
export const COMMON_LOGIN = 'COMMON_LOGIN';

export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_USER_SPECIFIC_FOLDER_DATA = 'CLEAR_USER_SPECIFIC_FOLDER_DATA';
export const CLEAR_USER_SPECIFIC_USER_DATA = 'CLEAR_USER_SPECIFIC_USER_DATA';
export const CLEAR_USER_SPECIFIC_DASHBOARD_DATA = 'CLEAR_USER_SPECIFIC_DASHBOARD_DATA';
export const CLEAR_USER_SPECIFIC_GROUP_DATA = 'CLEAR_USER_SPECIFIC_GROUP_DATA';

// Onboarding
export const SEND_OTP = generateConstants('onboarding', 'SEND_OTP', true);
export const SEND_OTP_VIA_EMAIL = generateConstants('onboarding', 'SEND_OTP_VIA_EMAIL', true);
export const VERIFY_PHONE_OTP = generateConstants('onboarding', 'VERIFY_PHONE_OTP', true);
export const RESET_VERIFY_OTP = 'RESET_VERIFY_OTP';
export const UPDATE_ONBOARDING_COUNTRY = 'UPDATE_ONBOARDING_COUNTRY';

// Breakouts
export const GET_BREAKOUT_TEAMS = generateConstants('athena', 'GET_BREAKOUT_TEAMS', true);
export const START_POLLING_BREAKOUT_TEAMS = 'START_POLLING_BREAKOUT_TEAMS';
export const STOP_POLLING_BREAKOUT_TEAMS = 'STOP_POLLING_BREAKOUT_TEAMS';
export const UPDATE_BREAKOUT_TEAMS_OFFSET = 'UPDATE_BREAKOUT_TEAMS_OFFSET';
export const NEW_TEAM_FORMED = 'NEW_TEAM_FORMED';
export const GET_LEADERBOARD_API = generateConstants('athena', 'GET_LEADERBOARD_API', true);
export const GET_TOTAL_MEMBER_COUNT = generateConstants('athena', 'GET_TOTAL_MEMBER_COUNT', true);
export const GET_BREAKOUT_REPORT = generateConstants('athena', 'GET_BREAKOUT_REPORT', true);
export const LOAD_MORE_TEAMS = generateConstants('athena', 'LOAD_MORE_TEEAMS', true);
export const LOAD_BREAKOUT_DATA = 'LOAD_BREAKOUT_DATA';
export const UPDATE_BREAKOUT_COUNT = 'UPDATE_BREAKOUT_COUNT';
export const UPDATE_BREAKOUT_DURATION = 'UPDATE_BREAKOUT_DURATION';
export const BREAKOUT_ENDED = 'BREAKOUT_ENDED';
export const BREAKOUT_STARTED = 'BREAKOUT_STARTED';

// Classroom
export const GET_SESSION_MESSAGE = generateConstants('athena', 'GET_SESSION_MESSAGE', true);
export const GET_PUBNUB_SESSION_MESSAGE = generateConstants('pubnub', 'GET_PUBNUB_SESSION_MESSAGE', true);
export const GET_BREAKOUT_MESSAGE = generateConstants('athena', 'GET_BREAKOUT_MESSAGE', true);
export const POST_SESSION_MESSAGE = generateConstants('athena', 'POST_SESSION_MESSAGE', true);
export const POST_BREAKOUT_MESSAGE = generateConstants('athena', 'POST_BREAKOUT_MESSAGE', true);
export const GET_ALL_CANVAS = generateConstants('athena', 'GET_ALL_CANVAS', true);
export const USER_JOINED_CLASSROOM = 'USER_JOINED_CLASSROOM';
export const USER_LEFT_CLASSROOM = 'USER_LEFT_CLASSROOM';
export const SET_PDF_DATA = 'SET_PDF_DATA';
export const GET_STUDENT_CLASSROOM_META = generateConstants('athena', 'POST_BREAKOUT_MESSAGE', true);
export const ADD_STUDENT_CLASSROOM_META_QUEUE = 'ADD_STUDENT_CLASSROOM_META_QUEUE';

// Classroom session
export const SESSION_PREP_START = 'SESSION_PREP_START';
export const SESSION_PREP_END_DATA_EVENT = 'SESSION_PREP_END_DATA_EVENT';

// pubnub
export const TEACHER_GRANT_ACCESS = generateConstants('pubnub', 'TEACHER_GRANT_ACCESS', true);
export const RESET_PUBNUB_TOKENS = 'RESET_PUBNUB_TOKENS';

// Curriculum
// API
export const GET_PREVIOUS_GROUP_CURRICULUM = generateConstants('curriculum', 'GET_PREVIOUS_GROUP_CURRICULUM', true);
export const GET_NOON_SUGGESTED_CURRICULUM = generateConstants('curriculum', 'GET_NOON_SUGGESTED_CURRICULUM', true);
export const PUBLISH_CURRICULUM = generateConstants('curriculum', 'PUBLISH_CURRICULUM');
export const GET_CURRICULUM_CHAPTER_TOPICS = generateConstants('curriculum', 'GET_CURRICULUM_CHAPTER_TOPICS', true);
export const GET_CURRICULUM_SUGGESTED_CHAPTER = generateConstants('curriculum', 'GET_CURRICULUM_SUGGESTED_CHAPTER', true);
export const GET_GROUP_CURRICULUM = generateConstants('curriculum', 'GET_GROUP_CURRICULUM', true);
export const DRAFT_CURRICULUM = generateConstants('curriculum', 'DRAFT_CURRICULUM');
export const GET_CURRICULUM_SIMILAR_GROUPS = generateConstants('curriculum', 'GET_CURRICULUM_SIMILAR_GROUPS', true);
export const SET_CURRICULUM_TEMPLATE = 'SET_CURRICULUM_TEMPLATE';
export const ADD_CHAPTER = 'ADD_CHAPTERS';
export const UPDATE_CHAPTER = 'UPDATE_CHAPTERS';
export const DELETE_CHAPTER = 'DELETE_CHAPTERS';
export const REORDER_CHAPTER = 'REORDER_CHAPTER';
export const EMPTY_CHAPTER = 'EMPTY_CHAPTER';
export const REORDER_TOPIC = 'REORDER_TOPIC';
export const ADD_TOPIC = 'ADD_TOPIC';
export const UPDATE_TOPIC = 'UPDATE_TOPIC';
export const DELETE_TOPIC = 'DELETE_TOPIC';
export const SET_TOPIC = 'SET_TOPIC';
// Create Group
export const GET_TEACHER_TAGS_GROUP = generateConstants('group', 'GET_TEACHER_TAGS_GROUP', true);
export const CREATE_GROUP_V2 = generateConstants('group', 'CREATE_GROUP_V2', true);
export const UPDATE_GROUP_V2 = generateConstants('group', 'UPDATE_GROUP_V2', true);
export const GET_CHAPTERS_FROM_GROUP = generateConstants('group', 'GET_CHAPTERS_FROM_GROUP', true);
export const GET_TOPICS_FROM_GROUP = generateConstants('group', 'GET_TOPICS_FROM_GROUP', true);
export const GET_TEACHER_GROUPS = generateConstants('groups', 'GET_TEACHER_GROUPS', true);
export const GET_TEACHER_GROUP_SELECTED = generateConstants('groups', 'GET_TEACHER_GROUP_SELECTED');
export const GET_TEACHER_GROUP_CHAPTERS = generateConstants('groups', 'GET_TEACHER_GROUP_CHAPTERS', true);
export const GET_TEACHER_GROUP_TOPICS = generateConstants('groups', 'GET_TEACHER_GROUP_TOPICS', true);
export const POST_TEACHER_GROUP_TOPICS_MARK_COMPLETE = generateConstants('groups', 'POST_TEACHER_GROUP_TOPICS_MARK_COMPLETE', true);
export const GET_TEACHER_GROUP_COURSE_PREVIEW = generateConstants('groups', 'GET_TEACHER_GROUP_COURSE_PREVIEW', true);
export const GET_TEACHER_GROUP_MEMBERS_INFO = generateConstants('groups', 'GET_TEACHER_GROUP_MEMBERS_INFO', true);

// feed question
export const GET_ALL_QUESTIONS_POSTS = generateConstants('feedQuestion', 'GET_ALL_QUESTIONS_POSTS');
export const GET_QUESTIONS_POST_BY_ID = generateConstants('feedQuestion', 'GET_QUESTIONS_POST_BY_ID');
export const CREATE_QUESTIONS_POST = generateConstants('feedQuestion', 'CREATE_QUESTIONS_POST');
export const CREATE_ANSWER = generateConstants('feedQuestion', 'CREATE_ANSWER');
export const CREATE_REPLY = generateConstants('feedQuestion', 'CREATE_REPLY');
export const GET_QUESTION_ANSWERS = generateConstants('feedQuestion', 'GET_QUESTION_ANSWERS');
export const GET_ANSWERS_REPLIES = generateConstants('feedQuestion', 'GET_ANSWERS_REPLIES');
export const DELETE_ANSWER = generateConstants('feedQuestion', 'DELETE_ANSWER');
export const DELETE_QUESTION = generateConstants('feedQuestion', 'DELETE_QUESTION');
export const DELETE_REPLY = generateConstants('feedQuestion', 'DELETE_REPLY');
export const UPVOTE_QUESTION = generateConstants('feedQuestion', 'UPVOTE_QUESTION');
export const THANK_ANSWER = generateConstants('feedQuestion', 'THANK_ANSWER');
export const UNTHANK_ANSWER = generateConstants('feedQuestion', 'UNTHANK_ANSWER');
export const LIKE_REPLY = generateConstants('feedQuestion', 'LIKE_REPLY');
export const UNLIKE_REPLY = generateConstants('feedQuestion', 'UNLIKE_REPLY');
export const GET_COUNT_FOR_QUESTION_FILTER = generateConstants('feedQuestion', 'GET_COUNT_FOR_QUESTION_FILTER');
export const QUEUE_FOR_QUESTION = 'QUEUE_FOR_QUESTION';
export const QUEUE_FOR_ANSWER = 'QUEUE_FOR_ANSWER';
export const QUEUE_FOR_REPLY = 'QUEUE_FOR_REPLY';
export const ANSWER_COUNT_MAP = 'ANSWER_COUNT_MAP';
export const REPLY_COUNT_MAP = 'REPLY_COUNT_MAP';
export const THANK_COUNT_MAP = 'THANK_COUNT_MAP';

// Curriculum
export const GET_COUNTRIES = generateConstants('curriculum', 'GET_COUNTRIES', true);
export const GET_GRADES = generateConstants('curriculum', 'GET_GRADES', true);
export const GET_BOARDS = generateConstants('curriculum', 'GET_BOARDS', true);
export const GET_DEGREES = generateConstants('curriculum', 'GET_DEGREES', true);
export const GET_SUBJECTS = generateConstants('curriculum', 'GET_SUBJECTS', true);
export const GET_CURRICULUM = generateConstants('curriculum', 'GET_CURRICULUM', true);
export const GET_CHAPTERS = generateConstants('curriculum', 'GET_CHAPTERS', true);
export const GET_TOPICS = generateConstants('curriculum', 'GET_TOPICS', true);
export const GET_TESTS = generateConstants('curriculum', 'GET_TESTS', true);
export const GET_ALL_TESTS = generateConstants('curriculum', 'GET_ALL_TESTS', true);
export const GET_SKILLS = generateConstants('curriculum', 'GET_SKILLS', true);
export const GET_ALL_SKILLS = generateConstants('curriculum', 'GET_ALL_SKILLS', true);
export const GET_MAJORS = generateConstants('curriculum', 'GET_MAJORS', true);
export const GET_COURSES = generateConstants('curriculum', 'GET_COURSES', true);
export const GET_SECTIONS = generateConstants('curriculum', 'GET_SECTIONS', true);

// General planner
export const GENERAL_PLANNER_GET_ACTIVITIES_LIST = generateConstants('planner', 'GENERAL_PLANNER_GET_ACTIVITIES_LIST');
export const GENERAL_PLANNER_GET_ACTIVITIES_GRID = generateConstants('planner', 'GENERAL_PLANNER_GET_ACTIVITIES_GRID');
export const GENERAL_PLANNER_GET_ACTIVITIES_COUNTS = generateConstants('planner', 'GENERAL_PLANNER_GET_ACTIVITIES_COUNTS');
export const GENERAL_PLANNER_GET_ACTIVITIES_DAYS = generateConstants('planner', 'GENERAL_PLANNER_GET_ACTIVITIES_DAYS');

// Scheduler
export const SCHEDULER_CREATE_SCHEDULE = generateConstants('scheduler', 'SCHEDULER_CREATE_SCHEDULE');
export const SCHEDULER_UPDATE_SCHEDULE = generateConstants('scheduler', 'SCHEDULER_UPDATE_SCHEDULE');
export const SCHEDULER_GET_SCHEDULES = generateConstants('scheduler', 'SCHEDULER_GET_SCHEDULES');

// Homework
export const EDIT_QUESTIONS_LIST = {
  ADD: 'ADD_QUESTION',
  DELETE: 'DELETE_QUESTION',
  CHANGE_ORDER: 'CHANGE_ORDER',
};
export const CLEAR_HOMEWORK_SPARTA_DETAILS = 'CLEAR_HOMEWORK_SPARTA_DETAILS';
export const CREATE_HOMEWORK_SPARTA = generateConstants('homework', 'CREATE_HOMEWORK_SPARTA', true);
export const GET_HOMEWORK_DETAILS_SPARTA = generateConstants('homework', 'GET_HOMEWORK_DETAILS', true);
export const SAVE_HOMEWORK_QUESTIONS_SPARTA = generateConstants('homework', 'SAVE_HOMEWORK_QUESTIONS', true);
export const UPDATE_HOMEWORK_SPARTA = generateConstants('homework', 'UPDATE_HOMEWORK_SPARTA', true);
export const GET_QUESTIONS_BY_IDS_SPARTA = generateConstants('homework', 'GET_QUESTIONS_BY_IDS', true);
export const DELETE_HOMEWORK_SPARTA = generateConstants('homework', 'DELETE_HOMEWORK_SPARTA', true);
export const CLEAR_UPDATE_HOMEWORK = 'CLEAR_UPDATE_HOMEWORK';
export const GET_PRE_PUBLISHED_HOMEWORKS = generateConstants('homework', 'GET_PRE_PUBLISHED_HOMEWORKS', true);
export const GET_POST_PUBLISHED_HOMEWORKS = generateConstants('homework', 'GET_POST_PUBLISHED_HOMEWORKS', true);
export const CLEAR_QUESTIONS = 'CLEAR_QUESTIONS';
export const SET_QUESTION_CHAPTER = 'SET_QUESTION_CHAPTER';
export const SET_QUESTION_TOPIC = 'SET_QUESTION_TOPIC';
export const CLEAR_TOPIC_QUESTIONS = 'CLEAR_TOPIC_QUESTIONS';
export const META_HOMEWORK_UPDATED = 'META_HOMEWORK_UPDATED';
export const CLEAR_CHAPTERS_AND_TOPICS = 'CLEAR_CHAPTERS_AND_TOPICS';

// Report Page
export const GET_SUBMITTED_USERS = generateConstants('homework', 'GET_SUBMITTED_USERS', true);
export const GET_NOT_SUBMITTED_USERS = generateConstants('homework', 'GET_NOT_SUBMITTED_USERS', true);
export const SORT_STUDENTS = 'SORT_STUDENTS';
export const GET_USER_RESPONSE = generateConstants('homework', 'GET_USER_RESPONSE', true);
export const CLEAR_REPORT_PAGE_DATA = 'CLEAR_REPORT_PAGE_DATA';
export const UPDATE_ORGANISATION = 'UPDATE_ORGANISATION';
export const STUDENT_RESPONSE_VIEWED = 'STUDENT_RESPONSE_VIEWED';

// Playback
export const SEND_PLAYBACK_RATING = generateConstants('athena', 'SEND_PLAYBACK_RATING', true);
export const GET_ORIGINAL_PLAYBACK_DETAILS = generateConstants('athena', 'GET_ORIGINAL_PLAYBACK_DETAILS');
export const GET_EDITED_PLAYBACK_DETAILS = generateConstants('athena', 'GET_EDITED_PLAYBACK_DETAILS');
export const GET_TIMESERIES_DATA = generateConstants('athena', 'GET_TIMESERIES_DATA');
export const GET_BUFFERED_TIMESERIES = generateConstants('athena', 'GET_BUFFERED_TIMESERIES');
export const GET_SNAPSHOT_DATA = generateConstants('athena', 'GET_SNAPSHOT_DATA');
export const UPDATE_PLAYBACK_LAST_SEEK = 'UPDATE_PLAYBACK_LAST_SEEK';
export const GET_PLAYBACK_CHAT_MESSAGES = generateConstants('athena', 'GET_PLAYBACK_CHAT_MESSAGES');
export const REQUEST_PLAYBACK_PUBNUB_ACCESS = generateConstants('athena', 'REQUEST_PLAYBACK_PUBNUB_ACCESS');
export const DELETE_PLAYBACK_SLIDE = generateConstants('athena', 'DELETE_PLAYBACK_SLIDE');
export const TRIM_PLAYBACK_SLIDE = generateConstants('athena', 'TRIM_PLAYBACK_SLIDE');

// playback player
export const PLAYBACK_START = 'PLAYBACK_START';
export const PLAYBACK_STOP = 'PLAYBACK_STOP';
export const PLAYBACK_PAUSE = 'PLAYBACK_PAUSE';
export const PLAYBACK_END = 'PLAYBACK_END';
export const PLAYBACK_SEEK_TO = 'PLAYBACK_SEEK_TO';
export const PLAYBACK_ON_PROGRESS = 'PLAYBACK_ON_PROGRESS';
export const PLAYBACK_ON_DURATION_LOAD = 'PLAYBACK_ON_DURATION_LOAD';
export const PLAYBACK_UPDATE_STATE = 'PLAYBACK_UPDATE_STATE';
export const CLEAR_PLAYBACK_DATA = 'CLEAR_PLAYBACK_DATA';
export const UPDATE_PLAYBACK_SECTIONS = 'UPDATE_PLAYBACK_SECTIONS';
export const PLAYBACK_ENTER = 'PLAYBACK_ENTER';
export const PLAYBACK_EXIT = 'PLAYBACK_EXIT';
export const RESET_DELETED_SLIDE = 'RESET_DELETED_SLIDE';
export const RESET_EDITED_SLIDE = 'RESET_EDITED_SLIDE';
export const PLAYBACK_LOAD_NEXT_DATA = 'PLAYBACK_LOAD_NEXT_DATA';
export const UPDATE_PLAYER_DATA = 'UPDATE_PLAYER_DATA';
export const PLAYBACK_INTERACTIVE_QUESTION_START = 'PLAYBACK_INTERACTIVE_QUESTION_START';
export const PLAYBACK_INTERACTIVE_QUESTION_END = 'PLAYBACK_INTERACTIVE_QUESTION_END';
export const PUBLISHED_PLAYBACK_EVENT = 'PUBLISHED_PLAYBACK_EVENT';
export const PUBLISH_LATER_EVENT = 'PUBLISH_LATER_EVENT';

// image to question
export const OPEN_ADD_IMAGE_FROM_QUESTION_DIALOG = 'OPEN_ADD_IMAGE_FROM_QUESTION_DIALOG';
export const CLOSE_ADD_IMAGE_FROM_QUESTION_DIALOG = 'CLOSE_ADD_IMAGE_FROM_QUESTION_DIALOG';
export const ENABLE_ADD_IMAGE_FROM_QUESTION_ONBOARDING = 'ENABLE_ADD_IMAGE_FROM_QUESTION_ONBOARDING';
export const ADD_IMAGE_FROM_QUESTION_ONBOARDING_COMPLETE = 'ADD_IMAGE_FROM_QUESTION_ONBOARDING_COMPLETE';
export const ADD_IMAGE_FROM_QUESTION_IMAGE_LOADED = 'ADD_IMAGE_FROM_QUESTION_IMAGE_LOADED';
export const SHOW_ADD_IMAGE_FROM_QUESTION_ONBOARDING_SUCCESS = 'SHOW_ADD_IMAGE_FROM_QUESTION_ONBOARDING_SUCCESS';
export const HIDE_ADD_IMAGE_FROM_QUESTION_ONBOARDING_SUCCESS = 'HIDE_ADD_IMAGE_FROM_QUESTION_ONBOARDING_SUCCESS';
export const SET_CHAPTER_AND_TOPIC = 'SET_CHAPTER_AND_TOPIC';

// promotion
export const GET_REFERRAL_LINK = generateConstants('promotion', 'GET_REFERRAL_LINK');
export const GET_CAMPAIGN_BANNER = generateConstants('promotion', 'GET_CAMPAIGN_BANNER');

// Payment
export const LIST_PENDING_CASH_PAYMENT = generateConstants('packages', 'LIST_PENDING_CASH_PAYMENT');
export const LIST_APPROVED_PAYMENT = generateConstants('packages', 'LIST_APPROVED_PAYMENT');
export const UPDATE_CASH_PAYMENT_STATE = generateConstants('packages', 'UPDATE_CASH_PAYMENT_STATE');

// classroom v2
export const INIT_CLASSROOM = generateConstants('classroom', 'INIT_CLASSROOM');
export const START_CLASSROOM = generateConstants('classroom', 'START_CLASSROOM');
export const CLASSROOM_LEADERBOARD = generateConstants('classroom', 'CLASSROOM_LEADERBOARD');
export const CLASSROOM_TEAMS = generateConstants('classroom', 'CLASSROOM_TEAMS');
export const RAISE_HAND_ACTION = generateConstants('classroom', 'RAISE_HAND_ACTION');
export const CLEAR_CLASSROOM = 'CLEAR_CLASSROOM';
export const SELECTED_TAB = 'SELECTED_TAB';
export const DISABLE_TABS = 'DISABLE_TABS';
export const ENABLE_TABS = 'ENABLE_TABS';
export const SIDEBAR_TAB_COUNT = 'SIDEBAR_TAB_COUNT';
export const CLASSROOM_STUDENT_JOIN = 'CLASSROOM_STUDENT_JOIN';
export const CLASSROOM_STUDENT_LEFT = 'CLASSROOM_STUDENT_LEFT';
export const RESET_LAST_SESSION_DATA = 'RESET_LAST_SESSION_DATA';
export const BREAKOUT_TEAM_FORMED = 'BREAKOUT_TEAM_FORMED';
export const INITIALIZE_RAISE_HAND = 'INITIALIZE_RAISE_HAND';
export const RAISE_HAND_REQUEST = 'RAISE_HAND_REQUEST';
export const CLASSROOM_NEED_TO_DISCONNECT = 'CLASSROOM_NEED_TO_DISCONNECT';
export const START_CLASSROOM_HEARTBEAT = 'START_CLASSROOM_HEARTBEAT';
export const STOP_CLASSROOM_HEARTBEAT = 'STOP_CLASSROOM_HEARTBEAT';
export const CLASSROOM_STUDENT_FEEDBACK = 'CLASSROOM_STUDENT_FEEDBACK';
export const EXIT_CLASSROOM = 'EXIT_CLASSROOM';
export const START_BREAKOUT = 'START_BREAKOUT';
export const END_BREAKOUT = 'END_BREAKOUT';
export const SET_LOCAL_DEVICE_DETAILS = 'SET_LOCAL_DEVICE_DETAILS';
export const SWITCH_VIDEO_DEVICE = 'SWITCH_VIDEO_DEVICE';
export const SWITCH_AUDIO_DEVICE = 'SWITCH_AUDIO_DEVICE';

// chat
export const CHAT_HISTORY = generateConstants('chat', 'CHAT_HISTORY');
export const ADD_CHAT = 'ADD_CHAT';
export const UPDATE_CHAT_STATUS = 'UPDATE_CHAT_STATUS';
export const BLOCK_CHAT = 'BLOCK_CHAT';

// whiteboard
export const GET_SLIDES = generateConstants('whiteboard', 'GET_SLIDES');
export const ADD_SLIDE = generateConstants('whiteboard', 'ADD_SLIDE');
export const TAKE_SCREEN_SHOT = 'TAKE_SCREEN_SHOT';
export const UPDATE_SCREEN_SHOT = 'UPDATE_SCREEN_SHOT';
export const RETRY_UPLOAD_SC = 'RETRY_UPLOAD_SC';
export const RETRY_UPLOAD_SC_SUCCESS = 'RETRY_UPLOAD_SC_SUCCESS';
export const RETRY_UPLOAD_SC_FAIL = 'RETRY_UPLOAD_SC_FAIL';
export const DELETE_SLIDE = generateConstants('whiteboard', 'DELETE_SLIDE');
export const MOVE_SLIDE = generateConstants('whiteboard', 'MOVE_SLIDE');
export const SELECT_SLIDE = 'SELECT_SLIDE';
export const SET_QUESTION_TIMER = 'SET_QUESTION_TIMER';
export const SET_CANVAS_DIMENTION = 'SET_CANVAS_DIMENTION';
export const INTERACTIVE_QUESTION_START = 'INTERACTIVE_QUESTION_START';
export const INTERACTIVE_QUESTION_END = 'INTERACTIVE_QUESTION_END';
export const UPDATE_VOTE_CHOICE = 'UPDATE_VOTE_CHOICE';
export const SHOW_COMPETITON_RESULT = 'SHOW_COMPETITON_RESULT';
export const SNED_INTERACTIVE_QUESTION = 'SNED_INTERACTIVE_QUESTION';
export const UPDATE_SLIDE_CONFIG = 'UPDATE_SLIDE_CONFIG';
export const SHOW_CANVAS = 'SHOW_CANVAS';
export const MARK_SLIDE_AS_LOADING = 'MARK_SLIDE_AS_LOADING';
export const SLIDE_TO_BE_ADDED = 'SLIDE_TO_BE_ADDED';
export const SLIDE_TO_BE_DELETED = 'SLIDE_TO_BE_DELETED';
export const IMAGE_ADDED_ON_WHITEBOARD = generateConstants('whiteboard', 'IMAGE_ADDED_ON_WHITEBOARD');
export const GENERATE_PDF_SLIDE = generateConstants('whiteboard', 'GENERATE_PDF_SLIDE');
export const UPDATE_PDF_SLIDE = 'UPDATE_PDF_SLIDE';
export const HIDE_PDF_SLIDE_MODAL = 'HIDE_PDF_SLIDE_MODAL';

// Content
export const CONTENT_FLASHCARD = generateConstants('content', 'FLASHCARD');
export const CONTENT_BOOK = generateConstants('content', 'BOOK');
export const CONTENT_QUESTION = generateConstants('content', 'QUESTION');
export const CONTENT_PDF = generateConstants('content', 'PDF');
export const CONTENT_SET_CHAPTER_TOPIC_ID = 'CONTENT_SET_CHAPTER_TOPIC_ID';
export const CONTENT_SET_TAG_ID = 'CONTENT_SET_TAG_ID';
export const SET_SELECTED_QUESTION_CONTENT = 'SET_SELECTED_QUESTION_CONTENT';
export const SET_SELECTED_IMAGE_CONTENT = 'SET_SELECTED_IMAGE_CONTENT';

// webrtc
export const WEBRTC_TOGGLE_AUDIO = generateConstants('webrtc', 'WEBRTC_TOGGLE_AUDIO');
export const START_SESSION_RECORDING = generateConstants('webrtc', 'START_SESSION_RECORDING');
export const SET_WEBRTC_STATUS = 'SET_WEBRTC_STATUS';
export const UPDATE_WEBRTC_STREAMS = 'UPDATE_WEBRTC_STREAMS';
export const SET_DISABLE_WEBRTC = 'SET_DISABLE_WEBRTC';
export const UPDATE_MIC_VOLUME_INDICATOR = 'UPDATE_MIC_VOLUME_INDICATOR';
export const WEBRTC_TOGGLE_VIDEO = generateConstants('webrtc', 'WEBRTC_TOGGLE_VIDEO');

// team qna breakout
export const QNA_GET_MESSAGES = generateConstants('athena', 'QNA_GET_MESSAGES');
export const QNA_START_BREAKOUT = 'QNA_START_BREAKOUT';
export const QNA_MESSAGE = {
  BEFORE: 'QNA_MESSAGE_BEFORE',
  AFTER: 'QNA_MESSAGE_AFTER',
};
export const LOAD_SLIDE_URLS = 'LOAD_SLIDE_URLS';
export const QNA_BREAKOUT_START_EVENT = {
  BEFORE: 'QNA_BREAKOUT_START_EVENT_BEFORE',
  AFTER: 'QNA_BREAKOUT_START_EVENT_AFTER',
};
export const QNA_BREAKOUT_START_EVENT_SERVER = 'QNA_BREAKOUT_START_EVENT_SERVER';
export const QNA_BREAKOUT_END_EVENT = {
  BEFORE: 'QNA_BREAKOUT_END_EVENT_BEFORE',
  AFTER: 'QNA_BREAKOUT_END_EVENT_AFTER',
};
export const QNA_SET_DURATION = 'QNA_SET_DURATION';
export const QNA_LOAD_DATA = {
  BEFORE: 'QNA_LOAD_DATA_BEFORE',
  AFTER: 'QNA_LOAD_DATA_AFTER',
};
export const QNA_SCREEN_SHOT_UPDATED = 'QNA_SCREEN_SHOT_UPDATED';
// Classroom config
export const UPDATE_CLASSROOM_CONFIG = 'UPDATE_CLASSROOM_CONFIG';

// Experiment
export const GET_FEATURE_FLAG = generateConstants('experiment', 'GET_FEATURE_FLAG');

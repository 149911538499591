import React from 'react';
import { Card, Badge } from '@noon/atom';
import { translationText } from '../../helpers';

export default function GetReadyScreen(prop) {
  return (
    <Card className="preparation-timer-wrapper">
      <div
        className="pt-spinner"
        style={{ width: `${(100 * (prop.questionData.get_ready - prop.timer)) / prop.questionData.get_ready}%` }}
      />
      <div className="pt-quest">{translationText(prop.noonText, 'question.question')}</div>
      <div className="pt-number">{prop.questionData.q_no}</div>
      <div>{translationText(prop.noonText, 'label.timeLimit')}</div>
      <div className="pt-time" dir="ltr">
        {prop.questionData.time_left || prop.questionData.time}s
      </div>
      {prop.teamFormationComplete && (
        <div className="preparation-timer-wrapper__breakout">
          <Badge color="red" size="sm">
            {translationText(prop.noonText, 'breakoutEndDesc.new')}
          </Badge>
          {translationText(prop.noonText, 'teacherBreakout.breakoutEndDesc')}
        </div>
      )}
    </Card>
  );
}

import React from 'react';
import { Row, Avatar } from '@noon/atom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { GET_USER_RESPONSE, STUDENT_RESPONSE_VIEWED } from '../../redux/constants';
import { checkIsMobile } from '../../helpers/prepareAssignment';

const ReportPageBarStudentCard = (props) => {
  const { student = {}, submitted, setIsBarOpen, setIsUserAnswersOpen } = props;
  const { hw_details = {} } = useSelector((state) => state.toJS().homeworkCreation);
  const dispatch = useDispatch();

  const getUserResponse = () => {
    if (submitted) {
      if (checkIsMobile()) {
        setIsUserAnswersOpen();
      } else {
        setIsBarOpen();
      }
      dispatch({
        type: GET_USER_RESPONSE.REQUEST,
        hw_id: hw_details.id,
        user_id: student.id,
      });
      dispatch({
        type: STUDENT_RESPONSE_VIEWED,
      });
    }

    return true;
  };

  return (
    <Row onClick={getUserResponse} align="center" justify="space-between" className="report--bar--students--student">
      <Row align="center" className={`student-info ${submitted ? '' : 'not-submitted'}`}>
        {submitted && <span className="position">{student.position}</span>}
        <Row align="center" className="student-personal">
          <Avatar url={student.profile_pic} gender={student.gender} rounded noShadow size="37px" />
          <span>{student.name}</span>
        </Row>
      </Row>
      {submitted && <div className="score">{student.score}</div>}
    </Row>
  );
};

ReportPageBarStudentCard.propTypes = {
  student: PropTypes.shape().isRequired,
  submitted: PropTypes.bool.isRequired,
  setIsBarOpen: PropTypes.func.isRequired,
  setIsUserAnswersOpen: PropTypes.func.isRequired,
};

export default ReportPageBarStudentCard;

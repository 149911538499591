import {
  CREATE_TEMPORARY_ACCESS_TOKEN,
  TEMPORARY_TOKEN_PAYLOAD,
  REFRESH_TOKEN,
  TOKEN_MIGRATION,
  TOKEN_DATA,
  TEMPORARY_TOKEN_DATA,
} from '../constants';

const initialState = {
  createTemporaryAccessToken: {
    response: {},
    isLoading: false,
  },
  refreshToken: {
    response: {},
    isLoading: false,
  },
  tokenMigration: {
    response: {},
    isLoading: false,
  },
  temporaryTokenPayload: null,
  tokenData: null,
  temporaryTokenData: null,
};

export default function biFrost(state = initialState, action) {
  switch (action.type) {
    case CREATE_TEMPORARY_ACCESS_TOKEN.REQUEST:
      return {
        ...state,
        createTemporaryAccessToken: {
          ...state.createTemporaryAccessToken,
          isLoading: true,
        },
      };
    case CREATE_TEMPORARY_ACCESS_TOKEN.LOADING:
      return {
        ...state,
        createTemporaryAccessToken: {
          ...state.createTemporaryAccessToken,
          isLoading: action.payload.isLoading,
        },
      };
    case CREATE_TEMPORARY_ACCESS_TOKEN.SUCCESS:
      return {
        ...state,
        createTemporaryAccessToken: {
          ...state.createTemporaryAccessToken,
          response: action.payload,
          isLoading: false,
        },
      };
    case CREATE_TEMPORARY_ACCESS_TOKEN.FAILURE:
      return {
        ...state,
        createTemporaryAccessToken: {
          ...state.createTemporaryAccessToken,
          isLoading: false,
        },
        error: {
          createTemporaryAccessToken: action.payload,
        },
      };
    case REFRESH_TOKEN.LOADING:
      return {
        ...state,
        refreshToken: {
          ...state.refreshToken,
          isLoading: action.payload.isLoading,
        },
      };
    case REFRESH_TOKEN.SUCCESS:
      return {
        ...state,
        refreshToken: {
          ...state.refreshToken,
          response: action.payload,
        },
      };
    case REFRESH_TOKEN.FAILURE:
      return {
        ...state,
        error: {
          refreshToken: action.payload,
        },
      };
    case TOKEN_MIGRATION.LOADING:
      return {
        ...state,
        tokenMigration: {
          ...state.tokenMigration,
          isLoading: action.payload.isLoading,
        },
      };
    case TOKEN_MIGRATION.SUCCESS:
      return {
        ...state,
        tokenMigration: {
          ...state.tokenMigration,
          response: action.payload,
        },
      };
    case TOKEN_MIGRATION.FAILURE:
      return {
        ...state,
        error: {
          tokenMigration: action.payload,
        },
      };
    case TEMPORARY_TOKEN_PAYLOAD:
      return {
        ...state,
        temporaryTokenPayload: action.payload,
      };
    case TEMPORARY_TOKEN_DATA:
      return {
        ...state,
        temporaryTokenData: action.payload,
      };
    case TOKEN_DATA:
      return {
        ...state,
        tokenData: action.payload,
      };
    default:
      if (action.type.indexOf('_REQUEST') !== -1) {
        return {
          ...state,
          error: {},
        };
      }
      return state;
  }
}

import React from 'react';
import Image from 'react-lazy-image';
import { isMobileOnly } from 'react-device-detect';
import { Row, Column } from '@noon/atom';

export default function SessionsSection() {
  return (
    <div className="section-outer-container sessions-outer">
      <div className="section landing-sessions">
        <Row className="text landing-sessions__heading">
          <h1 className="title-heading">Simplified Virtual Classroom</h1>
          <h1 className="content-heading">Simplified Virtual Classroom</h1>
          <p className="content-subheading">Your students can access your classrooms anywhere at anytime.</p>
        </Row>
        <Row className="landing-sessions__content">
          <Column className="image-wrapper">
            <Image offset={100} source={`/assets/images/new-landing/tablet-v1${isMobileOnly ? '' : '@2x'}.png`} alt="session" />
          </Column>
        </Row>
      </div>
    </div>
  );
}

/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import moment from 'moment';
import isEmpty from 'lodash-es/isEmpty';
import { IconChat, IconLoader, IconMediaFullscreen, IconMediaFullscreenExit, IconMediaPause, IconMediaPlay, IconMediaReplay } from '@noon/quark';
import { Badge } from '@noon/atom';
import { isMobileSafari, isSafari } from 'react-device-detect';
import { throttle } from 'lodash-es';
import VideoCard from '../../components/Classroom/Video/VideoCard';

const audioPlayerref = React.createRef();
const progressBarRef = React.createRef();

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leftposition: -1,
      dragWidth: 0,
      progressBarValue: 0,
      duration: 0,
      playPauseState: 'play',
      replay: false,
      isLoading: true,
      startPositionSet: false,
      dragging: false,
    };
    this.paused_duration = new Date().getTime();
    this.playPauseActionStarted = false;
    this.throttledDragProgressBar = throttle(this.dragProgressBar, 50);
    this.throttledProgressbarClickHandler = throttle(this.progressbarClickHandler, 50);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.audioStartTime
      && this.props.audioStartTime !== prevProps.audioStartTime
      && !this.state.startPositionSet
    ) {
      this.setStartPosition(this.props.audioStartTime);
    }
  }

  setStartPosition = (audioStartTime) => {
    const { sessionData, seekData } = this.props;
    if (!this.state.startPositionSet && !isEmpty(sessionData) && sessionData.last_seek) {
      audioPlayerref.current.currentTime = Math.max((sessionData.last_seek - audioStartTime) / 1000, 0);
      seekData(audioPlayerref.current.currentTime, () => {});
      this.setState({ startPositionSet: true });
    }
  };

  currentPlayingTime = (e) => {
    // For mobile browsers, ensure that the progress element's max attribute is set
    const { dragging, duration } = this.state;
    if (!duration) {
      this.setState({ duration: e.target.duration || 0 });
    }
    if (!dragging) {
      this.setState({
        progressBarValue: e.target.currentTime,
        dragWidth: (e.target.currentTime / this.state.duration) * 100,
        leftposition: (e.target.currentTime / this.state.duration) * 100 - 1,
      });
    }
  };

  handlePlayCallback = (res) => {
    if (res) {
      const { duration } = this.state;
      this.setState({ playPauseState: 'pause' });
      audioPlayerref.current.play();
      this.props.sendMixpanelEvent('teacher_paused_replay', {
        paused_duration: new Date().getTime() - this.paused_duration,
      });
      this.props.sendMixpanelEvent('teacher_played_replay', { duration });
      this.paused_duration = 0;
    }
    this.playPauseActionStarted = false;
  };

  handleProgressCallback = (res) => {
    if (res) {
      this.setState({ playPauseState: 'pause' });
      audioPlayerref.current.play();
      this.props.sendMixpanelEvent('teacher_seeked_replay', {
        time_seeked: this.state.progressBarValue,
        perc_total_duration_seeked: (this.state.progressBarValue - this.prev_CurrentTime) / this.state.duration,
      });
    }
    this.playPauseActionStarted = false;
  };

  handlePlayPauseButtonClick = () => {
    console.log('isMobileSafari', isMobileSafari);
    if (this.playPauseActionStarted || this.state.isLoading) return;
    this.playPauseActionStarted = true;
    const { peakActiveUsersCount, currentActiveUsersCount } = this.props;
    const { duration } = this.state;
    if (audioPlayerref.current.paused || audioPlayerref.current.ended) {
      if (isMobileSafari || (localStorage.multiTenantLogin && localStorage.multiTenantLogin.toLowerCase() === 'ios')) {
        this.props.seekData(audioPlayerref.current.currentTime, () => {});
        this.setState({ playPauseState: 'pause' });
        audioPlayerref.current.play();
        this.playPauseActionStarted = false;
        this.props.sendMixpanelEvent('teacher_paused_replay', {
          paused_duration: new Date().getTime() - this.paused_duration,
        });
        this.props.sendMixpanelEvent('teacher_played_replay', {
          duration,
          currentActiveUsersCount,
          peakActiveUsersCount,
        });
        this.paused_duration = 0;
      } else {
        this.props.seekData(audioPlayerref.current.currentTime, this.handlePlayCallback);
      }
    } else {
      audioPlayerref.current.pause();
      this.props.handlePause();
      this.paused_duration = new Date().getTime();
      this.setState({ playPauseState: 'play' }, () => {
        this.playPauseActionStarted = false;
        // this.props.sendMixpanelEvent('teacher_played_replay', { duration });
      });
    }
  };

  handleDragStart = (e) => {
    this.setState({
      dragging: true,
    });
  };

  dragFunction = (e) => {
    e.persist();
    this.throttledDragProgressBar(e);
  };

  dragProgressBar(e) {
    if (e.pageX || e.changedTouches) {
      const pagex = e.changedTouches && e.changedTouches.length > 0 ? e.changedTouches[0].pageX : e.pageX;
      const pos = (pagex - (progressBarRef.current.offsetLeft + progressBarRef.current.offsetParent.offsetLeft))
        / progressBarRef.current.offsetWidth;

      if (pos < 0) {
        this.setState({
          dragWidth: this.state.dragWidth,
          leftposition: this.state.leftposition,
        });
      } else if (pos * 100 > 100) {
        this.setState({
          dragWidth: 100,
          leftposition: 100 - 1,
        });
      } else {
        this.setState({
          dragWidth: pos * 100,
          leftposition: pos * 100 - 1,
        });
      }
    }
  }

  progressbarClickHandlerFunction = (e) => {
    e.persist();
    this.progressbarClickHandler(e);
  };

  progressbarClickHandler = (e) => {
    this.setState({
      dragging: false,
    });
    if (this.state.isLoading) return;
    if (audioPlayerref.current.ended) {
      this.setState({ replay: false });
    }
    const pagex = e.changedTouches && e.changedTouches.length > 0 ? e.changedTouches[0].pageX : e.pageX;
    const pos = (pagex - (progressBarRef.current.offsetLeft + progressBarRef.current.offsetParent.offsetLeft))
      / progressBarRef.current.offsetWidth;
    const progressBarPosition = pos * audioPlayerref.current.duration;
    let progressBarValue;
    if (progressBarPosition < 0) {
      progressBarValue = 0;
    } else if (progressBarPosition > audioPlayerref.current.duration) {
      progressBarValue = audioPlayerref.current.duration;
    } else {
      progressBarValue = progressBarPosition;
    }
    this.setState({ progressBarValue }, () => {
      this.prev_CurrentTime = audioPlayerref.current.currentTime;
      audioPlayerref.current.currentTime = progressBarValue || 0;
      audioPlayerref.current.pause();
      if (isMobileSafari || (localStorage.multiTenantLogin && localStorage.multiTenantLogin.toLowerCase() === 'ios')) {
        this.props.seekData(progressBarValue, () => {}, this.state.playPauseState);
        if (this.state.playPauseState === 'pause') {
          audioPlayerref.current.play();
        }
      } else if (this.state.dragWidth !== 100) {
        this.props.seekData(progressBarValue, this.handleProgressCallback, this.state.playPauseState);
      }

      if (this.state.dragWidth === 100) {
        this.props.seekData(progressBarValue, () => {}, this.state.playPauseState);
      }
    });
    if ((progressBarValue / this.state.duration) * 100 > 100) {
      this.setState({
        dragWidth: 100,
        leftposition: 100 - 1,
      });
    } else {
      this.setState({
        dragWidth: (progressBarValue / this.state.duration) * 100,
        leftposition: (progressBarValue / this.state.duration) * 100 - 1,
      });
    }
  };

  handleEnded = () => {
    this.setState({
      replay: true,
    });
  };

  handleReplay = () => {
    if (audioPlayerref.current.paused || audioPlayerref.current.ended) {
      if (isMobileSafari || (localStorage.multiTenantLogin && localStorage.multiTenantLogin.toLowerCase() === 'ios')) {
        this.props.seekData(0, () => {});
        this.setState({ dragWidth: 0, leftposition: -1, playPauseState: 'pause', replay: false });
        audioPlayerref.current.play();
      } else {
        this.props.seekData(0, (res) => {
          if (res) {
            this.setState({ dragWidth: 0, leftposition: -1, playPauseState: 'pause', replay: false });
            audioPlayerref.current.play();
          }
        });
      }
    }
  };

  handleLoadedMetadata = (e) => {
    this.setState({ duration: e.target.duration });
    this.props.setSessionDuration(e.target.duration);
    if (isSafari || isMobileSafari || (localStorage.multiTenantLogin && localStorage.multiTenantLogin.toLowerCase() === 'ios')) {
      this.handleCanPlay();
    }
  };

  handleCanPlay = () => {
    this.setState({ isLoading: false });
  };

  handleToggleFullScreen = () => {
    const { onToggleFullScreen } = this.props;
    onToggleFullScreen();
  };

  render() {
    const {
      url,
      className,
      toggleChat,
      chatCount,
      fullscreen,
      showQuestion,
      onToggleFullScreen,
      sessionData,
    } = this.props;
    const { duration, playPauseState, progressBarValue, leftposition, replay, isLoading, dragging } = this.state;
    return (
      <React.Fragment>
        <div className="video-container">
          <div className="playback__controls">
            <div className="chat-toggle" onClick={toggleChat}>
              {!!chatCount && <Badge rounded color="red" size="sm" value={chatCount > 99 ? '+99' : chatCount} />}
              <IconChat />
            </div>
            {replay ? <IconMediaReplay onClick={this.handleReplay} className="session-class-type" title="Replay" /> : !isLoading ? (
              playPauseState === 'pause' ? (
                <IconMediaPause
                  onClick={this.handlePlayPauseButtonClick}
                  className="session-class-type"
                  title={playPauseState}
                />
              ) : (
                <IconMediaPlay
                  onClick={this.handlePlayPauseButtonClick}
                  className="session-class-type"
                  title={playPauseState}
                />
              )
            ) : <IconLoader className="session-class-type" />}
            <div className="progress">
              <span>
                {moment.utc(moment.duration(progressBarValue, 'seconds').as('milliseconds')).format('HH:mm:ss')}
              </span>
              <div className="playback-progress">
                <div
                  className="playback-progress-bar"
                  ref={progressBarRef}
                  onClick={this.progressbarClickHandlerFunction}
                  onTouchEnd={dragging ? () => {} : this.progressbarClickHandlerFunction}
                >
                  <div
                    className="draggable"
                    onDrag={this.dragFunction}
                    onTouchMove={this.dragFunction}
                    onTouchStart={this.handleDragStart}
                    onDragStart={this.handleDragStart}
                    onTouchEnd={this.progressbarClickHandlerFunction}
                    onDragEnd={this.progressbarClickHandlerFunction}
                    draggable="true"
                    style={{ left: `${leftposition}%` }}
                  />
                  <span className="playback-progress__current" style={{ width: `${this.state.dragWidth}%` }} />
                </div>
                {/* {!isLoading && !!duration && playbackUsers && !fullscreen && (
                  <PlaybackActivity
                    playbackUsers={playbackUsers}
                    duration={duration}
                    progressBarLength={progressBarRef && progressBarRef.current && progressBarRef.current.clientWidth}
                  />
                )} */}
              </div>
              {!!duration && duration > 0 && (
                <span>{moment.utc(moment.duration(duration, 'seconds').as('milliseconds')).format('HH:mm:ss')}</span>
              )}
              {!duration && <IconLoader />}
            </div>
            {fullscreen ? <IconMediaFullscreenExit title="Exit Fullscreen" onClick={this.handleToggleFullScreen} /> : <IconMediaFullscreen title="Fullscreen" onClick={this.handleToggleFullScreen} />}
          </div>
          <VideoCard videoStatus={sessionData.is_video_enabled && audioPlayerref.current && !audioPlayerref.current.paused}>
            <video
              className={className}
              onPlay={this.changeButtonState}
              onPause={this.changeButtonState}
              onEnded={this.handleEnded}
              onLoadedMetadata={this.handleLoadedMetadata}
              onTimeUpdate={this.currentPlayingTime}
              onCanPlay={this.handleCanPlay}
              ref={audioPlayerref}
              width="320"
              height="240"
            >
              <source src={url} type="video/mp4" />
              Sorry, your browser doesn't support playback
            </video>
          </VideoCard>
        </div>
        {!showQuestion && (
          <div className="videoFullScreenToggler" onTouchEnd={onToggleFullScreen} onClick={onToggleFullScreen} />
        )}
      </React.Fragment>
    );
  }
}

export default VideoPlayer;

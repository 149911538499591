import { put, takeEvery, call, all, select } from 'redux-saga/effects';
import isEmpty from 'lodash-es/isEmpty';
import { athenaApi } from '../restApi';
import { GET_BREAKOUT_TEAMS } from '../constants';
import { teamsMetaSelector } from '../selectors/teams';

const getBreakoutTeamsApi = ({ sessionId, offset, last_score, last_rank }) => athenaApi.get(`breakouts/teams/${sessionId}`, { offset, last_score, last_rank });

const getMyClassroom = (state) => state.toJS().myClassroom;

function* getBreakoutTeams({ sessionId, resetOffset }) {
  try {
    const { sessionDetails = {} } = yield select(getMyClassroom);

    const { offset, last_score, last_rank } = yield select(teamsMetaSelector);
    const response = yield call(getBreakoutTeamsApi, {
      sessionId: sessionId || sessionDetails.id,
      offset: resetOffset ? undefined : offset,
      last_score: resetOffset ? undefined : last_score,
      last_rank: resetOffset ? undefined : last_rank,
    });
    if (response.ok) {
      yield put({
        type: GET_BREAKOUT_TEAMS.SUCCESS,
        payload: isEmpty(response.data) ? [] : response.data,
        resetOffset,
      });
    } else {
      yield put({ type: GET_BREAKOUT_TEAMS.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: GET_BREAKOUT_TEAMS.FAILURE, payload: err });
  }
}
export function* teamsSaga() {
  yield all([takeEvery(GET_BREAKOUT_TEAMS.REQUEST, getBreakoutTeams)]);
}

import get from 'lodash-es/get';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import t from '../../helpers/translate';
import { useLocalStorage } from '../../hooks';
import {
    setWebrtcStatus, toggleAudio, toggleVideo, updateMicVolumeIndicator, updateWebrtcStreams
} from '../../redux/actions/webrtc';
import {
    SET_LOCAL_DEVICE_DETAILS, SWITCH_AUDIO_DEVICE, SWITCH_VIDEO_DEVICE
} from '../../redux/constants';
import AgoraRtc from '../../sdk/agora/agoraRtc';
import AGORA_RTC_ERRORS from '../../sdk/agora/agoraRtcErrors';
import AgoraRtcOld from '../../sdk/agora/agoraRtcOld';
import { SentryType, TrackSentryError } from '../../sdk/sentry';
import { addToast, TOAST_TYPE } from '../Toast';

export enum WEBRTC_PROVIDER {
    AGORA
};

type IAgoraConnection = {
  appId: string,
  token: string,
  uid: string,
  channel: string,
}

export type IWebrtcConnectionDetails = {
    [WEBRTC_PROVIDER.AGORA]: IAgoraConnection,
}

type IWebrtcContainerProp = {
    connectionDetails: IWebrtcConnectionDetails,
    onListenStats: (payload) => void,
    setLocalDeviceDetails: () => void,
    config?: {
      enableStats?: boolean,
      useLatest?: boolean,
      enableProxy?: boolean,
      serverRegion?: string,
      autoEnableMicOnStart? : boolean, // Enable mic on start
      video? : boolean, 
    }
}

type IStats = [
  'Timestamp',
  'IsMute',
  'RecordingLevel',
  'SendBitrate',
  'SendVolumeLevel',
  'UplinkQuality',
  'DownlinkQuality',
]

const NUMBER_OF_STATS_IN_ONE_CALL = 30;

const WebrtcContainer = (prop: IWebrtcContainerProp) => {
  const { connectionDetails, onListenStats, config, setLocalDeviceDetails } = prop;

  const RtcRef = useRef(null);
  const localAudioStats = useRef(null);
  const dispatch = useDispatch();

  const audioStatus = useSelector((state) => state.toJS().webrtc.audioStatus);
  const videoStatus = useSelector((state) => state.toJS().webrtc.videoStatus);
  const localDevices = useSelector((state) => state.toJS().myClassroom.localDevices);
  const isAudioStatusLoading = useSelector((state) => state.toJS().webrtc.isAudioStatusLoading);
  const isVideoStatusLoading = useSelector((state) => state.toJS().webrtc.isVideoStatusLoading);
  const [connectionInitiated, setConnectionInitiated] = useState(false);
  const [micMuteForChannel, setMicMuteForChannel] = useLocalStorage('webrtc-mic-mute', '');
  const [videoError, setVideoError] = useState(false);

  // auto enable mic on start
  const autoEnableMic = () => {
    if (config?.autoEnableMicOnStart && micMuteForChannel !== connectionDetails[WEBRTC_PROVIDER.AGORA]?.channel) {
      dispatch(toggleAudio('REQUEST'));
    }
  }

  const connectionStatus = ({ curState, prevState }) => {
    // webrtc Initiated
    if (prevState === 'INITIATED' && curState === 'CONNECTED') {
      autoEnableMic();
      changeVideoStatus(false);
      const {videoStatus} = JSON.parse(localStorage.getItem('classroomDetails') || '{}')
      if(videoStatus) {
        dispatch(toggleVideo('REQUEST'));
      }
    }
    dispatch(setWebrtcStatus(curState));
  } 

  const onStreamUpdated = (streams) => {
    dispatch(updateWebrtcStreams(streams));
  }

  const changeMicStatus = (state: Boolean) => {
    dispatch(toggleAudio('SUCCESS', state));
  }

  const changeVideoStatus = (state: Boolean) => {
    dispatch(toggleVideo('SUCCESS', state));
  }

  const onError = (msg: string, err: any) => {
    TrackSentryError(msg, err, SentryType.ERROR);
    if (msg === AGORA_RTC_ERRORS[102]) {
      addToast(t('teacherRtcError', '102'), TOAST_TYPE.ERROR);
    } else if (msg === AGORA_RTC_ERRORS[103]) {
      addToast(t('teacherRtcError', '103'), TOAST_TYPE.ERROR);
    } else if (msg === AGORA_RTC_ERRORS[105]) {
      addToast(t('teacherRtcError', '105'), TOAST_TYPE.ERROR);
      dispatch(toggleAudio('FAILURE', audioStatus));
    } else if (msg === AGORA_RTC_ERRORS[107]) {
      addToast(t('teacherRtcError', '107'), TOAST_TYPE.ERROR);
    } else if (msg === AGORA_RTC_ERRORS[109]) {
      addToast(t('teacherRtcError', '109'), TOAST_TYPE.ERROR, 5000);
    } else if (msg === AGORA_RTC_ERRORS[110] || msg === AGORA_RTC_ERRORS[111]) {
      dispatch({
        type: SET_LOCAL_DEVICE_DETAILS,
        payload: {
          isCameraWorking: false,
        },
      });
      dispatch(toggleVideo('FAILURE', false));
    } 
  }

  const setVolumeBar = () => {
    RtcRef.current.on('volume-indicator', (volumeIndicatorData) => {
      dispatch(updateMicVolumeIndicator(volumeIndicatorData));
    });
  }

  const listenStats = () => {
    localAudioStats.current = [];
    RtcRef.current.on('localAudioStats', (stats: IStats) => {
      localAudioStats.current.push(stats.join('|'));
      if (localAudioStats.current?.length === NUMBER_OF_STATS_IN_ONE_CALL) {
        onListenStats(localAudioStats.current);
        localAudioStats.current = [];
      }
    });
  }

  // TODO: Mahipat - Try and Error we have to revisit this code 
  const handleOnVideoInitError = () => {
      setVideoError(true);
      try {
        TrackSentryError<string>(
          'CAMERA INITIALISATION ERROR',
          { msg: "Device has camera but error on initializing camera device" },
          SentryType.ERROR,
        );
      } catch(e) {
        console.log('camera initialisation error', e)
      }
      if (RtcRef.current) RtcRef.current.disconnect();
      dispatch({
        type: SET_LOCAL_DEVICE_DETAILS,
        payload: {
          isCameraWorking: false,
        },
      });
  }

  const handleOnCameraChanged = (event) => {
    setLocalDeviceDetails();
      setTimeout(() => {
        dispatch({
          type: SWITCH_VIDEO_DEVICE,
          payload: true,
        });
      }, 1000);
  }

  const handleOnMicrophoneChanged = (event) => {
    setLocalDeviceDetails();
      setTimeout(() => {
        dispatch({
          type: SWITCH_AUDIO_DEVICE,
          payload: true,
        });
      }, 1000)
  }

  const rtcInit = () => {
    setConnectionInitiated(true);
    console.log(localDevices);
    const connection = connectionDetails[WEBRTC_PROVIDER.AGORA];
    if (config.useLatest) {
        RtcRef.current = new AgoraRtc({ 
          connectionDetails: connection,
          connectionStatus,
          onStreamUpdated,
          changeMicStatus,
          changeVideoStatus,
          onError,
          handleOnVideoInitError,
          handleOnCameraChanged,
          handleOnMicrophoneChanged,
          options: config
        });
      
    } else {
        RtcRef.current = new AgoraRtcOld({
          connectionDetails: connection,
          connectionStatus,
          onStreamUpdated,
          changeMicStatus,
          changeVideoStatus,
          onError,
          handleOnVideoInitError,
          handleOnCameraChanged,
          handleOnMicrophoneChanged,
          options: config
        });
    }
    RtcRef.current.init();
    setVolumeBar();
    if (onListenStats) listenStats();
  }

  const initiateConnection = () => {
    if (connectionDetails[WEBRTC_PROVIDER.AGORA] && !connectionInitiated) {
        if (config.useLatest) {
          import('../../sdk/agora/AgoraRTC_N-4.6.2.100').then(() => {
            rtcInit();
          });
        } else {
          import('../../sdk/agora/AgoraRTCSDK-3.6.2.200').then(() => {
            rtcInit();
          });
        }
    }
  }

  useEffect(() => {
    if(!localDevices.isCameraWorking && videoError) {
      rtcInit();  
    }

    // On user switch audio/video device
    if (localDevices.isSwitchingVideo && localDevices.isCameraWorking && localDevices?.selected?.camera?.deviceId) {
      dispatch({
        type: SWITCH_VIDEO_DEVICE,
        payload: false,
      });
      RtcRef.current.switchDevice('video', localDevices?.selected?.camera?.deviceId);
    } else if (localDevices.isSwitchingAudio) {
      dispatch({
        type: SWITCH_AUDIO_DEVICE,
        payload: false,
      });
      RtcRef.current.switchDevice('audio', localDevices?.selected?.microphone?.deviceId);
    }
  }, [localDevices]);

  useEffect(() => {
    initiateConnection();
    return () => {
        if (RtcRef.current) RtcRef.current.disconnect();
    }
  }, []);

  useEffect(() => {
    if (isAudioStatusLoading) {
        if (audioStatus) {
            RtcRef.current.disableAudioStream();
            setMicMuteForChannel(connectionDetails[WEBRTC_PROVIDER.AGORA]?.channel);
        } else {
            RtcRef.current.enableAudioStream();
            setMicMuteForChannel('');
        }
    } 
  }, [isAudioStatusLoading]);

  useEffect(() => {
    if (isVideoStatusLoading && RtcRef.current) {
        if(videoStatus) {
            RtcRef.current.disableVideoStream();
        } else {
            RtcRef.current.enableVideoStream();
        }
    }
  }, [isVideoStatusLoading]);

  return (
    <div className="webrtc-container" />
  );
};

export default WebrtcContainer;

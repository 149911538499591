import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import { Card, Row, Column, Button, Select, Checkbox } from '@noon/atom';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { isEmpty, get } from 'lodash-es';
import {
  IconCorrectCircle,
  IconDelete,
  IconDrag,
  IconEditO,
  IconWrong,
} from '@noon/quark';
import { COLORS } from '../../constants';
import Autocomplete from './autocomplete';
import { translationText } from '../../helpers';
import { ConfirmationModal } from '../Modals';
import { addToast, TOAST_TYPE } from '../Toast';

const TopicCard = ({
  handleCheckDuplicateTopic,
  topic,
  index,
  chapterType,
  suggestedTopics,
  handleMapUpdateTopic,
  handleChapterTopicSuggestions,
  handleUpdateTopic,
  handleDeleteTopic,
  handleUpdateTopicStatus,
}) => {
  const [selectedTopic, setSelectedTopic] = useState({});
  const [autoCompleteUserInput, setAutoCompleteUserInput] = useState('');
  const [enableEditTopic, setEnableEditTopic] = useState(!topic.name);
  const [showEditDeleteIcon, setShowEditDeleteIcon] = useState(isMobileOnly);
  const { noonText } = useSelector((state) => state.toJS().translation);
  const { is_unified_group_teacher } = useSelector((state) => state.toJS().experiment.featureFlags);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDuplicateTopic, setIsDuplicateTopic] = useState(false);
  const tagOptions = [
    { id: 1, name: 'Free', title: translationText(noonText, 'groups.free') },
    { id: 2, name: 'Plus', title: translationText(noonText, 'groups.plus') },
  ];
  const [selectedStatus, setSelectedStatus] = useState(tagOptions[0]);

  // Set initial topic status free/paid.
  useEffect(() => {
    if (!topic.is_free) {
      setSelectedStatus(tagOptions[1]);
    }
  }, [topic]);

  useEffect(() => {
    if (isEmpty(suggestedTopics) && enableEditTopic) {
      handleChapterTopicSuggestions();
    }
  }, [enableEditTopic]);

  const ListComponent = (prop) => (
    <Row nowrap>
      <Checkbox checked={prop.id === selectedStatus.id} />
      <span className={classNames('status-title', { 'status-active': prop.id === selectedStatus.id })}>
        {prop.title}
      </span>
    </Row>
  );

  const TitleComponent = (prop) => (
    <Row nowrap align="start">
      <Row className="mr-1">
        {prop.id === 1 ? <img src="/assets/images/freecircle.svg" /> : <img src="/assets/images/pluscircle.svg" />}
      </Row>
      <span className="status-title">{prop.title}</span>
    </Row>
  );

  const handleTopicStatusChange = (value) => {
    handleUpdateTopicStatus(index, value.id === 1);
    setSelectedStatus(value);
  };

  const handleSubmitTopicUpdate = (value) => {
    if (isEmpty(selectedTopic) && !value) return false;
    const isDuplicate = handleCheckDuplicateTopic(get(selectedTopic, ['name']) || value, index);
    setIsDuplicateTopic(isDuplicate);
    if (isDuplicate) return false;

    handleUpdateTopic(get(selectedTopic, ['name']) || value, index);
    setEnableEditTopic(false);
    return true;
  };

  const handleDeleteTopicConfirmation = () => {
    if (topic.upcoming_activities > 0) {
      addToast(translationText(noonText, 'groupCurriculum.cantDeleteEntityWithActivity'), TOAST_TYPE.WARNING);
    } else {
      setShowDeleteConfirmation(true);
    }
  };

  const handleEditTopicConfirmation = () => {
      setEnableEditTopic(true);
  };

  const handleCloseEditTopicNameClick = () => {
    setEnableEditTopic(false);
    if (topic.name === '') {
      handleDeleteTopic(index);
    }
  };
  return (
    <Card className="mb-2">
      {enableEditTopic ? (
        <React.Fragment>
          <Column align="top" className="mlr-1 ptb-1 chapter-header-mobile" gap="sm">
            <Row align="center" justify="space-between" nowrap style={{ width: '100%' }}>
              <Button type="default" size="sm">
                {translationText(noonText, `groups.${topic.name_header_key || `Topic${index + 1}`}`)}
              </Button>
              <Row align="center" nowrap gap="sm">
                <IconCorrectCircle
                  height="40px"
                  width="40px"
                  stroke="#fff"
                  fill={COLORS.primary.base}
                  className="child"
                  onClick={() => handleSubmitTopicUpdate(autoCompleteUserInput)}
                />
                <IconWrong
                  height="40px"
                  width="40px"
                  fill="#707070"
                  stroke="#fff"
                  className="child"
                  onClick={handleCloseEditTopicNameClick}
                />
              </Row>
            </Row>
            <Autocomplete
              style={{ width: '100%' }}
              onSelect={handleSubmitTopicUpdate}
              onChange={(inputValue) => {
                setAutoCompleteUserInput(inputValue);
              }}
              value={topic.name}
              suggestions={suggestedTopics || []}
              emptyMessage="No Topic"
              error={isDuplicateTopic}
              errorMessage={translationText(noonText, 'groupCurriculum.topicNameExists')}
            />
          </Column>
          <Row align="top" className="ml-4 ptb-1 chapter-header">
            <Button type="default" size="sm" className="mlr-1">
              {translationText(noonText, `groups.${topic.name_header_key || `Topic${index + 1}`}`)}
            </Button>
            <Autocomplete
              onSelect={handleSubmitTopicUpdate}
              onChange={(inputValue) => {
                setAutoCompleteUserInput(inputValue);
              }}
              value={topic.name}
              suggestions={suggestedTopics || []}
              emptyMessage="No Topic"
              error={isDuplicateTopic}
              errorMessage={translationText(noonText, 'groupCurriculum.topicNameExists')}
            />

            <IconCorrectCircle
              height="40px"
              width="40px"
              stroke="#fff"
              fill={COLORS.primary.base}
              className="mlr-1"
              onClick={() => handleSubmitTopicUpdate(autoCompleteUserInput)}
            />
            <IconWrong
              height="40px"
              width="40px"
              fill="#707070"
              stroke="#fff"
              className="mlr-1"
              onClick={handleCloseEditTopicNameClick}
            />
          </Row>
        </React.Fragment>
      ) : (
        <Column align="start">
          <Row
            nowrap
            justify="space-between"
            align="center"
            style={{ width: '100%' }}
            onMouseEnter={() => setShowEditDeleteIcon(true)}
            onMouseLeave={() => setShowEditDeleteIcon(isMobileOnly)}
          >
            <Row align="center" className="flex-1 ptb-1">
              {topic.state === 'ongoing' ? (
                <IconCorrectCircle width="20px" className="edit-profile plr-2" fill={COLORS.brand.base} stroke="#fff" />
              ) : topic.state === 'completed' ? (
                <IconCorrectCircle
                  width="20px"
                  className="edit-profile plr-2"
                  fill={COLORS.coolGrey[1]}
                  stroke="#fff"
                />
              ) : (
                <IconDrag
                  height="13px"
                  className={classNames('icon-drag edit-profile plr-2', { 'icon-hover': showEditDeleteIcon })}
                />
              )}
              <h4 className="heading">
                <span className="topic">
                  {translationText(noonText, `groups.${topic.name_header_key || `Topic${index + 1}`}`)}
                </span>
                &nbsp; - {topic.name}
              </h4>
              {chapterType !== 'misc' && showEditDeleteIcon && (
                <React.Fragment>
                  <IconEditO
                    width="12px"
                    height="12px"
                    className="edit-profile mlr-1"
                    onClick={handleEditTopicConfirmation}
                  />
                  <IconDelete
                    width="12px"
                    height="12px"
                    className="edit-profile mlr-1"
                    onClick={handleDeleteTopicConfirmation}
                  />
                </React.Fragment>
              )}
            </Row>
              {is_unified_group_teacher.isEnabled &&
                <Row align="center">
                  <Select
                    selectedItem={selectedStatus}
                    onSelect={handleTopicStatusChange}
                    list={tagOptions}
                    listItemComponent={ListComponent}
                    titleComponent={TitleComponent}
                    outlined
                    className="status-select"
                  />
                </Row>
              }
          </Row>
        </Column>
      )}
      {showDeleteConfirmation && (
        <ConfirmationModal
          modalType="delete"
          successBtn={translationText(noonText, 'activity.delete')}
          closeBtn={translationText(noonText, 'button.cancel')}
          onClose={() => {
            setShowDeleteConfirmation(false);
          }}
          onSuccess={() => {
            setShowDeleteConfirmation(false);
            handleDeleteTopic(index);
          }}
          text={translationText(noonText, 'groupCurriculum.deleteTopic')}
          subText={translationText(noonText, 'groups.cantUndo')}
        />
      )}
    </Card>
  );
};

TopicCard.propTypes = {
  topic: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  chapterType: PropTypes.string.isRequired,
  suggestedTopics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleMapUpdateTopic: PropTypes.func.isRequired,
  handleChapterTopicSuggestions: PropTypes.func.isRequired,
  handleUpdateTopic: PropTypes.func.isRequired,
  handleDeleteTopic: PropTypes.func.isRequired,
  handleUpdateTopicStatus: PropTypes.func.isRequired,
  handleCheckDuplicateTopic: PropTypes.func.isRequired,
};

export default TopicCard;

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Column, Button, Card, Row } from '@noon/atom';
import { IconAdd, IconBack, IconNote } from '@noon/quark';
import { COLORS } from '../../../constants';
import { translationText } from '../../../helpers';
import NotAllowedOverlay from '../NotAllowedOverlay';

function EmptyState({ isArchived, miscChapter, history, id, handleMixpanelCurriculumEvents, isRtl, handleViewContentCurriculumClick, handleClickCreateNewActivity }) {
  const handleFillCurriculumClicked = () => {
    handleMixpanelCurriculumEvents({ type: 'fill_curriculum_clicked' });
    history.push(`/${localStorage.updatedLocale}/curriculum/${id}`);
  };
  const noonText = useSelector((state) => state.toJS().translation.noonText);

  return (
    <Card>
      <Row align="center" justify="start" flex="1" className="mtb-2 plr-2 card-header">
        <h3 className="card-header__title">{translationText(noonText, 'tab.curriculum')}</h3>
      </Row>
      <Column align="start" justify="center" className="card-body curriculum-widget">
        {isArchived && <NotAllowedOverlay />}
        <Row jutify="center" flex="1" className="card-body--empty">
          <span className="card-body__note mb-1">{translationText(noonText, 'groupCurriculum.emptyTitle')}</span>
          <Button
            type="primary"
            onClick={handleFillCurriculumClicked}
            value={translationText(noonText, 'groupCurriculum.fillCurriculum')}
            icon={IconNote}
            fill="#fff"
            style={{ textTransform: 'capitalize', width: '100%' }}
          />
        </Row>
        <Row
          style={{ width: '100%' }}
          nowrap
          align="center"
          justify="space-between"
          className={`${miscChapter.type}-card ${miscChapter.state}`}
        >
          <Column align="start" justify="start">
            <h4 style={{ fontWeight: 'normal', marginTop: '0' }} className="child">
              {miscChapter.name}
            </h4>
            <Row align="center" nowrap className="child">
              <label>{translationText(noonText, 'groupCurriculum.upcomingActivities')}</label>
              <label
                className="mlr-1"
                style={{
                  border: '0.5px solid #62687A',
                  borderRadius: '4px',
                  color: '#62687A',
                  width: '20px',
                  height: '20px',
                  textAlign: 'center',
                  verticalAlign: 'middle',
                  lineHeight: '1.3',
                }}
              >
                {miscChapter.upcoming_activities || 0}
              </label>
              <IconAdd onClick={() => handleClickCreateNewActivity({ chapter: miscChapter })} fill={COLORS.brand.base} width="21.5" height="21.5" />
            </Row>
          </Column>
          <Button
            type="secondary"
            size="sm"
            className="no-padding"
            style={{
              minWidth: '27px',
              borderColor: '#DCE3FA',
            }}
            outlined
            onClick={() => handleViewContentCurriculumClick(`chapterId=${miscChapter.chapter_id}`)}
          >
            <IconBack height="12px" width="12px" fill="#64D8AE" stroke="#64D8AE" flipX={!isRtl} />
          </Button>
        </Row>
      </Column>
    </Card>
  );
}

EmptyState.propTypes = {
  id: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
  handleMixpanelCurriculumEvents: PropTypes.func.isRequired,
  miscChapter: PropTypes.shape({}),
  isRtl: PropTypes.bool.isRequired,
  handleViewContentCurriculumClick: PropTypes.func.isRequired,
  handleClickCreateNewActivity: PropTypes.func.isRequired,
};

EmptyState.defaultProps = {
  miscChapter: {},
};

export default EmptyState;

import React from 'react';
import { Row } from '@noon/atom';
import PropTypes from 'prop-types';
import { IconLoader } from '@noon/quark';
import ReportQuestion from './ReportQuestion';
import { checkIsMobile } from '../../helpers/prepareAssignment';

const ReportQuestions = (props) => {
  const { questions, noonText, loading, profile, ref } = props;
  return (
    <div ref={ref} className="report--questions" style={{ height: checkIsMobile() ? profile.position === -1 ? 'calc(100vh - 156px - 87px)' : 'calc(100vh - 202px - 87px)' : '' }}>
      {loading
        ? (
          <Row align="center" justify="center" style={{ height: '100%' }}>
            <IconLoader height="90px" width="90px" />
          </Row>
        ) : questions && questions ? (
          questions.map((question, index) => (
            <ReportQuestion
              question={question}
              key={question.id}
              index={index}
              noonText={noonText}
            />
          ),
          )
        ) : null}
    </div>
  );
};

ReportQuestions.propTypes = {
  noonText: PropTypes.shape().isRequired,
  profile: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  questions: PropTypes.arrayOf({}).isRequired,
  ref: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

ReportQuestions.defaultProps = {
  ref: null,
};

export default ReportQuestions;


import { IQuestion } from './Content/Questions/questionContainer';

declare global {
  var LoggingManager: {
    mount(arg: any):Logger;
  }
  var AgoraRTC: {
    checkSystemRequirements(): boolean;
  }
}


export type Logger = {
  track(eventName: string, eventData:Record<string, unknown>):void
}

export type ISessionDetails = {
    id: string,
    state: string,
    title: String,
    start_time: number,
    curriculum_tags: Array<any>,
    class_type: string,
    breakout_mode_new: string,
}

type IRaiseHandUser = {
    profile_pic: string,
    name: string,
    user_id: number,
    value: Boolean
}

export type IAudioModeEvent = {
    active_user?: IRaiseHandUser,
    raise_hand_queue: IRaiseHandUser[],
    raise_hand_queue_size: number,
}

export const CLASSROOM_TYPE = {
  competition: 'competition',
  group: 'group',
};

export const CLASSROOM_STATE = {
  scheduled: 'scheduled',
  started: 'started',
  ended: 'ended',
  missed: 'missed',
};


export type ISlideOptions = {
  activate: Boolean,
  broadcast: Boolean,
  reviewed: Boolean,
};

export type ISlidePayload = {
  number: number;
  resource_type: string;
  resource_id?: number;
  resource: {};
  group_id: string;
  topic_id?: number;
  canvas_id?: string;
  prev_canvas_id: string | null;
  next_canvas_id?: string;
  label_name: string; // Optional,String, Represents label name for this canvas
  label_id: string; // Mandatory,String, Represents id for label, will help in grouping the canvas together
  session_state: string; // 'started/scheduled': Mandatory, Required to ignore start time update in draft mode
  activation_time?: number;
  active?: number;
  breakout_mode?: string;
  breakout_mode_new?: '';
  created_at?: number;
  folder_id?: number;
  height?: number;
  leaf_folder_id?: number;
  raise_hand?: boolean;
  reviewed?: number;
  start_time?: number;
  style?: {};
  visited?: boolean;
  width?: number;
  snapshotUrl?: {
    large_url: string;
  };
  canvas_start_time?: number;
  slideIndex?: number;
  isTeacher?: number;
  broadcast?: boolean;
  activate?: boolean;
  setReview?: boolean;
  boolean?: boolean;
};

export type ISlideQuestionPayload = ISlidePayload & {
number: number,
resource_type: 'question',
resource_id: number,
resource: IQuestion,
group_id: string,
topic_id: number,
canvas_id?: string,
prev_canvas_id: string | null,
next_canvas_id?: string,
label_name?: string,
label_id?: string,
session_state: string,
}

export type IMoveSlideData = {
canvas_id: string,
prev_canvas_id: string | null,
next_canvas_id?: string,
}

export type ISlideParams = {
resourceType?: string,
resource?: any,
resourceId?: number,
topicId?: number,
activate?: boolean,
broadcast?: boolean,
label_name?: string,
label_id?: string,
}

export type ISlide = {
  number: number,
  resource_type: 'question' | 'none',
  resource?: IQuestion,
  resource_id: number,
  group_id: string,
  topic_id: number,
  canvas_id: string,
  prev_canvas_id?: string,
  next_canvas_id?: string,
  reviewed?: Boolean,
  active?: 0 | 1,
  label_name: string,
  label_id: string,
  session_state: string,
  payload?: ISlidePayload,
  slideIndex?: number,
}

export type IClassSlidesProp = {
  slides: Array<ISlide>,
  activeSlideIndex: number,
  onSelectSlide: Function,
  onDeleteSlide: Function,
  onAddSlide: Function,
  onMoveSection: Function,
  getSlides: Function,
  onMoveSlide: (removedIndex: number, addedIndex: number) => void,
  config: {
    allowDelete: Boolean,
    disableAddSlide: Boolean,
    disableSelectSlide: Boolean,
  },
  isClassStarted: Boolean,
  isSlideLoading: string | number,
  isSlideAdding: string | number,
}

export type SlideDropParamType = {
  removedIndex: number,
  addedIndex: number
};

export enum RaiseHandStatus {
  active = 'active',
  pending = 'pending',
  accepted = 'accepted',
  cancelled = 'cancelled',
};

export enum RaiseHandActionTypes {
  remove = 'remove',
  start_speaking = 'start_speaking',
};
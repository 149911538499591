import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/file';
import { useDispatch, useSelector } from 'react-redux';

import {
  PLAYBACK_END,
  PLAYBACK_ON_DURATION_LOAD,
  PLAYBACK_ON_PROGRESS,
  PLAYBACK_UPDATE_STATE,
} from '../../../redux/constants';

const Player = React.forwardRef((props: any, ref: ((e: any) => void) | React.MutableRefObject<any> | null) => {
  const dispatch = useDispatch();
  const readyState = useRef(false);
  const { playerData } = useSelector((state) => state.toJS().playback);

  const [url, setUrl] = useState<string>('');
  const [key, setKey] = useState<string>('m3u8');

  const {
    streamUrl,
    fallbackUrl,
    volume,
    state: playerState,
    playbackRate,
  } = playerData;

  const progressInterval : number = 1000 / playbackRate;

  const fallbacktoMp4 = () : void => {
    setUrl(fallbackUrl);
    setKey('mp4');
  };

  const shouldPlay = () : boolean => playerState === 'playing';

  useEffect(() => {
    setUrl(streamUrl || fallbackUrl);
  }, [streamUrl, fallbackUrl]);

  return (
    <ReactPlayer
      ref={ref}
      url={url}
      key={key}
      playing={shouldPlay()}
      volume={volume}
      className="react-player"
      playbackRate={playbackRate}
      progressInterval={progressInterval}
      onDuration={(e) => { dispatch({ type: PLAYBACK_ON_DURATION_LOAD, payload: e }); }}
      onProgress={(e) => { dispatch({ type: PLAYBACK_ON_PROGRESS, payload: e }); }}
      onEnded={() => { dispatch({ type: PLAYBACK_END }); }}
      onError={fallbacktoMp4}
      onReady={() => {
        if (!readyState.current) {
          dispatch({ type: PLAYBACK_UPDATE_STATE, payload: 'ready' });
          readyState.current = true;
        }
      }}
     />
  );
});

Player.propTypes = {
  playerData: PropTypes.shape({}).isRequired,
};

export default Player;

import { useContext } from 'react';
import { EventContext, EventDispatchContext } from './context';
import { ISendMessage } from './provider';

function useEvent(): { pubnub: any, agoraRtm: any } {
  const context = useContext(EventContext);
  if (!context) {
    console.log('Please wrap your component inside EventProvider');
  }
  return context;
}

function useEventDispatch():ISendMessage {
  const context = useContext(EventDispatchContext);
  if (!context) {
    console.log('Please wrap your component inside EventProvider');
  }
  return context;
}

export { useEvent, useEventDispatch };

import { put, call, takeEvery, all, select } from 'redux-saga/effects';
import isEmpty from 'lodash-es/isEmpty';
import { noonTextSelector } from 'redux/selectors/translations';
import { groupDetailsSelector } from 'redux/selectors/groupsV2';
import { filter } from 'lodash-es';
import { DateTime } from 'luxon';
import { curriculumApi, groupsApi, excaliburApi } from '../restApi';
import {
  GET_TEACHER_TAGS_GROUP,
  GET_TEACHER_GROUPS,
  GET_TEACHER_GROUP_SELECTED,
  UPDATE_GROUP_V2,
  CREATE_GROUP_V2,
  GET_TEACHER_GROUP_CHAPTERS,
  GET_TEACHER_GROUP_TOPICS,
  GET_TEACHER_GROUP_COURSE_PREVIEW,
  POST_TEACHER_GROUP_TOPICS_MARK_COMPLETE,
  GET_TEACHER_GROUP_MEMBERS_INFO,
  GET_CHAPTERS_FROM_GROUP,
  GET_TOPICS_FROM_GROUP,
  GET_GROUP_CURRICULUM,
  ARCHIVE_GROUP,
  UNARCHIVE_GROUP,
  GET_TEACHER_ARCHIVE_GROUPS,
} from '../constants';
import { addToast, TOAST_TYPE } from '../../components/Toast';
import { translationText, objToQuery } from '../../helpers';

const limit = 50;
const start = 0;
const getTeachersTagsGroupAPI = ({ requestId, params }) => curriculumApi.get(`teacher/${requestId}?${params}`);
const createGroupAPI = (data) => groupsApi.post('group', data);
const updateGroupAPI = ({ groupId, data }) => groupsApi.put(`group/${groupId}`, data);
const getTeacherGroupsAPI = () => groupsApi.get('/group/teacher', { limit, start });
const getTeacherGroupSelectedAPI = (groupId) => groupsApi.get(`group/teacher/group_info/${groupId}`);
const getTeacherGroupCoursePreviewAPI = (groupId) => groupsApi.get(`group/teacher/course_preview/${groupId}`);
const postTeacherGroupChapterTopicMarkCompleteAPI = ({ topicId, chapterId, groupId }) => groupsApi.post(`curriculum/mark_as_complete/${groupId}`, { topic_id: topicId, chapter_id: chapterId });
const getTeacherGroupMembersInfoAPI = (groupId) => excaliburApi.get(`group/member_info/${groupId}`);
const getChaptersFromGroupIdAPI = (groupId) => groupsApi.get(`group/teacher/group_info_chapters/${groupId}`);
const getTeacherGroupChaptersAPI = (groupId) => groupsApi.get(`group/teacher/chapters/${groupId}`, { limit, start });
const getTeacherGroupChapterTopicsAPI = ({ chapterId, groupId }) => groupsApi.get(`group/teacher/topics/${groupId}`, { chapter_id: chapterId });
const getTopicsForChapterFromGroupIdAPI = ({ groupId, chapterId }) => groupsApi.get(`group/teacher/topics/${groupId}`, { chapter_id: chapterId });
const archiveGroupAPI = ({ group_id }) => groupsApi.put(`teacher/archive/${group_id}`);
const unArchiveGroupAPI = ({ group_id }) => groupsApi.put(`teacher/unarchive/${group_id}`);
const getTeacherArchivedGroupAPI = () => groupsApi.get('/group/teacher/archive', { limit, start });

function* archiveGroup({ payload }) {
  try {
    const response = yield call(archiveGroupAPI, payload);
    if (response.ok) {
      yield put({
        type: ARCHIVE_GROUP.SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: ARCHIVE_GROUP.FAILURE,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({
      type: ARCHIVE_GROUP.FAILURE,
      payload: error,
    });
  }
}

function* unArchiveGroup({ payload }) {
  try {
    const response = yield call(unArchiveGroupAPI, payload);
    if (response.ok) {
      yield put({
        type: UNARCHIVE_GROUP.SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: UNARCHIVE_GROUP.FAILURE,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({
      type: UNARCHIVE_GROUP.FAILURE,
      payload: error,
    });
  }
}

function* getTeacherArchiveGroups({ payload }) {
  try {
    const response = yield call(getTeacherArchivedGroupAPI);
    if (response.ok) {
      yield put({
        type: GET_TEACHER_ARCHIVE_GROUPS.SUCCESS,
        payload: response.data.data,
      });
    } else {
      if (response.data && response.data.message) {
        addToast(response.data.message, TOAST_TYPE.ERROR);
      }
      yield put({
        type: GET_TEACHER_ARCHIVE_GROUPS.FAILURE,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_TEACHER_ARCHIVE_GROUPS.FAILURE,
      payload: error,
    });
  }
}

function* updateGroup({ payload }) {
  try {
    const response = yield call(updateGroupAPI, payload);
    if (response.ok) {
      const groupDetails = yield select(groupDetailsSelector);
      const isSameGroup = payload.groupId === groupDetails.id;
      yield put({
        type: UPDATE_GROUP_V2.SUCCESS,
        payload: isSameGroup ? payload.data : groupDetails,
      });
      if (isSameGroup) {
        yield put({
          type: GET_TEACHER_GROUP_SELECTED.REQUEST,
          payload: payload.groupId,
        });
      }
      const noonText = yield select(noonTextSelector);
      addToast(translationText(noonText, 'success.success'), TOAST_TYPE.SUCCESS);
    } else {
      yield put({
        type: UPDATE_GROUP_V2.FAILURE,
        payload: 'NETWORK_ERROR',
      });
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
    }
  } catch (error) {
    yield put({
      type: UPDATE_GROUP_V2.FAILURE,
      payload: error,
    });
  }
}

function* createGroup({ payload }) {
  try {
    yield put({
      type: CREATE_GROUP_V2.LOADING,
      payload: { loading: true },
    });
    const response = yield call(createGroupAPI, payload);
    if (response.ok) {
      yield put({
        type: CREATE_GROUP_V2.SUCCESS,
        payload: { newGroup: response.data.data[0] },
      });
      addToast(`${response.data.message}`, TOAST_TYPE.SUCCESS);
      yield put({
        type: GET_TEACHER_GROUPS.REQUEST,
      });
    } else {
      yield put({
        type: CREATE_GROUP_V2.FAILURE,
        payload: 'NETWORK_ERROR',
      });
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
    }
  } catch (error) {
    yield put({
      type: CREATE_GROUP_V2.FAILURE,
      payload: error,
    });
  }
}

function* getTeacherGroups() {
  try {
    const response = yield call(getTeacherGroupsAPI);
    if (response.ok) {
      const inActiveGroups = filter(response.data.data, (group) => {
        const lastVisited = DateTime.fromMillis(group.last_visited || Date.now());
        const today = DateTime.fromMillis(Date.now());
        const dateDiff = today.diff(lastVisited, ['days']).toObject();
        if (response.data.meta.archive_notify_days < dateDiff.days) {
          return true;
        }
        return false;
      });

      yield put({
        type: GET_TEACHER_GROUPS.SUCCESS,
        payload: {
          groups: response.data.data,
          meta: response.data.meta,
          inActiveGroups,
        },
      });
    } else {
      if (response.data && response.data.message) {
        addToast(response.data.message, TOAST_TYPE.ERROR);
      }
      yield put({
        type: GET_TEACHER_GROUPS.FAILURE,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_TEACHER_GROUPS.FAILURE,
      payload: error,
    });
  }
}

function* getTeachersGroupSelected({ payload }) {
  try {
    const response = yield call(getTeacherGroupSelectedAPI, payload);
    if (response.ok) {
      yield put({
        type: GET_TEACHER_GROUP_SELECTED.SUCCESS,
        payload: response.data,
      });
    } else {
      if (response.data && response.data.message) {
        addToast(response.data.message, TOAST_TYPE.ERROR);
      }
      yield put({
        type: GET_TEACHER_GROUP_SELECTED.FAILURE,
        payload: 'NETWORK_ERROR',
      });
    }
  } catch (error) {
    yield put({
      type: GET_TEACHER_GROUP_SELECTED.FAILURE,
      payload: error,
    });
  }
}

function* getTeachersTagsGroup({ payload: { requestId, curriculumType, ...queryParams } }) {
  try {
    yield put({
      type: GET_TEACHER_TAGS_GROUP.LOADING,
      payload: {
        requestId: [requestId],
        data: { loading: true },
      },
    });
    const response = yield call(getTeachersTagsGroupAPI, {
      requestId,
      params: objToQuery({ curriculum_type: curriculumType, ...queryParams }),
    });
    if (response.ok) {
      yield put({
        type: GET_TEACHER_TAGS_GROUP.SUCCESS,
        payload: {
          requestId,
          data: { list: response.data.data || [], loading: false },
        },
      });
    } else {
      if (response.data && response.data.message) {
        addToast(response.data.message, TOAST_TYPE.ERROR);
      }
      yield put({
        type: GET_TEACHER_TAGS_GROUP.FAILURE,
        payload: 'NETWORK_ERROR',
      });
    }
  } catch (error) {
    yield put({
      type: GET_TEACHER_TAGS_GROUP.FAILURE,
      payload: error,
    });
  }
}

function* getTeacherGroupCoursePreview({ payload }) {
  try {
    // yield put({
    //   type: GET_TEACHER_GROUP_COURSE_PREVIEW.LOADING,
    // });
    const response = yield call(getTeacherGroupCoursePreviewAPI, payload);
    if (response.ok) {
      yield put({
        type: GET_TEACHER_GROUP_COURSE_PREVIEW.SUCCESS,
        payload: response.data,
      });
    } else {
      if (response.data && response.data.message) {
        addToast(response.data.message, TOAST_TYPE.ERROR);
      }
      yield put({
        type: GET_TEACHER_GROUP_COURSE_PREVIEW.FAILURE,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_TEACHER_GROUP_COURSE_PREVIEW.FAILURE,
      payload: error,
    });
  }
}

function* getTeacherGroupChapters({ payload }) {
  try {
    const response = yield call(getTeacherGroupChaptersAPI, payload);
    if (response.ok) {
      const miscIndex = response.data.data.findIndex((chapter) => chapter.type === 'misc');
      const miscChapter = miscIndex > -1 ? response.data.data.splice(miscIndex, 1)[0] : {};
      if (response.data.meta.total <= 1) {
        yield put({
          type: GET_GROUP_CURRICULUM.REQUEST,
          payload: { groupId: payload, isDraftOrEmpty: true },
        });
      } else {
        yield put({
          type: GET_GROUP_CURRICULUM.SUCCESS,
          payload: { content: response.data.data, curriculumExist: true, has_draft: false },
        });
      }
      yield put({
        type: GET_TEACHER_GROUP_CHAPTERS.SUCCESS,
        payload: {
          chapters: response.data.data,
          miscChapter,
        },
      });
    } else {
      if (response.data && response.data.message) {
        addToast(response.data.message, TOAST_TYPE.ERROR);
      }
      yield put({
        type: GET_TEACHER_GROUP_CHAPTERS.FAILURE,
        payload: 'NETWORK_ERROR',
      });
    }
  } catch (error) {
    yield put({
      type: GET_TEACHER_GROUP_CHAPTERS.FAILURE,
      payload: error,
    });
  }
}

function* getTeacherGroupChapterTopics({ payload }) {
  try {
    const response = yield call(getTeacherGroupChapterTopicsAPI, payload);
    if (response.ok) {
      yield put({
        type: GET_TEACHER_GROUP_TOPICS.SUCCESS,
        payload: {
          ...response.data,
          chapterId: payload.chapterId,
        },
      });
    }
  } catch (error) {
    yield put({
      type: GET_TEACHER_GROUP_TOPICS.FAILURE,
      payload: error,
    });
  }
}

function* getTopicsForChapterFromGroupId({ payload }) {
  try {
    yield put({ type: GET_TOPICS_FROM_GROUP.LOADING });
    const response = yield call(getTopicsForChapterFromGroupIdAPI, payload);
    if (response.ok) {
      yield put({
        type: GET_TOPICS_FROM_GROUP.SUCCESS,
        payload: {
          data: !isEmpty(response.data.data) ? response.data.data : [],
          meta: response.data.meta,
          chapterId: payload.chapterId,
        },
      });
    } else {
      yield put({ type: GET_TOPICS_FROM_GROUP.FAILURE, payload: 'ERROR' });
    }
  } catch (error) {
    put({ type: GET_TOPICS_FROM_GROUP.FAILURE, payload: error });
  }
}

function* postTeacherGroupChapterTopicMarkComplete({ payload }) {
  try {
    const response = yield call(postTeacherGroupChapterTopicMarkCompleteAPI, payload);
    if (response.ok) {
      const noonText = yield select(noonTextSelector);
      addToast(translationText(noonText, 'groups.topicMarkedCompleted'), TOAST_TYPE.SUCCESS);
      if (payload.topicId) {
        yield put({
          type: GET_TEACHER_GROUP_TOPICS.REQUEST,
          payload,
        });
      }
      yield put({
        type: GET_TEACHER_GROUP_CHAPTERS.REQUEST,
        payload: payload.groupId,
      });
      yield put({
        type: GET_TEACHER_GROUP_COURSE_PREVIEW.REQUEST,
        payload: payload.groupId,
      });
    } else {
      addToast(response.data.message, TOAST_TYPE.ERROR);
    }
  } catch (error) {
    yield put({
      type: POST_TEACHER_GROUP_TOPICS_MARK_COMPLETE.FAILURE,
      payload: error,
    });
  }
}

function* getTeacherGroupMembersInfo({ payload }) {
  yield put({
    type: GET_TEACHER_GROUP_MEMBERS_INFO.LOADING,
  });
  try {
    const response = yield call(getTeacherGroupMembersInfoAPI, payload);
    if (response.ok) {
      yield put({
        type: GET_TEACHER_GROUP_MEMBERS_INFO.SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_TEACHER_GROUP_MEMBERS_INFO.FAILURE,
        payload: 'ERROR',
      });
    }
  } catch (error) {
    put({
      type: GET_TEACHER_GROUP_MEMBERS_INFO.FAILURE,
      payload: error,
    });
  }
}
function* getChaptersFromGroupId({ payload }) {
  try {
    yield put({ type: GET_CHAPTERS_FROM_GROUP.LOADING });
    const response = yield call(getChaptersFromGroupIdAPI, payload);
    if (response.ok) {
      yield put({ type: GET_CHAPTERS_FROM_GROUP.SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_CHAPTERS_FROM_GROUP.FAILURE, payload: 'ERROR' });
    }
  } catch (error) {
    put({ type: GET_CHAPTERS_FROM_GROUP.FAILURE, payload: error });
  }
}
// function* getTopicsForChapterFromGroupId({ payload }) {
//   try {
//     yield put({ type: GET_TOPICS_FROM_GROUP.LOADING });
//     const response = yield call(getTopicsForChapterFromGroupIdAPI, payload);
//     if (response.ok) {
//       yield put({
//         type: GET_TOPICS_FROM_GROUP.SUCCESS,
//         payload: { data: !isEmpty(response.data.data) ? response.data.data : [], meta: response.data.meta, chapterId: payload.chapterId } });
//     } else {
//       yield put({ type: GET_TOPICS_FROM_GROUP.FAILURE, payload: 'ERROR' });
//     }
//   } catch (error) {
//     put({ type: GET_TOPICS_FROM_GROUP.FAILURE, payload: error });
//   }
// }

export function* groupsV2Saga() {
  yield all([
    takeEvery(UPDATE_GROUP_V2.REQUEST, updateGroup),
    takeEvery(GET_TEACHER_TAGS_GROUP.REQUEST, getTeachersTagsGroup),
    takeEvery(CREATE_GROUP_V2.REQUEST, createGroup),
    takeEvery(GET_TEACHER_GROUPS.REQUEST, getTeacherGroups),
    takeEvery(GET_TEACHER_GROUP_SELECTED.REQUEST, getTeachersGroupSelected),
    takeEvery(GET_TEACHER_GROUP_CHAPTERS.REQUEST, getTeacherGroupChapters),
    takeEvery(GET_TEACHER_GROUP_COURSE_PREVIEW.REQUEST, getTeacherGroupCoursePreview),
    takeEvery(GET_TEACHER_GROUP_TOPICS.REQUEST, getTeacherGroupChapterTopics),
    takeEvery(POST_TEACHER_GROUP_TOPICS_MARK_COMPLETE.REQUEST, postTeacherGroupChapterTopicMarkComplete),
    takeEvery(GET_TEACHER_GROUP_MEMBERS_INFO.REQUEST, getTeacherGroupMembersInfo),
    takeEvery(GET_CHAPTERS_FROM_GROUP.REQUEST, getChaptersFromGroupId),
    takeEvery(GET_TOPICS_FROM_GROUP.REQUEST, getTopicsForChapterFromGroupId),
    takeEvery(ARCHIVE_GROUP.REQUEST, archiveGroup),
    takeEvery(UNARCHIVE_GROUP.REQUEST, unArchiveGroup),
    takeEvery(GET_TEACHER_ARCHIVE_GROUPS.REQUEST, getTeacherArchiveGroups),
  ]);
}

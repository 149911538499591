import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { translationText } from '../../helpers';
import { translationType } from '../../types';

class Tos extends Component {
  static propTypes = {
    noonText: translationType.isRequired,
  };

  render() {
    const { noonText } = this.props;

    return (
      <div className="home-container" style={{ background: '#fff' }}>
        <div className="tos-container">
          <div className="terms-of-use">
            <div className="container">
              <div className="terms-of-use-inner">
                {/* <h2> {translationText(noonText, 'tac.noonPlatform')}</h2>
                <h3> {ReactHtmlParser(translationText(noonText, 'tac.preHeading'))} </h3>
                <h4> {ReactHtmlParser(translationText(noonText, 'tac.agreement'))}</h4>

                <h3> {ReactHtmlParser(translationText(noonText, 'tac.description'))} </h3>
                <section>
                  <strong>{translationText(noonText, 'tac.ownerShipHeading')}</strong> <br />
                  <p> {ReactHtmlParser(translationText(noonText, 'tac.ownerShipDesc'))}</p>
                </section> */}

                <h2> {translationText(noonText, 'tac.rules')}</h2>

                <h3> {ReactHtmlParser(translationText(noonText, 'teacherTos.beforeRuleContent1'))} </h3>

                <h4> {ReactHtmlParser(translationText(noonText, 'teacherTos.beforeRuleContent2'))}</h4>

                <section>
                  <strong>{translationText(noonText, 'teacherTos.ruleOneHeading')}</strong>
                  <br />
                  <p>{ReactHtmlParser(translationText(noonText, 'teacherTos.ruleOneContent'))}</p>
                </section>

                <section>
                  <strong>{translationText(noonText, 'teacherTos.ruleTwoHeading')}</strong>
                  <br />
                  <p>{ReactHtmlParser(translationText(noonText, 'teacherTos.ruleTwoContent'))}</p>
                </section>

                <section>
                  <strong>{translationText(noonText, 'teacherTos.ruleThreeHeading')}</strong>
                  <br />
                  <p>{ReactHtmlParser(translationText(noonText, 'teacherTos.ruleThreeContent'))}</p>
                </section>

                <section>
                  <strong>{translationText(noonText, 'teacherTos.ruleFourHeading')}</strong>
                  <br />
                  <p>{ReactHtmlParser(translationText(noonText, 'teacherTos.ruleFourContent'))}</p>
                </section>

                <section>
                  <strong>{translationText(noonText, 'teacherTos.ruleFiveHeading')}</strong>
                  <br />
                  <p>{ReactHtmlParser(translationText(noonText, 'teacherTos.ruleFiveContent'))}</p>
                </section>

                <section>
                  <strong>{translationText(noonText, 'teacherTos.ruleSixHeading')}</strong>
                  <br />
                  <p>{ReactHtmlParser(translationText(noonText, 'teacherTos.ruleSixContent'))}</p>
                </section>

                <section>
                  <strong>{translationText(noonText, 'teacherTos.ruleSevenHeading')}</strong>
                  <br />
                  <p>{ReactHtmlParser(translationText(noonText, 'teacherTos.ruleSevenContent'))}</p>
                </section>


              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  noonText: state.toJS().translation.noonText,
});

export default connect(mapStateToProps)(Tos);

import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash-es';
import { CLASSROOM_CHANNEL, IChannelPropDepedency } from './events';
import { useEvent } from '../../providers/Event';

const uuidv4 = require('uuid/v4');

type IEventManagerProp = {
  dynamicChannelValues: IChannelPropDepedency,
  user: {
    user_id: string | number,
    name: string,
    gender: string,
    profile_pic: string,
  }
};

const EventManager = (prop: IEventManagerProp) => {
  const { dynamicChannelValues, user } = prop;
  const [pubnubChannelToBeSubscribed, setPubnubChannelToBeSubscribed] = useState([]);
  const [agoraChannelToBeSubscribed, setAgoraChannelToBeSubscribed] = useState([]);

  const { pubnub, agoraRtm } = useEvent();

  const setPubnubState = (resolvedChannelsWithPresence) => {
    const { user_id, name, gender, profile_pic } = user;
    let device;
    if (localStorage.temporaryTokenPayload) {
      device = JSON.parse(localStorage.temporaryTokenPayload).device_id;
    }
    const userState = {
      user_id,
      name,
      gender,
      profile_pic,
      my_role: 'teacher',
      device,
      _uuid: uuidv4(),
    };

    pubnub.setState(
      {
        state: userState,
        uuid: userState.user_id,
        channels: resolvedChannelsWithPresence,
      },
      (status) => {
        console.log(status);
      },
    );
  };

  // setting pubnub channels
  useEffect(() => {
    if (!isEmpty(dynamicChannelValues) && pubnub && !pubnubChannelToBeSubscribed.length) {
      const pubnubChannels = [
        CLASSROOM_CHANNEL.chatChannel,
        CLASSROOM_CHANNEL.canvasChannel,
        CLASSROOM_CHANNEL.classroomUnicastChannel,
        CLASSROOM_CHANNEL.slideUnicastChannel,
        CLASSROOM_CHANNEL.clientDataChannel,
        CLASSROOM_CHANNEL.interactiveQuestionChannel,
        CLASSROOM_CHANNEL.raiseHandAudioChannel,
        CLASSROOM_CHANNEL.raiseHandChannel,
        CLASSROOM_CHANNEL.userUnicastChannel,
      ];
      const pubnubChannelsWithPresence = [
        CLASSROOM_CHANNEL.classroomBroadcastChannel,
      ];
      const resolvedChannels = pubnubChannels.map((channel) => channel(dynamicChannelValues));
      const resolvedChannelsWithPresence = pubnubChannelsWithPresence.map((channel) => channel(dynamicChannelValues));
      setPubnubChannelToBeSubscribed(resolvedChannels);

      setPubnubState(resolvedChannelsWithPresence);
      pubnub.subscribe({
        channels: resolvedChannelsWithPresence,
        withPresence: true,
      });
      pubnub.subscribe({ channels: resolvedChannels });
    }
  }, [dynamicChannelValues, pubnub]);

  // setting agora channels
  useEffect(() => {
    if (!isEmpty(dynamicChannelValues) && agoraRtm && !agoraChannelToBeSubscribed.length) {
      // setting pubnub channels
      const agoraChannels = [
        CLASSROOM_CHANNEL.agoraMainChannel,
      ];
      const resolvedChannels = agoraChannels.map((channel) => channel(dynamicChannelValues));
      setAgoraChannelToBeSubscribed(resolvedChannels);
      // eslint-disable-next-line no-unused-vars
      for (const key in resolvedChannels) {
        if (resolvedChannels[key]) {
          agoraRtm.joinChannel(resolvedChannels[key]);
        }
      }
    }
  }, [dynamicChannelValues, agoraRtm]);

  return <div />;
};

export default EventManager;

import { put, call, takeEvery, all } from 'redux-saga/effects';
import { usersApi } from '../restApi';
import { USER_PUSH_TOKEN, USER_PUSH_TOKEN_DELETE } from '../constants';

const userPushTokenApi = data => usersApi.post('userPushToken/', data);
const userPushTokenDeleteApi = data => usersApi.delete('userPushToken/', {}, { data });

function* userPushToken({ payload: token }) {
  try {
    const response = yield call(userPushTokenApi, { token, device: 'browser', app_name: 'noon_student_app' });
    if (response.data.success) {
      yield put({
        type: USER_PUSH_TOKEN.SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({ type: USER_PUSH_TOKEN.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: USER_PUSH_TOKEN.FAILURE, payload: err });
  }
}

function* userPushTokenDelete() {
  try {
    const response = yield call(userPushTokenDeleteApi, { device: 'browser', app_name: 'noon_student_app' });
    if (response.status === 204) {
      yield put({
        type: USER_PUSH_TOKEN_DELETE.SUCCESS,
        payload: false,
      });
    } else {
      yield put({ type: USER_PUSH_TOKEN_DELETE.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: USER_PUSH_TOKEN_DELETE.FAILURE, payload: err });
  }
}

export function* notificationSaga() {
  yield all([
    takeEvery(USER_PUSH_TOKEN.REQUEST, userPushToken),
    takeEvery(USER_PUSH_TOKEN_DELETE.REQUEST, userPushTokenDelete),
  ]);
}

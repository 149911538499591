const calculateCanvasAreaWidth = (flag?: boolean) => {
  if (!window) return 0;
  const w = window.innerWidth > 991 ? (flag ? window.innerWidth : window.innerWidth - 350) : window.innerWidth;
  const h = window.innerWidth > 991 ? window.innerHeight - 130 : window.innerHeight;
  const playerRatio = 16 / 9;
  const widthToHeightRatio = w / h;
  let calculatedWidth = w;
  if (widthToHeightRatio >= playerRatio) {
    calculatedWidth = playerRatio * h;
  }
  return calculatedWidth;
};

export default calculateCanvasAreaWidth;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Column, Select, MultiSelect } from '@noon/atom';

import { first, head, intersectionWith, isEmpty, omit, slice } from 'lodash-es';
import { GET_TEACHER_TAGS_GROUP } from '../../redux/constants';

import { translationText } from '../../helpers';
import curriculumTypes from '../../helpers/curriculumTypes';
import { K12 } from './constants';

function evaluateTargetStudents(data, targetedStudents) {
  if (data.board) {
    return targetedStudents.find((singleItem) => singleItem.key === data.board.curriculum_type);
  }
  if (data.degree) {
    return targetedStudents.find((singleItem) => singleItem.key === data.degree.curriculum_type);
  }
  if (data.test) {
    return targetedStudents.find((singleItem) => singleItem.key === data.test.curriculum_type);
  }
  if (data.skill) {
    return targetedStudents.find((singleItem) => singleItem.key === data.skill.curriculum_type);
  }
  return first(targetedStudents);
}

function evaluateCourseType(data, targetedStudents) {
  const targetedStudentsKey = targetedStudents.key;
  const { courseType } = targetedStudents;
  if (data.skill) {
    return courseType.find((singleItem) => singleItem.curriculumType === `${targetedStudentsKey}_skill`);
  }
  if (data.test) {
    return courseType.find((singleItem) => singleItem.curriculumType === `${targetedStudentsKey}_test_prep`);
  }
  return courseType.find((singleItem) => singleItem.curriculumType === `${targetedStudentsKey}`);
}

// eg => return board for boards
function getKeyFromField(value) {
  switch (value) {
    case 'curriculum':
      return value;
    default:
      return value.slice(0, -1);
  }
}

function CurriculumSelection({ isEdit, formatCurriculum, parentType, parseCurriculum }) {
  const { countryList, selectedCountry } = useSelector((state) => state.toJS().folder.countries);
  const { noonText } = useSelector((state) => state.toJS().translation);

  const groupsV2 = useSelector((state) => state.toJS().groupsV2);
  const {
    grades,
    boards,
    subjects,
    curriculum,
    degrees,
    tests,
    sections,
    majors,
    courses,
    skills,
    chapters,
    topics,
  } = groupsV2;
  const isDarkInput = parentType === 'group';

  const constantFields = {
    targetedStudents: curriculumTypes(parentType),
  };

  const privacy = {
    id: 1,
    name: translationText(noonText, 'groups.createGroupClosedGroupType'),
    value: 'private',
  };

  const [fieldsState, setFieldState] = useState({
    country: selectedCountry,
    privacy,
  });
  const [renderField, setRenderField] = useState([]);
  const [currentQueryParams, setCurrentQueryParams] = useState([]);
  const [targetIds, setTargetIds] = useState({});
  const [targetFields, setTargetFields] = useState([]);
  const dispatch = useDispatch();

  const getTeacherTagsGroupItemList = (queryParams, curriculumType, requestId) => {
    dispatch({
      type: GET_TEACHER_TAGS_GROUP.REQUEST,
      payload: { curriculumType, requestId, ...queryParams },
    });
  };

  const fetchDataForEdit = (courseType, fieldsState) => {
    const tags = { country_id: fieldsState.country.id };
    const queryParams = [{ country_id: fieldsState.country.id }];
    const { curriculumType } = courseType;
    courseType.fields.forEach((singleField, index) => {
      const singleFieldKey = getKeyFromField(singleField);
      getTeacherTagsGroupItemList(tags, curriculumType, singleField);
      tags[`${singleFieldKey}_id`] = fieldsState[singleFieldKey].id;
      if (index < courseType.fields.length - 1) {
        queryParams.push({ [`${singleFieldKey}_id`]: fieldsState[singleFieldKey].id });
      }
    });

    return queryParams;
  };

  const setQueryParamsUniquely = (key, value) => {
    const localParams = [...currentQueryParams];
    let allUnique = true;
    localParams.forEach((singleParam) => {
      if (Object.keys(singleParam)[0] === key) {
        // eslint-disable-next-line no-param-reassign
        singleParam[key] = value;
        allUnique = false;
      }
    });
    if (allUnique) {
      localParams.push({ [key]: value });
    }
    setCurrentQueryParams(localParams);
  };

  const resetPreviousFields = ({ courseType, field, value, fieldsState }) => {
    const localFieldState = { ...fieldsState };
    const currentFieldIndex = courseType.fields.findIndex((singleField) => getKeyFromField(singleField) === field);
    const fieldsToBeRemoved = slice(courseType.fields, currentFieldIndex + 1);

    if (currentFieldIndex >= 0) {
      fieldsToBeRemoved.forEach((singleField) => {
        delete localFieldState[getKeyFromField(singleField)];
      });
    }

    setFieldState({
      ...localFieldState,
      [field]: value,
    });
  };

  const resetTargetFields = (lastValue, targetFields, field, nextField) => {
    const newLastIndex = targetFields.indexOf(`${field}s`);
    const localTargetFields = [...targetFields];

    const fieldsToBeReset = slice(localTargetFields, 1, localTargetFields.length);
    if (!isEmpty(fieldsToBeReset)) {
      fieldsToBeReset.forEach((singleField) => setTargetIds((prevTargetIds) => ({
        ...prevTargetIds,
        [getKeyFromField(singleField)]: [],
      })),
      );
    }
    // localTargetFields = slice(localTargetFields, 1, localTargetFields.length);
    setTargetFields([...slice(targetFields, 0, newLastIndex + 1), nextField]);
  };

  const handleOnChangeFieldValue = (field, value) => {
    let lastValue = Object.assign(fieldsState);
    let nextField = '';
    let nextTargetField = '';
    const isTargetField = lastValue.courseType
      && fieldsState.courseType.targetFields
      && fieldsState.courseType.targetFields.indexOf(`${field}s`) > -1;
    const fieldIndex = fieldsState.courseType
      ? fieldsState.courseType.fields.findIndex((fieldString) => fieldString.indexOf(field) > -1)
      : -1;

    // Reset value if any of below value is changed
    // Single run scenario (function is returned if true)
    if (field === 'country') {
      setCurrentQueryParams([]);
      setRenderField([]);
      setTargetFields([]);
      setFieldState({
        country: value,
        targetedStudents: lastValue.targetedStudents,
        privacy: lastValue.privacy,
      });
      return false;
    }
    // Single run scenario (function is returned if true)
    if (field === 'targetedStudents') {
      setCurrentQueryParams([]);
      setTargetFields([]);
      setFieldState({
        country: lastValue.country,
        privacy: lastValue.privacy,
        targetedStudents: value,
      });
      setRenderField([]);
      return false;
    }

    if (field === 'courseType') {
      lastValue = {
        country: lastValue.country,
        privacy: lastValue.privacy,
        targetedStudents: lastValue.targetedStudents,
      };

      nextField = head(value.fields);
      nextTargetField = head(value.targetFields);
      setRenderField([nextField]);
      setCurrentQueryParams([{ country_id: lastValue.country.id }]);
      getTeacherTagsGroupItemList({ country_id: lastValue.country.id }, value.curriculumType, nextField);
      if (value.targetFields) {
        setTargetFields([nextTargetField]);
        getTeacherTagsGroupItemList({ country_id: lastValue.country.id }, null, nextTargetField);
      } else {
        setTargetFields([]);
      }
    } else if (isTargetField) {
      const ids = value.map((field) => field.id);
      setTargetIds({
        ...targetIds,
        [field]: ids,
      });

      const currentTargetFieldIndex = fieldsState.courseType.targetFields.indexOf(`${field}s`);
      if (currentTargetFieldIndex !== fieldsState.courseType.targetFields.length - 1 && !isEmpty(ids)) {
        const queryParams = Object.assign({}, ...currentQueryParams.slice(0, fieldIndex + 1));
        lastValue = omit(fieldsState, [nextField.replace(/s$/, '')]);
        nextField = lastValue.courseType.targetFields[currentTargetFieldIndex + 1];
        resetTargetFields(lastValue, targetFields, field, nextField);
        // setTargetFields([...targetFields, nextField]);
        getTeacherTagsGroupItemList(
          { ...queryParams, country_id: lastValue.country.id, [`${field}_id`]: ids },
          null,
          nextField,
        );
      }
      // if (fieldsState.courseType.targetFields.length !== targetFields.length) {
      //   const queryParams = Object.assign({}, ...currentQueryParams.slice(0, fieldIndex + 1));
      //   lastValue = omit(fieldsState, [nextField.replace(/s$/, '')]);
      //   nextField = lastValue.courseType.targetFields[targetFields.length];
      //   setTargetFields([...targetFields, nextField]);
      //   getTeacherTagsGroupItemList(
      //     { ...queryParams, country_id: lastValue.country.id, [`${field}_id`]: ids },
      //     null,
      //     nextField,
      //   );
      // }
    } else if (fieldIndex > -1 && lastValue.courseType) {
      //  && fieldsState.courseType.fields.length !== renderField.length
      nextField = fieldsState.courseType.fields[fieldIndex + 1];
      if (!fieldsState[field]) {
        setRenderField([...renderField, nextField]);
      } else {
        setRenderField([...renderField.slice(0, fieldIndex + 1), nextField]);
      }
      if (nextField) {
        const queryParams = Object.assign({}, ...currentQueryParams.slice(0, fieldIndex + 1));
        lastValue = omit(fieldsState, [nextField.replace(/s$/, '')]);
        getTeacherTagsGroupItemList(
          { ...queryParams, [`${field}_id`]: value.id },
          lastValue.courseType.curriculumType,
          nextField,
        );
      }
    }

    // value can be an array(for multiselect) or a single value
    // Common logic (needs to run at every instance)
    // If not target field and value exist
    if (!isTargetField && fieldIndex > -1 && (value.id || Array.isArray(value))) {
      const key = Array.isArray(value) ? `${field}_ids` : `${field}_id`;
      const v = Array.isArray(value) ? value.map((field) => field.id) : value.id;
      setQueryParamsUniquely(key, v);
    }
    if (!isTargetField) {
      resetPreviousFields({
        courseType: field === 'courseType' ? value : fieldsState.courseType,
        field,
        value,
        fieldsState: lastValue,
      });
    }
    return true;
  };

  // 1. Used for editing group or question 2. Initialize state and fetch required data
  useEffect(() => {
    if (parseCurriculum && isEdit) {
      let initialEditState = parseCurriculum();
      const targetedStudents = evaluateTargetStudents(initialEditState, constantFields.targetedStudents);

      const courseType = evaluateCourseType(initialEditState, targetedStudents);
      initialEditState = { ...initialEditState, targetedStudents, courseType };
      const queryParams = fetchDataForEdit(courseType, initialEditState);

      setFieldState(initialEditState);
      setRenderField([...renderField, ...courseType.fields]);
      setCurrentQueryParams(queryParams);
    }
  }, []);

  // Can be expensive
  useEffect(() => {
    formatCurriculum({
      fieldsState,
      targetIds,
      currentQueryParams,
      renderField,
      curriculumTypes: constantFields.targetedStudents,
    });
  }, [JSON.stringify({ fieldsState, targetIds, currentQueryParams, renderField })]);

  // Auto select first board
  useEffect(() => {
    if (
      boards
      && boards.list.length > 0
      && !boards.loading
      && !isEdit
      && fieldsState.courseType
      && fieldsState.courseType.curriculumType === K12
    ) {
      handleOnChangeFieldValue('board', boards.list[0]);
    }
  }, [boards]);

  return (
    <Column nowrap gap="sm" className="curriculum-selection">
      <Select
        selectedItem={fieldsState.country}
        name="country"
        onSelect={(value) => handleOnChangeFieldValue('country', value)}
        label={translationText(noonText, 'label.selectCountry')}
        title={translationText(noonText, 'placeholder.selectCountry')}
        list={countryList}
        dark={isDarkInput}
      />
      <Select
        selectedItem={fieldsState.targetedStudents}
        onSelect={(value) => handleOnChangeFieldValue('targetedStudents', value)}
        label={translationText(noonText, 'label.targetedStudents')}
        title={translationText(noonText, 'placeholder.targetedStudents')}
        list={constantFields.targetedStudents}
        dark={isDarkInput}
      />
      {fieldsState.targetedStudents && (
        <Select
          selectedItem={fieldsState.courseType}
          onSelect={(value) => handleOnChangeFieldValue('courseType', value)}
          label={translationText(noonText, 'label.courseType')}
          title={translationText(noonText, 'placeholder.courseType')}
          list={fieldsState.targetedStudents.courseType}
          dark={isDarkInput}
        />
      )}
      {renderField.includes('boards') && boards && (
        <Select
          isLoading={boards.loading}
          selectedItem={fieldsState.board}
          onSelect={(value) => handleOnChangeFieldValue('board', value)}
          label={translationText(noonText, 'label.selectBoard')}
          title={translationText(noonText, 'placeholder.selectBoard')}
          list={boards.list}
          disabled={isEmpty(boards.list)}
          dark={isDarkInput}
        />
      )}

      {renderField.includes('grades') && grades && (
        <Select
          isLoading={grades.loading}
          selectedItem={fieldsState.grade}
          onSelect={(value) => handleOnChangeFieldValue('grade', value)}
          label={translationText(noonText, 'label.selectGrade')}
          title={translationText(noonText, 'placeholder.selectGrade')}
          list={grades.list}
          disabled={isEmpty(grades.list)}
          dark={isDarkInput}
        />
      )}

      {renderField.includes('skills') && skills && (
        <Select
          isLoading={skills.loading}
          selectedItem={fieldsState.skill}
          onSelect={(value) => handleOnChangeFieldValue('skill', value)}
          label={translationText(noonText, 'label.selectSkill')}
          title={translationText(noonText, 'placeholder.selectSkill')}
          list={skills.list}
          disabled={isEmpty(skills.list)}
          dark={isDarkInput}
        />
      )}

      {renderField.includes('tests') && tests && (
        <Select
          isLoading={tests.loading}
          selectedItem={fieldsState.test}
          onSelect={(value) => handleOnChangeFieldValue('test', value)}
          label={translationText(noonText, 'label.selectTest')}
          title={translationText(noonText, 'placeholder.selectTest')}
          list={tests.list}
          disabled={isEmpty(tests.list)}
          dark={isDarkInput}
        />
      )}

      {renderField.includes('degrees') && degrees && (
        <Select
          isLoading={degrees.loading}
          selectedItem={fieldsState.degree}
          onSelect={(value) => handleOnChangeFieldValue('degree', value)}
          label={translationText(noonText, 'label.selectDegree')}
          title={translationText(noonText, 'placeholder.selectDegree')}
          list={degrees.list}
          disabled={isEmpty(degrees.list)}
          dark={isDarkInput}
        />
      )}

      {renderField.includes('majors') && majors && (
        <Select
          isLoading={majors.loading}
          selectedItem={fieldsState.major}
          onSelect={(value) => handleOnChangeFieldValue('major', value)}
          label={translationText(noonText, 'label.selectMajor')}
          title={translationText(noonText, 'placeholder.selectMajor')}
          list={majors.list}
          disabled={isEmpty(majors.list)}
          dark={isDarkInput}
        />
      )}

      {renderField.includes('courses') && courses && (
        <Select
          isLoading={courses.loading}
          selectedItem={fieldsState.course}
          onSelect={(value) => handleOnChangeFieldValue('course', value)}
          label={translationText(noonText, 'label.selectCourse')}
          title={translationText(noonText, 'placeholder.selectCourse')}
          list={courses.list}
          disabled={isEmpty(courses.list)}
          dark={isDarkInput}
        />
      )}

      {renderField.includes('subjects') && subjects && (
        <Select
          isLoading={subjects.loading}
          selectedItem={fieldsState.subject}
          onSelect={(value) => handleOnChangeFieldValue('subject', value)}
          label={translationText(noonText, 'label.selectSubject')}
          title={translationText(noonText, 'placeholder.selectSubject')}
          list={subjects.list}
          disabled={isEmpty(subjects.list)}
          dark={isDarkInput}
        />
      )}

      {targetFields.includes('boards') && parentType === 'group' && boards && (
        <MultiSelect
          isLoading={boards.loading}
          selectedItem={intersectionWith(boards.list, targetIds.board, (boardObj, boardId) => boardObj.id === boardId)}
          onSelect={(selectedItem, selectedArray) => handleOnChangeFieldValue('board', selectedArray)}
          label={translationText(noonText, 'label.selectMultipleBoard')}
          title={translationText(noonText, 'placeholder.selectBoard')}
          list={boards.list}
          disabled={isEmpty(boards.list)}
          dark={isDarkInput}
        />
      )}
      {targetFields.includes('grades') && parentType === 'group' && grades && !isEmpty(targetIds.board) && (
        <MultiSelect
          isLoading={grades.loading}
          selectedItem={intersectionWith(grades.list, targetIds.grade, (gradeObj, gradeId) => gradeObj.id === gradeId)}
          onSelect={(selectedItem, selectedArray) => handleOnChangeFieldValue('grade', selectedArray)}
          label={translationText(noonText, 'label.selectMultipleGrade')}
          title={translationText(noonText, 'placeholder.selectGrade')}
          list={grades.list}
          disabled={isEmpty(grades.list)}
          dark={isDarkInput}
        />
      )}

      {renderField.includes('sections') && parentType === 'group' && sections && (
        <MultiSelect
          isLoading={sections.loading}
          selectedItem={fieldsState.section}
          onSelect={(selectedItem, selectedArray) => handleOnChangeFieldValue('section', selectedArray)}
          label={translationText(noonText, 'label.selectSection')}
          title={translationText(noonText, 'placeholder.selectSection')}
          list={sections.list}
          disabled={isEmpty(sections.list)}
          dark={isDarkInput}
        />
      )}

      {renderField.includes('sections') && parentType === 'question' && sections && (
        <Select
          isLoading={sections.loading}
          selectedItem={fieldsState.section}
          onSelect={(value) => handleOnChangeFieldValue('section', value)}
          label={translationText(noonText, 'label.selectSection')}
          // title={translationText(noonText, 'placeholder.selectSection')}
          list={sections.list}
          disabled={isEmpty(sections.list)}
          dark={isDarkInput}
        />
      )}

      {renderField.includes('curriculum') && curriculum && parentType === 'group' && (
        <MultiSelect
          isLoading={curriculum.loading}
          selectedItem={fieldsState.curriculum}
          onSelect={(selectedItem, selectedArray) => handleOnChangeFieldValue('curriculum', selectedArray)}
          label={translationText(noonText, 'label.selectCurriculum')}
          title={translationText(noonText, 'placeholder.selectCurriculum')}
          list={curriculum.list}
          disabled={isEmpty(curriculum.list)}
          dark={isDarkInput}
        />
      )}

      {renderField.includes('curriculum') && curriculum && parentType === 'question' && (
        <Select
          isLoading={curriculum.loading}
          selectedItem={fieldsState.curriculum}
          onSelect={(value) => handleOnChangeFieldValue('curriculum', value)}
          label={translationText(noonText, 'label.selectCurriculum')}
          title={translationText(noonText, 'placeholder.selectCurriculum')}
          list={curriculum.list}
          disabled={isEmpty(curriculum.list)}
          dark={isDarkInput}
        />
      )}
      {renderField.includes('chapters') && chapters && (
        <Select
          isLoading={chapters.loading}
          selectedItem={fieldsState.chapter}
          onSelect={(value) => handleOnChangeFieldValue('chapter', value)}
          label={translationText(noonText, 'label.selectChapter')}
          title={translationText(noonText, 'placeholder.selectChapter')}
          list={chapters.list}
          disabled={isEmpty(chapters.list)}
          dark={isDarkInput}
        />
      )}
      {renderField.includes('topics') && topics && (
        <Select
          isLoading={topics.loading}
          selectedItem={fieldsState.topic}
          onSelect={(value) => handleOnChangeFieldValue('topic', value)}
          label={translationText(noonText, 'library.topic')}
          title={translationText(noonText, 'library.topic')}
          list={topics.list}
          disabled={isEmpty(topics.list)}
          dark={isDarkInput}
        />
      )}
    </Column>
  );
}

CurriculumSelection.propTypes = {
  formatCurriculum: PropTypes.func.isRequired,
  parseCurriculum: PropTypes.func.isRequired,
  parentType: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default CurriculumSelection;

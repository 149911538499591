import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { IconCompetitionO, IconRating1, IconRating1Active, IconRating2, IconRating2Active, IconRating3, IconRating3Active, IconRating4, IconRating4Active, IconRating5, IconRating5Active, IconSessionO } from '@noon/quark';
import { Modal, Button, Column, Row, SquircleIcon } from '@noon/atom';
import { OTHER_PROBLEMS } from '../../constants';
import t from '../../helpers/translate';
import { VOTE_TEACHER, CONFIG_REASON } from '../../redux/constants';
import { errorType, classType, reasonListType } from '../../types';
import { addToast, TOAST_TYPE } from '../Toast';

export class RatingModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rating: 0,
      selectedReasons: [],
      otherReason: '',
      noRating: false,
      noChooseReason: false,
      noWriteReason: false,
      isVoting: false,
    };

    this.ratingList = [
      {
        id: 1,
        name: 'disaster',
        icon: IconRating1,
        iconActive: IconRating1Active,
      },
      {
        id: 2,
        name: 'bad',
        icon: IconRating2,
        iconActive: IconRating2Active,
      },
      {
        id: 3,
        name: 'normal',
        icon: IconRating3,
        iconActive: IconRating3Active,
      },
      {
        id: 4,
        name: 'good',
        icon: IconRating4,
        iconActive: IconRating4Active,
      },
      {
        id: 5,
        name: 'excellent',
        icon: IconRating5,
        iconActive: IconRating5Active,
      },
    ];

    this.classTypes = {
      competition: IconCompetitionO,
      group: IconSessionO,
      private: IconSessionO,
    };
  }

  componentDidMount() {
    const { reasons, getReasons } = this.props;
    if (!reasons.length) {
      getReasons();
    }
  }

  componentWillReceiveProps({ tutoringError, voteTeacherResponse }) {
    if (voteTeacherResponse !== this.props.voteTeacherResponse && voteTeacherResponse.success) {
      this.setState({ isVoting: false });
      this.props.exitClass({ isRatingCompleted: true });
    }
    if (tutoringError && tutoringError.voteTeacher) {
      addToast('Network error', TOAST_TYPE.ERROR);
    }
  }

  checkReason = (reason) => {
    const { selectedReasons } = this.state;
    const index = selectedReasons.findIndex((o) => o.id === reason.id);
    if (index !== -1) {
      this.setState({
        selectedReasons: selectedReasons.filter((o) => o.id !== reason.id),
      });
    } else {
      this.setState({ selectedReasons: [...selectedReasons, reason] });
    }
  };

  vote = () => {
    const { sessionData, voteTeacher } = this.props;
    const { rating, otherReason, isVoting, selectedReasons } = this.state;

    if (isVoting) return;
    this.setState({ noChooseReason: false, noWriteReason: false, noRating: false, isVoting: false }, () => {
      if (!rating) {
        this.setState({ noRating: true });
        return;
      }
      if (selectedReasons.findIndex((o) => ['teacher-negative'].includes(o.sub_type)) === -1 && rating < 4) {
        this.setState({ noChooseReason: true });
        return;
      }
      if (selectedReasons.findIndex((o) => o.message === OTHER_PROBLEMS) !== -1 && !otherReason) {
        this.setState({ noWriteReason: true });
        return;
      }
      voteTeacher({
        tutoring_session_id: sessionData.id,
        reason: otherReason.length
          ? [...selectedReasons.filter((o) => o.message !== OTHER_PROBLEMS), { id: 0, message: otherReason }]
          : selectedReasons,
        rating,
      });
    });
  };

  handleUserInput = (ev) => {
    const { value } = ev.target;
    this.setState({ otherReason: value });
  };

  selectRating = (rating) => {
    const { selectedReasons } = this.state;
    this.setState({ rating, noRating: false, selectedReasons: rating > 3 ? [] : selectedReasons });
  };

  render() {
    const { sessionData = {}, reasons } = this.props;
    const { rating, noWriteReason, noChooseReason, noRating, isVoting, selectedReasons, otherReason } = this.state;
    return (
      <Modal>
        <Column nowrap align="center" className="rating-modal">
          <SquircleIcon icon={this.classTypes[sessionData.class_type]} className="header-icon" />
          <h3>{t('heading', 'teacherRatingTitle')}</h3>
          <div className="modal-body">
            <Row nowrap justify="center" className="rating-wrapper">
              {this.ratingList.map((item) => (
                <Column
                  nowrap
                  align="center"
                  className="rating-item"
                  key={item.id}
                  onClick={() => this.selectRating(item.id)}
                >
                  {item.id === rating ? (
                    <item.iconActive
                      height="50px"
                      width="50px"
                      fill="#333"
                    />
                  ) : (
                    <item.icon
                      height="50px"
                      width="50px"
                      fill="#a1a1a1"
                    />
                  )}
                  <span>{t('label', item.name)}</span>
                </Column>
              ))}
            </Row>
            {noRating && <p className="errorMessage">{t('tutoring', 'pleaseVote')}</p>}

            <div className="reason-container">
              <div className="comments-container">
                {!!rating && rating > 3 && (
                  <div className="comments-group noon-comments">
                    {reasons
                      .filter((o) => o.sub_type === 'teacher-positive')
                      .map((reason, index) => (
                        <div className="comment-item" key={index}>
                          <input
                            type="checkbox"
                            id={`tp-${index}`}
                            name="teacher-positive[]"
                            checked={selectedReasons.findIndex((o) => o.id === reason.id) !== -1}
                            onChange={() => this.checkReason(reason)}
                          />
                          <label htmlFor={`tp-${index}`}>{reason.message}</label>
                        </div>
                      ))}
                  </div>
                )}
                {!!rating && rating < 4 && (
                  <div className="comments-group noon-comments">
                    {reasons
                      .filter((o) => o.sub_type === 'teacher-negative')
                      .map((reason, index) => (
                        <div className="comment-item" key={index}>
                          <input
                            type="checkbox"
                            id={`tn-${index}`}
                            name="teacher-negative[]"
                            checked={selectedReasons.findIndex((o) => o.id === reason.id) !== -1}
                            onChange={() => this.checkReason(reason)}
                          />
                          <label htmlFor={`tn-${index}`}>{reason.message}</label>
                        </div>
                      ))}
                    <div className="comment-item">
                      <input
                        type="checkbox"
                        id="tn-other"
                        name="teacher-negative[]"
                        checked={selectedReasons.findIndex((o) => o.id === 'tn0') !== -1}
                        onChange={() => this.checkReason({ id: 'tn0', message: OTHER_PROBLEMS })}
                      />
                      <label htmlFor="tn-other">{localStorage.language === 'en' ? 'Others' : OTHER_PROBLEMS}</label>
                    </div>
                  </div>
                )}
              </div>
              {noChooseReason && <p className="errorMessage">{t('tutoring', 'chooseReason')}</p>}
              {!!rating && rating < 4 && (
                <textarea
                  className={classNames({ error: noWriteReason })}
                  name="other-reasons"
                  id="other-reasons1"
                  rows="10"
                  value={otherReason}
                  onChange={this.handleUserInput}
                  placeholder={t('tutoring', 'commentHere')}
                />
              )}
              {noWriteReason && <p className="errorMessage">{t('tutoring', 'writeReason')}</p>}
            </div>
          </div>
          <footer className="modal-footer">
            <Button size="lg" depth="2" type="primary" onClick={this.vote} disabled={isVoting} loading={isVoting}>
              {t('tutoring', 'sendVote')}
            </Button>
          </footer>
        </Column>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  noonText: state.toJS().translation.noonText,
  tutoringError: state.toJS().tutoring.error,
  voteTeacherResponse: state.toJS().tutoring.voteTeacher,
  reasons: state.toJS().tutoring.reasons,
});

const mapDispatchToProps = (dispatch) => ({
  voteTeacher: (data) => dispatch({ type: VOTE_TEACHER.REQUEST, payload: data }),
  getReasons: () => dispatch({ type: CONFIG_REASON.REQUEST }),
});

RatingModal.propTypes = {
  tutoringError: errorType.isRequired,
  sessionData: classType.isRequired,
  voteTeacherResponse: PropTypes.shape().isRequired,
  voteTeacher: PropTypes.func.isRequired,
  exitClass: PropTypes.func.isRequired,
  reasons: reasonListType.isRequired,
  getReasons: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RatingModal);

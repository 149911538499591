import { createSelector } from 'reselect';

const postSelectorRaw = state => state.toJS().posts;
const allPostsSelectorRaw = state => state.toJS().posts.allPosts.response;
const likePostSelectorRaw = state => state.toJS().posts.likePost.response;

const likeCommentSelectorRaw = state => state.toJS().posts.likeComment.response;
const commentsForPinnedPostsSelectorRaw = state => state.toJS().posts.commentsForPinnedPosts.response;
const commentsForAllPostsSelectorRaw = state => state.toJS().posts.commentsByPost.response;
const queueForCommentSelectorRaw = state => state.toJS().posts.queueForComment;
const queueForPostSelectorRaw = state => state.toJS().posts.queueForPost;

const pinPostsSelector = createSelector(
  postSelectorRaw,
  item => item.pinnedPostsForGroup.response,
);

const getCommentsByPostIdSelector = state =>
  state.toJS().posts.commentsByPost.response || {};

const commentsOfApostByIdSelector = createSelector(
  getCommentsByPostIdSelector,
  item => item,
);

const loadingStateOfCommentSelector = state =>
  state.toJS().posts.commentsByPost.isLoading;
// const loadMoreCommentsByIdSelector = createSelector(
//   loadingStateOfComment,
//   item => item,
// );

const likePostSelector = createSelector(
  likePostSelectorRaw,
  item => item,
);

const likeCommentSelector = createSelector(
  likeCommentSelectorRaw,
  item => item,
);

const allPostsSelector = createSelector(
  allPostsSelectorRaw,
  item => item,
);

const commentsForPinnedPostsSelector = createSelector(
  commentsForPinnedPostsSelectorRaw,
  item => item,
);

const commentsForAllPostsSelector = createSelector(
  commentsForAllPostsSelectorRaw,
  item => item,
);

const queueForCommentSelector = createSelector(
  queueForCommentSelectorRaw,
  item => item,
);

const queueForPostSelector = createSelector(
  queueForPostSelectorRaw,
  item => item,
);

export {
  pinPostsSelector,
  allPostsSelector,
  commentsForPinnedPostsSelector,
  commentsForAllPostsSelector,
  queueForCommentSelector,
  queueForPostSelector,
  likePostSelector,
  likeCommentSelector,
  loadingStateOfCommentSelector,
  getCommentsByPostIdSelector,
  commentsOfApostByIdSelector,
};

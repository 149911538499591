import './create-question-modal.scss';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash-es/isEmpty';
import { isIPad13, isMobile } from 'react-device-detect';
import { IconDelete, IconPlus, IconEyeO } from '@noon/quark';
import { Column, Row, Button, Radio, Checkbox } from '@noon/atom';
import classNames from 'classnames';
import { get, isEqual, map, uniqBy } from 'lodash-es';

import { CREATE_QUESTION } from '../../redux/constants';
import { ARABIC_TEXT_PATTERN } from '../../constants';
import htmlParser from '../../helpers/htmlParser';
import NoonEditor from './noon-editor';
import { addToast, TOAST_TYPE } from '../../components/Toast';
import t from '../../helpers/translate';
import PreviewQuestionModal from './question-preview-modal';

import { defaultChoices, defaultQuestion } from '../../constants/question-defaults';
import { getTagIds } from '../../helpers/curriculumTags';

export const QuestionContent = ({
  type = null,
  groupId = null,
  questionId = null,
  isSubmitted,
  question,
  validator,
  choices,
  isLoading,
  selectedCurriculum,
  difficultyLevel,
  setChoices,
  setQuestion,
  setValidator,
  setIsLoading,
  setIsSubmitted,
  setIsCreateAnotherQuestionChecked = null,
  toggleSidebar = null,
  hideAnotherQuestionCheckbox,
  handleSelectedTopic = null,
}) => {
  const dispatch = useDispatch();

  const [activeEditor, setActiveEditor] = useState('question-1');
  const [previewQuestion, setPreviewQuestion] = useState({
    showPreviewQuestion: false,
    question: defaultQuestion.question,
    question_json: htmlParser(defaultQuestion.question),
    choices: defaultChoices,
  });
  const [showLatexKeyboard, setShowLatexKeyboard] = useState(false);

  const { questionData: questionApiResponse } = useSelector((state) => state.toJS().question);

  const handleOnClickClosePreviewQuestion = () => {
    setPreviewQuestion((prevPreviewQuestion) => ({
      ...prevPreviewQuestion,
      showPreviewQuestion: false,
    }));
  };

  const toggleActiveEditor = (activeEditor) => {
    setActiveEditor((prevActiveEditor) => {
      if (activeEditor !== prevActiveEditor) {
        return activeEditor;
      }
      return prevActiveEditor;
    });
  };

  const handleUpdate = (selector, value) => {
    const idz = selector.split('-');
    if (idz[0] === 'choice' && idz.length >= 2) {
      const index = Number(idz[1]);
      setChoices((prevChoices) => [
        ...prevChoices.slice(0, index),
        { ...prevChoices[index], answer: value },
        ...prevChoices.slice(index + 1),
      ]);
    } else if (idz[0] === 'question') {
      setQuestion((prevQuestion) => ({ ...prevQuestion, question: value }));
    }
  };

  const removeChoice = (index) => {
    if (isLoading) return;
    if (choices.length > 2) {
      setChoices((prevChoices) => [...prevChoices.slice(0, index), ...prevChoices.slice(index + 1)]);
    }
    setShowLatexKeyboard(false);
  };

  const addChoice = () => {
    if (isLoading) return;
    if (choices.length < 4) {
      setChoices((prevChoices) => [...prevChoices, { is_correct: 0, answer: '', answer_json: [] }]);
    }
  };

  const handleCorrect = (index) => {
    if (isLoading) return;
    setChoices((prevChoices) =>
      prevChoices.map((choice, choiceIndex) =>
        choiceIndex === index ? { ...choice, is_correct: 1 } : { ...choice, is_correct: 0 }
      )
    );
  };

  const validate = (questionPayload) => {
    const { chapter = {}, topic = {}, country = {} } = selectedCurriculum;
    
    const validator = {
      isCorrect: false,
      choice: 0,
      question: false,
      curriculum: false,
      questionUpdated: false,
      duplicateChoice: false,
      incorrectQuestionFormat: false,
      incorrectChoicesFormat: false,
    };

    if (questionPayload.question?.question?.trim()) {
      validator.question = true;
    }
    choices.map((item) => {
      if (item.is_correct) {
        validator.isCorrect = true;
      }
      if (item.answer && item.answer.trim()) {
        validator.choice += 1;
      }
    });
    if (uniqBy(choices, 'answer').length !== choices.length) {
      validator.duplicateChoice = true;
    }
    if ((question.id && !isEmpty(question.all_tag_details)) || (questionPayload.tags && chapter.id && topic.id && country.id)) {
      validator.curriculum = true;
    }

    if (
      questionApiResponse.difficulty !== questionPayload?.question?.difficulty ||
      questionApiResponse.question !== questionPayload?.question?.question ||
      get(questionApiResponse, ['all_tag_details', 0, 'tags']) !==  questionPayload.tags ||
      !isEqual(choices, questionApiResponse.choices)
    ) {
      validator.questionUpdated = true;
    }

    if (isEmpty(questionPayload.question.question_json)) {
      validator.incorrectQuestionFormat = true;
    }
    questionPayload.choices.forEach((choice) => {
      if (isEmpty(choice.answer_json)) {
        validator.incorrectChoicesFormat = true;
      }
    });

    setValidator(validator);

    return (
      !validator.duplicateChoice &&
      validator.questionUpdated &&
      validator.isCorrect &&
      validator.question &&
      validator.choice >= 2 &&
      validator.curriculum &&
      !validator.incorrectChoicesFormat &&
      !validator.incorrectQuestionFormat
    );
  };

  const createQuestionPayload = () => {
    const dottedTagIds = !isEmpty(selectedCurriculum) ?
      selectedCurriculum.tags?.length > 0
        ? map(selectedCurriculum.tags, 'id').join('.')
        : getTagIds(selectedCurriculum) : null;

    return {
      question: {
        ...question,
        text_direction: ARABIC_TEXT_PATTERN.test(question.question) ? 'rtl' : 'ltr',
        question_json: htmlParser(question.question),
        difficulty: difficultyLevel.value,
      },
      choices: choices
        .filter((item) => item.answer)
        .map((choice) => ({ ...choice, answer_json: htmlParser(choice.answer) })),
      country_id: selectedCurriculum.country?.id,
      chapter_id: selectedCurriculum.chapter?.id,
      topic_id: selectedCurriculum.topic?.id,
      tags: dottedTagIds,
    };
  }
  const saveQuestion = () => {
    const questionPayload = createQuestionPayload();

    if (validate(questionPayload)) {
      setIsLoading(true);

      dispatch({ type: CREATE_QUESTION.REQUEST, payload: questionPayload });
    }
    setIsSubmitted(true);
    if (previewQuestion.showPreviewQuestion) {
      handleOnClickClosePreviewQuestion();
    }
  };

  const handleOnClickPreviewQuestion = () => {
    const questionPayload = createQuestionPayload();
    setIsSubmitted(true);

    if (!validate(questionPayload)) return;

    setPreviewQuestion({
      showPreviewQuestion: true,
      question: question.question,
      question_json: htmlParser(question.question),
      choices: choices
        .filter((item) => item.answer)
        .map((choice) => ({ ...choice, answer_json: htmlParser(choice.answer) })),
    });
  };

  return (
    <>
      <Column nowrap flex="1" className="create-question__content">
        <Row nowrap align="center">
          <h4 className="input__title flex-1">{t('library', 'enterQuestionTitle')}</h4>
          {(isMobile || isIPad13) && (
            <p
              className={classNames(isSubmitted ? 'text-color-red animated wobble fast' : 'text-color-primary')}
              onClick={toggleSidebar}
            >
              {t('library', 'assignQuestionFor')}
            </p>
          )}
        </Row>
        <NoonEditor
          id="question-1"
          className="expanded"
          placeholder={t('library', 'questionPlaceholder')}
          onClick={() => toggleActiveEditor('question-1')}
          active={activeEditor === 'question-1'}
          value={question.question}
          onChange={handleUpdate}
          showLatexKeyboard={showLatexKeyboard}
          onChangeLatexKeyboardState={(state) => setShowLatexKeyboard(state)}
        />

        <Row nowrap gap align="center" className="create-question__choice-header">
          <h4 className="input__title flex-1">{t('library', 'choicesTitle')}</h4>
          {choices.length > 2 && <p className="child blank" />}
        </Row>

        {choices.map((choice, index) => (
          <Row key={index} nowrap gap align="center" className="create-question__choice-item">
            <NoonEditor
              id={`choice-${index}`}
              placeholder={`${t('library', 'choicePlaceholder')} ${index + 1}`}
              value={choice.answer}
              onClick={() => toggleActiveEditor(`choice-${index}`)}
              active={activeEditor === `choice-${index}`}
              onChange={handleUpdate}
              showLatexKeyboard={showLatexKeyboard}
              onChangeLatexKeyboardState={(state) => setShowLatexKeyboard(state)}
            />
            <Radio
              readOnly={isLoading}
              name="choice"
              className="child"
              checked={choice.is_correct}
              onChange={() => handleCorrect(index)}
            />
            {choices.length > 2 && <IconDelete stroke="#BBB8B3" fill="#BBB8B3" onClick={() => removeChoice(index)} />}
          </Row>
        ))}
        <Column align="start">
          {isSubmitted && !validator.question && (
            <p className="error-text">{t('library', 'createQuestionTextError')}</p>
          )}

          {isSubmitted && validator.choice < 2 && (
            <p className="error-text flex-1">{t('library', 'createChoiceError')}</p>
          )}
          {isSubmitted && !validator.isCorrect && (
            <p className="error-text">{t('library', 'createChoiceCorrectError')}</p>
          )}
          {isSubmitted && validator.duplicateChoice && (
            <p className="error-text flex-1">{t('library', 'createChoiceDuplicateError')}</p>
          )}
          {isSubmitted && !validator.curriculum && (
            <p className="error-text flex-1">{t('library', 'createSelectTopicError')}</p>
          )}
          {isSubmitted && !validator.questionUpdated && (
            <p className="error-text flex-1">{t('error', 'sameContent')}</p>
          )}
          {isSubmitted && validator.incorrectQuestionFormat && (
            <p className="error-text flex-1">{t('library', 'incorrectQuestionFormat')}</p>
          )}
          {isSubmitted && validator.incorrectChoicesFormat && (
            <p className="error-text flex-1">{t('library', 'incorrectChoicesFormat')}</p>
          )}
        </Column>
        {choices.length < 4 && (
          <Row nowrap align="center" justify="start">
            <Button type="secondary" outlined onClick={addChoice}>
              <IconPlus height="24px" width="24px" fill="#469979" />
              {t('library', 'addMoreOption')}
            </Button>
          </Row>
        )}
        <Row
          nowrap
          align="center"
          justify="end"
          gap="sm"
          className={classNames('w-100 mt-4', { 'editor-padding': showLatexKeyboard })}
        >
          <Button
            type="blue"
            size="xl"
            outlined
            className={classNames('action-btn')}
            onClick={handleOnClickPreviewQuestion}
          >
            <IconEyeO height="24px" width="24px" />
            {t("library", "preview")}
          </Button>
          <Button 
            type="blue" 
            size="xl" 
            className="action-btn" 
            loading={isLoading}
            disabled={choices.length < 2}
            onClick={saveQuestion}>
            <IconPlus />
            {questionId && type !== 'clone'
              ? t('createHomework', 'updateQuestions')
              : t('image2question', 'DialogSave')}
          </Button>
          {!hideAnotherQuestionCheckbox && !questionId && (
            <Checkbox
              onClick={(event) => setIsCreateAnotherQuestionChecked(event.target.checked)}
              type="checkbox"
              label={t('label', 'anotherQuestion')}
              className="chk-anoter-question"
            />
          )}
        </Row>
      </Column>

      {previewQuestion.showPreviewQuestion && (
        <PreviewQuestionModal
          type={type}
          groupId={groupId}
          questionId={questionId}
          handleSelectedTopic={handleSelectedTopic}
          selectedCurriculum={selectedCurriculum}
          questionData={previewQuestion}
          onClose={handleOnClickClosePreviewQuestion}
          isLoading={isLoading}
          saveQuestion={saveQuestion}
        />
      )}
    </>
  );
};

// eslint-disable-next-line consistent-return
export default async function copyToClipboard(text) {
  if (navigator.clipboard) {
    const response = await navigator.clipboard.writeText(text);
    if (response && response.status) return response.status;
  } else {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  }
}

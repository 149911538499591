import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button, Row, Card, Column } from '@noon/atom';
import { IconHelp } from '@noon/quark';
import SmartCarousel from '../Layout/smartCarousel';
import { translationText } from '../../helpers';
import { Accordian } from '../Curriculum';

const name = 'help-categories';

function HelpPage(prop) {
  const { helpPageCategories, filteredQuestions, showFilteredQuestion, noonText, clearSearch } = prop;
  const [selectedCategory, setSelectedCategory] = useState(helpPageCategories[0]);
  const isRtl = document.body.dir === 'rtl';

  useEffect(() => {
    setSelectedCategory(helpPageCategories[0]);
  }, [helpPageCategories]);

  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
    clearSearch();
  };

  const handleContactUsClick = () => {
    window.$zoho.salesiq.chat.start();
  };

  return (
    <Column className="help-page container">
      <Card className="full-width ptb-2 mb-4">
        <Row align="center" justify="start" className="help-page__categories-slider">
          <span>{translationText(noonText, 'help.chooseCategory')}</span>
          <Row
            align="center"
            justify="center"
            className="mtb-2 card-body"
            gap
            style={{ width: '100%', position: 'relative' }}
          >
            <SmartCarousel
              name="help-categories"
              slides={helpPageCategories}
              classname={`noon-${name}-slider`}
              style={{ width: '100%' }}
            >
              {({ item }) => (
                <Row
                  justify="center"
                  align="center"
                  className={`noon-${name}-slider__slide`}
                  style={{ flex: '0 0 auto' }}
                >
                  <Button
                    onClick={() => handleSelectCategory(item)}
                    type="primary"
                    size="lg"
                    outlined
                    className={classNames('mlr-1 text-capitalize', { active: selectedCategory.name === item.name })}
                  >
                    {item.icon && <item.icon />}
                    {item.name}
                  </Button>
                </Row>
              )}
            </SmartCarousel>
          </Row>
        </Row>
        <Column justify="center" align="center" gap="lg" className="help-page__question-answer">
          <h1 className="title">{selectedCategory.name}</h1>
          {(showFilteredQuestion ? filteredQuestions : selectedCategory.questions).map((item, index) => (
            <Accordian key={index} heading={{ label: item.question }} initialState={false}>
              <Column className="details ptb-2">
                <p dangerouslySetInnerHTML={{ __html: item.answer }} />
                {selectedCategory.videos && selectedCategory.videos[index + 1] && (isRtl ? selectedCategory.videos[index + 1].ar : selectedCategory.videos[index + 1].en) && (
                  <iframe
                    className="mt-1"
                    title="create group"
                    width="275"
                    src={selectedCategory.videos[index + 1][isRtl ? 'ar' : 'en']}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                )}
              </Column>
            </Accordian>
          ))}
          <div className="image-wrapper">
            <IconHelp />
          </div>
          <span className="child">{translationText(noonText, 'help.cantFind')}</span>
          <Button
            type="secondary"
            value={translationText(noonText, 'help.contact')}
            className="text-capitalize plr-4"
            onClick={handleContactUsClick}
          />
        </Column>
      </Card>
    </Column>
  );
}

export default HelpPage;

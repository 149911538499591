import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Column, Row, Modal, Button } from '@noon/atom';
import { IconClose } from '@noon/quark';
import { translationText } from '../../../helpers';
import GroupDescriptionWidget from './Widget/GroupDescriptionWidget';
import GroupVideoWidget from './Widget/GroupVideoWidget';
import GroupReviewWidget from './Widget/GroupReviewWidget';
import GroupSchedule from './Widget/GroupSchedule';
import t from '../../../helpers/translate';

function GroupInfo(props) {
  const { isArchived, toggleArchiveModal, user, group, onClose, show, showCreateSchedule, handleOpenCreateSchedule, curriculumExist } = props;
  const noonText = useSelector((state) => state.toJS().translation.noonText);

  const handleOnClickCreateSchedule = () => {
    handleOpenCreateSchedule('group_info');
  };
  return (
    <React.Fragment>
      {!!show && !showCreateSchedule && (
      <Modal onClose={onClose} className="group-info-modal">
        <Row nowrap align="center" className="card-header">
          <h3 className="card-header__title">{translationText(noonText, 'groups.aboutGroup')}</h3>
          <IconClose onClick={onClose} />
        </Row>
        <Row className="group-info-modal__body" gap="lg">
          <Column className="sidebar" gap="lg">
            <GroupDescriptionWidget group={group} isArchived={isArchived} />
            {curriculumExist && <GroupSchedule isArchived={isArchived} noonText={noonText} schedules={group.schedules} handleOnClickCreateSchedule={handleOnClickCreateSchedule} />}
            <GroupVideoWidget group={group} isArchived={isArchived} />
            {isArchived
              ? <Button onClick={toggleArchiveModal} type="secondary" value={t('button', 'unarchive')} className="text-capitalize" />
              : <Button value={t('button', 'archive')} onClick={toggleArchiveModal} type="secondary" className="full-width" />}
          </Column>
          <Column className="content" gap="lg">
            <GroupReviewWidget group={group} user={user} />
          </Column>
        </Row>
      </Modal>
      )}
    </React.Fragment>
  );
}

GroupInfo.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  showCreateSchedule: PropTypes.bool.isRequired,
  handleOpenCreateSchedule: PropTypes.func.isRequired,
  group: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
  curriculumExist: PropTypes.bool.isRequired,
};
GroupInfo.defaultProps = {};

export default GroupInfo;

import React from 'react';
import { hot } from 'react-hot-loader';
import ErrorBoundry from 'components/ErrorBoundry';
import { Redirect, Switch, Route } from 'react-router-dom';
import { ProtectedRoute, GuestRoute, NoonRoute } from 'components/CustomRouter';
import Landing from 'routes/Landing';
import Tos from 'routes/Tos';
import Login from 'routes/Login';
import Signup from 'routes/Signup';
import Logout from 'routes/Logout';
import Classroom from 'routes/Classroom';
import CompleteProfile from 'routes/CompleteProfile';
import Profile from 'routes/Profile';
import Approval from 'routes/Approval';
import GroupHomePage from 'routes/GroupDetail';
import GroupQuestions from 'routes/GroupQuestions';
import NotFound from 'routes/NotFound';
import Notifications from 'components/Notifications';
import Play from './routes/Play';
import { CreateQuestion } from './routes/CreateQuestion';
import CurriculumContent from './routes/CurriculumContent';
import { GeneralPlanner } from './routes/GeneralPlanner';
import Curriculum from './routes/Curriculum';
import Training from './routes/Training';
import Library from './routes/Library';
import GroupMembers from './routes/GroupMembers';
import PrepareAssignment from './routes/prepareAssignment/prepareAssignment';
import ReportPage from './routes/ReportPage/reportPage';
import Payments from './routes/Payments';
import NoonersLogin from './routes/NoonersLogin';
import SvgStyle from './assets/icons/SvgStyle';

import './styles/app.scss';

const RouteManager = () => (
  <div className="page">
    <ErrorBoundry msg="Routes could not be loaded">
      {/* This is hack to add svg clipart and svg gradient colors */}
      <SvgStyle />
      <Switch>
        <GuestRoute exact path="/index.html" render={() => <Redirect to="/" />} />
        <NoonRoute exact path="/:locale" component={Landing} noFooter noHeader />
        <NoonRoute exact path="/:locale/tos" component={Tos} />
        <GuestRoute exact path="/:locale/login" noFooter component={Login} />
        <GuestRoute exact path="/:locale/nooners-login" noFooter component={NoonersLogin} />
        <GuestRoute exact path="/:locale/signup" noFooter component={Signup} />
        <ProtectedRoute exact path="/:locale/home" component={GeneralPlanner} />
        {/* Home Route will change to the home oage once we create it, addinng it for now, since all redirction happens here */}
        <Route
          exact
          path="/:locale/groups"
          render={({ match }) => <Redirect to={`/${match.params.locale}/schedule`} />}
        />
        <ProtectedRoute exact path="/:locale/groups/:groupId/content" noFooter component={CurriculumContent} />
        <ProtectedRoute exact path="/:locale/groups/:groupId/questions" noFooter component={GroupQuestions} />
        <ProtectedRoute exact path="/:locale/groups/:groupId/members" noFooter component={GroupMembers} />
        <ProtectedRoute exact path="/:locale/groups/:groupId" noFooter component={GroupHomePage} />
        <ProtectedRoute exact path="/:locale/schedule" noFooter component={GeneralPlanner} />
        <ProtectedRoute exact path="/:locale/schedule/:tab" noFooter component={GeneralPlanner} />
        <ProtectedRoute exact path="/:locale/library" noFooter component={Library} />
        <ProtectedRoute exact path="/:locale/training" noFooter component={Training} />
        <ProtectedRoute
          exact
          path="/:locale/prepare-assignment/:groupId"
          component={PrepareAssignment}
          noHeader
          noSidebar
        />
        <ProtectedRoute
          exact
          path="/:locale/prepare-assignment/:groupId/:id"
          component={PrepareAssignment}
          noHeader
          noSidebar
        />
        <ProtectedRoute exact path="/:locale/report/:groupId/:id" component={ReportPage} noHeader noSidebar />
        <ProtectedRoute exact path="/:locale/complete-profile" component={CompleteProfile} />
        <ProtectedRoute exact path="/:locale/profile/:edit" component={CompleteProfile} />
        <ProtectedRoute exact path="/:locale/profile" component={Profile} />
        <ProtectedRoute exact path="/:locale/email" component={Notifications} page />
        <ProtectedRoute exact path="/:locale/approval" component={Approval} />
        <ProtectedRoute exact path="/:locale/curriculum" component={Curriculum} noSidebar />
        <ProtectedRoute exact path="/:locale/curriculum/:id" component={Curriculum} noSidebar />
        <ProtectedRoute exact path="/:locale/create-question" component={CreateQuestion} noSidebar />
        <ProtectedRoute exact path="/:locale/edit-question/:id?/:type?" component={CreateQuestion} noSidebar />
        <ProtectedRoute exact path="/:locale/play/:id" component={Play} noHeader noFooter noSidebar />
        <ProtectedRoute exact path="/:locale/logout" component={Logout} />
        <ProtectedRoute exact path="/:locale/class/:id" noHeader noFooter noSidebar component={Classroom} />
        <ProtectedRoute exact path="/:locale/payments" noFooter component={Payments} />
        <ProtectedRoute path="*" noHeader noFooter component={NotFound} />
      </Switch>
    </ErrorBoundry>
  </div>
);

export default hot(module)(RouteManager);

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ClassroomAnalyticsContext from './context';
import { getDuration } from '../../helpers';
import Mixpanel from '../../components/Mixpanel';

const analyticsEvent = {
  mic_action: ['noon'],
  raise_hand_action: ['noon'],
  slide_action: ['noon'],
  question_send: ['noon'],
  teacher_session_end: ['noon', 'mixpanel'],
  raise_hand_recieved: ['noon'],
  teacher_session_start: ['noon'],
  teams_formed: [],
  breakout_triggered: [],
  breakout_ended: [],
  raise_hand_received: ['noon'],
  raisehand_info_viewed: ['noon'],
  missed_hand_raise: ['noon'],
};

function ClassroomAnalyticsProvider({ children }) {

  const logger = useRef(window.LoggingManager.mount({ moduleName: 'classroom' }));

  const sessionDetails = useSelector((state) => state.toJS().myClassroom.sessionDetails);

  const dispatchAnalytics = (eventName: string, props) => {
    if (eventName) {
      const payload = {
        session_id: sessionDetails?.id,
        web_rtc_type: 'agora',
        channel_type: 'pubnub',
        new_classroom: true,
        ...props,
      };
      if (eventName === 'stats') { // special case
        logger.current.track(eventName, {
          s: +sessionDetails?.id,
          as: props,
        });
        return;
      }
      if (eventName === 'teacher_session_end') {
        const duration = getDuration(new Date().toISOString(), sessionDetails?.start_time, ['minutes']);
        payload.group_id = sessionDetails?.group?.id;
        payload.session_type = sessionDetails?.class_type === 'competition' ? 'competition' : 'session';
        payload.session_topic = sessionDetails?.title;
        payload.duration = Math.round(duration);
      }
      if (analyticsEvent[eventName]?.includes('mixpanel')) {
        Mixpanel.track(eventName, payload);
      }
      if (analyticsEvent[eventName]?.includes('noon')) {
        logger.current.track(eventName, {
          classroom: payload,
        });
      }
    }
  };

  return <ClassroomAnalyticsContext.Provider value={dispatchAnalytics}>{children}</ClassroomAnalyticsContext.Provider>;
}

ClassroomAnalyticsProvider.propTypes = {
  children: PropTypes.node,
};

ClassroomAnalyticsProvider.defaultProps = {
  children: null,
};

export default ClassroomAnalyticsProvider;

import { updateSlideConfig } from '../../../redux/actions/whiteboard';
import { INTERACTIVE_QUESTION_START, START_BREAKOUT } from '../../../redux/constants';

export default function handleBreakoutQuestion(dispatch, e) {
  const { currentCube, load_time_duration, start_time = 0, end_time = 0, current_time = Date.now() } = e.message;

  if (currentCube) {
    const questionTimerTimeperiod = Math.round((end_time - start_time) / 1000);
    const secondsElapsedSinceStart = Math.round((current_time - start_time) / 1000);
    const getReadyTimer = secondsElapsedSinceStart > load_time_duration ? 0 : secondsElapsedSinceStart;
    const timeLeft = questionTimerTimeperiod - getReadyTimer;

    const payload = {
      questionTimer: (questionTimerTimeperiod - secondsElapsedSinceStart),
      getReadyTimer,
      slideResource: { ...currentCube, time: timeLeft },
    };
    if (payload.questionTimer) {
      dispatch({ type: INTERACTIVE_QUESTION_START, payload });
      dispatch({ type: START_BREAKOUT });
    }

    dispatch(updateSlideConfig({
      allowDelete: false,
      disableAddSlide: true,
      disableSelectSlide: true,
    }));
  }
}

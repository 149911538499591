import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash-es';
import { Card, Row, Column, Button } from '@noon/atom';
import { IconAdd } from '@noon/quark';
import { ScheduleListSessionCard } from '../../Session';
import { UPCOMING_TEACHER_SESSION } from '../../../redux/constants';
import ViewMoreButton from '../../Layout/viewMoreButton';
import Shimmer from '../../Layout/shimmer';
import { translationText } from '../../../helpers';
import { COLORS } from '../../../constants';

function ActivityWidget(props) {
  const dispatch = useDispatch();
  const {
    toggleCreateActivityDialog,
    curriculumExist,
    groupId,
    groupInfo,
    handleMixpanelCurriculumEvents,
    loading,
  } = props;
  const history = useHistory();
  const { upcomingTeacherSession, createdGroupSessionsForTeacher, deleteSession, updatedGroupSessions } = useSelector(
    (state) => state.toJS().tutoring,
  );
  const { noonText } = useSelector((state) => state.toJS().translation);
  const handleViewContentCurriculumClick = () => {
    handleMixpanelCurriculumEvents({
      type: 'activities_view_all',
      mixPanel: false,
    });
    history.push(`/${localStorage.updatedLocale}/schedule?groupId=${groupId}`);
  };

  const loadUpcommingSessions = () => {
    const { isLoading } = upcomingTeacherSession;
    if (isLoading) {
      return false;
    }
    const payload = {
      page: 1,
      limit: 1,
      group_id: groupId,
      insideGroup: true,
    };
    dispatch({
      type: UPCOMING_TEACHER_SESSION.REQUEST,
      payload,
    });
    return true;
  };

  useEffect(() => {
    if (
      (updatedGroupSessions && !updatedGroupSessions.loading)
      || (deleteSession && !deleteSession.loading && deleteSession.success)
      || (createdGroupSessionsForTeacher
        && !createdGroupSessionsForTeacher.loading
        && createdGroupSessionsForTeacher.success)
    ) {
      loadUpcommingSessions();
    }
  }, [createdGroupSessionsForTeacher, deleteSession, updatedGroupSessions]);

  useEffect(() => {
    // console.log('curriculumExist', curriculumExist, groupId);
    // if (curriculumExist) {
    // }
    loadUpcommingSessions();
  }, [curriculumExist, groupId]);

  return (
    <Card className="activity-widget group-activity-widget">
      {loading || !upcomingTeacherSession || upcomingTeacherSession.isLoading ? (
        <Shimmer obj={[{ heading: true }, { lines: 2 }]} />
      ) : (
        <React.Fragment>
          <Row align="center" justify="space-between" flex="1" className="card-header">
            <Row align="center" gap="sm">
              <h3 className="card-header__title">{translationText(noonText, 'groupCurriculum.upcomingActivities')}</h3>
              {!!get(groupInfo, 'is_session_created') && !!get(upcomingTeacherSession, ['meta', 'total']) && (
                <React.Fragment>
                  <span className="noon-dot" />
                  <div className="card-header__title--note">{get(upcomingTeacherSession, ['meta', 'total'])}</div>
                </React.Fragment>
              )}
            </Row>

            <ViewMoreButton
              handleClick={() => {
                handleViewContentCurriculumClick();
              }}
            />
          </Row>
          {!curriculumExist
          && (!upcomingTeacherSession || !upcomingTeacherSession.response || !upcomingTeacherSession.response[0]) ? (
            <Column align="start" justify="center" className="card-body" gap="sm">
              <span className="child card-body__note">
                {translationText(noonText, 'groupCurriculum.noCurriculumNoActivity')}
                <br />
                <b>{translationText(noonText, 'groupCurriculum.expSession')}</b>
              </span>
              <Row align="center" justify="start" gap="sm" nowrap>
                <Button
                  type="secondary"
                  className="plr-4 child"
                  value={translationText(noonText, 'groupCurriculum.startTrialSession')}
                  fill="#fff"
                  onClick={() => toggleCreateActivityDialog(true)}
                  style={{ textTransform: 'capitalize' }}
                />
              </Row>
            </Column>
            ) : (
              <Column align="start" justify="center" className="card-body" gap="sm">
                {upcomingTeacherSession
                && (!isEmpty(upcomingTeacherSession.response) ? (
                  <Row className="card-body__session-area" gap="sm" align="center">
                    <ScheduleListSessionCard
                      cardView="content"
                      noMargin
                      data={upcomingTeacherSession.response[0]}
                      groupId={groupId}
                    />
                    <IconAdd
                      className="mlr-2 child"
                      fill={COLORS.brand.base}
                      stroke="#fff"
                      onClick={() => toggleCreateActivityDialog(true)}
                    />
                  </Row>
                ) : get(groupInfo, 'is_session_created') ? (
                  <Column align="start" justify="center" className="card-body" gap="sm">
                    <span className="child card-body__note">
                      {translationText(noonText, 'activity.emptyUpcomingHeading')}
                    </span>
                    <Row align="center" justify="start" gap="sm" nowrap>
                      <Button
                        type="primary"
                        value={translationText(noonText, 'button.newActivity')}
                        onClick={() => toggleCreateActivityDialog(true)}
                        className="text-capitalize"
                        icon={IconAdd}
                      />
                    </Row>
                  </Column>
                ) : (
                  <Column align="start" justify="center" className="card-body" gap="sm">
                    <span className="child card-body__note">
                      {translationText(noonText, 'groupCurriculum.noActivity')}
                    </span>
                    <Row align="center" justify="start" gap="sm" nowrap>
                      <Button
                        type="secondary"
                        className="plr-4 child"
                        value={translationText(noonText, 'groupCurriculum.startTrialSession')}
                        fill="#fff"
                        onClick={() => toggleCreateActivityDialog(true)}
                        style={{ textTransform: 'capitalize' }}
                      />
                    </Row>
                  </Column>
                ))}
              </Column>
            )}
        </React.Fragment>
      )}
    </Card>
  );
}

ActivityWidget.propTypes = {
  toggleCreateActivityDialog: PropTypes.func.isRequired,
  groupId: PropTypes.number.isRequired,
  curriculumExist: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  groupInfo: PropTypes.shape({}).isRequired,
  handleMixpanelCurriculumEvents: PropTypes.func.isRequired,
};
ActivityWidget.defaultProps = {};

export default ActivityWidget;

import React from 'react';

import { Button, Row } from '@noon/atom';

import t from '../../../helpers/translate';
import { ISlide } from '../types';

type ICompetitionActionControl = {
    slides: ISlide[],
    activeSlideIndex: number,
    onNextQuestion: Function,
    onSelectQuestion: Function,
    showCanvas: Boolean,
    isLoading: Boolean,
}

const CompetitionActionControl = (prop: ICompetitionActionControl) => {
  const { slides, activeSlideIndex, onNextQuestion, onSelectQuestion, showCanvas, isLoading } = prop;
  return (
    <Row nowrap gap align="center" justify="center" className="competition-action-control animated slideInUp">
      {activeSlideIndex !== -1 ? (
        <Button loading={isLoading} type="primary" onClick={onNextQuestion}>
          {t('button', activeSlideIndex < slides.length - 1 ? 'next' : 'showResults')}
        </Button>
      ) : (
        <Button loading={isLoading} type="primary" onClick={onNextQuestion}>{t('button', 'startCompetition')}</Button>
      )}
      {activeSlideIndex !== -1 && !showCanvas && <Button loading={isLoading} type="green" onClick={onSelectQuestion}>{t('competition', 'explainQuestion')}</Button>}
    </Row>
  );
};

export default CompetitionActionControl;

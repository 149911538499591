import React, { useState } from 'react';
import { Column } from '@noon/atom';
import { IconAddCircle, IconPlus } from '@noon/quark';
import { translationText } from '../../helpers';
import { checkIsTablet, checkIsMobile } from '../../helpers/prepareAssignment';
import { COLORS } from '../../constants';

const StartScreenChoices = ({ setIsMobileQuestionsOpened, noonText }) => {
  const [isTipShown, setIsTipShown] = useState(false);
  const [isModalShown, setModalShown] = useState(false);

  const showOrCloseTip = (e) => {
    e.persist();
    if (window.innerWidth <= 991 && e.type === 'click') {
      setIsTipShown(!isTipShown);
    }
    if (window.innerWidth >= 991 && e.type !== 'click') {
      setIsTipShown(!isTipShown);
    }
  };

  return (
    <Column align="center" justify="center" className="prepare-assignment--startScreenChoices">
      {(checkIsTablet() || checkIsMobile())
        && (
        <IconAddCircle fill={COLORS.brand.base} onClick={setIsMobileQuestionsOpened} width="46px" height="46px" />
        )}
      <img src="/assets/images/drag_n_drop.svg" alt="grag_n_drop" />
      <span className="prepare-assignment--startScreenChoices--title">{translationText(noonText, 'createHomework.addQuestions')}</span>
      {/* <p className="separate-choices-line">{translationText(noonText, 'createHomework.or')}</p>
      <button onClick={() => setModalShown(!isModalShown)}>{translationText(noonText, 'createHomework.autoGenerate')}</button>
      <span
        className="prepare-assignment--startScreenChoices--tip"
        onMouseOver={showOrCloseTip}
        onMouseOut={showOrCloseTip}
        onClick={showOrCloseTip}
      >{translationText(noonText, 'createHomework.whatIsAutoGenerate')}</span>
      <div className="prepare-assignment--startScreenChoices--tipWrapper">
        {isTipShown &&
        <CreationHomeworkTip
          text={translationText(noonText, 'createHomework.autoGenerateTip')}
        />}
      </div>
      {isModalShown && <AutoGenerateCreationModal noonText={noonText} setModalShown={() => setModalShown(!isModalShown)} />} */}
    </Column>
  );
};

export default StartScreenChoices;

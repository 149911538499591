import { put, call, takeEvery, all, select } from 'redux-saga/effects';
import isEmpty from 'lodash-es/isEmpty';
import { tokenDataSelector, temporaryTokenPayloadSelector } from 'redux/selectors/userv2';
import { biFrostApi } from '../restApi';
import {
  CREATE_TEMPORARY_ACCESS_TOKEN,
  REFRESH_TOKEN,
  TOKEN_MIGRATION,
  TEMPORARY_TOKEN_DATA,
  TOKEN_DATA,
  EXPIRE_TOKEN,
  REDIRECT_URL,
  TEMPORARY_TOKEN_PAYLOAD,
  GET_TEACHER_PROFILE,
} from '../constants';
import { setAuthorizationHeader } from '../../helpers';
import { getCountryId } from '../../constants';

const createTemporaryTokenApi = json => biFrostApi.post('access_token', json);
const refreshTokenApi = json => biFrostApi.post('access_token/refresh', json);
const tokenMigrationApi = json => biFrostApi.post('tokens/migrate', json);
const expireTokenApi = json => biFrostApi.put('tokens/expire', json);

const uuidv4 = require('uuid/v4');

const getUser = state => state.toJS().user;

function* createTemporaryToken({ payload: { json } }) {
  yield put({ type: CREATE_TEMPORARY_ACCESS_TOKEN.LOADING, payload: { isLoading: true } });
  try {
    const response = yield call(createTemporaryTokenApi, json);
    if (response.ok) {
      yield put({
        type: CREATE_TEMPORARY_ACCESS_TOKEN.SUCCESS,
        payload: isEmpty(response.data) ? {} : response.data,
      });
      yield put({
        type: TEMPORARY_TOKEN_DATA,
        payload: isEmpty(response.data) ? {} : response.data,
      });
      localStorage.setItem('temporaryToken', JSON.stringify(response.data));
      yield setAuthorizationHeader(response.data.token);
    } else {
      yield put({ type: CREATE_TEMPORARY_ACCESS_TOKEN.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: CREATE_TEMPORARY_ACCESS_TOKEN.FAILURE, payload: err });
  } finally {
    yield put({ type: CREATE_TEMPORARY_ACCESS_TOKEN.LOADING, payload: { isLoading: false } });
  }
}

function* refreshToken() {
  try {
    yield put({ type: REFRESH_TOKEN.LOADING, payload: { isLoading: true } });
    const tokenData = yield select(tokenDataSelector);
    const temporaryTokenPayload = yield select(temporaryTokenPayloadSelector);
    const response = yield call(refreshTokenApi, {
      refresh_token: tokenData.r_tkn.tkn,
      access_token: tokenData.a_tkn.tkn,
      ...temporaryTokenPayload,
    });
    if (response.ok && response.data && response.data.token) {
      const token = {
        a_tkn: {
          tkn: response.data.token,
          exp: response.data.valid_till,
        },
        r_tkn: tokenData.r_tkn,
      };
      yield put({
        type: TOKEN_DATA,
        payload: token,
      });
      localStorage.tokenData = JSON.stringify(token);
      yield setAuthorizationHeader(response.data.token);

      const { loggedUser } = yield select(getUser);
      // get user profile from localstorage if not in store
      if (isEmpty(loggedUser) && localStorage.loggedUser) {
        yield put({ type: GET_TEACHER_PROFILE.SUCCESS, payload: JSON.parse(localStorage.loggedUser) });
      } else if (isEmpty(loggedUser)) {
        // get user profile if not in store and localstorage
        yield put({ type: GET_TEACHER_PROFILE.REQUEST });
      }
    } else {
      yield put({ type: REFRESH_TOKEN.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: REFRESH_TOKEN.FAILURE, payload: err });
  } finally {
    yield put({ type: REFRESH_TOKEN.LOADING, payload: { isLoading: false } });
  }
}

export function* expireToken() {
  yield put({ type: EXPIRE_TOKEN.LOADING, payload: { isLoading: true } });
  try {
    const tokenData = yield select(tokenDataSelector);
    const temporaryTokenPayload = yield select(temporaryTokenPayloadSelector);
    const response = yield call(expireTokenApi, {
      refresh_token: tokenData.r_tkn.tkn,
      access_token: tokenData.a_tkn.tkn,
      ...temporaryTokenPayload,
    });
    if (response.ok) {
      yield put({
        type: EXPIRE_TOKEN.SUCCESS,
        payload: isEmpty(response.data) ? {} : response.data,
      });
    } else {
      yield put({ type: EXPIRE_TOKEN.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: EXPIRE_TOKEN.FAILURE, payload: err });
  } finally {
    yield put({ type: EXPIRE_TOKEN.LOADING, payload: { isLoading: false } });
  }
}

function adaptor(data) {
  const { access_token, access_token_valid_till, refresh_token, refresh_token_valid_till } = data;
  return {
    a_tkn: {
      tkn: access_token,
      exp: access_token_valid_till,
    },
    r_tkn: {
      tkn: refresh_token,
      exp: refresh_token_valid_till,
    },
  };
}

function* tokenMigration() {
  try {
    yield put({ type: TOKEN_MIGRATION.LOADING, payload: { isLoading: true } });
    const temporaryTokenPayload = yield select(temporaryTokenPayloadSelector);
    let dummyPayload = temporaryTokenPayload;
    if (!temporaryTokenPayload) {
      const device_id = uuidv4();
      dummyPayload = {
        tenant: 'teacher',
        country: getCountryId(),
        os: 'web',
        device_id,
      };
      yield put({ type: TEMPORARY_TOKEN_PAYLOAD, payload: dummyPayload });
      localStorage.temporaryTokenPayload = JSON.stringify(dummyPayload);
    }
    const response = yield call(tokenMigrationApi, {
      token: localStorage.token,
      ...dummyPayload,
    });
    if (response.ok) {
      const responseWithStruct = yield call(adaptor, response.data);
      yield put({
        type: TOKEN_MIGRATION.SUCCESS,
        payload: isEmpty(response.data) ? {} : responseWithStruct,
      });
      yield put({
        type: TOKEN_DATA,
        payload: isEmpty(response.data) ? {} : responseWithStruct,
      });
      localStorage.setItem('tokenData', JSON.stringify(responseWithStruct));
      yield setAuthorizationHeader(responseWithStruct.a_tkn.tkn);
      yield put({ type: GET_TEACHER_PROFILE.REQUEST });
      yield put({ type: REDIRECT_URL, payload: { pathname: `/${localStorage.updatedLocale}/groups` } });
    } else {
      yield put({ type: TOKEN_MIGRATION.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: TOKEN_MIGRATION.FAILURE, payload: err });
  } finally {
    yield put({ type: TOKEN_MIGRATION.LOADING, payload: { isLoading: false } });
  }
}

export function* biFrostSaga() {
  yield all([
    takeEvery(CREATE_TEMPORARY_ACCESS_TOKEN.REQUEST, createTemporaryToken),
    takeEvery(REFRESH_TOKEN.REQUEST, refreshToken),
    takeEvery(TOKEN_MIGRATION.REQUEST, tokenMigration),
  ]);
}

import React, { Component } from 'react';
import { Row } from '@noon/atom';
import { SignupForm } from '../../components/LoginSignup';

export default class Signup extends Component {
  /* eslint-disable react/prop-types */
  render() {
    return (
      <Row nowrap className="guest-route-wrapper">
        <SignupForm match={this.props.match} location={this.props.location} history={this.props.history} />
      </Row>
    );
  }
}

import React from 'react';
import { Column, Row } from '@noon/atom';
import { translationText } from '../../helpers';

const HowToPrepareAssignmentTip = ({ closeTip, noonText, currentQuestions }) => {
  return (
    <React.Fragment>
      <div className="prepare-assignment--modalWrapper" />
      <Column justify="space-between" className="prepare-assignment--howToTip">
        <Row align="center" className="prepare-assignment--howToTip--rearrange">
          {currentQuestions.data.map((_, index) => (
            <div className={index === 0 ? 'selected' : ''}>{index + 1}</div>
          ))}
        </Row>
        <Column align="center" className="prepare-assignment--howToTip--dragndrop">
          <img src="/assets/images/drag_n_drop.svg" alt="grag_n_drop" />
          <span>{translationText(noonText, 'createHomework.dndDescription')}</span>
          <button onClick={closeTip}>{translationText(noonText, 'button.okayGotIt')}</button>
        </Column>
      </Column>
    </React.Fragment>
  )
}

export default HowToPrepareAssignmentTip

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Column, Toggle } from '@noon/atom';
import { useSelector, useDispatch } from 'react-redux';
import { UPDATE_GROUP_V2 } from '../../../redux/constants';
import { translationText } from '../../../helpers';

const OptoutWidget = ({ groupInfo, groupId, updating }) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const { noonText } = useSelector((state) => state.toJS().translation);
  useEffect(() => {
    setActive(groupInfo.auto_post_enabled);
  }, [groupInfo]);

  useEffect(() => {
    setLoading(updating);
  }, [updating]);

  const updateOptout = () => {
    if (loading) return;
    setLoading(true);
    dispatch({
      type: UPDATE_GROUP_V2.REQUEST,
      payload: {
        groupId,
        data: {
          ...groupInfo,
          enable_auto_post: !active,
        },
      },
    });
  };

  return (
    <Card className="member-widget">
      <Row align="center" justify="space-between" flex="1" className="card-header" gap="sm">
        <Row align="center" justify="start">
          <h3 className="card-header__title child">{translationText(noonText, 'groups.autoPostsHeader')}</h3>
        </Row>
      </Row>
      <Column align="start" justify="center" className="plr-2 mb-2 card-body" gap="sm">
        <span className="child card-body__note">
          {translationText(noonText, 'groups.autoPostsDescription')}
        </span>
      </Column>
      <Row align="center" flex="1" justify="end" className="ptb-1 plr-2">
        <Toggle
          outlined
          size="md"
          onClick={updateOptout}
          value={active ? 'on' : 'off'}
          className={loading ? 'disabled_toggle' : active && 'on'}
        />
      </Row>
    </Card>
  );
};

OptoutWidget.propTypes = {
  groupInfo: PropTypes.shape().isRequired,
  groupId: PropTypes.string.isRequired,
  updating: PropTypes.bool,
};
OptoutWidget.defaultProps = {
  updating: false,
};
export default OptoutWidget;

import sanitizeHtml from 'sanitize-html';

function dynamicParagraphParser(para) {
  const { childNodes } = para;
  let index = 0;
  const orderderData = [];
  orderderData[index] = { type: 'paragraph', value: [] };
  childNodes.forEach((child) => {
    if (child.nodeName === '#text' && child.data && child.data.trim().length) {
      orderderData[index].value.push({ type: 'text', value: child.data });
    } else {
      if (child.nodeName === 'IMG') {
        const styles = child.height ? { height: String(child.height) } : {};
        if (child.style && child.width) {
          styles.width = String(child.width);
        }
        orderderData[index].value.push({ type: 'image', value: child.src, ...styles });
      }
      if (child.nodeName === 'INPUT' && child.type === 'image') {
        const styles = child.style && child.style.height ? { height: String(child.style.height) } : {};
        if (child.style && child.style.width) {
          styles.width = String(child.style.width);
        }
        orderderData[index].value.push({ type: 'image', value: child.src, ...styles });
      }
      if (child.nodeName === 'CODE' && child.textContent && child.textContent.trim().length) {
        orderderData[index].value.push({ type: 'latex', value: child.dataset.latex });
      }
      if (child.nodeName === 'U' && child.textContent && child.textContent.trim().length) {
        orderderData[index].value.push({ type: 'text', value: child.textContent, style: { underline: true } });
      }
      if (child.nodeName === 'B' && child.textContent && child.textContent.trim().length) {
        orderderData[index].value.push({ type: 'text', value: child.textContent, style: { bold: true } });
      }
      if (child.nodeName === 'STRONG' && child.textContent && child.textContent.trim().length) {
        orderderData[index].value.push({ type: 'text', value: child.textContent, style: { bold: true } });
      }
      if (child.nodeName === 'I' && child.textContent && child.textContent.trim().length) {
        orderderData[index].value.push({ type: 'text', value: child.textContent, style: { italic: true } });
      }
      if (child.nodeName === 'BR') {
        index++;
        orderderData[index] = { type: 'paragraph', value: [] };
      }
    }
  });
  if (!orderderData[index].value.length) {
    orderderData.splice(index);
  }
  return orderderData;
}

function htmlParser(text) {
  let finalResponse = [];
  const cleanData = sanitizeHtml(text, {
    // 'u', 'b', 'i',
    // 'table', 'tbody', 'tr', 'td', 'th', 'theader',
    // 'input'
    allowedTags: ['img', 'p', 'div', 'br', 'code', 'u', 'b', 'i', 'strong'],
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      img: ['src', 'width', 'height'],
      input: ['src', 'type', 'style'],
      code: ['data-latex'],
    },
  });
  const parser = document.createElement('parser');
  parser.innerHTML = cleanData;
  if (parser.innerHTML.trim() && parser.innerHTML.slice(0, 2) !== '<p' && parser.innerHTML[0] !== '<d') {
    const [x, ...rest] = parser.innerHTML.split('<div>');
    parser.innerHTML = [`<div>${x}</div>`, ...rest].join('<div>');
  }
  parser.childNodes.forEach((childNode) => {
    if (childNode.nodeName === 'P') {
      const response = dynamicParagraphParser(childNode);
      if (response.length) {
        finalResponse = finalResponse.concat(response);
      }
    } else if (childNode.nodeName === 'DIV') { // paragraph, br and image (style - underline)
      const response = dynamicParagraphParser(childNode);
      if (response.length) {
        finalResponse = finalResponse.concat(response);
      }
    } else if (childNode.nodeName === 'TABLE') { // table
      const row = { type: 'table', value: [] };
      childNode.childNodes.forEach((tableNodes) => {
        if (tableNodes.nodeName === 'TBODY') {
          let i = 0;
          tableNodes.childNodes.forEach((tbodyNodes) => {
            if (tbodyNodes.nodeName === 'TR') {
              row.value[i] = { type: 'tr', value: [] };
              tbodyNodes.children.forEach((trEle) => {
                if (trEle.nodeName === 'TD') {
                  row.value[i].value.push({ type: 'td', value: trEle.textContent ? trEle.textContent.trim() : '' });
                }
              });
              i++;
            }
          });
        }
      });
      finalResponse.push(row);
    } else if (childNode.nodeName === 'UL' || childNode.nodeName === 'OL') {
      const list = { type: 'list', ordered: childNode.name === 'ol', value: [] };
      childNode.childNodes.forEach((listNodes) => {
        if (listNodes.nodeName === 'LI' && listNodes.textContent && listNodes.textContent.trim()) {
          list.value.push({ type: 'li', value: listNodes.textContent.trim() });
        }
      });
      finalResponse.push(list);
    }
  });
  return finalResponse.length ? finalResponse : null;
}

export default htmlParser;

import React from 'react';
import { Row, Button, Modal } from '@noon/atom';
import { useSelector } from 'react-redux';
import { IconCorrectCircle, IconClose } from '@noon/quark';
import PropTypes from 'prop-types';
import { translationText } from '../../../helpers';

const OnboardingSuccessDialog = ({ onClose, isTrialSession }) => {
  const { noonText } = useSelector((state) => state.toJS().translation);
  return (
    <Modal className="onboarding-success-modal">
      <div className="success-icon">
        <IconCorrectCircle height="60px" width="60px" fill="#64D8AE" stroke="white" />
      </div>
      <Row className="header">
        <IconClose height="22px" width="22px" onClick={onClose} />
      </Row>
      <h1 className="heading">{translationText(noonText, 'image2question.OnboardingSuccessTitle')}</h1>
      <p className="message">{translationText(noonText, 'image2question.OnboardingSuccessText')}</p>
      { isTrialSession && <p className="info">{translationText(noonText, 'image2question.OnboardingSuccessInfo')}</p> }
      <img alt="succes" className="onboarding-success-image" src="../../../assets/images/addQuestionFromImage/add-question-success.png" />
      <Button className="close-button" type="primary" size="lg" onClick={onClose}>
        {' '}
        {translationText(noonText, 'image2question.OnboardingClose')}
      </Button>
    </Modal>
  );
};

OnboardingSuccessDialog.propTypes = {
  onClose: PropTypes.func,
  isTrialSession: PropTypes.bool,
};

OnboardingSuccessDialog.defaultProps = {
  onClose: () => {},
  isTrialSession: true,
};

export default OnboardingSuccessDialog;

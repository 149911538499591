function translate() {
  let translationCache = null;
  return function t(group: string, key:string): string {
    if (!translationCache || localStorage.getItem('isStaleTranslation')) {
      translationCache = localStorage.translationData ? JSON.parse(localStorage.translationData)?.data : null;
      localStorage.removeItem('isStaleTranslation');
    }
    if (!translationCache?.[group]?.[key]) return '';

    return translationCache?.[group]?.[key];
  };
}

export default translate();

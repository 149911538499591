import React, { useState } from 'react';
import { Column, Button, Row, Checkbox } from '@noon/atom';
import { IconClose } from '@noon/quark';
import BreakoutModeSelector from './breakoutModeSelector';
import t from '../../../helpers/translate';

const QUESTION_TIME = {
  breakout: [15, 30, 45, 60, 90, 120, 180, 240, 300, 420],
  interactiveQuestion: [15, 30, 45, 60, 90, 120, 180, 240, 300],
};

const QuestionTypeSelection = (prop) => {
  const { breakoutTeamFormed, onSelectQuestion, breakoutMode, onSelectInteractiveQuestion, isQuestionLoading, activeSlide, isDiscussionEnabled } = prop;

  const [showTiming, setShowTiming] = useState(false);
  const [selectedTime, setSelectedTime] = useState(0);
  const [isBreakoutDeffered, setIsBreakoutDeffered] = useState(breakoutMode === 'deferred'); // default breakout mode
  const [isAllowedDiscussion, setIsAllowedDiscussion] = useState(true)

  const showTimingOption = () => {
    setShowTiming(true);
  };

  const hideTimingOption = () => {
    setShowTiming(false);
  };

  const handleAllowedDiscussion = () => {
    setIsAllowedDiscussion((isAllowedDiscussion) => !isAllowedDiscussion);
  }

  const handleTimeClick = (time: number) => {
    setSelectedTime(time);
    onSelectInteractiveQuestion(activeSlide, time, time > 30 ? isBreakoutDeffered : false, isAllowedDiscussion && isDiscussionEnabled);
  };

  const handleBreakoutMode = () => {
    setIsBreakoutDeffered((isBreakoutDeffered) => !isBreakoutDeffered);
  };

  const timings = breakoutTeamFormed ? QUESTION_TIME.breakout : QUESTION_TIME.interactiveQuestion;

  return (
    <Column gap="lg" align="center" justify="center" className="timing-overlay">
      <h3 className="timing-overlay__title">
        {t(showTiming ? 'teacherBreakout' : 'tutoring', showTiming ? 'timeSelectionBreakout' : 'selectHowToAskQuestion')}
      </h3>
      {showTiming && <IconClose className="timing-overlay__close" onClick={hideTimingOption} />}
      {showTiming ? (
        <Row gap="sm" align="center" justify="center">
          {timings.map((time) => (
            <Button
              key={time}
              type="primary"
              outlined
              onClick={() => handleTimeClick(time)}
              disabled={!!isQuestionLoading}
              loading={selectedTime === time && !!isQuestionLoading}
            >
              {time}
              <small>s</small>
            </Button>
          ))}
        </Row>
      ) : (
        <>
          <Row gap align="center" justify="center">
            <Button size="lg" type="primary" outlined onClick={onSelectQuestion} loading={!!isQuestionLoading}>
              {t('tutoring', 'explaintionWithoutTest')}
            </Button>
            <Button size="lg" type="primary" onClick={showTimingOption}>
              {t(breakoutTeamFormed ? 'teacherBreakout' : 'tutoring', breakoutTeamFormed ? 'startBreakoutButton' : 'askStudentsPoll')}
              {!breakoutTeamFormed && t('tutoring', 'recommended')}
            </Button>
          </Row>
          {breakoutTeamFormed && <BreakoutModeSelector isBreakoutDeffered={isBreakoutDeffered} onChangeBreakoutMode={handleBreakoutMode} isDiscussionEnabled={isDiscussionEnabled} isAllowedDiscussion={isAllowedDiscussion} handleAllowedDiscussion={handleAllowedDiscussion}/>}
        </>
      )}
    </Column>
  );
};

export default QuestionTypeSelection;

import React from 'react';
import { IconLoader } from '@noon/quark';
import { Column, Row } from '@noon/atom';
import classNames from 'classnames';
import { humanizeCount, translationText } from '../../helpers';

const Poll = (prop) => {
  const { clickable, disabled, noonText, pollData = {}, loading } = prop;
  const { choices = [], type_meta = {} } = pollData;
  const maxCount = choices.reduce((prev, current) => (prev.total_opted > current.total_opted ? prev : current)).total_opted;

  const days = Math.round(type_meta.minutes_left / 1440);
  let time = `${days} ${days > 1 ? translationText(noonText, 'datetime.days') : translationText(noonText, 'datetime.day')} ${translationText(noonText, 'datetime.remains')}`;
  if (type_meta.minutes_left < 720 && type_meta.minutes_left >= 60) {
    time = `${Math.round(type_meta.minutes_left / 60)} ${(type_meta.minutes_left / 60) > 1 ? translationText(noonText, 'datetime.hours') : translationText(noonText, 'datetime.hours')} ${translationText(noonText, 'datetime.remains')}`;
  } else if (type_meta.minutes_left < 60) {
    time = `${type_meta.minutes_left} ${type_meta.minutes_left > 1 ? translationText(noonText, 'datetime.minutes') : translationText(noonText, 'datetime.minute')} ${translationText(noonText, 'datetime.remains')}`;
  }
  return (
    <Column nowrap className={classNames('poll', { clickable, disabled })}>
      {!!type_meta.minutes_left && <p>{time}</p>}
      {choices.map((choice) => (
        <Row key={choice.id} className={classNames('poll__choice', { selected: choice.is_opted })} align="center" onClick={!choice.is_opted && clickable && !disabled ? () => prop.onSubmit(pollData, choice) : null}>
          <div className="poll__choice-progress rounded" style={{ width: `${(maxCount ? ((choice.total_opted / maxCount) * 100) : 0)}%` }} />
          <p>{choice.title}</p>
          {loading ? <IconLoader height="1.5rem" /> : <span>{humanizeCount(choice.total_opted)} {translationText(noonText, 'poll.votes')}</span>}
        </Row>
      ))}
    </Column>
  );
};

export default Poll;

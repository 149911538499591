import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile, isTablet, isPortrait } from 'react-device-detect';
import { useHistory, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { IconAdd, IconBack, IconKickOut, IconLogo, IconMenu, IconNoonWhite, IconMyGroups, IconQuestionFeedO, IconSetting, IconTravel, IconNoonPlus } from '@noon/quark';
import classNames from 'classnames';
import { Row, Column, Avatar, Button, Hr } from '@noon/atom';
import Popover from 'react-popover';
import { isRTL, COLORS, getSelectedCountry } from '../../constants';
import { LOGGED_OUT_TEACHER, TOGGLE_HOVER_SIDEBAR, TOGGLE_SIDEBAR, GET_TEACHER_GROUPS, GET_TEACHER_ARCHIVE_GROUPS } from '../../redux/constants';
import navigationRoutes from '../../constants/routeList';
import { CreateGroup } from '../Create';
import Notifications from '../Notifications';
import Mixpanel from '../Mixpanel';
import { generateLocalizedUrl, translationText } from '../../helpers';
import t from '../../helpers/translate';

const SideMenu = (prop) => {
  const { user, location, sidebarOpen } = prop;
  const { groupId } = useParams();
  const logger = useRef(LoggingManager.mount({ moduleName: 'group' })).current;
  const isRtl = isRTL();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedMenu, setSelectedMenu] = useState({});
  const [showItem, assignItem] = useState('');
  const [isSettingsPopoverVisible, togglePopoverList] = useState(false);
  const settingsActionList = [{ icon: IconKickOut, action: 'logout', label: 'logOut' }];
  const { groups: { data: list }, archivedGroups: { list: archivedGroupsList } } = useSelector((state) => state.toJS().groupsV2);
  const { loggedUser } = useSelector((state) => state.toJS().user);
  const sidebarHovered = useSelector((state) => state.toJS().dashboard.sidebarHovered);
  const { noonText } = useSelector((state) => state.toJS().translation);
  const selectedCountry = getSelectedCountry() || {};

  const getGroups = useCallback(() => {
    dispatch({
      type: GET_TEACHER_ARCHIVE_GROUPS.REQUEST,
    });
    dispatch({
      type: GET_TEACHER_GROUPS.REQUEST,
    });
  }, []);

  const handleToggleHoverSidebar = (status) => {
    dispatch({
      type: TOGGLE_HOVER_SIDEBAR,
      payload: status,
    });
  };

  const setSidebar = (isHidden) => {
    dispatch({
      type: TOGGLE_SIDEBAR,
      payload: isHidden,
    });
  };

  const toggleCreate = (name) => {
    const data = {
      user_id: loggedUser.user_id,
      source: 'sidebar',
      no_of_groups: list ? list.length : 0,
      timestamp: Date.now(),
    };
    const type = 'create_group_clicked';
    if (name === 'group') {
      Mixpanel.track(type, data);
      logger.track(type,
        { group: data },
      );
    }
    assignItem(name !== showItem ? name : '');
  };

  const handleMenu = useCallback((e, navItem) => {
    if (isMobile && !navItem.subMenu) setSidebar(true);
    if (e) e.stopPropagation();
    if (navItem.url) {
      if (selectedMenu !== navItem.groupName) setSelectedMenu('');
      setSelectedMenu(navItem);
      history.push(`/${localStorage.updatedLocale}/${navItem.url}`);
    } else if (selectedMenu.label !== navItem.label) {
      setSelectedMenu(navItem);
      if (navItem.subMenu && list && !list.length) {
        getGroups();
      }
    } else {
      setSelectedMenu({});
    }
  }, [selectedMenu, history]);

  useEffect(() => {
    getGroups();
    // TODO: Need to find better solution
    const selectedMenuItem = navigationRoutes.find((navItem) => location.pathname.indexOf(`/${localStorage.updatedLocale}/${navItem.url}`) > -1);
    if (!selectedMenuItem) {
      handleMenu(null, {
        label: 'mygroups',
        icon: IconMyGroups,
        subMenu: true,
        visible: true,
        fetchUrl: 'fetchGroups',
      });
    } else {
      handleMenu(null, selectedMenuItem);
    }
  }, []);

  const handleMouseHover = () => {
    if (!sidebarOpen && !isMobile) {
      handleToggleHoverSidebar(true);
    }
  };

  const handleMouseOut = () => {
    if (!sidebarOpen && !isMobile) {
      togglePopoverList(false);
      handleToggleHoverSidebar(false);
    }
  };

  const showHideMenu = (flag) => {
    setSidebar(flag);
  };

  const openGroup = (group) => {
    if (isMobile) setSidebar(true);
    history.push(`/${localStorage.updatedLocale}/groups/${group.id}`);
  };

  // const goHome = () => {
  //   if (isMobile) setSidebar(true);
  //   history.push(`/${localStorage.updatedLocale}/home`);
  //   setSelectedMenu('home');
  // };

  const goHelp = () => {
    if (isMobile) setSidebar(true);
    history.push(`/${localStorage.updatedLocale}/training`);
    setSelectedMenu('help');
  };

  const handleSettingsAction = (action) => {
    if (action === 'locale') {
      const language = localStorage.language === 'en' ? 'ar' : 'en';
      localStorage.setItem('language', language);
      delete localStorage.translationData;
      const isoCode = localStorage.updatedLocale.split('-')[0];
      const url = generateLocalizedUrl(window.location.pathname, `${isoCode}-${language}`);
      window.location.href = url;
    } else if (action === 'logout') {
      dispatch({ type: LOGGED_OUT_TEACHER.REQUEST });
    }
  };

  const settingsPopoverProps = {
    isOpen: isSettingsPopoverVisible,
    preferPlace: 'above',
    onOuterAction: () => togglePopoverList(false),
    body: (
      <Row justify="start">
        <ul className="settings-popover__list">
          {selectedCountry.locale !== 'en' && (
            <li onClick={() => handleSettingsAction('locale')}>
              <IconTravel height="20px" fill={COLORS.coolGrey.base} />
              <span>{(localStorage.language === 'en') ? ' اللغة العربية' : 'English'}</span>
            </li>
          )}
          {settingsActionList.map((item) => (
            <li key={item.icon} onClick={() => handleSettingsAction(item.action)}>
              <item.icon height="20px" fill={COLORS.coolGrey.base} />
              <span>{translationText(noonText, `tab.${item.label}`)}</span>
            </li>
          ))}
        </ul>
      </Row>
    ),
  };

  return (
    <React.Fragment>
      {showItem === 'group' && <CreateGroup teacherId={loggedUser.user_id} totalGroups={list.length} onClose={() => toggleCreate('')} />}
      <aside flex="column" className={classNames('noon-sidebar', { collapsed: !sidebarOpen, hovered: sidebarHovered, 'no-group': isEmpty(list) })} onMouseLeave={handleMouseOut}>
        <header className="noon-sidebar__header">
          <li className="nav-item logo flex-1">
            <div className={classNames('icon-wrapper', { 'full-logo': (sidebarOpen || sidebarHovered) && !isMobile && !isTablet && !isPortrait })}>
              {isMobile || (isTablet && isPortrait)
                ? (
                  <IconMenu
                    onClick={() => setSidebar(true)}
                    fill="#ffffff"
                    stroke="#ffffff"
                    height="22px"
                    width="22px"
                  />
                )
                : (!sidebarOpen && !sidebarHovered) ? <IconLogo height="24px" width="24px" />
                  : <IconNoonWhite height="50px" width="120px" />}
            </div>
          </li>
          {(sidebarOpen || sidebarHovered) && <Notifications history={history} />}
        </header>
        <nav className="noon-sidebar__navigation" onMouseEnter={handleMouseHover}>
          <ul className="navigation-list">
            {navigationRoutes.filter((item) => item.visible).map((navItem) => (
              <li
                key={navItem.label}
                data-index={navItem.label}
                className={classNames(
                  'navigation-list__item',
                  { selected: navItem.label === selectedMenu.label },
                  { active: navItem.label === selectedMenu.label && !navItem.subMenu },
                  { 'stick-to-bottom': navItem.stickToBottom },
                  { 'extra-height': navItem.extraHeight },
                  { expanded: (navItem.label === selectedMenu.label && navItem.subMenu) })}>
                {/* Main navigation icons and label */}
                <Row className={classNames('list-item-wrapper', { collapsed: !sidebarHovered && !sidebarOpen })} onClick={(e) => handleMenu(e, navItem)}>
                  {/* have to show profile photo and name in this menu item */}
                  {!navItem.selfContent ? (
                    <React.Fragment>
                      <div className="icon-wrapper">
                        {navItem.label === 'profile' ? (
                          <Avatar size="24px" url={user.profile_pic} rounded noShadow className="header-icon" />
                        ) : (
                          <navItem.icon width="24px" height="24px" />
                        )}
                      </div>
                      <div className={classNames('text-area', { hide: !(sidebarOpen || sidebarHovered) })}>
                        <span className="text">{navItem.label === 'profile' ? user.name : translationText(noonText, `tab.${navItem.label}`)}</span>
                        {navItem.label !== 'profile' && (
                        <React.Fragment>
                          {navItem.subMenu && <IconBack color={COLORS.coolGrey[4]} height="20px" rotate={navItem.label === selectedMenu.label ? 90 : -90} />}
                          {/* {!navItem.subMenu && !!notificationCount[idx] && <div className="notification">{notificationCount[idx]}</div>} */}
                        </React.Fragment>
                        )}
                      </div>
                    </React.Fragment>
                  ) : null}
                </Row>

                {/* The submenu for groups */}
                {(navItem.label === selectedMenu.label && navItem.subMenu) && (
                  <React.Fragment>
                    {isEmpty(list)
                      && (
                      <Row gap className="btn-create-group" justify="start">
                        <Button onClick={() => toggleCreate('group')} icon={IconAdd} value={translationText(noonText, 'button.createGroup')} type="secondary" className="text-capitalize" />
                      </Row>
                      )}
                    <ul className="submenu">
                      {list && list.map((group) => {
                        const count = group.tutoring_count + group.requested_users_count + group.homework_count;
                        return (
                          <li key={group.id} className={classNames('submenu-item', { active: groupId === group.id && (sidebarOpen || sidebarHovered) })} onClick={() => openGroup(group)}>
                            <Row className="list-item-wrapper" gap="sm" justify="start" title={group.title}>
                              <span className="child">{group.title}</span>
                              {group.is_premium && <span className="premium sidemenu child">{translationText(noonText, 'groups.plus')}</span>}
                              {!!count && <div className="notification">{count}</div>}
                            </Row>
                          </li>
                        );
                      })}
                      {!isEmpty(archivedGroupsList)
                    && (
                    <React.Fragment>
                      <Hr className="mb-1 mt-2" style={{ backgroundColor: '#A6ACC2' }} />
                      <li className={classNames('submenu-item')}>
                        <Row className="list-item-wrapper" justify="space-between" title={t('tab', 'archivedGroup')}>
                          <span>
                            <b>
                              {t('tab', 'archivedGroup')}
                              {' '}
                              (
                              {archivedGroupsList.length}
                              )
                            </b>
                          </span>
                        </Row>
                      </li>
                      {list && archivedGroupsList.map((group) => (
                        <li key={group.id} className={classNames('submenu-item', { active: groupId === group.id && (sidebarOpen || sidebarHovered) })} onClick={() => openGroup(group)}>
                          <Row className="list-item-wrapper" justify="start" gap="sm" title={group.title}>
                            <span className="child">{group.title}</span>
                            {group.is_premium && <span className="premium sidemenu child">{translationText(noonText, 'groups.plus')}</span>}
                          </Row>
                        </li>
                      ))}
                    </React.Fragment>
                    )}
                    </ul>
                  </React.Fragment>
                )}
              </li>
            ))}
          </ul>
        </nav>
        {!isEmpty(list)
       && (
       <Row nowrap gap align="center" justify="center" className="add-group-cta" onClick={() => toggleCreate('group')}>
         <IconAdd className="child" />
         {(sidebarOpen || sidebarHovered) && <span className="animated fadeIn">{translationText(noonText, 'button.createGroup')}</span>}
       </Row>
       )}
        <footer className="noon-sidebar__footer">
          <Row className="footer-inner" align="center" justify="space-between">
            {(sidebarOpen || sidebarHovered) && (
              <Row className="footer-options">
                <Row align="center" flex="1">
                  <IconQuestionFeedO height="24px" color={COLORS.coolGrey[2]} onClick={() => goHelp()} />
                  <Popover className="settings-popover" {...settingsPopoverProps}>
                    <IconSetting onClick={() => togglePopoverList(true)} width="32px" height="24px" fill={isSettingsPopoverVisible ? COLORS.coolGrey[4] : COLORS.coolGrey[2]} />
                  </Popover>
                </Row>
              </Row>
            )}
            <Column align="center" justify="center" className="toggle-icon" onClick={() => showHideMenu(sidebarOpen)}>
              <IconBack height="24px" fill={COLORS.coolGrey[4]} flipX={isRtl ? sidebarOpen : !sidebarOpen} />
            </Column>
          </Row>
        </footer>
      </aside>
    </React.Fragment>
  );
};

export default SideMenu;

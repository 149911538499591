import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash-es/isEmpty';
import find from 'lodash-es/find';
import io from 'socket.io-client';
import moment from 'moment';
import emojiRegex from 'emoji-regex';
import sortBy from 'lodash-es/sortBy';
import { NoonLoader } from '@noon/atom';
import { browserName, browserVersion, osName, osVersion, mobileVendor, mobileModel } from 'react-device-detect';
import { classType, userType, translationType } from '../../types';
import { addToast, TOAST_TYPE } from '../../components/Toast';
import { getCountryId, CHAT_THRESHOLD } from '../../constants';
import { translationText } from '../../helpers';
import ErrorBoundry from '../../components/ErrorBoundry';

const whiteboardRef = React.createRef();

let socketTrialNumber = 1;
class PlaybackSocketContainer extends Component {
  static propTypes = {
    sessionData: classType,
    noonText: translationType.isRequired,
    user: userType.isRequired,
    children: PropTypes.func.isRequired,
    token: PropTypes.string,
    onExit: PropTypes.func,
    temporaryTokenPayload: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    sessionData: null,
    onExit: null,
    token: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeSlide: 0,
      studentSupportArr: [],
      sketchData: [],
      sketchDataPlayback: {},
      index: 3,
      playerState: 'pause',
      chats: [],
      isChatVisible: false,
      activeSlideData: {},
      questionTimer: 0,
      getReadyTimer: 0,
      showQuestion: false,
      playbackUsers: [],
      peakActiveUsersCount: [],
      currentActiveUsersCount: 0,
      socket: null,
    };

    this.publisherProperties = {
      videoSource: null,
      insertMode: 'append',
      name: props.user.name || 'noon',
    };

    this.publisherEventHandlers = {};
    this.teacherLeftTimer = null;
    this.canvasEventTimeout = [];
    this.questionTimerInterval = null;
    this.eventsIds = [];
    this.playbackJoined = false;
  }

  componentDidMount() {
    this.connectSocket();
  }

  componentDidUpdate({ token }) {
    if (token && token !== this.props.token) {
      if (this.socket) {
        this.socket.disconnect();
        this.socket = null;
      }
      this.connectSocket();
    }
  }

  componentWillUnmount() {
    if (this.socket) this.socket.disconnect();
    if (this.questionTimerInterval) clearInterval(this.questionTimerInterval);
    if (this.eventLoopPolling) clearInterval(this.eventLoopPolling);
    if (this.playbackInterval) {
      clearInterval(this.playbackInterval);
    }
    if (this.playbackFirstTickTimeout) {
      clearTimeout(this.playbackFirstTickTimeout);
    }
    if (this.attendeesInterval) {
      clearInterval(this.attendeesInterval);
    }
    if (this.canvasEventTimeout && this.canvasEventTimeout.length > 0) {
      this.canvasEventTimeout.forEach((item) => {
        clearTimeout(item);
      });
    }
  }

  imagePreload = (data = {}) => {
    const keysForArray = Object.keys(data).filter(key => data[key].length > 0);
    keysForArray.forEach((x) => {
      const { e, t, p } = data[x][0].payload || {};
      const { u } = p || {};
      if (e === 'ADD' && t === 'image' && !!p && !!u) {
        const myImage = new Image();
        myImage.crossOrigin = 'Anonymous';
        myImage.src = u;
      }
    });
  };

  connectSocket = (trialNum) => {
    const { peakActiveUsersCount, currentActiveUsersCount } = this.state;
    const { tenant } = localStorage.temporaryTokenPayload ? JSON.parse(localStorage.temporaryTokenPayload) : {};
    if (this.socket) return false;
    if (trialNum > 10) {
      addToast('Network Error ! Please try after some time', TOAST_TYPE.ERROR);
      this.props.onExit('Network Error ! Please try after some time', {
        peakActiveUsersCount,
        currentActiveUsersCount,
      });
      return false;
    }
    this.socket = io(process.env.REPLAY_SRV_SMALL, {
      path: process.env.REPLAY_SRV_SUFFIX,
      query: `${
        this.props.token ? `token=${this.props.token}&` : ''
      }os-details=${osName}:${osVersion}&browser=${browserName}&country=${getCountryId()}&device-details=${mobileVendor}:${mobileModel}&brand-details=${browserName}:${browserVersion}:0&x-client-time=${Date.now()}&x-device-id=${
        this.props.temporaryTokenPayload.device_id
      }&api-version=2&role=teacher`,
      transports: ['websocket'],
    });

    this.setState({ socket: this.socket });

    this.socket.on('error', (res) => {
      setTimeout(() => {
        if (this.socket) this.socket.disconnect();
        this.connectSocket(++socketTrialNumber);
      }, 1000);
    });

    this.socket.on('connect', () => {
      if (this.playbackJoined) this.joinPlayback();
    });

    this.socket.on('playback_users_server', (payload) => {
      console.log('walk playback_users_server', payload);
      this.setPlaybackUsers(payload.users);
    });

    this.socket.on('last_seek_server', (payload) => {
      const { user } = this.props;
      console.log('walk last_seek_server', payload);
      if (user.user_id !== parseInt(payload.user_id, 10)) this.addRemovePlaybackUser(payload);
    });

    this.socket.on('playback_user_left', (payload) => {
      this.addRemovePlaybackUser(payload, true);
    });

    this.socket.on('connection_event', (payload) => {
      const { peakActiveUsersCount, currentActiveUsersCount } = this.state;
      if (payload.type === 'need_to_disconnect') {
        addToast('لقد كنت متصلا في جهاز آخر ، فصل من هنا', TOAST_TYPE.INFO, 5);
        setTimeout(() => {
          this.props.onExit('لقد كنت متصلا في جهاز آخر ، فصل من هنا', {
            peakActiveUsersCount,
            currentActiveUsersCount,
          });
        }, 7000);
      }
    });

    return true;
  };

  setSessionDuration = (audioDuration) => {
    this.setState({ audioDuration }, () => {
      this.joinPlayback();
    });
  };

  handlePause = () => {
    this.setState({
      playerState: 'play',
    });
    if (this.questionTimerInterval) {
      clearInterval(this.questionTimerInterval);
    }
    if (this.eventLoopPolling) {
      clearInterval(this.eventLoopPolling);
    }
    if (this.playbackFirstTickTimeout) {
      clearTimeout(this.playbackFirstTickTimeout);
    }
    if (this.playbackInterval) {
      clearInterval(this.playbackInterval);
    }
    if (this.canvasEventTimeout && this.canvasEventTimeout.length > 0) {
      this.canvasEventTimeout.forEach((item) => {
        clearTimeout(item);
      });
    }
  };

  // OTHER PLAYBACK USERS CODE BEGINS

  // Send last tick to server
  sendLastSeek = (timestamp) => {
    if (timestamp) {
      console.log('walk update_last_seek', timestamp);
      this.socket.emit('update_last_seek', { timestamp }, (error) => {
        if (error) {
          addToast(error.msg || translationText(this.props.noonText, 'error.failedSendPosition'), TOAST_TYPE.WARNING);
        }
      });
    }
  };

  // Update/increment students' position on the seek bar
  updateStudentsPosition = () => {
    const { playbackUsers, audioDuration } = this.state;
    const tempStudents = [];
    playbackUsers.forEach((student) => {
      if (student.current_position <= student.stop_at) {
        const updatedStudent = {
          ...student,
          current_position: Math.min(student.current_position + 1, audioDuration),
        };
        tempStudents.push(updatedStudent);
      } else tempStudents.push(student);
    });
    this.setState({ playbackUsers: tempStudents });
  };

  // Set the initial list of users viewing playback that we get on joining playback
  setPlaybackUsers = (students) => {
    const { audioStartTime } = this.state;
    const { user } = this.props;
    if (this.attendeesInterval) {
      clearInterval(this.attendeesInterval);
    }
    this.setState({
      peakActiveUsersCount: students.map(student => parseInt(student.user_id, 10)),
      currentActiveUsersCount: students.length,
    });
    const tempStudents = [];
    students
      .filter(student => parseInt(student.user_id, 10) !== user.user_id)
      .forEach((student) => {
        const currentTime = Math.ceil((student.last_seek - audioStartTime) / 1000);
        const nextTick1 = currentTime + 60;
        const newStudent = {
          ...student,
          current_position: student.last_seek === '0' ? 0 : currentTime,
          prev_played_seek: student.last_seek,
          stop_at: student.last_seek === '0' ? 0 : nextTick1,
        };
        tempStudents.push(newStudent);
      });
    this.setState({ playbackUsers: tempStudents }, () => {
      this.attendeesInterval = setInterval(() => {
        this.updateStudentsPosition(true);
      }, 1000);
    });
  };

  // Update a users position on playback
  updateUsersLastSeek = (data) => {
    const { playbackUsers, audioStartTime, audioDuration } = this.state;
    let updatedUserObject = playbackUsers.length ? find(playbackUsers, { user_id: data.user_id }) : data;
    if (isEmpty(updatedUserObject)) return;
    const currentTime = Math.ceil((data.last_seek - audioStartTime) / 1000);
    const nextTick = currentTime + 60;
    updatedUserObject = {
      ...updatedUserObject,
      prev_played_seek: updatedUserObject.last_seek,
      last_seek: data.last_seek,
      current_position: Math.min(currentTime, audioDuration),
      stop_at: nextTick,
    };
    const tempStudents = playbackUsers.filter(user => user.user_id !== data.user_id);
    tempStudents.push(updatedUserObject);
    this.setState({ playbackUsers: tempStudents });
  };

  // Add or remove a playback user based on socket events
  addRemovePlaybackUser = (payload, remove) => {
    const { playbackUsers } = this.state;
    const currentUserIndex = playbackUsers.findIndex(
      user => parseInt(user.user_id, 10) === parseInt(payload.user_id, 10),
    );
    if (remove) {
      this.setState((prevState) => {
        const filterPlaybackUsers = prevState.playbackUsers.filter(item => Number(item.user_id) !== Number(payload.user_id));
        return {
          playbackUsers: filterPlaybackUsers,
          currentActiveUsersCount: filterPlaybackUsers.length,
        };
      });
    } else if (!remove && currentUserIndex === -1) {
      this.setState(
        prevState => ({
          playbackUsers: [...prevState.playbackUsers, payload],
          currentActiveUsersCount: prevState.currentActiveUsersCount + 1,
          peakActiveUsersCount:
            prevState.peakActiveUsersCount.indexOf(parseInt(payload.user_id, 10)) === -1
              ? prevState.peakActiveUsersCount.concat(parseInt(payload.user_id, 10))
              : prevState.peakActiveUsersCount,
        }),
        () => {
          this.updateUsersLastSeek(payload);
        },
      );
    } else {
      this.updateUsersLastSeek(payload);
    }
  };
  // OTHER PLAYBACK USERS CODE ENDS

  joinPlayback = () => {
    const { sessionData } = this.props;
    const { peakActiveUsersCount, currentActiveUsersCount } = this.state;
    this.socket.emit(
      'join_playback',
      {
        groupSessionId: sessionData.id,
      },
      (error, resp) => {
        const { audioDuration } = this.state;
        if (error) {
          addToast(error.msg || 'Unable to plaback session', TOAST_TYPE.ERROR);
          this.props.onExit(error.msg || 'Unable to plaback session', {
            peakActiveUsersCount,
            currentActiveUsersCount,
          });
        } else if (!this.playbackJoined && audioDuration) {
          // added 'playbackJoined' variable to call joinPlayback function on reconeect socket without running below logic
          if (resp && resp.data && resp.data.values && resp.data.values.length) {
            const audioStartTime = new Date(resp.data.values[0].timestamp).getTime();
            const sessionEndTime = audioStartTime + Math.round(audioDuration * 1000);
            this.setState({
              audioStartTime,
              currentTickTime: audioStartTime,
              sessionEndTime,
              index: 0,
            });
            this.playbackJoined = true;
          } else {
            this.props.onExit('No Audio time', { peakActiveUsersCount, currentActiveUsersCount });
          }
        }
      },
    );
  };

  seekData = (time, cb, playerState) => {
    if (!this.socket) {
      cb(false);
      return false;
    }
    if (!this.playbackJoined) {
      console.log('Please wait for u to join the session');
      cb(false);
      return false;
    }
    const { audioStartTime } = this.state;
    const delta = Math.round(time * 1000);
    const newTickTime = audioStartTime + delta;
    this.setState(
      {
        playerState: 'pause',
        lastTickTime: this.state.currentTickTime,
        currentTickTime: newTickTime,
        sketchData: [],
        sketchDataPlayback: [],
        showQuestion: false,
        getReadyTimer: 0,
        questionTimer: 0,
      },
      () => {
        // clear temp drawing
        if (this.eventsIds.length) {
          whiteboardRef.current.deleteShapeFromParent(this.eventsIds);
          this.eventsIds = [];
        }
        if (this.eventLoopPolling) {
          clearInterval(this.eventLoopPolling);
        }
        if (this.canvasEventTimeout && this.canvasEventTimeout.length > 0) {
          this.canvasEventTimeout.forEach((item) => {
            clearTimeout(item);
          });
        }
        if (this.questionTimerInterval) {
          clearInterval(this.questionTimerInterval);
        }
        this.seekPlaybackInitial((res) => {
          cb(res);
        }, playerState);
        this.seekInitialPreviousChats();
      },
    );
  };

  seekInitialPreviousChats = () => {
    const { currentTickTime } = this.state;
    this.socket.emit('get_playback_chat', { timestamp: currentTickTime }, (error, resp) => {
      if (resp) {
        const payload = resp;
        if (payload) {
          const { group_messages, offsetCount } = payload;
          const chats = [];
          group_messages.forEach((chat) => {
            const regex = emojiRegex();
            let match;
            let emoji;
            let { msg } = chat;
            while ((match = regex.exec(chat.msg))) {
              [emoji] = match;
              msg = msg.replace(emoji, `<span>${emoji}</span>`);
            }
            if (emoji && emoji.length + 13 === msg.length) {
              msg = msg.replace('<span>', '<span class="only">');
            }
            if (chats.length >= 1 && chats[chats.length - 1].user_id === chat.user_id && isEmpty(chat.data.for_user)) {
              chats[chats.length - 1].messages.push({
                time: chat.data.time,
                likes: chat.data.likes,
                msg,
                image_thumbnail_uri: chat.data.image_thumbnail_uri,
                image_type: chat.data.image_type,
              });
            } else {
              chats.push({
                name: chat.data.name,
                gender: chat.data.gender,
                user_id: chat.data.user_id,
                pic: chat.data.pic,
                messages: [
                  {
                    msg,
                    likes: chat.data.likes,
                    time: chat.data.time,
                    for_user: chat.data.for_user,
                    image_thumbnail_uri: chat.data.image_thumbnail_uri,
                    image_type: chat.data.image_type,
                  },
                ],
              });
            }
          });
          this.setState({
            chats: chats || [],
            offsetCount,
          });
        }
      }
    });
  };

  seekPlaybackInitial = (cb, playerState) => {
    const { currentTickTime, lastTickTime, peakActiveUsersCount, currentActiveUsersCount } = this.state;
    this.socket.emit('playback_seek', { timestamp: currentTickTime }, (error, resp) => {
      if (resp && resp.data) {
        const firstTickSecond = new Date(currentTickTime).getSeconds();
        const payload = resp.data;
        if (!payload.cubeData) {
          cb(false);
          return;
        }
        const { cubeData, sketchData, tick, event_type } = payload;

        this.imagePreload(tick);

        // hacked the shit out of it
        this.setState(
          {
            activeSlide: !cubeData.number ? 1 : 0,
            activeSlideData: cubeData || {},
            sketchData: sketchData && sketchData.length ? sortBy(sketchData, [o => o.timestamp]) : [],
            sketchDataPlayback: tick,
            index: firstTickSecond,
            showQuestion: event_type !== 'ask_students' && cubeData.resource_type === 'question',
          },
          () => {
            this.setState(
              {
                activeSlide: cubeData.number,
              },
              () => {
                if (playerState === 'play') {
                  cb(false);
                  return;
                }
                cb(true);
                if (event_type === 'ask_students') {
                  this.interactiveQuestion(cubeData);
                }
                if (this.playbackFirstTickTimeout) {
                  clearTimeout(this.playbackFirstTickTimeout);
                }
                if (this.eventLoopPolling) {
                  clearInterval(this.eventLoopPolling);
                }
                if (this.playbackInterval) {
                  clearInterval(this.playbackInterval);
                }
                this.eventLoopPolling = setInterval(() => {
                  this.sendPlaybackDataToCanvas();
                }, 1000);
                this.playbackFirstTickTimeout = setTimeout(() => {
                  this.setState(
                    prevState => ({ currentTickTime: prevState.currentTickTime + 60000 }),
                    () => {
                      this.playbackNextTick();
                      this.playbackInterval = setInterval(() => {
                        this.setState(
                          prevState => ({ currentTickTime: prevState.currentTickTime + 60000 }),
                          () => {
                            this.playbackNextTick();
                          },
                        );
                      }, 60000);
                    },
                  );
                }, (60 - firstTickSecond) * 1000);
              },
            );
          },
        );
      }
      if (error) {
        cb(false);
        addToast(error.msg || 'Unable to get  playback_next_tick data', TOAST_TYPE.ERROR);
        this.props.onExit(error.msg || 'Unable to get  playback_next_tick data', {
          peakActiveUsersCount,
          currentActiveUsersCount,
        });
      }
    });
  };

  playbackNextTick = () => {
    const { currentTickTime, sessionEndTime, peakActiveUsersCount, currentActiveUsersCount } = this.state;
    const tickTimeFormated = moment(currentTickTime);
    const tickTime = tickTimeFormated.startOf('minute');
    if (tickTime.valueOf() <= sessionEndTime) {
      this.socket.emit('playback_next_tick', { timestamp: tickTime.valueOf() }, (error, resp) => {
        if (resp && resp.data) {
          // this.sendLastSeek(tickTime.valueOf()); // this wont work teacher side yet. Need backend effort to make this work.
          this.imagePreload(resp.data);

          this.setState({ sketchDataPlayback: resp.data, index: 0 });
        }
        if (error) {
          addToast(error.msg || 'Unable to get  playback_next_tick data', TOAST_TYPE.ERROR);
          this.props.onExit(error.msg || 'Unable to get  playback_next_tick data', {
            peakActiveUsersCount,
            currentActiveUsersCount,
          });
        }
      });
    } else {
      this.handlePause();
    }
  };

  sendPlaybackDataToCanvas = () => {
    let lastTime = 0;
    const { sketchDataPlayback, index, activeSlide, currentTickTime } = this.state;
    const paddedSecondBucket = `${index}`.padStart(2, 0);
    if (
      sketchDataPlayback[paddedSecondBucket] &&
      sketchDataPlayback[paddedSecondBucket].length > 0 &&
      parseInt(paddedSecondBucket, 10) <= 59
    ) {
      const tempData = sketchDataPlayback[paddedSecondBucket];
      // const tempLength = tempData.length;
      // const evenlyTimeDistribdution = 1000 / tempLength;
      const sortedEvents = sortBy(tempData, [o => o.timestamp]);
      sortedEvents.map((item) => {
        if (item && item.typ === 'add_cube_server') {
          const { cubeData } = item;
          const sketchDataVar = item.sketchData;
          this.setState({
            activeSlideData: cubeData || {},
            activeSlide: cubeData.number,
            sketchData: sketchDataVar || [],
            showQuestion: cubeData.resource_type === 'question',
          });
        } else if (item && item.typ === 'ask_students') {
          this.interactiveQuestion(item.currentCube);
        } else if (item && item.typ === 'chat') {
          if (item.msg || !isEmpty(item.for_user) || item.image_thumbnail_uri) {
            const { chats } = this.state;
            const regex = emojiRegex();
            let match;
            let emoji;
            let { msg } = item;
            while ((match = regex.exec(item.msg))) {
              [emoji] = match;
              msg = msg.replace(emoji, `<span>${emoji}</span>`);
            }
            if (emoji && emoji.length + 13 === msg.length) {
              msg =
                emoji === '❤️'
                  ? msg.replace('<span>', '<span class="only animated infinite pulse">')
                  : msg.replace('<span>', '<span class="only">');
            }
            if (chats.length >= 1 && chats[chats.length - 1].user_id === item.user_id && isEmpty(item.for_user)) {
              const lastChat = chats[chats.length - 1];
              lastChat.messages.push({
                time: item.time,
                likes: item.likes,
                msg,
                image_thumbnail_uri: item.image_thumbnail_uri,
                image_type: item.image_type,
              });
              this.setState({
                chats: [...chats.slice(0, chats.length - 1), lastChat],
              });
            } else {
              this.setState({
                chats: [
                  ...chats.splice(-CHAT_THRESHOLD),
                  {
                    name: item.name,
                    gender: item.gender,
                    user_id: item.user_id,
                    pic: item.pic,
                    messages: [
                      {
                        msg,
                        likes: item.likes,
                        time: item.time,
                        for_user: item.for_user,
                        image_thumbnail_uri: item.image_thumbnail_uri,
                        image_type: item.image_type,
                      },
                    ],
                  },
                ],
              });
            }
          }
        } else if (item && item.typ === 'canvas_events') {
          this.canvasEventTimeout.push(
            setTimeout(
              () => {
                whiteboardRef.current.movableCanvas.handleTalkBoxEvent(
                  ['DOWN_TEMP', 'UP_TEMP', 'MOVE_TEMP'].includes(item.payload.e)
                    ? { ...item, payload: { ...item.payload, ex: Date.now() + 10000 } }
                    : item,
                );
                this.setState({ currentEvent: { slideNumber: activeSlide, item } });
              },
              lastTime ? item.timestamp - lastTime : 0,
            ),
          );
          lastTime = sortedEvents[0].timestamp;
          if (this.state.showQuestion) {
            this.setState({ showQuestion: false });
          }
        }
      });
    }
    // Send last seek for this student
    // if (index === 0) {
    //   const tickTimeFormated = moment(currentTickTime);
    //   const tickTime = tickTimeFormated.startOf('minute');
    //   this.sendLastSeek(tickTime.valueOf());
    // }
    this.setState(prevState => ({ index: prevState.index + 1 }));
  };

  interactiveQuestion = (cubeData) => {
    this.setState(
      {
        activeSlideData: { number: cubeData.number || 0, resource: cubeData },
        activeSlide: cubeData.number,
        sketchData: [],
        showQuestion: true,
        getReadyTimer: cubeData.time_left > 0 && cubeData.time_left === cubeData.time ? cubeData.get_ready : 0,
        questionTimer: cubeData.time_left > 0 ? cubeData.time_left : 0,
      },
      () => {
        if (this.questionTimerInterval) {
          clearInterval(this.questionTimerInterval);
        }
        this.questionTimerInterval = setInterval(() => {
          if (this.state.getReadyTimer > 0) {
            this.setState(prevState => ({
              getReadyTimer: prevState.getReadyTimer - 1,
            }));
          } else {
            this.setState(prevState => ({
              questionTimer: prevState.questionTimer > 0 ? prevState.questionTimer - 1 : 0,
            }));
          }
          if (this.state.questionTimer < 1) {
            clearInterval(this.questionTimerInterval);
          }
        }, 1000);
      },
    );
  };

  toggleChat = () => {
    this.setState(prevState => ({ isChatVisible: !prevState.isChatVisible }));
  };

  recordTempSketch = (eventId) => {
    this.eventsIds.push(eventId);
  };

  render() {
    const {
      activeSlide,
      studentSupportArr,
      sketchData,
      playerState,
      chats,
      isChatVisible,
      activeSlideData,
      questionTimer,
      getReadyTimer,
      showQuestion,
      playbackUsers,
      peakActiveUsersCount,
      currentActiveUsersCount,
      audioStartTime,
      socket,
    } = this.state;
    return (
      <ErrorBoundry>
        <React.Fragment>
          {socket ? (
            this.props.children({
              whiteboardRef,
              seekData: this.seekData,
              handlePlay: this.handlePlay,
              handlePause: this.handlePause,
              setSessionDuration: this.setSessionDuration,
              toggleChat: this.toggleChat,
              recordTempSketch: this.recordTempSketch,
              studentSupportArr,
              sketchData,
              activeSlide,
              playerState,
              chats,
              isChatVisible,
              activeSlideData,
              questionTimer,
              getReadyTimer,
              showQuestion,
              playbackUsers,
              audioStartTime,
              peakActiveUsersCount,
              currentActiveUsersCount,
            })
          ) : (
            <NoonLoader />
          )}
        </React.Fragment>
      </ErrorBoundry>
    );
  }
}

export default PlaybackSocketContainer;

import {
  CLEAR_ERROR,
  GET_PROFILE,
  LIST_TEACHERS,
  PROFILE,
  TIMELINE,
  GET_TEACHER_DETAIL,
  TIMELINE_UPDATE,
  GET_TEACHER_REVIEWS,
  CLEAR_USER_SPECIFIC_USER_DATA,
  SERVER_TIME,
} from '../constants';

const initialState = {
  isLoginLoading: false,
  loggedUser: {},
  pinnedTeachers: [],
  verifyOtpResponse: {},
  verifyPasswordResponse: {},
  teacherReviews: {
    list: [],
    meta: {},
  },
  profile: {
    loading: false,
    success: false,
    error: false,
  },
  teacherDetail: {},
  timeline: {
    offset: 0,
    count: 0,
    list: [],
    isLoading: false,
    unreadCount: 0,
  },
  timelineUpdate: [],
  error: {},
  serverTime: new Date().getTime(),
  timeDifference: 0,
  notificationRequestCount: 0,
};

function formulateAndSaveUserData(data) {
  localStorage.setItem('loggedUser', JSON.stringify({ id: data.id, ...data }));
  return { id: data.user_id, ...data };
}

export default function user(state = initialState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      if (action.payload === 'user') {
        return {
          ...state,
          error: {},
        };
      }
      return {
        ...state,
      };
    case CLEAR_USER_SPECIFIC_USER_DATA:
      return {
        ...state,
        loggedUser: {},
        pinnedTeachers: [],
        verifyOtpResponse: {},
        verifyPasswordResponse: {},
        teacherReviews: {
          list: [],
          meta: {},
        },
        profile: {
          loading: false,
          success: false,
          error: false,
        },
        teacherDetail: {},
        timeline: {
          offset: 0,
          count: 0,
          list: [],
        },
        timelineUpdate: [],
      };
    case GET_TEACHER_REVIEWS.SUCCESS:
      return {
        ...state,
        teacherReviews: {
          list:
            action.payload.start === 0 ? action.payload.list : [...state.teacherReviews.list, ...action.payload.list],
          meta: action.payload.meta,
        },
      };
    case GET_TEACHER_REVIEWS.FAILURE:
      return {
        ...state,
        error: { teacherReviews: action.payload },
      };
    case GET_PROFILE.SUCCESS:
      return {
        ...state,
        loggedUser: formulateAndSaveUserData(action.payload),
      };
    case LIST_TEACHERS.SUCCESS:
      return {
        ...state,
        pinnedTeachers: action.payload,
      };
    case LIST_TEACHERS.FAILURE:
      return {
        ...state,
        error: {
          pinnedTeachers: action.payload,
        },
      };
    case GET_TEACHER_DETAIL.SUCCESS:
      return {
        ...state,
        teacherDetail: action.payload,
      };
    case GET_TEACHER_DETAIL.FAILURE:
      return {
        ...state,
        error: { teacherDetail: action.payload },
      };
    case TIMELINE.REQUEST:
      return {
        ...state,
        timeline: { 
          ...state.timeline,
          isLoading: true,
        },
      };  
    case TIMELINE.SUCCESS:
      const list = [...state.timeline.list, ...action.payload.list];
      const unreadCount = list.filter(o => !o.notification_feed.notification_viewed).length;
      return {
        ...state,
        timeline: { 
          ...action.payload,
          list,
          unreadCount,
          isLoading: false,
        },
      };
    case TIMELINE.FAILURE:
      return {
        ...state,
        timeline: { 
          ...state.timeline,
          isLoading: false,
        },
        error: {
          timeline: action.payload,
        },
      };
    case TIMELINE_UPDATE.SUCCESS:
      return {
        ...state,
        timeline: action.payload,
      };
    case TIMELINE_UPDATE.FAILURE:
      return {
        ...state,
        error: {
          timelineUpdate: action.payload,
        },
      };
    case PROFILE.REQUEST:
      return {
        ...state,
        profile: {
          loading: true,
          success: false,
          error: false,
        },
      };
    case PROFILE.SUCCESS:
      return {
        ...state,
        profile: {
          loading: false,
          success: true,
          error: false,
        },
      };
    case PROFILE.FAILURE:
      return {
        ...state,
        profile: {
          loading: false,
          success: false,
          error: true,
        },
        error: {
          profile: action.payload,
        },
      };
    case SERVER_TIME.SUCCESS:
      return {
        ...state,
        serverTime: action.payload.current_time,
        timeDifference: (action.payload.current_time + action.delta) - new Date().getTime(),
      };
    case SERVER_TIME.FAILURE:
      return {
        ...state,
        error: {
          serverTime: action.payload,
        },
        timeDifference: 0,
      };
    default:
      if (action.type.indexOf('_REQUEST') !== -1) {
        return {
          ...state,
          error: {},
        };
      }
      return state;
  }
}

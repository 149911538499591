import React from 'react';
import { Card, Row } from '@noon/atom';
import { IconClose } from '@noon/quark';
import t from '../../../helpers/translate';

type IHowItWorks ={
  onClose:Function
}

const HowItWorks = (props:IHowItWorks) => (
  <Card className="how-it-works-dialog padding">
    <Row className="title-wrapper" align="space-between">
      <span className="title">{t('teamQnaBreakout', 'howItWorksTitle')}</span>
      <IconClose className="close" width="16px" onClick={props.onClose} />
    </Row>
    <ol>
      <li>{t('teamQnaBreakout', 'step1')}</li>
      <li>{t('teamQnaBreakout', 'step2')}</li>
      <li>{t('teamQnaBreakout', 'step3')}</li>
    </ol>
  </Card>
);

export default HowItWorks;

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import isEmpty from 'lodash-es/isEmpty';
import { useHistory } from 'react-router-dom';
import { IconDelete, IconFlag } from '@noon/quark';
import { Column, Row, NoonDate, MoreActions, Badge } from '@noon/atom';
import { DELETE_HOMEWORK_SPARTA } from '../../redux/constants';
import { ConfirmationModal } from '../Modals';
import { translationText } from '../../helpers';
import EditAssignmentModal from '../PrepareAssignment/editAssignmentModal';
import Mixpanel from '../Mixpanel';
import { returnDuplicatePropsForMixpanelAndKafka } from '../../helpers/prepareAssignment';

export default function PreviousAssignmentCard(prop) {
  const { data: activity_content, cardView } = prop;
  const dispatch = useDispatch();
  const [showItem, assignItem] = useState('');
  const [itemActions, assignitemActions] = useState([]);
  const logger = useRef(LoggingManager.mount({ moduleName: 'homework' })).current;
  const { deleteHomework } = useSelector((state) => state.toJS().homeworkCreation);
  const noonText = useSelector((state) => state.toJS().translation.noonText);
  const { loggedUser: user } = useSelector((state) => state.toJS().user);
  const { selectedGroup } = useSelector((state) => state.toJS().groupsV2);

  const history = useHistory();
  const isRtl = document.body.dir === 'rtl';
  const firstChapter =
    !isEmpty(activity_content.groups) &&
    !isEmpty(activity_content.groups[0].chapters) &&
    activity_content.groups[0].chapters[0];
  const firstTopic =
    !isEmpty(activity_content.groups) &&
    !isEmpty(activity_content.groups[0].topics) &&
    activity_content.groups[0].topics[0];

  const mixpanelKafkaEvents = (type) => {
    const props = returnDuplicatePropsForMixpanelAndKafka(selectedGroup, user, activity_content);
    const no_of_questions =
      activity_content && activity_content.question_ids ? activity_content.question_ids.length : 0;
    const properties = {
      ...(type.kf === 'card_clicked' && {
        action_intended: isMobileOnly ? 'full_card_tap' : no_of_questions ? 'modify' : 'prepare',
      }),
      no_of_questions,
      ...props,
    };
    Mixpanel.track(type.mp, properties);
    logger.track(type.kf, { homework: properties });
  };

  useEffect(() => {
    if (deleteHomework && !deleteHomework.loading && deleteHomework.success) {
      assignItem('');
    }
  }, [deleteHomework]);

  useEffect(() => {
    if (showItem === 'report') {
      mixpanelKafkaEvents({ mp: 'homework_card_clicked', kf: 'card_clicked' });
      history.push(`/report/${activity_content.group_id}/${activity_content.id}`);
    }
  }, [showItem]);

  const assignAction = () => {
    if (!itemActions.action) {
      if (activity_content.state === 'active') {
        if (activity_content.question_ids.length > 0) {
          assignitemActions([{ text: 'activity.viewReport', action: 'report' }]);
        } else {
          assignitemActions([{ text: 'activity.prepare', action: 'prepare' }]);
        }
      } else {
        assignitemActions([{ text: 'activity.viewReport', action: 'report' }]);
      }
    }
  };

  useEffect(() => {
    assignAction();
  }, [activity_content.question_ids]);

  const getActivityClasses = () =>
    `flex-row activity-card activity-card--list-view activity-card--grid-view type_assignment hidden ${cardView}-page`;

  const takeAction = (actionType) => {
    if (actionType === 'prepare') assignItem('edit');
    if (actionType === 'publish') {
      mixpanelKafkaEvents({ mp: 'homework_publish_intended', kf: 'publish_intended' });
      assignItem('publish');
    }
    if (actionType === 'report') {
      assignItem('report');
    } else if (actionType === 'delete') {
      assignItem('delete');
    }
  };

  const takeActionOnPhone = (action) => {
    if (isMobileOnly) {
      takeAction(action);
    }
  };

  const deleteAssignmentFn = () => {
    mixpanelKafkaEvents({ mp: 'homework_deleted', kf: 'deleted' });
    dispatch({
      type: DELETE_HOMEWORK_SPARTA.REQUEST,
      payload: activity_content.id,
      listing: 'published',
    });
  };

  const getOptions = () => {
    const moreActionList = [
      {
        name: 'delete',
        icon: IconDelete,
        text: translationText(noonText, 'activity.delete'),
      },
    ];
    return moreActionList;
  };

  const AssignmentTypeBlock = () => (
    <Row className="type-block" align="center" justify="space-evenly">
      <IconFlag height="15px" width="15px" fill="#B1F0E4" />
      <span>{translationText(noonText, 'homework.title')}</span>
    </Row>
  );

  const AssignmentChapterTopicBlock = () => (
    <React.Fragment>
      {activity_content.groups && activity_content.groups[0] && (
        <p className="chapter-topic">
          {firstChapter && firstChapter.name}
          {firstChapter && firstTopic ? ', ' : ''}
          {firstTopic && firstTopic.name_header}
        </p>
      )}
    </React.Fragment>
  );

  const PlannerPageInfo = () => (
    <React.Fragment>
      <li className="card-section card-section--time" onClick={() => takeActionOnPhone(itemActions[0].action)}>
        <Column align="center" justify="center" gap="sm" flex="1" className="time-wrapper">
          <IconFlag height="15px" width="15px" fill="#B1F0E4" />
        </Column>
      </li>
      <li className="card-section card-section--main" onClick={() => takeActionOnPhone(itemActions[0].action)}>
        <Row className="main-content main-content--type-info">
          <AssignmentTypeBlock />
          <h6 className="group-name">
            <span className="noon-dot" />
            {activity_content.title}
          </h6>
        </Row>
        <Row className="main-content main-content--other-info">
          {/* <AssignmentTimeBlock /> */}
          <AssignmentChapterTopicBlock />
        </Row>
        <Row className="main-content main-content--name-area">
          <h5>{activity_content.title}</h5>
        </Row>
      </li>
    </React.Fragment>
  );

  const ContentPageInfo = () => (
    <React.Fragment>
      <li className="card-section card-section--time" onClick={() => takeActionOnPhone(itemActions[0].action)}>
        <Column align="center" justify="center" gap="sm" flex="1" className="time-wrapper">
          <Row align="center" className="date-block" gap="sm">
            <NoonDate style={{ fontWeight: 'bold' }} humanize value={activity_content.due_date} format="MMMM, d" />
          </Row>
          <Row align="center" className="time-block" gap="sm">
            <IconFlag height="15px" width="15px" fill="#B1F0E4" />
            <NoonDate value={activity_content.due_date} format="hh:mm a" />
          </Row>
        </Column>
      </li>
      <li className="card-section card-section--main" onClick={() => takeActionOnPhone(itemActions[0].action)}>
        <Row className="main-content main-content--type-info">
          {/* <AssignmentTypeBlock /> */}
          <span style={{ margin: '0 4px' }} className="students-attempted">
            {`
              ${translationText(noonText, 'createHomework.attemptedBy')}
              ${activity_content.homework_attempted}
              ${translationText(noonText, 'groups.followers')}
            `}
          </span>
          <AssignmentChapterTopicBlock />
        </Row>
        <Row align="center" className="main-content main-content--name-area">
          <h5 style={{ margin: '0 4px' }}>{activity_content.title}</h5>
        </Row>
      </li>
    </React.Fragment>
  );

  return (
    <Row style={{ backgroundColor: '#ffffff', position: 'relative' }} className={getActivityClasses()}>
      {showItem === 'delete' && (
        <ConfirmationModal
          modalType="delete"
          successBtn={translationText(noonText, 'activity.modalYes')}
          closeBtn={translationText(noonText, 'activity.modalNo')}
          onClose={() => assignItem('')}
          onSuccess={() => deleteAssignmentFn()}
          isLoading={deleteHomework.loading}
          text={translationText(noonText, 'teacherDashboard.deleteHomework')}
          subText={translationText(noonText, 'teacherDashboard.deleteConfirmation')}
        />
      )}
      {!activity_content.is_free && (
        <Badge type="default" dir="ltr" className="plus-badge">
          <span>{translationText(noonText, 'groups.plus')}</span>
        </Badge>
      )}

      {showItem === 'edit' && <EditAssignmentModal homework={activity_content} setIsEditOpen={() => assignItem('')} />}
      {/* Separated out time and main sections to reduce clutter */}
      {cardView === 'content' ? <ContentPageInfo /> : <PlannerPageInfo />}
      <li className="card-section card-section--action">
        <MoreActions
          type="dots"
          position={isRtl ? 'right' : 'left'}
          className="activity"
          listActions={getOptions()}
          cardId={activity_content.id}
          onSelect={(actionName) => takeAction(actionName)}
          vertical
        />
        <Row className="data" flex="1">
          <p>
            <Column className="action-items">
              {itemActions.length &&
                itemActions.map((item) => (
                  <span key={item.text} onClick={() => takeAction(item.action)}>
                    {translationText(noonText, item.text)}
                  </span>
                ))}
            </Column>
          </p>
        </Row>
      </li>
    </Row>
  );
}

import { ADD_SLIDE } from '../../../redux/constants';
import EM from '../../../providers/Event/EM';
import { CLASSROOM_EVENT } from '../../Classroom/events';
import store from '../../../store';
import { CLASSROOM_STATE, ISlidePayload } from '../types';

export default function handleMsg(dispatch, e) {
  if (process.env.ENV !== 'production') {
    console.log('handle message', e);
  }
  if (!e.message?.canvas_id) {
    dispatch({ type: ADD_SLIDE.FAILURE, payload: 'CANVAS_ID EMPTY' });
    return;
  }
  const slide: ISlidePayload = e.message;
  
  // addCube event payload
  // PB-2121 - When teacher reload the page or rejoin, teacher get add_cube_server event, in this event sever send property
  // `sender`, on we were sending all the property received from the server, if pubnub received `sender` props then abort the request 
  // to fix that, do not set all the prop received from server and only set required prop in addCube event
  dispatch({ 
    type: ADD_SLIDE.SUCCESS, 
    payload: {
      group_id: slide.group_id,
      number: slide.number,
      active: slide.number,
      label_id: slide.label_id,
      label_name: slide.label_name,
      prev_canvas_id: slide.prev_canvas_id,
      resource: slide.resource,
      resource_id: slide.resource_id,
      resource_type: slide.resource_type,
      topic_id: slide.topic_id,
      canvas_id: slide.canvas_id,
      session_state: slide.session_state,
      activation_time: slide.activation_time,
      breakout_mode: slide.breakout_mode,
      breakout_mode_new: slide.breakout_mode_new,
      created_at: slide.created_at,
      folder_id: slide.folder_id,
      leaf_folder_id: slide.leaf_folder_id,
      raise_hand: slide.raise_hand,
      reviewed: slide.reviewed,
      start_time: slide.start_time,
      style: slide.style,
      visited: slide.visited,
      snapshotUrl: slide.snapshotUrl,
      canvas_start_time: slide.canvas_start_time,
      slideIndex: slide.slideIndex,
      height: slide.height,
      width: slide.width,
      isTeacher: slide.isTeacher,
      activate: slide.activate,
      broadcast: slide.broadcast,
      setReview: slide.setReview,
      boolean: slide.boolean,
    }
  });
  const state = store.getState().toJS().myClassroom?.sessionDetails.state;
  if (process.env.ENV !== 'production') {
    console.log('CNS/SNS- Event', {
      event: 'addCube',
      data: e.message,
      isTeacher: 1,
      width: e.message.width,
      height: e.message.height,
      activate: e.message.activate,
      broadcast: e.message.broadcast,
      prepMode: state !== CLASSROOM_STATE.started,
    });
  }
  EM.sendMessage(CLASSROOM_EVENT.slideEventLog, {
    event: 'addCube',
    data: e.message,
    isTeacher: 1,
    width: e.message.width,
    height: e.message.height,
    activate: e.message.activate,
    broadcast: e.message.broadcast,
    prepMode: state !== CLASSROOM_STATE.started,
  }, { stringify: false });

}

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, InfiniteScroll } from '@noon/atom';
import { IconLoader } from '@noon/quark';
import { debounce, isEmpty, sortBy } from 'lodash-es';
import { Accordian } from '../../Curriculum';
import ActivityReservedCard from './ActivityReservedCard';
import { ScheduleListSessionCard } from '../../Session';
import { translationText } from '../../../helpers';
import ScrollToTop from '../../ScrollToTop';

function ActivityCardListView({
  noonText,
  activitiesList,
  loading,
  isLoadMoreActivities,
  loadMoreActivities,
  toggleCreateActivity,
}) {
  const activitiesGroupedList = Object.keys(activitiesList);
  const elementAccordion = useMemo(() => document.getElementById('activities-accordion-0'), [activitiesList]);
  return (
    <React.Fragment>
      <ScrollToTop element={elementAccordion} text={activitiesGroupedList[0]} />
      {!isEmpty(activitiesGroupedList) && (
        <InfiniteScroll
          onLoad={debounce(loadMoreActivities, 200)}
          loadMore={isLoadMoreActivities}
          onWindow
          offset={300}
          className="general-planner-activities-accordion-list"
        >
          {activitiesGroupedList.map((day, index) => (
            <Accordian
              key={index}
              id={`activities-accordion-${index}`}
              heading={{ label: day }}
              sideheading={`${translationText(noonText, 'planner.activities')} (${activitiesList[day].length})`}
              initialState
              loadingAccordian={false}
            >
              {sortBy(activitiesList[day], 'start_time').map((activity) => (activity.type === 'group' || activity.type === 'competition' ? (
                <ScheduleListSessionCard
                  key={activity.start_time}
                  cardView="planner"
                  data={{ ...activity, title: activity.name, ...activity.meta }}
                  groupId={activity.group_id}
                  toggleCreateActivity={toggleCreateActivity}
                  />
              ) : (
                <ActivityReservedCard
                  noonText={noonText}
                  key={activity.start_time}
                  activity={activity}
                  toggleCreateActivity={toggleCreateActivity}
                  />
              )),
              )}
            </Accordian>
          ))}
        </InfiniteScroll>
      )}
      {!isLoadMoreActivities && !loading && (
        <Row alig="center" justify="center" className="ptb-2">
          <span>{translationText(noonText, 'planner.noMoreActivity')}</span>
        </Row>
      )}
      {loading && (
        <Row alig="center" justify="center" className="ptb-2">
          <IconLoader />
        </Row>
      )}
    </React.Fragment>
  );
}

ActivityCardListView.propTypes = {
  activitiesList: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool,
  isLoadMoreActivities: PropTypes.bool,
  loadMoreActivities: PropTypes.func.isRequired,
  toggleCreateActivity: PropTypes.func.isRequired,
};

ActivityCardListView.defaultProps = {
  loading: false,
  isLoadMoreActivities: false,
};

export default ActivityCardListView;

import { timeParamtersFull } from '../constants';

export default function getTimeDifference(date_past) {
  const epochPastDate = new Date(date_past).getTime();
  let difference = Math.abs(Date.now() - epochPastDate) / 1000;
  let result = {};
  let flag = false;
  const r = {};
  Object.keys(timeParamtersFull).forEach((key) => {
    if (!flag) {
      r[key] = Math.floor(difference / timeParamtersFull[key]);
      difference -= r[key] * timeParamtersFull[key];
      if (r[key]) {
        if (!key || !r[key]) {
          result = { num: 5, timeType: 'seconds' };
        } else {
          result = { num: r[key], timeType: key };
        }
        flag = true;
      } else {
        result = { num: 5, timeType: 'seconds' };
      }
    }
  });
  return result;
}

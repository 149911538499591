import { createSelector } from 'reselect';

const noonTextSelectorRaw = state => state.toJS().translation.noonText;

const noonTextSelector = createSelector(
  noonTextSelectorRaw,
  item => item,
);

export { noonTextSelector };

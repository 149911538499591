import React from 'react';
import { Row } from '@noon/atom';
import { IconStopwatchPlus } from '@noon/quark/dist';
import { translationText } from '../../helpers';

const TimeAssignmentWarning = ({ noonText, date }) => {
  const hours = Math.abs(Math.round((date - new Date().getTime())/1000/3600));
  return (
    <Row align="center" className="time-warning">
      <IconStopwatchPlus height="20px" width="20px" />
      <span>{
        date < new Date().getTime()
          ? `${translationText(noonText, 'createHomework.selectedDueDateHasPassed')} ${hours + 1} ${translationText(noonText, 'createHomework.hoursAway')}`
          : `${translationText(noonText, 'createHomework.dueDateIs')} ${hours} ${translationText(noonText, 'createHomework.giveMoreTime')}`
      }</span>
    </Row>
  );
}

export default TimeAssignmentWarning;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import isEmpty from 'lodash-es/isEmpty';
import classNames from 'classnames';
import { Avatar, Row, Flex, Vr, Button, Column, NoonLink } from '@noon/atom';
import { isMobile, isTablet, isIPad13 } from 'react-device-detect';
import Popover from 'react-popover';
import { IconArrowDown, IconLogo, IconNoon, IconStudentAvatar, IconTeacherAvatar } from '@noon/quark';
import CountryDropdown from '../CountryDropdown';
import { LOGGED_OUT_TEACHER } from '../../redux/constants';
import { generateLocalizedUrl, translationText } from '../../helpers';
import { userType, translationType } from '../../types';
import { COLORS } from '../../constants';

class PublicNav extends Component {
  static propTypes = {
    noonText: translationType.isRequired,
    user: userType.isRequired,
    loggedOut: PropTypes.func.isRequired,
    location: PropTypes.shape().isRequired,
    page: PropTypes.string,
    history: PropTypes.shape().isRequired,
    countries: PropTypes.shape({
      countryList: PropTypes.array,
      selectedCountry: PropTypes.object,
    }).isRequired,
  };

  static defaultProps = {
    page: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      showUserOptions: false,
      showLoginOptions: false,
    };
    this.loginOptions = [
      { name: 'teacher', label: 'teacherDashboard.register_teacher', icon: IconTeacherAvatar },
      { name: 'student', label: 'teacherDashboard.register_student', icon: IconStudentAvatar },
    ];
    this.loginTypeSelection = this.loginTypeSelection.bind(this);
  }

  componentWillReceiveProps({ location }) {
    if (!isEmpty(location) && location !== this.props.location) {
      this.setState({ showUserOptions: false });
    }
  }

  toggleUserOptions = (flag = undefined) => {
    this.setState((prevState) => ({ showUserOptions: flag !== undefined ? flag : !prevState.showUserOptions }));
  };

  logout = () => {
    /* eslint-disable react/prop-types */
    this.props.loggedOut();
  };

  toggleLoginOptions = (showLoginOptions) => {
    this.setState({ showLoginOptions: !!showLoginOptions || !this.state.showLoginOptions });
  }

  loginTypeSelection(type) {
    const { match, history } = this.props;
    if (type === 'student') {
      window.open(`${process.env.STUDENT_URL}${localStorage.updatedLocale}/login`, '_self');
    } else if (type === 'teacher') {
      history.push(`/${match.params.locale}/login`);
    }
    this.toggleLoginOptions(false);
  }

  setLocale(locale) {
    localStorage.setItem('language', locale);
    delete localStorage.translationData;
    const isoCode = localStorage.updatedLocale.split('-')[0];
    const url = generateLocalizedUrl(window.location.pathname, `${isoCode}-${locale.split('_')[0]}`);
    window.location.href = url;
  }

  changeLocale = (localeSelected) => {
    const newLocale = localeSelected.locale;
    this.setLocale(newLocale);
  }

  render() {
    const { user, noonText, countries: { selectedCountry = {} }, history, match } = this.props;
    const isRtl = document.body.dir === 'rtl';
    const locale = localStorage.language;
    const { showUserOptions, showLoginOptions } = this.state;
    const countryLanguage = selectedCountry.locale || 'en';

    const popoverForLogin = {
      isOpen: showLoginOptions,
      preferPlace: 'below',
      onOuterAction: () => this.toggleLoginOptions(false),
      body: (
        <React.Fragment>
          {this.loginOptions.map((item) => (
            <Row key={item.name} onClick={() => this.loginTypeSelection(item.name)} align="center" className="create-row">
              <item.icon height="16px" />
              <p className="flex-1">{translationText(noonText, item.label)}</p>
            </Row>
          ))}
        </React.Fragment>
      ),
    };

    const popoverProps = {
      isOpen: showUserOptions,
      preferPlace: 'below',
      onOuterAction: () => this.toggleUserOptions(false),
      body: (
        <ul className="noon-list">
          <li>
            <Link to={`/${match.params.locale}`}>{translationText(noonText, 'tab.teacher')}</Link>
          </li>
          {selectedCountry && selectedCountry.allowed_locales && selectedCountry.allowed_locales.length > 1 && (
            <li>
              <div className="link" onClick={() => this.setLocale(locale === 'en' ? countryLanguage : 'en')}>
                {locale === 'en' ? countryLanguage.split('_')[0].toUpperCase() : 'EN'}
              </div>
            </li>
          )}
          {this.loginOptions.map((item) => (
            <li key={item.name}>
              <Row onClick={() => this.loginTypeSelection(item.name)} align="center" className="create-row">
                <item.icon height="16px" />
                <p className="flex-1">{translationText(noonText, item.label)}</p>
              </Row>
            </li>
          ))}
        </ul>
      ),
    };

    return (
      <React.Fragment>
        {!(isMobile || isIPad13) ? (
          <Row nowrap gap className={classNames('noon-navigation-bar')}>
            <Link to={`/${match.params.locale}`} className="noon-brand">
              <IconNoon />
            </Link>
            {isTablet && (
              <Row className="login-type" align="center">
                <Row className="login-type--teacher" align="center">
                  <IconTeacherAvatar className="mlr-05" height="18px" width="18px" stroke={COLORS.brand.base} />
                  <p>{translationText(noonText, 'teacherDashboard.teachers')}</p>
                </Row>
                <NoonLink type="link" href={`${process.env.STUDENT_URL}${localStorage.updatedLocale}`} size="lg" className="login-type--student" icon={IconStudentAvatar}>
                  {translationText(noonText, 'teacherDashboard.student')}
                </NoonLink>
              </Row>
            )}
            <Flex />
            {isEmpty(user) ? (
              <Row justify="space" align="center" className="login-as">
                <Popover {...popoverForLogin} className="create-popover">
                  <Button onClick={() => this.toggleLoginOptions(true)} type="primary" size="large">
                    {translationText(noonText, 'teacherDashboard.sign_in')}
                  </Button>
                </Popover>
              </Row>
            ) : (
              <React.Fragment>
                <div className="nav-login-button-wrapper">
                  <Button history={history} to={`/${match.params.locale}/home`} type="primary" size="large">
                    {translationText(noonText, 'tab.dashboard')}
                  </Button>
                </div>
                <Vr />
                <div className="noon-user-menu">
                  <Popover {...popoverProps}>
                    <Row align="center" onClick={() => this.toggleUserOptions()}>
                      <Avatar url={user.profile_pic} gender={user.gender} />
                      <Column className="nav-profile-info">
                        <div className="title" titlr={user.name}>
                          {user.name}
                        </div>
                      </Column>
                      <Button fab outlined icon={IconArrowDown} size="tiny" className="mlr-05" />
                    </Row>
                  </Popover>
                </div>
              </React.Fragment>
            )}
            <CountryDropdown />
            {selectedCountry && selectedCountry.allowed_locales && selectedCountry.allowed_locales.length > 1 && (
              <div className="language-toggle" onClick={() => this.setLocale(locale === 'en' ? countryLanguage : 'en')}>
                {selectedCountry.allowed_locales.map((item, index) => (
                  <span key={index} className={classNames({ active: localStorage.language && localStorage.language.split('_')[0] === item.locale.split('_')[0] })}>{item.locale.split('_')[0]}</span>
                ))}
              </div>
            )}
          </Row>
        ) : (
          <Row nowrap className="noon-navigation-bar">
            <Link to={`/${localStorage.updatedLocale}/`} className="noon-brand">
              <IconLogo />
            </Link>
            {!isMobile && (
              <Row justify="space" align="center" className="login-as">
                <Popover {...popoverForLogin} className="create-popover">
                  <p onClick={() => this.toggleLoginOptions(true)} className="login-as--type">{translationText(noonText, 'teacherDashboard.sign_in')}</p>
                </Popover>
                <p className="login-as--text">{translationText(noonText, 'teacherDashboard.not_teacher')}</p>
              </Row>
            )}
            <Flex />
            <CountryDropdown />
            {selectedCountry && selectedCountry.allowed_locales && selectedCountry.allowed_locales.length > 1 && (
              <div className="language-toggle" onClick={() => this.setLocale(locale === 'en' ? countryLanguage : 'en')}>
                {selectedCountry.allowed_locales.map((item, index) => (
                  <span key={index} className={classNames({ active: localStorage.language && localStorage.language.split('_')[0] === item.locale.split('_')[0] })}>{item.locale.split('_')[0]}</span>
                ))}
              </div>
            )}
          </Row>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  countries: state.toJS().folder.countries,
  noonText: state.toJS().translation.noonText,
  user: state.toJS().user.loggedUser,
});

const mapDispatchToProps = (dispatch) => ({
  loggedOut: () => dispatch({ type: LOGGED_OUT_TEACHER.REQUEST, payload: {} }),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PublicNav),
);

import { GET_FLASHCARD } from '../constants';

const initialState = {
  flashcards: { list: [], isLoading: false },
};

export default function flashcard(state = initialState, action) {
  switch (action.type) {
    case GET_FLASHCARD.REQUEST:
      return {
        ...state,
        flashcards: { ...state.flashcards, isLoading: true },
        error: {},
      };
    case GET_FLASHCARD.SUCCESS:
      return {
        ...state,
        flashcards: { list: action.payload, isLoading: false },
        error: {},
      };
    case GET_FLASHCARD.FAILURE:
      return {
        ...state,
        error: { flashcards: action.payload },
        flashcards: { list: [], isLoading: false },
      };
    default:
      if (action.type.indexOf('_REQUEST') !== -1) {
        return {
          ...state,
          error: {},
        };
      }
      return state;
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import values from 'lodash-es/values';
import { LinearPercentageIndicator, Row } from '@noon/atom';
import store from '../../store';
import { TOAST_DEFAULT_DISMISS_TIME } from '../../constants';
import { ADD_TOAST, REMOVE_TOAST, UPDATE_TOAST } from '../../redux/constants';
// import './toast.scss';

let id = 0;
export const addToast = (text, type = 'info', time, position, options = {}) => {
  if (!text || (text && !text.toString().trim())) {
    console.log('Error: empty text filed');
    return;
  }
  const isRtl = document.body.dir === 'rtl';
  id++;
  store.dispatch({
    type: ADD_TOAST,
    payload: {
      id: options.id ? options.id : id,
      text,
      type,
      time: time ? Number(time) * 1000 : TOAST_DEFAULT_DISMISS_TIME * 1000,
      position: position || (isRtl ? 'top-left' : 'top-right'),
      options,
    },
  });
};

export const updateToast = (text, type = 'info', time, position, options = {}) => {
  if (!text) {
    console.log('Error: empty text filed');
    return;
  }
  if (!options.id) {
    console.log('no id');
    return;
  }
  const isRtl = document.body.dir === 'rtl';
  store.dispatch({
    type: UPDATE_TOAST,
    payload: {
      id: options.id,
      text,
      type,
      time: time ? Number(time) * 1000 : TOAST_DEFAULT_DISMISS_TIME * 1000,
      position: position || (isRtl ? 'top-left' : 'top-right'),
      options,
    },
  });
};

export const TOAST_TYPE = { SUCCESS: 'success', ERROR: 'error', INFO: 'info', WARNING: 'warning', FROSTED: 'frosted' };
export const TOAST_POSITIONS = {
  TOP_LEFT: 'top-left',
  TOP_CENTER: 'top-center',
  TOP_RIGHT: 'top-right',
  CENTER: 'center',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_CENTER: 'bottom-center',
  BOTTOM_RIGHT: 'bottom-right',
};

export class Toast extends Component {
  static propTypes = {
    toasts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        text: PropTypes.string,
        type: PropTypes.oneOf(['error', 'success', 'info', 'warning', 'frosted']),
        position: PropTypes.oneOf(['top-left', 'top-center', 'top-right', 'center', 'bottom-left', 'bottom-center', 'bottom-right']),
        options: PropTypes.shape({
          className: PropTypes.string,
          icon: PropTypes.string,
          iconFill: PropTypes.string,
          iconStroke: PropTypes.string,
          showIconAfterText: PropTypes.bool,
          noCancelButton: PropTypes.bool,
        }),
      }),
    ).isRequired,
    dismissToast: PropTypes.func.isRequired,
  };

  positionAnimationMap = {
    'top-left': 'slideInLeft',
    'top-center': 'zoomIn',
    'top-right': 'slideInRight',
    center: 'zoomIn',
    'bottom-left': 'slideInLeft',
    'bottom-center': 'zoomIn',
    'bottom-right': 'slideInRight',
  };

  render() {
    const { toasts, dismissToast } = this.props;
    return (
      <React.Fragment>
        {values(TOAST_POSITIONS).map((position) => (
          <div key={position} className={classNames('toast-container', position)}>
            {toasts.filter((item) => item.position === position).map((toast) => (
              <div key={toast.id} className={classNames('toast animated faster', toast.options.className || this.positionAnimationMap[position], toast.type)}>
                <p className={classNames('toast__content')}>
                  {!!toast.options.icon && !toast.options.showIconAfterText && <toast.options.icon fill={toast.options.iconFill} stroke={toast.options.iconStroke} />}
                  <span>{toast.text}</span>
                  {!!toast.options.icon && !!toast.options.showIconAfterText && <toast.options.icon fill={toast.options.iconFill} stroke={toast.options.iconStroke} />}
                </p>
                {toast.options.percentCompleted
                && (
                <Row justify="start" align="center" nowrap className="full-width">
                  <LinearPercentageIndicator
                    total={100}
                    current={toast.options.percentCompleted}
                    color="#FFFFFF"
                    bg="#CCCCCC"
                    />
                  <span>
                    {toast.options.percentCompleted}
                    %
                  </span>
                </Row>
                )}
                {!toast.options.noCancelButton && <button className="toast__dismiss" onClick={() => dismissToast(toast.id)}>x</button>}
              </div>
            ))}
          </div>
        ))}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  toasts: state.toJS().toast.toasts,
});

const mapDispatchToProps = (dispatch) => ({
  dismissToast: (id) => dispatch({ type: REMOVE_TOAST, payload: id }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Toast);

import { updateSlideConfig } from '../../../redux/actions/whiteboard';
import { INTERACTIVE_QUESTION_START, INTERACTIVE_QUESTION_END } from '../../../redux/constants';

export default function handleInteractiveQuestion(dispatch, e) {

  const { currentCube } = e.message;

  if (currentCube) {
    const payload = {
      questionTimer: currentCube.time_left > 0 ? currentCube.time_left : 0,
      getReadyTimer:
                currentCube.time_left > 0 && currentCube.time_left === currentCube.time
                  ? currentCube.get_ready
                  : 0,
      slideResource: currentCube,
    };
    if (!currentCube.time_left) {
      dispatch({ type: INTERACTIVE_QUESTION_END, payload });
      return;
    }
    dispatch({ type: INTERACTIVE_QUESTION_START, payload });
    dispatch(updateSlideConfig({
      allowDelete: false,
      disableAddSlide: true,
      disableSelectSlide: true,
    }));
  }
}

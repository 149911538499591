import React from 'react';
import { Card, Row } from '@noon/atom';
import { IconDarkCircles } from '@noon/quark';

const steps = [
  '<p>You need to first sign up as a free teacher</p>',
  '<p>Create a demo session and send it to <a href="https://care@noonacademy.com">care@noonacademy.com</a> for approval</p>',
  '<p>Check out our training videos on getting started</p>',
  '<p>Teachers are paid purely on the count of students you are able to impact and the time you spend teaching them</p>',
];

const IndiaLoginHowToBecome = () => (
  <Row className="paid-teacher-wrapper" justify="center">
    <IconDarkCircles className="frame" />
    <IconDarkCircles className="frame" />
    <div className="paid-teacher">
      <h2>
        How can you become a
        {' '}
        <span>paid teacher</span>
        {' '}
        at Noon Academy?
      </h2>
      <div className="paid-teacher--steps">
        {steps.map((step, index) => (
          <Row key={index} className="paid-teacher--step" align="center">
            <div className="paid-teacher--step--rounded">{`0${index + 1}`}</div>
            <div className="paid-teacher--step--text" dangerouslySetInnerHTML={{ __html: step }} />
          </Row>
        ))}
      </div>
      <Card className="flex-row align-items justify-center ptb-2 plr-1">
        <span>
          Need any help? Write to
          {' '}
          <a href="https://care@noonacademy.com">care@noonacademy.com</a>
        </span>
      </Card>
    </div>
  </Row>
);

export default IndiaLoginHowToBecome;

import axios from 'axios';
import store from '../store';
import {
  CLEAR_USER_SPECIFIC_FOLDER_DATA,
  CLEAR_USER_SPECIFIC_USER_DATA,
  CLEAR_USER_SPECIFIC_DASHBOARD_DATA,
  CLEAR_USER_SPECIFIC_GROUP_DATA,
} from '../redux/constants';

export default (token = null) => {
  if (token !== null) {
    localStorage.token = token;
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    return true;
  }
  delete axios.defaults.headers.common.Authorization;
  localStorage.removeItem('loggedUser');
  localStorage.removeItem('token');
  localStorage.removeItem('sectionData');
  localStorage.removeItem('curriculum');
  store.dispatch({ type: CLEAR_USER_SPECIFIC_FOLDER_DATA });
  store.dispatch({ type: CLEAR_USER_SPECIFIC_USER_DATA });
  store.dispatch({ type: CLEAR_USER_SPECIFIC_DASHBOARD_DATA });
  store.dispatch({ type: CLEAR_USER_SPECIFIC_GROUP_DATA });
  return true;
};

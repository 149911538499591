import {
  UPDATE_CLASSROOM_CONFIG,
} from '../constants';

const initialState = {
  pdfToSlide: true,
};

export default function classroomConfig(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CLASSROOM_CONFIG:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

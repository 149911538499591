import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from '@noon/atom';
import { scroller } from 'react-scroll';
import NoonQuestion from '../NoonQuestion/NoonQuestion';
import { EDIT_QUESTIONS_LIST } from '../../redux/constants';
import { returnDuplicatePropsForMixpanelAndKafka } from '../../helpers/prepareAssignment';
import Mixpanel from '../Mixpanel';
import { addToast, TOAST_TYPE } from '../Toast';
import { translationText } from '../../helpers';

const LibraryQuestion = (props) => {
  const logger = useRef(LoggingManager.mount({ moduleName: 'homework' })).current;
  const { question_chapter, question_topic, hw_details, currentQuestions } = useSelector((state) => state.toJS().homeworkCreation);
  const { loggedUser: user } = useSelector((state) => state.toJS().user);
  const { selectedGroup } = useSelector((state) => state.toJS().groupsV2);
  const dispatch = useDispatch();
  const { question, isAdded, indexInExistingCollectionOfQuestions, index, showModal } = props;

  const classNameOfButton = 'prepare-assignment--libraryQuestion--add';

  useEffect(() => {
    const content = document.querySelectorAll('.noon-question__content');
    if (content) {
      const imageContainer = document.getElementsByTagName('img');
      if (imageContainer.length > 0) {
        for (let i = 0; i < imageContainer.length - 1; i++) {
          imageContainer[i].parentElement.classList.add('prepare-assignment--imageContainer');
        }
      }
    }
  }, []);

  const mixpanelKafkaEvents = (event) => {
    const type = { mp: `homework_question_${event}`, kf: `question_${event}` };
    const props = returnDuplicatePropsForMixpanelAndKafka(selectedGroup, user, hw_details);
    const properties = {
      no_of_questions: currentQuestions.data ? currentQuestions.data.length : 0,
      ...type.kf === 'question_added' && { number_questions_added: 1 },
      ...props,
    };
    Mixpanel.track(type.mp, properties);
    logger.track(type.kf, { homework: properties });
  };

  const addQuestionToCurrentQuestions = () => {
    if (currentQuestions.data.length < 25) {
      mixpanelKafkaEvents('added');
      new Promise((resolve, reject) => {
        dispatch({
          type: EDIT_QUESTIONS_LIST.ADD,
          payload: {
            question,
            chapter: question_chapter,
            topic: question_topic,
          },
        });
        resolve('success');
      }).then(() => {
        scroller.scrollTo(`question${currentQuestions.data.length + 1}`, {
          duration: 500,
          delay: 50,
          smooth: true,
          containerId: 'containerElement',
        });
      });
    } else {
      addToast(translationText(noonText, 'createHomework.questionsLimitExceed'), TOAST_TYPE.WARNING);
    }
  };

  const deleteQuestionFromCurrentQuestions = () => {
    mixpanelKafkaEvents('deleted');
    return dispatch({
      type: EDIT_QUESTIONS_LIST.DELETE,
      payload: {
        index: indexInExistingCollectionOfQuestions,
      },
    });
  };

  return (
    <Row
      align="center"
      justify="space-between"
      className="prepare-assignment--libraryQuestion"
      style={{ borderTop: index === 0 ? '1px solid #f2f7ff' : '' }}
      onClick={() => (isAdded ? deleteQuestionFromCurrentQuestions() : addQuestionToCurrentQuestions())}
    >
      <div
        className={`${classNameOfButton} ${isAdded ? `${classNameOfButton}--added` : `${classNameOfButton}--notAdded`}`}
      />
      <NoonQuestion dir={question.text_direction} content={question.question_json} className="prepare-assignment--libraryQuestion--question" onImageClick={(image) => showModal(image)} />
    </Row>
  );
};

export default LibraryQuestion;

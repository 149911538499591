/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

function AddNewImageIcon(props) {
  return (
    <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.165 12.8496C13.5082 12.8496 12.165 14.1928 12.165 15.8496V57.8496C12.165 59.5065 13.5082 60.8496 15.165 60.8496H57.165C58.8219 60.8496 60.165 59.5065 60.165 57.8496V15.8496C60.165 14.1928 58.8219 12.8496 57.165 12.8496H15.165ZM6.16504 15.8496C6.16504 10.879 10.1945 6.84961 15.165 6.84961H57.165C62.1356 6.84961 66.165 10.879 66.165 15.8496V57.8496C66.165 62.8202 62.1356 66.8496 57.165 66.8496H15.165C10.1945 66.8496 6.16504 62.8202 6.16504 57.8496V15.8496Z"
        fill="#64D8AE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.665 24.8496C24.8366 24.8496 24.165 25.5212 24.165 26.3496C24.165 27.178 24.8366 27.8496 25.665 27.8496C26.4935 27.8496 27.165 27.178 27.165 26.3496C27.165 25.5212 26.4935 24.8496 25.665 24.8496ZM18.165 26.3496C18.165 22.2075 21.5229 18.8496 25.665 18.8496C29.8072 18.8496 33.165 22.2075 33.165 26.3496C33.165 30.4917 29.8072 33.8496 25.665 33.8496C21.5229 33.8496 18.165 30.4917 18.165 26.3496Z"
        fill="#64D8AE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.0437 28.7283C47.2153 27.5567 49.1148 27.5567 50.2864 28.7283L65.2864 43.7283C66.4579 44.8999 66.4579 46.7994 65.2864 47.9709C64.1148 49.1425 62.2153 49.1425 61.0437 47.9709L48.165 35.0922L17.2864 65.9709C16.1148 67.1425 14.2153 67.1425 13.0437 65.9709C11.8721 64.7994 11.8721 62.8999 13.0437 61.7283L46.0437 28.7283Z"
        fill="#64D8AE"
      />
    </svg>
  );
}

export default AddNewImageIcon;

import React from 'react';
import { Row } from '@noon/atom';
import { IconLike, IconMyGroups, IconSession } from '@noon/quark';

export default function ImpactSection() {
  return (
    <div className="section-outer-container impact-outer">
      <div className="section landing-impact">
        <Row className="text landing-impact__heading">
          <h1 className="title-heading">Make an Impact</h1>
        </Row>
        <Row className="landing-impact__content">
          <Row className="group-items">
            <div className="item">
              <div className="icon-wrapper"><IconSession fill="#ffffff" /></div>
              <div className="text">
                <h1 className="content-heading-2">Effective Virtual Classrooms</h1>
                <p className="content-subheading">Our classrooms help you give a simplified teaching experience. Your students can access your classrooms at anytime from anywhere</p>
              </div>
            </div>
            <div className="item">
              <div className="icon-wrapper"><IconMyGroups fill="#ffffff" /></div>
              <div className="text">
                <h1 className="content-heading-2">Grow Your Reach</h1>
                <p className="content-subheading">You can invite your students to join your groups. In addition, your profile will be visible in the search results to bring you new students</p>
              </div>
            </div>
            <div className="item">
              <div className="icon-wrapper"><IconLike fill="#ffffff" /></div>
              <div className="text">
                <h1 className="content-heading-2">Inspire Students</h1>
                <p className="content-subheading">Help your students learn and get connected in the groups where you can clear their doubts and inspire them as a mentor</p>
              </div>
            </div>
          </Row>
        </Row>
      </div>
    </div>
  );
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import screenfull from 'screenfull';

class FullScreen extends Component {
  static propTypes = {
    enabled: PropTypes.bool,
    children: PropTypes.node.isRequired,
    onChange: PropTypes.func,
    onError: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    enabled: false,
    onChange: null,
    onError: null,
    className: '',
  };

  componentDidMount() {
    if (screenfull.isEnabled) {
      screenfull.on('change', this.detectFullscreen);
      screenfull.on('error', this.detectFullscreenError);
    }
  }

  componentWillUnmount() {
    if (screenfull.isEnabled) {
      screenfull.off('change', this.detectFullscreen);
      screenfull.off('error', this.detectFullscreenError);
    }
  }

  componentDidUpdate({ enabled }) {
    if (enabled && !this.props.enabled && screenfull.isFullscreen) {
      this.leaveFullScreen();
    } else if (!enabled && this.props.enabled && !screenfull.isFullscreen) {
      this.enterFullScreen();
    }
  }

  detectFullscreen = () => {
    if (this.props.onChange) {
      this.props.onChange(screenfull.isFullscreen);
    }
  };

  detectFullscreenError = (event) => {
    if (this.props.onError) {
      this.props.onError(event);
    }
  };

  enterFullScreen = () => {
    if (screenfull.isEnabled) {
      try {
        screenfull.request(this.fullscreenNode);
      } catch (err) {
        console.log(err);
      }
    }
  };

  leaveFullScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.exit(this.fullscreenNode);
    }
  };

  render() {
    const { enabled, className } = this.props;
    return (
      <div
        className={classNames('fullscreen', className, { 'fullscreen-enabled': enabled })}
        ref={(node) => {
          this.fullscreenNode = node;
        }}
        style={enabled ? { height: '100%', width: '100%' } : undefined}
      >
        {this.props.children}
      </div>
    );
  }
}

export default FullScreen;

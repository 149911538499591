import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Flashcards from './flashcards';
import { IFlashcard } from './flashcardItem';
import { getFlashcardContent, selectImageContent } from '../../../../redux/actions/classroom';

const FlashcardContainer = () => {

  const dispatch = useDispatch();
  const flashcards = useSelector((state) => state.toJS().content.flashcard);
  const topicId = useSelector((state) => state.toJS().content.topicId);

  const handleSelectFlashcard = (flashcard: IFlashcard) => {
    const payload = {
      resourceId: flashcard.id,
      resourceType: 'flashcard_image',
      url: flashcard.image_large_uri || flashcard.image_medium_uri,
    };
    dispatch(selectImageContent(payload));
  };

  useEffect(() => {
    if (topicId && flashcards.topicId !== topicId) {
      dispatch(getFlashcardContent({ topic_id: topicId }));
    }
  }, [topicId]);

  return (
    <Flashcards
      flashcards={flashcards}
      onSelectFlashcard={handleSelectFlashcard}
    />
  );
};

export default FlashcardContainer;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconBack } from '@noon/quark';
import { Row } from '@noon/atom';

export default class SmartCarousel extends Component {
  static propTypes = {
    slides: PropTypes.arrayOf(PropTypes.object).isRequired,
    children: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    classname: PropTypes.string,
    dots: PropTypes.bool,
    loadMore: PropTypes.func,
  };

  static defaultProps = {
    classname: '',
    dots: false,
    loadMore: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      translate: 0,
      isRemaining: false,
      initSlideStatus: false,
      activeSlideIndex: 0,
    };
  }

  componentDidMount() {
    this.initSlide();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.slides !== this.props.slides && this.props.slides.length) {
      this.initSlide();
    }
  }

  initSlide() {
    const { name } = this.props;
    const container = document.getElementsByClassName(`noon-${name}-slider__container`)[0];
    const slide = document.getElementsByClassName(`noon-${name}-slider__slide`);
    if (slide && slide.length && container) {
      let wrapperWidth = 0;
      for (const key in slide) {
        if (slide[key] && slide[key].offsetWidth) wrapperWidth += Number(slide[key].offsetWidth);
      }
      const remaining = wrapperWidth - (container.offsetWidth + Math.abs(this.state.translate));
      this.setState({
        initSlideStatus: true,
        isRemaining: remaining > 0,
      });
    }
  }

  slideMove(reverse) {
    const { name } = this.props;
    const isRtl = document.body.dir === 'rtl';
    const container = document.getElementsByClassName(`noon-${name}-slider__container`)[0];
    const wrapper = document.getElementsByClassName(`noon-${name}-slider__wrapper`)[0];
    const slide = document.getElementsByClassName(`noon-${name}-slider__slide`);
    let wrapperWidth = 0;
    for (const key in slide) {
      if (slide[key] && slide[key].offsetWidth) wrapperWidth += Number(slide[key].offsetWidth);
    }
    const remaining = wrapperWidth - (container.offsetWidth + Math.abs(this.state.translate));
    if (reverse) {
      this.setState(
        prevState => ({
          activeSlideIndex: prevState.activeSlideIndex - 1,
          translate:
            prevState.translate -
            (prevState.translate < slide[0].offsetWidth ? prevState.translate : slide[0].offsetWidth),
        }),
        () => {
          const remaining = wrapperWidth - (container.offsetWidth + Math.abs(this.state.translate));
          this.setState({ isRemaining: remaining > 0 });
          wrapper.style.transform = `translateX(${isRtl ? this.state.translate : -this.state.translate}px)`;
        },
      );
    } else {
      this.setState(
        prevState => ({
          activeSlideIndex: prevState.activeSlideIndex + 1,
          translate: prevState.translate + (remaining < slide[0].offsetWidth ? remaining : slide[0].offsetWidth),
        }),
        () => {
          const remaining1 = wrapperWidth - (container.offsetWidth + Math.abs(this.state.translate));
          this.setState({ isRemaining: remaining1 > 0 });
          if (this.props.classname === 'live-sessions__main' && (remaining1 > 0) === false) {
            this.props.loadMore();
          }

          wrapper.style.transform = `translateX(${isRtl ? this.state.translate : -this.state.translate}px)`;
        },
      );
    }
  }

  handleLeftNav = () => {
    this.slideMove(true);
  };

  handleRightNav = () => {
    this.slideMove();
  };

  render() {
    const isRtl = document.body.dir === 'rtl';
    const { translate, isRemaining, activeSlideIndex } = this.state;
    const { slides, children, name, classname, dots } = this.props;
    return (
      <React.Fragment>
        <div className={('noon-slider', 'flex-auto', `${classname}`)}>
          <div className={`noon-slider__container noon-${name}-slider__container`}>
            <div className={`noon-slider__wrapper noon-${name}-slider__wrapper flex-row flex-nowrap`}>
              {slides.map((slide, index) => (
                <React.Fragment key={index}>
                  {children({
                  item: slide,
                  order: index + 1,
                })}
                </React.Fragment>
            ))}
            </div>

          </div>
          {!!translate && (
          <div
            role="button"
            tabIndex="0"
            className="noon-slider__nav noon-slider__nav-left"
            onClick={this.handleLeftNav}
          >
            <IconBack flipX={isRtl} />
          </div>
        )}
          {isRemaining && (
          <div
            role="button"
            tabIndex="0"
            className="noon-slider__nav noon-slider__nav-right"
            onClick={this.handleRightNav}
          >
            <IconBack flipX={!isRtl} />
          </div>
        )}
        </div>
        {dots &&
        <Row justify="center" align="center" className="ptb-2 full-width">
          {slides.map((slide, index) => (
            <React.Fragment>
              { activeSlideIndex === index ?
                <span key={index} className="noon-dot blue md" /> :
                <span key={index} className="noon-dot grey md" />
              }
            </React.Fragment>
          ))}
        </Row>}
      </React.Fragment>
    );
  }
}

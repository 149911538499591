import { put, takeEvery, all, select } from 'redux-saga/effects';
import handleMixpanelKafkaActivityEvents from '../../components/Class/AddQuestionFromImage/eventsHelper';
import { OPEN_ADD_IMAGE_FROM_QUESTION_DIALOG, ADD_IMAGE_FROM_QUESTION_IMAGE_LOADED, CLOSE_ADD_IMAGE_FROM_QUESTION_DIALOG, ENABLE_ADD_IMAGE_FROM_QUESTION_ONBOARDING, HIDE_ADD_IMAGE_FROM_QUESTION_ONBOARDING_SUCCESS, SHOW_ADD_IMAGE_FROM_QUESTION_ONBOARDING_SUCCESS } from '../constants';

const getState = (state) => state.toJS().addQuestionFromImage;

function* checkLaunchLocation(action) {

  const opened_from = action.payload.from;
  yield handleMixpanelKafkaActivityEvents({ type: 'itq_started', data: { opened_from } });
}

function* checkAndEnableOnboarding() {
  const onboarding = localStorage.getItem('imageToQuestionOnboarding');
  if (!onboarding) {
    yield handleMixpanelKafkaActivityEvents({ type: 'itq_onboarding_started' });
    yield put({
      type: ENABLE_ADD_IMAGE_FROM_QUESTION_ONBOARDING,
    });
  }
}

function* checkAndShowOnBoardingSuccess() {
  const { onboarding, onBoardingComplete } = yield select(getState);
  const onboarded = localStorage.getItem('imageToQuestionOnboarding');
  if (!onboarded && !onboarding && onBoardingComplete) {
    yield localStorage.setItem('imageToQuestionOnboarding', true);
    yield handleMixpanelKafkaActivityEvents({ type: 'itq_onboarding_complete' });
    yield put({
      type: SHOW_ADD_IMAGE_FROM_QUESTION_ONBOARDING_SUCCESS,
    });
  }
}

export function* addQuestionFromImageSaga() {
  yield all([takeEvery(OPEN_ADD_IMAGE_FROM_QUESTION_DIALOG, checkLaunchLocation)]);
  yield all([takeEvery(ADD_IMAGE_FROM_QUESTION_IMAGE_LOADED, checkAndEnableOnboarding)]);
  yield all([takeEvery(CLOSE_ADD_IMAGE_FROM_QUESTION_DIALOG, checkAndShowOnBoardingSuccess)]);
}

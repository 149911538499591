import React from 'react';
import ReactTooltip from 'react-tooltip';
import t from '../../helpers/translate';

function NotAllowedOverlay() {
  return (
    <>
      <ReactTooltip place="bottom" />
      <div className="widget-overlay cursor-not-allowed" tooltipPos="bottom" data-tip={t('tooltip', 'actionNotAllowed')} />
    </>
  );
}

export default NotAllowedOverlay;

import classNames from 'classnames';
import { findIndex, flatten } from 'lodash-es';
import React, { useEffect, useRef, useState } from 'react';
import Popover from 'react-popover';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Column, Row } from '@noon/atom';
import { IconLoader, IconRaiseHand } from '@noon/quark';

import {
    DEFAULT_SECTION_TITLE_TEXT, isRTL, MEAN_SLIDE_LENGTH_TO_PIXEL_RATIO, PLAYBACK_SLIDE_CONFIG
} from '../../../constants';
import t from '../../../helpers/translate';
import { useOnPlaybackSeek } from '../../../hooks';
import { DELETE_PLAYBACK_SLIDE, PLAYBACK_PAUSE } from '../../../redux/constants';
import { addToast, TOAST_TYPE } from '../../Toast';
import { defaultSlide, ICombinedView, ISlide, ISlideWLength, TslideStyling } from '../types';

function CombinedView(props: ICombinedView) {
  const { sections, slides, activeSlide = defaultSlide, updateSlideState, updateSlideToTrim, playerRef } = props;
  const dispatch = useDispatch();

  // States and hooks
  const [showContextMenuFor, toggleContextMenu] = useState<ISlide>(null);
  const [, seeking, onSeek] = useOnPlaybackSeek(playerRef);

  // Redux state
  const { editedPlaybackDetails, playback_sections } = useSelector((state) => state.toJS().playback);

  const { original: originalSlides } = playback_sections;

  const allSlideIntervals = useRef({});

  // Logic
  const findSlideIndexById = (slideId) => findIndex(slides, { canvas_id: slideId });

  const getSlideStyling = (slide: ISlideWLength) : TslideStyling => {
    const { max_width } = PLAYBACK_SLIDE_CONFIG;
    const slideWidth = slide?.originalLength * MEAN_SLIDE_LENGTH_TO_PIXEL_RATIO > max_width ? max_width : slide?.originalLength * MEAN_SLIDE_LENGTH_TO_PIXEL_RATIO;
    const startNotCropped = slide?.payload?.intervals?.[0]?.start_time === allSlideIntervals?.[slide.canvas_id]?.[0]?.start_time;
    const endNotCropped = slide?.payload?.intervals?.[0]?.end_time === allSlideIntervals?.[slide.canvas_id]?.[0]?.end_time;

    if (slide?.payload?.intervals?.length <= 1 && startNotCropped && endNotCropped) {
      return {
        width: slideWidth,
        minWidth: PLAYBACK_SLIDE_CONFIG.min_width,
        maxWidth: PLAYBACK_SLIDE_CONFIG.max_width,
      };
    }
    let startTime;
    let endTime;
    const secondEditedSlideInterval = slide?.payload?.intervals?.[1];
    const firstEditedSlideInterval = slide?.payload?.intervals?.[0];
    const firstOriginalSlideInterval = slide?.payload?.originalIntervals?.[0];

    if (firstEditedSlideInterval?.start_time === firstOriginalSlideInterval?.start_time) {
      startTime = firstEditedSlideInterval?.end_time;
      if (secondEditedSlideInterval) {
        endTime = secondEditedSlideInterval?.start_time;
      } else {
        endTime = firstOriginalSlideInterval?.end_time;
      }
    } else {
      startTime = firstOriginalSlideInterval?.start_time;
      endTime = firstEditedSlideInterval?.start_time;
    }
    const startTimeSec = (startTime - firstOriginalSlideInterval?.start_time) / 1000;
    const startTimePoint = (slideWidth / slide?.originalLength) * startTimeSec;
    const endTimeSec = (endTime - firstOriginalSlideInterval?.start_time) / 1000;
    const endTimePoint = (slideWidth / slide?.originalLength) * endTimeSec;

    return {
      width: slideWidth,
      minWidth: PLAYBACK_SLIDE_CONFIG.min_width,
      maxWidth: PLAYBACK_SLIDE_CONFIG.max_width,
      ...(endTimePoint - startTimePoint) > 1 && { backgroundImage: `linear-gradient(to right, #D7DEF5 ${endTimePoint - startTimePoint}px, transparent 1px, transparent)` },
      backgroundPosition: `left ${startTimePoint}px top`,
    };
  };

  const showContextMenuForSlide = (slide: ISlide) : void => {
    toggleContextMenu(slide);
  };

  const takeActionOnSlide = (slide: ISlide, action: string) : void => {
    if (action === 'delete') {
      // There should always be at least one unhidden/undeleted slide inside a playback
      if (flatten(sections).filter((slide: ISlide) => !slide.delete).length < 2) {
        addToast(t('playback', 'cantDeleteLastSlide'), TOAST_TYPE.ERROR);
        toggleContextMenu(null);
        return;
      }
      dispatch({
        type: DELETE_PLAYBACK_SLIDE.REQUEST,
        payload: {
          session_id: editedPlaybackDetails?.data?.[0].id,
          canvas_id: slide?.canvas_id,
          delete: !slide?.delete,
        },
      });
    } else if (action === 'jump' || action === 'trim') {
      if (action === 'trim') {
        dispatch({ type: PLAYBACK_PAUSE });
        updateSlideToTrim(slide);
      } else {
        updateSlideState({ active: {
          index: findSlideIndexById(slide?.canvas_id),
          label_id: slide?.payload?.label_id,
          label_name: slide?.payload?.label_name,
          intervals: slide?.payload?.intervals,
          canvas_id: slide?.canvas_id,
          delete: slide?.delete } });
      }
      const sessionStart = originalSlides?.[0]?.payload?.intervals?.[0].start_time;
      const slideStart = slide?.payload?.intervals?.[0]?.start_time;
      onSeek(Number(sessionStart) === Number(slideStart) ? 0 : ((slideStart - sessionStart) / 1000));
    }
    toggleContextMenu(null);
  };

  useEffect(() => {
    allSlideIntervals.current = (originalSlides.reduce((acc, item) => ({ ...acc, [item.canvas_id]: item.payload.intervals }), {}));
  }, [originalSlides]);

  // Render functions
  const slideItemRender = (slide: ISlideWLength, index : number, section: string) => {
    const isCompetition = editedPlaybackDetails?.data?.[0]?.class_type === 'competition';
    const popoverProps = {
      isOpen: showContextMenuFor && slide.canvas_id === showContextMenuFor.canvas_id,
      preferPlace: 'above',
      onOuterAction: () => toggleContextMenu(null),
      body: (
        <Column className="slide-context-menu">
          {showContextMenuFor && <p className="underline">{`${showContextMenuFor.payload.label_name ? `${showContextMenuFor.payload.label_name}: ` : ''} ${t('playback', 'slide')} ${findSlideIndexById(showContextMenuFor?.canvas_id) + 1}`}</p>}
          {showContextMenuFor && !showContextMenuFor.delete && (
            <>
              <Button type="primary" link value={t('playback', 'jumpToIt')} onClick={() => takeActionOnSlide(showContextMenuFor, 'jump')} />
              {!isCompetition && <Button type="primary" link value={t('playback', 'trimPartOfSlide')} onClick={() => takeActionOnSlide(showContextMenuFor, 'trim')} />}
            </>
          )}
          <Button className={showContextMenuFor && showContextMenuFor.delete ? 'green' : 'red'} link value={t('playback', `${showContextMenuFor && showContextMenuFor.delete ? 'bringBackSlide' : 'deleteSlide'}`)} onClick={() => takeActionOnSlide(showContextMenuFor, 'delete')} />
        </Column>
      ),
    };

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Popover {...popoverProps} tipSize=".01">
        <div
          className={classNames('slide',
            { active: findSlideIndexById(slide.canvas_id) === activeSlide?.index && activeSlide?.label_id === section },
            { deleted: !!slide.delete },
            { loading: !!seeking })}
          onClick={() => showContextMenuForSlide(slide)}
          style={getSlideStyling(slide)}
          >
          {seeking ? (
            <IconLoader />
          ) : (
            <>
              {findSlideIndexById(slide.canvas_id) + 1}
              {slide.payload.raise_hand && <IconRaiseHand height="20px" width="20px" style={{ position: 'absolute', right: '5px' }} />}
            </>
          )}
        </div>
      </Popover>
    );
  };

  return (
    <>
      {sections.map((section, sectionIndex) => {
        const shouldShowAsDisabled = section.filter((slide) => slide.delete).length === section.length;
        const sectionsWithLabel = section.filter((slide) => !!slide?.payload?.label_name);
        const sectionName = sectionsWithLabel[0]?.payload?.label_name;
        const isActiveSection = section.findIndex((slide) => slide.canvas_id === activeSlide.canvas_id) > -1;
        return (
          <Column className={classNames('section', { active: isActiveSection }, { disabled: !!shouldShowAsDisabled })} key={sectionIndex}>
            <Row className="title" gap="sm">
              <span className="child">{sectionName || t('classroom', 'noSectionTitle') || DEFAULT_SECTION_TITLE_TEXT[isRTL() ? 'ar' : 'en']}</span>
            </Row>
            <Row>
              {section.map((slide, index) => (
                <div className="slide-wrapper" key={slide.canvas_id}>
                  {slideItemRender(slide, index, slide?.payload?.label_id)}
                </div>
              ))}
            </Row>
          </Column>
        );
      })}
    </>
  );
}

export default CombinedView;

import * as Sentry from '@sentry/react';

import { SentryType } from './sentryTypes';

interface ISentryErrorData<Type> {
  [arg: string]: Type;
}

export default function TrackSentryError<K>(name: string, data?: ISentryErrorData<K>, type?: string) : void {
  // try {
  //   if (type === SentryType.LOG || type === SentryType.INFO) {
  //     Sentry.captureMessage(name, SentryType[type]);
  //   } else {
  //     Sentry.withScope((scope) => {
  //       // The exception has the event level set by the scope (info).
  //       scope.setLevel(SentryType[type]);

  //       if (data) {
  //         Sentry.captureException({ ...data, se_msg: JSON.stringify(name) });
  //       } else {
  //         Sentry.captureException({ ...new Error(name), se_msg: name });
  //       }
  //     });
  //   }
  // } catch(e) {
    console.log('Snetry has been commented out ', type, data)
  // }
}

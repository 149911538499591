import React, { useState, useEffect } from 'react';
import { Column } from '@noon/atom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { translationText } from '../../helpers';
import { checkIsMobile, checkIsTablet } from '../../helpers/prepareAssignment';
import { GET_USER_RESPONSE } from '../../redux/constants';

import ReportPageBarTabs from './ReportPageBarTabs';
import ReportPageBarStats from './ReportPageBarStats';
import ReportPageBarStudentsList from './ReportPageBarStudentsList';
import ReportPageProfile from './ReportPageProfile';

const ReportPageBar = (props) => {
  const { details, noonText, isBarOpen, setLoadMore, setIsBarOpen, setIsUserAnswersOpen } = props;
  const dispatch = useDispatch();
  const [tabs, setTabs] = useState([{
    name: 'Submitted',
    value: 'Submitted',
  }]);
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);
  const { submittedUsers, notSubmittedUsers, studentProfile, studentsLoading } = useSelector((state) => state.toJS().homeworkReport);

  const selectTab = (tab) => {
    if (tab === 'NotSubmitted') dispatch({ type: GET_USER_RESPONSE.FAILURE });
    setSelectedTab(tab);
  };

  useEffect(() => {
    setTabs([
      {
        name: `${translationText(noonText, 'homeworkReport.submitted')} (${submittedUsers.meta.total})`,
        value: 'Submitted',
      },
      {
        name: `${translationText(noonText, 'homeworkReport.inProgress')} (${notSubmittedUsers.meta.total})`,
        value: 'NotSubmitted',
      },
    ]);
  }, [noonText, submittedUsers, notSubmittedUsers]);

  return (
    <Column className={`report--bar ${checkIsMobile() || checkIsTablet() ? isBarOpen ? 'modal-opened' : 'modal-closed' : ''}`}>
      {(studentProfile.position >= 0 || studentProfile.loading) && selectedTab === 'Submitted' && <ReportPageProfile profile={studentProfile} />}
      <ReportPageBarStats details={details} noonText={noonText} />
      <ReportPageBarTabs tabs={tabs} setSelectedTab={selectTab} selectedTab={selectedTab} />
      <ReportPageBarStudentsList
        submitted={selectedTab === 'Submitted'}
        students={selectedTab === 'Submitted' ? submittedUsers : notSubmittedUsers}
        loadMore={selectedTab === 'Submitted' ? submittedUsers.meta.total > submittedUsers.data.length : notSubmittedUsers.meta.total > notSubmittedUsers.data.length}
        setLoadMore={setLoadMore}
        studentProfile={studentProfile}
        studentsLoading={studentsLoading}
        setIsBarOpen={setIsBarOpen}
        noonText={noonText}
        setIsUserAnswersOpen={setIsUserAnswersOpen}
      />
      {/* {selectedTab !== 'Submitted' && <button className="secondary-button send-reminder">{translationText(noonText, 'homeworkReport.sendReminder')}</button>} */}
    </Column>
  );
};

ReportPageBar.propTypes = {
  noonText: PropTypes.shape().isRequired,
  isBarOpen: PropTypes.bool.isRequired,
  details: PropTypes.shape().isRequired,
  setIsBarOpen: PropTypes.func.isRequired,
  setLoadMore: PropTypes.func.isRequired,
  setIsUserAnswersOpen: PropTypes.func.isRequired,
};

export default ReportPageBar;

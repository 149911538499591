import React from 'react';
import ReactDOM from 'react-dom';
// import initReactFastclick from 'react-fastclick';

import App from './app';
import './polyfill';
import { initializeMessaging } from './components/Firebase';
import LoggingManager from './helpers/LoggingManager';
// import initialiseSentry from './helpers/sentrySetup';
import Worker from './workers/test.worker.js';

// initReactFastclick();
window.NoonLoggingWorker = new Worker();
window.LoggingManager = new LoggingManager();

ReactDOM.render(<App />, document.getElementById('root') || document.createElement('div'));
// ReactDOM.createRoot(document.getElementById('root')).render(<App />);
initializeMessaging();
// initialiseSentry();

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isMobile, isIPad13 } from 'react-device-detect';
import { IconArrowDown, IconLoader } from '@noon/quark';
import { Row, Column, Button } from '@noon/atom';
import { translationType } from '../../types';
import { translationText } from '../../helpers';

export default class FlashcardForLesson extends Component {
  static propTypes = {
    flashcards: PropTypes.shape().isRequired,
    errorFor: PropTypes.arrayOf(PropTypes.string).isRequired,
    noonText: translationType.isRequired,
    textAddToCanvas: PropTypes.string.isRequired,
    updateCurrentFlashcardId: PropTypes.func.isRequired,
    addToCanvas: PropTypes.func.isRequired,
    showEnlarged: PropTypes.func,
    simplified: PropTypes.bool,
  };

  static defaultProps = {
    simplified: false,
    showEnlarged: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      currentFlashcardIndex: 0,
      itemWidth: 210,
      gutter: 10,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.flashcards && this.props.flashcards !== prevProps.flashcards) {
      this.resetFlashcardIndex();
    }
  }

  resetFlashcardIndex = () => {
    this.setState({ currentFlashcardIndex: 0 });
  };

  handleFlashcardIndex = (index) => {
    const { updateCurrentFlashcardId, flashcards } = this.props;
    updateCurrentFlashcardId(flashcards.list[index].id);
    if (index >= 0 && index <= flashcards.list.length) this.setState({ currentFlashcardIndex: index });
  };

  selectThisFlashcard = (flashcard) => {
    const { addToCanvas } = this.props;
    const data = {
      resourceId: flashcard.id,
      resourceType: 'flashcard_image',
      url: flashcard.image_medium_uri || flashcard.image_large_uri,
    };
    addToCanvas(data);
  };

  showFlashcardEnlarged = (flashcard) => {
    const { showEnlarged, simplified } = this.props;
    if (simplified && !(isMobile || isIPad13)) {
      showEnlarged(flashcard);
    }
  };

  render() {
    const { currentFlashcardIndex, itemWidth, gutter } = this.state;
    const { simplified, flashcards, textAddToCanvas, noonText, errorFor } = this.props;
    const hasFlashcard = flashcards && flashcards.list && flashcards.list.length;
    const hasError = errorFor.indexOf('flashcard') !== -1;
    return (
      <div className={classNames('folder-flashcard', { simplified })}>
        {!flashcards.isLoading && hasFlashcard ? (
          <React.Fragment>
            {!simplified && (
              <Column className="folder-flashcard__buttons">
                <Button
                  className="next"
                  fab
                  icon={IconArrowDown}
                  rotate="90"
                  iconFill="blue"
                  onClick={() => this.handleFlashcardIndex(currentFlashcardIndex + 1)}
                  // onTouchStart={() => this.handleFlashcardIndex(currentFlashcardIndex + 1)}
                />
                <Button
                  className="prev"
                  fab
                  icon={IconArrowDown}
                  size="small"
                  rotate="270"
                  iconFill="blue"
                  onClick={() => this.handleFlashcardIndex(currentFlashcardIndex - 1)}
                  // onTouchStart={() => this.handleFlashcardIndex(currentFlashcardIndex - 1)}
                />
              </Column>
            )}
            <Row
              className="folder-flashcard__list"
              style={{
                width: `${simplified ? 100 : flashcards.length * 100}%`,
                transform: `translateX(${simplified ? 0 : currentFlashcardIndex * itemWidth + gutter * 2}px)`,
              }}
            >
              {flashcards.list.map((flashcard, index) => (
                <Column
                  key={flashcard.id}
                  className="folder-flashcard__list-item"
                  style={{
                    maxWidth: `${itemWidth}px`,
                    margin: `${simplified ? 10 : 0}px ${gutter}px`,
                  }}
                >
                  {/* <span className="index">{index + 1}</span> */}
                  <img
                    alt={flashcard.name}
                    src={flashcard.image_medium_uri}
                    data-resource-id={flashcard.id}
                    data-resource-type="flashcard_image"
                    onClick={() => this.showFlashcardEnlarged(flashcard)}
                  />
                  {(simplified || currentFlashcardIndex === index) && (
                    <Button
                      className="animate slideInUp"
                      rounded
                      type="primary"
                      size="sm"
                      onClick={() => this.selectThisFlashcard(flashcard)}
                      // onTouchStart={() => this.selectThisFlashcard(flashcard)}
                    >
                      {textAddToCanvas}
                    </Button>
                  )}
                </Column>
              ))}
            </Row>
            {!simplified && (
              <Row className="folder-flashcard__dots">
                {flashcards.list.map((flashcard, index) => (
                  <span
                    key={flashcard.id}
                    className={classNames('dot', {
                      active: currentFlashcardIndex === index,
                      small: currentFlashcardIndex + 2 < index || currentFlashcardIndex - 2 > index,
                    })}
                  />
                ))}
              </Row>
            )}
          </React.Fragment>
        ) : flashcards.isLoading ? (
          <IconLoader height="4rem" width="4rem" />
        ) : (
          <div className="no-content infoMsg infoMsg-blue">
            <span>{translationText(noonText, `classroom.${hasError ? 'failedToFetch' : 'selectOtherResource'}`)}</span>
          </div>
        )}
      </div>
    );
  }
}

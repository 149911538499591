import { SHOW_ADD_IMAGE_FROM_QUESTION_ONBOARDING_SUCCESS,
  CLOSE_ADD_IMAGE_FROM_QUESTION_DIALOG,
  OPEN_ADD_IMAGE_FROM_QUESTION_DIALOG, HIDE_ADD_IMAGE_FROM_QUESTION_ONBOARDING_SUCCESS, ADD_IMAGE_FROM_QUESTION_ONBOARDING_COMPLETE, ENABLE_ADD_IMAGE_FROM_QUESTION_ONBOARDING, ADD_IMAGE_FROM_QUESTION_IMAGE_LOADED, SET_CHAPTER_AND_TOPIC } from '../constants';

const initialState = {
  showDialog: false,
  showOnboardingSuccess: false,
  onboarding: false,
  onBoardingComplete: false,
  lessonDetails: {},
  openedFrom: null,
  conversionId: null,
};

const makeid = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export default function addQuestionFromImage(state = initialState, action) {
  switch (action.type) {
    case OPEN_ADD_IMAGE_FROM_QUESTION_DIALOG:
      return {
        ...state,
        showDialog: true,
        openedFrom: action.payload.from,
        conversionId: `itq_${makeid(6)}`,
      };
    case CLOSE_ADD_IMAGE_FROM_QUESTION_DIALOG:
      return {
        ...state,
        showDialog: false,
      };
    case SHOW_ADD_IMAGE_FROM_QUESTION_ONBOARDING_SUCCESS:
      return {
        ...state,
        showOnboardingSuccess: true,
      };
    case HIDE_ADD_IMAGE_FROM_QUESTION_ONBOARDING_SUCCESS:
      return {
        ...state,
        showOnboardingSuccess: false,
      };
    case ENABLE_ADD_IMAGE_FROM_QUESTION_ONBOARDING:
      return {
        ...state,
        onboarding: true,
      };
    case ADD_IMAGE_FROM_QUESTION_ONBOARDING_COMPLETE:
      return {
        ...state,
        onboarding: false,
        onBoardingComplete: true,
      };
    case ADD_IMAGE_FROM_QUESTION_IMAGE_LOADED:
      return {
        ...state,
      };
    case SET_CHAPTER_AND_TOPIC:
      return {
        ...state,
        lessonDetails: action.payload,
      };
    default:

      return {
        ...state,
      };

  }
}

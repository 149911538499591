import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LIST_COUNTRY } from '../../redux/constants';
import { translationType } from '../../types';
import { PublicNav } from '../NavigationBar';
import {
  HeroSection,
  GetStartedSection,
  SessionsSection,
  TeachersSection,
  CountriesSection,
  CovidSection,
  ImpactSection,
  FooterSection,
} from './sections';
import { getSelectedCountry } from '../../constants';
import Mixpanel from '../Mixpanel';
import { generateLocalizedUrl } from '../../helpers';

class GlobalLanding extends Component {
  static propTypes = {
    listCountry: PropTypes.func.isRequired,
    history: PropTypes.shape().isRequired,
    noonText: translationType.isRequired,
    countries: PropTypes.oneOfType([PropTypes.object]).isRequired,
  };

  componentDidMount() {
    this.props.listCountry();
    const country = getSelectedCountry();

    /* [start] mixpanel event */
    try {
      Mixpanel.track('teacher_landing_shown', {
        landing_country: country.full_name,
      });
    } catch (e) {
      console.log('error', e);
    }
    // end mixpanel event
  }

  changeLocale = () => {
    const { countries } = this.props;
    const selectedLocale = countries.selectedCountry.locale;
    const newLocale = localStorage.language && localStorage.language === 'en' ? selectedLocale : 'en';
    localStorage.setItem('language', newLocale);
    delete localStorage.translationData;
    const isoCode = localStorage.updatedLocale.split('-')[0];
    const url = generateLocalizedUrl(window.location.pathname, `${isoCode}-${newLocale.split('_')[0]}`);
    window.location.href = url;
  }

  render() {
    const { countries, noonText, history } = this.props;
    const { countryList, selectedCountry } = countries;

    return (
      <div className="landing-container">
        <PublicNav />
        <HeroSection history={history} />
        <TeachersSection />
        {countryList && countryList.length && <CountriesSection countries={countryList} />}
        <GetStartedSection />
        <SessionsSection />
        <ImpactSection />
        <CovidSection />
        <FooterSection history={history} noonText={noonText} selectedCountry={selectedCountry} changeLocale={this.changeLocale} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  noonText: state.toJS().translation.noonText,
  user: state.toJS().user.loggedUser,
  countries: state.toJS().folder.countries,
});

const mapDispatchToProps = (dispatch) => ({
  listCountry: () => dispatch({ type: LIST_COUNTRY.REQUEST }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GlobalLanding);

import { createSelector } from 'reselect';

const userSelectorRaw = state => state.toJS().user.loggedUser;

const userSelector = createSelector(
  userSelectorRaw,
  item => item,
);

export { userSelector };

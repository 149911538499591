import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { InlineMath } from 'react-katex';

const Paragraph = ({ content, onImageClick }) => (
  <div className="text-line">
    {content.map((item, index) => (
      <React.Fragment key={index}>
        {item.type === 'text' && <span className={classNames('text-inline', { ...item.style, ellipses: item.value.length > 100 })}>{item.value}</span>}
        {item.type === 'image' && (
          <img
            src={item.value}
            alt="choice"
            height={item.height}
            onClick={(event) => {
              if (onImageClick) {
                onImageClick(event, item.value);
              }
            }} />
        )}
        {item.type === 'latex' && <InlineMath>{item.value}</InlineMath> }
      </React.Fragment>
    ))}
  </div>
);

Paragraph.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  onImageClick: PropTypes.func,
};

Paragraph.defaultProps = {
  onImageClick: undefined,
};

export default Paragraph;

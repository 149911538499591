import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@noon/atom';
import { translationText } from '../../helpers';
import { RESET_VERIFY_OTP } from '../../redux/constants';

class EmailOtpSentConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showResendLink: true,
      resendCount: 0,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.showResendLink !== prevState.showResendLink && !this.state.showResendLink) {
      if (this.state.resendCount < 3) {
        setTimeout(() => {
          this.setState({ showResendLink: true });
        }, 30000);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetOtpInfo();
  }

  resendOtp = (e) => {
    const { email, sendOtpViaEmail } = this.props;
    this.setState(
      prevState => ({ resendCount: prevState.resendCount + 1, showResendLink: false }),
      () => {
        sendOtpViaEmail({ email });
      },
    );
    e.preventDefault();
  };
  render() {
    const { greeting, email, noonText } = this.props;
    const { showResendLink } = this.state;
    return (
      <div className="otpConfirmation">
        <div className="otpConfirmation__body">
          <img src="/assets/images/onboarding/success-email-otp.png" alt="otp sent succesfully" />
          <p
            className="otpConfirmation__greeting"
            dangerouslySetInnerHTML={{ __html: greeting.replace('${email}', `<span>${email}</span>`) }}
          />
          {showResendLink && (
            <p className="otpConfirmation__resend-container">
              {translationText(noonText, 'emailOtpSentConfirmation.didntRecieveEmail')}
              <Button className="otpConfirmation__resend" onClick={this.resendOtp} type="link">
                {translationText(noonText, 'emailOtpSentConfirmation.resend')}
              </Button>
            </p>
          )}
          <p className="otpConfirmation__expiry-message">
            {translationText(noonText, 'emailOtpSentConfirmation.linkExpiryMessage')}
          </p>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  noonText: state.toJS().translation.noonText,
});

const mapDispatchToProps = dispatch => ({
  resetOtpInfo: () => dispatch({
    type: RESET_VERIFY_OTP,
  }),
});

EmailOtpSentConfirmation.propTypes = {
  sendOtpViaEmail: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  greeting: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailOtpSentConfirmation);

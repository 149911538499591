import React, { useRef, useState, useEffect } from 'react';
import isEmpty from 'lodash-es/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { groupBy } from 'lodash-es';
import { isMobile } from 'react-device-detect';
import { Button, Column, Row, NoonDate, ShareUrl, Badge, LiveTime, Time, ProgressSpinner, LinearPercentageIndicator } from '@noon/atom';
import { IconLoaderDotted, IconHappy, IconSad } from '@noon/quark';
import ErrorBoundry from '../ErrorBoundry';
import { setDisableWebrtc, startClassroom, startSessionRecording } from '../../redux/actions/classroom';
import { useEvent, useEventDispatch } from '../../providers/Event';
import { CLASSROOM_EVENT } from './events';
import { COLORS } from '../../constants';
import MicIcon from '../Class/micIcon';
import { SessionGenericModal } from '../Modals';
import { athenaApi } from '../../redux/restApi';
import { addToast, TOAST_TYPE } from '../Toast';
import ExitClassModal from '../Class/exitClassModal';
import t from '../../helpers/translate';
import { useClassroomAnalytics } from '../../providers/ClassroomAnalytics';
import { takeScreenShot } from '../../redux/actions/whiteboard';
import { toggleAudio } from '../../redux/actions/webrtc';
import { SESSION_PREP_END_DATA_EVENT, SET_LOCAL_DEVICE_DETAILS, SWITCH_VIDEO_DEVICE, SWITCH_AUDIO_DEVICE } from '../../redux/constants';
import { mapRange } from '../../helpers';
import VideoCam from './Video/VideoCam';
import PopoverList from './popoverList';

const NO_OF_SLIDES_WHEN_MARK_SESSION_AS_PREPARED = 2;

const Mic = () => {
  const dispatch = useDispatch();
  const sendMessage = useEventDispatch();
  const dispatchAnalytics = useClassroomAnalytics();

  const sessionDetails = useSelector((state) => state.toJS().myClassroom.sessionDetails);
  const user = useSelector((state) => state.toJS().user.loggedUser);
  const micVolumeIndicator = useSelector((state) => state.toJS().webrtc.micVolumeIndicator);
  const isAudioStatusLoading = useSelector((state) => state.toJS().webrtc.isAudioStatusLoading);
  const audioStatus = useSelector((state) => state.toJS().webrtc.audioStatus);
  const webrtcStatus = useSelector((state) => state.toJS().webrtc.status);

  const isWebrtcConnected = webrtcStatus === 'CONNECTED';

  const handleMicToggle = () => {
    if (isAudioStatusLoading) return;
    if (!isWebrtcConnected) {
      addToast(t('classroom', 'audioServiceNotConnected'), TOAST_TYPE.ERROR);
      return;
    }
    dispatch(toggleAudio('REQUEST'));
  };

  useEffect(() => {
    if (sessionDetails?.id) {
      dispatchAnalytics('mic_action', {
        action: audioStatus ? 'unmute' : 'mute',
        status: 'success',
      });
      sendMessage(CLASSROOM_EVENT.audioToggle, { audioStatus });

      // initiate recording api
      if (audioStatus && localStorage.getItem('archiveStarted') !== String(sessionDetails.id)) {
        dispatch(startSessionRecording({ session_id: sessionDetails.id }));
      }
    }
  }, [audioStatus]);

  // mapRange(value, min input val, max input value, min output value, max output value)
  const volume = audioStatus ? mapRange((micVolumeIndicator?.[user.user_id] || 0), 0, 60, 0, 32) : 0;

  return (
    <Row align="center" className="relative">
      {!isWebrtcConnected && isAudioStatusLoading ? (
        <IconLoaderDotted height="32px" width="32px" className="mic-loader" />
      ) : (
        <div className="volume-bar">
          <div className="volume-bar__indicator" style={{ height: `${volume}px` }} />
        </div>
      )}
      <MicIcon fill="#31A359" width="28px" height="28px" active={audioStatus} onToggle={handleMicToggle} />
    </Row>
  );
};

const ClassroomHeader = (prop) => {
  const { onCloseClassroom } = prop;

  const dispatch = useDispatch();
  const sendMessage = useEventDispatch();
  const dispatchAnalytics = useClassroomAnalytics();
  const { agoraRtm, pubnub } = useEvent();
  const sessionTimer = useRef(null);

  const sessionDetails = useSelector((state) => state.toJS().myClassroom.sessionDetails);
  const isClassStartLoading = useSelector((state) => state.toJS().myClassroom.isClassStartLoading);
  const isClassInitiated = useSelector((state) => state.toJS().myClassroom.isClassInitiated);
  const { understoodCount, notUnderstoodCount } = useSelector((state) => state.toJS().myClassroom.studentFeedback);
  const exitClassroomByServer = useSelector((state) => state.toJS().myClassroom.exitClassroomByServer);
  const slides = useSelector((state) => state.toJS().whiteboard.slides);
  const localDevices = useSelector((state) => state.toJS().myClassroom.localDevices);

  const [startButtonState, setStartButtonState] = useState('');
  const [showExitConfirmationModal, setShowExitConfirmationModal] = useState(false);
  // const [showRatingModal, setShowRatingModal] = useState(false);

  const [showMarkSessionAsPreparedModal, setShowMarkSessionAsPreparedModal] = useState(false);
  const [isLoadingTogglePreparation, setIsLoadingTogglePreparation] = useState(false);

  const isStarted = sessionDetails.state === 'started';
  const isCompetition = sessionDetails.class_type === 'competition';

  const handleOnSelectCamera = (device) => {
    if (localDevices?.selected?.camera.deviceId !== device.deviceId) {
      dispatch({
        type: SET_LOCAL_DEVICE_DETAILS,
        payload: {
          selected: {
            camera: device,
            microphone: localDevices?.selected?.microphone,
          },
        },
      });
      dispatch({
        type: SWITCH_VIDEO_DEVICE,
        payload: true,
      });
    }
  };

  const handleOnSelectMicrophone = (device) => {
    if (localDevices?.selected?.microphone.deviceId !== device.deviceId) {
      dispatch({
        type: SET_LOCAL_DEVICE_DETAILS,
        payload: {
          selected: {
            camera: localDevices?.selected?.camera,
            microphone: device,
          },
        },
      });
      dispatch({
        type: SWITCH_AUDIO_DEVICE,
        payload: true,
      });
    }
  };

  const handleExit = () => {
    if (isStarted) {
      setShowExitConfirmationModal(false);
      // const secsSinceStart = (Date.now() - sessionDetails.start_time) / 1000;
      // if (!showRatingModal) {
      // if (!showRatingModal || secsSinceStart > 120) {
      sendMessage(CLASSROOM_EVENT.exitClass, { isTeacher: 1 }).then(() => {
        // destroy connection before opening rating modal
        agoraRtm?.disconnect();
        pubnub?.disconnect();
        dispatch(setDisableWebrtc(true)); // to disconnect webrtc modules
      });
      dispatchAnalytics('teacher_session_end', {
        number_of_slides: slides.length,
        number_of_questions: slides.filter((item) => item.resource_type === 'question')?.length,
        new_classroom: true,
      });
      // if (secsSinceStart <= 120) {
      // setShowRatingModal(true);
      // }
      localStorage.removeItem('classroom_mic-mute');
      localStorage.removeItem('raiseHandQueue');
      // }
      setTimeout(() => {
        onCloseClassroom();
      }, 200);
    } else {
      onCloseClassroom();
      const sections_count = groupBy(slides, 'label_id').length;

      // send prep end event
      dispatch({
        type: SESSION_PREP_END_DATA_EVENT,
        payload: { ended_by: 'save', sessionData: sessionDetails, sections_count, slides_count: slides.length },
      });
    }
  };

  const handleConfirmExit = () => {
    // if (!isCompetition) {
    dispatch(takeScreenShot());
    // }
    setTimeout(() => {
      if (isStarted) {
        setShowExitConfirmationModal(true);
      } else if (slides.length > NO_OF_SLIDES_WHEN_MARK_SESSION_AS_PREPARED && !showMarkSessionAsPreparedModal && !sessionDetails.preparation_complete) {
        setShowMarkSessionAsPreparedModal(true);
      } else {
        handleExit();
      }
    }, 10);

  };

  const startClassValidation = () => {
    if (isCompetition && !slides.length) {
      addToast(t('classroom', 'NotifyaddQuestionTostart'), TOAST_TYPE.ERROR, 5);
      return false;
    }
    return true;
  };

  const handleStartClassroom = () => {
    if (!startClassValidation()) return;
    dispatch(startClassroom(sessionDetails.id));
    const sections_count = groupBy(slides, 'label_id').length;
    localStorage.removeItem('raiseHandQueue');
    // send prep end event
    dispatch({
      type: SESSION_PREP_END_DATA_EVENT,
      payload: { ended_by: 'start_session', sessionData: sessionDetails, sections_count, slides_count: slides.length },
    });
  };

  const handleMarkSessionAsPrepared = () => {
    setIsLoadingTogglePreparation(true);
    athenaApi
      .put('teacher/togglePreparation', { id: sessionDetails.id, preparation_complete: true })
      .then(() => {
        handleExit();
      })
      .catch(() => {
        setIsLoadingTogglePreparation(true);
      });
  };

  const initiateTimer = (time: number) => {
    if (!time) return;

    if (sessionTimer.current) clearInterval(sessionTimer.current);
    sessionTimer.current = setInterval(() => {
      const duration = (time - Date.now()) / (1000 * 60); // in mins
      if (duration > 30 && startButtonState !== 'dateTime') {
        setStartButtonState('dateTime');
      } else if (duration <= 30 && duration > 10 && startButtonState !== 'timer') {
        setStartButtonState('timer');
      } else if (duration <= 10) {
        clearInterval(sessionTimer.current);
        setStartButtonState('startButton');
      }
    }, 1000);
  };

  const ClassroomTimer = () => (
    <>
      {isClassInitiated && !isStarted && (
      <>
        {startButtonState === 'dateTime' && (
        <Column align="center" gap="sm">
          <span>
            {t('classroom', 'scheduledFor')}
          </span>
          <NoonDate className="child" format="ccc, dd LLL hh:mm a" value={sessionDetails.start_time} />
        </Column>
        )}
        {startButtonState === 'timer' && (
        <LiveTime value={sessionDetails.start_time} rendered>
          {({ value }) => (
            <ProgressSpinner
              thickness={3}
              antiClockwise
              noPointer
              activeColor={COLORS.brand.base}
              progress={(value * 100) / 1800}
              text={<Time value={Math.abs(value)} />}
              radius={20}
              fill="#fff"
              className="child"
            />
          )}
        </LiveTime>
        )}
      </>
      )}
      {isStarted && (
      <LiveTime value={sessionDetails.start_time} rendered>
        {({ value }) => (
          <Badge className={classNames('class-timer child', { warn: value >= 3600, alert: value >= 6000 })}>
            <Time value={value} />
          </Badge>
        )}
      </LiveTime>
      )}
    </>
  );

  const StudentFeedbackMeter = () => (
    <Column className="reaction-counter">
      <Row className="reaction reaction--happy">
        <IconHappy />
        <LinearPercentageIndicator
          total={100}
          current={`${(understoodCount / (understoodCount + notUnderstoodCount)) * 100}%`}
          bg={COLORS.coolGrey[5]}
          color={COLORS.green.base}
        />
      </Row>
      <Row className="reaction reaction--sad">
        <IconSad />
        <LinearPercentageIndicator
          total={100}
          current={`${(notUnderstoodCount / (understoodCount + notUnderstoodCount)) * 100}%`}
          bg={COLORS.coolGrey[5]}
          color={COLORS.red.base}
        />
      </Row>
    </Column>
  );

  useEffect(() => {
    if (sessionDetails.state !== 'started') {
      initiateTimer(sessionDetails.start_time);
    }
    return () => {
      if (sessionTimer.current) clearInterval(sessionTimer.current);
    };
  }, [sessionDetails]);

  useEffect(() => {
    if (exitClassroomByServer) {
      addToast(t('classroom', 'endedByServer'), TOAST_TYPE.INFO);
      onCloseClassroom();
    }
  }, [exitClassroomByServer]);

  return (
    <ErrorBoundry msg="There is some problem with classroom header, please refresh to try again">
      {showMarkSessionAsPreparedModal && (
        <SessionGenericModal
          type="edit"
          text1={t('session', 'markSessionPrepared')}
          btn1={t('session', 'confirm')}
          btn2={t('session', 'cancel')}
          onClick={handleMarkSessionAsPrepared}
          isLoading={isLoadingTogglePreparation}
          onClose={handleExit}
        />
      )}

      {showExitConfirmationModal && <ExitClassModal exitClass={handleExit} onClose={() => setShowExitConfirmationModal(false)} />}

      {/* {showRatingModal && <RatingModal sessionData={sessionDetails} exitClass={handleExit} />} */}

      <Row className="my-classroom__header" gap>
        {isStarted
        && (
        <React.Fragment>
          <Row nowrap justify="start" align="center">
            <Mic />
            {!isEmpty(localDevices?.microphones)
            && (
              <PopoverList
                listItems={localDevices?.microphones}
                onSelect={handleOnSelectMicrophone}
                selectedItem={localDevices.selected?.microphone}
                uniqueKey="deviceId"
               />
            )}
          </Row>
          {sessionDetails.is_video_enabled
          && (
          <Row nowrap justify="start" align="center">
            <VideoCam />
            {!isEmpty(localDevices?.cameras) && localDevices.isCameraWorking
            && (
              <PopoverList
                listItems={localDevices?.cameras}
                onSelect={handleOnSelectCamera}
                selectedItem={localDevices.selected?.camera}
                uniqueKey="deviceId"
               />
            )}
          </Row>
          )}
        </React.Fragment>
        )}

        <Column flex="1" justify="center" className="my-classroom__header-title">
          <p>{sessionDetails.title}</p>
          {Boolean(sessionDetails.description) && <span>{sessionDetails.description}</span>}
        </Column>
        {isStarted && <StudentFeedbackMeter />}
        <ClassroomTimer />

        {!isMobile && (
          <ShareUrl
            url={`${process.env.STUDENT_URL}${localStorage.updatedLocale}/class/${sessionDetails.id}`}
            button="secondary"
            copyMessageDirection="right"
            buttonText={t('library', 'ctaShareClass')}
            messageCopied={t('label', 'copied')}
          />
        )}
         <Button type={isStarted ? 'red' : 'secondary'} onClick={handleConfirmExit}>
          {isStarted ? t('library', 'ctaEndClass') : t('competition', 'saveDraft')}
          </Button>
        
        {!isStarted && <Button type="primary" onClick={handleStartClassroom} loading={isClassStartLoading}>{t('button', 'startTheClass')}</Button>}

      </Row>
    </ErrorBoundry>
  );
};

export default ClassroomHeader;

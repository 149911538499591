import { CLASSROOM_STUDENT_FEEDBACK } from '../../../redux/constants';

let understoodTimer = null;
const CLEAR_FEEDBACK_COUNT_TIMEOUT = 30 * 1000; // 30s
export default function handleMsg(dispatch, e) {
  const payload = e.message;
  if (payload.value) {
    dispatch({ type: CLASSROOM_STUDENT_FEEDBACK, payload: { understood: true } });
  } else {
    dispatch({ type: CLASSROOM_STUDENT_FEEDBACK, payload: { understood: false } });
  }
  if (understoodTimer) {
    clearTimeout(understoodTimer);
    understoodTimer = null;
  }
  understoodTimer = setTimeout(() => {
    dispatch({ type: CLASSROOM_STUDENT_FEEDBACK, payload: { reset: true } });
    understoodTimer = null;
  }, CLEAR_FEEDBACK_COUNT_TIMEOUT);
}

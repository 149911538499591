import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Row, Column, Button } from '@noon/atom';
import { IconAddCircle, IconRemoveCircle, IconLoader, IconClose } from '@noon/quark';
import t from '../../../helpers/translate';
import { QNA_SET_DURATION, QNA_BREAKOUT_START_EVENT, GET_BREAKOUT_REPORT, QNA_BREAKOUT_END_EVENT } from '../../../redux/constants.js';

const TimeSelection = () => {

  const dispatch = useDispatch();
  const { duration, snapshotInProgress } = useSelector((state) => state.toJS().TeamQna);
  const { sessionDetails } = useSelector((state) => state.toJS().myClassroom);
  const setDuration = (payload) => { dispatch({ type: QNA_SET_DURATION, payload }); };

  const startQnaBreakout = () => {
    dispatch({ type: GET_BREAKOUT_REPORT.REQUEST, payload: { sessionId: sessionDetails.id, reload: false } });
    dispatch({ type: QNA_BREAKOUT_START_EVENT.BEFORE });
  };

  return (
    <Card className="qna-breakout-timer-seletion">
      <Column className="time-selection-wrapper">
        <Row justify="end">
          <IconClose className="close" height="30px" width="30px" fill="white" stroke="white" onClick={() => dispatch({ type: QNA_BREAKOUT_END_EVENT.AFTER })} />
        </Row>
        <h4>
          {t('teamQnaBreakout', 'setDuration')}
          {' '}
        </h4>
        <Row className="time-selection">
          <IconRemoveCircle className="selection-control" height="30px" width="30px" onClick={() => setDuration('dec')} />
          <Column className="time-display">
            {!!duration && <h1>{duration / 60}</h1>}
            <p>{t('teamQnaBreakout', 'minutes')}</p>
          </Column>
          <IconAddCircle className="selection-control" height="30px" width="30px" onClick={() => setDuration('inc')} />
        </Row>
        { !snapshotInProgress && <Button type="primary" onClick={startQnaBreakout}>{t('teamQnaBreakout', 'startDiscussion')}</Button> }
        { snapshotInProgress && <IconLoader height="40px" width="40px" fill="#0b9bd0" stroke="#0b9bd0" /> }
      </Column>
    </Card>
  );
};

export default TimeSelection;

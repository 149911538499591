import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconClose } from '@noon/quark';
import { Row } from '@noon/atom';
import { CLEAR_CHAPTERS_AND_TOPICS, CLEAR_TOPIC_QUESTIONS, GET_CHAPTERS_FROM_GROUP, GET_TOPICS_FROM_GROUP, LIBRARY_QUESTION_LIST, SET_QUESTION_CHAPTER, SET_QUESTION_TOPIC, TEACHER_QUESTION_LIST } from '../../redux/constants';
import ChapterAndTopicChoices from './chapterAndTopicChoices';
import LibrariesTabs from './librariesTabs';
import LibraryQuestionsList from './libraryQuestionsList';
import { translationText } from '../../helpers';
import { checkIsMobile, checkIsTablet, returnDuplicatePropsForMixpanelAndKafka, setHWChapterAndTopicInLocalStorage, returnSavedLastChapterTopic } from '../../helpers/prepareAssignment';

const defaultTopic = {
  chapter_id: null,
  end_date: null,
  group_id: null,
  id: null,
  name: null,
  name_header: null,
  name_header_key: null,
  start_date: null,
  state: null,
  topic_id: null,
  type: null,
};

const QuestionsBar = (props) => {
  const { groupId, isMobileQuestionsOpened, setIsMobileQuestionsOpened, noonText } = props;
  const isRtl = document.body.dir === 'rtl';

  const [tabs, setTabs] = useState([{ name: 'Noon Library', value: 'Noon Library' }]);
  const [selectedChapter, setSelectedChapter] = useState({
    id: undefined,
  });
  const [selectedTopic, setSelectedTopic] = useState(defaultTopic);
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);
  const [loadMore, setLoadMore] = useState(false);
  const [lastChapterId, setLastChapterId] = useState(undefined);
  const [lastTopicId, setLastTopicId] = useState(undefined);
  const logger = useRef(LoggingManager.mount({ moduleName: 'homework' })).current;

  const dispatch = useDispatch();
  const { groupChapters, groupTopics, selectedGroup } = useSelector((state) => state.toJS().groupsV2);
  const { loggedUser: user } = useSelector((state) => state.toJS().user);
  const { questions, teacherQuestions } = useSelector((state) => state.toJS().question);
  const { currentQuestions, hw_details } = useSelector((state) => state.toJS().homeworkCreation);

  useEffect(() => {
    setSelectedTopic(defaultTopic);
    setSelectedChapter({
      id: undefined
    });
    setLastChapterId(undefined);
    setLastTopicId(undefined);
    dispatch({
      type: CLEAR_CHAPTERS_AND_TOPICS
    })
    dispatch({
      type: CLEAR_TOPIC_QUESTIONS
    });
  }, [])
  useEffect(() => {
    setTabs([
      {
        name: translationText(noonText, 'createHomework.noonLibrary'),
        value: 'Noon Library',
      },
      {
        name: translationText(noonText, 'createHomework.myLibrary'),
        value: 'My Library',
      },
    ]);
  }, [noonText]);
  useEffect(() => {
    getChapters();
  }, [groupId]);
  useEffect(() => {
    if (selectedTopic.id && lastTopicId !== selectedTopic.id) {
      getQuestions();
      setLastTopicId(selectedTopic.id);
    }
  }, [selectedTopic]);
  useEffect(() => {
    if (selectedTopic.id) {
      getQuestions();
    }
  }, [selectedTab]);
  useEffect(() => {
    if (loadMore) getQuestions();
    return () => setLoadMore(false);
  }, [loadMore]);
  useEffect(() => {
    const hw_data = returnSavedLastChapterTopic();

    if (groupChapters.list.length > 0
      && (!hw_data || !hw_data[hw_details.id] || !hw_data[hw_details.id].chapter)
      && lastChapterId !== groupChapters.list[0].id
      && selectedChapter.id === undefined
      && selectedChapter.id !== groupChapters.list[0].id) {
      getTopics(groupChapters.list[0]);
      setSelectedChapter(groupChapters.list[0]);
    }
    if (hw_data && hw_data[hw_details.id] && hw_data[hw_details.id].chapter) {
      groupChapters.list.forEach((chapter, index) => {
        if (chapter.id === hw_data[hw_details.id].chapter && lastChapterId !== chapter.id) {
          getTopics(groupChapters.list[index], false);
          setSelectedChapter(groupChapters.list[index]);
        }
      });
    }
  }, [groupChapters, hw_details]);
  useEffect(() => {
    const hw_data = returnSavedLastChapterTopic();

    if (groupTopics.list.length > 0
      && (!hw_data || !hw_data[hw_details.id])
      && questions.list.length === 0
      && teacherQuestions.list.length === 0
      && lastChapterId !== groupTopics.chapterId
    ) {
      setSelectedTopic(groupTopics.list[0]);
      getQuestions();
      setLastChapterId(groupTopics.chapterId);
    }
    if (groupTopics.list.length === 0 && hw_data && hw_data[hw_details.id] && hw_data[hw_details.id].topic && hw_details.id !== undefined) {
      let isTopicRelated = false;
      groupTopics.list.forEach((topic, index) => {
        if (topic.id === hw_data[hw_details.id].topic) {
          isTopicRelated = true;
          setSelectedTopic(groupTopics.list[index]);
        }
      });
      if (!isTopicRelated) {
        setSelectedTopic(defaultTopic);
      }
      setLastChapterId(groupTopics.chapterId);
    }
  }, [groupTopics]);

  const getQuestions = () => {
    if (selectedTopic && selectedTab) {
      dispatch({
        type: SET_QUESTION_TOPIC,
        topic: selectedTopic,
      });
      if (selectedTab === 'My Library' && selectedTopic.topic_id && selectedTopic.group_id === groupId) {
        dispatch({
          type: TEACHER_QUESTION_LIST.REQUEST,
          payload: {
            page: teacherQuestions.list.length > 0 && selectedTab === teacherQuestions.tab
              ? teacherQuestions.nextPage === -1
                ? 1
                : teacherQuestions.nextPage
              : 1,
            limit: '10',
            topic_id: selectedTopic.topic_id,
          },
        });
      }
      if (selectedTab === 'Noon Library' && selectedTopic.topic_id && selectedTopic.group_id === groupId) {
        dispatch({
          type: LIBRARY_QUESTION_LIST.REQUEST,
          payload: {
            page: questions.list.length > 0 && selectedTab === questions.tab
              ? questions.nextPage === -1
                ? 1
                : questions.nextPage
              : 1,
            limit: '10',
            topic_id: selectedTopic.topic_id,
          },
        });
      }
    }
  };

  const getTopics = (chapter, isDefault = true) => {
    dispatch({
      type: GET_TOPICS_FROM_GROUP.REQUEST,
      payload: {
        groupId,
        chapterId: chapter.chapter_id,
      },
    });
    dispatch({
      type: SET_QUESTION_CHAPTER,
      chapter,
    });
    if (isDefault) {
      setSelectedTopic(defaultTopic);
    }
  };

  const getChapters = () => {
    dispatch({
      type: GET_CHAPTERS_FROM_GROUP.REQUEST,
      payload: groupId,
    });
  };

  const setChapterAndRequestTopics = (chapter) => {
    getTopics(chapter);
  };

  const mixpanelKafkaEvents = () => {
    const type = 'question_added';
    const props = returnDuplicatePropsForMixpanelAndKafka(selectedGroup, user, hw_details);
    const properties = {
      no_of_questions: currentQuestions.data ? currentQuestions.data.length : 0,
      ...props,
    };
    logger.track(type, { homework: properties });
  };

  const clearQuestions = () => {
    dispatch({
      type: CLEAR_TOPIC_QUESTIONS,
    });
  };

  const selectTopic = (topic) => {
    setHWChapterAndTopicInLocalStorage(hw_details, 'topic', topic.id);
    clearQuestions();
    setSelectedTopic(topic);
  };

  const returnPositionOfCrossButton = () => (isRtl ? { left: checkIsMobile() || checkIsTablet() ? '28px' : '0' } : { right: checkIsMobile() || checkIsTablet() ? '28px' : '0' });

  return (
    <nav className={`prepare-assignment--bar ${(checkIsMobile() || checkIsTablet()) ? isMobileQuestionsOpened ? 'modal-opened' : 'modal-closed' : ''}`}>
      {(checkIsMobile() || checkIsTablet()) && (
        <React.Fragment>
          <Row align="center" justify="center" className="prepare-assignment--bar--title">
            <span>{translationText(noonText, 'createHomework.addFromLibrary')}</span>
            <IconClose height="16px" width="16px" onClick={setIsMobileQuestionsOpened} style={returnPositionOfCrossButton()} />
          </Row>
        </React.Fragment>
      )}
      <ChapterAndTopicChoices
        chapters={groupChapters.list}
        setChapterAndRequestTopics={setChapterAndRequestTopics}
        topics={groupTopics.list}
        setSelectedTopic={(topic) => selectTopic(topic)}
        selectedTopic={selectedTopic}
        setSelectedChapter={(chapter) => {
          setHWChapterAndTopicInLocalStorage(hw_details, 'chapter', chapter.id);
          setSelectedChapter(chapter);
          setChapterAndRequestTopics(chapter);
          clearQuestions();
        }}
        selectedChapter={selectedChapter}
      />
      <LibrariesTabs tabs={tabs} setSelectedTab={setSelectedTab} selectedTab={selectedTab} />
      <LibraryQuestionsList
        questions={selectedTab === 'Noon Library' ? questions.list : teacherQuestions.list}
        isNextPage={selectedTab === 'Noon Library' ? questions.nextPage !== -1 : teacherQuestions.nextPage !== -1}
        setLoadMore={() => setLoadMore(true)}
        loading={selectedTab === 'Noon Library' ? questions.isLoading : teacherQuestions.isLoading}
        selectedTab={selectedTab}
        noonText={noonText}
      />
    </nav>
  );
};

export default QuestionsBar;

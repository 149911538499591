import React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash-es/isEmpty';
import ImageWithOrientation from '../Layout/imageWithOrientation';

export default function MultipleImages(prop) {
  const { files, showFullSizeImage } = prop;
  const numberOfImages = files && files.length;
  const imageQuantity = numberOfImages === 1 ? 'single' : numberOfImages < 5 ? 'multiple' : 'multiple more-than-four';
  const multiple = numberOfImages > 1;
  const fileList = numberOfImages > 4 ? files.slice(0, 4) : files;
  return (
    <div className={classNames('multiple-images', { multiple })}>
      {/* <Row justify="center"> */}
      {!isEmpty(files) &&
        fileList.map((img, idx) => (
          <div
            className={classNames(`multiple-images--images ${imageQuantity}`, {
              'third-image': idx === 2 && numberOfImages === 3,
            })}
            key={idx}
            onClick={() => showFullSizeImage(idx)}
          >
            <ImageWithOrientation
              mobileFullWidth={220}
              desktopFullWidth={680}
              maxHeight={multiple ? 350 : 600}
              className={idx === 3 ? 'post-image last-shown-image' : 'post-image'}
              url={img.large || img.medium || img.original}
              files={img}
              imageQuantity={imageQuantity}
              multiple={multiple}
              fourthIndex={idx === 3 && numberOfImages - 4 > 0}
              threeImages={idx === 2 && numberOfImages === 3}
              remainingImages={idx === 3 ? numberOfImages - 4 : null}
              totalImages={numberOfImages}
            />
          </div>
        ))}
    </div>
  );
}

import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Events, Element, scrollSpy, scroller } from 'react-scroll';
import { Row } from '@noon/atom';
import { IconBack, IconLoader } from '@noon/quark';
import Carousel from 'react-multi-carousel';
import { checkIsMobile, returnDuplicatePropsForMixpanelAndKafka } from '../../helpers/prepareAssignment';
import HomeworkSingleQuestion from './homeworkSingleQuestion';
import { EDIT_QUESTIONS_LIST } from '../../redux/constants';

const responsive = {
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 4,
    slidesToSlide: 4
  }
};

const HomeworkQuestions = () => {
  const { currentQuestions } = useSelector(state => state.toJS().homeworkCreation);
  const { loggedUser: user } = useSelector((state) => state.toJS().user);
  const { selectedGroup } = useSelector((state) => state.toJS().groupsV2);
  const dispatch = useDispatch();

  useEffect(() => {
    Events.scrollEvent.register('begin');
    Events.scrollEvent.register('end');

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  const mixpanelKafkaEvents = () => {
    const type = 'question_reordered';
    const props = returnDuplicatePropsForMixpanelAndKafka(selectedGroup, user, hw_details);
    const properties = {
      no_of_questions: currentQuestions.data ? currentQuestions.data.length : 0,
      ...props,
    };
    logger.track(type, { homework: properties });
  };

  const changeOrder = (e) => {
    mixpanelKafkaEvents();
    dispatch({
      type: EDIT_QUESTIONS_LIST.CHANGE_ORDER,
      payload: e,
    });
    if (e.addedIndex && e.removedIndex) {
      scroller.scrollTo(`question${e.addedIndex + 1}`, {
        duration: 500,
        delay: 50,
        smooth: true,
        containerId: 'containerElement',
      });
    }
  };

  return (
    <React.Fragment>
      {currentQuestions.loading ? (
        <Row align="center" justify="center" style={{ height: '100%' }}>
          <IconLoader height="90px" width="90px" />
        </Row>
      ) : (
        <Element
          className="element"
          id="containerElement"
          style={{
            position: 'relative',
            overflowY: 'scroll',
            height: '100%',
          }}
          >
          {currentQuestions.data.map((question, index) => <HomeworkSingleQuestion key={index} index={index} question={question} />)}
        </Element>
      )}
      {checkIsMobile() && (
        <Row justify="center" align="center" className="prepare-assignment--mobileQuestionsPagination--wrapper">
          <Carousel
            responsive={responsive}
            keyBoardControl={false}
            swipeable={true}
            draggable={true}
            transitionDuration={1000}
            containerClass="carousel-container"
            deviceType={'mobile'}
            ssr
            customLeftArrow={<IconBack fill="#ffffff" stroke="#ffffff" height="20px" width="20px" className="arrow arrow-left" />}
            customRightArrow={<IconBack fill="#ffffff" stroke="#ffffff" rotate="180" height="20px" width="20px" className="arrow arrow-right" />}
            arrows
          >
            {currentQuestions.data.map((_, index) => (
                <Link activeClass="activeQuestion" containerId="containerElement" spy smooth duration={250} to={`question${index + 1}`} key={index}>
                  {index + 1}
                </Link>
            ))}
          </Carousel>
        </Row>
      )}
    </React.Fragment>
  );
};

export default HomeworkQuestions;

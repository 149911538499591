import { put, call, takeEvery, all } from 'redux-saga/effects';
import isEmpty from 'lodash-es/isEmpty';
import { TrackSentryError, SentryType } from 'SentryAlias';
import { folderApi, curriculumApi } from '../restApi';
import { DEFAULT_COUNTRY } from '../../constants';
import {
  LIST_COUNTRY,
  SEARCH_PAGE,
} from '../constants';

const searchPageAPI = (payload) => folderApi.post('v2/searchPage', payload);
const listCountryAPI = () => curriculumApi.get('teacher/countries?start=0&limit=20');

function* searchPage({ payload }) {
  try {
    const response = yield call(searchPageAPI, payload);
    if (response.data.success) {
      yield put({
        type: SEARCH_PAGE.SUCCESS,
        payload: isEmpty(response.data.data) ? [] : response.data.data,
      });
    } else {
      yield put({ type: SEARCH_PAGE.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: SEARCH_PAGE.FAILURE, payload: err });
  }
}

function* listCountry() {
  try {
    const response = yield call(listCountryAPI);
    if (response.data.status) {
      let countryList = response.data.data;
      let selectedCountry = response.data.meta.selected_country;
      if (!isEmpty(selectedCountry)) {
        const CountriesNotInMeta = countryList.filter((country) => country.id !== selectedCountry.id);
        countryList = [
          ...CountriesNotInMeta,
          { ...selectedCountry, unpublished: CountriesNotInMeta.length === countryList.length },
        ];
      }
      const countries = localStorage.country ? JSON.parse(localStorage.country) : {};
      const selectedCountryIndex = countries.selectedCountry ? countryList.findIndex((o) => o.id === countries.selectedCountry.id) : -1;
      if (selectedCountryIndex !== -1) {
        selectedCountry = countryList[selectedCountryIndex];
      }
      yield put({
        type: LIST_COUNTRY.SUCCESS,
        payload: { countryList, selectedCountry },
      });
    } else {
      yield put({ type: LIST_COUNTRY.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    // fallback if country api is failing
    try {
      TrackSentryError('COUNTRY API FAILING', err, SentryType.ERROR);
    } catch (e) { console.log(e); }
    const countries = localStorage.country ? JSON.parse(localStorage.country) : {};
    const selectedCountry = countries.selectedCountry ? countries.selectedCountry : DEFAULT_COUNTRY;
    yield put({
      type: LIST_COUNTRY.SUCCESS,
      payload: { countryList: countries.countryList || [selectedCountry], selectedCountry },
    });
  }
}

export function* folderSaga() {
  yield all([
    takeEvery(LIST_COUNTRY.REQUEST, listCountry),
    takeEvery(SEARCH_PAGE.REQUEST, searchPage),
  ]);
}

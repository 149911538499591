import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash-es/isEmpty';
import { Info, DateTime } from 'luxon';
import Pikaday from 'pikaday';
import classNames from 'classnames';
import { Select, Button, Input, Checkbox, Row } from '@noon/atom';
import { IconLoaderDotted } from '@noon/quark';
import { useHistory, useLocation } from 'react-router-dom';
import { addToast, TOAST_TYPE } from '../Toast';

import {
  GET_TEACHER_GROUPS,
  GET_CHAPTERS_FROM_GROUP,
  GET_TOPICS_FROM_GROUP,
  CREATE_HOMEWORK_SPARTA,
} from '../../redux/constants';
import { translationText } from '../../helpers';
import DatePicker from '../DatePicker';
import { prepareDateForPosting, returnDuplicatePropsForMixpanelAndKafka } from '../../helpers/prepareAssignment';
import { getWeekdays, getWeekdaysShort } from '../../helpers/date';

import Mixpanel from '../Mixpanel';
import TimeAssignmentWarning from './TimeAssignmentWarning';

export default function CreateAssignmentDraft(prop) {
  const { groupId, sessionData, chapter, topic } = prop;
  const dispatch = useDispatch();
  const dateRef = useRef(null);
  const logger = useRef(LoggingManager.mount({ moduleName: 'homework' })).current;
  const history = useHistory();
  const location = useLocation();
  const initTime = DateTime.local().set({ hour: 23, minute: 45 }).toMillis();
  const [time, setTime] = useState(initTime);
  const [title, setTitle] = useState('');
  const [fieldsState, setFieldState] = useState({});
  const [inputCharCount, setInputCharCount] = useState({});
  const {
    groupChapters,
    groups: { data: groupList },
    selectedGroup,
  } = useSelector((state) => state.toJS().groupsV2);
  const { noonText } = useSelector((state) => state.toJS().translation);
  // const { homeworkCreate } = useSelector((state) => state.toJS().test);
  const { hw_creation } = useSelector((state) => state.toJS().homeworkCreation);
  const { loggedUser: user } = useSelector((state) => state.toJS().user);

  const isRtl = document.body.dir === 'rtl';

  const handleOnChangeTime = (time) => {
    setTime(time);
  };

  const mapGroupData = (data) => {
    if (data && data.length > 0) {
      return data.map(({ id, title }) => ({
        id,
        name: title,
      }));
    }
    return [];
  };

  const handleSelectChapter = (chapterId) => {
    const gId = groupId || (fieldsState.group && fieldsState.group.id);
    dispatch({
      type: GET_TOPICS_FROM_GROUP.REQUEST,
      payload: { groupId: gId, chapterId },
    });
  };

  const getChapters = (id) => {
    if (groupChapters.list && groupChapters.list.length && groupChapters.list[0].group_id === id) return;
    dispatch({
      type: GET_CHAPTERS_FROM_GROUP.REQUEST,
      payload: id,
    });
  };

  useEffect(() => {
    const locale = { locale: localStorage.language.split('_')[0] || 'en' };
    const picker = new Pikaday({
      field: dateRef.current,
      format: 'YYYY-MM-DD',
      i18n: {
        months: Info.months('long', locale),
        weekdays: getWeekdays(),
        weekdaysShort: getWeekdaysShort(),
      },
      onSelect: (event) => handleOnChangeFieldValue('date', event),
    });
    picker.setDate(new Date());
    if (chapter && chapter.state !== 'completed') {
      handleSelectChapter(chapter.chapter_id);
      setFieldState({
        ...fieldsState,
        chapter: {
          id: chapter.chapter_id,
          name: chapter.name,
          type: chapter.type,
        },
        topics:
          topic && topic.state !== 'completed'
            ? [
                {
                  id: topic.topic_id,
                  name: topic.name,
                },
              ]
            : [],
      });
    }
    if (sessionData) {
      const initFieldsState = {
        title: sessionData.title,
        agenda: sessionData.description,
      };
      if (sessionData.curriculum_tags) {
        initFieldsState.chapter = sessionData.curriculum_tags.chapter;
        if (sessionData.curriculum_tags.chapter) {
          handleSelectChapter(sessionData.curriculum_tags.chapter.id);
        }
        initFieldsState.topics = sessionData.curriculum_tags.topics;
      }
      setFieldState(initFieldsState);
      setInputCharCount({
        sessionAgenda: sessionData.description && sessionData.description.length,
        sessionTitle: sessionData.title && sessionData.title.length,
      });
      const date = new Date(sessionData.start_time);
      picker.setDate(date);
    } else {
      const datenew = DateTime.fromISO(new Date().toISOString());
      picker.setDate(datenew);
      picker.setMinDate(new Date());
    }
    if (!groupId) {
      dispatch({
        type: GET_TEACHER_GROUPS.REQUEST,
      });
    } else {
      getChapters(groupId);
    }
  }, []);

  const handleOnChangeFieldValue = (field, value) => {
    if (field === 'chapter') {
      handleSelectChapter(value.chapter_id);
      setFieldState((lastState) => ({
        ...lastState,
        [field]: value,
        topics: [],
      }));
    } else if (field === 'group') {
      console.log('-->', value.id);
      getChapters(value.id);
      setFieldState((lastState) => ({
        ...lastState,
        [field]: value,
        chapter: '',
        topics: [],
      }));
    } else {
      setFieldState((lastState) => ({
        ...lastState,
        [field]: value,
      }));
    }
  };

  useEffect(() => {
    if (!groupId) {
      dispatch({
        type: GET_TEACHER_GROUPS.REQUEST,
      });
    } else {
      getChapters(groupId);
    }

    if (chapter && chapter.state !== 'completed') {
      handleSelectChapter(chapter.chapter_id);
      setFieldState({
        ...fieldsState,
        chapter: {
          id: chapter.chapter_id,
          name: chapter.name,
          type: chapter.type,
        },
        topics:
          topic && topic.state !== 'completed'
            ? [
                {
                  id: topic.topic_id,
                  name: topic.name,
                },
              ]
            : [],
      });
    }
  }, []);

  const mixpanelKafkaEvents = () => {
    const subRoute = location ? location.pathname.split('/')[4] : '';
    const mainRoute = location ? location.pathname.split('/')[2] : '';
    const triggered_from = !subRoute
      ? mainRoute === 'schedule'
        ? 'group_activity_page'
        : 'group_assignment_preview'
      : 'group_content_page';
    const data = {
      title: fieldsState.title,
      due_date: prepareDateForPosting(time, dateRef.current.value),
      id: hw_creation.hw_id,
    };
    const props = returnDuplicatePropsForMixpanelAndKafka(selectedGroup, user, data);
    const properties = {
      triggered_from,
      ...props,
    };
    Mixpanel.track('homework_first_created', properties);
    logger.track('first_created', { homework: properties });
  };

  useEffect(() => {
    if (hw_creation.hw_id) {
      mixpanelKafkaEvents();
      history.push(
        `/${localStorage.updatedLocale}/prepare-assignment/${groupId || fieldsState.group.id}/${hw_creation.hw_id}`,
      );
    }
  }, [hw_creation.hw_id]);

  const saveAssignment = (e) => {
    e.preventDefault();

    if (!dateRef || !dateRef.current || !dateRef.current.value || !time) {
      return false;
    }

    if (title.length < 3) {
      addToast('Title should be 3 symbols and more', TOAST_TYPE.WARNING);
      return false;
    }

    if (prepareDateForPosting(time, dateRef.current.value) < new Date().getTime() + 1000 * 3600) {
      addToast(translationText(noonText, 'createHomework.publishTimeError'), TOAST_TYPE.WARNING);
      return false;
    }

    const gId = groupId || fieldsState.group.id;

    dispatch({
      type: CREATE_HOMEWORK_SPARTA.REQUEST,
      hw_details: {
        title,
        due_date: prepareDateForPosting(time, dateRef.current.value),
        group_id: gId,
      },
    });

    if (hw_creation && hw_creation.loading) {
      return false;
    }
    if (!fieldsState.topics || !title || !fieldsState.chapter || !gId) {
      return false;
    }
    return true;
  };

  return (
    <div className="create-session">
      <form className="session-form assignment">
        <div className="field-outer with_counting">
          <Input
            maxLength="60"
            onChange={(event) => setTitle(event.target.value)}
            onBlur={(event) => handleOnChangeFieldValue('title', event.target.value)}
            type="text"
            label={translationText(noonText, 'label.assignmentTitle')}
            placeholder={translationText(noonText, 'placeholder.assignmentTitle')}
          >
            <span>
              {title.length}
              /60
            </span>
          </Input>
        </div>

        {!groupId && (
          <div className="field-outer">
            <Select
              label={translationText(noonText, 'label.selectGroup')}
              title={translationText(noonText, 'placeholder.selectGroup')}
              selectedItem={fieldsState.group}
              list={mapGroupData(groupList)}
              onSelect={(value) => handleOnChangeFieldValue('group', value)}
            />
          </div>
        )}
        <DatePicker
          onToggle={handleOnChangeTime}
          ms={sessionData ? sessionData.state_time : time}
          dateRef={dateRef}
          onSelect={(event) => handleOnChangeFieldValue('date', event.target.value)}
          placeholder={translationText(noonText, 'placeholder.selectDate')}
          startTime={translationText(noonText, 'createHomework.dueDateLabel')}
        />
        <div className={`btn-outer ${isRtl ? 'text-left' : 'text-right'}`}>
          <Button
            disabled={
              title.length < 3 ||
              !time ||
              !dateRef.current ||
              (hw_creation && hw_creation.loading) ||
              (!groupId && !fieldsState.group)
            }
            loading={hw_creation && hw_creation.loading}
            onClick={saveAssignment}
            type="primary"
            size="lg"
          >
            {translationText(noonText, 'button.saveAssignment')}
            {hw_creation.loading && (
              <IconLoaderDotted
                name="loader-dotted"
                height="50px"
                width="50px"
                fill="#919a9e"
                stroke="#919a9e"
                rotate="20"
              />
            )}
          </Button>
        </div>
      </form>
      {dateRef.current &&
        prepareDateForPosting(time, dateRef.current.value) < new Date().getTime() + 1000 * 3600 * 6 && (
          <TimeAssignmentWarning date={prepareDateForPosting(time, dateRef.current.value)} noonText={noonText} />
        )}
    </div>
  );
}

import React from 'react';
// import { isMobileOnly } from 'react-device-detect';
import { Row } from '@noon/atom';

export default function StatsSection() {
  return (
    <div className="section-outer-container teachers-outer">
      <div className="section landing-teachers">
        <Row className="text landing-teachers__heading">
          <h1 className="title-heading">5,203</h1>
          <p className="title-subheading">Teachers in Noon</p>
        </Row>
        <Row className="landing-teachers__content">
          <div className="image-wrapper">
            <img src="/assets/images/new-landing/teachers-6.png" alt="teacher stats promotion" />
          </div>
        </Row>
      </div>
    </div>
  );
}

import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Badge, Row, Column, Button } from '@noon/atom';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import get from 'lodash-es/get';
import { IconAdd, IconArrowDown, IconBack, IconCorrectCircle, IconLoader } from '@noon/quark';
import ReactTooltip from 'react-tooltip';
import TopicCard from './topicCard';
import { COLORS } from '../../../constants';
import { translationText } from '../../../helpers';

function ChapterCard({
  activeCard,
  chapter,
  handleTeacherGroupTopicOrRevisionMarkComplete,
  topics,
  hasDraft,
  handleGetTeacherGroupChapterTopics,
  handleClickCreateNewActivity,
  isRtl,
  history,
  id,
  handleViewContentCurriculumClick,
  index,
  isArchived = false,
}) {
  const [accordionState, setAccordionState] = useState(false);
  const { noonText } = useSelector((state) => state.toJS().translation);
  const { topicProgress, tag } = useMemo(() => {
    const tag = get(chapter, 'curriculum') || get(chapter, 'section');
    if (!activeCard && chapter.state === 'ongoing' && topics && topics[chapter.chapter_id] && topics[chapter.chapter_id].meta) {
      return {
        topicProgress:
          10
          + Math.floor(
            (100 * topics[chapter.chapter_id].meta.completed_topics) / topics[chapter.chapter_id].meta.total
          ),
        tag,
      };
    }
    return {
      topicProgress: 0,
      tag,
    };
  }, [chapter, topics]);

  useEffect(() => {
    if (handleGetTeacherGroupChapterTopics && chapter.state === 'ongoing') {
      handleGetTeacherGroupChapterTopics(chapter.chapter_id);
    }
  }, []);

  const handleTopicCreateNewActivityClick = (topic) => {
    handleClickCreateNewActivity({ chapter, topic });
  };
  return (
    <React.Fragment>
      {chapter.type === 'chapter' && (
        <Row
          nowrap
          align="start"
          justify="space-between"
          className={`${chapter.type}-card ${chapter.state} ${classNames({ expanded: accordionState })}`}
        >
          <Column align="start" justify="center" flex="1">
            <Row nowrap flex="1" align="center" justify="space-between" gap="sm" className="full-width child">
              <Row nowrap flex="1" align="center" justify="start" gap="sm">
                <span className={`${chapter.type}-name child`}>{chapter.name_header || translationText(noonText, 'tag.chapter')}</span>
                <span className="noon-dot" />
                <span className="total-topics child">
                  {chapter.total_topics || (get(chapter, 'topic_info') || []).length}
                  {' '}
                  {translationText(noonText, 'tag.topics')}
                </span>
              </Row>
              {!activeCard && (
              <Button
                size="sm"
                className="no-padding"
                style={{
                  minWidth: '27px',
                  borderColor: '#DCE3FA',
                }}
                onClick={() => {
                  if (hasDraft || chapter.type !== 'chapter') return false;
                  if (!topics[chapter.chapter_id]) {
                    handleGetTeacherGroupChapterTopics(chapter.chapter_id);
                  }
                  setAccordionState(!accordionState);
                }}
                outlined
            >
                {topics && topics[chapter.chapter_id] && topics[chapter.chapter_id].loading ? (
                  <IconLoader width="1rem" fill="#C7D0EC" stroke="#C7D0EC" />
                ) : (
                  <IconArrowDown width="1rem" fill="#C7D0EC" stroke="#C7D0EC" flipY={accordionState} />
                )}
              </Button>
              )}
            </Row>
            <Column align="start" justify="center" className="child content" style={{ width: '100%' }}>
              <h4 style={{ fontWeight: 'normal' }} className="child">
                {chapter.name}
              </h4>
              {tag && (
                <Badge style={{ background: '#E4ECF8' }} outlined type="default" value={tag.name} size="medium" />
              )}
              {(accordionState || activeCard)
                && !hasDraft
                && chapter.type === 'chapter'
                && topics[chapter.chapter_id]
                && topics[chapter.chapter_id].data
                && topics[chapter.chapter_id].data.map((topic, topicIndex) => (
                  <TopicCard
                    id={id}
                    activeCard={activeCard}
                    handleTeacherGroupTopicMarkComplete={handleTeacherGroupTopicOrRevisionMarkComplete}
                    topic={{ ...topic, state: (index === 0 && topicIndex === 0) && topic.state === 'scheduled' ? 'ongoing' : topic.state }}
                    index={topicIndex}
                    isLastTopic={(!activeCard && topicIndex === topics[chapter.chapter_id].data.length - 1)}
                    handleClickCreateNewActivity={handleTopicCreateNewActivityClick}
                    history={history}
                    handleViewContentCurriculumClick={handleViewContentCurriculumClick}
                    isArchived={isArchived}
                  />
                ))}
              {hasDraft && (
                <Row align="center" nowrap>
                  <label>{translationText(noonText, 'groupCurriculum.estimatedSessions')}</label>
                  <label
                    className="mlr-2"
                    style={{
                      border: '0.5px solid #62687A',
                      borderRadius: '4px',
                      color: '#62687A',
                      width: '27px',
                      height: '20px',
                      textAlign: 'center',
                      verticalAlign: 'middle',
                      lineHeight: '1.4',
                    }}
                  >
                    {chapter.expected_session_count || 0}
                  </label>
                </Row>
              )}
            </Column>
          </Column>
          {!activeCard && (
            <div className="chapter-progress">
              <div className="chapter-progress-bar" style={{ height: `${topicProgress}%` }} />
              {/* <span className="big-dot" /> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                <circle id="Ellipse_23469" data-name="Ellipse 23469" cx="7.5" cy="7.5" r="7.5" fill="#e4ecf8" />
              </svg>
            </div>
          )}
        </Row>
      )}
      {chapter.type === 'revision' && (
        <Row
          style={{ width: '100%' }}
          nowrap
          align="center"
          justify="space-between"
          className={`${chapter.type}-card ${chapter.state}`}
        >
          <ReactTooltip place="bottom" />
          {chapter.state === 'ongoing'
            && (
            <React.Fragment>
              <ReactTooltip place="bottom" />
              <IconCorrectCircle
                data-tip={translationText(noonText, 'groups.markTopicCompleted')}
                onClick={() => {
                  if (!hasDraft && chapter.state === 'ongoing') {
                    handleTeacherGroupTopicOrRevisionMarkComplete({ chapterId: chapter.chapter_id });
                  }
                }}
                width="21"
                height="21"
                fill="#eee"
                stoke="#fff"
              />
            </React.Fragment>
            )}
          <Column align="start" justify="center" flex="1">
            <Button
              type="secondary"
              size="sm"
              rounded
              value={translationText(noonText, 'label.revision')}
              className={`${chapter.type}-name child`}
            />
            <h4 style={{ fontWeight: 'normal' }} className="child">
              {chapter.name}
            </h4>
            {chapter.state === 'completed' ? (
              <span>{translationText(noonText, 'groupCurriculum.revMarkedComplete')}</span>
            ) : (
              <Row align="center" nowrap className="child">
                <label>{translationText(noonText, 'groupCurriculum.upcomingActivities')}</label>
                <label
                  className="mlr-1"
                  style={{
                    border: '0.5px solid #62687A',
                    borderRadius: '4px',
                    color: '#62687A',
                    width: '24px',
                    height: '22px',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    lineHeight: '1.5',
                  }}
                >
                  {chapter.upcoming_activities || 0}
                </label>
                {!isArchived && <IconAdd onClick={() => handleClickCreateNewActivity({ chapter })} name="add" fill={COLORS.brand.base} width="21.5" height="21.5" />}
              </Row>
            )}
          </Column>
          <Button
            type="secondary"
            size="sm"
            className="no-padding"
            style={{
              minWidth: '27px',
              borderColor: '#DCE3FA',
            }}
            outlined
            onClick={() => handleViewContentCurriculumClick(`chapterId=${chapter.chapter_id}`)}
          >
            <IconBack height="12px" width="12px" fill="#64D8AE" stroke="#64D8AE" flipX={!isRtl} />
          </Button>
          {!activeCard && (
            <div className="chapter-progress">
              <div style={{ width: '100%', height: `${topicProgress}%`, background: '#64D8AE' }} />
            </div>
          )}
        </Row>
      )}
    </React.Fragment>
  );
}

ChapterCard.propTypes = {
  id: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
  activeCard: PropTypes.bool,
  chapter: PropTypes.shape({}).isRequired,
  topics: PropTypes.shape({}),
  hasDraft: PropTypes.bool.isRequired,
  handleTeacherGroupTopicOrRevisionMarkComplete: PropTypes.func.isRequired,
  handleGetTeacherGroupChapterTopics: PropTypes.func.isRequired,
  handleClickCreateNewActivity: PropTypes.func.isRequired,
  handleViewContentCurriculumClick: PropTypes.func.isRequired,
  isRtl: PropTypes.bool.isRequired,
  index: PropTypes.number,
};
ChapterCard.defaultProps = {
  activeCard: false,
  topics: {},
  index: 0,
};

export default ChapterCard;

import React, { useMemo } from 'react';
import { IconEdit } from '@noon/quark';
import { Card, Button, Row, Column, NoonDate } from '@noon/atom';
import { head, isEmpty } from 'lodash-es';
import { DateTime } from 'luxon';
import { GROUP_DURATION } from '../../../../constants';
import { translationText } from '../../../../helpers';
import NotAllowedOverlay from '../../NotAllowedOverlay';

function GroupSchedule({ schedules, isArchived, noonText, handleOnClickCreateSchedule }) {
  const firstSession = head(schedules) || {};
  const expectedDuration = useMemo(() => {
    if (isEmpty(firstSession)) return '';

    const validTill = DateTime.fromISO(new Date(firstSession ? firstSession.valid_till : Date.now()).toISOString());
    const validFrom = DateTime.fromISO(new Date(firstSession ? firstSession.valid_from : Date.now()).toISOString());
    const dateDiff = validTill.diff(validFrom, ['weeks']).toObject();
    return GROUP_DURATION.find((duration) => duration.value.weeks === dateDiff.weeks) || GROUP_DURATION[0];

  }, [firstSession]);
  const firstSessionTime = new Date(firstSession.valid_from).getTime();
  return (
    <Card className="schedule-widget">
      <Row align="center" nowrap className="card-header">
        <h4 className="card-header__title">{translationText(noonText, 'tab.table')}</h4>
        {isArchived && <NotAllowedOverlay />}
        <IconEdit onClick={() => handleOnClickCreateSchedule(true)} />
      </Row>
      {!isEmpty(schedules)
        ? (
          <Column className="card-body" gap="sm">
            <Row nowrap gap>
              <Column flex="1" className="schedule-widget__details">
                <span className="schedule-widget__details-title">{translationText(noonText, 'tutoring.expectedDuration')}</span>
                <p className="schedule-widget__details-input">
                  {expectedDuration.display[0]}
                  {' '}
                  {translationText(noonText, `label.${expectedDuration.display[1]}`)}
                </p>
              </Column>
              <Column flex="1" className="schedule-widget__details">
                <span className="schedule-widget__details-title">{translationText(noonText, 'label.firstSession')}</span>
                <NoonDate className="schedule-widget__details-input" format="MMMM, dd" humanize value={firstSessionTime} />
              </Column>
            </Row>
            <Column className="schedule-widget__details">
              <span className="schedule-widget__details-title">{translationText(noonText, 'tutoring.sessionAppointments')}</span>
              <ul className="schedule-widget__details-input">
                {schedules.map(({ day, start_time }, index) => {
                  const [hour, minute] = start_time.split(':');
                  return (
                    <li className="text-capitalize" key={index}>
                      {day}
                      {' '}
                      -
                      {' '}
                      <span className="schedule-widget__details-input-light">
                        {DateTime.local().set({ hour, minute }).toFormat('hh:mm a')}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </Column>
          </Column>
        )
        : (
          <Column gap className="card-body">
            {isArchived && <NotAllowedOverlay />}
            <p className="child">
              {' '}
              {translationText(noonText, 'help.setScheduleMessage')}
            </p>
            <Button type="secondary" size="xl" className="mt-2" onClick={() => handleOnClickCreateSchedule(true)}>
              {translationText(noonText, 'button.setSchedule')}
            </Button>

          </Column>
        )}
    </Card>
  );
}

export default GroupSchedule;

import './create-question-modal.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import { Column, Row, Button, Modal, Hr } from '@noon/atom';
import classNames from 'classnames';
import isEmpty from 'lodash-es/isEmpty';
import NoonQuestion from '../NoonQuestion/NoonQuestion';
import QuestionChoice from '../NoonQuestion/QuestionChoice';
import { ChapterTopicMultiSelect } from '../Curriculum';
import t from '../../helpers/translate';

function QuestionPreviewModal({ 
  type=null,
  questionId,
  questionData, 
  onClose, 
  onCloneQuestion = null, 
  isLoading, 
  saveQuestion, 
  selectedCurriculum, 
  handleSelectedTopic = null, 
  groupId 
}) {
  const { topicId, chapterId } = useSelector((state) => state.toJS().content);

  return (
    <Modal onClose={onClose} className="create-question-modal">
      <Row className="modal-header full-width" align="center" justify="space-between">
        <h4 className="title">Preview</h4>
      </Row>
      <Column nowrap align="start" justify="start" className="modal-body scroll-bar">
        {handleSelectedTopic && (
        <Row justify="start" align="center" gap className="create-question__select_chapter">
          <ChapterTopicMultiSelect
            groupId={groupId}
            onTopicSelect={handleSelectedTopic}
            preChapterId={chapterId || selectedCurriculum.chapterId}
            preTopicId={topicId || selectedCurriculum.topicId}
            className="flex-1"
            showNote
            />
        </Row>
        )}
        <div className='create-question__content'>
          {Boolean(questionData.question_json && questionData.question_json.length) && (
            <NoonQuestion content={questionData.question_json} />
          )}
          <h4 className="input__title flex-1">{t('library', 'choicesTitle')}</h4>
          <Row className="question-preview__choices" justify="space-between" align="center">
            {!isEmpty(questionData.choices) && questionData.choices.map((choice, index) => (
              <React.Fragment key={index}>
                {Boolean(choice.answer_json && choice.answer_json.length) && (
                  <QuestionChoice
                    className={classNames('animated faster fadeIn width-50')}
                    question={questionData.question}
                    choice={choice}
                    correct={choice.is_correct}
                  />
                )}
              </React.Fragment>
            ))}
          </Row>
        <Row nowrap align="center" justify="end" gap="sm" className="mt-4" style={{ width: '100%' }}>
          <Button
            type="blue"
            size="xl"
            outlined
            className='action-btn'
            onClick={onClose}>
            <svg className="icon icon-plus" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M19 3.17163C18.7599 3.17163 18.5222 3.21892 18.3003 3.31081C18.0785 3.4027 17.8769 3.53738 17.7071 3.70716L4.39491 17.0194L3.42524 20.5748L6.9807 19.6052L20.2929 6.29295C20.4627 6.12317 20.5974 5.9216 20.6893 5.69977C20.7812 5.47793 20.8284 5.24017 20.8284 5.00006C20.8284 4.75994 20.7812 4.52218 20.6893 4.30035C20.5974 4.07852 20.4627 3.87695 20.2929 3.70716C20.1231 3.53738 19.9216 3.4027 19.6997 3.31081C19.4779 3.21892 19.2401 3.17163 19 3.17163ZM17.5349 1.46305C17.9994 1.27066 18.4973 1.17163 19 1.17163C19.5028 1.17163 20.0006 1.27066 20.4651 1.46305C20.9296 1.65545 21.3516 1.93745 21.7071 2.29295C22.0626 2.64845 22.3446 3.07049 22.537 3.53498C22.7294 3.99947 22.8284 4.4973 22.8284 5.00006C22.8284 5.50281 22.7294 6.00064 22.537 6.46513C22.3446 6.92962 22.0626 7.35166 21.7071 7.70716L8.20713 21.2072C8.08407 21.3302 7.93104 21.419 7.76314 21.4648L2.26314 22.9648C1.91693 23.0592 1.54667 22.9609 1.29292 22.7072C1.03917 22.4534 0.940838 22.0832 1.03526 21.7369L2.53526 16.2369C2.58105 16.069 2.66986 15.916 2.79292 15.793L16.2929 2.29295C16.6484 1.93745 17.0705 1.65545 17.5349 1.46305Z" fill="#469979" />
            </svg>

            {onCloneQuestion ? t('image2question', 'OnboardingClose') : t('library', 'createQuestionBackCta')}
          </Button>
          <Button
            type="blue"
            size="xl"
            className="action-btn"
            loading={isLoading}
            onClick={onCloneQuestion || saveQuestion}>
            <svg className="icon icon-plus" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M12 4C12.5523 4 13 4.44772 13 5L13 19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19L11 5C11 4.44772 11.4477 4 12 4Z" fill="#141414" />
              <path fillRule="evenodd" clipRule="evenodd" d="M4 12C4 11.4477 4.44772 11 5 11L19 11C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13L5 13C4.44772 13 4 12.5523 4 12Z" fill="#141414" />
            </svg>
            {(questionId && type !== 'clone') ? t('createHomework', 'updateQuestions') : t('image2question', 'DialogSave')}
          </Button>
        </Row>
        </div>
      </Column>
    </Modal>
  );
}

export default QuestionPreviewModal;

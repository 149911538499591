import './create-question-modal.scss';

import React, { useEffect, useMemo, useState } from 'react';
import {  IconClose } from '@noon/quark';
import { Column, Row, Modal, Hr } from '@noon/atom';
import { useDispatch, useSelector } from 'react-redux';

import { QuestionContent } from './question-content';
import { defaultChoices, defaultQuestion, difficultyLevels } from '../../constants/question-defaults';
import { ChapterTopicMultiSelect } from '../Curriculum';
import { ITopicItem } from '../Classroom/Content/contentContainer';
import { getQuestionContent, setChapterTopicId } from '../../redux/actions/classroom';
import { toggleCreateQuestionModal } from '../../redux/actions/content';
import { isLatex } from '../../helpers/is-latex';
import { isImage } from '../../helpers/is-image';
import t from '../../helpers/translate';

export const CreateQuestionModal = ({ groupId, selectedCurriculum }): JSX.Element => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [choices, setChoices] = useState(defaultChoices);
  const [quesiton, setQuestion] = useState(defaultQuestion);
  const { topicId, chapterId } = useSelector((state) => state.toJS().content);
  const { createQuestionData } = useSelector((state) => state.toJS().question);

  const logger = useMemo(() => LoggingManager.mount({ moduleName: 'classroom' }), [])
  const startTime = useMemo(() => Date.now(), []);

  const [validator, setValidator] = useState({
    isCorrect: false,
    choice: 0,
    question: false,
    curriculum: false,
    questionUpdated: false,
    duplicateChoice: false,
  });


  const handleSelectedTopic = (topic: ITopicItem) => {
    const { chapter_id, topic_id } = topic;
    dispatch(setChapterTopicId({ chapterId: chapter_id, topicId: topic_id }));
  };

  const handleOnClose = () => {
    dispatch(toggleCreateQuestionModal(false))
  }

  useEffect(() => {
    if (isLoading && createQuestionData.question_id) {
      try {
          let is_latex = isLatex(createQuestionData.questionData?.quesiton?.quesiton);
          let is_image = isImage(createQuestionData.questionData?.quesiton?.quesiton);
          const choices = createQuestionData.questionData?.choices;

          for (const i in choices) {
              if (choices[i].answer) {
                  is_latex = isLatex(choices);
                  is_image = isImage(choices);
              }
              if (is_latex && is_image) break;
          }

          logger.track('teacher_click_save_question', {
            classroom: {
              time_spent: Math.floor((Date.now() - startTime) / 1000),
              country: selectedCurriculum.country?.name,
              is_image,
              is_latex,
              chapter: chapterId,
              topic: topicId,
              is_clone: false,
              question_id: createQuestionData?.question_id[0],
              channel: 'direct text', // ‘library’; ‘direct image’; ‘direct text’ 
            }
          });
      } catch (e) {
          console.log(e);
      }
      // call updated question list
      setIsLoading(false);
      handleOnClose();
      dispatch(getQuestionContent({ topic_id: topicId, page: 1 }));
    }
  }, [createQuestionData, chapterId, topicId]);

  return (
      <Modal className="create-question-modal">
        <Row className="modal-header full-width" align="center" justify="space-between">
          <h4 className="title">{t('library', 'addQuestionTitle')}</h4>
          <IconClose className="cursor-pointer" width="24px" height="24px" fill="#141414" onClick={handleOnClose} />
        </Row>
        <Column nowrap align="start" justify="start" className="modal-body scroll-bar">
          <Row justify="start" align="center" gap className="create-question__select_chapter">
            <ChapterTopicMultiSelect
              groupId={groupId}
              onTopicSelect={handleSelectedTopic}
              preChapterId={chapterId || selectedCurriculum?.chapter?.chapter_id}
              preTopicId={topicId || selectedCurriculum?.topic?.topic_id}
              className="flex-1"
              showNote
            />
          </Row>
          <QuestionContent
            isSubmitted={isSubmitted}
            question={quesiton}
            validator={validator}
            choices={choices}
            isLoading={isLoading}
            selectedCurriculum={{ 
              ...selectedCurriculum, 
              chapter: { 
                ...selectedCurriculum.chapter,
                id: chapterId
              },
              topic: { 
                ...selectedCurriculum.topic,
                id: topicId 
              }
            }}
            difficultyLevel={difficultyLevels[0]}
            setChoices={setChoices}
            setQuestion={setQuestion}
            setValidator={setValidator}
            setIsLoading={setIsLoading}
            setIsSubmitted={setIsSubmitted}
            hideAnotherQuestionCheckbox={true}
            handleSelectedTopic={handleSelectedTopic}
            groupId={groupId}
          />
        </Column>
    </Modal>
  );
};

import React from 'react';

export default function NotFound() {

  return (
    <div className="container">
      <div className="image-wrapper" style={{ width: '100%', maxWidth: '700px', margin: 'auto' }}>
        <img style={{ width: '100%' }} src="/assets/images/2020/404.svg" alt="Not found" />
      </div>
    </div>
  );
}

import React, { Component } from 'react';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import uniqueId from 'lodash-es/uniqueId';
import { isMobileOnly, isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { IconPicture, IconPlus, IconPoll, IconPost, IconQuestionFeed, IconUploadFile, IconWrong } from '@noon/quark';
import { Row, Button, ProgressSpinner, Checkbox } from '@noon/atom';
import FileItem from './files/fileItem';
import { HtmlInput, CreatePoll } from './index';
import { translationText } from '../../helpers';
import { COLORS } from '../../constants';
import { addToast, TOAST_TYPE } from '../Toast';
import { CreateQuestion } from './questionAndAnswer';

const mobileImgWidth = '75px';
const normalImgWidth = '100px';
export default class FileAttachment extends Component {
  static propTypes = {
    attachmentType: PropTypes.string,
    postInput: PropTypes.shape().isRequired,
    noonText: PropTypes.shape().isRequired,
    featureFlags: PropTypes.shape().isRequired,
    uploadImageProgress: PropTypes.func.isRequired,
    bulkFileUpload: PropTypes.func.isRequired,
    imageUploadProgressStatus: PropTypes.arrayOf(PropTypes.object),
    isBulkFileUploading: PropTypes.bool,
    emojis: PropTypes.shape().isRequired,
    user: PropTypes.shape().isRequired,
    isRtl: PropTypes.bool,
    chapterList: PropTypes.arrayOf(PropTypes.object),
    lessons: PropTypes.arrayOf(PropTypes.object),
    handlePost: PropTypes.func.isRequired,
    hideFocus: PropTypes.func.isRequired,
    postType: PropTypes.string.isRequired,
  };

  static defaultProps = {
    attachmentType: '',
    imageUploadProgressStatus: {},
    isBulkFileUploading: false,
    isRtl: true,
    chapterList: [],
    lessons: [],
  };

  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.fileRef = React.createRef();
    this.componentRef = React.createRef();
    this.state = {
      imagePreviewUrls: [],
      fileType: '',
      isHovering: 0,
      textData: '',
      isFree: true,
      postType: props.postType || 'question',
      selectedChapter: null,
      showQuestionType: false,
      selectedLesson: null,
      youtubeVideoIds: [],
    };

    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.handleMouseHover = this.handleMouseHover.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleSent = this.handleSent.bind(this);
    this.changePostType = this.changePostType.bind(this);
  }

  componentDidMount() {
    if (!isEmpty(this.props.pollExpirePost)) {
      this.componentRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !!this.state.imagePreviewUrls.length
      && prevState.imagePreviewUrls.length !== this.state.imagePreviewUrls.length
    ) {
      if (this.state.fileType === 'image') {
        this.scrollLeft();
      } else {
        this.scrollBottom();
      }
    }
    // if (announcementAndQuestion.includes(this.state.postType) && prevState.postType !== this.state.postType) {
    //   this.postInput.current.focus();
    // }
  }

  filterCondition = (file, type) => {
    if (type === 'image' && file.file.size / (1024 * 1024) >= 6) {
      addToast(translationText(this.props.noonText, 'warning.image_size_warning'), TOAST_TYPE.ERROR);
      return false;
    } if (type === 'file' && file.file.size / (1024 * 1024) >= 24) {
      addToast(translationText(this.props.noonText, 'warning.image_size_warning'), TOAST_TYPE.ERROR);
      return false;
    } if (!this.getFileType(file.file.name)) {
      addToast(
        `${translationText(this.props.noonText, 'post.warning_unsupported_file')} file name:${file.file.name}`,
        TOAST_TYPE.ERROR,
      );
      return false;
    }
    return true;
  };

  // eslint-disable-next-line react/sort-comp
  handleSent() {
    const { textData, imagePreviewUrls, selectedLesson, postType, youtubeVideoIds } = this.state;
    if (postType === 'question' && isEmpty(selectedLesson)) {
      addToast(translationText(this.props.noonText, 'post.no_chapter_selected'), TOAST_TYPE.ERROR);
    } else if (textData || !isEmpty(imagePreviewUrls)) {
      this.props.handlePost({
        imagePreviewUrls: this.state.imagePreviewUrls,
        textData: this.state.textData,
        isFree: this.state.isFree,
        youtubeVideoIds,
        fileType: this.state.fileType,
        postType: this.state.postType,
        ...(postType === 'question' && { chapterId: this.state.selectedChapter.id }),
        ...(postType === 'question' && { lessonId: this.state.selectedLesson.id }),
      });
      this.props.hideFocus();
      this.setState({ imagePreviewUrls: [] });
    }
  }

  handleMouseHover(id) {
    this.setState({ isHovering: id });
  }

  changePostType(e) {
    if (isEmpty(this.props.pollExpirePost)) {
      let type;
      let targetElement = e.target;
      do {
        if (targetElement.getAttribute('data-postName')) {
          type = targetElement.getAttribute('data-postName');
          break;
        }
        targetElement = targetElement.parentElement;
      } while (targetElement);
      this.setState({ postType: type });
      if (type === 'question') {
        // this.props.fetchChaptersAndLessons();
      }
      e.stopPropagation();
    }
  }

  handleMouseOut(e) {
    if (Array.from(e.relatedTarget.classList).some((item) => ['individual-image--cross', 'icon-plus'].includes(item))) {
      return;
    }
    this.setState({ isHovering: 0 });
  }

  toggleHoverState(state) {
    return {
      isHovering: !state.isHovering,
    };
  }

  getFileType = (fileName) => {
    const fileLen = fileName.split('.');
    const fileExt = fileLen[fileLen.length - 1].toLowerCase();
    const acceptedExts = ['doc', 'docx', 'ppt', 'pptx', 'txt', 'pdf', 'jpg', 'png', 'jpeg'];
    if (acceptedExts.includes(fileExt)) {
      return true;
    }
    return false;
  };

  handleFileUpload(e, type) {
    const {
      target: { files },
    } = e;
    const fileList = Array.from(files);
    const uploaders = fileList.map((file) => ({ file, id: uniqueId() }));
    const uploadersCopy = uploaders.filter((file) => this.filterCondition(file, type));
    this.setState((prevState) => {
      const newImagePreviewUrl = [...prevState.imagePreviewUrls, ...uploadersCopy];
      return {
        imagePreviewUrls: newImagePreviewUrl,
        isFree: !newImagePreviewUrl.length ? true : typeof this.props.groupDetails.free_percent === 'undefined' ? !this.props.groupDetails.group_info.is_premium : false,
        ...(newImagePreviewUrl.length && { fileType: type }),
      };
    });
    if (uploadersCopy.length) {
      this.uploadFiles(uploadersCopy);
    }
  }

  progressEventHandler = (id) => (progressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    this.props.uploadImageProgress(id, percentCompleted);
  };

  uploadFiles = (fileList) => {
    const attachmentList = fileList;
    const filesBatch = attachmentList.map((fileObj) => {
      const { file, id } = fileObj;
      const fd = new FormData();
      fd.append('destination', 'posts');
      const nameSplit = file.name.split('.');
      const ext = nameSplit[nameSplit.length - 1];
      nameSplit.splice(nameSplit.length - 1, 1);
      const originalName = nameSplit.join('.');
      fd.append('fileUpl', file, file.name);
      fd.append('file_name', `${originalName}##${id}.${ext}`);
      fd.append('id', id);
      return { fd, progressEvent: this.progressEventHandler(id) };
    });
    this.props.bulkFileUpload(filesBatch);
  };

  removeImage(id) {
    const removedIndex = this.state.imagePreviewUrls.findIndex((item) => item.id === id);
    const updatedBulkFileList = this.props.bulkFilesUploaded.filter(
      (fileData) => fileData.name.split('##')[1].split('.')[0] !== id,
    );
    this.props.bulkFileUploadSuccess(updatedBulkFileList);
    this.setState((prevState) => {
      const newImagePreviewUrl = prevState.imagePreviewUrls;
      newImagePreviewUrl.splice(removedIndex, 1);
      return {
        isFree: !newImagePreviewUrl.length ? true : typeof this.props.groupDetails.free_percent === 'undefined' ? !this.props.groupDetails.group_info.is_premium : false,
        imagePreviewUrls: newImagePreviewUrl,
        ...(isEmpty(newImagePreviewUrl) && { fileType: '' }),
      };
    });
  }

  validateYouTubeUrl(text) {
    const videoIds = [];
    if (text !== undefined || text !== '') {
      const urlsMatchRegEx = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
      const urls = text.match(urlsMatchRegEx);
      if (urls && urls.length) {
        urls.map((url) => {
          const youtubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
          const youtubeUrl = url.match(youtubeRegExp);
          if (youtubeUrl && youtubeUrl[2].length === 11) {
            videoIds.push(youtubeUrl[2]);
          }
        });
      }
    }
    return videoIds;
  }

  setTextDataInState = (data) => {
    this.setState({ textData: data, youtubeVideoIds: this.validateYouTubeUrl(data) });
  };

  scrollLeft() {
    console.log('componentref', this.imageRef.current);

    this.imageRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'end',
    });
  }

  scrollBottom() {
    this.fileRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }

  hideFocusOperation = () => {
    this.setState({ imagePreviewUrls: [] });
    this.props.hideFocus();
  };

  render() {
    const {
      noonText,
      imageUploadProgressStatus,
      isBulkFileUploading,
      emojis,
      user,
      postInput,
      isRtl,
      pollExpirePost,
      submitPoll,
      updatePollTime,
      featureFlags,
    } = this.props;

    const { imagePreviewUrls, fileType, textData, postType, youtubeVideoIds, isFree } = this.state;

    const btnListType = [
      {
        icon: IconPicture,
        text: translationText(noonText, 'post.picture'),
        func: 'handleImageUpload',
        accept: '.jpg, .png',
        type: 'image',
      },
      {
        icon: IconUploadFile,
        text: translationText(noonText, 'post.file'),
        func: 'handleFileUpload',
        accept: '.doc, .docx,.ppt, .pptx,.txt,.pdf',
        type: 'file',
      },
    ];
    let postDetailTypes = [
      {
        icon: IconPost,
        text: translationText(noonText, 'post.post'),
        name: 'announcement',
        width: '20px',
        fill: '#FF6F83',
      },
    ];
    if (!this.props.isArchived) {
      postDetailTypes = postDetailTypes.concat([{
        icon: IconQuestionFeed,
        text: translationText(noonText, 'post.question'),
        name: 'question',
        width: '20px',
        fill: '#70B02A',
      },
      { icon: IconPoll, text: translationText(noonText, 'post.poll'), name: 'poll', width: '20px', fill: '#1CAEEF' }]);
    }

    const fileActions = fileType ? btnListType.filter((btn) => btn.type === fileType) : btnListType;

    return (
      <div className={classNames('file-attachment', { withFiles: !isEmpty(imagePreviewUrls) })} ref={this.componentRef}>
        <Row className="file-attachment__header">
          <Row nowrap className="plr-2 file-attachment__header__types" onClick={this.changePostType}>
            {postDetailTypes.map((post) => (
              <div
                className={classNames('file-attachment__header__types--post', {
                  'selected-type': postType === post.name,
                })}
                data-postName={post.name}
              >
                <post.icon
                  fill={postType === post.name ? post.fill : '#62687A'}
                  stroke="#fff"
                  width={post.width}
                />
                <span className="plr-05">{post.text}</span>
              </div>
            ))}
          </Row>
          <IconPlus
            onClick={this.hideFocusOperation}
            width="20px"
            height="20px"
            rotate="45"
            className="mlr-2 mtb-1"
            fill="#62687A"
          />
        </Row>
        {postType === 'poll' && (
          <CreatePoll
            noonText={noonText}
            user={user}
            isRtl={isRtl}
            emojis={emojis}
            submitPoll={submitPoll}
            postInput={postInput}
            pollExpirePost={pollExpirePost}
            updatePollTime={updatePollTime}
            showPlusCheckbox={featureFlags.is_unified_group_teacher && featureFlags.is_unified_group_teacher.isEnabled && typeof this.props.groupDetails.free_percent !== 'undefined' }
            handleMixpanelKafkaFeeds={this.props.handleMixpanelKafkaFeeds}
          />
        )}
        {postType === 'question' && (
          <CreateQuestion
            noonText={noonText}
            user={user}
            emojis={emojis}
            uploadImageProgress={this.props.uploadImageProgress}
            bulkFileUpload={this.props.bulkFileUpload}
            bulkFilesUploaded={this.props.bulkFilesUploaded}
            resetUploadImageProgress={this.props.resetUploadImageProgress}
            imageUploadProgressStatus={this.props.imageUploadProgressStatus}
            isBulkFileUploading={this.props.isBulkFileUploading}
            groupDetails={this.props.groupDetails}
            groupId={this.props.groupId}
            hideFocusOperation={() => this.hideFocusOperation()}
            hideAdditionalHeading
            handleMixpanelKafkaFeeds={this.props.handleMixpanelKafkaFeeds}
            bulkFileUploadSuccess={this.props.bulkFileUploadSuccess}
          />
        )}
        {postType === 'announcement' && (
          <React.Fragment>
            <HtmlInput
              noonText={noonText}
              isRtl={isRtl}
              emojis={emojis}
              user={user}
              placeholder="post.search_post"
              type="post"
              textData={this.setTextDataInState}
              className="ptb-1 plr-1 create-post-html"
              maxChar={!isEmpty(imagePreviewUrls) ? 140 : 400}
            />
            {!isEmpty(imagePreviewUrls) && (
              <Row className="file-attachment__image-preview-wrapper">
                {fileType === 'image' ? (
                  <Row className="file-attachment__image-preview">
                    {imagePreviewUrls.map((item) => {
                      const { file, id } = item;
                      const imageFound = imageUploadProgressStatus && imageUploadProgressStatus.find((x) => x.id === id);
                      return (
                        <Row
                          align="center"
                          justify="center"
                          className={classNames('individual-image mlr-2', {})}
                          key={id}
                        >
                          <img
                            src={URL.createObjectURL(file)}
                            className={classNames('', {
                              'individual-image--opaque-more':
                                imageFound && imageFound.percentCompleted < 100 && isBulkFileUploading,
                              'individual-image--opaque-less': this.state.isHovering === id,
                            })}
                            alt="comment"
                            width={isMobileOnly ? mobileImgWidth : normalImgWidth}
                            height={isMobileOnly ? mobileImgWidth : normalImgWidth}
                            onMouseEnter={() => this.handleMouseHover(id)}
                            onMouseLeave={(e) => this.handleMouseOut(e, 0)}
                          />
                          {imageFound && imageFound.percentCompleted < 100 && isBulkFileUploading && (
                            <ProgressSpinner
                              progress={
                                imageFound && imageFound.percentCompleted ? imageFound.percentCompleted - 0.01 : 0
                              }
                              text={`${imageFound && imageFound.percentCompleted ? imageFound.percentCompleted : 0}%`}
                              radius={isMobile ? 25 : 35}
                              thickness={7}
                              noPointer
                              noShadow
                              activeColor="#b6dd8a"
                              color="#b6dd8a"
                              baseColor="white"
                              fill="none"
                            />
                          )}
                          <IconWrong
                            className="individual-image--cross"
                            width="18px"
                            height="18px"
                            onClick={() => this.removeImage(id)}
                            fill={COLORS.coolGrey[5]}
                            stroke={COLORS.brand.base}
                          />
                        </Row>
                      );
                    })}

                    <label htmlFor="addFile" className="file-attachment__image-preview--add" ref={this.imageRef}>
                      <IconPlus rotate="90" />
                      <input
                        id="addFile"
                        className="btn-upload--attach"
                        type="file"
                        multiple="true"
                        onChange={(e) => this.handleFileUpload(e, 'image')}
                        accept=".jpg, .png"
                      />
                    </label>
                  </Row>
                ) : (
                  <Row className="file-attachment__header__files">
                    {imagePreviewUrls.map((item) => (
                      <FileItem
                        item={item}
                        key={item.id}
                        removeImage={this.removeImage}
                        imageUploadProgressStatus={imageUploadProgressStatus}
                        submitted={!isBulkFileUploading}
                      />
                    ))}
                    <div ref={this.fileRef} />
                  </Row>
                )}
              </Row>
            )}
            {!!youtubeVideoIds && !!youtubeVideoIds.length && (
              <Row className="youtube-videos-frames">
                <Row nowrap gap="sm" align="center" className="youtube-videos-frames-scroll">
                  {youtubeVideoIds.map((videoId) => (
                    <iframe
                      className="mt-1 mr-1"
                      title="Youtube videos"
                      src={`https://youtube.com/embed/${videoId}?rel=0`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                  />
                  ))}
                </Row>
              </Row>
            )}
            <Row className="file-attachment__button-attachment">
              <Button
                loading={!!isBulkFileUploading}
                className={`text-capitalize ${!textData && isEmpty(imagePreviewUrls) ? 'mlr-2' : 'mlr-2'}`}
                type="primary"
                depth="2"
                onClick={this.handleSent}
                disabled={isEmpty(this.props.bulkFilesUploaded) && isEmpty(this.state.textData)}
              >
                {translationText(noonText, 'poll.publish')}
              </Button>
              <Row className="create-question-options--select" gap="sm">
                {fileActions.map((btn, idx) => (
                  <div className="btn-upload">
                    <label
                      htmlFor={`uploadPic-${idx}`}
                      role="button"
                      className="noon-btn-fab-lg noon-btn-fab noon-btn noon-btn-secondary no-padding child"
                      style={{ minWidth: 'auto' }}
                    >
                      <input
                        id={`uploadPic-${idx}`}
                        className="btn-upload--attach"
                        type="file"
                        multiple="true"
                        onChange={(e) => this.handleFileUpload(e, btn.type)}
                        accept={btn.accept}
                        style={{
                          opacity: '0',
                          position: 'absolute',
                          pointerEvents: 'none',
                          width: '1px',
                          height: '1px',
                        }}
                      />
                      <btn.icon fill={COLORS.brand.base} />
                    </label>
                  </div>
                ))}
              </Row>
              {featureFlags.is_unified_group_teacher && featureFlags.is_unified_group_teacher.isEnabled && typeof this.props.groupDetails.free_percent !== 'undefined' && (
                <Row className="mlr-1" align="center" justify="end">
                  <Checkbox
                    value={isFree ? 'off' : 'on'}
                    bit
                    checked={!isFree}
                    label={translationText(noonText, 'post.showOnlyToPlus')}
                    onChange={() =>  this.setState({ isFree: !isFree })}
                    className={classNames('chat-status', isFree ? 'off' : 'on')}
                  />
                </Row>
              )}
            </Row>
          </React.Fragment>
        )}
      </div>
    );
  }
}

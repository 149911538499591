import axios from 'axios';
import store from '../store';
import { getCountryId } from '../constants';
import { TEMPORARY_TOKEN_PAYLOAD } from '../redux/constants';

const uuidv4 = require('uuid/v4');

const generateTokenPayload = () => {
  const tokenPayload = localStorage.temporaryTokenPayload ? JSON.parse(localStorage.temporaryTokenPayload) : {};
  const temporaryTokenPayload = {
    tenant: 'teacher',
    country: getCountryId(),
    os: 'web',
    device_id: tokenPayload.device_id,
  };
  if (!tokenPayload.device_id) {
    temporaryTokenPayload.device_id = uuidv4();
  }
  localStorage.setItem('temporaryTokenPayload', JSON.stringify(temporaryTokenPayload));
  store.dispatch({ type: TEMPORARY_TOKEN_PAYLOAD, payload: temporaryTokenPayload });
  axios.defaults.headers.common['x-device-id'] = temporaryTokenPayload.device_id.toString();
};

export default generateTokenPayload;

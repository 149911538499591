import { useContext } from 'react';
import Context from './context';

function useAuth() {
  const context = useContext(Context);
  if (!context) {
    console.log('Please wrap your component inside AuthProvider');
  }
  return context;
}

export default useAuth;

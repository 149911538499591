import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card, Row, Column, Button } from '@noon/atom';
import { head } from 'lodash-es';
import { IconAdd, IconArrowDown, IconBack, IconEdit } from '@noon/quark';
import ChapterCard from './chapterCard';
import { COLORS } from '../../../constants';
import { translationText } from '../../../helpers';
import NotAllowedOverlay from '../NotAllowedOverlay';

function CurriculumWidgetPublished({
  percentCompleted,
  id,
  hasDraft,
  preview,
  topics,
  miscChapter,
  handleTeacherGroupTopicOrRevisionMarkComplete,
  handleGetTeacherGroupChapterTopics,
  chapters,
  history,
  handleMixpanelCurriculumEvents,
  handleClickCreateNewActivity,
  isRtl,
  handleViewContentCurriculumClick,
  isArchived,
}) {
  const [showChapters, setShowChapter] = useState(false);
  const { noonText } = useSelector((state) => state.toJS().translation);

  const handleShowChapter = () => {
    if (!showChapters) {
      handleMixpanelCurriculumEvents({
        type: 'curriculum_view_all',
        data: {
          curriculum_state: 'published',
          no_of_chapters: chapters.length,
        },
        mixPanel: false,
      });
    }
    setShowChapter(!showChapters);
  };

  const handleEditCurriculumClick = () => {
    handleMixpanelCurriculumEvents({
      type: 'edit_curriculum_clicked',
      data: {
        curriculum_state: 'published',
        no_of_chapters: chapters.length,
        curriculum_completion: percentCompleted,
      },
    });
    history.push(`/${localStorage.updatedLocale}/curriculum/${id}`);
  };

  return (
    <Card>
      <Row align="center" justify="space-between" flex="1" className="card-header" gap="sm">
        <Row align="center" justify="start" flex="1">
          <h3 className="card-header__title child">
            {translationText(noonText, 'tab.curriculum')}
          </h3>
          <span className="noon-dot" />
          <h4 className="card-header__title-state child ">
            {percentCompleted || 0}
            %
            {' '}
            <span>{translationText(noonText, 'groups.completedChapter')}</span>
          </h4>
        </Row>
        <div className="show-more-button text-center">
          <Button
            size="sm"
            className="no-padding"
            style={{ minWidth: '27px', border: '0', textTransform: 'capitalize' }}
            outlined
            onClick={handleShowChapter}
            >
            {translationText(noonText, showChapters ? 'groupCurriculum.hideAllChapters' : 'groupCurriculum.showAllChapters')}
            {' '}
            {chapters.length}
            <IconArrowDown flipY={showChapters} fill={COLORS.coolGrey[1]} stroke={COLORS.coolGrey[1]} height="15px" width="15px" className="mlr-1" />
          </Button>
        </div>
      </Row>
      <Column align="start" justify="center" className="card-body curriculum-widget">
        <Column align="center" justify="center" className={classNames('curriculum-widget__published-container', { show: showChapters })}>
          <Column style={{ margin: 0, width: '100%' }} align="center" justify="center">
            {preview && (
              <Row style={{ width: '100%' }} className="plr-2" align="center" justify="start">
                <ChapterCard
                  id={id}
                  activeCard
                  chapter={{ ...preview, state: 'ongoing' }}
                  topics={{
                    [preview.chapter_id]: { data: [{ ...(head(preview.topic_info) || {}), state: 'ongoing' }] },
                  }}
                  history={history}
                  handleClickCreateNewActivity={handleClickCreateNewActivity}
                  handleTeacherGroupTopicOrRevisionMarkComplete={handleTeacherGroupTopicOrRevisionMarkComplete}
                  handleViewContentCurriculumClick={handleViewContentCurriculumClick}
                  isArchived={isArchived}
                />
              </Row>
            )}
            {showChapters && (
              <Column align="start" justify="center" className="curriculum-widget__content plr-2 ptb-2">
                {chapters
                  && chapters.map((chapter, index) => (
                    <ChapterCard
                      id={id}
                      key={chapter.id}
                      handleTeacherGroupTopicOrRevisionMarkComplete={handleTeacherGroupTopicOrRevisionMarkComplete}
                      topics={topics}
                      history={history}
                      handleGetTeacherGroupChapterTopics={handleGetTeacherGroupChapterTopics}
                      chapter={{
                        ...chapter,
                        state: index === 0 && chapter.state === 'scheduled' ? 'ongoing' : chapter.state,
                      }}
                      index={index}
                      hasDraft={hasDraft}
                      handleClickCreateNewActivity={handleClickCreateNewActivity}
                      isRtl={isRtl}
                      handleViewContentCurriculumClick={handleViewContentCurriculumClick}
                      isArchived={isArchived}
                    />
                  ))}
              </Column>
            )}
            {showChapters && miscChapter.type === 'misc' && (
              <Row
                style={{ width: '100%' }}
                nowrap
                align="center"
                justify="space-between"
                className={`${miscChapter.type}-card plr-2 ptb-2 ${miscChapter.state}`}
              >
                <Column align="start" justify="start">
                  <h4 style={{ fontWeight: 'normal', marginTop: '0' }} className="child">
                    {miscChapter.name}
                  </h4>
                  <Row align="center" nowrap className="child">
                    <label>{translationText(noonText, 'groupCurriculum.upcomingActivities')}</label>
                    <label
                      className="mlr-1"
                      style={{
                        border: '0.5px solid #62687A',
                        borderRadius: '4px',
                        color: '#62687A',
                        width: '24px',
                        height: '22px',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        lineHeight: '1.5',
                      }}
                    >
                      {miscChapter.upcoming_activities || 0}
                    </label>
                    {!isArchived && <IconAdd onClick={() => handleClickCreateNewActivity({ chapter: miscChapter })} fill={COLORS.brand.base} width="21.5" height="21.5" />}
                  </Row>
                </Column>
                <Button
                  size="sm"
                  type="secondary"
                  className="no-padding"
                  style={{
                    minWidth: '27px',
                    height: '27px',
                    borderColor: '#DCE3FA',
                  }}
                  outlined
                  onClick={() => handleViewContentCurriculumClick(`chapterId=${miscChapter.chapter_id}`)}
                >
                  <IconBack height="12px" width="12px" fill="#64D8AE" stroke="#64D8AE" flipX={!isRtl} />
                </Button>
              </Row>
            )}
          </Column>
          <div className="show-more-button text-center">
            <Button
              size="sm"
              className="no-padding"
              style={{
                minWidth: '27px',
                border: '0',
                textTransform: 'capitalize',
              }}
              outlined
              onClick={handleShowChapter}
            >
              {translationText(noonText, showChapters ? 'groupCurriculum.hideAllChapters' : 'groupCurriculum.showAllChapters')}
              {' '}
              {chapters.length}
              <IconArrowDown
                flipY={showChapters}
                fill={COLORS.coolGrey[1]}
                stroke={COLORS.coolGrey[1]}
                height="15px"
                width="15px"
                className="mlr-1"
              />
            </Button>
          </div>
        </Column>
        <Row nowrap gap align="center" justify="space-between" className={classNames('edit-view-buttons')}>
          {isArchived && (<NotAllowedOverlay />)}
          <Button
            type="secondary"
            onClick={handleEditCurriculumClick}
            value={translationText(noonText, 'tab.editCurriculum')}
            icon={IconEdit}
            fill="#fff"
            style={{ textTransform: 'capitalize' }}
               />
          <Button
            type="primary"
            onClick={() => handleViewContentCurriculumClick()}
            style={{ textTransform: 'capitalize' }}
            >
            {translationText(noonText, 'groupCurriculum.viewContent')}
            <IconBack flipX={!isRtl} height="24px" width="24px" />
          </Button>
        </Row>

      </Column>
    </Card>
  );
}

CurriculumWidgetPublished.propTypes = {
  id: PropTypes.string.isRequired,
  hasDraft: PropTypes.bool.isRequired,
  preview: PropTypes.shape({}),
  miscChapter: PropTypes.shape({}),
  chapters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  topics: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  handleTeacherGroupTopicOrRevisionMarkComplete: PropTypes.func.isRequired,
  handleGetTeacherGroupChapterTopics: PropTypes.func.isRequired,
  percentCompleted: PropTypes.bool.isRequired,
  handleMixpanelCurriculumEvents: PropTypes.func.isRequired,
  handleClickCreateNewActivity: PropTypes.func.isRequired,
  isRtl: PropTypes.bool.isRequired,
  handleViewContentCurriculumClick: PropTypes.func.isRequired,
};

CurriculumWidgetPublished.defaultProps = {
  preview: {},
  miscChapter: {},
};

export default CurriculumWidgetPublished;

import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Badge, Column, Row } from '@noon/atom';
import { IconDoubleArrowLeft, IconDoubleArrowRight, IconLoader } from '@noon/quark';

import { isRTL } from '../../constants';
import { isTablet } from '../../helpers';
import t from '../../helpers/translate';
import { useOnClickOutside } from '../../hooks';
import { getLeaderboard, selectTab } from '../../redux/actions/classroom';
import ErrorBoundry from '../ErrorBoundry';
import { getFirebaseRemoteConfigValue } from '../Firebase';
import ClassroomSidebarGroupList from '../Promotion/ClassroomSidebarGroupList';
import ChatContainer from './Chat/chatContainer';
import ContentContainer from './Content/contentContainer';
import LeaderboardContainer from './Leaderboard/leaderboardContainer';
import RaiseHandContainer from './raise-hands/raise-hand-container';

import { CLASSROOM_STATE, CLASSROOM_TYPE } from './types';

type ITab = {
    name: string,
    icon: React.FC,
    disabled: Boolean,
    title: string,
};

type ISidebarTabProp = {
  activeTab: ITab,
  onTabClick: Function,
  tabs: Array<ITab>,
  tabCount: {
    [key: string]: number,
  }
}

const SidebarTab = (prop: ISidebarTabProp) => {
  const { tabs, activeTab, tabCount, onTabClick } = prop;
  return (
    <Row nowrap className="my-classroom__sidebar-tab">
      {tabs.map((tab) => {
        const Icon = tab.icon;
        return (
          <div key={tab.name} onClick={() => onTabClick(tab)} className={classNames('tab-item', { disabled: tab.disabled, active: activeTab.name === tab.name })}>
            <Column align="center" justify="center">
              <Icon />
              <span className='tab-item__title'>
                {tab.title}
              </span>
            </Column>
            {Boolean(tabCount[tab.name]) && <Badge rounded className="tab-item__count" type="red" size="sm" value={tabCount[tab.name]} />}
          </div>
        );
      })}
    </Row>
  );
};

const ClassroomSidebar = () => {
  const dispatch = useDispatch();
  const sidebarContainerRef = useRef(null);

  const sessionDetails = useSelector((state) => state.toJS().myClassroom.sessionDetails);
  const activeTab: ITab = useSelector((state) => state.toJS().myClassroom.activeTab);
  const tabs : Array<ITab> = useSelector((state) => state.toJS().myClassroom.sidebarTabs) || [];
  const tabCount = useSelector((state) => state.toJS().myClassroom.sidebarTabCount);
  const isClassInitiated = useSelector((state) => state.toJS().myClassroom.isClassInitiated);
  const { studentList } = useSelector((state) => state.toJS().myClassroom.leaderboard);

  const [disableContentTabs, setDisableContentTabs] = useState([]);
  const [clickOutsideRef, setClickOutsideRef] = useState({ current: null });
  const [isCollapsed, setIsCollapsed] = useState(true); // open sidebar by default for tab n mobile in case class is in prepare state
  const [enablePromotionInClassroom, setEnablePromotionInClassroom] = useState(false);

  

  const handleTabClick = (tabName: string) => {
    dispatch(selectTab(tabName));
  };

  const handleToggleSidebar = () => {
    setIsCollapsed((isCollapsed) => !isCollapsed);
  };

  const onClickOutside = useCallback(() => {
    if (!isCollapsed) {
      setIsCollapsed(true);
    }
  }, [setIsCollapsed, isCollapsed]);

  useOnClickOutside(clickOutsideRef, onClickOutside);

  const fetchFirebaseConstants = async () => {
    const response = await getFirebaseRemoteConfigValue('feature_toggle_config_teacher_web');
    setEnablePromotionInClassroom(response.enablePromotionInClassroom);
  };

  // added this section to remove outside click listner if sidebar is close or not using mobile or tablet
  useEffect(() => {
    if (isTablet() && !isCollapsed) {
      setClickOutsideRef(sidebarContainerRef);
    } else {
      setClickOutsideRef({ current: null });
    }
  }, [isCollapsed, setClickOutsideRef, sidebarContainerRef]);

  useEffect(() => {
    if (sessionDetails.state !== CLASSROOM_STATE.started && sessionDetails.class_type === CLASSROOM_TYPE.competition) {
      setDisableContentTabs(['flashcard', 'pdf', 'book']);
    } else {
      setDisableContentTabs([]);
    }

    if (!studentList?.length && sessionDetails?.id) {
      dispatch(getLeaderboard({ session_id: sessionDetails.id }));
    }
  }, [sessionDetails]);

  useEffect(() => {
    fetchFirebaseConstants();
  }, []);

  if (!isClassInitiated) {
    return (
      <Column nowrap align="center" justify="center" className="my-classroom__sidebar">
        <IconLoader />
      </Column>
    );
  }

  return (
    <ErrorBoundry msg={t('error', 'loadingSidebar')}>

      {/* // TODO: add backwprd ref in column and row */}
      <div ref={sidebarContainerRef} className={classNames('my-classroom__sidebar', { open: !isCollapsed })}>
        <h3 className='menu-title'>
          {activeTab?.title}
        </h3>
        {enablePromotionInClassroom && sessionDetails.state === CLASSROOM_STATE.started && sessionDetails.class_type === CLASSROOM_TYPE.group && <ClassroomSidebarGroupList />}
        {activeTab?.name === 'content' && <ContentContainer disableTabs={disableContentTabs} />}
        {activeTab?.name === 'chat' && <ChatContainer />}
        {activeTab?.name === 'leaderboard' && <LeaderboardContainer />}
        {activeTab?.name === 'raiseHand' && <RaiseHandContainer />}
        {/* show tabs bar only if more than 1 tabs */}
        {tabs.length > 1 && (
          <SidebarTab
            tabs={tabs}
            tabCount={tabCount}
            activeTab={activeTab}
            onTabClick={handleTabClick}
          />
        )}

        <div className="collapse-sidebar-btn" onClick={handleToggleSidebar}>
          {isCollapsed ? (isRTL() ? <IconDoubleArrowLeft /> : <IconDoubleArrowRight />) : (isRTL() ? <IconDoubleArrowRight /> : <IconDoubleArrowLeft />)}
        </div>
      </div>
    </ErrorBoundry>
  );
};

export default ClassroomSidebar;

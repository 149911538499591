export default function slugify(text, id) {
  if (!text && id) return id;
  const normalized = text
    .toString()
    .toLowerCase()
    .replace(/\(+/g, '')
    .replace(/\)+/g, '')
    .replace(/\!+/g, '')
    .replace(/\$+/g, '')
    .replace(/\%+/g, '')
    .replace(/\^+/g, '')
    .replace(/\&+/g, '')
    .replace(/\*+/g, '')
    .replace(/\?+/g, '')
    .replace(/\@+/g, '')
    .replace(/\#+/g, '')
    .replace(/\/+/g, '-') // Replace spaces with -
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/\.+/g, '-') // Replace spaces with -
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
  return id ? `${normalized}-${id}` : normalized;
}

import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@noon/atom';
import EmptyState from './emptyState';
import DraftState from './draftState';
import PublishedState from './publishedState';
import Shimmer from '../../Layout/shimmer';

function CurriculumWidget({
  id,
  hasDraft,
  preview,
  miscChapter,
  curriculumExist,
  chapters,
  topics,
  history,
  percentCompleted,
  handleTeacherGroupTopicOrRevisionMarkComplete,
  handleGetTeacherGroupChapterTopics,
  handleMixpanelCurriculumEvents,
  handleClickCreateNewActivity,
  isRtl,
  isArchived,
}) {
  const handleViewContentCurriculumClick = (query) => {
    if (query) {
      handleMixpanelCurriculumEvents({
        type: 'topic_selected_group_home',
        data: {
          curriculum_state: 'published',
          no_of_chapters: chapters.length,
          curriculum_completion: percentCompleted,
        },
      });
    } else {
      handleMixpanelCurriculumEvents({
        type: 'view_content_clicked',
        data: {
          curriculum_state: 'published',
          no_of_chapters: chapters.length,
          curriculum_completion: percentCompleted,
        },
      });
    }
    history.push(`/${localStorage.updatedLocale}/groups/${id}/content?${query || ''}`);
  };

  return (
    (!preview || preview.loading)
      ? (
        <Card>
          {' '}
          <Shimmer obj={[
            { heading: true },
            { box: true, lines: 2 },
          ]} />
        </Card>
      )
      : (
        <React.Fragment>
          {!curriculumExist ? (
            <EmptyState isArchived={isArchived} isRtl={isRtl} handleClickCreateNewActivity={handleClickCreateNewActivity} handleViewContentCurriculumClick={handleViewContentCurriculumClick} handleMixpanelCurriculumEvents={handleMixpanelCurriculumEvents} miscChapter={miscChapter} id={id} history={history} />
          ) : hasDraft ? (
            <DraftState
              hasDraft={hasDraft}
              history={history}
              miscChapter={miscChapter}
              curriculumExist={curriculumExist}
              id={id}
              chapters={chapters}
              percentCompleted={percentCompleted}
              handleMixpanelCurriculumEvents={handleMixpanelCurriculumEvents}
              handleClickCreateNewActivity={handleClickCreateNewActivity}
              isRtl={isRtl}
              handleViewContentCurriculumClick={handleViewContentCurriculumClick}
              isArchived={isArchived}
        />
          ) : (
            <PublishedState
              hasDraft={hasDraft}
              history={history}
              miscChapter={miscChapter}
              handleTeacherGroupTopicOrRevisionMarkComplete={handleTeacherGroupTopicOrRevisionMarkComplete}
              handleGetTeacherGroupChapterTopics={handleGetTeacherGroupChapterTopics}
              curriculumExist={curriculumExist}
              topics={topics}
              id={id}
              preview={preview}
              chapters={chapters}
              percentCompleted={percentCompleted}
              handleMixpanelCurriculumEvents={handleMixpanelCurriculumEvents}
              handleClickCreateNewActivity={handleClickCreateNewActivity}
              isRtl={isRtl}
              handleViewContentCurriculumClick={handleViewContentCurriculumClick}
              isArchived={isArchived}
        />
          )}
        </React.Fragment>
      )
  );
}

CurriculumWidget.propTypes = {
  id: PropTypes.string.isRequired,
  hasDraft: PropTypes.bool.isRequired,
  preview: PropTypes.shape({}),
  miscChapter: PropTypes.shape({}),
  curriculumExist: PropTypes.bool.isRequired,
  chapters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  topics: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  handleTeacherGroupTopicOrRevisionMarkComplete: PropTypes.func.isRequired,
  handleGetTeacherGroupChapterTopics: PropTypes.func.isRequired,
  percentCompleted: PropTypes.bool.isRequired,
  handleMixpanelCurriculumEvents: PropTypes.func.isRequired,
  handleClickCreateNewActivity: PropTypes.func.isRequired,
  isRtl: PropTypes.bool.isRequired,
};

CurriculumWidget.defaultProps = {
  preview: {},
  miscChapter: {},
};

export default CurriculumWidget;

import t from '../helpers/translate';

export const difficultyLevels = [
    { id: 'level1', value: 10, name: t('library', 'difficulty1') },
    { id: 'level2', value: 30, name: t('library', 'difficulty2') },
    { id: 'level3', value: 50, name: t('library', 'difficulty3') },
    { id: 'level4', value: 70, name: t('library', 'difficulty4') },
    { id: 'level5', value: 90, name: t('library', 'difficulty5') },
]

export const defaultQuestion = {
    category_type: 'practice',
    question_type_id: 1,
    text_direction: document.body.dir,
    question: '',
    question_json: [],
    solution: '',
    verified: 1,
    publish: 1,
    id: undefined,
    all_tag_details: [],
    choices: [],
    difficulty: difficultyLevels[0].value,
};

export const defaultChoices = new Array(4).fill({
    is_correct: 0,
    answer: '',
    answer_json: [],
});
import React from 'react';
import { Column, Badge, Button } from '@noon/atom';
import t from '../../../../helpers/translate';

export type IBookPage = {
  id: number,
  page_number: number,
  offset: number,
  name: string,
  image_uri: string,
  image_thumbnail_uri: string,
}

type IBookItemProp = {
    bookPage: IBookPage,
    selectPage: (bookPage: IBookPage) => void
}

const BookItem = ({ bookPage, selectPage }: IBookItemProp) => {
  const { id, page_number, offset, name, image_uri, image_thumbnail_uri } = bookPage;
  return (
    <Column key={id} className="folder-book__list-item">
      <Badge rounded color="primary" size="xs">
        {page_number - offset}
      </Badge>
      <img
        alt={name}
        src={image_uri || image_thumbnail_uri}
        data-resource-id={id}
        data-resource-type="bookPage_image"
      />
      <Button
        className="animate slideInUp"
        rounded
        type="primary"
        size="sm"
        onClick={() => selectPage(bookPage)}
      >
        {t('classroom', 'addToCanvas')}
      </Button>
    </Column>
  );
};

export default BookItem;

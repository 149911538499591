import React from 'react';
import { Column } from '@noon/atom';
import { IconCanvasDiscard } from '@noon/quark';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ReportPageBarStats from './ReportPageBarStats';
import { translationText } from '../../helpers';
import { checkIsTablet } from '../../helpers/prepareAssignment';
import ReportPageProfile from './ReportPageProfile';

const ReportPageMobileStats = (props) => {
  const { details, noonText, setIsOpen, isOpen = false } = props;
  const { submittedUsers, notSubmittedUsers, studentProfile } = useSelector((state) => state.toJS().homeworkReport);

  return (
    <Column
      style={{ flexDirection: checkIsTablet() ? studentProfile.position !== -1 ? 'row' : 'row-reverse' : 'column' }}
      className="report--mobileStats"
    >
      {isOpen && <IconCanvasDiscard onClick={setIsOpen} className="close-user-icon" name="canvas-discard" height="16px" width="16px" fill="#23294e" stroke="#23294e" />}
      {studentProfile.position !== -1 && <ReportPageProfile profile={studentProfile} />}
      <ReportPageBarStats details={details} noonText={noonText} />
      {studentProfile.position === -1 && (
        <Column>
          {checkIsTablet()
            && (
            <span className="students-submitted">
              {`
                ${submittedUsers.meta.total}/${submittedUsers.meta.total + notSubmittedUsers.meta.total}
                ${translationText(noonText, 'groups.followers')}
                ${translationText(noonText, 'homeworkReport.submitted')}
                `}
            </span>
            )}
          {studentProfile.position === -1 && <button onClick={setIsOpen}>{translationText(noonText, 'homeworkReport.viewSubmissions')}</button>}
        </Column>
      )}
    </Column>
  );
};

ReportPageMobileStats.propTypes = {
  details: PropTypes.shape(),
  noonText: PropTypes.shape().isRequired,
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

ReportPageMobileStats.defaultProps = {
  details: {},
};

export default ReportPageMobileStats;

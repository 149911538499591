import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Popover from 'react-popover';
import { IconArrowDown } from '@noon/quark';
import { Row } from '@noon/atom';
import { LIST_COUNTRY } from '../../redux/constants';
import { generateLocalizedUrl, operationsForLocaleChange } from '../../helpers';

export class CountryDropdown extends Component {
  static propTypes = {
    countries: PropTypes.shape({
      countryList: PropTypes.array,
      selectedCountry: PropTypes.object,
    }).isRequired,
    updateCountries: PropTypes.func.isRequired,
    location: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
      selectedCountry: props.countries.selectedCountry || {},
    };
  }

  onChange = (selectedCountry) => {
    if (selectedCountry.id === this.state.selectedCountry.id) {
      this.handleDropdown();
      return;
    }
    this.setState({ selectedCountry });
    const countries = JSON.parse(localStorage.getItem('country'));
    countries.selectedCountry = selectedCountry;
    localStorage.country = JSON.stringify({ countryList: countries.countryList, selectedCountry });
    delete localStorage.language;
    delete localStorage.translationData;
    const url = generateLocalizedUrl(this.props.location.pathname, `${selectedCountry.iso_code.toLowerCase()}-${selectedCountry.locale.split('_')[0]}`);
    operationsForLocaleChange(selectedCountry.locale, url);
  }

  handleDropdown = () => {
    this.setState(prevState => ({ showDropdown: !prevState.showDropdown }));
  };

  render() {
    const { showDropdown, selectedCountry } = this.state;

    const popoverProps = {
      isOpen: showDropdown,
      preferPlace: 'below',
      onOuterAction: () => this.handleDropdown(),
      body: (
        <ul className="noon-list">
          {this.props.countries.countryList.map(country => (
            <li className="country-dropdown__item" key={country.id} onClick={() => this.onChange(country)}>
              <span>{country.name}</span>
              <img src={country.flag} height="22px" alt={country.name} />
            </li>
          ))}
        </ul>
      ),
    };

    return (
      <div className="noon-user-menu">
        <Popover {...popoverProps}>
          <Row className="country-dropdown" align="center" onClick={this.handleDropdown}>
            <div className="title">{selectedCountry.name}</div>
            <img src={selectedCountry.flag} height="22px" alt={selectedCountry.name} />
            <IconArrowDown fill={this.props.dropDownColor || null} />
          </Row>
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  countries: state.toJS().folder.countries,
});

const mapDispatchToProps = dispatch => ({
  updateCountries: countries => dispatch({ type: LIST_COUNTRY.SUCCESS, payload: countries }),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CountryDropdown),
);

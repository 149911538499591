/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { IconMoreOption, IconFlag, IconKickOut } from '@noon/quark';
import { Row, InfiniteScroll, Avatar, Badge } from '@noon/atom';
import Popover from 'react-popover';
import EmptyState from '../emptyState';
import t from '../../../helpers/translate';
import { COLORS } from '../../../constants';
import { SessionGenericModal } from '../../Modals';
import ReportAbuseModal from '../../Class/reportAbuseModal';

type IStudent = {
  name: string,
  rank: number,
  score: number,
  user_id: number,
  blockTime: number,
  profile_pic: string,
  status: string,
}

type IStudentLeaderboardProp = {
  loadMoreLeaderboard: Function,
  allowLoadMoreLeaderboard: Boolean,
  isLeaderboardLoading: Boolean,
  studentList: IStudent[],
  onBlockUser: (student: IStudent) => void,
  onReportAbuse: Function,
};

export const BLOCK_TIME = 7 * 24 * 60 * 60; // 7 days

const StudentLeaderboard = (prop: IStudentLeaderboardProp) => {
  const { loadMoreLeaderboard, onBlockUser, onReportAbuse, allowLoadMoreLeaderboard, isLeaderboardLoading, studentList } = prop;

  const [optionPopup, setOptionPopup] = useState(0);
  const [blockChatModal, setBlockChatModal] = useState<IStudent>(null);
  const [flaggedPlayer, setFlaggedPlayer] = useState<IStudent>(null);

  if (!studentList.length && !isLeaderboardLoading) {
    return (
      <EmptyState
        title={t('classroom', 'empty_leaderboard_title')}
        subtitle={t('classroom', 'empty_leaderboard_subtitle')}
      />
    );
  }

  const closeReportAbuseModal = () => {
    setFlaggedPlayer(null);
  };

  const handleReportAbuse = (payload) => {
    onReportAbuse(payload);
    closeReportAbuseModal();
  };

  const closeBlockChatModal = () => {
    setBlockChatModal(null);
  };

  const blockUser = () => {
    onBlockUser(blockChatModal);
    closeBlockChatModal();
  };

  const openReportAbuseModal = (student) => {
    setFlaggedPlayer(student);
    setOptionPopup(0);
  };

  const openBlockUserModal = (student) => {
    setBlockChatModal(student);
    setOptionPopup(0);
  };

  return (
    <>
      {!isEmpty(blockChatModal) && (
        <SessionGenericModal
          type="edit"
          text1={t('classroom', 'chatBlockHeading')}
          text2={t('classroom', 'chatBlock')}
          btn1={t('classroom', 'buttonConfirm')}
          btn2={t('classroom', 'ButtonCancel')}
          onClick={blockUser}
          onClose={closeBlockChatModal}
        />
      )}

      {!isEmpty(flaggedPlayer) && (
        <ReportAbuseModal
          player={flaggedPlayer}
          onClose={closeReportAbuseModal}
          onSubmit={handleReportAbuse}
        />
      )}

      <InfiniteScroll
        onLoad={loadMoreLeaderboard}
        loadMore={allowLoadMoreLeaderboard}
        loading={isLeaderboardLoading}
        className="classroom-student-list"
      >
        {studentList.map((student: IStudent, index: number) => {
          const popoverOptions = {
            isOpen: optionPopup === student.user_id && (student.blockTime > BLOCK_TIME || student.blockTime <= 0 || !student.blockTime),
            preferPlace: 'below',
            onOuterAction: () => setOptionPopup(0),
            body: (
              <div className="action-options">
                <Row gap align="center" className="action-option" onClick={() => openReportAbuseModal(student)}>
                  <IconFlag className="child" fill={COLORS.grey[2]} width="1.8rem" />
                  <span className="child">{t('button', 'reportAbuse')}</span>
                </Row>
                <Row gap align="center" className="action-option" onClick={() => openBlockUserModal(student)}>
                  <IconKickOut className="child" fill={COLORS.grey[2]} width="1.8rem" />
                  <span className="child">{t('button', 'block')}</span>
                </Row>
              </div>
            ),
          };
          return (
            <Row
              gap="sm"
              align="center"
              key={student.user_id}
              className={classNames('classroom-student-list__item', {
                disabled: student.blockTime <= BLOCK_TIME && student.blockTime > 0,
              })}
            >
              <div className="index">{student.rank || index + 1}</div>
              <Avatar noShadow url={student.profile_pic} rounded showChild>
                <Badge size="xs" color={student.status === 'online' ? 'green' : 'default'} rounded />
              </Avatar>
              <div className="flex-1">{student.name}</div>
              {Boolean(student.score) && <div>{student.score}</div>}
              <Popover {...popoverOptions} className="leaderboard-popover">
                <IconMoreOption onClick={() => setOptionPopup(student.user_id)} className="pointer" width="2rem" color={COLORS.grey[2]} rotate="90" />
              </Popover>
            </Row>
          );
        })}
      </InfiniteScroll>
    </>
  );
};

export default StudentLeaderboard;

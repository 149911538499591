import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Card, Row, Column, Modal, Button, Badge } from '@noon/atom';
import { IconAdd, IconCorrectCircle, IconExclamationCircle } from '@noon/quark';
import { COLORS } from '../../../constants';
import { translationText } from '../../../helpers';
import NotAllowedOverlay from '../NotAllowedOverlay';

function TopicCard({ isArchived, isLastTopic, handleViewContentCurriculumClick, handleTeacherGroupTopicMarkComplete, topic, activeCard, handleClickCreateNewActivity }) {

  const [showConfirmMarkComplete, setShowConfirmMarkComplete] = useState(false);
  const { noonText } = useSelector((state) => state.toJS().translation);

  return (
    <Card spacing={15} style={{ borderRadius: '12px', width: !activeCard ? '100%' : '100%' }} className="mtb-1">
    {!topic.is_free && 
      <Badge type="default" dir="ltr" className="plus-badge">
        <span>{translationText(noonText, 'groups.plus')}</span>
      </Badge>
    }
      <Column gap="sm" onClick={() => handleViewContentCurriculumClick(`chapterId=${topic.chapter_id}&topicId=${topic.topic_id}`)}>
        <Row align="center" justify="start" flex="1" className="child position-relative">
          {isArchived && <NotAllowedOverlay />}
          {topic.state === 'ongoing' && (
            <React.Fragment>
              <ReactTooltip place="bottom" />
              <IconCorrectCircle
                data-tip={translationText(noonText, 'groups.markTopicCompleted')}
                tooltipPos="bottom"
                onClick={(event) => { setShowConfirmMarkComplete(!showConfirmMarkComplete); event.stopPropagation(); }}
                width="21"
                height="21"
                fill="#eee"
                stroke={COLORS.brand.base}
              />
            </React.Fragment>
          )}
          {topic.state === 'scheduled'
            && (
              <IconCorrectCircle
                width="21"
                height="21"
                fill="#eee"
                stroke="#fff"
              />
            )}

          {topic.state === 'completed'
            && (
              <IconCorrectCircle
                width="21"
                height="21"
                fill={COLORS.coolGrey[1]}
                stroke="#fff"
            />
            )}
          <span className="topic-name plr-1">
            {topic.name_header}
          </span>
        </Row>
        <span className="mb-3">
          {topic.name}
        </span>
        {topic.state === 'ongoing'
        && (
        <Row align="center" nowrap className="child">
          <label>{translationText(noonText, 'groupCurriculum.upcomingActivities')}</label>
          <label
            className="mlr-1"
            style={{
              border: '0.5px solid #62687A',
              borderRadius: '4px',
              color: '#62687A',
              width: '24px',
              height: '22px',
              textAlign: 'center',
              verticalAlign: 'middle',
              lineHeight: '1.5',
            }}>
            {topic.upcoming_activities || 0}
          </label>
          {!isArchived && <IconAdd onClick={(event) => { handleClickCreateNewActivity(topic); event.stopPropagation(); }} fill={COLORS.brand.base} width="21.5" height="21.5" />}
        </Row>
        )}
        {topic.state === 'completed'
        && <span>{translationText(noonText, 'groups.topicMarkedCompleted')}</span>}
      </Column>
      {showConfirmMarkComplete
      && (
      <Modal className="generic-modal-container modal-mark-complete">
        <Column nowrap className="generic-modal">
          <Column align="center" className="generic-modal--body">
            <div className="header-icon">
              <IconCorrectCircle
                width="81"
                height="81"
                fill="#000000"
                stroke="#fff"
            />
            </div>
            <div className="generic-modal--body--wrapper">
              <Column className="generic-modal--body--text" align="center">
                <div className="text-1">{translationText(noonText, 'groups.markTopicCompleted')}</div>
                {isLastTopic
                  ? (
                    <span className="ptb-1">
                      {translationText(noonText, 'groups.sureToMark')}
                      {' '}
                      <strong>
                        {' '}
                        {topic.name}
                        {' '}
                      </strong>
                      {' '}
                      {translationText(noonText, 'groups.asComplete')}
                      <br />
                      {' '}
                      {translationText(noonText, 'groups.thisWillMarkChapterAlso')}
                    </span>
                  )
                  : (
                    <span className="ptb-1">
                      {translationText(noonText, 'groups.sureToMark')}
                      {' '}
                      <strong>
                        {' '}
                        {topic.name}
                        {' '}
                      </strong>
                      {' '}
                      {translationText(noonText, 'groups.asComplete')}
                    </span>
                  )}
                <div className="text-2">
                  <IconExclamationCircle height="20px" width="20px" fill="#e22012" stroke="#e22012" className="mlr-1" />
                  {translationText(noonText, 'groups.cantUndo')}
                </div>
              </Column>
              <Row nowrap align="center" justify="center" gap="lg" className="generic-modal--body--btn">
                <Button
                  type="primary"
                  size="md"
                  className="btn-modal"
                  style={{
                    width: '159px',
                    textTransform: 'capitalize',
                  }}
                  onClick={() => { handleTeacherGroupTopicMarkComplete({ topicId: topic.topic_id, chapterId: topic.chapter_id }); setShowConfirmMarkComplete(!showConfirmMarkComplete); }}
                >
                  {translationText(noonText, 'button.mark')}
                </Button>
                <Button
                  type="secondary"
                  size="md"
                  className="btn-modal"
                  style={{
                    width: '159px',
                    textTransform: 'capitalize',
                  }}
                  onClick={() => setShowConfirmMarkComplete(!showConfirmMarkComplete)}
                >
                  {translationText(noonText, 'button.cancel')}
                </Button>
              </Row>
            </div>
          </Column>
        </Column>
      </Modal>
      )}
    </Card>
  );
}

TopicCard.propTypes = {
  handleTeacherGroupTopicMarkComplete: PropTypes.func.isRequired,
  topic: PropTypes.shape({}).isRequired,
  handleClickCreateNewActivity: PropTypes.func.isRequired,
  handleViewContentCurriculumClick: PropTypes.func.isRequired,
  isLastTopic: PropTypes.bool.isRequired,
};
TopicCard.defaultProps = {};

export default TopicCard;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row } from '@noon/atom';
import { withRouter, Redirect } from 'react-router-dom';
import { SEND_OTP_VIA_EMAIL, UPDATE_ORGANISATION, UPDATE_DATA_POINTS, GO_TO_LOGIN } from '../../redux/constants';
import { translationText } from '../../helpers';
import { MobileVerification } from '../../components/LoginSignup';
import EmailOtpSentConfirmation from './emailOtpSentConfirmation';
import Mixpanel from '../../components/Mixpanel';
import { getSelectedCountry } from '../../constants';
import IndiaLogin from '../../components/LoginSignup/indiaLogin';

class Login extends Component {
  /* eslint-disable react/prop-types */
  constructor(props) {
    super(props);
    const organisationName = props.match.params.organisation;
    this.state = {
      organisationName,
      countryIsoCode: '',
    };
  }

  componentDidMount() {
    const { updateOrganisation } = this.props;
    const { organisationName } = this.state;
    if (organisationName) updateOrganisation(organisationName);
    if (this.props.goToLoginFlag) {
      this.setChoiceForNonLinked('existing');
      this.props.goToLogin(false);
    }
    this.setState({
      countryIsoCode: getSelectedCountry().iso_code,
    });
  }

  componentDidUpdate(prevProps) {
    const { studentTempAuthData } = this.props;
    if (this.props.type !== prevProps.type && this.props.type === 'login') {
      // const redirectUrl = localStorage.lastUrl.pathname || `${localStorage.updatedLocale}/groups`;
      // this.props.history.push(redirectUrl);
      // eslint-disable-next-line no-unused-expressions
      <Redirect to={localStorage.lastUrl ? JSON.parse(localStorage.lastUrl) : `/${localStorage.updatedLocale}/home`} />;
    }
    if (this.props.otpVerified !== prevProps.otpVerified && this.props.otpVerified) {
      if (this.props.type === 'signup') {
        this.setChoiceForNonLinked('new');
      }
      /* [start] mixpanel event */
      try {
        Mixpanel.track('otp_verified_successfully', {
          ...studentTempAuthData,
          auto_fill: false,
        });
      } catch (e) {
        console.log('error', e);
      }
      /* [end] mixpanel event */
    }
  }

  setChoiceForNonLinked = (choice) => {
    const { updateUserDataPoints } = this.props;
    if (choice === 'new') {
      // todo redirect to signup flow
      this.props.history.push(`/${localStorage.updatedLocale}/signup`);
      updateUserDataPoints({
        existing_noon_account: 'no',
        enter_path: 'new_account',
        user_type: 'teacher',
      });
    } else if (choice === 'existing') {
      updateUserDataPoints({
        account_type: 'legacy',
        existing_noon_account: 'yes',
        enter_path: 'legacy_account',
        legacy_login: 'email_pass',
        user_type: 'teacher',
      });
    }
  };

  render() {
    const { otpVerified, email, sendOtpViaEmail, emailOtpSent, noonText } = this.props;
    const { organisationName, countryIsoCode } = this.state;
    return (
      <Row nowrap className="guest-route-wrapper">
        {emailOtpSent && (
          <EmailOtpSentConfirmation
            greeting={translationText(noonText, 'teacherDashboard.emailOtpSentConfirmation')}
            email={email}
            sendOtpViaEmail={sendOtpViaEmail}
          />
        )}
        {!emailOtpSent && !otpVerified && countryIsoCode === 'IN' ? (
          <IndiaLogin>
            <MobileVerification organisationName={organisationName} />
          </IndiaLogin>
        ) : <MobileVerification organisationName={organisationName} />}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  noonText: state.toJS().translation.noonText,
  otpVerified: state.toJS().onboarding.otpVerified,
  type: state.toJS().onboarding.type,
  studentTempAuthData: state.toJS().dashboard.studentTempAuthData,
  emailOtpSent: state.toJS().onboarding.emailOtpSent,
  email: state.toJS().onboarding.email,
  goToLoginFlag: state.toJS().dashboard.goToLogin,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserDataPoints: (item) => dispatch({ type: UPDATE_DATA_POINTS, payload: item }),
  updateOrganisation: (payload) => dispatch({ type: UPDATE_ORGANISATION, payload }),
  sendOtpViaEmail: (payload) => dispatch({ type: SEND_OTP_VIA_EMAIL.REQUEST, payload }),
  goToLogin: (payload) => dispatch({ type: GO_TO_LOGIN, payload }),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Login),
);

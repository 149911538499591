import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionContent, selectQuestionContent } from '../../redux/actions/classroom';
import OnboardingSuccessDialog from '../Class/AddQuestionFromImage/OnboardingSuccessDialog';
import AddQuestionFromImage from '../Class/AddQuestionFromImage/Container';
import { CLOSE_ADD_IMAGE_FROM_QUESTION_DIALOG, HIDE_ADD_IMAGE_FROM_QUESTION_ONBOARDING_SUCCESS } from '../../redux/constants';

const ImageToQuestionContainer = () => {

  const dispatch = useDispatch();

  const { sessionDetails = {} } = useSelector((state) => state.toJS().myClassroom);
  const addQuestionFromImage = useSelector((state) => state.toJS().addQuestionFromImage);
  const topicId = useSelector((state) => state.toJS().content.topicId);

  const closeImageToquestionOnboardingSuccess = () => {
    dispatch({ type: HIDE_ADD_IMAGE_FROM_QUESTION_ONBOARDING_SUCCESS });
  };

  const closeAddQuestionFromImageDialog = () => {
    dispatch({ type: CLOSE_ADD_IMAGE_FROM_QUESTION_DIALOG });
  };

  const handleImageToQuestionSuccess = (payload) => {
    closeAddQuestionFromImageDialog();
    dispatch(getQuestionContent({ topic_id: topicId, page: 1 }));
    // add question to canvas
    if (payload?.questionData?.question_json) {
      const filteredChoices = payload.questionData.choices.map((choice) => ({ ...choice, answer: '' }));
      const questionPayload = {
        ...payload.questionData,
        question: '',
        choices: filteredChoices,
        passage_json: payload.questionData?.passage?.content_json,
        passage: '',
      };
      selectQuestionContent(questionPayload);
    }
  };

  return (
    <>
      {addQuestionFromImage.showOnboardingSuccess && (
        <OnboardingSuccessDialog
          isTrialSession={!addQuestionFromImage?.lessonDetails}
          onClose={closeImageToquestionOnboardingSuccess}
        />
      )}
      {addQuestionFromImage.showDialog && (
        <AddQuestionFromImage
          onBoarding={addQuestionFromImage.onboarding}
          groupId={sessionDetails?.group?.id}
          curriculumTags={sessionDetails?.curriculum_tags}
          onDone={handleImageToQuestionSuccess}
          onClose={closeAddQuestionFromImageDialog}
        />
      )}
    </>
  );
};

export default ImageToQuestionContainer;

import React from 'react';
import Image from 'react-lazy-image';
import { translationText } from '../../../helpers';
import { getCountryId } from '../../../constants';

export default function FeatureCompetition(prop) {
  const { noonText, global } = prop;
  const countryId = getCountryId();
  return (
    <section className="tagline-sec">
      <div className="tagline-wrapper">
        <div className="tagline-content">
          <div className="head-fade-line">
            <span className="fading-line" />
          </div>
          <h2 className="heading-teacher">{translationText(noonText, 'teacherLanding.competitionHeading')}</h2>
          {/* <h4 className="subheading-teacher">{translationText(noonText, 'teacherLanding.competitionSubheading')}</h4> */}
          <p className="description-teacher">{translationText(noonText, 'teacherLanding.competitionContent')}</p>
        </div>
        <div className="tagline-image">
          <Image
            offset={100}
            source={`/assets/images/landing/feature-practice-question-${global ? '2' : countryId}.jpg`}
            type="jpg"
            alt=""
          />
        </div>
      </div>
    </section>
  );
}

import React from 'react';
import { useDispatch } from 'react-redux';
import { IconCanvasDiscard, IconLoader, IconPosition } from '@noon/quark';
import { Row, Column, Avatar } from '@noon/atom';
import { GET_USER_RESPONSE } from '../../redux/constants';

const ReportPageProfile = ({ profile, isOpen = false, closeModal }) => {
  const dispatch = useDispatch();

  const removeStudentProfile = () => {
    dispatch({
      type: GET_USER_RESPONSE.FAILURE,
    });
  };

  return (
    <div className="report--profile">
      {profile.loading ? (
        <Row align="center" justify="center" style={{ height: '100%' }}>
          <IconLoader height="78px" width="78px" />
        </Row>
      ) : (
        <Row align="center">
          {isOpen
            && (
            <IconCanvasDiscard
              onClick={closeModal}
              className="close-user-icon"
              height="16px"
              width="16px"
              fill="#23294e"
              stroke="#23294e"
            />
            )}
          <div className="report--profile--picture">
            <Avatar url={profile.profile_pic} gender={profile.gender} rounded noShadow size="65px" />
            <div className="student-position">
              <IconPosition width="70px" height="32px" />
              <span>{profile.position}</span>
            </div>
            <Row
              onClick={removeStudentProfile}
              align="center"
              justify="center"
              className="close-profile"
            >
              <IconCanvasDiscard height="12px" width="12px" fill="#64D8AE" stroke="#64D8AE" />
            </Row>
          </div>
          <Column className="report--profile--data">
            <Row>
              <span className="name">{profile.name}</span>
            </Row>
          </Column>
        </Row>
      )}
    </div>
  );
};

export default ReportPageProfile;

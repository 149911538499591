import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Column } from '@noon/atom';
import classNames from 'classnames';
import { useEventDispatch } from '../../../providers/Event';
import { toggleChatStatus, addLocalChat, selectImageContent } from '../../../redux/actions/classroom';
import { CLASSROOM_EVENT } from '../events';
import Chat from '../../Chat';
import TeamQna from '../TeamQna/TeamQna';
import t from '../../../helpers/translate';

const uuidv4 = require('uuid/v4');

const BLOCK_TIME = 7 * 24 * 60 * 60; // 7 days

enum TabTypes {
  chat = 'chat',
  teamQna = 'teamQna'
}

const ChatHeader = (prop: { selectedTab: TabTypes; onSelectedTab: (tab: TabTypes) => void }) => {
  const { selectedTab, onSelectedTab } = prop;
  return (
    <Row className="tab-container">
      <div className={`tab ${selectedTab !== 'teamQna' ? 'active' : ''}`} onClick={() => onSelectedTab(TabTypes.chat)}>
        {t('teamQnaBreakout', 'messages')}
      </div>
      <div
        className={`tab ${selectedTab === 'teamQna' ? 'active' : ''}`}
        onClick={() => onSelectedTab(TabTypes.teamQna)}
      >
        {t('teamQnaBreakout', 'teamsQuestions')}
      </div>
    </Row>
  );
};

const ChatContainer = () => {
  const sendMessage = useEventDispatch();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.toJS().user.loggedUser);
  const sessionDetails = useSelector((state) => state.toJS().myClassroom.sessionDetails);
  const disableChat = useSelector((state) => state.toJS().chat.disableChat);
  const chats = useSelector((state) => state.toJS().chat.chats);
  const [selectedTab, setSelectedTab] = useState<TabTypes>(TabTypes.chat);
  const { qna_breakout_enabled } = useSelector((state) => state.toJS().myClassroom.sessionDetails);

  const handleSendMessage = (msg: string, image?: string, imageType?: string) => {
    const payload = {
      id: uuidv4(),
      session_id: sessionDetails.id,
      time: Date.now(),
      name: user.name,
      gender: user.gender,
      user_id: user.id,
      pic: user.profile_pic,
      isTeacher: 1,
      image_thumbnail_uri: image || null,
      image_type: imageType || null,
      msg
    };
    dispatch(addLocalChat(payload)); // show it locally for faster response time
    // later update the double tick after getting response from server
    sendMessage(CLASSROOM_EVENT.chat, payload);
  };

  const handleBlockUser = (userChat) => {
    const payload = {
      user_id: userChat.user_id,
      blockTime: BLOCK_TIME,
      chat_time: userChat.time,
      msg: userChat.msg,
      isTeacher: 0
    };
    sendMessage(CLASSROOM_EVENT.blockChat, payload);
  };

  const handleToggleChatStatus = (disableChat: boolean) => {
    const payload = {
      event: 'disableStudentChat',
      disable: disableChat,
      isTeacher: 1
    };
    dispatch(toggleChatStatus());
    sendMessage(CLASSROOM_EVENT.disableStudentChat, payload);
  };

  const handleSendStudentImageToCanvas = (payload: { resourceId?: null; url: string; resourceType: string }) => {
    dispatch(selectImageContent(payload));
  };

  return (
    <Column className={classNames('chat-container', { team_qna_enabled: qna_breakout_enabled })}>
      {qna_breakout_enabled && <ChatHeader selectedTab={selectedTab} onSelectedTab={setSelectedTab} />}
      {selectedTab === 'chat' && (
        <Chat
          chats={chats}
          user={user}
          disableChat={disableChat}
          onSendMessage={handleSendMessage}
          onBlockUser={handleBlockUser}
          onToggleStatus={handleToggleChatStatus}
          onSelectImage={handleSendStudentImageToCanvas}
        />
      )}
      {selectedTab === 'teamQna' && <TeamQna />}
    </Column>
  );
};

export default ChatContainer;

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime, Info } from 'luxon';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import Pikaday from 'pikaday';
import { Select, Button, Input, Row, MultiSelect, Checkbox, Badge } from '@noon/atom';
import classNames from 'classnames';
import { translationText } from '../../helpers';
import DatePicker from '../DatePicker';
import { getWeekdays, getWeekdaysShort } from '../../helpers/date';

import {
  GET_TEACHER_GROUPS,
  GET_CHAPTERS_FROM_GROUP,
  GET_TOPICS_FROM_GROUP,
  CREATE_GROUP_TUTORING_FOR_TEACHER,
  UPDATE_GROUP_TUTORING,
  CLEAR_TUTORING_DETAILS,
  GET_TEACHER_GROUP_SELECTED,
} from '../../redux/constants';

export default function CreateSession(prop) {
  const {
    sessionType = 'session',
    onContentClose,
    groupId,
    sessionData,
    source,
    chapter,
    topic,
    handleMixpanelKafkaActivityEvents,
  } = prop;
  const dispatch = useDispatch();
  const dateRef = useRef(null);
  const [fieldsState, setFieldState] = useState({});
  const [inputCharCount, setInputCharCount] = useState({});
  const [time, setTime] = useState();
  const tagOptions = [
    { id: 1, name: 'Free', title: translationText(noonText, 'groups.free') },
    { id: 2, name: 'Plus', title: translationText(noonText, 'groups.plus') },
  ];
  const { groupChapters, groupTopics, selectedGroup } = useSelector((state) => state.toJS().groupsV2);
  const [selectedStatus, setSelectedStatus] = useState(selectedGroup && selectedGroup.group_info && selectedGroup.group_info.is_premium && selectedGroup.group_info.free_percent !== 100 ? tagOptions[1] : tagOptions[0]);
  const {
    groups: { data: groupList },
  } = useSelector((state) => state.toJS().groupsV2);
  const { createdGroupSessionsForTeacher, updatedGroupSessions } = useSelector((state) => state.toJS().tutoring);
  const { is_unified_group_teacher } = useSelector((state) => state.toJS().experiment.featureFlags);
  const { noonText } = useSelector((state) => state.toJS().translation);
  const isRtl = document.body.dir === 'rtl';

  const StatusListComponent = (value) => {
    const isChecked = fieldsState.topics.find((singleTopic) => singleTopic.id === value.id);
    return (
      <Row align="center" style={{ position: 'relative' }}>
        <Checkbox checked={isChecked} />
        <span>{value.name}</span>
        {!value.isFree &&
          <Badge type="default" dir="ltr" className="plus-badge">
            <span>{translationText(noonText, 'groups.plus')}</span>
          </Badge>
        }
      </Row>
    );
  };

  const ListComponent = (prop) => (
    <Row nowrap>
      <Checkbox checked={prop.id === selectedStatus.id} />
      <span className={classNames('status-title', { 'status-active': prop.id === selectedStatus.id })}>
        {prop.title}
      </span>
    </Row>
  );

  const TitleComponent = (prop) => (
    <Row nowrap align="start">
      <Row className="mr-1">
        {prop.id === 1 ? <img src="/assets/images/freecircle.svg" /> : <img src="/assets/images/pluscircle.svg" />}
      </Row>
      <span className="status-title">{prop.title}</span>
    </Row>
  );

  useEffect(() => {
    if (
      createdGroupSessionsForTeacher &&
      !createdGroupSessionsForTeacher.loading &&
      createdGroupSessionsForTeacher.success
    ) {
      dispatch({ type: CLEAR_TUTORING_DETAILS });
      onContentClose(dateRef.current.value);
    }
    if (updatedGroupSessions && !updatedGroupSessions.loading && updatedGroupSessions.success) {
      dispatch({ type: CLEAR_TUTORING_DETAILS });
      onContentClose(dateRef.current.value);
    }
  }, [createdGroupSessionsForTeacher, updatedGroupSessions]);

  const massageChapterData = (data) => {
    const chapters = data.list;
    if (chapters && chapters.length > 0) {
      return chapters.map(({ name, type, chapter_id }) => ({
        name,
        type,
        id: chapter_id,
      }));
    }
    return [];
  };

  const massageGroupData = (data) => {
    if (data && data.length > 0) {
      return data.map(({ id, title, is_premium, free_percent }) => ({
        id,
        name: title,
        is_premium,
        free_percent,
      }));
    }
    return [];
  };

  const massageTopicData = (data) => {
    const topics = data.list;
    if (topics && topics.length > 0) {
      return topics.map(({ topic_id, name, is_free }) => ({
        id: topic_id,
        name,
        isFree: is_free,
      }));
    }
    return [];
  };

  const handleSelectChapter = (chapterId) => {
    const gId = groupId || (fieldsState.group && fieldsState.group.id);
    dispatch({
      type: GET_TOPICS_FROM_GROUP.REQUEST,
      payload: { groupId: gId, chapterId },
    });
  };

  const getChapters = (id) => {
    if (!groupChapters || !groupChapters.lists || !groupChapters.lists.length) {
      dispatch({
        type: GET_CHAPTERS_FROM_GROUP.REQUEST,
        payload: id,
      });
    }
  };

  const handleTopicStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const handleOnChangeFieldValue = (field, value) => {
    if (field === 'chapter') {
      handleSelectChapter(value.id);
      setFieldState((lastState) => ({
        ...lastState,
        [field]: value,
        topics: [],
      }));
      setSelectedStatus(selectedGroup && selectedGroup.group_info && selectedGroup.group_info.is_premium && selectedGroup.group_info.free_percent !== 100 ? tagOptions[1] : tagOptions[0])
    } else if (field === 'topics') {
      let hasFreeTopic = false;
      setFieldState((lastState) => ({
        ...lastState,
        topics: value,
      }));
      if (!isEmpty(value)) {
        value.forEach((singleTopic) => {
            if (singleTopic.isFree) {
              hasFreeTopic = true;
            }
        });
        if (selectedGroup && selectedGroup.group_info && typeof selectedGroup.group_info.free_percent !== 'undefined') {
          // If the group is not unified only then change the class type based on topic
          setSelectedStatus(hasFreeTopic ? tagOptions[0] : tagOptions[1]);
        }
      }
    } else if (field === 'group') {
      // Teacher get an option to select group when they open this from planner, there selected group is empty hence getting it added there
      dispatch({
        type: GET_TEACHER_GROUP_SELECTED.REQUEST,
        payload: value.id,
      });
      getChapters(value.id);
      setFieldState((lastState) => ({
        ...lastState,
        [field]: value,
        chapter: '',
        topics: [],
      }));
    } else {
      setFieldState((lastState) => ({
        ...lastState,
        [field]: value,
      }));
    }
  };

  useEffect(() => {
    const locale = { locale: localStorage.language.split('_')[0] || 'en' };
    // Allow user to create activity in next 6 month
    const today = new Date();
    const maxDate = new Date();
    maxDate.setMonth(maxDate.getMonth() + 3);
    const picker = new Pikaday({
      field: dateRef.current,
      format: 'YYYY-MM-DD',
      minDate: today,
      maxDate,
      i18n: {
        months: Info.months('long', locale),
        weekdays: getWeekdays(),
        weekdaysShort: getWeekdaysShort(),
      },
      onSelect: () => {},
    });
    if (chapter && chapter.state !== 'completed') {
      handleSelectChapter(chapter.chapter_id);
      setFieldState({
        ...fieldsState,
        chapter: {
          id: chapter.chapter_id,
          name: chapter.name,
          type: chapter.type,
        },
        topics:
          topic && topic.state !== 'completed'
            ? [
                {
                  id: topic.topic_id,
                  name: topic.name,
                },
              ]
            : [],
      });
    }
    if (sessionData) {
      const initFieldsState = {
        title: sessionData.title,
        agenda: sessionData.description,
        group: sessionData.group,
      };
      if (sessionData.curriculum_tags) {
        initFieldsState.chapter = sessionData.curriculum_tags.chapter;
        if (sessionData.curriculum_tags.chapter) {
          handleSelectChapter(sessionData.curriculum_tags.chapter.id);
        }
        initFieldsState.topics = sessionData.curriculum_tags.topics;
      }
      setFieldState(initFieldsState);
      setInputCharCount({
        sessionAgenda: sessionData.description && sessionData.description.length,
        sessionTitle: sessionData.title && sessionData.title.length,
      });
      const date = new Date(sessionData.start_time);
      picker.setDate(date);
      setTime(sessionData.start_time);
      setSelectedStatus(sessionData.is_free ? tagOptions[0] : tagOptions[1]);
    } else {
      picker.setDate(today);
    }
    if (!groupId) {
      dispatch({
        type: GET_TEACHER_GROUPS.REQUEST,
      });
    } else {
      getChapters(groupId);
    }
  }, []);

  const handleOnChangeTime = (value) => {
    setTime(value);
  };

  const createSession = (e) => {
    e.preventDefault();
    if (!dateRef || !dateRef.current || !dateRef.current.value || !time) {
      return false;
    }
    const gId = groupId || fieldsState.group.id;
    if (
      (!fieldsState.topics &&
        (!fieldsState.chapter || ['misc', 'revision'].indexOf(fieldsState.chapter.type) === -1)) ||
      !fieldsState.title ||
      !fieldsState.chapter ||
      !gId
    ) {
      return false;
    }
    const topicIds =
      !fieldsState.chapter || ['misc', 'revision'].indexOf(fieldsState.chapter.type) === -1
        ? fieldsState.topics.map((item) => item.id)
        : [];
    const date = DateTime.fromISO(`${dateRef.current.value}T${DateTime.fromMillis(time).toFormat('HH:mm')}`);

    const payload = {
      title: fieldsState.title,
      class_type: sessionType === 'competition' ? 'competition' : 'group',
      group_id: gId,
      start_time: date.toMillis(),
      description: fieldsState.agenda,
      chapter_id: fieldsState.chapter.id,
      is_free: selectedStatus.id === 1,
      topic_ids: topicIds,
    };
    if (sessionData && sessionData.id) {
      dispatch({
        type: UPDATE_GROUP_TUTORING.REQUEST,
        payload: {
          id: sessionData.id,
          ...payload,
        },
      });
    } else {
      dispatch({
        type: CREATE_GROUP_TUTORING_FOR_TEACHER.REQUEST,
        payload,
      });
    }
    handleMixpanelKafkaActivityEvents({
      type: 'activity_created',
      data: {
        chapter_type: fieldsState.chapter.type || 'NA',
        activity_type: sessionType === 'competition' ? 'competition' : 'session',
        activity_topics: topicIds.length,
      },
    });
    return true;
  };

  const GroupListItem = ({ name, is_premium }) => (
    <Row nowrap align="center" justify="start" gap="sm" style={{ position: 'relative' }}>
      <span className="child">{name}</span>
      {is_premium && 
        <Badge type="default" dir="ltr" className="plus-badge">
          <span>{translationText(noonText, 'groups.plus')}</span>
        </Badge>
      }
    </Row>
  );
  return (
    <div className={`create-session create-session--${sessionType}`}>
      <form className="session-form">
        <div className="field-outer with_counting">
          <Input
            maxLength="60"
            onChange={(event) => setInputCharCount({ ...inputCharCount, sessionTitle: event.target.value.length })}
            onBlur={(event) => handleOnChangeFieldValue('title', event.target.value)}
            defaultValue={fieldsState.title}
            type="text"
            name="title"
            label={translationText(noonText, 'label.sessionTitle')}
            placeholder={translationText(noonText, 'placeholder.sessionTitle')}
          >
            <span>
              {inputCharCount.sessionTitle || 0}
              /60
            </span>
          </Input>
        </div>

        {!groupId && (
          <div className="field-outer">
            <Select
              label={translationText(noonText, 'label.selectGroup')}
              title={translationText(noonText, 'placeholder.selectGroup')}
              selectedItem={
                fieldsState.group
                  ? {
                      ...fieldsState.group,
                      name: sessionData && sessionData.group ? fieldsState.group.title : fieldsState.group.name,
                    }
                  : {}
              }
              list={massageGroupData(groupList)}
              listItemComponent={GroupListItem}
              onSelect={(value) => handleOnChangeFieldValue('group', value)}
            />
          </div>
        )}

        <div className="field-outer">
          <Select
            label={translationText(noonText, 'label.selectChapter')}
            title={translationText(noonText, 'placeholder.selectChapter')}
            selectedItem={fieldsState.chapter}
            list={massageChapterData(groupChapters)}
            className="create-session--select-chapter"
            onSelect={(value) => {
              handleOnChangeFieldValue('chapter', value);
            }}
          />
        </div>

        {get(fieldsState, ['chapter', 'type']) === 'chapter' && (
          <div className="field-outer">
            <MultiSelect
              label={translationText(noonText, 'label.selectTopics')}
              title={translationText(noonText, 'placeholder.selectTopics')}
              selectedItem={fieldsState.topics}
              list={massageTopicData(groupTopics)}
              className="create-session--select-topic"
              onSelect={(selectedItem, selectedArray) => handleOnChangeFieldValue('topics', selectedArray)}
              listItemComponent={StatusListComponent}
            />
          </div>
        )}

        <div className="field-outer with_counting">
          <Input
            maxLength="60"
            onChange={(event) => setInputCharCount({ ...inputCharCount, sessionAgenda: event.target.value.length })}
            onBlur={(event) => handleOnChangeFieldValue('agenda', event.target.value)}
            defaultValue={fieldsState.agenda}
            type="text"
            name="agenda"
            label={translationText(noonText, 'label.sessionAgenda')}
            placeholder={translationText(noonText, 'placeholder.sessionAgenda')}
          >
            <span>
              {inputCharCount.sessionAgenda || 0}
              /60
            </span>
          </Input>
        </div>
        {is_unified_group_teacher && is_unified_group_teacher.isEnabled &&
          <div className="field-outer">
            <Select
              label={translationText(
                noonText,
                `label.${sessionType.toLowerCase()}Type`,
              )}
              selectedItem={selectedStatus}
              onSelect={handleTopicStatusChange}
              list={tagOptions}
              listItemComponent={ListComponent}
              titleComponent={TitleComponent}
              className="status-select"
              disabled={!(selectedGroup && selectedGroup.group_info && selectedGroup.group_info.is_premium && (typeof selectedGroup.group_info.free_percent !== 'undefined'))}
            />
          </div>
        }

        {/* time and date picker */}
        <DatePicker
          onToggle={handleOnChangeTime}
          ms={sessionData ? sessionData.start_time : ''}
          dateRef={dateRef}
          onSelect={(event) => handleOnChangeFieldValue('date', event.target.value)}
          placeholder={translationText(noonText, 'placeholder.selectDate')}
          startTime={translationText(noonText, 'label.startTime')}
        />

        <div className={`submit-button ${isRtl ? 'text-left' : 'text-right'}`}>
          <Button
            type="primary"
            size="lg"
            className="create-session--save"
            onClick={createSession}
            disabled={
              isEmpty(fieldsState.chapter) ||
              (get(fieldsState, ['chapter', 'type']) !== 'misc' &&
                get(fieldsState, ['chapter', 'type']) !== 'revision' &&
                isEmpty(fieldsState.topics)) ||
              createdGroupSessionsForTeacher.loading ||
              !time
            }
            loading={createdGroupSessionsForTeacher.loading}
          >
            {translationText(
              noonText,
              `button.${
                !sessionData || !sessionData.id ? 'save' : 'update'
              }${sessionType[0].toUpperCase()}${sessionType.slice(1)}`,
            )}
          </Button>
        </div>
      </form>
    </div>
  );
}

import { takeEvery, put, call, select, all } from 'redux-saga/effects';
import isEmpty from 'lodash-es/isEmpty';
import map from 'lodash-es/map';
import { noonTextSelector } from 'redux/selectors/translations';
import { groupsApi, curriculumApi } from '../restApi';
import { translationText } from '../../helpers';

import {
  GET_PREVIOUS_GROUP_CURRICULUM,
  GET_NOON_SUGGESTED_CURRICULUM,
  GET_CURRICULUM_CHAPTER_TOPICS,
  PUBLISH_CURRICULUM,
  GET_GROUP_CURRICULUM,
  DRAFT_CURRICULUM,
  GET_CURRICULUM_SIMILAR_GROUPS,
  GET_TEACHER_GROUP_CHAPTERS,
  GET_TEACHER_GROUP_COURSE_PREVIEW,
  GET_COUNTRIES,
  GET_GRADES,
  GET_SUBJECTS,
  GET_DEGREES,
  GET_CURRICULUM,
  GET_CHAPTERS,
  GET_TOPICS,
  GET_TESTS,
  GET_SKILLS,
  GET_MAJORS,
  GET_COURSES,
  GET_SECTIONS,
  GET_CURRICULUM_SUGGESTED_CHAPTER,
  SET_CURRICULUM_TEMPLATE,
  GET_BOARDS,
} from '../constants';
import { CURRICULUM_TEMPLATE } from '../../constants';

import { TOAST_TYPE, addToast } from '../../components/Toast';

const getPreviousGroupCurriculumAPI = (groupId) => groupsApi.get(`group_curriculum/${groupId}`);
const getNoonSuggestedCurriculumAPI = (groupId) => groupsApi.get(`suggested_curriculum/${groupId}`);
const publishCurriculumCreateAPI = ({ data, groupId }) => groupsApi.post(`curriculum/${groupId}`, data);
const publishCurriculumUpdateAPI = ({ data, groupId }) => groupsApi.put(`curriculum/${groupId}`, data);
const draftCurriculumAPI = ({ data, groupId }) => groupsApi.post(`curriculum/draft/${groupId}`, data);
const getDraftCurriculumAPI = (groupId) => groupsApi.get(`curriculum/draft/${groupId}`);
const getGroupGroupCurriculumAPI = (groupId) => groupsApi.get(`curriculum/${groupId}`);
// const getCurriculumChapterTopicsAPI = ({ hierarchy, curriculumType }) => curriculumApi.get('teacher/tags/topics', { hierarchy, curriculum_type: curriculumType });
const getCurriculumChapterTopicsAPI = ({ queryParams, curriculumType }) => curriculumApi.get('teacher/topics', { ...queryParams, curriculum_type: curriculumType });
const getCurriculumSimilarGroupsAPI = (groupId) => groupsApi.get(`similar_groups/${groupId}`);
const getCurriculumSuggestedChapterAPI = ({ curriculumType, queryParams }) => curriculumApi.get('teacher/chapters', { curriculum_type: curriculumType, ...queryParams });

const getCountriesApi = (data) => curriculumApi.get('teacher/countries', data);
const getDegreesApi = (data) => curriculumApi.get('teacher/degrees', data);
const getSubjectsApi = (data) => curriculumApi.get('teacher/subjects', data);
const getCurriculumApi = (data) => curriculumApi.get('teacher/curriculum', data);
const getChaptersApi = (data) => curriculumApi.get('teacher/chapters', data);
const getTopicsApi = (data) => curriculumApi.get('teacher/topics', data);
const getGradesApi = (data) => curriculumApi.get('teacher/grades', data);
const getBoardsApi = (data) => curriculumApi.get('teacher/boards', data);
const getTestsApi = (data) => curriculumApi.get('teacher/tests', data);
const getSkillsApi = (data) => curriculumApi.get('teacher/skills', data);
const getMajorsApi = (data) => curriculumApi.get('teacher/majors', data);
const getCoursesApi = (data) => curriculumApi.get('teacher/courses', data);
const getSectionsApi = (data) => curriculumApi.get('teacher/sections', data);

function* getCurriculumSimilarGroups({ payload }) {
  try {
    const response = yield call(getCurriculumSimilarGroupsAPI, payload);
    console.log('similar group', response);
    if (response.ok) {
      yield put({
        type: GET_CURRICULUM_SIMILAR_GROUPS.SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_CURRICULUM_SIMILAR_GROUPS.FAILURE,
        payload: 'NETWORK_ERROR',
      });
    }

  } catch (error) {
    yield put({
      type: GET_CURRICULUM_SIMILAR_GROUPS.FAILURE,
      payload: error,
    });
  }
}

function* getCurriculumChapterTopics({ payload }) {
  try {
    const response = yield call(getCurriculumChapterTopicsAPI, payload);
    if (response.ok) {
      console.log('response topics', response);
      yield put({
        type: GET_CURRICULUM_CHAPTER_TOPICS.SUCCESS,
        payload: {
          [payload.queryParams.chapter_id]: response.data.data,
        },
      });
    } else {
      yield put({
        type: GET_CURRICULUM_CHAPTER_TOPICS.FAILURE,
        payload: 'NETWORK_ERROR',
      });
    }
  } catch (err) {
    yield put({
      type: GET_CURRICULUM_CHAPTER_TOPICS.FAILURE,
      payload: err,
    });
  }
}

function* getCurriculumSuggestedChapter({ payload }) {
  try {
    const response = yield call(getCurriculumSuggestedChapterAPI, payload);
    if (response.ok) {
      yield put({
        type: GET_CURRICULUM_SUGGESTED_CHAPTER.SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: GET_CURRICULUM_SUGGESTED_CHAPTER.FAILURE,
        payload: 'NETWORK_ERROR',
      });
    }
  } catch (err) {
    yield put({
      type: GET_CURRICULUM_SUGGESTED_CHAPTER.FAILURE,
      payload: err,
    });
  }
}

function* getGroupCurriculum({ payload: { groupId, getSuggestedCurriculum = false, isDraftOrEmpty = false } }) {
  try {
    yield put({
      type: GET_GROUP_CURRICULUM.LOADING,
    });
    let response = {};
    let curriculumExist = false;
    if (!isDraftOrEmpty) {
      response = yield call(getGroupGroupCurriculumAPI, groupId);
      curriculumExist = response.data.content.length > 0 || response.data.has_draft;
    }
    if (isDraftOrEmpty || response.data.has_draft) {
      const responseDraft = yield call(getDraftCurriculumAPI, groupId);
      response = { ...responseDraft, data: { ...response.data, content: responseDraft.data, has_draft: true } };
      curriculumExist = responseDraft.data.length > 0;
    }
    if (response.ok) {
      const filterData = response.data.content.filter((chapter) => chapter.type !== 'misc');
      yield put({
        type: GET_GROUP_CURRICULUM.SUCCESS,
        payload: { content: filterData, curriculumExist, has_draft: response.data.has_draft },
      });
      if (!curriculumExist && getSuggestedCurriculum) {
        const noonText = yield select(noonTextSelector);
        yield put({
          type: SET_CURRICULUM_TEMPLATE,
          payload: {
            name: translationText(noonText, 'groupCurriculum.noonSuggested'),
            id: 1,
          },
        });
        yield put({
          type: GET_NOON_SUGGESTED_CURRICULUM.REQUEST,
          payload: groupId,
        });
      }
    } else {
      yield put({
        type: GET_GROUP_CURRICULUM.FAILURE,
        payload: 'NETWORK_ERROR',
      });
    }

  } catch (err) {
    yield put({
      type: GET_GROUP_CURRICULUM.FAILURE,
      payload: err,
    });
  }
}

function* draftCurriculum({ payload }) {
  try {
    const response = yield call(draftCurriculumAPI, payload);
    if (response.ok) {
      const noonText = yield select(noonTextSelector);
      addToast(translationText(noonText, 'groupCurriculum.progressSaved'), TOAST_TYPE.SUCCESS);
      yield put({
        type: DRAFT_CURRICULUM.SUCCESS,
      });
      // yield put({
      //   type: GET_GROUP_CURRICULUM.REQUEST,
      //   payload: { groupId: payload.groupId, isDraftOrEmpty: true },
      // });
      yield put({
        type: GET_TEACHER_GROUP_CHAPTERS.REQUEST,
        payload: payload.groupId,
      });
      yield put({
        type: GET_TEACHER_GROUP_COURSE_PREVIEW.REQUEST,
        payload: payload.groupId,
      });
    } else {
      yield put({
        type: DRAFT_CURRICULUM.FAILURE,
        payload: 'NETWORK_ERROR',
      });
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
    }
  } catch (err) {
    yield put({
      type: DRAFT_CURRICULUM.FAILURE,
      payload: err,
    });
    addToast(`${err}`, TOAST_TYPE.ERROR);
  }
}

function* publishCurriculum({ payload }) {
  try {
    const response = payload.update ? yield call(publishCurriculumUpdateAPI, payload) : yield call(publishCurriculumCreateAPI, payload);
    if (response.ok) {
      const noonText = yield select(noonTextSelector);
      yield put({
        type: PUBLISH_CURRICULUM.SUCCESS,
      });
      addToast(translationText(noonText, 'groupCurriculum.publishedSuccess'), TOAST_TYPE.SUCCESS);
      // yield put({
      //   type: GET_GROUP_CURRICULUM.REQUEST,
      //   payload: { groupId: payload.groupId, isDraftOrEmpty: false },
      // });
      yield put({
        type: GET_TEACHER_GROUP_CHAPTERS.REQUEST,
        payload: payload.groupId,
      });
      yield put({
        type: GET_TEACHER_GROUP_COURSE_PREVIEW.REQUEST,
        payload: payload.groupId,
      });
    } else {
      yield put({
        type: PUBLISH_CURRICULUM.FAILURE,
        payload: 'NETWORK_ERROR',
      });
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
    }
  } catch (err) {
    yield put({
      type: PUBLISH_CURRICULUM.FAILURE,
      payload: err,
    });
    addToast(`${err}`, TOAST_TYPE.ERROR);
  }
}

function* getPreviousGroupCurriculum({ payload: groupId }) {
  try {
    const response = yield call(getPreviousGroupCurriculumAPI, groupId);
    if (response.ok) {
      yield put({
        type: GET_PREVIOUS_GROUP_CURRICULUM.SUCCESS,
        payload: response.data.content,
      });
    } else {
      yield put({
        type: GET_PREVIOUS_GROUP_CURRICULUM.FAILURE,
        payload: 'NETWORK_ERROR',
      });
    }
  } catch (err) {
    yield put({ type: GET_PREVIOUS_GROUP_CURRICULUM.FAILURE, payload: err });
  }
}

function* getNoonSuggestedCurriculum({ payload: groupId }) {
  try {
    yield put({
      type: GET_NOON_SUGGESTED_CURRICULUM.LOADING,
    });
    const response = yield call(getNoonSuggestedCurriculumAPI, groupId);
    if (response.ok) {
      const noonText = yield select(noonTextSelector);
      // Update chapter and topics name_header if it's empty, in suggested curriculum
      const chapters = map(response.data.content, (chapter, index) => {
        const topics = map(chapter.topic_info, (topic, index) => {
          if (!topic.name_header) {
            return { ...topic, name_header: translationText(noonText, `groups.topic${index + 1}`), name_header_key: `topic${index + 1}` };
          }

          return topic;
        });

        if (!chapter.name_header) {
          return { ...chapter, name_header: translationText(noonText, `groups.chapter${index + 1}`), topic_info: topics, name_header_key: `chapter${index + 1}` };
        }
        return { ...chapter, topic_info: topics };
      });
      yield put({
        type: GET_NOON_SUGGESTED_CURRICULUM.SUCCESS,
        payload: chapters,
      });
    } else {
      yield put({
        type: GET_NOON_SUGGESTED_CURRICULUM.FAILURE,
        payload: 'NETWORK_ERROR',
      });
    }
  } catch (err) {
    yield put({ type: GET_NOON_SUGGESTED_CURRICULUM.FAILURE, payload: err });
  }
}

function* getCountries({ payload }) {
  try {
    yield put({ type: GET_COUNTRIES.LOADING });
    const response = yield call(getCountriesApi, payload);
    if (response.data.status === 'OK') yield put({ type: GET_COUNTRIES.SUCCESS, payload: { data: isEmpty(response.data.data) ? [] : response.data.data, meta: response.data.meta } });
    else yield put({ type: GET_COUNTRIES.FAILURE, payload: response.data.message });
  } catch (err) { yield put({ type: GET_COUNTRIES.FAILURE, payload: err }); }
}
function* getGrades({ payload }) {
  try {
    yield put({ type: GET_GRADES.LOADING });
    const response = yield call(getGradesApi, payload);
    if (response.data.status === 'OK') yield put({ type: GET_GRADES.SUCCESS, payload: { data: isEmpty(response.data.data) ? [] : response.data.data, meta: response.data.meta, lastId: payload.country_id } });
    else yield put({ type: GET_GRADES.FAILURE, payload: response.data.message });
  } catch (err) { yield put({ type: GET_GRADES.FAILURE, payload: err }); }
}
function* getBoards({ payload }) {
  try {
    yield put({ type: GET_BOARDS.LOADING });
    const response = yield call(getBoardsApi, payload);
    if (response.data.status === 'OK') yield put({ type: GET_BOARDS.SUCCESS, payload: { data: isEmpty(response.data.data) ? [] : response.data.data, meta: response.data.meta, lastId: payload.country_id } });
    else yield put({ type: GET_BOARDS.FAILURE, payload: response.data.message });
  } catch (err) { yield put({ type: GET_BOARDS.FAILURE, payload: err }); }
}
function* getDegrees({ payload }) {
  try {
    yield put({ type: GET_DEGREES.LOADING });
    const response = yield call(getDegreesApi, payload);
    if (response.data.status === 'OK') yield put({ type: GET_DEGREES.SUCCESS, payload: { data: isEmpty(response.data.data) ? [] : response.data.data, meta: response.data.meta, lastId: payload.country_id } });
    else yield put({ type: GET_DEGREES.FAILURE, payload: response.data.message });
  } catch (err) { yield put({ type: GET_DEGREES.FAILURE, payload: err }); }
}
function* getSubjects({ payload }) {
  try {
    yield put({ type: GET_SUBJECTS.LOADING });
    const response = yield call(getSubjectsApi, payload);
    if (response.data.status === 'OK') yield put({ type: GET_SUBJECTS.SUCCESS, payload: { data: isEmpty(response.data.data) ? [] : response.data.data, meta: response.data.meta, lastId: payload.grade_id } });
    else yield put({ type: GET_SUBJECTS.FAILURE, payload: response.data.message });
  } catch (err) { yield put({ type: GET_SUBJECTS.FAILURE, payload: err }); }
}
function* getCurriculum({ payload }) {
  try {
    yield put({ type: GET_CURRICULUM.LOADING });
    const response = yield call(getCurriculumApi, payload);
    if (response.data.status === 'OK') yield put({ type: GET_CURRICULUM.SUCCESS, payload: { data: isEmpty(response.data.data) ? [] : response.data.data, meta: response.data.meta, lastId: payload.subject_id } });
    else yield put({ type: GET_CURRICULUM.FAILURE, payload: response.data.message });
  } catch (err) { yield put({ type: GET_CURRICULUM.FAILURE, payload: err }); }
}
function* getChapters({ payload }) {
  try {
    yield put({ type: GET_CHAPTERS.LOADING });
    const response = yield call(getChaptersApi, payload);
    if (response.data.status === 'OK') yield put({ type: GET_CHAPTERS.SUCCESS, payload: { data: isEmpty(response.data.data) ? [] : response.data.data, meta: response.data.meta, lastId: payload.curriculum_id || payload.section_id } });
    else yield put({ type: GET_CHAPTERS.FAILURE, payload: response.data.message });
  } catch (err) { yield put({ type: GET_CHAPTERS.FAILURE, payload: err }); }
}
function* getTopics({ payload }) {
  try {
    yield put({ type: GET_TOPICS.LOADING });
    const response = yield call(getTopicsApi, payload);
    if (response.data.status === 'OK') yield put({ type: GET_TOPICS.SUCCESS, payload: { data: isEmpty(response.data.data) ? [] : response.data.data, meta: response.data.meta, lastId: payload.chapter_id } });
    else yield put({ type: GET_TOPICS.FAILURE, payload: response.data.message });
  } catch (err) { yield put({ type: GET_TOPICS.FAILURE, payload: err }); }
}
function* getTests({ payload }) {
  try {
    yield put({ type: GET_TESTS.LOADING });
    const response = yield call(getTestsApi, payload);
    if (response.data.status === 'OK') yield put({ type: GET_TESTS.SUCCESS, payload: { data: isEmpty(response.data.data) ? [] : response.data.data, meta: response.data.meta, lastId: payload.country_id } });
    else yield put({ type: GET_TESTS.FAILURE, payload: response.data.message });
  } catch (err) { yield put({ type: GET_TESTS.FAILURE, payload: err }); }
}
function* getSkills({ payload }) {
  try {
    yield put({ type: GET_SKILLS.LOADING });
    const response = yield call(getSkillsApi, payload);
    if (response.data.status === 'OK') yield put({ type: GET_SKILLS.SUCCESS, payload: { data: isEmpty(response.data.data) ? [] : response.data.data, meta: response.data.meta, lastId: payload.country_id } });
    else yield put({ type: GET_SKILLS.FAILURE, payload: response.data.message });
  } catch (err) { yield put({ type: GET_SKILLS.FAILURE, payload: err }); }
}
function* getMajors({ payload }) {
  try {
    yield put({ type: GET_MAJORS.LOADING });
    const response = yield call(getMajorsApi, payload);
    if (response.data.status === 'OK') yield put({ type: GET_MAJORS.SUCCESS, payload: { data: isEmpty(response.data.data) ? [] : response.data.data, meta: response.data.meta, lastId: payload.degree_id } });
    else yield put({ type: GET_MAJORS.FAILURE, payload: response.data.message });
  } catch (err) { yield put({ type: GET_MAJORS.FAILURE, payload: err }); }
}
function* getCourses({ payload }) {
  try {
    yield put({ type: GET_COURSES.LOADING });
    const response = yield call(getCoursesApi, payload);
    if (response.data.status === 'OK') yield put({ type: GET_COURSES.SUCCESS, payload: { data: isEmpty(response.data.data) ? [] : response.data.data, meta: response.data.meta, lastId: payload.major_id } });
    else yield put({ type: GET_COURSES.FAILURE, payload: response.data.message });
  } catch (err) { yield put({ type: GET_COURSES.FAILURE, payload: err }); }
}
function* getSections({ payload }) {
  try {
    yield put({ type: GET_SECTIONS.LOADING });
    const response = yield call(getSectionsApi, payload);
    if (response.data.status === 'OK') yield put({ type: GET_SECTIONS.SUCCESS, payload: { data: isEmpty(response.data.data) ? [] : response.data.data, meta: response.data.meta, lastId: payload.course_id || payload.test_id || payload.skill_id } });
    else yield put({ type: GET_SECTIONS.FAILURE, payload: response.data.message });
  } catch (err) { yield put({ type: GET_SECTIONS.FAILURE, payload: err }); }
}

export function* curriculumSaga() {
  yield all([
    takeEvery(GET_CURRICULUM_CHAPTER_TOPICS.REQUEST, getCurriculumChapterTopics),
    takeEvery(GET_GROUP_CURRICULUM.REQUEST, getGroupCurriculum),
    takeEvery(DRAFT_CURRICULUM.REQUEST, draftCurriculum),
    takeEvery(PUBLISH_CURRICULUM.REQUEST, publishCurriculum),
    takeEvery(GET_PREVIOUS_GROUP_CURRICULUM.REQUEST, getPreviousGroupCurriculum),
    takeEvery(GET_NOON_SUGGESTED_CURRICULUM.REQUEST, getNoonSuggestedCurriculum),
    takeEvery(GET_CURRICULUM_SIMILAR_GROUPS.REQUEST, getCurriculumSimilarGroups),

    takeEvery(GET_COUNTRIES.REQUEST, getCountries),
    takeEvery(GET_GRADES.REQUEST, getGrades),
    takeEvery(GET_DEGREES.REQUEST, getDegrees),
    takeEvery(GET_BOARDS.REQUEST, getBoards),
    takeEvery(GET_SUBJECTS.REQUEST, getSubjects),
    takeEvery(GET_CURRICULUM.REQUEST, getCurriculum),
    takeEvery(GET_CHAPTERS.REQUEST, getChapters),
    takeEvery(GET_TOPICS.REQUEST, getTopics),
    takeEvery(GET_TESTS.REQUEST, getTests),
    takeEvery(GET_SKILLS.REQUEST, getSkills),
    takeEvery(GET_MAJORS.REQUEST, getMajors),
    takeEvery(GET_COURSES.REQUEST, getCourses),
    takeEvery(GET_SECTIONS.REQUEST, getSections),
    takeEvery(GET_CURRICULUM_SUGGESTED_CHAPTER.REQUEST, getCurriculumSuggestedChapter),
  ]);
}

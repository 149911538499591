import {
  JOIN_CLASS,
  GET_CLASS_REPLAY,
  CONFIG_REASON,
  UPCOMING_TEACHER_SESSION,
  MY_PREVIOUS_GROUP_TUTORING,
  CREATE_GROUP_TUTORING_FOR_TEACHER,
  UPDATE_GROUP_TUTORING,
  GROUP_TUTORING_UNIQUE_VALUES,
  GROUP_TUTORING_REVIEWS,
  START_GROUP_TUTORING,
  VOTE_TEACHER,
  DELETE_SESSION,
  CLEAR_WEBRTC_DETAILS,
  START_ARCHIVE,
  TOGGLE_PLAYBACK,
  SET_SESSION_CONTAINER_STATE_TO_PREVIOUS,
  TOKBOX_SESSION,
  CLEAR_TUTORING_DETAILS,
} from '../constants';

const initialState = {
  sessionDetails: {},
  voteTeacher: {},
  myScheduledSession: {
    page: 1,
    list: [],
    isLoading: false,
  },
  sessionDataForReplay: {},
  teacherGroupSessions: [],
  upcomingTeacherSession: { response: [], isLoading: false, page: 1 },
  createClass: {},
  toggleTeacher: {},
  myPreviousGroupTutoring: { response: [], isLoading: false, page: 1 },
  createdGroupSessions: {},
  createdGroupSessionsForTeacher: {},
  updatedGroupSessions: {},
  reasons: [],
  groupTutoringUniqueValues: { isLoading: 0, data: {} },
  groupTutoringReviews: { isLoading: 0, data: [], page: 1 },
  error: {},
  loading: false,
  webrtc_details: {
    webrtc_api: '',
    webrtc_session_id: '',
    webrtc_token: '',
  },
  GroupTutoringDetails: {},
  deleteSession: {},
  archiveStarted: false,
  setSessionContainerStateToPrevious: false,
  tokboxSession: {},
};

export default function tutoring(state = initialState, action) {
  switch (action.type) {
    case CLEAR_TUTORING_DETAILS:
      return {
        ...state,
        createdGroupSessions: {},
        createdGroupSessionsForTeacher: {},
        updatedGroupSessions: {},
        deleteSession: {},
        toggleTeacher: {},
      };
    case JOIN_CLASS.REQUEST:
      return {
        ...state,
        webrtc_details: {
          webrtc_api: '',
          webrtc_session_id: '',
          webrtc_token: '',
        },
        sessionDetails: {},
      };
    case JOIN_CLASS.SUCCESS:
      return {
        ...state,
        webrtc_details: action.payload.webrtc_details && action.payload.webrtc_details.webrtc_api ? action.payload.webrtc_details : state.webrtc_details,
        sessionDetails: action.payload,
      };
    case JOIN_CLASS.FAILURE:
      return {
        ...state,
        error: { sessionDetails: action.payload },
      };
    case GROUP_TUTORING_UNIQUE_VALUES.REQUEST:
      return {
        ...state,
        groupTutoringUniqueValues: {
          ...state.groupTutoringUniqueValues,
          isLoading: action.payload.sessionId,
        },
      };
    case START_ARCHIVE.SUCCESS:
      localStorage.setItem('archiveStarted', action.payload.id);
      return {
        ...state,
        archiveStarted: true,
      };
    case START_ARCHIVE.FAILURE:
      return {
        ...state,
        error: { archiveStarted: action.payload },
      };
    case GROUP_TUTORING_UNIQUE_VALUES.SUCCESS:
      return {
        ...state,
        groupTutoringUniqueValues: {
          ...state.groupTutoringUniqueValues,
          data: action.payload,
          isLoading: 0,
        },
      };
    case GROUP_TUTORING_UNIQUE_VALUES.FAILURE:
      return {
        ...state,
        groupTutoringUniqueValues: {
          ...state.groupTutoringUniqueValues,
          isLoading: 0,
        },
        error: { groupTutoringUniqueValues: action.payload },
      };
    case GROUP_TUTORING_REVIEWS.REQUEST:
      return {
        ...state,
        groupTutoringReviews: {
          ...state.groupTutoringReviews,
          isLoading: action.payload.session_id,
        },
      };
    case GROUP_TUTORING_REVIEWS.SUCCESS:
      return {
        ...state,
        groupTutoringReviews: {
          ...state.groupTutoringReviews,
          data: action.payload.page > 1 ? { users: [...state.groupTutoringReviews.data.users, ...action.payload.list.users] } : action.payload.list,
          page: action.payload.page,
          isLoading: 0,
        },
      };
    case GROUP_TUTORING_REVIEWS.FAILURE:
      return {
        ...state,
        groupTutoringReviews: {
          ...state.groupTutoringReviews,
          isLoading: 0,
        },
        error: { groupTutoringReviews: action.payload },
      };
    case GET_CLASS_REPLAY.SUCCESS:
      return {
        ...state,
        sessionDataForReplay: action.payload,
      };
    case GET_CLASS_REPLAY.FAILURE:
      return {
        ...state,
        error: { getClassReplay: action.payload },
      };
    case CONFIG_REASON.SUCCESS:
      return {
        ...state,
        reasons: action.payload,
      };
    case CONFIG_REASON.FAILURE:
      return {
        ...state,
        error: {
          reasons: action.payload,
        },
      };
    case TOKBOX_SESSION.SUCCESS:
      return {
        ...state,
        tokboxSession: action.payload,
      };
    case TOKBOX_SESSION.FAILURE:
      return {
        ...state,
        error: {
          tokboxSession: action.payload,
        },
      };
    case CREATE_GROUP_TUTORING_FOR_TEACHER.REQUEST:
      return {
        ...state,
        createdGroupSessionsForTeacher: {
          loading: true,
          success: false,
        },
      };
    case CREATE_GROUP_TUTORING_FOR_TEACHER.SUCCESS:
      return {
        ...state,
        createdGroupSessionsForTeacher: {
          ...action.payload,
          loading: false,
          success: true,
        },
      };
    case CREATE_GROUP_TUTORING_FOR_TEACHER.FAILURE:
      return {
        ...state,
        error: { createdGroupSessionsForTeacher: action.payload },
        createdGroupSessionsForTeacher: {
          loading: false,
          success: false,
        },
      };
    case UPDATE_GROUP_TUTORING.REQUEST:
      return {
        ...state,
        updatedGroupSessions: {
          loading: true,
          success: false,
        },
      };
    case UPDATE_GROUP_TUTORING.SUCCESS:
      return {
        ...state,
        updatedGroupSessions: {
          ...action.payload,
          loading: false,
          success: true,
        },
      };
    case UPDATE_GROUP_TUTORING.FAILURE:
      return {
        ...state,
        updatedGroupSessions: {
          loading: false,
          success: false,
        },
        error: { updatedGroupSessions: action.payload },
      };
    case DELETE_SESSION.REQUEST:
      return {
        ...state,
        deleteSession: {
          loading: true,
          success: false,
        },
      };
    case DELETE_SESSION.SUCCESS:
      return {
        ...state,
        deleteSession: {
          ...action.payload,
          loading: false,
          success: true,
        },
      };
    case DELETE_SESSION.FAILURE:
      return {
        ...state,
        deleteSession: {
          loading: false,
          success: false,
        },
        error: { deleteSession: action.payload },
      };
    case MY_PREVIOUS_GROUP_TUTORING.REQUEST:
      return {
        ...state,
        myPreviousGroupTutoring: {
          ...state.myPreviousGroupTutoring,
          isLoading: true,
        },
      };
    case MY_PREVIOUS_GROUP_TUTORING.SUCCESS:
      if (action.payload.type === 'toggle') {
        return {
          ...state,
          myPreviousGroupTutoring: {
            response: action.payload.response,
            page: action.payload.page,
            isLoading: false,
          },
        };
      }
      return {
        ...state,
        myPreviousGroupTutoring: {
          response: action.payload.page > 1 ? [...state.myPreviousGroupTutoring.response, ...action.payload.list] : action.payload.list,
          page: action.payload.list.length >= action.payload.limit ? action.payload.page + 1 : -1,
          isLoading: false,
        },
      };

    case MY_PREVIOUS_GROUP_TUTORING.FAILURE:
      return {
        ...state,
        error: { myPreviousGroupTutoring: action.payload },
        myPreviousGroupTutoring: {
          ...state.myPreviousGroupTutoring,
          isLoading: false,
        },
      };

    case TOGGLE_PLAYBACK.REQUEST:
      return {
        ...state,
        toggleTeacher: {
          loading: true,
          success: false,
        },
      };
    case TOGGLE_PLAYBACK.SUCCESS:
      return {
        ...state,
        toggleTeacher: {
          loading: false,
          success: true,
        },
      };
    case TOGGLE_PLAYBACK.FAILURE:
      return {
        ...state,
        toggleTeacher: {
          loading: false,
          success: false,
        },
        error: {
          toggleTeacher: action.payload,
        },
      };
    case UPCOMING_TEACHER_SESSION.REQUEST:
      return {
        ...state,
        upcomingTeacherSession: {
          ...state.upcomingTeacherSession,
          isLoading: true,
        },
      };
    case UPCOMING_TEACHER_SESSION.SUCCESS:
      return {
        ...state,
        upcomingTeacherSession: {
          response: action.payload.page > 1 && !action.payload.notPageUpdate ? [...state.upcomingTeacherSession.response, ...action.payload.list] : action.payload.list,
          page: !action.payload.notPageUpdate ? (action.payload.list.length >= action.payload.limit ? action.payload.page + 1 : -1) : action.payload.page,
          isLoading: false,
          meta: action.payload.meta,
        },
      };
    case UPCOMING_TEACHER_SESSION.FAILURE:
      return {
        ...state,
        error: { upcomingTeacherSession: action.payload },
        upcomingTeacherSession: {
          ...state.upcomingTeacherSession,
          isLoading: false,
        },
      };
    case START_GROUP_TUTORING.SUCCESS:
      return {
        ...state,
        GroupTutoringDetails: action.payload,
        webrtc_details: {
          webrtc_api: action.payload.webrtc_api,
          webrtc_session_id: action.payload.webrtc_provider_id,
          webrtc_token: action.payload.token,
          rtm_token: action.payload.rtm_token,
        },
        sessionDetails: {
          ...state.sessionDetails,
          state: 'started',
          webrtc_type: action.payload.webrtc_provider_type,
          start_time: action.payload.start_time,
        },
      };
    case START_GROUP_TUTORING.FAILURE:
      return {
        ...state,
        error: { GroupTutoringDetails: action.payload },
      };
    case VOTE_TEACHER.SUCCESS:
      return {
        ...state,
        voteTeacher: action.payload,
      };
    case VOTE_TEACHER.FAILURE:
      return {
        ...state,
        error: { voteTeacher: action.payload },
      };

    case CLEAR_WEBRTC_DETAILS:
      return {
        ...state,
        webrtc_details: {
          webrtc_api: '',
          webrtc_session_id: '',
          webrtc_token: '',
        },
      };
    case SET_SESSION_CONTAINER_STATE_TO_PREVIOUS:
      return {
        ...state,
        setSessionContainerStateToPrevious: action.payload,
      };
    default:
      if (action.type.indexOf('_REQUEST') !== -1) {
        return {
          ...state,
          error: {},
        };
      }
      return state;
  }
}

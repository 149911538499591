import React from 'react';
import TeamMemberListItem from './TeamMemberListItem';

const TeamMembersList = ({ members, teamname }) => (
  <div className="team-leader-board__memberslist">
    <h1>{teamname}</h1>
    {members.map(item => (
      <TeamMemberListItem member={item} />
    ))}
  </div>
);

export default TeamMembersList;

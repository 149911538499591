import React, { useState } from 'react';
import { Button, Row, Input } from '@noon/atom';
import { IconArrowDown } from '@noon/quark';

export default function SessionsSection(prop) {
  const { countries } = this.props;
  const [showCountryDropdown, toggleCountryDropdown] = useState(false);

  const handleDropdown = () => {
    const { organisationName } = this.props;
    if (organisationName && localStorage.updatedLocale.indexOf('sa') !== -1) {
      return;
    }
    this.setState((prevState) => ({ showCountryDropdown: !prevState.showCountryDropdown }));
  };

  const onChange = (option) => {
    const { changeCountryId } = this.props;
    this.setState({ currentlySelectedCountry: option });
    toggleCountryDropdown(false);
    changeCountryId(option.id);
    const countries = JSON.parse(localStorage.getItem('country'));
    countries.selectedCountry = option;
    localStorage.country = JSON.stringify({ countryList: countries.countryList, selectedCountry: option });
    operationsForLocaleChange(countries.selectedCountry.locale);
  };

  const popoverProps = {
    isOpen: showCountryDropdown,
    preferPlace: 'below',
    onOuterAction: () => this.handleDropdown(),
    body: (
      <ul className="noon-list">
        {countries.countryList
          .filter((country) => !country.meta)
          .map((country) => (
            <li className="country-dropdown__item" key={country.id} onClick={() => onChange(country)}>
              <span>{country.name}</span>
              <img src={country.flag} height="22px" alt={country.name} />
            </li>
          ))}
      </ul>
    ),
  };

  return (
    <div className="section-outer-container login-outer">
      <div className="section landing-login">
        <Row className="text landing-login__heading">
          <h1 className="title-heading">Live tutoring sessions</h1>
          <p className="title-subheading">With friends</p>
        </Row>
        <Row className="landing-login__content">
          <form noValidate onSubmit={this.submitForm}>
            <Input
              autoFocus
              outlined
              type="tel"
              name="phone"
              className="phone"
              label={translationText(noonText, 'form.phone')}
              placeholder="xxxxxxxxx"
              maxLength={max_value}
              onChange={this.changePhoneNumber}
              error={!!phoneNumberError}
              errorMessage={phoneNumberError}
            >
              <Popover {...popoverProps}>
                <Row
                  nowrap
                  align="center"
                  className={classNames('country-selection', {
                    disabled: localStorage.updatedLocale.indexOf('sa') !== -1 && organisationName,
                  })}
                  onClick={this.handleDropdown}
                >
                  <span dir="ltr" className="country-code">
                    {`${currentlySelectedCountry.calling_code}`}
                  </span>

                  <img src={currentlySelectedCountry.flag} alt="flag" height="22px" />
                  <IconArrowDown rotate={showCountryDropdown ? 180 : 0} fill="black" />
                </Row>
              </Popover>
            </Input>
            <Row align="center" justify="center" className="submit-button-block mt-4">
              <Button
                block
                htmlType="submit"
                loading={loading}
                type="green"
                disabled={!temporaryTokenData && !tokenData}
                size="xl"
                value={translationText(noonText, 'onboarding.continue')}
              />
            </Row>
          </form>
        </Row>
      </div>
    </div>
  );
}

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconCanvasDiscard, IconClock, IconCompetition, IconCorrectCircle, IconMembersO, IconRating1Active, IconRating2Active, IconRating3Active, IconRating4Active, IconRating5Active, IconSession, IconStar } from '@noon/quark';
import { ContentOnTop, NoonDate, Time, Row, Column, Vr, Hr, InfiniteScroll, Card } from '@noon/atom';
import isEmpty from 'lodash-es/isEmpty';
import { COLORS, sessionReportStudentsBatchLimit } from '../../constants';
import {
  GROUP_TUTORING_REVIEWS,
  GROUP_TUTORING_UNIQUE_VALUES,
  SET_SESSION_CONTAINER_STATE_TO_PREVIOUS,
} from '../../redux/constants';
import { translationText } from '../../helpers';
import ErrorBoundry from '../ErrorBoundry';

export default function ReportSession(prop) {
  const dispatch = useDispatch();
  const { onContentClose, session, classType } = prop;
  const { title: session_name, id: sessionId } = session || {};
  const { groupTutoringReviews: tutoringReviews, groupTutoringUniqueValues: tutoringUniqueValues } = useSelector(
    (state) => state.toJS().tutoring,
  );
  const { noonText } = useSelector((state) => state.toJS().translation);

  useEffect(() => {
    dispatch({
      type: GROUP_TUTORING_REVIEWS.REQUEST,
      payload: { session_id: sessionId, limit: sessionReportStudentsBatchLimit, page: 1 },
    });
    dispatch({ type: GROUP_TUTORING_UNIQUE_VALUES.REQUEST, payload: { sessionId } });
  }, []);

  const getMoreReports = () => {
    if (!tutoringReviews.isLoading) {
      dispatch({
        type: GROUP_TUTORING_REVIEWS.REQUEST,
        payload: { session_id: sessionId, limit: sessionReportStudentsBatchLimit, page: tutoringReviews.page + 1 },
      });
    }
  };

  const cleanAndClose = () => {
    dispatch({ type: GROUP_TUTORING_REVIEWS.SUCCESS, payload: { page: 1, list: {}, session_id: sessionId } });
    dispatch({ type: GROUP_TUTORING_UNIQUE_VALUES.SUCCESS, payload: {} });
    dispatch({ type: SET_SESSION_CONTAINER_STATE_TO_PREVIOUS, payload: true });
    onContentClose();
  };

  return (
    <ContentOnTop onClose={cleanAndClose}>
      <div className="create-activity">
        <div className="create-activity_header">
          <Row className="strip" align="center" justify="space-between">
            <Row align="center" gap="sm">
              {classType === 'competition' ? <IconCompetition className="child" color={COLORS.orange.base} /> : <IconSession className="child" color={COLORS.session.base} />}
              <h3>{classType === 'competition' ? translationText(noonText, 'label.reportCompetition') : translationText(noonText, 'label.reportSession')}</h3>
            </Row>
            <IconCanvasDiscard
              onClick={cleanAndClose}
              height="16px"
              width="16px"
              fill="#222c3c"
              stroke="#222c3c"
              rotate="-180"
            />
          </Row>
        </div>
        <div className="create-activity__body">
          <ErrorBoundry>
            <Card className="report">
              <React.Fragment>
                <div className="report__header">
                  <div className="rating">
                    {tutoringUniqueValues.data.average_rating > 0 && tutoringUniqueValues.data.average_rating < 1.5 ? (
                      <div className="icon-wrapper">
                        <IconRating1Active />
                        <span>{translationText(noonText, 'sessionReport.rating1')}</span>
                      </div>
                    ) : tutoringUniqueValues.data.average_rating >= 1.5
                      && tutoringUniqueValues.data.average_rating < 2.5 ? (
                        <div className="icon-wrapper">
                          <IconRating2Active />
                          <span>{translationText(noonText, 'sessionReport.rating2')}</span>
                        </div>
                      ) : tutoringUniqueValues.data.average_rating >= 2.5
                      && tutoringUniqueValues.data.average_rating < 3.5 ? (
                        <div className="icon-wrapper" ng-if="">
                          <IconRating3Active />
                          <span>{translationText(noonText, 'sessionReport.rating3')}</span>
                        </div>
                        ) : tutoringUniqueValues.data.average_rating >= 3.5
                      && tutoringUniqueValues.data.average_rating < 4.5 ? (
                        <div className="icon-wrapper">
                          <IconRating4Active />
                          <span>{translationText(noonText, 'sessionReport.rating4')}</span>
                        </div>
                          ) : tutoringUniqueValues.data.average_rating >= 4.5 ? (
                            <div className="icon-wrapper">
                              <IconRating5Active />
                              <span>{translationText(noonText, 'sessionReport.rating5')}</span>
                            </div>
                          ) : (
                            ''
                          )}
                    <div className="numbers">
                      <h1>
                        {tutoringUniqueValues.data.average_rating
                          && tutoringUniqueValues.data.average_rating.toFixed(1)}
                      </h1>
                      <div className="students">
                        <IconMembersO width="18px" />
                        <span>
                          {tutoringUniqueValues.data.total_rating && tutoringUniqueValues.data.total_rating.toFixed(1)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Vr />
                  <div className="class-details">
                    <h4>{session_name}</h4>
                    <p className="grade">{tutoringUniqueValues.data.folder_name}</p>
                  </div>
                </div>
                <Hr />
                <div className="report__body">
                  <div className="basic-information">
                    <Column className="date item" align="center">
                      <span className="title">
                        {translationText(noonText, 'sessionReport.date')}
                        :
                      </span>
                      <span>
                        <NoonDate className="subtitle" humanize value={tutoringUniqueValues.data.start_time} />
                      </span>
                    </Column>
                    <Column className="time item" align="center">
                      <span className="title">
                        {translationText(noonText, 'sessionReport.time')}
                        :
                      </span>
                      <Time value={tutoringUniqueValues.data.start_time} unit="timestamp" suffix />
                    </Column>
                    <Column className="attendees item" align="center">
                      <span className="title">
                        {translationText(noonText, 'sessionReport.numberOfAttendees')}
                        :
                      </span>
                      <span className="value">{tutoringUniqueValues.data.students}</span>
                    </Column>
                    <Column className="questions item" align="center">
                      <span className="title">
                        {translationText(noonText, 'sessionReport.numberOfQuestions')}
                        :
                      </span>
                      <span className="value">{tutoringUniqueValues.data.questions}</span>
                    </Column>
                  </div>
                  <Hr />
                  <div className="student-table">
                    <div className="student-table__header clearfix">
                      <div className="heading right-area">
                        {translationText(noonText, 'sessionReport.levelOfStudents')}
                      </div>
                      <Row className="stats left-area">
                        <Column className="stats__list stats__item--minutes" align="center">
                          <IconClock width="18px" fill={COLORS.text.base} />
                          <span style={{ color: COLORS.brand.base }}>
                            {translationText(noonText, 'sessionReport.numberOfMinutes')}
                          </span>
                        </Column>
                        <Column className="stats__list stats__item--correct" align="center">
                          <IconCorrectCircle fill="white" stroke={COLORS.text.base} width="18px" />
                          <span style={{ color: COLORS.brand.base }}>
                            {translationText(noonText, 'sessionReport.correctAnswers')}
                          </span>
                        </Column>
                        <Column className="stats__list stats__item--points" align="center">
                          <IconStar fill={COLORS.text.base} width="18px" />
                          <span style={{ color: COLORS.brand.base }}>
                            {translationText(noonText, 'sessionReport.points')}
                          </span>
                        </Column>
                      </Row>
                    </div>
                    <Hr />
                    {!isEmpty(tutoringReviews.data.users) && (
                      <div className="student-table__body">
                        <InfiniteScroll
                          onLoad={getMoreReports}
                          loadMore={
                            tutoringReviews.data.users
                            && tutoringReviews.data.users.length === tutoringReviews.page * sessionReportStudentsBatchLimit
                          }
                          loading={!!tutoringReviews.isLoading}
                          offset={200}
                          style={{ maxHeight: '350px', overflow: 'auto' }}
                        >
                          {tutoringReviews.data.users.map((review, i) => (
                            <div key={i} className="clearfix item">
                              <div style={{ marginBottom: '1rem' }}>{i !== 0 && <Hr />}</div>
                              <div>
                                {/* ng-repeat="(i,review) in tutoringReviews.data | orderBy : score" */}
                                <div className="right-area student-info">
                                  <span className="badge number">{i + 1}</span>
                                  <div className="image-wrapper">
                                    <img src={review.profile_pic} alt="" className="image" />
                                  </div>
                                  <div className="name_school">
                                    <p className="name">{review.name}</p>
                                  </div>
                                </div>
                                <div className="left-area stats">
                                  <Vr />
                                  <Row className="stats__list stats__item--minutes" justify="center" align="center">
                                    <p className="mlr-05">{review.credits}</p>
                                    <span className="word">{translationText(noonText, 'tutoring.minute')}</span>
                                  </Row>
                                  <Vr />
                                  <Row className="stats__list stats__item--correct" justify="center">
                                    <p>
                                      {review.correct_count}
                                      /
                                      <span style={{ color: '#bed224' }}>
                                        {review.correct_count + review.wrong_count}
                                      </span>
                                    </p>
                                  </Row>
                                  <Vr />
                                  <Row className="stats__list stats__item--points" justify="center">
                                    <span>{review.score}</span>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          ))}
                        </InfiniteScroll>
                      </div>
                    )}

                    {isEmpty(tutoringReviews.data.users) && (
                      <div className="item ptb-2">{translationText(noonText, 'sessionReport.noStudents')}</div>
                    )}
                  </div>
                </div>
              </React.Fragment>
            </Card>
          </ErrorBoundry>
        </div>
      </div>
    </ContentOnTop>
  );
}

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row } from '@noon/atom';

import { usePlaybackCheckpoints, useSections } from '../../../hooks';
import { usePlaybackAudio } from '../../../providers/PlaybackAudio';
import { usePlaybackTimeSeries } from '../../../providers/PlaybackTimeSeries';
import {
  TRIM_PLAYBACK_SLIDE,
  UPDATE_PLAYBACK_SECTIONS,
  UPDATE_PLAYER_DATA,
} from '../../../redux/constants';
import {
  defaultSlideWLength,
  IAreas,
  IAudioProvider,
  ISections,
  ISlide,
  ISlideIntervals,
} from '../types';
import { CombinedView, SlideEditView } from './index';

function Sections(props: ISections) {
  const { currentSlide, updateSlideState, playerRef } = props;
  const dispatch = useDispatch();
  const { handlePlayerView } : IAudioProvider = usePlaybackAudio();
  const { active: activeSlide, opened: currentlyViewingSlide } = currentSlide;

  // Redux states
  const { playback_sections, deletedSlideData, editSlideData, playerData } = useSelector((state) => state.toJS().playback);
  const { data: slides, audioLength, original: originalSlides } : { data: Array<ISlide>, audioLength: number, original: Array<ISlide> } = playback_sections;

  // Local States
  // const [currentlyViewingSlide, updateCurrentlyViewingSlide] = useState<ISlideWLength>(defaultSlideWLength);
  const [sections, evaluateSectionsForSlides, updateSectionsWithNewData] = useSections(slides, currentlyViewingSlide, originalSlides);
  const [playbackCheckpoints, calculateCheckpoints] = usePlaybackCheckpoints();
  const { updateCheckpoints } = usePlaybackTimeSeries();

  // Logic
  // Select slide and set start and end boundaries for playback
  const handleSelectSlide = async (slide : ISlide) : Promise<void> => {
    if (currentlyViewingSlide.canvas_id === slide.canvas_id) return;
    updateSlideState({ opened: slide });
    if (slide && slide.canvas_id) {
      await calculateCheckpoints(slide, 'slide');
    } else if (slide && !slide.canvas_id) {
      // calculate checkpoints for all of the data after editing has been done
      await calculateCheckpoints({ slides }, 'everything');
    }
    updateCheckpoints(playbackCheckpoints);
    if (slide.canvas_id) {
      handlePlayerView({ intervals: slide?.payload?.intervals, updateMainDuration: true });
    }
  };

  const updateTrimmedPlaybackTime = () : void => {
    dispatch({ type: UPDATE_PLAYER_DATA, payload: { trimmedDuration: audioLength, duration: playerData.fullDuration } });
  };

  // Update sections data when a slide is deleted or trimmed
  const handleSlideUpdateSuccess = async (data : ISlide, type : string) : Promise<void> => {
    await updateSectionsWithNewData(data, type);
    if (type === 'edit') handleSelectSlide(defaultSlideWLength);
  };

  // Get new intervals from newly selected areas
  const getIntervalsFromAreas = (originalIntervals: Array<ISlideIntervals>, areas: IAreas, originalLength: number, canvasWidth: number) : Array<ISlideIntervals> => {
    const originalSlideStartTime = originalIntervals?.[0]?.start_time;
    const originalSlideEndTime = originalIntervals?.[originalIntervals.length - 1]?.end_time;
    const slideLengthToCanvasLengthRatio = originalLength / canvasWidth;
    let newIntervals = [];
    if (areas.length === 1 && areas[0].endPoint === areas[0].startPoint) {
      newIntervals = [{ start_time: originalSlideStartTime, end_time: originalSlideEndTime }];
    } else if (areas.length === 1 && areas[0].startPoint === 0) {
      newIntervals = [{ start_time: originalSlideStartTime + Math.round(areas[0].endPoint * 1000 * slideLengthToCanvasLengthRatio), end_time: originalSlideEndTime }];
    } else if (areas.length === 1 && areas[0].endPoint === canvasWidth) {
      newIntervals = [{ start_time: originalSlideStartTime, end_time: originalSlideStartTime + Math.round(areas[0].startPoint * 1000 * slideLengthToCanvasLengthRatio) }];
    } else {
      newIntervals = areas.reduce((acc, area, index) => {
        const currentEndTime = originalSlideStartTime + Math.round(area.startPoint * 1000 * slideLengthToCanvasLengthRatio);
        const currentStartTime = originalSlideStartTime + Math.round(area.endPoint * 1000 * slideLengthToCanvasLengthRatio);
        const lastEndTime = areas.length > 1 ? originalSlideStartTime + Math.round(areas[index - 1].startPoint * 1000 * slideLengthToCanvasLengthRatio) : 0;

        const result = [
          ...acc,
          !!(index === 0) && { start_time: originalSlideStartTime, end_time: currentEndTime },
          { start_time: currentStartTime, end_time: index === areas.length - 1 ? originalSlideEndTime : lastEndTime }];
        return result;
      }, []);
    }
    return newIntervals;
  };

  // Passed areas as an array here to support multiple area selection and removal later on
  const handleSaveSlide = (areas: IAreas, canvasWidth: number) : boolean => {
    if (!currentlyViewingSlide || !currentlyViewingSlide.payload) return false;
    if (!areas.length || !canvasWidth || areas.reduce((acc, item) => item.startPoint > item.endPoint, false)) return false;
    const { payload: { originalIntervals }, originalLength, canvas_id, session_id } = currentlyViewingSlide;
    const newIntervals = getIntervalsFromAreas(originalIntervals, areas, originalLength, canvasWidth);
    updateTrimmedPlaybackTime();

    dispatch({ type: TRIM_PLAYBACK_SLIDE.REQUEST, payload: { intervals: newIntervals, canvas_id, session_id } });
    return false;
  };

  // Update the checkpoints range as soon as the area is updated
  const updatePlaybackArea = async (areas: IAreas, canvasWidth: number) : Promise<void> => {
    const { payload: { originalIntervals }, originalLength } = currentlyViewingSlide;
    const newIntervals = getIntervalsFromAreas(originalIntervals, areas, originalLength, canvasWidth);
    await calculateCheckpoints({ ...currentlyViewingSlide, payload: { ...currentlyViewingSlide.payload, intervals: newIntervals } }, 'slide');
    updateCheckpoints(playbackCheckpoints);
    handlePlayerView({ intervals: newIntervals, updateMainDuration: false });
  };

  // Effects
  useEffect(() => {
    if (slides?.length) {
      evaluateSectionsForSlides(slides);
      if (!activeSlide || !activeSlide.canvas_id) updateSlideState({ active: { index: 0, label_name: slides[0]?.payload?.label_name, label_id: slides[0]?.payload?.label_id, intervals: slides[0]?.payload?.intervals, canvas_id: slides[0]?.canvas_id, delete: slides[0]?.delete } });
    }
  }, [slides]);

  useEffect(() => {
    dispatch({ type: UPDATE_PLAYBACK_SECTIONS, payload: { sections } });
  }, [sections]);

  useEffect(() => {
    if (!slides[0].canvas_id) updateTrimmedPlaybackTime();
  }, [audioLength]);

  useEffect(() => {
    if (deletedSlideData.data && deletedSlideData.data.canvas_id) {
      handleSlideUpdateSuccess(deletedSlideData.data, 'delete');
    }
  }, [deletedSlideData.data]);

  useEffect(() => {
    if (editSlideData.data && editSlideData.data.canvas_id) handleSlideUpdateSuccess(editSlideData.data, 'edit');
  }, [editSlideData.data]);

  return (
    <div className="sections-container">
      {!currentlyViewingSlide || !currentlyViewingSlide.canvas_id
        ? (
          <div className="sections-view">
            <Row nowrap align="end" className="slides-bar">
              <div className={classNames('playback__slides', { empty: !Object.keys(sections).length })}>
                <CombinedView
                  updateSlideState={updateSlideState}
                  sections={sections}
                  slides={slides}
                  activeSlide={activeSlide}
                  updateSlideToTrim={(slide) => handleSelectSlide(slide)}
                  playerRef={playerRef}
                />
              </div>
            </Row>
          </div>
        )
        : (
          <div className="single-slide-view">
            <SlideEditView
              slide={currentlyViewingSlide}
              goBack={() => { handleSelectSlide(defaultSlideWLength); updateTrimmedPlaybackTime(); }}
              saveSlideState={handleSaveSlide}
              updatePlaybackArea={updatePlaybackArea}
            />
          </div>
        )}
    </div>
  );
}

Sections.propTypes = {
  currentSlide: PropTypes.shape({}).isRequired,
  updateSlideState: PropTypes.func,
  playerRef: PropTypes.shape({}).isRequired,
};

Sections.defaultProps = {
  updateSlideState: null,
};

export default Sections;

import React, { useState } from 'react';
import classNames from 'classnames';
import { Modal } from '@noon/atom';
import { CreateCurriculum } from '../../components/Curriculum';

const Curriculum = (prop) => {
  console.log('prop is', prop);
  const { noSidebar } = prop;
  const [isCurriculumModalOpen, setIsCurriculumModalOpen] = useState(true);

  const handleCurriculumModalOpenClick = () => {
    setIsCurriculumModalOpen(!isCurriculumModalOpen);
  };

  return isCurriculumModalOpen &&
  <Modal onClose={handleCurriculumModalOpenClick} size="xl" className={classNames('curriculum-modal', { 'no-sidebar': noSidebar })}>
    <div className="curriculum-modal__body">
      <CreateCurriculum closeModal={handleCurriculumModalOpenClick} />
    </div>
  </Modal>;
};

export default Curriculum;

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce, get, isEmpty } from 'lodash-es';
import { Card, Row, Search, Column } from '@noon/atom';
import { IconCorrect, IconMediaPlayO } from '@noon/quark';
import classNames from 'classnames';
import Popover from 'react-popover';
import { COLORS } from '../../../constants';
import { translationText } from '../../../helpers';
import { translationType } from '../../../types';
import FiltersApplied from '../Common/FiltersApplied';

function SearchAndSortHeader({ filters, disabled, noonText, onFilterSelection }) {
  const [showSortPopover, setShowSortPopover] = useState(false);
  const sortItems = [
    { title: translationText(noonText, 'planner.sortAllActivity'), value: 'asc' },
    { title: translationText(noonText, 'planner.sortPastActivity'), value: 'desc' },
  ];
  const [selectedSort, setSelectedSort] = useState(sortItems[0]);
  const searchInputRef = useRef();

  const popoverPropsForSort = {
    isOpen: showSortPopover,
    place: 'below',
    onOuterAction: () => setShowSortPopover(false),
    style: { marginTop: '-0.5rem' },
    body: (
      <Column className="filter-item-list">
        {sortItems.map((filter, index) => (
          <Row
            key={index}
            align="center"
            justify="space-between"
            className={classNames('ptb-05 plr-1 select-item', { selected: filter.title === selectedSort.title })}
            onClick={() => { setSelectedSort(filter); onFilterSelection({ sort: { value: filter.value, title: filter.title } }); }}
              >
            {filter.title}
            {filter.title === selectedSort.title
            && <IconCorrect fill={COLORS.brand.base} stroke={COLORS.brand.base} width="24px" height="24px" />}
          </Row>
        ))}
      </Column>
    ),
  };

  const handleOnSearchInputChangeDebounce = debounce((text) => {
    onFilterSelection({ text: { value: searchInputRef.current.value } });
  }, 200);

  useEffect(() => {
    searchInputRef.current.value = get(filters, ['text', 'value']) || '';
    setSelectedSort(filters.sort || sortItems[0]);
  }, [filters]);

  return (
    <Card>
      {disabled && <div className="disabled-overlay" />}
      <Row className="card-header general-planner__search-sort mtb-2" justify="space-between" align="center" gap="sm">
        <Search
          ref={searchInputRef}
          className="feed-search-box"
          name="book-search"
          autoComplete="off"
          loading={false}
          onChange={handleOnSearchInputChangeDebounce}
          placeholder={translationText(noonText, 'planner.searchActivities')}
        />
        <Popover {...popoverPropsForSort} className="questionList-popover feed-filter-popover">
          <Row onClick={() => setShowSortPopover(!showSortPopover)} justify="start" align="center" style={{ position: 'relative', color: COLORS.brand.base, cursor: 'pointer' }}>
            <span>{selectedSort.title}</span>
            <IconMediaPlayO height="20px" width="20px" rotate={!showSortPopover ? '90' : '-90'} fill={COLORS.brand.base} />
          </Row>
        </Popover>
      </Row>
      {!isEmpty(filters)
        && <FiltersApplied noonText={noonText} filters={filters} onFilterSelection={onFilterSelection} />}
    </Card>
  );
}

SearchAndSortHeader.propTypes = {
  noonText: translationType.isRequired,
  filters: PropTypes.shape({}),
  disabled: PropTypes.bool,
  onFilterSelection: PropTypes.func.isRequired,
};

SearchAndSortHeader.defaultProps = {
  filters: {},
  disabled: false,
};

export default SearchAndSortHeader;

import React, { useState, useEffect, useRef } from 'react';
import { Row, Column } from '@noon/atom';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash-es/get';
import { useHistory, useParams } from 'react-router-dom';
import { CreateActivity } from '../../components/Create';
import {
  GET_TEACHER_GROUP_SELECTED,
  GET_TEACHER_GROUP_CHAPTERS,
  GET_TEACHER_REVIEWS,
} from '../../redux/constants';
import { StudyGroupFeed } from '../../components/StudyGroup';
import Breadcrumbs from '../../components/Breadcrumbs';
import { translationText } from '../../helpers';
import GroupHeader from '../../components/GroupDetails/GroupHeader';
import Mixpanel from '../../components/Mixpanel';

const GroupQuestions = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const [showCreateActivityDialog, toggleCreateActivityDialog] = useState(false);
  const { selectedGroup: group } = useSelector((state) => state.toJS().groupsV2);
  const { loggedUser: user } = useSelector((state) => state.toJS().user);
  const { noonText } = useSelector((state) => state.toJS().translation);
  const logger = useRef(LoggingManager.mount({ moduleName: 'group' })).current;
  const goBack = () => {
    history.push(`/${localStorage.updatedLocale}/groups/${groupId}`);
  };
  const isArchived = get(group, ['archive', 'is_archived']);
  const handleMixpanelCurriculumEvents = ({ data = {}, type, mixPanel = true }) => {
    const dataConst = {
      user_id: user.user_id,
      group_id: groupId,
      group_name: get(group, ['group_info', 'title']),
      group_country: get(group, ['country', 'name']),
      group_subject: get(group, ['curriculum_tags', 'subject', 'name']),
      group_grades: get(group, ['curriculum_tags', 'grade', 'name']),
      group_curriculum_type: get(group, ['curriculum_tags', 'TYPE']),
      group_type: get(group, ['group_info', 'TYPE']),
      group_age: '',
      no_of_students: get(group, ['membersInfo', 'total_members']),
      timestamp: Date.now(),
      ...data,
    };
    if (mixPanel) {
      Mixpanel.track(type, dataConst);
    }
    logger.track(type,
      { group: dataConst },
    );
  };

  useEffect(() => {
    const selectedGroupId = get(group, 'group_info.id');
    if (selectedGroupId !== groupId) {
      dispatch({
        type: GET_TEACHER_GROUP_SELECTED.REQUEST,
        payload: groupId,
      });
      dispatch({
        type: GET_TEACHER_GROUP_CHAPTERS.REQUEST,
        payload: groupId,
      });
      dispatch({ type: GET_TEACHER_REVIEWS.REQUEST, payload: { start: 0, limit: 10 } });
    }
  }, [groupId]);

  useEffect(() => () => {
    LoggingManager.unmount(logger.id);
  }, []);
  return (
    group && (
      <React.Fragment>
        {showCreateActivityDialog && (
          <CreateActivity
            source="group_home_activity"
            groupId={groupId}
            onContentClose={() => toggleCreateActivityDialog(false)}
          />
        )}
        <GroupHeader group={group} handleMixpanelCurriculumEvents={handleMixpanelCurriculumEvents} />
        <Column>
          {/* <Row style={{ margin: '1rem', background: 'aliceblue', padding: '1.5rem', fontSize: '1.8rem' }}>
            <div onClick={() => goBack()} style={{ color: COLORS.brand.base, cursor: 'pointer' }}>
              Main View
            </div>
            <div>
              <span className="mlr-1"> &gt; </span>All Questions
            </div>
          </Row> */}
          <Breadcrumbs onItemClick={goBack} pageTitle={translationText(noonText, 'tab.allQuestions')} />
          <Row id="allQuestions" className="full-width page-content container all-questions" gap="lg">
            <StudyGroupFeed
              groupId={groupId}
              history={history}
              location={history.location}
              groupDetails={group}
              showAllQuestions={() => { goBack(false); }}
              match={history.match}
              viewComponentState="allQuestions"
              isArchived={isArchived}
              />
          </Row>
        </Column>
      </React.Fragment>
    )
  );
};

export default GroupQuestions;

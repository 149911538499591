import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, some, filter } from 'lodash-es';
import { Column, Row, Checkbox, Button } from '@noon/atom';
import { translationText } from '../../../helpers';
import { translationType } from '../../../types';

function CheckboxFilters({ noonText, onFilterSelection, filterCount, filters }) {
  const defaultSelectedFilter = useMemo(() => ['all'], []);
  const [selectedFilter, setSelectedFilter] = useState(() => (!isEmpty(filters.filters) ? filters.filters.value.split(',') : defaultSelectedFilter));
  const checkboxFiltersList = useMemo(() => [
    {
      key: translationText(noonText, 'tab.all'),
      value: 'all',
    },
    {
      key: translationText(noonText, 'session.typeGroup'),
      value: 'group',
      child: [
        {
          key: translationText(noonText, 'activity.hidden'),
          value: 'group:hidden',
        },
        {
          key: translationText(noonText, 'activity.notPrepared'),
          value: 'group:not_prepared',
        },
      ],
    },
    {
      key: translationText(noonText, 'session.typeCompetition'),
      value: 'competition',
      child: [
        {
          key: translationText(noonText, 'activity.notPrepared'),
          value: 'competition:not_prepared',
        },
      ],
    },
    // {
    //   key: 'assignment',
    //   value: '',
    // },
  ], []);

  const handleOnClearCheckboxSelection = () => {
    onFilterSelection({ filters: '' });
    setSelectedFilter(defaultSelectedFilter);
  };

  const handleOnChangeFilter = (event, { value, key: title }) => {
    let updatedFilters = [];
    // if (!event.target.checked) {
    //   updatedFilters = filter(selectedFilter, selectedValue => selectedValue !== value);
    // } else
    if (value === 'all') {
      updatedFilters = defaultSelectedFilter;
      onFilterSelection({ filters: '' });
    } else if (selectedFilter.findIndex((selectedValue) => selectedValue === value) < 0) {
      // updatedFilters = filter(selectedFilter, (selectedValue) => selectedValue !== 'all');
      updatedFilters = [value];
      onFilterSelection({ filters: { value, title } });
    }
    setSelectedFilter(updatedFilters);
  };

  useEffect(() => {
    if (isEmpty(filters.filters)) {
      setSelectedFilter(defaultSelectedFilter);
    }
  }, [filters]);

  return (
    <Column className="general-planner__filters-others" gap="sm">
      {checkboxFiltersList.map((checkboxFilter, index) => (
        <Column key={index}>
          <Row align="center" justify="space-between">
            <Checkbox
              checked={some(selectedFilter, (value) => value === checkboxFilter.value || value === defaultSelectedFilter[0])}
              onChange={(event) => handleOnChangeFilter(event, checkboxFilter)}
              type="checkbox"
              label={index === 0 ? checkboxFilter.key : `${checkboxFilter.key} (${filterCount[checkboxFilter.value]})`}
              className="text-capitalize"
          />
            {index === 0
          && (
          <Button link size="md" onClick={handleOnClearCheckboxSelection}>
            {translationText(noonText, 'filter.clear')}
          </Button>
          )}
          </Row>
          {checkboxFilter.child && checkboxFilter.child.map((checkboxFilterChild, index) => (
            <Row key={index} align="center" justify="space-between" className="plr-2">
              <Checkbox
                checked={some(selectedFilter, (value) => value === checkboxFilter.value || value === checkboxFilterChild.value || value === defaultSelectedFilter[0])}
                onChange={(event) => handleOnChangeFilter(event, checkboxFilterChild)}
                type="checkbox"
                label={`${checkboxFilterChild.key} (${filterCount[checkboxFilterChild.value]})`}
                className="text-capitalize"
          />
            </Row>
          ))}
        </Column>
      ))}
    </Column>
  );
}

CheckboxFilters.propTypes = {
  noonText: translationType.isRequired,
  onFilterSelection: PropTypes.func.isRequired,
  filters: PropTypes.shape({}),
  filterCount: PropTypes.shape({}),
};

CheckboxFilters.defaultProps = {
  filters: {},
  filterCount: {},
};

export default CheckboxFilters;

import PubNub from 'pubnub';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { addToast, TOAST_TYPE } from '../../components/Toast';
import t from '../../helpers/translate';
import AgoraRtm from '../../sdk/agora/agoraRtm';
import AGORA_RTM_ERRORS from '../../sdk/agora/agoraRtmErrors';
import { SentryType, TrackSentryError } from '../../sdk/sentry';
import { EventContext, EventDispatchContext } from './context';
import EM from './EM.js';

declare const window: any;
export const enum NETWORK_PROVIDER {
  PUBNUB,
  AGORA
};

export type IAgoraRtmConnection = {
  appId: string,
  token: string,
  uid: string,
  channel: string,
}

export type IPubnubConnection = {
  publish_key:string,
  subscribe_key:string,
  uuid: number,
  presence_timeout: number,
  authToken: string,
  origin: string,
}

export type IEventConnectionDetails = { 
  [NETWORK_PROVIDER.PUBNUB]: IPubnubConnection,
  [NETWORK_PROVIDER.AGORA]: IAgoraRtmConnection 
}

type IEventListener = {
  [key: string]: {
    [key: string]: Function,
  }
}

interface IEventProviderProp {
  connectionDetails: IEventConnectionDetails,
  eventListener: IEventListener,
  channelsToSubscribe?: {
    [NETWORK_PROVIDER.PUBNUB]: Array<string>,
    [NETWORK_PROVIDER.AGORA]:  Array<string>,
  },
  dynamicChannelValues?: any,
  children: React.ReactNode
  eventInterceptor?: (eventName: string, payload) => void
}

interface Ievent {
    event: string,
    channel: Function,
    service: NETWORK_PROVIDER
}

type sendMessageOptionType = {
  stringify?: boolean,
  storeInHistory?: boolean
}

export type ISendMessage = (eventObject: Ievent, message: Object, options?: sendMessageOptionType) => IsendMessageResponse;

type ISendPubnubMessage = (eventObject: Ievent, message: Object, options?: sendMessageOptionType, retryCount?: number) => IsendMessageResponse;

type ISendAgoraMessage = (eventObject: Ievent, message: Object, options?: sendMessageOptionType, retryCount?: number) => IsendMessageResponse;

type IState = {
  agoraRtm: Object,
  pubunb: Object,
  sendMessage: ISendMessage,
}

type IsendMessageResponse = Promise<{event: {}, message: {}}>;

type IHandlePubnubMessages = (dispatch: Function, event: { message: any, channel: any, state?: any  }, eventListener: any, eventInterceptor: Function, type?: string) => void

const handlePubnubMessages:IHandlePubnubMessages = (dispatch, event, eventListener, eventInterceptor, type) => {
  const { message } = event;
  if (process.env.ENV !== 'production') console.log('Pubnub event', event);
  if (type === 'presence') {
    eventListener[type](dispatch, event);
  } else if (eventListener[message.event]) {
    eventListener[message.event](dispatch, event);
    if (eventInterceptor) eventInterceptor(type, event);
  }
};

const handleAgoraMessages = (dispatch, event, eventListener, eventInterceptor) => {
  const { type } = event;
  if (process.env.ENV !== 'production') console.log('Agora event', event);
  if (eventListener[type]) {
    eventListener[type](dispatch, event);
    if (eventInterceptor) eventInterceptor(type, event);
  }
};

const handleAgoraConnectionStatus = (status, reason) => {
  if (process.env.ENV !== 'production') console.log('handleAgoraConnectionStatus', status, reason);
};

const handleAgoraError = (msg: string, err) => {
  TrackSentryError(msg, err, SentryType.ERROR);
  if (msg === AGORA_RTM_ERRORS[101]) {
    addToast(t('teacherRtmError', '101'), TOAST_TYPE.ERROR);
  } else if (msg === AGORA_RTM_ERRORS[108]) {
    addToast(t('teacherRtmError', '108'), TOAST_TYPE.ERROR);
  }
  if (process.env.ENV !== 'production') console.log('handleAgoraError', msg, err);
};

function EventProvider (prop: IEventProviderProp) {
  const { connectionDetails = {}, eventListener, dynamicChannelValues, eventInterceptor, children } = prop;
  const pubnubDetails = connectionDetails[NETWORK_PROVIDER.PUBNUB];
  const agoraRtmDetails = connectionDetails[NETWORK_PROVIDER.AGORA];

  const agoraRtmRef = useRef(null);
  const pubnubRef = useRef(null);

  const [state, setState] = useState({ pubnub: null, agoraRtm: null });
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      if (pubnubRef.current) {
        pubnubRef.current?.disconnect();
      }
      if (agoraRtmRef.current && agoraRtmRef.current?.logined) {
        agoraRtmRef.current?.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (pubnubDetails?.subscribe_key && !state.pubnub) {
      const { publish_key, subscribe_key, uuid, presence_timeout, authToken, origin } = pubnubDetails;
      const pubnubInstance = new PubNub({
        publishKey: publish_key,
        subscribeKey: subscribe_key,
        presenceTimeout: presence_timeout,
        authKey: authToken,
        uuid,
        origin,
        restore: true,
      });
      const pubnub = { 
        ...pubnubInstance,
        disconnect: () => { // extending method to make it similar to agora disconnect method
          pubnubInstance?.unsubscribeAll();
          pubnubInstance?.stop();
        }  
      };
      pubnubRef.current = pubnub;
      setState(prevState => ({ 
        ...prevState,
        pubnub,
      }));
      pubnubInstance.addListener({
        message: (e) => handlePubnubMessages(dispatch, e, eventListener[NETWORK_PROVIDER.PUBNUB], eventInterceptor),
        status: (statusEvent) => {
          if (process.env.ENV !== 'production') console.log('pubnub status', statusEvent);
        },
        presence: (e) => handlePubnubMessages(dispatch, e, eventListener[NETWORK_PROVIDER.PUBNUB], eventInterceptor, 'presence')
      });
    }
  }, [pubnubDetails]);

  useEffect(() => {
    if (agoraRtmDetails?.token && !state.agoraRtm) {
      const agoraRtmInstance = new AgoraRtm({
        connectionDetails: agoraRtmDetails,
        connectionStatus: handleAgoraConnectionStatus,
        onError: handleAgoraError,
      });
      agoraRtmRef.current = agoraRtmInstance;
      agoraRtmInstance.login()
        .then(() => {
          setState(prevState => ({ 
            ...prevState,
            agoraRtm: agoraRtmInstance,
          }));
          agoraRtmInstance.on('ChannelMessage',
            (e) => handleAgoraMessages(dispatch, e, eventListener[NETWORK_PROVIDER.AGORA], eventInterceptor),
          );
        });
    }
  }, [agoraRtmDetails]);


  useEffect(() => {
    if(dynamicChannelValues.sessionId){
     if(!(EM as any).sendMessage)
    (EM as any).sendMessage = sendMessage;
    }
  }, [agoraRtmDetails,pubnubDetails,dynamicChannelValues])

  const sendAgoraMessage: ISendAgoraMessage = (eventObject, message, options, retryCount = 3) => {
    return new Promise<{ event: {}, message: {} }>((resolve, reject) => {

      const { event, channel } = eventObject;
      if (!event || !channel) return;
      const channelName = channel(dynamicChannelValues);
      const { stringify = true } = options;
      const postData = {
        type: event,
        data: stringify ? JSON.stringify(message) : message,
      };
      agoraRtmRef.current?.sendMessage(postData, channelName, retryCount).then(() => {
        resolve({ event, message });
      })
      .catch((err) => {
        reject(err);
      });
    });
  };

  const sendPubnubMessage: ISendPubnubMessage = (eventObject, message, options, retryCount = 3) => {
    return new Promise<{ event: {}, message: {} }>((resolve, reject) => {
      const { event, channel } = eventObject;
      if (!event || !channel) return;
      const channelName = channel(dynamicChannelValues);
      pubnubRef.current?.publish({
        message: { ...message, event }, // append event name in payload
        channel: channelName,
        storeInHistory: options.storeInHistory || true,
      }, (status) => {
        if (!status.error) {
          resolve({ event, message });
        } else {
          // If retry == 0 then reject the request else keep trying until retry === 0
          if(retryCount === 0) {
            reject(status);
          } else {
            sendPubnubMessage(eventObject, message, options, retryCount - 1);
          }
        }
      });
    });
  };

  const sendMessage: ISendMessage = (eventObject, message, options = {}) => {
    if (eventObject.service === NETWORK_PROVIDER.AGORA) {
      // handle agora event
      return sendAgoraMessage(eventObject, message, options);
    } else if (eventObject.service === NETWORK_PROVIDER.PUBNUB) {
      // handle pubnub event
      return sendPubnubMessage(eventObject, message, options);
    }
  };

  return (
    <EventContext.Provider value={state}>
      <EventDispatchContext.Provider value={sendMessage}>
        {children}
      </EventDispatchContext.Provider>
    </EventContext.Provider>
  );
}

export default EventProvider;

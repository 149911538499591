import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-popover';
import { Column, Row, Hr } from '@noon/atom';
import classNames from 'classnames';
import { IconCorrect, IconArrowDown } from '@noon/quark';
import { COLORS } from '../../constants';

function PopoverList({ listItems, onSelect, selectedItem, uniqueKey = 'id' }) {
  const [showListItem, setShowListItem] = useState(false);

  const popoverProps = {
    isOpen: showListItem,
    place: 'below',
    onOuterAction: () => setShowListItem(false),
    style: { marginTop: '-0.5rem' },
    body: (
      <Column className="filter-item-list">
        {listItems.map((device, index) => (
          <>
            {index !== 0
                && <Hr />}
            <Row
              key={index}
              align="center"
              justify="space-between"
              className={classNames('ptb-05 plr-1 select-item cursor-pointer', { selected: selectedItem[uniqueKey] === device[uniqueKey] })}
              onClick={() => onSelect(device)}
                  >
              {device.label}
              {device.deviceId === selectedItem?.[uniqueKey]
                && <IconCorrect fill={COLORS.brand.base} stroke={COLORS.brand.base} width="24px" height="24px" />}
            </Row>
          </>
        ))}
      </Column>
    ),
  };
  return (
    <Popover {...popoverProps}>
      <IconArrowDown
        onClick={() => setShowListItem(!showListItem)}
        height="20px"
        width="20px"
        fill={showListItem ? COLORS.brand.base : ''}
        className="cursor-pointer" />
    </Popover>
  );
}

PopoverList.propTypes = {
  listItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedItem: PropTypes.shape({
    deviceId: PropTypes.string,
  }).isRequired,
  uniqueKey: PropTypes.string.isRequired,
};
export default PopoverList;

import React from 'react';
import { Link } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { Hr, Column, Button } from '@noon/atom';
import { IconFacebook, IconInstagram, IconNoon, IconTwitter } from '@noon/quark';
import { translationText } from '../../helpers';

export default function Footer(prop) {
  const { noonText } = prop;
  const isRtl = document.body.dir === 'rtl';

  const takeToLocation = (subLocation) => {
    window.location = `${process.env.STUDENT_URL}${localStorage.updatedLocale}/${subLocation}`;
  };

  return (
    <Column nowrap align="center" className="footer">
      <section className="landing-footer">
        <div className="landing-footer-wrapper">
          <div className="landing-footer-content">
            <h4 className="subheading-teacher">{translationText(noonText, 'teacherLanding.footerPara')}</h4>
            <Link to="/login">
              <Button type="orange" size={isMobileOnly ? 'lg' : 'xl'} depth="1" className="mt-2">
                {translationText(noonText, 'teacherLanding.register')}
              </Button>
            </Link>
          </div>
        </div>
      </section>
      <section className="footer-top">
        <div className="footer-top-wrapper">
          <div className="footer-left">
            <IconNoon />
          </div>
          <div className="mobile-footer">
            <div className="footer-center">
              <h5 className="footer-heading">{translationText(noonText, 'saudiLanding.aboutNoon')}</h5>
              <ul>
                <li>
                  <Link onClick={() => takeToLocation('career')}>{translationText(noonText, 'home.jobs')}</Link>
                </li>
              </ul>
            </div>
            <div className="footer-right">
              <h5 className="footer-heading">{translationText(noonText, 'saudiLanding.connectWithUs')}</h5>
              <ul>
                <li>
                  <Link to="/tos">
                    {translationText(noonText, 'home.tos')}
                    {' '}
                  </Link>
                </li>
                <li>
                  <Link onClick={() => takeToLocation('privacy')}>{translationText(noonText, 'home.privacy')}</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Hr width="80%" />
      <section className="footer-bottom">
        <div className="footer-bottom-wrapper">
          <div className="footer-bottom-text">
            <p>{translationText(noonText, 'home.copyRight')}</p>
          </div>
          <div className="footer-social-link">
            <ul className="social-icons">
              <li>
                <a className="btn-inst" href="//instagram.com/noonEdu" target="_blank">
                  <IconInstagram />
                </a>
              </li>
              <li>
                <a className="btn-fb" href="//fb.me/Noon.Academy.All" target="_blank">
                  <IconFacebook />
                </a>
              </li>
              <li>
                <a className="btn-tw" href="//twitter.com/noonEdu" target="_blank">
                  <IconTwitter />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </Column>
  );
}

import { Button, Modal, Row, Column, NoonDate } from '@noon/atom';
import PropTypes from 'prop-types';
import { IconClose } from '@noon/quark/dist';
import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash-es';
import { translationText } from '../../helpers';
import { translationType } from '../../types';
import { UPDATE_CASH_PAYMENT_STATE } from '../../redux/constants';

function AcceptPaymentConfirm({ transaction, updatePaymentState, noonText, viewDetails, onClose, onAccept }) {
  const dispatch = useDispatch();
  const productDetails = transaction.group || transaction.editorial;
  const [buttonClick, setButtonClick] = useState('');

  const handleOnClickAccept = () => {
    dispatch({
      type: UPDATE_CASH_PAYMENT_STATE.REQUEST,
      payload: {
        reference_id: transaction.reference_id,
        state: 'accept',
      },
    });
    setButtonClick('accept');
  };

  const handleOnClickDecline = () => {
    dispatch({
      type: UPDATE_CASH_PAYMENT_STATE.REQUEST,
      payload: {
        reference_id: transaction.reference_id,
        state: 'decline',
      },
    });
    setButtonClick('decline');
  };

  const setDecimalPlaces = (amount, decimalPlaces = 2) => (amount / (10 ** decimalPlaces)).toFixed(decimalPlaces);

  useEffect(() => {
    if (buttonClick && isEmpty(updatePaymentState)) {
      if (buttonClick === 'accept') {
        onAccept();
      }
      onClose();
    }
  }, [updatePaymentState]);

  const { decimalPlaces, currencySymbol, type } = useMemo(() => {
    const { subscription: { currency_symbol, decimal_places, type } } = transaction;
    return {
      currencySymbol: currency_symbol,
      decimalPlaces: decimal_places,
      type,
    };
  }, [transaction]);

  return (
    <Modal className={classnames('modal-payment-info', { 'payment-details': viewDetails })} onClose={() => onClose(transaction)}>
      <Row className="modal-header full-width" align="center" justify="space-between">
        <h4 className="title">{viewDetails ? translationText(noonText, 'label.transactionDetails') : translationText(noonText, 'button.confirmPayment')}</h4>
        <IconClose width="16px" height="16px" fill="#AFAFAF" onClick={() => onClose(transaction)} />
      </Row>
      <Column nowrap align="start" justify="start" className="modal-body scroll-bar">
        <div className="payment-details full-width">
          <Row className="w-full ptb-05" nowrap align="center" gap="xl">
            <span flex="1" className="child label">{translationText(noonText, 'school.type')}</span>
            <span flex="2" className="text-capitalize">
              {translationText(noonText, `label.${type || 'fixed'}`)}
            </span>
          </Row>
          <Row className="w-full ptb-05" nowrap align="center" gap="xl">
            <span flex="1" className="child label text-capitalize">{translationText(noonText, 'label.noOfGroups')}</span>
            <span flex="2">{productDetails.group_count || 1}</span>
          </Row>
          <Row className="w-full ptb-05" nowrap align="center" gap="xl">
            <span flex="1" className="child label">{translationText(noonText, 'label.originalGroupPrice')}</span>
            <span flex="2">
              {setDecimalPlaces(transaction.paid_amount + transaction.earning.discount, decimalPlaces)}
              {' '}
              {currencySymbol}
            </span>
          </Row>
          <Row className="w-full ptb-05" nowrap align="center" gap="xl">
            <span flex="1" className="child label">{translationText(noonText, 'label.totalPricePaid')}</span>
            <span flex="2">
              {setDecimalPlaces(transaction.paid_amount, decimalPlaces)}
              {' '}
              {currencySymbol}
            </span>
          </Row>
          <Row className="w-full ptb-05" nowrap align="center" gap="xl">
            <span flex="1" className="child label">{translationText(noonText, 'label.discount')}</span>
            <span flex="2">
              {setDecimalPlaces(transaction.earning.discount, decimalPlaces)}
              {' '}
              {currencySymbol}
            </span>
          </Row>
          {!!transaction.earning.refund_amount && (
            <Row className="w-full ptb-05" nowrap align="center" gap="xl">
              <span flex="1" className="child label">{translationText(noonText, 'label.refundAmount')}</span>
              <span flex="2">
                {setDecimalPlaces(transaction.earning.refund_amount, decimalPlaces)}
                {' '}
                {currencySymbol}
              </span>
            </Row>
          )}
          {transaction.earning.tax && transaction.earning.tax.map((tax) => (
            <Row className="w-full ptb-05" nowrap align="center" gap="xl">
              <span flex="1" className="child label text-capitalize">
                {tax.type}
                {' '}
                {tax.calculation === 'percentage' ? `(${tax.value}) %` : ''}
              </span>
              <span flex="2">
                {setDecimalPlaces(tax.share, decimalPlaces)}
                {' '}
                {currencySymbol}
              </span>
            </Row>
          ))}
          <Row className="w-full ptb-05" nowrap align="center" gap="xl">
            <span flex="1" className="child label">{translationText(noonText, 'label.noonShare')}</span>
            <span flex="2">
              {transaction.earning.noon_share
                ? (
                  <>
                    {setDecimalPlaces(transaction.earning.noon_share, decimalPlaces)}
                    {' '}
                    {currencySymbol}
                  </>
                ) : 'N/A'}
            </span>
          </Row>
          <Row className="w-full ptb-05" nowrap align="center" gap="xl">
            <span flex="1" className="child label"><b>{translationText(noonText, 'label.yourRevenue')}</b></span>
            <span flex="2">
              {transaction.earning.teacher_share
                ? (
                  <b>
                    {setDecimalPlaces(transaction.earning.teacher_share, decimalPlaces)}
                    {' '}
                    {currencySymbol}
                  </b>
                ) : 'N/A'}
            </span>
          </Row>
        </div>
        <div className="user-details full-width ptb-2">
          <Row className="w-full ptb-05" nowrap align="center" gap="xl">
            <span flex="1" className="child label">{translationText(noonText, 'label.groupName')}</span>
            <span flex="2">{productDetails.title}</span>
          </Row>
          <Row className="w-full ptb-05" nowrap align="center" gap="xl">
            <span flex="1" className="child label">{translationText(noonText, 'label.studentName')}</span>
            <span flex="2">{transaction.user.name}</span>
          </Row>
          <Row className="w-full ptb-05" nowrap align="center" gap="xl">
            <span flex="1" className="child label">{translationText(noonText, 'label.dateOfPurchase')}</span>
            <span flex="2"><NoonDate value={Number(transaction.created_at)} format="DD" /></span>
          </Row>
          <Row className="w-full ptb-05" nowrap align="center" gap="xl">
            <span flex="1" className="child label"><b>{translationText(noonText, 'label.paymentMode')}</b></span>
            <span flex="2" className="text-capitalize"><b>{transaction.vendor}</b></span>
          </Row>
        </div>
      </Column>
      {!viewDetails
      && (
      <div className="modal-footer">
        <Button
          type="primary"
          value={translationText(noonText, 'button.confirmPayment')}
          onClick={handleOnClickAccept}
          className="text-capitalize"
          loading={updatePaymentState.state === 'accept'}
          disabled={updatePaymentState.state === 'accept'}
        />
        <Button
          type="secondary"
          value={translationText(noonText, 'button.cancelRequest')}
          onClick={handleOnClickDecline}
          className="text-capitalize"
          loading={updatePaymentState.state === 'decline'}
          disabled={updatePaymentState.state === 'decline'}
              />
      </div>
      )}
    </Modal>
  );
}

AcceptPaymentConfirm.propTypes = {
  updatePaymentState: PropTypes.shape({
    state: PropTypes.string,
  }).isRequired,
  noonText: translationType.isRequired,
  viewDetails: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  transaction: PropTypes.shape({
    paid_amount: PropTypes.number,
    group: PropTypes.shape({}),
    editorial: PropTypes.shape({}),
    reference_id: PropTypes.string,
    vendor: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      profile_pic: PropTypes.string,
      gender: PropTypes.string,
    }),
    subscription: PropTypes.shape({
      tax: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      currency_symbol: PropTypes.string,
      decimal_places: PropTypes.number,
      type: PropTypes.string,
    }),
    earning: PropTypes.shape({
      tax: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      currency_symbol: PropTypes.string,
      decimal_places: PropTypes.number,
      teacher_share: PropTypes.number,
      noon_share: PropTypes.number,
      refund_amount: PropTypes.number,
      discount: PropTypes.number,
    }),
    created_at: PropTypes.number,
  }).isRequired,
};
AcceptPaymentConfirm.defaultProps = {
  viewDetails: false,
  onAccept: () => {},
};
export default AcceptPaymentConfirm;

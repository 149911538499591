import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash-es/get';
import find from 'lodash-es/find';
import classNames from 'classnames';
import { Card, Row, Column, Button, Flex } from '@noon/atom';
import { IconArrowDown } from '@noon/quark';
import { isEmpty } from 'lodash-es';
import { COLORS } from '../../../constants';
import Task from './Task';
import Shimmer from '../../Layout/shimmer';
import { translationText } from '../../../helpers';
import NotAllowedOverlay from '../NotAllowedOverlay';

function GroupStatusWidget({
  toggleCreateActivityDialog,
  handleGoToEditCurriculum,
  handleGroupInfoModalClose,
  membersInfo,
  isCurriculumFilled,
  groupInfo,
  loading,
  handleMixpanelCurriculumEvents,
  schedules,
  handleOpenCreateSchedule,
  isArchived,
}) {
  const [showTaskList, setShowTaskList] = useState(false);
  const noonText = useSelector((state) => state.toJS().translation.noonText);
  const { tasklist, nextTask } = useMemo(() => {
    const tasklist = [
      {
        isCompleted: get(groupInfo, ['description', 'length']) > 0,
        type: 'description',
        onClick: handleGroupInfoModalClose,
      },
      {
        isCompleted: !!get(groupInfo, 'preview_url_info'),
        type: 'video',
        onClick: handleGroupInfoModalClose,
        // subTitle: 'groupStatus.videoTaskBenifit',
      },
      {
        isCompleted: isCurriculumFilled,
        type: 'curriculum',
        groupId: get(groupInfo, 'id'),
        onClick: handleGoToEditCurriculum,
      },
      {
        isCompleted: get(membersInfo, 'total_members') >= 5,
        totalMembers: get(membersInfo, 'total_members'),
        type: 'member',
        groupId: get(groupInfo, 'id'),
      },
      {
        isCompleted: get(groupInfo, 'is_session_created'),
        type: 'activity',
        groupId: get(groupInfo, 'id'),
        onClick: toggleCreateActivityDialog,
      },
    ];
    if (isCurriculumFilled) {
      tasklist.push({
        isCompleted: !isEmpty(schedules),
        type: 'schedules',
        groupId: get(groupInfo, 'id'),
        onClick: handleOpenCreateSchedule,
      });
    }
    const nextTask = find(tasklist, { isCompleted: false }) || {};
    return { tasklist, nextTask };
  }, [groupInfo, membersInfo, isCurriculumFilled]);

  const percentCompleted = useMemo(() => {
    let totalItems = 0;
    if (isCurriculumFilled) {
      totalItems += 1;
    }
    if (get(groupInfo, 'preview_url_info')) {
      totalItems += 1;
    }
    if (get(groupInfo, 'description.length') > 0) {
      totalItems += 1;
    }
    if (isCurriculumFilled && get(groupInfo, 'schedules.length') > 0) {
      totalItems += 1;
    }
    if (get(membersInfo, 'total_members') >= 5) {
      totalItems += 1;
    }
    if (get(groupInfo, 'is_session_created')) {
      totalItems += 1;
    }
    const maxTask = (isCurriculumFilled) ? 6 : 5;
    return parseInt((100 * totalItems) / maxTask, 10);
  }, [groupInfo, membersInfo, isCurriculumFilled]);

  const handleShowHideTask = () => {
    if (!showTaskList) {
      handleMixpanelCurriculumEvents({
        type: 'group_status_view_all',
        data: {
          percentage_progress: percentCompleted,
          next_task_shown: nextTask.type,
        },
        mixPanel: false,
      });
    }
    setShowTaskList(!showTaskList);
  };
  return (
    <Card className="group-status-widget">
      {isArchived && <NotAllowedOverlay />}
      {loading || !groupInfo || !membersInfo || membersInfo.loading ? (
        <Shimmer obj={[{ heading: true }, { lines: 2 }]} />
      ) : (
        <React.Fragment>
          <Row align="center" justify="space-between" flex="1" className="card-header" gap="sm">
            <Row align="center" justify="start" flex="1">
              <h3 className="card-header__title child">{translationText(noonText, 'groupStatus.title')}</h3>
              <span className="noon-dot" />
              <h4 className="card-header__title-state child ">
                {percentCompleted || 0}
                %
                {' '}
                <span>{translationText(noonText, 'groupStatus.complete')}</span>
              </h4>
            </Row>
            <Row className="show-more-button text-center">
              <Button
                className="no-padding"
                style={{
                  minWidth: '27px',
                  border: '0',
                  textTransform: 'capitalize',
                }}
                outlined
                onClick={handleShowHideTask}
              >
                {translationText(noonText, showTaskList ? 'groupStatus.hideAll' : 'groupStatus.showAll')}
                <IconArrowDown
                  fill={COLORS.coolGrey[1]}
                  rotate={showTaskList ? 180 : 0}
                  stroke={COLORS.coolGrey[1]}
                  height="15px"
                  width="15px"
                  className="mlr-1"
                />
              </Button>
            </Row>
          </Row>
          <Column align="start" justify="start" className="card-body">
            <Column align="start" justify="start" className="content plr-2 mb-2">

              {percentCompleted === 100
                ? <span>{translationText(noonText, 'groupStatus.allTaskCompleted')}</span>
                : (
                  <React.Fragment>
                    <span className="content-note mb-1">{translationText(noonText, 'groupStatus.nextTask')}</span>
                    <Task {...nextTask} />
                  </React.Fragment>
                )}
            </Column>
            <Row
              align="center"
              justify="start"
              gap="lg"
              className={classNames('content content-mobile', {
                'not-completed': !get(groupInfo, 'preview_url_info'),
                completed: !!get(groupInfo, 'preview_url_info'),
              })}
            >
              <span className="content-note child">{translationText(noonText, 'groupStatus.nextTask')}</span>
              <Task {...nextTask} />
            </Row>
            {showTaskList && (
              <Column align="start" justify="start" className="plr-2 ptb-2 group-status-widget__content-more">
                {tasklist.map((task, index) => (
                  <Task key={index} {...task} subTitle={task.subTitle ? translationText(noonText, task.subTitle) : ''} />
                ))}
              </Column>
            )}
          </Column>
          <Flex className="show-more-button text-center">
            <Button
              // size="sm"
              className="no-padding"
              style={{
                minWidth: '27px',
                border: '0',
                textTransform: 'capitalize',
              }}
              outlined
              onClick={handleShowHideTask}
            >
              {translationText(noonText, showTaskList ? 'groupStatus.hideAll' : 'groupStatus.showAll')}
              <IconArrowDown
                fill={COLORS.coolGrey[1]}
                rotate={showTaskList ? 180 : 0}
                stroke={COLORS.coolGrey[1]}
                height="15px"
                width="15px"
                className="mlr-1"
              />
            </Button>
          </Flex>
        </React.Fragment>
      )}
    </Card>
  );
}

GroupStatusWidget.propTypes = {
  toggleCreateActivityDialog: PropTypes.func.isRequired,
  handleGoToEditCurriculum: PropTypes.func.isRequired,
  handleGroupInfoModalClose: PropTypes.func.isRequired,
  membersInfo: PropTypes.shape({}).isRequired,
  isCurriculumFilled: PropTypes.bool.isRequired,
  groupInfo: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
  handleMixpanelCurriculumEvents: PropTypes.func.isRequired,
  handleOpenCreateSchedule: PropTypes.func.isRequired,
  schedules: PropTypes.arrayOf(PropTypes.shape({})),
};
GroupStatusWidget.defaultProps = {
  schedules: [],
};
export default GroupStatusWidget;

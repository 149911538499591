import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { IconLoader } from '@noon/quark';
import { toggleVideo } from '../../../redux/actions/webrtc';
import Mixpanel from '../../Mixpanel';
import t from '../../../helpers/translate';

const VideoCam = () => {
  const dispatch = useDispatch();
  const videoStatus = useSelector((state) => state.toJS().webrtc.videoStatus);
  const webrtcStatus = useSelector((state) => state.toJS().webrtc.status);
  const isVideoStatusLoading = useSelector((state) => state.toJS().webrtc.isVideoStatusLoading);
  const sessionDetails = useSelector((state) => state.toJS().myClassroom.sessionDetails);
  const localDevices = useSelector((state) => state.toJS().myClassroom.localDevices);
  const breakoutState = useSelector((state) => state.toJS().myClassroom.breakoutState);
  const logger = LoggingManager.mount({ moduleName: 'classroom' });

  const isWebrtcConnected = webrtcStatus === 'CONNECTED';

  const handleVideoToggle = () => {
    if (!isWebrtcConnected || breakoutState || !localDevices.isCameraWorking) return;
    dispatch(toggleVideo('REQUEST'));
    localStorage.setItem('classroomDetails', JSON.stringify({ videoStatus: !videoStatus }));
  };
  useEffect(() => {
    const eventData = {
      group_id: sessionDetails.group.id,
      session_id: sessionDetails.id,
      session_type: sessionDetails.class_type === 'competition' ? 'CLASSROOM_SESSION_TYPE_COMPETITION' : 'CLASSROOM_SESSION_TYPE_TUTORING',
      time_passed: Math.round((Date.now() - sessionDetails.start_time) / 1000),
      video_status: videoStatus,
    };
    Mixpanel.track('teacher_toggle_video', eventData);
    logger.track('teacher_toggle_video',
      { classroom: eventData },
    );
  }, [videoStatus]);

  return (
    <div
      onClick={handleVideoToggle}
      style={{ position: 'relative', cursor: 'pointer' }}>
      <ReactTooltip place="bottom" />
      {(!videoStatus && !isVideoStatusLoading)
        ? (
          <svg
            data-tip={t('error', 'notAnInitiator')}
            height="36px"
            width="36px"
            viewBox="0 0 57 57"
            fill={videoStatus ? "url('#icons_gradient-green')" : 'rgb(153, 153, 153)'}
            xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9054 17.1749C10.8358 17.2366 10.7678 17.3006 10.7016 17.3668C9.82646 18.242 9.33479 19.429 9.33479 20.6667V34.6667C9.33479 35.9043 9.82646 37.0913 10.7016 37.9665C11.5768 38.8417 12.7638 39.3333 14.0015 39.3333H31.5015C31.966 39.3334 32.424 39.2642 32.8615 39.1309L10.9054 17.1749Z" />
            <path d="M45.3898 38.4515C45.4637 38.414 45.5357 38.3726 45.6056 38.3272C45.9314 38.1156 46.1993 37.826 46.3849 37.4846C46.5704 37.1432 46.6678 36.7609 46.6681 36.3723V18.961C46.668 18.5721 46.5706 18.1895 46.3849 17.8478C46.1992 17.5062 45.9311 17.2163 45.6049 17.0046C45.2787 16.793 44.9048 16.6662 44.5171 16.6358C44.1294 16.6054 43.7403 16.6724 43.3851 16.8307L36.1285 20.0553C35.9802 18.9332 35.4291 17.9032 34.5779 17.1572C33.7267 16.4111 32.6333 15.9999 31.5015 16H22.9383L45.3898 38.4515Z" />
            <path d="M8.59332 7.7905C9.37437 7.00945 10.6407 7.00945 11.4218 7.7905L48.1913 44.5601C48.9724 45.3411 48.9724 46.6074 48.1913 47.3885L47.7199 47.8599C46.9389 48.6409 45.6725 48.6409 44.8915 47.8599L8.12192 11.0903C7.34087 10.3093 7.34087 9.04295 8.12192 8.2619L8.59332 7.7905Z" />
          </svg>
        )
        : (
          <svg
            height="36px"
            width="36px"
            viewBox="0 0 57 57"
            fill={videoStatus ? "url('#icons_gradient-green')" : 'rgb(153, 153, 153)'}
            xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.70557 21.3356C9.70557 20.0979 10.1972 18.9109 11.0724 18.0358C11.9476 17.1606 13.1346 16.6689 14.3722 16.6689H31.8722C33.0041 16.6688 34.0975 17.0801 34.9487 17.8261C35.7999 18.5722 36.351 19.6022 36.4992 20.7243L43.7559 17.4996C44.1111 17.3413 44.5002 17.2744 44.8879 17.3047C45.2756 17.3351 45.6495 17.4619 45.9757 17.6736C46.3019 17.8853 46.57 18.1751 46.7557 18.5168C46.9414 18.8584 47.0387 19.2411 47.0389 19.6299V37.0413C47.0386 37.4298 46.9412 37.8122 46.7556 38.1535C46.5701 38.4949 46.3022 38.7846 45.9763 38.9962C45.6504 39.2078 45.2769 39.3347 44.8895 39.3653C44.5022 39.3959 44.1133 39.3294 43.7582 39.1716L36.4992 35.9469C36.351 37.0691 35.7999 38.0991 34.9487 38.8451C34.0975 39.5912 33.0041 40.0024 31.8722 40.0023H14.3722C13.1346 40.0023 11.9476 39.5106 11.0724 38.6354C10.1972 37.7603 9.70557 36.5733 9.70557 35.3356V21.3356Z" />
          </svg>
        ) }

      {isVideoStatusLoading
      && (
      <div
        style={{ position: 'absolute', top: '2px', left: '8px', zIndex: 9999 }}
      >
        <IconLoader width="20px" />
      </div>
      )}
    </div>
  );
};

export default VideoCam;

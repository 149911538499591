import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash-es';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PlaybackContainer from './playbackContainer';
import { useLocalStorage } from '../../hooks';
import OldPlay from '../../components/OldPlayback';
import { PlaybackLoadingModal, PlaybackDetailsErrorModal } from '../../components/Play';
import { DELAY_PLAYBACK_CALL_BY } from '../../constants';
import { GET_ORIGINAL_PLAYBACK_DETAILS, GET_EDITED_PLAYBACK_DETAILS, CLEAR_PLAYBACK_DATA } from '../../redux/constants';

function Play(props) {
  const { match } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: session_id } = useParams();
  const typeOfPlayback = 'normal';
  const { playbackDetails, editedPlaybackDetails, error } = useSelector((state) => state.toJS().playback);
  const [isPlaybackDetailsLoading, setPlaybackDetailsLoadingState] = useState(false);
  const [hasSections, setHasSections] = useState(false);
  const [showPlaybackDetailsErrorFor, togglePlaybackDetailsError] = useState('');

  // Retry fetching playback details if failed. Try 3 times
  const retryFetch = () => {
    if (showPlaybackDetailsErrorFor === 'original') dispatch({ type: GET_ORIGINAL_PLAYBACK_DETAILS.REQUEST, payload: { session_id, retries: playbackDetails.retries } });
    if (showPlaybackDetailsErrorFor === 'edited') dispatch({ type: GET_EDITED_PLAYBACK_DETAILS.REQUEST, payload: { session_id, retries: playbackDetails.retries } });
    togglePlaybackDetailsError('');
  };

  const onExit = () => {
    dispatch({ type: CLEAR_PLAYBACK_DATA });
    history.push(`/${localStorage.updatedLocale}/home`);
  };

  // Get playback details at the start of playback
  useEffect(() => {
    // if playback is opened directly from classroom, delay calling api since audio and data might take time to get ready
    const delayCallBy = history.location.search === '?fromClass' ? DELAY_PLAYBACK_CALL_BY : 0;
    dispatch({ type: GET_ORIGINAL_PLAYBACK_DETAILS.REQUEST, payload: { session_id, delayCallBy } });
    return () => {
      dispatch({ type: CLEAR_PLAYBACK_DATA });
    };
  }, []);

  useEffect(() => {
    if (error && error.editedPlaybackDetails && isEmpty(editedPlaybackDetails.data) && !showPlaybackDetailsErrorFor) {
      togglePlaybackDetailsError('edited');
    }
  }, [error]);

  useEffect(() => {
    if (error && error.playbackDetails && isEmpty(playbackDetails.data) && !showPlaybackDetailsErrorFor) {
      togglePlaybackDetailsError('original');
    }
  }, [error]);

  useEffect(() => {
    setPlaybackDetailsLoadingState(editedPlaybackDetails.loading);
  }, [editedPlaybackDetails.loading]);

  useEffect(() => {
    if (editedPlaybackDetails.data) {
      setHasSections(editedPlaybackDetails?.data?.[0]?.playback_section?.length);
    }
  }, [editedPlaybackDetails.data]);

  // this logic is written here to handle sessions which were prepared before trimming release #backwardCompatibility
  return (
    <>
      {isPlaybackDetailsLoading
        ? <PlaybackLoadingModal hasSections={hasSections} />
        : showPlaybackDetailsErrorFor
          ? <PlaybackDetailsErrorModal customMessage={showPlaybackDetailsErrorFor === 'edited' ? error.editedPlaybackDetails : error.playbackDetails} retries={editedPlaybackDetails.retries} retry={retryFetch} onExit={() => onExit()} />
          : hasSections
            ? <PlaybackContainer typeOfPlayback={typeOfPlayback} />
            : <OldPlay history={history} match={match} />}
    </>
  );
}

Play.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default Play;

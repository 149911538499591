import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash-es/debounce';
import isNaN from 'lodash-es/isNaN';
import { Row, Column, Button, Search, Badge } from '@noon/atom';
import { TOAST_TYPE, addToast } from '../Toast';
import { translationText } from '../../helpers';
import { translationType } from '../../types';

export default class BookForLesson extends Component {
  static propTypes = {
    noonText: translationType.isRequired,
    bookPages: PropTypes.arrayOf(PropTypes.object).isRequired,
    noonText: translationType.isRequired,
    textAddToCanvas: PropTypes.string.isRequired,
    addToCanvas: PropTypes.func.isRequired,
    searchBookPage: PropTypes.func.isRequired,
    isSearching: PropTypes.bool.isRequired,
    searchPlaceholder: PropTypes.string.isRequired,
    noBookPageFoundMessage: PropTypes.string.isRequired,
  };

  state = {
    search: '',
  };

  constructor(props) {
    super(props);
    const { searchBookPage } = this.props;
    searchBookPage();
  }

  selectThisBookPage = (bookPage) => {
    const { addToCanvas } = this.props;
    const data = {
      resourceId: bookPage.id,
      resourceType: 'bookPage_image',
      url: bookPage.image_uri,
    };
    addToCanvas(data);
  };

  searchByNumber = (pageNumber) => {
    const number = Number(pageNumber);
    this.setState({ search: pageNumber });
    if (pageNumber && isNaN(number)) {
      addToast(translationText(this.props.noonText, 'error.enterNumber'), TOAST_TYPE.ERROR);
      return;
    }
    const { searchBookPage } = this.props;
    searchBookPage(pageNumber);
  };

  debouncedSearchByString = debounce(this.searchByNumber, 500);

  handleUserInput = (e) => {
    const { value } = e.target;
    this.debouncedSearchByString(value);
    e.preventDefault();
  };

  render() {
    const {
      bookPages,
      textAddToCanvas,
      isSearching,
      searchPlaceholder,
      noBookPageFoundMessage,
    } = this.props;
    const { search } = this.state;
    return (
      <div className="folder-book">
        <div className="folder-book__search">
          <Search
            name="book-search"
            autoComplete="off"
            loading={isSearching}
            placeholder={searchPlaceholder}
            onChange={this.handleUserInput}
          />
        </div>
        <div className="folder-book__pages">
          {bookPages && bookPages.length ? (
            <Row className="folder-book__list">
              {bookPages.map((bookPage) => (
                <Column
                  key={bookPage.id}
                  className="folder-book__list-item"
                  >
                  <Badge rounded color="primary" size="xs">
                    {bookPage.page_number - bookPage.offset}
                  </Badge>
                  <img
                    alt={bookPage.name}
                    src={bookPage.image_uri || bookPage.image_thumbnail_uri}
                    data-resource-id={bookPage.id}
                    data-resource-type="bookPage_image"
                    />
                  <Button
                    className="animate slideInUp"
                    rounded
                    type="primary"
                    size="sm"
                    onClick={() => this.selectThisBookPage(bookPage)}
                    onTouchStart={() => this.selectThisBookPage(bookPage)}
                    >
                    {textAddToCanvas}
                  </Button>
                </Column>
              ))}
            </Row>
          ) : (
            search.length && !isSearching ? <div className="no-page mt-2 plr-1">{noBookPageFoundMessage}</div> : ''
          )}
        </div>
      </div>
    );
  }
}

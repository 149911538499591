/* eslint-disable no-undef */
/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash-es/isEmpty';
import { IconCanvasConfirm, IconCanvasDiscard, IconLoader, IconPicture } from '@noon/quark';
import ContentEditable from 'react-contenteditable';
import { Column, Row } from '@noon/atom';
import classNames from 'classnames';
import { UPLOAD_FILE } from '../../redux/constants';
import { addToast, TOAST_TYPE } from '../Toast';
import { getCountryId } from '../../constants';

class NoonEditor extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tools: {
        italic: false,
        bold: false,
        underline: false,
      },
      isUploading: false,
      showArabicNumpad: false,
    };
    this.count = 1;
    this.countryId = getCountryId();
  }

  componentDidMount() {
    // this.latexKeyboard = new GuppyOSK({ attach: 'focus' });
    // eslint-disable-next-line no-undef
    const { id } = this.props;
    document.querySelector(`.noon-editor-${id}`)?.addEventListener('paste', this.clearFormatting);
  }

  clearFormatting = (event) => {
    event.preventDefault();
    document.execCommand('inserttext', false, event.clipboardData.getData('text/plain'));
  }

  componentDidUpdate(prevProps) {
    const { id, uploadImageUrls, active, value, fileError, showLatexKeyboard } = this.props;
    if (!isEmpty(uploadImageUrls) && prevProps.uploadImageUrls !== uploadImageUrls && active) {
      const img = `${value}<div><img src="${uploadImageUrls.medium_url || uploadImageUrls.small_url}" height="${id.indexOf('question') !== -1 ? '180px' : '120px'}" alt="question" /></div><div>&nbsp;</div>`;
      const ele = document.querySelector(`.noon-editor-${id}`);
      ele.innerHTML = img;
      ele.focus();
      ele.scrollTop = ele.scrollHeight - ele.getBoundingClientRect().height;
      ele.style.height = `${ele.scrollHeight}px`;
      this.updateChange(img);
      this.setState({ isUploading: false });
    }
    if (!isEmpty(fileError) && prevProps.fileError.uploadImageUrls !== fileError.uploadImageUrls && active) {
      this.setState({ isUploading: false });
      addToast('Network Error', TOAST_TYPE.ERROR);
    }
    if (prevProps.active !== active && !active && showLatexKeyboard) {
      this.closeEquation();
    }

    if (!prevProps.showLatexKeyboard && showLatexKeyboard && this.kbId) {
      this.guppyEditor = new Guppy(`noon-kb-${this.kbId}`);
      this.guppyKeyboard = new GuppyOSK({ attach: 'focus' });
      this.guppyKeyboard.attach(this.guppyEditor);
      this.guppyEditor.activate();
    } else if (!showLatexKeyboard && this.kbId) {
      this.closeEquation();
    }
  }

  componentWillUnmount() {
    if (this.guppyKeyboard) this.guppyKeyboard.detach();
    const { id } = this.props;
    document.querySelector(`.noon-editor-${id}`)?.removeEventListener('paste', this.clearFormatting);
  }

  handleEdit = (evt) => {
    this.updateChange(evt.target.value);
  }

  updateChange = (value) => {
    const { id, onChange } = this.props;
    onChange(id, value);
  }

  handleEditorClick = () => {
    const { id } = this.props;
    if (document.querySelector(`.noon-editor-${id}`).innerHTML === '') {
      return;
    }
    const rng = window.getSelection().getRangeAt(0);
    const hash = {
      B: 'bold',
      STRONG: 'bold',
      I: 'italic',
      U: 'underline',
    };
    const toolStates = {};
    for (const key in hash) {
      if (hash[key]) {
        toolStates[hash[key]] = false;
      }
    }
    let node = rng.commonAncestorContainer.parentNode;
    while (node) {
      if (hash[node.tagName]) toolStates[hash[node.tagName]] = true;
      node = node.parentNode;
    }
    this.setState({ tools: toolStates });
    document.execCommand('formatBlock', false, 'span');
  }

  toggleTools = (e, tool) => {
    e.preventDefault();
    const { tools } = this.state;
    for (const key in tools) {
      if (tools[key] && tool !== key) {
        console.log(key, tool);
        document.execCommand(key, false);
        this.setState((prevProps) => ({ tools: { ...prevProps.tools, [key]: false } }));
      }
    }
    document.execCommand(tool, false);
    this.setState((prevProps) => ({ tools: { ...prevProps.tools, [tool]: !prevProps.tools[tool] } }));
  }

  fileChangedHandler = (e) => {
    const { uploadImage } = this.props;
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const fd = new FormData();
    fd.append('destination', 'questions');
    const nameSplit = file.name.split('.');
    const ext = nameSplit[nameSplit.length - 1];
    const originalName = nameSplit[0];
    fd.append('file_name', `${originalName}.${ext}`);
    fd.append('fileUpl', file, file.name);
    this.setState({ isUploading: true });
    // uploadImage({ name: 'library', fd, fileName: originalName, ext });
    uploadImage(fd);
    e.preventDefault();
  };

  handleFocus = () => {
  }

  handleBlur = () => {
    this.lastCursor = this.saveSelection();
  }

  handleKeyUp = () => {
    const { id } = this.props;
    if (document.querySelector(`.noon-editor-${id}`).innerHTML[0] !== '<') {
      document.execCommand('formatBlock', false, 'div');
    }
  }

  enableLatexKeyboard = (e) => {
    const { dataset = {} } = e.target;
    const { name, kbId } = dataset;
    if (name === 'equation' && kbId) {
      this.kbId = kbId;
      const { onChangeLatexKeyboardState } = this.props;
      onChangeLatexKeyboardState(true);
    }
  }

  saveSelection() {
    if (window.getSelection) {
      const sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        return sel.getRangeAt(0);
      }
    } else if (document.selection && document.selection.createRange) {
      return document.selection.createRange();
    }
    return null;
  }

  restoreSelection(range) {
    if (range) {
      let sel;
      if (window.getSelection) {
        sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (document.selection && range.select) {
        range.select();
      }
    }
  }

  insertEqAtCursor = (text) => {
    if (!text.trim()) return;
    const { id } = this.props;
    let latex = text.replace(/\\phantom{\\tiny{!}}/gi, '');
    latex = latex.replace(/\\dfrac/gi, '\\frac');
    document.execCommand('InsertHTML', false, '&nbsp;~~~~~');
    const eq = `<code id="noon-latex-${id}-${this.count}" class="katex" data-latex="${latex}">${document.querySelector(`#noon-kb-${id} > span > span.katex-html`).innerHTML}</code><span>&nbsp;</span>`;
    let html = document.querySelector(`.noon-editor-${id}`).innerHTML;
    html = html.replace('~~~~~', eq);
    this.updateChange(html);
    this.count++;
  }

  insertTextAtCursor = (e) => {
    if (this.lastCursor) {
      this.restoreSelection(this.lastCursor);
    }
    const { id } = this.props;
    document.querySelector(`.noon-editor-${id}`).focus();
    document.execCommand('insertText', false, e.target.innerText.trim());
    const html = document.querySelector(`.noon-editor-${id}`).innerHTML;
    this.updateChange(html);
  }

  insertEquation = () => {
    const { id, onChangeLatexKeyboardState } = this.props;
    const eq = this.guppyEditor.latex();
    this.kbId = null;
    onChangeLatexKeyboardState(false);
    this.guppyEditor.deactivate();
    this.guppyKeyboard.detach();
    if (this.lastCursor) {
      this.restoreSelection(this.lastCursor);
    }
    document.querySelector(`.noon-editor-${id}`).focus();
    this.insertEqAtCursor(eq);
  }

  closeEquation = () => {
    const { id, onChangeLatexKeyboardState } = this.props;
    this.kbId = null;
    onChangeLatexKeyboardState(false);
    if (this.lastCursor) {
      this.restoreSelection(this.lastCursor);
    }
    this.guppyEditor.deactivate();
    this.guppyKeyboard.detach();
    document.querySelector(`.noon-editor-${id}`).focus();
  }

  toggleArabicNumPad = () => {
    const { showArabicNumpad } = this.state;
    this.setState({ showArabicNumpad: !showArabicNumpad });
  }

  render() {
    const { className, id, onClick, active, placeholder, value, showToolbar, disabled, showLatexKeyboard } = this.props;
    const { isUploading, tools, showArabicNumpad } = this.state;

    return (
      <Column nowrap className={classNames('ne__wrapper', { active: active && showToolbar }, className)} onClick={onClick}>
        <ContentEditable
          className={`ne__input noon-editor-${id}`}
          disabled={disabled}
          html={value}
          data-placeholder={placeholder}
          onClick={this.handleEditorClick}
          onChange={this.handleEdit}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onKeyUp={this.handleKeyUp}
        />
        {showToolbar && (
          <Row nowrap align="center" className="ne__toolbar">
            <div className="ne__toolbar__item ne__toolbar--numpad" onClick={() => { this.toggleArabicNumPad(); }}>
              <span> ١ </span>
            </div>
            {showArabicNumpad && (
              <Column nowrap className="ne__toolbar__numpad">
                <Row className="numpad-row" flex="1" align="center" justify="center" nowrap>
                  <Column className="numpad-item" nowrap flex="1" gap onClick={this.insertTextAtCursor}> ١ </Column>
                  <Column className="numpad-item" nowrap flex="1" gap onClick={this.insertTextAtCursor}> ٢ </Column>
                  <Column className="numpad-item" nowrap flex="1" gap onClick={this.insertTextAtCursor}> ٣ </Column>
                </Row>
                <Row className="numpad-row" flex="1" align="center" justify="center" nowrap>
                  <Column className="numpad-item" nowrap flex="1" gap onClick={this.insertTextAtCursor}> ٤ </Column>
                  <Column className="numpad-item" nowrap flex="1" gap onClick={this.insertTextAtCursor}> ٥ </Column>
                  <Column className="numpad-item" nowrap flex="1" gap onClick={this.insertTextAtCursor}> ٦ </Column>
                </Row>
                <Row className="numpad-row" flex="1" align="center" justify="center" nowrap>
                  <Column className="numpad-item" nowrap flex="1" gap onClick={this.insertTextAtCursor}> ٧ </Column>
                  <Column className="numpad-item" nowrap flex="1" gap onClick={this.insertTextAtCursor}> ٨ </Column>
                  <Column className="numpad-item" nowrap flex="1" gap onClick={this.insertTextAtCursor}> ٩ </Column>
                </Row>
                <Row className="numpad-row" flex="1" align="center" justify="center" nowrap>
                  <Column className="numpad-item" onClick={this.insertTextAtCursor}> ٠ </Column>
                </Row>
              </Column>
            )}
            <span data-cmd="bold" onMouseDown={(e) => this.toggleTools(e, 'bold')} className={classNames('ne__toolbar__item ne__toolbar--bold', { active: tools.bold })}>B</span>
            <span data-cmd="italic" onMouseDown={(e) => this.toggleTools(e, 'italic')} className={classNames('ne__toolbar__item ne__toolbar--italic', { active: tools.italic })}>I</span>
            <span data-cmd="underline" onMouseDown={(e) => this.toggleTools(e, 'underline')} className={classNames('ne__toolbar__item ne__toolbar--underline', { active: tools.underline })}>U</span>
            <div className="ne__toolbar__item ne__toolbar--image">
              <label htmlFor={`uploadPic-${id}`}>
                {isUploading ? <IconLoader /> : <IconPicture />}
                <input id={`uploadPic-${id}`} className="upload-img" disabled={isUploading} accept="image/*" type="file" onChange={this.fileChangedHandler} />
              </label>
            </div>
            {!showLatexKeyboard && <div data-name="equation" data-kb-id={id} onClick={this.enableLatexKeyboard} className={classNames('ne__toolbar__item ne__toolbar--equation')}>f(x)</div>}
            {showLatexKeyboard && (
              <Row nowrap className="ne__toolbar__kb">
                <div id={`noon-kb-${id}`} onBlur={this.kbBlur} />
                <div data-name="equation" onClick={this.insertEquation} className="ne__toolbar__item"><IconCanvasConfirm /></div>
                <div data-name="equation" onClick={this.closeEquation} className="ne__toolbar__item"><IconCanvasDiscard /></div>
              </Row>
            )}
          </Row>
        )}
      </Column>
    );
  }
}
NoonEditor.propTypes = {
  uploadImageUrls: PropTypes.shape(),
  uploadImage: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  fileError: PropTypes.shape().isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onClick: PropTypes.func,
  showToolbar: PropTypes.bool,
  active: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  showLatexKeyboard: PropTypes.bool.isRequired,
  onChangeLatexKeyboardState: PropTypes.func.isRequired,
};

NoonEditor.defaultProps = {
  uploadImageUrls: {},
  onClick: null,
  showToolbar: true,
  placeholder: '',
  className: '',
  value: '',
  disabled: false,
};
const mapStateToProps = (state) => ({
  uploadImageUrls: state.toJS().file.uploadImageUrls,
  fileError: state.toJS().file.error,
});

const mapDispatchToProps = (dispatch) => ({
  uploadImage: (payload) => dispatch({ type: UPLOAD_FILE.REQUEST, payload }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NoonEditor);

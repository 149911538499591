import { createSelector } from 'reselect';

const likeQuestionSelectorRaw = state => state.toJS().feedQuestion.upvoteQuestion.response;
const thankAnswerSelectorRaw = state => state.toJS().feedQuestion.thankAnswer.response;
const likeAnswerSelectorRaw = state => state.toJS().feedQuestion.thankAnswer.response;
const likeReplySelectorRaw = state => state.toJS().feedQuestion.likeReply.response;
const allQuestionSelectorRaw = state => state.toJS().feedQuestion.allQuestions.response;
const allAnswerSelectorRaw = state => state.toJS().feedQuestion.answers.response;
const allReplySelectorRaw = state => state.toJS().feedQuestion.replies.response;
const currentUpvoteIdRaw = state => state.toJS().feedQuestion.currentIdForUpvote;
const currentThankIdRaw = state => state.toJS().feedQuestion.currentThankId;
const currentReplyIdRaw = state => state.toJS().feedQuestion.currentReplyId;
const questionQueueRaw = state => state.toJS().feedQuestion.queueForQuestion;
const answerQueueRaw = state => state.toJS().feedQuestion.queueForAnswer;
const replyQueueRaw = state => state.toJS().feedQuestion.queueForReply;
const answerCountMapRaw = state => state.toJS().feedQuestion.answerCountMap;
const replyCountMapRaw = state => state.toJS().feedQuestion.replyCountMap;
const thankCountMapRaw = state => state.toJS().feedQuestion.thankCountMap;

const likeQuestionSelector = createSelector(
  likeQuestionSelectorRaw,
  item => item,
);

const thankAnswerSelector = createSelector(
  thankAnswerSelectorRaw,
  item => item,
);

const likeAnswerSelector = createSelector(
  likeAnswerSelectorRaw,
  item => item,
);

const currentUpvoteId = createSelector(
  currentUpvoteIdRaw,
  item => item,
);

const currentThankId = createSelector(
  currentThankIdRaw,
  item => item,
);

const currentReplyId = createSelector(
  currentReplyIdRaw,
  item => item,
);


const likeRepliesSelector = createSelector(
  likeReplySelectorRaw,
  item => item,
);

const allQuestionSelector = createSelector(
  allQuestionSelectorRaw,
  item => item,
);

const allAnswerSelector = createSelector(
  allAnswerSelectorRaw,
  item => item,
);

const allReplySelector = createSelector(
  allReplySelectorRaw,
  item => item,
);

const questionLikeQueueSelector = createSelector(
  questionQueueRaw,
  item => item,
);

const thankQueueSelector = createSelector(
  answerQueueRaw,
  item => item,
);

const replyQueueSelector = createSelector(
  replyQueueRaw,
  item => item,
);

const answerCountSelector = createSelector(
  answerCountMapRaw,
  item => item,
);

const replyCountSelector = createSelector(
  replyCountMapRaw,
  item => item,
);

const thankCountSelector = createSelector(
  thankCountMapRaw,
  item => item,
);

export {
  likeQuestionSelector,
  likeAnswerSelector,
  likeRepliesSelector,
  allQuestionSelector,
  allAnswerSelector,
  allReplySelector,
  currentUpvoteId,
  questionLikeQueueSelector,
  thankAnswerSelector,
  currentThankId,
  thankQueueSelector,
  currentReplyId,
  replyQueueSelector,
  answerCountSelector,
  replyCountSelector,
  thankCountSelector,
};

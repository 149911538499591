import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import classNames from 'classnames';
import { translationText } from '../../../helpers';
import { NoonLink } from '@noon/atom';

export default function BannerEgypt(prop) {
  const { noonText, className, history } = prop;
  return (
    <section className={classNames('homepage-banner banner-egypt', className)}>
      <div className="banner-caption">
        {!global && <h3 className="caption-subheading">{translationText(noonText, 'teacherLanding.bannerSubHeading')}</h3>}
        <h1 className="caption-heading">{translationText(noonText, 'teacherLanding.bannerHeading')}</h1>
        <p className="caption-para">{translationText(noonText, 'teacherLanding.bannerContent')}</p>
      </div>
      <NoonLink
        className="CTA-egypt"
        onClick={() => history.push('/login')}
        type="orange"
        size={isMobileOnly ? 'lg' : 'xl'}
        depth="2"
      >
        {translationText(noonText, 'teacherLanding.register')}
      </NoonLink>
      {/* Live sessions */}
      {prop.children}
    </section>
  );
}

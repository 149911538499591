import { createSelector } from 'reselect';

const classroomSessionMessagesSelector = (state) => state.toJS().classroom.sessionMessage;
const leaderboardDataSelector = (state) => state.toJS().classroom.leaderboardData;
const pubnubTokensSelectorRaw = (state) => state.toJS().classroom.pubnubTokens;
const allCanvasDataSelectorRaw = (state) => state.toJS().classroom.allCanvasData;
const breakoutReportSelectorRaw = (state) => state.toJS().classroom.breakoutReport;

const sessionMessagesSelector = createSelector(
  classroomSessionMessagesSelector,
  (item) => item.messages,
);

const sessionMessagesIsLoadingSelector = createSelector(
  classroomSessionMessagesSelector,
  (item) => item.loading,
);

const sessionMessagesOffsetSelector = createSelector(
  classroomSessionMessagesSelector,
  (item) => item.offset,
);

const leaderboardUserSelector = createSelector(
  leaderboardDataSelector,
  (item) => item.user,
);
const leaderboarLoadingSelector = createSelector(
  leaderboardDataSelector,
  (item) => item.isLoading,
);

const leaderboardOffsetSelector = createSelector(
  leaderboardDataSelector,
  (item) => item.offset,
);

const leaderboardTotalSelector = createSelector(
  leaderboardDataSelector,
  (item) => item.total,
);

const leaderboardTotalLiveSelector = createSelector(
  leaderboardDataSelector,
  (item) => item.totalLiveUsers,
);

const pubnubTokensSelector = createSelector(
  pubnubTokensSelectorRaw,
  (item) => item,
);

const allCanvasDataSelector = createSelector(
  allCanvasDataSelectorRaw,
  (item) => item,
);

const allCanvasDataSelectorLoading = createSelector(
  allCanvasDataSelectorRaw,
  (item) => ({ loading: item.loading, loaded: item.loaded }),
);

const breakoutReportSelector = createSelector(
  breakoutReportSelectorRaw,
  (item) => item,
);

export {
  sessionMessagesSelector,
  sessionMessagesIsLoadingSelector,
  sessionMessagesOffsetSelector,
  leaderboardUserSelector,
  leaderboarLoadingSelector,
  leaderboardOffsetSelector,
  leaderboardTotalSelector,
  leaderboardTotalLiveSelector,
  pubnubTokensSelector,
  allCanvasDataSelector,
  allCanvasDataSelectorLoading,
  breakoutReportSelector,
};

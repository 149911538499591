const typeMapping = {
  k12: ['country', 'board', 'grade', 'subject', 'curriculum', 'chapter', 'topic'],
  university: ['country', 'degree', 'major', 'course', 'section', 'chapter', 'topic'],
  k12_test_prep: ['country', 'test', 'section', 'chapter', 'topic'],
  university_test_prep: ['country', 'test', 'section', 'chapter', 'topic'],
  general_test_prep: ['country', 'test', 'section', 'chapter', 'topic'],
  k12_skill: ['country', 'skill', 'section', 'chapter', 'topic'],
  university_skill: ['country', 'skill', 'section', 'chapter', 'topic'],
  general_skill: ['country', 'skill', 'section', 'chapter', 'topic'],
};

function getSortedTags(tags, skipTypes = []) {
  const sortedTags = [];
  typeMapping[tags.type].forEach((type) => {
    if (skipTypes.includes(type)) return;
    if (tags[type] && tags[type].id) {
      sortedTags.push(tags[type]);
    } else if (tags[type] && tags[type].length) {
      sortedTags.push(tags[type][0]);
    }
  });
  return sortedTags;
}

function getTagIds(tags, skipTypes) {
  return getSortedTags(tags, skipTypes).map((item) => item.id).join('.');
}

export { getSortedTags, getTagIds };

import {
  ADD_SLIDE, DELETE_SLIDE, GET_SLIDES, INTERACTIVE_QUESTION_END, MARK_SLIDE_AS_LOADING,
  MOVE_SLIDE, SELECT_SLIDE, SET_CANVAS_DIMENTION, SET_QUESTION_TIMER, SHOW_COMPETITON_RESULT,
  SLIDE_TO_BE_ADDED, SLIDE_TO_BE_DELETED, SNED_INTERACTIVE_QUESTION, TAKE_SCREEN_SHOT,
  UPDATE_SLIDE_CONFIG,
} from '../constants';

type ISlideParams = {
  resourceType?: string,
  resource?: any,
  resourceId?: number,
  topicId?: number
}

export const getSlides = (session_id: string) => ({
  type: GET_SLIDES.REQUEST,
  payload: { session_id },
});

export const addSlide = (payload) => ({
  type: ADD_SLIDE.REQUEST,
  payload,
});

export const takeScreenShot = (payload = null) => ({
  type: TAKE_SCREEN_SHOT,
  payload,
});

export const addSlideSuccess = (payload) => ({
  type: ADD_SLIDE.SUCCESS,
  payload,
});

export const deleteSlide = (payload) => ({
  type: DELETE_SLIDE.REQUEST,
  payload,
});

export const selectSlide = (slideIndex: number) => ({
  type: SELECT_SLIDE,
  payload: slideIndex,
});

export const slideToBeAdded = (slideIndex: number, slideParams: ISlideParams) => ({
  type: SLIDE_TO_BE_ADDED,
  payload: { slideIndex, ...slideParams },
});

export const slideToBeDeleted = (slideIndex: number) => ({
  type: SLIDE_TO_BE_DELETED,
  payload: { slideIndex },
});

export const moveSlide = (state: 'REQUEST' | 'SUCCESS' | 'FAILURE', payload?: { slides: any, activeSlideIndex: number }) => ({
  type: MOVE_SLIDE[state],
  payload,
});

export const markSlideAsLoading = (payload) => ({
  type: MARK_SLIDE_AS_LOADING,
  payload,
});

export const showResult = (payload) => ({
  type: SHOW_COMPETITON_RESULT,
  payload,
});

export const setQuestionTimer = (payload: { getReadyTimeLeft?: number, questionTimeLeft?: number }) => ({
  type: SET_QUESTION_TIMER,
  payload,
});

export const questionEnded = () => ({ type: INTERACTIVE_QUESTION_END });

export const setCanvasDimention = (payload: { width: number, height: number }) => ({
  type: SET_CANVAS_DIMENTION,
  payload,
});

export const updateSlideConfig = (payload) => ({
  type: UPDATE_SLIDE_CONFIG,
  payload,
});

export const sendInteractiveQuestion = (activeSlideIndex: number) => ({
  type: SNED_INTERACTIVE_QUESTION,
  payload: activeSlideIndex,
});

import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LiveTime, Time, Row, Button, MoreActions, Badge } from '@noon/atom';
import classNames from 'classnames';
import { IconClock, IconCompetition, IconDelete, IconEditO, IconLive, IconNotAllowed, IconReplayPlay, IconTutoringNew } from '@noon/quark';
import { get } from 'lodash-es';
import { DateTime } from 'luxon';
import { translationText } from '../../../helpers';
import { COLORS } from '../../../constants';
import { ConfirmationModal } from '../../Modals';
import { ReportSession } from '../../Create';
import { addToast, TOAST_TYPE } from '../../Toast';
import { translationType } from '../../../types';

function CalendarActivitySessionCard({
  activity,
  noonText,
  handleDeleteActivity,
  deleteSession,
  handleTogglePlayback,
  toggleTeacher,
  toggleCreateActivity,
}) {
  const [actionButton, setActionButton] = useState({});
  const [timeRemainInMin, setTimeRemainInMin] = useState(0);
  const [actionItem, setActionItem] = useState('');
  const history = useHistory();
  const location = useLocation();
  let localTimeRemainingMin = Math.floor(Math.round((activity.start_time - Date.now()) / 1000) / 60);
  const isMissed = localTimeRemainingMin < -15 && activity.state !== 'started';

  const { isSessionEnded, showSessionCardAsStarted, isSessionAboutToStart } = useMemo(() => ({
    isSessionEnded: activity.state === 'ended' || activity.state === 'visible' || activity.state === 'playback' || activity.state === 'hidden' || (DateTime.local().minus({ hour: 2 }).ts > activity.start_time && activity.state !== 'started' && !isMissed),
    showSessionCardAsStarted: activity.state === 'started' && !isMissed && localTimeRemainingMin < 0 && activity.start_time < Date.now(),
    isSessionAboutToStart: (activity.state === 'scheduled' || activity.state === 'not_prepared' || activity.state === 'prepared') && localTimeRemainingMin < 10 && !isMissed,
  }), [activity]);

  const assignAction = () => {
    const timeRemaining = activity.start_time - Date.now();
    if (isSessionEnded) {
      if (activity.meta.playback_available) {
        setActionButton({ text: 'activity.watch', action: 'watch' });
      } else {
        setActionButton({ text: 'activity.viewReport', action: 'report' });
      }
    } else if (activity.state === 'scheduled' || activity.state === 'prepared' || activity.state === 'not_prepared') {
      if (isMissed) setActionButton({ text: 'activity.start', action: 'start' });
      else if (timeRemaining <= 0) setActionButton({ text: 'activity.start', action: 'start' });
      else if (activity.state === 'not_prepared') {
        setActionButton({ text: 'activity.prepare', action: 'prepare' });
      } else setActionButton({ text: 'activity.edit', action: 'modify' });
    } else {
      setActionButton({ text: 'activity.rejoin', action: 'rejoin' });
    }
  };

  const getMoreAction = () => {
    const moreActionItems = [{
      name: 'delete',
      icon: IconDelete,
      text: translationText(noonText, 'activity.delete'),
    }];
    if ((activity.state === 'scheduled' || activity.state === 'prepared' || activity.state === 'not_prepared') && timeRemainInMin > 10) {
      moreActionItems.push({
        name: 'edit',
        icon: IconEditO,
        text: translationText(noonText, 'activity.edit'),
      });
    } else if (isSessionEnded && activity.meta.playback_available) {
      moreActionItems.push({
        name: 'togglePlayback',
        icon: IconReplayPlay,
        text: translationText(noonText, `session.${activity.meta.teacher_enabled ? 'unpublish' : 'publishedNew'}`),
      });
    }
    return moreActionItems;
  };

  const takeAction = (actionName) => {
    localStorage.lastGroupUrl = JSON.stringify(location);
    if (!activity.id) {
      addToast('DATA MISSING', TOAST_TYPE.ERROR);
    }
    if (actionName === 'prepare' || actionName === 'modify' || actionName === 'rejoin' || actionName === 'start') {
      history.push(`/${localStorage.updatedLocale}/class/${activity.id}`);
    } else if (actionName === 'watch') {
      history.push(`/${localStorage.updatedLocale}/play/${activity.id}`);
    } else if (actionName === 'edit') {
      toggleCreateActivity({ type: activity.type, source: 'planner-grid-edit', sessionData: { ...activity, title: activity.name, ...activity.meta } });
    } else {
      setActionItem(actionName);
    }
    return true;
  };

  useEffect(() => {
    let sessionTimer = '';
    if (localTimeRemainingMin <= 30 && localTimeRemainingMin > 0) {
      if (sessionTimer) clearInterval(sessionTimer);
      sessionTimer = setInterval(() => {
        localTimeRemainingMin -= 1;
        setTimeRemainInMin(localTimeRemainingMin);
        if (localTimeRemainingMin < 0) {
          setTimeRemainInMin(0);
          clearInterval(sessionTimer);
        }
      }, 60000);
      setTimeRemainInMin(localTimeRemainingMin);
    } else {
      setTimeRemainInMin(localTimeRemainingMin);
    }
    assignAction();
    return () => {
      if (sessionTimer) clearInterval(sessionTimer);
    };
  }, [activity]);

  useEffect(() => {
    if (deleteSession && !deleteSession.loading && deleteSession.success) {
      setActionItem('');
    }
    if (toggleTeacher && !toggleTeacher.loading && toggleTeacher.success) {
      setActionItem('');
    }
  }, [deleteSession, toggleTeacher]);

  return (
    <div id={activity.id} className={classNames('activity-card--grid-view light_gray_bg pdb_none', { red_border: (showSessionCardAsStarted || isSessionAboutToStart) && !isMissed })}>
      {isSessionAboutToStart
        && (
          <div className="time_left_block">
            { timeRemainInMin > 0
              ? (
                <React.Fragment>
                  <p>
                    <LiveTime unit="epoch" value={activity.start_time} className="" />
                  </p>
                  <p>{translationText(noonText, 'session.minsLeft')}</p>
                </React.Fragment>
              )
              : <span>{translationText(noonText, 'session.startNow')}</span>}
          </div>
        )}
      {showSessionCardAsStarted && (
      <div className="time_left_block">
        <Row align="center">
          <IconLive height="16px" width="14px" fill={COLORS.red.base} />
          <span className="plr-1">{translationText(noonText, 'label.live')}</span>
        </Row>
        <LiveTime unit="epoch" value={activity.start_time} />
      </div>
      )}
      {isSessionEnded
      && (
      <div className="views_block for_cal">
        <div className="views">
          <img src="/assets/images/2020/playback.jpg" alt="playback still" />
          {activity.meta.playback_available
            ? <IconReplayPlay fill="#ffffff" width="21px" height="21px" />
            : (
              <IconNotAllowed width="21px" />
            )}
        </div>
      </div>
      )}
      {/* icon content */}
      {activity.type === 'group'
        ? (
          <div className="icon_content">
            <IconTutoringNew />
            <span>{translationText(noonText, 'session.typeGroup')}</span>
          </div>
        )
        : (
          <div className="icon_content competition">
            <IconCompetition />
            <span>{translationText(noonText, 'session.typeCompetition')}</span>
          </div>
        )}

      {!isSessionAboutToStart && !showSessionCardAsStarted
      && (
      <div className="icon_content time">
        <IconClock />
        <span><Time unit="epoch" humanize suffix value={activity.start_time} /></span>
      </div>
      )}
      {isMissed && !isSessionEnded
      && (
      <div className="tags">
        <Badge type="primary" value={translationText(noonText, 'session.missed')} size="sm" />
      </div>
      )}
      {isSessionEnded && !get(activity, ['meta', 'teacher_enabled'])
      && (
      <div className="tags">
        <Badge type="primary" value={translationText(noonText, 'activity.hidden')} size="sm" />
      </div>
      )}
      {/* border_block */}
      {(activity.state === 'not_prepared' || activity.state === 'scheduled') && !isMissed
      && (
      <div className="border_block">
        <p>{translationText(noonText, 'activity.notPrepared')}</p>
      </div>
      )}

      {/* content */}
      <div className="content pdb">
        <p title={get(activity, ['meta', 'group', 'title'])}>{get(activity, ['meta', 'group', 'title'])}</p>
      </div>
      <div className="button_block">
        <Button
          type={showSessionCardAsStarted || isSessionAboutToStart ? 'red' : 'secondary'}
          size="md"
          onClick={() => takeAction(actionButton.action)}>
          {translationText(noonText, `${actionButton.text}`)}
        </Button>
        {activity.state !== 'started' && (
          <div className="more-action-block">
            <MoreActions
              type="dots"
              position="below"
              className="activity"
              listActions={getMoreAction()}
              cardId={activity.id}
              onSelect={(actionName) => takeAction(actionName)}
              vertical
          />
          </div>
        )}
      </div>

      {actionItem === 'delete' && (
        <ConfirmationModal
          modalType="delete"
          successBtn={translationText(noonText, 'activity.modalYes')}
          closeBtn={translationText(noonText, 'activity.modalNo')}
          onClose={() => setActionItem('')}
          onSuccess={() => handleDeleteActivity(activity.id)}
          isLoading={deleteSession.loading}
          text={translationText(noonText, 'session.deleteHeading')}
          subText={translationText(noonText, 'session.deleteSubheading')}
        />
      )}
      {actionItem === 'togglePlayback' && (
        <ConfirmationModal
          modalType="warning"
          successBtn={translationText(noonText, 'button.yesIWantTo')}
          closeBtn={translationText(noonText, 'button.noIDontWant')}
          onClose={() => { setActionItem(''); }}
          onSuccess={() => { handleTogglePlayback(activity.id, !activity.meta.teacher_enabled); }}
          isLoading={deleteSession.loading}
          text={translationText(noonText, 'session.wantToShowPlayback')}
          subText={translationText(noonText, 'session.uCanChangeIt')}
        />
      )}
      {actionItem === 'report' && (
        <ReportSession
          onContentClose={() => setActionItem('')}
          session={activity}
          groupId={activity.group_id}
          type={activity.type}
        />
      )}
    </div>
  );
}

CalendarActivitySessionCard.propTypes = {
  activity: PropTypes.shape({}).isRequired,
  noonText: translationType.isRequired,
  handleDeleteActivity: PropTypes.func.isRequired,
  deleteSession: PropTypes.shape({}).isRequired,
  handleTogglePlayback: PropTypes.func.isRequired,
  toggleTeacher: PropTypes.shape({}),
  toggleCreateActivity: PropTypes.func.isRequired,
};
CalendarActivitySessionCard.defaultProps = {
  toggleTeacher: {},
};
export default CalendarActivitySessionCard;

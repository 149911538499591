import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconAddCircle } from '@noon/quark/dist';
import PrepareAssignmentHeader from '../../components/PrepareAssignment/prepareAssignmentHeader';
import QuestionsBar from '../../components/PrepareAssignment/questionsBar';
import HomeworkCreation from '../../components/PrepareAssignment/homeworkCreation';
import PublishModal from '../../components/PrepareAssignment/publishModal';
import { checkIsMobile, checkIsTablet } from '../../helpers/prepareAssignment';
import { CLEAR_QUESTIONS, GET_HOMEWORK_DETAILS_SPARTA, GET_QUESTIONS_BY_IDS_SPARTA, GET_TEACHER_GROUP_SELECTED } from '../../redux/constants';
import { COLORS } from '../../constants';

const PrepareAssignment = ({ match }) => {
  const [isPublishModalShown, setPublishModalShown] = useState(false);
  const [isMobileQuestionsOpened, setIsMobileQuestionsOpened] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const { currentQuestions, hw_details } = useSelector((state) => state.toJS().homeworkCreation);
  const { noonText } = useSelector((state) => state.toJS().translation);

  useEffect(() => {
    if (hw_details && hw_details.state !== 'pending' && hw_details.id === match.params.id) {
      history.goBack();
    }
    if (hw_details.question_ids && hw_details.question_ids.length > 0 && hw_details.id === match.params.id) {
      dispatch({
        type: GET_QUESTIONS_BY_IDS_SPARTA.REQUEST,
        hw_id: match.params.id,
        hw_ids: hw_details.question_ids,
      });
    } else {
      dispatch({
        type: CLEAR_QUESTIONS
      })
    }
  }, [JSON.stringify(hw_details)]);
  useEffect(() => {
    dispatch({
      type: GET_HOMEWORK_DETAILS_SPARTA.REQUEST,
      hw_id: match.params.id,
    });
    dispatch({
      type: GET_TEACHER_GROUP_SELECTED.REQUEST,
      payload: match.params.groupId,
    });
  }, []);

  return (
    <div className="prepare-assignment">
      {(checkIsTablet() || checkIsMobile()) && isMobileQuestionsOpened && <div className="prepare-assignment--modalWrapper" />}
      <PrepareAssignmentHeader noonText={noonText} setPublishModalShown={() => setPublishModalShown(true)} />
      <div className="prepare-assignment--wrapper">
        <QuestionsBar
          isMobileQuestionsOpened={isMobileQuestionsOpened}
          setIsMobileQuestionsOpened={() => setIsMobileQuestionsOpened(!isMobileQuestionsOpened)}
          groupId={match.params.groupId}
          noonText={noonText}
        />
        <HomeworkCreation
          setIsMobileQuestionsOpened={() => setIsMobileQuestionsOpened(!isMobileQuestionsOpened)}
          noonText={noonText}
        />
        {isPublishModalShown && (
          <PublishModal
            isPublishModalShown={isPublishModalShown}
            setPublishModalShown={() => setPublishModalShown(false)}
            noonText={noonText}
            hw_id={match.params.id}
          />
        )}
      </div>
      {(checkIsMobile() || checkIsTablet()) && currentQuestions.data.length > 0 && (
        <div className="add-question-button-fixed">
          <IconAddCircle fill={COLORS.brand.base} onClick={setIsMobileQuestionsOpened} width="46px" height="46px" />
        </div>
      )}
    </div>
  );
};

export default PrepareAssignment;

import React from 'react';
import classNames from 'classnames';
import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';
import { IconArrowDown, IconBlock, IconClockO, IconComment, IconDelete, IconDoc, IconDownload, IconExclamationCircle, IconLike, IconLoader, IconPdf, IconPlus, IconPpt, IconQuestionFeed, IconRemove, IconTxt, IconUnblock, IconUnblockAndAdd } from '@noon/quark';
import isEmpty from 'lodash-es/isEmpty';
import {
  Column,
  Row,
  Card,
  FormattedText,
  NoonDate,
  Avatar,
  Modal,
  MoreActions,
  Badge,
  Button,
} from '@noon/atom';
import debounce from 'lodash-es/debounce';
import { translationText } from '../../helpers';
import { Comment, HtmlInput, DummyComment, MultipleImages } from './index';
import ImageWithOrientation from '../Layout/imageWithOrientation';
import { COLORS } from '../../constants';
import Mixpanel from '../Mixpanel';
import Poll from './poll';
import PostModal from './postModal';
import Playbackcard from './Playbackcard';

class StudyGroupPostCard extends React.Component {
  static propTypes = {
    currentPostIndex: PropTypes.number.isRequired,
    clearPostIndex: PropTypes.func.isRequired,
    showMoreComments: PropTypes.func.isRequired,
    handleCommentSend: PropTypes.func.isRequired,
    noonText: PropTypes.shape().isRequired,
    emojis: PropTypes.shape().isRequired,
    user: PropTypes.shape().isRequired,
    sendCommentLoader: PropTypes.number.isRequired,
    comments: PropTypes.shape(),
    data: PropTypes.shape().isRequired,
    deleteComment: PropTypes.func.isRequired,
    postIndex: PropTypes.shape(),
    showPostView: PropTypes.func,
    isPostViewActive: PropTypes.bool,
    loadForViewMoreCommentsId: PropTypes.number.isRequired,
    deletePost: PropTypes.func.isRequired,
    isRtl: PropTypes.bool.isRequired,
    animation: PropTypes.string,
    isUploading: PropTypes.number,
    removeMember: PropTypes.func.isRequired,
    type: PropTypes.string,
    groupDetails: PropTypes.shape().isRequired,
    likePost: PropTypes.func.isRequired,
    likeComment: PropTypes.func.isRequired,
    blockUser: PropTypes.func,
    unblockUser: PropTypes.func,
    groupMembersIdMap: PropTypes.shape(),
    blockListIdMap: PropTypes.shape(),
    singlePost: PropTypes.bool,
  };

  static defaultProps = {
    showPostView: null,
    postIndex: null,
    isPostViewActive: false,
    comments: {},
    animation: '',
    type: '',
    isUploading: 0,
    blockUser: () => null,
    unblockUser: () => null,
    groupMembersIdMap: {},
    blockListIdMap: {},
    singlePost: false,
    showEndPollModal: false,
    showConfirmationModal: false,
  };

  validateYouTubeUrl(text) {
    const videoIds = [];
    if (text !== undefined || text !== '') {
      const urlsMatchRegEx = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
      const urls = text.match(urlsMatchRegEx);
      if (urls && urls.length) {
        urls.map((url) => {
          const youtubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
          const youtubeUrl = url.match(youtubeRegExp);
          if (youtubeUrl && youtubeUrl[2].length === 11) {
            videoIds.push(youtubeUrl[2]);
          }
        });
      }
    }
    return videoIds;
  }

  constructor(props) {
    super(props);
    this._nodes = [];
    this.state = {
      showMore: false,
      // eslint-disable-next-line react/no-unused-state
      totalComments: props.data && props.data.interaction_count ? props.data.interaction_count.comments : 0,
      showFullImage: -1,
      showComment: false,
      postLiked: props.data && props.data.is_liked_by_me,
      likeCount: props.data && props.data.interaction_count.likes,
      numbersToBeShown: 4,
      youtubeVideoIds: props.data && props.data.text ? this.validateYouTubeUrl(props.data.text) : [],
      optionType: {},
    };
    if (props.data.type === 'poll') {
      const checkPollStatus = props.data.type_meta.minutes_left > 0 ? 'pollAvailable' : 'pollExpired';
      this.pollActionList = [
        {
          name: checkPollStatus,
          icon: IconClockO,
          text:
            checkPollStatus === 'pollAvailable'
              ? translationText(this.props.noonText, 'poll.end_poll')
              : translationText(this.props.noonText, 'poll.extend_poll'),
        },
      ];
    }

    this.moreActionListCommon = [
      {
        name: 'delete',
        icon: IconDelete,
        header: translationText(props.noonText, 'post.deleteContent'),
        text: translationText(props.noonText, 'post.deleteSubText'),
        confirmationText: translationText(props.noonText, 'post.deleteConfirmText'),
      },
    ];

    this.moreActionListBlockUser = [
      {
        name: 'remove',
        icon: IconRemove,
        header: translationText(props.noonText, 'post.removeMember'),
        text: translationText(props.noonText, 'post.removeSubText'),
        confirmationText: translationText(props.noonText, 'post.removeConfirmText'),
      },
      {
        name: 'block',
        icon: IconBlock,
        header: translationText(props.noonText, 'post.blockMember'),
        text: translationText(props.noonText, 'post.blockSubText'),
        confirmationText: translationText(props.noonText, 'post.blockConfirmText'),
      },
    ];

    this.moreActionListForUnblockUser = [
      {
        name: 'unblock',
        text: translationText(props.noonText, 'post.unblock'),
        icon: IconUnblock,
      },
      {
        name: 'unblockAndAdd',
        text: translationText(props.noonText, 'post.unblockAndAdd'),
        icon: IconUnblockAndAdd,
      },
    ];
    this.showMoreTrue = this.showMoreTrue.bind(this);
    this.onNextOrPreviousClick = this.onNextOrPreviousClick.bind(this);
    this.handleCall = debounce(this.handleCall, 1000);
    this.commentSend = this.commentSend.bind(this);
  }

  componentWillReceiveProps({ comments, data }) {
    if (!isEmpty(comments) && this.props.comments !== comments) {
      this.setState({ totalComments: comments && comments.meta && comments.meta.total });
    }

    if (!isEmpty(data) && this.props.data !== data) {
      this.setState({
        postLiked: data.is_liked_by_me,
        likeCount: data.interaction_count.likes,
      });
      if (data.type === 'poll') {
        const checkPollStatus = data.type_meta.minutes_left > 0 ? 'pollAvailable' : 'pollExpired';
        this.pollActionList = [
          {
            name: checkPollStatus,
            icon: IconClockO,
            text:
              checkPollStatus === 'pollAvailable'
                ? translationText(this.props.noonText, 'poll.end_poll')
                : translationText(this.props.noonText, 'poll.extend_poll'),
          },
        ];
      }
    }
  }

  // eslint-disable-next-line react/sort-comp
  showMoreComments = () => {
    const { showMoreComments } = this.props;
    showMoreComments(this.props.data.id, this.props.currentPostIndex);
  };

  showThisPost = () => {
    const { showPostView } = this.props;
    showPostView(this.props.data);
  };

  showMoreTrue() {
    this.setState({ showMore: !this.state.showMore });
  }

  showFullSizeImage = (idx) => {
    this.setState({ showFullImage: idx });
  };

  removeImage = (e) => {
    if (e.target.localName !== 'img') {
      this.setState({ showFullImage: -1 });
    }
  };

  handleMoreActions = (type) => {
    const { pollOperation, type: postType } = this.props;
    if (type === 'pollExpired') {
      try {
        const { groupDetails, data } = this.props;
        Mixpanel.track('poll_extended', {
          group_id: groupDetails.id,
          group_type: 'teacher',
          group_privacy: groupDetails.type,
          group_members_count: groupDetails.joined_users_count,
          poll_question: data.text,
          poll_id: data.id,
          number_of_options: data.choices.length,
          number_of_votes: data.type_meta.total_opted,
          cause_of_expiry: 'premature',
        });
      } catch (e) {
        console.log('error', e);
      }
      pollOperation({ pollType: type, pollData: this.props.data, postType });
    } else if (type === 'pollAvailable') {
      this.setState({ showEndPollModal: true });
    } else if (type === 'delete') {
      this.setState({ optionType: this.moreActionListCommon[0] }, () => {
        this.setState({ showConfirmationModal: true });
      });
    } else {
      this.moreActionListBlockUser.find((option) => {
        if (option.name === type) {
          this.setState({ optionType: option }, () => {
            this.setState({ showConfirmationModal: true });
          });
        }
      });
    }
  };

  handleConfirmation = () => {
    const {
      data: {
        id: postId,
        user: { id: userId },
        type: postSelectedType,
      },
      deletePost,
      blockUser,
      removeMember,
    } = this.props;
    const { optionType } = this.state;
    if (optionType.name === 'delete') {
      deletePost(postId);
    } else if (optionType.name === 'block') {
      blockUser(userId);
    } else if (optionType.name === 'remove') {
      removeMember(userId);
    }
    this.removeConfirmation();

    /* if we introduce unblock in future */
    // else if (type === 'unblock' || type === 'unblockAndAdd') {
    //   unblockUser(userId, type === 'unblockAndAdd');
    // }
  }

  getFileIcon = (filetype) => {
    if (filetype.includes('image')) {
      return false;
    } if (filetype.includes('pdf')) {
      return 'pdf';
    } if (filetype.includes('doc')) {
      return 'doc';
    } if (filetype.includes('text') || filetype.includes('txt')) {
      return 'txt';
    }

    return 'ppt';
  };

  increaseCount = () => {
    this.setState((prevState) => ({
      numbersToBeShown: prevState.numbersToBeShown + 4,
    }));
  };

  sortByDate = (a, b) => new Date(a.created_at) - new Date(b.created_at);

  actionListData(isSelf) {
    this.moreActionListCommon = [...[this.moreActionListCommon[0]]];
    if (isSelf) {
      if (this.pollActionList) {
        return [...this.moreActionListCommon, ...this.pollActionList];
      }
      return this.moreActionListCommon;
    }
    return [...this.moreActionListCommon, ...this.moreActionListBlockUser];
  }

  toggleMoreAction = (id) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ togglePopoverId: id });
  };

  commentSend(text, imgPreviewUrl, imageFile) {
    this.setState({ dummyData: { ...this.props.user, text, image: imgPreviewUrl }, showComment: true });
    this.props.handleCommentSend(
      text,
      imgPreviewUrl,
      imageFile,
      this.props.data,
      this.props.currentPostIndex,
      this.props.type,
    );
  }

  likePost = () => {
    this.setState((prevState) => {
      this.handleCall(this.props.data.id, !prevState.postLiked, this.props.groupId);

      const postLikedCopy = !prevState.postLiked;
      const likeCountCopy = !prevState.postLiked ? prevState.likeCount + 1 : prevState.likeCount - 1;
      return {
        postLiked: postLikedCopy,
        likeCount: likeCountCopy,
      };
    });
  };

  contentType = (data) => {
    const contentType = [];
    if (!isEmpty(data.files)) {
      if (data.files[0].fileType === 'attachment') {
        contentType.push('files');
      } else {
        contentType.push('images');
      }
    }
    if (data.text) {
      contentType.push('text');
    }
    return contentType;
  };

  handleCall(id, reaction, type) {
    const postData = this.props.data;
    this.props.likePost(id, reaction, type, postData);
  }

  onNextOrPreviousClick(e, currentIndex, type, lastIndex) {
    if (type === 'next') {
      if (lastIndex !== currentIndex) {
        this.setState({ showFullImage: currentIndex + 1 });
      } else {
        this.setState({ showFullImage: 0 });
      }
    } else if (currentIndex === 0) {
      this.setState({ showFullImage: lastIndex });
    } else {
      this.setState({ showFullImage: currentIndex - 1 });
    }
    e.stopPropagation();
  }

  openFile = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  };

  likeTranslation = (selfLike, likeCount) => {
    const { noonText } = this.props;
    if (selfLike) {
      if (likeCount === 1) {
        return translationText(noonText, 'post.you_liked');
      } if (likeCount === 2) {
        return translationText(noonText, 'post.you_and_person').replace('{}', likeCount - 1);
      }
      return translationText(noonText, 'post.you_and_person').replace('{}', likeCount - 1);
    }
    if (likeCount === 1) {
      return `${likeCount} ${translationText(noonText, 'post.one_person_like')}`;
    }
    return `${likeCount} ${translationText(noonText, 'post.more_than_more_person_like')}`;
  };

  checkIfLikedByTeacher = (usersData) => {
    const { liked_by_users_display } = usersData;
    if (liked_by_users_display.some((data) => data.roles.includes(4))) {
      let profilePic;
      liked_by_users_display.forEach((user) => {
        if (user.roles.includes(4)) {
          profilePic = user.profile_pic;
        }
      });
      return profilePic;
    }
    return false;
  };

  sessionAction = () => {
    const {
      data: { session },
    } = this.props;
    if (session.state === 'playback_available') {
      const splittedUrl = session.playback_url.split('/');
      const splittedUrlLength = session.playback_url.split('/').length;
      const playbackId = splittedUrl[splittedUrlLength - 1];
      this.props.history.push(`/${localStorage.updatedLocale}/play/${playbackId}`);
      // eslint-disable-next-line no-unused-expressions
      // window.open(splittedUrl, '_self');
    } else if (session.state === 'live') {
      this.props.history.push(`/${localStorage.updatedLocale}/class/${session.id || session.tutoring_logger_id}`);
    }
  };

  cancelEndPoll = () => {
    this.setState({ showEndPollModal: false });
  };

  removeConfirmation = () => {
    this.setState({ showConfirmationModal: false, optionType: {} });
  };

  showSinglePost = () => {
    if (!this.state.showComment) {
      this.props.getComments({
        id: this.props.data.id,
        start: 0,
        limit: isMobileOnly ? 20 : 3,
      });
    }
    this.setState({ showComment: !this.state.showComment });
  };

  deletePost = () => {
    const {
      data: { id: postId },
      deletePost,
    } = this.props;
    deletePost(postId);
    this.setState({ showConfirmationModal: false });
  };

  endPoll = () => {
    this.setState({ showEndPollModal: false });
    this.props.pollOperation({ pollType: 'pollAvailable', pollData: this.props.data, postType: this.props.postType });
    /* [start] mixpanel event */
    try {
      const { groupDetails, data } = this.props;
      Mixpanel.track('poll_expired', {
        group_id: groupDetails.id,
        group_type: 'teacher',
        group_privacy: groupDetails.type,
        group_members_count: groupDetails.joined_users_count,
        poll_question: data.text,
        poll_id: data.id,
        number_of_options: data.choices.length,
        number_of_votes: data.type_meta.total_opted,
        cause_of_expiry: 'premature',
      });
    } catch (e) {
      console.log('error', e);
    }
    /* [end] mixpanel event */
  };

  render() {
    const {
      data,
      noonText,
      emojis,
      comments,
      currentPostIndex,
      deleteComment,
      sendCommentLoader,
      loadForViewMoreCommentsId,
      user,
      isRtl,
      isUploading,
      removeMember,
      animation,
      type,
      singlePost,
      isArchived,
    } = this.props;

    const { showComment, postLiked, showFullImage, showEndPollModal, showConfirmationModal, youtubeVideoIds, optionType } = this.state;
    const iconSize = isMobileOnly ? '15px' : '25px';
    return (
      <React.Fragment>
        {showEndPollModal && (
          <PostModal
            text1={translationText(noonText, 'poll.endPollHeading')}
            text2={translationText(noonText, 'poll.endPollSubheading')}
            btn1={translationText(noonText, 'session.confirm')}
            btn2={translationText(noonText, 'session.cancel')}
            type="exclamation"
            onClick={this.endPoll}
            onClose={this.cancelEndPoll}
          />
        )}
        {showConfirmationModal && (
          <PostModal
            text1={optionType.header}
            text2={optionType.confirmationText}
            btn1={translationText(noonText, 'session.confirm')}
            btn2={translationText(noonText, 'session.cancel')}
            type={optionType.name}
            onClick={this.handleConfirmation}
            onClose={this.removeConfirmation}
          />
        )}
        <Card className={classNames(`study-feed study-feed__post animated ${animation}`, {})}>
          {showFullImage !== -1 && (
            <React.Fragment>
              <Modal
                className="img-wrapper"
                backDropClassName="img-backdrop"
                closeButtonCustom={(
                  <IconPlus
                    rotate="45"
                    fill="#fff"
                    className="mlr-2 mt-2"
                    size="20px"
                    onClick={this.removeImage}
                  />
                )}
                onClick={this.removeImage}
                onClose={this.removeImage}
              >
                {/* {
                  data.files.map((imgs) => <img url={imgs.original} style={{ width: '1px', height: '1px', visibility: 'hidden' }} />)
                } */}
                <Row align="center" className="full-image">
                  <div style={{ width: '30px', height: '30px', cursor: 'pointer' }}>
                    <IconArrowDown
                      rotate={!isRtl ? 90 : 270}
                      size="20px"
                      fill="white"
                      className={classNames('next', { invisible: data.files.length === 1 })}
                      onClick={(e) => this.onNextOrPreviousClick(e, showFullImage, 'previous', data.files.length - 1)}
                    />
                  </div>
                  <ImageWithOrientation
                    url={data.files[showFullImage].original}
                    files={data.files[showFullImage]}
                    onNextOrPreviousClick={this.onNextOrPreviousClick}
                  />
                  <div style={{ width: '30px', height: '30px', cursor: 'pointer' }}>
                    <IconArrowDown
                      rotate={!isRtl ? 270 : 90}
                      size="20px"
                      fill="white"
                      className={classNames('next', { invisible: data.files.length === 1 })}
                      onClick={(e) => this.onNextOrPreviousClick(e, showFullImage, 'next', data.files.length - 1)}
                    />
                  </div>
                </Row>
              </Modal>
            </React.Fragment>
          )}

          <Row
            nowrap
            align="center"
            justify="space-between"
            className={classNames('study-feed__post__info relative', {})}
          >
            <Column
              className={classNames('study-feed__post__info__icons', {
                singlePost,
              })}
            >
              {data.type === 'question' && (
                <IconQuestionFeed
                  flipX={!isRtl}
                  className="study-feed__post__info__icons--question-icon"
                  width={iconSize}
                  height={iconSize}
                  fill={COLORS.red.base}
                />
              )}
            </Column>
            <Row>
              <Avatar rounded noShadow url={data.user.profile_pic} size="48px" showChild={data.user.is_plus_user}>
                <Button rounded size="sm" className="user-plus-tag">{translationText(noonText,'groups.plus')}</Button>
              </Avatar>
              <Column nowrap justify="center" align="start" className="user mlr-1">
                <h6 className="child user__name">{data.user.name}</h6>
                <Row gap="sm" align="center" justify="start">
                  <span className="noon-dot" />
                  <p>
                    <NoonDate humanize value={data.created_at} format="dd LLL, hh:mm a" />
                  </p>
                </Row>
              </Column>
            </Row>
            <Column className="study-feed__post__info__additional">
              <Row className="study-feed__post__info__additional--action-wrapper" nowrap align="center">
                {data.type === 'poll' && data.type_meta && !data.type_meta.minutes_left && (
                  <Badge type="default" dir="ltr">
                    <IconExclamationCircle />
                    <span>{translationText(noonText, 'poll.expired')}</span>
                  </Badge>
                )}
                {!data.is_free && (
                  <Badge type="default" dir="ltr" className="plus-badge">
                    <span>{translationText(noonText, 'groups.plus')}</span>
                  </Badge>
                )}
                <MoreActions
                  type="dots"
                  className="activity"
                  listActions={this.actionListData(data.user.id === user.id)}
                  cardId={data.id}
                  onSelect={(e) => this.handleMoreActions(e)}
                  vertical
                  position={isMobileOnly ? 'right' : 'above'}
                  noonText={noonText}
                  iconSize="20px"
                  fill="black"
                  toggleMoreAction={this.toggleMoreAction}
                />
              </Row>
              {/* <Row
                className={classNames('study-feed__post__info__additional--post-date', isMobileOnly ? '' : 'm-2')}
              >
                <NoonDate
                  className="subtitle line-height mlr-05"
                  color={COLORS.brand.base}
                  humanize
                  short
                  value={data.created_at}
                />
                {', '}
                <Time color={COLORS.brand.base} value={data.created_at} unit="epoch" suffix />
              </Row> */}
            </Column>
          </Row>
          <Column className={classNames('study-feed__post__content', { 'mt-2': !isMobileOnly })}>
            {!isEmpty(data.files) && data.files[0].fileType === 'attachment' && (
              <Row className="study-feed__post__content__files" gap="sm">
                {data.files.slice(0, this.state.numbersToBeShown).map((file, index) => {
                  // eslint-disable-next-line no-unused-expressions
                  const fileUrl = file.large || file.medium || file.original;
                  const ext = fileUrl.split('.');
                  const type = ext[ext.length - 1].toUpperCase();
                  return (
                    <div key={index} className="mt-1 study-feed__post__content__files--wrapper">
                      <Row>
                        {this.getFileIcon(fileUrl) === 'doc'
                          ? <IconDoc height="5rem" width="5rem" />
                          : this.getFileIcon(fileUrl) === 'ppt'
                            ? <IconPpt height="5rem" width="5rem" />
                            : this.getFileIcon(fileUrl) === 'pdf'
                              ? <IconPdf height="5rem" width="5rem" />
                              : <IconTxt height="5rem" width="5rem" />}
                        <div className="upload-file-form__file-meta">
                          <span>{file.name}</span>
                          <span>{type}</span>
                        </div>
                      </Row>
                      <IconDownload
                        height="2rem"
                        width="2rem"
                        fill="#d6d6d6"
                        name="download"
                        onClick={() => this.openFile(fileUrl)}
                      />
                    </div>
                  );
                })}
                {data.files.length > this.state.numbersToBeShown && (
                  <div className="show-more margin-auto" onClick={this.increaseCount}>
                    {translationText(noonText, 'post.showMore')}
                  </div>
                )}
              </Row>
            )}
            {!!data.text && (
              <React.Fragment>
                <FormattedText
                  className={classNames('study-feed__post__content--text mtb-1', {
                    'flex-column': data.text.length > 400,
                  })}
                  classNameForShowMore="mt-1"
                  text={data.text}
                  showMoreAt={400}
                  breakpoint={95}
                  largeFont={isMobileOnly ? '14px' : '16px'}
                  normalFont={isMobileOnly ? '14px' : '16px'}
                  slim
                  shorten
                  shortenMsg={
                    this.state.showMore
                      ? translationText(noonText, 'post.show_less')
                      : translationText(noonText, 'post.view_more')
                  }
                  showMoreTrue={this.showMoreTrue}
                  showMoreFlag={this.state.showMore}
                />
              </React.Fragment>
            )}
            {data.auto_post_type === 'session_ended' && data.playback_meta && (
              <Playbackcard data={data.playback_meta} cardView="content" />
            )}
            {!!youtubeVideoIds && !!youtubeVideoIds.length && (
              <Row className="youtube-videos-frames">
                <Row nowrap gap="sm" align="center" className="youtube-videos-frames-scroll">
                  {youtubeVideoIds.map((videoId) => (
                    <iframe
                      key={videoId}
                      className="mt-1 mr-1"
                      title="Youtube videos"
                      src={`https://youtube.com/embed/${videoId}?rel=0`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                  />
                  ))}
                </Row>
              </Row>
            )}
            {!isEmpty(data.files) && data.files[0].fileType === 'image' && showFullImage === -1 && (
              <MultipleImages files={data.files} showFullSizeImage={this.showFullSizeImage} />
            )}
            {data.type === 'poll' && (
              <Poll pollData={data} disabled={!data.type_meta.minutes_left} noonText={noonText} />
            )}
          </Column>

          <Row className="mt-2 study-feed__post__footer">
            <React.Fragment>
              <Column className="post-details">
                {(!!this.state.likeCount || !!this.state.totalComments || data.type === 'poll') && (
                  <Row className={classNames('plr-2 mb-2 post-details__counts')}>
                    {!!this.state.likeCount && (
                      <Row align="center" className={classNames('', { invisible: !this.state.likeCount })}>
                        <Row className={classNames('post-details__counts--likes')} justify="center">
                          {this.state.postLiked && (
                            <Avatar
                              className="incoming-comments__content__likes--avatar"
                              size="20px"
                              url={user && user.profile_pic}
                              noShadow
                            />
                          )}
                          <IconLike
                            height="18px"
                            width="18px"
                            fill={postLiked ? COLORS.brand.base : COLORS.grey[2]}
                            className="mlr-05"
                          />
                          <span>{this.likeTranslation(this.state.postLiked, this.state.likeCount)}</span>
                        </Row>
                      </Row>
                    )}
                    <span>
                      {data.type === 'poll' && (
                        <span className="post-details__counts--comments">
                          {data.type_meta.total_opted}
                          {' '}
                          {translationText(noonText, 'poll.votes')}
                        </span>
                      )}
                      {!!this.state.totalComments && data.type === 'poll' && <strong className="mlr-05">.</strong>}
                      {!!this.state.totalComments && (
                        <span className="post-details__counts--comments" onClick={this.showSinglePost}>
                          {this.state.totalComments}
                          {' '}
                          {translationText(noonText, 'studyGroups.comments')}
                        </span>
                      )}
                    </span>
                  </Row>
                )}
                <Row className="post-attributes">
                  <div
                    className={classNames('post-attributes__wrapper', {
                      reacted: postLiked,
                    })}
                  >
                    <Row className={classNames('post-attributes__wrapper__general', { disabled: isArchived })} onClick={!isArchived && this.likePost}>
                      <IconLike
                        height="24px"
                        width="24px"
                        fill={postLiked ? COLORS.brand.base : COLORS.grey[1]}
                      />
                      <p
                        className="mlr-05 post-attributes__wrapper__general--text text-capitalize"
                        style={{ color: postLiked ? COLORS.brand.base : COLORS.grey[1] }}
                      >
                        {translationText(noonText, 'post.like')}
                      </p>
                    </Row>
                  </div>
                  <div className={classNames('post-attributes__wrapper', {})} onClick={this.showSinglePost}>
                    <Row className={classNames('post-attributes__wrapper__general', {})}>
                      <IconComment
                        height="24px"
                        width="24px"
                        fill={showComment ? COLORS.brand.base : COLORS.grey[1]}
                      />
                      <p
                        className="mlr-05 post-attributes__wrapper__general--text text-capitalize"
                        style={{ color: showComment ? COLORS.brand.base : COLORS.grey[1] }}
                      >
                        {translationText(noonText, 'post.comment')}
                      </p>
                    </Row>
                  </div>
                </Row>
              </Column>
            </React.Fragment>
          </Row>
          <div
            className={classNames('plr-2 ptb-1', {
              'non-empty-comments': !!this.state.totalComments,
            })}
            id={`comment-${data.id}`}
            >
            <Row justify="start">
              <HtmlInput
                inputRef={(input) => {
                  this._nodes[currentPostIndex] = input;
                }}
                sendOperation={this.commentSend}
                sendLoader={sendCommentLoader === data.id}
                isUploading={isUploading}
                noonText={noonText}
                isRtl={isRtl}
                emojis={emojis}
                user={user}
                placeholder="post.write_comment"
                node={this._nodes}
                currentPostIndex={currentPostIndex}
                type="comment"
                idOfSrc={data.id}
                showAttachment
                typeOfAttachment={type || 'normal'}
                />
            </Row>
          </div>
          {(sendCommentLoader === data.id || isUploading === data.id) && this.state.dummyData && (
            <DummyComment data={this.state.dummyData} noonText={noonText} />
          )}
          {/* && (!isMobileOnly || (isMobileOnly && isPostViewActive)) */}
          {!!comments && comments.list && showComment && (
            <React.Fragment>
              {comments.list.map((value, idx) => (
                <Comment
                  idx={idx}
                  data={value}
                  comments={comments}
                  deleteComment={deleteComment}
                  postId={data.id}
                  postData={data}
                  noonText={noonText}
                  key={value.id}
                  user={user}
                  removeMember={removeMember}
                  likeComment={this.props.likeComment}
                  type={type}
                  moreActionListBlockUser={this.moreActionListBlockUser}
                  moreActionListForUnblockUser={this.moreActionListForUnblockUser}
                  groupMembersIdMap={this.props.groupMembersIdMap}
                  blockListIdMap={this.props.blockListIdMap}
                  blockUser={this.props.blockUser}
                  unblockUser={this.props.unblockUser}
                  groupDetails={this.props.groupDetails}
                  isArchived={isArchived}
                />
              ))}
              {!!comments && comments.meta && !!comments.meta.paging && (
                <React.Fragment>
                  {loadForViewMoreCommentsId === data.id ? (
                    <Row alig="center" justify="center" className="ptb-2">
                      <IconLoader />
                    </Row>
                  ) : (
                    <Button
                      link
                      onClick={this.showMoreComments}
                      className={classNames('show-more mb-2 plr-4 mlr-2', {
                        disable: loadForViewMoreCommentsId === data.id,
                      })}
                    >
                      {translationText(noonText, 'post.show_more_comments')}
                      <IconArrowDown
                        fill={COLORS.grey[1]}
                        stroke={COLORS.grey[1]}
                        height="16px"
                        width="16px"
                      />
                    </Button>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {isEmpty(comments.list) && loadForViewMoreCommentsId === data.id && <div className="loader-linear" />}
        </Card>
      </React.Fragment>
    );
  }
}

export default StudyGroupPostCard;

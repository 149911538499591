import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-smooth-dnd';
import { useSelector, useDispatch } from 'react-redux';
import { Row } from '@noon/atom';
import { IconLoader } from '@noon/quark';
import { scroller } from 'react-scroll';
import HomeworkCreationHeader from './homeworkCreationHeader';
import { EDIT_QUESTIONS_LIST } from '../../redux/constants';
import HomeworkQuestions from './homeworkQuestions';
import StartScreenChoices from './startScreenChoices';
import { returnDuplicatePropsForMixpanelAndKafka, setHowManyTimes } from '../../helpers/prepareAssignment';
import { addToast, TOAST_TYPE } from '../Toast';
import HowToPrepareAssignmentTip from './howToPrepareAssignmentTip';
import { translationText } from '../../helpers';

const HomeworkCreation = ({ setIsMobileQuestionsOpened, noonText }) => {
  const [isTipOpen, setIsTipOpen] = useState(false);
  const logger = useRef(LoggingManager.mount({ moduleName: 'homework' })).current;
  const dispatch = useDispatch();
  const { currentQuestions, question_chapter, question_topic, hw_details } = useSelector((state) => state.toJS().homeworkCreation);
  const { loggedUser: user } = useSelector((state) => state.toJS().user);
  const { selectedGroup } = useSelector((state) => state.toJS().groupsV2);

  useEffect(() => {
    if (currentQuestions.data.length === 2 && localStorage.isTeacherShownTheTip === undefined && !isTipOpen) {
      setIsTipOpen(true);
      setHowManyTimes();
    }
  }, [currentQuestions]);

  const mixpanelKafkaEvents = () => {
    const type = 'question_added';
    const props = returnDuplicatePropsForMixpanelAndKafka(selectedGroup, user, hw_details);
    const properties = {
      number_questions_added: 1,
      no_of_questions: currentQuestions.data ? currentQuestions.data.length : 0,
      ...props,
    };
    logger.track(type, { homework: properties });
  };

  const addQuestion = (e) => {
    if (currentQuestions.data.length < 25) {
      mixpanelKafkaEvents();
      new Promise((resolve, reject) => {
        dispatch({
          type: EDIT_QUESTIONS_LIST.ADD,
          payload: {
            question: e.payload,
            chapter: question_chapter,
            topic: question_topic,
          },
        });
        currentQuestions.data.forEach(exQuestion => {
          if (exQuestion.id === e.payload.id && e.addedIndex === 1) {
            return reject();
          }
        })
        resolve('success');
      }).then(() => {
        scroller.scrollTo(`question${currentQuestions.data.length + 1}`, {
          duration: 500,
          delay: 50,
          smooth: true,
          containerId: 'containerElement',
        });
      })
      .catch(() => addToast(translationText(noonText, 'createHomework.questionBeenAdded'), TOAST_TYPE.WARNING));
    } else {
      addToast(translationText(noonText, 'createHomework.maxQuestionsLength'), TOAST_TYPE.WARNING);
    }
  };

  return (
    <div className="prepare-assignment--creation">
      {currentQuestions && currentQuestions.data.length > 0 && <HomeworkCreationHeader noonText={noonText} />}
      <Container
        groupName="questions-list"
        onDrop={(e) => addQuestion(e)}
      >
        {currentQuestions.loading
          ? (
            <Row align="center" justify="center" style={{ height: '100%' }}>
              <IconLoader name="loader" height="90px" width="90px" />
            </Row>
          )
          : currentQuestions.data.length > 0
            ? <HomeworkQuestions />
            : <StartScreenChoices noonText={noonText} setIsMobileQuestionsOpened={setIsMobileQuestionsOpened} />}
      </Container>
      {isTipOpen && currentQuestions.data.length === 2
        && <HowToPrepareAssignmentTip currentQuestions={currentQuestions} noonText={noonText} closeTip={() => setIsTipOpen(false)} />}
    </div>
  );
};

export default HomeworkCreation;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconArrowDown, IconFilter } from '@noon/quark';
import classNames from 'classnames';
import { isMobileOnly } from 'react-device-detect';
import { isEmpty } from 'lodash-es';
import { Row, Button, Card } from '@noon/atom';
import FilterGroupList from '../Common/FiltersGroupList';
import FilterCalendar from './FilterCalendar';
import { translationText } from '../../../helpers';
import CheckboxFilters from '../Common/CheckboxFilters';
import { translationType } from '../../../types';

function ActivitySidebarFilters({
  loggedUser,
  filterCount,
  disabled,
  onFilterSelection,
  groupsList,
  handleOnClearFilters,
  filters,
  noonText,
}) {
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);
  const [showFilters, setShowFilters] = useState(!isMobileOnly);

  const handleOnClickHideFilters = () => {
    setShowAdditionalFilters((prevShowAdditionalFilters) => !prevShowAdditionalFilters);
  };
  return (
    <Card className="general-planner__filters">
      {disabled && <div className="disabled-overlay" />}
      <Row align="center" justify="space-between" className="general-planner__filters-header">
        <div>
          <IconFilter width="20px" />
          <span className="mlr-1 general-planner__filters-header__title">{translationText(noonText, 'filter.title')}</span>
          {isMobileOnly
          && <IconArrowDown onClick={() => setShowFilters(!showFilters)} height="20px" width="20px" fill="#62687A" stroke="#62687A" />}
        </div>
        {!isEmpty(filters)
        && (
        <Button link size="md" onClick={handleOnClearFilters}>
          {translationText(noonText, 'filter.clear')}
        </Button>
        )}
      </Row>
      {isEmpty(groupsList)
        ? (
          <Row
            align="center"
            justify="start"
            className="full-width general-planner__filters-expand"
            gap="sm"
        >
            <span>
              {translationText(noonText, 'planner.noFilterAvailable')}
            </span>
          </Row>
        )
        : (
          <React.Fragment>
            {showFilters
          && (
          <React.Fragment>
            <FilterGroupList noonText={noonText} groupsList={groupsList} onFilterSelection={onFilterSelection} filters={filters} />
            <Row
              align="center"
              justify="center"
              className={classNames('full-width general-planner__filters-expand', { expanded: showAdditionalFilters })}
              gap="sm"
              onClick={handleOnClickHideFilters}
        >
              <span className="child">
                {`${showAdditionalFilters ? translationText(noonText, 'planner.hideFilters') : translationText(noonText, 'planner.showFilters')}`}
              </span>
              <IconArrowDown className="child" fill="#62687A" stroke="#62687A" width="16px" height="16px" rotate={showAdditionalFilters ? 180 : 0} />
            </Row>
            {showAdditionalFilters
            && (
            <React.Fragment>
              <FilterCalendar
                noonText={noonText}
                loggedUser={loggedUser}
                onFilterSelection={onFilterSelection}
                filters={filters} />
              <CheckboxFilters
                filterCount={filterCount}
                noonText={noonText}
                onFilterSelection={onFilterSelection}
                filters={filters} />
            </React.Fragment>
            )}
          </React.Fragment>
          )}
          </React.Fragment>
        )}
    </Card>
  );
}

ActivitySidebarFilters.propTypes = {
  noonText: translationType.isRequired,
  loggedUser: PropTypes.shape({}).isRequired,
  filterCount: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool.isRequired,
  onFilterSelection: PropTypes.func.isRequired,
  groupsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleOnClearFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape({}).isRequired,
};
ActivitySidebarFilters.defaultProps = {};

export default ActivitySidebarFilters;

import React from 'react';
import { Column } from '@noon/atom';
import { useDispatch } from 'react-redux';
import ReportPageMobileStats from './ReportPageMobileStats';
import ReportQuestions from './ReportQuestions';
import { GET_USER_RESPONSE } from '../../redux/constants';
import { checkIsMobile } from '../../helpers/prepareAssignment';
import ReportPageProfile from './ReportPageProfile';
import ReportPageBarStats from './ReportPageBarStats';

const ReportPageMobileUserModal = (props) => {
  const dispatch = useDispatch();
  const { details, noonText, questions, loading, profile, isOpen, setIsOpen } = props;

  const closeModal = () => {
    dispatch({
      type: GET_USER_RESPONSE.FAILURE,
    });
    setIsOpen();
  };

  return (
    <React.Fragment>
      <div
        style={{ display: checkIsMobile() ? isOpen ? 'block' : 'none' : 'none' }}
        className="prepare-assignment--modalWrapper"
        onClick={closeModal}
      />
      <Column className={`report--user-modal ${isOpen ? 'vertical-modal-open' : 'vertical-modal-closed'}`}>
        <ReportPageProfile profile={profile} isOpen={isOpen} closeModal={closeModal} />
        <ReportPageBarStats details={details} noonText={noonText} />
        <ReportQuestions
          questions={questions}
          loading={loading}
          profile={profile}
          noonText={noonText}
        />
      </Column>
    </React.Fragment>
  );
};

export default ReportPageMobileUserModal;

import React, { useState, useEffect } from 'react';
import { Column } from '@noon/atom';
import { useSelector, useDispatch } from 'react-redux';
import TabBar from '../tabBar';
import t from '../../../helpers/translate';
import FlashcardContainer from './Flashcards/flashcardContainer';
import QuestionContainer from './Questions/questionContainer';
import BookContainer from './Books/bookContainer';
import PdfContainer from './Pdfs/pdfContainer';
import { ChapterTopicMultiSelect } from '../../Curriculum';
import { setChapterTopicId, setTagId } from '../../../redux/actions/classroom';
import { getTagIds } from '../../../helpers/curriculumTags';
import { updateImage2QuestionChapterAndTopic } from '../../../redux/actions/content';

export type ITopicItem = {
  chapter_id: string,
  topic_id: string,
}

type ITab = {
  name: string,
  label: string,
  disabled?: Boolean,
}

type IContentContainerProp = {
  defaultTab?: string,
  disableTabs?: Array<string>,
};

const tabToContentMap = {
  question: <QuestionContainer />,
  flashcard: <FlashcardContainer />,
  book: <BookContainer />,
  pdf: <PdfContainer />,
};

const chapterLessonSelectorDisabledFor = ['book', 'pdf'];

const ContentContainer = (prop: IContentContainerProp) => {
  const { defaultTab = 'question', disableTabs = [] } = prop;
  const dispatch = useDispatch();

  const { group, curriculum_tags } = useSelector((state) => state.toJS().myClassroom.sessionDetails);
  const chapterId = useSelector((state) => state.toJS().content.chapterId);
  const topicId = useSelector((state) => state.toJS().content.topicId);
  const tagId = useSelector((state) => state.toJS().content.tagId);

  const [activeTab, setActiveTab] = useState(defaultTab);

  const tabList: ITab[] = [
    { name: 'question', label: t('tab', 'question') },
    { name: 'flashcard', label: t('tab', 'flashcard') },
    { name: 'book', label: t('tab', 'book') },
    { name: 'pdf', label: t('tab', 'pdf') },
  ];

  const [tabs, setTabs] = useState(tabList);

  const handleTabClick = (tab: string) => {
    if (activeTab === tab) return;
    setActiveTab(tab);
  };

  const handleSelectedTopic = (payload: ITopicItem) => {
    const { chapter_id, topic_id } = payload;
    dispatch(setChapterTopicId({ chapterId: chapter_id, topicId: topic_id }));
    dispatch(updateImage2QuestionChapterAndTopic(payload));
  };

  useEffect(() => {
    if (!chapterId && !topicId) {
      handleSelectedTopic({
        chapter_id: localStorage.getItem('content_chapter_id') || curriculum_tags?.chapter?.id,
        topic_id: localStorage.getItem('content_topic_id') || (curriculum_tags?.chapter?.type === 'chapter' ? curriculum_tags?.topics?.[0]?.id : ''),
      });
    }
    if (curriculum_tags?.chapter?.type === 'chapter' && !tagId) {
      const tagId = localStorage.getItem('content_tag_id') || getTagIds(curriculum_tags, ['chapter', 'topic']);
      dispatch(setTagId(tagId));
    }
  }, []);

  useEffect(() => {
    if (disableTabs?.length) {
      const updatedTabs = tabList.map((tab) => (disableTabs.includes(tab.name) ? { ...tab, disabled: true } : tab));
      setTabs(updatedTabs);
    }
  }, [disableTabs]);

  return (
    <Column nowrap flex="1" className="my-classroom__content">
      <div className="content--primary__chapter-lesson-selector plr-1">
        <ChapterTopicMultiSelect
          groupId={group?.id}
          disabled={chapterLessonSelectorDisabledFor.includes(activeTab)}
          onTopicSelect={handleSelectedTopic}
          preChapterId={chapterId}
          preTopicId={topicId}
        />
      </div>
      <TabBar
        tabs={tabs}
        activeTab={activeTab}
        onTabClick={handleTabClick}
      />
      {tabToContentMap[activeTab]}
    </Column>
  );
};

export default ContentContainer;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Button, Row } from '@noon/atom';
import { DateTime } from 'luxon';
import { IconMediaPlayO } from '@noon/quark';
import { omit } from 'lodash-es';
import CalendarCardHeader from './CalendarCardHeader';
import CalendarActivityCardList from './CalendarActivityCardList';
import { translationType } from '../../../types';
import { translationText } from '../../../helpers';
import EmptyPlanner from '../Common/EmptyPlanner';
import { COLORS, isRTL } from '../../../constants';

const PlannerCalendarView = ({
  noonText,
  onFilterSelection,
  activitiesList,
  toggleCreateActivity,
  handleDeleteAssignment,
  handleDeleteActivity,
  deleteSession,
  handleTogglePlayback,
  groupsList,
  filters,
  loading,
  filterCount,
  loggedUser,
  disabled,
  toggleCreateGroup,
  isGroupEmpty,
  noActivity,
  noUpcomingActivity,
  nextActivityOffset,
  noScheduledActivity,
}) => {
  const today = DateTime.local();
  const getWeekDays = (date) => {
    const weekDays = {};
    for (let index = 0; index < 7; index++) {
      const nextDate = date.plus({ days: index });
      if (today.hasSame(nextDate, 'day')) {
        weekDays[translationText(noonText, 'datetime.today')] = translationText(noonText, 'datetime.today');
      } else if (today.plus({ days: 1 }).hasSame(nextDate, 'day')) {
        weekDays[translationText(noonText, 'datetime.tomorrow')] = translationText(noonText, 'datetime.tomorrow');
      } else {
        weekDays[date.plus({ days: index }).toFormat('dd ccc, MMM')] = date.plus({ days: index }).toFormat('dd ccc');
      }
    }
    return weekDays;
  };
  const [selectedWeekStartedOn, setSelectedWeekStartedOn] = useState(() => (localStorage.getItem('selectedWeek') ? DateTime.fromISO(localStorage.getItem('selectedWeek')) : today.startOf('week').minus({ days: 1 })));
  const [selectedWeekDays, setSelectedWeekDays] = useState(() => getWeekDays(selectedWeekStartedOn));

  const handleOnWeekChange = (updatedDate) => {
    setSelectedWeekStartedOn(updatedDate);
    localStorage.setItem('selectedWeek', updatedDate.toISODate());
    const weekDays = getWeekDays(updatedDate);
    setSelectedWeekDays(weekDays);
    const weekOffset = updatedDate.diff(today.startOf('week').minus({ days: 1 }), ['weeks']).toObject();
    onFilterSelection({ week_offset: { value: weekOffset.weeks } });
  };

  const handleOnClickNextWeekOffset = () => {
    handleOnWeekChange(DateTime.local().plus({ weeks: nextActivityOffset }).startOf('week').minus({ days: 1 }));
  };

  useEffect(() => {
    const weekOffset = selectedWeekStartedOn.diff(today.startOf('week').minus({ days: 1 }), ['weeks']).toObject();
    onFilterSelection({ ...omit(filters, ['sort', 'text']), week_offset: { value: weekOffset.weeks } }, true);
  }, []);

  return (
    <Card className="mt-2 mb-4 animated schedule_card general-planner__calendar">
      <CalendarCardHeader
        className="active"
        moreBtn
        lessBtn
        handleOnWeekChange={handleOnWeekChange}
        selectedWeekStartedOn={selectedWeekStartedOn}
        onFilterSelection={onFilterSelection}
        groupsList={groupsList}
        filters={filters}
        filterCount={filterCount}
        loggedUser={loggedUser}
        disabled={disabled}
        noonText={noonText}
        />
      <EmptyPlanner
        noonText={noonText}
        toggleCreateGroup={toggleCreateGroup}
        toggleCreateActivity={toggleCreateActivity}
        isGroupEmpty={isGroupEmpty}
        noActivity={noActivity}
        noUpcomingActivity={noUpcomingActivity}
        noScheduledActivity={noScheduledActivity}
        >
        <CalendarActivityCardList
          handleDeleteAssignment={handleDeleteAssignment}
          noonText={noonText}
          activitiesList={activitiesList}
          selectedWeekDays={selectedWeekDays}
          toggleCreateActivity={toggleCreateActivity}
          handleDeleteActivity={handleDeleteActivity}
          deleteSession={deleteSession}
          handleTogglePlayback={handleTogglePlayback}
          filters={filters}
          loading={loading}
          selectedWeekStartedOn={selectedWeekStartedOn}
                  />
      </EmptyPlanner>
      {!!nextActivityOffset
      && (
      <Row nowrap className="btn-next-activities" onClick={handleOnClickNextWeekOffset}>
        <Button size="sm" rounded className="text-capitalize">
          <span>{translationText(noonText, 'teacherBreakout.coachmarkNext')}</span>
        </Button>
        <IconMediaPlayO fill={COLORS.coolGrey.base} flipX={isRTL} height="24px" width="24px" />
      </Row>
      )}
    </Card>
  );
};

PlannerCalendarView.propTypes = {
  noonText: translationType.isRequired,
  onFilterSelection: PropTypes.func.isRequired,
  activitiesList: PropTypes.shape({}).isRequired,
  toggleCreateActivity: PropTypes.func.isRequired,
  handleDeleteAssignment: PropTypes.func,
  handleDeleteActivity: PropTypes.func.isRequired,
  deleteSession: PropTypes.shape({}).isRequired,
  handleTogglePlayback: PropTypes.func.isRequired,
  groupsList: PropTypes.arrayOf(PropTypes.shape({})),
  toggleCreateGroup: PropTypes.func.isRequired,
  filters: PropTypes.shape({}),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  filterCount: PropTypes.shape({}),
  loggedUser: PropTypes.shape({}).isRequired,
  isGroupEmpty: PropTypes.bool,
  noActivity: PropTypes.bool,
  noUpcomingActivity: PropTypes.bool,
  noScheduledActivity: PropTypes.bool,
};
PlannerCalendarView.defaultProps = {
  filters: {},
  groupsList: [],
  loading: false,
  disabled: false,
  filterCount: {},
  isGroupEmpty: false,
  noActivity: false,
  noUpcomingActivity: false,
  noScheduledActivity: false,
  handleDeleteAssignment: () => {},
};
export default PlannerCalendarView;

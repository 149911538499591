import React from 'react';
import { Column } from '@noon/atom';
import { IconFlags, IconFood, IconNature, IconObjects, IconPeople, IconSmileys, IconSports, IconSymbols, IconTravel } from '@noon/quark';

export default function Smileys(prop) {
  const { emojis, selectedCategory, selectEmoji, selectedCategoryIndex } = prop;

  return (
    <Column>
      <div className="emoji-tab">
        {Object.keys(emojis).map((category, index) => (
          <span
            className="tab-item"
            key={category}
            onClick={() => {
              selectedCategoryIndex(index);
            }}
          >
            {category === 'People'
              ? <IconPeople color="#BFC3C4" width="28px" height="28px" />
              : category === 'Nature'
                ? <IconNature color="#BFC3C4" width="28px" height="28px" />
                : category === 'Smileys'
                  ? <IconSmileys color="#BFC3C4" width="28px" height="28px" />
                  : category === 'Food'
                    ? <IconFood color="#BFC3C4" width="28px" height="28px" />
                    : category === 'Sports'
                      ? <IconSports color="#BFC3C4" width="28px" height="28px" />
                      : category === 'Travel'
                        ? <IconTravel color="#BFC3C4" width="28px" height="28px" />
                        : category === 'Objects'
                          ? <IconObjects color="#BFC3C4" width="28px" height="28px" />
                          : category === 'Symbols'
                            ? <IconSymbols color="#BFC3C4" width="28px" height="28px" />
                            : <IconFlags color="#BFC3C4" width="28px" height="28px" />}
          </span>
        ))}
      </div>
      <div className="emoji-wrapper">
        {emojis[Object.keys(emojis)[selectedCategory]].map((list, index) => (
          <span
            className="emoji-item"
            key={index}
            onClick={(e) => {
              selectEmoji(list[0], e);
            }}
          >
            {list[0]}
          </span>
        ))}
      </div>
    </Column>
  );
}

import { fileApi } from '../../redux/restApi';
import { addToast, TOAST_TYPE } from '../Toast';

const fileUpload = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('tags', 'codeinfuse, medium, gist');
  formData.append('upload_preset', 'pvhilzh7');
  formData.append('api_key', '1234567');
  formData.append('timestamp', Date.now() / 1000 || 0);
  const fd = new FormData();
  fd.append('destination', 'canvas');
  const nameSplit = file.name.split('.');
  const ext = nameSplit[nameSplit.length - 1];
  const originalName = nameSplit[0];
  fd.append('fileUpl', file, file.name);
  fd.append('file_name', `${originalName}.${ext}`);
  return fileApi.post('files', fd).then((response) => {
    const { meta, success } = response.data;
    if (success) {
      return meta.large_url;
    }

  }).catch(() => {
    addToast('Unexpected error', TOAST_TYPE.ERROR);
  });
};

export default fileUpload;

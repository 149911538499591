import { Card, Column, Row } from '@noon/atom';
import { IconCrown, IconNoonPlus } from '@noon/quark';
import { get } from 'lodash-es';
import React, { Fragment } from 'react';
import t from '../../helpers/translate';
import GroupDuration from './GroupDuration';
import GroupSchedule from './GroupSchedule';

function CampaignBannerCard({ campaign }) {
  return (
    <div className="campaign-banner" id="div-campaign-banner">
      <Card className="campaign-banner__card">

        <Row gap justify="start" align="start">
          <Column flex="1" justify="start" align="start" gap="md">
            <IconNoonPlus className="child mb-1" width="112px" />
            <span className="teacher-name child"><i>{t('campaign', 'bannerTitle').replace('{teachername}', campaign.creator.name)}</i></span>
            <h2 className="ad-title child">{campaign?.title}</h2>

            <GroupDuration
              courseInfo={{ start_date: get(campaign?.course_info, ['start_date']), end_date: get(campaign.course_info, ['end_date']) }} />
            <GroupSchedule
              schedules={campaign?.course_info?.schedules} />
            <h2 className="ad-price ptb-1">
              {t('campaign', 'bannerPrice').replace('{price}', `${campaign?.subscription?.price / (10 ** campaign.subscription.decimal_places)} ${campaign?.subscription?.currency_symbol}`)}
              !
            </h2>
            <span className="ad-sub-title">{t('campaign', 'footerNote')}</span>
          </Column>
        </Row>
      </Card>
    </div>

  );
}

export default CampaignBannerCard;

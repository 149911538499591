import React from 'react';
import {} from 'redux';
import { Card, Row } from '@noon/atom';
import { useSelector, useDispatch } from 'react-redux';
// import BreakoutTeam from '../BreakoutReport/breakoutTeam';
import QuestionTimer from '../Whiteboard/questionTimer';
import BreakoutReportingArea from '../BreakoutReport/BreakoutReportingArea';
import { QNA_BREAKOUT_END_EVENT } from '../../../redux/constants.js';
import t from '../../../helpers/translate';

const BreakoutScreen = () => {

  const { timeRemaining } = useSelector((state) => state.toJS().TeamQna);
  const dispatch = useDispatch();

  return (
    <Card className="breakout-screen">
      <div className="breakout-header">
        <h4>{t('teamQnaBreakout', 'breakoutScreenTitle')}</h4>
      </div>
      <div className="breakout-body">
        <QuestionTimer
          className="timer"
          questionTime={timeRemaining}
          questionTimeLeft={timeRemaining}
          onQuestionTimeEnded={() => { dispatch({ type: QNA_BREAKOUT_END_EVENT.BEFORE }); }}
             />
        <p>{t('teamQnaBreakout', 'breakoutScreenDescription')}</p>
        {/* <Row className="breakout-teams-wrapper">
          {
          breakoutTeams.map((team) => <BreakoutTeam team={team} />)
}
        </Row> */}
        <BreakoutReportingArea />
      </div>
    </Card>
  );
};

export default BreakoutScreen;

import Logger from './logger';

const uuidv4 = require('uuid/v4');

class LoggingManager {
  constructor() {
    this.loggers = [];
    this.clientSessionId = uuidv4(); // will create this after every reload
  }

  getAllLoggers() {
    return this.loggers;
  }

  getLoggerById(id) {
    const logger = this.loggers.find((item) => item.id === id);
    return logger;
  }

  getLoggerByModuleName(moduleName) {
    const logger = this.loggers.find((item) => item.moduleName === moduleName);
    return logger;
  }

  mount(loggerParams) {
    const logger = new Logger({ ...loggerParams, clientSessionId: this.clientSessionId });
    logger.id = uuidv4();
    this.loggers.push(logger);
    return logger;
  }

  unmount(id) {
    this.loggers = this.loggers.filter((logger) => logger.id !== id);
  }
}

export default LoggingManager;

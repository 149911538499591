import React from 'react';
import Image from 'react-lazy-image';
import { Row, Column } from '@noon/atom';
import { IconIphoneFrame } from '@noon/quark';

export default function GetStartedSection() {
  return (
    <div className="section-outer-container groups-outer">
      <div className="section landing-groups">
        <Row className="landing-groups__header">
          <Row className="text">
            <h1 className="title-heading">Get Started Quickly</h1>
          </Row>
        </Row>
        <Row className="landing-groups__content">
          <Row className="group-items">
            <div className="item">
              <Column className="image-wrapper">
                <IconIphoneFrame className="frame" />
                <Image className="foreground" offset={100} source="/assets/images/new-landing/started1-v1.jpg" alt="register" />
              </Column>
              <div className="text">
                <p className="content-subheading">Join Noon academy and sign up to access all the features and be a member of the community</p>
              </div>
            </div>
            <div className="item">
              <Column className="image-wrapper">
                <IconIphoneFrame className="frame" />
                <Image className="foreground" offset={100} source="/assets/images/new-landing/started2-v1.jpg" alt="create group" />
              </Column>
              <div className="text">
                <p className="content-subheading">Once you enter, you will see a discovery tab where you can create groups and invite your students</p>
              </div>
            </div>
            <div className="item">
              <Column className="image-wrapper">
                <IconIphoneFrame className="frame" />
                <Image className="foreground" offset={100} source="/assets/images/new-landing/started3-v1.jpg" alt="start teaching" />
              </Column>
              <div className="text">
                <p className="content-subheading">Start scheduling and teaching your online lessons. In addition, hand out assignments to your students</p>
              </div>
            </div>
          </Row>
        </Row>
      </div>
    </div>
  );
}

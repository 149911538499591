import React from 'react';
import { Column, Modal } from '@noon/atom';
import { IconNoWifi } from '@noon/quark';
import t from '../../../helpers/translate';

const TestNetworkStatusOfflineModal = () => (
  <Modal>
    <Column className="no-connection-modal">
      <Column className="no-connection-modal--body" justify="center" align="center">
        <IconNoWifi width="70px" height="70px" />
        <p className="no-connection-modal--body__text1">{t('session', 'disconnectHeading')}</p>
        <p className="no-connection-modal--body__text2">{t('session', 'disconnectSubheading')}</p>
      </Column>
    </Column>
  </Modal>
);

export default TestNetworkStatusOfflineModal;

import { useContext } from 'react';
import { PlaybackAudioContext } from './context';

function usePlaybackAudio(): { } {
  const context = useContext(PlaybackAudioContext);
  if (!context) {
    console.log('Please wrap your component inside EventProvider');
  }
  return context;
}

export { usePlaybackAudio };

import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash-es/isEmpty';
import { isMobileOnly } from 'react-device-detect';
import { IconDelete, IconFlag, IconPublish } from '@noon/quark';
import { Column, Row, NoonDate, MoreActions } from '@noon/atom';
import { DateTime } from 'luxon';
import { DELETE_HOMEWORK_SPARTA, UPDATE_HOMEWORK_SPARTA } from '../../redux/constants';
import { ConfirmationModal } from '../Modals';
import { translationText } from '../../helpers';
import EditAssignmentModal from '../PrepareAssignment/editAssignmentModal';
import Mixpanel from '../Mixpanel';
import { returnDuplicatePropsForMixpanelAndKafka } from '../../helpers/prepareAssignment';

export default function CurrentAssignmentCard(prop) {
  const { data: activity_content, cardView } = prop;
  const dispatch = useDispatch();
  // const { groupId } = prop; // Use this if u want to redirect to the group
  const history = useHistory();
  const { groupId } = useParams();

  const [showItem, assignItem] = useState('');
  const [itemActions, assignitemActions] = useState([]);
  const logger = useRef(LoggingManager.mount({ moduleName: 'homework' })).current;
  const { deleteHomework, updateHomework } = useSelector((state) => state.toJS().homeworkCreation);
  const noonText = useSelector((state) => state.toJS().translation.noonText);
  const { loggedUser: user } = useSelector((state) => state.toJS().user);
  const { selectedGroup } = useSelector((state) => state.toJS().groupsV2);

  const isRtl = document.body.dir === 'rtl';
  const firstChapter = !isEmpty(activity_content.groups) && !isEmpty(activity_content.groups[0].chapters) && activity_content.groups[0].chapters[0];
  const firstTopic = !isEmpty(activity_content.groups) && !isEmpty(activity_content.groups[0].topics) && activity_content.groups[0].topics[0];

  const assignAction = () => {
    if (!itemActions.action) {
      if (activity_content.state === 'pending') {
        if (activity_content.question_ids && activity_content.question_ids.length > 0) {
          assignitemActions([{ text: 'activity.modify', action: 'prepare' }]);
        } else {
          assignitemActions([{ text: 'activity.prepare', action: 'prepare' }]);
        }
      } else {
        assignitemActions([{ text: 'activity.modify', action: 'edit' }, { text: 'form.publishNow', action: 'publish' }]);
      }
    }
  };

  useEffect(() => {
    assignAction();
  }, [activity_content.question_ids, activity_content.state]);

  const getActivityClasses = () => {
    if (activity_content.state === 'draft') {
      return `flex-row activity-card activity-card--list-view type_assignment hidden ${cardView}-page`;
    }
    return `flex-row activity-card activity-card--list-view type_assignment ${cardView}-page`;
  };

  const mixpanelKafkaEvents = (type) => {
    const props = returnDuplicatePropsForMixpanelAndKafka(selectedGroup, user, activity_content);
    const no_of_questions = activity_content && activity_content.question_ids ? activity_content.question_ids.length : 0;
    const properties = {
      ...type.kf === 'card_clicked' && { action_intended: isMobileOnly ? 'full_card_tap' : no_of_questions ? 'modify' : 'prepare' },
      no_of_questions,
      ...props,
    };
    Mixpanel.track(type.mp, properties);
    logger.track(type.kf, { homework: properties });
  };

  useEffect(() => {
    if (deleteHomework && !deleteHomework.loading && deleteHomework.success) {
      assignItem('');
    }
  }, [deleteHomework]);

  const takeAction = (actionType) => {
    if (actionType === 'edit') assignItem('edit');
    if (actionType === 'prepare') {
      const routeEnd = cardView === 'content' ? `${groupId}/${activity_content.id}` : `${activity_content.id}`;
      mixpanelKafkaEvents({ mp: 'homework_card_clicked', kf: 'card_clicked' });
      history.push(`/${localStorage.updatedLocale}/prepare-assignment/${routeEnd}`);
    } else if (actionType === 'publish') {
      mixpanelKafkaEvents({ mp: 'homework_publish_intended', kf: 'publish_intended' });
      assignItem('publish');
    } else if (actionType === 'delete') {
      assignItem('delete');
    }
  };

  const takeActionOnPhone = (action) => {
    if (isMobileOnly) {
      takeAction(action);
    }
  };

  const deleteAssignmentFn = () => {
    mixpanelKafkaEvents({ mp: 'homework_deleted', kf: 'deleted' });
    dispatch({
      type: DELETE_HOMEWORK_SPARTA.REQUEST,
      payload: activity_content.id,
      listing: 'prepublished',
    });
  };

  const publishNow = () => {
    dispatch({
      type: UPDATE_HOMEWORK_SPARTA.REQUEST,
      hw_id: activity_content.id,
      hw_details: {
        publish_now: true,
        start_date: DateTime.fromISO(new Date()).ts + 2000,
      },
    });
  };

  const getOptions = () => {
    const moreActionList = [
      {
        name: 'delete',
        icon: IconDelete,
        text: translationText(noonText, 'activity.delete'),
      },
    ];
    if (isMobileOnly) {
      moreActionList.push({
        name: 'publish',
        icon: IconPublish,
        text: translationText(noonText, 'createHomework.publishBtn'),
      });
    }
    return moreActionList;
  };

  const returnHomeworkStatus = () => {
    if (activity_content.state === 'pending') {
      if (activity_content.question_ids && activity_content.question_ids.length > 0) {
        return <span>{translationText(noonText, 'session.draft')}</span>;
      }
      return <span style={{ textTransform: 'lowercase' }}>{translationText(noonText, 'activity.notPrepared')}</span>;

    }
    if (activity_content.state === 'scheduled') {
      return (
        <React.Fragment>
          <span>
            {`
            ${translationText(noonText, 'createHomework.scheduledFor')}
            `}
            <NoonDate value={activity_content.start_date} format="MMMM dd, hh:mm a" />
          </span>
        </React.Fragment>
      );
    }
    return <span>else</span>;
  };

  const AssignmentTypeBlock = () => (
    <Row className="type-block" align="center" justify="space-evenly">
      <IconFlag height="15px" width="15px" fill="#B1F0E4" />
      <span>{translationText(noonText, 'homework.title')}</span>
    </Row>
  );

  const AssignmentTimeBlock = () => (
    <Row align="center" className="time-block" gap="sm">
      <IconFlag height="15px" width="15px" fill="#B1F0E4" />
      <NoonDate value={activity_content.due_date} format="hh:mm a" />
    </Row>
  );

  const AssignmentChapterTopicBlock = () => (
    <React.Fragment>
      {activity_content.groups && activity_content.groups[0] && (
        // <p className="chapter-topic">
        //   {firstChapter && firstChapter.name}
        //   {firstChapter && firstTopic ? ', ' : ''}
        //   {firstTopic && firstTopic.name_header}
        // </p>
        <p className="chapter-topic">
          {firstChapter && firstChapter.name_header}
          {firstChapter && firstChapter.name_header && firstTopic && firstTopic.name_header ? ', ' : ''}
          {firstTopic && firstTopic.name_header}
        </p>
      )}
    </React.Fragment>
  );

  const PlannerPageInfo = () => (
    <React.Fragment>
      <li className="card-section card-section--time" onClick={() => takeActionOnPhone(itemActions[0].action)}>
        <Column align="center" justify="center" gap="sm" flex="1" className="time-wrapper">
          <AssignmentTypeBlock />
          {activity_content.state !== 'draft' ? (
            <AssignmentTimeBlock />
          ) : (
            <div className="tags">
              <span>{translationText(noonText, 'session.draft')}</span>
            </div>
          )}
        </Column>
      </li>
      <li className="card-section card-section--main" onClick={() => takeActionOnPhone(itemActions[0].action)}>
        <Row className="main-content main-content--type-info">
          <div className="activity-status" />
          {((firstChapter && firstChapter.name_header) || (firstTopic && firstTopic.name_header)) && (
            <React.Fragment>
              <span className="noon-dot" />
              <AssignmentChapterTopicBlock />
            </React.Fragment>
          )}
        </Row>
        <Row className="main-content main-content--name-area">
          <h5>{activity_content.title}</h5>
        </Row>
      </li>
    </React.Fragment>
  );

  const ContentPageInfo = () => (
    <React.Fragment>
      <li className="card-section card-section--time" onClick={() => takeActionOnPhone(itemActions[0].action)}>
        <Column align="center" justify="center" gap="sm" flex="1" className="time-wrapper">
          <Row align="center" className="date-block" gap="sm">
            <NoonDate style={{ fontWeight: 'bold' }} humanize value={activity_content.due_date} format="MMMM, d" />
          </Row>
          {activity_content.state !== 'draft' && (
            <AssignmentTimeBlock />
          )}
        </Column>
      </li>
      <li className="card-section card-section--main" onClick={() => takeActionOnPhone(itemActions[0].action)}>
        <Row className="main-content main-content--type-info">
          <div className="activity-status">{returnHomeworkStatus()}</div>
          {(firstChapter || firstTopic) && (
            <React.Fragment>
              <span className="noon-dot" />
              <AssignmentChapterTopicBlock />
            </React.Fragment>
          )}
        </Row>
        <Row className="main-content main-content--name-area">
          <h5>{activity_content.title}</h5>
        </Row>
      </li>
    </React.Fragment>
  );

  return (
    <Row className={getActivityClasses()}>
      {showItem === 'delete' && (
        <ConfirmationModal
          modalType="delete"
          successBtn={translationText(noonText, 'activity.modalYes')}
          closeBtn={translationText(noonText, 'activity.modalNo')}
          onClose={() => assignItem('')}
          onSuccess={() => deleteAssignmentFn()}
          isLoading={deleteHomework.loading}
          text={translationText(noonText, 'teacherDashboard.deleteHomework')}
          subText={translationText(noonText, 'teacherDashboard.deleteConfirmation')}
        />
      )}
      {showItem === 'publish' && (
        <ConfirmationModal
          modalType="info"
          successBtn={translationText(noonText, 'activity.modalYes')}
          closeBtn={translationText(noonText, 'activity.modalNo')}
          onClose={() => assignItem('')}
          onSuccess={() => publishNow()}
          isLoading={updateHomework.loading}
          text={translationText(noonText, 'form.publishNow')}
          subText={translationText(noonText, 'form.publishNowSubheading')}
        />
      )}
      {showItem === 'edit' && (
        <EditAssignmentModal setIsEditOpen={() => assignItem('')} homework={activity_content} modify />
      )}
      {/* Separated out time and main sections to reduce clutter */}
      {cardView === 'content' ? <ContentPageInfo /> : <PlannerPageInfo />}
      <li className="card-section card-section--action">
        <MoreActions
          type="dots"
          position={isRtl ? 'right' : 'left'}
          className="activity"
          listActions={getOptions()}
          cardId={activity_content.id}
          onSelect={(actionName) => takeAction(actionName)}
          vertical
        />
        <Row className="data" flex="1">
          <p>
            <Column className="action-items">
              {itemActions.length && itemActions.map((item) => (
                <span key={item.text} onClick={() => takeAction(item.action)}>{translationText(noonText, item.text)}</span>
              ))}
            </Column>
          </p>
        </Row>
      </li>
    </Row>
  );
}

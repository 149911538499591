import React, { useEffect, useState } from 'react';
import { Column, Button } from '@noon/atom';
import { useSelector, useDispatch } from 'react-redux';
import WhiteboardQuestion from './whiteboardQuestion';
import { IQuestion } from '../Content/Questions/questionContainer';
import QuestionTimer from './questionTimer';
import GetReadyCard from './getReadyCard';
import { questionEnded, setQuestionTimer } from '../../../redux/actions/whiteboard';
import t from '../../../helpers/translate';
import { END_BREAKOUT } from '../../../redux/constants';

export type IQuizQuestionPayload = {
  currentCube: any;
  bot: Boolean;
  groupSessionId: string;
  canvas_id: string;
  breakout_mode?: string;
  breakout_mode_new?: string;
  label_name?: string;
  label_id?: string;
  session_state?: string;
  allow_discussion: Boolean;
};

type IWhiteboardQuestionContainerProp = {
  onQuestionEnded: Function;
  children: React.ReactNode;
  questionDetails: IQuestion;
  onShowCanvas: Function;
  showExplanationButton: Boolean;
};

const WhiteboardQuestionContainer = (prop: IWhiteboardQuestionContainerProp) => {
  const { onQuestionEnded, children, questionDetails, onShowCanvas, showExplanationButton } = prop;
  const dispatch = useDispatch();
  const { getReadyTimeLeft, questionTimeLeft } = useSelector((state) => state.toJS().whiteboard.questionTimer);
  const { width: whiteboardWidth } = useSelector((state) => state.toJS().whiteboard.whiteboardSize);
  const breakoutState = useSelector((state) => state.toJS().myClassroom.breakoutState);

  const [questionTimeEnded, setQuestionTimeEnded] = useState(false);

  const handleGetReadyTimeEnded = () => {
    dispatch(setQuestionTimer({ getReadyTimeLeft: 0 }));
  };

  const handleQuestionTimeEnded = () => {
    setQuestionTimeEnded(true);
  };

  useEffect(() => {
    if (questionTimeEnded) {
      dispatch(questionEnded());
      if (breakoutState) {
        dispatch({ type: END_BREAKOUT });
      }
      onQuestionEnded();
      setQuestionTimeEnded(false);
    }
  }, [questionTimeEnded]);

  return (
    <Column nowrap style={{ width: `${whiteboardWidth}px` }} className="whiteboard-question-wrapper">
      {!!getReadyTimeLeft && (
        <GetReadyCard
          questionNumber={questionDetails.q_no}
          questionTime={questionDetails.time || questionDetails.time_left}
          getReadyTimeLeft={getReadyTimeLeft}
          onGetReadyTimeEnded={handleGetReadyTimeEnded}
        />
      )}
      {!getReadyTimeLeft && !!questionTimeLeft && (
        <QuestionTimer
          questionTime={questionDetails.time || questionDetails.time_left}
          questionTimeLeft={questionTimeLeft}
          onQuestionTimeEnded={handleQuestionTimeEnded}
        />
      )}
      {!getReadyTimeLeft && <WhiteboardQuestion showStats={!questionTimeLeft} question={questionDetails} showSolution />}
      {showExplanationButton && (
        <Button onClick={onShowCanvas} type="primary" className="explain-canvas">
          {t('tutoring', 'explainInCanvas')}
        </Button>
      )}
      {children}
    </Column>
  );
};

export default WhiteboardQuestionContainer;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Hr } from '@noon/atom';
import { IconBook, IconCompetitionO, IconEditO, IconFeedO, IconFlashcard, IconLocation } from '@noon/quark';
import { LIST_TEACHERS } from '../../redux/constants';
import { translationType, userType, teacherListType } from '../../types';
import {
  HomePageBanner,
  FeatureFlashcard,
  FeatureCompetition,
  FeaturedTeachers,
  FeatureClassroom,
  FeatureDashboard,
  FeatureList,
  MediaSection,
} from './Partials';
import { PublicNav } from '../NavigationBar';
import Mixpanel from '../Mixpanel';
import Footer from '../Footer';

class SaudiLanding extends Component {
  static propTypes = {
    listTeachers: PropTypes.func.isRequired,
    pinnedTeachers: teacherListType.isRequired,
    noonText: translationType.isRequired,
    user: userType.isRequired,
    history: PropTypes.shape().isRequired,
  };

  componentDidMount() {
    const { listTeachers, pinnedTeachers } = this.props;

    if (!pinnedTeachers.length) {
      listTeachers();
    }

    try {
      Mixpanel.track('teacher_landing_shown', {
        landing_country: 'Saudi Arabia',
      });
    } catch (e) {
      console.log('error', e);
    }
  }

  render() {
    const { noonText, pinnedTeachers, user, history } = this.props;
    return (
      <React.Fragment>
        <div className="home-container">
          <PublicNav history={history} />
          <HomePageBanner history={history} noonText={noonText}>
            <MediaSection />
          </HomePageBanner>
          <FeatureFlashcard noonText={noonText} />
          <Hr />
          <FeatureCompetition noonText={noonText} />
          <Hr />
          <FeatureClassroom noonText={noonText} />
          {/* <FeatureList
            noonText={noonText}
            group="teacherLanding"
            icons={{ one: IconFeedO, two: IconFlashcard, three: IconEditO, four: IconCompetitionO, five: IconBook, six: IconLocation }}
          /> */}
          <Hr />
          <FeatureDashboard />
          <Hr />
          <FeaturedTeachers pinnedTeachers={pinnedTeachers} noonText={noonText} user={user} />
        </div>
        <Footer noonText={noonText} />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  pinnedTeachers: state.toJS().user.pinnedTeachers,
  noonText: state.toJS().translation.noonText,
  user: state.toJS().user.loggedUser,
});

const mapDispatchToProps = (dispatch) => ({
  listTeachers: () => dispatch({ type: LIST_TEACHERS.REQUEST }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaudiLanding);


// eg => return board for boards
function getKeyFromField(value) {
    switch (value) {
      case 'curriculum':
        return value;
      default:
        return value.slice(0, -1);
    }
  }

export const generateTags = (data, curriculumTypes) => {
    const tags = [];
    tags.push({ id: data.country.id, country_id: data.country.id });
    const combinedCurriculumTypes = curriculumTypes.reduce((acc, curr) => [...acc, ...curr.courseType], []);
    const fieldMap = new Map();
    combinedCurriculumTypes.forEach((singleCurriculumType) => {
        fieldMap.set(singleCurriculumType.curriculumType, singleCurriculumType.fields);
    });
    fieldMap.get(data.courseType?.curriculumType)?.forEach((singleField) => {
        const tag = getKeyFromField(singleField);
        const tagId = data[`${tag}`].id;
        tags.push({ id: tagId, [`${tag}_id`]: tagId });
    });
    return tags;
}
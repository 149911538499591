import React from 'react';
import { Modal, Button, Row, Column } from '@noon/atom';
import classNames from 'classnames';
import { IconClose, IconCorrect, IconDelete, IconEditO } from '@noon/quark';
import { COLORS } from '../../constants';

const CheckIcon = ({ height = '32px', width = '32px', fill = '#fff' }) => (
  <IconCorrect width={width} height={height} stroke={fill} />
);

const typeOfBtn = [
  { type: 'edit', icon: IconEditO, color: COLORS.primary.base },
  { type: 'delete', icon: IconDelete, color: COLORS.red.base },
  { type: 'success', icon: IconCorrect, color: '#fff', background: 'dark' },
];

export default function SessionGenericModal(prop) {
  const type = typeOfBtn.filter((btn) => btn.type === prop.type)[0];
  return (
    <Modal onClose={prop.onClose} className="generic-modal-container">
      <Column nowrap className="generic-modal">
        {!prop.btn2 && <IconClose onClick={prop.onClose} />}
        <Column align="center" className="generic-modal--body">
          <div className={classNames('header-icon', { dark: type.background === 'dark' })}>
            {type.icon === 'correct' ? <CheckIcon width="40px" height="40px" fill={type.color} /> : <type.icon width="40px" height="40px" fill={type.color} stroke={type.color} />}
          </div>
          <div className="generic-modal--body--wrapper">
            <Column className="generic-modal--body--text" align="center">
              <div className="text-1">{prop.text1}</div>
              {!!prop.text2 && <div className="text-2">{prop.text2}</div>}
            </Column>
            <Row nowrap align="center" justify="center" gap="lg" className="generic-modal--body--btn">
              <Button
                type="primary"
                size="large"
                loading={!!prop.isLoading}
                onClick={prop.onClick}
                className="btn-modal"
              >
                {prop.btn1}
              </Button>
              <Button
                type="secondary"
                size="large"
                disabled={!!prop.isLoading}
                onClick={prop.onClose}
                className="btn-modal">
                {prop.btn2}
              </Button>
            </Row>
          </div>
        </Column>
      </Column>
    </Modal>
  );
}

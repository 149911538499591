import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconDelete } from '@noon/quark';
import { Column, Row } from '@noon/atom';
import { Element } from 'react-scroll';
import { EDIT_QUESTIONS_LIST } from '../../redux/constants';
import { translationText } from '../../helpers';
import NoonQuestion from '../NoonQuestion/NoonQuestion';
import QuestionChoice from '../NoonQuestion/QuestionChoice';
import { returnDuplicatePropsForMixpanelAndKafka } from '../../helpers/prepareAssignment';

const HomeworkSingleQuestion = (props) => {
  const { question, index } = props;

  const logger = useRef(LoggingManager.mount({ moduleName: 'homework' })).current;

  const dispatch = useDispatch();
  const { noonText } = useSelector((state) => state.toJS().translation);
  const { hw_details, currentQuestions } = useSelector((state) => state.toJS().homeworkCreation);
  const { loggedUser: user } = useSelector((state) => state.toJS().user);
  const { selectedGroup } = useSelector((state) => state.toJS().groupsV2);

  const mixpanelKafkaEvents = () => {
    const type = 'question_deleted';
    const props = returnDuplicatePropsForMixpanelAndKafka(selectedGroup, user, hw_details);
    const properties = {
      no_of_questions: currentQuestions.data ? currentQuestions.data.length : 0,
      ...props,
    };
    logger.track(type, { homework: properties });
  };

  const deleteQuestion = (index) => {
    mixpanelKafkaEvents();
    return dispatch({
      type: EDIT_QUESTIONS_LIST.DELETE,
      payload: {
        index,
      },
    });
  };

  return (
    <Element
      name={`question${index + 1}`}
    >
      <Column align="center" className="prepare-assignment--singleQuestion">
        <div className="prepare-assignment--singleQuestion--header">
          <div>
            <span className="prepare-assignment--singleQuestion--header--question">
              {translationText(noonText, 'post.question')}
              {' '}
              {index + 1}
              :
              {' '}
            </span>
            <span>
              {question.chapter_info && question.chapter_info.name}
              ,
              {' '}
              {question.topic_info && question.topic_info.name}
            </span>
            <span className="prepare-assignment--singleQuestion--header--hardness">
              {translationText(noonText, 'createHomework.hardness')}
              :
              {' '}
              {question.difficulty && question.difficulty}
            </span>
          </div>
          <div className="prepare-assignment--singleQuestion--header--icons">
            <div onClick={() => deleteQuestion(index)}>
              <IconDelete width="14px" height="14px" />
            </div>
          </div>
        </div>
        <span>{question.title}</span>
        <div className="prepare-assignment--singleQuestion--question">
          {question.question_json && question.question_json.length ? (
            <NoonQuestion dir={question.text_direction} content={question.question_json} />
          ) : (
            <div
              dir={question.text_direction}
              dangerouslySetInnerHTML={{ __html: question.question }}
              />
          )}
        </div>
        <div className="prepare-assignment--singleQuestion--choices">
          {question.choices.map((choice) => (
            <Column key={choice.id}>
              {choice.is_correct ? <span className="correct-answer">{translationText(noonText, 'homeworkReport.correctAnswer')}</span> : ''}
              <div
                key={choice.id}
                className={`${choice.is_correct ? 'choice right-answer' : 'choice'}`}
                >
                {choice.answer_json && choice.answer_json.length ? (
                  <QuestionChoice question={question} choice={choice} />
                ) : (
                  <div className="choiceBox">
                    <p className="choiceMatter" dangerouslySetInnerHTML={{ __html: choice.answer }} />
                  </div>
                )}
              </div>
            </Column>
          ))}
        </div>
      </Column>
    </Element>
  );
};

export default HomeworkSingleQuestion;

import React, { useState } from 'react';
import { Card, Button, Column } from '@noon/atom';
import { useSelector } from 'react-redux';

import t from "../../../helpers/translate";

const CreateQuestionCTA = ({ onClickCreateTextQuestion, onClickCreateImageQuestion }) => {
  const loggedUser = useSelector((state) => state.toJS().user.loggedUser);

  const [showCreateQuestionOption, setShowCreateQuestionOption] = useState(false);

  const handleOnClickCreateQuestion = () => {
    setShowCreateQuestionOption((prevState) => !prevState);
  };

  return (
    <Card className="create-question-cta">
      <p className="cta-text text-center mb-2">
        {t('library', 'createQuestionCtaTitle')}
      </p>
      <Button type="primary" size="lg" onClick={handleOnClickCreateQuestion}>
        <svg className="icon icon-plus" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M12 4C12.5523 4 13 4.44772 13 5L13 19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19L11 5C11 4.44772 11.4477 4 12 4Z" fill="#141414" />
          <path fillRule="evenodd" clipRule="evenodd" d="M4 12C4 11.4477 4.44772 11 5 11L19 11C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13L5 13C4.44772 13 4 12.5523 4 12Z" fill="#141414" />
        </svg>
        {t("library", "addQuestionTitle")}
        <svg style={{ rotate: !showCreateQuestionOption ? "180deg" : "0deg" }} className="icon icon-arrow" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M11.5285 6.52864C11.7889 6.26829 12.211 6.26829 12.4713 6.52864L20.4713 14.5286C20.7317 14.789 20.7317 15.2111 20.4713 15.4714C20.211 15.7318 19.7889 15.7318 19.5285 15.4714L11.9999 7.94285L4.47132 15.4714C4.21097 15.7318 3.78886 15.7318 3.52851 15.4714C3.26816 15.2111 3.26816 14.789 3.52851 14.5286L11.5285 6.52864Z" fill="#141414" />
        </svg>
      </Button>
      {showCreateQuestionOption && (
      <Card className="create-question__link-card">
        <Column className="px-2 py-2">
          <div
            className="create-question__link-card__link"
            onClick={() => {
              handleOnClickCreateQuestion();
              onClickCreateTextQuestion();
            }}>
            {t("library", "addQuestionTitle")}
          </div>
          <div
            className="create-question__link-card__link"
            onClick={() => {
              handleOnClickCreateQuestion();
              onClickCreateImageQuestion();
            }}>
            {t('library', 'createQuestionCtaImage')}
          </div>
        </Column>
      </Card>
      )}
    </Card>
  );
};

export default CreateQuestionCTA;

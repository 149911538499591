import {
  LIST_COUNTRY,
  CLEAR_USER_SPECIFIC_FOLDER_DATA,
  SEARCH_PAGE,
} from '../constants';

const initialState = {
  folderDetailsById: {},
  bookPages: [],
  countries: {},
  heirarchies: {},
  error: {},
  folderList: [],
};

export default function folder(state = initialState, action) {

  switch (action.type) {
    case CLEAR_USER_SPECIFIC_FOLDER_DATA: {
      return {
        ...state,
        error: {},
      };
    }
    case LIST_COUNTRY.SUCCESS:
      localStorage.country = JSON.stringify(action.payload);
      return {
        ...state,
        countries: action.payload,
      };
    case LIST_COUNTRY.FAILURE:
      return {
        ...state,
        error: { countries: action.payload },
      };
    case SEARCH_PAGE.SUCCESS:
      return {
        ...state,
        bookPages: action.payload,
      };
    case SEARCH_PAGE.FAILURE:
      return {
        ...state,
        error: { bookPages: action.payload },
      };
    default:
      if (action.type.indexOf('_REQUEST') !== -1) {
        return {
          ...state,
          error: {},
        };
      }
      return state;
  }
}

import React, { memo, useEffect, useState, useMemo, useCallback } from 'react';
import { Row } from '@noon/atom';
import isEmpty from 'lodash-es/isEmpty';
import { IconFlags, IconFood, IconNature, IconObjects, IconPeople, IconSmileys, IconSports, IconSymbols, IconTravel } from '@noon/quark';
import { useDispatch, useSelector } from 'react-redux';
import { useOnClickOutside } from '../../hooks';
import { GET_EMOJI } from '../../redux/constants';
import { isRTL, COLORS } from '../../constants';

const EmojiCard = (prop) => {
  const { onSelect, footerContainerRef, onClose } = prop;

  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState(0);
  const emojis = useSelector((state) => state.toJS().file.emojis);

  const onClickOutside = useCallback(() => {
    onClose();
  }, []);

  // putting this logic inside , so that on unmount it stop listing mouse down events,
  // else it keeps calling onClickOutside function even when teacher clicking on canvas.
  useOnClickOutside(footerContainerRef, onClickOutside);

  useEffect(() => {
    if (isEmpty(emojis)) {
      dispatch({ type: GET_EMOJI.REQUEST });
    }
  }, []);

  const getIndicatorStyle = useMemo(() => ({ transform: `translateX(${isRTL() ? -(selectedCategory * 100) : selectedCategory * 100}%)` }), [selectedCategory, isRTL]);
  const emojiKeys = useMemo(() => Object.keys(emojis), [emojis]);

  return (
    <div className="emoji-card flex-column flex-nowrap">
      {Boolean(emojiKeys && emojiKeys.length) && (
        <Row className="emoji-list">
          {emojis[emojiKeys[selectedCategory]].map((list, index) => (
            <div
              className="emoji-list__item"
              key={index}
              onClick={() => onSelect(list[0])}
              >
              {list[0]}
            </div>
          ))}
        </Row>
      )}
      <Row nowrap align="center" className="emoji-category">
        <div
          className="emoji-category__indicator"
          style={getIndicatorStyle}
        />
        {emojiKeys.map((category, index) => (
          <span
            className="emoji-category__item"
            key={category}
            onClick={() => setSelectedCategory(index)}
            >
            {category === 'People'
              ? <IconPeople fill={selectedCategory === index ? COLORS.primary.base : COLORS.coolGrey[2]} width="26px" height="26px" />
              : category === 'Nature'
                ? <IconNature fill={selectedCategory === index ? COLORS.primary.base : COLORS.coolGrey[2]} width="26px" height="26px" />
                : category === 'Smileys'
                  ? <IconSmileys fill={selectedCategory === index ? COLORS.primary.base : COLORS.coolGrey[2]} width="26px" height="26px" />
                  : category === 'Food'
                    ? <IconFood fill={selectedCategory === index ? COLORS.primary.base : COLORS.coolGrey[2]} width="26px" height="26px" />
                    : category === 'Sports'
                      ? <IconSports fill={selectedCategory === index ? COLORS.primary.base : COLORS.coolGrey[2]} width="26px" height="26px" />
                      : category === 'Travel'
                        ? <IconTravel fill={selectedCategory === index ? COLORS.primary.base : COLORS.coolGrey[2]} width="26px" height="26px" />
                        : category === 'Objects'
                          ? <IconObjects fill={selectedCategory === index ? COLORS.primary.base : COLORS.coolGrey[2]} width="26px" height="26px" />
                          : category === 'Symbols'
                            ? <IconSymbols fill={selectedCategory === index ? COLORS.primary.base : COLORS.coolGrey[2]} width="26px" height="26px" />
                            : <IconFlags fill={selectedCategory === index ? COLORS.primary.base : COLORS.coolGrey[2]} width="26px" height="26px" />}
          </span>
        ))}
      </Row>
    </div>
  );
};

export default memo(EmojiCard);

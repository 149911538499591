import { isEmpty, throttle } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';

import { Badge, Row } from '@noon/atom';
import { WhiteBoard } from '@noon/molecule';
import { IconLoader } from '@noon/quark';

import { isTablet } from '../../../helpers';
import t from '../../../helpers/translate';
import { useClassroomAnalytics } from '../../../providers/ClassroomAnalytics';
import { useEvent, useEventDispatch } from '../../../providers/Event';
import { ISendMessage } from '../../../providers/Event/provider';
import { getLeaderboard } from '../../../redux/actions/classroom';
import { toggleVideo } from '../../../redux/actions/webrtc';
import {
  markSlideAsLoading, setCanvasDimention, showResult, takeScreenShot, updateSlideConfig,
} from '../../../redux/actions/whiteboard';
import {
  GET_BREAKOUT_REPORT, IMAGE_ADDED_ON_WHITEBOARD, OPEN_ADD_IMAGE_FROM_QUESTION_DIALOG,
  UPDATE_BREAKOUT_DURATION,
} from '../../../redux/constants';
import CompetitionResult from '../../Class/competitionResult';
import ReconnectingModal from '../../Socket/ReconnectingModal';
import { addToast, TOAST_TYPE } from '../../Toast';
import ReportingArea from '../BreakoutReport/BreakoutReportingArea';
import { CLASSROOM_CHANNEL, CLASSROOM_EVENT } from '../events';
import fileUpload from '../fileUpload';
import { SLIDE_HEIGHT, SLIDE_WIDTH } from '../Slide/slideContainer';
import BreakoutScreen from '../TeamQna/BreakoutSceen';
import TimeSelection from '../TeamQna/TimeSelection';
import { CLASSROOM_STATE, CLASSROOM_TYPE, ISlide, ISlidePayload, ISlideQuestionPayload } from '../types';
import CompetitionActionControl from './competitionActionControl';
import QuestionTypeSelection from './questionTypeSelection';
import WhiteboardQuestionContainer, { IQuizQuestionPayload } from './whiteboardQuestionContainer';
import { IQuestion } from '../Content/Questions/questionContainer';

type IWhiteBoardEvent = {
    e: string,
    n: number,
    id: string,
    p?: any,
    t? :string,
}

type ISketchPayload = {
    payload: IWhiteBoardEvent,
    number: number,
    canvas_id: string,
    msg_id?: number,
    cs?: number,
}

type ISendMessageRef = {
    current: ISendMessage,
}

type ICanvasImagePayload = {
  url: string,
  resourceType?: string,
  resourceId?: string | number,
  isDataUrl?: Boolean,
}

type Iwhiteboard = {
  bringFocusToUploadCanvas: Function,
  handleDrop: Function,
  loadAndAddImageFromUrl: (url: string, resourceId?: string | number, resourceType?: string) => void,
}

const whiteboardLabels = {
  pointer: t('whiteboard', 'pointer'),
  pen: t('whiteboard', 'pen'),
  erase: t('whiteboard', 'erase'),
  delete: t('whiteboard', 'delete'),
  text: t('whiteboard', 'text'),
  line: t('whiteboard', 'line'),
  shape: t('whiteboard', 'shape'),
  circle: t('whiteboard', 'circle'),
  triangle: t('whiteboard', 'triangle'),
  square: t('whiteboard', 'square'),
  undo: t('whiteboard', 'undo'),
  redo: t('whiteboard', 'redo'),
  upload: t('whiteboard', 'upload'),
  crop: t('whiteboard', 'crop'),
  confirm: t('whiteboard', 'actionConfirm'),
  discard: t('whiteboard', 'actionDiscard'),
  small: t('whiteboard', 'textSmall'),
  medium: t('whiteboard', 'textMedium'),
  large: t('whiteboard', 'textLarge'),
  colorSelector: t('whiteboard', 'colorSelector'),
};

const BreakoutInfo = () => (
  <Row align="center" justify="start" nowrap gap="sm" className="preparation-timer-wrapper__breakout">
    <Badge color="red" size="sm">
      {t('teacherBreakout', 'new')}
    </Badge>
    <span>{t('teacherBreakout', 'breakoutEndDesc')}</span>
  </Row>
);

const findTagsinJson = (question, choices) => {
  let has_latex = false;
  let has_image = false;
  if (question) {
    has_latex = question.indexOf('<code') !== -1;
    has_image = question.indexOf('<img') !== -1;
  }
  if (has_latex && has_image) return { has_latex, has_image };
  for (const i in choices) {
    if (choices[i].answer) {
      has_latex = choices[i].answer.indexOf('<code') !== -1;
      has_image = choices[i].answer.indexOf('<img') !== -1;
      if (has_latex && has_image) return { has_latex, has_image };
    }
  }
  return { has_latex, has_image };
};

const WhiteboardContainer = () => {
  const sendMessage = useEventDispatch();
  const dispatch = useDispatch();
  const dispatchAnalytics = useClassroomAnalytics();
  const { agoraRtm, pubnub } = useEvent();

  const sendMessageRef: ISendMessageRef = useRef();
  const sketchesArrayRef = useRef([]);
  const whiteboardRef = useRef<Iwhiteboard | undefined>();
  const lastCanvasImageData = useRef<Iwhiteboard | undefined>();
  const lastCanvasId = useRef('');
  const activeSlideRef = useRef(null);
  const lastChecksum = useRef(0);

  const sessionDetails = useSelector((state) => state.toJS().myClassroom.sessionDetails);
  const breakoutTeamFormed = useSelector((state) => state.toJS().myClassroom.breakoutTeamFormed);
  const { breakoutReport } = useSelector((state) => state.toJS().classroom);
  const { studentList } = useSelector((state) => state.toJS().myClassroom.leaderboard);
  const slides = useSelector((state) => state.toJS().whiteboard.slides);
  const activeSlideIndex = useSelector((state) => state.toJS().whiteboard.activeSlideIndex);
  const { width: whiteboardWidth } = useSelector((state) => state.toJS().whiteboard.whiteboardSize);
  const { questionTimeLeft } = useSelector((state) => state.toJS().whiteboard.questionTimer);
  const showCanvas = useSelector((state) => state.toJS().whiteboard.showCanvas);
  const result = useSelector((state) => state.toJS().whiteboard.result);
  const isSlideLoading: number = useSelector((state) => state.toJS().whiteboard.isSlideLoading);
  const selectedImage = useSelector((state) => state.toJS().content.selectedImage);
  const webrtcStatus = useSelector((state) => state.toJS().webrtc.status);
  const { qnaBreakoutStarted, qnaBreakoutOngoing } = useSelector((state) => state.toJS().TeamQna);

  const [customTools, setCustomTools] = useState([]);
  const [isSketchLoading, setIsSketchLoading] = useState(false);
  const activeSlide: ISlidePayload = slides[activeSlideIndex];

  const isCompetition = sessionDetails.class_type === CLASSROOM_TYPE.competition;
  const hideCanvas = !showCanvas || !!questionTimeLeft || (isCompetition && !activeSlide?.reviewed) || activeSlideIndex === -1 || qnaBreakoutStarted;
  const [showExplanation, setShowExplanation] = useState(false);
  const isClassStarted = useMemo(() => sessionDetails.state === CLASSROOM_STATE.started, [sessionDetails]);
  const showCompetitionControl = isCompetition && isClassStarted && !questionTimeLeft;
  const enableTypeSelection = !activeSlide?.reviewed && !isCompetition && isClassStarted;
  const isWebrtcConnected = (webrtcStatus === 'CONNECTED' && webrtcStatus !== 'ABORTED') || webrtcStatus === '';
  const videoStatus = useSelector((state) => state.toJS().webrtc.videoStatus);
  const breakoutState = useSelector((state) => state.toJS().myClassroom.breakoutState);
  const loggedUser = useSelector((state) => state.toJS().user.loggedUser);

  const disableTools = useMemo(() => {
    const disableTools = [];
    if(!isClassStarted) {
      disableTools.push('laser-pointer');
    }
    if (isMobileOnly) {
      disableTools.push('text');
    }
    return disableTools;
  }, [loggedUser, isClassStarted]);

  const getSketchHistory = (slideChannel: string, timetoken: number) => {
    pubnub.fetchMessages(
      {
        channels: [slideChannel],
        count: 100,
        start: timetoken,
      }, (status, response) => {
        if (!timetoken) {
          dispatchAnalytics('slide_action', {
            slide_no: activeSlide?.canvas_id,
            action: 'sketch',
            status: JSON.stringify({ error: status?.error, response: response?.channels?.[slideChannel]?.[0] }),
          });
        }
        if (!isEmpty(response?.channels) && Object.keys(response.channels)?.length) {
          const sketches = response.channels[slideChannel]?.map((event) => {
            const { message, timetoken } = event;
            return { ...message, timetoken };
          }).filter((item) => item.payload);

          sketchesArrayRef.current = filterPastLaserPointer([...sketchesArrayRef.current, ...sketches]);
          const lastTimestamp = response.channels[slideChannel][0]?.timetoken;
          getSketchHistory(slideChannel, lastTimestamp);
        } else {
          sketchesArrayRef.current = sketchesArrayRef.current.sort((a, b) => a.timetoken - b.timetoken);
          setIsSketchLoading(false);
        }
      },
    );
  };

  const calculateWidth = () => {
    const w = isTablet() ? (window.innerWidth - 90) : (window.innerWidth - 400); // 390: sidebar width + toolbar width, 60: toolbar width for mobile
    const h = window.innerHeight - 150;
    const playerRatio = 16 / 9;
    const widthToHeightRatio = w / h;
    let calculatedWidth = w;
    if (widthToHeightRatio >= playerRatio) {
      calculatedWidth = playerRatio * h;
    }
    return calculatedWidth;
  };

  const handleResize = () => {
    const width = calculateWidth();
    dispatch(setCanvasDimention({ width, height: width * (9 / 16) }));
  };

  const sendNotification = ({ type }) => {
    if (type === 'error') {
      addToast(t('toast', 'fileUploadFailed'), TOAST_TYPE.ERROR);
      dispatch({ type: IMAGE_ADDED_ON_WHITEBOARD.FAILURE });
    }
  };

  const handleSketchEvent = (event: IWhiteBoardEvent) => {
    if (event.e && event.e !== 'CURSOR_POSITION') {
      const sketchPayload: ISketchPayload = {
        payload: event,
        number: event.n,
        canvas_id: activeSlideRef?.current?.canvas_id || '',
      };
      if (!['DOWN', 'MOVE', 'UP'].includes(sketchPayload.payload.e)) {
        // reset checksum if if slide (canvas_id) is different from last saved CanvasId
        if (lastCanvasId.current !== sketchPayload.canvas_id) {
          // if sketch already in slide reset to last saved checksum else reset to zero
          const slideSketches = sketchesArrayRef.current;
          lastChecksum.current = slideSketches.length ? (slideSketches[slideSketches.length - 1].cs || 0) : 0;
          lastCanvasId.current = sketchPayload.canvas_id;
        }
        sketchPayload.msg_id = Date.now() - 1603695177659; // subtracting date in ms till 01/01/2020
        sketchPayload.cs = sketchPayload.msg_id + lastChecksum.current;
        lastChecksum.current = sketchPayload.cs;
        // only send main events (skip -> down | move | up event)
        sendMessageRef.current(CLASSROOM_EVENT.sketchForHistory, sketchPayload).then(() => {
          if (sketchPayload?.payload?.t === 'image') {
            dispatch({ type: IMAGE_ADDED_ON_WHITEBOARD.SUCCESS, payload: sketchPayload?.payload?.p?.u });
          }
        });
      }
      sendMessageRef.current(CLASSROOM_EVENT.sketch, sketchPayload);
    }
  };

  const addImageToCanvas = (payload: ICanvasImagePayload) => {
    if (!whiteboardRef?.current) return;
    const { url, resourceType, resourceId, isDataUrl } = payload;
    if (isDataUrl) {
      whiteboardRef.current?.bringFocusToUploadCanvas();
      whiteboardRef.current?.handleDrop([url]);
      return;
    }
    const ENCODE_PATTERN = /(?!%22)[!@#$%^&*]..[%]/gi;
    const encodedUrl = ENCODE_PATTERN.test(url) ? url : encodeURI(url);
    whiteboardRef.current?.loadAndAddImageFromUrl(encodedUrl, resourceId, resourceType);
  };

  const openAddQuestionFromImageDialog = () => {
    // TODO: need to improve this later
    dispatch({ type: OPEN_ADD_IMAGE_FROM_QUESTION_DIALOG, payload: { from: 'toolbar' } });
  };

  const sendScrollEvent = (y: number, height: number) => {
    const msg = {
      e: 'scrolling',
      y,
      height,
      isTeacher: 1,
      canvas_id: activeSlideRef.current?.canvas_id,
    };
    sendMessageRef.current(CLASSROOM_EVENT.sketch, msg);
    sendMessageRef.current(CLASSROOM_EVENT.scrollData, msg); // saving scroll data for playback
  };

  const throttledScrollEvent = useCallback(throttle(sendScrollEvent, 300), []);

  const handleScroll = (e) => {
    if (activeSlide?.resource_type === 'question') {
      throttledScrollEvent(e.target.scrollTop, e.target.clientHeight);
    }
  };

  const handleSelectQuestion = () => {
    // addCube event payload
    const payload = {
      ...activeSlide,
      width: SLIDE_WIDTH,
      height: SLIDE_HEIGHT,
      isTeacher: 1,
      broadcast: true,
      activate: true,
      active: 1,
      reviewed: true,
      setReview: true,
    };
    payload.session_state = isClassStarted ? 'started' : 'scheduled';
    // if (isCompetition) {
    //   dispatch(markSlideAsLoading(activeSlide));
    // } else {
    dispatch(takeScreenShot(markSlideAsLoading(activeSlide)));
    // }
    sendMessage(CLASSROOM_EVENT.addSlide, payload)
      .then(() => {
        dispatchAnalytics('slide_action', {
          slide_no: activeSlide?.canvas_id,
          action: 'change',
          status: 'success',
        });
      }).catch(() => {
        dispatchAnalytics('slide_action', {
          slide_no: activeSlide?.canvas_id,
          action: 'change',
          status: 'failure',
        });
      });
  };

  const handleSelectInteractiveQuestion = (questionSlide: ISlide, time: number, isBreakoutDeffered?: Boolean, isDiscussionAllowed?: Boolean) => {
    const choices = [];
    const questionDetails = questionSlide?.resource;

    questionDetails.choices.map((choice) => {
      choices.push({
        id: choice.id,
        answer: '',
        answer_json: choice.answer_json,
        total_votes: 0,
        avg_vote: 0,
        is_correct: choice.is_correct,
      });
    });

    // get slide number after filtering only question slides
    const questionSlideIndex = slides
      .filter((item) => item.resource_type === 'question')
      .findIndex((item) => item.canvas_id === questionSlide?.canvas_id) || 0;

    const resource = {
      question_id: questionDetails.id,
      passage: questionDetails.passage,
      question: '',
      solution: '',
      passage_json: questionDetails.passage_json,
      question_json: questionDetails.question_json,
      text_direction: questionDetails.text_direction,
      question_type_name: questionDetails.question_type_name,
      solution_json: questionDetails.solution_json,
      get_ready: questionDetails.get_ready || 5,
      choices,
      total_votes: 0,
      total_students: studentList.filter(s => s.status === 'online').length,
      time_left: time,
      time,
      isTeacher: 1,
      number: questionSlide.number,
      q_no: Number(questionSlideIndex) + 1,
      canvas_id: questionSlide.canvas_id,
      temp_width: breakoutTeamFormed ? SLIDE_WIDTH : null,
    };

    const payload: IQuizQuestionPayload = {
      currentCube: resource,
      bot: false,
      groupSessionId: questionSlide.group_id,
      canvas_id: questionSlide.canvas_id,
      allow_discussion: time < 60 ? false : !!isDiscussionAllowed,
    };

    payload.currentCube.label_name = questionSlide.label_name;
    payload.currentCube.label_id = questionSlide.label_id;
    payload.currentCube.session_state = sessionDetails.state === 'started' ? 'started' : 'scheduled';

    const { has_image, has_latex } = findTagsinJson(resource.question, resource.choices);

    const questionEventPayload = {
      id: resource.question_id,
      has_image,
      has_latex,
      type: 'breakout',
    };

    if (breakoutTeamFormed) {
      // load breakout report
      dispatch({ type: UPDATE_BREAKOUT_DURATION, payload: { breakoutDuration: time } });
      dispatch({ type: GET_BREAKOUT_REPORT.REQUEST, payload: { sessionId: sessionDetails.id } });
      setShowExplanation(false);

      payload.breakout_mode = isBreakoutDeffered ? 'deferred' : 'immediate';
      payload.breakout_mode_new = isBreakoutDeffered ? 'deferred' : 'immediate';
      sendMessage(CLASSROOM_EVENT.breakoutQuestion, payload)
        .then(() => {
          dispatchAnalytics('question_send', {
            question: questionEventPayload,
            status: 'success',
          });
        }).catch(() => {
          dispatchAnalytics('question_send', {
            question: questionEventPayload,
            status: 'failure',
          });
        });
    } else {
      questionEventPayload.type = 'interactive';
      sendMessage(CLASSROOM_EVENT.interactiveQuestion, payload)
        .then(() => {
          dispatchAnalytics('question_send', {
            question: questionEventPayload,
            status: 'success',
          });
        }).catch(() => {
          dispatchAnalytics('question_send', {
            question: questionEventPayload,
            status: 'failure',
          });
        });
    }
    // if (isCompetition) {
    //   dispatch(markSlideAsLoading(questionSlide));
    // } else {
    dispatch(takeScreenShot(markSlideAsLoading(questionSlide)));
    // }

    // Agora interceptor
    if (breakoutTeamFormed) {
      sendMessage(CLASSROOM_EVENT.breakoutQuestionLog, { ...payload, event: 'ask_students_in_breakout' }, { stringify: false });
    } else {
      sendMessage(CLASSROOM_EVENT.interactiveQuestionLog, { ...payload, event: 'ask_students' }, { stringify: false });
    }

    // TODO: kafka logging
  };

  const handleNextQuestion = () => {
    if (isCompetition && !studentList.length) {
      addToast(t('classroom', 'noStudentInClassroomToStartCompetition'), TOAST_TYPE.ERROR, 5);
      return;
    }
    const nextSlideIndex = activeSlideIndex + 1;
    if (nextSlideIndex >= slides.length) {
      const result = studentList.slice(0, 3);
      sendMessage(CLASSROOM_EVENT.showResult, { result });
      dispatch(showResult(result));
      return;
    }
    const nextSlide = slides[nextSlideIndex];
    handleSelectInteractiveQuestion(nextSlide, nextSlide?.resource?.time || 60);
  };

  const handleQuestionEnded = () => {
    if (!breakoutTeamFormed && !isCompetition) {
      handleSelectQuestion();
    }
    if (!isCompetition) {
      dispatch(updateSlideConfig({ disableAddSlide: false, disableSelectSlide: false }));
    }
    dispatch(getLeaderboard({ session_id: sessionDetails.id, offset: 0 }));
  };

  // Filter laserpointer event from the history 
  const filterPastLaserPointer = (sketchData) => {
    return sketchData?.filter(sketch => sketch.payload.t !== 'laser-pointer')
  }

  useEffect(() => {

    const customToolOptions = [];
    if (!isMobileOnly) {
      customToolOptions.push({
        text: t('image2question', 'ToolbarOption1'),
        onClick: () => openAddQuestionFromImageDialog(),
        nonPopoverIconClick: () => openAddQuestionFromImageDialog(),
        type: 'button',
      });
    }

    customToolOptions.push({
      text: t('image2question', 'ToolbarOption2'),
      name: 'upload',
      type: 'button',
    });

    setCustomTools([{
      name: 'upload question image',
      icon: 'upload-image',
      label: t('image2question', 'ToolbarTitle'),
      optionsTitle: t('image2question', 'ToolbarLabel'),
      options: customToolOptions,
    }]);

    handleResize();

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };

  }, []);

  useEffect(() => {
    sendMessageRef.current = sendMessage;
  }, [sendMessage]);

  useEffect(() => {
    if (pubnub?.fetchMessages && activeSlideIndex >= 0 && slides.length) {
      setIsSketchLoading(true);
      sketchesArrayRef.current = [];
      const slideChannel = CLASSROOM_CHANNEL.canvasSketchChannel({ sessionId: sessionDetails.id, canvasId: slides[activeSlideIndex]?.canvas_id });
      getSketchHistory(slideChannel, 0);
      activeSlideRef.current = activeSlide; // update active slide reference
    }
  }, [activeSlideIndex, pubnub, slides]);

  useEffect(() => {
    if (selectedImage !== lastCanvasImageData.current) {
      lastCanvasImageData.current = selectedImage;
      addImageToCanvas(selectedImage);
    }
  }, [selectedImage]);

  useEffect(() => {
    if (breakoutState) {
      if (videoStatus) {
        dispatch(toggleVideo('REQUEST'));
      }
    } else {
      const { videoStatus: teacherVideoStatus } = JSON.parse(localStorage.getItem('classroomDetails') || '{}');
      if (teacherVideoStatus) {
        dispatch(toggleVideo('REQUEST'));
      }
    }
  }, [breakoutState]);
  // load breakout report if page was refreshed

  setTimeout(() => {

    // rest breakout duration for report:

    const breakoutReportLS = JSON.parse(localStorage.getItem(`breakout${sessionDetails.id}`) || '{}');
    if (breakoutTeamFormed && !breakoutReport.notEngaged && breakoutReport.breakoutCount <= breakoutReportLS.breakoutCount) {
      dispatch({ type: GET_BREAKOUT_REPORT.REQUEST, payload: { sessionId: sessionDetails.id, reload: true } });
    }
  }, 1000);

  if (!slides.length) {
    return null;
  }

  const renderQuestion = (
    <>
      {activeSlide?.resource_type === 'question' && !qnaBreakoutStarted && !qnaBreakoutOngoing && (
        <WhiteboardQuestionContainer showExplanationButton={hideCanvas && !showExplanation} onShowCanvas={() => { setShowExplanation(true); }} questionDetails={activeSlide?.resource as IQuestion} onQuestionEnded={handleQuestionEnded}>
          <>
            {Boolean(breakoutTeamFormed) && <BreakoutInfo />}
            {enableTypeSelection && (
              <QuestionTypeSelection
                breakoutTeamFormed={breakoutTeamFormed}
                isQuestionLoading={isSlideLoading}
                onSelectQuestion={handleSelectQuestion}
                activeSlide={activeSlide}
                onSelectInteractiveQuestion={handleSelectInteractiveQuestion}
                breakoutMode={sessionDetails.breakout_mode_new}
                isDiscussionEnabled={sessionDetails.should_allow_bo_discussion}
              />
            )}
          </>
        </WhiteboardQuestionContainer>
      )}
    </>
  );

  const renderBreakoutReport = (
    <>
      {breakoutTeamFormed && !qnaBreakoutStarted && !qnaBreakoutOngoing && (breakoutReport.breakoutCount > 0) && <ReportingArea />}
    </>
  );  

  return (
    <>
      {!result?.length ? (
        <div className="whiteboard-wrapper">
          {isSketchLoading && <IconLoader className="sketch-loader" />}
          {qnaBreakoutStarted && !qnaBreakoutOngoing && <TimeSelection />}
          {qnaBreakoutStarted && qnaBreakoutOngoing && <BreakoutScreen />}
          <WhiteBoard
            ref={whiteboardRef}
            sendNotification={sendNotification}
            fileUpload={fileUpload}
            slideNumber={activeSlide?.number || 0}
            defaultEvents={sketchesArrayRef.current}
            webrtcSession={agoraRtm}
            labels={whiteboardLabels}
            disableCanvas={isSketchLoading}
            hideCanvas={hideCanvas && !showExplanation}
            disableToolBar={hideCanvas && !showExplanation}
            onScroll={handleScroll}
            onDispatchEvent={handleSketchEvent}
            disableTools={disableTools}
            width={whiteboardWidth}
            customTools={customTools}
            renderOnTop={renderQuestion}
            renderOnBottom={renderBreakoutReport}
          />

          {showCompetitionControl && (
            <CompetitionActionControl
              slides={slides}
              isLoading={!!isSlideLoading}
              showCanvas={showCanvas}
              activeSlideIndex={activeSlideIndex}
              onSelectQuestion={handleSelectQuestion}
              onNextQuestion={handleNextQuestion}
            />
          )}
          {!isWebrtcConnected && <ReconnectingModal playerWidth={whiteboardWidth} />}
        </div>
      ) : (
        <CompetitionResult winners={result} />
      )}
    </>
  );
};

export default WhiteboardContainer;

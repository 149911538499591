import React from 'react';
import PropTypes from 'prop-types';
import { translationType } from '../../../types';
import EmptyStateNoGroup from './EmptyStateNoGroup';
import EmptyStateNoActivity from './EmptyStateNoActivity';
import EmptyStateNoUpcomingActivities from './EmptyStateNoUpcomingActivities';
import EmptyStateNoScheduledActivities from './EmptyStateNoScheduledActivities';

function EmptyPlanner({
  isGroupEmpty,
  noActivity,
  noUpcomingActivity,
  children,
  noonText,
  toggleCreateActivity,
  noScheduledActivity,
  toggleCreateGroup }) {
  return (
    isGroupEmpty
      ? <EmptyStateNoGroup noonText={noonText} toggleCreateGroup={toggleCreateGroup} />
      : noActivity
        ? <EmptyStateNoActivity noonText={noonText} emptyStateType="activity" toggleCreateActivity={toggleCreateActivity} />
        : noScheduledActivity
          ? <EmptyStateNoScheduledActivities noonText={noonText} emptyStateType="activity" />
          : noUpcomingActivity
            ? <EmptyStateNoUpcomingActivities noonText={noonText} emptyStateType="activity" />
            : children
  );
}

EmptyPlanner.propTypes = {
  noonText: translationType.isRequired,
  toggleCreateActivity: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  toggleCreateGroup: PropTypes.func.isRequired,
  isGroupEmpty: PropTypes.bool,
  noActivity: PropTypes.bool,
  noUpcomingActivity: PropTypes.bool,
  noScheduledActivity: PropTypes.bool,
};

EmptyPlanner.defaultProps = {
  isGroupEmpty: false,
  noActivity: false,
  noUpcomingActivity: false,
  noScheduledActivity: false,
};
export default EmptyPlanner;

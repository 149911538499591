import React, { useEffect, useState } from 'react';
import { IconStudentO, IconMembersO } from '@noon/quark';
import { useSelector, useDispatch } from 'react-redux';
import { Column, Row, Badge } from '@noon/atom';
import ReactTooltip from 'react-tooltip';
import { CLASSROOM_LEADERBOARD } from '../../../redux/constants';
import { getLeaderboard } from '../../../redux/actions/classroom';
import ErrorBoundry from '../../ErrorBoundry';
import TabBar from '../tabBar';
import StudentLeaderboard, { BLOCK_TIME } from './studentLeaderboard';
import TeamContainer from './teamContainer';
import t from '../../../helpers/translate';
import { useEventDispatch } from '../../../providers/Event';
import { CLASSROOM_EVENT } from '../events';

const tabs = [
  { name: 'teams', label: t('teacherBreakout', 'team'), icon: IconMembersO },
  { name: 'students', label: t('teacherBreakout', 'individual'), icon: IconStudentO },
];

const LeaderboardContainer = () => {

  const dispatch = useDispatch();
  const sendMessage = useEventDispatch();

  const [activeTab, setActiveTab] = useState('students');

  const sessionDetails = useSelector((state) => state.toJS().myClassroom.sessionDetails);
  const {
    isLeaderboardLoading,
    studentList,
    studentCount,
    liveStudentCount,
    leaderboardOffset,
    // isTeamLoading, teamList, teamOffset
  } = useSelector((state) => state.toJS().myClassroom.leaderboard);
  const teams = useSelector((state) => state.toJS().breakoutTeams.teams);

  const allowLoadMoreLeaderboard = leaderboardOffset !== -1 && !isLeaderboardLoading;

  const handleBlockUser = (userChat) => {
    const payload = {
      user_id: userChat.user_id,
      blockTime: BLOCK_TIME,
      chat_time: userChat.time,
      msg: userChat.msg,
      isTeacher: 0,
    };
    sendMessage(CLASSROOM_EVENT.blockChat, payload);
  };

  // TODO: will fix report abuse component, once we remove old classroom code
  const handleReportAbuse = ({ reason, player }) => {
    const payload = {
      user_id: player.user_id,
      message: '',
      reason,
    };
    sendMessage(CLASSROOM_EVENT.reportAbuse, payload);
  };

  const handleClick = (tab: string) => {
    setActiveTab(tab);
  };

  const loadMoreLeaderboard = () => {
    dispatch(getLeaderboard({ session_id: sessionDetails.id, offset: leaderboardOffset }));
  };

  useEffect(() => {
    if (!studentList.length) {
      dispatch(getLeaderboard({ session_id: sessionDetails.id, offset: 0 }));
    }
  }, []);

  // const loadMoreTeams = () => {
  // };

  return (
    <ErrorBoundry msg="There is some problem with leaderboard, please refresh to try again">
      <Column nowrap flex="1" className="classroom-leaderboard-container" data-type="leaderboard-container">
        <Row className="classroom-leaderboard-container__header" align="center">
          {Boolean(studentCount) && activeTab === 'students' && (
            <>
              <ReactTooltip place="top" />
              <Badge
                size="sm"
                type="default"
                data-tip={`${liveStudentCount} ${t('leaderboard', 'activeFrom')} ${studentCount}`}
                tooltipPos="top"
              >
                {`${liveStudentCount}/${studentCount}`}
              </Badge>
            </>
          )}
          {Boolean(teams.length) && activeTab === 'teams' && (
            <>
              <ReactTooltip place="top" />
              <Badge
                size="sm"
                type="default"
                data-tip={`${teams.length} ${t('teacherBreakout', 'totalTeams')}`}
                tooltipPos="top"
            >
                {teams.length}
              </Badge>
            </>
          )}
        </Row>
        <TabBar
          tabs={tabs}
          activeTab={activeTab}
          onTabClick={handleClick}
        />

        {activeTab === 'students' && (
          <StudentLeaderboard
            loadMoreLeaderboard={loadMoreLeaderboard}
            allowLoadMoreLeaderboard={allowLoadMoreLeaderboard}
            isLeaderboardLoading={isLeaderboardLoading}
            studentList={studentList}
            onBlockUser={handleBlockUser}
            onReportAbuse={handleReportAbuse}
          />
        )}
        {activeTab === 'teams' && (
          <TeamContainer sessionId={sessionDetails.id} />
        )}
      </Column>
    </ErrorBoundry>
  );

};

export default LeaderboardContainer;

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash-es/isEmpty';
import { isMobileOnly, isMobile, isIPad13 } from 'react-device-detect';
import { IconBack, IconLoader, IconPlus } from '@noon/quark';
import { Column, Row, Button, Select } from '@noon/atom';
import classNames from 'classnames';
import { get, keyBy, uniqBy } from 'lodash-es';
import { useParams, useHistory } from 'react-router-dom';

import { GET_QUESTION } from '../../redux/constants';
import { COLORS } from '../../constants';
import htmlParser from '../../helpers/htmlParser';
import { CurriculumSelection } from '../../components/Curriculum';
import PageHeader from '../../components/Layout/pageHeader';
import { addToast, TOAST_TYPE } from '../../components/Toast';
import t from '../../helpers/translate';
import isRTL from '../../helpers/isRTL';
import { QuestionContent } from '../../components/MyLibrary/question-content';
import { defaultChoices, defaultQuestion, difficultyLevels } from '../../constants/question-defaults';
import { generateTags } from '../../helpers/generateTags';
import { isLatex } from '../../helpers/is-latex';
import { isImage } from '../../helpers/is-image';

export interface Tag {
	color?: string;
	color_start?: string;
	negative_pic?: string;
	background_pic?: string;
	pic?: string;
	name: string;
	id: string;
}

export interface Curriculum {
	board?: Tag;
	subject?: Tag;
	test?: Tag;
	skill?: Tag;
	course?: Tag;
	grade?: Tag;
    country?: Tag,
    chapter?: Tag,
    topic?: Tag
	[key: string]: unknown;
}

export enum CURRICULUM_TYPE {
	UNIVERSITY = 'university',
	K12 = 'k12'
}

export const CreateQuestion = (): JSX.Element => {
    const { id: questionId, type } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [choices, setChoices] = useState(defaultChoices);
    const [quesiton, setQuestion] = useState(defaultQuestion);
    const [difficultyLevel, setDifficultyLevel] = useState(difficultyLevels[0]);
    const [selectedCurriculum, setSelectedCurriculum] = useState<Curriculum>({});
    const [validator, setValidator] = useState({
        isCorrect: false,
        choice: 0,
        question: false,
        curriculum: false,
        questionUpdated: false,
        duplicateChoice: false,
    });
    const [previewQuestion, setPreviewQuestion] = useState({
        showPreviewQuestion: false,
        question: defaultQuestion.question,
        question_json: htmlParser(defaultQuestion.question),
        choices: defaultChoices,
    });
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isCreateAnotherQuestionChecked, setIsCreateAnotherQuestionChecked] = useState(false);

    const { questionData, createQuestionData, error } = useSelector((state) => state.toJS().question);

    const logger = useMemo(() => LoggingManager.mount({ moduleName: 'classroom' }), [])
    const startTime = useMemo(() => Date.now(), []);

    useEffect(() => {
        if (isLoading && createQuestionData.question_id) {
            try {
                const isQuestionLatex = isLatex(createQuestionData.questionData?.question);
                const isQuestionImage = isImage(createQuestionData.questionData?.question);
                const choices = createQuestionData.questionData?.choices;
                let isChoiceLatex = false;
                let isChoiceImage = false;

                for (const i in choices) {
                    if (choices[i].answer) {
                        if(!isChoiceImage) {
                            isChoiceImage = isImage(choices[i].answer);
                        }
                        if(!isChoiceLatex) {
                            isChoiceLatex = isLatex(choices[i].answer);
                        }
                    }
                    if (isChoiceLatex && isChoiceImage) break;
                }

                logger.track('teacher_click_save_question', {
                    classroom: {
                        time_spent: Math.floor((Date.now() - startTime) / 1000),
                        country: selectedCurriculum.country?.name,
                        is_image: isQuestionImage || isChoiceImage,
                        is_latex: isQuestionLatex || isChoiceLatex,
                        chapter: selectedCurriculum.chapter?.id,
                        topic: selectedCurriculum.topic?.id,
                        is_clone: type === 'clone',
                        question_id: createQuestionData?.question_id,
                        channel: 'library', // ‘library’; ‘direct image’; ‘direct text’ 
                    }
                });
            } catch (e) {
                console.log(e);
            }
            // call updated question list
            setIsLoading(false);
            if (isCreateAnotherQuestionChecked) {
              addToast(t('groupCurriculum', 'publishedSuccess'), TOAST_TYPE.SUCCESS, 8, 'center');
              setChoices(defaultChoices);
              setQuestion(defaultQuestion);
            } else {
                history.push(`/${localStorage.updatedLocale}/library?country_id=${selectedCurriculum.country.id}`);
            }
        }   
    }, [createQuestionData]);

    useEffect(() => {
        if (error.createQuestionData) {
            setIsLoading(false);
        }
    }, [error]);

    useEffect(() => {
        if (`${questionData.id}` === questionId) {
          const sortedTags = uniqBy(get(questionData, ['all_tag_details', 0, 'tag_details']), 'type');
          setQuestion({
            category_type: questionData.category_type,
            question_type_id: questionData.question_type_id,
            text_direction: questionData.text_direction,
            question: questionData.question,
            question_json: questionData.question_json,
            solution: questionData.solution,
            verified: questionData.verified,
            publish: questionData.publish,
            id: type !== 'clone' ? questionData.id : undefined,
            all_tag_details: questionData.all_tag_details,
            choices: [],
            difficulty: difficultyLevels[0].value,
          })
          setChoices(questionData.choices);
          setDifficultyLevel(() => (
            difficultyLevels.find((difficulty) => difficulty.value === questionData.difficulty)
          ));
          setSelectedCurriculum({
            ...keyBy(sortedTags, 'type'),
            tags: [],
          })
        }
    }, [questionData]);

    useEffect(() => {
        if(questionId && `${questionData.id}` !== questionId) {
            dispatch({ type: GET_QUESTION.REQUEST, payload: questionId })
        }
    }, [])

    const toggleSidebar = () => {
        setIsSidebarOpen(prevIsSidebarOpen => !prevIsSidebarOpen);
    };
  
    const handleCuriculumSelection = (selectedCurriculum) => {
        setSelectedCurriculum(selectedCurriculum);
        setQuestion(prevQuestion => ({
            ...prevQuestion,
            all_tag_details: [],
        }))
    }
  

  
    const formatCurriculum = ({ fieldsState, curriculumTypes }) => {
        const { chapter, topic } = fieldsState;
        if (chapter && topic) {
            const localValues: Curriculum = {};

            localValues.country = fieldsState.country;
            localValues.chapter = fieldsState.chapter;
            localValues.topic = fieldsState.topic;
            localValues.tags = generateTags(fieldsState, curriculumTypes);
            handleCuriculumSelection(localValues);
        }

    }

    const parseCurriculum = () => {
        if (!isEmpty(questionData) && !isEmpty(questionData.all_tag_details)) {
            const fieldsState = {};
            const { tag_details } = questionData.all_tag_details[0];

            tag_details.forEach((singleTag) => {
            fieldsState[`${singleTag.type}`] = singleTag;
            });
            return fieldsState;
        }
    }
    const isEdit = Boolean(questionId);
    const questionDataLoading = !isEdit ? questionData.loading : (questionData.loading || isEmpty(questionData));
    

    return (
        <Column nowrap className="create-question">
            <PageHeader
                hideToggle
                title={questionId && !type ? t('library', 'editQuestionTitle') : t('library', 'addQuestionTitle')}
                className="page-header">
                <Row nowrap align="center">
                    <IconPlus
                        onClick={() => history.push(`/${localStorage.updatedLocale}/library`)}
                        className="close-sidebar-btn pointer"
                        rotate="45"
                        width="2.4rem"
                        fill={isMobileOnly ? '#fff' : COLORS.brand.base}
                    />
                </Row>
            </PageHeader>

            <div className="create-question__body">
                {questionDataLoading ? 
                    <Row nowrap align="start" justify="center" className="full-width">
                        <IconLoader height="60px" width="60px" fill={COLORS.brand.base} />
                    </Row> :
                    <React.Fragment>
                        <Column nowrap className={classNames('create-question__sidebar', { active: isSidebarOpen })}>
                            <h4 className="heading full-width">
                                {(isMobile || isIPad13) && <IconBack flipX={isRTL()} onClick={toggleSidebar} height="24px" width="24px" />}
                                <span>{t('library', 'assignQuestionFor')}</span>
                            </h4>

                            <Column nowrap gap="sm" className="full-width">
                                <CurriculumSelection
                                isLoading={isLoading}
                                onFetchSelected={handleCuriculumSelection}
                                selectedCurriculum={selectedCurriculum}
                                formatCurriculum={formatCurriculum}
                                parseCurriculum={parseCurriculum}
                                parentType="question"
                                isEdit={isEdit}
                                    />
                                {isSubmitted && !validator.curriculum && <p className="error-text">{t('error', 'topicNotAssigned')}</p>}
                                {/* ------- difficulty -------- */}
                                <Select
                                    list={difficultyLevels}
                                    onSelect={(item) => setDifficultyLevel(item)}
                                    selectedItem={difficultyLevel}
                                    title={t('library', 'selectDifficulty')}
                                    label={t('library', 'difficulty')}
                                />
                                {(isMobile || isIPad13) && <Button onClick={toggleSidebar}>{t('library', 'saveCurriculum')}</Button>}
                            </Column>
                        </Column>
                        <QuestionContent 
                            type={type}
                            questionId={questionId}
                            isSubmitted={isSubmitted}
                            question={quesiton}
                            validator={validator}
                            choices={choices}
                            isLoading={isLoading}
                            selectedCurriculum={selectedCurriculum}
                            difficultyLevel={difficultyLevel}
                            setChoices={setChoices}
                            setQuestion={setQuestion}
                            setValidator={setValidator}
                            setIsLoading={setIsLoading}
                            setIsSubmitted={setIsSubmitted}
                            setIsCreateAnotherQuestionChecked={setIsCreateAnotherQuestionChecked}
                            toggleSidebar={toggleSidebar}
                            hideAnotherQuestionCheckbox={false}
                        />
                    </React.Fragment>
                }
            </div>
        </Column>
    );
};

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Column } from '@noon/atom';

import { ClassroomQuestion, GetReadyScreen } from '../../components/Class';
import Fullscreen from '../../components/Layout/fullscreen';
import {
  PlaybackCanvas,
  PlaybackControls,
  PlaybackHeader,
  PlaybackOnboardingModal,
  PlaybackPlayer,
  PlaybackSections,
} from '../../components/Play';
import {
  defaultSlide,
  IPlaybackContainer,
  ISlide,
} from '../../components/Play/types';
import { calculateCanvasAreaWidth, translationText } from '../../helpers';
import { PlaybackAudioProvider } from '../../providers/PlaybackAudio';
import { PlaybackTimeSeriesProvider } from '../../providers/PlaybackTimeSeries';
import { CLEAR_PLAYBACK_DATA } from '../../redux/constants';

function PlaybackContainer(props:IPlaybackContainer) {
  const { typeOfPlayback } = props;
  const showHeader: boolean = typeOfPlayback === 'normal';
  const showControls: boolean = typeOfPlayback === 'normal';
  const history = useHistory();
  const dispatch = useDispatch();

  // States
  const { playbackDetails, playerData, config, editedPlaybackDetails, playback_sections, publishedPlaybackData, interactiveQuestion } = useSelector((state) => state.toJS().playback);
  const user = useSelector((state) => state.toJS().user.loggedUser);
  const { noonText = {} } = useSelector((state) => state.toJS().translation);

  const [showOnboarding, updateShowOnboarding] = useState<boolean>(!JSON.parse(localStorage.getItem('skipPlaybackOnboarding') || 'false'));
  const [fullscreen, updateFullscreenMode] = useState<boolean>(false);
  const [playerWidth, updatePlayerWidth] = useState<number>(window.innerHeight > 800 ? 992 : 768);
  const [playerHeight, updatePlayerHeight] = useState<number>(playerWidth ? playerWidth * (9 / 16) : calculateCanvasAreaWidth(false) * (9 / 16));
  const [currentSlide, updateSlideState] = useState<{active: ISlide, opened: ISlide }>({ active: defaultSlide, opened: defaultSlide });
  const [questionData, setQuestionData] = useState(null);
  const [questionTime, setQuestionTime] = useState<number>(0);
  const [getReadyTime, setGetReadyTime] = useState<number>(0);
  const [showCanvas, setShowCanvas] = useState<boolean>(typeOfPlayback === 'normal');

  // Refs
  const playerRef = useRef(null);

  // Logic
  const onExit = (): void => {
    dispatch({ type: CLEAR_PLAYBACK_DATA });
    history.push(`/${localStorage.updatedLocale}/home`);
  };

  // Handle resizing of canvas on user action (minimising window or fullscreen)
  const handleResize = () : void => {
    // Manually setting width for the new design
    const newWidth = window.innerHeight > 800 ? 992 : 768;
    updatePlayerWidth(newWidth);
    updatePlayerHeight(newWidth * (9 / 16));
  };

  // Obviously
  const toggleFullScreen = (fullscreen : boolean) : void => {
    updateFullscreenMode(fullscreen);
    handleResize();
  };

  const updateOnboardingState = () : void => {
    updateShowOnboarding(false);
    localStorage.skipPlaybackOnboarding = true;
  };

  const handleSlideAction = (payload: ISlide) : void => {
    updateSlideState({ ...currentSlide, ...payload });
  };

  const scrollCanvas = (height: number) : void => {
    document.querySelector('.noon-content').scrollTo({ behavior: 'smooth', top: height });
  };

  const showExplanation = () : void => {
    setShowCanvas(true);
  };

  useEffect(() => {
    setQuestionData(interactiveQuestion);
    setShowCanvas(!interactiveQuestion);
    setGetReadyTime(interactiveQuestion ? interactiveQuestion.get_ready : 0);
    setQuestionTime(interactiveQuestion ? interactiveQuestion.time : 0);
  }, [interactiveQuestion]);

  useEffect(() => {
    if (questionData) {
      if (getReadyTime > 0) {
        setGetReadyTime(getReadyTime > 0 ? getReadyTime - 1 : 0);
      }
      if (getReadyTime === 0) {
        setQuestionTime(questionTime > 0 ? questionTime - 1 : 0);
      }
    }
  }, [playerData.playedSeconds]);

  return (
    <PlaybackAudioProvider
      playerRef={playerRef}
      >
      <PlaybackTimeSeriesProvider
        playerRef={playerRef}
        scrollFunction={scrollCanvas}
        showExplanation={showExplanation}

      >
        <Fullscreen enabled={fullscreen} onChange={toggleFullScreen}>
          {showHeader && playbackDetails ? <PlaybackHeader noonText={noonText} playbackDetails={editedPlaybackDetails?.data?.[0]} onClose={onExit} onPublish={() => onExit()} /> : null}

          <div className="playback playback--new">
            {/* {showChat ? <PlaybackChat /> : null} */}
            <Column className="playback__wrapper mt-1">
              {!!playback_sections?.original?.length && (
                <Column className="playback__top-message mb-1">
                  <h4 className="message-heading">{translationText(noonText, 'playback.headerTitle')}</h4>
                  <p className="message-subheading">{translationText(noonText, 'playback.headerSubTitle')}</p>
                </Column>
              )}
              <Column className="playback__main">
                <div style={{ maxHeight: '52vh', height: '52vh', overflowY: 'scroll' }}>
                  {interactiveQuestion && (
                  <div
                    className="playback__question"
                    style={{
                      position: 'relative',
                      transform: 'none',
                      left: 'auto',
                      maxHeight: '52vh',
                    }}
                          >
                    { questionData && getReadyTime > 0 && (
                    <GetReadyScreen
                      timer={getReadyTime}
                      questionData={questionData}
                      noonText={noonText}
                              />
                    )}
                    {questionData && getReadyTime <= 0 && (
                    <ClassroomQuestion
                      questionData={questionData}
                      timer={questionTime}
                      userData={user}
                      showAnswer={questionTime <= 0}
                      noonText={noonText}
                      slide={{ resource: questionData }}
                      onVoteChoice={() => {}}
                              />
                    )}
                  </div>
                  )}
                  {showCanvas ? <PlaybackCanvas playerHeight={playerHeight} playerWidth={playerWidth} /> : null}
                </div>
                <div className="playback__footer">
                  {showControls && editedPlaybackDetails && playerData
                    ? <PlaybackControls playerRef={playerRef} updateSlideState={handleSlideAction} toggleFullScreen={toggleFullScreen} fullscreen={fullscreen} config={config} currentSlide={currentSlide} />
                    : null}
                  {playerData && <PlaybackPlayer ref={playerRef} />}
                  <PlaybackSections currentSlide={currentSlide} updateSlideState={handleSlideAction} playerRef={playerRef} />
                </div>
              </Column>
            </Column>

            {showOnboarding
              ? <PlaybackOnboardingModal onExit={updateOnboardingState} />
              : null}
          </div>
        </Fullscreen>
      </PlaybackTimeSeriesProvider>
    </PlaybackAudioProvider>
  );
}

export default PlaybackContainer;

import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Button, Column } from '@noon/atom';
import { translationText } from '../../../helpers';
import { translationType } from '../../../types';

function EmptyStateNoGroup({ noonText, toggleCreateGroup }) {
  return (
    <Card className="planner-empty-card">
      <Row flex="1" justify="space-between" align="center">
        <Column gap="lg" justify="center" align="start" flex="1" className="planner-empty-card__text">
          <h4>{translationText(noonText, 'teacherDashboard.plannerEmptyHeading1')}</h4>
          <p className="child">
            {translationText(noonText, 'planner.startCreatingGroup')}
          </p>
          <Button type="primary" size="lg" icon="add" className="plr-4 text-capitalize" onClick={() => toggleCreateGroup(true)}>
            {translationText(noonText, 'button.createGroup')}
          </Button>
        </Column>
        <div className="flex-1 planner-empty-card__image text-center">
          <img src="/assets/images/2020/empty-state-no-group.png" alt="Empty Screen" />
        </div>
      </Row>
    </Card>
  );
}

EmptyStateNoGroup.propTypes = {
  noonText: translationType.isRequired,
  toggleCreateGroup: PropTypes.func.isRequired,
};

EmptyStateNoGroup.defaultProps = {
};
export default EmptyStateNoGroup;

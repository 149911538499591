import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import { Column, Row, Button, Checkbox } from '@noon/atom';
import Popover from 'react-popover';
import uniqueId from 'lodash-es/uniqueId';
import classNames from 'classnames';
import { IconArrowDown, IconLoaderDotted, IconPicture, IconPlus, IconQuestionFeed, IconWrong } from '@noon/quark';
import { get, isEmpty } from 'lodash-es';

import { COLORS } from '../../../constants';
import { translationText } from '../../../helpers';
import { HtmlInput } from '../index';
import { Accordian } from '../../Curriculum';

import {
  GET_CHAPTERS_FROM_GROUP,
  GET_TEACHER_GROUP_TOPICS,
  BULK_FILE_UPLOAD,
  CREATE_QUESTIONS_POST,
} from '../../../redux/constants';
import { addToast, TOAST_TYPE } from '../../Toast';

const CreateQuestion = (prop) => {
  const dispatch = useDispatch();
  const [selectedTopic, setTopic] = useState({});
  const [selectedChapter, setChapter] = useState({});
  const [questionText, setQuestionText] = useState('');
  const [imagePreviewUrls, setImagePreviewUrls] = useState([]);
  const [isFree, setIsFree] = useState(true);
  const [showChapterAndLesson, setShowChapterAndLesson] = useState(false);
  const {
    user,
    noonText,
    isRtl,
    emojis,
    isBulkFileUploading,
    bulkFileUpload,
    groupId,
    hideFocusOperation,
    bulkFilesUploaded,
    hideAdditionalHeading,
    onlyQuestions,
    bulkFileUploadSuccess,
    groupDetails,
  } = prop;
  const { groupChapters, curriculumList } = useSelector((state) => state.toJS().groupsV2);
  const { is_unified_group_teacher } = useSelector((state) => state.toJS().experiment.featureFlags);

  useEffect(() => {
    dispatch({
      type: GET_CHAPTERS_FROM_GROUP.REQUEST,
      payload: groupId,
    });
  }, []);

  const changePostText = (data) => {
    setQuestionText(data);
  };

  const sendQuestion = () => {
    const payload = {
      text: questionText,
      group_id: groupId,
      chapter_id: selectedChapter.chapter_id,
      topic_id: selectedTopic.topic_id,
      is_free: isFree,
      ...(!isEmpty(bulkFilesUploaded) && {
        files: [
          {
            file_type: 'image',
            name: bulkFilesUploaded[0].meta.name,
            original: bulkFilesUploaded[0].meta.large_url,
            small: bulkFilesUploaded[0].meta.small_url,
            medium: bulkFilesUploaded[0].meta.medium_url,
            large: bulkFilesUploaded[0].meta.large_url,
            thumbnail: bulkFilesUploaded[0].meta.thumbnail_url,
          },
        ],
      }),
    };

    dispatch({ type: CREATE_QUESTIONS_POST.REQUEST, payload });
    dispatch({
      type: BULK_FILE_UPLOAD.SUCCESS,
      payload: 'clear',
    });
    hideFocusOperation();
    prop.handleMixpanelKafkaFeeds({
      type: 'create_question',
      data: {
        no_of_attachments: bulkFilesUploaded.length,
        attachment_file_type: 'image',
        question_text_length: questionText.length,
        chapter: selectedChapter.chapter_id,
        topic: selectedTopic.topic_id,
      },
    });
  };

  const filterCondition = (file, type) => {
    if (type === 'image' && file.file.size / (1024 * 1024) >= 6) {
      addToast(translationText(noonText, 'warning.image_size_warning'), TOAST_TYPE.ERROR);
      return false;
    }
    return true;
  };

  const uploadFiles = (fileList) => {
    const attachmentList = fileList;
    const filesBatch = attachmentList.map((fileObj) => {
      const { file, id } = fileObj;
      const fd = new FormData();
      fd.append('destination', 'posts');
      fd.append('fileUpl', file, file.name);
      fd.append('file_name', file.name);
      fd.append('id', id);
      return { fd };
    });
    bulkFileUpload(filesBatch);
  };

  const handleFileUpload = (e) => {
    const {
      target: { files },
    } = e;
    const fileList = Array.from(files);
    const uploaders = fileList.map((file) => ({ file, id: uniqueId() }));
    const uploadersCopy = uploaders.filter((file) => filterCondition(file, 'image'));
    const newImagePreviewUrl = [...imagePreviewUrls, ...uploadersCopy];
    setImagePreviewUrls(newImagePreviewUrl);
    if (newImagePreviewUrl.length) {
      if (typeof groupDetails.free_percent !== 'undefined' ||  groupDetails.group_info.is_premium) {
        setIsFree(false);
      }
    }
    if (uploadersCopy.length) {
      uploadFiles(uploadersCopy);
    }
  };

  const toggleQuestionType = () => {
    setShowChapterAndLesson(!showChapterAndLesson);
  };

  const selectTopicAndChapter = (chapter, lesson) => {
    setTopic(lesson);
    setChapter(chapter);
    toggleQuestionType();
  };

  const handleChapterSelection = (chapter) => {
    if (chapter.type !== 'chapter') {
      toggleQuestionType();
    }
    setChapter(chapter);
    setTopic({});
  };

  const handleExpandChapter = (chapterId, accordianState) => {
    if (accordianState) {
      dispatch({
        type: GET_TEACHER_GROUP_TOPICS.REQUEST,
        payload: { groupId, chapterId },
      });
    }
  };

  const hideFocusOperationImpl = () => {
    hideFocusOperation();
  };

  const removeImage = (id) => {
    bulkFileUploadSuccess([]);
    setImagePreviewUrls([]);
    setIsFree(true);
  };

  const btnListType = [
    {
      icon: IconPicture,
      text: translationText(noonText, 'post.picture'),
      func: 'handleImageUpload',
      accept: '.jpg, .png',
      type: 'image',
    },
  ];

  const toolTipText = () => {
    if ((isEmpty(selectedChapter) || isEmpty(selectedTopic)) && selectedChapter.type === 'chapter') {
      return 'Please select chapter and lesson';
    }
    if (isEmpty(selectedChapter) && selectedChapter.type !== 'chapter') {
      return 'Please select chapter';
    }
    if (!!imagePreviewUrls.length || !!questionText) {
      return 'Submit your question';
    }
    return 'Please add image or text';
  };

  const popoverPropsForQuestions = {
    isOpen: showChapterAndLesson,
    place: 'below',
    preferPlace: 'start',
    onOuterAction: () => toggleQuestionType(),
    body: (
      <div className="curriculum-content--chapters ">
        <Column>
          {!!groupChapters.list.length
            && groupChapters.list.map((chapter) => (
              <Accordian
                type={get(chapter, 'type') === 'chapter' ? 'dropdown' : 'checkbox'}
                data={chapter}
                initialState={false}
                selectCheckBox={() => handleChapterSelection(chapter)}
                togglingAccordian={(accordianState) => {
                  handleExpandChapter(chapter.chapter_id, accordianState);
                }}
                loadingAccordian={
                  curriculumList.topics
                  && curriculumList.topics[chapter.chapter_id]
                  && curriculumList.topics[chapter.chapter_id].loading
                }
                checked={selectedChapter.name === chapter.name}
                hideExpandButton={get(chapter, 'type') !== 'chapter'}
              >
                {curriculumList.topics
                  && curriculumList.topics[chapter.chapter_id]
                  && curriculumList.topics[chapter.chapter_id].data
                  && curriculumList.topics[chapter.chapter_id].data.map((topic) => (
                    <Column
                      onClick={() => selectTopicAndChapter(chapter, topic)}
                      className={classNames('topic', {
                        activeTopic: selectedTopic && selectedTopic.id === topic.id,
                      })}
                    >
                      <div className="topic__title">{topic.name_header}</div>
                      <div className="topic__name">{topic.name}</div>
                    </Column>
                  ))}
              </Accordian>
            ))}
        </Column>
      </div>
    ),
  };

  return (
    <div className="create-question-post">
      {/* <PostModal text1="abcd" text2="abcd" btn1="btn1" btn2="btn2" type="delete" /> */}
      <Column className="create-question-post--container no-padding">
        {!hideAdditionalHeading && (
          <Row
            className="create-question-post--container__header"
            style={{ background: '#F2F7FF' }}
          >
            <div className={classNames('file-attachment__header__types--post')}>
              <IconQuestionFeed fill="#31C173" width="20px" />
              <span className="plr-05">Question</span>
            </div>
            <IconPlus
              onClick={() => hideFocusOperationImpl()}
              width="20px"
              height="20px"
              rotate="45"
              className="mlr-2 mtb-1"
              fill="#A6ACC2"
            />
          </Row>
        )}
        <Row align="center" justify="start" className="mt-2 plr-2">
          <Popover {...popoverPropsForQuestions} className="questionList-popover">
            <Row
              onClick={() => toggleQuestionType()}
              className="question-header"
              style={{
                height: 'fit-content',
                background: 'white',
                border: '1px solid #d0d6ee',
                alignSelf: 'center',
                margin: '0',
                width: 'fit-content',
              }}
              gap
            >
              <div>
                {!isEmpty(selectedChapter) ? selectedChapter.name_header || selectedChapter.name : translationText(noonText, 'post.chooseChapterTopic')}
                {!isEmpty(selectedTopic) ? `, ${selectedTopic.name_header}` : '' }
              </div>
              <IconArrowDown fill="#62687A" rotate={showChapterAndLesson ? '180' : '0'} width="15px" height="15px" />
            </Row>
          </Popover>
        </Row>
        <div className={classNames('create-question-post--container--info', { 'plr-2': onlyQuestions })}>
          <HtmlInput
            noonText={noonText}
            isRtl={isRtl}
            emojis={emojis}
            user={user}
            value={translationText(noonText, 'post.askQuestion')}
            type="post"
            textData={(data) => changePostText(data)}
            className={isMobileOnly ? 'plr-1' : ''}
            maxChar={imagePreviewUrls.length > 0 ? 140 : 400}
        />
        </div>
        <Row justify="end" className="create-question-post--container--question  plr-2 mtb-2">
          <Column className="create-question-post-options">
            <Row gap="sm" className="create-question-options--select">
              <React.Fragment>
                {!imagePreviewUrls.length ? (
                  <Row className="create-question-options--select" gap="sm">
                    {btnListType.map((btn, idx) => (
                      <div className="btn-upload">
                        <label htmlFor={`uploadPic-${idx}`} role="button" className="noon-btn-fab-lg noon-btn-fab noon-btn noon-btn-secondary no-padding child" style={{ minWidth: 'auto' }}>
                          <input
                            id={`uploadPic-${idx}`}
                            className="btn-upload--attach"
                            type="file"
                            onChange={(e) => handleFileUpload(e, btn.type)}
                            accept={btn.accept}
                            style={{
                              opacity: '0',
                              position: 'absolute',
                              pointerEvents: 'none',
                              width: '1px',
                              height: '1px',
                            }}
                        />
                          <btn.icon fill={COLORS.brand.base} />
                        </label>
                      </div>
                    ))}
                  </Row>
                ) : (
                  <React.Fragment>
                    {imagePreviewUrls.map((item) => {
                      const { file, id } = item;
                      return (
                        <Row className={classNames('individual-image', {})} key={id}>
                          <img
                            src={URL.createObjectURL(file)}
                            className={classNames('', {
                              'individual-image--opaque-more': isBulkFileUploading,
                            })}
                            alt="comment"
                            width="100px"
                            height="50px"
                            style={{ border: '3px solid #e4ecf8' }}
                          />
                          <IconWrong
                            className="individual-image--cross"
                            width="18px"
                            height="18px"
                            onClick={() => removeImage(id)}
                            fill={COLORS.coolGrey[5]}
                            stroke={COLORS.brand.base}
                              />
                          {isBulkFileUploading && (
                            <Row justify="center" align="center">
                              <IconLoaderDotted />
                            </Row>
                          )}
                        </Row>
                      );
                    })}
                  </React.Fragment>
                )}
              </React.Fragment>
              {is_unified_group_teacher && is_unified_group_teacher.isEnabled && typeof groupDetails.free_percent !== 'undefined' && (
                <Row className="mlr-1" align="center" justify="end">
                  <Checkbox
                    value={isFree ? 'off' : 'on'}
                    bit
                    checked={!isFree}
                    label={translationText(noonText, 'post.showOnlyToPlus')}
                    onChange={() =>  setIsFree(!isFree)}
                    className={classNames('chat-status', isFree ? 'off' : 'on')}
                  />
                </Row>
              )}
              <Button
                type="primary"
                size="large"
                onClick={() => sendQuestion()}
                tooltip={toolTipText()}
                tooltipPos="top left"
                className="text-capitalize prepare"
                value={translationText(noonText, 'poll.publish')}
                disabled={toolTipText() !== 'Submit your question'}
                loading={isBulkFileUploading}
              />
            </Row>
          </Column>
        </Row>
      </Column>
    </div>
  );
};

export default CreateQuestion;

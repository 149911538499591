import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import classNames from 'classnames';
import { IconArrowDown, IconCorrectCircle, IconLoader, IconPoll, IconPost, IconQuestionFeed } from '@noon/quark';
import { get, intersectionWith } from 'lodash-es';

import PropTypes from 'prop-types';
import isEmpty from 'lodash-es/isEmpty';
import { Row, Avatar, Button, Column, Card, Vr } from '@noon/atom';
import { FileAttachment, PostContainer } from './index';
import { userType, translationType } from '../../types';
import { addToast, TOAST_TYPE, updateToast } from '../Toast';
import {
  GET_ALL_POSTS,
  GET_EMOJI,
  CREATE_POST,
  DELETE_POST,
  GET_POST_BY_ID,
  LIKE_POST,
  SEARCH_AND_FILTER,
  BULK_FILE_UPLOAD,
  UPLOAD_IMAGE_PROGRESS,
  RESET_UPLOAD_IMAGE_PROGRESS,
  GET_FOLDER_LIST,
  CLEAR_SEARCH_AND_FILTER,
  CREATE_POLL_POST,
  NOTIFICATION_POST_DATA,
  NOTIFICATION_COMMENT_DATA,
  UPDATE_POLL_POST,
  GET_COMMENTS,
} from '../../redux/constants';
import { chaptersSelector, gradesSelector, lessonsSelector } from '../../redux/selectors/folders';
import { translationText, slugify } from '../../helpers';
import Mixpanel from '../Mixpanel';
import ErrorBoundry from '../ErrorBoundry';
import { QuestionAndAnswer, QuestionFilter } from './questionAndAnswer';
import { getQueryObject } from '../../helpers/getQueryItem';
import Shimmer from '../Layout/shimmer';

class StudyGroupFeed extends Component {
  static propTypes = {
    user: userType.isRequired,
    noonText: translationType.isRequired,
    featureFlags: PropTypes.shape().isRequired,
    allPosts: PropTypes.shape().isRequired,
    getEmojis: PropTypes.func.isRequired,
    getPosts: PropTypes.func.isRequired,
    emojis: PropTypes.shape().isRequired,
    sendPost: PropTypes.func.isRequired,
    groupDetails: PropTypes.shape().isRequired,
    groupId: PropTypes.string.isRequired,
    sentPost: PropTypes.shape(),
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    postsFiltered: PropTypes.shape(),
    searchAndFilter: PropTypes.func,
    clearSearchAndFilter: PropTypes.func,
    bulkFilesUploaded: PropTypes.arrayOf(PropTypes.object),
    grades: PropTypes.arrayOf(PropTypes.object),
    chapters: PropTypes.arrayOf(PropTypes.object),
    uploadImageProgress: PropTypes.func,
    bulkFileUpload: PropTypes.func,
    resetUploadImageProgress: PropTypes.func,
    imageUploadProgressStatus: PropTypes.arrayOf(PropTypes.object),
    isBulkFileUploading: PropTypes.bool,
    lessons: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    sentPost: {},
    postsFiltered: null,
    searchAndFilter: () => null,
    clearSearchAndFilter: () => null,
    bulkFilesUploaded: [],
    grades: [],
    chapters: [],
    uploadImageProgress: () => null,
    bulkFileUpload: () => null,
    resetUploadImageProgress: () => null,
    imageUploadProgressStatus: {},
    isBulkFileUploading: false,
    lessons: [],
  };

  constructor(props) {
    super(props);
    this.postInput = React.createRef();
    this.offset = 200;

    const values = getQueryObject(this.props.location.search, '=');
    let commentIdOfNotification = null;
    let postIdOfNotification = null;
    if (values.pid) {
      this.props.getPostDataForNotification({ id: values.pid });
      if (values.cid) {
        this.props.getCommentDataForNotification({ commentId: values.cid, postId: values.pid });
      }

      commentIdOfNotification = values.cid;
      postIdOfNotification = values.pid;
      // eslint-disable-next-line react/no-did-update-set-state
    }

    this.state = {
      commentFocus: false,
      chapterList: [],
      showFilterOptions: false,
      postType: '',
      postIdOfNotification,
      viewComponent: postIdOfNotification ? 'notification' : 'normal',
    };
    this.onCommentClick = this.onCommentClick.bind(this);
    this.onCommentRemoveFocus = this.onCommentRemoveFocus.bind(this);
    this.sendPost = this.sendPost.bind(this);
    this.loadMorePosts = this.loadMorePosts.bind(this);
    this.goBackToFeed = this.goBackToFeed.bind(this);
    this.logger = LoggingManager.mount({ moduleName: 'group' });
  }

  componentDidMount() {
    if (isEmpty(this.props.emojis)) {
      this.props.getEmojis();
    }
    this.props.getPosts({ id: this.props.groupId, start: 0, limit: 3 });
  }

  addOpenPostId = (postId) => {
    this.setState({ openPostId: postId });
  };

  componentDidUpdate(preVProps, prevState) {
    const { sentPost, noonText, viewComponentState, groupId, groupDetails } = this.props;
    const { viewComponent } = this.state;

    if (preVProps.location !== this.props.location) {
      // eslint-disable-next-line react/no-did-update-set-state
      const values = getQueryObject(this.props.location.search);
      if (values.pid) {
        this.props.getPostDataForNotification({ id: values.pid });
        if (values.cid) {
          this.props.getCommentDataForNotification({ commentId: values.cid, postId: values.pid });
        }
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ postIdOfNotification: values.pid });
      }
    }

    // needed
    if (!isEmpty(sentPost.response) && preVProps.sentPost.response !== sentPost.response) {
      this.props.getPosts({ id: this.props.groupId, start: 0, limit: 3 });
    }

    if (viewComponent !== viewComponentState) {
      this.setState({ viewComponent: viewComponentState });
    }

    if (groupId !== preVProps.groupId) {
      // window.location.reload();
      this.props.getPosts({ id: this.props.groupId, start: 0, limit: 3 });
    }
    // handle in saga
    if (!preVProps.sentPost.isLoading && sentPost.isLoading) {
      // id here is just a unique number
      addToast(translationText(noonText, 'post.createPost'), TOAST_TYPE.FROSTED, 8, 'center', {
        id: 591897,
        noCancelButton: true,
        icon: IconLoader,
      });
    }
    // handle in saga
    if (!sentPost.isLoading && preVProps.sentPost.isLoading) {
      this.setState({ commentFocus: false });
      updateToast(translationText(noonText, 'post.postSuccessFull'), TOAST_TYPE.FROSTED, 3, 'center', {
        id: 591897,
        noCancelButton: true,
        icon: IconCorrectCircle,
        iconFill: '#1adc51',
        iconStroke: '#fff',
      });
    }
  }

  componentWillUnmount() {
    this.props.clearSearchAndFilter();
    this.props.getComments({ clear: true });
    LoggingManager.unmount(this.logger.id);
  }

  handleMixpanelKafkaFeeds = ({ type, data }) => {
    const { groupId, user, groupDetails } = this.props;
    const trackData = {
      user_id: user.user_id,
      group_id: groupId,
      group_name: get(groupDetails, ['group_info', 'title']),
      group_country: get(groupDetails, ['country', 'name']),
      group_subject: get(groupDetails, ['curriculum_tags', 'subject', 'name']),
      group_grades: get(groupDetails, ['curriculum_tags', 'grade', 'name']),
      group_curriculum_type: get(groupDetails, ['curriculum_tags', 'TYPE']),
      group_type: get(groupDetails, ['group_info', 'TYPE']),
      group_age: 'NA',
      no_of_students: get(groupDetails, ['membersInfo', 'total_members']),
      timestamp: Date.now(),
      feed: data,
    };

    Mixpanel.track(type, trackData);
    this.logger.track(type,
      { group: trackData },
    );
  }

  onCommentClick(e) {
    if (!this.state.commentFocus) {
      this.setState({ commentFocus: true });
      if (e) {
        e.stopPropagation();
      }
    }
  }

  postType = (postType) => {
    this.setState({ postType });
    this.onCommentClick();
  };

  onCommentRemoveFocus(e) {
    let clickedInside = false;
    let targetElement = e.target;
    do {
      if (
        (targetElement.classList && targetElement.classList.contains('post-feed--wrapper'))
        || this.clickOnPopoverBtn(e.target, 'smiley-popover')
        || this.clickOnPopoverBtn(e.target, 'questionList-popover')
      ) {
        clickedInside = true;
        break;
      }
      targetElement = targetElement.parentNode;
    } while (targetElement);
    if (clickedInside) {
      e.stopPropagation();
    } else if (this.state.commentFocus) {
      this.setState({ commentFocus: false });
    }
  }

  // eslint-disable-next-line react/sort-comp

  clickOnPopoverBtn = (target, popoverNames) => {
    let targetElement = target;
    do {
      if (targetElement.classList && targetElement.classList.contains(popoverNames)) {
        return true;
      }
      targetElement = targetElement.parentNode;
    } while (targetElement);
    return false;
  };

  sendPost(sendingInfo) {
    const { textData, postType, fileType, imagePreviewUrls, chapterId, lessonId, youtubeVideoIds, isFree } = sendingInfo;
    const commonPayload = {
      id: this.props.groupId,
      text: textData,
      type: postType,
      isFree: isFree,
    };
    if (!fileType) {
      this.props.sendPost({ ...commonPayload, ...(!!chapterId && { chapterId }), ...(!!lessonId && { lessonId }) });
    } else {
      const { bulkFilesUploaded } = this.props;
      const filterBulkFilesUploaded = intersectionWith(bulkFilesUploaded, imagePreviewUrls, (item, imageItem) => item.name.split('##')[1].split('.')[0] === imageItem.id);
      const files = filterBulkFilesUploaded.map((file) => {
        const { meta, name } = file;
        if (name) {
          const decodedName = `${name.split('##')[0]}.${name.split('##')[1].split('.')[1]}`;
          return {
            original: meta.large_url,
            small: meta.small_url,
            medium: meta.medium_url,
            large: meta.large_url,
            thumbnail: meta.thumbnail_url,
            ...(fileType !== 'image' && { name: decodedName }),
            file_type: fileType === 'image' ? 'image' : 'attachment',
          };
        }
      });

      this.props.sendPost({
        ...commonPayload,
        ...(!!chapterId && { chapterId }),
        ...(!!lessonId && { lessonId }),
        files,
      });
      this.props.bulkFileUpload({ clear: true });
    }
    if (postType === 'poll') {
      this.handleMixpanelKafkaFeeds({
        type: 'poll_created',
      });
    } else if (postType === 'announcement') {
      this.handleMixpanelKafkaFeeds({
        type: 'create_announcement_post',
        data: {
          no_of_attachments: get(this.props, ['bulkFilesUploaded', 'length']) || 'NA',
          no_of_youtube_videos: youtubeVideoIds ? youtubeVideoIds.length : '0',
          attachment_file_type: fileType === 'image' ? 'image' : 'attachment',
        },
      });
    }
  }

  hideFocus = () => {
    this.setState({ commentFocus: false });
  };

  loadMorePosts() {
    if (!isEmpty(this.props.allPosts.response.list) && !this.props.allPosts.isLoading) {
      const payload = {
        id: this.props.groupId,
        start: this.props.allPosts.response.meta.paging.start,
        limit: 3,
      };
      if (isEmpty(this.props.postsFiltered)) {
        this.props.getPosts(payload);
      } else {
        this.props.searchAndFilter({
          ...payload,
          qs: this.props.postsFiltered.qs,
          type: this.props.postsFiltered.type,
        });
      }
    }
  }

  showFilter = (e) => {
    if (this.clickOnPopoverBtn(e.target, ['filter-options'])) {
      this.setState((prevState) => ({
        showFilterOptions: !prevState.showFilterOptions,
      }));
      e.stopPropagation();
    }
  };

  submitPoll = (pollData) => {
    this.props.createPollPost({ ...pollData, type: 'poll', groupId: this.props.groupId });
    this.hideFocus();
    this.handleMixpanelKafkaFeeds({
      type: 'poll_created',
    });
  };

  updatePollTime = (updatedPoll) => {
    this.props.updatePollPost({ ...updatedPoll });
    this.hideFocus();
  };

  pollOperationOnParent = () => {
    this.setState({ postType: 'poll' });
    this.onCommentClick();
  };

  goBackToFeed = () => {
    this.setState({ postIdOfNotification: null });
    this.props.clearPostNotificationData();
    this.props.clearCommentNotificationData();
    // window.location.reload();
    // this.props.history.push(`/${localStorage.updatedLocale}/groups/${this.props.match.params.id}/feed`);
  };

  showSinglePost = (postId) => {
    this.setState({ postIdOfNotification: postId });
  };

  showAllQuestions = () => {
    this.setState({ viewComponent: 'allQuestions' });
    this.props.showAllQuestions();
  };

  render() {
    const { postType, viewComponent } = this.state;
    const {
      noonText,
      allPosts,
      emojis,
      user,
      postsFiltered,
      history,
      groupDetails,
      notificationCommentData,
      notificationPostData,
      isArchived,
      featureFlags,
    } = this.props;
    const isRtl = document.body.dir === 'rtl';
    // const grade = !isEmpty(grades) && grades.filter(grade => grade.slug.indexOf(groupDetails.grade) !== -1);

    let btnListType = [
      { icon: IconPost, text: translationText(noonText, 'post.announcement'), fill: '#62687A', type: 'announcement' },
    ];
    if (!isArchived) {
      btnListType = btnListType.concat([{ icon: IconQuestionFeed, text: translationText(noonText, 'post.question'), fill: '#62687A', type: 'question' },
        { icon: IconPoll, text: translationText(noonText, 'post.poll'), fill: '#62687A', type: 'poll' }]);
    }
    return (
      <ErrorBoundry>
        {!this.props.groupDetails && (
          <Card className="mlr-1">
            {this.props.groupDetails.loading ? (
              <Shimmer obj={[{ heading: true }, { box: true, lines: 2 }]} />
            ) : (
              <div className="empty-slate flex-column flex-nowrap align-center">
                <h3>{translationText(noonText, 'groups.welcomeScreenTitle')}</h3>
                <p className="subtitle mb-2">{translationText(noonText, 'groups.welcomeScreenSubtitle')}</p>
                <img src="/assets/images/2020/no-feed.png" alt="no feeed" />
              </div>
            )}
          </Card>
        )}
        {this.props.groupDetails && !this.props.groupDetails.loading && viewComponent === 'notification' && (
          <React.Fragment>
            {!notificationCommentData.loading
            && !notificationPostData.loading
            && !isEmpty(notificationPostData.response) ? (
              <React.Fragment>
                <Button
                  outlined
                  icon={IconArrowDown}
                  rotate={-90}
                  fab
                  iconWidth="10px"
                  className="mr-2 go-back-feed"
                  onClick={this.goBackToFeed}
                />

                <PostContainer
                  post={notificationPostData.response[0]}
                  isRtl={isRtl}
                  groupDetails={this.props.groupDetails}
                  noonText={noonText}
                  user={this.props.user}
                  history={history}
                  pollOperationOnParent={this.pollOperationOnParent}
                  groupId={this.props.groupId}
                  showSinglePost={this.showSinglePost}
                  singlePost
                  handleMixpanelKafkaFeeds={this.handleMixpanelKafkaFeeds}
                  isArchived={isArchived}
                />
              </React.Fragment>
              ) : (
                <Row justify="center" align="center">
                  <IconLoader />
                </Row>
              )}
          </React.Fragment>
        )}
        {this.props.groupDetails && !this.props.groupDetails.loading && viewComponent === 'normal' && (
          <Column gap="lg" className="study-groups__main__content--feed">
            {(isEmpty(postsFiltered) || (!isEmpty(postsFiltered) && isEmpty(allPosts.response.list))) && (
              <div className="post-feed" onClick={this.onCommentRemoveFocus}>
                {this.state.commentFocus && <div className="overlay" />}
                <React.Fragment>
                  <div className={classNames('post-feed--wrapper')}>
                    {!this.state.commentFocus ? (
                      <Card className="feed-card">
                        <Row
                          className="feed-card--container ptb-1"
                          align="center"
                        >
                          <Row flex="1" gap className="feed-card--container--input">
                            <Avatar
                              size={isMobileOnly ? '32px' : '42px'}
                              url={user && user.profile_pic}
                              rounded
                              className="feed-card--container--input__avatar"
                            />
                            <div
                              className="plr-2 ptb-1 full-width br-2"
                              onClick={this.onCommentClick}
                              style={{
                                border: '1px solid #E4ECF8',
                                background: '#fff',
                                color: '#A6ACC2',
                              }}
                            >
                              {translationText(noonText, 'post.shareSomething')}
                            </div>
                            <Row nowrap className="feed-card--container--types" gap>
                              {btnListType.map((btn, idx) => (
                                <btn.icon
                                  key={idx}
                                  onClick={() => { this.postType(btn.type); }}
                                  className="child"
                                  style={{ padding: '0' }}
                                  fill={btn.fill}
                                  width="25px"
                                  height="25px"
                                />
                              ))}
                            </Row>
                            {/* <Input type="text" placeholder={translationText(noonText, 'post.search_post')} hr_optional className="feed-card--container--input__text" onClick={this.onCommentClick} /> */}
                          </Row>
                        </Row>
                      </Card>
                    ) : (
                      <React.Fragment>
                        <FileAttachment
                          noonText={noonText}
                          featureFlags={featureFlags}
                          imageUpload={this.imageUpload}
                          uploadImageProgress={this.props.uploadImageProgress}
                          bulkFileUpload={this.props.bulkFileUpload}
                          bulkFilesUploaded={this.props.bulkFilesUploaded}
                          resetUploadImageProgress={this.props.resetUploadImageProgress}
                          imageUploadProgressStatus={this.props.imageUploadProgressStatus}
                          isBulkFileUploading={this.props.isBulkFileUploading}
                          postInput={this.postInput}
                          emojis={emojis}
                          user={user}
                          hideFocus={this.hideFocus}
                          lessons={this.props.lessons}
                          handlePost={this.sendPost}
                          bulkFileUploadSuccess={this.props.bulkFileUploadSuccess}
                          postType={postType}
                          submitPoll={this.submitPoll}
                          pollExpirePost={this.props.pollExpirePost}
                          updatePollTime={this.updatePollTime}
                          groupDetails={groupDetails}
                          groupId={this.props.groupId}
                          handleMixpanelKafkaFeeds={this.handleMixpanelKafkaFeeds}
                          isArchived={isArchived}
                        />
                      </React.Fragment>
                    )}
                  </div>
                </React.Fragment>
              </div>
            )}

            <QuestionAndAnswer
              noonText={noonText}
              list={allPosts.response.list}
              isRtl={isRtl}
              user={this.props.user}
              groupDetails={this.props.groupDetails}
              history={history}
              pollOperationOnParent={this.pollOperationOnParent}
              groupId={this.props.groupId}
              showSinglePost={this.showSinglePost}
              showAllQuestions={this.showAllQuestions}
              handleMixpanelKafkaFeeds={this.handleMixpanelKafkaFeeds}
              isArchived={isArchived}
            />

            {/* {!isEmpty(postsFiltered) && !isEmpty(allPosts.response.list) && (
                <Row className="full-width">
                  <Column>
                    <h2>{translationText(noonText, 'post.searchAndFilterResultsLabel')}</h2>
                    { !allPosts.loading &&
                    <span>
                      <span>{allPosts.response.meta.total}</span>
                      {translationText(noonText, 'post.searchAndFilterResultsCount')}
                    </span>
                }
                  </Column>
                </Row>
            )} */}

            <ErrorBoundry>
              <PostContainer
                noonText={noonText}
                isRtl={isRtl}
                user={this.props.user}
                groupDetails={this.props.groupDetails}
                history={history}
                pollOperationOnParent={this.pollOperationOnParent}
                groupId={this.props.groupId}
                showSinglePost={this.showSinglePost}
                handleMixpanelKafkaFeeds={this.handleMixpanelKafkaFeeds}
                isArchived={isArchived}
              />
            </ErrorBoundry>
          </Column>
        )}
        {this.props.groupDetails && !this.props.groupDetails.loading && viewComponent === 'allQuestions' && (
          // Have to fix and use proper class here later on, just adding this as a temporary measure
          <Row className="full-width curriculum-content">
            <QuestionAndAnswer
              noonText={noonText}
              list={allPosts.response.list}
              isRtl={isRtl}
              user={this.props.user}
              groupDetails={this.props.groupDetails}
              history={history}
              groupId={this.props.groupId}
              showSinglePost={this.showSinglePost}
              showAllQuestions={this.showAllQuestions}
              onlyQuestions
              handleMixpanelKafkaFeeds={this.handleMixpanelKafkaFeeds}
              isArchived={isArchived}
            />
          </Row>
        )}
      </ErrorBoundry>
    );
  }
}

const mapStateToProps = (state) => ({
  emojis: state.toJS().file.emojis,
  user: state.toJS().user.loggedUser,
  allPosts: state.toJS().posts.allPosts,
  noonText: state.toJS().translation.noonText,
  featureFlags: state.toJS().experiment.featureFlags,
  sentPost: state.toJS().posts.sendPost,
  pollExpirePost: state.toJS().posts.pollExpirePost,
  // groupDetails: groupDetailsSelector(state),
  groupError: state.toJS().groups.error,
  postsFiltered: state.toJS().posts.postsFiltered,
  imageUploadProgressStatus: state.toJS().file.imageUploadProgressStatus,
  isBulkFileUploading: state.toJS().file.isBulkFileUploading,
  bulkFilesUploaded: state.toJS().file.bulkFilesUploaded,
  chapters: chaptersSelector(state),
  grades: gradesSelector(state),
  lessons: lessonsSelector(state),
  notificationPostData: state.toJS().posts.notificationPostData,
  notificationCommentData: state.toJS().posts.notificationCommentData,
});

const mapDispatchToProps = (dispatch) => ({
  getEmojis: () => dispatch({ type: GET_EMOJI.REQUEST }),
  getPosts: (data) => dispatch({ type: GET_ALL_POSTS.REQUEST, payload: data }),
  searchAndFilter: (data) => dispatch({ type: SEARCH_AND_FILTER.REQUEST, payload: data }),
  sendPost: (data) => dispatch({ type: CREATE_POST.REQUEST, payload: data }),
  getPostById: (data) => dispatch({ type: GET_POST_BY_ID.REQUEST, payload: data }),
  deletePost: (data) => dispatch({ type: DELETE_POST.REQUEST, payload: data }),
  likePost: (payload) => dispatch({ type: LIKE_POST.REQUEST, payload }),
  bulkFileUpload: (filesBatch) => dispatch({ type: BULK_FILE_UPLOAD.REQUEST, filesBatch }),
  bulkFileUploadSuccess: (payload) => dispatch({ type: BULK_FILE_UPLOAD.SUCCESS, payload, remove: 1 }),
  uploadImageProgress: (id, percentCompleted) => dispatch({
    type: UPLOAD_IMAGE_PROGRESS,
    payload: { id, percentCompleted },
  }),
  resetUploadImageProgress: () => dispatch({ type: RESET_UPLOAD_IMAGE_PROGRESS }),
  clearSearchAndFilter: () => dispatch({ type: CLEAR_SEARCH_AND_FILTER.REQUEST }),
  createPollPost: (payload) => dispatch({ type: CREATE_POLL_POST.REQUEST, payload }),
  getPostDataForNotification: (payload) => dispatch({ type: NOTIFICATION_POST_DATA.REQUEST, payload }),
  getCommentDataForNotification: (payload) => dispatch({ type: NOTIFICATION_COMMENT_DATA.REQUEST, payload }),
  clearPostNotificationData: () => dispatch({ type: NOTIFICATION_POST_DATA.SUCCESS, payload: { response: {}, isLoading: false } }),
  clearCommentNotificationData: () => dispatch({ type: NOTIFICATION_COMMENT_DATA.SUCCESS, payload: { id: 0 } }),

  getComments: (data) => dispatch({ type: GET_COMMENTS.REQUEST, payload: data }),
  updatePollPost: (payload) => dispatch({ type: UPDATE_POLL_POST.REQUEST, payload }),
  // openActionMenu: data => dispatch({ type: CREATE_CLASS_SIDEBAR.OPEN, payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudyGroupFeed);

import {
  WEBRTC_TOGGLE_AUDIO,
  SET_WEBRTC_STATUS,
  UPDATE_WEBRTC_STREAMS,
  UPDATE_MIC_VOLUME_INDICATOR,
  WEBRTC_TOGGLE_VIDEO,
} from '../constants';

export const toggleAudio = (state: 'REQUEST' | 'SUCCESS' | 'FAILURE', payload?: any) => ({
  type: WEBRTC_TOGGLE_AUDIO[state],
  payload,
});

export const toggleVideo = (state: 'REQUEST' | 'SUCCESS' | 'FAILURE', payload?: any) => ({
  type: WEBRTC_TOGGLE_VIDEO[state],
  payload,
});

export const setWebrtcStatus = (curState: string) => ({
  type: SET_WEBRTC_STATUS,
  payload: curState,
});

export const updateWebrtcStreams = (streams) => ({
  type: UPDATE_WEBRTC_STREAMS,
  payload: streams,
});

export const updateMicVolumeIndicator = (volumeIndicatorData) => ({
  type: UPDATE_MIC_VOLUME_INDICATOR,
  payload: volumeIndicatorData,
});

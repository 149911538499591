/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Popover from 'react-popover';
import { Row, Button, Input, Column } from '@noon/atom';
import { IconArrowDown, IconBackButton } from '@noon/quark';
import { isEmpty } from 'lodash-es';
import { getCountryId, getSelectedCountry } from '../../constants';
import { setAuthorizationHeader, translationText, parseArabic, generateLocalizedUrl } from '../../helpers';
import {
  CREATE_TEMPORARY_ACCESS_TOKEN,
  REFRESH_TOKEN,
  TEMPORARY_TOKEN_PAYLOAD,
  SEND_OTP,
  VERIFY_PHONE_OTP,
  UPDATE_DATA_POINTS,
  UPDATE_TEMP_AUTH_DATA,
  UPDATE_ONBOARDING_COUNTRY,
  RESET_VERIFY_OTP,
  LIST_COUNTRY,
} from '../../redux/constants';
import Mixpanel from '../Mixpanel';

const uuidv4 = require('uuid/v4');

class MobileVerification extends React.Component {
  constructor(props) {
    super(props);
    const selectedCountry = getSelectedCountry();
    this.state = {
      phoneNumber: null,
      showResendLink: true,
      currentlySelectedCountry: selectedCountry || { id: 0, full_name: '', name: '' },
      otpSubmitted: false,
      otpChannel: 'sms',
      isIndia: false,
    };
    this.logger = LoggingManager.mount({ moduleName: 'MobileVerification' });
  }

  componentDidMount() {
    this.checkTokenValidity();
    this.resendLinkTimer = setTimeout(() => {
      this.setState({ showResendLink: true });
    }, 65000);
    /* [start] mixpanel event */
    try {
      Mixpanel.track('onboarding_initiated', {
        from: 'landing_signup_login',
      });
    } catch (e) {
      console.log('error', e);
    }
    /* [end] mixpanel event */
    if (getSelectedCountry().iso_code === 'IN') {
      this.setState({
        isIndia: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { studentTempAuthData, retry_time, verification_id, error, temporaryTokenData } = this.props;
    if ((this.props.tokenData !== prevProps.tokenData && !this.props.tokenData)) {
      this.checkTokenValidity();
    }
    // ToDo: This should be handle in restApi handler or sagas, when api failed because of taken should send request again with same payload
    if (verification_id && !isEmpty(error) && temporaryTokenData.token !== prevProps.temporaryTokenData.token) {
      this.resendOtp(this.state.otpChannel);
    }
    if (
      this.props.verification_id !== prevProps.verification_id
      && this.props.verification_id
      && this.state.phoneNumber
    ) {
      /* [start] mixpanel event */
      try {
        Mixpanel.track('otp_screen_launched');
      } catch (e) {
        console.log('error', e);
      }
      /* [end] mixpanel event */
    }
    if (this.state.showResendLink !== prevState.showResendLink && !this.state.showResendLink) {
      if (studentTempAuthData.resend_otp_count < 3) {
        if (this.resendLinkTimer) clearTimeout(this.resendLinkTimer);
        this.resendLinkTimer = setTimeout(() => {
          this.setState({ showResendLink: true });
        }, retry_time * 1000);
      }
    }
  }

  componentWillUnmount() {
    /* added to stop memory leaks */
    if (this.resendLinkTimer) clearTimeout(this.resendLinkTimer);
    LoggingManager.unmount(this.logger.id);
  }

  checkTokenValidity(type) {
    const { tokenData, temporaryTokenData, serverTime, timeDifference } = this.props;
    if (tokenData) {
      if (new Date(tokenData.a_tkn.exp).getTime() > (new Date().getTime() + timeDifference)) {
        setAuthorizationHeader(tokenData.a_tkn.tkn);
        return true;
      }
    } else if (temporaryTokenData) {
      if (temporaryTokenData.valid_till > (new Date().getTime() + timeDifference)) {
        setAuthorizationHeader(temporaryTokenData.token);
        return true;
      }
      this.createTemporaryAccessToken(type);
      return false;
    } else {
      this.createTemporaryAccessToken(type);
      return false;
    }
    return false;
  }

  createTemporaryAccessToken() {
    const { temporaryTokenPayload } = this.props;
    let dummyPayload = temporaryTokenPayload;
    if (!temporaryTokenPayload) {
      dummyPayload = {
        tenant: 'teacher',
        country: getCountryId(),
        os: 'web',
        device_id: uuidv4(),
      };
      this.props.temporaryTokenPayloadCreation(dummyPayload);
      localStorage.temporaryTokenPayload = JSON.stringify(dummyPayload);
    }
    this.props.createTemporaryAccessToken({ json: dummyPayload });
  }

  submitForm = (ev) => {
    ev.preventDefault();
    const { sendOtp, verifyOtp, verification_id, otp_type, studentTempAuthData } = this.props;
    const { phoneNumber, otp, currentlySelectedCountry, isArabicKeyboard, otpChannel } = this.state;
    if (this.validateForm()) {
      if (this.checkTokenValidity()) {
        if (!verification_id) {
          const payload = {
            identity_type: 'PHONE',
            identity_value: phoneNumber,
            dialing_code: `${currentlySelectedCountry.calling_code}`,
            channel: otpChannel || 'sms', // 'sms' or 'whatsapp'
          };
          sendOtp(payload);
          /* [start] mixpanel event */
          try {
            Mixpanel.track('phone_entered', { keyboard_locale: isArabicKeyboard ? 'AR' : 'EN' });
          } catch (e) {
            console.log('error', e);
          }
          /* [end] mixpanel event */
        } else {
          const payload = {
            identity_type: 'PHONE', // optional
            identity_value: phoneNumber, // optional
            dialing_code: `${currentlySelectedCountry.calling_code}`, // optional
            verification_id,
            otp_type,
            otp: parseInt(otp, 10),
          };
          this.setState({ otpSubmitted: true });
          verifyOtp(payload);

          /* [start] mixpanel event */
          try {
            Mixpanel.track('otp_entered', studentTempAuthData);
          } catch (e) {
            console.log('error', e);
          }
          /* [end] mixpanel event */
        }
      }
    }
  };

  resendOtp = (channel) => {
    const { studentTempAuthData, updateStudentTempAuthData } = this.props;
    updateStudentTempAuthData({ resend_otp_count: studentTempAuthData.resend_otp_count + 1 });
    this.setState({ showResendLink: false, otpChannel: channel },
      () => {
        const { sendOtp } = this.props;
        const { phoneNumber, currentlySelectedCountry, otpChannel } = this.state;
        const payload = {
          resent: true, // flag to identify otp is being resent
          identity_type: 'PHONE',
          identity_value: phoneNumber,
          dialing_code: `${currentlySelectedCountry.calling_code}`,
          retry: true,
          channel: otpChannel || 'sms', // 'sms' or 'whatsapp'
        };
        sendOtp(payload);
      },
    );
  };

  changePhoneNumber = (event) => {
    const phoneNumber = `${parseArabic(event.target.value)}`;
    this.setState({ phoneNumber, invalidPhone: false, isArabicKeyboard: `${phoneNumber}` !== `${event.target.value}` });
  };

  changeOtp = (event) => {
    const otp = `${parseArabic(event.target.value)}`;
    this.setState({ otp });
  };

  onChange(option) {
    const { changeCountryId, location } = this.props;
    this.setState({ currentlySelectedCountry: option, showCountryDropdown: false });
    changeCountryId(option.id);
    const countries = JSON.parse(localStorage.getItem('country'));
    countries.selectedCountry = option;
    localStorage.country = JSON.stringify({ countryList: countries.countryList, selectedCountry: option });
    // this.props.updateCountries(countries);
    delete localStorage.translationData;
    const url = generateLocalizedUrl(window.location.pathname, `${option.iso_code.toLowerCase()}-${option.locale.split('_')[0]}`);
    localStorage.setItem('language', option.locale);
    window.location.href = url;
  }

  handleDropdown = () => {
    const { organisationName } = this.props;
    if (organisationName && localStorage.updatedLocale.indexOf('sa') !== -1) {
      return;
    }
    this.setState((prevState) => ({ showCountryDropdown: !prevState.showCountryDropdown }));
  };

  validateForm = () => {
    const { noonText } = this.props;
    const { phone_validation } = this.state.currentlySelectedCountry;
    const { max_value, min_value, start_values } = phone_validation;
    const regExPhone = new RegExp(`^[${start_values.join(',')}]\\d{${min_value - 1},${max_value - 1}}$`);
    const regExOtp = new RegExp('^\\d{4}$');
    if (this.props.verification_id && !regExOtp.test(this.state.otp)) {
      this.setState({ otpError: translationText(noonText, 'onboarding.otpErrorMessage') });
      return false;
    }
    if (!this.props.verification_id && !regExPhone.test(this.state.phoneNumber)) {
      this.setState({ phoneNumberError: translationText(noonText, 'onboarding.phoneErrorMessage') });
      return false;
    }
    this.setState({ otpError: null, phoneNumberError: null });
    return true;
  };

  resetVerifyOtpHandler = (e) => {
    const { studentTempAuthData, updateStudentTempAuthData } = this.props;
    updateStudentTempAuthData({ change_number_count: studentTempAuthData.change_number_count + 1 });
    this.setState({ phoneNumber: null, invalidPhone: false },
      () => {
        this.props.resetVeryOtp();
      },
    );
    e.preventDefault();
  };

  incrementOTPAttemtps = () => {
    const { studentTempAuthData, updateStudentTempAuthData, otp_type, updateUserDataPoints } = this.props;
    this.setState({ otpSubmitted: false });
    updateStudentTempAuthData({ wrong_otp_count: studentTempAuthData.wrong_otp_count + 1 });

    // updating user data points failure count only in case of login because if he is creating new account
    // or is going to connect a previous account, that failure will be captured at later steps
    if (otp_type === 'login') updateUserDataPoints({ failed_login_count: studentTempAuthData.wrong_otp_count + 1 });
  };

  gerErrorMessage = (error) => {
    const { otpSubmitted } = this.state;
    const { noonText } = this.props;
    let message = '';
    if (error) {
      switch (error.error_code) {
        case 'INCORRECT_OTP':
          if (otpSubmitted) {
            this.incrementOTPAttemtps();
          }
          message = translationText(noonText, 'onboarding.incorrectOtp');
          break;
        case 'OTP_EXPIRED':
          message = translationText(noonText, 'onboarding.otpExpired');
          break;
        default:
          message = translationText(noonText, 'onboarding.genericError');
          break;
      }
    } else if (this.state.otpError) {
      message = this.state.otpError;
    }
    return message;
  };

  setOtpChannel = (channel) => {
    this.setState({ otpChannel: channel });
  }

  render() {
    const { verification_id, temporaryTokenData, countries, loading, error, resent, noonText, organisationName } = this.props;
    const { phone_validation } = this.state.currentlySelectedCountry;
    const { max_value } = phone_validation;
    const {
      phoneNumber,
      otpError,
      phoneNumberError,
      showCountryDropdown,
      currentlySelectedCountry,
      showResendLink,
      otpChannel,
      tokenData,
      isIndia,
    } = this.state;
    const heading = verification_id
      ? translationText(noonText, 'onboarding.veryOtpHeading')
      : translationText(noonText, 'teacherDashboard.teacherHeading');
    const isRtl = document.body.dir === 'rtl';
    const selectedCountry = getSelectedCountry();
    const isWhatsappEnabled = selectedCountry && selectedCountry.onboarding && selectedCountry.onboarding.whatsapp;
    const isSMSEnabled = selectedCountry && selectedCountry.onboarding && selectedCountry.onboarding.sms;
    const popoverProps = {
      isOpen: showCountryDropdown,
      preferPlace: 'below',
      onOuterAction: () => this.handleDropdown(),
      body: (
        <ul className="noon-list">
          {countries.countryList
            .filter((country) => !country.meta)
            .map((country) => (
              <li className="country-dropdown__item" key={country.id} onClick={() => this.onChange(country)}>
                <span>{country.name}</span>
                <img src={country.flag} height="22px" alt={country.name} />
              </li>
            ))}
        </ul>
      ),
    };

    return (
      <div className="login-form sm onboarding">
        <Column align="start">
          {!!verification_id && phoneNumber && (
          <IconBackButton className="onboarding__back-button" rotate={isRtl ? '180' : '0'} onClick={this.resetVerifyOtpHandler} />
          )}
          <h1 className="text-left login-form--mobile-verification-heading">{heading.toUpperCase()}</h1>
        </Column>
        <p
          className="login-form--student-link"
          onClick={() => window.open(`${process.env.STUDENT_URL}${localStorage.updatedLocale}/login`, '_self')}>
          {translationText(noonText, 'teacherDashboard.not_teacher')}
        </p>
        <form noValidate onSubmit={this.submitForm}>
          {!verification_id && (
            <Input
              autoFocus
              outlined
              type="tel"
              name="phone"
              className="phone"
              label={translationText(noonText, 'teacherDashboard.mobile')}
              placeholder="xxxxxxxxx"
              maxLength={max_value}
              onChange={this.changePhoneNumber}
              error={!!phoneNumberError}
              errorMessage={phoneNumberError}
            >
              <Popover {...popoverProps}>
                <Row nowrap align="center" className={classNames('country-selection', { disabled: organisationName && localStorage.updatedLocale.indexOf('sa') !== -1 })} onClick={this.handleDropdown}>
                  <span dir="ltr" className="country-code">
                    {`${currentlySelectedCountry.calling_code}`}
                  </span>

                  <img src={currentlySelectedCountry.flag} alt="flag" height="22px" />
                  <IconArrowDown rotate={showCountryDropdown ? 180 : 0} fill="black" />
                </Row>
              </Popover>
            </Input>
          )}
          {!!verification_id && phoneNumber && (
            <React.Fragment>
              {/* <p>
                {translationText(noonText, 'onboarding.otpSentMessage').replace(
                  '${phoneNumber}',
                  phoneNumber.replace(/.(?=.{3,}$)/g, '*'),
                )}
                <Button className="onboarding__change-number" onClick={this.resetVerifyOtpHandler} type="link">
                  {translationText(noonText, 'onboarding.changePhoneNumber')}
                </Button>
              </p> */}
              <Column className="mb-1 onboarding__otp-data">
                <Input
                  autoFocus
                  outlined
                  error={!!otpError || !!error}
                  errorMessage={this.gerErrorMessage(error)}
                  maxLength="4"
                  type="tel"
                  name="otp"
                  label={translationText(noonText, 'teacherDashboard.otp_label')}
                  onChange={this.changeOtp}
               />
                {!resent && (
                  <p className="onboarding__otp-data--resend-message mt-3">{translationText(noonText, 'teacherDashboard.first_otp_msg')}</p>
                )}
                {resent && (
                  <p className="text-color-green onboarding__otp-data--resend-message mt-3">{translationText(noonText, 'onboarding.otpSentSuccessMessage')}</p>
                )}
                {showResendLink && (
                  <Row justify="center">
                    <ul className="login-form--student mt-1">
                      <li>
                        {translationText(noonText, 'onboarding.resendSameOtp')}
                        {' '}
                        <span className="login-form--student-link" onClick={() => this.resendOtp(otpChannel)}>{translationText(noonText, `onboarding.${otpChannel}`)}</span>
                      </li>
                      {otpChannel === 'sms' ? isWhatsappEnabled : isSMSEnabled && (
                      <li>
                        {translationText(noonText, 'onboarding.retryOTPWith')}
                        {' '}
                        <span className="login-form--student-link" onClick={() => this.resendOtp(otpChannel === 'sms' ? 'whatsapp' : 'sms')}>{translationText(noonText, `onboarding.${otpChannel === 'sms' ? 'whatsapp' : 'sms'}`)}</span>
                      </li>
                      )}
                    </ul>
                  </Row>
                )}
              </Column>
            </React.Fragment>
          )}
          <Column className="otp-button-wrapper">
            {!verification_id && <span>{translationText(noonText, 'onboarding.getOTP')}</span>}
            <Row align="center" justify="center" className="submit-button-block mt-4">
              {(verification_id || (isWhatsappEnabled && !verification_id)) && (
                <Button
                  block
                  htmlType="submit"
                  loading={loading}
                  type="secondary"
                  disabled={!temporaryTokenData && !tokenData}
                  size="xl"
                  onClick={() => this.setOtpChannel(verification_id ? otpChannel : 'whatsapp')}
                  value={translationText(noonText, `onboarding.${verification_id ? 'continue' : 'whatsapp'}`)}
                />
              )}
              {!verification_id && isSMSEnabled && (
                <Button
                  block
                  htmlType="submit"
                  loading={loading}
                  type="primary"
                  disabled={!temporaryTokenData && !tokenData}
                  onClick={() => this.setOtpChannel('sms')}
                  size="xl"
                  value={translationText(noonText, 'onboarding.sms')}
                />
              )}
            </Row>
          </Column>
          {/* {!verification_id && (
            <React.Fragment>
              <Row align="center" justify="center" className="submit-button-block mt-4">
                <hr className="onboarding__separator" />
                <span className="onboarding__or">Or</span>
              </Row>
              <Row align="center" justify="center" className="submit-button-block mt-4">
                <Button block type="orange" loading={false} size="xl" value="Continue With MIP" />
              </Row>
            </React.Fragment>
          )} */}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  noonText: state.toJS().translation.noonText,
  temporaryTokenPayload: state.toJS().biFrost.temporaryTokenPayload,
  tokenData: state.toJS().biFrost.tokenData,
  verification_id: state.toJS().onboarding.verification_id,
  retry_time: state.toJS().onboarding.retry_time,
  loading: state.toJS().onboarding.loading,
  error: state.toJS().onboarding.error,
  resent: state.toJS().onboarding.resent,
  otp_type: state.toJS().onboarding.otp_type,
  studentTempAuthData: state.toJS().dashboard.studentTempAuthData,
  countries: state.toJS().folder.countries,
  temporaryTokenData: state.toJS().biFrost.temporaryTokenData,
  createTemporaryAccessTokenLoading: state.toJS().biFrost.createTemporaryAccessToken.isLoading,
  serverTime: state.toJS().user.serverTime,
  timeDifference: state.toJS().user.timeDifference,
});

const mapDispatchToProps = (dispatch) => ({
  sendOtp: (payload) => dispatch({ type: SEND_OTP.REQUEST, payload }),
  resetVeryOtp: () => dispatch({ type: RESET_VERIFY_OTP }),
  verifyOtp: (payload) => dispatch({ type: VERIFY_PHONE_OTP.REQUEST, payload }),
  changeCountryId: (payload) => dispatch({ type: UPDATE_ONBOARDING_COUNTRY, payload }),
  updateStudentTempAuthData: (item) => dispatch({ type: UPDATE_TEMP_AUTH_DATA, payload: item }),
  updateUserDataPoints: (item) => dispatch({ type: UPDATE_DATA_POINTS, payload: item }),
  createTemporaryAccessToken: (payload) => dispatch({ type: CREATE_TEMPORARY_ACCESS_TOKEN.REQUEST, payload }),
  refreshAcessToken: (payload) => dispatch({ type: REFRESH_TOKEN.REQUEST, payload }),
  temporaryTokenPayloadCreation: (payload) => dispatch({ type: TEMPORARY_TOKEN_PAYLOAD, payload }),
  updateCountries: (countries) => dispatch({ type: LIST_COUNTRY.SUCCESS, payload: countries }),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MobileVerification);

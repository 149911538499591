import { put, call, takeEvery, all } from 'redux-saga/effects';
import isEmpty from 'lodash-es/isEmpty';
import { addToast, TOAST_TYPE } from '../../components/Toast';
import { CREATE_HOMEWORK_SPARTA, DELETE_HOMEWORK_SPARTA, GET_HOMEWORK_DETAILS_SPARTA, GET_NOT_SUBMITTED_USERS, GET_POST_PUBLISHED_HOMEWORKS, GET_PRE_PUBLISHED_HOMEWORKS, GET_QUESTIONS_BY_IDS_SPARTA, GET_SUBMITTED_USERS, GET_USER_RESPONSE, SAVE_HOMEWORK_QUESTIONS_SPARTA, UPDATE_HOMEWORK_SPARTA } from '../constants';
import { spartaApi } from '../restApi';

const createHomeworkApi = ({ hw_details }) => spartaApi.post('homework', hw_details);
const getHomeworkDetailsSpartaApi = ({ hw_id }) => spartaApi.get(`homework/teacher/${hw_id}`);
const saveHomeworkQuestionsSpartaApi = ({ hw_id, data }) => spartaApi.post(`homework/${hw_id}/questions`, data);
const updateHomeworkSpartaApi = ({ hw_id, hw_details }) => spartaApi.post(`homework/${hw_id}`, hw_details);
const getQuestionsByIdsSpartaApi = ({ hw_id, hw_ids }) => spartaApi.get(`homework/teacher/${hw_id}/questions?ids=${hw_ids.join()}`);
const deleteHomeworkSpartaApi = (hw_id) => spartaApi.delete(`homework/${hw_id}`);
const getPrePublishedHomeworksApi = (payload) => spartaApi.get('homework/teacher/drafts', payload);
const getPostPublishedHomeworksApi = (payload) => spartaApi.get('homework/teacher/published', payload);
const getSubmittedUserListApi = ({ hw_id, payload }) => spartaApi.get(`homework/teacher/${hw_id}/submitted`, payload);
const getNotSumbittedUserListApi = ({ hw_id, payload }) => spartaApi.get(`homework/teacher/${hw_id}/not_submitted`, payload);
const getUserResponseApi = ({ hw_id, user_id }) => spartaApi.get(`homework/teacher/${hw_id}/response/${user_id}`);

function* createHomework(hw_details) {
  try {
    yield put({ type: CREATE_HOMEWORK_SPARTA.LOADING });
    const response = yield call(createHomeworkApi, hw_details);
    if (response.status === 200) {
      yield put({
        type: CREATE_HOMEWORK_SPARTA.SUCCESS,
        payload: response.data.id,
      });
    } else {
      yield put({ type: CREATE_HOMEWORK_SPARTA.FAILURE });
      addToast(response.data.message, TOAST_TYPE.ERROR);
    }
  } catch (error) {}
}

function* updateHomeworkSparta({ hw_id, hw_details, notUpdate = false }) {
  try {
    yield put({ type: UPDATE_HOMEWORK_SPARTA.LOADING });
    const response = yield call(updateHomeworkSpartaApi, { hw_id, hw_details });
    if (response.status !== 200) {
      addToast(response.data.message, TOAST_TYPE.ERROR);
    } else {
      yield put({ type: UPDATE_HOMEWORK_SPARTA.SUCCESS, notUpdate });
    }
  } catch (error) {
    addToast(error, TOAST_TYPE.ERROR);
    yield put({ type: UPDATE_HOMEWORK_SPARTA.FAILURE });
  }
}

function* getHomeworkDetailsSparta(payload) {
  if (!isEmpty(payload.hw_id)) {
    console.log('inside', payload);
    try {
      yield put({ type: GET_HOMEWORK_DETAILS_SPARTA.LOADING });
      const response = yield call(getHomeworkDetailsSpartaApi, { hw_id: payload.hw_id });
      if (response.status === 500) {
        addToast(response.data.message, TOAST_TYPE.ERROR);
      } else {
        yield put({ type: GET_HOMEWORK_DETAILS_SPARTA.SUCCESS, payload: response.data });
      }
    } catch (error) {
      console.log(error);
      yield put({ type: GET_HOMEWORK_DETAILS_SPARTA.FAILURE });
    }
  }
}

function* saveHomeworkQuestionsSparta({ hw_id, data, justSaved }) {
  try {
    yield put({ type: SAVE_HOMEWORK_QUESTIONS_SPARTA.LOADING });
    const response = yield call(saveHomeworkQuestionsSpartaApi, { hw_id, data });
    if (response.status === 200) {
      yield put({ type: SAVE_HOMEWORK_QUESTIONS_SPARTA.SUCCESS, justSaved });
      yield put({ type: GET_HOMEWORK_DETAILS_SPARTA.REQUEST, hw_id });
    } else {
      addToast(response.data.message, TOAST_TYPE.ERROR);
    }
  } catch (error) {
    console.log(error);
    yield put({ type: SAVE_HOMEWORK_QUESTIONS_SPARTA.FAILURE });
    addToast(error.message, TOAST_TYPE.ERROR);
  }
}

function* getQuestionsByIdsSparta(hw_id, hw_ids) {
  try {
    yield put({ type: GET_QUESTIONS_BY_IDS_SPARTA.LOADING });
    const response = yield call(getQuestionsByIdsSpartaApi, hw_id, hw_ids);
    if (response.status === 200) {
      yield put({ type: GET_QUESTIONS_BY_IDS_SPARTA.SUCCESS, payload: response.data });
    } else {
      yield put({ type: GET_QUESTIONS_BY_IDS_SPARTA.FAILURE });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: GET_QUESTIONS_BY_IDS_SPARTA.FAILURE });
    addToast(error.message, TOAST_TYPE.ERROR);
  }
}

function* deleteHomeworkSparta({ payload, listing = '' }) {
  try {
    yield put({ type: DELETE_HOMEWORK_SPARTA.LOADING });
    const response = yield call(deleteHomeworkSpartaApi, payload);
    if (response.status === 200) {
      yield put({ type: DELETE_HOMEWORK_SPARTA.SUCCESS, payload: { payload, listing } });
    } else {
      addToast(response.data.message, TOAST_TYPE.ERROR);
      yield put({ type: DELETE_HOMEWORK_SPARTA.FAILURE });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: DELETE_HOMEWORK_SPARTA.FAILURE });
    addToast(error.message, TOAST_TYPE.ERROR);
  }
}

function* getPrePublishedHomeworks({ payload, isDeleted = false }) {
  try {
    yield put({ type: GET_PRE_PUBLISHED_HOMEWORKS.LOADING });
    const response = yield call(getPrePublishedHomeworksApi, payload);
    if (response.status === 200) {
      yield put({ type: GET_PRE_PUBLISHED_HOMEWORKS.SUCCESS, payload: { ...response.data, saveImmediately: payload.last_tag_id === undefined }, isDeleted });
    } else {
      addToast(response.error, TOAST_TYPE.ERROR);
    }
  } catch (error) {
    console.log(error);
  }
}

function* getPostPublishedHomeworks({ payload }) {
  try {
    yield put({ type: GET_POST_PUBLISHED_HOMEWORKS.LOADING });
    const response = yield call(getPostPublishedHomeworksApi, payload);
    if (response.status === 200) {
      yield put({ type: GET_POST_PUBLISHED_HOMEWORKS.SUCCESS, payload: { ...response.data, saveImmediately: payload.last_tag_id === undefined } });
    } else {
      addToast(response.error, TOAST_TYPE.ERROR);
    }
  } catch (error) {
    console.log(error);
  }
}

function* getSubmittedUserList({ hw_id, payload }) {
  try {
    yield put({ type: GET_SUBMITTED_USERS.LOADING });
    const response = yield call(getSubmittedUserListApi, { hw_id, payload });
    if (response.status === 200) {
      yield put({ type: GET_SUBMITTED_USERS.SUCCESS, payload: response.data });
    } else {
      addToast(response.error, TOAST_TYPE.ERROR);
      yield put({ type: GET_SUBMITTED_USERS.FAILURE });
    }
  } catch (error) {
    addToast(error, TOAST_TYPE.ERROR);
  }
}

function* getNotSumbittedUserList({ hw_id, payload }) {
  try {
    yield put({ type: GET_NOT_SUBMITTED_USERS.LOADING });
    const response = yield call(getNotSumbittedUserListApi, { hw_id, payload });
    if (response.status === 200) {
      yield put({ type: GET_NOT_SUBMITTED_USERS.SUCCESS, payload: response.data });
    } else {
      addToast(response.data.message, TOAST_TYPE.ERROR);
      yield put({ type: GET_NOT_SUBMITTED_USERS.FAILURE });
    }
  } catch (error) {
    addToast(error, TOAST_TYPE.ERROR);
  }
}

function* getUserResponse({ hw_id, user_id }) {
  try {
    yield put({ type: GET_USER_RESPONSE.LOADING });
    const response = yield call(getUserResponseApi, { hw_id, user_id });
    if (response.status === 200) {
      yield put({ type: GET_USER_RESPONSE.SUCCESS, payload: response.data, user_id });
    } else {
      addToast(response.error, TOAST_TYPE.ERROR);
    }
  } catch (error) {
    console.log(error);
  }
}

export function* spartaSaga() {
  yield all([
    takeEvery(CREATE_HOMEWORK_SPARTA.REQUEST, createHomework),
    takeEvery(UPDATE_HOMEWORK_SPARTA.REQUEST, updateHomeworkSparta),
    takeEvery(GET_HOMEWORK_DETAILS_SPARTA.REQUEST, getHomeworkDetailsSparta),
    takeEvery(SAVE_HOMEWORK_QUESTIONS_SPARTA.REQUEST, saveHomeworkQuestionsSparta),
    takeEvery(GET_QUESTIONS_BY_IDS_SPARTA.REQUEST, getQuestionsByIdsSparta),
    takeEvery(DELETE_HOMEWORK_SPARTA.REQUEST, deleteHomeworkSparta),
    takeEvery(GET_PRE_PUBLISHED_HOMEWORKS.REQUEST, getPrePublishedHomeworks),
    takeEvery(GET_POST_PUBLISHED_HOMEWORKS.REQUEST, getPostPublishedHomeworks),
    takeEvery(GET_SUBMITTED_USERS.REQUEST, getSubmittedUserList),
    takeEvery(GET_NOT_SUBMITTED_USERS.REQUEST, getNotSumbittedUserList),
    takeEvery(GET_USER_RESPONSE.REQUEST, getUserResponse),
  ]);
}

import React from 'react';
import classNames from 'classnames';
import { Row, Avatar, Button } from '@noon/atom';
import { IconArrowDown, IconCompetitionO, IconEye, IconSessionO } from '@noon/quark';
import moment from 'moment';
import { translationText } from '../../helpers';

const PlayerHeader = (prop) => {
  const { noonText, sessionData, onClose, playbackUsers, peakActiveUsersCount, currentActiveUsersCount } = prop;
  const isRtl = document.body.dir === 'rtl';

  return (
    <Row nowrap align="center" className={classNames('playback__header', 'old')}>
      <div className="player-card flex-1">
        <Avatar rounded noShadow url={sessionData.creator ? sessionData.creator.profile_pic : null} teacher />
        <div className={classNames('player-info')}>
          <div className="session-time">
            {sessionData.class_type === 'competition' ? <IconCompetitionO className="session-class-type competition" /> : <IconSessionO className="session-class-type group" />}
            <span
              className={classNames(
                'mlr-05',
                sessionData.class_type === 'competition' ? 'text-color-orange' : 'text-color-primary',
              )}
            >
              {translationText(
                noonText,
                `session.type${sessionData.class_type === 'competition' ? 'Competition' : 'Group'}`,
              )}
            </span>
            {' • '}
            <span>{moment(sessionData.start_time).format('ddd Do MMM h:mm a')}</span>
          </div>
          <div className="session-title">{sessionData.title}</div>
        </div>
      </div>
      <Row className="secondary-area" justify="center" align="center">
        {!!playbackUsers.length && (
          <div className="playback__body--attendees">
            <span>{playbackUsers.length}</span>
            <IconEye fill="#ffffff" width="20px" height="20px" />
          </div>
        )}
        <Button
          type="link"
          size="lg"
          onClick={() => onClose('exit', { peakActiveUsersCount, currentActiveUsersCount })}
        >
          {translationText(noonText, 'competition.exit')}
          <IconArrowDown rotate={isRtl ? 90 : 270} />
        </Button>
      </Row>
    </Row>
  );
};

export default PlayerHeader;

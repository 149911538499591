import React, { useEffect, useState } from 'react';
import {
	IAgoraRTCRemoteUser,
} from 'agora-rtc-sdk-ng';
import { Column, Row, Avatar, Button, LiveTime, Badge } from '@noon/atom';
import { IconRaiseHandMic, IconRaiseHandMicOff } from '@noon/quark';
import t from '../../../helpers/translate';
import { RaiseHandStatus } from '../types';
import useNoonDuration from '../../../hooks/useNoonDuration';
import { useSelector } from 'react-redux';
import first from 'lodash-es/first';
import { useClassroomAnalytics } from '../../../providers/ClassroomAnalytics';
import { COLORS } from '../../../constants';

export type raiseHandTypes = 'start_speaking' | 'mute' | 'unmute' | 'remove';

export type IRaiseHandUser = {
    name: string;
    profile_pic: string;
    user_id: number;
    status?: string;
    type?: raiseHandTypes;
    insertTime: string;
    active?: boolean;
    totalRequest?: number;
    total_class_attended?: number;
    cancelledWithin?: number;
    id: number | string;
}

type Props = {
    onRaiseHandAction: (type: raiseHandTypes, user: IRaiseHandUser) => void,
    raiseHandUser: IRaiseHandUser,
    activeUser: IRaiseHandUser,
    raiseHandLoadingFor: string | number,
    audioStream?: IAgoraRTCRemoteUser
}

const RaiseHandUser = (prop: Props) => {
  const { data } = useSelector((state) => state.toJS().myClassroom.studentClassroomMeta);
  const sessionDetails = useSelector((state) => state.toJS().myClassroom.sessionDetails);
  const loggedUser = useSelector((state) => state.toJS().user.loggedUser);

  const [showUserState, setShowUserState] = useState(false);
  const sendAnalytics = useClassroomAnalytics();
  const { raiseHandUser, activeUser, raiseHandLoadingFor, onRaiseHandAction, audioStream } = prop;
  const isActiveUser = raiseHandUser.user_id === activeUser?.user_id;
  const raiseHandTime = useNoonDuration({ ms: raiseHandUser?.insertTime || Date.now() });

  const userClassroomMeta = first(data[raiseHandUser.user_id]);

  const handleOnHoverUser = (state: boolean) => {
    setShowUserState(prevState => state);
    sendAnalytics('raisehand_info_viewed', {
      student_id: raiseHandUser.user_id,
      session_id: sessionDetails.id,
      group_id: sessionDetails.group.id,
      teacher_id: loggedUser.id,
      event_id: raiseHandUser.id,
      new_badge: userClassroomMeta?.total_class_attended === 0 ? 1 : 0,
      classes_attended: userClassroomMeta?.total_class_attended,
      raisehand_requests: raiseHandUser.totalRequest,
    });
  };

  const handleOnClickActionButton = () => {
    onRaiseHandAction(!isActiveUser ? 'start_speaking' : 'remove', raiseHandUser)
  }

  useEffect(() => {
    if(raiseHandUser.status === RaiseHandStatus.cancelled) {
      sendAnalytics('missed_hand_raise', {
        student_id: raiseHandUser.user_id,
        session_id: sessionDetails.id,
        group_id: sessionDetails.group.id,
        teacher_id: loggedUser.id,
        event_id: raiseHandUser.id,
      });
    } else if(raiseHandUser.status === RaiseHandStatus.pending && userClassroomMeta) {
      sendAnalytics('raise_hand_received', {
        student_id: raiseHandUser.user_id,
        session_id: sessionDetails.id,
        group_id: sessionDetails.group.id,
        teacher_id: loggedUser.id,
        event_id: raiseHandUser.id,
        new_badge: userClassroomMeta?.total_class_attended === 0 ? 1 : 0
      });
    }
  }, [raiseHandUser, userClassroomMeta]);

  return (
    <Row onMouseLeave={() => handleOnHoverUser(false)} onMouseEnter={() => handleOnHoverUser(true)} gap="sm" align="center" className="raisehand__user" nowrap>
      {showUserState &&
      <Column className="raisehand__user-stats" justify="start" align="start">
        <span className='raisehand__user-stats__raisehand-count'>{t("classroom", "classCount").replace('{count}', `${userClassroomMeta?.total_class_attended || 0}`)}</span>
        <span className='raisehand__user-stats__class-count'>{t("classroom", "raiseHandCount").replace('{count}', `${raiseHandUser.totalRequest}`)}</span>
      </Column>}
      {userClassroomMeta?.is_plus_user ?
        <Button rounded size="sm" className="raisehand__user__plus-tag">{t('groups', 'plus')}</Button>
        : (userClassroomMeta?.total_class_attended === 0 && !isActiveUser && <Button rounded size="sm" className="raisehand__user__new-tag">{t('homework', 'new')}</Button>)
      }
      <Avatar url={raiseHandUser.profile_pic} size="4rem" rounded noShadow />
      {isActiveUser &&
      <Badge rounded type="default" size="sm" className="raisehand__user__mic">
        {!audioStream ?
          <IconRaiseHandMicOff fill={COLORS.orange.base} height="12px" width="12px" /> :
          <IconRaiseHandMic fill={COLORS.primary.base} height="12px" width="12px" />
        }
      </Badge>}
      <Column flex="1" nowrap>
        <div className="raisehand__user__name" title={raiseHandUser.name}>{raiseHandUser.name}</div>
        <small className='raisehand__user__time'>
          {isActiveUser ? (
            <Badge size="sm" type="default" className="badge-dark">
              <LiveTime value={activeUser.insertTime || 0} />
            </Badge>
          ) : (
            <span>{raiseHandTime}</span>
          )}
        </small>
      </Column>
      { 
      raiseHandUser.status === RaiseHandStatus.accepted ?
      <span className='raisehand__user__status__accepted'>{t('label', 'requestAccepted')}</span> :
      raiseHandUser.status === RaiseHandStatus.cancelled ?
      <span className='raisehand__user__status__cancelled'>{t('classroom', 'cancelled')}</span> :
      (raiseHandUser.active || raiseHandUser.status === RaiseHandStatus.pending || raiseHandUser.status === RaiseHandStatus.active) &&
      <Button
        size="md"
        type={isActiveUser ? "red" : "secondary"}
        disabled={String(raiseHandLoadingFor) === String(raiseHandUser.user_id)}
        loading={String(raiseHandLoadingFor) === String(raiseHandUser.user_id)}
        onClick={handleOnClickActionButton}
         >
        {isActiveUser ? t('button', 'cancel') : t('button', 'accept')}
      </Button>}
    </Row>
  );
};

export default RaiseHandUser;

const parseArabic = str =>
  Number(
    str
      .replace(
        /[٠١٢٣٤٥٦٧٨٩]/g,
        d => d.charCodeAt(0) - 1632, // Arabic
      )
      .replace(
        /[۰۱۲۳۴۵۶۷۸۹]/g,
        d => d.charCodeAt(0) - 1776, // Persian
      ),
  );

export default parseArabic;

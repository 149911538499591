/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ProgressSpinner, Button, Row, Column, Avatar } from '@noon/atom';
import isEmpty from 'lodash-es/isEmpty';
import { COLORS } from '../../constants';
import ErrorBoundry from '../ErrorBoundry';
import NoonQuestion from '../NoonQuestion/NoonQuestion';
import QuestionChoice from '../NoonQuestion/QuestionChoice';
import t from '../../helpers/translate';

const sec2time = (seconds) => {
  let sec = seconds;
  if (sec > 0) {
    let min = Math.floor(sec / 60);
    sec %= 60;
    sec = sec < 10 ? `0${sec}` : sec;
    min = min < 10 ? `0${min}` : min;
    if (min >= 60) {
      min %= 60;
      min = min < 10 ? `0${min}` : min;
    }
    return `${min}:${sec}`;
  }
  return '00:00';
};

export default class ClassroomQuestion extends Component {
  constructor(props) {
    super(props);
    const { teamFormationComplete } = this.props || {};
    this.state = {
      choiceId: 0,
      showPollPopover: false,
      times: [60, 90, 120, 180, 240, 300].concat(teamFormationComplete ? [420] : [15, 30, 45]).sort((a, b) => a - b),
    };
  }

  componentDidUpdate({ teamIsLoading, slide }) {
    if (
      // eslint-disable-next-line react/destructuring-assignment
      teamIsLoading !== this.props.teamIsLoading
      // eslint-disable-next-line react/destructuring-assignment
      && !this.props.teamIsLoading
      // eslint-disable-next-line react/destructuring-assignment
      && this.props.timer === 0
      // eslint-disable-next-line react/destructuring-assignment
      && !this.state.animationHasRun
    ) {
      this.startAnimate();
    }

    // eslint-disable-next-line react/destructuring-assignment
    if (slide.resource_id !== this.props.slide.resource_id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showPollPopover: false, animationHasRun: false });
    }

  }
  // handleVoteChoice = (choiceId) => {
  //   if (!this.props.voteChoice) return;
  //   const { showAnswer, userData, slide } = this.props;
  //   if (userData.id && !this.state.choiceId && !showAnswer) {
  //     this.setState({ choiceId });
  //     this.props.voteChoice(slide.resource.question_id, choiceId);
  //   }
  // };

  togglePollPopover = () => {
    this.setState((prevState) => ({ showPollPopover: !prevState.showPollPopover }));
  };

  startAnimate = () => {
    this.setState({ isAnimateRank1: true });
    setTimeout(() => {
      this.setState({ isAnimateRank1: false, isAnimateRank2: true });
    }, 1000);
    setTimeout(() => {
      this.setState({ isAnimateRank2: false, animationHasRun: true });
    }, 4000);
  };

  handleSendQuestion = (type, slide, time) => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.sendQuestion(type, slide, time);
    setTimeout(() => {
      this.setState({ showPollPopover: false });
    }, 5000);
  };

  render() {
    const {
      slide,
      showAnswer,
      timer,
      sendQuestion,
      sessionData,
      showSolution,
      teamFormationComplete,
      teams,
    } = this.props;
    const { showPollPopover, times, isAnimateRank1, isAnimateRank2 } = this.state;
    const questionData = slide.resource;
    const [team1, team2] = teams || [];
    const team1Score = team1 ? team1.team_members.filter((item) => item.is_last_choice_correct).length : 0;
    const team2Score = team2 ? team2.team_members.filter((item) => item.is_last_choice_correct).length : 0;
    const warnTimeout = timer < 20;
    return (
      <ErrorBoundry msg={t('error', 'loadingQuestion')}>
        <>
          <div className="question-wrapper" style={{ marginTop: timer > 0 ? '45px' : 0 }}>

            {!isEmpty(team1) && (
            <div className={classNames('topTeam top', { active: isAnimateRank1 })}>
              <Avatar url={team1 ? team1.team_image : ''} className="pointer" size="35px" />
              <div className="topTeam__body">
                <div className="topTeam__title">{team1 ? team1.team_name : ''}</div>
                <span className="topTeam__score">{t('teacherBreakout', 'marks')}</span>
                <span>
                  {team1Score}
                  /
                  {team1 ? team1.team_members.length : 0}
                </span>
              </div>
            </div>
            )}
            {!isEmpty(team1) && (
            <div className={classNames('topTeam top', { active: isAnimateRank2 })}>
              <Avatar url={team2 ? team2.team_image : ''} className="pointer" size="35px" />
              <div className="topTeam__body">
                <div className="topTeam__title">{team2 ? team2.team_name : ''}</div>
                <span className="topTeam__score">{t('teacherBreakout', 'marks')}</span>
                <span>
                  {team2Score}
                  /
                  {team2 ? team2.team_members.length : 0}
                </span>
              </div>
            </div>
            )}
            <div className={classNames('questionWrapper', { english: questionData.text_direction === 'ltr' })} dir={questionData.text_direction}>
              <div className="question-body">
                {!!questionData.question_json && !!questionData.question_json.length ? (
                  <React.Fragment>
                    {questionData.passage_json && (
                    <NoonQuestion dir={questionData.text_direction} content={questionData.passage_json} />
                    )}
                    <NoonQuestion dir={questionData.text_direction} content={questionData.question_json} />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {questionData.passage && JSON.stringify(questionData.passage) !== '{}' && (
                    <p dangerouslySetInnerHTML={{ __html: questionData.passage }} />
                    )}
                    <h3
                      className="questionMatter correct"
                      id="questionPara"
                      dangerouslySetInnerHTML={{ __html: questionData.question }}
                  />
                  </React.Fragment>
                )}

                {!!slide.reviewed && timer > 0 && (
                <ProgressSpinner
                  progress={(timer * 100) / questionData.time_left}
                  text={`${sec2time(timer)}`}
                  radius={30}
                  activeColor={warnTimeout ? '#FF3B30' : COLORS.brand.base}
                  baseColor={warnTimeout ? '#ff3b3033' : '#01C3F94d'}
                  color={warnTimeout ? '#FF3B30' : COLORS.brand.base}
                  className={classNames({ 'question-wrapper__pointer-red': warnTimeout })}
                  fill="#fff"
            />
                )}
              </div>
              {questionData.question_type_name === 'mcq' && (
              <div className="mcq-type-question">
                <div className="choicesList">
                  {questionData.choices.map((choice) => (
                    <div
                      key={choice.id}
                      className={classNames('questionChoice', 'clearfix', {
                        // eslint-disable-next-line react/destructuring-assignment
                        'my-answer': choice.id === this.state.choiceId,
                        correct: choice.is_correct && showAnswer,
                        // eslint-disable-next-line react/destructuring-assignment
                        disabled: this.state.choiceId || showAnswer,
                      })}
                    >
                      {!!choice.answer_json && !!choice.answer_json.length ? (
                        <QuestionChoice question={questionData} choice={choice} />
                      ) : (
                        <div className="choiceBox">
                          <p className="choiceMatter" dangerouslySetInnerHTML={{ __html: choice.answer }} />
                        </div>
                      )}
                      {showAnswer && questionData.time_left > 0 && !!choice.avg_vote && (
                        <div className="total-votes">
                          <span>{`${choice.total_votes}`}</span>
                          <span className="bar" style={{ width: `calc(${choice.avg_vote}% - 35px)` }} />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                {showAnswer && (
                <p>
                  {questionData.total_votes > questionData.total_students
                    ? t('classroom', 'questionAttemptedBy').replace('{NumberOfStudent}', `${questionData.total_votes || 0}/${questionData.total_votes || 0}`)
                    : t('classroom', 'questionAttemptedBy').replace('{NumberOfStudent}', `${questionData.total_votes || 0}/${questionData.total_students || 0}`)}
                </p>
                )}
              </div>
              )}
            </div>

            {/* {!!slide.reviewed && !!teamFormationComplete && showSolution && (
            <div className="preparation-timer-wrapper__breakout">
              <Badge color="red" size="sm">
                {t('teacherBreakout', 'new')}
              </Badge>
              {t('teacherBreakout', 'breakoutEndDesc')}
            </div>
            )} */}
            {showSolution && !!questionData.solution && (
            <Row align="start" justify="center" className="flex-1 question-solution">
              {!!questionData.solution && (
                !!questionData.solution_json && !!questionData.solution_json.length ? (
                  <NoonQuestion className="mt-1" content={questionData.solution_json} dir={questionData.text_direction} />
                ) : (
                  <Column justify="start" className="flex-1 ">
                    <h4 className="mt-2">{t('practice', 'solution')}</h4>
                    <p dir={questionData.text_direction} dangerouslySetInnerHTML={{ __html: questionData.solution }} />
                  </Column>
                )
              )}
            </Row>
            )}
            {/* eslint-disable-next-line react/destructuring-assignment */}
            {this.props.showExplanationButton && (
            // eslint-disable-next-line react/destructuring-assignment
            <Button className="show-more" type="primary" size="md" onClick={this.props.onShowCanvas}>
              {t('tutoring', 'explainInCanvas')}
            </Button>
            )}
            {!slide.reviewed && sessionData.state === 'started' && sessionData.class_type !== 'competition' && (
            <div className="question-overlay">
              <h3>
                {showPollPopover
                  ? t('teacherBreakout', 'timeSelectionBreakout')
                  : t('tutoring', 'selectHowToAskQuestion')}
              </h3>
              <Row nowrap justify="space-lg">
                {showPollPopover && (
                  <div className="time-selection-container">
                    {times.map((time) => (
                      <Button
                        key={time}
                        type="primary"
                        className="time-selection-container__button"
                        outlined
                        onClick={() => this.handleSendQuestion('competitive', slide, time)}
                      >
                        {time}
                        <small>s</small>
                      </Button>
                    ))}
                  </div>
                )}
                {!showPollPopover && (
                  <React.Fragment>
                    <Button size="lg" type="primary" onClick={this.togglePollPopover}>
                      {!teamFormationComplete
                        && `${t('tutoring', 'askStudentsPoll')} ${t('tutoring', 'recommended')}`}
                      {teamFormationComplete && t('teacherBreakout', 'startBreakoutButton')}
                    </Button>
                    <Button size="lg" type="primary" outlined onClick={() => sendQuestion('static', slide)}>
                      {t('tutoring', 'explaintionWithoutTest')}
                    </Button>
                  </React.Fragment>
                )}
              </Row>
              {teamFormationComplete && !showPollPopover && (
                <div className="breakout-mode-selector__popover-body">
                  {t('teacherBreakout', 'breakoutDetails')}
                  <span className="breakout-mode-selector__pointer" />
                </div>
              )}
            </div>
            )}
          </div>
        </>
      </ErrorBoundry>
    );
  }
}

ClassroomQuestion.propTypes = {
  slide: PropTypes.shape().isRequired,
  showAnswer: PropTypes.bool,
  timer: PropTypes.number,
  sendQuestion: PropTypes.func,
  sessionData: PropTypes.shape(),
  showSolution: PropTypes.bool,
  teamIsLoading: PropTypes.bool,
  teamFormationComplete: PropTypes.bool,
  teams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showExplanationButton: PropTypes.bool,
  onShowCanvas: PropTypes.func.isRequired,
};

ClassroomQuestion.defaultProps = {
  showAnswer: false,
  timer: 0,
  sendQuestion: null,
  sessionData: {},
  showSolution: false,
  teamIsLoading: false,
  teamFormationComplete: false,
  showExplanationButton: false,
};

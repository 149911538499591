import { Duration } from 'luxon';
import { useMemo } from 'react';
import t from '../helpers/translate';

// Get duration passed using this hook
const useNoonDuration = ({ ms, customSuffix }) => {
  const { time, milliseconds, showSuffix } = useMemo(() => {
    let milliseconds = ms < 0 ? -ms : ms;
    const currentTime = Date.now();
    const showSuffix = currentTime > milliseconds;
    milliseconds = showSuffix ? Math.floor(currentTime - milliseconds) : Math.floor(milliseconds - currentTime);

    const time = {
      month: Math.floor(Duration.fromMillis(milliseconds).as('months')),
      week: Math.floor(Duration.fromMillis(milliseconds).as('weeks')),
      day: Math.floor(Duration.fromMillis(milliseconds).as('days')),
      hour: Math.floor(Duration.fromMillis(milliseconds).as('hours')),
      minute: Math.floor(Duration.fromMillis(milliseconds).as('minutes')),
      second: Math.floor(Duration.fromMillis(milliseconds).as('seconds')),
    };
    return { time, milliseconds, showSuffix };
  }, [ms]);
  // eslint-disable-next-line no-param-reassign
  if (milliseconds < 1000) {
    return t('datetime', 'now') || null;
  }
  return Object.entries(time)
    .filter((val) => val[1] !== 0)
    .map(([key, val]) => `${val} ${t('datetime', key)} ${showSuffix ? customSuffix || t('datetime', 'ago') : ''}`)[0] || null;
};

export default useNoonDuration;

import React from 'react';
import { useParams } from 'react-router-dom';
import MemberList from '../../components/StudyGroup/memberList';

const GroupMembers = () => {
  const { groupId } = useParams();
  return (
    <React.Fragment>
      <MemberList groupId={groupId} />
    </React.Fragment>
  );
};
export default GroupMembers;

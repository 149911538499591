import React from 'react';
import { SaudiLanding, GlobalLanding, EgyptLanding } from '../../components/Landing';
import ErrorBoundry from '../../components/ErrorBoundry';
import { getCountryId } from '../../constants';

export default function Landing(prop) {
  const id = getCountryId();

  return (
    <ErrorBoundry>
      <React.Fragment>
        {String(id) === '1' && <SaudiLanding {...prop} />}
        {!['1', '6'].includes(String(id)) && <EgyptLanding {...prop} />}
        {String(id) === '6' && <GlobalLanding {...prop} />}
      </React.Fragment>
    </ErrorBoundry>
  );
}

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash-es/isEmpty';
import ErrorBoundry from '../../ErrorBoundry';
import Precall from './pre-call';
import { IWebrtcConnectionDetails } from '../WebrtcContainer';

type Props = {
  onCloseClassroom: Function,
  connectionDetails: IWebrtcConnectionDetails,
  setLocalDeviceDetails: () => void
}
const PrecallTestContainer = ({ onCloseClassroom, connectionDetails, setLocalDeviceDetails }: Props) => {
  const sessionDetails = useSelector((state) => state.toJS().myClassroom.sessionDetails);
  const localDevices = useSelector((state) => state.toJS().myClassroom.localDevices);
  const agoraRtcDetails = useSelector((state) => state.toJS().myClassroom.agoraRtcDetails);
  const user = useSelector((state) => state.toJS().user.loggedUser);
  const [showPrecallTest, setShowPrecallTest] = useState(false);

  useEffect(() => {
    if (!isEmpty(sessionDetails)) {
      // remove precall session id after class started
      const classroomPrecallTestSessionIds = localStorage.getItem('classroom_precall');
      if (sessionDetails?.state === 'started' && classroomPrecallTestSessionIds) {
      // split comma seperated string, convert it to array , filter out session id join it back to comma seperated string
        localStorage.setItem('classroom_precall', classroomPrecallTestSessionIds.split(',').reverse().filter((key, index) => (key !== sessionDetails.id && index < 10)).join(','));
      }
      if (!localStorage.getItem('noonSkipPrecall') && sessionDetails?.state === 'scheduled' && !localStorage.getItem('classroom_precall')?.includes(sessionDetails?.id)) {
        setShowPrecallTest(true);
      }
    }
  }, [sessionDetails]);

  const handleEndPrecall = () => {
    setShowPrecallTest(false);
    if (localStorage.getItem('classroom_precall')?.includes(sessionDetails?.id)) return;

    if (localStorage.getItem('classroom_precall')) {
      localStorage.setItem('classroom_precall', `${localStorage.getItem('classroom_precall')},${sessionDetails?.id}`);
    } else {
      localStorage.setItem('classroom_precall', sessionDetails?.id);
    }

    // Remove raisehand data if teacher is opening session for the first time, to start with fresh raisehand data.
    localStorage.removeItem('raiseHandQueue');
    sessionStorage.removeItem('studentClassroomMeta');
  };

  const handleClassroomExit = () => {
    onCloseClassroom();
  };

  return (
    <ErrorBoundry msg="There is some problem with pre call test , please refresh to try again">
      {showPrecallTest && (
        <Precall
          // added this way to support old classroom precall format
          sessionData={{
            ...sessionDetails,
            isCameraWorking: localDevices.isCameraWorking,
            webrtc_details: { webrtc_token: agoraRtcDetails?.token, webrtc_api: agoraRtcDetails?.appId },
          }}
          connectionDetails={connectionDetails}
          user={user}
          endTheTest={handleEndPrecall}
          onExit={handleClassroomExit}
          setLocalDeviceDetails={setLocalDeviceDetails}
        />
      )}
    </ErrorBoundry>
  );

};

export default PrecallTestContainer;

import React, { useState } from 'react';
import { Row } from '@noon/atom';

const LibrariesTabs = (props) => {
  const { tabs, setSelectedTab, selectedTab } = props;

  return (
    <Row justify="space-between" align="center" className="prepare-assignment--tabs">
      {tabs.map((tab) => {
        let cn = 'prepare-assignment--tabs-tab';
        if (tab.value === selectedTab) cn += ' selected-tab';
        
        return (
          <Row
            justify="center"
            align="center"
            className={cn}
            key={tab.value}
            onClick={() => setSelectedTab(tab.value)}
          >
            {tab.name}
          </Row>
        )
      })}
    </Row>
  )
}

export default LibrariesTabs;

import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { WhiteBoard } from '@noon/molecule';

import { usePlaybackTimeSeries } from '../../../providers/PlaybackTimeSeries';
import { ClassroomQuestion, GetReadyScreen } from '../../Class';
import { TPlaybackCanvas } from '../types';

function PlaybackCanvas(props: TPlaybackCanvas) {
  const { playbackView, getReadyTimer = 0, activeSlideData, playerWidth, playerHeight } = props;
  const questionTimer = 0;
  const { sketchData = [], activeSlide } = usePlaybackTimeSeries();

  const whiteboardRef = useRef(null);

  // Redux state
  const { noonText = {} } = useSelector((state) => state.toJS().translation);
  const { playerData, playback_sections } = useSelector((state) => state.toJS().playback);

  const sendData = {
    send: () => {},
  };

  return (
    <div ref={whiteboardRef} data-testid="playback-canvas-area" className="playback__body">
      {playbackView === 'question' ? (
        <div className="playback__question" style={{ width: `${playerWidth}px`, height: `${playerHeight}px` }}>
          {getReadyTimer > 0 ? (
            <GetReadyScreen timer={getReadyTimer} questionData={activeSlideData.resource} noonText={noonText} />
          ) : (
            <ClassroomQuestion
              questionData={activeSlideData.resource}
              timer={questionTimer}
              showAnswer={!questionTimer}
            />
          )}
        </div>
      ) : playerWidth > 0 ? (
        <WhiteBoard
          ref={whiteboardRef}
          slideNumber={activeSlide}
          defaultEvents={sketchData}
          disableToolBar
          socketConnection={sendData}
          drawOnlyMode={playerData?.drawOnlyMode || false}
          width={playerWidth}
          sessionStartTime={playback_sections.session_start}
          currectSeekTime={playerData.playedSeconds}
          isPlayback={true}
          isPaused={playerData.state === "paused"}
        />
      ) : null}
    </div>
  );
}

PlaybackCanvas.propTypes = {
  playbackView: PropTypes.string,
  getReadyTimer: PropTypes.number,
  activeSlideData: PropTypes.shape({}),
  playerWidth: PropTypes.number.isRequired,
  playerHeight: PropTypes.number.isRequired,
};

PlaybackCanvas.defaultProps = {
  playbackView: '',
  getReadyTimer: 0,
  activeSlideData: {},
};

export default PlaybackCanvas;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { isTablet } from 'react-device-detect';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { ContentOnTop } from '@noon/atom';
import { userType } from '../../types';
import ErrorBoundry from '../ErrorBoundry';
import SideMenu from '../SideMenu';
import { WelcomeReturningUser } from '../WelcomeDialogue';
import { getFirebaseRemoteConfigValue } from '../Firebase';
import { TOGGLE_SIDEBAR } from '../../redux/constants';
import { useLocalStorage } from '../../hooks';
import { getSelectedCountry } from '../../constants';

const ProtectedRoute = ({
  user,
  isAuthenticated,
  noHeader,
  navType,
  component: Component,
  history,
  sidebarClosed,
  noSidebar,
  toggleSidebar,
  showNotificationBar,
  ...rest
}) => {
  localStorage.lastUrl = JSON.stringify(rest.location);
  const [welcomeMessageShown, toggleWelcomeMessage] = useState(localStorage.welcomeMessageShown || false);

  const handleWelcomeClose = () => {
    toggleWelcomeMessage(true);
    localStorage.welcomeMessageShown = true;
  };

  const isNotPublished = user && !user.publish && !['approval', 'complete-profile'].includes(rest.location.pathname.split('/')[2]);

  return (
    <ErrorBoundry>
      <Route
        {...rest}
        protected
        render={(props) => (isAuthenticated ? (
          isNotPublished ? (
            <Redirect to={`/${localStorage.updatedLocale}/approval`} />
          ) : (
            <React.Fragment>
              {!welcomeMessageShown && localStorage.otp_type === 'login' && (
                <ContentOnTop position="welcome" closeButtonCustom backDropClassName="welcome-bg">
                  <WelcomeReturningUser onClose={handleWelcomeClose} />
                </ContentOnTop>
              )}
              {!noSidebar && <SideMenu user={user} location={rest.location} sidebarOpen={!sidebarClosed} />}
              <main
                id="main-root"
                className={classNames('noon-content', 'teacher-content', 'animated', 'fadeIn', {
                  showHeader: !noHeader,
                  'no-sidebar': noSidebar,
                  'sidebar-open': !noSidebar && !sidebarClosed,
                  'show-notification-bar': showNotificationBar,
                })}
              >
                {isTablet && !sidebarClosed && <span className="sidebar-outer" onClick={() => toggleSidebar(true)} />}
                <header id="protectedHeader" className="noon-header" />
                <Component noSidebar={noSidebar} {...props} />
              </main>
            </React.Fragment>
          )
        ) : (
          <Redirect to={`/${localStorage.updatedLocale}/login`} />
        ))}
      />
    </ErrorBoundry>
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]).isRequired,
  isAuthenticated: PropTypes.bool,
  user: userType.isRequired,
  history: PropTypes.shape(),
  noHeader: PropTypes.bool,
  noSidebar: PropTypes.bool,
  navType: PropTypes.string,
  sidebarClosed: PropTypes.bool,
  toggleSidebar: PropTypes.func.isRequired,
  showNotificationBar: PropTypes.bool,
};

ProtectedRoute.defaultProps = {
  noHeader: false,
  noSidebar: false,
  history: {},
  navType: 'default',
  isAuthenticated: false,
  sidebarClosed: true,
  showNotificationBar: false,
};

const mapStateToProps = (state) => ({
  user: state.toJS().userv2.getTeacher.response,
  isAuthenticated: !!state.toJS().userv2.getTeacher.response.user_id,
  sidebarClosed: state.toJS().dashboard.sidebarClosed,
  showNotificationBar: state.toJS().notification.notificationBar.show,
});
const mapDispatchToProps = (dispatch) => ({
  toggleSidebar: (payload) => dispatch({ type: TOGGLE_SIDEBAR, payload }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute));

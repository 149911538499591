import React from 'react';
import PropTypes from 'prop-types';
import { Row } from '@noon/atom';

function FilterGroupSubject({ product }) {
  return (
    <Row nowrap justify="start" align="center" gap="sm">
      {product.pic && <img className="img-product child" src={product.pic} alt={product.name} />}
      <span className="child title flex-1">{product.name}</span>
    </Row>
  );
}

FilterGroupSubject.propTypes = {
  product: PropTypes.shape({
    pic: PropTypes.string,
    name: PropTypes.string,
  }),
};

FilterGroupSubject.defaultProps = {
  product: {
    pic: '',
    name: '',
  },
};
export default FilterGroupSubject;

import React from 'react';
// eslint-disable-next-line import/no-cycle
import { TimePicker } from '../Create';

const DatePicker = ({
  onSelect,
  dateRef,
  onToggle,
  ms,
  placeholder,
  timePlaceholder,
  startTime,
  defaultValue,
}) => (
  <div className="field-outer">
    <label className="label">{startTime}</label>
    <ul className="field-listing">
      <li>
        <input
          dir="ltr"
          type="text"
          ref={dateRef}
          placeholder={placeholder}
          className="form-control"
          onSelect={onSelect}
          />
      </li>
      <li>
        <TimePicker
          onToggle={onToggle}
          ms={ms}
          placeholder={timePlaceholder}
          defaultValue={defaultValue}
        />
      </li>
    </ul>
  </div>
);

DatePicker.defaultProps = {
  defaultValue: true,
  timePlaceholder: '',
};

export default DatePicker;

import React from 'react';
import Image from 'react-lazy-image';
import { translationText } from '../../../helpers';

export default function FeatureStudy(prop) {
  return (
    <section className="classroom-section class-demo">
      <div className="container text-center">
        <div className="head-fade-line">
          <span className="fading-line" />
        </div>
        <h5 className="heading-section">{translationText(prop.noonText, 'teacherLanding.classroomDemoTitle')}</h5>
        <p className="subheading-section">{translationText(prop.noonText, 'teacherLanding.classroomDemoSubtitle')}</p>
        <Image
          offset={100}
          source="/assets/images/landing/feature-classroom.jpg"
          className="allsubject_img"
          alt="subject icons"
        />
      </div>
    </section>
  );
}

import React from 'react';
import classNames from 'classnames';
import { Row, Flexbox } from '@noon/atom';

const TabBar = (prop) => {
  const { className, tabs, onTabClick, activeTab, tabCount = {}, wrap } = prop;

  return (
    <Row nowrap className={classNames('tab-bar', { wrap }, className)}>
      {tabs.map((tab) => {
        const Icon = tab.icon;
        return (
          <Flexbox
            key={tab.name}
            gap="sm"
            align="center"
            justify="center"
            dir={wrap ? 'column' : 'row'}
            onClick={() => !tab.disabled && onTabClick(tab.name)}
            className={classNames('tab-bar__item', { disabled: tab.disabled, active: activeTab === tab.name })}
          >
            {tab.icon && <Icon />}
            {tab.name && (
              <div className="child">
                {Boolean(tabCount[tab.name]) && <span className="tab-count">{tabCount[tab.name]}</span>}
                {tab.label}
              </div>
            )}
          </Flexbox>
        );
      })}
    </Row>
  );
};

export default TabBar;

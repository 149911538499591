import EventEmitter from 'events';
import AgoraRTM from 'agora-rtm-sdk';
import AGORA_RTM_ERRORS from './agoraRtmErrors';

export default class AgoraRtm extends EventEmitter {

  clientEvents = ['ConnectionStateChanged']; // 'MessageFromPeer'

  channelEvents = ['ChannelMessage']; // 'MemberJoined', 'MemberLeft'

  logined = false;

  constructor({ connectionDetails, connectionStatus, onError, options = {} }) {
    super();

    this.connectionDetails = connectionDetails;
    this.connectionStatus = connectionStatus;
    this.onError = onError;
    this.options = options;
    this.channels = {};
    this.client = AgoraRTM.createInstance(
      connectionDetails.appId,
      {
        enableLogUpload: this.options.enableLogUpload || true,
        logFilter: AgoraRTM.LOG_FILTER_ERROR,
      },
    );
  }

  login = () => new Promise((resolve, reject) => {
    const { uid, token } = this.connectionDetails;
    this.subscribeClientEvents();
    this.client.login({ uid, token })
      .then(() => {
        this.logined = true;
        this.stateChangeListener();
        resolve(true);
      })
      .catch((err) => {
        this.onError(AGORA_RTM_ERRORS[101], err);
        reject(err);
      });
  })

    stateChangeListener = () => {
      this.client.on('ConnectionStateChanged', (newState, reason) => {
        this.connectionStatus(newState, reason);
        if (newState === 'CONNECTED') {
          this.logined = true;
        } else if (newState === 'ABORTED' && reason === 'REMOTE_LOGIN') {
        // fire kickout event if user join from another device
          this.logined = false;
          this.emit('ChannelMessage', { type: 'kickout', message: { event: 'need_to_disconnect' } });
        }
      });
    }

    subscribeClientEvents() {
      this.clientEvents.forEach((eventName) => {
        this.client.on(eventName, (...args) => {
          this.emit(eventName, ...args);
        });
      });
    }

    subscribeChannelEvents(channelName) {
      this.channelEvents.forEach((eventName) => {
        this.channels[channelName].on(eventName, (...args) => {
          if (eventName === 'ChannelMessage') {
            const data = JSON.parse(args[0].text);
            const { type } = data;
            let eventName = 'signal:canvasSketch'; // default
            if (type === 'client_data') {
              eventName = 'client_data';
            }
            this.emit(eventName, { channelName, ...data });
            this.emit('ChannelMessage', { channelName, ...data });
          } else {
            this.emit(eventName, args);
          }
        });
      });
    }

    joinChannel(channelName) {
      return new Promise((resolve, reject) => {
        try {
          const channel = this.client.createChannel(channelName);
          this.channels[channelName] = channel;
          this.subscribeChannelEvents(channelName);
          channel.join()
            .then(() => {
              resolve(channel);
            })
            .catch((err) => {
              delete this.channels[channelName];
              this.onError(AGORA_RTM_ERRORS[102], err);
              reject(err);
            });
        } catch (err) {
          delete this.channels[channelName];
          this.onError(AGORA_RTM_ERRORS[102], err);
          reject(err);
        }
      });
    }

    async leaveChannel(channelName) {
      if (!this.channels[channelName] || !this.logined) return;
      await this.channels[channelName].leave();
    }

    async sendMessage(text, channelName, retryCount = 3) {
      if (!this.channels[channelName]) {
        this.onError(AGORA_RTM_ERRORS[103]);
        return null;
      }
      try {
        const response = await this.channels[channelName].sendMessage({ text: JSON.stringify(text) });
        return Promise.resolve(response);
      } catch (err) {
        if (retryCount > 0) {
          return this.sendMessage(text, channelName, retryCount - 1);
        }
        if (err) {
          const error = JSON.stringify(err);
          if (error && error.indexOf('RtmLimitExceededError') !== -1) {
            this.onError(AGORA_RTM_ERRORS[108], err);
          } else {
            this.onError(AGORA_RTM_ERRORS[104], err);
          }
        }
        return Promise.reject(err);
      }
    }

    async sendPeerMessage(text, peerId) {
      return this.client.sendMessageToPeer({ text }, peerId.toString()).catch((err) => {
        this.onError(AGORA_RTM_ERRORS[105], err);
      });
    }

    async disconnect() {
      if (this.client && this.client.connectionState !== 'DISCONNECTED') {
        // eslint-disable-next-line no-unused-vars
        for (const channel in this.channels) {
          if (channel) {
            this.leaveChannel(channel);
          }
        }
        return this.client.logout().catch((err) => {
          this.onError(AGORA_RTM_ERRORS[106], err);
        });
      }
      this.onError(AGORA_RTM_ERRORS[107]);
      return false;
    }
}

import { ADD_CHAT, SIDEBAR_TAB_COUNT } from '../../../redux/constants';
import store from '../../../store';

let userId = 0; // caching id to improve performance, reducing getState() calls

export default function handleMsg(dispatch, e) {
  if (!userId) userId = store.getState().toJS().user?.loggedUser?.id;

  const markDelivered = String(e.message?.user_id) === String(userId);

  dispatch({ type: ADD_CHAT, payload: { ...e.message, isDelivered: true, markDelivered } });
  dispatch({ type: SIDEBAR_TAB_COUNT, payload: { tab: 'chat' } });
}

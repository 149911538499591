import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import { Row, ContentOnTop, Select, Input, Badge, Column, Button, MultiSelect } from '@noon/atom';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime, Info, Interval } from 'luxon';
import Pikaday from 'pikaday';
import { IconAdd, IconArrowDown, IconBulb, IconCalendarPlus, IconClose, IconEdit, IconEye, IconWrong } from '@noon/quark';
import { flatten, get, groupBy, head, intersectionWith, isEmpty, isEqual, map, reject, uniqBy } from 'lodash-es';
import Popover from 'react-popover';
import TimePicker from './timePicker';
import { COLORS, GROUP_DURATION } from '../../constants';
import { SCHEDULER_CREATE_SCHEDULE, SCHEDULER_GET_SCHEDULES, SCHEDULER_UPDATE_SCHEDULE } from '../../redux/constants';
import { getWeekdays, getWeekdaysShort } from '../../helpers/date';
import { translationText } from '../../helpers';
import Mixpanel from '../Mixpanel';

function CreateSchedule({ schedules, onClose, source }) {
  const dateRef = useRef(null);
  const weekdaysShort = getWeekdaysShort();
  const weekdays = getWeekdays(true).map((day, index) => ({ id: index, name: day }));
  const dayHours = Array.from({ length: 25 });
  const firstSession = head(schedules);
  const currentDateTime = DateTime.local();

  const { noonText } = useSelector((state) => state.toJS().translation);
  const everyDay = { id: 8, name: translationText(noonText, 'datetime.everyday') };

  const SLOT_DURATION = [
    {
      id: 1,
      name: `30 ${translationText(noonText, 'label.minutes')}`,
      time: 30,
    },
    {
      id: 5,
      name: `45 ${translationText(noonText, 'label.minutes')}`,
      time: 45,
    },
    {
      id: 2,
      name: `60 ${translationText(noonText, 'label.minutes')}`,
      time: 60,
    },
    {
      id: 3,
      name: `90 ${translationText(noonText, 'label.minutes')}`,
      time: 90,
    },
    {
      id: 4,
      name: `120 ${translationText(noonText, 'label.minutes')}`,
      time: 120,
    },
  ];

  const [selectedDays, setSelectedDays] = useState([]);
  const [totalSlots, setTotalSlots] = useState(() => {
    const groupedSchedules = Object.values(groupBy(schedules, ((schedule) => (schedule.start_time + schedule.duration))));

    return groupedSchedules.map((schedule) => {
      const firstSession = head(schedule);
      const [hour, minute] = firstSession.start_time.split(':');
      const startTimeHour = currentDateTime.set({ hour, minute }).toFormat('hh:mm a');
      const days = weekdays.filter((day) => schedule.findIndex((scheduleDay) => scheduleDay.day === day.name.toLowerCase()) > -1);
      return {
        duration: SLOT_DURATION.find((duration) => duration.time === firstSession.duration),
        days,
        selectedSlotHour: parseInt(hour, 10),
        selectedSlotMinutes: parseInt(minute, 10),
        startTime: startTimeHour,
      };
    });
  });
  const [selectedSlotDuration, setSelectedSlotDuration] = useState({});
  const [selectedSlotHour, setSelectedSlotHour] = useState(parseInt(currentDateTime.toFormat('HH'), 10));
  const [selectedSlotMinutes, setSelectedSlotMinutes] = useState(currentDateTime.toFormat('mm'));
  const [selectedGroupDuration, setSelectedGroupDuration] = useState(() => {
    const validTill = DateTime.fromISO(new Date(firstSession ? firstSession.valid_till : Date.now()).toISOString());
    const validFrom = DateTime.fromISO(new Date(firstSession ? firstSession.valid_from : Date.now()).toISOString());
    const dateDiff = validTill.diff(validFrom, ['weeks']).toObject();
    return GROUP_DURATION.find((duration) => duration.value.weeks === dateDiff.weeks);
  });
  const [formFieldError, setFormFieldError] = useState({});
  const [isSlotFormValid, setIsSlotFormValid] = useState(false);
  const [editingSlot, setEditingSlot] = useState();
  const [showNewSlotButton, setShowNewSlotButton] = useState(false);

  const [showPrevGroupSchedule, setShowPrevGroupSchedule] = useState(false);
  const { createScheduleInProgress, previousSchedules } = useSelector((state) => state.toJS().scheduler);
  const { selectedGroup, groups: { data: groupsList }, curriculumList: { totalTopics } } = useSelector((state) => state.toJS().groupsV2);

  const dispatch = useDispatch();
  const { groupId } = useParams();
  const logger = useRef(LoggingManager.mount({ moduleName: 'group' })).current;

  const remainNoOfSlots = useMemo(() => {
    const fieldError = {
      slots: isEmpty(totalSlots),
      groupDuration: isEmpty(selectedGroupDuration),
      firstSession: (dateRef.current && !dateRef.current.value) && !firstSession.valid_from,
    };
    if (fieldError.slots || fieldError.groupDuration || fieldError.firstSession) {
      return 0;
    }
    const validFrom = DateTime.fromISO(new Date(get(dateRef, ['current', 'value']) || firstSession.valid_from).toISOString());
    const validTill = validFrom.plus(selectedGroupDuration.value);
    const slotsPerWeek = totalSlots.reduce((prevTotalTime, currentSlot) => prevTotalTime + currentSlot.days.length, 0);
    const { weeks } = validTill.diff(validFrom, ['weeks']).toObject();
    return Math.round(totalTopics - (slotsPerWeek * weeks));
  }, [totalSlots]);

  const formatedPreviousSchedule = useMemo(() => {
    if (isEmpty(previousSchedules.list)) {
      return false;
    }
    const scheduleGroupByDay = groupBy(reject(previousSchedules.list, { group_id: groupId }), 'day');
    return weekdays.map((day) => {
      const mappedSlots = (scheduleGroupByDay[day.name.toLowerCase()] || []).map((slot) => {
        const [hour, minute] = slot.start_time.split(':');
        return {
          duration: slot.duration,
          title: slot.title,
          hour: parseInt(hour, 10),
          minute: parseInt(minute, 10),
        };
      });
      return groupBy(mappedSlots, 'hour');
    });
  }, [previousSchedules.list]);

  const prevSchedulePopoverProps = {
    isOpen: showPrevGroupSchedule,
    preferPlace: 'below',
    onOuterAction: () => setShowPrevGroupSchedule(false),
    body: (
      <div className=" create-schedule__prev-calendar">
        <Row flex="1" nowrap align="center" justify="start" className="full-width">
          <Column style={{ height: '100%' }} justify="center" align="center" className="day-column">
            <div className="cell" />
            {dayHours.map((v, index) => (
              <div className="cell">{currentDateTime.set({ hour: index, minute: 0 }).toFormat('hh:mm a')}</div>
            ))}
          </Column>
          {weekdays.map((day, index) => (
            <Column key={day} style={{ height: '100%' }} justify="center" align="center" className="day-column">
              <div className="cell">{day.name}</div>
              {dayHours.map((v, hour) => {
                const [slot] = get(formatedPreviousSchedule, [index, hour]) || [];
                return (
                  <div key={index + hour} className="cell">
                    {slot && (
                    <span
                      title={slot.title}
                      className="slot"
                      style={{
                        top: `${slot.minute * (32 / 60)}px`,
                        height: `${slot.duration * (32 / 60)}px`,
                        lineHeight: `${slot.duration * (32 / 60)}px` }}>
                      {slot.title}
                    </span>
                    )}
                  </div>
                );
              })}
            </Column>
          ))}
        </Row>
      </div>
    ),
  };

  useEffect(() => {
    const picker = new Pikaday({
      field: dateRef.current,
      format: 'YYYY-MM-DD',
      minDate: new Date(get(selectedGroup, ['group_info', 'published_at'])),
      i18n: {
        months: Info.months(),
        weekdays: map(weekdays, 'name'),
        weekdaysShort,
      },
      onSelect: () => {},
    });
    const date = new Date(firstSession ? firstSession.valid_from : Date.now());
    picker.setDate(date);
    dispatch({
      type: SCHEDULER_GET_SCHEDULES.REQUEST,
    });
  }, []);

  useEffect(() => {
    setShowNewSlotButton(!isEmpty(totalSlots));
  }, [totalSlots]);

  const handleOnChangeTime = (value) => {
    const [hour, minute] = DateTime.fromMillis(value).toFormat('HH:mm').split(':');
    setSelectedSlotHour(hour);
    setSelectedSlotMinutes(minute);
  };

  const formatSlotInfo = (slots) => flatten(slots.map((slot) => slot.days.map((day) => ({
    day: day.name.toLowerCase(),
    start_time: currentDateTime.set({ hour: slot.selectedSlotHour, minute: slot.selectedSlotMinutes }).toFormat('HH:mm'),
    duration: slot.duration.time,
  }))));

  const isSlotOverlaping = (slot, existingSlot = []) => intersectionWith(existingSlot, formatSlotInfo([slot]), (firstSlot, secondSlot) => {
    const [hour, minute] = firstSlot.start_time.split(':');
    const startDateTime = DateTime.local().set({ hour, minute });
    const firstSlotInterval = Interval.fromDateTimes(startDateTime, startDateTime.plus({ minutes: firstSlot.duration }));
    const [secondSlotHour, secondSlotMinute] = secondSlot.start_time.split(':');
    const secondSlotStartTime = startDateTime.set({ hour: secondSlotHour, minute: secondSlotMinute });
    return firstSlot.day === secondSlot.day && firstSlotInterval.overlaps(Interval.fromDateTimes(secondSlotStartTime, secondSlotStartTime.plus({ minutes: secondSlot.duration })));
  });

  const handleOnClickAddSlot = () => {
    if (!isSlotFormValid) {
      return false;
    }
    const currentDateTime = DateTime.local();
    const startTimeHour = currentDateTime.set({ hour: selectedSlotHour, minute: selectedSlotMinutes }).toFormat('hh:mm a');

    const newSlot = {
      startTime: startTimeHour,
      duration: selectedSlotDuration,
      days: isEqual(selectedDays, [everyDay]) ? weekdays : selectedDays,
      selectedSlotHour,
      selectedSlotMinutes,
    };

    const overlapingSlots = uniqBy(isSlotOverlaping(newSlot, reject(previousSchedules.list, { group_id: groupId })), 'group_id');
    if (!isEmpty(isSlotOverlaping(newSlot, formatSlotInfo(totalSlots.filter((v, index) => (index !== editingSlot)))) || [])) {
      setFormFieldError({
        slots: translationText(noonText, 'scheduler.duplicateSlotErrorSameGroup'),
      });
    } else if (!isEmpty(overlapingSlots)) {
      setFormFieldError({
        slots: `${translationText(noonText, 'scheduler.duplicateSlotErrorOtherGroup')} ${map(overlapingSlots, 'title').join(',') || 'other group'}`,
      });
    } else if (editingSlot >= 0) {
      totalSlots[editingSlot] = newSlot;
      setTotalSlots([...totalSlots]);
      setEditingSlot(-1);
      setFormFieldError({
        slots: '',
      });
    } else {
      setTotalSlots((prevTotalSlot) => [...prevTotalSlot, newSlot]);
      setFormFieldError({
        slots: '',
      });
    }
  };

  const handleOnClickEditSlot = (slot, index) => {
    setSelectedSlotDuration(slot.duration);
    setSelectedDays(isEqual(slot.days, weekdays) ? [everyDay] : slot.days);
    setSelectedSlotHour(slot.selectedSlotHour);
    setSelectedSlotMinutes(slot.selectedSlotMinutes);
    setEditingSlot(index);
    setFormFieldError({
      slots: '',
    });
    setIsSlotFormValid(true);
    setShowNewSlotButton(false);
  };

  const handleOnClickRemoveSlot = (index) => {
    setTotalSlots((prevTotalSlot) => prevTotalSlot.filter((slot, prevIndex) => index !== prevIndex));
  };

  const handleOnSelectDays = (selectedItem, selectedArray) => {
    setIsSlotFormValid(!isEmpty(selectedArray) && !isEmpty(selectedSlotDuration));
    if (!isEmpty(selectedArray) && (isEqual(selectedDays, [everyDay]) || isEqual(selectedItem, everyDay))) {
      setSelectedDays([selectedItem]);
    } else if (isEqual(selectedArray, weekdays)) {
      setSelectedDays([everyDay]);
    } else {
      setSelectedDays(selectedArray);
    }
  };

  const handleOnClickCreateSchedule = () => {
    const fieldError = {
      slots: isEmpty(totalSlots) ? translationText(noonText, 'error.noSlot') : '',
      groupDuration: isEmpty(selectedGroupDuration),
      firstSession: !dateRef.current.value,
    };

    setFormFieldError(fieldError);
    if (fieldError.slots || fieldError.groupDuration || fieldError.firstSession) {
      return false;
    }
    const validFrom = DateTime.fromISO(new Date(dateRef.current.value).toISOString());
    const validTill = validFrom.plus(selectedGroupDuration.value).toFormat('yyyy-MM-dd');
    const payload = {
      group_id: groupId,
      schedules: formatSlotInfo(totalSlots),
      valid_from: validFrom.toFormat('yyyy-MM-dd'),
      valid_till: validTill,
    };
    const eventPayload = {
      source,
      group_id: groupId,
      no_of_groups: groupsList.length,
      old_schedule: 'None',
      new_schedule: payload.schedules.map((schedule) => `${schedule.day} - ${schedule.start_time}`),
      group_duration: selectedGroupDuration.value,
      first_session: validFrom,
      no_of_slots: totalSlots.length,
    };
    if (!isEmpty(schedules)) {
      dispatch({
        type: SCHEDULER_UPDATE_SCHEDULE.REQUEST,
        payload,
      });
      eventPayload.old_schedule = schedules.map((schedule) => `${schedule.day} - ${schedule.start_time}`);
    } else {
      dispatch({
        type: SCHEDULER_CREATE_SCHEDULE.REQUEST,
        payload,
      });
    }

    const eventName = 'schedule_set';
    Mixpanel.track(eventName, eventPayload);
    logger.track(eventName,
      { group: eventPayload },
    );
  };

  return (
    <ContentOnTop position={isMobileOnly ? 'custom' : 'center'} targetId="main-root">
      <div className="create-activity create-schedule">
        <div className="create-activity_header">
          <Row className="strip" align="center" justify="space-between">
            <h3>{translationText(noonText, 'scheduler.createScheduleTitle')}</h3>
            {onClose && <IconClose onClick={onClose} height="20px" width="20px" fill={COLORS.coolGrey[2]} />}
          </Row>
        </div>
        <Column nowrap className="create-activity__body" gap>
          <Row align="center" justify="start" className="full-width" gap>
            <Select
              // Improve group duration translation
              // label={translationText(noonText, 'label.groupDuration')}
              title={translationText(noonText, 'label.groupDuration')}
              selectedItem={selectedGroupDuration ? { ...selectedGroupDuration, name: `${selectedGroupDuration.display[0]} ${translationText(noonText, `label.${selectedGroupDuration.display[1]}`)}` } : {}}
              list={GROUP_DURATION.map((duration) => ({ ...duration, name: `${duration.display[0]} ${translationText(noonText, `label.${duration.display[1]}`)}` }))}
              onSelect={(value) => { setSelectedGroupDuration(value); }}
              className="flex-1 child"
              error={!!formFieldError.groupDuration}
              errorMessage={translationText(noonText, 'error.selectGroupDuration')}
            />
            <Input
              type="text"
              ref={dateRef}
              placeholder={translationText(noonText, 'label.firstSession')}
              className="form-control flex-1 child"
              error={!!formFieldError.firstSession}
              errorMessage={translationText(noonText, 'error.selectFirstSessionDate')}
            >
              <IconArrowDown />
            </Input>
          </Row>
          <Column gap="sm" align="start" justify="center">
            <Row justify="space-between" align="center" gap flex="1" className="full-width">
              <Row justify="start" align="center" gap="sm">
                <span className="child label">
                  {translationText(noonText, 'label.addSlot')}
                </span>
                <IconBulb width="25px" height="26px" stroke={COLORS.coolGrey[6]} fill={COLORS.coolGrey[6]} />
                <span>{translationText(noonText, 'scheduler.noteAddSlot')}</span>
              </Row>
              <Row justify="end" align="center" gap="sm">
                <span className="child label">
                  {translationText(noonText, 'label.slotAdded')}
                </span>
                <Badge value={totalSlots.length || '0'} size="sm" />
              </Row>
            </Row>
            {!isEmpty(totalSlots) && (
            <React.Fragment>
              <Row align="center" justify="space-between" className="full-width">
                {totalSlots.map((slot, index) => (
                  <Row nowrap key={index} align="center" justify="space-between" gap className="create-schedule__slots-added">
                    <span className="child">
                      {`${isEqual(slot.days, weekdays) ? everyDay.name : map(slot.days, 'name').join(', ')}, ${slot.startTime}, ${slot.duration.name}`}
                    </span>
                    <Row nowrap gap="sm">
                      <IconEdit
                        width="16px"
                        height="16px"
                        fill={COLORS.brand.base}
                        stroke={COLORS.brand.base}
                        onClick={() => handleOnClickEditSlot(slot, index)}
                        className="child"
                  />
                      <IconWrong
                        width="16px"
                        height="16px"
                        fill={COLORS.coolGrey[3]}
                        stroke="#ffffff"
                        onClick={() => handleOnClickRemoveSlot(index)}
                        className="child"
                  />
                    </Row>
                  </Row>
                ))}
              </Row>
              {showNewSlotButton
              && (
              <Button
                link
                onClick={() => {
                  setShowNewSlotButton(false);
                }}
                className="no-padding"
            >
                <IconAdd
                  className="mlr-05 child"
                  style={{ fill: COLORS.brand.base }}
                  stroke="#fff"
                  height="36px"
                  width="36px"
              />
                <span className="child">{translationText(noonText, 'label.newSlot')}</span>
              </Button>
              )}
            </React.Fragment>
            )}
          </Column>
          {!showNewSlotButton
          && (
          <Column gap="sm" align="start">
            <Row nowrap align="center" justify="start" gap className="full-width add-slot-fields">
              <MultiSelect
                label={isSlotFormValid && translationText(noonText, 'label.day')}
                title={translationText(noonText, 'label.day')}
                list={[everyDay, ...weekdays]}
                selectedItem={selectedDays}
                onSelect={handleOnSelectDays}
                className="flex-1"
            />
              <Column align="start" justify="center" className="field-outer no-margin">
                {isSlotFormValid && <span className="field-label">{translationText(noonText, 'sessionReport.time')}</span>}
                <TimePicker
                  onToggle={handleOnChangeTime}
                  ms={currentDateTime.set({ hour: selectedSlotHour, minute: selectedSlotMinutes }).toMillis()}
                />
              </Column>
              <Select
                label={isSlotFormValid && translationText(noonText, 'label.duration')}
                title={translationText(noonText, 'label.duration')}
                selectedItem={selectedSlotDuration}
                list={SLOT_DURATION}
                onSelect={(item) => {
                  setSelectedSlotDuration(item);
                  setIsSlotFormValid(!isEmpty(selectedDays));
                }}
                className="child"
            />
              <Button
                link
                onClick={handleOnClickAddSlot}
                className="btn-add-slot mt-1"
                disabled={!isSlotFormValid}
              >
                <IconAdd
                  className="mlr-05 child"
                  style={{ fill: COLORS.brand.base }}
                  stroke="#fff"
                  height="24px"
                  width="24px"
                />
                <span className="child text-capitalize">{editingSlot >= 0 ? translationText(noonText, 'label.update') : translationText(noonText, 'label.add')}</span>
              </Button>
            </Row>
            {formFieldError.slots && <span className="errorMessage">{formFieldError.slots}</span>}
          </Column>
          )}
          {previousSchedules.list.length > 0
          && (
          <Column gap="sm" className="create-schedule__other-schedules">
            <Popover {...prevSchedulePopoverProps}>
              <Button link onClick={() => setShowPrevGroupSchedule(true)}>
                <IconEye width="20px" fill={showPrevGroupSchedule ? COLORS.brand.base : COLORS.coolGrey[1]} />
                {translationText(noonText, 'label.viewGroupsSchedule')}
              </Button>
            </Popover>
          </Column>
          )}
          <Row justify="center" align="center" className="submit-button">
            <Button
              onClick={handleOnClickCreateSchedule}
              loading={createScheduleInProgress}
              disabled={createScheduleInProgress}
              size="lg"
              type="primary"
              value={isEmpty(schedules) ? translationText(noonText, 'button.confirmSchedule') : `${translationText(noonText, 'button.updateSchedule')}`}
              className="text-capitalize" />
          </Row>
          {remainNoOfSlots > 0
          && (
          <Row justify="start" align="center" className="create-schedule__footer-note">
            <IconCalendarPlus fill={COLORS.brand.base} />
            <span>{translationText(noonText, 'scheduler.noteRemainingSlot').replace('$slots', remainNoOfSlots)}</span>
          </Row>
          )}
        </Column>
      </div>
    </ContentOnTop>
  );
}

CreateSchedule.propTypes = {
  schedules: PropTypes.arrayOf(PropTypes.shape({})),
  onClose: PropTypes.func,
  source: PropTypes.string,
};
CreateSchedule.defaultProps = {
  schedules: [],
  onClose: () => {},
  source: 'auto',
};
export default CreateSchedule;

import { isEmpty } from 'lodash-es';
import { put, takeEvery, all, call } from 'redux-saga/effects';
import { GET_FEATURE_FLAG } from '../constants';
import { experimentApi } from '../restApi';

const getFeatureFlagApi = ({ featureFlagName, entityType, entityId }) => experimentApi.get(`/featureFlags/${featureFlagName}/evaluate`, { entityType, entityId });

function* getFeatureFlag({ payload }) {
  try {
    const { featureFlagName } = payload;

    const response = yield call(getFeatureFlagApi, payload);
    if (!isEmpty(response.data)) {
      yield put({
        type: GET_FEATURE_FLAG.SUCCESS,
        payload: {
          [featureFlagName]: response.data,
        },
      });
    } else {
      yield put({
        type: GET_FEATURE_FLAG.FAILURE, payload: 'get feature flag failed' });
    }
  } catch (err) {
    yield put({ type: GET_FEATURE_FLAG.FAILURE, payload: err });
  }
}

export function* experimentSaga() {
  yield all([
    takeEvery(GET_FEATURE_FLAG.REQUEST, getFeatureFlag),
  ]);
}

import { IconEar, IconPuzzle, IconSmile, IconTime } from '@noon/quark';
import React from 'react';

const options = [
  {
    text: 'Make learning a fun experience for your students.',
    icon: IconSmile,
  },
  {
    text: 'Desire to making a long lasting impact on the society.',
    icon: IconPuzzle,
  },
  {
    text: 'Patience & perseverance to keep students engaged.',
    icon: IconEar,
  },
  {
    text: 'Can spare a min. of 30 hours  of your time every month.',
    icon: IconTime,
  },
];

const IndiaLoginTeacherDescription = () => (
  <div className="teacher-description flex-column align-center">
    <h2>What we look for in a teacher?</h2>
    <div className="teacher-description--options">
      {options.map((option) => (
        <div className="teacher-description--option" key={option.text}>
          <option.icon fill="#1C8DFF" width="100px" height="100px" />
          <span>{option.text}</span>
        </div>
      ))}
    </div>
  </div>
);

export default IndiaLoginTeacherDescription

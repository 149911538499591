import { NETWORK_PROVIDER } from '../../providers/Event/provider';
import EM from '../../providers/Event/EM.js';

export interface IChannelPropDepedency {
  sessionId: string,
  userId: string,
  canvasId: string,
}

const CLASSROOM_CHANNEL = {
  // Pubnub  channels
  chatChannel: ({ sessionId }) => `chat_event.${sessionId}`,
  classroomUnicastChannel: ({ sessionId, userId }) => `session_unicast.${sessionId}_${userId}`,
  classroomBroadcastChannel: ({ sessionId }) => `classroom.${sessionId}`,
  userUnicastChannel: ({ userId }) => `classroom_unicast.${userId}`,
  canvasChannel: ({ sessionId }) => `canvas_events.${sessionId}`,
  canvasSketchChannel: ({ sessionId, canvasId }) => `sketch_events.${sessionId}_${canvasId}`,
  interactiveQuestionChannel: ({ sessionId }) => `ask_students.${sessionId}`,
  slideUnicastChannel: ({ sessionId }) => `unicast_teacher_event.${sessionId}`, // not subscribed
  breakoutQuestionChannel: ({ sessionId }) => `ask_students_in_breakout.${sessionId}`,
  raiseHandChannel: ({ sessionId }) => `raise_hand.${sessionId}`,
  raiseHandAudioChannel: ({ sessionId }) => `student_audio_event.${sessionId}`,
  raiseHandSendChannel: ({ sessionId }) => `teacher_audio_event.${sessionId}`,
  classroomAudioChannel: ({ sessionId }) => `classroom_audio.${sessionId}`, // not subscribed
  clientDataChannel: ({ sessionId }) => `client_data.${sessionId}`,
  scrollChannel: ({ sessionId }) => `scroll_data.${sessionId}`,
  // Agora channels
  agoraMainChannel: ({ sessionId }) => `${sessionId}`,
};

const CLASSROOM_EVENT = {
  // pubnub
  chat: {
    event: 'chat',
    channel: CLASSROOM_CHANNEL.chatChannel,
    service: NETWORK_PROVIDER.PUBNUB,
  },
  breakoutQuestion: {
    event: 'ask_students_in_breakout',
    channel: CLASSROOM_CHANNEL.breakoutQuestionChannel,
    service: NETWORK_PROVIDER.PUBNUB,
  },
  interactiveQuestion: {
    event: 'ask_students',
    channel: CLASSROOM_CHANNEL.interactiveQuestionChannel,
    service: NETWORK_PROVIDER.PUBNUB,
  },
  addSlideUnicast: {
    event: 'addCube',
    channel: CLASSROOM_CHANNEL.slideUnicastChannel,
    service: NETWORK_PROVIDER.PUBNUB,
  },
  addSlide: {
    event: 'addCube',
    channel: CLASSROOM_CHANNEL.canvasChannel,
    service: NETWORK_PROVIDER.PUBNUB,
  },
  updateSlideSnapshot: {
    event: 'snapshot_update',
    channel: CLASSROOM_CHANNEL.slideUnicastChannel,
    service: NETWORK_PROVIDER.PUBNUB,
  },
  updateLabel: {
    event: 'label_update',
    channel: CLASSROOM_CHANNEL.slideUnicastChannel,
    service: NETWORK_PROVIDER.PUBNUB,
  },
  startTeamQna: {
    event: 'start_teamqna_breakout',
    channel: CLASSROOM_CHANNEL.canvasChannel,
    service: NETWORK_PROVIDER.PUBNUB,
  },
  deleteSlide: {
    event: 'deleteCube',
    channel: CLASSROOM_CHANNEL.canvasChannel,
    service: NETWORK_PROVIDER.PUBNUB,
  },
  sketchForHistory: {
    event: 'canvasSketch',
    channel: CLASSROOM_CHANNEL.canvasSketchChannel,
    service: NETWORK_PROVIDER.PUBNUB,
  },
  blockChat: {
    event: 'blockChat',
    channel: CLASSROOM_CHANNEL.clientDataChannel,
    service: NETWORK_PROVIDER.PUBNUB,
  },
  disableStudentChat: {
    event: 'disableStudentChat',
    channel: CLASSROOM_CHANNEL.clientDataChannel,
    service: NETWORK_PROVIDER.PUBNUB,
  },
  showResult: {
    event: 'showResult',
    channel: CLASSROOM_CHANNEL.clientDataChannel,
    service: NETWORK_PROVIDER.PUBNUB,
  },
  reportAbuse: {
    event: 'report_abuse',
    channel: CLASSROOM_CHANNEL.clientDataChannel,
    service: NETWORK_PROVIDER.PUBNUB,
  },
  exitClass: {
    event: 'exitClass',
    channel: CLASSROOM_CHANNEL.clientDataChannel,
    service: NETWORK_PROVIDER.PUBNUB,
  },
  sendRaiseHand: {
    event: 'teacher_audio_event',
    channel: CLASSROOM_CHANNEL.raiseHandSendChannel,
    service: NETWORK_PROVIDER.PUBNUB,
  },
  audioToggle: {
    event: 'audio_toggle',
    channel: CLASSROOM_CHANNEL.classroomAudioChannel,
    service: NETWORK_PROVIDER.PUBNUB,
  },
  scrollData: {
    event: 'scroll_data',
    channel: CLASSROOM_CHANNEL.scrollChannel,
    service: NETWORK_PROVIDER.PUBNUB,
  },
  // Agora
  sketch: {
    event: 'canvasSketch',
    channel: CLASSROOM_CHANNEL.agoraMainChannel,
    service: NETWORK_PROVIDER.AGORA,
  },
  slideEventLog: {
    event: 'b_canvas_events',
    channel: CLASSROOM_CHANNEL.agoraMainChannel,
    service: NETWORK_PROVIDER.AGORA,
  },
  breakoutQuestionLog: {
    event: 'breakout_question_events',
    channel: CLASSROOM_CHANNEL.agoraMainChannel,
    service: NETWORK_PROVIDER.AGORA,
  },
  interactiveQuestionLog: {
    event: 'question_events',
    channel: CLASSROOM_CHANNEL.agoraMainChannel,
    service: NETWORK_PROVIDER.AGORA,
  },
  raiseHandCancelFallback: {
    event: 'teacher_audio_event',
    channel: CLASSROOM_CHANNEL.agoraMainChannel,
    service: NETWORK_PROVIDER.AGORA,
  },
  videoMuteFallback: {
    event: 'teacher_video_mute',
    channel: CLASSROOM_CHANNEL.agoraMainChannel,
    service: NETWORK_PROVIDER.AGORA,
  },
  videoUnmuteFallback: {
    event: 'teacher_video_unmute',
    channel: CLASSROOM_CHANNEL.agoraMainChannel,
    service: NETWORK_PROVIDER.AGORA,
  },
  promotion: {
    event: 'promotion',
    channel: CLASSROOM_CHANNEL.classroomBroadcastChannel,
    service: NETWORK_PROVIDER.PUBNUB,
  },
};

EM.CLASSROOM_CHANNEL = CLASSROOM_CHANNEL;
EM.CLASSROOM_EVENT = CLASSROOM_EVENT;

export {
  CLASSROOM_EVENT,
  CLASSROOM_CHANNEL,
};

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { InfiniteScroll } from '@noon/atom';
import {
  teamsSelector,
  teamsOffsetSelector,
  teamsIsLoadingSelector,
  totalTeamCountsetSelector,
} from '../../../redux/selectors/teams';
import { GET_BREAKOUT_TEAMS } from '../../../redux/constants';
import TeamLeaderBoardItem from '../../Class/Breakout/TeamLeaderBoardItem';
import EmptyState from '../emptyState';
import t from '../../../helpers/translate';

const TeamLeaderBoard = (prop) => {
  const { teams, sessionId, getBreakoutTeams, offset, loading } = prop;

  useEffect(() => {
    getBreakoutTeams(sessionId, true);
  }, []);

  if (!teams.length && !loading) {
    return (
      <EmptyState
        title={t('classroom', 'empty_teams_title')}
        subtitle={t('classroom', 'empty_teams_subtitle')}
      />
    );
  }

  return (
    <InfiniteScroll
      onLoad={() => getBreakoutTeams(sessionId, false)}
      loadMore={!loading && offset !== -1}
      loading={loading}
      className="team-leader-board"
        >
      {teams.map((item) => (
        <TeamLeaderBoardItem team={item} />
      ))}
    </InfiniteScroll>
  );
};

const mapStateToProps = (state) => ({
  teams: teamsSelector(state),
  offset: teamsOffsetSelector(state),
  loading: teamsIsLoadingSelector(state),
  totalTeams: totalTeamCountsetSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getBreakoutTeams: (sessionId, resetOffset) => dispatch({ type: GET_BREAKOUT_TEAMS.REQUEST, sessionId, resetOffset }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeamLeaderBoard);

import React, { memo, useEffect } from 'react';
import { Row } from '@noon/atom';
import { useDispatch, useSelector } from 'react-redux';
import { COMPETITION_EMOJI } from '../../redux/constants';

const QuickEmoji = (prop) => {
  const { sendEmoji } = prop;

  const dispatch = useDispatch();
  const competitionEmojis = useSelector((state) => state.toJS().file.competitionEmojis);

  useEffect(() => {
    if (!competitionEmojis.length) {
      dispatch({ type: COMPETITION_EMOJI.REQUEST });
    }
  }, []);

  return (
    <Row nowrap align="center" justify="center" className="quick-emoji-card">
      {competitionEmojis
        .filter((x) => x.indexOf('evil') === -1 && x.indexOf('down-vote') === -1)
        .map((emoji, index) => (
          <div
            key={index}
            className="quick-emoji-item"
            onClick={() => sendEmoji(emoji)}
                >
            <img src={emoji} alt="emoji" />
          </div>
        ))}
    </Row>
  );
};

export default memo(QuickEmoji);

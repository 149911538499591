import React, { memo } from 'react';
import { IconCanvasConfirm, IconExclamationCircle, IconLoader } from '@noon/quark';
import classNames from 'classnames';
import { Avatar, Column, Time, Button, Row } from '@noon/atom';
import t from '../../helpers/translate';
import { isRTL } from '../../constants';

const ChatItem = (prop) => {
  const { chat, user, addStudentImageToCanvas, blockChat, onImageLoad } = prop;
  const you = String(user.id) === String(chat.user_id);
  return (
    <Row className="chat-item" gap="sm" nowrap dir={isRTL() ? 'rtl' : 'ltr'}>
      <Column nowrap className="chat-avatar">
        <Avatar
          url={chat.pic}
          className="pointer"
          rounded
          noShadow
        />
        {chat.is_plus_user && <Button rounded size="sm" className="chat-plus-tag">{t('groups', 'plus')}</Button>}
        {!you && chat.msg !== t('classroom', 'chatMsgBlocked') && (
          <Button
            title="Block student"
            className="block-user"
            type="red"
            size="sm"
            onClick={() => blockChat(chat)}
          >
            {t('classroom', 'ButtonBlockUser')}
          </Button>
        )}
      </Column>
      <Column nowrap>
        <Row className="chat-title" align="center" gap="sm">
          <div className="chat-title__name">{you ? t('label', 'you') : chat.name}</div>
          <span className="child"><Time value={chat.time} unit="epoch" smallSuffix /></span>
          {you && (
            chat.isDelivered ? chat.isDelivered === 'blocked' ? (
              <IconExclamationCircle
                width="16px"
                height="16px"
                className="child"
                color="red"
              />
            ) : (
              <IconCanvasConfirm
                width="16px"
                height="16px"
                className="child"
                color="#03A9F4"
              />
            ) : (
              <IconLoader
                width="16px"
                height="16px"
                className="child"
                color="gray"
              />
            )
          )}
        </Row>
        {chat.image_thumbnail_uri ? (
          <img
            className={classNames('chat-image', { emoji: chat.image_type === 'emojiImage' })}
            src={chat.image_thumbnail_uri}
            onLoad={onImageLoad}
            alt={chat.msg}
          />
        ) : (
          <div className={classNames('chat-message', { you })}>
            {chat.msg}
            {chat.for_user && chat.for_user.image_thumbnail_uri && (
              <React.Fragment>
                <img
                  className="chat-image"
                  onLoad={onImageLoad}
                  src={chat.for_user.image_thumbnail_uri}
                  alt="chat thumbnail"
                />
                <Button
                  type="link"
                  value="Add to canvas"
                  onClick={() => addStudentImageToCanvas(chat.for_user)}
                />
              </React.Fragment>
            )}
          </div>
        )}
      </Column>
    </Row>
  );
};

export default memo(ChatItem);

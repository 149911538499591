/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Row, Column, Badge } from '@noon/atom';
import { useSelector, useDispatch } from 'react-redux';
import {
  get,
  findIndex,
  filter,
  reject,
  forIn,
  flatten,
  map,
  isEmpty,
  differenceBy,
  toLower,
  forEach,
} from 'lodash-es';
import { useHistory, useParams } from 'react-router-dom';
import { IconEditO, IconLoader, IconPlus } from '@noon/quark';
import { Container, Draggable } from 'react-smooth-dnd';
import {
  TOGGLE_NOTIFICATION_BAR,
  SET_CURRICULUM_TEMPLATE,
  REORDER_CHAPTER,
  UPDATE_CHAPTER,
  ADD_CHAPTER,
  DELETE_CHAPTER,
  EMPTY_CHAPTER,
  GET_NOON_SUGGESTED_CURRICULUM,
  PUBLISH_CURRICULUM,
  GET_GROUP_CURRICULUM,
  DRAFT_CURRICULUM,
  GET_CURRICULUM_CHAPTER_TOPICS,
  GET_PREVIOUS_GROUP_CURRICULUM,
  GET_CURRICULUM_SIMILAR_GROUPS,
  GET_TEACHER_GROUP_SELECTED,
  GET_CURRICULUM_SUGGESTED_CHAPTER,
} from '../../redux/constants';
import ChapterCard from './chapterCard';
import SelectCurriculumModal from './selectCurriculumModal';
import { ConfirmationModal } from '../Modals';
import { translationText } from '../../helpers';

import { CURRICULUM_TEMPLATE } from '../../constants';
import PageHeader from '../Layout/pageHeader';
import Mixpanel from '../Mixpanel';
import Shimmer from '../Layout/shimmer';
import { addToast, TOAST_TYPE } from '../Toast';

function CreateCurriculum({ closeModal }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const logger = useRef(LoggingManager.mount({ moduleName: 'group' })).current;
  const selectedCurriculumTemplate = useSelector((state) => state.toJS().curriculum.selectedCurriculumTemplate);
  const {
    chapters,
    curriculumExist,
    hasDraft,
    suggestedTopicsList,
    similarGroups,
    suggestedChaptersList,
    loading,
    isCurriculumUpdated,
    curriculumPublishInProgress,
    curriculumDraftInProgress,
  } = useSelector((state) => state.toJS().curriculum);
  const noonText = useSelector((state) => state.toJS().translation.noonText);
  const { selectedGroup, curriculumList } = useSelector((state) => state.toJS().groupsV2);
  const { is_unified_group_teacher } = useSelector((state) => state.toJS().experiment.featureFlags);
  const user = useSelector((state) => state.toJS().userv2.getTeacher.response);
  const { id: groupId } = useParams();
  const groupName = get(selectedGroup, 'group_info.title') || '';
  const [isSelectCurriculumModalOpen, setIsSelectCurriculumModalState] = useState(false);
  const [showChapterTags, setShowChapterTags] = useState(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [publishErrorText, setPublishErrorText] = useState('');
  const [showPublishConfirmation, setShowPublishConfirmation] = useState(false);
  const [showPublishErrorConfirmation, setShowPublishErrorConfirmation] = useState(false);
  const [publishedOrCloseButtonClicked, setPublishOrCloseButtonClicked] = useState(false);
  const isAllTopicNotMapped = useMemo(() => false);

  const filteredSuggestedChaptersList = useMemo(() => {
    const chaptersList = reject(chapters || [], (chapter) => chapter.is_deleted);
    return differenceBy(suggestedChaptersList.list || [], chaptersList, 'name');
  }, [suggestedChaptersList]);

  const tagsList = useMemo(
    () => ({
      key: get(selectedGroup, 'curriculum_tags.curriculum') ? 'curriculum' : 'section',
      list: get(selectedGroup, 'curriculum_tags.curriculum') || get(selectedGroup, 'curriculum_tags.sections') || [],
    }),
    [selectedGroup],
  );

  const moveChapter = ({ removedIndex, addedIndex }) => {
    const chapter = chapters[addedIndex];
    if (chapter.state === 'ongoing' || chapter.state === 'completed') {
      addToast(translationText(noonText, 'warning.cannotMoveChapter'), TOAST_TYPE.WARNING);
    } else {
      dispatch({
        type: REORDER_CHAPTER,
        payload: {
          chapter: chapters[removedIndex],
          removedIndex,
          addedIndex,
        },
      });
    }
  };

  const handleAddNewChapter = (key, tag) => {
    dispatch({
      type: ADD_CHAPTER,
      payload: {
        type: 'chapter',
        [`${key}_id`]: tag.id,
        [key]: tag,
      },
    });
  };

  const handleDeleteNewChapterOrRevision = (removedIndex) => {
    const chapter = chapters[removedIndex];
    dispatch({
      type: DELETE_CHAPTER,
      payload: {
        removedIndex,
        chapter,
      },
    });
  };

  const handleUpdateChapter = (values, index) => {
    const chapter = chapters[index];
    dispatch({
      type: UPDATE_CHAPTER,
      payload: {
        index,
        chapter: { ...chapter, ...values },
      },
    });
  };

  const handleAddNewRevision = () => {
    dispatch({
      type: ADD_CHAPTER,
      payload: { type: 'revision' },
    });
  };

  const handleSelectCurriculumModal = () => {
    if (!isSelectCurriculumModalOpen) {
      dispatch({
        type: GET_CURRICULUM_SIMILAR_GROUPS.REQUEST,
        payload: groupId,
      });
    }
    setIsSelectCurriculumModalState(!isSelectCurriculumModalOpen);
  };

  const handleCurriculumModalClose = () => {
    closeModal();
    history.push(`/${localStorage.updatedLocale}/groups/${groupId}`);
  };

  const draftCurriculum = () => {
    setPublishOrCloseButtonClicked(true);
    dispatch({
      type: DRAFT_CURRICULUM.REQUEST,
      payload: {
        groupId,
        data: { content: chapters },
      },
    });
  };

  const handleOnClickClose = () => {
    if (curriculumExist && !hasDraft) {
      if (isCurriculumUpdated) {
        setShowCloseConfirmation(true);
      } else {
        handleCurriculumModalClose();
      }
    } else if (isCurriculumUpdated) {
      draftCurriculum();
    } else {
      handleCurriculumModalClose();
    }
  };

  const handleOnSelectCurriculum = (value) => {
    dispatch({ type: SET_CURRICULUM_TEMPLATE, payload: value });
    if (value.id === CURRICULUM_TEMPLATE.noonSuggested.id) {
      dispatch({
        type: GET_NOON_SUGGESTED_CURRICULUM.REQUEST,
        payload: groupId,
      });
    } else if (value.id === CURRICULUM_TEMPLATE.previousGroup.id) {
      dispatch({
        type: GET_PREVIOUS_GROUP_CURRICULUM.REQUEST,
        payload: value.group_id,
      });
    } else {
      dispatch({
        type: EMPTY_CHAPTER,
      });
    }
  };

  const publishCurriculum = () => {
    for (let i in chapters) {
      if (isEmpty(chapters[i].name_header_key)) {
        chapters[i].name_header = `Chapter ${i+1}`
        chapters[i].name_header_key = `chapter${i+1}`
      }
      for (let j in chapters[i].topic_info) {
        if (isEmpty(chapters[i].topic_info[j].name_header_key)) {
          chapters[i].topic_info[j].name_header = `Topic ${j+1}`
          chapters[i].topic_info[j].name_header_key = `topic${j+1}`
        }
      }
    }
    dispatch({
      type: PUBLISH_CURRICULUM.REQUEST,
      payload: {
        groupId,
        data: { content: chapters },
        update: curriculumExist,
      },
    });
    const data = {
      user_id: user.user_id,
      group_id: groupId,
      group_name: get(selectedGroup, ['group_info', 'title']),
      group_country: get(selectedGroup, ['country', 'name']),
      group_subject: get(selectedGroup, ['curriculum_tags', 'subject', 'name']),
      group_grades: get(selectedGroup, ['curriculum_tags', 'grade', 'name']),
      group_curriculum_type: get(selectedGroup, ['curriculum_tags', 'type']),
      group_type: get(selectedGroup, ['group_info', 'type']),
      group_age: '',
      no_of_students: get(selectedGroup, ['membersInfo', 'total_members']),
      curriculum_completion: curriculumList.percentCompleted,
      publish_count: 'NA',
      no_of_topics: flatten(map(chapters, 'topic_info')).length,
      no_of_revision: filter(chapters, { type: 'revision' }).length,
    };
    const type = 'curriculum_published';
    Mixpanel.track(type, data);
    logger.track(type, { group: data });
  };

  const publishCurriculumCheck = () => {
    setPublishOrCloseButtonClicked(true);
    const isPublishError = findIndex(chapters, (chapter) => {
      const topicsList = reject(chapter.topic_info || [], (topic) => topic.is_deleted || topic.name === '');
      // const allTopicNotMapped = filter(topicsList, { isNotMapped: true, topic_id: undefined }).length > 0;
      const chapterEmpty = chapter.type === 'chapter' && topicsList.length === 0;
      // setIsAllTopicNotMapped(allTopicNotMapped);
      return chapterEmpty; // || allTopicNotMapped;
    });
    let hasPlusPublishErrors = false;

    // If techer is part of unied group experiment, then check if they are adding paid topics on a free group
    if (selectedGroup && selectedGroup.group_info && !selectedGroup.group_info.is_premium && is_unified_group_teacher.isEnabled) {
      let allTopicsFree = true;
      if (chapters && !isEmpty(chapters)) {
        chapters.forEach((singleChapter) => {
          if (singleChapter.topic_info && !isEmpty(singleChapter.topic_info)) {
            singleChapter.topic_info.forEach((singleTopic) => {
              if (allTopicsFree) {
                allTopicsFree = singleTopic.is_free;
              }
            });
          }
        });
      }
      if (!allTopicsFree) {
        hasPlusPublishErrors = true;
        if (curriculumExist && !hasDraft) {
          setPublishErrorText(translationText(noonText, 'groupCurriculum.cantAddPlusToFree'));
        } else {
          setPublishErrorText(translationText(noonText, 'groupCurriculum.contactAdminToPublishPlusGroup'));
        }
      }
    }

    if (isPublishError > -1) {
      if (isAllTopicNotMapped) {
        setPublishErrorText('All topics not mapped. Please rectify and try again.');
      } else {
        setPublishErrorText(translationText(noonText, 'groupCurriculum.errorChapterWithoutTopic'));
      }
    }

    if (isPublishError > -1 || hasPlusPublishErrors) {
      setShowPublishErrorConfirmation(true);
    } else {
      setShowPublishConfirmation(true);
    }
  };

  const getTagsQueryParams = (chapter) => {
    const queryParams = {
      country_id: get(selectedGroup, 'country.id'),
      section_id: get(chapter, 'section.id'),
    };
    const tags = get(selectedGroup, 'curriculum_tags');
    forIn(tags, (value, key) => {
      if (Array.isArray(value)) {
        queryParams[`${key}_id`] = chapter[key] && chapter[key].id;
      } else {
        queryParams[`${key}_id`] = value.id;
      }
    });
    return queryParams;
  };

  const handleGetSuggestedTopics = (chapter) => {
    if (chapter.chapter_id) {
      const curriculumType = get(selectedGroup, 'curriculum_tags.type');
      const queryParams = {
        ...getTagsQueryParams(chapter),
        chapter_id: chapter.chapter_id,
      };

      dispatch({
        type: GET_CURRICULUM_CHAPTER_TOPICS.REQUEST,
        payload: { queryParams, curriculumType },
      });
    }
  };

  const handleGetSuggestedChapters = (chapter) => {
    const curriculumType = get(selectedGroup, 'curriculum_tags.type');
    const queryParams = getTagsQueryParams(chapter);

    dispatch({
      type: GET_CURRICULUM_SUGGESTED_CHAPTER.REQUEST,
      payload: { queryParams, curriculumType },
    });
  };

  const handleOnClickAddNewChapter = () => {
    if (tagsList.list.length <= 1) {
      handleAddNewChapter(tagsList.key, tagsList.list[0]);
    } else {
      setShowChapterTags(true);
    }
  };

  const handleCheckDuplicateChapter = (chapterName, chapterIndex) => {
    const duplicateChapterIndex = findIndex(
      chapters,
      (chapter) => toLower(chapter.name) === toLower(chapterName) && !chapter.is_deleted && chapter.type !== 'revision',
    );
    return duplicateChapterIndex > -1 && chapterIndex !== duplicateChapterIndex;
  };

  useEffect(() => {
    dispatch({
      type: GET_TEACHER_GROUP_SELECTED.REQUEST,
      payload: groupId,
    });
    dispatch({
      type: GET_GROUP_CURRICULUM.REQUEST,
      payload: { groupId, getSuggestedCurriculum: true },
    });
  }, []);

  useEffect(() => {
    if (!curriculumPublishInProgress && publishedOrCloseButtonClicked) {
      handleCurriculumModalClose();
    }
  }, [curriculumPublishInProgress]);

  useEffect(() => {
    if (!curriculumDraftInProgress && publishedOrCloseButtonClicked) {
      handleCurriculumModalClose();
    }
  }, [curriculumDraftInProgress]);

  return (
    <div className="create-curriculum">
      {isSelectCurriculumModalOpen && (
        <SelectCurriculumModal
          noonText={noonText}
          selectedCurriculumTemplate={selectedCurriculumTemplate}
          onClose={handleSelectCurriculumModal}
          handleOnSelectCurriculum={handleOnSelectCurriculum}
          similarGroups={similarGroups}
        />
      )}
      <PageHeader hideToggle title={translationText(noonText, 'tab.editCurriculum')}>
        <Row nowrap align="center">
          <Button
            size="lg"
            type="primary"
            value={translationText(noonText, 'poll.publish')}
            onClick={publishCurriculumCheck}
            className="plr-5 curriculum-page__publish-button"
            loading={curriculumPublishInProgress}
            disabled={curriculumPublishInProgress || isEmpty(chapters)}
          />
          <Button
            size="lg"
            type="secondary"
            value={curriculumExist && !hasDraft ? translationText(noonText, 'groupCurriculum.exit') : translationText(noonText, 'groupCurriculum.saveAndExit')}
            onClick={handleOnClickClose}
            className="plr-5 curriculum-page__publish-button"
            loading={curriculumDraftInProgress}
          />
          {/* {curriculumDraftInProgress ? (
            <IconLoader onClick={handleOnClickClose} className="close-sidebar-btn pointer" width="2.4rem" rotate="45" />
          ) : (
            <Button
              size="lg"
              type="secondary"
              value="Save and Exit"
              onClick={handleOnClickClose}
              className="plr-5 curriculum-page__publish-button"
              loading={curriculumDraftInProgress}
            />
          )} */}
        </Row>
      </PageHeader>

      <Column className="page-content curriculum-page" gap="lg">
        <Card className="mtb-3 ptb-5 plr-4">
          <Row className="mtb-2 curriculum-page__header" justify="space-between">
            <Column className="header-title">
              <Row align="center">
                <h3 className="no-margin heading">{translationText(noonText, 'tab.curriculum')}</h3>
                {hasDraft && (
                  <Badge size="sm" className="draft-badge">
                    {translationText(noonText, 'tab.draft')}
                  </Badge>
                )}
              </Row>
              <h4 className="no-margin group-name heading">"{groupName}"</h4>
            </Column>
            {(!curriculumExist || hasDraft) && (
              <Column className="header-select mb-1">
                <Row align="center">
                  <h4 className="no-margin">{translationText(noonText, 'label.curriculumSelected')}</h4>
                  <IconEditO
                    onClick={handleSelectCurriculumModal}
                    width="12px"
                    height="12px"
                    className="edit-profile mlr-1"
                  />
                </Row>
                <h4 className="no-margin group-name">
                  {selectedCurriculumTemplate.previousGroupTemplate || selectedCurriculumTemplate.name}
                </h4>
              </Column>
            )}
          </Row>
          {(typeof loading === 'undefined' || loading) && (
            <Shimmer
              obj={[
                { heading: true },
                { box: true, lines: 3 },
                { box: true, lines: 3 },
                { box: true, lines: 3 },
                { box: true, lines: 3 },
              ]}
            />
          )}
          <Container orientation="vertical" groupName={groupId} lockAxis="y" onDrop={moveChapter}>
            {chapters.map((chapter, index) => {
              // To avoid duplication, need to improve this code
              const chapterCardProps = {
                key: chapter.id,
                handleDeleteNewChapterOrRevision,
                handleUpdateChapter,
                index,
                chapter,
                moveCard: moveChapter,
                suggestedChaptersList: filteredSuggestedChaptersList,
                handleGetSuggestedChapters,
                handleGetSuggestedTopics,
                suggestedTopics: suggestedTopicsList[chapter.chapter_id],
                publishedOrCloseButtonClicked,
                handleCheckDuplicateChapter,
              };
              if (!chapter.is_deleted) {
                if (chapter.state === 'ongoing' || chapter.state === 'completed') {
                  return <ChapterCard {...chapterCardProps} />;
                }
                return (
                  <Draggable key={chapter.id}>
                    <ChapterCard {...chapterCardProps} />
                  </Draggable>
                );
              }
              return <div />;
            })}
          </Container>
          <Row gap align="center mb-2" nowrap style={{ position: 'relative' }} className="chapter-revision-btn">
            <Button type="secondary" size="lg" className="child" onClick={() => handleOnClickAddNewChapter()}>
              <IconPlus />
              {translationText(noonText, 'button.addChapter')}
            </Button>
            {showChapterTags && (
              <Card className="select-chapter-tag">
                <Column align="start" justify="center">
                  {tagsList.list.map((tag) => (
                    <span
                      key={tag.id}
                      onClick={() => {
                        handleAddNewChapter(tagsList.key, tag);
                        setShowChapterTags(false);
                      }}
                      className="plr-2 tag-label"
                    >
                      {tag.name}
                    </span>
                  ))}
                </Column>
              </Card>
            )}
            <Button type="secondary" size="lg" onClick={handleAddNewRevision}>
              <IconPlus />
              {translationText(noonText, 'button.addRevision')}
            </Button>
          </Row>
        </Card>
      </Column>
      {showCloseConfirmation && (
        <ConfirmationModal
          modalType="info"
          successBtn={translationText(noonText, 'groupCurriculum.changeYes')}
          closeBtn={translationText(noonText, 'button.dontClose')}
          onClose={() => {
            setShowCloseConfirmation(false);
          }}
          onSuccess={() => {
            handleCurriculumModalClose();
            setShowCloseConfirmation(false);
          }}
          text={translationText(noonText, 'groupCurriculum.leavePage')}
          subText={translationText(noonText, 'groupCurriculum.leavePageText')}
        />
      )}
      {showPublishErrorConfirmation && (
        <ConfirmationModal
          modalType="error"
          successBtn={translationText(noonText, 'groupCurriculum.iUnderstand')}
          onClose={() => {
            setShowPublishErrorConfirmation(false);
          }}
          onSuccess={() => {
            setShowPublishErrorConfirmation(false);
            handleOnClickClose();
          }}
          text={translationText(noonText, 'groupCurriculum.curriculumCantBePublished')}
          subText={publishErrorText}
        />
      )}
      {showPublishConfirmation && (
        <ConfirmationModal
          modalType="info"
          successBtn={translationText(noonText, 'form.publishNow')}
          closeBtn={translationText(noonText, 'form.later')}
          onClose={() => {
            setShowPublishConfirmation(false);
          }}
          onSuccess={() => {
            publishCurriculum();
            setShowPublishConfirmation(false);
          }}
          text={translationText(
            noonText,
            `groupCurriculum.${!curriculumExist || hasDraft ? 'publishCurriculum' : 'rePublishCurriculum'}`,
          )}
          subText={translationText(
            noonText,
            `groupCurriculum.${!curriculumExist || hasDraft ? 'publishCurriculumText' : 'rePublishCurriculumText'}`,
          )}
        />
      )}
    </div>
  );
}

CreateCurriculum.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default CreateCurriculum;

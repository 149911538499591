import { keyBy, mapValues } from 'lodash-es';
import { put, takeEvery, call, select, all } from 'redux-saga/effects';
import {
  GENERAL_PLANNER_GET_ACTIVITIES_COUNTS,
  GENERAL_PLANNER_GET_ACTIVITIES_DAYS,
  GENERAL_PLANNER_GET_ACTIVITIES_GRID,
  GENERAL_PLANNER_GET_ACTIVITIES_LIST } from '../constants';
import { schedulerPlannerApi } from '../restApi';

const getActivitiesListAPI = (params) => schedulerPlannerApi.get('activities/list', params);
const getActivitiesGridAPI = (params) => schedulerPlannerApi.get('activities/grid', params);
const getActivitiesCountsAPI = (params) => schedulerPlannerApi.get('activities/count', params);
const getActivitiesDaysAPI = (params) => schedulerPlannerApi.get('activities/days', params);

function* getActivitiesList({ payload: { filters, reload, update } }) {
  try {
    const response = yield call(getActivitiesListAPI, filters);
    if (response.ok) {
      yield put({
        type: GENERAL_PLANNER_GET_ACTIVITIES_LIST.SUCCESS,
        payload: { data: response.data, reload, update },
      });
    } else {
      yield put({
        type: GENERAL_PLANNER_GET_ACTIVITIES_LIST.FAILURE,
        payload: 'NETWORK_ERROR',
      });
    }
  } catch (error) {
    yield put({
      type: GENERAL_PLANNER_GET_ACTIVITIES_LIST.FAILURE,
      payload: error,
    });
  }
}

function* getActivitiesGrid({ payload: { filters, reload } }) {
  try {
    const response = yield call(getActivitiesGridAPI, filters);
    if (response.ok) {
      yield put({
        type: GENERAL_PLANNER_GET_ACTIVITIES_GRID.SUCCESS,
        payload: { data: response.data, reload },
      });
    } else {
      yield put({
        type: GENERAL_PLANNER_GET_ACTIVITIES_GRID.FAILURE,
        payload: 'NETWORK_ERROR',
      });
    }
  } catch (error) {
    yield put({
      type: GENERAL_PLANNER_GET_ACTIVITIES_GRID.FAILURE,
      payload: error,
    });
  }
}

function* getActivitiesCounts({ payload: { filters, reload } }) {
  try {
    const response = yield call(getActivitiesCountsAPI, filters);
    if (response.ok) {
      yield put({
        type: GENERAL_PLANNER_GET_ACTIVITIES_COUNTS.SUCCESS,
        payload: { data: mapValues(keyBy(response.data.data, 'name'), 'count'), reload },
      });
    } else {
      yield put({
        type: GENERAL_PLANNER_GET_ACTIVITIES_COUNTS.FAILURE,
        payload: 'NETWORK_ERROR',
      });
    }

  } catch (error) {
    yield put({
      type: GENERAL_PLANNER_GET_ACTIVITIES_COUNTS.FAILURE,
      payload: error,
    });
  }
}

function* getActivitiesDays({ payload }) {
  try {
    const response = yield call(getActivitiesDaysAPI, payload);
    if (response.ok) {
      yield put({
        type: GENERAL_PLANNER_GET_ACTIVITIES_DAYS.SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: GENERAL_PLANNER_GET_ACTIVITIES_DAYS.FAILURE,
        payload: 'NETWORK_ERROR',
      });
    }

  } catch (error) {
    yield put({
      type: GENERAL_PLANNER_GET_ACTIVITIES_DAYS.FAILURE,
      payload: error,
    });
  }
}
export function* generalPlannerSagas() {
  yield all([
    takeEvery(GENERAL_PLANNER_GET_ACTIVITIES_COUNTS.REQUEST, getActivitiesCounts),
    takeEvery(GENERAL_PLANNER_GET_ACTIVITIES_LIST.REQUEST, getActivitiesList),
    takeEvery(GENERAL_PLANNER_GET_ACTIVITIES_GRID.REQUEST, getActivitiesGrid),
    takeEvery(GENERAL_PLANNER_GET_ACTIVITIES_DAYS.REQUEST, getActivitiesDays),
  ]);
}

import { put, call, takeEvery, all } from 'redux-saga/effects';
import isEmpty from 'lodash-es/isEmpty';
import { flashcardApiV2 } from '../restApi';
import { GET_FLASHCARD } from '../constants';

const getFlashcardAPI = id => flashcardApiV2.get(`flashcardImagesByLeafId/${id}`);

function* getFlashcards({ payload: id }) {
  try {
    const response = yield call(getFlashcardAPI, id);
    if (response.data.success) {
      yield put({
        type: GET_FLASHCARD.SUCCESS,
        payload: isEmpty(response.data.data) ? [] : response.data.data,
      });
    } else {
      yield put({ type: GET_FLASHCARD.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: GET_FLASHCARD.FAILURE, payload: err });
  }
}

export function* flashcardSaga() {
  yield all([takeEvery(GET_FLASHCARD.REQUEST, getFlashcards)]);
}

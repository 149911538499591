/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Search, Badge, Row, Checkbox } from '@noon/atom';
import { connect } from 'react-redux';
import { IconLoader, IconPdf } from '@noon/quark';
import { noonTextSelector } from 'redux/selectors/translations';
import Mixpanel from '../../Mixpanel';
import t from '../../../helpers/translate';
import { dataURLtoFile } from '../../../helpers';
import { SET_PDF_DATA } from '../../../redux/constants';

class PdfUpload extends React.Component {
  constructor(props) {
    super(props);
    const { pdfData } = props;
    this.state = {
      images: pdfData.images,
      onceUploaded: Boolean(pdfData.images.length),
      selectedPdfs: [],
    };
  }

  componentDidMount() {
    const { sessionData = {} } = this.props;
    const { teacher_id, id, state, group = {}, product } = sessionData;
    try {
      Mixpanel.track('teacher_opened_pdf_tab', {
        teacher_id,
        session_id: id,
        session_state: state,
        group_id: group.id,
        product,
      });
    } catch (e) {
      console.log(e);
    }
    this.pdfjsLib = window['pdfjs-dist/build/pdf'];
    //
    // Disable workers to avoid yet another cross-origin issue (workers need the URL of
    // the script to be loaded, and dynamically loading a cross-origin script does
    // not work)
    //
    // this.pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
    if (this.pdfjsLib) {
      this.pdfjsLib.disableWorker = true;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pdfSlide.isGeneratingPdfSlide && !this.props.pdfSlide.isGeneratingPdfSlide) {
      this.setState({ selectedPdfs: [] });
    }
  }

  generateImages = async (pdf, index, total) => {
    try {
      const page = await pdf.getPage(index);
      const scale = 1.5;
      const viewport = page.getViewport(scale);
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const task = page.render({ canvasContext: context, viewport });
      await task.promise;
      this.setState((prevState) => ({
        images: prevState.images.concat({ src: canvas.toDataURL('image/jpeg', 1.0), id: index }),
      }));
    } finally {
      if (index >= total) {
        this.props.setPdfData({
          images: this.state.images,
        });
        this.setState({ isProcessing: false });
      } else {
        this.generateImages(pdf, index + 1, total);
      }
    }
  }

  handleChange = () => {
    const file = document.getElementById('pdf').files[0];
    if (!file) return;
    this.setState({ images: [], isProcessing: true, onceUploaded: true, selectedPdfs: [] });
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const loadingTask = this.pdfjsLib.getDocument(fileReader.result);
      const pdf = await loadingTask.promise;
      const numberOfPages = pdf.numPages;
      pdf.getMetadata().then((pdfInfo) => {
        const { sessionData } = this.props;
        const { teacher_id, id, state, group = {}, product } = sessionData || {};
        const { info } = pdfInfo || {};
        const { Title } = info || {};
        try {
          Mixpanel.track('teacher_uploaded_pdf', {
            teacher_id,
            session_id: id,
            session_state: state,
            group_id: group.id,
            product,
            no_of_pages: numberOfPages,
            pdf_title: Title,
          });
        } catch (e) {
          console.log(e);
        }
      });
      this.generateImages(pdf, 1, numberOfPages);
    };
    fileReader.readAsArrayBuffer(file);
  };

  searchByPageNumber = (e) => {
    this.setState({ pageNumber: parseInt(e.target.value, 10) });
  };

  sendEvent = (params) => {
    const { sessionData } = this.props;
    const { teacher_id, id, state, group = {}, product } = sessionData || {};
    try {
      Mixpanel.track('teacher_added_pdf_page_to_canvas', {
        teacher_id,
        session_id: id,
        session_state: state,
        group_id: group.id,
        product,
        ...params,
      });
    } catch (e) {
      console.log(e);
    }
  } ;

  addResourceToCanvas = (file) => {
    const fileEncode = dataURLtoFile(file.url, 'upl.jpeg');
    this.props.addResourceToCanvas(fileEncode, true);
    this.sendEvent({ selection: 'single', added_pages_count: 1 });
  };

  handleSelectAll = (e) => {
    const { checked } = e.target;
    const { images } = this.state;
    if (checked) {
      this.setState({ selectedPdfs: [...images.map((item) => item.id)] });
    } else {
      this.setState({ selectedPdfs: [] });
    }

  };

  handleSelectPdf = (id) => {
    const { selectedPdfs } = this.state;
    if (selectedPdfs.includes(id)) {
      this.setState((prevState) => ({ selectedPdfs: [...prevState.selectedPdfs.filter((item) => item !== id)] }));
    } else {
      this.setState((prevState) => ({ selectedPdfs: [...prevState.selectedPdfs, id] }));
    }
  }

  handleAddPdfSlide = () => {
    const { selectedPdfs, images } = this.state;
    const { onAddPdfSlide } = this.props;
    onAddPdfSlide(images.filter((image) => selectedPdfs.includes(image.id)));
    const selection = images.length === selectedPdfs.length ? 'all' : (selectedPdfs.length === 1 ? 'single' : 'multiple');
    this.sendEvent({ selection, added_pages_count: selectedPdfs.length });
  }

  render() {
    const { classroomConfig } = this.props;
    const { images, pageNumber, isProcessing, onceUploaded, selectedPdfs } = this.state;
    // eslint-disable-next-line no-restricted-globals
    const sortedImages = !isNaN(pageNumber)
      ? images.filter((item) => item.id === pageNumber)
      : images.sort((a, b) => a.id - b.id);
    return (
      <div className="pdf-image">
        {!onceUploaded && images.length === 0 && (
          <div className="pdfupload-blank-state">
            <IconPdf width="60px" height="60px" />
            <p>{t('classroom', 'noPdfUploaded')}</p>
            <label htmlFor="pdf" className="noon-btn noon-btn-secondary mlr-1">
              <input
                className="btn-upload--attach"
                id="pdf"
                // accept=".pdf"
                accept="application/pdf"
                type="file"
                multiple
                onChange={this.handleChange}
              />
              {t('classroom', 'uploadPdfLabel')}
            </label>
            {isProcessing && (
              <div className="pdf-loader">
                <span>{t('classroom', 'processingPDF')}</span>
                {' '}
                <IconLoader height="80px" width="80px" fill="#0684e7" />
              </div>
            )}
          </div>
        )}
        {onceUploaded && (
          <div className="btn-upload">
            <label htmlFor="pdf" className="noon-btn noon-btn-primary noon-btn-md noon-btn-outlined mb-1">
              <input
                className="btn-upload--attach"
                id="pdf"
                accept="application/pdf"
                type="file"
                multiple
                onChange={this.handleChange}
              />
              {t('classroom', 'uploadNewPdfLabel')}
            </label>
            <Search
              name="book-search"
              autoComplete="off"
              loading={false}
              placeholder={t('classroom', 'searchByPageNumber')}
              onChange={this.searchByPageNumber}
            />
          </div>
        )}
        {!!sortedImages.length && classroomConfig.pdfToSlide && (
          <Row nowrap className="full-width">
            <Checkbox className="pdf-checkbox pdf-checkbox__all" name="pdf-checkbox_all" onChange={this.handleSelectAll}>
              {`${t('label', 'selectAll')} (${images.length})`}
            </Checkbox>
          </Row>
        )}
        <Row className="pdf-image__container">
          {sortedImages.map((item) => (
            <div key={item.id} className="pdf-image__image-wrapper">
              <img src={item.src} alt={item.id} />
              <Badge type="default" size="xs" className="page-number-count">
                {item.id}
              </Badge>
              {classroomConfig.pdfToSlide && (
                <Checkbox
                  className="pdf-checkbox"
                  checked={selectedPdfs.includes(item.id)}
                  name={`pdf-checkbox_${item.id}`}
                  onChange={() => this.handleSelectPdf(item.id)}
                />
              )}
              <Button
                className="pdf-add-button animate slideInUp"
                type="primary"
                size="sm"
                onClick={() => this.addResourceToCanvas({ url: item.src })}
                >
                {t('classroom', 'addToCanvas')}
              </Button>
            </div>
          ))}
        </Row>

        {!!selectedPdfs.length && <Button type="primary" depth="2" className="create-pdf-slide-btn" onClick={this.handleAddPdfSlide}>{t('content', 'addPdfAsSlideBtn').replace('{pages}', selectedPdfs.length)}</Button>}

        {isProcessing && (
          <div className="pdf-loader">
            <span>{t('classroom', 'processingPDF')}</span>
            <IconLoader height="80px" width="80px" fill="#0684e7" />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  noonText: noonTextSelector(state),
  pdfData: state.toJS().classroom.pdfData,
  pdfSlide: state.toJS().whiteboard.pdfSlide,
  classroomConfig: state.toJS().classroomConfig,
});

const mapDispatchToProps = (dispatch) => ({
  setPdfData: (payload) => dispatch({ type: SET_PDF_DATA, payload }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PdfUpload);

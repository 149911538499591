import {
  GET_FEATURE_FLAG,
} from '../constants';

const initialState = {
  featureFlags: {},
  error: {},
};

export default function experiment(state = initialState, action) {

  switch (action.type) {
    case GET_FEATURE_FLAG.SUCCESS:
      return {
        ...state,
        featureFlags: {
          ...state.featureFlags,
          ...action.payload,
        },
      };
    case GET_FEATURE_FLAG.FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      if (action.type.indexOf('_REQUEST') !== -1) {
        return {
          ...state,
          error: {},
        };
      }
      return state;
  }
}

import React from 'react';
import isEmpty from 'lodash-es/isEmpty';
import { Avatar, Column, Row, Button } from '@noon/atom';
import { translationText } from '../../helpers';
import ErrorBoundry from '../ErrorBoundry';

export default function FriendRequest(prop) {
  return (
    <ErrorBoundry>
      <Column className="friend-request-container">
        <h4>{translationText(prop.noonText, 'notification.studentRequests')}</h4>
        {prop.pendingRequest.map((friend, index) => (
          <Row key={index} align="center" gap="sm" justify="end" nowrap className="friend-request-item">
            <Avatar url={friend.profile_pic} gender={friend.gender} size="36px" />
            <div className="player-info flex-1">
              <div className="player-title">{friend.name}</div>
              <div className="player-group">{friend.group_info.title}</div>
            </div>
            <Row nowrap align="center" justify="space-sm">
              <Button
                type="primary"
                size="small"
                onClick={() => prop.acceptGroupRequest(friend, index)}
                loading={prop.acceptBtnLoadingId === index}
              >
                {translationText(prop.noonText, 'button.acceptRequest')}
              </Button>
              <Button
                type="secondary"
                size="small"
                onClick={() => prop.rejectGroupRequest(friend, index)}
                loading={prop.rejectBtnLoadingId === index}
              >
                {translationText(prop.noonText, 'button.reject')}
              </Button>
            </Row>
          </Row>
        ))}
      </Column>
    </ErrorBoundry>
  );
}

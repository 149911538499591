import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InfiniteScroll, ContentOnTop } from '@noon/atom';
import { Container, Draggable } from 'react-smooth-dnd';
import { IconLoaderDotted } from '@noon/quark/dist';
import LibraryQuestion from './libraryQuestion';
import { checkIsTablet, checkIsMobile } from '../../helpers/prepareAssignment';
import { translationText } from '../../helpers';

const LibraryQuestionsList = ({ questions, isNextPage, setLoadMore, selectedTab, noonText, loading }) => {
  const { currentQuestions } = useSelector((state) => state.toJS().homeworkCreation);

  const checkIfAdded = (question) => currentQuestions.data.map((existingQuestion) => existingQuestion.id === question.id).includes(true);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const showModal = (image) => {
    setModalImage(image);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalImage('');
    setModalVisible(false);
  };

  const escFunc = (event) => {
    if (event.keyCode === 27) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunc, false);
    return () => {
      document.removeEventListener('keydown', escFunc, false);
    };
  }, [modalVisible]);

  return (
    <div className="prepare-assignment--questionsBarContainer">
      {modalVisible && (
        <ContentOnTop
          className="question-content"
          noBackground
          position={!localStorage.language.includes('en') ? (window.innerWidth > 992 ? 'custom-position-right' : 'custom-position-left') : 'custom-position-left'}
          onClose={closeModal}
        >
          <div className="image-container">
            <img src={modalImage} alt={`${modalImage}`} />
          </div>
        </ContentOnTop>
      )}
      {questions.length > 0 ? (
        <InfiniteScroll
          onLoad={() => {
            if (!loading) {
              setLoadMore();
            }
          }}
          loadMore={isNextPage}
          loading={loading}
          offset={0}
          className="infinite-scroll"
        >
          <Container
            groupName="questions-list"
            behaviour="copy"
            getChildPayload={(index) => questions[index]}
            onDrop={(e) => console.log(e)}
        >
            {questions.map((question, index) => {
              let indexInExistingCollectionOfQuestions = 0;
              currentQuestions.data.forEach((currentQuestion, idx) => {
                if (currentQuestion.id === question.id) indexInExistingCollectionOfQuestions = idx;
              });
              return (
                <Draggable key={index}>
                  <LibraryQuestion
                    question={question}
                    index={index}
                    showModal={(image) => showModal(image)}
                    isAdded={checkIfAdded(question)}
                    indexInExistingCollectionOfQuestions={indexInExistingCollectionOfQuestions}
                    noonText={noonText}
                  />
                </Draggable>
              );
            })}
          </Container>
        </InfiniteScroll>
      ) : (
        <div className="prepare-assignment--emptyQuestions">
          <img src="/assets/images/empty-state/empty-questions.svg" alt="empty-questions" />
          <span>
            {selectedTab === 'My Library'
              ? translationText(noonText, 'createHomework.noYourLibraryQuestions')
              : translationText(noonText, 'createHomework.noNoonLibraryQuestions')}
          </span>
        </div>
      )}
    </div>
  );
};

export default LibraryQuestionsList;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

import { isMobile } from 'react-device-detect';
import { COLORS } from '../../constants';
import t from '../../helpers/translate';

class CoachmarkClassroom extends Component {

  constructor(props) {
    super(props);

    this.state = {
      locale: 'ar',
      run: true,
      stepIndex: 0,
      steps: [
        {
          content: t('coachmark', 'addSlideSubheading'),
          placement: isMobile ? 'bottom' : 'top',
          target: isMobile ? '.slide' : '.classroom-waiting-card',
          title: t('coachmark', 'addSlideHeading'),
          locale: { next: t('coachmark', 'next'), back: t('coachmark', 'back') },
          disableBeacon: true,
        },
        {
          content: t('coachmark', 'addContentSubheading'),
          placement: 'right',
          target: '.my-classroom__content',
          title: t('coachmark', 'addContentHeading'),
          locale: { next: t('coachmark', 'next'), back: t('coachmark', 'back') },
          disableBeacon: true,
        },
        {
          content: t('coachmark', 'addPdfSubHeading'),
          placement: 'right',
          target: '.tab-bar__item:nth-child(4)',
          title: t('coachmark', 'addPdfHeading'),
          locale: { next: t('coachmark', 'next'), back: t('coachmark', 'back') },
          disableBeacon: true,
        },
        {
          content: t('coachmark', 'toolboxSubheading'),
          placement: 'right',
          target: '.canvasToolBar',
          locale: { last: t('coachmark', 'okay'), back: t('coachmark', 'back') },
          title: t('coachmark', 'toolboxHeading'),
          disableBeacon: true,
        },
      ],
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.slides.length && this.props.slides.length) {
      this.setState({ stepIndex: 1 });
    }
  }

  handleJoyrideCallback = (data) => {
    const { joyride, onClose } = this.props;
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ run: false });
      // set create coachmarks to be true
      localStorage.createCoachmarks = true;
      if (onClose) onClose();
      delete localStorage.classroomCoachmark;
    }

    if (typeof joyride.callback === 'function') {
      joyride.callback(data);
    }
  };

  getHelpers = (helpers) => {
    this.helpers = helpers;
    window.helpers = helpers;
  };

  render() {
    const { loading, ...joyrideProps } = this.state;
    const props = {
      ...joyrideProps,
      ...this.props.joyride,
    };

    return (
      <ReactJoyride
        scrollToFirstStep
        {...props}
        callback={this.handleJoyrideCallback}
        run={this.state.run}
        steps={this.state.steps}
        getHelpers={this.getHelpers}
        continuous
        spotlightClicks
        styles={{
          options: {
            primaryColor: COLORS.brand.base,
            zIndex: 100000,
          },
        }}
        />
    );
  }
}

CoachmarkClassroom.propTypes = {
  joyride: PropTypes.shape({
    callback: PropTypes.func,
  }),
  onClose: PropTypes.func,
};

CoachmarkClassroom.defaultProps = {
  joyride: {},
  onClose: () => {},
};

export default CoachmarkClassroom;

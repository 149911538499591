import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { IconAdd, IconArrowDown, IconAssignment, IconPlus, IconReplayPlayO, IconSchedule } from '@noon/quark';
import { get, isEmpty } from 'lodash-es';
import { Row, Column, Button, Checkbox, InfiniteScroll, Badge } from '@noon/atom';
import { isMobileOnly, isMobile } from 'react-device-detect';

import { Accordian } from '../../components/Curriculum';
import {
  ScheduleListSessionCard,
  CurrentAssignmentCard,
  PreviousAssignmentCard,
  EmptyListActivityCard,
} from '../../components/Session';
import { CreateActivity } from '../../components/Create';
import { getQueryObject } from '../../helpers/getQueryItem';
import Breadcrumbs from '../../components/Breadcrumbs';
import { translationText } from '../../helpers';
import {
  GET_CHAPTERS_FROM_GROUP,
  GET_TEACHER_GROUP_TOPICS,
  UPCOMING_TEACHER_SESSION,
  MY_PREVIOUS_GROUP_TUTORING,
  GET_TEACHER_GROUP_SELECTED,
  GET_PRE_PUBLISHED_HOMEWORKS,
  GET_POST_PUBLISHED_HOMEWORKS,
  CLEAR_UPDATE_HOMEWORK,
  CLEAR_HOMEWORK_SPARTA_DETAILS,
  CLEAR_QUESTIONS,
  CLEAR_TOPIC_QUESTIONS,
  CLEAR_REPORT_PAGE_DATA,
} from '../../redux/constants';
import GroupHeader from '../../components/GroupDetails/GroupHeader';
import Mixpanel from '../../components/Mixpanel';
import { COLORS } from '../../constants';

const contents = [
  { heading: { icon: IconSchedule, label: 'teacherDashboard.upcomingSessions' }, isAccordianOpen: false },
  { heading: { icon: IconReplayPlayO, label: 'teacherDashboard.previousSessions' }, isAccordianOpen: false },
  { heading: { icon: IconAssignment, label: 'teacherDashboard.unpublishedHomeworks' }, isAccordianOpen: false },
  { heading: { icon: IconAssignment, label: 'teacherDashboard.publishedHomeworks' }, isAccordianOpen: false },
];

const CurriculumContent = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { prePublishedHomeworks, hw_creation, postPublishedHomeworks, deleteHomework, updateHomework } = useSelector(
    (state) => state.toJS().homeworkCreation,
  );
  const {
    myPreviousGroupTutoring,
    upcomingTeacherSession,
    createdGroupSessionsForTeacher,
    deleteSession,
    updatedGroupSessions,
  } = useSelector((state) => state.toJS().tutoring);
  const { noonText } = useSelector((state) => state.toJS().translation);
  const [selectedTopic, setTopic] = useState(false);
  const [selectedChapter, setChapter] = useState(false);
  const [showItem, assignItem] = useState('');
  const { groupId } = useParams();
  const { groupChapters, curriculumList } = useSelector((state) => state.toJS().groupsV2);
  const { chapterId, topicId } = getQueryObject(location.search, '=');
  const [accordionState, setAccordionState] = useState('');
  const [showFilters, setShowFilters] = useState(!isMobileOnly);

  const { selectedGroup: group } = useSelector((state) => state.toJS().groupsV2);
  const user = useSelector((state) => state.toJS().userv2.getTeacher.response);
  const logger = useRef(LoggingManager.mount({ moduleName: 'group' })).current;
  const isArchived = get(group, ['archive', 'is_archived']);

  const handleMixpanelCurriculumEvents = ({ data = {}, type, mixPanel = true }) => {
    const dataConst = {
      user_id: user.user_id,
      group_id: groupId,
      group_name: get(group, ['group_info', 'title']),
      group_country: get(group, ['country', 'name']),
      group_subject: get(group, ['curriculum_tags', 'subject', 'name']),
      group_grades: get(group, ['curriculum_tags', 'grade', 'name']),
      group_curriculum_type: get(group, ['curriculum_tags', 'TYPE']),
      group_type: get(group, ['group_info', 'TYPE']),
      group_age: '',
      no_of_students: get(group, ['membersInfo', 'total_members']),
      timestamp: Date.now(),
      ...data,
    };
    if (mixPanel) {
      Mixpanel.track(type, dataConst);
    }
    logger.track(type, { group: dataConst });
  };

  // const [seachAnimation, showSearchAnimation] = useState(false);
  // const mouseEventSearch = (value) => {
  //   showSearchAnimation(value);
  // };

  const selectTopicAndChapter = (chapter, topic) => {
    setTopic(topic);
    setChapter(chapter);
  };

  const toggleCreate = (name) => {
    assignItem(name !== showItem ? name : '');
  };

  const handleExpandChapter = (chapterId, accordianState) => {
    if (accordianState) {
      dispatch({
        type: GET_TEACHER_GROUP_TOPICS.REQUEST,
        payload: { groupId, chapterId },
      });
    }
  };

  const loadUpcommingSessions = (isAccordianOpen, pagination) => {
    contents[0].isAccordianOpen = isAccordianOpen;
    if (!isAccordianOpen) {
      return false;
    }
    const { response, page, isLoading } = upcomingTeacherSession;
    if (isLoading) {
      return false;
    }
    const payload = {
      page: 1,
      limit: 10,
      group_id: groupId,
    };
    if (!isEmpty(response) && page > 0 && pagination) {
      payload.page = page;
    }
    if (selectedChapter) {
      payload.chapter_id = selectedChapter.chapter_id;
    }
    if (selectedTopic) {
      payload.topic_ids = selectedTopic.topic_id;
    }
    dispatch({
      type: UPCOMING_TEACHER_SESSION.REQUEST,
      payload,
    });
    return true;
  };

  const loadPrevSessions = (isAccordianOpen, pagination) => {
    contents[1].isAccordianOpen = isAccordianOpen;
    if (!isAccordianOpen) {
      return false;
    }
    const { response, page, isLoading } = myPreviousGroupTutoring;
    if (isLoading) {
      return false;
    }
    const payload = {
      page: 1,
      limit: 10,
      group_id: groupId,
    };
    if (!isEmpty(response) && page > 0 && pagination) {
      payload.page = page;
    }
    if (selectedChapter) {
      payload.chapter_id = selectedChapter.chapter_id;
    }
    if (selectedTopic) {
      payload.topic_ids = selectedTopic.topic_id;
    }
    dispatch({
      type: MY_PREVIOUS_GROUP_TUTORING.REQUEST,
      payload,
    });
    return true;
  };

  const loadUpcommingHW = (isAccordianOpen, pagination, deleted) => {
    contents[2].isAccordianOpen = isAccordianOpen;
    if (!isAccordianOpen) {
      return false;
    }
    const { data, meta, isLoading } = prePublishedHomeworks;
    if (isLoading) {
      return false;
    }
    const payload =
      prePublishedHomeworks.meta.last_tag_id && !deleted
        ? {
            last_tag_id: prePublishedHomeworks.meta.last_tag_id,
            limit: 10,
            group_id: groupId,
          }
        : {
            limit: 10,
            group_id: groupId,
          };
    if (!isEmpty(data) && meta.next_offset > 0 && pagination) {
      payload.start = meta.next_offset;
      payload.limit = meta.limit;
    }
    if (selectedChapter) {
      payload.chapter_id = selectedChapter.chapter_id;
    }
    if (selectedTopic) {
      payload.topic_id = selectedTopic.topic_id;
    }
    dispatch({
      type: GET_PRE_PUBLISHED_HOMEWORKS.REQUEST,
      payload,
    });
    return true;
  };

  const loadPrevHW = (isAccordianOpen, pagination, deleted) => {
    contents[3].isAccordianOpen = isAccordianOpen;
    if (!isAccordianOpen) {
      return false;
    }
    const { data, meta, isLoading } = postPublishedHomeworks;
    if (isLoading) {
      return false;
    }
    const payload =
      postPublishedHomeworks.meta.last_tag_id && !deleted
        ? {
            last_tag_id: postPublishedHomeworks.meta.last_tag_id,
            limit: 10,
            group_id: groupId,
          }
        : {
            limit: 10,
            group_id: groupId,
          };
    if (!isEmpty(data) && meta.next_offset > 0 && pagination) {
      payload.start = meta.next_offset;
      payload.limit = meta.limit;
    }
    if (selectedChapter) {
      payload.chapter_id = selectedChapter.chapter_id;
    }
    if (selectedTopic) {
      payload.topic_id = selectedTopic.topic_id;
    }
    dispatch({
      type: GET_POST_PUBLISHED_HOMEWORKS.REQUEST,
      payload,
    });
    return true;
  };

  useEffect(() => {
    dispatch({
      type: CLEAR_UPDATE_HOMEWORK,
    });
    dispatch({
      type: CLEAR_HOMEWORK_SPARTA_DETAILS,
    });
    dispatch({
      type: CLEAR_QUESTIONS,
    });
    dispatch({
      type: CLEAR_TOPIC_QUESTIONS,
    });
    dispatch({
      type: CLEAR_REPORT_PAGE_DATA,
    });
  }, []);
  useEffect(() => {
    if (contents[0].isAccordianOpen) {
      loadUpcommingSessions(contents[0].isAccordianOpen);
    }
    if (contents[1].isAccordianOpen) {
      loadPrevSessions(contents[1].isAccordianOpen);
    }
    if (contents[2].isAccordianOpen) {
      loadUpcommingHW(contents[2].isAccordianOpen);
    }
    if (contents[3].isAccordianOpen) {
      loadPrevHW(contents[3].isAccordianOpen);
    }
  }, [selectedChapter, selectedTopic]);

  useEffect(() => {
    if (hw_creation && !hw_creation.loading && hw_creation.success) {
      if (contents[2].isAccordianOpen) {
        loadUpcommingHW(contents[2].isAccordianOpen);
      }
    }
    if (!deleteHomework.loading && deleteHomework.success) {
      if (contents[2].isAccordianOpen) {
        loadUpcommingHW(contents[2].isAccordianOpen, false, true);
      }
      if (contents[3].isAccordianOpen) {
        loadPrevHW(contents[3].isAccordianOpen, false, true);
      }
    }
    if (!updateHomework.loading && updateHomework.success && contents[2].isAccordianOpen) {
      loadUpcommingHW(contents[2].isAccordianOpen);
      dispatch({
        type: CLEAR_UPDATE_HOMEWORK,
      });
    }
  }, [hw_creation, deleteHomework, updateHomework]);

  // useEffect(() => {
  //   if (
  //     createdGroupSessionsForTeacher &&
  //     !createdGroupSessionsForTeacher.loading &&
  //     createdGroupSessionsForTeacher.success
  //   ) {
  //     if (contents[0].isAccordianOpen) {
  //       loadUpcommingSessions(contents[0].isAccordianOpen);
  //     }
  //   }
  // }, [createdGroupSessionsForTeacher]);

  useEffect(() => {
    if (
      (updatedGroupSessions && !updatedGroupSessions.loading && updatedGroupSessions.success) ||
      (deleteSession && !deleteSession.loading && deleteSession.success) ||
      (createdGroupSessionsForTeacher &&
        !createdGroupSessionsForTeacher.loading &&
        createdGroupSessionsForTeacher.success)
    ) {
      if (contents[0].isAccordianOpen) {
        loadUpcommingSessions(contents[0].isAccordianOpen);
      }
    }
  }, [createdGroupSessionsForTeacher, deleteSession, updatedGroupSessions]);

  useEffect(() => {
    dispatch({
      type: GET_CHAPTERS_FROM_GROUP.REQUEST,
      payload: groupId,
    });
    dispatch({
      type: GET_TEACHER_GROUP_SELECTED.REQUEST,
      payload: groupId,
    });
    return () => {
      LoggingManager.unmount(logger.id);
    };
  }, []);

  useEffect(() => {
    if (!selectedChapter && chapterId && groupChapters && groupChapters.list && !groupChapters.loading) {
      groupChapters.list.map((item) => {
        if (item.chapter_id == chapterId) {
          setChapter(item);
          handleExpandChapter(chapterId, true);
        }
      });
    }
  }, [groupChapters]);

  useEffect(() => {
    if (
      !selectedTopic &&
      chapterId &&
      topicId &&
      curriculumList.topics &&
      curriculumList.topics[chapterId] &&
      !curriculumList.topics[chapterId].loading
    ) {
      curriculumList.topics[chapterId].data.map((item) => {
        if (item.topic_id === topicId) {
          setTopic(item);
        }
      });
    }
  }, [curriculumList]);

  const goBack = () => {
    history.push(`/${localStorage.updatedLocale}/groups/${groupId}`);
  };
  return (
    <React.Fragment>
      <GroupHeader group={group} handleMixpanelCurriculumEvents={handleMixpanelCurriculumEvents} />
      {/* <PageHeader title={translationText(noonText, 'tab.curriculumContent')} /> */}
      <Breadcrumbs onItemClick={goBack} pageTitle={translationText(noonText, 'tab.content')} />
      {showItem === 'activity' && (
        <CreateActivity
          groupId={groupId}
          onContentClose={() => toggleCreate('activity')}
          source="group_home_curriculum"
        />
      )}
      <Row className="container curriculum-content">
        <Column className="curriculum-content--menu mb-2">
          <div className="curriculum-content--header">
            <Row justify="space-between" align="start">
              <div>
                <svg
                  className="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.591"
                  height="12.909"
                  viewBox="0 0 12.591 12.909"
                >
                  <g id="Group_72471" data-name="Group 72471" transform="translate(-233.941 -106.025)">
                    <path
                      id="Path_138874"
                      data-name="Path 138874"
                      d="M238.918,118.934a.635.635,0,0,1-.425-.688c.009-1.529,0-3.059.006-4.589a1.471,1.471,0,0,0-.284-.893q-2.069-2.9-4.13-5.813a.629.629,0,0,1-.142-.432.54.54,0,0,1,.5-.494c.038,0,.076,0,.113,0h11.358a.55.55,0,0,1,.6.439.581.581,0,0,1-.124.472q-.709,1-1.416,1.993-1.379,1.942-2.758,3.883a1.335,1.335,0,0,0-.254.8q0,1.891,0,3.782a.547.547,0,0,1-.429.625l-2.4.915Z"
                      transform="translate(0 0)"
                      fill="#333"
                    />
                  </g>
                </svg>
                <span className="mlr-1 curriculum-content--header__title">
                  {translationText(noonText, 'filter.title')}
                </span>
                {isMobile && (
                  <IconArrowDown
                    onClick={() => setShowFilters(!showFilters)}
                    height="20px"
                    width="20px"
                    fill="#62687A"
                    stroke="#62687A"
                  />
                )}
              </div>
              <Button link size="md" onClick={() => selectTopicAndChapter(false, false)}>
                {translationText(noonText, 'filter.clear')}
              </Button>
            </Row>
          </div>
          {showFilters && (
            <Column className="curriculum-content--body curriculum-content--chapters">
              {/* <div className="curriculum-content--body"> */}
              {groupChapters && groupChapters.list && (
                <React.Fragment>
                  <div className="all-filter">
                    <Checkbox
                      checked={!selectedChapter.id}
                      onChange={() => selectTopicAndChapter(false, false)}
                      type="checkbox"
                      label={`${translationText(noonText, 'filter.allChapters')} (${groupChapters.list.length})`}
                    />
                  </div>
                  {groupChapters.list.map((chapter) => (
                    <Accordian
                      key={chapter.id}
                      type="checkbox"
                      data={chapter}
                      initialState={accordionState === 'upcoming-classes' && selectedChapter.id === chapter.id}
                      checked={selectedChapter.id === chapter.id || !selectedChapter.id}
                      togglingAccordian={(accordianState) => {
                        handleExpandChapter(chapter.chapter_id, accordianState);
                        setAccordionState('upcoming-classes');
                      }}
                      selectCheckBox={() => {
                        selectTopicAndChapter(chapter, false);
                      }}
                      loadingAccordian={
                        curriculumList.topics &&
                        curriculumList.topics[chapter.chapter_id] &&
                        curriculumList.topics[chapter.chapter_id].loading
                      }
                      hideExpandButton={get(chapter, 'type') !== 'chapter'}
                    >
                      {curriculumList.topics &&
                        curriculumList.topics[chapter.chapter_id] &&
                        curriculumList.topics[chapter.chapter_id].data &&
                        curriculumList.topics[chapter.chapter_id].data.map((topic) => (
                          <Column
                            key={topic.id}
                            onClick={() => selectTopicAndChapter(chapter, topic)}
                            className={classNames('topic', {
                              activeTopic: selectedTopic && selectedTopic.id === topic.id,
                            })}
                          >
                            <Row>
                              <div className="topic__title">{topic.name_header}</div>
                            </Row>
                            <div className="topic__name">{topic.name}</div>
                            {!topic.is_free && 
                              <Badge type="default" dir="ltr" className="plus-badge">
                                <span>{translationText(noonText, 'groups.plus')}</span>
                              </Badge>
                            }
                          </Column>
                        ))}
                    </Accordian>
                  ))}
                </React.Fragment>
              )}
              {/* </div> */}
            </Column>
          )}
          {/* <div className="curriculum-content--menu">
          </div> */}
        </Column>
        <Column className="curriculum-content--content">
          <div className="custom-curriculum">
            <Column className="list">
              {!isArchived && (
                <Row className="list--search-and-content search--block">
                  <Row>
                    <div className="icon_btn_outer sm blue">
                      <Button
                        type="primary"
                        size="md"
                        value={translationText(noonText, 'button.newActivity')}
                        onClick={() => toggleCreate('activity')}
                        icon={IconAdd}
                        className="text-capitalize"
                      />
                    </div>
                  </Row>
                </Row>
              )}
              <div className="filtered--data">
                <div className="left-data">
                  <p className="title">{translationText(noonText, 'filter.applied')}</p>
                </div>
                <div className="right-data">
                  {!isEmpty(selectedChapter) ? (
                    <div className="list--selected-content">
                      <div className="list--selected-content--text">
                        {selectedChapter.name_header || selectedChapter.name}
                        {selectedTopic ? `, ${selectedTopic.name_header || selectedTopic.name}` : ''}
                        <span>
                          <IconPlus
                            rotate="45"
                            width="12px"
                            height="12px"
                            fill={COLORS.coolGrey[1]}
                            stroke={COLORS.coolGrey[1]}
                            onClick={() => selectTopicAndChapter(false, false)}
                          />
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="list--selected-content">
                      <div className="list--selected-content--text">
                        {translationText(noonText, 'filter.allChapters')}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {!isArchived && (
                <Accordian
                  heading={contents[0].heading}
                  initialState={contents[0].isAccordianOpen && accordionState === 'previous-classes'}
                  togglingAccordian={(accordianState) => {
                    loadUpcommingSessions(accordianState);
                    setAccordionState('previous-classes');
                  }}
                  loadingAccordian={upcomingTeacherSession.isLoading}
                >
                  <InfiniteScroll
                    onLoad={() => loadUpcommingSessions(contents[0].isAccordianOpen, true)}
                    loadMore={!upcomingTeacherSession.isLoading && upcomingTeacherSession.page > 0}
                    loading={!!upcomingTeacherSession.isLoading}
                    offset={400}
                    className="infinite-scroll"
                  >
                    {upcomingTeacherSession.response.map((activity) => (
                      <ScheduleListSessionCard key={activity.id} cardView="content" data={activity} groupId={groupId} />
                    ))}
                  </InfiniteScroll>
                  {!upcomingTeacherSession.isLoading && !upcomingTeacherSession.response.length && (
                    <EmptyListActivityCard actionTake={(nameOfAction) => toggleCreate(nameOfAction)} />
                  )}
                </Accordian>
              )}
              <Accordian
                heading={contents[1].heading}
                initialState={contents[1].isAccordianOpen && accordionState === 'current-homework'}
                togglingAccordian={(accordianState) => {
                  loadPrevSessions(accordianState);
                  setAccordionState('current-homework');
                }}
                loadingAccordian={myPreviousGroupTutoring.isLoading}
              >
                <InfiniteScroll
                  onLoad={() => loadPrevSessions(contents[1].isAccordianOpen, true)}
                  loadMore={!myPreviousGroupTutoring.isLoading && myPreviousGroupTutoring.page > 0}
                  loading={!!myPreviousGroupTutoring.isLoading}
                  offset={400}
                  className="infinite-scroll"
                >
                  {myPreviousGroupTutoring.response.map((activity) => (
                    <ScheduleListSessionCard key={activity.id} cardView="content" data={activity} groupId={groupId} />
                  ))}
                </InfiniteScroll>
                {!myPreviousGroupTutoring.isLoading && !myPreviousGroupTutoring.response.length && !isArchived && (
                  <EmptyListActivityCard pastActivity actionTake={(nameOfAction) => toggleCreate(nameOfAction)} />
                )}
              </Accordian>
              {!isArchived && (
                <Accordian
                  heading={contents[2].heading}
                  initialState={contents[2].isAccordianOpen && accordionState === 'past-homework'}
                  togglingAccordian={(accordianState) => {
                    loadUpcommingHW(accordianState);
                    setAccordionState('past-homework');
                  }}
                  sideheading={
                    prePublishedHomeworks.meta.count
                      ? `${prePublishedHomeworks.meta.count} ${translationText(noonText, 'homework.title')}`
                      : ''
                  }
                  loadingAccordian={prePublishedHomeworks.isLoading}
                >
                  <InfiniteScroll
                    onLoad={() => loadUpcommingHW(contents[2].isAccordianOpen, true)}
                    loadMore={prePublishedHomeworks.meta.last_tag_id !== undefined}
                    loading={prePublishedHomeworks.isLoading}
                    offset={0}
                    className="infinite-scroll"
                  >
                    {!!prePublishedHomeworks.data.length &&
                      prePublishedHomeworks.data.map((activity) => (
                        <CurrentAssignmentCard cardView="content" key={activity.id} data={activity} groupId={groupId} />
                      ))}
                  </InfiniteScroll>
                  {!prePublishedHomeworks.isLoading && !prePublishedHomeworks.data.length && (
                    <EmptyListActivityCard actionTake={(nameOfAction) => toggleCreate(nameOfAction)} />
                  )}
                </Accordian>
              )}
              <Accordian
                heading={contents[3].heading}
                initialState={contents[3].isAccordianOpen && accordionState === 'homework'}
                togglingAccordian={(accordianState) => {
                  loadPrevHW(accordianState);
                  setAccordionState('homework');
                }}
                sideheading={
                  postPublishedHomeworks.meta.count
                    ? `${postPublishedHomeworks.meta.count} ${translationText(noonText, 'homework.title')}`
                    : ''
                }
                loadingAccordian={postPublishedHomeworks.isLoading}
              >
                <InfiniteScroll
                  onLoad={() => loadPrevHW(contents[3].isAccordianOpen, true)}
                  loadMore={postPublishedHomeworks.meta.last_tag_id !== undefined}
                  loading={postPublishedHomeworks.isLoading}
                  offset={400}
                  className="infinite-scroll"
                >
                  {!!postPublishedHomeworks.data.length &&
                    postPublishedHomeworks.data.map((activity) => (
                      <PreviousAssignmentCard cardView="content" key={activity.id} data={activity} groupId={groupId} />
                    ))}
                </InfiniteScroll>
                {!postPublishedHomeworks.isLoading && !postPublishedHomeworks.data.length && (
                  <EmptyListActivityCard pastActivity actionTake={(nameOfAction) => toggleCreate(nameOfAction)} />
                )}
              </Accordian>
            </Column>
          </div>
        </Column>
      </Row>
    </React.Fragment>
  );
};

export default CurriculumContent;

import { DateTime, Info } from 'luxon';

export const formatSecondsToString = (totalSeconds = 0, format) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  let seconds = totalSeconds - hours * 3600 - minutes * 60;

  // round seconds
  seconds = Math.round(seconds * 100) / 100;
  let result = '';
  switch (format) {
    case 'MM:SS':
      result += `${minutes < 10 ? `0${minutes}` : minutes}`;
      result += `:${seconds < 10 ? `0${seconds}` : seconds}`;
      return result;
    default:
      result = hours < 10 ? `0${hours}` : hours;
      result += `:${minutes < 10 ? `0${minutes}` : minutes}`;
      result += `:${seconds < 10 ? `0${seconds}` : seconds}`;
      return result;
  }
};

export const formatStringToSeconds = (value) => {
  const time = value || '00:00:00';
  const timeArray = time.split(':');
  return +timeArray[0] * 60 * 60 + +timeArray[1] * 60 + +timeArray[2];
};

export const getDuration = (startTime, endTime, format = ['days', 'hours', 'minutes', 'seconds']) => {
  const start = DateTime.fromISO(startTime);
  const end = DateTime.fromISO(endTime);
  return end.diff(start, format).toObject() || {};
};

export const getWeekdays = (onlyEnglish) => {
  const locale = localStorage.language && !onlyEnglish ? localStorage.language.split('_')[0] : 'en';
  // TODO: Hack to set sunday and first day of week
  const weekdays = [...Info.weekdays('long', { locale })];
  weekdays.unshift(weekdays.splice(6)[0]);
  return [...weekdays];
};

export const getWeekdaysShort = () => {
  const locale = localStorage.language ? localStorage.language.split('_')[0] : 'en';
  // TODO: Hack to set sunday and first day of week
  // localStorage.language ? localStorage.language.split('_')[0] :
  const weekdaysShort = [...Info.weekdays('short', { locale })];
  weekdaysShort.unshift(weekdaysShort.splice(6)[0]);
  return [...weekdaysShort];
};

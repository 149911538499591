import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Row, Column, Button } from '@noon/atom';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash-es/get';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { head, isEmpty } from 'lodash-es';
import { CreateActivity, CreateSchedule } from '../../components/Create';
import {
  GET_TEACHER_GROUP_SELECTED,
  GET_TEACHER_GROUP_CHAPTERS,
  GET_TEACHER_GROUP_COURSE_PREVIEW,
  GET_TEACHER_GROUP_TOPICS,
  POST_TEACHER_GROUP_TOPICS_MARK_COMPLETE,
  GET_TEACHER_GROUP_MEMBERS_INFO,
  GET_TEACHER_TAGS_GROUP,
} from '../../redux/constants';
import CurriculumWidget from '../../components/GroupDetails/CurriculumWidget';
import MembersWidget from '../../components/GroupDetails/MembersWidget';
import GroupStatusWidget from '../../components/GroupDetails/GroupStatusWidget';
import OptoutWidget from '../../components/GroupDetails/OptoutWidget';
import FillMissingValueModal from '../../components/GroupDetails/FillMissingValueModal';
import { StudyGroupFeed } from '../../components/StudyGroup';
import Mixpanel from '../../components/Mixpanel';
import ActivityWidget from '../../components/GroupDetails/ActivityWidget';
import GroupHeader from '../../components/GroupDetails/GroupHeader';
import NotificationBar from '../../components/Layout/NotificationBar';
import ArchivedGroupMessage from '../../components/GroupDetails/ArchiveGroup/ArchivedGroupMessage';
import ArchiveGroupModal from '../../components/GroupDetails/ArchiveGroup/ArchiveGroupModal';
import { getQueryObject } from '../../helpers';
import t from '../../helpers/translate';
import UnArchiveGroupModal from '../../components/GroupDetails/ArchiveGroup/UnArchiveGroupModal';
import useNoonDuration from '../../hooks/useNoonDuration';

const GroupDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const logger = useRef(LoggingManager.mount({ moduleName: 'group' })).current;
  const isRtl = document.body.dir === 'rtl';
  const { groupId: id } = useParams();
  const { archive = false } = getQueryObject(location.search, '=');
  const [showCreateActivityDialog, toggleCreateActivityDialog] = useState(false);
  const [createActivityParams, setCreateActivityParams] = useState({});
  const [showGroupInfoModal, setShowGroupInfoModal] = useState(false);
  const [showCurriculumModalFor, selectCurriculumModal] = useState(null);
  const [showArchiveGroupConfirmation, setShowArchiveGroupConfirmation] = useState(archive === 'true');
  const [hideNotificationBar] = useState(false);
  const [showUnArchiveGroupConfirmation, setShowUnArchiveGroupConfirmation] = useState(false);

  const { selectedGroup: group, curriculumList, sections, curriculum, error, archiveGroup } = useSelector(
    (state) => state.toJS().groupsV2,
  );

  // get feature flags from redux store
  const featureFlags = useSelector((state) => state.toJS().experiment.featureFlags);

  const user = useSelector((state) => state.toJS().userv2.getTeacher.response);
  const { chapters, curriculumExist, hasDraft, loading: curriculumLoading } = useSelector(
    (state) => state.toJS().curriculum,
  );
  const [showCreateSchedule, setShowCreateSchedule] = useState({ show: false, source: 'auto' });
  const { createScheduleCompleted } = useSelector((state) => state.toJS().scheduler);
  const missingCurriculumOrSection = useMemo(() => {
    const curriculumType = get(group, 'curriculum_tags.type');
    const key = curriculumType === 'k12' ? 'curriculum' : 'sections';
    const list = get(group, ['curriculum_tags', key]) || [];
    return {
      key,
      list,
    };
  }, [group]);

  const isArchived = get(group, ['archive', 'is_archived']);
  const isScheduleNotSet = id === get(group, ['group_info', 'id'])
    && !group.loading
    && !get(curriculumList, ['preview', 'loading'])
    && get(curriculumList, ['preview', 'type']) !== 'misc'
    && curriculumExist
    && isEmpty(group.schedules);
  const groupEndDate = new Date(get(head(group.schedules), 'valid_till'));
  const isGroupDurationOver = Date.now() > groupEndDate;
  const groupEndDateDuration = useNoonDuration({ ms: groupEndDate || Date.now() });

  const handleMixpanelCurriculumEvents = ({ data = {}, type, mixPanel = true }) => {
    const dataConst = {
      user_id: user.user_id,
      group_id: id,
      group_name: get(group, ['group_info', 'title']),
      group_country: get(group, ['country', 'name']),
      group_subject: get(group, ['curriculum_tags', 'subject', 'name']),
      group_grades: get(group, ['curriculum_tags', 'grade', 'name']),
      group_curriculum_type: get(group, ['curriculum_tags', 'TYPE']),
      group_type: get(group, ['group_info', 'TYPE']),
      no_of_students: get(group, ['membersInfo', 'total_members']),
      timestamp: Date.now(),
      ...data,
    };
    if (mixPanel) {
      Mixpanel.track(type, dataConst);
    }
    logger.track(type, { group: dataConst });
  };

  useEffect(
    () => () => {
      LoggingManager.unmount(logger.id);
    },
    [],
  );

  useEffect(() => {
    localStorage.lastGroupUrl = JSON.stringify(location);
  }, [location]);

  useEffect(() => {
    const selectedGroupId = get(group, 'group_info.id');
    if (String(selectedGroupId) !== String(id)) {
      handleMixpanelCurriculumEvents({
        type: 'opens_group',
        data: {
          curriculum_state: curriculumExist ? (hasDraft ? 'draft' : 'published') : 'empty',
        },
      });
    }
    dispatch({
      type: GET_TEACHER_GROUP_SELECTED.REQUEST,
      payload: id,
    });
    dispatch({
      type: GET_TEACHER_GROUP_CHAPTERS.REQUEST,
      payload: id,
    });
    dispatch({
      type: GET_TEACHER_GROUP_COURSE_PREVIEW.LOADING,
    });
    dispatch({
      type: GET_TEACHER_GROUP_COURSE_PREVIEW.REQUEST,
      payload: id,
    });
    dispatch({
      type: GET_TEACHER_GROUP_MEMBERS_INFO.REQUEST,
      payload: id,
    });
    setShowArchiveGroupConfirmation(archive === 'true');
    console.log('featureFlags', featureFlags);
  }, [id]);

  useEffect(() => {
    const selectedGroupId = get(group, 'group_info.id');
    if (error && error.selectedGroup && String(selectedGroupId) !== String(id)) {
      history.push(`/${localStorage.updatedLocale}/schedule`);
    }
  }, [error]);

  useEffect(() => {
    if (group && group.group_info && group.group_info.id && missingCurriculumOrSection.list.length === 0) {
      selectCurriculumModal(group.group_info.id);
    } else {
      selectCurriculumModal('');
    }
  }, [group, missingCurriculumOrSection]);

  useEffect(() => {
    if (createScheduleCompleted) {
      setShowCreateSchedule({
        show: false,
      });
      dispatch({
        type: GET_TEACHER_GROUP_SELECTED.REQUEST,
        payload: id,
      });
    }
  }, [createScheduleCompleted]);

  useEffect(() => {
    if (!archiveGroup.loading && (archiveGroup.archived || archiveGroup.success)) {
      handleMixpanelCurriculumEvents({
        type: archiveGroup.archived ? 'group_archived' : 'group_unarchived',
        data: {
          curriculum_state: curriculumExist ? (hasDraft ? 'draft' : 'published') : 'empty',
          no_of_chapters: chapters.length,
          group_start_date: get(group, ['group_info', 'published_at']),
          group_end_date: archiveGroup.archived ? Date.now() : get(group, ['archive', 'archive_date']),
        },
      });
    }
  }, [archiveGroup]);

  const handleGetTeacherGroupChapterTopics = (chapterId) => {
    dispatch({
      type: GET_TEACHER_GROUP_TOPICS.REQUEST,
      payload: {
        chapterId,
        groupId: id,
      },
    });
  };

  const handleTeacherGroupTopicOrRevisionMarkComplete = ({ topicId, chapterId }) => {
    handleMixpanelCurriculumEvents({
      type: 'topic_marked_complete',
      data: {
        curriculum_completion: curriculumList.percentCompleted,
        source: 'group_home',
      },
    });
    dispatch({
      type: POST_TEACHER_GROUP_TOPICS_MARK_COMPLETE.REQUEST,
      payload: { topicId, chapterId, groupId: id },
    });
  };

  const handleGroupInfoModalClose = () => {
    setShowGroupInfoModal(!showGroupInfoModal);
  };

  const getTeacherTagsGroupItemList = (queryParams, curriculumType, requestId) => {
    dispatch({
      type: GET_TEACHER_TAGS_GROUP.REQUEST,
      payload: { curriculumType, requestId, ...queryParams },
    });
  };

  const handleGetCurriculumOrSection = (queryParams) => {
    getTeacherTagsGroupItemList(queryParams, get(group, 'curriculum_tags.type'), missingCurriculumOrSection.key);
  };

  const handleGoToEditCurriculum = () => {
    history.push(`/${localStorage.updatedLocale}/curriculum/${id}`);
  };

  const handleClickCreateNewActivity = ({ chapter, topic }) => {
    setCreateActivityParams({ chapter, topic });
    toggleCreateActivityDialog(true);
  };

  const handleOpenCreateSchedule = (source) => {
    setShowCreateSchedule({
      show: !showCreateSchedule.show,
      source,
    });
  };

  const toggleArchiveModal = () => {
    // if (showGroupInfoModal) {
    //   setShowGroupInfoModal(showArchiveGroupConfirmation);
    // }
    setShowArchiveGroupConfirmation(!showArchiveGroupConfirmation);
  };

  const toggleUnArchiveModal = () => {
    // if (showGroupInfoModal) {
    //   setShowGroupInfoModal(showArchiveGroupConfirmation);
    // }
    setShowUnArchiveGroupConfirmation(!showUnArchiveGroupConfirmation);
  };

  // const handleOnCloseNotificationBar = () => {
  //   setHideNotificationBar(true);
  // };

  return (
    group && (
      <React.Fragment>
        {showCreateActivityDialog && (
          <CreateActivity
            source="group_home_activity"
            groupId={id}
            onContentClose={() => toggleCreateActivityDialog(false)}
            chapter={createActivityParams.chapter}
            topic={createActivityParams.topic}
          />
        )}
        <GroupHeader
          group={group}
          openGroupInfo={showGroupInfoModal}
          onClose={handleGroupInfoModalClose}
          handleMixpanelCurriculumEvents={handleMixpanelCurriculumEvents}
          handleOpenCreateSchedule={handleOpenCreateSchedule}
          showCreateSchedule={showCreateSchedule.show}
          curriculumExist={curriculumExist}
          isArchived={isArchived}
          toggleArchiveModal={isArchived ? toggleUnArchiveModal : toggleArchiveModal}
        />

        {showArchiveGroupConfirmation && !isEmpty(group.group_info) && (
          <ArchiveGroupModal
            archiveGroup={archiveGroup}
            groupId={group.group_info.id}
            toggleArchiveModal={toggleArchiveModal}
          />
        )}
        {showUnArchiveGroupConfirmation && (
          <UnArchiveGroupModal
            groupId={group.group_info.id}
            archiveGroup={archiveGroup}
            toggleUnArchiveModal={toggleUnArchiveModal}
          />
        )}

        {!hideNotificationBar && isScheduleNotSet && !isArchived && (
          <NotificationBar
            text={t('notification', 'groupScheduleNotSet')}
            // onClose={handleOnCloseNotificationBar}
          >
            <Button
              value={t('button', 'setDuration')}
              type="primary"
              className="text-capitalize"
              onClick={() => handleOpenCreateSchedule('notification-bar')}
            />
          </NotificationBar>
        )}
        {!hideNotificationBar && !isScheduleNotSet && isGroupDurationOver && (
          <NotificationBar
            text={() => (
              <>
                <span>{t('notification', 'groupDurationPassed').replace('{groupEndDate}', groupEndDateDuration)}</span>
              </>
            )}
            // onClose={handleOnCloseNotificationBar}
          >
            <Button
              value={t('button', 'archive')}
              type="primary"
              className="text-capitalize"
              onClick={toggleArchiveModal}
            />
            <Button
              value={t('button', 'increaseDuration')}
              type="secondary"
              className="text-capitalize"
              onClick={() => handleOpenCreateSchedule('notification-bar')}
            />
          </NotificationBar>
        )}
        {/* page-content */}
        <Row id="group-home-page" className="group-home-page container" gap="lg">
          <Column className="sidebar" gap="lg">
            <MembersWidget
              groupId={id}
              membersInfo={group.membersInfo}
              shareInfo={group.group_info}
              handleMixpanelCurriculumEvents={handleMixpanelCurriculumEvents}
              isArchived={isArchived}
            />
            <GroupStatusWidget
              isArchived={isArchived}
              toggleCreateActivityDialog={() => toggleCreateActivityDialog(true)}
              handleGoToEditCurriculum={handleGoToEditCurriculum}
              handleGroupInfoModalClose={handleGroupInfoModalClose}
              groupInfo={group.group_info}
              schedules={group.schedules}
              loading={group.loading}
              membersInfo={group.membersInfo}
              isCurriculumFilled={curriculumExist && !hasDraft}
              handleMixpanelCurriculumEvents={handleMixpanelCurriculumEvents}
              handleOpenCreateSchedule={() => handleOpenCreateSchedule('group_progress')}
              showCreateSchedule={showCreateSchedule.show}
            />
            <CurriculumWidget
              isRtl={isRtl}
              hasDraft={hasDraft}
              history={history}
              curriculumExist={curriculumExist}
              id={id}
              miscChapter={curriculumList.miscChapter}
              chapters={hasDraft ? [...chapters, ...curriculumList.chapters] : curriculumList.chapters}
              topics={curriculumList.topics}
              handleTeacherGroupTopicOrRevisionMarkComplete={handleTeacherGroupTopicOrRevisionMarkComplete}
              handleGetTeacherGroupChapterTopics={handleGetTeacherGroupChapterTopics}
              preview={curriculumList.preview}
              percentCompleted={curriculumList.percentCompleted}
              handleMixpanelCurriculumEvents={handleMixpanelCurriculumEvents}
              handleClickCreateNewActivity={handleClickCreateNewActivity}
              isArchived={isArchived}
            />
            {!isArchived && group.group_info && (
              <OptoutWidget
                groupId={id}
                groupInfo={group.group_info}
                updating={group.loading}
                handleMixpanelCurriculumEvents={handleMixpanelCurriculumEvents}
              />
            )}
          </Column>

          <Column className="content" gap="lg">
            {isArchived && (
              <ArchivedGroupMessage
                archivedDate={get(group, ['archive', 'archive_date'])}
                groupId={id}
                archiveGroup={archiveGroup}
              />
            )}

            {!isArchived && (
              <ActivityWidget
                groupId={id}
                toggleCreateActivityDialog={toggleCreateActivityDialog}
                curriculumExist={curriculumExist}
                loading={curriculumLoading}
                groupInfo={group.group_info}
                handleMixpanelCurriculumEvents={handleMixpanelCurriculumEvents}
                handleClickCreateNewActivity={handleClickCreateNewActivity}
              />
            )}
            <StudyGroupFeed
              groupId={id}
              history={history}
              location={history.location}
              groupDetails={group}
              showAllQuestions={() => history.push(`/${localStorage.updatedLocale}/groups/${id}/questions`)}
              match={history.match}
              viewComponentState="normal"
              isArchived={isArchived}
            />
          </Column>
          {showCurriculumModalFor === get(group, ['group_info', 'id']) && (
            <FillMissingValueModal
              folders={{ sections, curriculum }}
              fieldKey={missingCurriculumOrSection.key}
              handleGetCurriculumOrSection={handleGetCurriculumOrSection}
              group={group}
            />
          )}
        </Row>
        {showCreateSchedule.show && (
          <CreateSchedule
            source={showCreateSchedule.source}
            schedules={group.schedules}
            onClose={() => setShowCreateSchedule({ show: false })}
          />
        )}
        {/* Enable this after group info api return response for schedule <CreateSchedule /> */}
      </React.Fragment>
    )
  );
};

export default GroupDetail;

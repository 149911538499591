import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconBack, IconSend, IconEdit, IconDelete, IconClose, IconSave } from '@noon/quark';
import { Row, Button, MoreActions } from '@noon/atom';
import { useHistory } from 'react-router-dom';
import CreationHomeworkTip from './creationHomeworkTip';
import { translationText } from '../../helpers';
import {
  CLEAR_HOMEWORK_SPARTA_DETAILS,
  CLEAR_QUESTIONS,
  CLEAR_TOPIC_QUESTIONS,
  CLEAR_UPDATE_HOMEWORK,
  DELETE_HOMEWORK_SPARTA,
  SAVE_HOMEWORK_QUESTIONS_SPARTA,
} from '../../redux/constants';
import { checkIsMobile, returnDuplicatePropsForMixpanelAndKafka } from '../../helpers/prepareAssignment';
import { addToast, TOAST_TYPE } from '../Toast';
import Mixpanel from '../Mixpanel';
import { ConfirmationModal } from '../Modals';
import EditAssignmentModal from './editAssignmentModal';

const PrepareAssignmentHeader = ({ setPublishModalShown, noonText, match }) => {
  const [isTipShown, setIsTipShown] = useState(false);
  const [assignedAction, setAssignedAction] = useState('');
  const logger = useRef(LoggingManager.mount({ moduleName: 'homework' })).current;

  const { currentQuestions, hw_details, deleteHomework, is_questions_saved } = useSelector(
    (state) => state.toJS().homeworkCreation,
  );
  const { loggedUser: user } = useSelector((state) => state.toJS().user);
  const { selectedGroup } = useSelector((state) => state.toJS().groupsV2);
  const history = useHistory();
  const dispatch = useDispatch();

  const isRtl = document.body.dir === 'rtl';

  const mixpanelKafkaEvents = (eventName) => {
    const props = returnDuplicatePropsForMixpanelAndKafka(selectedGroup, user, hw_details);
    const data = {
      ...props,
      ...(eventName.kf === 'publish_intended' && {
        no_of_questions: currentQuestions.data ? currentQuestions.data.length : 0,
      }),
    };
    Mixpanel.track(eventName.mp, data);
    logger.track(eventName.kf, { homework: data });
  };

  useEffect(() => {
    if (is_questions_saved.success && is_questions_saved.justSaved) {
      dispatch({
        type: CLEAR_UPDATE_HOMEWORK,
      });
      dispatch({
        type: CLEAR_QUESTIONS,
      });
      dispatch({
        type: CLEAR_HOMEWORK_SPARTA_DETAILS,
      });
      dispatch({
        type: CLEAR_TOPIC_QUESTIONS,
      });
      history.push(`/${localStorage.updatedLocale}/groups/${hw_details.group_id}/content`);
      addToast(translationText(noonText, 'createHomework.successfullySaved'), TOAST_TYPE.SUCCESS);
    }
  }, [is_questions_saved]);
  useEffect(() => {
    if (assignedAction === 'exit') history.push(`/groups/${hw_details.group_id}/content`);
  }, [assignedAction]);
  useEffect(() => {
    if (!deleteHomework.loading && deleteHomework.success) {
      addToast(translationText(noonText, 'createHomework.deletedNotify', TOAST_TYPE.SUCCESS));
      history.push(`/groups/${hw_details.group_id}/content`);
    }
  }, [deleteHomework]);

  const showOrCloseTip = () => {
    if (currentQuestions.data.length <= 0) {
      setIsTipShown(!isTipShown);
    }
  };

  const checkIfCanShowPublishModal = () => {
    if (currentQuestions.data.length > 0) {
      setPublishModalShown();
      mixpanelKafkaEvents({ mp: 'homework_publish_intended', kf: 'publish_intended' });
    }
  };

  const saveHomework = () => {
    dispatch({
      type: SAVE_HOMEWORK_QUESTIONS_SPARTA.REQUEST,
      hw_id: hw_details.id,
      data: {
        question_ids: currentQuestions.data.map((question) => ({
          question_id: question.id,
          topic_id: question.topic_info.id,
          chapter_id: question.chapter_info.id,
        })),
      },
      justSaved: true,
    });
  };
  const getOptions = () => {
    const moreActionList = [
      {
        name: 'extend',
        icon: IconEdit,
        text: translationText(noonText, 'groups.statusEdit'),
      },
      {
        name: 'delete',
        icon: IconDelete,
        text: translationText(noonText, 'classroom.deleteSlideConfirm'),
      },
    ];
    if (checkIsMobile()) {
      moreActionList.push({
        name: 'exit',
        icon: IconClose,
        text: translationText(noonText, 'competition.exit'),
      });
    }
    return moreActionList;
  };
  const deleteAssignmentFn = () => {
    mixpanelKafkaEvents({ mp: 'homework_deleted', kf: 'deleted' });
    dispatch({
      type: DELETE_HOMEWORK_SPARTA.REQUEST,
      payload: hw_details.id,
    });
  };

  return (
    <header className="prepare-assignment--header">
      <div className="prepare-assignment--header--title">
        {checkIsMobile() && (
          <IconBack
            height="21px"
            width="12px"
            fill="#ffffff"
            stroke="#ffffff"
            rotate={isRtl ? 180 : 0}
            onClick={() => {
              mixpanelKafkaEvents({ mp: 'homework_save_exit_later', kf: 'save_exit_later' });
              history.push(`/${localStorage.updatedLocale}/groups/${hw_details.group_id}/content`);
            }}
          />
        )}
        <span>{hw_details.title}</span>
      </div>
      <Row align="center" className="prepare-assignment--header--buttons" gap>
        <MoreActions
          type="dots"
          position={isRtl ? 'right' : 'left'}
          className="activity"
          listActions={getOptions()}
          cardId={hw_details.id}
          onSelect={(actionName) => setAssignedAction(actionName)}
          vertical
          style={{ margin: checkIsMobile() && currentQuestions.data.length === 0 ? '0 8px' : '0px' }}
        />
        <Button
          // className="exit-button"
          type="secondary"
          onClick={() => {
            if (currentQuestions.data.length > 0) {
              mixpanelKafkaEvents({ mp: 'homework_save_exit_later', kf: 'save_exit_later' });
              saveHomework();
            } else {
              mixpanelKafkaEvents({ mp: 'homework_save_exit_later', kf: 'save_exit_later' });
              history.push(`/${localStorage.updatedLocale}/groups/${hw_details.group_id}/content`);
            }
          }}
          value={
            currentQuestions.data.length > 0
              ? translationText(noonText, 'createHomework.save')
              : translationText(noonText, 'createHomework.prepareLater')
          }
        />
        <button
          onMouseOver={showOrCloseTip}
          onMouseOut={showOrCloseTip}
          onClick={checkIfCanShowPublishModal}
          className={
            currentQuestions.data.length > 0 ? 'publish-button button-active' : 'publish-button button-disabled'
          }
        >
          {translationText(noonText, 'poll.publish')}
        </button>
        {currentQuestions.data.length > 0 && (
          <IconSave width="20px" height="20px" onClick={saveHomework} className="save-mobile-icon" />
        )}
        <IconSend
          height="20px"
          width="22px"
          fill={currentQuestions.data.length > 0 ? '#ffffff' : '#919a9e'}
          stroke={currentQuestions.data.length > 0 ? '#ffffff' : '#919a9e'}
          onClick={checkIfCanShowPublishModal}
        />
      </Row>
      {/* {checkIsMobile() && (
        <div className={isRtl ? 'icons_mobile icons_mobile--reverse' : 'icons_mobile'}>

        </div>
      )} */}
      {isTipShown && <CreationHomeworkTip text={translationText(noonText, 'createHomework.publishCondition')} />}
      {assignedAction === 'delete' && (
        <ConfirmationModal
          modalType="delete"
          successBtn={translationText(noonText, 'activity.modalYes')}
          closeBtn={translationText(noonText, 'activity.modalNo')}
          onClose={() => setAssignedAction('')}
          onSuccess={() => deleteAssignmentFn()}
          isLoading={deleteHomework.loading}
          text={translationText(noonText, 'teacherDashboard.deleteHomework')}
          subText={translationText(noonText, 'teacherDashboard.deleteConfirmation')}
        />
      )}
      {assignedAction === 'extend' && (
        <EditAssignmentModal setIsEditOpen={() => setAssignedAction('')} homework={hw_details} isChanging />
      )}
    </header>
  );
};

export default PrepareAssignmentHeader;

/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable no-return-assign */
/* eslint-disable react/destructuring-assignment */
import React, { PureComponent, createRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { cloneDeep } from 'lodash-es';

// Feature detection
// https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Improving_scrolling_performance_with_passive_listeners
let passiveSupported = false;

try {
  window.addEventListener(
    'test',
    null,
    Object.defineProperty({}, 'passive', {
      get: () => {
        passiveSupported = true;
        return true;
      },
    }),
  );
} catch (err) {} // eslint-disable-line no-empty

function getClientPos(e) {
  let pageX;
  let pageY;

  if (e.touches) {
    [{ pageX, pageY }] = e.touches;
  } else {
    ({ pageX, pageY } = e);
  }

  return {
    x: pageX,
    y: pageY,
  };
}

function clamp(num, min, max) {
  return Math.min(Math.max(num, min), max);
}

function isCropValid(crop) {
  return crop && !isNaN(crop.width) && !isNaN(crop.height);
}

function inverseOrd(ord) {
  if (ord === 'n') return 's';
  if (ord === 'ne') return 'sw';
  if (ord === 'e') return 'w';
  if (ord === 'se') return 'nw';
  if (ord === 's') return 'n';
  if (ord === 'sw') return 'ne';
  if (ord === 'w') return 'e';
  if (ord === 'nw') return 'se';
  return ord;
}

function makeAspectCrop(crop, imageWidth, imageHeight) {
  if (isNaN(crop.aspect)) {
    console.warn(
      '`crop.aspect` should be a number in order to make an aspect crop',
      crop,
    );
    return crop;
  }

  const completeCrop = {
    unit: 'px',
    x: 0,
    y: 0,
    ...crop,
  };

  if (crop.width) {
    completeCrop.height = completeCrop.width / crop.aspect;
  }

  if (crop.height) {
    completeCrop.width = completeCrop.height * crop.aspect;
  }

  if (completeCrop.y + completeCrop.height > imageHeight) {
    completeCrop.height = imageHeight - completeCrop.y;
    completeCrop.width = completeCrop.height * crop.aspect;
  }

  if (completeCrop.x + completeCrop.width > imageWidth) {
    completeCrop.width = imageWidth - completeCrop.x;
    completeCrop.height = completeCrop.width / crop.aspect;
  }

  return completeCrop;
}

function convertToPercentCrop(crop, imageWidth, imageHeight) {
  if (crop.unit === '%') {
    return crop;
  }

  return {
    unit: '%',
    aspect: crop.aspect,
    x: (crop.x / imageWidth) * 100,
    y: (crop.y / imageHeight) * 100,
    width: (crop.width / imageWidth) * 100,
    height: (crop.height / imageHeight) * 100,
  };
}

function convertToPixelCrop(crop, imageWidth, imageHeight) {
  if (!crop.unit) {
    return { ...crop, unit: 'px' };
  }

  if (crop.unit === 'px') {
    return crop;
  }

  return {
    unit: 'px',
    aspect: crop.aspect,
    x: (crop.x * imageWidth) / 100,
    y: (crop.y * imageHeight) / 100,
    width: (crop.width * imageWidth) / 100,
    height: (crop.height * imageHeight) / 100,
  };
}

// function resolveCrop(pixelCrop, imageWidth, imageHeight) {
//   if (pixelCrop.aspect && (!pixelCrop.width || !pixelCrop.height)) {
//     return makeAspectCrop(pixelCrop, imageWidth, imageHeight);
//   }

//   return pixelCrop;
// }

function containCrop(prevCrop, crop, imageWidth, imageHeight) {
  const pixelCrop = convertToPixelCrop(crop, imageWidth, imageHeight);
  const prevPixelCrop = convertToPixelCrop(prevCrop, imageWidth, imageHeight);
  const contained = { ...pixelCrop };

  // Non-aspects are simple
  if (!pixelCrop.aspect) {
    if (pixelCrop.x < 0) {
      contained.x = 0;
      contained.width += pixelCrop.x;
    } else if (pixelCrop.x + pixelCrop.width > imageWidth) {
      contained.width = imageWidth - pixelCrop.x;
    }

    if (pixelCrop.y + pixelCrop.height > imageHeight) {
      contained.height = imageHeight - pixelCrop.y;
    }

    return contained;
  }

  let adjustedForX = false;

  if (pixelCrop.x < 0) {
    contained.x = 0;
    contained.width += pixelCrop.x;
    contained.height = contained.width / pixelCrop.aspect;
    adjustedForX = true;
  } else if (pixelCrop.x + pixelCrop.width > imageWidth) {
    contained.width = imageWidth - pixelCrop.x;
    contained.height = contained.width / pixelCrop.aspect;
    adjustedForX = true;
  }

  // If sizing in up direction we need to pin Y at the point it
  // would be at the boundary.
  if (adjustedForX && prevPixelCrop.y > contained.y) {
    contained.y = pixelCrop.y + (pixelCrop.height - contained.height);
  }

  let adjustedForY = false;

  if (contained.y + contained.height > imageHeight) {
    contained.height = imageHeight - pixelCrop.y;
    contained.width = contained.height * pixelCrop.aspect;
    adjustedForY = true;
  }

  // If sizing in left direction we need to pin X at the point it
  // would be at the boundary.
  if (adjustedForY && prevPixelCrop.x > contained.x) {
    contained.x = pixelCrop.x + (pixelCrop.width - contained.width);
  }

  return contained;
}

class QuestionBuilderComponent extends PureComponent {
  window = typeof window !== 'undefined' ? window : {};

  document = typeof document !== 'undefined' ? document : {};

  constructor(props) {
    super(props);
    this.state = {};
    this.cropIndex = -1;
    this.componentRef = createRef();
  }

  keysDown = new Set();

  componentDidMount() {

    if (this.componentRef.current.addEventListener) {
      const options = passiveSupported ? { passive: false } : false;

      this.componentRef.current.addEventListener(
        'mousemove',
        this.onDocMouseTouchMove,
        options,
      );
      this.componentRef.current.addEventListener(
        'touchmove',
        this.onDocMouseTouchMove,
        options,
      );

      this.componentRef.current.addEventListener(
        'mouseup',
        this.onDocMouseTouchEnd,
        options,
      );
      this.componentRef.current.addEventListener(
        'touchend',
        this.onDocMouseTouchEnd,
        options,
      );
      this.componentRef.current.addEventListener(
        'touchcancel',
        this.onDocMouseTouchEnd,
        options,
      );

      this.componentRef.current.addEventListener('medialoaded', this.onMediaLoaded);
    }

    if (this.componentRef.current) {
      const { onScroll, scrollHeight, scrollLeft } = this.props;
      const isRTL = document.body.dir === 'rtl';
      this.componentRef.current.scrollTo(scrollLeft || isRTL ? this.componentRef.current.scrollWidth : 0, scrollHeight);
      this.componentRef.current.onscroll = (e) => {
        onScroll(e);
      };
    }

    this.forceUpdate();

  }

  componentWillUnmount() {
    if (this.componentRef.current.removeEventListener) {
      this.componentRef.current.removeEventListener('mousemove', this.onDocMouseTouchMove);
      this.componentRef.current.removeEventListener('touchmove', this.onDocMouseTouchMove);

      this.componentRef.current.removeEventListener('mouseup', this.onDocMouseTouchEnd);
      this.componentRef.current.removeEventListener('touchend', this.onDocMouseTouchEnd);
      this.componentRef.current.removeEventListener('touchcancel', this.onDocMouseTouchEnd);

      this.componentRef.current.removeEventListener('medialoaded', this.onMediaLoaded);
    }
  }

  componentDidUpdate(prevProps) {

    if (this.cropIndex === -1 && this.props.crops.length > 0) {
      const { crops, onChange } = this.props;
      const latestCrop = crops[crops.length - 1];
      const { width, height } = this.mediaDimensions;
      const pixelCrop = convertToPixelCrop(latestCrop, width, height);
      this.cropIndex = crops.length - 1;
      onChange(
        pixelCrop,
        this.cropIndex,
      );
      this.forceUpdate();
    }

    if (prevProps.crops.length !== this.props.crops.length) {
      this.forceUpdate();
    }

    if (!this.props.crops.length) {
      this.cropIndex = -1;
    }

    // if (
    //   this.imageRef
    //   && prevProps.crop !== crop
    //   && crop.aspect
    //   && ((crop.width && !crop.height) || (!crop.width && crop.height))
    // ) {
    //
    //   const { width, height } = this.imageRef;
    //   const newCrop = this.makeNewCrop();
    //   const completedCrop = makeAspectCrop(newCrop, width, height);

    //   const pixelCrop = convertToPixelCrop(completedCrop, width, height);
    //   const percentCrop = convertToPercentCrop(completedCrop, width, height);
    //   this.props.onChange(pixelCrop, percentCrop, this.cropIndex);
    //   this.props.onComplete(pixelCrop, percentCrop, this.cropIndex);
    // }
  }

  onCropMouseTouchDown = (e, _crop, cropIndex) => {

    const { disabled, onSelected } = this.props;
    const crop = _crop;
    const { width, height } = this.mediaDimensions;
    const pixelCrop = convertToPixelCrop(crop, width, height);

    if (disabled) {
      return;
    }
    e.preventDefault(); // Stop drag selection.

    const clientPos = getClientPos(e);

    // Focus for detecting keypress.
    if (this.componentRef.current.setActive) {
      this.componentRef.current.setActive({ preventScroll: true }); // IE/Edge #289
    } else {
      this.componentRef.current.focus({ preventScroll: true }); // All other browsers
    }

    const { ord } = e.target.dataset;
    const xInversed = ord === 'nw' || ord === 'w' || ord === 'sw';
    const yInversed = ord === 'nw' || ord === 'n' || ord === 'ne';

    let cropOffset;

    if (pixelCrop.aspect) {
      cropOffset = this.getElementOffset(e);
    }

    crop.evData = {

      clientStartX: clientPos.x,
      clientStartY: clientPos.y,
      cropStartWidth: pixelCrop.width,
      cropStartHeight: pixelCrop.height,
      cropStartX: xInversed ? pixelCrop.x + pixelCrop.width : pixelCrop.x,
      cropStartY: yInversed ? pixelCrop.y + pixelCrop.height : pixelCrop.y,
      xInversed,
      yInversed,
      xCrossOver: xInversed,
      yCrossOver: yInversed,
      startXCrossOver: xInversed,
      startYCrossOver: yInversed,
      isResize: e.target.dataset.ord,
      ord,
      cropOffset,
    };

    this.mouseDownOnCrop = true;
    this.setState({ cropIsActive: true });
    onSelected(
      pixelCrop,
      cropIndex,
    );

  };

  onComponentMouseTouchDown = (e) => {
    const { crop, disabled, locked, keepSelection, onStart, crops, showAddon } = this.props;

    if (showAddon) {
      return;
    }
    const componentEl = this.mediaWrapperRef.firstChild;

    if (e.target !== componentEl || !componentEl.contains(e.target)) {
      return;
    }

    if (disabled || locked || (keepSelection && isCropValid(crop))) {
      return;
    }

    e.preventDefault(); // Stop drag selection.

    const clientPos = getClientPos(e);

    // Focus for detecting keypress.
    if (this.componentRef.current.setActive) {
      this.componentRef.current.setActive({ preventScroll: true }); // IE/Edge #289
    } else {
      this.componentRef.current.focus({ preventScroll: true }); // All other browsers
    }

    const mediaOffset = this.getElementOffset(this.mediaWrapperRef);
    const x = clientPos.x - mediaOffset.left;
    const y = clientPos.y - mediaOffset.top;

    const nextCrop = {
      unit: 'px',
      aspect: crop ? crop.aspect : undefined,
      x,
      y,
      width: 0,
      height: 0,
    };

    this.cropIndex = crops.length;
    nextCrop.evData = {
      clientStartX: clientPos.x,
      clientStartY: clientPos.y,
      cropStartWidth: nextCrop.width,
      cropStartHeight: nextCrop.height,
      cropStartX: nextCrop.x,
      cropStartY: nextCrop.y,
      xInversed: false,
      yInversed: false,
      xCrossOver: false,
      yCrossOver: false,
      startXCrossOver: false,
      startYCrossOver: false,
      isResize: true,
      ord: 'nw',
    };

    this.mouseDownOnCrop = true;

    const { width, height } = this.mediaDimensions;

    onStart(
      convertToPixelCrop(nextCrop, width, height),
      this.cropIndex,
    );

    this.setState({ cropIsActive: true, newCropIsBeingDrawn: true });

  };

  onDocMouseTouchMove = (e) => {

    const { crop, disabled, onChange, onDragStart, showAddon } = this.props;
    if (showAddon) {
      return;
    }

    if (disabled) {
      return;
    }

    if (!this.mouseDownOnCrop) {
      return;
    }

    e.preventDefault(); // Stop drag selection.
    if (!this.dragStarted) {
      this.dragStarted = true;
      onDragStart(e);
    }

    const { evData } = crop;
    const _evData = evData;
    const clientPos = getClientPos(e);

    if (_evData.isResize && _evData.cropOffset) {
      clientPos.y = this.straightenYPath(clientPos.x);
    }

    _evData.xDiff = clientPos.x - _evData.clientStartX;
    _evData.yDiff = clientPos.y - _evData.clientStartY;

    let nextCrop = crop;

    nextCrop.evData = _evData;

    if (_evData.isResize) {
      nextCrop = this.resizeCrop(crop);
    } else {
      nextCrop = this.dragCrop(crop);
    }

    const { width, height } = this.mediaDimensions;
    onChange(
      convertToPixelCrop(nextCrop, width, height),
      this.cropIndex,
    );

  };

  onComponentKeyDown = (e) => {
    const { crop, disabled, onChange, showAddon } = this.props;

    if (showAddon) {
      return;
    }

    if (disabled) {
      return;
    }

    this.keysDown.add(e.key);
    let nudged = false;

    if (!isCropValid(crop)) {
      return;
    }

    const nextCrop = this.makeNewCrop();
    const ctrlCmdPressed = navigator.platform.match('Mac')
      ? e.metaKey
      : e.ctrlKey;
    const nudgeStep = ctrlCmdPressed
      ? QuestionBuilderComponent.nudgeStepLarge
      : e.shiftKey
        ? QuestionBuilderComponent.nudgeStepMedium
        : QuestionBuilderComponent.nudgeStep;

    if (this.keysDown.has('ArrowLeft')) {
      nextCrop.x -= nudgeStep;
      nudged = true;
    }

    if (this.keysDown.has('ArrowRight')) {
      nextCrop.x += nudgeStep;
      nudged = true;
    }

    if (this.keysDown.has('ArrowUp')) {
      nextCrop.y -= nudgeStep;
      nudged = true;
    }

    if (this.keysDown.has('ArrowDown')) {
      nextCrop.y += nudgeStep;
      nudged = true;
    }

    if (nudged) {
      e.preventDefault(); // Stop drag selection.
      const { width, height } = this.mediaDimensions;

      nextCrop.x = clamp(nextCrop.x, 0, width - nextCrop.width);
      nextCrop.y = clamp(nextCrop.y, 0, height - nextCrop.height);

      const pixelCrop = convertToPixelCrop(nextCrop, width, height);

      onChange(pixelCrop, this.cropIndex);
    }
  };

  onComponentKeyUp = (e) => {
    this.keysDown.delete(e.key);
  };

  onDocMouseTouchEnd = () => {
    const { disabled, onComplete } = this.props;

    if (disabled) {
      return;
    }

    if (this.mouseDownOnCrop) {
      this.mouseDownOnCrop = false;
      this.dragStarted = false;

      this.setState({ cropIsActive: false, newCropIsBeingDrawn: false });
    }
    onComplete(this.cropIndex);
  };

  // When the image is loaded or when a custom component via `renderComponent` prop fires
  // a custom "medialoaded" event.
  // createNewCrop() {
  //   const { width, height } = this.mediaDimensions;
  //   const crop = this.makeNewCrop();
  //   const resolvedCrop = resolveCrop(crop, width, height);
  //   const pixelCrop = convertToPixelCrop(resolvedCrop, width, height);
  //   const percentCrop = convertToPercentCrop(resolvedCrop, width, height);
  //   // push new crop into state
  //   this.setState({
  //     crops: [...this.state.crops, crop],
  //   });
  //   return { pixelCrop, percentCrop };
  // }

  // Custom components (using `renderComponent`) should fire a custom event
  // called "medialoaded" when they are loaded.
  onMediaLoaded = () => {
    // const { onComplete, onChange } = this.props;
    // const { pixelCrop, percentCrop } = this.createNewCrop();
    // onChange(pixelCrop, percentCrop);
    // onComplete(pixelCrop, percentCrop);
  };

  onImageLoad(imageEle) {
    const { onImageLoaded } = this.props;

    // Return false from onImageLoaded if you set the crop with setState in there as otherwise
    // the subsequent onChange + onComplete will not have your updated crop.
    // const res = onImageLoaded(image);

    // if (res !== false) {
    //   const { pixelCrop, percentCrop } = this.createNewCrop();
    //   onChange(pixelCrop, percentCrop);
    //   onComplete(pixelCrop, percentCrop);
    // }

    return onImageLoaded(imageEle);
  }

  get mediaDimensions() {
    const { clientWidth, clientHeight } = this.mediaWrapperRef;
    return { width: clientWidth, height: clientHeight };
  }

  getDocumentOffset() {
    const { clientTop = 0, clientLeft = 0 } = this.document.documentElement || {};
    return { clientTop, clientLeft };
  }

  getWindowOffset() {
    const { pageYOffset = 0, pageXOffset = 0 } = this.window;
    return { pageYOffset, pageXOffset };
  }

  getElementOffset(el) {
    const rect = el.getBoundingClientRect();
    const doc = this.getDocumentOffset();
    const win = this.getWindowOffset();

    const top = rect.top + win.pageYOffset - doc.clientTop;
    const left = rect.left + win.pageXOffset - doc.clientLeft;

    return { top, left };
  }

  getCropStyle(_crop, cropIndex) {
    const crop = this.getCrop(_crop,
      _crop ? _crop.unit : 'px',
    );

    let cropType;

    switch (true) {
      case crop.isQuestion:
        cropType = 'question'; break;
      case crop.isAnswer:
        cropType = 'answer'; break;
      case crop.isCorrectAnswer:
        cropType = 'correct-answer'; break;
      default:
        cropType = ''; break;

    }

    return [{
      top: `${crop.y}${crop.unit}`,
      left: `${crop.x}${crop.unit}`,
      width: `${crop.width}${crop.unit}`,
      height: `${crop.height}${crop.unit}`,
      zIndex: cropIndex + 1,
    }, cropType];
  }

  getAddonStyle(_crop, cropIndex) {
    // TODO: improve alignment
    if (!this.componentRef.current) return {};
    const crop = this.getCrop(_crop,
      _crop ? _crop.unit : 'px',
    );

    const height = this.componentRef.current.clientHeight;
    const scrollAmount = this.componentRef.current.scrollTop;
    const addonPosY = (crop.y + crop.height + scrollAmount <= (height / 2) - scrollAmount) ? (crop.y - scrollAmount) + crop.height + 10 : (crop.y - scrollAmount) - 240;
    // if (!isRTL) {
    const addonPosX = crop.width < 244 ? crop.x - (244 / 2 - crop.width / 2) : crop.x + (crop.width / 2 - 244 / 2);
    const style = {
      marginLeft: `${addonPosX}px`,
      marginTop: `${addonPosY}px`,
      zIndex: 2 + cropIndex,
      position: 'absolute',
    };
    // } else {

    //   // TODO : figure out how to center in RTL
    //   const compoentWidth = this.componentRef.current.clientWidth;
    //   addonPosX = compoentWidth - crop.x - crop.width;
    //   addonPosX += 30;

    //   style = {
    //     right: `${addonPosX}px`,
    //     marginTop: `${addonPosY}px`,
    //     zIndex: 2 + cropIndex,
    //     position: 'absolute',
    //   };
    // }

    return style;

  }

  getNewSize(crop) {
    const { minWidth, maxWidth, minHeight, maxHeight } = this.props;
    const { evData } = crop;
    const _evData = evData;
    const { width, height } = this.mediaDimensions;

    // New width.
    let newWidth = _evData.cropStartWidth + _evData.xDiff;

    if (_evData.xCrossOver) {
      newWidth = Math.abs(newWidth);
    }

    newWidth = clamp(newWidth, minWidth, maxWidth || width);

    // New height.
    let newHeight;

    if (crop.aspect) {
      newHeight = newWidth / crop.aspect;
    } else {
      newHeight = _evData.cropStartHeight + _evData.yDiff;
    }

    if (_evData.yCrossOver) {
      // Cap if polarity is inversed and the height fills the y space.
      newHeight = Math.min(Math.abs(newHeight), _evData.cropStartY);
    }

    newHeight = clamp(newHeight, minHeight, maxHeight || height);

    if (crop.aspect) {
      newWidth = clamp(newHeight * crop.aspect, 0, width);
    }

    return {
      width: newWidth,
      height: newHeight,
    };
  }

  dragCrop(crop) {
    const nextCrop = cloneDeep(this.getCrop(crop));
    const { evData } = nextCrop;
    const _evData = evData;
    const { width, height } = this.mediaDimensions;

    nextCrop.x = clamp(
      _evData.cropStartX + _evData.xDiff,
      0,
      width - nextCrop.width,
    );
    nextCrop.y = clamp(
      _evData.cropStartY + _evData.yDiff,
      0,
      height - nextCrop.height,
    );

    return nextCrop;
  }

  resizeCrop(crop) {

    const nextCrop = cloneDeep(this.getCrop(crop));
    const { evData } = nextCrop;
    const _evData = evData;
    const { ord } = _evData;

    // On the inverse change the diff so it's the same and
    // the same algo applies.
    if (_evData.xInversed) {
      _evData.xDiff -= _evData.cropStartWidth * 2;
      _evData.xDiffPc -= _evData.cropStartWidth * 2;
    }
    if (_evData.yInversed) {
      _evData.yDiff -= _evData.cropStartHeight * 2;
      _evData.yDiffPc -= _evData.cropStartHeight * 2;
    }

    // New size.
    const newSize = this.getNewSize(nextCrop);

    // Adjust x/y to give illusion of 'staticness' as width/height is increased
    // when polarity is inversed.
    let newX = _evData.cropStartX;
    let newY = _evData.cropStartY;

    if (_evData.xCrossOver) {
      newX = nextCrop.x + (nextCrop.width - newSize.width);
    }

    if (_evData.yCrossOver) {
      // This not only removes the little "shake" when inverting at a diagonal, but for some
      // reason y was way off at fast speeds moving sw->ne with fixed aspect only, I couldn't
      // figure out why.
      if (_evData.lastYCrossover === false) {
        newY = nextCrop.y - newSize.height;
      } else {
        newY = nextCrop.y + (nextCrop.height - newSize.height);
      }
    }

    const { width, height } = this.mediaDimensions;
    const containedCrop = containCrop(
      crop,
      {
        unit: nextCrop.unit,
        x: newX,
        y: newY,
        width: newSize.width,
        height: newSize.height,
        aspect: nextCrop.aspect,
      },
      width,
      height,
    );

    // Apply x/y/width/height changes depending on ordinate (fixed aspect always applies both).
    if (nextCrop.aspect || QuestionBuilderComponent.xyOrds.indexOf(ord) > -1) {
      nextCrop.x = containedCrop.x;
      nextCrop.y = containedCrop.y;
      nextCrop.width = containedCrop.width;
      nextCrop.height = containedCrop.height;
    } else if (QuestionBuilderComponent.xOrds.indexOf(ord) > -1) {
      nextCrop.x = containedCrop.x;
      nextCrop.width = containedCrop.width;
    } else if (QuestionBuilderComponent.yOrds.indexOf(ord) > -1) {
      nextCrop.y = containedCrop.y;
      nextCrop.height = containedCrop.height;
    }

    _evData.lastYCrossover = _evData.yCrossOver;
    const updatedEvData = this.crossOverCheck(_evData);
    nextCrop.evData = updatedEvData;
    return nextCrop;
  }

  straightenYPath(clientX) {
    // TODO: remove this
    const { evData } = this;
    const { ord } = evData;
    const { cropOffset, cropStartWidth, cropStartHeight } = evData;
    let k;
    let d;

    if (ord === 'nw' || ord === 'se') {
      k = cropStartHeight / cropStartWidth;
      d = cropOffset.top - cropOffset.left * k;
    } else {
      k = -cropStartHeight / cropStartWidth;
      d = cropOffset.top + (cropStartHeight - cropOffset.left * k);
    }

    return k * clientX + d;
  }

  createCropSelection(crop, cropIndex) {
    const {
      disabled,
      locked,
      renderSelectionAddon,
      showAddon,
      addonContainer,
      activeCrop,
      ruleOfThirds,
      // crop
    } = this.props;
    const [style, cropType] = this.getCropStyle(crop, cropIndex);
    const addonPositionStyle = this.getAddonStyle(crop, cropIndex);
    style.zIndex = activeCrop === cropIndex ? '10' : '1';
    const handleCropMouseDown = (evt) => {

      this.cropIndex = cropIndex;
      this.onCropMouseTouchDown(evt, crop, cropIndex);
    };
    const cropClass = this.cropIndex === cropIndex ? `QuestionBuilderComponent__crop-selection active-crop ${cropType}` : `QuestionBuilderComponent__crop-selection ${cropType}`;
    return (
      <div
        key={cropIndex}
        ref={(r) => (this.cropSelectRef = r)}
        style={style}
        className={cropClass}
        onMouseDown={(e) => handleCropMouseDown(e)}
        onTouchStart={(e) => handleCropMouseDown(e)}
      >
        {!disabled && !locked && (
          <div className="QuestionBuilderComponent__drag-elements">
            <div className="QuestionBuilderComponent__drag-bar ord-n" data-ord="n" onMouseDown={(e) => handleCropMouseDown(e)} />
            <div className="QuestionBuilderComponent__drag-bar ord-e" data-ord="e" onMouseDown={(e) => handleCropMouseDown(e)} />
            <div className="QuestionBuilderComponent__drag-bar ord-s" data-ord="s" onMouseDown={(e) => handleCropMouseDown(e)} />
            <div className="QuestionBuilderComponent__drag-bar ord-w" data-ord="w" onMouseDown={(e) => handleCropMouseDown(e)} />

            <div className="QuestionBuilderComponent__drag-handle ord-nw" data-ord="nw" onMouseDown={(e) => handleCropMouseDown(e)} />
            <div className="QuestionBuilderComponent__drag-handle ord-n" data-ord="n" onMouseDown={(e) => handleCropMouseDown(e)} />
            <div className="QuestionBuilderComponent__drag-handle ord-ne" data-ord="ne" onMouseDown={(e) => handleCropMouseDown(e)} />
            <div className="QuestionBuilderComponent__drag-handle ord-e" data-ord="e" onMouseDown={(e) => handleCropMouseDown(e)} />
            <div className="QuestionBuilderComponent__drag-handle ord-se" data-ord="se" onMouseDown={(e) => handleCropMouseDown(e)} />
            <div className="QuestionBuilderComponent__drag-handle ord-s" data-ord="s" onMouseDown={(e) => handleCropMouseDown(e)} />
            <div className="QuestionBuilderComponent__drag-handle ord-sw" data-ord="sw" onMouseDown={(e) => handleCropMouseDown(e)} />
            <div className="QuestionBuilderComponent__drag-handle ord-w" data-ord="w" onMouseDown={(e) => handleCropMouseDown(e)} />
          </div>
        )}
        {showAddon && renderSelectionAddon && isCropValid(crop) && ReactDOM.createPortal(
          <div
            style={addonPositionStyle}
            className="QuestionBuilderComponent__selection-addon"
            onMouseDown={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
          >
            {renderSelectionAddon({ crop, cropIndex })}
          </div>,
          addonContainer,
        )}
        {ruleOfThirds && (
          <>
            <div className="QuestionBuilderComponent__rule-of-thirds-hz" />
            <div className="QuestionBuilderComponent__rule-of-thirds-vt" />
          </>
        )}
      </div>
    );
  }

  makeNewCrop(unit = 'px') {
    const crop = { ...QuestionBuilderComponent.defaultCrop, ...this.props.crop };
    const { width, height } = this.mediaDimensions;

    return unit === 'px'
      ? convertToPixelCrop(crop, width, height)
      : convertToPercentCrop(crop, width, height);
  }

  getCrop(_crop, unit = 'px') {
    const { width, height } = this.mediaDimensions;

    return unit === 'px'
      ? convertToPixelCrop(_crop, width, height)
      : convertToPercentCrop(_crop, width, height);
  }

  crossOverCheck(_evData) {
    const evData = cloneDeep(_evData);
    const { minWidth, minHeight } = this.props;

    if (
      !minWidth
      && ((!evData.xCrossOver
        && -Math.abs(evData.cropStartWidth) - evData.xDiff >= 0)
        || (evData.xCrossOver
          && -Math.abs(evData.cropStartWidth) - evData.xDiff <= 0))
    ) {
      evData.xCrossOver = !evData.xCrossOver;
    }

    if (
      !minHeight
      && ((!evData.yCrossOver
        && -Math.abs(evData.cropStartHeight) - evData.yDiff >= 0)
        || (evData.yCrossOver
          && -Math.abs(evData.cropStartHeight) - evData.yDiff <= 0))
    ) {
      evData.yCrossOver = !evData.yCrossOver;
    }

    const swapXOrd = evData.xCrossOver !== evData.startXCrossOver;
    const swapYOrd = evData.yCrossOver !== evData.startYCrossOver;

    evData.inversedXOrd = swapXOrd ? inverseOrd(evData.ord) : false;
    evData.inversedYOrd = swapYOrd ? inverseOrd(evData.ord) : false;
    return evData;
  }

  handleImageLoad(image) {
    const { onImageLoaded } = this.props;
    onImageLoaded(image);
  }

  render() {
    const {
      className,
      crossorigin,
      locked,
      imageAlt,
      onImageError,
      renderComponent,
      src,
      style,
    } = this.props;

    const { cropIsActive, newCropIsBeingDrawn } = this.state;
    const { crops, showAddon } = this.props;

    const cropSelection = (crop, cropIndex) => {

      const selection = (isCropValid(crop) && this.componentRef.current
        ? this.createCropSelection(crop, cropIndex)
        : null);

      return selection;
    };

    const componentClasses = clsx('QuestionBuilderComponent', className, {
      'QuestionBuilderComponent--active': cropIsActive,
      'QuestionBuilderComponent--disabled': showAddon,
      'QuestionBuilderComponent--locked': locked,
      'QuestionBuilderComponent--new-crop': newCropIsBeingDrawn,
      // "QuestionBuilderComponent--fixed-aspect": crop && crop.aspect,
      // "QuestionBuilderComponent--circular-crop": crop && circularCrop,
      // "QuestionBuilderComponent--rule-of-thirds": crop && ruleOfThirds,
      // "QuestionBuilderComponent--invisible-crop":
      //   !this.dragStarted && crop && !crop.width && !crop.height
    });

    return (
      <div
        dir="ltr"
        ref={this.componentRef}
        className={componentClasses}
        style={style}
        onTouchStart={this.onComponentMouseTouchDown}
        onMouseDown={this.onComponentMouseTouchDown}
        tabIndex="0"
        onKeyDown={this.onComponentKeyDown}
        onKeyUp={this.onComponentKeyUp}
      >
        <div
          ref={(n) => {
            this.mediaWrapperRef = n;
          }}
        >
          {renderComponent || (
            <img
              ref={(r) => (this.imageRef = r)}
              crossOrigin={crossorigin}
              className="QuestionBuilderComponent__image"
              src={src}
              onLoad={(e) => this.handleImageLoad(e.target)}
              onError={onImageError}
              alt={imageAlt}
            />
          )}
        </div>
        {crops && crops.map((crop, index) => cropSelection(crop, index)) }
      </div>
    );
  }
}

QuestionBuilderComponent.xOrds = ['e', 'w'];
QuestionBuilderComponent.yOrds = ['n', 's'];
QuestionBuilderComponent.xyOrds = ['nw', 'ne', 'se', 'sw'];

QuestionBuilderComponent.nudgeStep = 1;
QuestionBuilderComponent.nudgeStepMedium = 10;
QuestionBuilderComponent.nudgeStepLarge = 100;

QuestionBuilderComponent.defaultCrop = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  unit: 'px',
};

QuestionBuilderComponent.propTypes = {
  className: PropTypes.string,
  //   circularCrop: PropTypes.bool,
  crop: PropTypes.shape({
    aspect: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    unit: PropTypes.oneOf(['px', '%']),
    evData: PropTypes.shape({
      clientStartX: PropTypes.number,
      clientStartY: PropTypes.number,
      cropStartWidth: PropTypes.number,
      cropStartHeight: PropTypes.number,
      cropStartX: PropTypes.number,
      cropStartY: PropTypes.number,
      xInversed: false,
      yInversed: false,
      xCrossOver: false,
      yCrossOver: false,
      startXCrossOver: false,
      startYCrossOver: false,
      isResize: true,
      ord: PropTypes.string,
      xDiff: PropTypes.number,
      yDiff: PropTypes.number,
      inversedXOrd: PropTypes.number,
      inversedYOrd: PropTypes.number,
    }),
  }),
  crops: PropTypes.arrayOf(PropTypes.shape({
    aspect: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    unit: PropTypes.oneOf(['px', '%']),
    evData: PropTypes.shape({
      clientStartX: PropTypes.number,
      clientStartY: PropTypes.number,
      cropStartWidth: PropTypes.number,
      cropStartHeight: PropTypes.number,
      cropStartX: PropTypes.number,
      cropStartY: PropTypes.number,
      xInversed: false,
      yInversed: false,
      xCrossOver: false,
      yCrossOver: false,
      startXCrossOver: false,
      startYCrossOver: false,
      isResize: true,
      ord: PropTypes.string,
      xDiff: PropTypes.number,
      yDiff: PropTypes.number,
      inversedXOrd: PropTypes.number,
      inversedYOrd: PropTypes.number,
    }),
  })),
  crossorigin: PropTypes.string,
  disabled: PropTypes.bool,
  locked: PropTypes.bool,
  imageAlt: PropTypes.string,
  imageStyle: PropTypes.shape({}),
  keepSelection: PropTypes.bool,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  onStart: PropTypes.func,
  onSelected: PropTypes.func,
  onChange: PropTypes.func,
  onImageError: PropTypes.func,
  onComplete: PropTypes.func,
  onImageLoaded: PropTypes.func,
  onDragStart: PropTypes.func,
  src: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  renderComponent: PropTypes.node,
  renderSelectionAddon: PropTypes.func,
  ruleOfThirds: PropTypes.bool,
  showAddon: PropTypes.bool,
  activeCrop: PropTypes.number,
  addonContainer: PropTypes.instanceOf(Element).isRequired,
  scrollLeft: PropTypes.number.isRequired,
  scrollHeight: PropTypes.number.isRequired,
  onScroll: PropTypes.func.isRequired,
};

QuestionBuilderComponent.defaultProps = {
//   circularCrop: false,
  className: undefined,
  crop: undefined,
  crossorigin: undefined,
  disabled: false,
  locked: false,
  imageAlt: '',
  maxWidth: undefined,
  maxHeight: undefined,
  minWidth: 0,
  minHeight: 0,
  keepSelection: false,
  onStart: () => {},
  onSelected: () => {},
  onChange: () => {},
  onComplete: () => {},
  onImageError: () => {},
  onImageLoaded: () => {},
  onDragStart: () => {},
  style: undefined,
  renderComponent: undefined,
  imageStyle: undefined,
  renderSelectionAddon: undefined,
  ruleOfThirds: false,
  crops: [],
  showAddon: false,
  activeCrop: -1,
};

export {
  QuestionBuilderComponent as default,
  QuestionBuilderComponent as Component,
  makeAspectCrop,
  containCrop,
};

import classNames from 'classnames';
import React, { useState } from 'react';

import { Button, Column, Modal, Row } from '@noon/atom';

import { isRTL } from '../../../constants';
import t from '../../../helpers/translate';

function PlaybackOnboardingModal(props : { onExit: Function }) {
  const { onExit } = props;
  const onboardingSteps : Array<{ title: string, description: string, image: string }> = [
    {
      title: t('playback', 'sectionOnboardingTitle1'),
      description: t('playback', 'sectionOnboardingDescription1'),
      image: '../../../assets/images/onboarding/playback-onboarding.png',
    },
    {
      title: t('playback', 'sectionOnboardingTitle2'),
      description: t('playback', 'sectionOnboardingDescription2'),
      image: `../../../assets/images/onboarding/playback_trimming_${isRTL() ? 'ar' : 'en'}.gif`,
    },
  ];

  // States
  const [onboardingIndex, setOnboardingIndex] = useState<number>(0);

  // Logic
  const handleUserAction = () : void => {
    if (onboardingIndex < (onboardingSteps.length - 1)) setOnboardingIndex(onboardingIndex + 1);
    else onExit();
  };

  return (
    <Modal className="playback-onboarding">
      <Column nowrap className="modal-body">
        <div className="top-area">
          <img src={onboardingSteps[onboardingIndex].image} alt="Playback onboarding" />
        </div>
        <div className="bottom-area">
          <div className="texts">
            <h3>{onboardingSteps[onboardingIndex].title}</h3>
            <p>{onboardingSteps[onboardingIndex].description}</p>
          </div>
          {!!onboardingSteps.length && (
            <div className="footer">
              <Row className="dots" gap="sm">{onboardingSteps.map((step, index) => <span key={step.title} onClick={() => setOnboardingIndex(index)} className={classNames('child', onboardingIndex + 1, { active: index === onboardingIndex })} />)}</Row>
              <Button type="primary" value={onboardingIndex === onboardingSteps.length - 1 ? t('button', 'okayGotIt') : t('button', 'next')} onClick={() => handleUserAction()} />
            </div>
          )}
        </div>
      </Column>
    </Modal>
  );
}

export default PlaybackOnboardingModal;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Avatar, SquircleIcon, Row } from '@noon/atom';
import { IconBadge, IconStar } from '@noon/quark';
import { getAudio } from '../../helpers';
import t from '../../helpers/translate';
import { userType } from '../../types';
import ErrorBoundry from '../ErrorBoundry';

export default class CompetitionResult extends Component {

  componentDidMount() {
    try {
      const competitionWinner = getAudio('win-a-game');
      competitionWinner.play();
    } catch (e) {
      console.log('audio error', e);
    }
  }

  render() {
    const { winners } = this.props;
    return (
      <ErrorBoundry msg={t('error', 'loadingResults')}>
        <div className="competition-result">
          <SquircleIcon icon={IconBadge} className="icon-pull-up" />
          <div className="cr-title">{t('competition', 'congratulationsCompetitor')}</div>

          <Row nowrap align="center">
            {winners.map((student, index) => (
              <div style={{ order: index === 0 ? 2 : index === 1 ? 1 : 3 }} className="prize-winner" key={index}>
                <div className="prize-winner__avatar">
                  <div className={classNames('prize-winner__rank', `prize-winner__rank-${index + 1}`)}>
                    {student.rank || index + 1}
                  </div>
                  <Avatar url={student.profile_pic} gender={student.gender} size={index === 0 ? '126px' : '92px'} />
                </div>
                <div className="prize-winner__name">{student.name}</div>

                <Row nowrap align="center" justify="center">
                  <div className="prize-winner__star-icon">
                    <IconStar />
                  </div>
                  <div className="prize-winner__score  mlr-05">{student.score}</div>
                </Row>
              </div>
            ))}
          </Row>
        </div>
      </ErrorBoundry>
    );
  }
}

CompetitionResult.propTypes = {
  winners: PropTypes.arrayOf(userType).isRequired,
};

import { get, isEmpty } from 'lodash-es';
import { LIST_APPROVED_PAYMENT, LIST_PENDING_CASH_PAYMENT, UPDATE_CASH_PAYMENT_STATE } from '../constants';

const initialState = {
  transactions: {
    list: [],
    loading: false,
    meta: {
      page: 1,
      limit: 10,
    },
    error: '',
  },
  updatePaymentState: {},
  error: null,
};

export default function packages(state = initialState, action) {
  switch (action.type) {
    case LIST_PENDING_CASH_PAYMENT.REQUEST:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          loading: true,
          list: (get(action, ['payload', 'page']) || 1) <= 1 ? [] : state.transactions.list,
        },
      };
    case LIST_PENDING_CASH_PAYMENT.SUCCESS:
      return {
        ...state,
        transactions: {
          loading: false,
          list: !isEmpty(state.transactions.list) ? [...state.transactions.list, ...action.payload.data] : action.payload.data,
          meta: {
            ...state.transactions.meta,
            page: action.payload.meta.next_page,
          },
        },
      };
    case LIST_PENDING_CASH_PAYMENT.FAILURE:
      return {
        ...state,
        transactions: {
          loading: false,
          list: [],
          error: action.payload,
        },
      };
    case LIST_APPROVED_PAYMENT.REQUEST:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          loading: true,
          list: (get(action, ['payload', 'page']) || 1) <= 1 ? [] : state.transactions.list,
        },
      };
    case LIST_APPROVED_PAYMENT.SUCCESS:
      return {
        ...state,
        transactions: {
          loading: false,
          list: !isEmpty(state.transactions.list) ? [...state.transactions.list, ...action.payload.data] : action.payload.data,
          meta: {
            ...state.transactions.meta,
            page: action.payload.meta.next_page,
          },
        },
      };
    case LIST_APPROVED_PAYMENT.FAILURE:
      return {
        ...state,
        transactions: {
          loading: false,
          list: [],
          error: action.payload,
        },
      };

    case UPDATE_CASH_PAYMENT_STATE.REQUEST:
      return {
        ...state,
        updatePaymentState: {
          ...action.payload,
        },
      };
    case UPDATE_CASH_PAYMENT_STATE.SUCCESS:
      return {
        ...state,
        updatePaymentState: {},
        transactions: {
          ...state.transactions,
          list: state.transactions.list.filter((transaction) => transaction.reference_id !== action.payload.reference_id),
        },
      };
    case UPDATE_CASH_PAYMENT_STATE.FAILURE:
      return {
        ...state,
        updatePaymentState: {},
        error: action.payload,
      };
    default:
      return state;
  }
}

import t from '../../helpers/translate';
import {
  CLEAR_CLASSROOM,
  CHAT_HISTORY,
  ADD_CHAT,
  UPDATE_CHAT_STATUS,
  BLOCK_CHAT,
} from '../constants';

const initialState = {
  isChatLoading: false,
  disableChat: false,
  chats: [],
  error: {},
};

function markChatAsDelivered(chats, userId) {
  return chats.map((chat) => {
    if (String(chat.user_id) === String(userId)) {
      return { ...chat, isDelivered: true };
    }
    return chat;
  });
}

function addChat(state, payload) {
  if (payload.markDelivered) {
    return markChatAsDelivered(state.chats, payload.user_id);
  }
  const lastChat = state.chats.length ? state.chats[state.chats.length - 1] : {};
  // safety check if last msg is same as current. sometimes mobile app send 2 same events, TODO: need to debug
  if (lastChat.user_id === payload.user_id && lastChat.time === payload.time && lastChat.msg === payload.msg) return state.chats;

  return [...state.chats, payload];
}

function markChatAsBlock(chats, blockedChat) {
  if (!blockedChat.chat_time) return chats;

  return chats.map((chat) => {
    if (String(chat.user_id) === String(blockedChat.user_id) && chat.time === blockedChat.chat_time) {
      return {
        ...chat,
        msg: t('classroom', 'chatMsgBlocked'),
      };
    }
    return chat;
  });
}

export default function myClassroom(state = initialState, action) {
  switch (action.type) {
    case CLEAR_CLASSROOM:
      return {
        ...initialState,
      };
    case ADD_CHAT:
      return {
        ...state,
        chats: addChat(state, action.payload),
      };
    case UPDATE_CHAT_STATUS:
      return {
        ...state,
        disableChat: action.payload === undefined ? !state.disableChat : action.payload,
      };
    case CHAT_HISTORY.REQUEST:
      return {
        ...state,
        isChatLoading: true,
      };
    case CHAT_HISTORY.SUCCESS:
      return {
        ...state,
        isChatLoading: false,
        chats: action.payload,
      };
    case CHAT_HISTORY.FAILURE:
      return {
        ...state,
        isChatLoading: false,
      };
    case BLOCK_CHAT:
      return {
        ...state,
        chats: markChatAsBlock(state.chats, action.payload),
      };

    default:
      if (action.type.indexOf('_REQUEST') !== -1) {
        return {
          ...state,
          error: {},
        };
      }
      return state;
  }
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { DateTime } from 'luxon';
import { Button, Row } from '@noon/atom';
import { isEmpty, lowerCase } from 'lodash-es';
import t from '../../helpers/translate';

function GroupSchedule({ schedules }) {
  // const groupedList = useMemo(() => groupBy(schedules, 'day'), [schedules]);
  const [maxVisibleSchedules, setMaxVisibleSchedules] = useState(() => (isMobile ? 4 : 6));

  const handleOnClickShowMore = () => {
    if (maxVisibleSchedules < schedules.length) {
      setMaxVisibleSchedules(schedules.length);
    } else {
      setMaxVisibleSchedules(isMobile ? 4 : 6);
    }
  };

  return (
    !isEmpty(schedules)
    && (
    <>
      <span className="child block-label">
        {t('groups', 'sessionTimings')}
        {' '}
        (
        {schedules.length}
        )
      </span>
      <Row className="group-schedules" justify="start" align="center" gap="sm">
        {/* duplicate logic to print schedules same thing we are doing in Schedules component */}
        {schedules.slice(0, maxVisibleSchedules).map(({ start_time, end_time, day }, index) => {
          const [hour, minute] = start_time.split(':');
          const [endHour, endMinute] = end_time.split(':');
          return (
            <p key={day} className="child noon-btn noon-btn-lg noon-btn-rounded btn-schedules mb-05">
              <span className="text-capitalize group-schedules-day">
                {t('groups', lowerCase(day))}
              </span>
              <span key={index} className="mlr-1 schedule-widget__details-input-light">
                {`${DateTime.local().set({ hour, minute }).toFormat('hh:mm a')}-${DateTime.local().set({ hour: endHour, minute: endMinute }).toFormat('hh:mm a')}`}
              </span>
            </p>
          );
        })}
      </Row>
      {(maxVisibleSchedules === schedules.length || maxVisibleSchedules < schedules.length)
        && (
        <Button link onClick={handleOnClickShowMore}>
          {maxVisibleSchedules < schedules.length ? `+ ${schedules.length - maxVisibleSchedules} ${t('payment', 'more')}` : t('post', 'show_less')}
        </Button>
        )}
    </>
    )
  );
}

GroupSchedule.propTypes = {
  schedules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
GroupSchedule.defaultProps = {};

export default GroupSchedule;

import React from 'react';
import { IconLoader } from '@noon/quark';
import classNames from 'classnames';
import { createResource } from './react-cache/react-cache';

const mobileScreenSize = 430;

function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth = window.innerWidth, maxHeight = window.innerHeight) {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  const ratioAsPerDevice = maxWidth < mobileScreenSize ? ratio * 0.8 : ratio;

  return { width: srcWidth * ratioAsPerDevice, height: srcHeight * ratioAsPerDevice };
}

const ImageResource = createResource(
  source =>
    new Promise((resolve) => {
      const img = new Image();
      img.src = source;
      img.onload = e => resolve({ source, srcWidth: e.target.width, srcHeight: e.target.height });
    }),
);

const Img = (prop) => {
  const {
    src,
    alt,
    disableViewportBasedResize,
    maxHeight,
    mobileFullWidth,
    desktopFullWidth,
    imageQuantity,
    remainingImages,
    fourthIndex,
    currentImageIndex,
    onNextOrPreviousClick,
    totalImages,
    threeImages,
    ...props
  } = prop;
  const { source, srcWidth, srcHeight } = ImageResource.read(src);
  const { width, height } = calculateAspectRatioFit(srcWidth, srcHeight);
  if (imageQuantity && imageQuantity.indexOf('multiple') !== -1) {
    props.height = '300px';
    if (fourthIndex) {
      props.style = { opacity: '0.3' };
    }
  } else if (!disableViewportBasedResize) {
    const offsetWidth = width * 0.8;
    const offsetHeight = height * 0.8;
    props.width = `${offsetWidth}px`;
    props.height = `${offsetHeight}px`;
    const imageContainerWidth = window.innerWidth <= mobileScreenSize ? mobileFullWidth : desktopFullWidth;
    const renderedHeight =
      offsetWidth > imageContainerWidth ? offsetHeight : offsetHeight * (imageContainerWidth / offsetWidth);
    if (offsetWidth / offsetHeight < 1 && renderedHeight >= maxHeight) {
      const topOffset = (renderedHeight - maxHeight) / 2;
      if (topOffset) {
        props.style = { top: `-${topOffset}px` };
      }
    }
  }
  return (
    <React.Fragment>
      <img src={source} alt={alt} {...props} />
      {fourthIndex && <div className="abcd">{`${remainingImages}+`}</div>}
    </React.Fragment>
  );
};

export default function ImageWithOrientation(prop) {
  const {
    url,
    alt,
    disableViewportBasedResize,
    maxHeight,
    mobileFullWidth,
    desktopFullWidth,
    className,
    files,
    imageQuantity,
    multiple,
    fourthIndex,
    remainingImages,
    onNextOrPreviousClick,
    currentImageIndex,
    totalImages,
    threeImages,
  } = prop;
  const thumbUrl = files && files.thumbnail;
  return (
    <div className={classNames('image-wrapper', className)} style={{ maxHeight }}>
      <React.Suspense
        maxDuration={200}
        fallback={
          imageQuantity > 1 ? (
            <IconLoader />
          ) : (
            <img src={thumbUrl} className={multiple ? 'preview multiple' : 'preview'} alt={alt || ''} />
          )
        }
      >
        <Img
          src={url}
          mobileFullWidth={mobileFullWidth}
          desktopFullWidth={desktopFullWidth}
          maxHeight={maxHeight}
          disableViewportBasedResize={disableViewportBasedResize}
          alt={alt || ''}
          className={multiple ? 'loaded multiple' : 'loaded'}
          imageQuantity={imageQuantity}
          fourthIndex={fourthIndex}
          remainingImages={remainingImages}
          onNextOrPreviousClick={onNextOrPreviousClick}
          currentImageIndex={currentImageIndex}
          totalImages={totalImages}
          threeImages={threeImages}
        />
      </React.Suspense>
    </div>
  );
}

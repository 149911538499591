/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import { IconWait, IconRaiseHandMic, IconRaiseHandMicOff } from '@noon/quark';

const MicIcon = (prop) => {
  const { className, style, height, width, active, wait, onToggle, user, ...rest } = prop;
  return (
    <React.Fragment>
      {wait
        ? (
          <IconWait
            onClick={() => ((wait || !onToggle) ? null : onToggle(active ? 'mute' : 'unmute', user))}
            className={classNames({ pointer: !wait })}
            style={{ height: height || '2.5rem', width: width || '2.5rem', ...style }}
            {...rest}
            fill="#999"
    />
        )
        : (
          active
            ? (
              <IconRaiseHandMic
                onClick={() => ((wait || !onToggle) ? null : onToggle(active ? 'mute' : 'unmute', user))}
                className={classNames({ pointer: !wait })}
                style={{ height: height || '2.5rem', width: width || '2.5rem', ...style }}
                {...rest}
                fill="#31A359 "
          />
            )
            : (
              <IconRaiseHandMicOff
                onClick={() => ((wait || !onToggle) ? null : onToggle(active ? 'mute' : 'unmute', user))}
                className={classNames({ pointer: !wait })}
                style={{ height: height || '2.5rem', width: width || '2.5rem', ...style }}
                {...rest}
                fill="#31A359"
          />
            )
        )}
    </React.Fragment>
  );
};

export default MicIcon;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import isEmpty from 'lodash-es/isEmpty';
import { Column, Button, Row, Modal } from '@noon/atom';
import { Checkbox } from '../Content';
import { userType, classType, translationType } from '../../types';
import { translationText } from '../../helpers';
import ErrorBoundry from '../ErrorBoundry';
import { SessionGenericModal } from '../Modals';
import ChatList from '../Chat/chatList';
import ChatFooter from '../Chat/chatFooter';
import ReportAbuseModal from './reportAbuseModal';

const uuidv4 = require('uuid/v4');

const BLOCK_TIME = 7 * 24 * 60 * 60; // 7 days

class Chat extends Component {
  static propTypes = {
    sessionData: classType.isRequired,
    noonText: translationType.isRequired,
    userData: userType.isRequired,
    chats: PropTypes.arrayOf(PropTypes.object).isRequired,
    sendSocketSignal: PropTypes.func.isRequired,
    sendStudentImageToCanvas: PropTypes.func.isRequired,
    handleIncomingChatMsg: PropTypes.bool.isRequired,
    chatBlockedByTeacher: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      chatStatus: props.chatBlockedByTeacher ? 'off' : 'on',
      userUploadedImageConfirmation: {},
      flaggedPlayer: {},
      blockChatModal: {},
    };
  }

  sendMessage = (msg, image, imageType) => {
    const { userData, sessionData, sendSocketSignal, handleIncomingChatMsg } = this.props;
    const msgObj = {
      event: 'chat',
      name: userData.name,
      time: Date.now(),
      gender: userData.gender,
      user_id: userData.id,
      pic: userData.profile_pic,
      isTeacher: 1,
      msg,
      image_thumbnail_uri: image || null,
      image_type: imageType || null,
      id: uuidv4(),
      session_id: sessionData.id,
    };
    sendSocketSignal('send_message', msgObj);
    handleIncomingChatMsg({ message: msgObj, isLocal: true });
  }

  addStudentImageToCanvas = (imageObj) => {
    const img = new Image();
    img.src = imageObj.image_uri || imageObj.image_thumbnail_uri;
    img.onload = (e) => {
      this.setState({
        userUploadedImageConfirmation: {
          orientation: e.target.height > e.target.width ? 'portrait' : 'landscape',
          ...imageObj,
        },
      });
    };
  };

  confirmSendToCanvas = (url) => {
    const { sendStudentImageToCanvas } = this.props;
    const data = {
      resourceId: null,
      resourceType: 'uploadedImage', // this is literal text in case of flashcard
      url,
    };
    sendStudentImageToCanvas(data);
    this.setState({ userUploadedImageConfirmation: {} });
  };

  handleReportAbuse = (player) => {
    this.setState({ flaggedPlayer: player });
  };

  closeReportAbuseModal = () => {
    this.setState({ flaggedPlayer: {} });
  };

  sendReportAbuse = ({ reason, player }) => {
    const msg = {
      event: 'report_abuse',
      user_id: player.user_id,
      message: player.messages.map((o) => o.msg).join(','),
      reason,
    };
    this.props.sendSocketSignal('client_data', msg);
  };

  toggleStatus = () => {
    const { chatBlockedByTeacher } = this.props;
    this.setState({ chatStatus: chatBlockedByTeacher ? 'on' : 'off' });
    const msg = {
      event: 'disableStudentChat',
      disable: !chatBlockedByTeacher,
      isTeacher: 1,
    };
    this.props.sendSocketSignal('client_data', msg);
  };

  blockUser = () => {
    const msg = {
      event: 'blockChat',
      user_id: this.state.blockChatModal.user_id,
      blockTime: BLOCK_TIME,
      chat_time: this.state.blockChatModal.time,
      msg: this.state.blockChatModal.msg,
      isTeacher: 0,
    };
    this.props.sendSocketSignal('client_data', msg);
    this.closeBlockChatModal();
  };

  openBlockChatModal = (chat) => {
    this.setState({
      blockChatModal: chat,
    });
  }

  closeBlockChatModal = () => {
    this.setState({
      blockChatModal: {},
    });
  }

  render() {
    const {
      userUploadedImageConfirmation,
      chatStatus,
      flaggedPlayer,
      blockChatModal,
    } = this.state;
    const { chats, userData, noonText } = this.props;

    return (
      <ErrorBoundry msg={translationText(noonText, 'error.loadingChat')}>
        <Column nowrap className="full-height">
          {!isEmpty(blockChatModal) && (
            <SessionGenericModal
              noonText={noonText}
              type="edit"
              text1={translationText(noonText, 'classroom.chatBlockHeading')}
              text2={translationText(noonText, 'classroom.chatBlock')}
              btn1={translationText(noonText, 'classroom.buttonConfirm')}
              btn2={translationText(noonText, 'classroom.ButtonCancel')}
              onClick={this.blockUser}
              onClose={this.closeBlockChatModal}
            />
          )}

          {!isEmpty(flaggedPlayer) && (
            <ReportAbuseModal
              player={flaggedPlayer}
              onClose={this.closeReportAbuseModal}
              onSubmit={this.sendReportAbuse}
            />
          )}

          {!isEmpty(userUploadedImageConfirmation) && (
            <Modal
              className={classNames('student-image-modal', `${userUploadedImageConfirmation.orientation}`)}
              onClose={() => this.setState({ userUploadedImageConfirmation: {} })}>
              <div className="modal-body">
                <img src={userUploadedImageConfirmation.image_thumbnail_uri} alt="" />
              </div>
              <div className="modal-footer">
                <Button
                  type="primary"
                  value="Add"
                  onClick={() => this.confirmSendToCanvas(userUploadedImageConfirmation.image_uri)}
                      />
                <Button
                  type="default"
                  value="Cancel"
                  onClick={() => this.setState({ userUploadedImageConfirmation: {} })}
                />
              </div>
            </Modal>
          )}

          <Row className="mlr-1" align="center">
            <h4 className="color-primary flex-1">{translationText(noonText, 'tutoring.chats')}</h4>
            <Checkbox
              value={chatStatus}
              bit
              checked={chatStatus === 'on'}
              label={translationText(noonText, `classroom.${chatStatus === 'on' ? 'open' : 'closed'}`)}
              onChange={this.toggleStatus}
              className={classNames('chat-status', `${chatStatus === 'on' ? 'on' : 'off'}`)}
            />
          </Row>
          <ChatList
            chats={chats}
            user={userData}
            addStudentImageToCanvas={this.addStudentImageToCanvas}
            blockChat={this.openBlockChatModal}
          />
          <ChatFooter sendMessage={this.sendMessage} />
        </Column>
      </ErrorBoundry>
    );
  }
}

const mapStateToProps = (state) => ({
  noonText: state.toJS().translation.noonText,
  sessionData: state.toJS().tutoring.sessionDetails,
  userData: state.toJS().user.loggedUser,
  chatStatus: state.toJS().dashboard.chatStatus,
});

export default connect(
  mapStateToProps,
)(Chat);

import React from 'react';
import isEmpty from 'lodash-es/isEmpty';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Avatar, Column, Row, NoonLink, Hr, Time, NoonDate, Button } from '@noon/atom';
import { translationText } from '../../helpers';

export default function FriendActivity(prop) {
  const { noonText, readNotification, notifications, onClose } = prop;
  const history = useHistory();
  const handleMouseEnter = (notification) => {
    if (notification.notification_feed.notification_viewed) return;
    readNotification({
      feed_type: 'notification',
      notificationstatus: true,
      notification_uid: notification.notification_feed.UUID,
    });
  };

  return (
    <Column className="friend-request-container">
      <h4>{translationText(prop.noonText, 'notification.studentActivities')}</h4>
      {notifications.list.map((notification) => (
        <Row
          key={notification.notification_feed.UUID}
          align="center"
          justify="end"
          nowrap
          gap="sm"
          className={classNames('friend-request-item', {
            'new-notification': !notification.notification_feed.notification_viewed,
          })}
          onClick={onClose}
          onMouseEnter={() => handleMouseEnter(notification)}
        >
          <Avatar
            url={notification.notification_feed.meta_data.profile_pic}
            gender={notification.notification_feed.meta_data.gender}
            size="36px"
            className="mb-1"
            rounded
            noShadow
          />
          <Column nowrap flex="1" gap="sm">
            <div className="player-info flex-auto">
              <div className="player-title">{notification.notification_feed.meta_data.notification_message}</div>
              <Row align="center" className="notification-time">
                <NoonDate humanize format="dd MMM hh:mm a" value={notification.notification_feed.created_at} />
              </Row>
            </div>
            <Row nowrap align="center" justify="space-sm">
              {notification.notification_feed.event_type === 'group_status' && (
                <NoonLink
                  type="primary"
                  size="small"
                  href={`/${localStorage.updatedLocale}/groups/${notification.notification_feed.meta_data.group_id}?group_status=true`}
                >
                  {translationText(noonText, 'button.open')}
                </NoonLink>
              )}
              {notification.notification_feed.event_type === 'post_added' && (
                <Button
                  type="primary"
                  size="small"
                  history={history}
                  to={{
                    pathname: `/${localStorage.updatedLocale}/groups/${notification.notification_feed.meta_data.group_id}`,
                    // search: `?pid=${notification.notification_feed.meta_data.post_id}`,
                  }}
                >
                  {translationText(noonText, 'button.open')}
                </Button>
              )}
              {notification.notification_feed.event_type === 'comment_added' && (
                <Button
                  type="primary"
                  size="small"
                  history={history}
                  to={{
                    pathname: `/${localStorage.updatedLocale}/groups/${notification.notification_feed.meta_data.group_id}`,
                    // search: `?pid=${notification.notification_feed.meta_data.post_id}&cid=${notification.notification_feed.meta_data.comment_id}`,
                  }}
                >
                  {translationText(noonText, 'button.open')}
                </Button>
              )}
              {notification.notification_feed.event_type === 'post_liked' && (
                <Button
                  type="primary"
                  size="small"
                  history={history}
                  to={{
                    pathname: `/${localStorage.updatedLocale}/groups/${notification.notification_feed.meta_data.group_id}`,
                    // search: `?pid=${notification.notification_feed.meta_data.post_id}`,
                  }}
                >
                  {translationText(noonText, 'button.open')}
                </Button>
              )}
              {notification.notification_feed.event_type === 'comment_liked' && (
                <Button
                  type="primary"
                  size="small"
                  history={history}
                  to={{
                    pathname: `/${localStorage.updatedLocale}/groups/${notification.notification_feed.meta_data.group_id}`,
                    // search: `?pid=${notification.notification_feed.meta_data.post_id}&cid=${notification.notification_feed.meta_data.comment_id}`,
                  }}
                >
                  {translationText(noonText, 'button.open')}
                </Button>
              )}
              {notification.notification_feed.event_type === 'request_received' && (
                <NoonLink
                  type="primary"
                  size="small"
                  href={`/${localStorage.updatedLocale}/groups/${notification.notification_feed.meta_data.group_id}/members`}
                >
                  {translationText(noonText, 'button.open')}
                </NoonLink>
              )}
            </Row>
          </Column>
        </Row>
      ))}
    </Column>
  );
}

import React from 'react';

import { IconExclamationCircle } from '@noon/quark';
import { Column, Row } from '@noon/atom';
import PrecallIcon from './pre-call-icon';
import t from '../../../helpers/translate';

type Props = {
    errorMessage: {
        icon: string,
        errorHeading: string,
        errorContent: string,
        downloadLink?: string
    },
}

const PrecallMessage = ({ errorMessage }: Props) => {
  const { icon, errorHeading, errorContent, downloadLink } = errorMessage;

  return (
    <Row className="modalErrors--block">
      <Row className="modalErrors--block__icon">
        <PrecallIcon size="26" name={icon} />
      </Row>
      <Column flex={1}>
        <Row className="modalErrors--block__heading">
          {t('classroom', errorHeading)}
        </Row>
        <Row className="modalErrors--block__content">
          <IconExclamationCircle className="exclamation" height="20px" width="20px" fill="#222c3c" stroke="#222c3c" rotate="180" />
          <Row flex={1}>
            {t('classroom', errorContent)}
            {downloadLink && (
            <a target="_blank" href="https://www.google.com/chrome/?brand=CHBD&gclid=EAIaIQobChMI95mdlvnV6AIVUA4rCh1TnQidEAAYASAAEgKNUPD_BwE&gclsrc=aw.ds">
              {t('classroom', downloadLink)}
            </a>
            )}
          </Row>
        </Row>
      </Column>
    </Row>
  );
};

export default PrecallMessage;

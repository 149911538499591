import {
  CREATE_CLASS_SIDEBAR,
  TEACHER_PRODUCT,
  REDIRECT_URL,
  CLEAR_USER_SPECIFIC_DASHBOARD_DATA,
  UPDATE_DATA_POINTS,
  UPDATE_TEMP_AUTH_DATA,
  GO_TO_LOGIN,
  INVALID_TIME_MODAL,
  SET_SESSION_COACHMARK,
  TOGGLE_HOVER_SIDEBAR,
  TOGGLE_SIDEBAR,
  TOGGLE_TAB_STATUS,
} from '../constants';

const initialState = {
  creationType: null,
  createClassSidebar: {},
  currentResource: {},
  selectedProduct: {},
  teacherProduct: {},
  chatStatus: 'on',
  noCreditModal: false,
  redirectUrl: {},
  homeworkDetails: {},
  sidebarHovered: false,
  sidebarClosed: localStorage.getItem('shouldSidebarCollapse') ? localStorage.getItem('shouldSidebarCollapse') === 'true' : true,
  studentDataPoints: {
    account_type: 'new',
    existing_noon_account: 'not_applicable',
    enter_path: 'direct_login',
    legacy_login: 'none',
    failed_login_count: 0,
    failed_login_error: null,
    returning_sign_up: null,
  },
  studentTempAuthData: { wrong_otp_count: 0, change_number_count: 0, resend_otp_count: 0 },
  goToLogin: false,
  invalidTimeModal: false,
  openEditSchool: false,
  sessionCoachmark: false,
  isTabActive: true,
};

export default function dashboard(state = initialState, action) {
  switch (action.type) {
    case CLEAR_USER_SPECIFIC_DASHBOARD_DATA:
      delete localStorage.teacherStatus;
      return {
        ...state,
        teacherProduct: {},
        homeworkDetails: {},
        creationType: null,
      };
    case CREATE_CLASS_SIDEBAR.OPEN:
      return {
        ...state,
        createClassSidebar: action.payload,
      };
    case CREATE_CLASS_SIDEBAR.CLOSE:
      return {
        ...state,
        createClassSidebar: {},
      };
    case TEACHER_PRODUCT:
      return {
        ...state,
        teacherProduct: action.payload,
      };
    case REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.payload,
      };
    case UPDATE_DATA_POINTS:
      localStorage.setItem('studentDataPoints', JSON.stringify({ ...state.studentDataPoints, ...action.payload }));
      return {
        ...state,
        studentDataPoints: { ...state.studentDataPoints, ...action.payload },
      };
    case UPDATE_TEMP_AUTH_DATA:
      return {
        ...state,
        studentTempAuthData: { ...state.studentTempAuthData, ...action.payload },
      };
    case GO_TO_LOGIN:
      return {
        ...state,
        goToLogin: action.payload,
      };
    case INVALID_TIME_MODAL:
      return {
        ...state,
        invalidTimeModal: action.payload,
      };
    case SET_SESSION_COACHMARK:
      return {
        ...state,
        sessionCoachmark: action.payload,
      };
    case TOGGLE_SIDEBAR:
      localStorage.shouldSidebarCollapse = action.payload;
      return {
        ...state,
        sidebarClosed: action.payload,
        sidebarHovered: false,
      };
    case TOGGLE_HOVER_SIDEBAR:
      return {
        ...state,
        sidebarHovered: action.payload,
      };
    case TOGGLE_TAB_STATUS:
      return {
        ...state,
        isTabActive: action.payload,
      };
    default:
      return state;
  }
}

import { createSelector } from 'reselect';

const groupDetailsSelectorRaw = state => state.toJS().groupsV2.selectedGroup.group_info;

const groupDetailsSelector = createSelector(
  groupDetailsSelectorRaw,
  item => item,
);
export {
  groupDetailsSelector,
};

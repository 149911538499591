import React from 'react';
import { Row, Column } from '@noon/atom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash-es/get';
import { IconBarChart, IconCheckHeavy } from '@noon/quark/dist';
import { translationText } from '../../helpers';
import { calculateRightAnswers } from '../../helpers/homeworkReport';
import { COLORS } from '../../constants';

const ReportPageBarStats = (props) => {
  const { details, noonText } = props;
  const { studentProfile } = useSelector((state) => state.toJS().homeworkReport);

  const avgQuestionsSolved = get(details, ['metadata', 'avg_questions_solved']) || 0;
  const avgScore = get(details, ['metadata', 'avg_score']) || 0;

  const returnAvgCorrectAnswersText = () => {
    if (calculateRightAnswers(studentProfile.questions) === avgQuestionsSolved) {
      return translationText(noonText, 'homeworkReport.sameAsAvgCorrectAnswers');
    } if (calculateRightAnswers(studentProfile.questions) < avgQuestionsSolved) {
      return translationText(noonText, 'homeworkReport.belowAvgCorrectAnswers');
    }
    return translationText(noonText, 'homeworkReport.fromAvgCorrectAnswers');
  };

  const returnAvgScoreText = () => {
    if (studentProfile.score === avgScore) {
      return translationText(noonText, 'homeworkReport.sameAsAvgScore');
    } if (studentProfile.score < avgScore) {
      return translationText(noonText, 'homeworkReport.belowAvgCorrectScore');
    }
    return translationText(noonText, 'homeworkReport.fromAvgScore');

  };

  return (
    <Row className="report--bar--stats">
      <Column align="start">
        <IconCheckHeavy fill={COLORS.green.base} stroke={COLORS.green.base} width="20px" height="20px" />
        <span className="stat">
          {studentProfile.position >= 0 ? studentProfile.questions.length > 0 ? calculateRightAnswers(studentProfile.questions) : 0 : details && details.metadata && details.metadata.avg_questions_solved}
          /
          {details.question_ids && details.question_ids.length}
        </span>
        <span>
          {studentProfile.position >= 0
            ? (
              <React.Fragment>
                {calculateRightAnswers(studentProfile.questions) !== avgQuestionsSolved && (
                  <span className="difference-answers">
                    {`${calculateRightAnswers(studentProfile.questions) - avgQuestionsSolved > 0 ? '+' : ''}
                      ${studentProfile.questions.length > 0
                      ? Math.abs(calculateRightAnswers(studentProfile.questions) - avgQuestionsSolved)
                      : 0}`}
                  </span>
                )}
                {returnAvgCorrectAnswersText()}
              </React.Fragment>
            )
            : `${translationText(noonText, 'homeworkReport.avgCorrectAnswers')}`}
        </span>
      </Column>
      <Column align="start">
        <IconBarChart />
        <span className="stat">{studentProfile.position >= 0 ? studentProfile.score : avgScore}</span>
        <span>
          {studentProfile.position >= 0
            ? (
              <React.Fragment>
                <span className="difference-score">
                  {`${studentProfile.score - avgScore > 0 ? '+' : ''}${Math.abs(studentProfile.score - avgScore)}`}
                </span>
                {returnAvgScoreText()}
              </React.Fragment>
            )
            : `${translationText(noonText, 'homeworkReport.avgScore')}`}
        </span>
      </Column>
    </Row>
  );
};

ReportPageBarStats.propTypes = {
  details: PropTypes.shape(),
  noonText: PropTypes.shape().isRequired,
};

ReportPageBarStats.defaultProps = {
  details: {},
};

export default ReportPageBarStats;

export const ListingOrder = {
  k12: ['board', 'grade', 'subject', 'curriculum', 'chapter', 'topic'],
  university: ['degree', 'major', 'course', 'section', 'chapter', 'topic'],
  test_prep: ['test', 'section', 'chapter', 'topic'],
  skill: ['skill', 'section', 'chapter', 'topic'],

  k12_test_prep: ['test', 'section', 'chapter', 'topic'],
  university_test_prep: ['test', 'section', 'chapter', 'topic'],
  general_test_prep: ['test', 'section', 'chapter', 'topic'],
  k12_skill: ['skill', 'section', 'chapter', 'topic'],
  university_skill: ['skill', 'section', 'chapter', 'topic'],
  general_skill: ['skill', 'section', 'chapter', 'topic'],
};

export const K12 = 'k12';
export const UNIVERSITY = 'university';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import t from '../../../../helpers/translate';
import QuestionForLesson from '../../../Content/questionForLesson';
import { getQuestionContent, selectQuestionContent } from '../../../../redux/actions/classroom';

type IChoice = {
  id: number,
  answer?: string,
  answer_json: Array<any> | null,
  total_votes: number | null,
  avg_votes: number | null,
  is_correct: 0 | 1,
};

type IPassage = {
  name: string,
  content?: string,
  content_json: Array<any> | null
};

export type IQuestion = {
  id: number,
  question?: string,
  question_json: Array<any> | null,
  choices: Array<IChoice>,
  solution?: string,
  solution_json: Array<any> | null,
  passage?: any,
  passage_json: Array<any> | null,
  country_id: number | string,
  creator_type: string,
  difficulty: number,
  total_votes: number | null,
  total_students: number | null,
  text_direction: 'ltr' | 'rtl',
  user_id: number,
  time_left?: number,
  time?: number,
  get_ready?: number,
  q_no?: number,
  question_type_name: string,
  labelId?: string,
  labelName?: string,
}

const QuestionContainer = () => {

  const dispatch = useDispatch();
  const questions = useSelector((state) => state.toJS().content.question);
  const contentError = useSelector((state) => state.toJS().content.error);
  const topicId = useSelector((state) => state.toJS().content.topicId);
  const isSlideAdding = useSelector((state) => state.toJS().whiteboard.isSlideAdding);

  const handleSelectQuestion = (question: IQuestion) => {
    if (isSlideAdding) return;

    // clear html data before adding to slide
    const sanitizedChoices = question.choices.map((choice) => ({ ...choice, answer: '' }));
    const questionPayload = {
      ...question,
      question: '', // clear html data before adding to slide
      solution: '', // clear html data before adding to slide
      passage: '',
      passage_json: question?.passage.content_json,
      choices: sanitizedChoices,
    };
    dispatch(selectQuestionContent(questionPayload));
  };

  const getQuestions = (payload = {}) => {
    const { limit, nextPage } = questions;
    dispatch(getQuestionContent({ topic_id: topicId, page: nextPage > 0 ? nextPage : 1, limit, ...payload }));
  };

  const handleLoadMoreQuestion = (filters = {}) => {
    getQuestions(filters);
  };

  useEffect(() => {
    if (topicId && questions.topicId !== topicId) {
      dispatch(getQuestionContent({ topic_id: topicId, page: 1 }));
    }
  }, [topicId]);

  return (
    <QuestionForLesson
      noQuestionFoundMessage={t('classroom', 'noQuestion')}
      addQuestionToCanvas={handleSelectQuestion}
      errorFor={contentError.question ? ['question'] : []} // TODO: fix - simulate old behaviour, will change once we rewrite this view
      questions={questions}
      handleLoadMoreQuestion={handleLoadMoreQuestion}
    />
  );
};

export default QuestionContainer;

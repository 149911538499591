import { createSelector } from 'reselect';

const groupDetailsSelectorRaw = state => state.toJS().groups.groupDetails;
const groupListSelectorRaw = state => state.toJS().groups.groupData.list;
const blockListMapSelectorRaw = state => state.toJS().groups.blockListMap;
const groupMembersListMapSelectorRaw = state => state.toJS().groups.groupMembersListMap;
const groupMembersSelectorRaw = state => state.toJS().groups.groupMembers.list;
const pendingMembersSelectorRaw = state => state.toJS().groups.allPendingRequest.list;
const blockedUsersSelectorRaw = state => state.toJS().groups.blockUserList.response;

const groupDetailsSelector = createSelector(
  groupDetailsSelectorRaw,
  item => item,
);

const groupListSelector = createSelector(
  groupListSelectorRaw,
  item => item,
);

const blockListMapSelector = createSelector(
  blockListMapSelectorRaw,
  item => item,
);

const groupMembersListMapSelector = createSelector(
  groupMembersListMapSelectorRaw,
  item => item,
);

const groupMembersSelector = createSelector(
  groupMembersSelectorRaw,
  item => item,
);

const blockedUsersSelector = createSelector(
  blockedUsersSelectorRaw,
  item => item,
);

const pendingMembersSelector = createSelector(
  pendingMembersSelectorRaw,
  item => item,
);
export {
  groupDetailsSelector,
  groupListSelector,
  blockListMapSelector,
  groupMembersSelector,
  blockedUsersSelector,
  groupMembersListMapSelector,
  pendingMembersSelector
};

import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Draggable } from 'react-smooth-dnd';
import { Link, scroller } from 'react-scroll';
import { Row } from '@noon/atom';
import { translationText } from '../../helpers';
import { EDIT_QUESTIONS_LIST } from '../../redux/constants';
import { returnDuplicatePropsForMixpanelAndKafka } from '../../helpers/prepareAssignment';

const HomeworkCreationHeader = ({ noonText }) => {
  const logger = useRef(LoggingManager.mount({ moduleName: 'homework' })).current;
  const { currentQuestions, hw_details } = useSelector((state) => state.toJS().homeworkCreation);
  const { loggedUser: user } = useSelector((state) => state.toJS().user);
  const { selectedGroup } = useSelector((state) => state.toJS().groupsV2);
  const dispatch = useDispatch();

  const mixpanelKafkaEvents = () => {
    const type = 'question_reordered';
    const props = returnDuplicatePropsForMixpanelAndKafka(selectedGroup, user, hw_details);
    const properties = {
      no_of_questions: currentQuestions.data ? currentQuestions.data.length : 0,
      ...props,
    };
    logger.track(type, { homework: properties });
  };

  const changeOrder = (e) => {
    mixpanelKafkaEvents();
    dispatch({
      type: EDIT_QUESTIONS_LIST.CHANGE_ORDER,
      payload: e,
    });
    if (e.addedIndex != null && e.removedIndex != null) {
      scroller.scrollTo(`question${e.addedIndex + 1}`, {
        duration: 500,
        delay: 50,
        smooth: true,
        containerId: 'containerElement',
      });
    }
  };

  return (
    <Row justify="center" className="prepare-assignment--mainHeader">
      <span className="prepare-assignment--mainHeader--amount">
        {translationText(noonText, 'homework.leaderBoard_question_numbers')}
        :
        {' '}
        {currentQuestions.data.length}
      </span>
      <Container
        groupName="questions-list"
        onDrop={(e) => changeOrder(e)}
        getChildPayload={(index) => currentQuestions.data[index]}
        orientation="horizontal"
      >
        {currentQuestions.data.map((_, index) => (
          <Draggable
            key={index}
            className="prepare-assignment--mainHeader--paginationItem"
            >
            <Link
              activeClass="activeQuestion"
              containerId="containerElement"
              spy
              smooth
              duration={250}
              offset={-200}
              to={`question${index + 1}`} key={index}
            >
              {index + 1}
            </Link>
          </Draggable>
        ))}
      </Container>
    </Row>
  );
};

export default HomeworkCreationHeader;

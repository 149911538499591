import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@noon/atom';
import { translationText } from 'helpers';
import { userType, playerListType, translationType } from '../../types';
import IndividualLeaderboard from './Breakout/IndividualLeaderboard';
import TeamLeaderBoard from './Breakout/TeamLeaderBoard';

class ClassLeaderboard extends PureComponent {
  static propTypes = {
    players: playerListType.isRequired,
    userData: userType.isRequired,
    noonText: translationType.isRequired,
    // chatBlockTime: PropTypes.number.isRequired,
    sendSocketSignal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { sessionData } = this.props;
    const isBreakoutEnabled = !!sessionData.breakout_enabled;
    this.state = {
      flaggedPlayer: {},
      leaderBoardTab: isBreakoutEnabled ? 'team' : 'individual',
      isBreakoutEnabled,
    };
  }

  componentWillUnmount() {
    if (this.searchTimer) clearTimeout(this.searchTimer);
  }

  handleReportAbuse = (player) => {
    this.setState({ flaggedPlayer: player });
  };

  closeReportAbuseModal = () => {
    this.setState({ flaggedPlayer: {} });
  };

  setSelectedTab = (leaderBoardTab) => {
    this.setState({ leaderBoardTab });
  };

  sendReportAbuse = ({ reason, player }) => {
    const msg = {
      event: 'report_abuse',
      user_id: player.user_id,
      message: '',
      reason,
    };
    this.props.sendSocketSignal('client_data', msg);
  };

  blockUser = (student) => {
    const msg = {
      event: 'blockChat',
      user_id: student.user_id,
      blockTime: 7 * 24 * 60 * 60, // 7 days
      isTeacher: 0,
    };
    this.props.sendSocketSignal('client_data', msg);
  };

  render() {
    const {
      players,
      noonText,
      sessionData,
      loadPreviousLeaderboard,
      leaderboardUser,
      leaderboardLoading,
      leaderboardOffset,
      totalUsers,
      totalLiveUsers,
    } = this.props;
    const { flaggedPlayer, leaderBoardTab, isBreakoutEnabled } = this.state;
    const isIndividualActive = leaderBoardTab === 'individual';

    return (
      <div className="class-leader-board-wrapper">
        <h1 className="class-leader-board-wrapper__heading">
          {translationText(noonText, 'teacherBreakout.honorList')}
        </h1>
        {isBreakoutEnabled && (
          <div className="class-leader-board-wrapper__buttoncontainer">
            <Button
              size="lg"
              type="primary"
              outlined={isIndividualActive}
              className="mtb-05 relative"
              onClick={() => this.setSelectedTab('team')}
            >
              {translationText(noonText, 'teacherBreakout.team')}
              {/* <span>{translationText(noonText, 'teacherBreakout.new')}</span> */}
            </Button>
            <Button
              size="lg"
              type="primary"
              outlined={!isIndividualActive}
              className="mtb-05"
              onClick={() => this.setSelectedTab('individual')}
            >
              {translationText(noonText, 'teacherBreakout.individual')}
            </Button>
          </div>
        )}
        {isIndividualActive && (
          <IndividualLeaderboard
            loadPreviousLeaderboard={loadPreviousLeaderboard}
            leaderboardUser={leaderboardUser}
            leaderboardLoading={leaderboardLoading}
            leaderboardOffset={leaderboardOffset}
            handleReportAbuse={this.handleReportAbuse}
            players={players}
            blockUser={this.blockUser}
            flaggedPlayer={flaggedPlayer}
            closeReportAbuseModal={this.closeReportAbuseModal}
            sendReportAbuse={this.sendReportAbuse}
            totalLiveUsers={totalLiveUsers}
            totalUsers={totalUsers}
          />
        )}
        {!isIndividualActive && <TeamLeaderBoard sessionId={sessionData.id} />}
      </div>
    );
  }
}

export default ClassLeaderboard;

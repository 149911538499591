import { put, call, takeEvery, all, select } from 'redux-saga/effects';
import axios from 'axios';
import { noonTextSelector } from 'redux/selectors/translations';
import { tokenDataSelector } from 'redux/selectors/userv2';
import { onboardingSelector } from 'redux/selectors/onboarding';
import isEmpty from 'lodash-es/isEmpty';
import { usersv2Api, onboardingApi, usersApiNew } from '../restApi';
import { setAuthorizationHeader, translationText } from '../../helpers';
import {
  NOONERS_LOGIN,
  SIGNUP_TEACHER,
  GET_TEACHER_PROFILE,
  UPDATE_TEACHER_PROFILE,
  LOGGED_OUT_TEACHER,
  TEMPORARY_TOKEN_DATA,
  TOKEN_DATA,
  GET_PROFILE,
  COMMON_LOGOUT,
  COMMON_LOGIN,
  REDIRECT_URL,
  LIST_COUNTRY,
  RESET_VERIFY_OTP,
} from '../constants';
import { initializePush, deleteToken } from '../../components/Firebase';
import { addToast, TOAST_TYPE } from '../../components/Toast';
import { getCountryId } from '../../constants';

const signupTeacherApi = (data) => onboardingApi.post('create_user', data);
const noonersLoginAPI = (user) => usersv2Api.post('nooners_login/teacher', user);
const getTeacherProfileApi = () => usersApiNew.get('profile');
const updateTeacherProfileApi = (data) => usersv2Api.put('teacher_profile', data);
const logOutTeacherApi = () => usersv2Api.post('teacher_logout');

const getFolder = (state) => state.toJS().folder;

function* updateCountryOnLogin(user) {
  const { countries } = yield select(getFolder);
  if (countries.selectedCountry.id !== user.country_id) {
    const selectedCountry = countries.countryList.find((country) => country.id === user.country_id);
    if (!selectedCountry) return;
    localStorage.country = JSON.stringify({ countryList: countries.countryList, selectedCountry });
    yield put({
      type: LIST_COUNTRY.SUCCESS,
      payload: { countryList: countries.countryList, selectedCountry },
    });
  }
}

export function* commonLogin({ payload }) {
  yield setAuthorizationHeader(payload.a_tkn.tkn);
  yield put({
    type: TEMPORARY_TOKEN_DATA,
    payload: null,
  });
  delete localStorage.temporaryToken;
  yield put({
    type: TOKEN_DATA,
    payload,
  });
  localStorage.setItem('tokenData', JSON.stringify(payload));
  yield put({ type: GET_TEACHER_PROFILE.REQUEST });
  return true;
}

function* noonersLogin({ payload: user }) {
  try {
    yield put({ type: NOONERS_LOGIN.LOADING, payload: { isLoading: true } });
    const response = yield call(noonersLoginAPI, user);
    if (response.ok) {
      yield put({ type: NOONERS_LOGIN.SUCCESS,
        payload: isEmpty(response.data.data) ? {} : response.data.data[0],
      });
      yield call(commonLogin, {
        payload: isEmpty(response.data.data) ? {} : response.data.data[0],
      });
      // initialize push notification
      initializePush();
    } else {
      yield put({ type: NOONERS_LOGIN.FAILURE, payload: response.data ? response.data.message : 'server error' });
    }
  } catch (err) {
    yield put({ type: NOONERS_LOGIN.FAILURE, payload: 'server error' });
  } finally {
    yield put({ type: NOONERS_LOGIN.LOADING, payload: { isLoading: false } });
  }
}

function* signUpTeacher({ payload: user }) {
  try {
    yield put({ type: SIGNUP_TEACHER.LOADING, payload: { isLoading: true } });

    const onboardingData = yield select(onboardingSelector);
    const { verification_id, otp, phone_number, dialing_code } = onboardingData;
    const response = yield call(signupTeacherApi, {
      ...user,
      school_teacher: 1,
      verification_id,
      otp,
      phone_number,
      dialing_code,
      tenant: 'TEACHER',
      country: getCountryId(),
      locale: localStorage.language || 'ar',
    });
    if (response.ok) {
      yield setAuthorizationHeader(response.data.a_tkn.tkn);
      localStorage.setItem('tokenData', JSON.stringify(response.data));
      initializePush();
      localStorage.setItem('classroomCoachmark', true);
      yield put({
        type: SIGNUP_TEACHER.SUCCESS,
        payload: isEmpty(response.data) ? {} : response.data,
      });
      yield call(commonLogin, {
        payload: response.data ? response.data : {},
      });
      yield put({ type: GET_TEACHER_PROFILE.REQUEST });
    } else {
      yield put({ type: SIGNUP_TEACHER.FAILURE, payload: response.data });
    }
  } catch (err) {
    yield put({ type: SIGNUP_TEACHER.FAILURE, payload: 'server error' });
  } finally {
    yield put({ type: SIGNUP_TEACHER.LOADING, payload: { isLoading: false } });
  }
}

function* logoutOperations() {
  // yield call(expireToken);
  yield call(setAuthorizationHeader);
  yield put({
    type: TEMPORARY_TOKEN_DATA,
    payload: null,
  });
  delete localStorage.temporaryToken;
  yield put({
    type: TOKEN_DATA,
    payload: null,
  });
  // coachmarks local storage
  delete localStorage.sessionCreationCoachmark;
  delete localStorage.showFirstCoachmark;
  delete localStorage.showGroupCoachmarks;
  delete localStorage.sessionCoachmark;
  delete localStorage.classroomCoachmark;

  delete localStorage.tokenData;
  delete localStorage.welcomeMessageSeen;
  delete localStorage.lastUrl;
  delete axios.defaults.headers.common['x-refresh-token'];
  delete localStorage.firstLoadAfterAuthentication;
  yield call(deleteToken);
  yield put({ type: LOGGED_OUT_TEACHER.SUCCESS, payload: {} });
  yield put({
    type: GET_TEACHER_PROFILE.SUCCESS,
    payload: {},
  });
  yield put({
    type: RESET_VERIFY_OTP,
  });
  yield put({ type: REDIRECT_URL, payload: { pathname: `/${localStorage.updatedLocale}/login` } });
}

function* getTeacherProfile() {
  try {
    yield put({ type: GET_TEACHER_PROFILE.LOADING, payload: { isLoading: true } });
    const noonText = yield select(noonTextSelector);
    const response = yield call(getTeacherProfileApi);
    if (response.data.error_code) {
      addToast(translationText(noonText, 'teacherDashboard.wrong_role'), TOAST_TYPE.ERROR);
      yield call(logoutOperations);
    }
    if (response.ok) {
      yield updateCountryOnLogin(response.data);
      yield put({ type: GET_PROFILE.SUCCESS, payload: isEmpty(response.data) ? {} : response.data });
      yield put({
        type: GET_TEACHER_PROFILE.SUCCESS,
        payload: isEmpty(response.data) ? {} : { id: response.data.user_id, ...response.data },
      });
    } else {
      yield put({ type: GET_TEACHER_PROFILE.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: GET_TEACHER_PROFILE.FAILURE, payload: 'server error' });
  } finally {
    yield put({ type: GET_TEACHER_PROFILE.LOADING, payload: { isLoading: false } });
  }
}

function* updateTeacherProfile({ payload }) {
  try {
    yield put({ type: UPDATE_TEACHER_PROFILE.LOADING, payload: { isLoading: true } });
    const response = yield call(updateTeacherProfileApi, { ...payload,
      locale: localStorage.language });
    if (response.data && response.data.success) {
      yield put({
        type: UPDATE_TEACHER_PROFILE.SUCCESS,
        payload: isEmpty(response.data.data) ? {} : response.data.data[0],
      });
      yield put({ type: GET_TEACHER_PROFILE.REQUEST });
    } else {
      yield put({
        type: UPDATE_TEACHER_PROFILE.FAILURE,
        payload: response.data ? response.data.message : 'network failure',
      });
      yield put({ type: LOGGED_OUT_TEACHER.REQUEST, payload: true });
    }
  } catch (err) {
    yield put({ type: UPDATE_TEACHER_PROFILE.FAILURE, payload: 'server error' });
    yield put({ type: LOGGED_OUT_TEACHER.REQUEST, payload: true });
  } finally {
    yield put({ type: UPDATE_TEACHER_PROFILE.LOADING, payload: { isLoading: false } });
  }
}

function* logOutTeacher() {
  try {
    const tokenData = yield select(tokenDataSelector);
    axios.defaults.headers.common['x-refresh-token'] = tokenData.r_tkn.tkn;
    yield put({ type: LOGGED_OUT_TEACHER.LOADING, payload: { isLoading: true } });
    const response = yield call(logOutTeacherApi);
    if (response.data && response.data.success) {
      yield put({
        type: LOGGED_OUT_TEACHER.SUCCESS,
        payload: {},
      });
      yield call(logoutOperations);
    } else {
      yield put({
        type: LOGGED_OUT_TEACHER.FAILURE,
        payload: response.data ? response.data.message : 'network failure',
      });
      yield call(logoutOperations);
    }
  } catch (err) {
    yield put({ type: LOGGED_OUT_TEACHER.FAILURE, payload: 'server error' });
  } finally {
    yield put({ type: LOGGED_OUT_TEACHER.LOADING, payload: { isLoading: false } });
  }
}

export function* userv2Saga() {
  yield all([
    takeEvery(NOONERS_LOGIN.REQUEST, noonersLogin),
    takeEvery(SIGNUP_TEACHER.REQUEST, signUpTeacher),
    takeEvery(GET_TEACHER_PROFILE.REQUEST, getTeacherProfile),
    takeEvery(UPDATE_TEACHER_PROFILE.REQUEST, updateTeacherProfile),
    takeEvery(LOGGED_OUT_TEACHER.REQUEST, logOutTeacher),
    takeEvery(COMMON_LOGOUT, logoutOperations),
    takeEvery(COMMON_LOGIN, commonLogin),
  ]);
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import { IconCanvasExit, IconHappy, IconLoaderDotted, IconSad, IconStar, IconTooltip } from '@noon/quark';
import Popover from 'react-popover';
import {
  Avatar,
  SignalIndicator,
  ShareUrl,
  Badge,
  Column,
  Button,
  Row,
  Vr,
  NoonDate,
  LiveTime,
  LinearPercentageIndicator,
  Time,
  Card,
  ProgressSpinner,
} from '@noon/atom';
import { translationText } from '../../helpers';
import MicIcon from './micIcon';
import { classType, teacherType, translationType, playerListType } from '../../types';
import { COLORS } from '../../constants';
import { SESSION_PREP_END_DATA_EVENT } from '../../redux/constants';

class ClassHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      secondsLeftToSessionStart: 99999999,
      sessionStage: 'showNothing',
    };
    this.sessionCountdown = null;
  }

  componentDidMount() {
    const { sessionData } = this.props;
    if (sessionData.start_time) this.calculateSessionDate(sessionData.start_time);

  }

  componentDidUpdate(prevProps) {
    if (this.props.sessionData !== prevProps.sessionData && this.props.sessionData.start_time) {
      this.calculateSessionDate(this.props.sessionData.start_time);
    }
    // if (this.props.connectionHealth !== prevProps.connectionHealth) {
    //   this.toggleMessageOn(this.props.connectionHealth < ((1 - tokboxStatsCalculation.dying) * 100));
    // }
  }

  componentWillUnmount() {
    if (this.sessionCountdown) clearInterval(this.sessionCountdown);
    const { sendPrepEndEvent, sessionData } = this.props;
    // send prep end event on back navigation

    if (sendPrepEndEvent) sendPrepEndEvent({ ended_by: 'back navigation', sessionData });

  }

  toggleMessageOn = (flag) => {
    this.setState({ showLowSignalStrengthMessage: flag });
  }

  calculateSessionDate = (time) => {
    console.log('calculateSessionDate', time);
    let dt = '';
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(time)) {
      dt = DateTime.fromMillis(time);
    } else {
      dt = DateTime.fromISO(time);
    }
    const rightNow = DateTime.local();
    const obj = dt.diff(rightNow).toObject();
    let seconds = obj.milliseconds / 1000;
    if (this.sessionCountdown) clearInterval(this.sessionCountdown);
    this.sessionCountdown = setInterval(() => {
      seconds -= 1;
      if (seconds > 1800) {
        this.setState({ sessionStage: 'showDateTime' });
      } else if (seconds <= 1800 && seconds > 600) {
        this.setState({ secondsLeftToSessionStart: seconds, sessionStage: 'showCountdown' });
      } else if (seconds <= 600 && seconds > 0) {
        if (this.state.sessionStage !== 'showStartButton') this.setState({ sessionStage: 'showStartButton' });
      } else if (seconds <= 0) {
        // start Session
        this.setState({ sessionStage: 'showStartButton' });
        clearInterval(this.sessionCountdown);
      }
    }, 1000);
  };

  render() {
    const isRtl = document.body.dir === 'rtl';
    const {
      isMute,
      toggleMic,
      teacher,
      understoodCount,
      didntUnderstandCount,
      sessionData,
      noonText,
      startTheClass,
      exitClass,
      precallTest,
      connectionHealth,
      showMicToast,
      rtcRreconnecting,
    } = this.props;
    const { secondsLeftToSessionStart, sessionStage, showLowSignalStrengthMessage } = this.state;
    const classStarted = sessionData.state === 'started';
    const micPopover = {
      isOpen: showMicToast,
      preferPlace: isRtl ? 'left' : 'right',
      body: (
        <div className="text">
          {translationText(this.props.noonText, 'classroom.microPhoneOnn')}
        </div>
      ),
    };

    const onStartClass = () => {
      const { startTheClass, sendPrepEndEvent, sessionData } = this.props;
      sendPrepEndEvent({ ended_by: 'start_session', sessionData });
      startTheClass();
    };

    const onExitClass = () => {
      const { exitClass, sendPrepEndEvent, sessionData } = this.props;
      sendPrepEndEvent({ ended_by: 'save', sessionData });
      exitClass();
    };

    return (
      <Row nowrap align="center" className="classroom-header animated slideInDown">
        <React.Fragment>
          {classStarted ? (
            <div className="player-card">
              {rtcRreconnecting ? (
                <Row align="center" className="relative" title="reconnecting">
                  <MicIcon width="4.5rem" height="4.5rem" active={false} />
                  <IconLoaderDotted height="32px" width="32px" className="mic-loader" />
                </Row>
              ) : (
                <Popover className="microphoneStatusBlue" {...micPopover}>
                  <Row align="center">
                    <div className="volume-bar">
                      <div className="volume-bar__indicator" />
                    </div>
                    <MicIcon width="4.5rem" height="4.5rem" active={!isMute} onToggle={toggleMic} />
                  </Row>
                </Popover>
              )}
              <Avatar rounded url={teacher.profile_pic} className="mlr-1" />

              <div className={classNames('player-info', { placeholder: !teacher.name })}>
                <div className="player-title">{teacher.name}</div>
                <div className="player-score">
                  {teacher.name ? (
                    <span>
                      <IconStar color="#FFE038" height="18px" />
                      {teacher.rating_count ? (teacher.rating_total / teacher.rating_count).toFixed(2) : 0}
                    </span>
                  ) : null}
                </div>
              </div>
              {!!sessionData.start_time && (
                <LiveTime value={sessionData.start_time} rendered>
                  {({ value }) => (
                    <Badge rounded className={classNames('class-timer', { warn: value >= 3600, alert: value >= 6000 })}>
                      <Time value={value} />
                    </Badge>
                  )}
                </LiveTime>
              )}
            </div>
          ) : (
            <Row className="welcome-teacher">
              <Column className="welcome-teacher__image">
                <img src="/assets/images/header-placeholder.png" alt="welcome" />
              </Column>
              <Column className="welcome-teacher__text">
                <h4>{translationText(noonText, 'classroom.welcomeTeacherHeading')}</h4>
                <p>{translationText(noonText, 'classroom.welcomeTeacherText')}</p>
              </Column>
            </Row>
          )}
          <Vr height="100%" />
        </React.Fragment>
        {!classStarted && (
          <div className="session-stage-indicator mlr-2">
            {sessionStage === 'showStartButton' && (
              <Button
                disabled={precallTest}
                className={classNames('start-class-button', { disabled: precallTest })}
                type="green"
                onClick={onStartClass}
                tooltip={precallTest && translationText(noonText, 'toast.precallButtonTooltip')}
                tooltipPos="bottom"
              >
                {translationText(noonText, 'button.startTheClass')}
              </Button>
            )}
            {sessionStage === 'showDateTime' && (
              <div className="show-start">
                <h6 className="show-start--heading">{translationText(noonText, 'classroom.scheduledFor')}</h6>
                <Vr className="start-separator" />
                <div className="date-time">
                  <NoonDate className="show-start--date" value={new Date(sessionData.start_time).toISOString()} />
                  <Time className="show-start--time" withHour unit="epoch" suffix value={sessionData.start_time} />
                </div>
              </div>
            )}
            {sessionStage === 'showCountdown' && (
              <LiveTime value={sessionData.start_time} rendered>
                {({ value }) => (
                  <ProgressSpinner
                    thickness={3}
                    antiClockwise
                    noPointer
                    activeColor={COLORS.brand.base}
                    progress={(value * 100) / 1800}
                    text={<Time value={Math.abs(value)} />}
                    radius={20}
                    fill="#fff"
                  />
                )}
              </LiveTime>
            )}
          </div>
        )}

        {sessionData.state === 'started' && (
          <Column className="reaction-counter">
            <Row className="reaction reaction--happy">
              <IconHappy />
              <LinearPercentageIndicator
                total={100}
                current={`${(understoodCount / (understoodCount + didntUnderstandCount)) * 100}%`}
                bg="#ececec"
                color="#7DBA3A"
              />
            </Row>
            <Row className="reaction reaction--sad">
              <IconSad />
              <LinearPercentageIndicator
                total={100}
                current={`${(didntUnderstandCount / (understoodCount + didntUnderstandCount)) * 100}%`}
                bg="#ececec"
                color="#DD3546"
              />
            </Row>
          </Column>
        )}

        <div className="subject-info">
          <p className="text-light">{sessionData.title}</p>
          {!!sessionData.description && <p>{sessionData.description}</p>}
        </div>

        {classStarted && !isMobileOnly && (
          <React.Fragment>
            <ShareUrl
              url={`${process.env.STUDENT_URL}${localStorage.updatedLocale}/class/${sessionData.id}`}
              button="pastel-blue"
              copyMessageDirection="right"
              buttonText={translationText(noonText, 'button.invite')}
              messageCopied={translationText(noonText, 'label.copied')}
            />
            <div className="signal-strength">
              <SignalIndicator value={connectionHealth || 80} />
              {showLowSignalStrengthMessage && (
                <Card className="signal-strength-message">
                  <IconTooltip color={COLORS.red.base} width="15px" height="15px" rotate="180" />
                  <span>{translationText(noonText, 'tutoring.slowInternetMessage')}</span>
                </Card>
              )}
            </div>
          </React.Fragment>
        )}
        {!isMobileOnly && <span className="vr" />}
        {!isMobileOnly && (
          <Button type="link" size="lg" onClick={onExitClass} className={classNames('exit-button', { preparing: !classStarted })}>
            {translationText(noonText, `competition.${classStarted ? 'exit' : 'saveDraft'}`)}
          </Button>
        )}
        {isMobileOnly && <IconCanvasExit onClick={onExitClass} fill={COLORS.red.base} height="2rem" width="5rem" rotate={isRtl ? 0 : 180} />}
      </Row>
    );
  }
}

ClassHeader.propTypes = {
  sessionData: classType.isRequired,
  teacher: teacherType,
  players: playerListType.isRequired,
  exitClass: PropTypes.func.isRequired,
  startTheClass: PropTypes.func.isRequired,
  noonText: translationType.isRequired,
  isMute: PropTypes.bool,
  precallTest: PropTypes.bool,
  connectionHealth: PropTypes.number.isRequired,
  understoodCount: PropTypes.number.isRequired,
  didntUnderstandCount: PropTypes.number.isRequired,
  toggleMic: PropTypes.func.isRequired,
  sendPrepEndEvent: PropTypes.func.isRequired,
};

ClassHeader.defaultProps = {
  teacher: {},
  isMute: true,
  precallTest: false,
};

const mapDispatchToProps = (dispatch) => ({
  sendPrepEndEvent: (payload) => { dispatch({ type: SESSION_PREP_END_DATA_EVENT, payload }); },
});

const mapStateToProps = () => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClassHeader);

import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { IconLike } from '@noon/quark';

const Template = (prop) => {
  const {
    children,
    onClose,
    closeButtonCustom,
    backDropClassName,
    noBackground = false,
    position = 'center', // custom, center, sidebar
  } = prop;
  const noContent = (
    <div className="no-content">
      <IconLike rotate="180" />
      <h3>No content found</h3>
    </div>
  );
  const noBackgroundClass = noBackground ? 'no-background' : '';
  return (
    <div className="content-on-top">
      {closeButtonCustom ? (
        <div className={`content-on-top__bg ${backDropClassName} ${noBackgroundClass}`}>{closeButtonCustom}</div>
      ) : (
        <div className={`content-on-top__bg ${noBackgroundClass}`} onClick={onClose} />
      )}
      <div className={`content-on-top__body ${position}`}>
        {children || noContent}
      </div>
    </div>
  );
};

// Creates DOM element to be used as React root
function createRootElement(id) {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
}

// Appends element as last child of body
function addRootElement(rootElem) {
  document.body.insertBefore(rootElem, document.body.lastElementChild.nextElementSibling);
}

// hook to create a react portal
function usePortal(id) {
  const rootElemRef = useRef(null);

  useEffect(() => {
    // Look for existing target dom element to append to
    const existingParent = document.querySelector(`#${id}`);
    // Parent is either a new root or the existing dom element
    const parentElem = existingParent || createRootElement(id);

    // If there is no existing DOM element, add a new one.
    if (!existingParent) {
      addRootElement(parentElem);
    }

    // Add the detached element to the parent
    parentElem.appendChild(rootElemRef.current);

    return function removeElement() {
      rootElemRef.current.remove();
      if (parentElem.childNodes.length === -1) {
        parentElem.remove();
      }
    };
  }, []);

  // Putting here instead of useEffect since we want this to run only once
  // Normally this would have gone in constructor
  function getRootElem() {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  }

  return getRootElem();
}

export default function ContentOnTop({ id = 'sidebar-root', ...rest }) {
  const target = usePortal(id);
  console.log('target is', target, id);
  return ReactDOM.createPortal(<Template {...rest} />, target);
}

import {
  DISABLE_CHAT_SUPPORT_PAGES,
} from '../constants';

const setZohoInfo = (status) => {
  window.$zoho.salesiq.floatbutton.visible(status);
  if (localStorage.loggedUser && status === 'show') {
    const user = JSON.parse(localStorage.loggedUser);
    window.$zoho.salesiq.visitor.name(user.name);
    window.$zoho.salesiq.visitor.email(user.email);
    window.$zoho.salesiq.visitor.info({
      subject: user.subject,
      grade: user.grade,
      gender: user.gender,
      user_id: user.id,
    });
  }
};

const zohoIntegration = (pathname) => {
  if (window.$zoho && window.$zoho.salesiq && window.$zoho.salesiq.floatbutton) {
    const status = DISABLE_CHAT_SUPPORT_PAGES.includes(pathname.split('/')[2]) ? 'hide' : 'show';
    setZohoInfo(status);
  } else {
    setTimeout(() => {
      const isZohoAvailable = window.$zoho && window.$zoho.salesiq && window.$zoho.salesiq.floatbutton;
      const status = DISABLE_CHAT_SUPPORT_PAGES.includes(pathname.split('/')[2]) ? 'hide' : 'show';
      if (isZohoAvailable) setZohoInfo(status);
    }, 5000);
  }
};

export default zohoIntegration;

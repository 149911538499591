import {
  COUNTRY_LOCALE_PATTERN,
} from '../constants';

function generateLocalizedUrl(pathname, localizedUrlPrefix) {
  let url = null;
  const pathArray = pathname.replace(/^\//g, '').split('/');
  if (!pathArray.length) {
    url = `/${localizedUrlPrefix}`;
  } else if (pathArray[0] !== localizedUrlPrefix) {
    if (COUNTRY_LOCALE_PATTERN.test(pathArray[0])) {
      // if localized-prefix already added, replace it with new localized-prefix.
      pathArray.splice(0, 1, localizedUrlPrefix);
    } else {
      // if localized-prefix not added, add it to the url
      pathArray.unshift(localizedUrlPrefix);
    }
    // form new url using localized prefix
    url = `/${pathArray.join('/')}`;
  }
  return url;
}

export default generateLocalizedUrl;

// locale name examplee : sa-ar , in-en
function getLocaleName(pathname) {
  const url = null;
  const pathArray = pathname.replace(/^\//g, '').split('/');
  if (pathArray[0] && COUNTRY_LOCALE_PATTERN.test(pathArray[0])) {
    return pathArray[0];
  }
  return url;
}

export { generateLocalizedUrl, getLocaleName };

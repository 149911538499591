import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconArrowDown } from '@noon/quark';
import { Row, Column, Button } from '@noon/atom';
import { translationText } from '../../helpers';
import { COLORS } from '../../constants';

export default function WelcomeReturningUser(prop) {
  const { onClose } = prop;
  const [currentSlide, changeActiveSlide] = useState(0);
  const isRTL = document.body.dir === 'rtl';
  const { noonText } = useSelector((state) => state.toJS().translation);
  const slides = [
    { btnText: 'button.letsGo' },
    { btnText: 'button.okayGotIt' },
    { btnText: 'button.okayGotIt' },
    { btnText: 'button.okayGotIt' },
    { btnText: 'button.okayGotIt' },
    { btnText: 'button.getStarted' },
  ];

  const handleButtonClick = () => {
    if (currentSlide + 1 < slides.length) changeActiveSlide(currentSlide + 1);
    else onClose();
  };

  return (
    <React.Fragment>
      <Column className="welcome__content" align="center">
        <Row className="image-area" align="center" justify="center">
          <img src={`/assets/images/2020/Step${currentSlide}Graphic.svg`} alt="Step 1" />
        </Row>
        <Column className="text-area" align="center" justify="space-around">
          <Column align="center" className="text-wrapper">
            <h2 className="heading">{translationText(noonText, `coachmark.teacherSlide${currentSlide + 1}Heading`)}</h2>
            <p className="paragraph">{translationText(noonText, `coachmark.teacherSlide${currentSlide + 1}Subheading`)}</p>
          </Column>
          <Column className="button-wrapper">
            <Button onClick={() => handleButtonClick()} type={currentSlide === 0 || currentSlide + 1 === slides.length ? 'primary' : 'secondary'}>{translationText(noonText, slides[currentSlide].btnText)}</Button>
            {currentSlide === 0 && <Button onClick={onClose} className="mt-1" type="link">{translationText(noonText, 'button.noThanks')}</Button>}
          </Column>
        </Column>
      </Column>
      {currentSlide > 0 && (
        <React.Fragment>
          <Row className="welcome__dots" justify="center">
            {slides.map((item, index) => (
              <span className={`dot ${index === currentSlide ? 'active' : ''}`} onClick={() => changeActiveSlide(index)} />
            ))}
          </Row>
          <Row className="welcome__arrows" justify={currentSlide > 1 ? 'space-between' : currentSlide < slides.length ? 'end' : 'start'}>
            {currentSlide > 1 && <div className="arrow-button backward" onClick={() => changeActiveSlide(currentSlide - 1)}><IconArrowDown height="20px" width="20px" fill={COLORS.brand.base} stroke={COLORS.brand.base} rotate={isRTL ? '270' : '90'} /></div>}
            {currentSlide + 1 < slides.length && <div className="arrow-button forward" onClick={() => changeActiveSlide(currentSlide + 1)}><IconArrowDown height="20px" width="20px" fill={COLORS.brand.base} stroke={COLORS.brand.base} rotate={isRTL ? '90' : '270'} /></div>}
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

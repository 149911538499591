import React from 'react';

const CreationHomeworkTip = ({ text }) => {
  return (
    <div className="prepare-assignment--autoGenerateTip">
      {text}
    </div>
  )
}

export default CreationHomeworkTip;

import { Button, Card, Row, Search, Column, Avatar, NoonDate } from '@noon/atom';
import classnames from 'classnames';
import { IconBack, IconLoader } from '@noon/quark';
import { isEmpty } from 'lodash-es';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COLORS, isRTL } from '../../constants';
import { translationText } from '../../helpers';
import { LIST_APPROVED_PAYMENT, LIST_PENDING_CASH_PAYMENT, UPDATE_CASH_PAYMENT_STATE } from '../../redux/constants';
import AcceptPaymentConfirm from '../../components/Payments/acceptPaymentConfirm';
import PageHeader from '../../components/Layout/pageHeader';
import ScrollToTop from '../../components/ScrollToTop';

const TABS = ['new', 'purchased'];
const INDIA_COUNTRY_ID = 6;
function Payments() {
  const { noonText } = useSelector((state) => state.toJS().translation);
  const { transactions, updatePaymentState } = useSelector((state) => state.toJS().packages);
  const { loggedUser } = useSelector((state) => state.toJS().user);
  const logger = useRef(LoggingManager.mount({ moduleName: 'group' })).current;
  const dispatch = useDispatch();
  const [showAcceptPaymentConfirm, setShowAcceptPaymentConfirm] = useState(false);
  const [activeTab, setActiveTab] = useState(TABS[0]);
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const [searchText, setSearchText] = useState('');
  const tableElement = useMemo(() => document.getElementById('table-payment'), [activeTab]);

  const handleOnClickAccept = (transaction, type) => {
    setShowAcceptPaymentConfirm(type);
    setSelectedTransaction(transaction);
  };
  const handleOnClickDecline = (transaction) => {
    setSelectedTransaction(transaction);
    setShowAcceptPaymentConfirm('');
    dispatch({
      type: UPDATE_CASH_PAYMENT_STATE.REQUEST,
      payload: {
        reference_id: transaction.reference_id,
        state: 'decline',
      },
    });
  };

  const getTransactions = (payload = {}) => {
    if (activeTab === TABS[0]) {
      dispatch({
        type: LIST_PENDING_CASH_PAYMENT.REQUEST,
        payload: { ...payload, reference_id: searchText },
      });
    } else {
      dispatch({
        type: LIST_APPROVED_PAYMENT.REQUEST,
        payload: { ...payload, invoice_id: searchText },
      });
    }
  };

  const handleOnClickSearch = () => {
    getTransactions();
  };

  useEffect(() => () => {
    LoggingManager.unmount(logger.id);
  }, []);

  useEffect(() => {
    getTransactions();
  }, [activeTab]);

  return (
    <React.Fragment>
      <PageHeader title={translationText(noonText, 'tab.payments')} />
      <div className="container">
        <Card className="payments table">
          <Row id="table-payment" align="center" justify="start" gap className="ptb-2 table-filter">
            <Row gap="sm">
              <Button onClick={() => setActiveTab(TABS[0])} link value={translationText(noonText, 'button.newPurchases')} className={classnames('tab', { 'tab-active': activeTab === TABS[0] })} />
              <Button onClick={() => setActiveTab(TABS[1])} link value={translationText(noonText, 'button.purchases')} className={classnames('tab', { 'tab-active': activeTab === TABS[1] })} />
            </Row>
            <Row nowrap gap="sm" align="center" className="flex-2">
              <Search
                name="book-search"
                autoComplete="off"
                placeholder={translationText(noonText, 'placeholder.searchRefNo')}
                onChange={(event) => setSearchText(event.target.value)}
                className="search-input full-width"
          />
              <Button onClick={handleOnClickSearch} className="text-capitalize" value={translationText(noonText, 'tab.search')} />
            </Row>
          </Row>
          {transactions.loading && isEmpty(transactions.list)
            ? (
              <Row justify="center" align="center" className="h-half">
                <IconLoader />
              </Row>
            )
            : (
              <table className={classnames(activeTab)}>
                <thead>
                  <tr>
                    <th className="text-capitalize">
                      {translationText(noonText, 'label.date')}
                    </th>
                    <th>
                      {translationText(noonText, 'label.studentDetails')}
                    </th>
                    <th>
                      {translationText(noonText, 'label.groupOrCollectionName')}
                    </th>
                    <th>
                      {translationText(noonText, 'label.referenceNo')}
                    </th>
                    <th>
                      {translationText(noonText, 'label.yourEarnings')}
                    </th>
                    {activeTab === TABS[0]
                 && (
                 <th>
                   {translationText(noonText, 'label.confirmRequest')}
                 </th>
                 )}
                  </tr>
                </thead>
                <tbody>
                  {transactions.list.map((transaction) => (
                    <tr key={transaction.reference_id || transaction.invoice_id}>
                      <td><NoonDate value={Number(transaction.created_at)} format="DD" /></td>
                      <td>
                        <Row justify="start" align="start" gap="sm" nowrap>
                          <Avatar size="40px" url={transaction.user.profile_pic} noShadow rounded gender={transaction.user.gender} />
                          <Column>
                            <span className="child">{transaction.user.name}</span>
                          </Column>
                        </Row>
                      </td>
                      <td>{transaction.group.title}</td>
                      <td>{transaction.reference_id || transaction.invoice_id}</td>
                      <td>
                        <Column align="start">
                          {transaction.earning.teacher_share
                            ? (
                              <>
                                {(transaction.earning.teacher_share / (10 ** transaction.subscription.decimal_places)).toFixed(2)}
                                {' '}
                                {transaction.subscription.currency_symbol}
                              </>
                            )
                            : 'N/A'}
                          <Button link onClick={() => handleOnClickAccept(transaction, 'details')}>
                            {translationText(noonText, 'button.viewDetails')}
                            {' '}
                            <IconBack flipX={!isRTL()} fill={COLORS.brand.base} />
                          </Button>
                        </Column>
                      </td>
                      {activeTab === TABS[0]
                 && (
                 <td>
                   {
                     (transaction.vendor === 'cash' && loggedUser && Number(loggedUser.country_id) === INDIA_COUNTRY_ID) ? <div style={{ color: 'grey' }}>Contract Admin</div>
                       : (
                         <Row align="center" justify="start" gap="sm" nowrap>
                           <Button
                             onClick={() => handleOnClickAccept(transaction, 'accept')}
                             type="primary"
                             value={translationText(noonText, 'button.accept')}
                             className="text-capitalize" />
                           <Button
                             onClick={() => handleOnClickDecline(transaction)}
                             type="secondary"
                             value={translationText(noonText, 'button.decline')}
                             loading={updatePaymentState.reference_id === transaction.reference_id && updatePaymentState.state === 'decline'}
                             disabled={updatePaymentState.reference_id === transaction.reference_id && updatePaymentState.state === 'decline'}
                             className="text-capitalize" />
                         </Row>
                       )
                   }
                 </td>
                 )}
                    </tr>
                  ))}
                  {isEmpty(transactions.list) && (
                  <tr>
                    <td>
                      {translationText(noonText, 'error.noResourceFound')}
                    </td>
                  </tr>
                  )}
                </tbody>
              </table>
            )}
        </Card>
        {transactions.meta && transactions.meta.page
        && (
        <Row gap="sm" align="center" justify="center" className="ptb-2 plr-2 full-width">
          <Button
            loading={transactions.loading}
            onClick={() => getTransactions({ page: transactions.meta.page })}
            type="primary"
            size="lg"
            value={translationText(noonText, 'dashboard.loadMore')} />
        </Row>
        )}
      </div>
      <ScrollToTop element={tableElement} />
      {showAcceptPaymentConfirm && (
      <AcceptPaymentConfirm
        transaction={selectedTransaction}
        updatePaymentState={updatePaymentState}
        noonText={noonText}
        viewDetails={showAcceptPaymentConfirm === 'details' || (selectedTransaction.vendor === 'cash' && loggedUser && Number(loggedUser.country_id) === INDIA_COUNTRY_ID)}
        onClose={() => setShowAcceptPaymentConfirm('')} />
      )}
    </React.Fragment>
  );
}

export default Payments;

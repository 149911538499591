import React from 'react';
import cx from 'classnames';

import { IconDoc, IconPdf, IconPlus, IconPpt, IconTxt } from '@noon/quark';
import { humanizeFileSize } from 'helpers';
import { ProgressSpinner } from '@noon/atom';

const getFileIcon = (filetype) => {
  if (filetype.includes('image')) {
    return false;
  } else if (filetype.includes('pdf')) {
    return 'pdf';
  } else if (filetype.includes('doc')) {
    return 'doc';
  } else if (filetype.includes('text')) {
    return 'txt';
  }

  return 'ppt';
};
class FileItem extends React.PureComponent {
  render() {
    const { item, imageUploadProgressStatus, removeImage, submitted } = this.props;
    const { file, id } = item;
    const imageFound = imageUploadProgressStatus.find(x => x.id === id);
    return (
      <div
        className={cx('upload-file-form__file-item', {
          isUploading: !!imageFound,
        })}
      >
        {!!imageFound && imageFound.percentCompleted < 100 && !submitted && (
          <div>
            <ProgressSpinner
              progress={imageFound && imageFound.percentCompleted ? imageFound.percentCompleted - 0.01 : 0}
              text={`${imageFound && imageFound.percentCompleted ? imageFound.percentCompleted : 0}%`}
              radius={20}
              noPointer
              noShadow
              activeColor="#1199ff"
              color="#1199ff"
            />
          </div>
        )}
        <div className="upload-file-form__file-item-container">
          {getFileIcon(file.type) ? (
            getFileIcon(file.type) === 'doc'
              ? <IconDoc height="5rem" width="5rem" />
              : getFileIcon(file.type) === 'ppt'
                ? <IconPpt height="5rem" width="5rem" />
                : getFileIcon(file.type) === 'pdf'
                  ? <IconPdf height="5rem" width="5rem" />
                  : <IconTxt height="5rem" width="5rem" />
          ) : <img src={URL.createObjectURL(file)} alt="comment" />}
          <div className="upload-file-form__file-meta">
            <span>{file.name}</span>
            <span className="upload-file-form__file-size">{humanizeFileSize(file.size)}</span>
          </div>
        </div>
        {submitted && (
          <div>
            <IconPlus onClick={() => removeImage(id)} height="1.5rem" rotate={45} />
          </div>
        )}
      </div>
    );
  }
}

export default FileItem;

import React, { useState, useEffect, useRef } from 'react';
import { Row, NoonDate, MoreActions } from '@noon/atom';
import { IconBack, IconFlag, IconEdit, IconDelete, IconClose } from '@noon/quark';
import PropTypes from 'prop-types';
import get from 'lodash-es/get';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { translationText } from '../../helpers';
import { ConfirmationModal } from '../Modals';
import { DELETE_HOMEWORK_SPARTA } from '../../redux/constants';
import EditAssignmentModal from '../PrepareAssignment/editAssignmentModal';
import { checkIsMobile, checkIsTablet, returnDuplicatePropsForMixpanelAndKafka } from '../../helpers/prepareAssignment';
import Mixpanel from '../Mixpanel';

const ReportPageHeader = (props) => {
  const { noonText, isBarOpen, setIsBarOpen } = props;

  const [assignedAction, setAssignedAction] = useState('');
  const logger = useRef(LoggingManager.mount({ moduleName: 'homework' })).current;
  const { deleteHomework, hw_details = {} } = useSelector((state) => state.toJS().homeworkCreation);
  const { students_response_viewed } = useSelector((state) => state.toJS().homeworkReport);
  const { loggedUser: user } = useSelector((state) => state.toJS().user);
  const { selectedGroup } = useSelector((state) => state.toJS().groupsV2);

  const history = useHistory();
  const dispatch = useDispatch();
  const isRtl = document.body.dir === 'rtl';

  useEffect(() => {
    if (assignedAction === 'exit') history.push(`/groups/${hw_details.group_id}/content`);
  }, [assignedAction]);

  const getOptions = () => {
    const moreActionList = [
      {
        name: 'delete',
        icon: IconDelete,
        text: translationText(noonText, 'classroom.deleteSlideConfirm'),
      },
    ];
    if (hw_details.state !== 'ended') {
      moreActionList.unshift({
        name: 'extend',
        icon: IconEdit,
        text: translationText(noonText, 'homeworkReport.extendDueDate'),
      });
    }
    if (checkIsMobile()) {
      moreActionList.push({
        name: 'exit',
        icon: IconClose,
        text: translationText(noonText, 'competition.exit'),
      });
    }
    return moreActionList;
  };

  const mixpanelKafkaEvents = (type) => {
    const props = returnDuplicatePropsForMixpanelAndKafka(selectedGroup, user, hw_details);
    const no_of_questions = hw_details && hw_details.question_ids ? hw_details.question_ids.length : 0;
    const properties = {
      no_of_questions,
      ...type.kf === 'report_exited' && { no_students_viewed: students_response_viewed || 0 },
      ...props,
    };
    Mixpanel.track(type.mp, properties);
    logger.track(type.kf, { homework: properties });
  };

  const deleteAssignmentFn = () => {
    mixpanelKafkaEvents({ mp: 'homework_deleted', kf: 'deleted' });
    dispatch({
      type: DELETE_HOMEWORK_SPARTA.REQUEST,
      payload: hw_details.id,
    });
  };

  const backTo = () => {
    if ((checkIsMobile() || checkIsTablet()) && isBarOpen) {
      setIsBarOpen();
    } else {
      mixpanelKafkaEvents({ mp: 'homework_report_exited_teacher', kf: 'report_exited' });
      history.push(`/${localStorage.updatedLocale}/groups/${hw_details.group_id}/content`);
    }
  };

  return (
    <Row align="center" justify="space-between" className="report--header">
      <div className="report--header--details">
        <Row align="center">
          {(checkIsMobile() || (checkIsTablet() && isBarOpen))
            && (
            <IconBack
              height="21px"
              width="12px"
              fill={isBarOpen && checkIsTablet() ? '#000000' : '#ffffff'}
              stroke={isBarOpen && checkIsTablet() ? '#000000' : '#ffffff'}
              onClick={() => backTo()}
              rotate={isRtl ? 180 : 0}
            />
            )}
          <span className="title">{isBarOpen ? translationText(noonText, 'homeworkReport.mainReport') : hw_details ? hw_details.title : ''}</span>
        </Row>
        <Row align="center" className="report--header--info" style={{ display: checkIsTablet() && isBarOpen ? 'none' : 'flex' }}>
          <span className="amount-of-questions">
            {get(hw_details, ['question_ids']) ? get(hw_details, ['question_ids']).length : ''}
            {' '}
            {translationText(noonText, 'library.questions')}
          </span>
          <IconFlag
            height="16px"
            width="16px"
            fill={checkIsMobile() ? '#ffffff' : '#333333'}
            stroke={checkIsMobile() ? '#ffffff' : '#333333'}
          />
          <span className="due-on">{translationText(noonText, 'homeworkReport.dueOn')}</span>
          <NoonDate className="date" value={hw_details.due_date} format="MMMM d, hh:mm a" />
        </Row>
      </div>
      <Row align="center" className="report--header--options">
        <MoreActions
          type="dots"
          position={isRtl ? 'right' : 'left'}
          className="activity"
          listActions={getOptions()}
          cardId={hw_details.id}
          onSelect={(actionName) => setAssignedAction(actionName)}
          vertical
        />
        {!checkIsMobile() && <button className="secondary-button" onClick={backTo}>{translationText(noonText, 'competition.exit')}</button>}
      </Row>
      {assignedAction === 'delete' && (
        <ConfirmationModal
          modalType="delete"
          successBtn={translationText(noonText, 'activity.modalYes')}
          closeBtn={translationText(noonText, 'activity.modalNo')}
          onClose={() => setAssignedAction('')}
          onSuccess={() => deleteAssignmentFn()}
          isLoading={deleteHomework.loading}
          text={translationText(noonText, 'teacherDashboard.deleteHomework')}
          subText={translationText(noonText, 'teacherDashboard.deleteConfirmation')}
        />
      )}
      {assignedAction === 'extend' && (
        <EditAssignmentModal
          setIsEditOpen={() => setAssignedAction('')}
          homework={hw_details}
          extend
        />
      )}
    </Row>
  );
};

ReportPageHeader.propTypes = {
  noonText: PropTypes.shape().isRequired,
  isBarOpen: PropTypes.bool.isRequired,
  setIsBarOpen: PropTypes.func.isRequired,
};

// ReportPageHeader.defaultProps = {

// };

export default ReportPageHeader;

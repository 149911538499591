import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IconArrowDown } from '@noon/quark';
import { Button, Column } from '@noon/atom';

function ScrollToTop({ element, text }) {
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  const inViewPort = (entries) => {
    if (entries[0].boundingClientRect.y < 0) {
      setShowScrollToTop(true);
    } else {
      setShowScrollToTop(false);
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (element) {
      const observer = new IntersectionObserver(inViewPort, {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      });

      try {
        observer.observe(element);
      } catch (error) {
        console.log(console.error());
      }
    }
  }, [element]);

  return (
    showScrollToTop
      && (
        <Column align="center" justify="center" className="btn-scroll-to-top" onClick={handleScrollToTop}>
          <Button type="secondary" size="lg" fab>
            <IconArrowDown height="90px" width="90px" flipY />
          </Button>
          {text && <span>{text}</span>}
        </Column>
      )
  );
}

ScrollToTop.propTypes = {
  element: PropTypes.shape({}).isRequired,
  text: PropTypes.string,
};

ScrollToTop.defaultProps = {
  text: '',
};

export default ScrollToTop;

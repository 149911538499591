import { put, call, takeEvery, all, delay, select } from 'redux-saga/effects';
import isEmpty from 'lodash-es/isEmpty';
import { athenaApi, athenaApiV2 } from '../restApi';

import { GET_ORIGINAL_PLAYBACK_DETAILS, GET_EDITED_PLAYBACK_DETAILS, GET_TIMESERIES_DATA, GET_SNAPSHOT_DATA, UPDATE_PLAYBACK_SECTIONS, PLAYBACK_START, DELETE_PLAYBACK_SLIDE, TRIM_PLAYBACK_SLIDE, GET_BUFFERED_TIMESERIES, PLAYBACK_LOAD_NEXT_DATA } from '../constants';

const originalPlaybackDetailsAPI = ({ session_id }) => athenaApi.get(`original/playback/${session_id}`);
const editedPlaybackDetailsAPI = ({ session_id }) => athenaApiV2.get(`playback/teacher/class/${session_id}?action=trimming`);
const getTimeseriesDataAPI = ({ session_id, last_seek }) => athenaApi.get(`replay/timeseries/${session_id}?timestamp=${last_seek}`);
const getSnapshotDataAPI = ({ session_id, last_seek }) => athenaApi.get(`replay/seekData/${session_id}?timestamp=${last_seek}`);
const deleteSlideAPI = (payload) => athenaApi.post('playback/delete/slide', payload);
const trimSlideAPI = (payload) => athenaApi.post('playback/trim/slide', payload);
const getPlaybackData = (state) => state.toJS().playback;
let paused = false;

function roundOffMinute(time) {
  const d = new Date(time);
  // if (d.getSeconds() >= 59) {
  //   d.setMinutes(d.getMinutes() + 1);
  //   d.setSeconds(0);
  // }
  d.setSeconds(0);
  return d.getTime();
}

function* shouldResume() {
  const { playerData, snapShotData, timeSeriesData, chatData } = yield select(getPlaybackData);
  const { pausedByUser } = playerData;
  const canResume = yield !snapShotData.loading && !timeSeriesData.loading && !pausedByUser;
  return canResume;
}

const playBackSectionData = (state) => state.toJS().playback.playback_sections;

function* getOriginalPlaybackDetails({ payload }) {
  const { session_id, delayCallBy } = payload;
  try {
    yield delay(delayCallBy);
    const response = yield call(originalPlaybackDetailsAPI, payload);
    if (response.ok && response.status === 200) {
      yield put({
        type: GET_ORIGINAL_PLAYBACK_DETAILS.SUCCESS,
        payload: !isEmpty(response.data.data) ? response.data.data : {},
        retries: payload.retries || 0,
      });
      yield put({ type: GET_EDITED_PLAYBACK_DETAILS.REQUEST, payload: { session_id, delayCallBy } });

    } else {
      yield put({ type: GET_ORIGINAL_PLAYBACK_DETAILS.FAILURE, payload: response.data.message });
    }

  } catch (err) {
    yield put({
      type: GET_ORIGINAL_PLAYBACK_DETAILS.FAILURE,
      payload: err,
    });
  }
}

function* getEditedPlaybackDetails({ payload }) {
  try {
    // yield delay(payload.delayCallBy);
    const response = yield call(editedPlaybackDetailsAPI, payload);
    if (response.ok && response.status === 200) {
      yield put({
        type: GET_EDITED_PLAYBACK_DETAILS.SUCCESS,
        payload: !isEmpty(response.data.data) ? response.data.data : {},
        retries: payload.retries || 0,
      });
    } else {
      yield put({ type: GET_EDITED_PLAYBACK_DETAILS.FAILURE, payload: response.data.message });
    }

  } catch (err) {
    yield put({
      type: GET_EDITED_PLAYBACK_DETAILS.FAILURE,
      payload: err,
    });
  }
}

function* getTimeseriesData({ payload }) {
  try {
    const roundOffTime = yield roundOffMinute(payload.last_seek);

    const requestPayload = yield {
      ...payload,
      last_seek: roundOffTime,
    };

    const response = yield call(getTimeseriesDataAPI, requestPayload);
    if (response.ok && response.status === 200) {
      yield put({
        type: GET_TIMESERIES_DATA.SUCCESS,
        payload: !isEmpty(response.data.data) ? response.data.data[0].values : {},
        // retries: payload.retries || 0,
      });

      // Buffer next timeseries data
      const bufferDate = yield new Date(roundOffTime);
      bufferDate.setMinutes(bufferDate.getMinutes() + 1);
      const bufferedTime = bufferDate.getTime();

      const bufferedRequestPayload = yield {
        ...payload,
        last_seek: bufferedTime,
      };

      yield put({ type: GET_BUFFERED_TIMESERIES.REQUEST, payload: bufferedRequestPayload });

      // resume playback
      const resume = yield shouldResume();
      if (resume && paused) {
        yield put({ type: PLAYBACK_START });
        paused = yield false;
      }
    } else {
      yield put({ type: GET_TIMESERIES_DATA.FAILURE, payload: response.data.problem });
    }
  } catch (err) {
    yield put({
      type: GET_TIMESERIES_DATA.FAILURE,
      payload: err,
    });
    console.log(err);
  }
}

function* getBufferedTimeseriesData({ payload }) {
  try {
    yield delay(2000);
    const updatedPayload = { ...payload, last_seek: yield roundOffMinute(payload.last_seek) };
    const response = yield call(getTimeseriesDataAPI, updatedPayload);
    if (response.ok && response.status === 200) {
      const data = response.data.data[0].values;
      yield put({
        type: GET_BUFFERED_TIMESERIES.SUCCESS,
        payload: data,
      });
    } else {
      yield put({ type: GET_TIMESERIES_DATA.FAILURE, payload: response.data.problem });
    }
  } catch (err) {
    console.log(err);
    yield put({
      type: GET_TIMESERIES_DATA.FAILURE,
      payload: err,
    });
  }
}

function* getSnapshotData({ payload }) {
  try {
    // stop the playback
    const response = yield call(getSnapshotDataAPI, payload);
    if (response.ok && response.status === 200) {
      yield put({
        type: GET_SNAPSHOT_DATA.SUCCESS,
        payload: !isEmpty(response.data.data) ? response.data.data : {},
      });

      yield put({ type: GET_TIMESERIES_DATA.REQUEST, payload });

      const resume = yield shouldResume();
      if (resume && paused) {
        yield put({ type: PLAYBACK_START });
        paused = yield false;
      }
    } else {
      yield put({ type: GET_SNAPSHOT_DATA.FAILURE, payload: response.data.problem });
    }
  } catch (err) {
    yield put({
      type: GET_SNAPSHOT_DATA.FAILURE,
      payload: err,
    });
  }
}

function* deleteSlide({ payload }) {
  try {
    const response = yield call(deleteSlideAPI, payload);
    if (response.ok && response.status === 200) {

      const data = yield select(playBackSectionData);
      const responseData = data.data;
      const objIndex = responseData.findIndex(((obj) => obj.canvas_id === payload.canvas_id));
      const updatedValue = { ...responseData[objIndex], delete: payload.delete };
      const filterData = [
        ...responseData.slice(0, objIndex),
        updatedValue,
        ...responseData.slice(objIndex + 1),
      ];
      yield put({
        type: UPDATE_PLAYBACK_SECTIONS,
        payload: {
          data: filterData,
        },
      });

      yield put({
        type: DELETE_PLAYBACK_SLIDE.SUCCESS,
        payload: {
          canvas_id: payload.canvas_id,
          deleted: payload.delete,
        },
      });
    } else {
      yield put({ type: DELETE_PLAYBACK_SLIDE.FAILURE, payload: response.data.message });
    }

  } catch (err) {
    yield put({
      type: DELETE_PLAYBACK_SLIDE.FAILURE,
      payload: err,
    });
  }
}

function* trimSlide({ payload }) {
  try {
    const { intervals, canvas_id } = payload;
    const response = yield call(trimSlideAPI, payload);
    if (response.ok && response.status === 200) {
      yield put({
        type: TRIM_PLAYBACK_SLIDE.SUCCESS,
        payload: {
          intervals,
          canvas_id,
        },
      });
    } else {
      yield put({ type: TRIM_PLAYBACK_SLIDE.FAILURE, payload: response.data.message });
    }

  } catch (err) {
    yield put({
      type: TRIM_PLAYBACK_SLIDE.FAILURE,
      payload: err,
    });
  }
}

export function* playbackSaga() {
  yield all([
    takeEvery(GET_ORIGINAL_PLAYBACK_DETAILS.REQUEST, getOriginalPlaybackDetails),
    takeEvery(GET_EDITED_PLAYBACK_DETAILS.REQUEST, getEditedPlaybackDetails),
    takeEvery(GET_TIMESERIES_DATA.REQUEST, getTimeseriesData),
    takeEvery(GET_BUFFERED_TIMESERIES.REQUEST, getBufferedTimeseriesData),
    takeEvery(GET_SNAPSHOT_DATA.REQUEST, getSnapshotData),
    takeEvery(DELETE_PLAYBACK_SLIDE.REQUEST, deleteSlide),
    takeEvery(TRIM_PLAYBACK_SLIDE.REQUEST, trimSlide),
    takeEvery(PLAYBACK_LOAD_NEXT_DATA, getBufferedTimeseriesData),
  ]);
}

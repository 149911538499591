export default function getQueryItem(search, param) {
  let query = [];
  if (search) {
    query = search
      .replace('?', '')
      .split('&')
      .filter(o => o.indexOf(param) !== -1);
    query = query.length ? query[0].split('=') : [];
  }
  return query.length ? query[1] : null;
}

export function getQueryObject(search, param) {
  let query = [];
  const obj = {};
  if (search) {
    query = search
      .replace('?', '')
      .split('&')
      .filter(o => o.indexOf(param) !== -1);

    if (query.length) {
      query.map((o) => {
        const [key, value] = o.split('=');
        obj[key] = value;
      });
    }
  }
  return obj;
}

export function objToQuery(obj = {}, prefix = '') {
  if (!Object.keys(obj).length) return '';
  const params = [];
  for (const x in obj) {
    if (obj[x]) {
      params.push(`${x}=${obj[x]}`);
    }
  }
  return `${prefix}${params.join('&')}`;
}

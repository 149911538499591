import { put, call, takeEvery, all, select } from 'redux-saga/effects';
import isEmpty from 'lodash-es/isEmpty';
import {
  allPostsSelector,
  commentsForAllPostsSelector,
  queueForPostSelector,
  likePostSelector,
  likeCommentSelector,
} from 'redux/selectors/posts';

import {
  groupDetailsSelector,
} from 'redux/selectors/groupsV2';
import { postApi } from '../restApi';
import { addToast, TOAST_TYPE } from '../../components/Toast';

import store from '../../store';

import {
  GET_ALL_POSTS,
  GET_POST_BY_ID,
  CREATE_POST,
  GET_COMMENTS,
  CREATE_COMMENT,
  DELETE_COMMENT,
  DELETE_POST,
  LIKE_POST,
  SEARCH_AND_FILTER,
  LIKE_COMMENT,
  QUEUE_FOR_POST,
  QUEUE_FOR_COMMENT,
  CREATE_POLL_POST,
  UPDATE_POLL_POST,
  GET_COMMENT_POSITION,
  NOTIFICATION_COMMENT_DATA,
  NOTIFICATION_POST_DATA,
} from '../constants';
import Mixpanel from '../../components/Mixpanel';

const getPostsApi = (payload) => postApi.get(`teacher/group_post/${payload.id}`, { start: payload.start, limit: payload.limit, creator_type: payload.creator_type, next_tag: payload.next_tag });
const searchPostsApi = (payload) => postApi.get(`search_post/${payload.id}`, { start: payload.start, limit: payload.limit, creator_type: payload.creator_type, next_tag: payload.next_tag, text: payload.text });
const getPostByIdApi = (payload) => postApi.get(`post/${payload.id}`);
const getCommentsApi = (payload) => postApi.get(`post/${payload.id}/comment`, { start: payload.start, limit: payload.limit, next_tag: payload.next_tag });
const sendCommentApi = (payload) => postApi.post(`post/${payload.postId}/comment`, {
  group_id: payload.groupId,
  text: payload.text ? payload.text : undefined,
  files: payload.files,
});
const sendPostApi = (payload) => postApi.post('post', {
  group_id: payload.id,
  text: payload.text,
  is_free: payload.isFree,
  files: payload.files,
});
const deleteCommentApi = (payload) => postApi.delete(`comment/${payload}`);
const createPollPostApi = (payload) => postApi.post('poll', {
  text: payload.text,
  group_id: payload.groupId,
  active_hours: payload.active_hours,
  choices: payload.choices,
  is_free: payload.is_free,
});
const updatePollPostApi = (payload) => postApi.put(`post/${payload.id}/manage_poll`, {
  active_hours: payload.active_hours,
});
const deletePostApi = (payload) => postApi.delete(`post/${payload}`);
const likePostApi = (payload) => postApi.put(`post/${payload.postId}/like`, {
  group_id: payload.groupId,
});
const unlikePostApi = (payload) => postApi.put(`post/${payload.postId}/unlike`, {
  group_id: payload.groupId,
});
const likeCommentApi = (payload) => postApi.put(`comment/${payload.commentId}/like`, {
  group_id: payload.groupId,
  post_id: payload.postId,
});
const unlikeCommentApi = (payload) => postApi.put(`comment/${payload.commentId}/unlike`, {
  group_id: payload.groupId,
  post_id: payload.postId,
});
const getCommentPositionApi = (payload) => postApi.get(`commentPosition/${payload.commentId}`);

function* getPosts({ payload }) {
  try {
    yield put({
      type: GET_ALL_POSTS.LOADING,
      payload: { isLoading: payload.id },
    });
    const response = yield call(payload.text ? searchPostsApi : getPostsApi, payload);
    if (response.ok) {
      const likeCountObj = yield select(likePostSelector);
      response.data.data.forEach((post) => {
        likeCountObj[post.id] = post.is_liked_by_me ? 'likePost' : 'unlikePost';
      });
      yield put({
        type: LIKE_POST.SUCCESS,
        payload: likeCountObj,
      });
      yield put({
        type: GET_ALL_POSTS.SUCCESS,
        payload: !isEmpty(response.data.data) || payload.start > 0
          ? { list: response.data.data, start: payload.start, meta: response.data.meta }
          : { list: [], start: 0, meta: {} },
      });
    } else {
      yield put({ type: GET_ALL_POSTS.FAILURE, payload: response.data.message });
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
    }
  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: GET_ALL_POSTS.FAILURE, payload: err });
  } finally {
    yield put({
      type: GET_ALL_POSTS.LOADING,
      payload: { isLoading: 0 },
    });
  }
}

function* getCommentPosition({ payload }) {
  try {
    yield put({
      type: GET_COMMENT_POSITION.LOADING,
      payload: { isLoading: payload.commentId },
    });
    const response = yield call(getCommentPositionApi, payload);
    if (response.ok) {
      yield put({
        type: GET_COMMENT_POSITION.SUCCESS,
        payload: !isEmpty(response.data.data) ? response.data.data : 0,
      });
    } else {
      yield put({ type: GET_COMMENT_POSITION.FAILURE, payload: response.data.message });
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
    }
  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: GET_ALL_POSTS.FAILURE, payload: err });
  } finally {
    yield put({
      type: GET_COMMENT_POSITION.LOADING,
      payload: { isLoading: 0 },
    });
  }
}

function* getPostNotificationData({ payload }) {
  try {
    yield put({
      type: NOTIFICATION_POST_DATA.LOADING,
      payload: { isLoading: payload.id },
    });
    const response = yield call(getPostByIdApi, payload);
    if (response.ok) {
      yield put({
        type: NOTIFICATION_POST_DATA.SUCCESS,
        payload: !isEmpty(response.data.data) ? response.data.data : {},
      });
    } else {
      yield put({ type: NOTIFICATION_POST_DATA.FAILURE, payload: response.data.message });
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
    }
  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: GET_ALL_POSTS.FAILURE, payload: err });
  } finally {
    yield put({
      type: NOTIFICATION_POST_DATA.LOADING,
      payload: { isLoading: 0 },
    });
  }
}

function* getCommentNotificationData({ payload }) {
  try {
    yield put({
      type: NOTIFICATION_COMMENT_DATA.LOADING,
      payload: { isLoading: payload.id },
    });
    const commentPosition = yield call(getCommentPositionApi, payload);
    if (commentPosition.data.success) {
      const commentNotificationData = yield call(getCommentsApi, { id: payload.postId, start: commentPosition.data.data[0].position, limit: 1 });
      if (commentNotificationData.data.success) {
        yield put({
          type: GET_COMMENTS.SUCCESS,
          payload: !isEmpty(commentNotificationData.data.data)
            ? { list: commentNotificationData.data.data, id: payload.postId, start: 0, meta: commentNotificationData.data.meta }
            : { list: [], id: payload.id, start: 0, meta: {} },
        });
        yield put({
          type: NOTIFICATION_COMMENT_DATA.SUCCESS,
          payload: payload.id,
        });
      } else {
        yield put({ type: NOTIFICATION_COMMENT_DATA.FAILURE, payload: commentNotificationData.data.message });
        addToast(`${commentNotificationData.data.message}`, TOAST_TYPE.ERROR);
      }
    } else {
      yield put({ type: NOTIFICATION_COMMENT_DATA.FAILURE, payload: commentPosition.data.message });
      addToast(`${commentPosition.data.message}`, TOAST_TYPE.ERROR);
    }
  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: NOTIFICATION_COMMENT_DATA.FAILURE, payload: err });
  } finally {
    yield put({
      type: NOTIFICATION_COMMENT_DATA.LOADING,
      payload: { isLoading: 0 },
    });
  }
}

function* searchAndFilterPost({ payload }) {
  try {
    yield put({
      type: GET_ALL_POSTS.LOADING,
      payload: { isLoading: payload.id },
    });
    const response = yield call(searchPostsApi, payload);
    if (response.ok) {
      yield put({
        type: GET_ALL_POSTS.SUCCESS,
        payload: !isEmpty(response.data.data) || payload.start > 0
          ? { list: response.data.data, start: payload.start, meta: response.data.meta }
          : { list: [], start: 0, meta: {} },
      });
    } else {
      yield put({ type: GET_ALL_POSTS.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: GET_ALL_POSTS.FAILURE, payload: err });
  } finally {
    yield put({
      type: GET_ALL_POSTS.LOADING,
      payload: { isLoading: 0 },
    });
  }
}

function* getPostById({ payload }) {
  try {
    const response = yield call(getPostByIdApi, payload);
    if (response.ok) {
      yield put({
        type: GET_POST_BY_ID.SUCCESS,
        payload: !isEmpty(response.data.data) ? response.data.data : [],
      });
    } else {
      yield put({ type: GET_POST_BY_ID.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: GET_POST_BY_ID.FAILURE, payload: err });
  }
}

function* getComments({ payload }) {
  if (payload.clear) {
    yield put({
      type: GET_COMMENTS.SUCCESS,
      payload: 'clear',
    });
  } else {
    yield put({
      type: GET_COMMENTS.LOADING,
      payload: { isLoading: payload.id },
    });
    try {
      const response = yield call(getCommentsApi, payload);
      if (response.ok) {
        const likeCountObj = yield select(likeCommentSelector);
        response.data.data.forEach((post) => {
          likeCountObj[post.id] = post.is_liked_by_me ? 'likeComment' : 'unlikeComment';
        });
        yield put({
          type: LIKE_COMMENT.SUCCESS,
          payload: likeCountObj,
        });
        yield put({
          type: GET_COMMENTS.SUCCESS,
          payload: !isEmpty(response.data.data)
            ? { list: response.data.data, id: payload.id, start: payload.start, meta: response.data.meta }
            : { list: [], id: payload.id, start: 0, meta: {} },
        });
      } else {
        yield put({ type: GET_COMMENTS.FAILURE, payload: response.data.message });
        addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
      }
    } catch (err) {
      addToast('something went wrong', TOAST_TYPE.ERROR);
      yield put({ type: GET_COMMENTS.FAILURE, payload: err });
    } finally {
      yield put({
        type: GET_COMMENTS.LOADING,
        payload: { isLoading: 0 },
      });
    }
  }
}

function* sendComment({ payload }) {
  try {
    yield put({
      type: CREATE_COMMENT.LOADING,
      payload: { isLoading: payload.postId },
    });
    const response = yield call(sendCommentApi, payload);
    if (response.ok) {
      yield put({
        type: CREATE_COMMENT.SUCCESS,
        payload: { type: payload.type || 'normal' },
      });
      const commentBasedOnTypeAndPostId = yield call(getCommentList, payload.postId, payload.type);
      const { reducerType } = commentBasedOnTypeAndPostId;
      yield put({
        type: reducerType.REQUEST,
        payload: { id: payload.postId,
          start: 0,
          limit: 3 },
      });
    } else {
      yield put({ type: CREATE_COMMENT.FAILURE, payload: response.data.message });
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
    }
  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: CREATE_COMMENT.FAILURE, payload: err });
  } finally {
    yield put({
      type: CREATE_COMMENT.LOADING,
      payload: { isLoading: 0 },
    });
  }
}

function* likePost({ payload }) {
  const state = store.getState();
  const loadingForPost = state.toJS().posts.likePost.isLoading;
  if (loadingForPost === payload.postId) {
    yield put({
      type: QUEUE_FOR_POST,
      payload,
    });
  } else {
    try {
      yield put({
        type: LIKE_POST.LOADING,
        payload: { isLoading: payload.postId },
      });
      const postLastResponseList = state.toJS().posts.likePost.response;
      const postLastResponse = postLastResponseList[payload.postId] === 'likePost';

      if (postLastResponse !== payload.reaction) {
        const response = payload.reaction ? yield call(likePostApi, payload) : yield call(unlikePostApi, payload);
        postLastResponseList[payload.postId] = payload.reaction ? 'likePost' : 'unlikePost';
        if (response.ok) {
          const queueForPost = yield select(queueForPostSelector);
          yield put({
            type: LIKE_POST.SUCCESS,
            payload: postLastResponseList,
          });
          const allPosts = yield select(allPostsSelector);
          const reducerPostList = allPosts;
          const reducerType = GET_ALL_POSTS;
          const updatedQueueForPost = queueForPost;
          if (updatedQueueForPost[payload.postId] && updatedQueueForPost[payload.postId] !== payload.reaction) {
            yield put({
              type: LIKE_POST.LOADING,
              payload: { isLoading: 0 },
            });
            yield put({
              type: QUEUE_FOR_POST,
              payload: { ...payload, reaction: null },
            });
            yield call(likePost({ ...payload, reaction: updatedQueueForPost[payload.postId] }));
          }

          let postIndexForLike;
          reducerPostList.list.forEach((post, idx) => {
            if (post.id === payload.postId) {
              postIndexForLike = idx;
            }
          });
          reducerPostList.list[postIndexForLike].interaction_count.likes = payload.reaction
            ? reducerPostList.list[postIndexForLike].interaction_count.likes + 1
            : reducerPostList.list[postIndexForLike].interaction_count.likes - 1;
          reducerPostList.list[postIndexForLike].is_liked_by_me = payload.reaction;
          const { meta, list } = reducerPostList;
          yield put({
            type: reducerType.SUCCESS,
            payload: meta.total
              ? {
                list,
                start: 0,
                meta: {
                  ...meta,
                  total: meta.total,
                  paging: meta.paging,
                },
              }
              : { list: [], start: 0 },
          });
        } else {
          yield put({ type: LIKE_POST.FAILURE, payload: response.data.message });
          addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
        }
      }
    } catch (err) {
      addToast('something went wrong', TOAST_TYPE.ERROR);
      yield put({ type: LIKE_POST.FAILURE, payload: err });
    } finally {
      yield put({
        type: LIKE_POST.LOADING,
        payload: { isLoading: 0 },
      });
    }
  }
}

function* likeComment({ payload }) {
  const state = store.getState();
  const loadingForComment = state.toJS().posts.likeComment.isLoading;
  if (loadingForComment === payload.commentId) {
    yield put({
      type: QUEUE_FOR_COMMENT,
      payload,
    });
  } else {
    try {
      yield put({
        type: LIKE_COMMENT.LOADING,
        payload: { isLoading: payload.commentId },
      });
      const { likeComment, commentsByPost } = state.toJS().posts;
      const commentsLastResponse = likeComment.response[payload.commentId] && likeComment.response[payload.commentId] === 'likeComment';
      if (commentsLastResponse !== payload.reaction) {
        const response = payload.reaction ? yield call(likeCommentApi, payload) : yield call(unlikeCommentApi, payload);
        if (response.ok) {
          const state = store.getState();
          const comments = commentsByPost.response[payload.postId];
          const updatedQueueForComment = state.toJS().posts.queueForComment;
          if (
            updatedQueueForComment[payload.commentId]
            && updatedQueueForComment[payload.commentId] !== payload.reaction
          ) {
            yield put({
              type: LIKE_COMMENT.LOADING,
              payload: { isLoading: 0 },
            });
            yield put({
              type: QUEUE_FOR_COMMENT,
              payload: { ...payload, reaction: null },
            });
            yield call(likeComment({ ...payload, reaction: updatedQueueForComment[payload.commentId] }));
          }

          let commentIndexForLike;
          comments.list.forEach((comment, idx) => {
            if (comment.id === payload.commentId) {
              commentIndexForLike = idx;
            }
          });
          comments.list[commentIndexForLike].interaction_count.likes = payload.reaction
            ? comments.list[commentIndexForLike].interaction_count.likes + 1
            : comments.list[commentIndexForLike].interaction_count.likes - 1;
          comments.list[commentIndexForLike].is_liked_by_me = payload.reaction;
          const { meta, list } = comments;
          yield put({
            type: LIKE_COMMENT.SUCCESS,
            payload: { commentId: payload.commentId, action: payload.reaction ? 'likeComment' : 'unlikeComment' },
          });
          yield put({
            type: GET_COMMENTS.SUCCESS,
            payload: {
              list,
              start: 0,
              meta,
              id: payload.postId,
            },
          });
        } else {
          yield put({ type: LIKE_COMMENT.FAILURE, payload: response.data.message });
          addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
        }
      }
    } catch (err) {
      addToast('something went wrong', TOAST_TYPE.ERROR);
      yield put({ type: LIKE_COMMENT.FAILURE, payload: err });
    } finally {
      yield put({
        type: LIKE_COMMENT.LOADING,
        payload: { isLoading: 0 },
      });
    }
  }
}

function* sendPost({ payload }) {
  try {
    yield put({
      type: CREATE_POST.LOADING,
      payload: { isLoading: true },
    });
    const response = yield call(sendPostApi, payload);
    if (response.ok) {
      yield put({
        type: CREATE_POST.SUCCESS,
        payload: !isEmpty(response.data.data) ? response.data.data : null,
      });
    } else {
      yield put({ type: CREATE_POST.FAILURE, payload: response.data.message });
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
    }
  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: CREATE_POST.FAILURE, payload: err });
  } finally {
    yield put({
      type: CREATE_POST.LOADING,
      payload: { isLoading: false },
    });
  }
}

function* deletePost({ payload }) {
  try {
    const response = yield call(deletePostApi, payload.postId);
    const allPosts = yield select(allPostsSelector);
    const reducerPostList = allPosts;
    const reducerType = GET_ALL_POSTS;
    // if (!isEmpty(pinnedPostsForGroup.list) && pinnedPostsForGroup.list[0].id === payload.postId) {
    //   const postData = yield call(getPostByIdApi, { id: payload.postId });
    //   yield call(updatePostList, payload.type, postData, payload.postId);
    // }
    const post = reducerPostList.list.filter((value) => value.id !== payload.postId);
    const { meta } = reducerPostList;
    if (response.ok) {
      yield put({
        type: reducerType.SUCCESS,
        payload: meta.total
          ? {
            list: post,
            start: 0,
            meta: {
              ...meta,
              total: meta.total - 1,
              paging: meta.paging ? { ...meta.paging, start: meta.paging && meta.paging.start - 1 } : null,
            },
          }
          : { list: [], start: 0 },
      });

      if (meta.total && post.length === 1) {
        const groupDetails = yield select(groupDetailsSelector);
        yield put({ type: GET_ALL_POSTS.REQUEST, payload: { id: groupDetails.id, start: 0, limit: 3 } });
      }

      if (isEmpty(post)) {
        const groupDetails = yield select(groupDetailsSelector);
        yield put({ type: GET_ALL_POSTS.REQUEST, payload: { id: groupDetails.id, start: 0, limit: 3 } });
      }

      yield put({
        type: DELETE_POST.SUCCESS,
        payload: !isEmpty(response.data.data) ? response.data.data : null,
      });
    } else {
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
      yield put({ type: DELETE_POST.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: DELETE_POST.FAILURE, payload: err });
  }
}

function* createPollPost({ payload }) {
  try {
    const response = yield call(createPollPostApi, payload);
    if (response.ok) {
      /* [start] mixpanel event */
      try {
        const groupDetails = yield select(groupDetailsSelector);
        Mixpanel.track('poll_created', {
          group_id: groupDetails.id,
          group_type: 'teacher',
          group_privacy: groupDetails.type,
          group_members_count: groupDetails.joined_users_count,
          poll_question: payload.text,
          poll_id: response.data.data[0].id,
          number_of_options: payload.choices.length,
          original_duration: payload.active_hours,
          is_free: payload.is_free,
        });
      } catch (e) {
        console.log('error', e);
      }
      /* [end] mixpanel event */
      yield put({
        type: CREATE_POLL_POST.SUCCESS,
        payload: !isEmpty(response.data.data) ? response.data.data : null,
      });
      yield put({ type: GET_ALL_POSTS.REQUEST, payload: { id: payload.groupId, start: 0, limit: 3 } });
    } else {
      yield put({ type: CREATE_POLL_POST.FAILURE, payload: response.data.message });
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
    }
  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: CREATE_POLL_POST.FAILURE, payload: err });
  }
}

function* updatePollPost({ payload }) {
  try {
    const response = yield call(updatePollPostApi, payload);
    if (response.ok) {
      const allPosts = yield select(allPostsSelector);
      const reducerPostList = allPosts;
      const reducerType = GET_ALL_POSTS;
      const post = reducerPostList.list.map((value) => {
        if (value.id === payload.id) {
          return { ...value, type_meta: { ...value.type_meta, minutes_left: payload.active_hours * 60 } };
        } return value;
      });
      yield put({
        type: reducerType.SUCCESS,
        payload: { ...reducerPostList,
          list: post,
          start: 0,

        },
      });
      yield put({
        type: UPDATE_POLL_POST.SUCCESS,
        payload: !isEmpty(response.data.data) ? response.data.data : null,
      });
    } else {
      yield put({ type: UPDATE_POLL_POST.FAILURE, payload: response.data.message });
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
    }
  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: UPDATE_POLL_POST.FAILURE, payload: err });
  }
}

function* getCommentList(postId) {
  const result = {};
  const list = yield select(commentsForAllPostsSelector);
  result.reducerType = GET_COMMENTS;
  result.list = list[postId];
  return result;
}

function* deleteComment({ payload }) {
  try {
    const response = yield call(deleteCommentApi, payload.commentId);
    const commentBasedOnTypeAndPostId = yield call(getCommentList, payload.postId, payload.type);
    const { reducerType } = commentBasedOnTypeAndPostId;
    const commentsList = commentBasedOnTypeAndPostId.list.list.filter((value) => value.id !== payload.commentId);
    const { meta } = commentBasedOnTypeAndPostId.list;
    if (response.ok) {
      yield put({
        type: reducerType.SUCCESS,
        payload: meta.total
          ? {
            list: commentsList,
            id: payload.postId,
            start: 0,
            meta: {
              ...meta,
              total: meta.total - 1,
              paging: meta.paging ? { ...meta.paging, start: meta.paging && meta.paging.start - 1 } : null,
            },
          }
          : { list: [], id: payload.postId, start: 0 },
      });

      yield put({
        type: DELETE_COMMENT.SUCCESS,
        payload: !isEmpty(response.data.data) ? response.data.data : null,
      });
    } else {
      addToast(`${response.data.message}`, TOAST_TYPE.ERROR);
      yield put({ type: DELETE_COMMENT.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    addToast('something went wrong', TOAST_TYPE.ERROR);
    yield put({ type: DELETE_COMMENT.FAILURE, payload: err });
  }
}

export function* postSaga() {
  yield all([
    takeEvery(GET_ALL_POSTS.REQUEST, getPosts),
    takeEvery(GET_POST_BY_ID.REQUEST, getPostById),
    takeEvery(GET_COMMENTS.REQUEST, getComments),
    takeEvery(CREATE_COMMENT.REQUEST, sendComment),
    takeEvery(CREATE_POST.REQUEST, sendPost),
    takeEvery(DELETE_COMMENT.REQUEST, deleteComment),
    takeEvery(DELETE_POST.REQUEST, deletePost),
    takeEvery(LIKE_POST.REQUEST, likePost),
    takeEvery(SEARCH_AND_FILTER.REQUEST, searchAndFilterPost),
    takeEvery(LIKE_COMMENT.REQUEST, likeComment),
    takeEvery(CREATE_POLL_POST.REQUEST, createPollPost),
    takeEvery(UPDATE_POLL_POST.REQUEST, updatePollPost),
    takeEvery(GET_COMMENT_POSITION.REQUEST, getCommentPosition),
    takeEvery(NOTIFICATION_POST_DATA.REQUEST, getPostNotificationData),
    takeEvery(NOTIFICATION_COMMENT_DATA.REQUEST, getCommentNotificationData),
  ]);
}

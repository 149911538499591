import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import { IconCorrect, IconMediaPlayO } from '@noon/quark';
import classNames from 'classnames';
import Popover from 'react-popover';
import { COLORS } from '../../constants';
import { translationText } from '../../helpers';

export default function ActivityFilter(prop) {
  const { items, selectedItem, onChange } = prop;
  const [openDropdown, toggleDropDown] = useState(false);
  const { noonText } = useSelector((state) => state.toJS().translation);

  const activityPopover = {
    isOpen: openDropdown,
    preferPlace: 'below',
    onOuterAction: () => toggleDropDown(false),
    body: (
      <ul className={classNames('schedule-dropdown__popover', { active: openDropdown })}>
        {items.map((item) => (
          <li
            key={item.id}
            className={classNames({ active: item.id === selectedItem.id })}
            onClick={() => { onChange(item); toggleDropDown(false); }}
          >
            <span>{translationText(noonText, item.label)}</span>
            <IconCorrect width="25px" height="25px" fill={COLORS.brand.base} stroke={COLORS.brand.base} />
          </li>
        ))}
      </ul>
    ),
  };

  return (
    <div className="schedule-dropdown">
      <Popover {...activityPopover}>
        <div className="schedule-dropdown__button" onClick={() => toggleDropDown(!openDropdown)}>
          <p>{translationText(noonText, selectedItem.label) || 'Select an Option'}</p>
          <IconMediaPlayO height="16px" width="16px" className="animate ease-in" rotate={openDropdown ? -90 : 90} fill={isMobileOnly ? '#fff' : COLORS.brand.base} />
        </div>
      </Popover>
    </div>
  );
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Column, Row } from '@noon/atom';
import { IconArrowDown, IconInfo } from '@noon/quark';
import { trim } from 'lodash';
import { TrackSentryError, SentryType } from 'SentryAlias';
import { COLORS } from '../../constants';

class ErrorBoundry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorInfo: {},
      showErrorInfo: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo = {}) {
    this.setState({ errorInfo: { error, ...errorInfo } });
    TrackSentryError('Crash', {
      error,
      errorInfo,
      temporaryTokenPayload: localStorage.temporaryTokenPayload,
      userId: localStorage.loggedUser ? JSON.parse(localStorage.loggedUser).id : 0,
    }, SentryType.CRITICAL);
  }

  onClickShowErrorInfo = () => {
    this.setState((prevState) => ({ showErrorInfo: !prevState.showErrorInfo }));
  }

  render() {
    const { msg, children, icon: Icon } = this.props;
    const { hasError, showErrorInfo, errorInfo } = this.state;
    if (hasError) {
      return (
        <>
          <div className="error-boundary animated fadeIn">
            <Icon height="30px" width="30px" color="#f6a742" className="error-boundary__icon" />
            <p>{msg}</p>
            <Button
              size="lg"
              depth="2"
              type="primary"
              className="animated fadeIn slower"
              onClick={() => {
                window.location.reload();
              }}
          >
              Reload
            </Button>
            <div className="w-full plr-2 ptb-2">
              <Row
                onClick={this.onClickShowErrorInfo}
                nowrap
                justify="start"
                align="center"
          >
                <IconArrowDown
                  className="child mr-05 ml-05"
                  height="20px"
                  width="20px"
                  fill={COLORS.coolGrey[3]}
                  rotate={showErrorInfo ? 0 : -90} />
                <span className="">Error Stack Trace</span>
              </Row>
              {showErrorInfo
            && (
            <Column justify="start" align="start">
              {errorInfo.name && <strong className="child">{errorInfo.name}</strong>}
              {errorInfo.error && (
              <p
                style={{ whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{ __html: errorInfo.error }} />
              )}
              <code
                className="child"
                style={{ whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{ __html: trim(errorInfo.componentStack) }} />
            </Column>
            )}
            </div>
          </div>
        </>
      );
    }
    return children;
  }
}

PropTypes.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  msg: PropTypes.string,
  icon: PropTypes.func,
};

PropTypes.defaultProps = {
  msg: 'Something went wrong. Please reload the page.',
  icon: IconInfo,
  children: null,
};

export default ErrorBoundry;

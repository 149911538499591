import React from 'react';
import { Modal, Button, Row, Column } from '@noon/atom';
import { IconCorrectCircle, IconDelete, IconExclamationCircle, IconPlus, IconBlock, IconRemove } from '@noon/quark';
import classNames from 'classnames';
import { COLORS } from '../../constants';

const typeOfBtn = [
  { type: 'exclamation', icon: IconExclamationCircle, color: '#8888e0' },
  { type: 'delete', icon: IconDelete, color: 'red' },
  { type: 'correct', icon: IconCorrectCircle, color: '#fff', stroke: COLORS.grey[2] },
  { type: 'block', icon: IconBlock, color: COLORS.grey[2], stroke: COLORS.grey[2] },
  { type: 'remove', icon: IconRemove, color: COLORS.grey[2], stroke: COLORS.grey[2] },
];
export default function PostModal(prop) {
  const type = typeOfBtn.filter((btn) => btn.type === prop.type)[0];
  return (
    <Modal onClose={prop.onClose} className="post-modal-container">
      <Column align="plus" nowrap className="post-modal">

        <IconPlus className="plus" rotate="45" fill={COLORS.text.light} width="22px" onClick={prop.onClose} />

        <Column align="center" className="post-modal--body">
          <div
            className={classNames('header-icon', {
              retry: prop.type === 'exclamation',
            })}>
            {prop.type === 'exclamation'
              ? <div className="exclamation">!</div>
              : <type.icon width="40px" height="40px" fill={type.color} stroke={type.stroke} />}
          </div>
          <div className="post-modal--body--wrapper">
            <Column className="post-modal--body--text" align="center">
              <div className="text-1">{prop.text1}</div>
              <div className="text-2">{prop.text2}</div>
            </Column>
            <Row nowrap className="post-modal--body--btn">
              <Button
                type="primary"
                size="large"
                onClick={prop.onClick}
                className="btn-modal mlr-2"
              >
                {prop.btn1}
              </Button>
              <Button size="large" onClick={prop.onClose} className="btn-modal mlr-2">
                {prop.btn2}
              </Button>
            </Row>
          </div>
        </Column>
      </Column>
    </Modal>
  );
}

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, Row, Column, Badge, Button } from '@noon/atom';
import { Link } from 'react-router-dom';
import { IconAdd, IconArrowDown, IconBack, IconEdit } from '@noon/quark';
import ChapterCard from './chapterCard';
import { COLORS } from '../../../constants';
import { translationText } from '../../../helpers';
import NotAllowedOverlay from '../NotAllowedOverlay';

function CurriculumWidgetDraftState({
  handleClickCreateNewActivity,
  isRtl,
  handleViewContentCurriculumClick,
  handleMixpanelCurriculumEvents,
  id,
  hasDraft,
  miscChapter,
  chapters,
  history,
  isArchived,
  percentCompleted }) {
  const [showChapters, setShowChapter] = useState(false);
  const { noonText } = useSelector((state) => state.toJS().translation);
  const handleShowChapter = () => {
    if (!showChapters) {
      handleMixpanelCurriculumEvents({
        type: 'curriculum_view_all',
        data: {
          curriculum_state: 'draft',
          no_of_chapters: chapters.length,
        },
        mixPanel: false,
      });
    }
    setShowChapter(!showChapters);
  };

  const handleEditCurriculumClick = () => {
    handleMixpanelCurriculumEvents({
      type: 'edit_curriculum_clicked',
      data: {
        curriculum_state: 'draft',
        no_of_chapters: chapters.lenght,
        curriculum_completion: percentCompleted,
      },
    });
    history.push(`/${localStorage.updatedLocale}/curriculum/${id}`);
  };

  return (
    <Card>
      <Row align="center" justify="start" flex="1" className="mtb-2 plr-2 card-header">
        <h3 className="card-header__title">
          {translationText(noonText, 'tab.curriculum')}
          {hasDraft && (
            <Badge
              size="md"
              className="mlr-2"
              style={{ backgroundColor: '#A6ACC2', border: '1px solid #A6ACC2', color: '#fff' }}
            >
              {translationText(noonText, 'groupCurriculum.draft')}
            </Badge>
          )}
        </h3>
      </Row>
      {isArchived && <NotAllowedOverlay />}
      <Column align="start" justify="center" className="card-body curriculum-widget" gap="sm">
        <Column align="center" justify="center" className="curriculum-widget__draft-container">
          <Column style={{ margin: 0, width: '100%' }} align="center" justify="start" className="no-margin">
            <span className="child card-body__note plr-2 mtb-2">
              {translationText(noonText, 'groupCurriculum.draftExplain')}
              <Link to={`/${localStorage.updatedLocale}/curriculum/${id}`}>
                {' '}
                {translationText(noonText, 'groupCurriculum.draftPublishIt')}
                {' '}
              </Link>
            </span>
            {showChapters && (
              <Column align="start" justify="center" className="curriculum-widget__content plr-2 ptb-2">
                {chapters.map((chapter, index) => (
                  <ChapterCard key={chapter.id} chapter={chapter} index={index} hasDraft />
                ))}
              </Column>
            )}
          </Column>
          <div className="curriculum-widget__show-button ptb-1 text-center">
            <Button
              size="sm"
              className="no-padding"
              style={{
                minWidth: '27px',
                border: '0',
                textTransform: 'capitalize',
              }}
              outlined
              onClick={handleShowChapter}
            >
              {translationText(noonText, !showChapters ? 'groupCurriculum.showDraft' : 'groupCurriculum.hideDraft')}
              <IconArrowDown
                flipY={showChapters}
                fill={COLORS.coolGrey[1]}
                stroke={COLORS.coolGrey[1]}
                height="15px"
                width="15px"
                className="mlr-1"
              />
            </Button>
          </div>
          <Row align="center" justify="center" gap="sm" className="mtb-2">
            <Button
              type="primary"
              onClick={handleEditCurriculumClick}
              value={translationText(noonText, 'tab.editCurriculum')}
              icon={IconEdit}
              fill="#fff"
              style={{ textTransform: 'capitalize' }}
            />
          </Row>
          <Row
            style={{ width: '100%' }}
            nowrap
            align="center"
            justify="space-between"
            className={`${miscChapter.type}-card plr-2 ptb-2 ${miscChapter.state}`}
          >
            <Column align="start" justify="start">
              <h4 style={{ fontWeight: 'normal', marginTop: '0' }} className="child">
                {miscChapter.name}
              </h4>
              <Row align="center" nowrap className="child">
                <label>{translationText(noonText, 'groupCurriculum.upcomingActivities')}</label>
                <label
                  className="mlr-1"
                  style={{
                    border: '0.5px solid #62687A',
                    borderRadius: '4px',
                    color: '#62687A',
                    width: '24px',
                    height: '22px',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    lineHeight: '1.5',
                  }}
                >
                  {miscChapter.upcoming_activities || 0}
                </label>

                <IconAdd onClick={() => handleClickCreateNewActivity({ chapter: miscChapter })} fill={COLORS.brand.base} width="21.5" height="21.5" />
              </Row>
            </Column>
            <Button
              size="sm"
              type="secondary"
              className="no-padding"
              style={{
                minWidth: '27px',
                height: '27px',
                borderColor: '#DCE3FA',
              }}
              outlined
              onClick={() => handleViewContentCurriculumClick(`chapterId=${miscChapter.chapter_id}`)}
                >
              <IconBack height="12px" width="12px" fill="#64D8AE" stroke="#64D8AE" flipX={!isRtl} />
            </Button>
          </Row>
        </Column>
      </Column>
    </Card>
  );
}

CurriculumWidgetDraftState.propTypes = {
  id: PropTypes.string.isRequired,
  percentCompleted: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  hasDraft: PropTypes.bool.isRequired,
  miscChapter: PropTypes.shape({}),
  chapters: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.shape({}).isRequired,
  handleMixpanelCurriculumEvents: PropTypes.func.isRequired,
  isRtl: PropTypes.bool.isRequired,
  handleViewContentCurriculumClick: PropTypes.func.isRequired,
  handleClickCreateNewActivity: PropTypes.func.isRequired,
};
CurriculumWidgetDraftState.defaultProps = {
  miscChapter: {},
};

export default CurriculumWidgetDraftState;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash-es/isEmpty';
import classNames from 'classnames';
import { IconBook, IconClass, IconFlashcard, IconQuestionDartboard } from '@noon/quark';
import { Row, Column } from '@noon/atom';
import { connect } from 'react-redux';
import { FlashcardForLesson, BookForLesson, QuestionForLesson } from '../Content';
import { translationType, classType } from '../../types';
import { translationText, getNoonItem, setNoonItem } from '../../helpers';
import { COLORS } from '../../constants';
import { ChapterTopicMultiSelect } from '../Curriculum';
import { SET_CHAPTER_AND_TOPIC } from '../../redux/constants';

class ContentSidebar extends Component {
  static propTypes = {
    noonText: translationType.isRequired,
    topicContent: PropTypes.shape().isRequired,
    getContentForTopic: PropTypes.func.isRequired,
    showEnlarged: PropTypes.func.isRequired,
    addResourceToCanvas: PropTypes.func.isRequired,
    searchBookByNumber: PropTypes.func.isRequired,
    showRelatedContent: PropTypes.bool.isRequired,
    isSearchingBook: PropTypes.bool.isRequired,
    addQuestion: PropTypes.func.isRequired,
    sessionData: classType.isRequired,
    updateLessonDetails: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedLesson: {},
      selectedContentTab: props.sessionData.class_type === 'competition' ? 'question' : 'flashcard',
      currentFlashcardId:
        !isEmpty(this.props.topicContent.flashcardImages) && !isEmpty(this.props.topicContent.flashcardImages.list)
          ? this.props.topicContent.flashcardImages.list[0].id
          : -1,
    };
  }

  componentDidMount() {
    const { updateLessonDetails } = this.props;
    const localLesson = getNoonItem('selectedLesson');
    if (localLesson && localLesson.id) {
      this.handleLessonSelection(localLesson);
      updateLessonDetails(localLesson);

    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.topicContent && this.props.topicContent !== prevProps.topicContent) {
      setNoonItem('topicContent', this.props.topicContent);
    }
  }

  componentWillUnmount() {
    const { updateLessonDetails } = this.props;
    updateLessonDetails({});
  }

  handleLessonSelection = (data) => {
    const { getContentForTopic, updateLessonDetails } = this.props;
    this.setState({ selectedLesson: data });
    updateLessonDetails(data);
    getContentForTopic(data);

  };

  selectContentTab = (tab) => {
    this.setState({ selectedContentTab: tab });
  };

  updateCurrentFlashcardId = (id) => {
    this.setState({ currentFlashcardId: id });
  };

  render() {
    const { selectedLesson, selectedContentTab } = this.state;
    const {
      noonText,
      topicContent,
      showRelatedContent,
      isSearchingBook,
      searchBookByNumber,
      addResourceToCanvas,
      addQuestion,
      showEnlarged,
      sessionData,
      handleLoadMoreQuestion,
    } = this.props;

    return (
      <div className="sidebar-body--content">
        {!showRelatedContent ? (
          <div className="content content--primary">
            <div className="content--primary__chapter-lesson-selector plr-1">
              <ChapterTopicMultiSelect
                groupId={sessionData.group.id}
                disabled={selectedContentTab === 'book'}
                onTopicSelect={this.handleLessonSelection}
                preChapterId={!isEmpty(sessionData.curriculum_tags) ? sessionData.curriculum_tags.chapter.id : ''}
                preTopicId={!isEmpty(sessionData.curriculum_tags) && sessionData.curriculum_tags.chapter.type === 'chapter' ? sessionData.curriculum_tags.topics[0].id : ''}
              />
            </div>
            {sessionData.class_type !== 'competition' && (
              <Row className="content--primary__tabs">
                <React.Fragment>
                  <Column
                    className={classNames('tab', { active: selectedContentTab === 'flashcard' })}
                    onClick={() => this.selectContentTab('flashcard')}
                  >
                    <IconFlashcard
                      width="30px"
                      height="30px"
                      stroke={selectedContentTab === 'flashcard' ? COLORS.primary.base : COLORS.text.base}
                      fill="#ffffff"
                    />
                    <p>{translationText(noonText, 'tab.flashcard')}</p>
                  </Column>
                  <div className="vr" />
                </React.Fragment>
                <React.Fragment>
                  <Column
                    className={classNames('tab', { active: selectedContentTab === 'question' })}
                    onClick={() => this.selectContentTab('question')}
                  >
                    <IconQuestionDartboard
                      width="30px"
                      height="30px"
                      fill={selectedContentTab === 'question' ? COLORS.primary.base : COLORS.text.base}
                    />
                    <p>{translationText(noonText, 'tab.question')}</p>
                  </Column>
                  <div className="vr" />
                </React.Fragment>
                <Column
                  className={classNames('tab', { active: selectedContentTab === 'book' })}
                  onClick={() => this.selectContentTab('book')}
                >
                  <IconBook
                    width="30px"
                    height="30px"
                    stroke={selectedContentTab === 'book' ? COLORS.primary.base : COLORS.text.base}
                    fill={selectedContentTab === 'book' ? COLORS.primary.base : COLORS.text.base}
                  />
                  <p>{translationText(noonText, 'tab.book')}</p>
                </Column>
              </Row>
            )}
            <div className="content--primary__main">
              {!isEmpty(selectedLesson) || selectedContentTab === 'book' ? (
                <div className={`content--primary__main--${selectedContentTab}`}>
                  {selectedContentTab === 'flashcard' ? (
                    <FlashcardForLesson
                      simplified
                      showEnlarged={showEnlarged}
                      updateCurrentFlashcardId={this.updateCurrentFlashcardId}
                      noonText={noonText}
                      errorFor={topicContent.errorFor || []}
                      addToCanvas={addResourceToCanvas}
                      noBookPageFoundMessage={translationText(noonText, 'classroom.noFlashcard')}
                      flashcards={topicContent.flashcardImages}
                      textAddToCanvas={translationText(noonText, 'classroom.addToCanvas')}
                    />
                  ) : selectedContentTab === 'question' ? (
                    <QuestionForLesson
                      noQuestionFoundMessage={translationText(noonText, 'classroom.noQuestion')}
                      noonText={noonText}
                      addQuestionToCanvas={addQuestion}
                      errorFor={topicContent.errorFor || []}
                      questions={topicContent.questions}
                      handleLoadMoreQuestion={handleLoadMoreQuestion}
                      groupId={sessionData.group.id}
                      curriculumTags={sessionData.curriculum_tags}
                    />
                  ) : (
                    <BookForLesson
                      bookPages={topicContent.bookPages || []}
                      textAddToCanvas={translationText(noonText, 'classroom.addToCanvas')}
                      addToCanvas={addResourceToCanvas}
                      searchBookPage={searchBookByNumber}
                      selectedLesson={selectedLesson}
                      noonText={noonText}
                      isSearching={isSearchingBook}
                      searchPlaceholder={translationText(noonText, 'classroom.searchBookByPage')}
                      noBookPageFoundMessage={translationText(noonText, 'classroom.noBookPage')}
                    />
                  )}
                </div>
              ) : (
                <div className="content--primary__main--selectLesson infoMsg infoMsg-blue">
                  <IconClass color="#898989" />
                  <span>{translationText(noonText, 'classroom.selectLesson')}</span>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="content content--related">
            <div className="content--related__subcontent content--related__subcontent--flashcard" />
            <div className="content--related__subcontent content--related__subcontent--question">
              <div className="sidebar-heading-wrapper">
                <h4 className="sidebar-heading">{translationText(noonText, 'classroom.relatedQuestions')}</h4>
              </div>
              <QuestionForLesson
                noQuestionFoundMessage={translationText(noonText, 'classroom.noQuestion')}
                noonText={noonText}
                errorFor={topicContent.errorFor || []}
                addQuestionToCanvas={addQuestion}
                questions={topicContent.flashcardQuestions}
                handleLoadMoreQuestion={handleLoadMoreQuestion}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateLessonDetails: (payload) => dispatch({ type: SET_CHAPTER_AND_TOPIC, payload }),
});

const mapStateToProps = () => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentSidebar);

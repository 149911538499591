import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Info } from 'luxon';
import Pikaday from 'pikaday';
import { Column, Input } from '@noon/atom';
import { get } from 'lodash-es';
import { getWeekdays, getWeekdaysShort } from '../../../helpers/date';
import { translationText } from '../../../helpers';

function FilterCalendar({ noonText, loggedUser, onFilterSelection, filters }) {
  const dateRef = useRef();
  useEffect(() => {
    const picker = new Pikaday({
      field: dateRef.current,
      format: 'YYYY-MM-DD',
      // firstDay: 1,
      minDate: new Date(loggedUser.publish_date || '01-01-2020'),
      i18n: {
        months: Info.months(),
        weekdays: getWeekdays(),
        weekdaysShort: getWeekdaysShort(),
      },
      onSelect: () => {},
    });
    const date = new Date();
    picker.setDate(date);
    // picker.show();
  }, []);

  useEffect(() => {
    if (!filters.date_offset) {
      dateRef.current.value = '';
    }
  }, [filters]);

  return (
    <Column className="general-planner__filters-calendar" gap="sm">
      <span className="child">
        {translationText(noonText, 'label.selectDate')}
      </span>
      <Input
        ref={dateRef}
        maxLength="60"
        onChange={(event) => onFilterSelection({ date_offset: { value: event.target.value } })}
        onBlur={(event) => onFilterSelection({ date_offset: { value: event.target.value } })}
        type="text"
        label=""
        placeholder={translationText(noonText, 'planner.noDateSelected')}
        readOnly
        />
    </Column>
  );
}

FilterCalendar.propTypes = {
  onFilterSelection: PropTypes.func.isRequired,
  filters: PropTypes.shape({}),
};

FilterCalendar.defaultProps = {
  filters: {},
};

export default FilterCalendar;

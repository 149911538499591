const convertLengthToTimeStamp = (length) => {
  const d = Number(length > 0 ? length : 0);
  const m = Math.floor(d / 60);
  const s = Math.round(d % 60);

  const mDisplay = m > 9 ? m : `0${m}`;
  const sDisplay = s > 9 ? s : `0${s}`;
  return `${mDisplay}:${sDisplay}`;
};

export default convertLengthToTimeStamp;

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { findIndex, find, flatten } from 'lodash-es';
import last from 'lodash-es/last';
import { UPDATE_PLAYBACK_SECTIONS, RESET_DELETED_SLIDE, RESET_EDITED_SLIDE } from '../redux/constants';

export default function useSections(slides, currentlyViewingSlide, originalSlides) {
  const dispatch = useDispatch();
  const [sections, updateSections] = useState([]);

  // Session playback length is used to show the updated time after a slide is deleted/edited
  const calculateSessionLength = (sectionsHashmap) => {
    const totalSessionLength = Object.values(sectionsHashmap).reduce((totalLength, sectionSlides) => {
      const sectionLength = sectionSlides && Array.isArray(sectionSlides)
        ? sectionSlides.reduce((lengthAccumulator, slide) => {
          const slideLength = slide.delete ? 0 : slide?.payload?.intervals.reduce((totalLength, { start_time, end_time }) => totalLength + ((end_time - start_time) / 1000), 0);
          return lengthAccumulator + slideLength;
        }, 0)
        : 0;
      return totalLength + sectionLength;
    }, 0);
    dispatch({ type: UPDATE_PLAYBACK_SECTIONS, payload: { audioLength: totalSessionLength } });
  };

  // Get sections out of slides
  const evaluateSectionsForSlides = (slides, returnResult) => {
    const sectionsHashmap = slides.reduce((sections, item, index) => {
      const labelId = item?.payload?.label_id;
      const slideLength = item?.payload?.intervals.reduce((totalLength, { start_time, end_time }) => totalLength + ((end_time - start_time) / 1000), 0);
      const originalItem = originalSlides.find((x) => x.canvas_id === item.canvas_id);
      const originalSlideLength = originalItem?.payload?.intervals.reduce((totalLength, { start_time, end_time }) => totalLength + ((end_time - start_time) / 1000), 0);
      const lastSection = last(sections) || [];
      const lastSlide = last(lastSection) || {};

      const updatedItem = {
        ...item,
        length: slideLength,
        originalLength: originalSlideLength,
        payload: {
          ...item.payload,
          originalIntervals: originalItem?.payload?.intervals,
        },
      };

      // If a slide from a section is not coming in order, remove it from the current instance of section
      // and create another section at appropriate location
      if (lastSlide?.payload?.label_id === labelId) {
        lastSection.push(updatedItem);
        sections.splice(sections.length - 1, 1, lastSection);
      } else {
        sections.push([updatedItem]);
      }
      return sections;
    }, []);

    updateSections(sectionsHashmap);
    calculateSessionLength(sectionsHashmap);
    if (returnResult) return sectionsHashmap;
  };

  // Update sections data when a slide is deleted or trimmed
  const updateSectionsWithNewData = (data, type) => {
    let currentSlide = {};
    if (currentlyViewingSlide && currentlyViewingSlide.canvas_id) currentSlide = currentlyViewingSlide;
    else currentSlide = find(slides, ['canvas_id', data.canvas_id]);

    const newSlides = flatten(sections);
    const currentSectionSlide = find(newSlides, ['canvas_id', currentSlide.canvas_id]);
    const currentSectionSlideIndex = findIndex(newSlides, ['canvas_id', currentSlide.canvas_id]);

    const slideLength = currentSlide?.payload?.intervals.reduce((totalLength, { start_time, end_time }) => totalLength + ((end_time - start_time) / 1000), 0);
    if (type === 'delete') {
      newSlides[currentSectionSlideIndex] = { ...currentSectionSlide, length: data.deleted ? 0 : slideLength, delete: data.deleted };
      dispatch({ type: RESET_DELETED_SLIDE });
    } else if (type === 'edit') {
      newSlides[currentSectionSlideIndex] = { ...currentSectionSlide, length: slideLength, payload: { ...currentSectionSlide.payload, intervals: data.intervals } };
      dispatch({ type: RESET_EDITED_SLIDE });
    }
    const newSections = evaluateSectionsForSlides(newSlides, true);
    return newSections;
  };

  return [sections, evaluateSectionsForSlides, updateSectionsWithNewData, calculateSessionLength];
}

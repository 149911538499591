import difference from 'lodash-es/difference';

function SlideList() {
  this.start = null;
  this.head = null;
  this.errors = {
    CANVAS_ID_IS_NULL: 'CANVAS_ID_IS_NULL',
    SLIDES_EMPTY: 'SLIDES_EMPTY',
    ID_NOT_IN_LIST: 'ID_NOT_IN_LIST',
  };
  this.slideMap = new Map();
}

SlideList.prototype.update = function update(canvasId, props) {
  const slideData = this.slideMap.has(canvasId) ? this.slideMap.get(canvasId) : {};
  this.slideMap.set(canvasId, { ...slideData, ...props });
};

SlideList.prototype.add = function add(slideData = {}, nextSlideId) {
  const { canvas_id: canvasId, prev_canvas_id: prevCanvasId } = slideData;
  if (!canvasId) {
    console.log('Error:', this.errors.CANVAS_ID_IS_NULL);
    return this.errors.CANVAS_ID_IS_NULL;
  }

  // add first slide
  if (!prevCanvasId && !nextSlideId) {
    this.start = canvasId;
    this.head = canvasId;
    this.slideMap.set(canvasId, slideData);
    return null;
  }

  // add slide in first position
  if (!prevCanvasId && nextSlideId) {
    this.start = canvasId;
    this.update(nextSlideId, { prev_canvas_id: canvasId });
    this.slideMap.set(canvasId, slideData);
    return null;
  }

  // add slide in last position
  if (!this.head || this.head === prevCanvasId) {
    this.head = canvasId;
    this.slideMap.set(canvasId, slideData);
    return null;
  }

  // add slide in between 2 slides
  if (prevCanvasId && nextSlideId) {
    // next client id should point this slide
    this.update(nextSlideId, { prev_canvas_id: canvasId });
    this.slideMap.set(canvasId, slideData);
    return null;
  }

  return null;
};

SlideList.prototype.remove = function remove(canvasId) {
  if (!this.slideMap.size) {
    console.log('Error:', this.errors.SLIDES_EMPTY);
    return this.errors.SLIDES_EMPTY;
  }
  if (!canvasId) {
    console.log('Error:', this.errors.CANVAS_ID_IS_NULL);
    return this.errors.CANVAS_ID_IS_NULL;
  }
  if (!this.slideMap.has(canvasId)) {
    console.log('Error:', this.errors.ID_NOT_IN_LIST);
    return this.errors.ID_NOT_IN_LIST;
  }

  const currentSlide = this.slideMap.get(canvasId);

  const { canvas_id: nextCanvasId } = [...this.slideMap.values()].find(slide => slide.prev_canvas_id === canvasId) || {};
  // if slide to be deleted is last slide
  if (!nextCanvasId) {
    this.head = currentSlide.prev_canvas_id;
  }

  // if slide to be deleted is first slide
  if (!currentSlide.prev_canvas_id) {
    this.start = nextCanvasId;
  }

  // if slide to be deleted lies between 2 slides
  if (nextCanvasId) {
    // point next slide's to prev slide
    this.update(nextCanvasId, { prev_canvas_id: currentSlide.prev_canvas_id });
  }

  this.slideMap.delete(canvasId);
  return null;
};

SlideList.prototype.move = function move(canvasId, prevSlideId, nextSlideId) {
  if (!canvasId) return this.errors.CANVAS_ID_IS_NULL;
  if (!this.slideMap.has(canvasId)) return this.errors.ID_NOT_IN_LIST;

  const fromSlide = this.slideMap.get(canvasId);
  // remove slide from current position
  this.remove(canvasId);
  // add slide to new position
  this.add({ ...fromSlide, prev_canvas_id: prevSlideId }, nextSlideId);
  return null;
};

SlideList.prototype.toArray = function toArray() {
  const slideArray = [];
  let cursor = this.head;

  // traverse from last to first
  while (cursor) {
    const slide = this.slideMap.get(cursor);
    slideArray.push(slide);
    cursor = slide.prev_canvas_id;
  }
  // return slide list in correct order
  return slideArray.reverse();
};

SlideList.prototype.clearAll = function clearAll() {
  this.slideMap.clear();
  this.start = null;
  this.head = null;
};

SlideList.prototype.fromArray = function fromArray(slides) {
  this.clearAll();
  const canvasIdz = [];
  const prevCanvasIdz = [];

  slides.forEach((slide) => {
    if (!slide.canvas_id) {
      return this.errors.CANVAS_ID_IS_NULL;
    }

    canvasIdz.push(slide.canvas_id);
    if (slide.prev_canvas_id) prevCanvasIdz.push(slide.prev_canvas_id);

    // if prev canvas id is null, mark the slide as start
    if (!slide.prev_canvas_id) {
      this.start = null;
    }

    // adding item to linked list
    this.slideMap.set(slide.canvas_id, slide);
  });

  // getting head of linked list
  const diffArray = difference(canvasIdz, prevCanvasIdz);
  if (diffArray && diffArray.length === 1) {
    // eslint-disable-next-line prefer-destructuring
    this.head = diffArray[0];
  }
};

SlideList.prototype.size = function size() {
  return this.slideMap.size;
};

export default SlideList;

import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import CoachmarkClassroom from '../../Coachmark/coachmarkClassroom';

const CoachmarkContainer = () => {

  const slides = useSelector((state) => state.toJS().whiteboard.slides);
  const isPrecallTestFinished = !!localStorage.getItem('classroom_precall');

  const showClassroomCoachMark = localStorage.getItem('classroomCoachmark') && !isMobile;

  return (
    <>
      {showClassroomCoachMark && isPrecallTestFinished && <CoachmarkClassroom slides={slides} />}
    </>
  );

};

export default CoachmarkContainer;

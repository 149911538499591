import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Badge, Row, Column } from '@noon/atom';
import get from 'lodash-es/get';

const Autocomplete = (props) => {
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState(props.value);

  const onChange = (e) => {
    const { suggestions } = props;
    const userInput = e.currentTarget.value;
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
    );

    setActiveSuggestion(0);
    setFilteredSuggestions(filteredSuggestions);
    setShowSuggestions(true);
    setUserInput(e.currentTarget.value);
    props.onChange(e.currentTarget.value);
  };

  const onClick = (e, suggestion) => {
    e.stopPropagation();
    setActiveSuggestion(0);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setUserInput(suggestion);
    props.onChange(suggestion);
    props.onSelect(suggestion);
  };

  const onBlur = () => {
    props.onSelect(userInput);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      setActiveSuggestion(0);
      setShowSuggestions(false);
      setUserInput(get(filteredSuggestions[activeSuggestion], 'name') || userInput);
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      setActiveSuggestion(activeSuggestion - 1);
    } else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      setActiveSuggestion(activeSuggestion + 1);
    }
  };

  let suggestionsListComponent;

  if (showSuggestions && userInput) {
    if (filteredSuggestions && filteredSuggestions.length) {
      suggestionsListComponent = (
        <ul className="suggestions">
          {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = 'suggestion-active';
              }

              return (
                <li
                  className={className}
                  key={suggestion.id}
                  onClick={e => onClick(e, suggestion.name)}
                >
                  {suggestion.name}
                </li>
              );
            })}
        </ul>
      );
    } else {
      suggestionsListComponent = (
        <div className="no-suggestions">
          <em>{props.emptyMessage}</em>
        </div>
      );
    }
  }
  return (
    <Column nowrap align="start" justify="start" className="flex-1 mr-2" style={props.style}>
      <Column nowrap align="start" justify="start" className="topic-autocomplete full-width">
        <Row nowrap align="center" justify="start" flex="1" style={{ width: '100%' }}>
          <Input
            autoFocus
            list="topics"
            type="text"
            placeholder="Type text here.."
            outlined
            className="no-margin flex-1"
            onChange={onChange}
            onKeyDown={onKeyDown}
          // onBlur={onBlur}
            value={userInput}
            maxLength="80"
        />
          <Badge type="default" size="sm">
            {`${userInput.length} / 80`}
          </Badge>
        </Row>
        {suggestionsListComponent}
      </Column>
      {props.error && <span className="ptb-1 errorMessage">{props.errorMessage}</span>}
    </Column>
  );
};

Autocomplete.propTypes = {
  suggestions: PropTypes.instanceOf(Array),
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  emptyMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  error: PropTypes.bool,
};

Autocomplete.defaultProps = {
  suggestions: [],
  emptyMessage: 'Not found',
  errorMessage: '',
  error: false,
};

export default Autocomplete;

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Column, Select, Search, Hr } from '@noon/atom';
import { debounce, get, isEmpty, map, unionBy } from 'lodash-es';
import { translationText } from '../../helpers';
import { translationType } from '../../types';
import SelectCountryWithFlag from './SelectCountryWithFlag';
import curriculumTypes from '../../helpers/curriculumTypes';

function ListFilters({
  noonText,
  countryList,
  handleOnFilterChange,
  countryId,
  getTeacherGroupTags,
  boards,
  grades,
  subjects,
  curriculum,
  chapters,
  topics,
  degrees,
  tests,
  sections,
  majors,
  courses,
  skills,
  isListEmpty,
}) {
  const [formFieldValues, setFormFieldValues] = useState(new Map([['country', countryList.find((country) => String(country.id) === String(countryId))]]));
  const [disabledFilter, setDisabledFilter] = useState('');
  const searchInputRef = useRef();

  const constantFields = {
    targetedStudents: curriculumTypes(noonText, 'question'),
  };

  const getFilters = (fieldValues) => ({
    country_id: get(fieldValues.get('country'), 'id'),
    curriculum_type: get(fieldValues.get('courseType'), 'curriculumType'),
    board_id: get(fieldValues.get('board'), 'id'),
    grade_id: get(fieldValues.get('grade'), 'id'),
    subject_id: get(fieldValues.get('subject'), 'id'),
    curriculum_id: get(fieldValues.get('curriculum'), 'id'),
    chapter_id: get(fieldValues.get('chapter'), 'id'),
    degree_id: get(fieldValues.get('degree'), 'id'),
    test_id: get(fieldValues.get('test'), 'id'),
    major_id: get(fieldValues.get('major'), 'id'),
    section_id: get(fieldValues.get('section'), 'id'),
    course_id: get(fieldValues.get('course'), 'id'),
    skill_id: get(fieldValues.get('skill'), 'id'),
    topic_id: get(fieldValues.get('topic'), 'id'),
  });
  const handleOnChangeFormFieldValues = (key, value, apiRequest) => {
    const updatedFieldValues = new Map();
    let isAfterKey = false;
    for (const [currentKey, currentValue] of formFieldValues.entries()) {
      if (key === currentKey) {
        isAfterKey = true;
      }
      if (!isAfterKey) {
        updatedFieldValues.set(currentKey, currentValue);
      }
    }
    updatedFieldValues.set(key, value);
    setFormFieldValues(updatedFieldValues);
    const filters = getFilters(updatedFieldValues);
    handleOnFilterChange({ ...filters, text: searchInputRef.current.value });
    if (apiRequest) {
      getTeacherGroupTags({
        curriculumType: get(updatedFieldValues.get('courseType'), 'curriculumType'),
        requestId: apiRequest,
        ...filters,
      });
    }
  };
  useEffect(() => {
    if (isListEmpty) {
      setDisabledFilter(Array.from(formFieldValues.keys()).pop());
    } else {
      setDisabledFilter('');
    }
  }, [isListEmpty]);

  useEffect(() => {
    // only autofill board if courseType and targetedStudents exist
    const showBoards = formFieldValues.get('targetedStudents') && formFieldValues.get('courseType');
    if (boards && boards.list.length > 0 && !boards.loading && showBoards) {
      handleOnChangeFormFieldValues('board', boards.list[0], 'grades');
    }
  }, [boards]);

  return (

    <Column gap align="start" justify="center" className="library__filter">
      <Row justify="start" align="center">
        <p className="mlr-1 text-center">{translationText(noonText, 'tab.search')}</p>
        <Search
          ref={searchInputRef}
          name="question-search"
          autoComplete="on"
          loading={false}
          placeholder={translationText(noonText, 'dashboard.searchFor')}
          onChange={debounce(() => {
            handleOnFilterChange({ ...getFilters(formFieldValues), text: searchInputRef.current.value });
          }, 200)}
  />
      </Row>
      <Row gap justify="start" align="center">
        <p className="mlr-1 text-center">{translationText(noonText, 'library.filterBy')}</p>
        <Select
          selectedItem={formFieldValues.get('country')}
          onSelect={(item) => handleOnChangeFormFieldValues('country', item)}
          list={countryList}
          listItemComponent={SelectCountryWithFlag}
          titleComponent={SelectCountryWithFlag}
          outlined
          label={translationText(noonText, 'label.country')}
        />
        <Select
          list={constantFields.targetedStudents}
          selectedItem={formFieldValues.get('targetedStudents')}
          label={translationText(noonText, 'label.targetedStudents')}
          onSelect={(item) => handleOnChangeFormFieldValues('targetedStudents', item)}
          disabled={disabledFilter === 'country'}
          outlined
                  />

        {formFieldValues.get('targetedStudents') && (
        <Select
          selectedItem={formFieldValues.get('courseType')}
          onSelect={(item) => handleOnChangeFormFieldValues('courseType', item, item.fields[0])}
          label={translationText(noonText, 'label.courseType')}
          list={formFieldValues.get('targetedStudents').courseType}
          outlined
                    />
        )}
        {formFieldValues.get('courseType') && formFieldValues.get('courseType').fields.indexOf('boards') > -1
          && !isEmpty(boards.list) && (
            <Select
              list={boards.list}
              isLoading={boards.loading}
              selectedItem={formFieldValues.get('board')}
              label={translationText(noonText, 'label.selectBoard')}
              onSelect={(item) => {
                handleOnChangeFormFieldValues('board', item, 'grades');
              }}
              outlined
              disabled={disabledFilter === 'courseType'}
            />
        )}
        {formFieldValues.get('board')
          && !isEmpty(grades.list) && (
            <Select
              list={grades.list}
              isLoading={grades.loading}
              selectedItem={formFieldValues.get('grade')}
              label={translationText(noonText, 'label.selectGrade')}
              onSelect={(item) => {
                handleOnChangeFormFieldValues('grade', item, 'subjects');
              }}
              outlined
              disabled={disabledFilter === 'board'}
            />
        )}
        {formFieldValues.get('grade')
          && !isEmpty(subjects.list) && (
            <Select
              list={subjects.list}
              isLoading={subjects.loading}
              selectedItem={formFieldValues.get('subject')}
              label={translationText(noonText, 'label.selectSubject')}
              onSelect={(item) => {
                handleOnChangeFormFieldValues('subject', item, 'curriculum');
              }}
              outlined
              disabled={disabledFilter === 'grade'}
            />
        )}
        {formFieldValues.get('subject')
      && !isEmpty(curriculum.list) && (
      <Select
        list={curriculum.list}
        isLoading={curriculum.loading}
        selectedItem={formFieldValues.get('curriculum')}
        label={translationText(noonText, 'label.selectCurriculum')}
        onSelect={(item) => {
          handleOnChangeFormFieldValues('curriculum', item, 'chapters');
        }}
        outlined
        disabled={disabledFilter === 'subject'}
        />
        )}
        {formFieldValues.get('courseType') && formFieldValues.get('courseType').fields.indexOf('degrees') > -1
      && !isEmpty(degrees.list) && (
      <Select
        list={degrees.list}
        isLoading={degrees.loading}
        selectedItem={formFieldValues.get('degree')}
        label={translationText(noonText, 'label.selectDegree')}
        onSelect={(item) => {
          handleOnChangeFormFieldValues('degree', item, 'majors');
        }}
        outlined
        disabled={disabledFilter === 'courseType'}
        />
        )}
        {formFieldValues.get('degree')
      && !isEmpty(majors.list) && (
      <Select
        list={majors.list}
        isLoading={majors.loading}
        selectedItem={formFieldValues.get('major')}
        label={translationText(noonText, 'label.selectMajor')}
        onSelect={(item) => {
          handleOnChangeFormFieldValues('major', item, 'courses');
        }}
        outlined
        disabled={disabledFilter === 'degree'}
        />
        )}
        {formFieldValues.get('major')
      && !isEmpty(courses.list) && (
      <Select
        list={courses.list}
        isLoading={courses.loading}
        selectedItem={formFieldValues.get('course')}
        label={translationText(noonText, 'label.selectCourse')}
        onSelect={(item) => {
          handleOnChangeFormFieldValues('course', item, 'sections');
        }}
        outlined
        disabled={disabledFilter === 'major'}
        />
        )}

        {formFieldValues.get('courseType') && formFieldValues.get('courseType').fields.indexOf('tests') > -1
      && !isEmpty(tests.list) && (
      <Select
        list={tests.list}
        isLoading={tests.loading}
        selectedItem={formFieldValues.get('test')}
        label={translationText(noonText, 'label.selectTest')}
        onSelect={(item) => {
          handleOnChangeFormFieldValues('test', item, 'sections');
        }}
        outlined
        disabled={disabledFilter === 'courseType'}
        />
        )}
        {formFieldValues.get('courseType') && formFieldValues.get('courseType').fields.indexOf('skills') > -1
      && !isEmpty(skills.list) && (
      <Select
        list={skills.list}
        isLoading={skills.loading}
        selectedItem={formFieldValues.get('skill')}
        label={translationText(noonText, 'label.selectSkill')}
        onSelect={(item) => {
          handleOnChangeFormFieldValues('skill', item, 'sections');
        }}
        outlined
        disabled={disabledFilter === 'courseType'}
        />
        )}
        {(formFieldValues.get('course') || formFieldValues.get('test') || formFieldValues.get('skill'))
      && !isEmpty(sections.list) && (
      <Select
        list={sections.list}
        isLoading={sections.loading}
        selectedItem={formFieldValues.get('section')}
        label={translationText(noonText, 'label.selectSection')}
        onSelect={(item) => {
          handleOnChangeFormFieldValues('section', item, 'chapters');
        }}
        outlined
        disabled={disabledFilter === 'course' || disabledFilter === 'test' || disabledFilter === 'skill'}
        />
        )}
        {(formFieldValues.get('curriculum') || formFieldValues.get('section'))
      && !isEmpty(chapters.list) && (
      <Select
        list={chapters.list}
        isLoading={chapters.loading}
        selectedItem={formFieldValues.get('chapter')}
        label={translationText(noonText, 'label.selectChapter')}
        onSelect={(item) => {
          handleOnChangeFormFieldValues('chapter', item, 'topics');
        }}
        outlined
        disabled={disabledFilter === 'curriculum' || disabledFilter === 'section'}
        />
        )}
        {formFieldValues.get('chapter')
      && !isEmpty(topics.list) && (
      <Select
        list={topics.list}
        isLoading={topics.loading}
        selectedItem={formFieldValues.get('topic')}
        label={translationText(noonText, 'groupCurriculum.selectTopic')}
        onSelect={(item) => {
          handleOnChangeFormFieldValues('topic', item);
        }}
        outlined
        disabled={disabledFilter === 'chapter'}
        />
        )}
      </Row>
      <Hr />
    </Column>
  );
}

ListFilters.propTypes = {
  noonText: translationType.isRequired,
  countryList: PropTypes.arrayOf(PropTypes.shape({})),
  handleOnFilterChange: PropTypes.func.isRequired,
  getTeacherGroupTags: PropTypes.func.isRequired,
  countryId: PropTypes.string.isRequired,
  boards: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  grades: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  subjects: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  curriculum: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  chapters: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  topics: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  degrees: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  tests: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  sections: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  majors: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  courses: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  skills: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  isListEmpty: PropTypes.bool.isRequired,
};
ListFilters.defaultProps = {
  countryList: [],
  boards: {
    list: [],
    loading: false,
  },
  grades: {
    list: [],
    loading: false,
  },
  subjects: {
    list: [],
    loading: false,
  },
  curriculum: {
    list: [],
    loading: false,
  },
  chapters: {
    list: [],
    loading: false,
  },
  topics: {
    list: [],
    loading: false,
  },
  degrees: {
    list: [],
    loading: false,
  },
  tests: {
    list: [],
    loading: false,
  },
  sections: {
    list: [],
    loading: false,
  },
  majors: {
    list: [],
    loading: false,
  },
  courses: {
    list: [],
    loading: false,
  },
  skills: {
    list: [],
    loading: false,
  },
};

export default ListFilters;

import React, { useState } from 'react';
import { Column } from '@noon/atom';

const { innerHeight, innerWidth } = window;
const startingPosition = { x: 100, y: 10 };
const videoWidth = 160;
const videoHeight = 120;

const VideoCard = ({ videoStatus, children = null }) => {
  const [dragInfo, setDragInfo] = useState({
    isDragging: false,
    origin: { x: 0, y: 0 },
    translation: startingPosition,
    lastTranslation: startingPosition,
  });

  const { isDragging } = dragInfo;

  const handleMouseDown = (event) => {
    const { clientX, clientY } = event.touches ? event.touches[0] : event;
    if (!isDragging) {
      setDragInfo({
        ...dragInfo,
        isDragging: true,
        origin: { x: clientX, y: clientY },
      });
    }
  };

  const handleMouseMove = (event) => {
    const { clientX, clientY } = event.touches ? event.touches[0] : event;
    if (isDragging) {
      const { origin, lastTranslation } = dragInfo;
      const x = Math.abs(clientX - (origin.x + lastTranslation.x));
      const y = Math.abs(clientY - (origin.y + lastTranslation.y));
      setDragInfo({
        ...dragInfo,
        translation: {
          x: x < innerWidth - videoWidth ? x : innerWidth - videoWidth,
          y: y < innerHeight - videoHeight ? y : innerHeight - videoHeight,
        },
      });
    }
  };

  const handleMouseUp = () => {
    if (isDragging) {
      const { translation } = dragInfo;
      setDragInfo({
        ...dragInfo,
        isDragging: false,
        lastTranslation: { x: translation.x, y: translation.y },
      });
    }
  };

  return (
    <Column
      justify="start"
      align="start"
      nowrap
      style={{
        visibility: videoStatus ? 'visible' : 'hidden',
        position: 'fixed',
        height: `${videoHeight}px`,
        width: `${videoWidth}px`,
        zIndex: 999,
        cursor: 'move',
        right: `${dragInfo.translation.x}px`,
        bottom: `${dragInfo.translation.y}px`,
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchMove={handleMouseMove}
      onTouchCancel={handleMouseMove}
      onTouchEnd={handleMouseUp}
      >
      <div
        id="noon-agora-video"
        style={{
          visibility: videoStatus ? 'visible' : 'hidden',
          width: '100%',
          height: '100%',

        }}
       >
        {children || null}
      </div>
    </Column>
  );
};

export default VideoCard;

import classNames from 'classnames';
import { findIndex, flatten, groupBy, isEmpty } from 'lodash-es';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Draggable } from 'react-smooth-dnd';

import { Button, Column, Hr, Modal, Row } from '@noon/atom';
import {
    IconCanvasConfirm, IconEdit, IconError, IconLoader, IconPlus, IconWrong
} from '@noon/quark';

import { CLASSROOM_EVENT } from '../../../components/Classroom/events';
import { DEFAULT_SECTION_TITLE_TEXT, isRTL } from '../../../constants';
import { delay } from '../../../helpers';
import t from '../../../helpers/translate';
import { useOnClickOutside } from '../../../hooks';
import { useEventDispatch } from '../../../providers/Event';
import { moveSlide } from '../../../redux/actions/whiteboard';
import DeleteSlideConfirmationModal from '../../Class/DeleteSlideConfirmationModal';
import { IClassSlidesProp, ISlide, SlideDropParamType } from '../types';

const ClassroomSlides = (props: IClassSlidesProp) : JSX.Element => {
  const dispatch = useDispatch();
  const sendMessage = useEventDispatch();
  const slideContainerRef = useRef(null);
  const inputLabelRef = useRef(null);
  const { slides, activeSlideIndex, onSelectSlide, onDeleteSlide, onAddSlide, onMoveSlide, onMoveSection, config, isSlideLoading, isSlideAdding, isClassStarted, getSlides } = props;
  const { allowDelete, disableAddSlide, disableSelectSlide } = config;
  const allowMoveSlide = allowDelete && !isRTL() && !(isSlideLoading || isSlideAdding) && !isClassStarted;

  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [slideNumberToBeDeleted, setSlideNumberToBeDeleted] = useState<number>(-1);
  const [sections, updateSections] = useState<any>({});
  const [lastSectionClick, setLastSectionClick] = useState<string>('');
  const [showEmptyLabelWarning, toggleShowEmptyLabelWarning] = useState<boolean>(false);
  const [shouldEditNewSectionTitle, setShouldEditNewSectionTitle] = useState<boolean>(false);
  const [slidesGettingRearrangedDetails, setSlidesRearrangeDetails] = useState({ active: false, currentSelectedSectionSlides: [] });
  const lastIndex = slides.length - 1;
  const findSlideIndexById = (slideId) => findIndex(slides, { canvas_id: slideId });

  const [labelBeingEdited, updateLabelBeingEdited] = useState<{id: null | string, value: string, active: boolean}>({ id: null, value: '', active: false });

  const handleLabelUpdate = (value: string, section: string, active?: boolean): void => {
    updateLabelBeingEdited({ id: section, value, active: active || labelBeingEdited.active });
  };

  // Check if label is changed and send classroom event
  const sendUpdatedLabel = async (section) : Promise<void | boolean> => {
    if (!section || !section.length) return false;

    // Send event
    const payload = {
      label_name: labelBeingEdited.value,
      canvas_ids: section.map((item) => item.canvas_id),
    };
    sendMessage(CLASSROOM_EVENT.updateLabel, payload);

    // Reset label editing states
    handleLabelUpdateAction('');
    updateLabelBeingEdited({ ...labelBeingEdited, active: false });

    await delay(500);
    getSlides();
  };
  // Logic
  const openConfirmationModal = (slideNumber: number): void => {
    if (isSlideLoading || isSlideAdding) return;
    setDeleteConfirmationModalOpen(true);
    setSlideNumberToBeDeleted(slideNumber);
  };

  const cancelConfirmationModal = (): void => {
    setDeleteConfirmationModalOpen(false);
    setSlideNumberToBeDeleted(-1);
  };

  const confirmSlideDelete = (): void => {
    if (slideNumberToBeDeleted >= 0) {
      onDeleteSlide(slideNumberToBeDeleted);
    }
    setDeleteConfirmationModalOpen(false);
    setSlideNumberToBeDeleted(-1);
  };

  const sectionsAfterDeletingObjectBeingMoved = (allSections: Array<ISlide>, sectionToRemove: string) => {
    const sectionsCopy = allSections;
    delete sectionsCopy[sectionToRemove];
    return sectionsCopy;
  };

  // Handle rearrangement of slide when one slide is moved inside a section
  const handleSlideDrop = ({ removedIndex, addedIndex }: SlideDropParamType) => {
    if (removedIndex === addedIndex) return;
    if (isSlideLoading || isSlideAdding) return;
    const actualAddedIndex = sections[lastSectionClick][addedIndex]?.canvas_id ? findSlideIndexById(sections[lastSectionClick][addedIndex]?.canvas_id) : undefined;
    const actualRemovedIndex = sections[lastSectionClick][removedIndex]?.canvas_id ? findSlideIndexById(sections[lastSectionClick][removedIndex]?.canvas_id) : undefined;

    onMoveSlide(actualRemovedIndex, (actualAddedIndex < actualRemovedIndex) ? actualAddedIndex - 1 : actualAddedIndex);
  };

  function getSlidePayloadForIndex(index) : number {
    return index;
  }

  function onSectionSlideClick(section) : void {
    setLastSectionClick(section);
  }

  function onSectionHover(section) : void {
    // This will be added back with fixes in the next build, hence leaving it commented
    const sectionIndex = Object.keys(sections).indexOf(section);
  }

  async function activateLabelInput(labelId) {
    await delay(500);
    document.getElementById(labelId).focus();
  }

  const handleKeyDown = (e): void => {
    if (!e.target.id) return;
    if (e.key === 'Enter' && e.target.id) {
      // eslint-disable-next-line no-use-before-define
      sendUpdatedLabel(sections[e.target.id]);
    }
  };

  const handleLabelUpdateAction = (section: string): void => {
    if (section) {
      activateLabelInput(section);
      // We will bring this back later. probably needs a refactor to work
      // window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }
  };

  // Calculate sections after list of slides changes
  const evaluateSectionsForSlides = (slides): void => {
    const slideBySections = groupBy(slides, 'label_id');
    let slideIndex = 0;
    Object.keys(slideBySections).forEach((sectionId) => (slideBySections[sectionId].forEach((slide, index) => {
      slideIndex += 1;
      slideBySections[sectionId][index].slideIndex = slideIndex;
    })));
    updateSections(slideBySections);

    updateLabelBeingEdited({ value: '', id: null, active: false });

    // Activate Label edit field for newly created section
    if (shouldEditNewSectionTitle) {
      const labelId = slides[slides.length - 1]?.label_id;
      handleLabelUpdateAction(labelId);
      activateLabelInput(labelId);
      setShouldEditNewSectionTitle(false);
    }
  };

  // Handle rearrangement of slides when a whole section is moved
  const handleSectionDrop = ({ removedIndex, addedIndex } : {removedIndex:number, addedIndex:number}): void => {

    if (removedIndex === addedIndex) return;
    if (isSlideLoading || isSlideAdding) return;
    // Array of all slides (to be moved) in the current section
    let currentSelectedSectionSlides = [];

    // Destination of the slide before the destination location so the new slides can be placed after it
    let currentSectionId: string = '';
    // const activeSection: any = Object.values(sections)[removedIndex];

    // Set values for currentSelectedSectionSlides and destinationPrevSectionLastSlide
    Object.keys(sections).forEach((section, index) => {
      if (index === removedIndex) {
        currentSelectedSectionSlides = sections[section];
        currentSectionId = section;
      }
    });

    // Update the local hashmap of sections
    let newSections = sectionsAfterDeletingObjectBeingMoved(sections, currentSectionId) || {};
    if (addedIndex < Object.keys(sections).length) {
      newSections = Object.keys(sections).reduce((acc, curr, index) => {
        if (index === addedIndex) {
          return { ...acc, [currentSectionId]: currentSelectedSectionSlides, [curr]: newSections[curr] };
        }
        return { ...acc, [curr]: newSections[curr] };
      }, {});
    } else {
      newSections = { ...newSections, [currentSectionId]: currentSelectedSectionSlides };
    }

    const rearrangedSlide = flatten(Object.values(newSections));
    const sortedSlides = [...rearrangedSlide].map((slide, index) => ({
      ...slide,
      prev_canvas_id: index === 0 ? null : rearrangedSlide[index - 1].canvas_id,
      next_canvas_id: index === rearrangedSlide.length - 1 ? null : rearrangedSlide[index + 1].canvas_id }));

    const updatedSlides = sortedSlides.filter((slide, index) => slide.prev_canvas_id !== rearrangedSlide[index].prev_canvas_id);

    setSlidesRearrangeDetails({ active: true,
      currentSelectedSectionSlides: updatedSlides.map((slide) => ({
        canvas_id: slide.canvas_id,
        prev_canvas_id: slide.prev_canvas_id,
        next_canvas_id: slide.next_canvas_id,
      })) });

    dispatch(moveSlide('REQUEST', { slides: sortedSlides, activeSlideIndex: findIndex(sortedSlides, { canvas_id: slides[activeSlideIndex].canvas_id }) }));

    evaluateSectionsForSlides(sortedSlides);
  };

  const handleNewSectionAddClick = () : void => {
    setShouldEditNewSectionTitle(true);
    onAddSlide(lastIndex);
  };

  const skipLabelId = (dontShowAgain: boolean) : void => {
    toggleShowEmptyLabelWarning(false);
    handleLabelUpdateAction('');
    if (dontShowAgain) localStorage.setItem('labelWarningShown', 'true');
  };

  const handleSendLabelUpdate = async (sectionId: string): Promise<void> => {
    await sendUpdatedLabel(sectionId);
    handleLabelUpdateAction('');
  };

  const handleOnClickOutSide = useCallback(() => {
    const isWarningShown = JSON.parse(localStorage.getItem('labelWarningShown'));
    if (!isWarningShown && !labelBeingEdited.value && !sections[labelBeingEdited.id][0].label_name) {
      toggleShowEmptyLabelWarning(true);
    } else if (labelBeingEdited.value && labelBeingEdited.value !== sections[labelBeingEdited.id][0].label_name) {
      // sendUpdatedLabel(sections[labelBeingEdited.id]);
      handleSendLabelUpdate(sections[labelBeingEdited.id]);
    } else {
      handleLabelUpdateAction('');
      updateLabelBeingEdited({ id: null, value: '', active: false });
    }
  }, [labelBeingEdited.value]);

  useOnClickOutside(inputLabelRef, handleOnClickOutSide);

  const initialiseLabelEditing = (section: string, label_name: string): void => {
    handleLabelUpdateAction(section);
    handleLabelUpdate(label_name, section, true);
  };

  // Effects
  useEffect(() => {
    if (!isSlideAdding && slideContainerRef?.current?.scrollTo) {
      const scrollWidth = slideContainerRef.current?.scrollWidth || 0;
      slideContainerRef.current.scrollTo(isRTL() ? -scrollWidth : scrollWidth, 0, { behavior: 'smooth' });
    }
  }, [isSlideAdding]);

  useEffect(() => {
    if (Object.keys(sections).length && slidesGettingRearrangedDetails.active) {

      if (!isEmpty(slidesGettingRearrangedDetails.currentSelectedSectionSlides)) {
        onMoveSection(slidesGettingRearrangedDetails.currentSelectedSectionSlides);
      }

      setSlidesRearrangeDetails({ ...slidesGettingRearrangedDetails, active: false });
    }
  }, [sections]);

  useEffect(() => {
    if (slides?.length && !slidesGettingRearrangedDetails.active) {
      evaluateSectionsForSlides(slides);
    }
  }, [slides]);

  useEffect(() => () => {
    window.removeEventListener('keydown', handleKeyDown);
  }, []);

  // Render functions
  const slideItemRender = (slide: ISlide, index: number, section: string) : JSX.Element => (
    <>
      <div
        id={slide.canvas_id}
        className={classNames('slide', { active: findSlideIndexById(slide.canvas_id) === activeSlideIndex && slides[activeSlideIndex]?.label_id === section })}
        onClick={() => (disableSelectSlide ? {} : onSelectSlide(findSlideIndexById(slide.canvas_id)))}
        >
        {allowDelete && (
          <IconWrong
            title="delete"
            role="button"
            tabIndex="-1"
            onClick={(e) => {
              e.stopPropagation();
              openConfirmationModal(findSlideIndexById(slide.canvas_id));
            }}
          />
        )}
        {/* {slide.canvas_id.slice(19, 24)} */}
        {isSlideLoading === slide.canvas_id ? <IconLoader height="20" /> : slide.slideIndex}

        {/* old numbering */}
        {/* {isSlideLoading === slide.canvas_id ? <IconLoader height="20" /> : index + 1} */}
      </div>
      {!disableAddSlide && index + 1 !== sections[section].length && (
        <div
          className={classNames('add-slide', { loading: isSlideAdding === slide.canvas_id })}
          onClick={() => onAddSlide(findSlideIndexById(slide.canvas_id), { label_id: section, label_name: slide?.payload?.label_name })}>
          {isSlideAdding === slide.canvas_id ? <IconLoader /> : <IconPlus width="20px" height="20px" />}
        </div>
      )}
    </>
  );

  const sectionsRender = (section: string, sectionIndex: number) : JSX.Element => {
    const currentLabelId = sections[section]?.[0]?.label_id;
    const currentLabelName = sections[section]?.[0]?.label_name;
    const currentVisibleLabel = labelBeingEdited.id === section ? labelBeingEdited.value : currentLabelName;
    const defaultLabelName = t('classroom', 'noSectionTitle') || DEFAULT_SECTION_TITLE_TEXT[isRTL() ? 'ar' : 'en'];
    return (
      <Column className={classNames('section', { active: slides[activeSlideIndex]?.label_id === section })} key={sectionIndex}>
        <div ref={labelBeingEdited.id === section ? inputLabelRef : null}>
          <Row nowrap className="title" gap="sm" align="center">
            {labelBeingEdited.id === section && labelBeingEdited.active
              ? <input maxLength={40} id={currentLabelId} value={currentVisibleLabel} onChange={(e) => handleLabelUpdate(e.target.value, section)} placeholder="Enter title" />
              : <span className="child" onClick={() => initialiseLabelEditing(section, currentLabelName)}>{currentVisibleLabel || defaultLabelName}</span>}
            {labelBeingEdited.id === section && labelBeingEdited.active
              ? <IconCanvasConfirm className="child" fill="#ffffff" width="16px" height="16px" onClick={() => handleSendLabelUpdate(sections[section])} />
              : <IconEdit className="child hide-by-default" fill="#ffffff" width="16px" height="16px" onClick={() => initialiseLabelEditing(section, currentLabelName)} />}
          </Row>
        </div>
        <Row
          onClick={() => onSectionSlideClick(section)}
          onMouseEnter={lastSectionClick && lastSectionClick !== section ? () => onSectionHover(section) : null}>
          {allowMoveSlide ? (
            <Container groupName="section" orientation="horizontal" onDrop={handleSlideDrop} getChildPayload={getSlidePayloadForIndex}>
              {sections[section].map((slide, index) => (
                <Draggable data-id={slide.canvas_id} className="slide-draggable" key={slide.canvas_id}>
                  {slideItemRender(slide, index, section)}
                </Draggable>
              ))}
            </Container>
          ) : (
            <>
              {sections[section].map((slide, index) => (
                <div className="slide-wrapper" key={slide.canvas_id}>
                  {slideItemRender(slide, index, section)}
                </div>
              ))}
            </>
          )}
          {!disableAddSlide && (
            <Button 
              type="primary"
              size="md"
              onClick={() => onAddSlide(findSlideIndexById(sections[section][sections[section].length - 1].canvas_id), { label_id: section, label_name: currentLabelName })}
            >
              {isSlideAdding === slides[lastIndex]?.canvas_id ? <IconLoader /> : <IconPlus />}
            </Button>
          )}
        </Row>
        <div className="resize-handle" />
      </Column>
    );
  };

  return (
    <Row nowrap align="end" className="slides-bar">
      <div className="whiteboard-slides" ref={slideContainerRef}>
        {deleteConfirmationModalOpen && (
          <DeleteSlideConfirmationModal
            cancelConfirmationModal={cancelConfirmationModal}
            confirmDelete={confirmSlideDelete}
          />
        )}
        {allowMoveSlide ? (
          <Container orientation="horizontal" onDrop={handleSectionDrop}>
            {!isEmpty(sections) && !!Object.keys(sections).length ? Object.keys(sections).map((section, sectionIndex) => {
              const sectionClassName = !isEmpty(sections) && !!Object.keys(sections).length && sections[section]?.[0]?.label_id ? sections[section]?.[0]?.label_id.slice(0, 5) : '';
              return (
                <Draggable className={sectionClassName} key={sections[section]?.[0]?.label_id}>
                  {sectionsRender(section, sectionIndex)}
                </Draggable>
              );
            },
            ) : null}
          </Container>
        ) : (
          <div className="slides-container">
            {!isEmpty(sections) && !!Object.keys(sections).length ? Object.keys(sections).map((section, sectionIndex) => (
              <>{sectionsRender(section, sectionIndex)}</>
            )) : null}
          </div>
        )}
      </div>
      {!disableAddSlide && slides.length && (
        // Dummy section for visuals
        <div className="whiteboard-slides">
          <div className="slides-container">
            <Column className={classNames('section')}>
              <div>
                <Row nowrap className="title" gap="sm" align="center">
                  <span className="child">Add Section</span>
                </Row>
              </div>
              <Row>
                <Button 
                type="primary"
                size="md"
                onClick={() => handleNewSectionAddClick()}
                >
                  {isSlideAdding === slides[lastIndex]?.canvas_id ? <IconLoader /> : <IconPlus />}
                </Button>
              </Row>
            </Column>
          </div>
      </div>
      )}
      {showEmptyLabelWarning && (
        <Modal className="section-title-warning-modal" onClose={() => skipLabelId(false)}>
          <div className="body">
            <h3>
              <IconError />
              <span>{t('classroom', 'emptyLabelSectionWarningTitle')}</span>
            </h3>
            <Hr />
            <div className="text mt-1 mb-2">
              <p>{t('classroom', 'emptyLabelSectionWarningText')}</p>
              <h5>{t('classroom', 'emptyLabelSectionWarningSubtext')}</h5>
            </div>
            <Row className="button-group" justify="start" gap>
              <Button type="default" value={t('home', 'skip')} onClick={() => skipLabelId(true)} />
              <Button type="primary" value={t('home', 'emptyLabelSectionWarningCTA')} onClick={() => { toggleShowEmptyLabelWarning(false); activateLabelInput(labelBeingEdited.id); }} />
            </Row>
          </div>
        </Modal>
      )}
    </Row>
  );
};

export default ClassroomSlides;

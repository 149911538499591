import React from 'react';
import { Modal, Button, Row, Column, NoonDate } from '@noon/atom';
import { useSelector } from 'react-redux';
import { translationText } from '../../helpers';

export default function IncorrectTimeModal(prop) {

  const noonText = useSelector(state => state.toJS().translation.noonText);
  const serverTime = useSelector(state => state.toJS().user.serverTime);

  const onRefresh = () => {
    window.location.reload();
  };

  return (
    <Modal onClose={prop.onClose} className="incorrect-time-modal-container">
      <Column align="plus" nowrap className="incorrect-time-modal">
        <Column align="center" className="incorrect-time-modal--body">
          <div className="header-icon retry">
            <div className="exclamation">!</div>
          </div>
          <div className="incorrect-time-modal--body--wrapper">
            <Column className="incorrect-time-modal--body--text" align="center">
              <div className="text-1">{translationText(noonText, 'label.timeMismatchHeading')}</div>
              <div className="text-2">{translationText(noonText, 'label.timeMismatchSubheading')}</div>
            </Column>

            <Column className="incorrect-time-modal--body--time">
              <Row className="incorrect-time-modal--body--time--type mtb-1">
                <div className="incorrect-time-modal--body--time--type__text">{translationText(noonText, 'label.currentTime')}</div>
                <div className="incorrect-time-modal--body--time--type__time">
                  <NoonDate value={serverTime} format="dd LLL, hh:mm:ss a" />
                </div>
              </Row>
              <Row className="incorrect-time-modal--body--time--type mb-1">
                <div className="incorrect-time-modal--body--time--type__text">{translationText(noonText, 'label.systemTime')}</div>
                <div className="incorrect-time-modal--body--time--type__time">
                  <NoonDate value={Date.now()} format="dd LLL, hh:mm:ss a" />
                </div>
              </Row>
            </Column>
            <div className="incorrect-time-modal--body--note color-primary-light">{translationText(noonText, 'label.noteRefresh')}</div>
            <Row nowrap className="incorrect-time-modal--body--btn">
              <Button
                type="primary"
                size="large"
                onClick={onRefresh}
                className="btn-modal mlr-2"
              >
                {translationText(noonText, 'label.refresh')}
              </Button>
            </Row>
          </div>
        </Column>
      </Column>
    </Modal>
  );
}

import t from './translate';

// Add Fields based on where we are using it (group,question,etc..)
function addFields(baseFields, type) {
  switch (type) {
    case 'group':
      return baseFields;
    case 'question':
      return [...baseFields, 'chapters', 'topics'];
    default:
      return baseFields;

  }
}

export default (type = 'groups') => [
  {
    id: 1,
    key: 'k12',
    name: t('label', 'targetk12'),
    courseType: [
      {
        id: 1,
        name: t('label', 'schoolSubjects'),
        curriculumType: 'k12',
        fields: addFields(['boards', 'grades', 'subjects', 'curriculum'], type),
      },
      {
        id: 2,
        name: t('label', 'testPrep'),
        curriculumType: 'k12_test_prep',
        fields: addFields(['tests', 'sections'], type),
        targetFields: ['boards', 'grades'],
        // targetFields: ['grades'],
      },
      {
        id: 3,
        name: t('label', 'skills'),
        curriculumType: 'k12_skill',
        fields: addFields(['skills', 'sections'], type),
        targetFields: ['boards', 'grades'],
        // targetFields: ['grades'],
      },
    ],
  },
  {
    id: 2,
    key: 'university',
    name: t('label', 'targetUniversity'),
    courseType: [
      {
        id: 4,
        name: t('label', 'universityCourses'),
        curriculumType: 'university',
        fields: addFields(['degrees', 'majors', 'courses', 'sections'], type),
      },
      {
        id: 5,
        name: t('label', 'testPrep'),
        curriculumType: 'university_test_prep',
        fields: addFields(['tests', 'sections'], type),
      },
      {
        id: 6,
        name: t('label', 'skills'),
        curriculumType: 'university_skill',
        fields: addFields(['skills', 'sections'], type),
      },
    ],
  },
  // {
  //   id: 3,
  //   key: 'test_prep',
  //   name: t('label', 'testPrep'),
  //   courseType: [],
  // },
  // {
  //   id: 4,
  //   key: 'skill',
  //   name: t('label', 'skills'),
  //   courseType: [],
  // },
  // {
  //   name: 'Others',
  //   courseType: [
  //     {
  //       name: 'Specific Test Preparation (GMAT, GRE, Etc.)',
  //       curriculumType: 'general_test_prep',
  //       fields: ['tests', 'sections', 'ageGroup'],
  //     },
  //     {
  //       name: 'Skills',
  //       curriculumType: 'general_skill',
  //       fields: ['skills', 'sections', 'ageGroup'],
  //     },
  //   ],
  // },
];

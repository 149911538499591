import { create } from 'apisauce';
import { browserName, browserVersion, osName, osVersion, mobileVendor, mobileModel } from 'react-device-detect';
import store from '../store';
import {
  REFRESH_TOKEN,
  CREATE_TEMPORARY_ACCESS_TOKEN,
  COMMON_LOGOUT,
  INVALID_TIME_MODAL,
} from './constants';
import { addToast, TOAST_TYPE } from '../components/Toast';
import { getCountryId } from '../constants';
import Mixpanel from '../components/Mixpanel';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const headers = {
  country: getCountryId(),
  locale: localStorage.getItem('language') || 'en',
  platform: 'web',
  browser: `${browserName}:${browserVersion}`,
  'os-details': `${osName}:${osVersion}`,
  'device-details': `${mobileVendor}:${mobileModel}`,
  resolution: `${window.innerWidth}:${window.innerHeight}`,
  'api-version': 2,
};
const authHeaders = {
  country: getCountryId(),
  locale: localStorage.getItem('language') || 'en',
  platform: 'web',
  'api-version': 2,
};

const requestTransform = (request) => {
  if (!request.headers['x-client-time']) {
    request.headers['x-client-time'] = new Date().getTime();
  }
  if (localStorage.temporaryTokenPayload) {
    request.headers['x-device-id'] = JSON.parse(localStorage.temporaryTokenPayload).device_id;
  }
  if (localStorage.token) {
    request.headers.Authorization = `Bearer ${localStorage.token}`;
  }
  if (request.url.indexOf('teacher_logout') !== -1) {
    const { r_tkn } = localStorage.tokenData ? JSON.parse(localStorage.tokenData) : {};
    if (r_tkn) request.headers['x-refresh-token'] = r_tkn.tkn;
  }
  request.headers.country = getCountryId();
  request.headers.locale = localStorage.getItem('language') || 'en';
};

// Intercept all api responses
const apiMonitor = (response) => {
  if (response.status === 418) {
    store.dispatch({ type: INVALID_TIME_MODAL, payload: true });
    store.dispatch({ type: COMMON_LOGOUT });
    addToast('System date/time is incorrect, Please update', TOAST_TYPE.ERROR, 5);
    return false;
  } if (response.status === 401) {
    store.dispatch({ type: COMMON_LOGOUT });
    addToast('User not Authorized, Please login', TOAST_TYPE.ERROR, 5);
    // mixpanel [start]
    try {
      Mixpanel.reset();
    } catch (e) {
      console.log(e);
    }
    // mixpanel [end]
    return false;
  } if (response.status === 430) {
    if (localStorage.tokenData) {
      store.dispatch({ type: REFRESH_TOKEN.REQUEST });
    } else {
      store.dispatch({ type: CREATE_TEMPORARY_ACCESS_TOKEN.REQUEST, payload: { json: localStorage.temporaryTokenPayload } });
    }
  } else if (store.getState().dashboard.invalidTimeModal) {
    store.dispatch({ type: INVALID_TIME_MODAL, payload: false });
  }
  return true;
};

export const usersApi = create({
  baseURL: process.env.API_USER_URL,
  headers,
});
usersApi.addMonitor(apiMonitor);
usersApi.addRequestTransform(requestTransform);

export const usersv2Api = create({
  baseURL: process.env.API_USER_URL_V2,
  headers,
});
usersv2Api.addMonitor(apiMonitor);
usersv2Api.addRequestTransform(requestTransform);

export const postApi = create({
  baseURL: process.env.API_POST_URL,
  headers,
});
postApi.addMonitor(apiMonitor);
postApi.addRequestTransform(requestTransform);

export const postv3Api = create({
  baseURL: process.env.API_POST_URL_V3,
  headers,
});
postv3Api.addMonitor(apiMonitor);
postv3Api.addRequestTransform(requestTransform);

export const biFrostApi = create({
  baseURL: process.env.API_BI_FROST_URL,
  headers: { ...authHeaders },
});
biFrostApi.addMonitor(apiMonitor);
biFrostApi.addRequestTransform(requestTransform);

export const usersApiNew = create({
  baseURL: process.env.API_USER_URL_NEW,
  headers: { ...authHeaders, 'x-tenant': 'teacher' },
});
usersApiNew.addMonitor(apiMonitor);
usersApiNew.addRequestTransform(requestTransform);

export const translationApi = create({
  baseURL: process.env.API_TRANSLATION_URL,
  headers,
});
translationApi.addMonitor(apiMonitor);
translationApi.addRequestTransform(requestTransform);

export const folderApi = create({
  baseURL: process.env.API_FOLDER_URL,
  headers,
});
folderApi.addMonitor(apiMonitor);
folderApi.addRequestTransform(requestTransform);

export const athenaApi = create({
  baseURL: process.env.API_ATHENA,
  headers,
});
athenaApi.addMonitor(apiMonitor);
athenaApi.addRequestTransform(requestTransform);

export const athenaApiV2 = create({
  baseURL: process.env.API_ATHENA_V2,
  headers,
});
athenaApiV2.addMonitor(apiMonitor);
athenaApiV2.addRequestTransform(requestTransform);

export const packageApi = create({
  baseURL: process.env.API_PACKAGE_URL,
  headers,
});
packageApi.addMonitor(apiMonitor);
packageApi.addRequestTransform(requestTransform);

export const questionApi = create({
  baseURL: process.env.API_QUESTION_URL,
  headers,
});
questionApi.addMonitor(apiMonitor);
questionApi.addRequestTransform(requestTransform);

export const questionApiv2 = create({
  baseURL: process.env.API_QUESTION_URL_V2,
  headers,
});
questionApiv2.addMonitor(apiMonitor);
questionApiv2.addRequestTransform(requestTransform);

export const flashcardApiV2 = create({
  baseURL: process.env.API_FLASHCARD_URL_V2,
  headers,
});
flashcardApiV2.addMonitor(apiMonitor);
flashcardApiV2.addRequestTransform(requestTransform);

export const amoebaApi = create({
  baseURL: process.env.API_AMOEBA_URL,
  headers,
});
amoebaApi.addMonitor(apiMonitor);
amoebaApi.addRequestTransform(requestTransform);

export const fileApi = create({
  baseURL: process.env.API_FILE_URL,
  headers,
});
fileApi.addMonitor(apiMonitor);
fileApi.addRequestTransform(requestTransform);

export const onboardingApi = create({
  baseURL: process.env.API_ONBOARDING_URL,
  headers: { ...authHeaders, 'x-tenant': 'teacher' },
});
onboardingApi.addMonitor(apiMonitor);
onboardingApi.addRequestTransform(requestTransform);

export const excaliburApi = create({
  baseURL: process.env.API_EXCALIBUR_URL,
  headers,
});
excaliburApi.addMonitor(apiMonitor);
excaliburApi.addRequestTransform(requestTransform);

export const loggerApi = create({
  baseURL: process.env.NOON_LOGGER_URL,
  headers: { ...authHeaders, 'x-tenant': 'teacher' },
});
loggerApi.addMonitor(apiMonitor);
loggerApi.addRequestTransform(requestTransform);

export const statsLoggerApi = 'classroom_stats?is_teacher=true';

export const realtimeApi = create({
  baseURL: process.env.REAL_TIME_API,
  headers: { ...authHeaders, 'x-tenant': 'teacher' },
});
realtimeApi.addMonitor(apiMonitor);
realtimeApi.addRequestTransform(requestTransform);

export const curriculumApi = create({
  baseURL: process.env.API_CURRICULUM_URL,
  headers,
});
curriculumApi.addMonitor(apiMonitor);
curriculumApi.addRequestTransform(requestTransform);

export const giphyApi = create({
  baseURL: '//api.giphy.com/v1/',
});

// V2 groups api
export const groupsApi = create({
  baseURL: process.env.API_GROUPS_URL_V2,
  headers: { ...headers, timezone },
});
groupsApi.addMonitor(apiMonitor);
groupsApi.addRequestTransform(requestTransform);

export const feedQuesionApi = create({
  baseURL: process.env.API_FEED_QUESTION_URL,
  headers,
});
feedQuesionApi.addMonitor(apiMonitor);
feedQuesionApi.addRequestTransform(requestTransform);

// sparta api
export const spartaApi = create({
  baseURL: process.env.API_SPARTA_URL,
  headers,
});
spartaApi.addMonitor(apiMonitor);
spartaApi.addRequestTransform(requestTransform);

// promotion api
export const promotionApi = create({
  baseURL: process.env.API_PROMOTION_URL,
  headers: { ...authHeaders, timezone, 'x-tenant': 'teacher' },
});
promotionApi.addMonitor(apiMonitor);
promotionApi.addRequestTransform(requestTransform);

export const schedulerPlannerApi = create({
  baseURL: process.env.API_SCHEDULER_PLANNER_URL,
  headers: { ...headers, timezone },
});
schedulerPlannerApi.addMonitor(apiMonitor);
schedulerPlannerApi.addRequestTransform(requestTransform);

export const experimentApi = create({
  baseURL: process.env.API_EXPERIMENT_URL,
  headers: authHeaders,
});
experimentApi.addMonitor(apiMonitor);
experimentApi.addRequestTransform(requestTransform);

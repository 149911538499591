import React, { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { Card, Radio, Column, Row, Button } from '@noon/atom';
import { IconArrowDown } from '@noon/quark';
import classNames from 'classnames';
import { GET_CAMPAIGN_BANNER, SET_SELECTED_IMAGE_CONTENT } from '../../redux/constants';
import { COLORS } from '../../constants';
import { useEventDispatch } from '../../providers/Event';
import { CLASSROOM_EVENT } from '../Classroom/events';
import CampaignBannerCard from './CampaignBannerCard';
import { addToast, TOAST_TYPE } from '../Toast';
import t from '../../helpers/translate';
import ErrorBoundry from '../ErrorBoundry';
import Mixpanel from '../Mixpanel';
import { getFirebaseRemoteConfigValue } from '../Firebase';
import { selectSlide, slideToBeAdded, slideToBeDeleted, updateSlideConfig } from '../../redux/actions/whiteboard';
import { dataURLtoFile } from '../../helpers';
interface ICustomWindow extends Window {
  LoggingManager: any;
}

declare let window: ICustomWindow;
function ClassroomSidebarGroupList() {
  const { id: classId } = useParams();
  const logger = useRef(window.LoggingManager.mount({ moduleName: 'classroom' }));

  const { campaignBanner } = useSelector((state) => state.toJS().promotion);
  const [promotionStarted, setPromotionStarted] = useState(false);
  const {
    start_time: sessionStartTime,
    group: { id: groupId },
  } = useSelector((state) => state.toJS().myClassroom.sessionDetails);
  const { width: whiteboardWidth } = useSelector((state) => state.toJS().whiteboard.whiteboardSize);
  const { liveStudentCount } = useSelector((state) => state.toJS().myClassroom.leaderboard);
  const slides = useSelector((state) => state.toJS().whiteboard.slides);
  const activeSlideIndex = useSelector((state) => state.toJS().whiteboard.activeSlideIndex);

  const sendMessage = useEventDispatch();

  const isPromotionCompleted = localStorage.getItem('classroomPromotion') === classId;
  const [showGroupList, setShowGroupList] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({
    title: String,
    description: String,
    group_info: { id: Number, apple_product_id: String },
    tags: {},
  });
  const [enableMarketingButton, setEnableMarketingButton] = useState(false);
  const [minTimeForPromotion, setMinTimeForPromotion] = useState(10);

  const dispatch = useDispatch();

  const getEnableMarketingButton = () => {
    getFirebaseRemoteConfigValue('constants_config_teacher_web').then((response) => {
      setMinTimeForPromotion(response.MIN_TIME_FOR_PROMOTION);
      const clearEnableMarketingInterval = setInterval(() => {
        let isEnabled = Date.now() - sessionStartTime > response.MIN_TIME_FOR_PROMOTION * 60 * 1000;
        if (isEnabled) {
          setEnableMarketingButton(isEnabled);
          clearInterval(clearEnableMarketingInterval);
        }
      }, 1000);
    });
  };

  const handleOnClickExpandGroupList = () => {
    setShowGroupList(!showGroupList);
  };
  const handleOnClickGroupCard = (group) => {
    setSelectedGroup(group);
  };

  const triggerAnalyticsDataEvent = (eventName) => {
    // Trigger event when user click on any part of the banner
    const eventData = {
      ad_campaign_id: selectedGroup.tags?.['ntm_reference_id'],
      ad_location: 'myc_ad_post_session',
      ntm_campaign: selectedGroup.tags?.['ntm_campaign'],
      ntm_goal: selectedGroup.tags?.['ntm_goal'],
      ntm_myc_group_id: selectedGroup.tags?.['ntm_myc_group_id'],
      group_id: selectedGroup.tags?.['ntm_premium_group_id'],
      ntm_reference_id: selectedGroup.tags?.['ntm_reference_id'],
      ntm_source: selectedGroup.tags?.['ntm_source'],
      subject_id: selectedGroup?.['curriculum_tags']?.['subject']?.['id'],
      teacher_id: selectedGroup?.['creator']?.['id'],
      subject: selectedGroup?.['curriculum_tags']?.['subject']?.['name'],
      timestamp: Date.now(),
      no_of_students: liveStudentCount,
    };
    Mixpanel.track(eventName, eventData);
    logger.current.track(eventName, { classroom: eventData });
  };

  const handleOnClickMarketThisGroup = () => {
    localStorage.setItem('classroomPromotion', classId);
    setPromotionStarted(true);
    dispatch(updateSlideConfig({ disableAddSlide: true, disableSelectSlide: true }));
    // Add new slide to draw promotion banner
    const slideIndex = slides.length || 0;
    localStorage.setItem('classroomPromotionSlide', String(slideIndex));
    dispatch(slideToBeAdded(slideIndex, { resourceType: 'promotion' }));

    // Draw promotion banner on canvas
    const bannerElement = document.getElementById('div-campaign-banner');
    if (bannerElement) {
      const bannerWidth = whiteboardWidth - 50;
      const bannerHeight = bannerWidth * (9 / 16);
      bannerElement.style.width = `${bannerWidth}px`;
      bannerElement.style.height = `${bannerHeight}px`;

      html2canvas(bannerElement, { width: whiteboardWidth, height: bannerHeight }).then((canvas) => {
        document.body.appendChild(canvas);
        const imageUrl = canvas.toDataURL('image/png', 1.0);

        const payload = {
          url: dataURLtoFile(imageUrl, `promotion-image-${classId}.png`),
          isDataUrl: true,
        };
        dispatch({ type: SET_SELECTED_IMAGE_CONTENT, payload });
        canvas.remove();
      });
    }

    // Send promotion event
    sendMessage(CLASSROOM_EVENT.promotion, {
      ...selectedGroup.tags,
      sessionId: classId,
      apple_product_id: selectedGroup.group_info.apple_product_id,
      title: selectedGroup.title,
      description: selectedGroup.description,
    });
    addToast(t('notification', 'enabledMarketing'), TOAST_TYPE.INFO);
    triggerAnalyticsDataEvent('classroom_promotion_started');
  };

  // Clear promotion slide and storage on close
  const handleOnClickCloseMarketing = () => {
    setPromotionStarted(false);
    addToast(t('notification', 'marketingSuccessful'), TOAST_TYPE.SUCCESS);
    triggerAnalyticsDataEvent('classroom_promotion_ended');
    dispatch(updateSlideConfig({ disableAddSlide: false, disableSelectSlide: false }));
    const promotionSlideIndex = parseInt(localStorage.getItem('classroomPromotionSlide'), 10);
    const prevSlideIndex = promotionSlideIndex - 1;
    if (prevSlideIndex > -1) {
      dispatch(selectSlide(prevSlideIndex));
    }
    dispatch(slideToBeDeleted(promotionSlideIndex));
    localStorage.removeItem('classroomPromotionSlide');
  };

  useEffect(() => {
    dispatch({
      type: GET_CAMPAIGN_BANNER.REQUEST,
      payload: { group_id: groupId },
    });
    if (
      parseInt(localStorage.getItem('classroomPromotionSlide'), 10) >= 0 &&
      localStorage.getItem('classroomPromotion') === classId
    ) {
      setPromotionStarted(true);
      setShowGroupList(true);
    } else {
      localStorage.removeItem('classroomPromotionSlide');
    }
    getEnableMarketingButton();
  }, []);

  return !isEmpty(campaignBanner.list) ? (
    <ErrorBoundry>
      <Column gap justify="center" align="start" className="classroom-promotion-sidebar">
        <Row flex="1" nowrap gap justify="start" align="center" className="full-width">
          <h4 className="flex-1 child classroom-promotion-sidebar__title">{t('campaign', 'classroomSidebarTitle')}</h4>
          <Button onClick={handleOnClickExpandGroupList} className="classroom-promotion-sidebar__button-expand">
            <IconArrowDown flipY={showGroupList} width="12px" height="12px" fill="#62687A" stroke="#62687A" />
          </Button>
        </Row>
        {showGroupList && (
          <Column gap justify="center" align="start" className="classroom-promotion-sidebar__detail">
            <p className="child flex-1 classroom-promotion-sidebar__message">
              {t('campaign', 'classroomSidebarMessage')}
            </p>
            <h6 className="child flex-1 classroom-promotion-sidebar__heading-group-list">
              {t('placeholder', 'selectGroup')}
            </h6>
            <div
              className={classNames('classroom-promotion-sidebar__group-list full-width', {
                disable: promotionStarted || isPromotionCompleted,
              })}
            >
              <Column gap="sm" justify="center" align="start" nowrap className="full-width">
                {campaignBanner.list.map((group) => (
                  <Card
                    key={group.group_info.id}
                    onClick={() => handleOnClickGroupCard(group)}
                    className={classNames('classroom-promotion-sidebar__group-card full-width', {
                      active: group.group_info.id === selectedGroup.group_info.id,
                    })}
                  >
                    <Row gap nowrap justify="start" align="start">
                      <Radio checked={group.group_info.id === selectedGroup.group_info.id} name="group" />
                      <Column gap="sm" flex="1">
                        <Row className="subject-tag" justify="start" align="center">
                          <img
                            className="child subject-tag__image"
                            alt="group name"
                            height="16px"
                            src={group.product.pic}
                          />
                          <span
                            style={{
                              backgroundColor: group.product.color || COLORS.brand.base,
                            }}
                            className="child subject-tag__name"
                          >
                            {group.product.name}
                          </span>
                        </Row>
                        <h3 className="classroom-promotion-sidebar__group-card__group-name child">
                          {group.group_info.title}
                        </h3>
                      </Column>
                    </Row>
                  </Card>
                ))}
              </Column>
            </div>
            {promotionStarted ? (
              <Button
                type="red"
                value={t('button', 'closeMarketing')}
                block
                size="lg"
                onClick={handleOnClickCloseMarketing}
                className="text-capitalize"
              />
            ) : (
              <Button
                type="primary"
                value={t('button', 'startMarketing')}
                block
                size="lg"
                onClick={handleOnClickMarketThisGroup}
                disabled={!enableMarketingButton || isPromotionCompleted || isEmpty(selectedGroup.tags)}
                className="text-capitalize"
                tooltip={
                  isPromotionCompleted
                    ? t('tooltip', 'campaignOnlyOnce')
                    : t('tooltip', 'campaignAfterXMinute').replace('{xMinutes}', String(minTimeForPromotion))
                }
                tooltipPos="left"
              />
            )}
            <Row gap="sm" nowrap justify="start" align="start">
              <svg
                className="icon child"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.99999 16C6.41774 16 4.871 15.5308 3.55541 14.6518C2.23982 13.7727 1.21451 12.5233 0.60901 11.0615C0.00351018 9.59966 -0.154976 7.99113 0.153706 6.43929C0.462387 4.88744 1.22438 3.46195 2.3432 2.34313C3.46202 1.22432 4.88746 0.462411 6.43931 0.153729C7.99115 -0.154952 9.59959 0.00344284 11.0614 0.608943C12.5232 1.21444 13.7726 2.23984 14.6517 3.55543C15.5307 4.87102 16 6.41776 16 8.00001C15.9976 10.121 15.154 12.1544 13.6542 13.6542C12.1545 15.154 10.121 15.9976 7.99999 16ZM7.99999 1.1163C6.63852 1.1163 5.3077 1.52002 4.17568 2.27641C3.04366 3.0328 2.16127 4.10787 1.64026 5.3657C1.11925 6.62354 0.982935 8.00763 1.24854 9.34294C1.51415 10.6783 2.16974 11.9048 3.13244 12.8675C4.09515 13.8302 5.32166 14.4858 6.65697 14.7515C7.99228 15.0171 9.37642 14.8807 10.6343 14.3597C11.8921 13.8387 12.9672 12.9564 13.7236 11.8244C14.48 10.6923 14.8837 9.36148 14.8837 8.00001C14.8817 6.17494 14.1559 4.42517 12.8654 3.13465C11.5748 1.84413 9.82506 1.11827 7.99999 1.1163ZM8.00002 5.76741C8.61652 5.76741 9.11629 5.26764 9.11629 4.65113C9.11629 4.03463 8.61652 3.53486 8.00002 3.53486C7.38351 3.53486 6.88374 4.03463 6.88374 4.65113C6.88374 5.26764 7.38351 5.76741 8.00002 5.76741ZM7.96656 6.51162H8.03353C8.42604 6.51162 8.74423 6.82981 8.74423 7.22232V11.7544C8.74423 12.1469 8.42604 12.4651 8.03353 12.4651H7.96656C7.57405 12.4651 7.25586 12.1469 7.25586 11.7544V7.22232C7.25586 6.82981 7.57405 6.51162 7.96656 6.51162Z"
                  fill="#62687A"
                />
              </svg>
              <span className="classroom-promotion-sidebar__note">{t('campaign', 'noteOnlyOnce')}</span>
            </Row>
          </Column>
        )}
      </Column>
      {!isEmpty(selectedGroup.tags) && <CampaignBannerCard campaign={selectedGroup} />}
    </ErrorBoundry>
  ) : null;
}

ClassroomSidebarGroupList.propTypes = {};

export default ClassroomSidebarGroupList;

import { Card } from '@noon/atom';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { translationText } from '../../../helpers';

function TeamGroup(props) {
  const { engaged, notEngaged, superEngaged, team } = props;
  let fillColor;
  switch (true) {
    case engaged:
      fillColor = '#F2994A';
      break;
    case superEngaged:
      fillColor = '#27AE60';
      break;
    case notEngaged:
      fillColor = '#EB5757';
      break;
    default:
      fillColor = '#23294E';
      break;
  }

  return (
    <svg
      width={134}
      height={126}
      viewBox="0 0 134 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className="breakout-team-display"
    >
      <circle opacity={0.05} cx={70} cy={70} r={50} fill={fillColor} />
      <circle opacity={0.05} cx={70} cy={70} r={40} fill={fillColor} />
      <circle opacity={0.05} cx={70} cy={70} r={30} fill={fillColor} />
      {getTeamMembers(team ? team.team_members : null, fillColor)}
    </svg>
  );
}

function BreakoutTeam(props) {
  const { team, engaged, superEngaged, notEngaged } = props;
  const { noonText } = useSelector((state) => state.toJS().translation);
  let statusTextStyle = null;
  let statusText = '';
  switch (true) {
    case engaged:
      statusTextStyle = { color: '#F2994A' };
      statusText = translationText(noonText, 'breakoutReport.good');
      break;
    case notEngaged:
      statusTextStyle = { color: '#EB5757' };
      statusText = translationText(noonText, 'breakoutReport.silent');
      break;
    case superEngaged:
      statusTextStyle = { color: '#27AE60' };
      statusText = translationText(noonText, 'breakoutReport.active');
      break;
    default:
      break;
  }

  return (
    <Card spacing={20} className="breakout-team breakout-team-container">
      {!team && (
      <h4 className="breakout-team-title">
        {translationText(noonText, 'breakoutReport.starting')}
        {' '}
      </h4>
      )}

      {team && (
        <h4 className="breakout-team-title">
          <img
            alt="team"
            className="team-profile-pic"
            src={team.team_image}
            style={{ width: '24px', height: '24px', marginRight: '4px' }}
          />
          {team.team_name}
          {' '}
        </h4>
      )}

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <TeamGroup {...props} />

      <span className="status-text" style={statusTextStyle}>
        {statusText}
      </span>

    </Card>
  );
}

function getTeamMembers(members, fillColor) {

  switch (true) {
    case !members:
      return (
        <React.Fragment>
          <path
            d="M32 125.5c9.113 0 16.5-7.387 16.5-16.5S41.113 92.5 32 92.5 15.5 99.887 15.5 109s7.387 16.5 16.5 16.5z"
            fill="url(#prefix__pattern0)"
            stroke={fillColor}
          />
          <path
            d="M67 33.5c9.113 0 16.5-7.387 16.5-16.5S76.113.5 67 .5 50.5 7.887 50.5 17 57.887 33.5 67 33.5z"
            fill="url(#prefix__pattern1)"
            stroke={fillColor}
          />
          <path
            d="M117 77.5c9.113 0 16.5-7.387 16.5-16.5s-7.387-16.5-16.5-16.5-16.5 7.387-16.5 16.5 7.387 16.5 16.5 16.5z"
            fill="url(#prefix__pattern2)"
            stroke={fillColor}
          />
          <path
            d="M98 125.5c9.113 0 16.5-7.387 16.5-16.5S107.113 92.5 98 92.5 81.5 99.887 81.5 109s7.387 16.5 16.5 16.5z"
            fill="url(#prefix__pattern3)"
            stroke={fillColor}
          />
          <path
            d="M17 77.5c9.113 0 16.5-7.387 16.5-16.5S26.113 44.5 17 44.5.5 51.887.5 61 7.887 77.5 17 77.5z"
            fill="url(#prefix__pattern4)"
            stroke={fillColor}
          />
          <defs>
            <pattern id="prefix__pattern0" patternContentUnits="objectBoundingBox" width={1} height={1}>
              <use xlinkHref="#prefix__image0" transform="scale(.00098)" />
            </pattern>
            <pattern id="prefix__pattern1" patternContentUnits="objectBoundingBox" width={1} height={1}>
              <use xlinkHref="#prefix__image1" transform="scale(.00195)" />
            </pattern>
            <pattern id="prefix__pattern2" patternContentUnits="objectBoundingBox" width={1} height={1}>
              <use xlinkHref="#prefix__image2" transform="scale(.00098)" />
            </pattern>
            <pattern id="prefix__pattern3" patternContentUnits="objectBoundingBox" width={1} height={1}>
              <use xlinkHref="#prefix__image3" transform="scale(.00098)" />
            </pattern>
            <pattern id="prefix__pattern4" patternContentUnits="objectBoundingBox" width={1} height={1}>
              <use xlinkHref="#prefix__image4" transform="matrix(.00581 0 0 .00581 0 -.244)" />
            </pattern>
            <image id="prefix__image0" width={1024} height={1024} href="/assets/images/profileFilled.png" />
            <image id="prefix__image1" width={512} height={512} href="/assets/images/profileFilled.png" />
            <image id="prefix__image2" width={1024} height={1024} href="/assets/images/profileFilled.png" />
            <image id="prefix__image3" width={1024} height={1024} href="/assets/images/profileFilled.png" />
            <image id="prefix__image4" width={172} height={256} href="/assets/images/profileFilled.png" />
          </defs>
        </React.Fragment>
      );
    case members && members.length <= 2:
      return (
        <React.Fragment>
          <path
            d="M 67 33.5 c 9.113 0 16.5 -7.387 16.5 -16.5 S 76.113 0.5 67 0.5 S 50.5 7.887 50.5 17 S 57.887 33.5 67 33.5 Z"
            fill="url(#prefix__pattern0)"
            stroke={fillColor}
      />
          <path
            d="M 67 141.5 c 9.113 0 16.5 -7.387 16.5 -16.5 s -7.387 -16.5 -16.5 -16.5 S 50.5 115.887 50.5 125 s 7.387 16.5 16.5 16.5 z"
            fill="url(#prefix__pattern1)"
            stroke={fillColor}
      />
          <defs>
            <pattern
              id="prefix__pattern0"
              patternContentUnits="objectBoundingBox"
              width={1}
              height={1}
        >
              <use xlinkHref="#prefix__image0" transform="scale(.00195)" />
            </pattern>
            <pattern
              id="prefix__pattern1"
              patternContentUnits="objectBoundingBox"
              width={1}
              height={1}
        >
              <use xlinkHref="#prefix__image1" transform="scale(.00098)" />
            </pattern>
            <image id="prefix__image0" width={512} height={512} href={members && members.length ? (members[0].profile_pic ? members[0].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="prefix__image1" width={1024} height={1024} href={members && members.length === 2 ? (members[1].profile_pic ? members[1].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
          </defs>
        </React.Fragment>
      );
    case members && members.length === 3:
      return (
        <React.Fragment>
          <path
            d="M71 33.5c9.113 0 16.5-7.387 16.5-16.5S80.113.5 71 .5 54.5 7.887 54.5 17 61.887 33.5 71 33.5z"
            fill="url(#prefix__pattern0)"
            stroke={fillColor}
          />
          <g filter="url(#prefix__filter0_d)">
            <path
              d="M125 87c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16z"
              fill="url(#prefix__pattern1)"
            />
            <path
              d="M125 87.5c9.113 0 16.5-7.387 16.5-16.5s-7.387-16.5-16.5-16.5-16.5 7.387-16.5 16.5 7.387 16.5 16.5 16.5z"
              stroke={fillColor}
            />
          </g>
          <path
            d="M17 87.5c9.113 0 16.5-7.387 16.5-16.5S26.113 54.5 17 54.5.5 61.887.5 71 7.887 87.5 17 87.5z"
            fill="url(#prefix__pattern2)"
            stroke={fillColor}
          />
          <defs>
            <pattern id="prefix__pattern0" patternContentUnits="objectBoundingBox" width={1} height={1}>
              <use xlinkHref="#prefix__image0" transform="scale(.00195)" />
            </pattern>
            <pattern id="prefix__pattern1" patternContentUnits="objectBoundingBox" width={1} height={1}>
              <use xlinkHref="#prefix__image1" transform="scale(.00098)" />
            </pattern>
            <pattern id="prefix__pattern2" patternContentUnits="objectBoundingBox" width={1} height={1}>
              <use xlinkHref="#prefix__image2" transform="matrix(.00581 0 0 .00581 0 -.244)" />
            </pattern>
            <image id="prefix__image0" width={512} height={512} href={members && members.length ? (members[1].profile_pic ? members[1].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="prefix__image1" width={1024} height={1024} href={members && members.length ? (members[0].profile_pic ? members[0].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="prefix__image2" width={172} height={256} href={members && members.length ? (members[2].profile_pic ? members[2].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <filter
              id="prefix__filter0_d"
              x={104}
              y={54}
              width={42}
              height={42}
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity={0} result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
              <feOffset dy={4} />
              <feGaussianBlur stdDeviation={2} />
              <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0" />
              <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
              <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
          </defs>
        </React.Fragment>
      );
    case members && members.length === 4:
      return (
        <React.Fragment>
          <path
            d="M71 141.5c9.113 0 16.5-7.387 16.5-16.5s-7.387-16.5-16.5-16.5-16.5 7.387-16.5 16.5 7.387 16.5 16.5 16.5z"
            fill="url(#prefix__pattern0)"
            stroke={fillColor}
          />
          <path
            d="M71 33.5c9.113 0 16.5-7.387 16.5-16.5S80.113.5 71 .5 54.5 7.887 54.5 17 61.887 33.5 71 33.5z"
            fill="url(#prefix__pattern1)"
            stroke={fillColor}
          />
          <g filter="url(#prefix__filter0_d)">
            <path
              d="M125 87c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16z"
              fill="url(#prefix__pattern2)"
            />
            <path
              d="M125 87.5c9.113 0 16.5-7.387 16.5-16.5s-7.387-16.5-16.5-16.5-16.5 7.387-16.5 16.5 7.387 16.5 16.5 16.5z"
              stroke={fillColor}
            />
          </g>
          <path
            d="M17 87.5c9.113 0 16.5-7.387 16.5-16.5S26.113 54.5 17 54.5.5 61.887.5 71 7.887 87.5 17 87.5z"
            fill="url(#prefix__pattern3)"
            stroke={fillColor}
          />
          <defs>
            <pattern id="prefix__pattern0" patternContentUnits="objectBoundingBox" width={1} height={1}>
              <use xlinkHref="#prefix__image0" transform="scale(.00098)" />
            </pattern>
            <pattern id="prefix__pattern1" patternContentUnits="objectBoundingBox" width={1} height={1}>
              <use xlinkHref="#prefix__image1" transform="scale(.00195)" />
            </pattern>
            <pattern id="prefix__pattern2" patternContentUnits="objectBoundingBox" width={1} height={1}>
              <use xlinkHref="#prefix__image2" transform="scale(.00098)" />
            </pattern>
            <pattern id="prefix__pattern3" patternContentUnits="objectBoundingBox" width={1} height={1}>
              <use xlinkHref="#prefix__image3" transform="matrix(.00581 0 0 .00581 0 -.244)" />
            </pattern>
            <image id="prefix__image0" width={1024} height={1024} href={members && members.length ? (members[0].profile_pic ? members[0].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="prefix__image1" width={512} height={512} href={members && members.length ? (members[1].profile_pic ? members[1].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="prefix__image2" width={1024} height={1024} href={members && members.length ? (members[2].profile_pic ? members[2].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="prefix__image3" width={172} height={256} href={members && members.length ? (members[3].profile_pic ? members[3].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <filter
              id="prefix__filter0_d"
              x={104}
              y={54}
              width={42}
              height={42}
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity={0} result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
              <feOffset dy={4} />
              <feGaussianBlur stdDeviation={2} />
              <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0" />
              <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
              <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
          </defs>
        </React.Fragment>
      );
    case members && members.length === 5:
      return (
        <React.Fragment>
          <path
            d="M32 125.5c9.113 0 16.5-7.387 16.5-16.5S41.113 92.5 32 92.5 15.5 99.887 15.5 109s7.387 16.5 16.5 16.5z"
            fill="url(#prefix__pattern0)"
            stroke={fillColor}
          />
          <path
            d="M67 33.5c9.113 0 16.5-7.387 16.5-16.5S76.113.5 67 .5 50.5 7.887 50.5 17 57.887 33.5 67 33.5z"
            fill="url(#prefix__pattern1)"
            stroke={fillColor}
          />
          <path
            d="M117 77.5c9.113 0 16.5-7.387 16.5-16.5s-7.387-16.5-16.5-16.5-16.5 7.387-16.5 16.5 7.387 16.5 16.5 16.5z"
            fill="url(#prefix__pattern2)"
            stroke={fillColor}
          />
          <path
            d="M98 125.5c9.113 0 16.5-7.387 16.5-16.5S107.113 92.5 98 92.5 81.5 99.887 81.5 109s7.387 16.5 16.5 16.5z"
            fill="url(#prefix__pattern3)"
            stroke={fillColor}
          />
          <path
            d="M17 77.5c9.113 0 16.5-7.387 16.5-16.5S26.113 44.5 17 44.5.5 51.887.5 61 7.887 77.5 17 77.5z"
            fill="url(#prefix__pattern4)"
            stroke={fillColor}
          />
          <defs>
            <pattern id="prefix__pattern0" patternContentUnits="objectBoundingBox" width={1} height={1}>
              <use xlinkHref="#prefix__image0" transform="scale(.00098)" />
            </pattern>
            <pattern id="prefix__pattern1" patternContentUnits="objectBoundingBox" width={1} height={1}>
              <use xlinkHref="#prefix__image1" transform="scale(.00195)" />
            </pattern>
            <pattern id="prefix__pattern2" patternContentUnits="objectBoundingBox" width={1} height={1}>
              <use xlinkHref="#prefix__image2" transform="scale(.00098)" />
            </pattern>
            <pattern id="prefix__pattern3" patternContentUnits="objectBoundingBox" width={1} height={1}>
              <use xlinkHref="#prefix__image3" transform="scale(.00098)" />
            </pattern>
            <pattern id="prefix__pattern4" patternContentUnits="objectBoundingBox" width={1} height={1}>
              <use xlinkHref="#prefix__image4" transform="matrix(.00581 0 0 .00581 0 -.244)" />
            </pattern>
            <image id="prefix__image0" width={1024} height={1024} href={members && members.length ? (members[0].profile_pic ? members[0].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="prefix__image1" width={512} height={512} href={members && members.length ? (members[1].profile_pic ? members[1].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="prefix__image2" width={1024} height={1024} href={members && members.length ? (members[2].profile_pic ? members[2].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="prefix__image3" width={1024} height={1024} href={members && members.length ? (members[3].profile_pic ? members[3].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="prefix__image4" width={172} height={256} href={members && members.length ? (members[4].profile_pic ? members[4].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
          </defs>
        </React.Fragment>
      );
    case members && members.length === 6:
      return (
        <React.Fragment>
          <path
            d="M71 33.5c9.113 0 16.5-7.387 16.5-16.5S80.113.5 71 .5 54.5 7.887 54.5 17 61.887 33.5 71 33.5z"
            fill="url(#prefix__pattern0)"
            stroke={fillColor}
      />
          <path
            d="M125 65.5c9.113 0 16.5-7.387 16.5-16.5s-7.387-16.5-16.5-16.5-16.5 7.387-16.5 16.5 7.387 16.5 16.5 16.5z"
            fill="url(#prefix__pattern1)"
            stroke={fillColor}
      />
          <path
            d="M125 109.5c9.113 0 16.5-7.387 16.5-16.5s-7.387-16.5-16.5-16.5-16.5 7.387-16.5 16.5 7.387 16.5 16.5 16.5z"
            fill="url(#prefix__pattern2)"
            stroke={fillColor}
      />
          <path
            d="M71 141.5c9.113 0 16.5-7.387 16.5-16.5s-7.387-16.5-16.5-16.5-16.5 7.387-16.5 16.5 7.387 16.5 16.5 16.5z"
            fill="url(#prefix__pattern3)"
            stroke={fillColor}
      />
          <path
            d="M17 109.5c9.113 0 16.5-7.387 16.5-16.5S26.113 76.5 17 76.5.5 83.887.5 93s7.387 16.5 16.5 16.5z"
            fill="url(#prefix__pattern4)"
            stroke={fillColor}
      />
          <path
            d="M17 65.5c9.113 0 16.5-7.387 16.5-16.5S26.113 32.5 17 32.5.5 39.887.5 49 7.887 65.5 17 65.5z"
            fill="url(#prefix__pattern5)"
            stroke={fillColor}
      />
          <defs>
            <pattern
              id="prefix__pattern0"
              patternContentUnits="objectBoundingBox"
              width={1}
              height={1}
        >
              <use xlinkHref="#prefix__image0" transform="scale(.00195)" />
            </pattern>
            <pattern
              id="prefix__pattern1"
              patternContentUnits="objectBoundingBox"
              width={1}
              height={1}
        >
              <use xlinkHref="#prefix__image1" transform="scale(.00098)" />
            </pattern>
            <pattern
              id="prefix__pattern2"
              patternContentUnits="objectBoundingBox"
              width={1}
              height={1}
        >
              <use xlinkHref="#prefix__image2" transform="scale(.00098)" />
            </pattern>
            <pattern
              id="prefix__pattern3"
              patternContentUnits="objectBoundingBox"
              width={1}
              height={1}
        >
              <use xlinkHref="#prefix__image3" transform="scale(.00195)" />
            </pattern>
            <pattern
              id="prefix__pattern4"
              patternContentUnits="objectBoundingBox"
              width={1}
              height={1}
        >
              <use xlinkHref="#prefix__image4" transform="scale(.00098)" />
            </pattern>
            <pattern
              id="prefix__pattern5"
              patternContentUnits="objectBoundingBox"
              width={1}
              height={1}
        >
              <use
                xlinkHref="#prefix__image5"
                transform="matrix(.00581 0 0 .00581 0 -.244)"
          />
            </pattern>
            <image id="prefix__image0" width={512} height={512} href={members && members.length ? (members[0].profile_pic ? members[0].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="prefix__image1" width={1024} height={1024} href={members && members.length ? (members[1].profile_pic ? members[1].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="prefix__image2" width={1024} height={1024} href={members && members.length ? (members[2].profile_pic ? members[2].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="prefix__image3" width={512} height={512} href={members && members.length ? (members[3].profile_pic ? members[3].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="prefix__image4" width={1024} height={1024} href={members && members.length ? (members[4].profile_pic ? members[4].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="prefix__image5" width={172} height={256} href={members && members.length ? (members[5].profile_pic ? members[5].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
          </defs>
        </React.Fragment>
      );
    case members && members.length === 7:
      return (
        <React.Fragment>
          <path
            d="M71 33.5c9.113 0 16.5-7.387 16.5-16.5S80.113.5 71 .5 54.5 7.887 54.5 17 61.887 33.5 71 33.5z"
            fill="url(#prefix__pattern0)"
            stroke={fillColor}
      />
          <path
            d="M125 65.5c9.113 0 16.5-7.387 16.5-16.5s-7.387-16.5-16.5-16.5-16.5 7.387-16.5 16.5 7.387 16.5 16.5 16.5z"
            fill="url(#prefix__pattern1)"
            stroke={fillColor}
      />
          <path
            d="M125 109.5c9.113 0 16.5-7.387 16.5-16.5s-7.387-16.5-16.5-16.5-16.5 7.387-16.5 16.5 7.387 16.5 16.5 16.5z"
            fill="url(#prefix__pattern2)"
            stroke={fillColor}
      />
          <path
            d="M91 141.5c9.113 0 16.5-7.387 16.5-16.5s-7.387-16.5-16.5-16.5-16.5 7.387-16.5 16.5 7.387 16.5 16.5 16.5z"
            fill="url(#prefix__pattern3)"
            stroke={fillColor}
      />
          <path
            d="M51 141.5c9.113 0 16.5-7.387 16.5-16.5s-7.387-16.5-16.5-16.5-16.5 7.387-16.5 16.5 7.387 16.5 16.5 16.5z"
            fill="url(#prefix__pattern4)"
            stroke={fillColor}
      />
          <path
            d="M17 109.5c9.113 0 16.5-7.387 16.5-16.5S26.113 76.5 17 76.5.5 83.887.5 93s7.387 16.5 16.5 16.5z"
            fill="url(#prefix__pattern5)"
            stroke={fillColor}
      />
          <path
            d="M17 65.5c9.113 0 16.5-7.387 16.5-16.5S26.113 32.5 17 32.5.5 39.887.5 49 7.887 65.5 17 65.5z"
            fill="url(#prefix__pattern6)"
            stroke={fillColor}
      />
          <defs>
            <pattern
              id="prefix__pattern0"
              patternContentUnits="objectBoundingBox"
              width={1}
              height={1}
        >
              <use xlinkHref="#prefix__image0" transform="scale(.00195)" />
            </pattern>
            <pattern
              id="prefix__pattern1"
              patternContentUnits="objectBoundingBox"
              width={1}
              height={1}
        >
              <use xlinkHref="#prefix__image1" transform="scale(.00098)" />
            </pattern>
            <pattern
              id="prefix__pattern2"
              patternContentUnits="objectBoundingBox"
              width={1}
              height={1}
        >
              <use xlinkHref="#prefix__image2" transform="scale(.00195)" />
            </pattern>
            <pattern
              id="prefix__pattern3"
              patternContentUnits="objectBoundingBox"
              width={1}
              height={1}
        >
              <use xlinkHref="#prefix__image3" transform="scale(.00098)" />
            </pattern>
            <pattern
              id="prefix__pattern4"
              patternContentUnits="objectBoundingBox"
              width={1}
              height={1}
        >
              <use xlinkHref="#prefix__image4" transform="scale(.00098)" />
            </pattern>
            <pattern
              id="prefix__pattern5"
              patternContentUnits="objectBoundingBox"
              width={1}
              height={1}
        >
              <use
                xlinkHref="#prefix__image5"
                transform="matrix(.005 0 0 .005 0 -.125)"
          />
            </pattern>
            <pattern
              id="prefix__pattern6"
              patternContentUnits="objectBoundingBox"
              width={1}
              height={1}
        >
              <use
                xlinkHref="#prefix__image6"
                transform="matrix(.00581 0 0 .00581 0 -.244)"
          />
            </pattern>
            <image id="prefix__image0" width={512} height={512} href={members && members.length ? (members[1].profile_pic ? members[1].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="prefix__image1" width={1024} height={1024} href={members && members.length ? (members[0].profile_pic ? members[0].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="prefix__image2" width={512} height={512} href={members && members.length ? (members[2].profile_pic ? members[2].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="prefix__image3" width={1024} height={1024} href={members && members.length ? (members[3].profile_pic ? members[3].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="prefix__image4" width={1024} height={1024} href={members && members.length ? (members[4].profile_pic ? members[4].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="prefix__image5" width={200} height={250} href={members && members.length ? (members[5].profile_pic ? members[5].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="prefix__image6" width={172} height={256} href={members && members.length ? (members[6].profile_pic ? members[6].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
          </defs>
        </React.Fragment>
      );
    case members && members.length === 8:
      return (
        <React.Fragment>
          <path
            d="M51 33.5C60.1127 33.5 67.5 26.1127 67.5 17C67.5 7.8873 60.1127 0.5 51 0.5C41.8873 0.5 34.5 7.8873 34.5 17C34.5 26.1127 41.8873 33.5 51 33.5Z"
            fill="url(#pattern0)"
            stroke={fillColor}
          />
          <path
            d="M91 33.5C100.113 33.5 107.5 26.1127 107.5 17C107.5 7.8873 100.113 0.5 91 0.5C81.8873 0.5 74.5 7.8873 74.5 17C74.5 26.1127 81.8873 33.5 91 33.5Z"
            fill="url(#pattern1)"
            stroke={fillColor}
          />
          <path
            d="M125 65.5C134.113 65.5 141.5 58.1127 141.5 49C141.5 39.8873 134.113 32.5 125 32.5C115.887 32.5 108.5 39.8873 108.5 49C108.5 58.1127 115.887 65.5 125 65.5Z"
            fill="url(#pattern2)"
            stroke={fillColor}
          />
          <path
            d="M125 109.5C134.113 109.5 141.5 102.113 141.5 93C141.5 83.8873 134.113 76.5 125 76.5C115.887 76.5 108.5 83.8873 108.5 93C108.5 102.113 115.887 109.5 125 109.5Z"
            fill="url(#pattern3)"
            stroke={fillColor}
          />
          <path
            d="M91 141.5C100.113 141.5 107.5 134.113 107.5 125C107.5 115.887 100.113 108.5 91 108.5C81.8873 108.5 74.5 115.887 74.5 125C74.5 134.113 81.8873 141.5 91 141.5Z"
            fill="url(#pattern4)"
            stroke={fillColor}
          />
          <path
            d="M51 141.5C60.1127 141.5 67.5 134.113 67.5 125C67.5 115.887 60.1127 108.5 51 108.5C41.8873 108.5 34.5 115.887 34.5 125C34.5 134.113 41.8873 141.5 51 141.5Z"
            fill="url(#pattern5)"
            stroke={fillColor}
          />
          <path
            d="M17 109.5C26.1127 109.5 33.5 102.113 33.5 93C33.5 83.8873 26.1127 76.5 17 76.5C7.8873 76.5 0.5 83.8873 0.5 93C0.5 102.113 7.8873 109.5 17 109.5Z"
            fill="url(#pattern6)"
            stroke={fillColor}
          />
          <path
            d="M17 65.5C26.1127 65.5 33.5 58.1127 33.5 49C33.5 39.8873 26.1127 32.5 17 32.5C7.8873 32.5 0.5 39.8873 0.5 49C0.5 58.1127 7.8873 65.5 17 65.5Z"
            fill="url(#pattern7)"
            stroke={fillColor}
          />
          <defs>
            <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
              <use xlinkHref="#image0" transform="scale(0.00195312)" />
            </pattern>
            <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
              <use xlinkHref="#image1" transform="scale(0.000976562)" />
            </pattern>
            <pattern id="pattern2" patternContentUnits="objectBoundingBox" width="1" height="1">
              <use xlinkHref="#image2" transform="scale(0.000976562)" />
            </pattern>
            <pattern id="pattern3" patternContentUnits="objectBoundingBox" width="1" height="1">
              <use xlinkHref="#image3" transform="scale(0.00195312)" />
            </pattern>
            <pattern id="pattern4" patternContentUnits="objectBoundingBox" width="1" height="1">
              <use xlinkHref="#image4" transform="scale(0.000976562)" />
            </pattern>
            <pattern id="pattern5" patternContentUnits="objectBoundingBox" width="1" height="1">
              <use xlinkHref="#image5" transform="scale(0.000976562)" />
            </pattern>
            <pattern id="pattern6" patternContentUnits="objectBoundingBox" width="1" height="1">
              <use xlinkHref="#image6" transform="translate(0 -0.125) scale(0.005)" />
            </pattern>
            <pattern id="pattern7" patternContentUnits="objectBoundingBox" width="1" height="1">
              <use xlinkHref="#image7" transform="translate(0 -0.244186) scale(0.00581395)" />
            </pattern>
            <image id="image0" width="512" height="512" href={members && members.length ? (members[0].profile_pic ? members[0].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="image1" width="1024" height="1024" href={members && members.length ? (members[1].profile_pic ? members[1].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="image2" width="1024" height="1024" href={members && members.length ? (members[2].profile_pic ? members[2].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="image3" width="512" height="512" href={members && members.length ? (members[3].profile_pic ? members[3].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="image4" width="1024" height="1024" href={members && members.length ? (members[4].profile_pic ? members[4].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="image5" width="1024" height="1024" href={members && members.length ? (members[5].profile_pic ? members[5].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="image6" width="200" height="250" href={members && members.length ? (members[6].profile_pic ? members[6].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
            <image id="image7" width="172" height="256" href={members && members.length ? (members[7].profile_pic ? members[7].profile_pic : '/assets/images/profileFilled.png') : '/assets/images/profileFilled.png'} />
          </defs>

        </React.Fragment>
      );
    default:
      return <></>;
  }
}

export default BreakoutTeam;

import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { PlaybackAudioContext } from './context';

import { PLAYBACK_UPDATE_STATE, UPDATE_PLAYER_DATA } from '../../redux/constants';

// declare const window: any;

export type IPlayerProps = {
  intervals?: Array<any>,
  updateMainDuration?: Boolean
}

function PlaybackAudioProvider(prop: any) {
  const { children, playerRef } = prop;
  const dispatch = useDispatch();

  // State
  const [audioCheckpoints, setAudioCheckpoints] = useState({});

  // Redux state
  const { editedPlaybackDetails, playerData = {} } = useSelector((state) => state.toJS().playback);

  // Logic
  const initialisePlayer = async (editedPlaybackDetails) => {
    // Check player state and seek to last seek position received from playback details data
    if (!editedPlaybackDetails?.data?.[0] || playerData.state !== 'ready') return;
    const { last_seek, start_time } = editedPlaybackDetails?.data?.[0];
    const convertedSeekPosition = Math.round((last_seek - start_time) / 1000);

    await playerRef.current.seekTo(convertedSeekPosition);
    dispatch({ type: PLAYBACK_UPDATE_STATE, payload: 'paused' });
  };

  const handlePlayerView = (payload: IPlayerProps) => {
    const { intervals, updateMainDuration } = payload;
    if (isEmpty(intervals)) setAudioCheckpoints({});
    else {
      setAudioCheckpoints(intervals);
      const slideLength = intervals.reduce((totalLength, { start_time, end_time }) => totalLength + ((end_time - start_time) / 1000), 0);
      const data: { trimmedDuration: Number, duration?: Number } = {
        trimmedDuration: slideLength,
      };
      if (updateMainDuration) {
        const totalSlideDuration = (intervals[intervals.length - 1].end_time - intervals[0].start_time) / 1000;
        data.duration = totalSlideDuration;
      }
      dispatch({ type: UPDATE_PLAYER_DATA, payload: data });
    }
  };

  // Effects
  useEffect(() => {
    if (!!editedPlaybackDetails?.data?.[0] && playerData.state === 'ready') {
      initialisePlayer(editedPlaybackDetails);
    }
  }, [editedPlaybackDetails, playerData]);

  return (
    <PlaybackAudioContext.Provider value={{ audioCheckpoints, handlePlayerView }}>
      {children}
    </PlaybackAudioContext.Provider>
  );
}

export default PlaybackAudioProvider;

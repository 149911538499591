import React from 'react';
import { Row, Column, Button } from '@noon/atom';
import { IconNoon } from '@noon/quark';
import { translationText } from '../../../helpers';

export default function FooterSection(prop) {
  const { selectedCountry, noonText, changeLocale, history } = prop;
  const isRTL = document.body.dir === 'rtl';
  return (
    <div className="section-outer-container footer-outer">
      <Row className="section landing-footer">
        <Row className="landing-footer__social">
          <ul className="social-icons">
            <li>
              <a className="btn-inst" href="//instagram.com/noonEdu" target="_blank">
                <i className="fa fa-instagram" />
              </a>
            </li>
            <li>
              <a className="btn-fb" href="//fb.me/Noon.Academy.Egypt" target="_blank">
                <i className="fa fa-facebook" />
              </a>
            </li>
            <li>
              <a className="btn-tw" href="//twitter.com/noonEdu" target="_blank">
                <i className="fa fa-twitter" />
              </a>
            </li>
            <li>
              <a className="btn-tw" href="//www.linkedin.com/company/noonacademy/" target="_blank">
                <i className="fa fa-linkedin" />
              </a>
            </li>
          </ul>
          <Row className="logo-and-toggle" align="center">
            <IconNoon width="60px" height="60px" />
            {selectedCountry && selectedCountry.allowed_locales.length > 1 && (
              <div className="language-toggle">
                <span onClick={changeLocale} className={!isRTL ? 'active' : ''}>En</span>
                <span onClick={changeLocale} className={isRTL ? 'active' : ''}>Ar</span>
              </div>
            )}
          </Row>
          <Button onClick={() => history.push(`/${localStorage.updatedLocale}/login`)} className="noon-btn" size="lg" type="primary">Sign Up For Free</Button>
        </Row>
        <Row className="landing-footer__links">
          {selectedCountry && (
            <Column className="grade">
              <h5>
                Grades for
                {selectedCountry.name}
              </h5>
              <ul>
                {selectedCountry && selectedCountry.allowed_grades && selectedCountry.allowed_grades.map((grade) => (
                  <li key={grade.grade} className="grade-item">{translationText(noonText, `grade.${grade.grade}`)}</li>
                ))}
              </ul>
            </Column>
          )}
          <Column className="links">
            <h5>Important links</h5>
            <ul>
              <li><a href="/career">Jobs </a></li>
              <li><a href="/tos">Terms of service </a></li>
              <li><a href="/privacy">Privacy policy </a></li>
            </ul>
          </Column>
        </Row>
      </Row>
    </div>
  );
}

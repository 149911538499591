import { put, takeEvery, all } from 'redux-saga/effects';
import delay from '@redux-saga/delay-p';
import { ADD_TOAST, REMOVE_TOAST, UPDATE_TOAST } from '../constants';

function* addToast({ payload: data }) {
  const { time, id } = data;
  if (time) {
    yield delay(time);
    yield put({ type: REMOVE_TOAST, payload: id });
  }
}

export function* toastSaga() {
  yield all([
    takeEvery(ADD_TOAST, addToast),
    takeEvery(UPDATE_TOAST, addToast),
  ]);
}

import React, { useState, useRef, useEffect } from 'react';

import { IconCanvasDiscard, IconEdit, IconFlag, IconLoaderDotted, IconEditO } from '@noon/quark';
import { Select, Input, Button, Column, Row, NoonDate, Checkbox } from '@noon/atom';
import { DateTime, Info } from 'luxon';
import Pikaday from 'pikaday';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { addToast, TOAST_TYPE } from '../Toast';
import { translationText } from '../../helpers';
import DatePicker from '../DatePicker';
import {
  checkIsMobile,
  prepareDateForPosting,
  returnDuplicatePropsForMixpanelAndKafka,
} from '../../helpers/prepareAssignment';
import {
  CLEAR_HOMEWORK_SPARTA_DETAILS,
  CLEAR_QUESTIONS,
  CLEAR_TOPIC_QUESTIONS,
  CLEAR_UPDATE_HOMEWORK,
  GET_HOMEWORK_DETAILS_SPARTA,
  SAVE_HOMEWORK_QUESTIONS_SPARTA,
  UPDATE_HOMEWORK_SPARTA,
} from '../../redux/constants';
import { getWeekdays, getWeekdaysShort } from '../../helpers/date';
import Mixpanel from '../Mixpanel';
import { COLORS } from '../../constants';

const PublishModal = (props) => {
  const { setPublishModalShown, isPublishModalShown, noonText, hw_id } = props;
  const isRtl = document.body.dir === 'rtl';

  const [isAutoPublishTimeEmpty, setIsAutoPublishTimeEmpty] = useState(true);
  const [status, setStatus] = useState('');
  const [isDateEditing, setIsDateEditing] = useState(false);
  const [isDueDatePassed, setIsDueDatePassed] = useState(false);
  const [time, setTime] = useState();
  const [date, setDate] = useState('');
  const dateRef = useRef();
  const [dueDateTime, setDueDateTime] = useState();
  const tagOptions = [
    { id: 1, name: 'Free', title: translationText(noonText, 'groups.free') },
    { id: 2, name: 'Plus', title: translationText(noonText, 'groups.plus') },
  ];
  const [shouldRenderNewAutoPublish, setShouldRenderNewAutoPublish] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const dueDateRef = useRef();
  const logger = useRef(LoggingManager.mount({ moduleName: 'homework' })).current;
  const dispatch = useDispatch();
  const history = useHistory();

  const { hw_details, updateHomework, is_questions_saved, currentQuestions, loading_details } = useSelector(
    (state) => state.toJS().homeworkCreation,
  );
  const { loggedUser: user } = useSelector((state) => state.toJS().user);
  const { selectedGroup } = useSelector((state) => state.toJS().groupsV2);
  const { is_unified_group_teacher } = useSelector((state) => state.toJS().experiment.featureFlags);
  const [selectedStatus, setSelectedStatus] = useState(selectedGroup && selectedGroup.group_info && selectedGroup.group_info.is_premium && selectedGroup.group_info.free_percent !== 100 ? tagOptions[1] : tagOptions[0]);

  const ListComponent = (prop) => (
    <Row nowrap>
      <Checkbox checked={prop.id === selectedStatus.id} />
      <span className={classNames('status-title', { 'status-active': prop.id === selectedStatus.id })}>
        {prop.title}
      </span>
    </Row>
  );

  const TitleComponent = (prop) => (
    <Row nowrap align="start">
      <Row className="mr-1">
        {prop.id === 1 ? <img src="/assets/images/freecircle.svg" /> : <img src="/assets/images/pluscircle.svg" />}
      </Row>
      <span className="status-title">{prop.title}</span>
    </Row>
  );

  const handleTopicStatusChange = (value) => {
    setSelectedStatus(value);
  };

  useEffect(() => {
    if (updateHomework.success) {
      addToast(translationText(noonText, 'success.userUpdated'), TOAST_TYPE.SUCCESS);
      dispatch({
        type: CLEAR_UPDATE_HOMEWORK,
      });
      dispatch({
        type: CLEAR_TOPIC_QUESTIONS,
      });
      dispatch({
        type: CLEAR_HOMEWORK_SPARTA_DETAILS,
      });
      dispatch({
        type: CLEAR_QUESTIONS,
      });
      setIsPublished(true);
      history.push(`/${localStorage.updatedLocale}/groups/${hw_details.group_id}/content`);
    }
  }, [updateHomework]);

  useEffect(() => {
    if (isDueDatePassed || isDateEditing) {
      const locale = { locale: localStorage.language.split('_')[0] || 'en' };
      const dueDatePicker = new Pikaday({
        field: dueDateRef.current,
        format: 'YYYY-MM-DD',
        i18n: {
          months: Info.months('long', locale),
          weekdays: getWeekdays(),
          weekdaysShort: getWeekdaysShort(),
        },
        onSelect: (event) => {
          if (prepareDateForPosting(dueDateTime, dueDateRef.current.value) > new Date().getTime() + 1000 * 3600) {
            setShouldRenderNewAutoPublish(true);
          }
        },
      });
      dueDatePicker.setMinDate(new Date());
      const date = new Date(hw_details.due_date);
      dueDatePicker.setDate(new Date(date));
      setDueDateTime(hw_details.due_date);
      return () => dueDatePicker.destroy();
    }
  }, [isDueDatePassed, isDateEditing === true]);

  useEffect(() => {
    const locale = { locale: localStorage.language ? localStorage.language.split('_')[0] : 'en' };
    const picker = new Pikaday({
      field: dateRef.current,
      format: 'YYYY-MM-DD',
      i18n: {
        months: Info.months('long', locale),
        weekdays: getWeekdays(),
        weekdaysShort: getWeekdaysShort(),
      },
      onSelect: (event) => setDate(event),
    });
    picker.setMinDate(new Date());
    return () => picker.destroy();
  }, [shouldRenderNewAutoPublish]);

  useEffect(() => {
    if (hw_details.due_date !== null) {
      if (hw_details.due_date < new Date().getTime() + 1000 * 3600) {
        setIsDueDatePassed(true);
      }
    }
  }, [hw_details]);

  useEffect(() => {
    if (time) {
      setIsAutoPublishTimeEmpty(false);
    }
  }, [time]);

  const saveHomework = (status) => {
    setStatus(status);
    dispatch({
      type: SAVE_HOMEWORK_QUESTIONS_SPARTA.REQUEST,
      hw_id: hw_details.id,
      data: {
        question_ids: currentQuestions.data.map((question) => ({
          question_id: question.id,
          chapter_id: question.chapter_info.id,
          topic_id: question.topic_info.id,
        })),
      },
      justSaved: false,
    });
  };

  const mixpanelKafkaEvents = () => {
    const props = returnDuplicatePropsForMixpanelAndKafka(selectedGroup, user, hw_details);
    const properties = {
      publish_method: status === 'publish_now' ? 'publish_now' : 'scheduled_later',
      publish_time: status === 'publish_now' ? Date.now() : prepareDateForPosting(time, dateRef.current.value),
      no_of_questions: hw_details.question_ids ? hw_details.question_ids.length : 0,
      ...props,
    };
    Mixpanel.track('homework_published', properties);
    logger.track('published', { homework: properties });
  };

  const publishHomework = () => {
    if (hw_details.due_date == null) {
      return addToast(translationText(noonText, 'createHomework.publishDueDateError'), TOAST_TYPE.ERROR);
    }
    const is_free = selectedStatus.id === 1;
    if (status === 'publish_now') {
      dispatch({
        type: UPDATE_HOMEWORK_SPARTA.REQUEST,
        hw_id,
        hw_details:
          isDueDatePassed || dueDateRef.current
            ? {
                publish_now: true,
                due_date: prepareDateForPosting(dueDateTime, dueDateRef.current.value),
                is_free,
              }
            : {
                publish_now: true,
                is_free,
              },
      });
    }
    if (status === 'auto_publish') {
      // if (prepareDateForPosting(time, dateRef.current.value) < (new Date().getTime() + 1000 * 3600)) {
      //   addToast(translationText(noonText, 'createHomework.publishTimeError'), TOAST_TYPE.WARNING);
      //   setStatus('');
      //   return false;
      // }
      if (
        prepareDateForPosting(time, dateRef.current.value) >
        (dueDateRef.current ? prepareDateForPosting(dueDateTime, dueDateRef.current.value) : hw_details.due_date)
      ) {
        addToast(translationText(noonText, 'createHomework.autoPublishDateError'), TOAST_TYPE.WARNING);
        return false;
      }
      let timeToSend = time;
      if (!time) timeToSend = DateTime.local().set({ hour: 12, minute: 0 });
      const details = dueDateRef.current
        ? {
            publish_now: false,
            start_date: prepareDateForPosting(timeToSend, dateRef.current.value),
            due_date: prepareDateForPosting(dueDateTime, dueDateRef.current.value),
            is_free,
          }
        : {
            publish_now: false,
            start_date: prepareDateForPosting(timeToSend, dateRef.current.value),
            is_free,
          };
      dispatch({
        type: UPDATE_HOMEWORK_SPARTA.REQUEST,
        hw_id,
        hw_details: details,
      });
    }
    mixpanelKafkaEvents();
  };

  useEffect(() => {
    if (
      is_questions_saved.success &&
      !is_questions_saved.justSaved &&
      status.length > 0 &&
      hw_details.question_ids !== undefined &&
      !isPublished
    ) {
      publishHomework();
    }
  }, [is_questions_saved, hw_details]);

  const returnPositionOfCrossButton = () =>
    isRtl ? { left: checkIsMobile() ? '28px' : '0' } : { right: checkIsMobile() ? '28px' : '0' };
  const showDueDatePassed =
    isDueDatePassed &&
    dueDateRef.current &&
    prepareDateForPosting(dueDateTime, dueDateRef.current.value) <= new Date().getTime() + 1000 * 3600;
  return (
    <React.Fragment>
      <div
        className="prepare-assignment--modalWrapper"
        style={{ display: isPublishModalShown ? 'inherit' : 'none' }}
        onClick={setPublishModalShown}
      />
      <Column
        className={`prepare-assignment--publish session-form ${
          isPublishModalShown ? ' modal-opened' : ' modal-closed'
        }`}
      >
        {checkIsMobile() && (
          <Row align="center" justify="center" className="mobile-title">
            <span>{translationText(noonText, 'button.publishAssignment')}</span>
          </Row>
        )}
        <div className="prepare-assignment--publish--dueDateAndInstructions">
          <Row align="center" className="prepare-assignment--publish--header">
            <span className="prepare-assignment--publish--header__title">{translationText(noonText, 'createHomework.publishBtn')}</span>

            <IconCanvasDiscard
              name="canvas-discard"
              height="16px"
              width="15px"
              fill="#333"
              stroke="#333"
              className="cross"
              onClick={setPublishModalShown}
              style={returnPositionOfCrossButton()}
            />
          </Row>
          {/* {showDueDatePassed && (
            <Column className="prepare-assignment--publish--dueDatePassed">
              <span>{translationText(noonText, 'createHomework.dueDatePassed')}</span>
            </Column>
          )} */}
        </div>
        <Column align="start" className="prepare-assignment--publish--details">
          <div className="prepare-assignment--publish--details__date mb-2 mt-1">
            <Row>
              <span className="prepare-assignment--publish--details__label">{translationText(noonText, 'createHomework.dueDateLabel')}</span>
            </Row>
            <Row className="prepare-assignment--publish--details__date-container">
              <NoonDate
                value={
                  dueDateRef.current
                    ? prepareDateForPosting(dueDateTime, dueDateRef.current.value)
                    : hw_details.due_date
                }
                format="LLLL dd hh:mm a"
                className="due-date"
              />
              <IconEditO
                height="14.55px"
                width="14.55px"
                stroke="#141414"
                className="edit"
                onClick={() => setIsDateEditing(!isDateEditing)}
              />
            </Row>
            {(isDueDatePassed || isDateEditing) && (
              <DatePicker
                onToggle={(toggle) => setDueDateTime(toggle)}
                dateRef={dueDateRef}
                onSelect={() => {}}
                placeholder={translationText(noonText, 'placeholder.selectDate')}
                timePlaceholder={translationText(noonText, 'placeholder.selectTime')}
                ms={dueDateTime}
              />
            )}
          </div>
          {is_unified_group_teacher && is_unified_group_teacher.isEnabled &&
            <Row className="prepare-assignment--publish--details__select">
              <Select
                label={translationText(noonText, 'createHomework.assignmentType')}
                selectedItem={selectedStatus}
                onSelect={handleTopicStatusChange}
                list={tagOptions}
                listItemComponent={ListComponent}
                titleComponent={TitleComponent}
                className="status-select"
                disabled={!(selectedGroup && selectedGroup.group_info && selectedGroup.group_info.is_premium && (typeof selectedGroup.group_info.free_percent !== 'undefined'))}
              />
            </Row>
          }
          <div className="divider-container">
            <div className="divider" />
          </div>
        </Column>
        <Column align="start" className="prepare-assignment--publish--detailsOfPublishing">
          <p className="prepare-assignment--publish--dateTitle">
            {`${translationText(noonText, 'createHomework.autoPublishDateTitle')} (${translationText(
              noonText,
              'createHomework.optional',
            ).toLowerCase()})`}
          </p>
          <DatePicker
            onToggle={(toggle) => setTime(toggle)}
            dateRef={dateRef}
            defaultValue={false}
            placeholder={translationText(noonText, 'placeholder.selectDate')}
            timePlaceholder={translationText(noonText, 'placeholder.selectTime')}
            ms={time}
          />
          {/* {date && (
            <React.Fragment>
              <Button
                disabled={!time || !date}
                type="secondary"
                onClick={() => saveHomework('auto_publish')}
                className="auto-publishs"
              >
                {translationText(noonText, 'createHomework.scheduleAutoPublish')}
                {updateHomework.loading && status === 'auto_publish' && (
                  <IconLoaderDotted
                    style={{ margin: '4px' }}
                    height="20px"
                    width="20px"
                    fill="#919a9e"
                    stroke="#919a9e"
                    rotate="20"
                  />
                )}
              </Button>
            </React.Fragment>
          )} */}
        </Column>
        <Column align="end" className="">
          <Button
            classNames="prepare-assignment--publish--button"
            onClick={() => {
              let publishState = 'publish_now';
              if (date) publishState = 'auto_publish';
              saveHomework(publishState);
            }}
            type="primary"
            size="lg"
          >
            {date
              ? translationText(noonText, 'createHomework.scheduleAutoPublish')
              : translationText(noonText, 'form.publishNow')}

            {updateHomework.loading && status === 'publish_now' && (
              <IconLoaderDotted
                style={{ margin: '4px' }}
                height="20px"
                width="20px"
                fill="#919a9e"
                stroke="#919a9e"
                rotate="20"
              />
            )}
          </Button>
        </Column>
      </Column>
    </React.Fragment>
  );
};

export default PublishModal;

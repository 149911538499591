import { addQuestionsToCurrentQuestions, changeOrderOfCurrentQuestions, checkIfHWsExist, checkIfQuestionExist, deleteQuestion } from '../../helpers/prepareAssignment';
import { CLEAR_HOMEWORK_SPARTA_DETAILS, CLEAR_QUESTIONS, CLEAR_UPDATE_HOMEWORK, CREATE_HOMEWORK_SPARTA, DELETE_HOMEWORK_SPARTA, EDIT_QUESTIONS_LIST, GET_HOMEWORK_DETAILS_SPARTA, GET_POST_PUBLISHED_HOMEWORKS, GET_PRE_PUBLISHED_HOMEWORKS, GET_QUESTIONS_BY_IDS_SPARTA, META_HOMEWORK_UPDATED, SAVE_HOMEWORK_QUESTIONS_SPARTA, SET_QUESTION_CHAPTER, SET_QUESTION_TOPIC, UPDATE_HOMEWORK_SPARTA } from '../constants';

const initialState = {
  currentQuestions: {
    data: [],
    loading: false,
  },
  hw_creation: {
    hw_id: undefined,
    loading: false,
  },
  loading_details: false,
  updateHomework: {
    loading: false,
    success: false,
    notUpdate: false,
  },
  updated: false,
  hw_details: {
    due_date: null,
    title: '',
    questions_ids: [],
  },
  is_questions_saved: {
    success: false,
    justSaved: false,
  },
  save_questions_loading: false,
  prePublishedHomeworks: {
    data: [],
    meta: [],
  },
  deleteHomework: {
    loading: false,
    success: false,
  },
  postPublishedHomeworks: {
    data: [],
    meta: [],
  },
  question_chapter: undefined,
  question_topic: undefined,
};

export default function homeworkCreation(state = initialState, action = {}) {
  switch (action.type) {
    case EDIT_QUESTIONS_LIST.ADD: {
      return {
        ...state,
        currentQuestions: {
          ...state.currentQuestions,
          data: checkIfQuestionExist(action.payload, state.currentQuestions.data),
        },
      };
    }

    case EDIT_QUESTIONS_LIST.DELETE: {
      return {
        ...state,
        currentQuestions: {
          ...state.currentQuestions,
          data: deleteQuestion(action.payload.index, state.currentQuestions.data),
        },
      };
    }

    case EDIT_QUESTIONS_LIST.CHANGE_ORDER: {
      return {
        ...state,
        currentQuestions: {
          ...state.currentQuestions,
          data: changeOrderOfCurrentQuestions(action.payload, state.currentQuestions.data),
        },
      };
    }

    case CREATE_HOMEWORK_SPARTA.LOADING: {
      return {
        ...state,
        hw_creation: {
          ...state.hw_creation,
          loading: true,
        },
      };
    }

    case CREATE_HOMEWORK_SPARTA.SUCCESS: {
      return {
        ...state,
        hw_creation: {
          hw_id: action.payload,
          loading: false,
        },
      };
    }

    case CREATE_HOMEWORK_SPARTA.FAILURE: {
      return {
        ...state,
        hw_creation: {
          ...state.hw_creation,
          loading: false,
        },
      };
    }

    case CLEAR_HOMEWORK_SPARTA_DETAILS: {
      return {
        ...state,
        hw_creation: {
          ...state.hw_creation,
          hw_id: undefined,
        },
        deleteHomework: {
          loading: false,
          success: false,
        },
      };
    }

    case GET_HOMEWORK_DETAILS_SPARTA.LOADING: {
      return {
        ...state,
        loading_details: true,
      };
    }

    case GET_HOMEWORK_DETAILS_SPARTA.SUCCESS: {
      return {
        ...state,
        loading_details: false,
        hw_details: action.payload || state.hw_details,
      };
    }

    case UPDATE_HOMEWORK_SPARTA.LOADING: {
      return {
        ...state,
        updateHomework: {
          ...state.updateHomework,
          loading: true,
        },
      };
    }

    case UPDATE_HOMEWORK_SPARTA.SUCCESS: {
      return {
        ...state,
        updateHomework: {
          loading: false,
          success: true,
          notUpdate: action.notUpdate,
        },
      };
    }

    case CLEAR_UPDATE_HOMEWORK: {
      return {
        ...state,
        updateHomework: {
          ...state.updateHomework,
          success: false,
        },
        currentQuestions: {
          data: [],
          loading: false,
        },
        question_chapter: undefined,
        question_topic: undefined,
        hw_details: {
          due_date: null,
          title: '',
        },
        is_questions_saved: false,
      };
    }

    case META_HOMEWORK_UPDATED: {
      return {
        ...state,
        updateHomework: {
          ...state.updateHomework,
          success: false,
          notUpdate: false,
        },
      };
    }

    case UPDATE_HOMEWORK_SPARTA.FAILURE: {
      return {
        ...state,
        updateHomework: {
          ...state.updateHomework,
          loading: false,
          success: false,
        },
        is_questions_saved: {
          ...state.is_questions_saved,
          success: false,
        },
      };
    }

    case SAVE_HOMEWORK_QUESTIONS_SPARTA.LOADING: {
      return {
        ...state,
        save_questions_loading: true,
      };
    }

    case SAVE_HOMEWORK_QUESTIONS_SPARTA.SUCCESS: {
      return {
        ...state,
        save_questions_loading: false,
        is_questions_saved: {
          success: true,
          justSaved: action.justSaved,
        },
      };
    }

    case GET_QUESTIONS_BY_IDS_SPARTA.LOADING: {
      return {
        ...state,
        currentQuestions: {
          ...state.currentQuestions,
          loading: true,
        },
      };
    }

    case GET_QUESTIONS_BY_IDS_SPARTA.SUCCESS: {
      return {
        ...state,
        currentQuestions: {
          loading: false,
          data: action.payload.data,
        },
      };
    }

    case GET_QUESTIONS_BY_IDS_SPARTA.FAILURE: {
      return {
        ...state,
        currentQuestions: {
          loading: false,
          data: [],
        },
      };
    }

    case GET_PRE_PUBLISHED_HOMEWORKS.LOADING: {
      return {
        ...state,
        prePublishedHomeworks: {
          ...state.prePublishedHomeworks,
          isLoading: true,
        },
      };
    }

    case GET_PRE_PUBLISHED_HOMEWORKS.SUCCESS: {
      return {
        ...state,
        prePublishedHomeworks: {
          data: action.payload.saveImmediately ? action.payload.data : checkIfHWsExist(state.prePublishedHomeworks.data, action.payload.data),
          meta: action.payload.meta,
          isLoading: false,
        },
      };
    }

    case GET_POST_PUBLISHED_HOMEWORKS.LOADING: {
      return {
        ...state,
        postPublishedHomeworks: {
          ...state.postPublishedHomeworks,
          isLoading: true,
        },
      };
    }

    case GET_POST_PUBLISHED_HOMEWORKS.SUCCESS: {
      return {
        ...state,
        postPublishedHomeworks: {
          data: action.payload.saveImmediately ? action.payload.data : checkIfHWsExist(state.postPublishedHomeworks.data, action.payload.data),
          meta: action.payload.meta,
          isLoading: false,
        },
      };
    }

    case DELETE_HOMEWORK_SPARTA.LOADING: {
      return {
        ...state,
        deleteHomework: {
          ...state.deleteHomework,
          loading: true,
        },
      };
    }

    case DELETE_HOMEWORK_SPARTA.SUCCESS: {
      return {
        ...state,
        deleteHomework: {
          loading: false,
          success: true,
        },
      };
    }

    case DELETE_HOMEWORK_SPARTA.FAILURE: {
      return {
        ...state,
        deleteHomework: {
          loading: false,
          success: false,
        },
      };
    }

    case CLEAR_QUESTIONS: {
      return {
        ...state,
        hw_details: {
          ...state.hw_details,
          questions_ids: null,
        },
        currentQuestions: {
          loading: false,
          data: [],
        },
      };
    }

    case SET_QUESTION_CHAPTER: {
      return {
        ...state,
        question_chapter: action.chapter,
      };
    }

    case SET_QUESTION_TOPIC: {
      return {
        ...state,
        question_topic: action.topic,
      };
    }

    default: {
      return state;
    }
  }
}

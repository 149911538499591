const Meta = {
  '/sa-ar': {
    title: 'منصة نون أكاديمي التعليمية | صفحة المعلم ',
    description: 'من خلال المنصة يمكن للمعلم إنشاء فصول تفاعلية وتقديم الحصص المباشرة وكذلك إعطاء الواجبات.',
  },
  '/sa-ar/login': {
    title: 'تسجيل دخول - نون أكاديمي | صفحة المعلم',
    description: 'موقع التدريس الخصوصي والجماعي الرائد بالوطن العربي، سجل الان وابدا حصة مع اصدقائك وزملائك',
  },
  // egypt
  '/eg-ar': {
    title: 'منصة نون أكاديمي التعليمية | صفحة المعلم ',
    description:
      'يمكنك الان حجز درس خصوصي رياضيات وأنجليزي وفيزياء وكيمياء وايضا القيام بأختبار قدرات وتحصيلي .. أحجز الأن حصة جماعي وتدرب مع زملائك واصدقائك!',
  },
  '/eg-ar/login': {
    title: 'تسجيل دخول - نون أكاديمي | صفحة المعلم',
    description: 'موقع التدريس الخصوصي والجماعي الرائد بالوطن العربي، سجل الان وابدا حصة مع اصدقائك وزملائك',
  },
  // english sa
  '/sa-en': {
    title: 'Teachers Page | Apply for Online Tutoring and Join the Best Teachers',
    description:
      "If you're a qualified English tutor, Math tutor or have experience with the K12 curriculum, join Noon Academy TODAY and INCREASE your current income!",
  },
  '/sa-en/login': {
    title: 'Teacher Login | Teach Homeschool Curriculums and Earn Extra Income',
    description:
      'Login and start your career as a private tutor. Be part of a distinguished group of the best online math tutors and online english tutors.',
  },
  // english eg
  '/eg-en': {
    title: 'Teachers | Apply for Online Tutoring and Join the Best Teachers',
    description:
      "If you're a qualified English tutor, Math tutor or have experience with the K12 curriculum, join Noon Academy TODAY and INCREASE your current income!",
  },
  '/eg-en/login': {
    title: 'Teacher Login Page | Teach Homeschool Curriculums and Earn Extra Income',
    description:
      'Login and start your career as a private tutor. Be part of a distinguished group of the best online math tutors and online english tutors.',
  },
  '/in-en': {
    title: 'Online Tutoring & Teaching Jobs In India | Noon Academy',
    description:
      'Sign up and become a private online tutor in India for Maths, Science, Physics, Chemistry, Biology, English, Social Science and more for grades 5 to 12',
  },
  '/in-en/login': {
    title: 'Teacher Login Page | Teach Homeschool Curriculums and Earn Extra Income | India',
    description:
      'Login and start your career as a private tutor. Be part of a distinguished group of the best online math tutors and online english tutors.',
  },
  '/pk-en': {
    title: 'Online Tutoring & Teaching Jobs in Pakistan | Noon Academy',
    description:
      'Signup to teach Matric & Inter and Cambridge O & A levels for sciences, commerce, and arts subjects including math, physics, biology, chemistry subjects for grade 9 to grade 12',
  },
  '/pk-en/login': {
    title: 'Teacher Login Page | Teach Homeschool Curriculums and Earn Extra Income | Pakistan',
    description:
      'Login and start your career as a private tutor. Be part of a distinguished group of the best online math tutors and online english tutors.',
  },
};

export default Meta;

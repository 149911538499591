import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Checkbox, Column } from '@noon/atom';
import classNames from 'classnames';
import { IconBack, IconLoader } from '@noon/quark';
import { translationText } from '../../helpers';

const Accordian = (prop) => {
  const {
    id,
    hideExpandButton,
    alwaysExpanded,
    checked,
    type,
    selectCheckBox,
    data,
    heading,
    sideheading,
    initialState = true,
    togglingAccordian,
    loadingAccordian = false,
  } = prop;
  const [showAccordian, toggleAccordian] = useState(alwaysExpanded ? true : initialState);
  const { noonText } = useSelector((state) => state.toJS().translation);

  const setAccrordianState = () => {
    if (togglingAccordian) {
      togglingAccordian(!showAccordian);
    }
    toggleAccordian(!showAccordian);
  };

  useEffect(() => {
    toggleAccordian(initialState);
  }, [initialState]);

  return (
    <div id={id} className={classNames('accordian', { expanded: showAccordian })}>
      <Row nowrap className="accordian--top" onClick={() => setAccrordianState()}>
        {type === 'checkbox' ? (
          <Column
            nowrap
            align="start"
            justify="start"
            className={classNames('has-checkbox accordian--top--header mlr-05', { 'has-toggle': !alwaysExpanded })}
          >
            <Checkbox
              id={data.id}
              name="checkbox-1"
              type="checkbox"
              checked={checked}
              onChange={(a) => selectCheckBox(a)}
              label={data.title || data.name_header || data.name}
              className={classNames({ 'accordian--top--header__subtitle': !data.title && !data.name_header })}
            />
            {/* {data.name_header && <div className="accordian--top--header__title">{data.name_header}</div>} */}
            {data.name_header && data.name && <div className="accordian--top--header__subtitle">{data.name}</div>}
          </Column>
        ) : type === 'dropdown' ? (
          <Row
            nowrap
            align="center"
            justify="center"
            className={classNames('accordian--top--header mlr-05', { 'has-toggle': !alwaysExpanded })}
          >
            <Column>
              {data.name_header && <div className="accordian--top--header__title">{data.name_header}</div>}
              {data.name && <div className="accordian--top--header__subtitle">{data.name}</div>}
            </Column>
          </Row>
        ) : (
          <Row align="center" className="content-data" gap="sm">
            {heading.icon && <heading.icon height="20px" width="20px" className="child" />}
            {heading.label && (
              <div className="content-data--heading">{translationText(noonText, heading.label) || heading.label}</div>
            )}
            {sideheading && (
              <React.Fragment>
                <span className="child noon-dot" />
                <div className="content-data--activities">{sideheading}</div>
              </React.Fragment>
            )}
          </Row>
        )}
        {!alwaysExpanded && (
          <React.Fragment>
            {loadingAccordian ? (
              <IconLoader className="accordian--top__icon" height="20px" width="20px" />
            ) : (
              !hideExpandButton && (
                <IconBack
                  fill="#C7D0EC"
                  className="accordian--top__icon"
                  rotate={showAccordian ? 90 : -90}
                  height="20px"
                  width="20px"
                  onClick={() => setAccrordianState()}
                />
              )
            )}
          </React.Fragment>
        )}
      </Row>
      <div
        className={classNames('accordian--children', {
          expandAccordian: showAccordian,
          shrinkAccordian: !showAccordian,
        })}
      >
        {prop.children}
      </div>
    </div>
  );
};

export default Accordian;

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Row, Column, Button } from '@noon/atom';
import { IconEdit, IconReplayPlay } from '@noon/quark';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import { UPLOAD_VIDEO, UPDATE_GROUP_V2 } from 'redux/constants';
import { translationText } from 'helpers';
import { TOAST_TYPE, updateToast, addToast } from '../../../Toast';
import NotAllowedOverlay from '../../NotAllowedOverlay';

function GroupVideoWidget(prop) {
  const { group, isArchived } = prop;
  const dispatch = useDispatch();
  const [isUploadingVideo, setIsUploadingVideo] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [showUploadError, setShowUploadError] = useState(false);
  const noonText = useSelector((state) => state.toJS().translation.noonText);
  const videoUrl = get(group, ['group_info', 'preview_url_info', 'large']);

  const updateVideoDetails = (uploadVideoUrls) => {
    dispatch({
      type: UPDATE_GROUP_V2.REQUEST,
      payload: {
        groupId: group.group_info.id,
        data: {
          ...group.group_info,
          preview_url_info: {
            original: uploadVideoUrls.large_url,
            large: uploadVideoUrls.large_url,
            medium: uploadVideoUrls.medium_url,
            small: uploadVideoUrls.small_url,
            thumbnail: uploadVideoUrls.thumbnail_url,
          },
        },
      },
    });
    setIsUploadingVideo(false);
  };

  const handleVideoProgressEvent = (progressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    if (percentCompleted === 100) {
      updateToast(translationText(noonText, 'groups.videoSuccessfullyUploaded'), TOAST_TYPE.FROSTED, 3, 'top-center', {
        id: 'video-upload-progress',
        noCancelButton: true,
        icon: 'correct-circle',
        iconFill: '#1adc51',
        iconStroke: '#fff',
      });
    } else {
      updateToast(translationText(noonText, 'groups.videoBeingUploaded'), TOAST_TYPE.FROSTED, 86000, 'top-center', {
        id: 'video-upload-progress',
        noCancelButton: true,
        percentCompleted,
      });
    }
  };

  const uploadVideo = ({ fd, onComplete }) => {
    dispatch({ type: UPLOAD_VIDEO.REQUEST, payload: fd, progressEventHandler: handleVideoProgressEvent, onComplete });
  };

  const handleUploadVideo = (e) => {
    // User cancelled
    const groupId = get(group, 'group_info.id');
    const element = document.getElementById('uploadVideo');
    const file = element.files[0];
    const filesize = (file.size / 1024 / 1024).toFixed(4);

    if (!file || filesize > 100) {
      setShowUploadError(true);
      return;
    }
    const fd = new FormData();
    fd.append('destination', 'videos');
    const nameSplit = file.name.split('.');
    const ext = nameSplit[nameSplit.length - 1];
    const originalName = nameSplit[0];
    fd.append('file_name', `${originalName}_${groupId}.${ext}`);
    fd.append('fileUpl', file, file.name);
    uploadVideo({
      fd,
      onComplete: updateVideoDetails,
    });
    e.preventDefault();
    setIsUploadingVideo(true);
    addToast(translationText(noonText, 'groups.videoBeingUploaded'), TOAST_TYPE.FROSTED, 86000, 'top-center', {
      id: 'video-upload-progress',
      noCancelButton: true,
    });
  };

  const handlePlayVideo = () => {
    setPlayVideo(true);
    document.getElementById('group-info-video').play();
  };

  return (
    <Card className="video-widget">
      <Row align="center" nowrap className="card-header">
        <h4 className="card-header__title">{translationText(noonText, 'tab.video')}</h4>
        {!isEmpty(videoUrl) && (
          <React.Fragment>
            <input
              id="uploadVideo"
              type="file"
              className="upload-video"
              accept=".mp4, .mov, .mpeg-4"
              disabled={isUploadingVideo}
              onChange={handleUploadVideo}
                />
            <label htmlFor="uploadVideo">
              <IconEdit />
            </label>
          </React.Fragment>
        )}
      </Row>
      <Column className="card-body">
        {isArchived && <NotAllowedOverlay />}
        {!isEmpty(videoUrl) ? (
          <div className="video-wrapper no-margin no-padding">
            <video id="group-info-video" controls src={videoUrl}>
              <track kind="captions" />
              {translationText(noonText, 'error.unsupportedVideo')}
            </video>
            {!playVideo && (
              <Row align="center" justify="center" className="play-button">
                <IconReplayPlay onClick={handlePlayVideo} height="44px" width="44px" fill="#fff" />
              </Row>
            )}
          </div>
        ) : (
          <React.Fragment>
            <div className="empty-card">
              <div className="empty-card__title">{translationText(noonText, 'groups.noVideo')}</div>
              <div className="empty-card__description">{translationText(noonText, 'groups.whyVideo')}</div>
            </div>
            <Button type="secondary" className="mt-2">
              <input
                id="uploadVideo"
                type="file"
                className="upload-video"
                accept=".mp4, .mov, .mpeg-4"
                disabled={isUploadingVideo}
                onChange={handleUploadVideo}
              />
              <label htmlFor="uploadVideo">{translationText(noonText, 'button.uploadVideo')}</label>
            </Button>
            {showUploadError && (
              <span className="errorMessage child card-body__note">
                {translationText(noonText, 'groups.uploadVideoError')}
              </span>
            )}
          </React.Fragment>
        )}
      </Column>
    </Card>
  );
}

export default React.memo(GroupVideoWidget);

import React, { useState } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash-es/isEmpty';
import { Column, Button, Row, Modal } from '@noon/atom';
import { Checkbox } from '../Content';
import t from '../../helpers/translate';
import ErrorBoundry from '../ErrorBoundry';
import ChatList from './chatList';
import { SessionGenericModal } from '../Modals';
import ChatFooter from './chatFooter';

const Chat = (prop) => {

  const { chats, user, disableChat, onSendMessage, onToggleStatus, onBlockUser, onSelectImage } = prop;

  const [blockChatModal, setBlockChatModal] = useState({});
  const [userUploadedImageConfirmation, setUserUploadedImageConfirmation] = useState({});

  const sendMessage = (msg, image, imageType) => {
    onSendMessage(msg, image, imageType);
  };

  const addStudentImageToCanvas = (imageObj) => {
    const img = new Image();
    img.src = imageObj.image_uri || imageObj.image_thumbnail_uri;
    img.onload = (e) => {
      setUserUploadedImageConfirmation({
        ...imageObj,
        orientation: e.target.height > e.target.width ? 'portrait' : 'landscape',
      });
    };
  };

  const cancelUserUploadedImageConfirmation = () => {
    setUserUploadedImageConfirmation({});
  };

  const confirmSendToCanvas = () => {
    const data = {
      resourceId: null,
      resourceType: 'uploadedImage',
      url: userUploadedImageConfirmation.image_uri,
    };
    onSelectImage(data);
    cancelUserUploadedImageConfirmation();
  };

  const toggleStatus = () => {
    onToggleStatus(!disableChat);
  };

  const closeBlockChatModal = () => {
    setBlockChatModal({});
  };

  const blockUser = () => {
    onBlockUser(blockChatModal);
    closeBlockChatModal();
  };

  const openBlockChatModal = (chat) => {
    setBlockChatModal(chat);
  };

  return (
    <ErrorBoundry msg={t('error', 'loadingChat')}>
      <Column nowrap className="flex-1">
        {!isEmpty(blockChatModal) && (
          <SessionGenericModal
            type="edit"
            text1={t('classroom', 'chatBlockHeading')}
            text2={t('classroom', 'chatBlock')}
            btn1={t('classroom', 'buttonConfirm')}
            btn2={t('classroom', 'ButtonCancel')}
            onClick={blockUser}
            onClose={closeBlockChatModal}
          />
        )}

        {!isEmpty(userUploadedImageConfirmation) && (
          <Modal
            className={classNames('student-image-modal', `${userUploadedImageConfirmation.orientation}`)}
            onClose={cancelUserUploadedImageConfirmation}
          >
            <div className="modal-body">
              <img src={userUploadedImageConfirmation.image_thumbnail_uri} alt="" />
            </div>
            <div className="modal-footer">
              <Button
                type="primary"
                value="Add"
                onClick={confirmSendToCanvas}
              />
              <Button
                type="default"
                value="Cancel"
                onClick={cancelUserUploadedImageConfirmation}
              />
            </div>
          </Modal>
        )}

        <Row className="mlr-1" align="center" justify="end">
          <Checkbox
            value={disableChat ? 'off' : 'on'}
            bit
            checked={!disableChat}
            label={t('classroom', disableChat ? 'closed' : 'open')}
            onChange={toggleStatus}
            className={classNames('chat-status', disableChat ? 'off' : 'on')}
          />
        </Row>
        <ChatList
          chats={chats}
          user={user}
          addStudentImageToCanvas={addStudentImageToCanvas}
          blockChat={openBlockChatModal}
        />
        <ChatFooter sendMessage={sendMessage} />
      </Column>
    </ErrorBoundry>
  );
};

export default Chat;

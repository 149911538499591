import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button, Row } from '@noon/atom';
import { drop, flatten, isEmpty, map } from 'lodash-es';
import classNames from 'classnames';
import { isMobileOnly } from 'react-device-detect';
import { IconSearchO } from '@noon/quark';
import PageHeader from '../../components/Layout/pageHeader';
import HelpPage from '../../components/HelpPage';
import { MY_PREVIOUS_GROUP_TUTORING } from '../../redux/constants';
import { translationText } from '../../helpers';

const HELP_PAGE_CATEGORIES = [
  { order: 1, translationId: 1, icon: '', questionsCount: 11 },
  { order: 2, translationId: 2, icon: '', questionsCount: 11 },
  { order: 3, translationId: 3, icon: '', questionsCount: 6 },
  { order: 4, translationId: 4, icon: '', questionsCount: 6, videos: { 4: { ar: 'https://youtube.com/embed/4NAEkwvTErQ?rel=0', en: 'https://youtube.com/embed/Xw9sD5js-b4?rel=0' } } },
  { order: 5, translationId: 5, icon: '', questionsCount: 11, videos: { 2: { ar: 'https://youtube.com/embed/181mpqWbgg4?rel=0', en: 'https://youtube.com/embed/SPIJ5nTy_J8?rel=0' } } },
  { order: 6, translationId: 6, icon: '', questionsCount: 2 },
  { order: 7, translationId: 7, icon: '', questionsCount: 8 },
  { order: 8, translationId: 8, icon: '', questionsCount: 3, videos: { 2: { ar: '', en: 'https://www.youtube.com/embed/eoGYRZFkB5s?rel=0' } } },
  { order: 9, translationId: 9, icon: '', questionsCount: 3 },
  { order: 10, translationId: 10, icon: '', questionsCount: 3 },
  { order: 11, translationId: 11, icon: '', questionsCount: 6 },
];

const Training = () => {
  const [searchInputText, setSearchInputText] = useState('');
  const [showSearchInput, setShowSearchInput] = useState(false);
  const dispatch = useDispatch();
  const [helpPageCategories, setHelpPageCategories] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const noonText = useSelector((state) => state.toJS().translation.noonText);
  const { groups } = useSelector((state) => state.toJS().groupsV2);
  const { myPreviousGroupTutoring } = useSelector((state) => state.toJS().tutoring);

  const handleOnSearchInputChange = (event) => {
    const inputText = event.target.value;
    setSearchInputText(inputText);
    if (inputText) {
      const questions = flatten(map(helpPageCategories, 'questions'));
      const filteredQuestionByInput = questions.filter((question) => question.question.indexOf(inputText) > -1 || question.answer.indexOf(inputText) > -1);
      setFilteredQuestions(filteredQuestionByInput);
    }
  };

  const handleOnSearchButtonClick = () => {
    setShowSearchInput((prevShowSearchInput) => !prevShowSearchInput);
  };

  const initialCalculation = () => {
    const newHelpCategories = HELP_PAGE_CATEGORIES.map((category) => {
      const name = translationText(noonText, `help.cat${category.translationId}Title`);
      let questions = [];
      for (let i = 0; i < category.questionsCount; i++) {
        questions = [...questions, {
          question: translationText(noonText, `help.cat${category.translationId}q${i + 1}`),
          answer: translationText(noonText, `help.cat${category.translationId}a${i + 1}`),
        }];
      }
      return { ...category, name, questions };
    });
    setHelpPageCategories(newHelpCategories);
  };

  const clearSearch = () => {
    setFilteredQuestions([]);
    setSearchInputText('');
  };

  useEffect(() => {
    const payload = { page: 1, limit: 20 };
    initialCalculation();
    dispatch({
      type: MY_PREVIOUS_GROUP_TUTORING.REQUEST,
      payload,
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(groups.data) && !isEmpty(myPreviousGroupTutoring.response) && myPreviousGroupTutoring.response.length > 3) {
      setHelpPageCategories((prevCategories) => drop(prevCategories));
    }
  }, [myPreviousGroupTutoring]);

  // const videos = isRtl? [
  //   { src: 'https://youtube.com/embed/4NAEkwvTErQ?rel=0', label: 'create groups', order: 1 },
  //   { src: 'https://youtube.com/embed/181mpqWbgg4?rel=0', label: 'create session', order: 2 },
  //   { src: 'https://www.youtube.com/embed/ZWBQLa8dsZc?rel=0', label: 'create hw', order: 3 },
  //   { src: 'https://www.youtube.com/embed/kQQmRFtAI74?rel=0', label: 'invite', order: 4 },
  // ] : [
  //   { src: 'https://youtube.com/embed/Xw9sD5js-b4?rel=0', label: 'create groups', order: 1 },
  //   { src: 'https://youtube.com/embed/SPIJ5nTy_J8?rel=0', label: 'create session', order: 2 },
  //   { src: 'https://www.youtube.com/embed/ZcBf9uG10OQ?rel=0', label: 'prepare', order: 3 },
  //   { src: 'https://www.youtube.com/embed/eoGYRZFkB5s?rel=0', label: 'create hw', order: 4 },
  // ];

  return (
    <React.Fragment>
      <PageHeader title={translationText(noonText, 'help.headerTitle')} className="help-page-header">
        <Row justify="end" align="center" className={classNames('search-input-box full-width', { active: showSearchInput })} gap="sm">
          {(!isMobileOnly || showSearchInput)
            && <Input onChange={handleOnSearchInputChange} type="text" placeholder={translationText(noonText, 'help.searchAnswer')} className="search-input" />}
          <Button fab type="secondary" size="lg" className="search-icon" onClick={handleOnSearchButtonClick}>
            <IconSearchO width="24px" height="24px" name="search-o" fill="#23294E" stroke="#23294E" />
          </Button>
        </Row>
      </PageHeader>
      {!isEmpty(helpPageCategories) && (
        <HelpPage
          helpPageCategories={helpPageCategories}
          filteredQuestions={filteredQuestions}
          showFilteredQuestion={!!searchInputText}
          noonText={noonText}
          clearSearch={clearSearch}
        />
      )}
    </React.Fragment>
  );
};

export default Training;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column, Row, Avatar, MoreActions } from '@noon/atom';
import { IconBlock, IconCorrect, IconDelete, IconLike, IconUnblock, IconUnblockAndAdd } from '@noon/quark';
import { translationText } from '../../helpers';
import PostModal from './postModal';
import {
  BLOCK_USER,
  UN_BLOCK_USER,
  GROUP_REMOVE_MEMBERS,
} from '../../redux/constants';
import NotAllowedOverlay from '../GroupDetails/NotAllowedOverlay';

export default function GroupMemberCard(prop) {
  const { id, name, profile_pic, gender, groupId, blockList, subscription, joined_at, isArchived } = prop;
  const dispatch = useDispatch();
  const isRtl = document.body.dir === 'rtl';
  const { noonText } = useSelector((state) => state.toJS().translation);
  const isNew = ((Date.now() - new Date(joined_at)) / 3600000) < 168; // 7 days old check
  const [modalVisible, setModalVisible] = useState(false);
  const [optionType, setOptionType] = useState({});

  const getOptions = () => {
    if (blockList) {
      return [
        {
          name: 'unblockAndAdd',
          icon: IconUnblockAndAdd,
          text: translationText(noonText, 'post.unblockAndAdd'),
          confirmationText: '',
        },
        {
          name: 'unblock',
          icon: IconUnblock,
          text: translationText(noonText, 'post.unblock'),
          confirmationText: '',
        },
      ];
    }
    return [
      {
        name: 'remove',
        icon: IconDelete,
        header: translationText(noonText, 'post.removeMember'),
        text: translationText(noonText, 'post.removeSubText'),
        confirmationText: translationText(noonText, 'post.removeConfirmText'),
      },
      {
        name: 'block',
        icon: IconBlock,
        header: translationText(noonText, 'post.blockMember'),
        text: translationText(noonText, 'post.blockSubText'),
        confirmationText: translationText(noonText, 'post.blockConfirmText'),
      },
    ];
  };

  const takeAction = (actionName) => {
    if (actionName === 'unblock') {
      dispatch({ type: UN_BLOCK_USER.REQUEST, payload: { user_id: id, group_id: groupId, join_group: false } });
    } else if (actionName === 'unblockAndAdd') {
      dispatch({ type: UN_BLOCK_USER.REQUEST, payload: { user_id: id, group_id: groupId, join_group: true } });
    } else {
      setModalVisible(true);
      getOptions().find((option) => { if (option.name === actionName) setOptionType(option); });
    }
  };

  const closeConfirmation = () => {
    setModalVisible(false);
    setOptionType({});
  };

  const sendRequest = () => {
    if (optionType.name === 'block') {
      dispatch({ type: BLOCK_USER.REQUEST, payload: { user_id: id, group_id: groupId } });
    } else if (optionType.name === 'remove') {
      dispatch({ type: GROUP_REMOVE_MEMBERS.REQUEST, payload: { group_id: groupId, users_array: [id] } });
    }
    closeConfirmation();
  };

  return (
    <Row key={id} className="single-request-card member">
      <Column className="hidden left-content" flex="1">
        <div className="media">
          <div className="media-left">
            <Avatar url={profile_pic} noShadow rounded gender={gender} size="42px" />
          </div>
          <div className="media-body">
            <div className="flex">
              <p>{name}</p>
              {isNew && (
                <span>
                  <IconLike height="11px" width="11px" fill="#64D8AE" />
                  {translationText(noonText, 'groups.newMember')}
                </span>
              )}
            </div>
          </div>
          {subscription && !!subscription.amount && (
            <Row className="subscription-info" gap="sm" align="center">
              <div className="wrapper mlr-1"><IconCorrect fill="#ffffff" stroke="#ffffff" height="22px" width="22px" /></div>
              <p>{translationText(noonText, 'groups.paid')}</p>
            </Row>
          )}
        </div>
      </Column>
      <Row className="right-content position-relative" align="center" justify="end">
        {isArchived && <NotAllowedOverlay />}
        <MoreActions
          type="dots"
          position={isRtl ? 'right' : 'left'}
          className="activity"
          listActions={getOptions()}
          cardId={id}
          onSelect={(actionName) => takeAction(actionName)}
          vertical
        />
      </Row>
      {modalVisible && (
        <PostModal
          text1={optionType.header}
          text2={optionType.confirmationText}
          btn1={translationText(noonText, 'session.confirm')}
          btn2={translationText(noonText, 'session.cancel')}
          type={optionType.name}
          onClick={sendRequest}
          onClose={closeConfirmation}
        />
      )}
    </Row>
  );
}

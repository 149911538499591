import React from 'react';
import { Modal, Button, Row, Hr } from '@noon/atom';
import { IconCorrectCircle, IconPlus } from '@noon/quark';
import classNames from 'classnames';
import { translationText } from '../../helpers';
import ErrorBoundry from '../ErrorBoundry';
import { COLORS } from '../../constants/index';

export default function QuestionPreviewModal(prop) {
  const { onClose, onAddQuestion, questionData, noonText } = prop;
  const times = [15, 30, 45, 60, 90, 120, 180, 240, 300];
  return (
    <ErrorBoundry>
      <Modal className="question-modal" onClose={onClose}>
        <Row className="ptb-1 plr-2">
          <p className="mtb-1 flex-1">{translationText(noonText, 'question.question')}</p>
          <IconPlus rotate="45" fill={COLORS.text.base} height="3rem" className="pointer" onClick={onClose} />
        </Row>
        <div className={classNames('questionWrapper', 'plr-2', { english: questionData.text_direction === 'ltr' })}>
          {questionData.passage && typeof questionData.passage !== 'object' && (
            <p dangerouslySetInnerHTML={{ __html: questionData.passage }} />
          )}
          {questionData.passage && typeof questionData.passage === 'object' && questionData.passage.content && (
            <p dangerouslySetInnerHTML={{ __html: questionData.passage.content }} />
          )}
          <h3
            className="questionMatter correct"
            id="questionPara"
            dangerouslySetInnerHTML={{ __html: questionData.question }}
          />
          {questionData.question_type_name === 'mcq' && (
            <div className="mcq-type-question">
              <div className="choicesList">
                {questionData.choices.map((choice) => (
                  <div
                    key={choice.id}
                    className={classNames('questionChoice', 'clearfix', {
                      correct: choice.is_correct,
                    })}
                  >
                    <div className="choiceBox">
                      <p className="choiceMatter" dangerouslySetInnerHTML={{ __html: choice.answer }} />
                      {!!choice.is_correct && <IconCorrectCircle />}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <Hr />
        <Row align="Center" justify="center" className="justify-space mtb-2">
          <span>{translationText(noonText, 'competition.enterTimeLimit')}</span>
          {times.map((time) => (
            <Button
              key={time}
              size="sm"
              type="primary"
              outlined
              style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
              onClick={() => onAddQuestion(questionData, time)}
            >
              {time}
              <small>s</small>
            </Button>
          ))}
        </Row>
        {/* <Row nowrap align="Center" justify="center" className="justify-space-lg mtb-1">
          <Button type="primary" onClick={() => }>
            {translationText(noonText, 'competition.save')}
          </Button>
          <Button onClick={onClose}>{translationText(noonText, 'tutoring.notNow')}</Button>
        </Row> */}
      </Modal>
    </ErrorBoundry>
  );
}

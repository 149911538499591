import React, { useState, useRef, useEffect } from 'react';
import { Input, Button, Column, Row } from '@noon/atom';
import { Info } from 'luxon';
import { useLocation } from 'react-router-dom';
import Pikaday from 'pikaday';
import { useSelector, useDispatch } from 'react-redux';
import { IconAssignment, IconCanvasDiscard, IconFlag, IconLoaderDotted } from '@noon/quark';
import { addToast, TOAST_TYPE } from '../Toast';

import DatePicker from '../DatePicker';
import { translationText } from '../../helpers';
import { returnDuplicatePropsForMixpanelAndKafka, prepareDateForPosting } from '../../helpers/prepareAssignment';
import { CLEAR_UPDATE_HOMEWORK, GET_HOMEWORK_DETAILS_SPARTA, META_HOMEWORK_UPDATED, UPDATE_HOMEWORK_SPARTA } from '../../redux/constants';
import { getWeekdays, getWeekdaysShort } from '../../helpers/date';
import Mixpanel from '../Mixpanel';

const EditAssignmentModal = ({ setIsEditOpen, homework, extend = false, modify = false, isChanging = false }) => {
  const isRtl = document.body.dir === 'rtl';

  const [title, setTitle] = useState('');
  const location = useLocation();
  const autoPublishDateRef = useRef(null);
  const logger = useRef(LoggingManager.mount({ moduleName: 'homework' })).current;
  const [time, setTime] = useState();
  const { loggedUser: user } = useSelector((state) => state.toJS().user);

  const { noonText } = useSelector((state) => state.toJS().translation);
  const { updateHomework } = useSelector((state) => state.toJS().homeworkCreation);
  const { selectedGroup } = useSelector((state) => state.toJS().groupsV2);

  const dispatch = useDispatch();

  useEffect(() => {
    setTitle(homework.title);
  }, [homework]);
  useEffect(() => {
    if (updateHomework.success) {
      addToast(translationText(noonText, 'success.userUpdated'), TOAST_TYPE.SUCCESS);
      setIsEditOpen();
      if (!updateHomework.notUpdate) {
        dispatch({
          type: CLEAR_UPDATE_HOMEWORK,
        });
      }
      if (updateHomework.notUpdate) {
        dispatch({
          type: META_HOMEWORK_UPDATED,
        });
        dispatch({
          type: GET_HOMEWORK_DETAILS_SPARTA.REQUEST,
          hw_id: homework.id,
        });
      }
    }
  }, [updateHomework]);
  useEffect(() => {
    const locale = { locale: localStorage.language ? localStorage.language.split('_')[0] : 'en' };
    const picker = new Pikaday({
      field: autoPublishDateRef.current,
      format: 'YYYY-MM-DD',
      i18n: {
        months: Info.months('long', locale),
        weekdays: getWeekdays(),
        weekdaysShort: getWeekdaysShort(),
      },
      onSelect: () => {},
    });

    picker.setMinDate(new Date());
    const date = extend || isChanging ? homework.due_date : homework.start_date;
    picker.setDate(new Date(date));
    setTime(date);
  }, []);

  const mixpanelKafkaEvents = () => {
    const props = returnDuplicatePropsForMixpanelAndKafka(selectedGroup, user, homework);
    const subRoute = location ? location.pathname.split('/')[4] : '';
    const mainRoute = location ? location.pathname.split('/')[2] : '';
    const triggered_from = !subRoute ? (mainRoute === 'schedule' ? 'group_activity_page' : 'group_assignment_preview') : 'group_content_page';

    const properties = {
      no_of_questions: homework && homework.question_ids ? homework.question_ids.length : 0,
      triggered_from,
      ...props,
    };
    Mixpanel.track('homework_meta_edited', properties);
    logger.track('meta_edited', { homework: properties });
  };

  const saveChanges = () => {
    if (prepareDateForPosting(time, autoPublishDateRef.current.value) < (new Date().getTime() + 1000 * 3600)) {
      addToast(translationText(noonText, 'createHomework.publishTimeError'), TOAST_TYPE.WARNING);
      return false;
    }
    if (extend) {
      dispatch({
        type: UPDATE_HOMEWORK_SPARTA.REQUEST,
        hw_id: homework.id,
        hw_details: {
          publish_now: false,
          due_date: prepareDateForPosting(time, autoPublishDateRef.current.value),
        },
      });
    } else if (isChanging) {
      dispatch({
        type: UPDATE_HOMEWORK_SPARTA.REQUEST,
        hw_id: homework.id,
        hw_details: {
          title,
          publish_now: false,
          due_date: prepareDateForPosting(time, autoPublishDateRef.current.value),
        },
        notUpdate: true,
      });
    } else {
      dispatch({
        type: UPDATE_HOMEWORK_SPARTA.REQUEST,
        hw_id: homework.id,
        hw_details: {
          publish_now: false,
          start_date: prepareDateForPosting(time, autoPublishDateRef.current.value),
          title,
        },
      });
    }
    mixpanelKafkaEvents();
  };

  const returnPositionOfCrossButton = () => (isRtl ? { left: '28px' } : { right: '28px' });
  return (
    <React.Fragment>
      <div onClick={setIsEditOpen} className="prepare-assignment--edit--wrapper" />
      <Column className="prepare-assignment--edit">
        <Row align="center" className="prepare-assignment--edit--header">
          <IconAssignment height="20px" width="16px" fill="#1bbc9c" stroke="#1bbc9c" />
          <span>{extend ? translationText(noonText, 'homeworkReport.extendDueDate') : translationText(noonText, 'createHomework.editAssignment')}</span>
          <IconCanvasDiscard
            name="canvas-discard"
            height="16px"
            width="15px"
            fill="#333"
            stroke="#333"
            className="cross"
            onClick={setIsEditOpen}
            style={returnPositionOfCrossButton()}
          />
        </Row>
        <Column align="center" className="prepare-assignment--edit--container session-form">
          {!extend && (
          <React.Fragment>
            <Input
              type="text"
              label={translationText(noonText, 'label.assignmentTitle')}
              maxLength="60"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
                />
            <div className="prepare-assignment--edit--titleBlock">
              {title && !!title.length && (
              <span>
                {title.length}
                {' '}
                / 60
              </span>
              )}
            </div>
          </React.Fragment>
          )}
          <div className="prepare-assignment--edit--datepicker">
            <Row align="center">
              <IconFlag height="16px" width="13px" fill="#333333" stroke="#333333" />
              <span>
                {modify
                  ? translationText(noonText, 'createHomework.autoPublishDateTitle')
                  : translationText(noonText, 'createHomework.assignmentDue')}
              </span>
            </Row>
            <DatePicker
              onToggle={(value) => setTime(value)}
              ms={time}
              dateRef={autoPublishDateRef}
              // onSelect={(event) => console.log('selected date')}
              placeholder={translationText(noonText, 'placeholder.selectDate')}
            />
          </div>
          <Button
            onClick={() => saveChanges()}
            type="primary"
            size="lg"
            className="confirm-button"
          >
            {translationText(noonText, 'groupCurriculum.saveChanges')}
            {updateHomework.loading && <IconLoaderDotted height="50px" width="50px" fill="#919a9e" stroke="#919a9e" rotate="20" />}
          </Button>
        </Column>
      </Column>
    </React.Fragment>
  );
};

export default EditAssignmentModal;

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash-es/get';
import classNames from 'classnames';
import { Card, Row, Column } from '@noon/atom';
import { useHistory } from 'react-router-dom';
import ViewMoreButton from '../../Layout/viewMoreButton';
import ShareButton from '../ShareButton';
import Shimmer from '../../Layout/shimmer';
import { translationText } from '../../../helpers';
import NotAllowedOverlay from '../NotAllowedOverlay';

function MembersWidget({ isArchived, membersInfo, shareInfo, groupId, handleMixpanelCurriculumEvents }) {
  const isPremium = shareInfo && shareInfo.is_premium;
  const history = useHistory();
  const hasMembers = useMemo(() => get(membersInfo, 'total_members') > 0, [membersInfo]);
  const hasPendingReq = useMemo(() => get(membersInfo, 'pending_count') > 0, [membersInfo]);
  const viewAllLinkClasses = classNames({
    'card-header__members-request': !isArchived && get(membersInfo, 'pending_count') > 0,
    'card-header__view-link': get(membersInfo, 'pending_count') === 0,
  });
  const { noonText } = useSelector((state) => state.toJS().translation);

  const handleViewAllMembers = () => {
    if (hasMembers || hasPendingReq) {
      handleMixpanelCurriculumEvents({
        type: 'members_view_all',
        data: {
          student_requests: get(membersInfo, 'pending_count'),
        },
        mixPanel: false,
      });
    }
    history.push(`/${localStorage.updatedLocale}/groups/${groupId}/members`);
  };

  return (
    <Card className="member-widget">
      {(!membersInfo || membersInfo.loading) ? (
        <Shimmer obj={[{ heading: true }, { photo: true }]} />
      ) : (
        <React.Fragment>
          <Row align="center" justify="space-between" flex="1" className="card-header" gap="sm">
            <Row align="center" justify="start">
              <h3 className="card-header__title child">{translationText(noonText, 'groupMembers.title')}</h3>
              {hasMembers && (
                <React.Fragment>
                  <span className="noon-dot" />
                  <h4 className="card-header__title-state child ">{get(membersInfo, 'total_members')}</h4>
                </React.Fragment>
              )}
            </Row>
            {(hasMembers || hasPendingReq || isPremium) && (
            <ViewMoreButton
              className={viewAllLinkClasses}
              handleClick={handleViewAllMembers}
              text={`${get(membersInfo, 'pending_count') > 0 && !isArchived ? `+${get(membersInfo, 'pending_count')} ${translationText(noonText, 'groupMembers.requests')}` : ''}`}
            />
            )}
          </Row>
          <Column align="start" justify="center" className="plr-2 mb-2 card-body" gap="sm">
            {isArchived && <NotAllowedOverlay />}
            <span className="child card-body__note">
              {translationText(noonText, hasMembers ? 'groupMembers.inviteMore' : 'groupMembers.noMemberInviteMore')}
            </span>
            <ShareButton trigger="members-widget" groupInfo={shareInfo} referralEnabled="true" native hasMembers={hasMembers} />
          </Column>
        </React.Fragment>
      )}
    </Card>
  );
}

MembersWidget.propTypes = {};
MembersWidget.defaultProps = {};
export default MembersWidget;

import React, { useCallback, useState } from 'react';
import { debounce } from 'lodash-es';
import { Column, Search, Row } from '@noon/atom';
import t from '../../../../helpers/translate';
import BookItem, { IBookPage } from './bookItem';
import EmptyState from '../../emptyState';

type IBooksProp = {
    bookPages: {
        list: IBookPage[],
        isLoading: Boolean,
    },
    onSearchPage: (pageNumber: number | null) => void,
    onSelectPage: (bookPage: IBookPage) => void,
}

const DEBOUCE_SEARCH_TIME = 500; // in ms

const Books = ({ bookPages, onSearchPage, onSelectPage }: IBooksProp) => {

  const [error, setError] = useState('');

  const handleSearch = (pageNumber: string) => {
    const number = Number(pageNumber);
    setError('');
    if (pageNumber && Number.isNaN(number)) {
      setError('enterNumber');
      return;
    }
    onSearchPage(number);
  };

  const debouncedSearch = useCallback(debounce(handleSearch, DEBOUCE_SEARCH_TIME), []);

  const handleUserInput = (e) => {
    const { value } = e.target;
    debouncedSearch(value);
    e.preventDefault();
  };

  return (
    <Column nowrap className="folder-book flex-1 full-height">
      <Row nowrap align="center" justify="center" className="folder-book__filter">
        <Search
          name="book-search"
          autoComplete="off"
          loading={bookPages.isLoading}
          placeholder={t('classroom', 'searchBookByPage')}
          onChange={handleUserInput}
        />
      </Row>
      {!!error && (
        <small className="text-color-red">
          {t('error', error)}
        </small>
      )}

      {!bookPages.isLoading && !bookPages?.list?.length && (
        <EmptyState
          title={t('content', 'empty_book_title')}
          subtitle={t('content', 'empty_book_subtitle')}
        />
      )}

      {!!bookPages?.list?.length && (
        <div className="folder-book__pages">
          <Row className="folder-book__list">
            {bookPages.list.map((bookPage) => <BookItem key={bookPage.id} bookPage={bookPage} selectPage={onSelectPage} />)}
          </Row>
        </div>
      )}
    </Column>
  );
};

export default Books;

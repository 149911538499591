import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Column, Button } from '@noon/atom';
import ReactHtmlParser from 'react-html-parser';
import { IconLoader } from '@noon/quark';
import t from '../../helpers/translate';
import { classType } from '../../types';
import ErrorBoundry from '../ErrorBoundry';

export default class ClassroomWaitingCard extends Component {

  render() {
    const { sessionData, onAddSlide, isClassReady, onStartCompetition, isSlideAdding } = this.props;
    const isCompetition = sessionData.class_type === 'competition';
    return (
      <ErrorBoundry msg={t('error', 'classroomDetails')}>
        <Card className="classroom-waiting-card flex-column flex-nowrap align-center justify-center">
          {isClassReady ? (
            isCompetition ? (
              <React.Fragment>
                <h4>{t('classroom', 'competitionRulesTitle')}</h4>
                <ul className="mt-2 text-color-light">
                  {ReactHtmlParser(t('classroom', 'competitionRulesList'))}
                </ul>
                {sessionData.state === 'started' && onStartCompetition && (
                  <Button type="primary" size="lg" className="mt-4" onClick={onStartCompetition}>
                    {t('button', 'startCompetition')}
                  </Button>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h3>{t('classroom', 'addCardHeading')}</h3>
                <p>{t('classroom', 'addCardMessage')}</p>
                <Button type="primary" size="lg" loading={!!isSlideAdding} className="mt-4" onClick={() => onAddSlide(null)}>
                  {t('classroom', 'addCardButton')}
                </Button>
              </React.Fragment>
            )
          ) : (
            <Column align="center">
              <IconLoader height="50px" width="50px" />
            </Column>
          )}
        </Card>
      </ErrorBoundry>
    );
  }
}

ClassroomWaitingCard.propTypes = {
  sessionData: classType.isRequired,
  onAddSlide: PropTypes.func.isRequired,
  onStartCompetition: PropTypes.func.isRequired,
  isClassReady: PropTypes.bool,
  isSlideAdding: PropTypes.number,
};

ClassroomWaitingCard.defaultProps = {
  isClassReady: false,
  isSlideAdding: 0,
};

import React from 'react';
import Popover from 'react-popover';
import isEmpty from 'lodash-es/isEmpty';
import debounce from 'lodash-es/debounce';
import { IconArrowDown, IconBlock, IconComment, IconCommentO, IconDelete, IconLoader, IconMoreOption, IconPlus, IconRemove, IconThankYou, IconUnblock, IconUnblockAndAdd } from '@noon/quark';
import { isMobileOnly, isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Avatar, Column, Row, FormattedText, Modal, Button, NoonDate } from '@noon/atom';
import ImageWithOrientation from '../../Layout/imageWithOrientation';
import { COLORS, isRTL } from '../../../constants';
import { translationText } from '../../../helpers';
import { translationType } from '../../../types';
import Comment from '../comment';
import { HtmlInput } from '..';
import MultipleImages from '../multipleImages';

class Answer extends React.Component {
  static propTypes = {
    noonText: translationType.isRequired,
    data: PropTypes.shape().isRequired,
    user: PropTypes.shape().isRequired,
    replies: PropTypes.shape().isRequired,
    postData: PropTypes.shape().isRequired,
    // countries: PropTypes.shape().isRequired,
    // getMyQuestionList: PropTypes.func.isRequired,
    // teacherQuestions: PropTypes.shape().isRequired,
    // questionDeleteId: PropTypes.number.isRequired,
    // deleteQuestion: PropTypes.func.isRequired,
    thankCount: PropTypes.number.isRequired,
    likeComment: PropTypes.func.isRequired,
    thankAnswer: PropTypes.func.isRequired,
    blockUser: PropTypes.func.isRequired,
    unblockUser: PropTypes.func.isRequired,
    removeMember: PropTypes.func.isRequired,
    postId: PropTypes.func.isRequired,
    deleteAnswer: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this._nodes = {};
    this.state = {
      showCommentPopOver: false,
      showMore: false,
      showFullImage: -1,
      showHover: false,
      showReplies: false,
    };

    this.commonActionItem = [
      { text: translationText(props.noonText, 'activity.delete'), name: 'delete', iconSize: '16px', icon: IconDelete },
    ];

    this.moreActionListBlockUser = [
      {
        name: 'block',
        text: translationText(props.noonText, 'post.block'),
        icon: IconBlock,
        fill: '#fff',
        stroke: COLORS.text.dark,
        iconSize: '20px',
      },
      {
        name: 'remove',
        text: translationText(props.noonText, 'activity.remove_user').replace('{}', props.data.user.name),
        notFromNoonText: true,
        icon: IconRemove,
        fill: '#fff',
        stroke: COLORS.text.dark,
        iconSize: '20px',
      },
    ];

    this.moreActionListForUnblockUser = [
      {
        name: 'unblock',
        text: translationText(props.noonText, 'post.unblock'),
        icon: IconUnblock,
        fill: '#fff',
        stroke: COLORS.text.dark,
        iconSize: '20px',
      },
      {
        name: 'unblockAndAdd',
        text: translationText(props.noonText, 'post.unblockAndAdd'),
        icon: IconUnblockAndAdd,
        fill: '#fff',
        stroke: COLORS.text.dark,
        iconSize: '20px',
      },
    ];

    this.showPopoverForComment = this.showPopoverForComment.bind(this);
    this.showMoreTrue = this.showMoreTrue.bind(this);
    this.hidePopOverForComment = this.hidePopOverForComment.bind(this);

    this.handleCall = debounce(this.handleCall, 1000);
  }

  hidePopOverForComment() {
    this.setState({ showCommentPopOver: false });
  }

  showPopoverForComment() {
    this.setState({ showCommentPopOver: !this.state.showCommentPopOver });
  }

  handleMoreActions = (type) => {
    const {
      data: {
        id: answerId,
        user: { id: userId },
      },
      blockUser,
      unblockUser,
      removeMember,
      postId,
      likeComment,
      deleteAnswer,
    } = this.props;
    if (type === 'delete') {
      deleteAnswer({ answerId, questionId: postId });
    } else if (type === 'block') {
      blockUser(userId);
    } else if (type === 'unblock' || type === 'unblockAndAdd') {
      unblockUser(userId, type === 'unblockAndAdd');
    } else if (type === 'remove') {
      removeMember(userId);
    }
    this.hidePopOverForComment();
  };

  handleCall(id, postId, reaction) {
    this.props.likeComment(id, postId, reaction, this.props.type);
  }

  removeMember() {
    this.props.removeMember(this.props.data);
  }

  showMoreTrue() {
    this.setState({ showMore: !this.state.showMore });
  }

  showFullSizeImage = (idx) => {
    this.setState({ showFullImage: idx });
  };

  removeImage = (e) => {
    if (e.target.localName !== 'img') {
      this.setState({ showFullImage: -1 });
    }
  };

  getActionItemList(isSelf) {
    const {
      data: {
        user: { id },
      },
    } = this.props;
    const isGroupMember = this.props.groupMembersIdMap[id];
    const isBlockUser = this.props.blockListIdMap[id];
    if (isSelf) {
      return this.commonActionItem;
    } if (isBlockUser) {
      return [...this.commonActionItem, ...this.moreActionListForUnblockUser];
    } if (!isGroupMember) {
      return this.commonActionItem;
    }
    return [...this.commonActionItem, ...this.moreActionListBlockUser];
  }

  handleMouseEnter = (e) => {
    this.setState({ showHover: true });
    e.stopPropagation();
  };

  handleMouseLeave = (e) => {
    this.setState({ showHover: false });
    e.stopPropagation();
  };

  commentSend = (text) => {
    this.props.sendReply({ text, answerId: this.props.data.id });
  };

  viewReplies = (e) => {
    this.setState({ showReplies: !this.state.showReplies });
    this.props.viewReplies({ answerId: this.props.data.id, questionId: this.props.postId, start: 0, limit: 5 });
    e.stopPropagation();
  };

  showMoreReplies = () => {
    this.props.viewReplies({
      answerId: this.props.data.id,
      questionId: this.props.postId,
      start: this.props.replies.response[this.props.data.id].meta.paging.start,
      limit: this.props.replies.response[this.props.data.id].meta.paging.limit,
      next_tag: this.props.replies.response[this.props.data.id].meta.paging.next_tag,
    });
  };

  onNextOrPreviousClick(e, currentIndex, type, lastIndex) {
    if (type === 'next') {
      if (lastIndex !== currentIndex) {
        this.setState({ showFullImage: currentIndex + 1 });
      } else {
        this.setState({ showFullImage: 0 });
      }
    } else if (currentIndex === 0) {
      this.setState({ showFullImage: lastIndex });
    } else {
      this.setState({ showFullImage: currentIndex - 1 });
    }
    e.stopPropagation();
  }

  render() {
    const {
      data,
      idx,
      noonText,
      user,
      moreActionListBlockUser,
      moreActionListForUnblockUser,
      groupMembersIdMap,
      blockListIdMap,
      comments,
      showBorder,
      correctAnswer,
      emojis,
      replies,
      deleteReply,
      replyLoadingId,
      createReplyLoading,
      isRtl,
      isArchived,
    } = this.props;
    const { howCommentPopOver, showHover, showCommentPopOver, showFullImage } = this.state;

    const actionItems = this.getActionItemList(data.user.id === user.id);
    const popoverProps = {
      isOpen: showCommentPopOver,
      preferPlace: isMobileOnly ? 'right' : 'above',
      onOuterAction: () => this.hidePopOverForComment(),
      body: (
        <ul className="activity-popover-list vertical">
          {actionItems.map((item) => (
            <li key={item.text} onClick={() => this.handleMoreActions(item.name)} className="pointer">
              <item.icon height={item.iconSize || '16px'} fill={item.fill} stroke={item.stroke} />
              <span>{item.text}</span>
            </li>
          ))}
        </ul>
      ),
    };
    const isTeacher = Number(data.user.id) === Number(user.id);

    return (
      <div
        className="full-width incoming-comments fadeOut"
        key={data.id}
        style={{ border: `${showBorder ? (correctAnswer ? '2px solid #e4ecf8' : '1px solid #e4ecf8') : 'none'}` }}
      >
        {showFullImage !== -1 && (
          <React.Fragment>
            <Modal
              className="img-wrapper"
              backDropClassName="img-backdrop"
              closeButtonCustom={(
                <IconPlus
                  rotate="45"
                  fill="#fff"
                  className="mlr-2 mt-2"
                  size="20px"
                  onClick={this.removeImage}
                />
              )}
              onClick={this.removeImage}
              onClose={this.removeImage}
            >
              <Row align="center" className="full-image">
                <div style={{ width: '30px', height: '30px', cursor: 'pointer' }}>
                  <IconArrowDown
                    rotate={!isRTL() ? 90 : 270}
                    size="20px"
                    fill="white"
                    className={classNames('next', { invisible: data.files.length === 1 })}
                    onClick={(e) => this.onNextOrPreviousClick(e, showFullImage, 'previous', data.files.length - 1)}
                  />
                </div>
                <ImageWithOrientation
                  url={data.files[showFullImage].original}
                  files={data.files[showFullImage]}
                  onNextOrPreviousClick={this.onNextOrPreviousClick}
                />
                <div style={{ width: '30px', height: '30px', cursor: 'pointer' }}>
                  <IconArrowDown
                    rotate={!isRTL() ? 270 : 90}
                    size="20px"
                    fill="white"
                    className={classNames('next', { invisible: data.files.length === 1 })}
                    onClick={(e) => this.onNextOrPreviousClick(e, showFullImage, 'next', data.files.length - 1)}
                  />
                </div>
              </Row>
            </Modal>
          </React.Fragment>
        )}
        <Column
          className={classNames('full-width incoming-comments--head', {})}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          <Column nowrap className="incoming-comments__inner">
            <Row style={{ justifyContent: 'space-between' }}>
              <Row gap="sm">
                <Avatar
                  className="child incoming-comments__avatar"
                  size={isMobile ? '30px' : '42px'}
                  url={data.user && data.user.profile_pic}
                  noShadow
                  rounded
                />
                <Column align="start" justify="center" className="mlr-05 user">
                  <h6 className="no-margin child user__name">{data.user.name}</h6>
                  <Row gap="sm" align="center" justify="start">
                    <p>
                      <NoonDate humanize value={data.created_at} format="dd LLL, hh:mm a" />
                    </p>
                  </Row>
                </Column>
              </Row>
              <Popover {...popoverProps} className="comment-popover">
                <IconMoreOption
                  onClick={() => this.showPopoverForComment(idx, data)}
                  width="16px"
                  height="16px"
                  fill={COLORS.text.light}
                  className={classNames('', {
                    invisible: !showHover && !isMobile,
                  })}
                />
              </Popover>
            </Row>
            <Column className="comment-text-parent mtb-2 align-start relative">
              {(data.text || !isEmpty(data.files)) && (
              <FormattedText
                className={classNames('comment-text fadeIn disp-flex', {
                  'flex-column': data.text.length >= 400,
                })}
                classNameForShowMore="mt-1"
                text={data.text}
                showMoreAt={400}
                breakpoint={95}
                largeFont="14px"
                normalFont="14px"
                slim
                shorten
                shortenMsg={
                      this.state.showMore
                        ? translationText(noonText, 'post.show_less')
                        : translationText(noonText, 'post.view_more')
                    }
                showMoreTrue={this.showMoreTrue}
                showMoreFlag={this.state.showMore}
                  />
              )}
              {data.files && !!data.files.length && showFullImage === -1 && (
              <MultipleImages files={data.files} showFullSizeImage={this.showFullSizeImage} />
              )}
            </Column>
          </Column>
          <Row className="comment-reactions" align="center" justify="space-between" gap="lg">
            {this.props.data.user && user.user_id && String(user.user_id) === String(this.props.data.user.id) ? (
              <span className="child comment-reactions--like disabled">
                {translationText(noonText, 'post.saidThanks')}
                {' '}
                (
                {this.props.thankCount}
                )
              </span>
            ) : (
              <Row
                className="comment-reactions--like"
                align="center"
                gap="sm"
                onClick={() => this.props.thankAnswer({
                  payload: {
                    answerId: this.props.data.id,
                    questionId: this.props.postId,
                    reaction: !this.props.thankAnswerMap[this.props.data.id],
                    group_id: this.props.groupId,
                  },
                  thankCount: this.props.thankCount,
                  correctAnswer,
                })}
              >
                <IconThankYou className="child icon" stroke={this.props.thankAnswerMap[this.props.data.id] ? COLORS.brand.base : COLORS.grey[1]} fill={this.props.thankAnswerMap[this.props.data.id] ? COLORS.brand.base : COLORS.grey[1]} />
                <div
                  className={classNames('', {})}
                  style={{ color: `${this.props.thankAnswerMap[this.props.data.id] ? COLORS.brand.base : COLORS.grey[1]}` }}
                >
                  {`${
                    this.props.thankAnswerMap[this.props.data.id]
                      ? `${translationText(noonText, 'post.saidThanks')} (${this.props.thankCount})`
                      : `${translationText(noonText, 'post.sayThanks')} (${this.props.thankCount})`
                  }`}
                </div>
              </Row>
            )}
            <Row onClick={this.viewReplies} gap="sm" className="comment-reactions__replies" align="center">
              {this.state.showReplies ? <IconComment className="child" width="24px" fill={COLORS.brand.base} /> : <IconCommentO className="child" width="24px" fill={COLORS.grey[1]} />}
              <p
                className="child"
                style={{ color: `${this.state.showReplies ? COLORS.brand.base : COLORS.grey[1]}` }}
              >
                {`${translationText(noonText, 'post.replies')} (${this.props.replyCount})`}
              </p>
              <IconArrowDown className="child" width="1.8rem" />
            </Row>
          </Row>
          {this.state.showReplies && (
            <Column>
              {!isArchived && (
              <Row>
                <HtmlInput
                  inputRef={(input) => {
                    if (this._nodes) this._nodes[data.id] = input;
                  }}
                  sendOperation={this.commentSend}
                  noonText={noonText}
                  emojis={emojis}
                  user={user}
                  placeholder="post.write_comment"
                  node={this._nodes}
                  currentPostIndex={data.id}
                  type="comment"
                  idOfSrc={data.id}
                  showAttachment
                  typeOfAttachment="normal"
                  hideImage
                  maxChar={400}
                />
              </Row>
              )}
              {createReplyLoading && <div className="loader-linear" />}
              {!!replies.response[data.id]
                && replies.response[data.id].list.map((value, idx) => (
                  <Comment
                    idx={idx}
                    data={value}
                    comments={value}
                    deleteComment={() => deleteReply({ answerId: data.id, commentId: value.id })}
                    postId={data.id}
                    postData={data}
                    noonText={noonText}
                    key={value.id}
                    user={user}
                    likeComment={(id, postId, reaction, type) => this.props.likeComment({ replyId: id, postId, reaction, type })}
                    moreActionListBlockUser={this.props.moreActionListBlockUser}
                    moreActionListForUnblockUser={this.props.moreActionListForUnblockUser}
                    groupMembersIdMap={this.props.groupMembersIdMap}
                    blockListIdMap={this.props.blockListIdMap}
                    blockUser={this.props.blockUser}
                    unblockUser={this.props.unblockUser}
                    groupDetails={this.props.groupDetails}
                    likeReplyValue={this.props.likeReplyMap[value.id]}
                    type="reply"
                    isArchived={isArchived}
                  />
                ))}
              {!!replies
                && !!replies.response[data.id]
                && replies.response[data.id].meta
                && !!replies.response[data.id].meta.paging && (
                  <React.Fragment>
                    {replyLoadingId ? (
                      <Row alig="center" justify="center" className="ptb-2">
                        <IconLoader />
                      </Row>
                    ) : (
                      <Row alig="center" justify="start" className="plr-4 mlr-2">
                        <Button
                          link
                          onClick={this.showMoreReplies}
                          className={classNames('text-capitalize show-more mb-2', {
                            disable: replyLoadingId,
                          })}
                        >
                          {translationText(noonText, 'post.showMoreReplies')}
                          <IconArrowDown fill={COLORS.grey[1]} stroke={COLORS.grey[1]} height="16px" width="16px" />
                        </Button>
                      </Row>
                    )}
                  </React.Fragment>
              )}
            </Column>
          )}
        </Column>
      </div>
    );
  }
}

export default Answer;

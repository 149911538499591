import { put, call, all, takeLatest } from 'redux-saga/effects';
import isEmpty from 'lodash-es/isEmpty';
import { flashcardApiV2, folderApi, questionApiv2 } from '../restApi';
import { CONTENT_FLASHCARD, CONTENT_QUESTION, CONTENT_BOOK } from '../constants';

const getBooksAPI = ({ page_number, tag_id }) => folderApi.post('v2/searchPage', { page_no: page_number, tag_id });
const getFlashcardAPI = ({ topic_id }) => flashcardApiV2.get(`flashcardImagesByLeafId/${topic_id}`);
const getQuestionsAPI = ({ topic_id, contributor = 'all', page = 1, limit = 10, text, sort = 'DESC' }) => questionApiv2.get(`teacher/questionListWithChoices/${topic_id}`, { contributor, page, limit, text, sort });

function* getBooks({ payload }) {
  try {
    const response = yield call(getBooksAPI, payload);
    if (response.data.success) {
      yield put({
        type: CONTENT_BOOK.SUCCESS,
        payload: isEmpty(response.data.data) ? [] : response.data.data,
      });
    } else {
      yield put({ type: CONTENT_BOOK.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: CONTENT_BOOK.FAILURE, payload: err });
  }
}

function* getFlashcards({ payload }) {
  try {
    const response = yield call(getFlashcardAPI, payload);
    if (response.data.success) {
      yield put({
        type: CONTENT_FLASHCARD.SUCCESS,
        payload: isEmpty(response.data.data) ? [] : response.data.data,
        meta: {
          topic_id: payload.topic_id,
        },
      });
    } else {
      yield put({ type: CONTENT_FLASHCARD.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: CONTENT_FLASHCARD.FAILURE, payload: err });
  }
}

function* getQuestions({ payload }) {
  try {
    const response = yield call(getQuestionsAPI, payload);
    if (response.data.success) {
      yield put({
        type: CONTENT_QUESTION.SUCCESS,
        payload: isEmpty(response.data.data) ? [] : response.data.data,
        meta: {
          next_page: response.data.next_page,
          topic_id: payload.topic_id,
          page: payload.page,
        },
      });
    } else {
      yield put({ type: CONTENT_QUESTION.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: CONTENT_QUESTION.FAILURE, payload: err });
  }
}

export function* contentSaga() {
  yield all([
    takeLatest(CONTENT_FLASHCARD.REQUEST, getFlashcards),
    takeLatest(CONTENT_BOOK.REQUEST, getBooks),
    takeLatest(CONTENT_QUESTION.REQUEST, getQuestions),
  ]);
}

import {
  GET_ALL_POSTS,
  GET_POST_BY_ID,
  CREATE_POST,
  GET_COMMENTS,
  CREATE_COMMENT,
  DELETE_COMMENT,
  DELETE_POST,
  LIKE_POST,
  LIKE_COMMENT,
  QUEUE_FOR_POST,
  QUEUE_FOR_COMMENT,
  SEARCH_AND_FILTER,
  CLEAR_SEARCH_AND_FILTER,
  CREATE_POLL_POST,
  POLL_EXPIRE_POST_DATA,
  UPDATE_POLL_POST,
  NOTIFICATION_POST_DATA,
  NOTIFICATION_COMMENT_DATA,
} from '../constants';

const initialState = {
  allPosts: {
    response: {
      list: [],
      meta: {},
    },
    isLoading: false,
  },
  unloadedPosts: [],
  commentsByPost: {
    response: {},
    isLoading: false,
  },
  flashcardImageUser: {},
  sendComment: {
    response: null,
    isLoading: 0,
  },
  sendPost: {
    response: null,
    isLoading: 0,
  },
  deleteComment: {
    response: null,
    isLoading: 0,
  },
  deletePost: {
    response: null,
    isLoading: 0,
  },
  likePost: {
    response: {},
    isLoading: 0,
  },
  postsFiltered: null,
  likeComment: {
    response: {},
    isLoading: 0,
  },
  queueForPost: {},
  queueForComment: {},
  createPollPost: {
    response: {},
    isLoading: false,
  },
  pollExpirePost: {},
  updatePollPost: {
    response: {},
    isLoading: false,
  },
  notificationPostData: {
    response: null,
    isLoading: 0,
  },
  notificationCommentData: {
    response: null,
    isLoading: 0,
  },
  error: {},
};

export default function posts(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_POSTS.LOADING:
      return {
        ...state,
        allPosts: {
          ...state.allPosts,
          isLoading: action.payload.isLoading,
        },
      };
    case GET_ALL_POSTS.SUCCESS:
      return {
        ...state,
        allPosts: {
          ...state.allPosts,
          response: {
            ...state.allPosts.response,
            list:
              action.payload.start === 0
                ? action.payload.list
                : [...state.allPosts.response.list, ...action.payload.list],
            meta: action.payload.meta ? action.payload.meta : state.allPosts.response.meta,
          },
        },
      };
    case GET_ALL_POSTS.FAILURE:
      return {
        ...state,
        error: { allPosts: action.payload },
      };
    case SEARCH_AND_FILTER.REQUEST:
      return {
        ...state,
        postsFiltered: action.payload,
      };
    case CLEAR_SEARCH_AND_FILTER.REQUEST:
      return {
        ...state,
        postsFiltered: null,
      };
    case GET_POST_BY_ID.SUCCESS:
      return {
        ...state,
        unloadedPosts:
          state.unloadedPosts.findIndex(o => o.id === action.payload.id) === -1
            ? [...state.unloadedPosts, ...action.payload]
            : state.unloadedPosts,
      };
    case GET_POST_BY_ID.FAILURE:
      return {
        ...state,
        error: { allPosts: action.payload },
      };
    case CREATE_COMMENT.LOADING:
      return {
        ...state,
        sendComment: {
          ...state.sendComment,
          isLoading: action.payload.isLoading,
        },
      };
    case CREATE_COMMENT.SUCCESS:
      return {
        ...state,
        sendComment: {
          ...state.sendComment,
          response: action.payload,
        },
      };
    case CREATE_COMMENT.FAILURE:
      return {
        ...state,
        error: { sendComment: action.payload },
      };
    case LIKE_COMMENT.LOADING:
      return {
        ...state,
        likeComment: {
          ...state.likeComment,
          isLoading: action.payload.isLoading,
        },
      };
    case LIKE_COMMENT.SUCCESS:
      return {
        ...state,
        likeComment: {
          ...state.likeComment,
          response: {
            ...state.likeComment.response,
            [action.payload.commentId]: action.payload.action,
          },
        },
      };
    case LIKE_COMMENT.FAILURE:
      return {
        ...state,
        error: { likeComment: action.payload },
      };
    case LIKE_POST.LOADING:
      return {
        ...state,
        likePost: {
          ...state.likePost,
          isLoading: action.payload.isLoading,
        },
      };
    case LIKE_POST.SUCCESS:
      return {
        ...state,
        likePost: {
          ...state.likePost,
          response: {
            ...state.likePost.response,
            ...action.payload,
          },
        },
      };
    case LIKE_POST.FAILURE:
      return {
        ...state,
        error: { likePost: action.payload },
      };
    case DELETE_COMMENT.LOADING:
      return {
        ...state,
        deleteComment: {
          ...state.deleteComment,
          isLoading: action.payload.isLoading,
        },
      };
    case DELETE_COMMENT.SUCCESS:
      return {
        ...state,
        deleteComment: {
          ...state.deleteComment,
          reponse: action.payload,
        },
      };
    case DELETE_COMMENT.FAILURE:
      return {
        ...state,
        error: { deleteComment: action.payload },
      };
    case DELETE_POST.LOADING:
      return {
        ...state,
        deletePost: {
          ...state.deletePost,
          isLoading: action.payload.isLoading,
        },
      };
    case DELETE_POST.SUCCESS:
      return {
        ...state,
        deletePost: {
          ...state.deletePost,
          reponse: action.payload,
        },
      };
    case QUEUE_FOR_POST:
      return {
        ...state,
        queueForPost: {
          ...state.queueForPost,
          [action.payload.postId]: action.payload.reaction,
        },
      };
    case POLL_EXPIRE_POST_DATA:
      return {
        ...state,
        pollExpirePost: action.payload,
      };
    case QUEUE_FOR_COMMENT:
      return {
        ...state,
        queueForComment: {
          ...state.queueForComment,
          [action.payload.commentId]: action.payload.reaction,
        },
      };
    case DELETE_POST.FAILURE:
      return {
        ...state,
        error: { deletePost: action.payload },
      };
    case CREATE_POST.LOADING:
      return {
        ...state,
        sendPost: {
          ...state.sendPost,
          isLoading: action.payload.isLoading,
        },
      };
    case CREATE_POST.SUCCESS:
      return {
        ...state,
        sendPost: {
          ...state.sendPost,
          response: action.payload,
        },
      };
    case CREATE_POST.FAILURE:
      return {
        ...state,
        error: { sendPost: action.payload },
      };
    case GET_COMMENTS.LOADING:
      return {
        ...state,
        commentsByPost: {
          ...state.commentsByPost,
          isLoading: action.payload.isLoading,
        },
      };
    case GET_COMMENTS.SUCCESS:
      return {
        ...state,
        commentsByPost: {
          ...state.commentsByPost,
          response:
            action.payload === 'clear'
              ? {}
              : {
                ...state.commentsByPost.response,
                [action.payload.id]: {
                  ...state.commentsByPost.response[action.payload.id],
                  list:
                      action.payload.start === 0
                        ? action.payload.list
                        : [...state.commentsByPost.response[action.payload.id].list, ...action.payload.list],
                  meta: action.payload.meta,
                },
              },
        },
      };
    case GET_COMMENTS.FAILURE:
      return {
        ...state,
        error: { commentsByPost: action.payload },
      };
    case CREATE_POLL_POST.REQUEST:
      return {
        ...state,
        createPollPost: {
          ...state.createPollPost,
          isLoading: true,
        },
        sendPost: {
          ...state.sendPost,
          isLoading: true,
        },
      };
    case CREATE_POLL_POST.SUCCESS:
      return {
        ...state,
        createPollPost: {
          ...state.createPollPost,
          isLoading: false,
          response: action.payload,
        },
        sendPost: {
          ...state.sendPost,
          isLoading: false,
        },
      };
    case CREATE_POLL_POST.FAILURE:
      return {
        ...state,
        createPollPost: {
          ...state.createPollPost,
          isLoading: false,
        },
        sendPost: {
          ...state.sendPost,
          isLoading: false,
        },
        error: { createPollPost: action.payload },
      };
    case UPDATE_POLL_POST.REQUEST:
      return {
        ...state,
        updatePollPost: {
          ...state.createPollPost,
          isLoading: true,
        },
        sendPost: {
          ...state.sendPost,
          isLoading: true,
        },
      };
    case UPDATE_POLL_POST.SUCCESS:
      return {
        ...state,
        updatePollPost: {
          ...state.createPollPost,
          isLoading: false,
          response: action.payload,
        },
        sendPost: {
          ...state.sendPost,
          isLoading: false,
        },
      };
    case UPDATE_POLL_POST.FAILURE:
      return {
        ...state,
        updatePollPost: {
          ...state.createPollPost,
          isLoading: false,
        },
        sendPost: {
          ...state.sendPost,
          isLoading: false,
        },
        error: { createPollPost: action.payload },
      };
    case NOTIFICATION_POST_DATA.LOADING:
      return {
        ...state,
        notificationPostData: {
          ...state.notificationPostData,
          isLoading: action.payload.isLoading,
        },
      };
    case NOTIFICATION_POST_DATA.SUCCESS:
      return {
        ...state,
        notificationPostData: {
          ...state.notificationPostData,
          response: action.payload,
        },
      };
    case NOTIFICATION_POST_DATA.FAILURE:
      return {
        ...state,
        error: { notificationPostData: action.payload },
      };
    case NOTIFICATION_COMMENT_DATA.LOADING:
      return {
        ...state,
        notificationCommentData: {
          ...state.notificationCommentData,
          isLoading: action.payload.isLoading,
        },
      };
    case NOTIFICATION_COMMENT_DATA.SUCCESS:
      return {
        ...state,
        notificationCommentData: {
          ...state.notificationCommentData,
          response: action.payload,
        },
      };
    case NOTIFICATION_COMMENT_DATA.FAILURE:
      return {
        ...state,
        error: { notificationCommentData: action.payload },
      };
    default:
      if (action.type.indexOf('_REQUEST') !== -1) {
        return {
          ...state,
          error: {},
        };
      }
      return state;
  }
}

import './classroom.scss';

import get from 'lodash-es/get';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Column, Row } from '@noon/atom';

import { getSelectedCountry, ROUTE_CLASSROOM_EXIT_TO_PLAYBACK } from '../../constants';
import t from '../../helpers/translate';
import { useClassroomAnalytics } from '../../providers/ClassroomAnalytics';
import EventProvider, { IEventConnectionDetails, NETWORK_PROVIDER } from '../../providers/Event/provider';
import {
  clearClassroom,
  getChatHistory,
  joinClassroom,
  loadTeamQna,
  resetLastSessionData,
  startHeartbeat,
  stopHeartbeat,
} from '../../redux/actions/classroom';
import { getSlides } from '../../redux/actions/whiteboard';
import { SET_LOCAL_DEVICE_DETAILS } from '../../redux/constants';
import { addToast, TOAST_TYPE } from '../Toast';
import ClassroomHeader from './classroomHeader';
import ClassroomSidebar from './classroomSidebar';
import CoachmarkContainer from './Coachmark/coachmarkContainer';
import eventListener from './eventListener';
import EventManager from './eventManager';
import { IChannelPropDepedency } from './events';
import ImageToQuestionContainer from './imageToQuestionContainer';
import PrecallTestContainer from './PrecallTest/precall-container';
import SlideContainer from './Slide/slideContainer';
import VideoCard from './Video/VideoCard';
import WebrtcContainer, { IWebrtcConnectionDetails, WEBRTC_PROVIDER } from './WebrtcContainer';
import WhiteboardContainer from './Whiteboard/whiteboardContainer';
import { Sprig, sprigEventTeacherSessionEnd } from '../../helpers/sprig';
import { CreateQuestionModal } from '../MyLibrary/create-question-modal';
import { generateTags } from '../../helpers/generateTags';
import curriculumTypes from '../../helpers/curriculumTypes';
import { toggleCreateQuestionModal } from '../../redux/actions/content';

type IClassroomContainerProps = {
  id: string;
};

const ClassroomContainer = ({ id }: IClassroomContainerProps) => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const dispatchAnalytics = useClassroomAnalytics();

  const [dynamicChannelValues, setdynamicChannelValues] = useState<IChannelPropDepedency>({
    sessionId: '',
    userId: '',
    canvasId: '',
  });

  const sessionDetails = useSelector((state) => state.toJS().myClassroom.sessionDetails);
  const localDevices = useSelector((state) => state.toJS().myClassroom.localDevices);
  const agoraRtcDetails = useSelector((state) => state.toJS().myClassroom.agoraRtcDetails);
  const agoraRtmDetails = useSelector((state) => state.toJS().myClassroom.agoraRtmDetails);
  const pubnubDetails = useSelector((state) => state.toJS().myClassroom.pubnubDetails);
  const lastSessionId = useSelector((state) => state.toJS().myClassroom.lastSessionId);
  const needToDisconnect = useSelector((state) => state.toJS().myClassroom.needToDisconnect);
  const error = useSelector((state) => state.toJS().myClassroom.error);
  const slides = useSelector((state) => state.toJS().whiteboard.slides);
  const activeSlideIndex = useSelector((state) => state.toJS().whiteboard.activeSlideIndex);
  const user = useSelector((state) => state.toJS().user.loggedUser);
  const disableWebrtc = useSelector((state) => state.toJS().webrtc.disableWebrtc);
  const {
    createQuestion: { showCreateQuesitonModal },
  } = useSelector((state) => state.toJS().content);

  const videoStatus = useSelector((state) => state.toJS().webrtc.videoStatus);
  const enableWebrtc = sessionDetails.state === 'started' && !disableWebrtc;

  const webrtcConfig = useMemo(() => {
    const country = getSelectedCountry();
    const { audio_config = {} } = country;

    return {
      enableStats: true, // RTC audio stats
      enableProxy: audio_config.enable_proxy,
      useLatest: audio_config.use_latest,
      serverRegion: audio_config.server_region,
      autoEnableMicOnStart: true,
    };
  }, []);

  const socketConnectionDetails: IEventConnectionDetails = {
    [NETWORK_PROVIDER.PUBNUB]: pubnubDetails,
    [NETWORK_PROVIDER.AGORA]: agoraRtmDetails,
  };

  const webrtcConnectionDetails: IWebrtcConnectionDetails = {
    [WEBRTC_PROVIDER.AGORA]: agoraRtcDetails,
  };

  const handleCloseClassroom = () => {
    const secsSinceStart = (Date.now() - sessionDetails.start_time) / 1000;

    // Sprig event
    if (sessionDetails.state !== 'scheduled') {
      setTimeout(() => {
        sprigEventTeacherSessionEnd();
      }, 2000);
    }

    if (ROUTE_CLASSROOM_EXIT_TO_PLAYBACK && sessionDetails.state !== 'scheduled' && secsSinceStart > 120) {
      history.push(`/${params.locale}/play/${sessionDetails.id}?fromClass`);
      return;
    }
    if (sessionDetails?.group?.id) {
      history.push(`/${params.locale}/groups/${sessionDetails?.group?.id}`);
    } else {
      history.push(`/${params.locale}/schedule`);
    }
    localStorage.removeItem('classroomDetails');
  };

  const handleWebrtcStats = (payload) => {
    dispatchAnalytics('stats', payload);
  };

  const setLocalDeviceDetails = async () => {
    if (
      !navigator ||
      !navigator.mediaDevices ||
      !navigator.mediaDevices.enumerateDevices ||
      !navigator.mediaDevices.getUserMedia
    ) {
      return;
    }
    const devices = await navigator.mediaDevices.enumerateDevices();
    const microphones = devices.filter((device) => device.kind === 'audioinput' && device.deviceId);
    const cameras = devices.filter((device) => device.kind === 'videoinput' && device.deviceId);

    dispatch({
      type: SET_LOCAL_DEVICE_DETAILS,
      payload: {
        microphones,
        cameras,
        selected: {
          camera: cameras.find((device) => device.deviceId === 'default') || get(cameras, [0]),
          microphone: microphones.find((device) => device.deviceId === 'default') || get(microphones, [0]),
        },
      },
    });
  };

  // get session details
  useEffect(() => {
    if (id !== lastSessionId) {
      // reset data for old session
      dispatch(resetLastSessionData());
    }
    if (id) {
      dispatch(joinClassroom(id));
      dispatch(getSlides(id));
    }
    return () => {
      dispatch(clearClassroom());
      dispatch(stopHeartbeat());
    };
  }, []);

  useEffect(() => {
    if (sessionDetails.id) {
      dispatch(startHeartbeat(sessionDetails.id, sessionDetails.heartbeat_duration));
      // set values use in dynamic channels
      if (!dynamicChannelValues?.sessionId) {
        setdynamicChannelValues({
          sessionId: sessionDetails.id,
          userId: user.id,
          canvasId: null,
        });
      }

      // fetch Chat history after class started
      if (sessionDetails.state === 'started') {
        dispatch(getChatHistory(sessionDetails.id));
      }
      setLocalDeviceDetails();
      if (sessionDetails.is_video_enabled) {
        dispatch({
          type: SET_LOCAL_DEVICE_DETAILS,
          payload: {
            isCameraWorking: true,
          },
        });
      }

      // Set sprig session attributes
      Sprig.setAttributes({
        country: sessionDetails.curriculum_tags.country?.name,
        board: sessionDetails.curriculum_tags.board?.name,
        is_premium: sessionDetails.group?.is_premium,
      });
    }
  }, [sessionDetails]);

  useEffect(() => {
    if (slides[activeSlideIndex]?.canvas_id && slides[activeSlideIndex].canvas_id !== dynamicChannelValues.canvasId) {
      setdynamicChannelValues((prevState) => ({ ...prevState, canvasId: slides[activeSlideIndex].canvas_id }));
    }
  }, [slides, activeSlideIndex]);

  useEffect(() => {
    if (error.initClassroom) {
      if (error.initClassroom === 'error.sessionNotStartedTeacher') addToast('Classroom ended', TOAST_TYPE.ERROR);
      handleCloseClassroom();
    }
  }, [error]);

  // If teacher open another tab or open classsroom from another device, close first one
  useEffect(() => {
    if (needToDisconnect) {
      addToast(t('classroom', 'needToDisconnect'), TOAST_TYPE.ERROR, 5);
      setTimeout(() => {
        handleCloseClassroom();
      }, 5000);
    }
  }, [needToDisconnect]);

  // load team qna breakout data
  useEffect(() => {
    dispatch(loadTeamQna());

    return () => {
      dispatch(toggleCreateQuestionModal(false));
    }
  }, []);

  return (
    <EventProvider
      connectionDetails={socketConnectionDetails}
      dynamicChannelValues={dynamicChannelValues}
      eventListener={eventListener}
    >
      <EventManager user={user} dynamicChannelValues={dynamicChannelValues} />

      {enableWebrtc && (
        <WebrtcContainer
          connectionDetails={webrtcConnectionDetails}
          onListenStats={handleWebrtcStats}
          setLocalDeviceDetails={setLocalDeviceDetails}
          config={{ ...webrtcConfig, video: sessionDetails.is_video_enabled }}
        />
      )}

      {sessionDetails.id && (
        <PrecallTestContainer
          connectionDetails={webrtcConnectionDetails}
          onCloseClassroom={handleCloseClassroom}
          setLocalDeviceDetails={setLocalDeviceDetails}
        />
      )}

      <Column nowrap className="my-classroom">
        <ClassroomHeader onCloseClassroom={handleCloseClassroom} />
        <Row className="my-classroom__body">
          <ClassroomSidebar />
          <Column nowrap className="my-classroom__main">
            <SlideContainer />
            <WhiteboardContainer />
          </Column>
        </Row>
        {sessionDetails.is_video_enabled && localDevices.isCameraWorking && <VideoCard videoStatus={videoStatus} />}
      </Column>

      <ImageToQuestionContainer />
      <CoachmarkContainer />
      {showCreateQuesitonModal && (
        <CreateQuestionModal groupId={sessionDetails?.group?.id} selectedCurriculum={sessionDetails.curriculum_tags} />
      )}
    </EventProvider>
  );
};

export default ClassroomContainer;

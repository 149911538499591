import React, { Component } from 'react';
import { Container, Draggable } from 'react-smooth-dnd';
import PropTypes from 'prop-types';
import { IconLoader, IconPlus, IconWrong } from '@noon/quark';
import classNames from 'classnames';
import DeleteSlideConfirmationModal from './DeleteSlideConfirmationModal';
import { isRTL } from '../../constants';

export default class ClassSlides extends Component {

  constructor(props) {
    super(props);
    this.state = { deleteConfirmationModalOpen: false };
  }

  componentDidMount() {
    this.wrapper = document.getElementById('slideWrapper');
  }

  openConfirmationModal = (n) => {
    const { isSlideLoading, isSlideAdding } = this.props;
    if (isSlideLoading || isSlideAdding) return;
    this.setState({ deleteConfirmationModalOpen: true, slideNumberToBeDeleted: n });
  }

  cancelConfirmationModal = () => {
    this.setState({ deleteConfirmationModalOpen: false, slideNumberToBeDeleted: -1 });
  }

  confirmSlideDelete = () => {
    const { slideNumberToBeDeleted } = this.state;
    const { deleteSlide } = this.props;
    this.setState((prevState) => {
      if (slideNumberToBeDeleted >= 0) {
        deleteSlide(prevState.slideNumberToBeDeleted);
      }
      return { deleteConfirmationModalOpen: false, slideNumberToBeDeleted: -1 };
    });
  }

  onHandleDrop = ({ removedIndex, addedIndex }) => {
    if (removedIndex === addedIndex) return;
    const { moveSlide, isSlideLoading, isSlideAdding } = this.props;
    if (isSlideLoading || isSlideAdding) return;
    moveSlide(removedIndex, (addedIndex < removedIndex) ? addedIndex - 1 : addedIndex);
  };

  onAddSlide = (type, resource, prevCanvasIndex) => {
    const { addSlide, isSlideLoading, isSlideAdding } = this.props;
    if (isSlideLoading || isSlideAdding) return;
    addSlide(type, resource, prevCanvasIndex);
  }

  render() {
    const { deleteConfirmationModalOpen } = this.state;
    const { slides, activeSlide, selectSlide, isSlideLoading, options, isSlideAdding } = this.props;
    const { allowDelete, disableAddSlide, disableSelectSlide } = options;
    const allowMoveSlide = allowDelete && !isRTL() && !(isSlideLoading || isSlideAdding);

    const slideItemRender = (slide, index) => (
      <>
        <div
          className={classNames('slide', { active: index === activeSlide })}
          onClick={() => (disableSelectSlide ? {} : selectSlide(index))}
        >
          {allowDelete && (
            <IconWrong
              title="delete"
              role="button"
              tabIndex="-1"
              onClick={(e) => {
                e.stopPropagation();
                this.openConfirmationModal(index);
              }}
            />
          )}
          {isSlideLoading === slide.canvas_id ? <IconLoader height="20" /> : slide.slideIndex}
        </div>
        {!disableAddSlide && (
          <div className={classNames('add-slide', { loading: isSlideAdding === slide.canvas_id })} onClick={() => this.onAddSlide(null, null, index)}>
            {isSlideAdding === slide.canvas_id ? <IconLoader /> : <IconPlus />}
          </div>
        )}
      </>
    );
    return (
      <div className="whiteboard-slides">
        {deleteConfirmationModalOpen && (
          <DeleteSlideConfirmationModal
            cancelConfirmationModal={this.cancelConfirmationModal}
            confirmDelete={this.confirmSlideDelete}
          />
        )}
        {allowMoveSlide ? (
          <Container orientation="horizontal" onDrop={this.onHandleDrop}>
            {slides.map((slide, index) => (
              <Draggable key={slide.canvas_id}>
                {slideItemRender(slide, index)}
              </Draggable>
            ))}
          </Container>
        ) : (
          <React.Fragment>
            {slides.map((slide, index) => (
              <div className="slide-wrapper" key={slide.canvas_id}>
                {slideItemRender(slide, index)}
              </div>
            ))}
          </React.Fragment>
        )}
        {!disableAddSlide && !slides.length && (
          <div className="add-slide" onClick={() => this.onAddSlide()}>
            {isSlideAdding ? <IconLoader /> : <IconPlus />}
          </div>
        )}
      </div>
    );
  }
}

ClassSlides.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.any).isRequired,
  activeSlide: PropTypes.number.isRequired,
  addSlide: PropTypes.func.isRequired,
  selectSlide: PropTypes.func.isRequired,
  deleteSlide: PropTypes.func,
  isSlideLoading: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isSlideAdding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  moveSlide: PropTypes.func.isRequired,
  options: PropTypes.shape({
    allowDelete: PropTypes.bool,
    disableAddSlide: PropTypes.bool,
    disableSelectSlide: PropTypes.bool,
  }),
};

ClassSlides.defaultProps = {
  deleteSlide: null,
  isSlideLoading: 0,
  isSlideAdding: 0,
  options: {
    allowDelete: false,
    disableAddSlide: false,
    disableSelectSlide: false,
    slideNumberToBeDeleted: -1,
  },
};

import React from 'react';
import PropTypes from 'prop-types';
import { Row } from '@noon/atom';

function SelectCountryWithFlag({ name, flag }) {
  return (
    <Row nowrap align="center" justify="space-between">
      <span>
        {name}
        {' '}
      &nbsp;
      </span>
      <img src={flag} height="15px" alt={name} />
    </Row>
  );
}

SelectCountryWithFlag.propTypes = {
  name: PropTypes.string,
  flag: PropTypes.string,
};

SelectCountryWithFlag.defaultProps = {
  name: '',
  flag: '',
};

export default SelectCountryWithFlag;

import { createSelector } from 'reselect';

const folderSelector = state => state.toJS().folder;

const productsSelector = createSelector(
  folderSelector,
  items => items.products,
);

const countrySelector = createSelector(
  folderSelector,
  items => items.countries
);

const productsByCountrySelector = createSelector(
  folderSelector,
  items => items.productsByCountry,
);

const selectProductFromProductId = createSelector(
  productsSelector,
  (_, productId) => productId,
  (products, productId) => {
    const product = products.find(item => item.id === productId);
    return product || {};
  },
);

const productByCountryIdSelector = createSelector(
  productsByCountrySelector,
  (_, options) => options,
  (productsByCountry, options) => {
    const products = productsByCountry[options.countryId] || [];
    return products.find(item => item.id === options.productId) || {};
  },
);

const folderListSelector = createSelector(
  folderSelector,
  items => items.folderList,
);

const gradesSelector = createSelector(
  folderListSelector,
  items =>
    (items.filter(item => item.slug === 'grades')[0] ? items.filter(item => item.slug === 'grades')[0].nodes : []),
);

const selecGradeFromGradeId = createSelector(
  gradesSelector,
  (_, gradeId) => gradeId,
  (gradeList, gradeId) => {
    const computedGrade = gradeList.filter(item => item && item.slug && item.slug.indexOf(gradeId) !== -1)[0];
    return computedGrade || {};
  },
);

const semesterSelector = createSelector(
  folderListSelector,
  items =>
    (items.filter(item => item.slug === 'semester')[0] ? items.filter(item => item.slug === 'semester')[0].nodes : []),
);

const sectionSelector = createSelector(
  folderListSelector,
  items => (items.filter(item => item.level === 1)[0] ? items.filter(item => item.level === 1)[0].nodes : []),
);

const chaptersSelector = createSelector(
  folderListSelector,
  items =>
    (items.filter(item => (items.length === 3 ? item.level === 2 : item.level === 3))[0]
      ? items.filter(item => (items.length === 3 ? item.level === 2 : item.level === 3))[0].nodes
      : []),
);

const lessonsSelector = createSelector(
  folderListSelector,
  items =>
    (items.filter(item => (items.length === 3 ? item.level === 3 : item.level === 4))[0]
      ? items.filter(item => (items.length === 3 ? item.level === 3 : item.level === 4))[0].nodes
      : []),
);

const semesterByGradeIdSelector = createSelector(
  gradesSelector,
  semesterSelector,
  (_, gradeId) => gradeId,
  (gradeList, semesters, gradeId) =>
    (semesters.length > 0 ? semesters.filter(item => Number(item.parent.slice(0, -1)) === gradeId) : []),
);

export {
  folderListSelector,
  productsSelector,
  selectProductFromProductId,
  gradesSelector,
  selecGradeFromGradeId,
  semesterSelector,
  chaptersSelector,
  lessonsSelector,
  sectionSelector,
  semesterByGradeIdSelector,
  countrySelector,
  productByCountryIdSelector,
};

import { GET_CAMPAIGN_BANNER, GET_REFERRAL_LINK } from '../constants';

const initialState = {
  referralLinkData: {},
  campaignBanner: {
    list: [],
    loading: false,
  },
  error: {},
};

export default function promotion(state = initialState, action) {
  switch (action.type) {
    case GET_REFERRAL_LINK.REQUEST:
      return {
        ...state,
        error: {},
      };
    case GET_REFERRAL_LINK.SUCCESS:
      return {
        ...state,
        referralLinkData: action.payload,
      };
    case GET_REFERRAL_LINK.FAILURE:
      return {
        ...state,
        error: { referralLinkData: action.payload },
      };
    case GET_CAMPAIGN_BANNER.REQUEST:
      return {
        ...state,
        campaignBanner: {
          list: [],
          loading: true,
        },
      };
    case GET_CAMPAIGN_BANNER.SUCCESS:
      return {
        ...state,
        campaignBanner: {
          list: action.payload,
          loading: false,
        },
      };
    case GET_CAMPAIGN_BANNER.FAILURE:
      return {
        ...state,
        campaignBanner: {
          list: [],
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}

import React from 'react';
import { IconLandscapeMode } from '@noon/quark';
import { translationText } from '../../helpers';

export default function RotateToLandscapePlaceholder(prop) {
  return (
    <div className="mobile-portrait-warning">
      {/* landscape mode */}
      <IconLandscapeMode fill="#fff" width="200px" />
      <p>{translationText(prop.noonText, 'classroom.screenRotateMsg')}</p>
    </div>
  );
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card, Row, Button, Input, Column } from '@noon/atom';
import { IconFemale, IconMale } from '@noon/quark';
import { connect } from 'react-redux';
import {
  CREATE_TEMPORARY_ACCESS_TOKEN,
  SIGNUP_TEACHER,
  TEMPORARY_TOKEN_PAYLOAD,
  GO_TO_LOGIN,
  RESET_VERIFY_OTP,
} from '../../redux/constants';
import { getSelectedCountry, EMAIL_PATTERN, getCountryId, COLORS } from '../../constants';
import NavigationMenu from '../../components/NavigationMenu';

import { translationText, clearError, setAuthorizationHeader } from '../../helpers';
import { translationType, errorType } from '../../types';

const uuidv4 = require('uuid/v4');

const form_order = ['gender', 'name', 'email'];

export class SignupForm extends Component {
  static propTypes = {
    noonText: translationType.isRequired,
    userError: errorType.isRequired,
    resetVeryOtp: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    /* eslint-disable react/prop-types */
    const selectedCountry = getSelectedCountry();
    this.state = {
      user: {
        name: '',
        email: '',
        gender: '',
        country_code: `${selectedCountry.calling_code}`,
        country_id: selectedCountry.id,
      },
      formErrors: {
        name: '',
        email: '',
        gender: '',
      },
      genderList: [
        {
          name: 'male',
          label: 'form.male',
          icon: IconMale,
        },
        {
          name: 'female',
          label: 'form.female',
          icon: IconFemale,
        },
      ],
      isLoading: false,
    };
    if (!props.otpVerified) {
      props.history.push(`/${localStorage.updatedLocale}/login`);
    }
  }

  componentWillUnmount() {
    clearError('user');
  }

  componentDidUpdate(prevProps) {
    const { isSignupLoading, createTemporaryAccessTokenLoading } = this.props;
    if (isSignupLoading !== prevProps.isSignupLoading && !isSignupLoading) {
      this.setState({ isLoading: false });
    }
    if (
      createTemporaryAccessTokenLoading !== prevProps.createTemporaryAccessTokenLoading &&
      !createTemporaryAccessTokenLoading
    ) {
      this.setState({ isLoading: false });
    }
  }

  handleUserInput = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({ user: { ...prevState.user, [name]: value } }), () => {
      this.validateField(name, value);
    });
  };

  validateField(name, value) {
    const fieldValidationErrors = this.state.formErrors;
    let emailValid;

    switch (name) {
      case 'gender':
        fieldValidationErrors.gender = value.length ? '' : 'validation.genderRequired';
        break;
      case 'name':
        fieldValidationErrors.name = value.length ? '' : 'validation.nameRequired';
        break;
      case 'email':
        emailValid = EMAIL_PATTERN.test(value);
        fieldValidationErrors.email = emailValid
          ? ''
          : value.length
            ? 'error.invalidEmail'
            : 'validation.emailRequired';
        break;
      default:
        break;
    }
    this.setState({ formErrors: fieldValidationErrors });
  }

  validateForm() {
    // agreeTerms
    const { formErrors, user } = this.state;

    let dupFormErrors = formErrors;
    if (
      user.name === '' ||
      user.email === '' ||
      user.gender === ''
    ) {
      dupFormErrors = {
        ...dupFormErrors,
        ...(user.name === '' && { name: 'validation.nameRequired' }),
        ...(user.email === '' && { email: 'validation.emailRequired' }),
        ...(user.gender === '' && { gender: 'validation.genderRequired' }),
      };
      this.setState({
        formErrors: dupFormErrors,
      }, () => {
        this.FocusOnError();
      });
    }

    if (
      dupFormErrors.gender === '' &&
      dupFormErrors.name === '' &&
      dupFormErrors.email === ''
    ) {
      return true;
    }
    return false;
  }

  FocusOnError=() => {
    for (const el of form_order) {
      if (this.state.formErrors[el] === '') {
        document.getElementById(el).focus();
        break;
      }
    }
    return null;
  }

  submitForm = (ev) => {
    if (this.state.isloading) return;
    ev.preventDefault();
    const { signupTeacher } = this.props;
    const { user } = this.state;
    if (!this.validateForm()) return;
    if (this.checkTokenValidity()) {
      signupTeacher(user);
      this.setState({ isLoading: true });
      localStorage.firstSingup = true;
      localStorage.showFirstCoachmark = true;
      delete localStorage.lastUrl;
    }
  };

  createTemporaryAccessToken() {
    const { temporaryTokenPayload } = this.props;
    let dummyPayload = temporaryTokenPayload;
    if (!temporaryTokenPayload) {
      dummyPayload = {
        tenant: 'teacher',
        country: getCountryId(),
        os: 'web',
        device_id: uuidv4(),
      };
      this.props.temporaryTokenPayloadCreation(dummyPayload);
      localStorage.temporaryTokenPayload = JSON.stringify(dummyPayload);
    }
    this.props.createTemporaryAccessToken({ json: temporaryTokenPayload });
  }

  checkTokenValidity() {
    const { tokenData, temporaryTokenData, serverTime, timeDifference } = this.props;
    if (tokenData) {
      if (tokenData.expiry > (new Date().getTime() + timeDifference)) {
        setAuthorizationHeader(tokenData.a_tkn.tkn);
        return true;
      }
    } else if (temporaryTokenData) {
      if (temporaryTokenData.valid_till > (new Date().getTime() + timeDifference)) {
        setAuthorizationHeader(temporaryTokenData.token);
        return true;
      }
      this.createTemporaryAccessToken();
      return false;
    } else {
      this.createTemporaryAccessToken();
      return false;
    }
    return false;
  }

  selectGender = (gender) => {
    this.setState({
      user: { ...this.state.user, gender: gender.name },
    }, () => {
      this.validateField('gender', gender.name);
    });
  };

  gerErrorMessage = (error) => {
    const { noonText } = this.props;
    let message = '';
    if (error.error_code) {
      switch (error.error_code) {
        case 'DUPLICATE_EMAIL':
          message = translationText(noonText, 'error.emailAlreadyExists');
          break;
        default:
          message = translationText(noonText, 'onboarding.genericError');
          break;
      }
    }
    return message;
  };

  takeBackToLogin = () => {
    const { resetVeryOtp } = this.props;
    resetVeryOtp();
    this.props.history.push(`/${localStorage.updatedLocale}/login`);
  }

  render() {
    const { noonText } = this.props;
    const {
      formErrors,
      user,
      genderList,
      isLoading,
    } = this.state;

    return (
      <div className="login-form mt-4">
        <Row justify="center" className="mb-2">
          <h3 className="text-center">{translationText(noonText, 'teacherDashboard.signupHeading')}</h3>
        </Row>

        <form noValidate onSubmit={this.submitForm}>
          <div className="gender">
            <p className="gender--label">{translationText(noonText, 'teacherDashboard.gender')}</p>
            <Card className={classNames('gender-profile mb-1', { 'error-gender': !!formErrors.gender })} id="gender" >
              <NavigationMenu
                seperator
                items={genderList}
                noonText={noonText}
                activeTab={genderList.findIndex(o => o.name === user.gender)}
                onSelect={this.selectGender}
            />
            </Card>
          </div>
          {!!formErrors.gender && (
            <p className="text-center text-color-red">{translationText(noonText, formErrors.gender)}</p>
          )}
          <Input
            type="text"
            name="name"
            id="name"
            outlined
            label={translationText(noonText, 'teacherDashboard.full_name')}
            onChange={this.handleUserInput}
            error={formErrors.name}
            className="input-name"
          />
          {!!formErrors.name && <p className="form-error">{translationText(noonText, formErrors.name)}</p>}
          <Input
            type="email"
            name="email"
            id="email"
            outlined
            label={translationText(noonText, 'form.email')}
            onChange={this.handleUserInput}
            error={formErrors.email}
          />
          {!!formErrors.email && <p className="form-error">{translationText(noonText, formErrors.email)}</p>}
          <Row nowrap className="tos mt-2">
            <span>{translationText(noonText, 'form.tosSection2')}</span>
            <span style={{ cursor: 'pointer', color: COLORS.brand.base, margin: '0 0.5rem' }} onClick={() => this.props.history.push(`/${localStorage.updatedLocale}/tos`)}>{translationText(noonText, 'form.tosSection1')}</span>
          </Row>
          <Column align="center" justify="center" className="submit-button-block mt-4">
            {this.props.userError && this.props.userError.signUpTeacher && (
              <p className="text-color-red">{this.gerErrorMessage(this.props.userError.signUpTeacher)}</p>
            )}
            <Button htmlType="submit" type="green" size="xl" className="lelelel full-width" loading={isLoading} value={translationText(noonText, 'button.continue')} />
          </Column>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  noonText: state.toJS().translation.noonText,
  userError: state.toJS().userv2.error,
  temporaryTokenPayload: state.toJS().biFrost.temporaryTokenPayload,
  tokenData: state.toJS().biFrost.tokenData,
  temporaryTokenData: state.toJS().biFrost.temporaryTokenData,
  isSignupLoading: state.toJS().userv2.signUpTeacher.isLoading,
  createTemporaryAccessTokenLoading: state.toJS().biFrost.createTemporaryAccessToken.isLoading,
  otpVerified: state.toJS().onboarding.otpVerified,
  serverTime: state.toJS().user.serverTime,
  timeDifference: state.toJS().user.timeDifference,
});

const mapDispatchToProps = dispatch => ({
  signupTeacher: user => dispatch({ type: SIGNUP_TEACHER.REQUEST, payload: user }),
  createTemporaryAccessToken: (payload, accessType, user) =>
    dispatch({ type: CREATE_TEMPORARY_ACCESS_TOKEN.REQUEST, payload, accessType, user }),
  temporaryTokenPayloadCreation: payload => dispatch({ type: TEMPORARY_TOKEN_PAYLOAD, payload }),
  resetVeryOtp: () => dispatch({ type: RESET_VERIFY_OTP }),
  goToLogin: payload => dispatch({ type: GO_TO_LOGIN, payload }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignupForm);

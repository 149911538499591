import { useState } from 'react';
import { isEmpty } from 'lodash-es';

function usePlaybackCheckpoints() {
  const [playbackCheckpoints, updatePlaybackCheckpoints] = useState({});

  const roundOffTimestamps = (timestamp) => {
    const roundedTimestamp = Math.round(timestamp / 1000) * 1000;
    return roundedTimestamp;
  };

  // Checkpoints are timestamps at which some action is to be taken by the player
  // By default, there will only be two checkpoints, one at the start of the playback, one at the end
  // When the player in continuous playback mode, it will constantly check for the timestamps,
  // saved in the playbackCheckpoints hashmap to take certain action (seek, play, stop) etc

  // More checkpoints will be added using this function based on whether a particular slide is edited/deleted
  const calculateCheckpoints = (data, dataType) => {
    let payloadIntervals = [];
    if (!dataType) return;

    // While calculating checkpoints for a slide, we are setting the start and end points of the player to slide's
    // start and end points. The player should not play before or beyond this
    if (dataType === 'slide') {
      const { payload: { intervals } } = data;

      // We are adding all intervals to the payload because all the timestamps will be important here,
      // whether the slide was trimmed/edited or not
      payloadIntervals = intervals;
    } else if (dataType === 'everything') {
      const { slides } = data;
      const onlyActiveSlides = slides.filter((slide) => !slide.delete);
      const lastNonDeletedSlideIntervals = onlyActiveSlides[onlyActiveSlides.length - 1]?.payload?.intervals;

      // First, get all the intervals from all the slides of the playback THAT CHANGED
      payloadIntervals = slides.reduce((acc, slide, index) => {
        const value = [];

        // 1635770912905: add timestamp to start the actual playback from based on the first non-deleted slide encountered
        if (isEmpty(acc)
          && onlyActiveSlides[0].canvas_id === slide.canvas_id) value.push({ start_time: slide?.payload?.intervals?.[0].start_time });
        // if (isEmpty(acc)
        //   && !slide.delete
        //   && slide?.payload?.intervals?.length === 1) value.push({ start_time: slide?.payload?.intervals?.[0].start_time });

        // if the slide was edited, add relevant timestamps on which to skip and play from next
        if (slide?.payload?.intervals?.length > 1
          && !slide.delete) {
          slide?.payload?.intervals.forEach((interval, intervalIndex) => {
            if (intervalIndex === slide?.payload?.intervals.length - 1) value.push({ start_time: interval.start_time, ...slides[index + 1].delete && { end_time: interval.end_time } });
            else value.push(interval);
          });
        }
        // if the last item in the intervals list was a timestamp on which to skip, find the not deleted slide on which to next play from
        if (acc.length > 1
          && !!acc[acc.length - 1].end_time
          && slide?.payload?.intervals?.length === 1
          && !slide.delete) value.push({ start_time: slide?.payload?.intervals?.[0].start_time });

        // if the next slide is deleted, add end_time from current one to mark timestamp on which to skip
        if (!slide.delete
          && index + 1 < slides.length
          && slides[index + 1].delete
          && slide?.payload?.intervals?.length === 1) value.push({ end_time: slide?.payload?.intervals?.[0].end_time });

        // add timestamp of the last non-deleted slide end_time to finally stop the playback at
        if (!slide.delete
          && lastNonDeletedSlideIntervals[lastNonDeletedSlideIntervals.length - 1]?.end_time === slide?.payload?.intervals[slide?.payload?.intervals.length - 1]?.end_time) {
          value.push({ end_time: slide?.payload?.intervals[slide?.payload?.intervals.length - 1].end_time });
        }
        return [...acc, ...value];
      }, []);
    }

    let payload;
    if (payloadIntervals.length > 1) {
      for (let i = 0; i < payloadIntervals.length; i++) {
        switch (i) {
          // This is the first entry, it will overwrite the data and set the start timestamp of playback
          case 0:
            payload = {
              ...payloadIntervals?.[i]?.start_time && { [roundOffTimestamps(payloadIntervals?.[i]?.start_time)]: { action: 'play', meta: null } },
              ...payloadIntervals?.[i]?.end_time && { [roundOffTimestamps(payloadIntervals?.[i]?.end_time)]: { action: 'seek', meta: payloadIntervals?.[i + 1]?.start_time } },
            };
            break;
          // Last interval. Just to add an end action to the timestamp if the playback after editing is supposed to end before actual end time
          case payloadIntervals.length - 1:
            payload = {
              ...payload,
              ...payloadIntervals?.[i]?.end_time && { [roundOffTimestamps(payloadIntervals?.[i]?.end_time)]: { action: 'end', meta: null } },
            };
            break;
          // Reading all collected intervals and adding timestamps to the list based on that
          default:
            payload = {
              ...payload,
              ...payloadIntervals?.[i]?.start_time && { [roundOffTimestamps(payloadIntervals?.[i]?.start_time)]: { action: 'play', meta: null } },
              ...payloadIntervals?.[i]?.end_time && { [roundOffTimestamps(payloadIntervals?.[i]?.end_time)]: { action: 'seek', meta: payloadIntervals?.[i + 1]?.start_time } },
            };
        }
      }
    } else {
      // In case the session is not edited at all. Since this will be needed in any case
      payload = {
        [roundOffTimestamps(payloadIntervals?.[0]?.start_time)]: { action: 'play', meta: null },
        [roundOffTimestamps(payloadIntervals?.[0]?.end_time)]: { action: 'stop', meta: null },
      };
    }
    updatePlaybackCheckpoints(payload);

  };
  return [playbackCheckpoints, calculateCheckpoints];
}

export default usePlaybackCheckpoints;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ConfirmationModal } from '../../Modals';
import { GET_TEACHER_ARCHIVE_GROUPS, GET_TEACHER_GROUPS, GET_TEACHER_GROUP_SELECTED, UNARCHIVE_GROUP } from '../../../redux/constants';
import t from '../../../helpers/translate';

function UnArchiveGroupModal({ groupId, archiveGroup, toggleUnArchiveModal }) {
  const dispatch = useDispatch();

  const handleOnclickUnArchiveGroup = () => {
    dispatch({
      type: UNARCHIVE_GROUP.REQUEST,
      payload: { group_id: groupId },
    });
  };

  useEffect(() => {
    if (!archiveGroup.loading && archiveGroup.success) {
      toggleUnArchiveModal();

      dispatch({
        type: GET_TEACHER_GROUP_SELECTED.REQUEST,
        payload: groupId,
      });
      dispatch({
        type: GET_TEACHER_ARCHIVE_GROUPS.REQUEST,
      });
      dispatch({
        type: GET_TEACHER_GROUPS.REQUEST,
      });
    }
  }, [archiveGroup]);

  return (
    <ConfirmationModal
      hideTextIcon
      modalType="warning"
      successBtn={t('button', 'unarchiveNow')}
      closeBtn={t('button', 'unarchiveLater')}
      onClose={toggleUnArchiveModal}
      onSuccess={handleOnclickUnArchiveGroup}
      isLoading={archiveGroup.loading}
      text={t('groups', 'unarchiveGroupModalTitle')}
      subText={t('groups', 'unarchiveGroupModalText')}
    />
  );
}

UnArchiveGroupModal.propTypes = {
  toggleUnArchiveModal: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  archiveGroup: PropTypes.shape({
    loading: PropTypes.bool,
    archived: PropTypes.bool,
    success: PropTypes.bool,
  }).isRequired,
};
export default UnArchiveGroupModal;

/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Joyride, { EVENTS, STATUS, Step, Styles } from 'react-joyride';
import { Column } from '@noon/atom';
import { useSelector } from 'react-redux';
import { translationText } from '../../../helpers';

const QuestionBuilderCoacMark = ({ crops, canRun }) => {
  const isRtl = document.body.dir === 'rtl';
  const { noonText } = useSelector((state) => state.toJS().translation);

  const styles: Styles = {
    options: {
      primaryColor: '#64D8AE',
    },
    tooltipTitle: {
      color: '#62687a',
      fontWeight: 400,
      fontSize: '14px',
    },
    tooltipContainer: {
      textAlign: 'left',
    },
    tooltip: {
      width: '300px',
    },
    buttonClose: {
      display: 'none',
    },

  };

  const locale = { close: translationText(noonText, 'image2question.OnboardingClose') };

  const steps: Step[] = [{
    title: translationText(noonText, 'image2question.OnboardingTitle'),
    target: '.QuestionBuilderComponent',
    content: <Column>
      <p>{translationText(noonText, 'image2question.OnboardingStepOne')}</p>
      <img className="onboarding-gif" alt="onboarding-animation" src={`../../../assets/images/addQuestionFromImage/OnboardingGifs/${isRtl ? 'Arabic' : 'English'}/1.gif`} />
    </Column>,
    disableBeacon: true,
    placement: 'auto',
  },
  {
    title: translationText(noonText, 'image2question.OnboardingTitle'),
    target: '.QuestionBuilderComponent',
    content: <Column>
      <p>{translationText(noonText, 'image2question.OnboardingStepTwo')}</p>
      <img className="onboarding-gif" alt="onboarding-animation" src={`../../../assets/images/addQuestionFromImage/OnboardingGifs/${isRtl ? 'Arabic' : 'English'}/2.gif`} />
             </Column>,
    disableBeacon: true,
    placement: 'auto',
  },
  {
    title: translationText(noonText, 'image2question.OnboardingTitle'),
    target: '.QuestionBuilderComponent',
    content: <Column>
      <p>{translationText(noonText, 'image2question.OnboardingStepThree')}</p>
      <img className="onboarding-gif" alt="onboarding-animation" src={`../../../assets/images/addQuestionFromImage/OnboardingGifs/${isRtl ? 'Arabic' : 'English'}/3.gif`} />
             </Column>,
    disableBeacon: true,
    placement: 'auto',
  },
  {
    title: translationText(noonText, 'image2question.OnboardingTitle'),
    target: '.preview',
    content: translationText(noonText, 'image2question.OnboardingStepFour'),
    disableBeacon: true,
    placement: 'auto',
  }];

  const [step, addStep] = useState(-1);
  const [run, setRun] = useState(false);

  const handleJoyrideCallback = (data) => {
    const { action, index, type, status } = data;
    if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      return false;
    } if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND]).includes(type)) {
      setRun(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {

      if (!canRun) return;
      const questions = crops.filter((c) => c.isQuestion);
      const answers = crops.filter((a) => a.isAnswer);
      const correctAnswer = crops.filter((a) => a.isCorrectAnswer);
      const questionBodySelected = questions.length > 0;
      const answersSelected = answers.length >= 3;
      const correctAnswerSelected = correctAnswer.length > 0;
      
      switch (true) {
        case !questionBodySelected && !answersSelected && !correctAnswerSelected:
          if (step === 0) break;
          addStep(0);
          setRun(true);
          break;
        case questionBodySelected && !answersSelected && !correctAnswerSelected:
          if (step === 1) break;
          addStep(1);
          setRun(true);
          break;
        case questionBodySelected && answersSelected && !correctAnswerSelected:
          if (step === 2) break;
          addStep(2);
          setRun(true);
          break;
        case questionBodySelected && answersSelected && correctAnswerSelected:
          if (step === 3) break;
          addStep(3);
          setRun(true);
          break;
        default: break;
      }

    }, 10);
  }, [crops, canRun]);

  return (
    <Joyride
      steps={steps}
      run={run}
      stepIndex={step}
      showProgress
      styles={styles}
      hideBackButton={true}
      locale={locale}
    //   spotlightClicks={true}
      callback={handleJoyrideCallback}
    />
  );

};

QuestionBuilderCoacMark.propTypes = {
  crops: PropTypes.arrayOf(PropTypes.shape({
    isQuestion: PropTypes.bool,
    isAnswer: PropTypes.bool,
    isCorrectAnswer: PropTypes.bool,
  })),
  canRun: PropTypes.bool,
};

QuestionBuilderCoacMark.defaultProps = {
  crops: [],
  canRun: true,
};

export default QuestionBuilderCoacMark;

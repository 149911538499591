import React from 'react';
import PropTypes from 'prop-types';
import { Row, Column } from '@noon/atom';

function Shimmer({ obj = [] }) {
  // const obj = [
  //   { heading: true },
  //   { box: true, lines: 3 },
  //   { photo: true },
  // ];
  return (
    <Column gap="sm" className="shimmer-container">
      {obj.map((item, index) => {
        const lines = [];
        if (item.lines) {
          for (let i = 0; i < item.lines; i++) {
            lines.push(<div key={i} className="line shine" />);
          }
        }
        return (
          <Row key={index} nowrap gap="sm">
            {!!item.heading && <div className="heading shine" />}
            {!!item.photo && <div className="photo shine" />}
            {!!item.box && <div className="box shine" />}
            {!!item.lines && (
              <Column nowrap gap="sm">
                {lines}
              </Column>
            )}
          </Row>
        );
      })}
    </Column>
  );
}

Shimmer.propTypes = {
  obj: PropTypes.shape({}).isRequired,
};
export default Shimmer;

import { createSelector } from 'reselect';

const breakoutTeamsSelector = state => state.toJS().breakoutTeams;

const teamsSelector = createSelector(
  breakoutTeamsSelector,
  item => item.teams,
);

const teamsIsLoadingSelector = createSelector(
  breakoutTeamsSelector,
  item => item.loading,
);

const teamsOffsetSelector = createSelector(
  breakoutTeamsSelector,
  item => item.offset,
);

const teamsMetaSelector = createSelector(
  breakoutTeamsSelector,
  ({ offset, calculatePercentage, total_teams, last_score, last_rank }) => ({
    offset,
    calculatePercentage,
    total_teams,
    last_score,
    last_rank,
  }),
);
const totalTeamCountsetSelector = createSelector(
  breakoutTeamsSelector,
  item => item.total_teams,
);

export { teamsSelector, teamsOffsetSelector, teamsIsLoadingSelector, totalTeamCountsetSelector, teamsMetaSelector };

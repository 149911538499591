import { createSelector } from 'reselect';

const onboardingSelectorRaw = state => state.toJS().onboarding;

const onboardingSelector = createSelector(
  onboardingSelectorRaw,
  item => item,
);

export { onboardingSelector };

import React from 'react';
import { Column, Row } from '@noon/atom';
import PropTypes from 'prop-types';
import { IconLoader } from '@noon/quark';
import ReportPageBarStudentCard from './ReportPageBarStudentCard';
import { checkIsMobile, checkIsTablet } from '../../helpers/prepareAssignment';
import { translationText } from '../../helpers';

const ReportPageBarStudentsList = (props) => {
  const { students, submitted, loadMore, setLoadMore, studentProfile, studentsLoading, setIsBarOpen, noonText, setIsUserAnswersOpen } = props;

  return (
    <Column
      className="report--bar--students"
      id="wrapper"
      style={{
        height: checkIsMobile() || checkIsTablet()
          ? ''
          : studentProfile.position >= 0 || studentProfile.loading ? 'calc(100% - 345px)' : 'calc(100% - 230px)',
      }}
    >
      {studentsLoading && (!students || !students.data || students.data.length === 0) ? (
        <Row align="center" justify="center" style={{ height: '100%' }}>
          <IconLoader height="90px" width="90px" />
        </Row>
      ) : students && students.data && students.data.length > 0 ? (
        students.data.map((student) => (
          <ReportPageBarStudentCard
            setIsBarOpen={setIsBarOpen}
            submitted={submitted}
            student={student}
            key={student.id}
            setIsUserAnswersOpen={setIsUserAnswersOpen}
          />
        ))) : (
          <div className="prepare-assignment--emptyQuestions">
            <img src="/assets/images/empty-state/empty-students.svg" alt="empty-students" />
            <span>{translationText(noonText, 'homeworkReport.noSubs')}</span>
          </div>
      )}
      {loadMore
        && (
        <button type="submit" className="prepare-assignment--loadMore" onClick={() => setLoadMore(submitted ? 'submitted' : 'notSubmitted')}>
          {translationText(noonText, 'dashboard.loadMore')}
          {studentsLoading && <IconLoader height="16px" width="16px" />}
        </button>
        )}
    </Column>
  );
};

ReportPageBarStudentsList.propTypes = {
  noonText: PropTypes.shape().isRequired,
  students: PropTypes.shape().isRequired,
  studentProfile: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  submitted: PropTypes.bool.isRequired,
  loadMore: PropTypes.bool.isRequired,
  studentsLoading: PropTypes.bool.isRequired,
  setLoadMore: PropTypes.func.isRequired,
  setIsBarOpen: PropTypes.func.isRequired,
  setIsUserAnswersOpen: PropTypes.func.isRequired,
};

export default ReportPageBarStudentsList;

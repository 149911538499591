import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import { translationText } from '../../../helpers';
import { NoonLink } from '@noon/atom';

export default function HomePageBanner(prop) {
  const { noonText, className, history } = prop;
  return (
    <section className={classNames('homepage-banner banner-saudi', className)}>
      <div className="banner-caption">
        <h1 className="caption-heading">
          {ReactHtmlParser(translationText(noonText, 'teacherLanding.bannerHeading'))}
        </h1>
        <h3 className="caption-subheading">
          {ReactHtmlParser(translationText(noonText, 'teacherLanding.bannerSubHeading'))}
        </h3>
        <p className="caption-para">{ReactHtmlParser(translationText(noonText, 'teacherLanding.bannerContent'))}</p>
        <NoonLink
          onClick={() => history.push('/login')}
          type="orange"
          size={isMobileOnly ? 'lg' : 'xl'}
          depth="2"
        >
          {translationText(noonText, 'teacherLanding.register')}
        </NoonLink>
      </div>

      {/* Live sessions */}
      {prop.children}
    </section>
  );
}

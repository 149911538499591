import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { isMobile, isIPad13 } from 'react-device-detect';
import { IconDelete, IconEdit, IconEye, IconLoader, IconPlus, IconQuestion } from '@noon/quark';
import { Column, Row, Button } from '@noon/atom';
import { translationType } from '../../types';
import { translationText } from '../../helpers';
import { TEACHER_QUESTION_LIST, DELETE_TEACHER_QUESTION, GET_TEACHER_TAGS_GROUP } from '../../redux/constants';
import { countrySelector } from '../../redux/selectors/folders';
import { getSelectedCountry } from '../../constants';
import PreviewQuestionModal from '../../components/MyLibrary/question-preview-modal';
import NoonQuestion from '../../components/NoonQuestion/NoonQuestion';
import Mixpanel from '../../components/Mixpanel';
import { getQueryObject } from '../../helpers/getQueryItem';
import { SessionGenericModal } from '../../components/Modals';
import PageHeader from '../../components/Layout/pageHeader';
import ListFilters from '../../components/Library/ListFilters';

class Library extends Component {
  constructor(props) {
    super(props);
    const { country_id } = getQueryObject(props.location.search, '=');
    this.state = {
      showPreviewModal: false,
      showDeleteModal: 0,
      filters: {
        country_id: country_id || getSelectedCountry().id,
      },
    };

    this.difficultyMap = {
      10: translationText(props.noonText, 'library.difficulty1'),
      30: translationText(props.noonText, 'library.difficulty2'),
      50: translationText(props.noonText, 'library.difficulty3'),
      70: translationText(props.noonText, 'library.difficulty4'),
      90: translationText(props.noonText, 'library.difficulty5'),
    };

    // clear params
    if (country_id) {
      props.history.replace({ pathname: props.location.pathname, search: '' });
    }
  }

  componentDidMount() {
    const { getMyQuestionList, teacherQuestions } = this.props;
    const { filters = {} } = this.state;
    getMyQuestionList({ page: 1, limit: teacherQuestions.limit, country_id: filters.country_id, tags: this.getTagsFromFilters(filters) });
  }

  // componentWillReceiveProps({ countries }) {
  //   console.log('countries', countries);
  // }

    handleOnFilterChange = (updatedFilters) => {
      const { getMyQuestionList, teacherQuestions } = this.props;
      this.setState({
        filters: updatedFilters,
      });
      getMyQuestionList({ page: 1, limit: teacherQuestions.limit, country_id: updatedFilters.country_id, text: updatedFilters.text, tags: this.getTagsFromFilters(updatedFilters) });
    };

    trimCurriculumType = (curriculum_type) => {
      // TODO: Improve this piece of code, as condition check is little bit wierd
      const valueArray = curriculum_type?.split('_') || [];
      if (valueArray.length === 1) {
        return curriculum_type;
      } if (valueArray.includes('skill')) {
        return 'skills';
      }
      return 'test_prep';
    }

    getTagsFromFilters = (updatedFilters) => {
      let tags = '';
      // if (updatedFilters.curriculum_type === 'k12') {
      //   tags = `${updatedFilters.country_id}.${updatedFilters.board_id ? `${updatedFilters.board_id}.${updatedFilters.grade_id ? `${updatedFilters.grade_id}.${updatedFilters.subject_id ? `${updatedFilters.subject_id}.${updatedFilters.curriculum_id ? `${updatedFilters.curriculum_id}.${updatedFilters.chapter_id ? `${updatedFilters.chapter_id}.${updatedFilters.topic_id ? updatedFilters.topic_id : ''}` : ''}` : ''}` : ''}` : ''}` : ''}`;
      // } else if (updatedFilters.curriculum_type === 'university') {
      //   tags = `${updatedFilters.country_id}.${updatedFilters.degree_id ? `${updatedFilters.degree_id}.${updatedFilters.major_id ? `${updatedFilters.major_id}.${updatedFilters.course_id ? `${updatedFilters.course_id}.${updatedFilters.section_id ? `${updatedFilters.section_id}.${updatedFilters.chapter_id ? `${updatedFilters.chapter_id}.${updatedFilters.topic_id ? updatedFilters.topic_id : ''}` : ''}` : ''}` : ''}` : ''}` : ''}`;
      // } else if (updatedFilters.curriculum_type === 'skills') {
      //   tags = `${updatedFilters.country_id}.${updatedFilters.skill_id ? `${updatedFilters.skill_id}.${updatedFilters.section_id ? `${updatedFilters.section_id}.${updatedFilters.chapter_id ? `${updatedFilters.chapter_id}.${updatedFilters.topic_id ? updatedFilters.topic_id : ''}` : ''}` : ''}` : ''}`;
      // } else if (updatedFilters.curriculum_type === 'test_prep') {
      //   tags = `${updatedFilters.country_id}.${updatedFilters.test_id ? `${updatedFilters.test_id}.${updatedFilters.section_id ? `${updatedFilters.section_id}.${updatedFilters.chapter_id ? `${updatedFilters.chapter_id}.${updatedFilters.topic_id ? updatedFilters.topic_id : ''}` : ''}` : ''}` : ''}`;
      // } else {
      //   tags = `${updatedFilters.country_id}.`;
      // }
      const trimmedCurriculumType = this.trimCurriculumType(updatedFilters.curriculum_type);
      if (trimmedCurriculumType === 'k12') {
        tags = `${updatedFilters.country_id}.${updatedFilters.board_id ? `${updatedFilters.board_id}.${updatedFilters.grade_id ? `${updatedFilters.grade_id}.${updatedFilters.subject_id ? `${updatedFilters.subject_id}.${updatedFilters.curriculum_id ? `${updatedFilters.curriculum_id}.${updatedFilters.chapter_id ? `${updatedFilters.chapter_id}.${updatedFilters.topic_id ? updatedFilters.topic_id : ''}` : ''}` : ''}` : ''}` : ''}` : ''}`;
      } else if (trimmedCurriculumType === 'university') {
        tags = `${updatedFilters.country_id}.${updatedFilters.degree_id ? `${updatedFilters.degree_id}.${updatedFilters.major_id ? `${updatedFilters.major_id}.${updatedFilters.course_id ? `${updatedFilters.course_id}.${updatedFilters.section_id ? `${updatedFilters.section_id}.${updatedFilters.chapter_id ? `${updatedFilters.chapter_id}.${updatedFilters.topic_id ? updatedFilters.topic_id : ''}` : ''}` : ''}` : ''}` : ''}` : ''}`;
      } else if (trimmedCurriculumType === 'skills') {
        tags = `${updatedFilters.country_id}.${updatedFilters.skill_id ? `${updatedFilters.skill_id}.${updatedFilters.section_id ? `${updatedFilters.section_id}.${updatedFilters.chapter_id ? `${updatedFilters.chapter_id}.${updatedFilters.topic_id ? updatedFilters.topic_id : ''}` : ''}` : ''}` : ''}`;
      } else if (trimmedCurriculumType === 'test_prep') {
        tags = `${updatedFilters.country_id}.${updatedFilters.test_id ? `${updatedFilters.test_id}.${updatedFilters.section_id ? `${updatedFilters.section_id}.${updatedFilters.chapter_id ? `${updatedFilters.chapter_id}.${updatedFilters.topic_id ? updatedFilters.topic_id : ''}` : ''}` : ''}` : ''}`;
      } else {
        tags = `${updatedFilters.country_id}.`;
      }
      return tags;
    }

    openQuestionModal = (question) => {
      this.setState({ showPreviewModal: true, previewQuestion: question });
    }

    handleCloseModal = () => {
      this.setState({ showPreviewModal: false });
    }

    addQuestion = () => {
      const { history } = this.props;
      history.push(`/${localStorage.updatedLocale}/create-question`);
      try {
        Mixpanel.track('teacher_click_add_question', {
          source: 'library',
        });
      } catch (e) {
        console.log(e);
      }
    }

    loadMore = () => {
      const { teacherQuestions, getMyQuestionList } = this.props;
      const { filters } = this.state;
      const payload = { page: teacherQuestions.nextPage, limit: teacherQuestions.limit, country_id: filters.country_id, text: filters.text, tags: this.getTagsFromFilters(filters) };
      getMyQuestionList(payload);
    }

    handleDelete = (id) => {
      this.setState({ showDeleteModal: id });
    }

    handleDeleteModalSuccess = () => {
      const { deleteQuestion } = this.props;
      const { showDeleteModal } = this.state;
      deleteQuestion(showDeleteModal);
      this.setState({ showDeleteModal: 0 });
    }

    handleDeleteModalClose = () => {
      this.setState({ showDeleteModal: 0 });
    }

    handleOnEditQuestionClicked= (id) => {
      const { history } = this.props;
      history.push(`/${localStorage.updatedLocale}/edit-question/${id}`);
    }

    handleOnClickCloneQuestion = () => {
      const { history } = this.props;
      const { previewQuestion } = this.state;
      history.push(`/${localStorage.updatedLocale}/edit-question/${previewQuestion.id}/clone`);
      this.handleCloseModal();
    }

    render() {
      const {
        noonText,
        countries,
        teacherQuestions,
        questionDeleteId,
        getTeacherGroupTags,
        boards,
        grades,
        subjects,
        curriculum,
        chapters,
        topics,
        degrees,
        tests,
        sections,
        majors,
        courses,
        skills,
        user,
      } = this.props;
      const { filters, previewQuestion, showPreviewModal, showDeleteModal } = this.state;
      const isRtl = document.body.dir === 'rtl';
      return (
        <React.Fragment>
          <PageHeader title={translationText(noonText, 'tab.library')} />
          <div className="table-wrapper container">
            <Column nowrap className="accordian library">
              {showPreviewModal && (
                <PreviewQuestionModal
                  noonText={noonText}
                  questionData={previewQuestion}
                  onCloneQuestion={this.handleOnClickCloneQuestion}
                  onClose={this.handleCloseModal} />
              )}
              {!!showDeleteModal && (
                <SessionGenericModal
                  noonText={noonText}
                  type="delete"
                  text1={translationText(noonText, 'library.deleteQuestion')}
                  btn1={translationText(noonText, 'session.confirm')}
                  btn2={translationText(noonText, 'session.cancel')}
                  onClick={this.handleDeleteModalSuccess}
                  onClose={this.handleDeleteModalClose}
                />
              )}
              <Row nowrap className="library__header" gap>
                <h3>{translationText(noonText, 'library.myQuestions')}</h3>
                <Button type="blue" onClick={this.addQuestion}>
                  <IconPlus className="add-question-icon" />
                  {translationText(noonText, 'library.addQuestion')}
                </Button>
              </Row>
              <ListFilters
                noonText={noonText}
                countryList={countries.countryList}
                handleOnFilterChange={this.handleOnFilterChange}
                countryId={filters.country_id}
                getTeacherGroupTags={getTeacherGroupTags}
                boards={boards}
                grades={grades}
                subjects={subjects}
                curriculum={curriculum}
                chapters={chapters}
                topics={topics}
                degrees={degrees}
                tests={tests}
                sections={sections}
                majors={majors}
                courses={courses}
                skills={skills}
                isListEmpty={teacherQuestions.list.length === 0 && !teacherQuestions.isLoading}
                />
              {!!teacherQuestions.list.length && (
              <table className="noon-table library__table">
                <thead>
                  <tr>
                    <td className="start">
                      {translationText(noonText, 'library.questions')}
                      {' '}
                      (
                      {teacherQuestions.list.length}
                      )
                    </td>
                    {!(isMobile || isIPad13) && <td>{translationText(noonText, 'library.difficulty')}</td>}
                    {!(isMobile || isIPad13) && <td className="end" />}
                  </tr>
                </thead>
                <tbody>
                  {teacherQuestions.list.map((question) => (
                    <tr key={question.id}>
                      <td className="start" onClick={() => this.openQuestionModal(question)}>
                        {!!question.question_json && !!question.question_json.length ? <NoonQuestion content={question.question_json} /> : ReactHtmlParser(question.question)}
                      </td>
                      {!(isMobile || isIPad13) && <td>{this.difficultyMap[question.difficulty]}</td>}
                      {!(isMobile || isIPad13) && (
                        <td className="end">
                          <Row nowrap gap="sm">
                            {question.user_id === user.id && <Button fab outlined type="primary" icon={IconEdit} onClick={() => this.handleOnEditQuestionClicked(question.id)} />}
                            <Button fab outlined type="primary" icon={IconEye} onClick={() => this.openQuestionModal(question)} />
                            <Button fab outlined type="red" icon={question.id === questionDeleteId ? IconLoader : IconDelete} disabled={question.id === questionDeleteId} onClick={() => this.handleDelete(question.id)} />
                          </Row>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              )}

              {!teacherQuestions.list.length && teacherQuestions.isLoading && (
                <Row nowrap align="center" justify="center" className="loader-wrapper">
                  <IconLoader height="60px" width="60px" />
                </Row>
              )}

              {!teacherQuestions.list.length && !teacherQuestions.isLoading && (
                <Column nowrap align="center" gap justify="center" className="loader-wrapper with-bg">
                  <IconQuestion height="12rem" width="12rem" flipX={!isRtl} />
                  <h3>{translationText(noonText, 'library.noQuestionAdded')}</h3>
                  <p>{translationText(noonText, 'library.addQuestionOnboarding')}</p>
                </Column>
              )}

              {!!teacherQuestions.list.length && teacherQuestions.nextPage !== -1 && (
                <Row nowrap className="mtb-2" justify="center" align="center">
                  <Button type="secondary" onClick={this.loadMore} loading={teacherQuestions.isLoading}>{translationText(noonText, 'tutoring.loadMore')}</Button>
                </Row>
              )}
            </Column>
          </div>
        </React.Fragment>
      );
    }
}

const mapStateToProps = (state) => ({
  noonText: state.toJS().translation.noonText,
  user: state.toJS().user.loggedUser,
  countries: countrySelector(state),
  teacherQuestions: state.toJS().question.teacherQuestions,
  questionDeleteId: state.toJS().question.deleteQuestion,
  boards: state.toJS().groupsV2.boards,
  grades: state.toJS().groupsV2.grades,
  subjects: state.toJS().groupsV2.subjects,
  curriculum: state.toJS().groupsV2.curriculum,
  chapters: state.toJS().groupsV2.chapters,
  topics: state.toJS().groupsV2.topics,
  degrees: state.toJS().groupsV2.degrees,
  tests: state.toJS().groupsV2.tests,
  sections: state.toJS().groupsV2.sections,
  majors: state.toJS().groupsV2.majors,
  courses: state.toJS().groupsV2.courses,
  skills: state.toJS().groupsV2.skills,
});

const mapDispatchToProps = (dispatch) => ({
  getMyQuestionList: (payload) => dispatch({ type: TEACHER_QUESTION_LIST.REQUEST, payload }),
  deleteQuestion: (payload) => dispatch({ type: DELETE_TEACHER_QUESTION.REQUEST, payload }),
  getTeacherGroupTags: (payload) => dispatch({ type: GET_TEACHER_TAGS_GROUP.REQUEST, payload }),
});

Library.propTypes = {
  noonText: translationType.isRequired,
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  countries: PropTypes.shape().isRequired,
  getMyQuestionList: PropTypes.func.isRequired,
  teacherQuestions: PropTypes.shape().isRequired,
  questionDeleteId: PropTypes.number.isRequired,
  deleteQuestion: PropTypes.func.isRequired,
  getTeacherGroupTags: PropTypes.func.isRequired,
  boards: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  grades: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  subjects: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  curriculum: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  chapters: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  topics: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  degrees: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  tests: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  sections: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  majors: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  courses: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  skills: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  }),
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
};

Library.defaultProps = {
  boards: {
    list: [],
    loading: false,
  },
  grades: {
    list: [],
    loading: false,
  },
  subjects: {
    list: [],
    loading: false,
  },
  curriculum: {
    list: [],
    loading: false,
  },
  chapters: {
    list: [],
    loading: false,
  },
  topics: {
    list: [],
    loading: false,
  },
  degrees: {
    list: [],
    loading: false,
  },
  tests: {
    list: [],
    loading: false,
  },
  sections: {
    list: [],
    loading: false,
  },
  majors: {
    list: [],
    loading: false,
  },
  courses: {
    list: [],
    loading: false,
  },
  skills: {
    list: [],
    loading: false,
  },
  user: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Library);

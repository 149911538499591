import React from 'react';
import { omit } from 'lodash-es';
import { Row, Button } from '@noon/atom';
import { IconClose } from '@noon/quark';
import { translationText } from '../../../helpers';

function FiltersApplied({ filters, onFilterSelection, noonText }) {
  const filtersApplied = Object.keys(omit(filters, ['week_offset', 'text']));
  return (
    filtersApplied.length > 0
      && (
      <Row className="general-planner__filters-applied" gap="sm" justify="start" align="center">
        <span className="title">
          {translationText(noonText, 'filter.applied')}
          {' '}
          {`(${filtersApplied.length})`}
        </span>
        {filtersApplied.map((filter, index) => (
          <Button key={index} rounded type="secondary" className="text-capitalize child">
            <span title={filters[filter].title || filters[filter].value}>{filters[filter].title || filters[filter].value}</span>
            <IconClose height="12px" width="12px" fill="#62687A" onClick={() => onFilterSelection({ [filter]: '' })} />
          </Button>
        ))}
      </Row>
      )
  );
}

export default FiltersApplied;

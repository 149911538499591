import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Hr } from '@noon/atom';
import { IconCompetition, IconEdit, IconFlashcard, IconLocation, IconSession, IconStar } from '@noon/quark';
import { LIST_TEACHERS } from '../../redux/constants';
import { translationType, userType, teacherListType } from '../../types';
import { getCountryId, getSelectedCountry } from '../../constants';

import {
  FeatureCompetition,
  FeaturedTeachers,
  FeatureClassroom,
  FeatureList,
  BannerEgypt,
  MediaSection,
  FeatureDashboard,
  FeatureFlashcard,
} from './Partials';
import { PublicNav } from '../NavigationBar';
import Mixpanel from '../Mixpanel';
import Footer from '../Footer';

class EgyptLanding extends Component {
  static propTypes = {
    listTeachers: PropTypes.func.isRequired,
    pinnedTeachers: teacherListType.isRequired,
    noonText: translationType.isRequired,
    user: userType.isRequired,
    history: PropTypes.shape().isRequired,
  };

  componentDidMount() {
    const { listTeachers, pinnedTeachers } = this.props;

    if (!pinnedTeachers.length) {
      listTeachers();
    }
    const country = getSelectedCountry();
    try {
      Mixpanel.track('teacher_landing_shown', {
        landing_country: country.full_name,
      });
    } catch (e) {
      console.log('error', e);
    }
  }

  render() {
    const { noonText, pinnedTeachers, user, history } = this.props;
    const global = !['1', '2'].includes(String(getCountryId()));
    return (
      <React.Fragment>
        <div className="home-container">
          <PublicNav history={history} />
          <BannerEgypt history={history} noonText={noonText} global={global}>
            {!global && <MediaSection />}
          </BannerEgypt>
          <FeatureFlashcard noonText={noonText} />
          <Hr />
          <FeatureCompetition noonText={noonText} global={global} />
          <Hr />
          <FeatureClassroom noonText={noonText} />
          {/* <FeatureList
            noonText={noonText}
            group="teacherLanding"
            icons={{ one: IconSession, two: IconFlashcard, three: IconEdit, four: IconCompetition, five: IconStar, six: IconLocation }}
          /> */}
          <Hr />
          <FeatureDashboard global={global} />
          <Hr />
          {!global && <FeaturedTeachers pinnedTeachers={pinnedTeachers} history={history} noonText={noonText} user={user} />}
        </div>
        <Footer noonText={noonText} />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  pinnedTeachers: state.toJS().user.pinnedTeachers,
  noonText: state.toJS().translation.noonText,
  user: state.toJS().user.loggedUser,
});

const mapDispatchToProps = (dispatch) => ({
  listTeachers: () => dispatch({ type: LIST_TEACHERS.REQUEST }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EgyptLanding);

import React from 'react';
import { Checkbox } from '@noon/atom';
import t from '../../../helpers/translate';

const BreakoutModeSelector = ({ isBreakoutDeffered, onChangeBreakoutMode, isDiscussionEnabled, isAllowedDiscussion, handleAllowedDiscussion}) => (
  <div className="breakout-mode-selector">
    <div>
    <Checkbox
      type="checkbox"
      label={t('teacherBreakout', 'LabelbreakoutModeSelector')}
      name="isBreakoutDeffered"
      checked={isBreakoutDeffered}
      onChange={onChangeBreakoutMode}
    />
    </div>
    
    {isDiscussionEnabled &&
      <div>
      <Checkbox
        type="checkbox"
        label={t('teacherBreakout', 'LabelbreakoutModeSelectorDiscussion')}
        name="isDiscussionAllowed"
        checked={isAllowedDiscussion}
        onChange={handleAllowedDiscussion}
      />
      </div>
    }
  </div>
);
export default BreakoutModeSelector;

import React from 'react';
import { IconChrome, IconMicOff, IconNoWifi } from '@noon/quark';

const PrecallIcon = (prop) => {
  const { name, size } = prop;
  return (
    <React.Fragment>
      {name === 'chrome'
        ? <IconChrome width={size || '26'} height={size || '26'} stroke="#353535" />
        : name === 'mic'
          ? <IconMicOff width={size || '26'} height={size || '26'} stroke="#353535" />
          : name === 'nowifi'
            ? <IconNoWifi width={size || '26'} height={size || '26'} stroke="#353535" />
            : ''}
    </React.Fragment>
  );
};

export default PrecallIcon;

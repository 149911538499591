import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Route, Redirect } from 'react-router-dom';
import Footer from '../Footer';
import { PublicNav } from '../NavigationBar';
import ErrorBoundry from '../ErrorBoundry';
import { translationType } from '../../types';

const GuestRoute = ({ isAuthenticated, noFooter, history, noonText, component: Component, ...rest }) => (
  <ErrorBoundry>
    <Route
      {...rest}
      render={(props) => (!isAuthenticated ? (
        <React.Fragment>
          {props.location.pathname !== '/' && (
          <header className="noon-header no-shadow">
            <PublicNav history={history} />
          </header>
          )}
          <main
            className={classNames('noon-content', 'animated', 'fadeIn', {
              showHeader: props.location.pathname !== '/',
            })}
            >
            <Component {...props} />
          </main>
          {!noFooter && <Footer noonText={noonText} />}
        </React.Fragment>
      ) : (
        <Redirect
          to={localStorage.lastUrl ? JSON.parse(localStorage.lastUrl) : `/${localStorage.updatedLocale}/home`}
          />
      ))}
    />
  </ErrorBoundry>
);

GuestRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]).isRequired,
  noFooter: PropTypes.bool,
  history: PropTypes.shape(),
  isAuthenticated: PropTypes.bool.isRequired,
  noonText: translationType.isRequired,
  location: PropTypes.shape(),
};

GuestRoute.defaultProps = {
  noFooter: false,
  location: {},
  history: {},
};

const mapStateToProps = (state) => ({
  noonText: state.toJS().translation.noonText,
  isAuthenticated: !!state.toJS().userv2.getTeacher.response.user_id,
});

export default connect(mapStateToProps)(GuestRoute);

import React from 'react';
import classNames from 'classnames';
import { Column, Row, Avatar, FormattedText } from '@noon/atom';
import ImageWithOrientation from '../Layout/imageWithOrientation';
import ErrorBoundry from '../ErrorBoundry';
import { translationText } from '../../helpers';

export default function DummyComment(prop) {
  const { data, noonText } = prop;

  return (
    <div className="incoming-comments fadeIn animated">
      <ErrorBoundry>
        <Column className="full-width mb-2">
          <Row align="center" nowrap className="mb-05">
            <Avatar className="incoming-comments__avatar" size="36px" url={data && data.profile_pic} rounded />
            <Column className={classNames('full-width br-1 mlr-05 background-transparent')}>
              <Column className="mtb-3 mlr-3" align="end">
                <p className="subtitle">{translationText(noonText, 'post.isUploading')}</p>
                <div className="loader-linear" mt-2 />
              </Column>
              <Row align="center">
                {data && data.text && (
                  <FormattedText
                    className={classNames('study-feed--post__content--text mb-2')}
                    classNameForShowMore="mt-1"
                    text={data.text}
                    breakpoint={95}
                    largeFont="14px"
                    normalFont="14px"
                    slim
                    shorten
                    extraText={data.name}
                  />
                )}
              </Row>
              {data && !!data.image && (
                <div className="dummy-comment-image" >
                  <ImageWithOrientation className="post-image" url={data.image} />
                </div>
              )}
            </Column>
          </Row>
        </Column>
      </ErrorBoundry>
    </div>
  );
}

import { connect } from 'react-redux';
import { GET_CLASS_REPLAY } from 'redux/constants';
import Play from './SocketPlayer';
import { CONFIG_REASON } from '../../redux/constants';

const mapStateToProps = state => ({
  sessionData: state.toJS().tutoring.sessionDataForReplay,
  user: state.toJS().user.loggedUser,
  token: state.toJS().user.token,
  noonText: state.toJS().translation.noonText,
  tutoringError: state.toJS().tutoring.error,
  temporaryTokenPayload: state.toJS().biFrost.temporaryTokenPayload,
  reasons: state.toJS().tutoring.reasons,
  selectedCountry: state.toJS().folder.countries.selectedCountry,
});

const mapDispatchToProps = dispatch => ({
  getSessionForReplay: id => dispatch({ type: GET_CLASS_REPLAY.REQUEST, payload: id }),
  clearSession: payload => dispatch({ type: GET_CLASS_REPLAY.SUCCESS, payload }),
  getReasons: () => dispatch({ type: CONFIG_REASON.REQUEST }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Play);

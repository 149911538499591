import React from 'react';
import IndiaLoginHowToBecome from './indiaLoginHowToBecome';
import IndiaLoginTeacherDescription from './indiaLoginTeacherDescription';

const IndiaLogin = ({ children }) => (
  <section className="signUp-india">
    <div className="signUp-india-title">
      <div className="square" />
      <div className="signUp-india--form">
        <div className="signUp-india--form--text">
          <p>Hello Teacher,</p>
          <span>Welcome to Noon Academy!</span>
        </div>
        <div className="girl" />
        <div className="signUp-india--form--form">
          {children}
        </div>
      </div>
    </div>
    <IndiaLoginTeacherDescription />
    <IndiaLoginHowToBecome />
  </section>
);

export default IndiaLogin;


import React from 'react';
import { translationText } from '../../../helpers';

export default function FeatureFlashcard(prop) {
  const { noonText } = prop;
  return (
    <section className="tagline-sec">
      <div className="tagline-wrapper">
        <div className="tagline-image background-pattern-1">
          <img src="/assets/images/landing/feature-flashcard.jpg" alt="flashcard notes" />
        </div>
        <div className="tagline-content">
          <div className="head-fade-line">
            <span className="fading-line" />
          </div>
          <h2 className="heading-teacher">{translationText(noonText, 'teacherLanding.flashcardTitle')}</h2>
          {/* <h4 className="subheading-teacher">{translationText(noonText, 'teacherLanding.flashcardSubTitle')}</h4> */}
          <p className="description-teacher">{translationText(noonText, 'teacherLanding.flashcardDescription')}</p>
        </div>
      </div>
    </section>
  );
}

/* eslint-disable no-case-declarations */
import { RESET_PUBNUB_TOKENS,
  GET_PUBNUB_SESSION_MESSAGE,
  GET_SESSION_MESSAGE,
  GET_BREAKOUT_MESSAGE,
  POST_SESSION_MESSAGE,
  POST_BREAKOUT_MESSAGE,
  GET_LEADERBOARD_API,
  USER_JOINED_CLASSROOM,
  USER_LEFT_CLASSROOM,
  GET_TOTAL_MEMBER_COUNT,
  GET_BREAKOUT_REPORT,
  TEACHER_GRANT_ACCESS,
  GET_ALL_CANVAS,
  SET_PDF_DATA,
  RESET_LAST_SESSION_DATA,
  LOAD_MORE_TEAMS,
  LOAD_BREAKOUT_DATA,
  UPDATE_BREAKOUT_DURATION,
  BREAKOUT_ENDED,
  BREAKOUT_STARTED,
} from '../constants';

const initialState = {
  sessionMessage: {
    messages: [],
    offset: 0,
    loading: false,
  },
  breakoutMessage: {
    messages: [],
    offset: 0,
    loading: false,
  },
  isLoading: false,
  error: {},
  leaderboardData: {
    user: [],
    offset: 0,
    isLoading: false,
  },
  pubnubTokens: { isLoading: false, isLoaded: false },
  allCanvasData: {
    isLoading: false,
    error: {},
    maxSlideNumber: -1,
    data: [],
  },
  pdfData: {
    sessionId: 0,
    images: [],
  },
  breakoutReport: {
    breakoutCount: 0,
    totalTeams: 0,
    totalLiveUsers: 0,
    breakoutDuration: -1,
    onGoing: false,
    offset: {
      engaged: 0,
      superEngaged: 0,
      notEngaged: 0,
    },
  },
};

export default function classroom(state = initialState, action) {
  switch (action.type) {

    case RESET_LAST_SESSION_DATA:
      return {
        ...state,
        pdfData: {
          sessionId: 0,
          images: [],
        },
      };
    case GET_SESSION_MESSAGE.REQUEST:
      return {
        ...state,
        sessionMessage: {
          ...state.sessionMessage,
          loading: true,
        },
      };
    case GET_PUBNUB_SESSION_MESSAGE.REQUEST:
      return {
        ...state,
        sessionMessage: {
          ...state.sessionMessage,
          loading: true,
        },
      };
    case GET_SESSION_MESSAGE.SUCCESS:
      return {
        ...state,
        sessionMessage: {
          messages: action.isReset ? action.data : [...action.data, ...state.sessionMessage.messages],
          offset: action.offset,
          loading: false,
        },
      };
    case GET_SESSION_MESSAGE.FAILURE:
      return {
        ...state,
        sessionMessage: {
          ...state.sessionMessage,
          loading: false,
        },
        error: action.payload,
      };

    case GET_BREAKOUT_MESSAGE.REQUEST:
      return {
        ...state,
        breakoutMessage: {
          ...state.breakoutMessage,
          loading: true,
        },
      };
    case GET_BREAKOUT_MESSAGE.SUCCESS:
      return {
        ...state,
        breakoutMessage: {
          messages: action.data,
          offset: action.offset,
          loading: false,
        },
      };
    case GET_BREAKOUT_MESSAGE.FAILURE:
      return {
        ...state,
        breakoutMessage: {
          ...state.breakoutMessage,
          loading: false,
        },
        error: action.payload,
      };

    case POST_SESSION_MESSAGE.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case POST_SESSION_MESSAGE.SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case POST_SESSION_MESSAGE.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case POST_BREAKOUT_MESSAGE.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case POST_BREAKOUT_MESSAGE.SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case POST_BREAKOUT_MESSAGE.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case GET_LEADERBOARD_API.REQUEST:
      return {
        ...state,
        leaderboardData: {
          ...state.leaderboardData,
          isLoading: true,
        },
      };
    case GET_LEADERBOARD_API.SUCCESS:
      return {
        ...state,
        leaderboardData: {
          ...state.leaderboardData,
          isLoading: false,
          user: action.isReset ? action.data : [...state.leaderboardData.user, ...action.data],
          offset: action.offset,
          total: action.total,
          totalLiveUsers: action.totalLiveUsers,
        },
      };
    case GET_TOTAL_MEMBER_COUNT.SUCCESS:
      return {
        ...state,
        leaderboardData: {
          ...state.leaderboardData,
          total: action.total,
          totalLiveUsers: action.totalLiveUsers,
        },
      };
    case GET_BREAKOUT_REPORT.SUCCESS:
      const breakoutCount = action.reload ? state.breakoutReport.breakoutCount : state.breakoutReport.breakoutCount + 1;
      const totalTeams = (
        action.data.engaged.meta.total_teams
        + action.data.notEngaged.meta.total_teams
        + action.data.superEngaged.meta.total_teams
      );
      const totalLiveUsers = action.data.engaged.data.length ? action.data.engaged.data.map((t) => t.team_members.length).reduce((a, b) => a + b) : 0
                             + action.data.notEngaged.data.length ? action.data.notEngaged.data.map((t) => t.team_members.length).reduce((a, b) => a + b) : 0
                             + action.data.superEngaged.data.length ? action.data.superEngaged.data.map((t) => t.team_members.length).reduce((a, b) => a + b) : 0;
      return {
        ...state,
        breakoutReport: { ...state.breakoutReport,
          ...action.data,
          breakoutCount,
          totalTeams,
          totalLiveUsers,
        },
      };
    case LOAD_BREAKOUT_DATA:
      return {
        ...state,
        breakoutReport: {
          ...state.breakoutReport,
          ...action.data,
        },
      };
    case UPDATE_BREAKOUT_DURATION:
      return {
        ...state,
        breakoutReport: {
          ...state.breakoutReport,
          ...action.payload,
        },
      };
    case BREAKOUT_ENDED:
      return {
        ...state,
        breakoutReport: {
          ...state.breakoutReport,
          breakoutDuration: -1,
          onGoing: false,
        },
      };
    case BREAKOUT_STARTED:
      return {
        ...state,
        breakoutReport: {
          ...state.breakoutReport,
          onGoing: true,
        },
      };
    case LOAD_MORE_TEAMS.SUCCESS:

      switch (action.data.team_type) {

        case 'engaged': return ({
          ...state,
          breakoutReport: {
            ...state.breakoutReport,
            engaged: { data: [...state.breakoutReport.engaged.data, ...action.data.teams.data] },
            offset: {
              ...state.breakoutReport.offset,
              engaged: state.breakoutReport.offset.engaged + 1,
            },
          },
        });
        case 'notEngaged': return ({
          ...state,
          breakoutReport: {
            ...state.breakoutReport,
            notEngaged: { data: [...state.breakoutReport.notEngaged, ...action.data.teams.data] },
            offset: {
              ...state.breakoutReport.offset,
              notEngaged: state.breakoutReport.offset.notEngaged + 1,
            },
          },
        });
        case 'superEngaged': return ({
          ...state,
          breakoutReport: {
            ...state.breakoutReport,
            superEngaged: { data: [...state.breakoutReport.superEngaged, ...action.data.teams.data] },
            offset: {
              ...state.breakoutReport.offset,
              superEngaged: state.breakoutReport.offset.superEngaged + 1,
            },
          },
        });
        default: return state;
      }

    case USER_JOINED_CLASSROOM:
      return {
        ...state,
        leaderboardData: {
          ...state.leaderboardData,
          total: action.isNewUser ? state.leaderboardData.total + 1 : state.leaderboardData.total,
          totalLiveUsers: state.leaderboardData.totalLiveUsers + 1,
        },
      };
    case USER_LEFT_CLASSROOM:
      return {
        ...state,
        leaderboardData: {
          ...state.leaderboardData,
          totalLiveUsers: state.leaderboardData.totalLiveUsers - 1,
        },
      };
    case GET_LEADERBOARD_API.ERROR:
      return {
        ...state,
        leaderboardData: {
          ...state.leaderboardData,
          isLoading: false,
        },
      };
    case RESET_PUBNUB_TOKENS:
      return {
        ...state,
        pubnubTokens: { isLoading: false, isLoaded: false },
      };
    case TEACHER_GRANT_ACCESS.REQUEST:
      return {
        ...state,
        pubnubTokens: {
          ...state.pubnubTokens,
          isLoading: true,
          isLoaded: false,
        },
      };
    case TEACHER_GRANT_ACCESS.SUCCESS:
      return {
        ...state,
        pubnubTokens: {
          ...state.pubnubTokens,
          ...action.data,
          isLoading: false,
          isLoaded: true,
        },
      };
    case TEACHER_GRANT_ACCESS.ERROR:
      return {
        ...state,
        pubnubTokens: {
          ...state.pubnubTokens,
          isLoading: false,
          isLoaded: false,
        },
      };

    case GET_ALL_CANVAS.REQUEST:
      return {
        ...state,
        allCanvasData: {
          ...state.allCanvasData,
          isLoading: true,
        },
        pdfData: action.session_id !== state.pdfData.sessionId ? {
          sessionId: action.session_id,
          images: [],
        } : state.pdfData,
      };
    case GET_ALL_CANVAS.SUCCESS:
      return {
        ...state,
        allCanvasData: {
          ...state.allCanvasData,
          data: [...action.data],
          maxSlideNumber: action.maxSlideNumber,
          isLoading: false,
          error: {},
        },
      };
    case GET_ALL_CANVAS.FAILURE:
      return {
        ...state,
        allCanvasData: {
          ...state.allCanvasData,
          error: action.payload,
          isLoading: false,
        },
      };
    case SET_PDF_DATA:
      return {
        ...state,
        pdfData: {
          ...state.pdfData,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}

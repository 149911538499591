import * as firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/performance';
import 'firebase/analytics';
import 'firebase/remote-config';
import store from '../../store';
import { DEFAULT_PLAYBACK_CONFIG, SLIDE_REUSABILITY_TEACHERS } from '../../constants';
import { USER_PUSH_TOKEN } from '../../redux/constants';

firebase.initializeApp({
  apiKey: process.env.FIREBASE_API_KEY,
  projectId: process.env.FIREBASE_PROJECT_ID,
  appId: process.env.FIREBASE_APP_ID,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
});

export const remoteConfig = firebase.remoteConfig();

remoteConfig.settings = {
  fetchTimeoutMillis: 60000,
  minimumFetchIntervalMillis: 3600000,
};

const configDefault = {
  disabled: false,
  disabled_in_country: [],
  disabled_in_grade: [],
  disabled_in_version: [],
};

remoteConfig.defaultConfig = ({
  classroom_config_teacher_web: {
    connection_status: configDefault,
    raise_hand_action: configDefault,
    raise_hand_recieved: configDefault,
    slide_action: configDefault,
    mic_action: configDefault,
    question_send: configDefault,
    question_rendered: configDefault,
    sns_detection: configDefault,
  },
  feature_toggle_config_teacher_web: {
    enablePromotionInClassroom: false,
    showPlaybackTrimmingTo: DEFAULT_PLAYBACK_CONFIG.whitelistedTeachers,
    playbackEnabledCountries: DEFAULT_PLAYBACK_CONFIG.whitelistedCountries,
  },
  constants_config_teacher_web: {},
});

export function sendTokenToServer(token) {
  store.dispatch({ type: USER_PUSH_TOKEN.REQUEST, payload: token });
}

export function initializePush() {
  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .then((token) => {
        sendTokenToServer(token);
      })
      .catch((error) => {
        if (error.code === 'messaging/permission-blocked') {
          console.log('Please Unblock Notification Request Manually');
        } else {
          console.log('Error Occurred', error);
        }
      });

    messaging.onTokenRefresh(async () => {
      const token = await messaging.getToken();
      sendTokenToServer(token);
    });

    // messaging.onMessage((payload) => {
    //   console.log('--> msg', payload);
    // });
  }
}

export const initializeMessaging = () => {
  // intialize firebase notification and register with service worker
  if (firebase.messaging.isSupported && 'serviceWorker' in navigator) {
    navigator.serviceWorker.register(`${process.env.TEACHER_URL}noon-sw.js`).then((registration) => {
      firebase.messaging().useServiceWorker(registration);
    });
  }
  // initalize firebase performance
  if (firebase && firebase.performance) {
    firebase.performance();
  }
  // initalize firebase analytics
  // if (firebase && firebase.analytics) {
  //   firebase.analytics();
  // }
};

export const deleteToken = () => {
  if (firebase && firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();
    messaging.getToken().then((token) => {
      if (token) messaging.deleteToken(token);
    });
  }
};

export const getFirebaseRemoteConfigValue = (key) => new Promise((resolve, reject) => {
  remoteConfig.fetchAndActivate()
    .then(() => {
      // eslint-disable-next-line no-underscore-dangle
      const value = remoteConfig.getValue(key)._value;
      resolve(JSON.parse(value || '{}'));
    })
    .catch((err) => {
      console.log('logger error', err);
      reject(err);
    });
});

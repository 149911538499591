import { put, call, takeEvery, all } from 'redux-saga/effects';
import isEmpty from 'lodash-es/isEmpty';
import { fileApi } from '../restApi';
import {
  UPLOAD_FILE,
  COMPETITION_EMOJI,
  GET_EMOJI,
  BULK_FILE_UPLOAD,
  UPLOAD_VIDEO,
} from '../constants';

const getEmojiAPI = () => fileApi.get('smileys');
const competitionEmojiAPI = () => fileApi.get('competitionEmojis');
const uploadFileAPI = (payload, progressEventHandler) => fileApi.post('files', payload, { onUploadProgress: progressEventHandler });

const uploadVideoAPI = (payload, progressEventHandler) => fileApi.post('videos', payload, { onUploadProgress: progressEventHandler });

function* uploadFile({ payload, progressEventHandler }) {
  console.log('upload file', payload);
  try {
    const response = yield call(uploadFileAPI, payload, progressEventHandler);
    if (response.ok && response.data.success) {
      yield put({
        type: UPLOAD_FILE.SUCCESS,
        payload: isEmpty(response.data.data) ? {} : response.data.meta,
      });
    } else {
      yield put({ type: UPLOAD_FILE.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: UPLOAD_FILE.FAILURE, payload: err });
  }
}

function* uploadVideo({ payload, progressEventHandler, onComplete }) {
  try {
    const response = yield call(uploadVideoAPI, payload, progressEventHandler);
    if (response.ok && response.data.success) {
      yield put({
        type: UPLOAD_VIDEO.SUCCESS,
        payload: isEmpty(response.data.data) ? {} : response.data.meta,
      });
      onComplete(response.data.meta);
    } else {
      yield put({ type: UPLOAD_VIDEO.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: UPLOAD_VIDEO.FAILURE, payload: err });
  }
}

function getFileName(data) {
  for (const value of data.entries()) {
    if (value[0] === 'file_name') return value[1];
  }
  return '';
}

function* bulkFileUpload({ filesBatch }) {
  console.log('filesbatch', filesBatch);
  try {
    if (filesBatch.clear) {
      yield put({
        type: BULK_FILE_UPLOAD.SUCCESS,
        payload: 'clear',
      });
    } else {
      const response = yield all(filesBatch.map((x) => call(uploadFileAPI, x.fd, x.progressEvent)));
      const filesMeta = response.filter((item) => item.ok).map((item) => ({ meta: item.data.meta, name: getFileName(item.config.data) }));
      yield put({
        type: BULK_FILE_UPLOAD.SUCCESS,
        payload: isEmpty(filesMeta) ? [] : filesMeta,
      });
    }
  } catch (err) {
    yield put({ type: BULK_FILE_UPLOAD.FAILURE, payload: err });
  }
}

function* getEmojies() {
  try {
    const response = yield call(getEmojiAPI);
    if (response.data.success) {
      yield put({
        type: GET_EMOJI.SUCCESS,
        payload: isEmpty(response.data.data) ? {} : response.data.data[0],
      });
    } else {
      yield put({ type: GET_EMOJI.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: GET_EMOJI.FAILURE, payload: err });
  }
}

function* competitionEmoji() {
  try {
    const response = yield call(competitionEmojiAPI);
    if (response.data.success) {
      yield put({
        type: COMPETITION_EMOJI.SUCCESS,
        payload: isEmpty(response.data.data) ? [] : response.data.data,
      });
    }
  } catch (err) {
    console.log('err');
  }
}

export function* fileSaga() {
  yield all([
    takeEvery(GET_EMOJI.REQUEST, getEmojies),
    takeEvery(UPLOAD_FILE.REQUEST, uploadFile),
    takeEvery(COMPETITION_EMOJI.REQUEST, competitionEmoji),
    takeEvery(BULK_FILE_UPLOAD.REQUEST, bulkFileUpload),
    takeEvery(UPLOAD_VIDEO.REQUEST, uploadVideo),
  ]);
}

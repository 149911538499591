import React from 'react';
import Image from 'react-lazy-image';
import { getCountryId } from '../../../constants';

export default function FeatureDahsboard(prop) {
  const { global } = prop;
  const countryId = getCountryId();
  return (
    <section className="classroom-section">
      <Image
        offset={100}
        source={`/assets/images/landing/feature-dashboard-${global ? '2' : countryId}.jpg`}
        className="allsubject_img"
        alt="subject icons"
      />
    </section>
  );
}

import * as Sentry from '@sentry/react';

export default class SentryContext {
  contextSet = false;

  setContext(data: { id: string, country: string }) : void {
    if (this.contextSet) {
      Sentry.setUser({ ...data, ip_address: '{{auto}}' });
      this.contextSet = true;
    }
  }

  removeContext() {
    Sentry.configureScope((scope) => scope.setUser(null));
    this.contextSet = false;
  }
}

import React from 'react';
import classNames from 'classnames';
import { Row } from '@noon/atom';
import Paragraph from './Paragraph';

const QuestionChoice = (prop) => {
  const { dir, question = {}, choice = {}, onSelect, wrong, correct, selected, align = 'normal', className } = prop;
  if (!choice.answer_json) return '';
  return (
    <Row justify="start" align="center" nowrap gap="sm" dir={dir || question.text_direction} className={classNames('noon-question__choice', `align-${align}`, { wrong, correct, selected }, className)} onClick={() => onSelect && onSelect(choice, question)}>
      {choice.answer_json.map((para, index) => {

        if (para.type === 'paragraph') {
          return <Paragraph key={index} content={para.value} />;
        }
        return '';
      })}
      {!!correct
        && (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="8.25" fill="#31A359" stroke="#31A359" strokeWidth="1.5" />
          <path fillRule="evenodd" clipRule="evenodd" d="M13.3536 5.14645C13.5488 5.34171 13.5488 5.65829 13.3536 5.85355L7.85355 11.3536C7.65829 11.5488 7.34171 11.5488 7.14645 11.3536L4.64645 8.85355C4.45118 8.65829 4.45118 8.34171 4.64645 8.14645C4.84171 7.95118 5.15829 7.95118 5.35355 8.14645L7.5 10.2929L12.6464 5.14645C12.8417 4.95118 13.1583 4.95118 13.3536 5.14645Z" fill="white" />
        </svg>
        )}

    </Row>
  );
};

export default QuestionChoice;

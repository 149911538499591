export function getNoonItem(item) {
  try {
    return JSON.parse(localStorage.getItem(item));
  } catch (err) {
    console.log(err);
    console.log(`could not get item ${item} from localstorage`);
  }
}

export function setNoonItem(name, objectItem) {
  try {
    localStorage.setItem(name, JSON.stringify(objectItem));
  } catch (err) {
    console.log(err);
    console.log(`could not save item ${objectItem} in localstorage`);
  }
}

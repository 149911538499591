import React from 'react';
import { useSelector } from 'react-redux';
import { Row } from '@noon/atom';
import { IconBack } from '@noon/quark';
import { translationText } from '../../helpers';

export default function ViewMoreButton(prop) {
  const {
    text = '',
    iconHeight = '12px',
    iconWidth = '8px',
    handleClick,
    justify = 'end',
    rotate = 0,
    className,
  } = prop;

  const isRtl = document.body.dir === 'rtl';
  const noonText = useSelector(state => state.toJS().translation.noonText);

  return (
    <Row className={`noon-view-all ${className}`} align="center" justify={justify} gap="sm" onClick={handleClick}>
      <p className="child">{text || translationText(noonText, 'dashboard.viewAll')}</p>
      <IconBack fill="#62687A" stroke="#62687A" height={iconHeight} width={iconWidth} flipX={!isRtl} rotate={rotate} />
    </Row>
  );
}

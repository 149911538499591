/* eslint-disable no-undef */
// import AgoraRTC from 'agora-rtc-sdk';
import EventEmitter from 'events';
import { isIOS, isMobile, isSafari } from 'react-device-detect';
import AGORA_RTC_ERRORS from './agoraRtcErrors';

const AUDIO_STATS_INTERVAL = 2000; // in ms
const RETRY_PUBLISH_AUDIO_THRESHOLD = 3;

export default class AgoraRtcOld extends EventEmitter {

    joined = false; // Joined Channel Flag

    published = false; // is stream published

    localStream = null; // localstream instance

    remoteStreams = []; // remote stream list

    isMute = true; // mute status

    client = null;

    downlinkQuality = 0;

    uplinkQuality = 0;

    isVideoMute = false;

    constructor({ handleOnVideoInitError, connectionDetails, connectionStatus, streamStatus, onStreamUpdated, changeMicStatus, changeVideoStatus, onError, handleOnMicrophoneChanged, handleOnCameraChanged, options = {} }) {
      super();

      if (options.enableLogUpload) AgoraRTC.Logger.enableLogUpload();
      AgoraRTC.Logger.setLogLevel(AgoraRTC.Logger.ERROR);

      // proxy setting change to force tcp
      if (options.enableProxy) {
        console.log('RTC : force tcp enabled');
        AgoraRTC.setParameter('FORCE_TURN_TCP', true);
      }

      const clientConfig = {
        mode: options.mode || 'rtc',
        codec: (isIOS || isSafari || isMobile) ? 'vp8' : (options.codec || 'h264'),
      };

      // set server region
      if (options.serverRegion) {
        console.log('RTC Area code : ', options.serverRegion);
        clientConfig.areaCode = [AgoraRTC.AREAS[options.serverRegion]];
      }

      this.client = AgoraRTC.createClient(clientConfig);

      // if (options.enableProxy) {
      //   console.log('RTC Proxy : Enabled');
      //   this.client.startProxyServer(3);
      // }

      console.log('RTC Version : ', AgoraRTC.VERSION);

      this.connectionDetails = connectionDetails;
      this.connectionStatus = connectionStatus;
      this.streamStatus = streamStatus;
      this.changeMicStatus = changeMicStatus;
      this.changeVideoStatus = changeVideoStatus;
      this.onError = onError;
      this.onStreamUpdated = onStreamUpdated;
      this.options = options;
      this.handleOnVideoInitError = handleOnVideoInitError;
      this.handleOnCameraChanged = handleOnCameraChanged;
      this.handleOnMicrophoneChanged = handleOnMicrophoneChanged;
    }

    init = () => {
      this.client.init(this.connectionDetails.appId, this.initSuccess, this.initFailure);
    }

    initSuccess = () => {
      const { channel } = this.connectionDetails;
      this.joinChannel(channel)
        .then(this.initializeListeners)
        .catch(this.joinChannelFailure);
    }

    initFailure = (err) => {
      this.onError(AGORA_RTC_ERRORS[101], err);
    }

    joinChannel = (channel) => {
      const { token, uid } = this.connectionDetails;
      return new Promise((resolve, reject) => {
        this.client.join(token, channel, Number(uid), (res) => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      });
    }

    joinChannelFailure = (err) => {
      this.onError(AGORA_RTC_ERRORS[102], err);
    }

    initializeListeners = () => {
      this.listenConnectionStateChange();
      this.listenStreamPublished();
      this.listenStreamAdd();
      this.listenStreamRemove();
      this.listenStreamSubscription();
      this.listenAudioStatus();
      this.listenNetworkQuality();
      this.listenAudioTrackEnded();
      this.listenVolumeChange();
      this.listenOnCameraChanged();
      this.listenOnMicrophoneChanged();
      if (this.options.enableStats) this.emitLocalStats();
      this.connectionStatus({ curState: 'CONNECTED', prevState: 'INITIATED' });
    }

    setVideoProfile = (videoStream, videoProfile = '240p_1') => {
      // Set video profile
      // https://docs.agora.io/en/Video/video_profile_web?platform=Web
      videoStream.setVideoProfile(videoProfile);
    }

    emitLocalStats = async () => {
      const audioStatsKeysToManipulate = ['RecordingLevel', 'SendBitrate', 'SendLevel'];
      const { uid } = this.connectionDetails;
      this.localAudioStatsInterval = setInterval(() => {
        this.client.getLocalAudioStats((response) => {
          if (response) {
            let data = [];
            data.push(Date.now());
            data.push(this.isMute ? 1 : 0);
            if (response[uid]) {
              data = [...data, ...audioStatsKeysToManipulate.map((key) => response[uid][key])];
            } else {
              data = [...data, '', '', ''];
            }
            data.push(this.uplinkQuality);
            data.push(this.downlinkQuality);
            data.push(this.isVideoMute ? 0 : 1);
            this.emit('localAudioStats', data);
          }
        },
        () => {
          this.onError(AGORA_RTC_ERRORS[106]);
        });
      }, AUDIO_STATS_INTERVAL);
    };

    listenConnectionStateChange = () => {
      this.client.on('connection-state-change', (state) => {
        this.connectionStatus(state);
      });
    }

    listenNetworkQuality = () => {
      this.client.on('network-quality', ({ uplinkNetworkQuality, downlinkNetworkQuality }) => {
        this.uplinkQuality = uplinkNetworkQuality;
        this.downlinkQuality = downlinkNetworkQuality;
      });
    }

    listenAudioTrackEnded = () => {
      this.client.on('audio-track-ended', (err) => {
        this.onError(AGORA_RTC_ERRORS[108], err);
      });
    }

    listenAudioStatus = () => {
      this.client.on('mute-audio', (evt) => {
        if (this.onAudioMute) this.remoteAudioStatus(evt);
        this.remoteStreams = [...this.remoteStreams.map((stream) => {
          if (stream.id !== evt.id) {
            return { ...stream, mute: true };
          }
          return stream;
        })];
        this.onStreamUpdated(this.remoteStreams);
      });
      this.client.on('unmute-audio', (evt) => {
        if (this.onAudioMute) this.remoteAudioStatus(evt);
        this.remoteStreams = [...this.remoteStreams.map((stream) => {
          if (stream.id !== evt.id) {
            return { ...stream, mute: false };
          }
          return stream;
        })];
        this.onStreamUpdated(this.remoteStreams);
      });
    }

    listenStreamAdd = () => {
      this.client.on('stream-added', (evt) => {
        const remoteStream = evt.stream;
        const id = remoteStream.getId();
        if (id !== this.connectionDetails.uid) {
          this.remoteStreams = [...this.remoteStreams.filter((stream) => stream.id !== id), { id, remoteStream, mute: false }];
          this.onStreamUpdated(this.remoteStreams);
          this.client.subscribe(remoteStream, (err) => {
            if (err) {
              this.onError(AGORA_RTC_ERRORS[107], err);
            }
          });
        }
      });
    }

    listenStreamRemove = () => {
      this.client.on('stream-removed', (evt) => {
        const remoteStream = evt.stream;
        const id = remoteStream.getId();
        remoteStream.stop(this.getAudioElementId(id));
        this.remoteStreams = [...this.remoteStreams.filter((stream) => stream.id !== id)];
        this.onStreamUpdated(this.remoteStreams);
        this.removeAudioElement(id);
      });
    }

    listenStreamSubscription = () => {
      this.client.on('stream-subscribed', (evt) => {
        const remoteStream = evt.stream;
        const id = remoteStream.getId();
        const audioElement = this.createAudioElement(id);
        if (audioElement) {
          remoteStream.play(this.getAudioElementId(id));
        }
      });
    }

    createAudioElement = (id) => {
      const audioElement = document.getElementById(this.getAudioElementId(id));
      if (audioElement) return audioElement;
      // if not in dom create new element
      const audioContainer = document.querySelector('.webrtc-container');
      if (!audioContainer) return null;
      const ele = document.createElement('div');
      ele.setAttribute('id', this.getAudioElementId(id));
      audioContainer.appendChild(ele);
      return ele;
    }

    getAudioElementId = (id) => `remote_audio_${id}`;

    removeAudioElement = (id) => {
      const audioContainer = document.querySelector('.webrtc-container');
      const audioElement = document.getElementById(`remote_audio_${id}`);
      if (audioContainer && audioElement) {
        try {
          audioContainer.removeChild(audioElement);
        } catch (e) {
          console.log(e);
        }
      }
    }

    listenStreamPublished = () => {
      this.client.on('stream-published', () => {
        // this.changeMicStatus(true);
        // this.changeVideoStatus(true);
        // this.isMute = false;
      });
    }

    listenOnMicrophoneChanged = () => {
      this.client.on('playout-device-changed', (evt) => {
        switch (evt.state) {
          case 'ACTIVE':
            this.handleOnCameraChanged(evt);
            // this.enableVideoStream();
            break;
          case 'INACTIVE':
            this.handleOnMicrophoneChanged(evt);
            // this.disableVideoStream();
            // if (this.localVideo) {
            //   this.localVideo.close();
            //   this.localVideo = null;
            // }
            break;
          default:
        }
        console.log('Playout Device Changed', evt.state, evt.device);
      });
    }

    listenOnCameraChanged = () => {
      this.client.on('camera-changed', (evt) => {
        switch (evt.state) {
          case 'ACTIVE':
            this.handleOnCameraChanged(evt);
            // this.enableVideoStream();
            break;
          case 'INACTIVE':
            this.handleOnCameraChanged(evt);
            // this.disableVideoStream();
            // if (this.localVideo) {
            //   this.localVideo.close();
            //   this.localVideo = null;
            // }
            break;
          default:
        }
        console.log('Camera Changed', evt.state, evt.device);
      });
    }

    publishStream = (retryCount) => {
      if (retryCount < 1) {
        this.changeMicStatus(false);
        this.changeVideoStatus(false);
        this.isMute = true;
        this.isVideoMute = true;
        this.onError(AGORA_RTC_ERRORS[105]);
        return;
      }
      // Publish the local stream
      this.client.publish(this.localStream, (err) => {
        this.onError(AGORA_RTC_ERRORS[104], err);
        this.publishStream(retryCount - 1);
      });
    }

    initLocalStream = () => {
      if (!this.localStream) {
        this.localStream = AgoraRTC.createStream({
          streamID: this.connectionDetails.uid,
          audio: this.options.audio || true,
          video: this.options.video || false,
          screen: this.options.screen || false,
        });
        this.localStream.setAudioProfile('speech_low_quality');
        this.setVideoProfile(this.localStream);
        this.localStream.init(
          () => {
            this.publishStream(RETRY_PUBLISH_AUDIO_THRESHOLD);
            setTimeout(() => {
              if (this.isMute) {
                this.disableAudioStream();
              }
              if (this.options.video && this.isVideoMute) {
                this.disableVideoStream();
              }
            }, 1000);
            if (this.options.video) {
              this.localStream.play('noon-agora-video');
            }
          },
          (err) => {

            if (err?.msg === 'NotAllowedError') {
              this.localStream = null;
              this.onError(AGORA_RTC_ERRORS[109], err);
              this.disableAudioStream();
              if (this.options.video) {
                this.disableVideoStream();
              }
            } else if (this.options.video && err?.msg === 'NotReadableError' && err?.info.indexOf('video') > -1) {
              this.localStream.stop();
              this.localStream.close();
              this.localStream = null;
              this.handleOnVideoInitError();
            } else {
              this.localStream = null;
              this.onError(AGORA_RTC_ERRORS[103], err);
            }
          });
      }
    }

    enableAudioStream = () => {
      if (!this.localStream) {
        this.isVideoMute = true;
        this.initLocalStream();
      } else if (this.localStream) {
        this.localStream.unmuteAudio();
      }
      this.changeMicStatus(true);
      this.isMute = false;
    }

    disableAudioStream = () => {
      if (this.localStream) {
        this.localStream.muteAudio();
      }
      this.changeMicStatus(false);
      this.isMute = true;
    }

    enableVideoStream = () => {
      if (!this.localStream) {
        this.isMute = true;
        this.initLocalStream();
      } else if (this.localStream) {
        this.localStream.unmuteVideo();
      }
      this.changeVideoStatus(true);
      this.isVideoMute = false;
    }

    disableVideoStream = () => {
      if (this.localStream) {
        this.localStream.muteVideo();
      }
      this.changeVideoStatus(false);
      this.isVideoMute = true;
    }

    switchDevice = (type, deviceId) => {
      if (this.localStream) {
        const videoTracks = this.localStream.getVideoTrack();
        const audioTracks = this.localStream.getAudioTrack();
        if (type === 'video' && videoTracks) {
          videoTracks.stop();
        } else if (type === 'audio') {
          audioTracks.stop();
        }
        this.localStream.switchDevice(type, deviceId);
      }
    }

    listenVolumeChange = () => {
      this.client.enableAudioVolumeIndicator();
      const volumeMap = {};
      this.client.on('volume-indicator', (volumes) => {
        if (this.isMute && !this.remoteStreams.length) return;
        if (volumes.attr && volumes.attr.length) {
          volumes.attr.forEach((volume) => {
            volumeMap[volume.uid] = volume.level;
          });
        }
        if (this.localStream) {
          const volume = this.localStream.getAudioLevel();
          volumeMap[this.connectionDetails.uid] = volume * 100;
        }
        this.emit('volume-indicator', volumeMap);
      });
    }

    async disconnect() {
      if (this.localAudioStatsInterval) clearInterval(this.localAudioStatsInterval);
      if (this.localStream) {
        this.localStream.stop();
        this.localStream.close();
        this.localStream = null;
      }
      this.client.leave();
      // if (this.options.enableProxy) {
      //   this.client.stopProxyServer();
      // }
    }
}

import {
  GET_EMOJI,
  UPLOAD_FILE,
  COMPETITION_EMOJI,
  UPLOAD_IMAGE_PROGRESS,
  RESET_UPLOAD_IMAGE_PROGRESS,
  BULK_FILE_UPLOAD,
  UPLOAD_VIDEO,
} from '../constants';

const initialState = {
  emojis: {},
  uploadImageUrls: {},
  competitionEmojis: [],
  imageUploadProgressStatus: [],
  error: {},
  bulkFilesUploaded: [],
  isBulkFileUploading: false,
  uploadVideoUrls: {},
};

export default function file(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_VIDEO.SUCCESS:
      return {
        ...state,
        uploadVideoUrls: action.payload,
      };
    case UPLOAD_VIDEO.FAILURE:
      return {
        ...state,
        error: { uploadVideoUrls: action.payload },
      };
    case UPLOAD_FILE.SUCCESS:
      return {
        ...state,
        uploadImageUrls: action.payload,
      };
    case UPLOAD_FILE.FAILURE:
      return {
        ...state,
        error: { uploadImageUrls: action.payload },
      };
    case GET_EMOJI.SUCCESS:
      return {
        ...state,
        emojis: action.payload,
      };
    case GET_EMOJI.FAILURE:
      return {
        ...state,
        error: { emojis: action.payload },
      };
    case COMPETITION_EMOJI.SUCCESS:
      return {
        ...state,
        competitionEmojis: action.payload,
      };
    case COMPETITION_EMOJI.FAILURE:
      return {
        ...state,
        error: { competitionEmojis: action.payload },
      };
    case BULK_FILE_UPLOAD.REQUEST:
      return {
        ...state,
        isBulkFileUploading: true,
      };
    case BULK_FILE_UPLOAD.SUCCESS:
      return {
        ...state,
        isBulkFileUploading: action.remove === 1 ? state.isBulkFileUploading : false,
        bulkFilesUploaded:
          action.payload === 'clear'
            ? []
            : action.remove === 1
              ? action.payload
              : [...state.bulkFilesUploaded, ...action.payload],
      };
    case UPLOAD_IMAGE_PROGRESS:
      return {
        ...state,
        imageUploadProgressStatus: state.imageUploadProgressStatus.find((item) => item.id === action.payload.id)
          ? state.imageUploadProgressStatus.map((item) => {
            if (item.id !== action.payload.id) {
              return item;
            }
            // Otherwise, this is the one we want - return an updated value
            return {
              ...item,
              ...action.payload,
            };
          })
          : state.imageUploadProgressStatus.concat(action.payload),
      };
    case RESET_UPLOAD_IMAGE_PROGRESS:
      return {
        ...state,
        imageUploadProgressStatus: [],
      };
    default:
      return state;
  }
}

/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import merge from 'lodash-es/merge';
import { NoonLoader } from '@noon/atom';
import isEmpty from 'lodash-es/isEmpty';
import { TrackSentryError, SentryType } from 'SentryAlias';
import Mixpanel from '../../components/Mixpanel';
import { RTL_LOCALE, GROUPS, localesForTranslation, EXPERIMNET_ENTITY_TYPES } from '../../constants';
import {
  GET_TRANSLATION,
  REFRESH_TOKEN,
  GET_TRANSLATION_ON_TIMESTAMP,
  COMMON_LOGIN,
  LIST_COUNTRY,
  SERVER_TIME,
  GET_FEATURE_FLAG,
} from '../../redux/constants';
import Toast from '../../components/Toast';
import { generateLocalizedUrl, zohoIntegration, getQueryObject, objToQuery, getLocaleName, generateTokenPayload } from '../../helpers';
import AuthContext from './context';
import SeoMeta from '../../components/Seo/seoMeta';
import { IncorrectTimeModal } from '../../components/Modals';
import { Sprig } from '../../helpers/sprig';

const TIME_TO_REQUEST_REFRESH_TOKEN_BEFORE_EXPIRY = 60 * 1000; // 1 min

class AuthProvider extends Component {
  constructor(props) {
    super(props);

    this.oldStorageCleanup();
    this.getCountryList();
    this.analyticsSetup();

    this.state = {
      isSettled: false,
      language: localStorage.language || 'en',
      localizedUrlPrefix: '',
    };
  }

  componentDidMount() {
    const { location, getServerTime } = this.props;
    getServerTime();
    zohoIntegration(location.pathname);
  }

  componentDidUpdate(prevProps) {
    const { tokenData, user, location, countries, getFeatureFlag, history, redirectUrl, updateCountries } = this.props;
    const { isSettled, localizedUrlPrefix } = this.state;

    if (!isEmpty(tokenData) && !isSettled && user.id) {
      this.setState({ isSettled: true });
    }

    if (user !== prevProps.user && user.id) {
      getFeatureFlag({
        featureFlagName: 'is_unified_group_teacher',
        entityType: EXPERIMNET_ENTITY_TYPES.USER,
        entityId: user.id,
      });
    }

    if (!isEmpty(tokenData) && prevProps.tokenData !== tokenData) {
      this.setIntervalForRefreshAndAccessToken(tokenData.a_tkn);
    }

    if (!isEmpty(countries) && countries !== prevProps.countries && countries.selectedCountry && !isSettled) {

      const localeName = getLocaleName(location.pathname);
      // force localization
      if (localeName && !localizedUrlPrefix) {
        const [code, lang] = localeName.split('-');
        const { country_id } = localStorage.loggedUser ? JSON.parse(localStorage.loggedUser) : {};
        const selectedCountry = country_id ? countries.countryList.find((item) => String(item.id) === String(country_id)) : countries.countryList.find((item) => item.iso_code.toLowerCase() === code);
        this.setState({ localizedUrlPrefix: `${code.toLowerCase()}-${lang.toLowerCase()}` });
        delete localStorage.translationData;
        localStorage.language = lang.toLowerCase();
        localStorage.country = JSON.stringify({ countryList: countries.countryList, selectedCountry });
        updateCountries({ countryList: countries.countryList, selectedCountry });
        return;
      }

      this.setLocalization(countries.selectedCountry, location);
      this.authenticate();
    }

    if (prevProps.location.pathname !== location.pathname && isSettled) {
      zohoIntegration(location.pathname);
      const url = generateLocalizedUrl(location.pathname, localizedUrlPrefix);
      if (url) {
        history.replace({
          pathname: url,
          search: location.search,
        });
      }
    }

    // redirect to url using dispatch action
    if (!isEmpty(redirectUrl) && redirectUrl !== prevProps.redirectUrl) {
      history.push(redirectUrl);
    }

    // Sprig init user attribute
    if (user.id) {
      Sprig.setUserId(user.id);
      if (user.email) {
        Sprig.setEmail(user.email);
      }
    }
  }

  oldStorageCleanup = () => {
    localStorage.removeItem('userLoggedOut');
    localStorage.removeItem('eT');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('globalText');
  };

  setIntervalForRefreshAndAccessToken = (accessToken = {}) => {
    if (this.refreshTokenTime) clearTimeout(this.refreshTokenTime);
    const { timeDifference, refreshAccessToken } = this.props;
    const { exp } = accessToken;
    if (!exp) return;
    const refreshTime = (exp - (Date.now() + timeDifference)) - TIME_TO_REQUEST_REFRESH_TOKEN_BEFORE_EXPIRY;
    this.refreshTokenTime = setTimeout(() => {
      refreshAccessToken();
    }, refreshTime);
  }

  analyticsSetup = () => {
    if (process.env.ENV !== 'production') {
      console.log('%c ------------- BUILD INFO -----------', 'background: #fff; color: #64D8AE');
      console.log(`%c BUILD  NUMBER = ${process.env.BUILD_NUMBER || ''}     `, 'background: #64D8AE; color: #fff');
      console.log(`%c BRANCH NAME   = ${process.env.BRANCH || ''}       `, 'background: #64D8AE; color: #fff');
      console.log(`%c GIT HASH      = ${process.env.GIT_HASH || ''}  `, 'background: #64D8AE; color: #fff');
      console.log(`%c LAST UPDATED  = ${process.env.LAST_UPDATED ? new Date(Number(process.env.LAST_UPDATED)) : ''} `, 'background: #64D8AE; color: #fff');
    } else {
      console.log('Build  version', process.env.VERSION || '0.0.0');
    }

    Mixpanel.register({ version: process.env.VERSION, role_type: 'teacher' });
    const utmTags = getQueryObject(window.location.search, 'utm_');
    if (!isEmpty(utmTags)) {
      Mixpanel.register({ ...utmTags });
    }

    try {
      // sending logger api error to track js
      if (window.NoonLoggingWorker) {
        window.NoonLoggingWorker.addEventListener('message', (msg) => {
          TrackSentryError('Logger_Api_Error', msg.data, SentryType.ERROR);
        });
      }
    } catch (e) {
      console.error(e);
    }

  }

  getCountryList = () => {
    const { updateCountries, getCountries } = this.props;
    if (localStorage.country) {
      updateCountries(JSON.parse(localStorage.country));
    }
    getCountries();
  };

  authenticate = () => {
    const { commonLogin } = this.props;
    const tokenData = localStorage.tokenData ? JSON.parse(localStorage.tokenData) : {};
    generateTokenPayload(); // it will generate temporaryTokenPayload if not there (TODO: fix whole temporarytokenpayload logic)
    if (!tokenData.a_tkn || !tokenData.r_tkn) {
      localStorage.removeItem('tokenData');
      this.setState({ isSettled: true });
      return;
    }
    commonLogin(tokenData);
  };

  setLocalization = (selectedCountry = {}, location) => {
    const { iso_code = '', locale = 'ar' } = selectedCountry;

    const language = localStorage.language === 'en' ? 'en' : locale;
    localStorage.setItem('language', language);
    const localizedUrlPrefix = `${iso_code.toLowerCase()}-${language.split('_')[0]}`;
    const presentLocaleTranslation = localesForTranslation[localizedUrlPrefix];
    this.setLayoutDirection(language);
    this.handleTranslation(presentLocaleTranslation);

    this.setState({ localizedUrlPrefix });
    localStorage.updatedLocale = localizedUrlPrefix;
    // remove extra params and generate localized url
    this.cleanUrl(location, localizedUrlPrefix);
  }

  cleanUrl = (location, localizedUrlPrefix) => {
    const url = generateLocalizedUrl(location.pathname, localizedUrlPrefix);
    const { history } = this.props;
    const { a_tkn, r_tkn, a_tkn_exp, r_tkn_exp, device_id, locale, ...rest } = getQueryObject(location.search, '=');
    if (url) {
      history.replace({
        pathname: url,
        search: objToQuery(rest, '?'), // remove token params
      });
    }
  }

  async handleTranslation(presentLocaleTranslation) {
    const { updateTranslation } = this.props;
    const myModule = await import(/* webpackChunkName: "translation" */ `../../translation/${presentLocaleTranslation || 'en'}.json`);

    // TODO: remove dependency on localstorage
    if (localStorage.translationData) {
      const myModuleTimeStamp = myModule.date;
      const myModuleData = myModule.data;
      const localStorageData = JSON.parse(localStorage.getItem('translationData')).data;
      const localStorageTimeStamp = JSON.parse(localStorage.getItem('translationData')).date;
      let updatedData;
      if (localStorageTimeStamp > myModuleTimeStamp) {
        updatedData = merge({}, myModuleData, localStorageData);
      } else {
        updatedData = merge({}, localStorageData, myModuleData);
      }
      updateTranslation(updatedData);
      this.getTranslation(localStorageTimeStamp);
      localStorage.setItem(
        'translationData',
        JSON.stringify({
          data: updatedData,
          date: localStorageTimeStamp,
          locale: presentLocaleTranslation,
        }),
      );
    } else {
      localStorage.setItem('translationData', JSON.stringify(myModule));
      updateTranslation(myModule.data);
      this.getTranslation(myModule.date);
    }
  }

  getTranslation = (localStorageTimeStamp) => {
    const { getTranslationsBasedOnTimeStamp } = this.props;
    getTranslationsBasedOnTimeStamp({
      groups: GROUPS,
      date: localStorageTimeStamp,
    });
  };

  setLayoutDirection = (lang) => {
    if (RTL_LOCALE.includes(lang)) {
      document.body.dir = 'rtl';
    } else {
      document.body.dir = 'ltr';
    }
  };

  render() {
    const { isSettled, language } = this.state;
    const { user, invalidTimeModal, children } = this.props;
    return (
      <React.Fragment>
        <Toast />
        <SeoMeta />
        {invalidTimeModal && <IncorrectTimeModal />}
        {!isSettled && <NoonLoader />}
        {isSettled && (
          <AuthContext.Provider value={{ language }} isAuthenticated={!isEmpty(user)}>{children}</AuthContext.Provider>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.toJS().user.loggedUser,
  countries: state.toJS().folder.countries,
  tokenData: state.toJS().biFrost.tokenData,
  timeDifference: state.toJS().user.timeDifference,
  serverTime: state.toJS().user.serverTime,
  redirectUrl: state.toJS().dashboard.redirectUrl,
  invalidTimeModal: state.toJS().dashboard.invalidTimeModal,
});

const mapDispatchToProps = (dispatch) => ({
  getCountries: () => dispatch({ type: LIST_COUNTRY.REQUEST }),
  updateTranslation: (data) => dispatch({ type: GET_TRANSLATION.SUCCESS, payload: data }),
  getTranslationsBasedOnTimeStamp: (payload) => dispatch({ type: GET_TRANSLATION_ON_TIMESTAMP.REQUEST, payload }),
  refreshAccessToken: () => dispatch({ type: REFRESH_TOKEN.REQUEST }),
  commonLogin: (payload) => dispatch({ type: COMMON_LOGIN, payload }),
  getServerTime: () => dispatch({ type: SERVER_TIME.REQUEST }),
  updateCountries: (countries) => dispatch({ type: LIST_COUNTRY.SUCCESS, payload: countries }),
  getFeatureFlag: (payload) => dispatch({ type: GET_FEATURE_FLAG.REQUEST, payload }),
});

AuthProvider.propTypes = {
  children: PropTypes.element,
  tokenData: PropTypes.shape(),
  user: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  refreshAccessToken: PropTypes.func.isRequired,
  updateTranslation: PropTypes.func.isRequired,
  countries: PropTypes.shape().isRequired,
  timeDifference: PropTypes.number.isRequired,
  history: PropTypes.shape().isRequired,
  commonLogin: PropTypes.func.isRequired,
  updateCountries: PropTypes.func.isRequired,
  getCountries: PropTypes.func.isRequired,
  getTranslationsBasedOnTimeStamp: PropTypes.func.isRequired,
  getServerTime: PropTypes.func.isRequired,
  redirectUrl: PropTypes.shape().isRequired,
  invalidTimeModal: PropTypes.bool,
  getFeatureFlag: PropTypes.func.isRequired,
};

AuthProvider.defaultProps = {
  children: null,
  tokenData: null,
  invalidTimeModal: false,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthProvider));

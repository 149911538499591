import React from 'react';
import classNames from 'classnames';
import { InfiniteScroll } from '@noon/atom';
import FriendActivity from './friendActivity';


export default function NotificationList(prop) {
  return (
    <React.Fragment>
      <InfiniteScroll
        onLoad={prop.loadMore}
        offset={100}
        loadMore={prop.notifications.offset !== -1}
        loading={prop.isLoading}
        className={classNames('notification-list')}
      >
        {!!prop.notifications.list.length && <FriendActivity {...prop} />}
      </InfiniteScroll>
    </React.Fragment>
  );
}

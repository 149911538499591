import React, { useState, useEffect } from 'react';
import { isMobileOnly, isMobile } from 'react-device-detect';
import classNames from 'classnames';
import get from 'lodash-es/get';
import { useDispatch, useSelector } from 'react-redux';
import { Column, Checkbox, Card, Row, Button } from '@noon/atom';
import { IconArrowDown, IconFilter } from '@noon/quark';
import { Accordian } from '../../Curriculum';
import { translationText } from '../../../helpers';

import { GET_CHAPTERS_FROM_GROUP, GET_TEACHER_GROUP_TOPICS } from '../../../redux/constants';

const QuestionFilter = (prop) => {
  const dispatch = useDispatch();
  const [allChapters, setAllChapters] = useState(true);
  const [showFilters, setShowFilters] = useState(!isMobileOnly);
  const { noonText } = useSelector((state) => state.toJS().translation);

  const { groupChapters, curriculumList } = useSelector((state) => state.toJS().groupsV2);
  const { getFilterCount } = useSelector((state) => state.toJS().feedQuestion);
  const { groupId, filterPart, filterApplied, questionFilters } = prop;

  useEffect(() => {
    dispatch({
      type: GET_CHAPTERS_FROM_GROUP.REQUEST,
      payload: groupId,
    });
  }, []);

  useEffect(() => {
    if (filterApplied && !filterApplied.chapter && !allChapters) {
      setAllChapters(true);
    }
  }, [filterApplied]);

  const selectTopicAndChapter = (chapter, topic) => {
    setAllChapters(false);
    filterPart({ ...filterApplied, chapter, topic });
  };

  const changeFilter = (filterSelected) => {
    filterPart({ ...filterApplied, type: filterSelected });
  };

  const handleChapterSelection = (chapter) => {
    setAllChapters(false);
    filterPart({ ...filterApplied, chapter, topic: false });
  };

  const handleExpandChapter = (chapterId, accordianState) => {
    if (accordianState) {
      dispatch({
        type: GET_TEACHER_GROUP_TOPICS.REQUEST,
        payload: { groupId, chapterId },
      });
    }
  };

  const allChapterFilter = () => {
    setAllChapters(!allChapters);
    filterPart({ type: filterApplied.type });
  };

  const clearFilters = () => {
    filterPart({ type: questionFilters[0] });
    setAllChapters(true);
  };

  return (
    <Card className="curriculum-content--menu question-filters">
      <div className="curriculum-content--header">
        <Row justify="space-between" align="start">
          <div>
            <IconFilter width="12.591" height="12.909" />
            <span className="mlr-1 curriculum-content--header__title">{translationText(noonText, 'filter.title')}</span>
            {isMobile
              && <IconArrowDown onClick={() => setShowFilters(!showFilters)} height="20px" width="20px" fill="#62687A" stroke="#62687A" />}
          </div>
          <Button link size="md" onClick={clearFilters}>
            {translationText(noonText, 'filter.clear')}
          </Button>
        </Row>
      </div>
      {showFilters
      && (
      <React.Fragment>
        <Column className="filter-options">
          {getFilterCount
          && !getFilterCount.isLoading
          && questionFilters.map((filter) => (
            <Checkbox
              name="checkbox-1"
              type="checkbox"
              checked={filter.title === filterApplied.type.title}
              onChange={() => changeFilter(filter)}
              label={`${filter.title} (${getFilterCount.response[filter.filterCountId]})`}
              className="mtb-1"
            />
          ))}
        </Column>
        <Column className="curriculum-content--chapters question-filters--chapters">
          <Checkbox
            id="allChapters"
            name="checkbox-all-chapters"
            type="checkbox"
            checked={allChapters}
            onChange={() => allChapterFilter(!allChapters)}
            label={translationText(noonText, 'filter.allChapters')}
            className="mtb-1"
        />
          {!!groupChapters.list.length && groupChapters.list.map((chapter) => (
            <Accordian
              type="checkbox"
              data={chapter}
              initialState={false}
              selectCheckBox={() => handleChapterSelection(chapter)}
              togglingAccordian={(accordianState) => {
                handleExpandChapter(chapter.chapter_id, accordianState);
              }}
              loadingAccordian={
              curriculumList.topics
              && curriculumList.topics[chapter.chapter_id]
              && curriculumList.topics[chapter.chapter_id].loading
            }
              checked={get(filterApplied, ['chapter', 'name']) === chapter.name}
              hideExpandButton={get(chapter, 'type') !== 'chapter'}
          >
              {curriculumList.topics
              && curriculumList.topics[chapter.chapter_id]
              && curriculumList.topics[chapter.chapter_id].data
              && curriculumList.topics[chapter.chapter_id].data.map((topic) => (
                <Column
                  onClick={() => selectTopicAndChapter(chapter, topic)}
                  className={classNames('topic', { activeTopic: get(filterApplied, ['topic', 'name']) === topic.name })}
                >
                  <div className="topic__title">{topic.name_header}</div>
                  <div className="topic__name">{topic.name}</div>
                </Column>
              ))}
            </Accordian>
          ))}
        </Column>
      </React.Fragment>
      )}
    </Card>
  );
};

export default QuestionFilter;

import React from 'react';

function SvgStyle() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ position: 'absolute', width: '0', height: '0' }} id="__SVG_SPRITE_NODE__">
      <symbol xmlns="http://www.w3.org/2000/svg" id="icons">
        <defs>
          <linearGradient id="icons_gradient-blue" x1="50%" y1="0%" x2="50%" y2="100%">
            <stop stopColor="#4FC1E9" offset="0%" />
            <stop stopColor="#48BDE6" offset="10%" />
            <stop stopColor="#41B9E4" offset="20%" />
            <stop stopColor="#3BB6E1" offset="30%" />
            <stop stopColor="#34B2DF" offset="40%" />
            <stop stopColor="#2DAEDD" offset="50%" />
            <stop stopColor="#26AADA" offset="60%" />
            <stop stopColor="#1FA6D7" offset="70%" />
            <stop stopColor="#19A2D5" offset="80%" />
            <stop stopColor="#129FD2" offset="90%" />
            <stop stopColor="#0B9BD0" offset="100%" />
          </linearGradient>
          <linearGradient id="icons_gradient-blue-green" x1=".5" x2=".5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#01c3f9" />
            <stop offset=".1" stopColor="#07c8f4" />
            <stop offset=".2" stopColor="#0ecdef" />
            <stop offset=".3" stopColor="#14d2ea" />
            <stop offset=".4" stopColor="#1ad7e5" />
            <stop offset=".5" stopColor="#20dde1" />
            <stop offset=".6" stopColor="#27e2dc" />
            <stop offset=".7" stopColor="#2de7d7" />
            <stop offset=".8" stopColor="#33ecd2" />
            <stop offset=".9" stopColor="#3af1cd" />
            <stop offset="1" stopColor="#40f6c8" />
          </linearGradient>
          <linearGradient id="icons_gradient-green" x1="50%" y1="0%" x2="50%" y2="100%">
            <stop stopColor="#A0D468" offset="0%" />
            <stop stopColor="#9BD061" offset="10%" />
            <stop stopColor="#96CC5B" offset="20%" />
            <stop stopColor="#91C954" offset="30%" />
            <stop stopColor="#8CC54E" offset="40%" />
            <stop stopColor="#87C147" offset="50%" />
            <stop stopColor="#82BD41" offset="60%" />
            <stop stopColor="#7DBA3A" offset="70%" />
            <stop stopColor="#78B634" offset="80%" />
            <stop stopColor="#73B22D" offset="86.5%" />
            <stop stopColor="#6EAF27" offset="100%" />
          </linearGradient>
          <linearGradient id="icons_gradient-tahsili" x1="5.18425388%" y1="5.1%" x2="93.2187485%" y2="103.6%">
            <stop stopColor="#6B9296" offset="0%" />
            <stop stopColor="#36494B" offset="100%" />
          </linearGradient>
          <linearGradient id="icons_gradient-qudrat" x1="0.0938239168%" y1="0%" x2="89.7253162%" y2="94.9%">
            <stop stopColor="#656D78" offset="0%" />
            <stop stopColor="#606772" offset="10%" />
            <stop stopColor="#5A626D" offset="20%" />
            <stop stopColor="#555C67" offset="30%" />
            <stop stopColor="#4F5762" offset="40%" />
            <stop stopColor="#4A525C" offset="50%" />
            <stop stopColor="#454C56" offset="60%" />
            <stop stopColor="#3F4651" offset="70%" />
            <stop stopColor="#3A414B" offset="80%" />
            <stop stopColor="#343B46" offset="90%" />
            <stop stopColor="#2F3640" offset="100%" />
          </linearGradient>
          <linearGradient id="icons_gradient-yellow" x1=".5" x2=".5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#ffd97c" />
            <stop offset=".1" stopColor="#fed476" />
            <stop offset=".2" stopColor="#fdcf70" />
            <stop offset=".3" stopColor="#fcca6a" />
            <stop offset=".4" stopColor="#fbc565" />
            <stop offset=".5" stopColor="#fbc05f" />
            <stop offset=".6" stopColor="#fabb59" />
            <stop offset=".7" stopColor="#f9b653" />
            <stop offset=".8" stopColor="#f8b14e" />
            <stop offset=".9" stopColor="#f7ac48" />
            <stop offset="1" stopColor="#f6a742" />
          </linearGradient>
          <linearGradient id="icons_gradient-red" x1="0.5" y1="0.884" x2="0.5" y2="0.091" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#fff" stopOpacity="0" />
            <stop offset="0.567" stopColor="#ed1c24" />
          </linearGradient>

          <clipPath id="icons_squircle-36px" viewBox="0 0 36 36">
            <path d="M18.5131613,0.997999137 C15.3830052,0.997714038 12.2581501,1.25566822 9.17032627,1.76924234 C5.30630354,2.41129251 2.61035246,4.96905336 1.88378407,8.82787929 C1.29232845,11.9797468 0.995118622,15.1798655 0.995997471,18.3868579 L0.995997471,18.4812516 C1.0046449,21.6645703 1.27746687,24.8416679 1.81164868,27.9797661 C2.47868365,31.9543004 5.30369624,34.6138496 9.25767092,35.244155 C12.3516456,35.7424841 15.4802103,35.9945546 18.613977,35.9979985 L18.6404846,35.9979985 C21.589054,35.9973693 24.5332777,35.7715322 27.4475189,35.3224538 C31.8677662,34.6429941 34.6749622,31.7629032 35.3467771,27.3585957 C35.7737371,24.4239476 35.9906955,21.4625137 35.9959955,18.4969112 C35.9888448,15.8248656 35.8175987,13.1557872 35.4832261,10.5047786 C34.7953327,5.16045043 32.193679,2.36126827 26.9734243,1.59872494 C24.2340683,1.19834659 21.4692888,0.997576932 18.7008871,0.997999137 L18.5131613,0.997999137 Z" />
          </clipPath>
          <clipPath id="icons_squircle-42px" viewBox="0 0 42 42">
            <path d="M21.0156135,0.997999232 C17.4382923,0.997673404 13.8670293,1.29247818 10.3380878,1.87942004 C5.92206182,2.61319166 2.84097488,5.53634691 2.01061101,9.9464337 C1.33466172,13.5485679 0.99499335,17.2058465 0.995997749,20.8709807 L0.995997749,20.9788592 C1.00588053,24.6169377 1.31767706,28.2479064 1.92817056,31.8343044 C2.69049624,36.3766293 5.91908206,39.416114 10.4379103,40.136463 C13.9738813,40.705982 17.5493838,40.9940626 21.1308315,40.9979985 L21.1611258,40.9979985 C24.5309195,40.9972795 27.8957466,40.7391799 31.226308,40.2259474 C36.2780192,39.449422 39.4862432,36.1578896 40.2540316,31.1243953 C40.7419859,27.7705117 40.9899383,24.3860158 40.9959955,20.9967559 C40.9878233,17.9429895 40.7921134,14.8926142 40.4099733,11.8628901 C39.6238094,5.75508642 36.650491,2.5560211 30.6844855,1.684543 C27.553793,1.22696775 24.394045,0.997516712 21.2301573,0.997999232 L21.0156135,0.997999232 Z" />
          </clipPath>
          <clipPath id="icons_squircle-72px" viewBox="0 0 72 72">
            <path d="M36.030327,0.997999802 C29.7700149,0.997429603 23.5203046,1.51333796 17.344657,2.54048621 C9.61661154,3.82458655 4.22470939,8.94010824 2.77157262,16.6577601 C1.58866136,22.961495 0.994241717,29.3617325 0.995999415,35.7757174 L0.995999415,35.9645047 C1.01329428,42.3311421 1.55893822,48.6853373 2.62730184,54.9615338 C3.96137178,62.9106023 9.61139695,68.2297007 17.5193463,69.4903114 C23.7072956,70.4869697 29.9644251,70.9911107 36.2319585,70.9979985 L36.2849736,70.9979985 C42.1821125,70.9967402 48.07056,70.545066 53.8990423,69.646909 C62.7395369,68.2879897 68.3539289,62.5278079 69.6975587,53.7191928 C70.5514786,47.8498966 70.9853954,41.9270288 70.9959955,35.995824 C70.9816941,30.6517328 70.6392018,25.3135759 69.9704566,20.0115587 C68.5946698,9.32290238 63.3913625,3.72453807 52.950853,2.1994514 C47.472141,1.3986947 41.942582,0.997155392 36.4057786,0.997999802 L36.030327,0.997999802 Z" />
          </clipPath>
          <clipPath id="icons_squircle-96px" viewBox="0 0 96 96">
            <path d="M48.0420978,0.998000257 C39.6353929,0.997234562 31.2429249,1.69002579 22.9499124,3.06933915 C12.5722513,4.79370246 5.331697,11.6631173 3.38034191,22.0268212 C1.79186108,30.4918367 0.99364041,39.0864413 0.996000747,47.6995067 L0.996000747,47.9530211 C1.01922528,56.5025057 1.75194714,65.035282 3.18660686,73.4633173 C4.97807221,84.1377808 12.5652489,91.28057 23.1844951,92.9733902 C31.4940271,94.3117598 39.8964581,94.9887491 48.3128601,94.9979985 L48.3840518,94.9979985 C56.3030669,94.9963088 64.2104106,94.3897749 72.0372298,93.1836784 C83.9087511,91.3588438 91.4480775,83.6237426 93.2523804,71.7950309 C94.3990728,63.9134046 94.9817611,55.9598392 94.9959955,47.9950785 C94.9767908,40.8187274 94.5168726,33.6503453 93.6188433,26.5304937 C91.7713582,12.1771552 84.7840598,4.65935165 70.763947,2.61137812 C63.4068195,1.53607627 55.9814117,0.996866335 48.5462757,0.998000257 L48.0420978,0.998000257 Z" />
          </clipPath>
          <clipPath id="icons_squircle-126px" viewBox="0 0 126 126">
            <path d="M63.0568113,0.998000827 C51.9671155,0.996990761 40.8962002,1.91088557 29.9564816,3.73040532 C16.266801,6.00509735 6.71543151,15.0668786 4.14130352,28.7381477 C2.04586072,39.9047638 0.992888777,51.2423273 0.996002413,62.6042434 L0.996002413,62.9386666 C1.02663903,74.2167101 1.99320829,85.472713 3.88573814,96.5905467 C6.24894774,110.671754 16.2575638,120.094157 30.2659312,122.327239 C41.2274414,124.092747 52.3114993,124.985797 63.4139871,124.997998 L63.5078995,124.997998 C73.9542599,124.99577 84.3852239,124.195661 94.7099641,122.60464 C110.370269,120.197411 120.315763,109.993661 122.695907,94.3898285 C124.208566,83.9927895 124.977218,73.5008522 124.995996,62.9941466 C124.970662,53.5274706 124.363961,44.0713071 123.179327,34.6791624 C120.742219,15.7449711 111.524931,5.82786862 93.0303145,3.12628652 C83.3251676,1.70780322 73.5299488,0.996505015 63.721897,0.998000827 L63.0568113,0.998000827 Z" />
          </clipPath>
          <filter id="icons_g-blur">
            <feGaussianBlur stdDeviation="10" />
          </filter>

          <filter id="icons_shadow" x="0" y="0" width="59" height="70.44" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
      </symbol>
    </svg>
  );
}

export default SvgStyle;

import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Row, Vr } from '@noon/atom';
import { translationText } from '../../helpers';
import { translationType } from '../../types';
import ErrorBoundry from '../ErrorBoundry';

function setActive(prevState, props) {
  const { location, activeTab, items } = props;
  if (location.pathname === '/') {
    return {
      activeTab: activeTab || items.findIndex(o => location.pathname === o.url),
    };
  }
  return {
    activeTab:
      activeTab || activeTab === 0
        ? activeTab
        : items.findIndex(o =>
          (o.exact
            ? o.url === location.pathname || `${o.url}/` === location.pathname
            : location.pathname.search(new RegExp(`${o.url}/|${o.url}$`)) !== -1),
        ),
  };
}

class NavigationMenu extends Component {
  static propTypes = {
    activeTab: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSelect: PropTypes.func,
    noonText: translationType,
    noUnderline: PropTypes.bool,
    seperator: PropTypes.bool,
    itemKey: PropTypes.string,
    isDisabled: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.shape(),
    sideIcon: PropTypes.bool,
    trackClick: PropTypes.func,
  };

  static defaultProps = {
    activeTab: null,
    onSelect: null,
    noonText: null,
    noUnderline: false,
    seperator: false,
    isDisabled: false,
    className: '',
    style: {},
    sideIcon: false,
    itemKey: 'label',
    trackClick: null,
  };

  constructor(props) {
    super(props);

    /* eslint-disable react/prop-types */
    this.state = setActive(null, this.props);
  }

  UNSAFE_componentWillReceiveProps({ location, activeTab, items }) {
    if (
      items !== this.props.items ||
      location.pathname !== this.props.location.pathname ||
      activeTab !== this.props.activeTab
    ) {
      this.setState(setActive);
    }
  }

  tabClicked = (item, isDisabled) => {
    if (!isDisabled) {
      if (this.props.onSelect) this.props.onSelect(item);
    }
  };

  render() {
    const { items, noonText, noUnderline, seperator, className, style, isDisabled, sideIcon, itemKey, onSelect, trackClick } = this.props;
    const { activeTab } = this.state;
    const itemWidth = 100 / items.length;
    const isRtl = document.body.dir === 'rtl';

    return (
      <ErrorBoundry>
        <Row align="center" justify="start" className={classNames('noon-navigation-menu', className)} style={style}>
          {items.map((item, index) => (
            <React.Fragment key={item.label || index}>
              <Link
                className={classNames('noon-navigation-menu__item', {
                  active: activeTab === index,
                  disabled: isDisabled || item.isDisabled,
                  sideIcon,
                })}
                to={!isDisabled && !item.isDisabled ? item.url || '#' : '#'}
                onClick={(e) => {
                  if (trackClick) trackClick(item);
                  if (onSelect && !item.isDisabled) {
                    e.preventDefault();
                    this.tabClicked(item, isDisabled);
                  }
                }}
              >
                {!!item.icon && item.activeIcon && activeTab === index ? <item.activeIcon /> : <item.icon />}
                {item[itemKey] && <span>{noonText ? translationText(noonText, item[itemKey]) : item[itemKey]}</span>}
              </Link>
              {items.length !== index + 1 && seperator && <Vr />}
            </React.Fragment>
          ))}
          {!noUnderline && (
            <span
              className="active-indicator"
              style={{ width: `${itemWidth}%`, transform: `translateX(${activeTab * (isRtl ? -100 : 100)}%)` }}
            />
          )}
        </Row>
      </ErrorBoundry>
    );
  }
}

export default withRouter(NavigationMenu);

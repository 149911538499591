import React from 'react';
import classNames from 'classnames';

export default function VoteAnimation(prop) {
  return (
    <div className="smiley-animation-container" tabIndex="-1">
      {prop.studentSupportArr.map((student, index) => (
        <div
          key={index}
          data-name={student.player_name}
          className={classNames('vote-animation', `vote-animation-${index % 5}`, {
            'no-pic': !student.player_profile_pic,
          })}
          style={{
            backgroundImage: `url(${student.player_profile_pic})`,
          }}
        >
          {!student.player_profile_pic && !!student.player_name && student.player_name[0]}
          {student.type !== 'competition_emoji' && (
            <span className="emoticon" role="img" aria-label="emoji">
              {student.emoji}
            </span>
          )}
          {student.type === 'competition_emoji' && (
            <div className="competition-emoticon">
              <img src={student.url} alt="emoji" />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

import {
  NOONERS_LOGIN,
  SIGNUP_TEACHER,
  GET_TEACHER_PROFILE,
  UPDATE_TEACHER_PROFILE,
  LOGGED_OUT_TEACHER,
  CLEAR_USER_SPECIFIC_USER_DATA,
} from '../constants';

const initialState = {
  noonersLogin: {
    response: {},
    isLoading: false,
  },
  signUpTeacher: {
    response: {},
    isLoading: false,
  },
  getTeacher: {
    response: {},
    isLoading: false,
  },
  updateTeacher: {
    response: {},
    isLoading: false,
  },
  loggedOutTeacher: {
    isLoading: false,
  },
};

export default function userv2(state = initialState, action) {
  switch (action.type) {
    case CLEAR_USER_SPECIFIC_USER_DATA:
      return {
        ...initialState,
      };
    case NOONERS_LOGIN.LOADING:
      return {
        ...state,
        noonersLogin: {
          ...state.noonersLogin,
          isLoading: action.payload.isLoading,
        },
      };
    case NOONERS_LOGIN.SUCCESS:
      return {
        ...state,
        noonersLogin: {
          ...state.noonersLogin,
          response: action.payload,
        },
      };
    case NOONERS_LOGIN.FAILURE:
      return {
        ...state,
        error: {
          noonersLogin: action.payload,
        },
      };
    case SIGNUP_TEACHER.LOADING:
      return {
        ...state,
        signUpTeacher: {
          ...state.signUpTeacher,
          isLoading: action.payload.isLoading,
        },
      };
    case SIGNUP_TEACHER.SUCCESS:
      return {
        ...state,
        signUpTeacher: {
          ...state.signUpTeacher,
          response: action.payload,
        },
      };
    case SIGNUP_TEACHER.FAILURE:
      return {
        ...state,
        error: {
          signUpTeacher: action.payload,
        },
      };
    case GET_TEACHER_PROFILE.LOADING:
      return {
        ...state,
        getTeacher: {
          ...state.getTeacher,
          isLoading: action.payload.isLoading,
        },
      };
    case GET_TEACHER_PROFILE.SUCCESS:
      return {
        ...state,
        getTeacher: {
          ...state.getTeacher,
          response: action.payload,
        },
      };
    case GET_TEACHER_PROFILE.FAILURE:
      return {
        ...state,
        error: {
          getTeacher: action.payload,
        },
      };
    case LOGGED_OUT_TEACHER.LOADING:
      return {
        ...state,
        loggedOutTeacher: {
          ...state.loggedOutTeacher,
          isLoading: action.payload.isLoading,
        },
      };
    case LOGGED_OUT_TEACHER.SUCCESS:
      return {
        ...state,
        getTeacher: {
          response: {},
        },
      };
    case LOGGED_OUT_TEACHER.FAILURE:
      return {
        ...state,
        error: {
          loggedOutTeacher: action.payload,
        },
      };
    case UPDATE_TEACHER_PROFILE.LOADING:
      return {
        ...state,
        updateTeacher: {
          ...state.updateStudnet,
          isLoading: action.payload.isLoading,
        },
      };
    case UPDATE_TEACHER_PROFILE.SUCCESS:
      return {
        ...state,
        updateTeacher: {
          ...state.updateTeacher,
          response: action.payload,
        },
      };
    case UPDATE_TEACHER_PROFILE.FAILURE:
      return {
        ...state,
        error: {
          updateTeacher: action.payload,
        },
      };
    default:
      if (action.type.indexOf('_REQUEST') !== -1) {
        return {
          ...state,
          error: {},
        };
      }
      return state;
  }
}

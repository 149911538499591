import {
  CLEAR_CLASSROOM,
  INIT_CLASSROOM,
  GET_SLIDES,
  ADD_SLIDE,
  MOVE_SLIDE,
  SELECT_SLIDE,
  DELETE_SLIDE,
  SET_CANVAS_DIMENTION,
  SET_QUESTION_TIMER,
  INTERACTIVE_QUESTION_START,
  INTERACTIVE_QUESTION_END,
  UPDATE_VOTE_CHOICE,
  SHOW_COMPETITON_RESULT,
  START_CLASSROOM,
  UPDATE_SLIDE_CONFIG,
  SHOW_CANVAS,
  MARK_SLIDE_AS_LOADING,
  UPDATE_SCREEN_SHOT,
  LOAD_SLIDE_URLS,
  SLIDE_TO_BE_ADDED,
  SLIDE_TO_BE_DELETED,
  GENERATE_PDF_SLIDE,
  UPDATE_PDF_SLIDE,
  RETRY_UPLOAD_SC,
  RETRY_UPLOAD_SC_SUCCESS,
  HIDE_PDF_SLIDE_MODAL,
} from '../constants';
import { CLASSROOM_STATE, CLASSROOM_TYPE } from '../../components/Classroom/types';

const initialState = {
  slides: [],
  slideUrls: {},
  screenshotRetries: {},
  maxSlideNumber: -1,
  slideConfig: {
    allowDelete: true,
    disableAddSlide: false,
    disableSelectSlide: false,
  },
  whiteboardSize: {
    width: 920,
    height: 517.5,
  },
  selectedSlide: -1,
  slideToBeAdded: {
    slideIndex: -1,
  },
  slideToBeDeleted: {
    slideIndex: -1,
  },
  isSlideLoading: -1,
  isSlideAdding: 0,
  activeSlideIndex: 0,
  slideSketches: [],
  result: [],
  questionTimer: {
    getReadyTimeLeft: 0,
    questionTimeLeft: 0,
  },
  showCanvas: false,
  pdfSlide: {
    isGeneratingPdfSlide: false,
    totalSlides: 0,
    slideInProcessIndex: -1,
    slideInProcessUrl: '',
    slideInProcessFailed: false,
  },
  error: {},
};

function updateSlide(slides, activeSlideIndex, paramsToUpdate = {}) {
  return [
    ...slides.slice(0, activeSlideIndex),
    { ...slides[activeSlideIndex], ...paramsToUpdate },
    ...slides.slice(activeSlideIndex + 1),
  ];
}

function addToSlideUrls(state, { id, urls, slideIndex }) {
  const { slideUrls } = state;
  slideUrls[id] = { snapshotUrl: urls,
    slideIndex,
  };
  return slideUrls;
}

function addToSlideArray(state, masterSlideData) { // change masterSlideData to slide later
  const slide = masterSlideData;
  if (state.isSlideAdding) {
    // Adding new slide
    const prevSlideIndex = state.slides.findIndex((slideData) => slideData.canvas_id === slide.prev_canvas_id);
    let slideData = [];
    if (!state.slides.length) {
      slideData.push(slide);
    } else if (prevSlideIndex === state.slides.length - 1) {
      slideData = [...state.slides.slice(0, prevSlideIndex + 1), slide];
    } else {
      slideData = [...state.slides.slice(0, prevSlideIndex + 1), slide, { ...state.slides[prevSlideIndex + 1], prev_canvas_id: slide.canvas_id }, ...state.slides.slice(prevSlideIndex + 2)];
    }
    return { slides: slideData, activeSlideIndex: prevSlideIndex + 1, maxSlideNumber: state.maxSlideNumber + 1 };
  }
  // select slide
  const activeSlideIndex = state.slides.findIndex((slideData) => slideData.canvas_id === slide.canvas_id);

  if (activeSlideIndex > -1) {
    return {
      slides: updateSlide(state.slides, activeSlideIndex, { ...slide, reviewed: slide.reviewed, active: 1 }),
      activeSlideIndex,
    };
  }
  return {};

}

function deleteSlide(state, slide) {
  const { activeSlideIndex, slides } = state;
  const slideIndex = slides.findIndex((slideData) => slideData.canvas_id === slide.canvas_id);
  if (slideIndex === -1) return {};
  let remainingSlides = [];
  if (slides.length > 1 && slideIndex === slides.length - 1) {
    remainingSlides = [...slides.slice(0, slideIndex)];
  } else if (slides.length > 1) {
    remainingSlides = [
      ...slides.slice(0, slideIndex),
      { ...slides[slideIndex + 1], prev_canvas_id: slides[slideIndex - 1] ? slides[slideIndex - 1].canvas_id : null },
      ...slides.slice(slideIndex + 2),
    ];
  }
  return {
    slides: remainingSlides,
    activeSlideIndex: activeSlideIndex > 0 ? activeSlideIndex - 1 : (remainingSlides.length ? activeSlideIndex : -1),
    isSlideLoading: 0,
  };
}

function startInteractiveQuestion(state, payload) {
  const { slides } = state;
  const { questionTimer, getReadyTimer, slideResource } = payload;

  const activeSlideIndex = state.slides.findIndex((slideData) => slideData.canvas_id === slideResource.canvas_id);
  if (activeSlideIndex > -1) {
    return {
      slides: updateSlide(slides, activeSlideIndex, { reviewed: true, active: 1, resource: { ...slides[activeSlideIndex].resource, ...slideResource } }),
      activeSlideIndex,
      questionTimer: {
        questionTimeLeft: questionTimer,
        getReadyTimeLeft: getReadyTimer,
      },
      isSlideLoading: 0,
    };
  }
  return {};
}

function updatePropertiesOnClassStart(state, payload, activeSlideIndex) {
  const isClassStarted = payload.sessionDetails && payload.sessionDetails.state === CLASSROOM_STATE.started;
  const isCompetition = payload.sessionDetails.class_type === CLASSROOM_TYPE.competition;
  return {
    slideConfig: {
      allowDelete: !isClassStarted,
      disableAddSlide: isCompetition,
      disableSelectSlide: isClassStarted && isCompetition,
    },
    activeSlideIndex: activeSlideIndex || ((isClassStarted && isCompetition) ? -1 : 0),
    showCanvas: !isCompetition,
  };
}

export default function whiteboard(state = initialState, action) {
  let newActiveIndex;
  switch (action.type) {
    case UPDATE_SCREEN_SHOT:
      // eslint-disable-next-line no-case-declarations
      const { payload } = action;
      return {
        ...state,
        slideUrls: addToSlideUrls(state, payload),
      };

    case LOAD_SLIDE_URLS: {
      const slides = action.payload;
      let slideUrls = {};

      slides.map((slide) => {
        const { canvas_id, snapshotUrl, number } = slide;
        const slidePayload = {
          id: canvas_id,
          urls: snapshotUrl,
          slideIndex: number,
        };
        slideUrls = addToSlideUrls(state, slidePayload);
        return slideUrls;
      });
      return {
        ...state,
        slideUrls,
      };
    }

    case CLEAR_CLASSROOM:
      return {
        ...initialState,
      };
    case INIT_CLASSROOM.SUCCESS:
      return {
        ...state,
        ...updatePropertiesOnClassStart(state, action.payload, state.activeSlideIndex),
      };
    case START_CLASSROOM.SUCCESS:
      return {
        ...state,
        ...updatePropertiesOnClassStart(state, { sessionDetails: action.payload }, -1),
      };
    case GET_SLIDES.REQUEST:
      return {
        ...state,
        isSlideLoading: -1,
      };
    case GET_SLIDES.SUCCESS:
      newActiveIndex = action.payload.length ? action.payload.findIndex((slide) => !!slide.active) : 0;
      return {
        ...state,
        slides: action.payload,
        maxSlideNumber: action.meta.maxSlideNumber,
        activeSlideIndex: newActiveIndex > -1 ? newActiveIndex : state.activeSlideIndex,
        isSlideLoading: 0,
      };
    case ADD_SLIDE.REQUEST:
      return {
        ...state,
        isSlideAdding: action.payload.prev_canvas_id || -1,
      };
    case ADD_SLIDE.SUCCESS:
      return {
        ...state,
        ...addToSlideArray(state, action.payload), // return { slides, activeSlideIndex }
        isSlideAdding: 0,
        isSlideLoading: 0,
        showCanvas: true,
      };
    case ADD_SLIDE.FAILURE:
      return {
        ...state,
        isSlideAdding: 0,
      };
    case MOVE_SLIDE.REQUEST:
      return {
        ...state,
        slides: action.payload.slides,
        activeSlideIndex: action.payload.activeSlideIndex,
        isSlideLoading: -1,
      };
    case MOVE_SLIDE.SUCCESS:
      return {
        ...state,
        isSlideLoading: 0,
      };
    case MOVE_SLIDE.FAILURE:
      return {
        ...state,
        slides: action.payload.slides,
        activeSlideIndex: action.payload.activeSlideIndex,
        isSlideLoading: 0,
      };
    case SELECT_SLIDE:
      return {
        ...state,
        selectedSlide: action.payload,
      };
    case SLIDE_TO_BE_ADDED:
      return {
        ...state,
        slideToBeAdded: action.payload,
        slideToBeDeleted: {
          slideIndex: -1,
        },
      };
    case SLIDE_TO_BE_DELETED:
      return {
        ...state,
        slideToBeDeleted: action.payload,
        slideToBeAdded: {
          slideIndex: -1,
        },
      };
    case MARK_SLIDE_AS_LOADING:
      return {
        ...state,
        isSlideLoading: action.payload.canvas_id || 0,
      };
    case DELETE_SLIDE.REQUEST:
      return {
        ...state,
        isSlideLoading: action.payload.canvas_id,
      };
    case DELETE_SLIDE.SUCCESS:
      return {
        ...state,
        ...deleteSlide(state, action.payload), // passing dispatch from delete success trigger
      };
    case DELETE_SLIDE.FAILURE:
      return {
        ...state,
        isSlideLoading: 0,
      };
    case SET_CANVAS_DIMENTION:
      return {
        ...state,
        whiteboardSize: action.payload,
      };
    case SET_QUESTION_TIMER:
      return {
        ...state,
        questionTimer: {
          ...state.questionTimer,
          ...action.payload,
        },
      };
    case INTERACTIVE_QUESTION_END:
      return {
        ...state,
        questionTimer: {
          getReadyTimeLeft: 0,
          questionTimeLeft: 0,
        },
      };
    case INTERACTIVE_QUESTION_START:
      return {
        ...state,
        showCanvas: false,
        ...startInteractiveQuestion(state, action.payload),
      };
    case UPDATE_SLIDE_CONFIG:
      return {
        ...state,
        slideConfig: {
          ...state.slideConfig,
          ...action.payload,
        },
      };
    case UPDATE_VOTE_CHOICE:
      return {
        ...state,
        slides: updateSlide(state.slides, state.activeSlideIndex, { resource: { ...state.slides[state.activeSlideIndex].resource, choices: action.payload.choices, total_votes: action.payload.total_votes } }),
      };
    case SHOW_COMPETITON_RESULT:
      return {
        ...state,
        result: action.payload,
        activeSlideIndex: -1,
      };
    case SHOW_CANVAS:
      return {
        ...state,
        showCanvas: action.payload,
      };
    case GENERATE_PDF_SLIDE.REQUEST:
      return {
        ...state,
        pdfSlide: {
          ...state.pdfSlide,
          isGeneratingPdfSlide: true,
          totalSlides: action.payload.images.length,
        },
      };
    case UPDATE_PDF_SLIDE:
      return {
        ...state,
        pdfSlide: {
          ...state.pdfSlide,
          ...action.payload,
        },
      };
    case GENERATE_PDF_SLIDE.SUCCESS:
      return {
        ...state,
        pdfSlide: {
          ...state.pdfSlide,
          isGeneratingPdfSlide: false,
        },
      };
    case GENERATE_PDF_SLIDE.FAILURE:
      return {
        ...state,
        pdfSlide: {
          ...state.pdfSlide,
          isGeneratingPdfSlide: false,
        },
        error: {
          pdfSlide: action.payload,
        },
      };

    case HIDE_PDF_SLIDE_MODAL:
      return {
        ...state,
        pdfSlide: {
          ...state.pdfSlide,
          isGeneratingPdfSlide: false,
        },
      };

      // upload screenshot retry mechanism
    case RETRY_UPLOAD_SC: {
      const { id } = action.payload.payload;

      const { screenshotRetries } = state;
      const retry = screenshotRetries[id] || {};
      retry.count = retry.count ? retry.count + 1 : 1;
      screenshotRetries[id] = retry;

      return {
        ...state,
        screenshotRetries,
      };

    }
    case RETRY_UPLOAD_SC_SUCCESS: {
      const { id } = action.payload;
      const { screenshotRetries } = state;
      screenshotRetries[id] = {};
      return {
        ...state,
        screenshotRetries,
      };

    }
    default:
      if (action.type.indexOf('_REQUEST') !== -1) {
        return {
          ...state,
          error: {},
        };
      }
      return state;
  }
}

// eslint-disable-next-line import/no-cycle
import store from '../../../store';
import Mixpanel from '../../Mixpanel';

let logger = null;

const handleMixpanelKafkaActivityEvents = ({ type, data = {} }) => {
  if (!logger) logger = LoggingManager.mount({ moduleName: 'classroom' });

  const state = store.getState().toJS();

  const sessionDetails = state.tutoring.sessionDetails.id ? state.tutoring.sessionDetails : state.myClassroom.sessionDetails;
  const { user_id } = state.user.loggedUser;
  const session_id = sessionDetails.id;
  const group_id = sessionDetails.group.id;
  const while_in = sessionDetails.state === 'started' ? 'live' : 'prep';
  const timestamp = Date.now();
  const opened_from = data.opened_from || state.addQuestionFromImage.openedFrom;
  const conversion_identifier = state.addQuestionFromImage.conversionId;

  const eventData = {
    ...data,
    user_id,
    session_id,
    group_id,
    while_in,
    timestamp,
    opened_from,
    conversion_identifier,
  };

  console.log(state);

  Mixpanel.track(type, eventData);
  logger.track(type,
    { classroom: eventData },
  );
};

export default handleMixpanelKafkaActivityEvents;

import React from 'react';
import withNoonText from 'helpers/withNoonText';

const NoTeams = ({ text, noonText }) => (
  <div className="no-teams">
    <h1>{text(noonText, 'teacherBreakout.noTeamsHeading')}</h1>
    <p>{text(noonText, 'teacherBreakout.noTeamsDescription')}</p>
    <img src="/assets/images/breakout-circle.png" alt="breakout question" />
  </div>
);

export default withNoonText(NoTeams);

import {
  GET_QUESTION,
  QUESTION_LIST_WITH_CHOICES,
  ASSIGN_QUESTION,
  CREATE_QUESTION,
  ADD_CHOICE_QUESTION,
  TEACHER_QUESTION_LIST,
  DELETE_TEACHER_QUESTION,
  QUESTIONS_LIST,
  CLEAR_TOPIC_QUESTIONS,
} from '../constants';

const initialState = {
  questionData: {},
  questionListWithChoices: {
    list: [],
    isLoading: false,
    limit: 10,
    page: 1,
    total: 0,
    nextPage: -1,
  },
  error: {},
  assignQuestionData: {},
  createQuestionData: {},
  deleteQuestion: 0,
  addChoiceData: {},
  teacherQuestions: {
    list: [],
    limit: 10,
    page: 1,
    isLoading: false,
    total: 0,
    nextPage: -1,
    tab: ''
  },
  questions: {
    list: [],
    limit: 10,
    page: 1,
    isLoading: false,
    total: 0,
    nextPage: -1,
    tab: '',
  },
};

export default function question(state = initialState, action) {
  switch (action.type) {
    case GET_QUESTION.REQUEST:
      return {
        ...state,
        questionData: {
          loading: true,
        },
      };
    case GET_QUESTION.SUCCESS:
      return {
        ...state,
        questionData: action.payload,
      };
    case GET_QUESTION.FAILURE:
      return {
        ...state,
        questionData: {},
        error: { questionData: action.payload },
      };
    case QUESTION_LIST_WITH_CHOICES.REQUEST:
      return {
        ...state,
        questionListWithChoices: { ...state.questionListWithChoices, isLoading: true },
        error: {},
      };
    case QUESTION_LIST_WITH_CHOICES.SUCCESS:
      return {
        ...state,
        questionListWithChoices: {
          ...state.questionListWithChoices,
          list: action.payload.page === 1 ? action.payload.list : [...state.questionListWithChoices.list, ...action.payload.list],
          isLoading: false,
          page: action.payload.page,
          nextPage: action.payload.nextPage,
        },
        error: {},
      };
    case QUESTION_LIST_WITH_CHOICES.FAILURE:
      return {
        ...state,
        error: { questionListWithChoices: action.payload },
        questionListWithChoices: { ...state.questionListWithChoices, list: [], isLoading: false },
      };
    case ASSIGN_QUESTION.SUCCESS:
      return {
        ...state,
        assignQuestionData: {},
        error: {},
      };
    case ASSIGN_QUESTION.FAILURE:
      return {
        ...state,
        assignQuestionData: {},
        error: {},
      };
    case CREATE_QUESTION.SUCCESS:
      return {
        ...state,
        createQuestionData: action.payload,
        error: {},
      };
    case CREATE_QUESTION.FAILURE:
      return {
        ...state,
        createQuestionData: {},
        error: {
          createQuestionData: action.payload,
        },
      };
    case ADD_CHOICE_QUESTION.SUCCESS: {
      return {
        ...state,
        addChoiceData: {},
        error: {},
      };
    }
    case ADD_CHOICE_QUESTION.FAILURE: {
      return {
        ...state,
        addChoiceData: {},
        error: {},
      };
    }
    case TEACHER_QUESTION_LIST.LOADING: {
      return {
        ...state,
        teacherQuestions: {
          ...state.teacherQuestions,
          isLoading: true,
        },
      };
    };
    case TEACHER_QUESTION_LIST.SUCCESS: {
      return {
        ...state,
        teacherQuestions: {
          ...state.teacherQuestions,
          list: action.payload.page === 1 ? action.payload.list : [...state.teacherQuestions.list, ...action.payload.list],
          nextPage: action.payload.nextPage,
          isLoading: false,
          tab: action.payload.tab
        },
        createQuestionData: {},
      };
    }
    case TEACHER_QUESTION_LIST.FAILURE: {
      return {
        ...state,
        teacherQuestions: {
          ...state.teacherQuestions,
          isLoading: false,
        },
        error: {
          teacherQuestions: action.payload,
        },
      };
    };
    case QUESTIONS_LIST.LOADING: {
      return {
        ...state,
        questions: {
          ...state.questions,
          isLoading: true
        }
      }
    };
    case QUESTIONS_LIST.SUCCESS: {
      return {
        ...state,
        questions: {
          ...state.teacherQuestions,
          list: action.payload.page === 1 ? action.payload.list : state.questions.list.concat(action.payload.list),
          nextPage: action.payload.nextPage,
          isLoading: false,
          tab: action.payload.tab,
        },
      };
    };
    case CLEAR_TOPIC_QUESTIONS: {
      return {
        ...state,
        questions: initialState.questions,
        teacherQuestions: initialState.teacherQuestions
      };
    };
    case DELETE_TEACHER_QUESTION.REQUEST:
      return {
        ...state,
        deleteQuestion: action.payload,
      };
    case DELETE_TEACHER_QUESTION.SUCCESS:
      return {
        ...state,
        teacherQuestions: {
          ...state.teacherQuestions,
          list: [...state.teacherQuestions.list.filter((item) => item.id !== action.payload)],
        },
        deleteQuestion: 0,
      };
    case DELETE_TEACHER_QUESTION.FAILURE:
      return {
        ...state,
        deleteQuestion: 0,
        error: {
          deleteQuestion: action.payload,
        },
      };
    default:
      if (action.type.indexOf('_REQUEST') !== -1) {
        return {
          ...state,
          error: {},
        };
      }
      return state;
  }
}

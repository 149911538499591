/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash-es';
import { PlaybackTimeSeriesContext } from './context';
import { GET_SNAPSHOT_DATA, PLAYBACK_LOAD_NEXT_DATA, PLAYBACK_INTERACTIVE_QUESTION_START, PLAYBACK_INTERACTIVE_QUESTION_END, PLAYBACK_STOP } from '../../redux/constants';

function PlaybackTimeSeriesProvider(prop) {
  const { children } = prop;
  const dispatch = useDispatch();

  // States
  const [sketchData, setSketchData] = useState([]);
  const [activeSlide, updateActiveSlide] = useState(0);
  const [currentPlaybackCheckpoints, updateCheckpoints] = useState({});

  // Redux states
  const { 
    timeSeriesData = {}, 
    timeSeriesNextData = {}, 
    snapShotData = {}, 
    editedPlaybackDetails = {}, 
    playerData = {}, 
    interactiveQuestion, 
    playback_sections 
  } = useSelector(
    (state) => state.toJS().playback,
  );
  const { duration, ended } = playerData;

  const updateSketchData = (eventSketchData, isSketchEvent = false) => {
    setSketchData((prevSketchData) => {
      if (!isSketchEvent) {
        return eventSketchData;
      }
      return [...prevSketchData, ...eventSketchData];
    });
  }

  // Logic
  // Get snapshot & timeseries data when the playback is first started
  const initialiseCanvas = (editedPlaybackDetails) => {
    if (!editedPlaybackDetails?.data?.[0]) return;
    const { start_time } = playback_sections?.data?.[0]?.payload?.intervals?.[0];
    const { id: session_id, last_seek } = editedPlaybackDetails?.data?.[0];
    const lastSeekValue = last_seek || start_time;
    const convertedSeekPosition = Math.round((lastSeekValue - start_time) / 1000);

    dispatch({
      type: GET_SNAPSHOT_DATA.REQUEST,
      payload: { session_id, last_seek: lastSeekValue, second: convertedSeekPosition },
    });
  };

  // Send snapshot data to canvas whenever playback is opened or seeked to a new position
  const handleUpdatedSnapshotData = (slotData) => {
    if (slotData && slotData[0]) {
      const { sketchData, cubeData } = slotData[0];
      updateSketchData(sketchData || []);
      updateActiveSlide(!cubeData ? 0 : cubeData.number);
    }
  };

  const handleInteractiveQuestion = useCallback((evt) => {
    const questionData = evt.currentCube || evt.cubeData;
    const sketchData = evt.sketchData || [];
    questionData.time = questionData.time_left;
    const payload = {
      ...questionData,
    };

    const _cubeData = [
      {
        cubeData: {
          number: questionData.number,
        },
        sketchData,
      },
    ];

    setTimeout(() => {
      handleUpdatedSnapshotData(_cubeData);
      if (questionData.time_left === 0) {
        prop.showExplanation && prop.showExplanation();
      }
    }, 100);
    dispatch({ type: PLAYBACK_INTERACTIVE_QUESTION_START, payload });
  }, [editedPlaybackDetails.data, interactiveQuestion, playerData]);

  // Play timeseries data
  // currentTime: the current time stamp the player has played
  // fetchedAhead: how many minutes of data should be buffered in advance
  const playTimeSeriesData = (playedSeconds) => {
    if (ended) return;
    const { id: session_id } = editedPlaybackDetails?.data?.[0];
    const { start_time } = playback_sections.data[0].payload.intervals[0];

    const delta = Math.round(playedSeconds) * 1000;
    const timestamp = start_time + delta;

    // Calculate seconds elapsed after seek
    const second = Math.round(playedSeconds);
    const secondElapsedInCurrentMinute = new Date(start_time + (Math.round(playedSeconds) * 1000)).getSeconds();

    // Check for the actions that need to be taken based on playbackCheckpoints
    if (currentPlaybackCheckpoints[timestamp]) {
      switch (currentPlaybackCheckpoints[timestamp].action) {
        // case 'seek':
        //   // onSeek((currentPlaybackCheckpoints[timestamp].meta - start_time) / 1000);
        //   break;
        case 'end':
          dispatch({ type: PLAYBACK_STOP, payload: { last_seek: Object.keys(currentPlaybackCheckpoints)[0] } });
          break;
        default:
          break;
      }
    }

    const events = timeSeriesData.data?.[secondElapsedInCurrentMinute];

    if (secondElapsedInCurrentMinute === 59 && !timeSeriesNextData.buffering) {
      if (!isEqual(timeSeriesData.data, timeSeriesNextData.data)) {

        const delta = Math.round(second) * 1000;
        const timestamp = start_time + delta;
        const nextDate = new Date(timestamp);

        // if you don't check  this the next time stamp will be rounded off to the previous existing timestamp!
        if (nextDate.getSeconds() >= 59) {
          nextDate.setMinutes(nextDate.getMinutes() + 1);
        }

        nextDate.setMinutes(nextDate.getMinutes() + 1);
        const nextTimeStamp = nextDate.getTime();
        dispatch({ type: PLAYBACK_LOAD_NEXT_DATA, payload: { session_id, last_seek: nextTimeStamp, currentSeekSeconds: Math.round(playedSeconds), duration } });
      }
    }

    if (events) {
      if (events) {
        events.map((evt, evtsArr) => {
          switch ((evt).typ) {
            case 'canvas_events':
              if (evt.number !== activeSlide) break;
              updateSketchData([evt], true);
              break;
            case 'add_cube_server':
              if (evt.cubeData.number === activeSlide) {
                prop.showExplanation && prop.showExplanation();
                break;
              }
              if (interactiveQuestion) {
                dispatch({ type: PLAYBACK_INTERACTIVE_QUESTION_END });
              }
              updateSketchData(evt.sketchData || []);
              updateActiveSlide(evt.cubeData.number);
              break;
            case 'chat':
              // updateChatData(prevChatData => handleChatMessage(prevChatData, evt));
              break;
            case 'ask_students':
            case 'ask_students_in_breakout':
              handleInteractiveQuestion(evt);
              break;
            default:
              if (evt.e === 'scrolling') {
                prop.scrollFunction && prop.scrollFunction(evt.height);
              }
              console.log(evt);
              break;
          }
        });

      }
    }
  };

  // Effects
  // Listen to changes in snapshot data
  useEffect(() => {
    if (snapShotData.data[0].cubeData) {
      const typ = snapShotData.data[0].typ || null;
      if (typ === 'ask_students' || typ === 'ask_students_in_breakout') {
        handleInteractiveQuestion(snapShotData.data[0]);
      } else {
        handleUpdatedSnapshotData(snapShotData.data);
      }
    }
  }, [snapShotData]);

  // Initialise canvas after react player is initialised and player state is set to ready
  // Intentionally not initialising canvas simply on availability of playbackDetails
  useEffect(() => {
    if (playerData?.state === 'ready') initialiseCanvas(editedPlaybackDetails);

    if (playerData?.state === 'playing') playTimeSeriesData(Math.round(playerData.playedSeconds));
  }, [playerData]);

  return (
    <PlaybackTimeSeriesContext.Provider value={{ sketchData, activeSlide, updateCheckpoints }}>
      {children}
    </PlaybackTimeSeriesContext.Provider>
  );
}

export default PlaybackTimeSeriesProvider;

import React, { useEffect, useState } from 'react';
import { Modal, Row, Column, Button, MultiSelect } from '@noon/atom';
import { IconExclamationCircle, IconPostO } from '@noon/quark';
import { get, forIn, isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { UPDATE_GROUP_V2 } from '../../../redux/constants';
import { translationText } from '../../../helpers';

function FillMissingValueModal({ fieldKey, group, handleGetCurriculumOrSection, folders }) {
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState({});
  const { noonText } = useSelector((state) => state.toJS().translation);
  const { selectedGroup } = useSelector((state) => state.toJS().groupsV2);
  const getTagsQueryParams = () => {
    const queryParams = {
      country_id: get(group, 'country.id'),
    };
    const tags = get(group, 'curriculum_tags');
    forIn(tags, (value, key) => {
      queryParams[`${key}_id`] = value.id;
    });
    return queryParams;
  };

  const updateGroup = () => {
    if (!isEmpty(selectedItems)) {
      dispatch({
        type: UPDATE_GROUP_V2.REQUEST,
        payload: { groupId: get(group, 'group_info.id'), data: selectedItems },
      });
    }
  };

  const handleOnChangeFieldValue = (key, selectedValue) => {
    // TODO: Need to improve this check
    const keyName = key === 'sections' ? 'section_ids' : `${key}_ids`;
    const ids = selectedValue.map((field) => field.id);
    if (ids.length) {
      setSelectedItems({ [keyName]: ids });
    } else {
      setSelectedItems({ });
    }
  };

  useEffect(() => {
    const queryParams = getTagsQueryParams();
    setSelectedItems({});
    handleGetCurriculumOrSection(queryParams);
    return () => setSelectedItems({});
  }, []);

  return (
    <Modal onClose className="generic-modal-container fill-missing-value-modal" targetId="main-root">
      <Column nowrap className="generic-modal">
        <Column align="center" className="generic-modal--body">
          <div className="header-icon">
            <IconPostO height="45px" width="40px" fill="#3C9CFF" stroke="#3C9CFF" />
          </div>
          <div className="generic-modal--body--wrapper">
            <Column className="generic-modal--body--text pb-1" align="center">
              <div className="title">{translationText(noonText, `groupCurriculum.add${fieldKey}ToProceed`)}</div>
              <div className="sub-title mb-2">
                <IconExclamationCircle height="20px" width="20px" fill="#e22012" stroke="#e22012" className="mlr-1" />
                {translationText(noonText, 'groupCurriculum.cantUndoAddingCurriculum')}
              </div>
              <p className="text text-center mb-1">{translationText(noonText, `groupCurriculum.add${fieldKey}Hint`)}</p>
              {folders[fieldKey] && (
                <MultiSelect
                  onSelect={(selectedItem, selectedArray) => handleOnChangeFieldValue(fieldKey, selectedArray)}
                  label={translationText(noonText, `label.${fieldKey}`)}
                  title={translationText(noonText, `placeholder.${fieldKey}`)}
                  className="mtb-2 select-input"
                  list={folders[fieldKey].list}
                />
              )}
            </Column>
            <Row nowrap align="center" justify="start" gap="lg" flex="1" />
            <Row nowrap align="center" justify="end" gap="lg" className="generic-modal--body--btn">
              <Button
                type="primary"
                size="large"
                onClick={updateGroup}
                loading={selectedGroup && selectedGroup.loading}
                disabled={isEmpty(selectedItems)}
                className="btn-modal">
                {translationText(noonText, `groupCurriculum.confirm${fieldKey}`)}
              </Button>
            </Row>
          </div>
        </Column>
      </Column>
    </Modal>
  );
}

FillMissingValueModal.propTypes = {
  group: PropTypes.shape({}).isRequired,
  handleGetCurriculumOrSection: PropTypes.func.isRequired,
  folders: PropTypes.shape({}).isRequired,
  fieldKey: PropTypes.string.isRequired,
};
export default FillMissingValueModal;

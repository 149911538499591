import React from 'react';
import { Modal, Button } from '@noon/atom';
import t from '../../helpers/translate';

export default function ExitClassModal(prop) {
  const { onClose, exitClass } = prop;

  return (
    <Modal>
      <div className="modal-body mt-2 text-center">
        {t('tutoring', 'exitClass')}
      </div>
      <footer className="modal-footer mt-1">
        <Button type="primary" onClick={exitClass}>
          {t('tutoring', 'quitClass')}
        </Button>
        <Button onClick={onClose}>{t('tab', 'cancel')}</Button>
      </footer>
    </Modal>
  );
}

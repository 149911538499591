import React, { useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { IconLoader, IconPlus, IconSort } from '@noon/quark';
import { Row, Column, Badge, Button, Search } from '@noon/atom';
import { debounce, isEmpty } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import t from '../../helpers/translate';
import { COLORS } from '../../constants';
import NoonQuestion from '../NoonQuestion/NoonQuestion';
import CreateQuestionCta from '../Class/AddQuestionFromImage/create-question-cta';
import { OPEN_ADD_IMAGE_FROM_QUESTION_DIALOG } from '../../redux/constants';
import { toggleCreateQuestionModal } from '../../redux/actions/content';

const LevelMap = {
  10: 'difficulty1',
  30: 'difficulty2',
  50: 'difficulty3',
  70: 'difficulty4',
  90: 'difficulty5',
};

export default function QuestionForLesson(prop) {
  const searchInputRef = useRef();
  const { questions, addQuestionToCanvas, errorFor, handleLoadMoreQuestion } = prop;
  const hasQuestions = questions && questions.list && questions.list.length;
  const hasError = errorFor.indexOf('question') !== -1;
  const [filters, setFilters] = useState({ sort: 'DESC' });
  const dispatch = useDispatch();

  const openCreateQuestionFromImageDialog = () => {
    dispatch({ type: OPEN_ADD_IMAGE_FROM_QUESTION_DIALOG, payload: { from: 'questions_tab' } });
  };

  const handleOnFilterChange = (key, value) => {
    const updatedFilters = {
      ...filters,
      [key]: value,
    };
    setFilters(updatedFilters);
    handleLoadMoreQuestion({ ...updatedFilters, page: 1 });
  };

  const handleOnClickLoadMore = () => {
    handleLoadMoreQuestion(filters);
  };

  const handleOnClickCreateTextQuestion = () => {
    dispatch(toggleCreateQuestionModal(true));
  };

  // TODO handle when question is loaded
  // const onAddQuestionFromImageDone = (isOnboarded) => {
  //   if (!isOnboarded) { setShowOnbaordingcomplete(true); }
  //   handleLoadMoreQuestion({ ...filters, page: 1 });
  // };

  return (
    <div className="folder-questions">
      {(hasQuestions || !isEmpty(filters)) && (
      <Row flex="1" justify="space-between" align="center" className="plr-1 ptb-1 folder-questions__filter" gap="sm">
        <Search
          ref={searchInputRef}
          name="question-search"
          className="flex-1 child"
          autoComplete="on"
          loading={false}
          placeholder={t('dashboard', 'searchFor')}
          onChange={debounce(() => {
            handleOnFilterChange('text', searchInputRef.current.value);
          }, 200)}
        />
        <IconSort
          width="25px"
          fill={COLORS.grey[1]}
          flipY={filters.sort === 'DESC'}
          onClick={debounce(() => {
            handleOnFilterChange('sort', filters.sort === 'DESC' ? 'ASC' : 'DESC');
          }, 200)}
          style={{ cursor: 'pointer' }}
        />
      </Row>
      )}
      { !isMobileOnly && <CreateQuestionCta onClickCreateTextQuestion={handleOnClickCreateTextQuestion} onClickCreateImageQuestion={() => openCreateQuestionFromImageDialog()} /> }
      {hasQuestions ? (
        <div className="folder-questions__list">
          {questions.list.map((question, index) => (
            <Row key={question.id} className="folder-questions__list-item">
              <Column className="index">{index + 1}</Column>
              <Column className="question" align="start">
                {!!question.question_json && !!question.question_json.length ? (
                  <NoonQuestion content={question.question_json} dir={question.text_direction} />
                ) : (
                  ReactHtmlParser(question.question.replace('<img ', '<img draggable="false" '))
                )}
                {LevelMap[question.difficulty] && (
                  <Badge size="sm" type="primary" outlined title="difficulty">{`${t('library', LevelMap[question.difficulty])}`}</Badge>
                )}
              </Column>
              <Button
                outlined
                onClick={() => addQuestionToCanvas(question)}
                >
                <IconPlus />
              </Button>
            </Row>
          ))}
          {questions.nextPage > 0
            && (
            <Row flex="1" justify="center" align="center" className="ptb-1">
              <Button
                onClick={handleOnClickLoadMore}
                value={t('tutoring', 'loadMore')}
                type="secondary"
                outlined
                loading={questions.isLoading}
              />
            </Row>
            )}
        </div>
      ) : questions.isLoading && questions.nextPage <= 0 ? (
        <IconLoader height="4rem" width="4rem" />
      ) : (
        <div className="no-content infoMsg infoMsg-blue">
          <span>{t('classroom', hasError ? 'failedToFetch' : 'selectOtherResource')}</span>
        </div>
      )}
    </div>
  );
}

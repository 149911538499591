import React from 'react';
import { Button, Modal, Select, Column } from '@noon/atom';
import get from 'lodash-es/get';
import t from '../../../helpers/translate';
import PrecallMessage from './pre-call-message';

type Props = {
  onExit: () => void,
  handleOnClickRetry: () => void,
  isNetworkIssue: boolean,
  audioStats: number,
  isCompatible: boolean,
  handleOnSelectMicrophone: (value: { deviceId: string }) => void,
  handleOnSelectCamera: (value: { deviceId: string }) => void,
  sessionData: {
    id: string,
    is_video_enabled: boolean,
    isCameraWorking: boolean,
        webrtc_details: {
            webrtc_api: string,
            webrtc_token: string
        }
    },
    localDevices: {
        selected: {}
    }
}

const precallErrors = {
  audioData: {
    errorHeading: 'audioHeadingError',
    errorContent: 'audioContentError',
    icon: 'mic',
  },
  networkIssue: {
    errorHeading: 'networkHeadingError',
    errorContent: 'networkContentError',
    icon: 'nowifi',
  },
  browserCompatible: {
    errorHeading: 'browserHeadingError',
    errorContent: 'browserContentError',
    downloadLink: 'browserDownloadLink',
    icon: 'chrome',
  },
};

const TestAgoraServiceFailedModal = ({ handleOnClickRetry, onExit, isNetworkIssue, audioStats, isCompatible, localDevices, sessionData, handleOnSelectMicrophone, handleOnSelectCamera }: Props) => (
  <Modal className="modalErrors">
    {isNetworkIssue && <PrecallMessage errorMessage={precallErrors.networkIssue} />}
    {(audioStats <= 60 && audioStats !== null) && <PrecallMessage errorMessage={precallErrors.audioData} />}
    {!isCompatible && <PrecallMessage errorMessage={precallErrors.browserCompatible} />}
    <Column gap nowrap justify="start" align="start" className="full-width plr-2 mb-2">
      <Select
        className="full-width"
        selectedItem={get(localDevices, ['selected', 'microphone'])}
        onSelect={(value) => handleOnSelectMicrophone(value)}
        label={t('classroom', 'selectMicrophone')}
        title={t('classroom', 'microphone')}
        listItem="label"
        list={(get(localDevices, ['microphones']) || []).map((device) => { device.id = device.deviceId; return device; })}
        outlined
              />
      {sessionData.is_video_enabled && sessionData.isCameraWorking && (
      <Select
        className="full-width"
        selectedItem={get(localDevices, ['selected', 'camera'])}
        onSelect={(value) => handleOnSelectCamera(value)}
        label={t('classroom', 'selectCamera')}
        title={t('classroom', 'camera')}
        listItem="label"
        list={(get(localDevices, ['cameras']) || []).map((device) => { device.id = device.deviceId; return device; })}
        outlined
              />
      )}
    </Column>
    <footer className="modal-footer">
      <Button size="lg" depth="2" type="secondary" onClick={() => onExit()}>
        {t('classroom', 'exitClass')}
      </Button>
      <Button size="lg" depth="2" type="primary" onClick={() => handleOnClickRetry()}>
        {t('classroom', 'retry')}
      </Button>
    </footer>
  </Modal>
);

export default TestAgoraServiceFailedModal;

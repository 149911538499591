import React, { useState, useRef, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import get from 'lodash-es/get';
import { useSelector } from 'react-redux';
import { IconAssignment, IconCanvasDiscard, IconCompetition, IconSession } from '@noon/quark';
import { Row, ContentOnTop } from '@noon/atom';
import CreateSession from './createSession';
import CreateAssignmentDraft from './createAssignmentDraft';
import { COLORS } from '../../constants';
import { translationText } from '../../helpers';
import Mixpanel from '../Mixpanel';

export default function CreateActivity(prop) {
  const {
    hideAssignment = false,
    hideSession = false,
    hideCompetition = false,
    onContentClose,
    groupId,
    sessionData,
    hwData,
    update = false,
    source,
    chapter,
    topic,
    defaultTab,
  } = prop;

  const [activeTab, setActiveTab] = useState(() => {
    if (defaultTab === 'assignment') { return defaultTab; }
    return !hideSession ? 'session' : !hideAssignment ? 'assignment' : 'competition';
  });
  const { noonText } = useSelector((state) => state.toJS().translation);
  const { loggedUser: user } = useSelector((state) => state.toJS().user);
  const { selectedGroup: group, curriculumList } = useSelector((state) => state.toJS().groupsV2);
  const logger = useRef(LoggingManager.mount({ moduleName: 'group' })).current;
  const { curriculumExist, hasDraft } = useSelector((state) => state.toJS().curriculum);

  const activities = [
    { icon: IconSession, text: 'session', color: COLORS.session.base, show: hideSession },
    { icon: IconCompetition, text: 'competition', color: COLORS.orange.base, show: hideCompetition },
    { icon: IconAssignment, text: 'assignment', color: COLORS.homework.base, show: hideAssignment },
  ];

  const handleMixpanelKafkaActivityEvents = ({ type, data = {} }) => {
    const dataConst = {
      user_id: user.user_id,
      group_id: groupId,
      group_name: get(group, ['group_info', 'title']),
      group_country: get(group, ['country', 'name']),
      group_subject: get(group, ['curriculum_tags', 'subject', 'name']),
      group_grades: get(group, ['curriculum_tags', 'grade', 'name']),
      group_curriculum_type: get(group, ['curriculum_tags', 'type']),
      group_type: get(group, ['group_info', 'type']),
      group_age: 'NA',
      no_of_students: get(group, ['membersInfo', 'total_members']),
      timestamp: Date.now(),
      curriculum_state: curriculumExist ? hasDraft ? 'draft' : 'published' : 'empty',
      no_of_chapters: curriculumList.chapters.length,
      curriculum_completion: curriculumList.percentCompleted,
      source: source || 'NA',
      ...data,
    };
    Mixpanel.track(type, dataConst);
    logger.track(type,
      { group: dataConst },
    );
  };

  useEffect(() => {
    handleMixpanelKafkaActivityEvents({
      type: 'create_activity_clicked',
    });
    return () => {
      LoggingManager.unmount(logger.id);
    };
  }, []);

  return (
    <ContentOnTop position={isMobileOnly ? 'custom' : 'center'} className="activity-on-top">
      <div className="create-activity">
        {!update ? (
          <div className="create-activity_header">
            <Row className="strip" align="center" justify="space-between">
              <h3>
                {translationText(noonText, 'button.create')}
                {' '}
                {translationText(noonText, 'label.activity')}
              </h3>
              <IconCanvasDiscard onClick={onContentClose} className="pointer" height="16px" width="16px" fill="#222c3c" stroke="#222c3c" rotate="-180" />
            </Row>
            <Row className="create-activity__tabs">
              {activities
                && activities
                  .filter((items) => !items.show)
                  .map((activity) => (
                    <Row
                      key={activity.id}
                      className={`tab-item mlr-1 ${activity.text} ${activeTab === activity.text ? 'active' : ''}`}
                      align="center"
                      onClick={() => setActiveTab(activity.text)}
                    >
                      <activity.icon
                        height="20px"
                        color={activeTab === activity.text ? activity.color : COLORS.coolGrey[1]}
                      />
                      <h4>{translationText(noonText, `label.${activity.text}`)}</h4>
                    </Row>
                  ))}
            </Row>
          </div>
        ) : (
          <div className="create-activity_header">
            <Row className="strip" align="center" justify="space-between">
              {activities
                && activities
                  .filter((item) => !item.show)
                  .map((activity) => (
                    <Row key={activity.id} align="center" gap="sm">
                      <activity.icon
                        color={activeTab === activity.text ? activity.color : COLORS.coolGrey[1]}
                        className="child"
                      />
                      <h3>
                        {translationText(noonText, 'label.update')}
                        {' '}
                        {translationText(noonText, activity.text)}
                      </h3>
                    </Row>
                  ))}
              <IconCanvasDiscard onClick={onContentClose} height="16px" width="16px" fill="#222c3c" stroke="#222c3c" rotate="-180" />
            </Row>
          </div>
        )}
        <div className="create-activity__body">
          {activeTab === 'session' && (
            <CreateSession
              source={source}
              sessionData={sessionData}
              groupId={groupId}
              onContentClose={onContentClose}
              chapter={chapter}
              topic={topic}
              handleMixpanelKafkaActivityEvents={handleMixpanelKafkaActivityEvents}
            />
          )}
          {activeTab === 'competition' && (
            <CreateSession
              source={source}
              sessionData={sessionData}
              groupId={groupId}
              sessionType="competition"
              onContentClose={onContentClose}
              chapter={chapter}
              topic={topic}
              handleMixpanelKafkaActivityEvents={handleMixpanelKafkaActivityEvents}
            />
          )}
          {activeTab === 'assignment' && (
            <CreateAssignmentDraft
              source={source}
              hwData={hwData}
              groupId={groupId}
              onContentClose={onContentClose}
              chapter={chapter}
              topic={topic}
              handleMixpanelKafkaActivityEvents={handleMixpanelKafkaActivityEvents}
            />
          )}
        </div>
      </div>
    </ContentOnTop>
  );
}

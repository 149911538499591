import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Button, Column } from '@noon/atom';
import { IconAdd, IconCamera, IconCorrectCircle, IconFileEdit, IconFileI, IconSchedule, IconStudent } from '@noon/quark';
import { translationText } from '../../../helpers';
import { COLORS } from '../../../constants';

function Task({ isCompleted, type, onClick, groupId, subTitle, totalMembers }) {
  const noonText = useSelector((state) => state.toJS().translation.noonText);
  return (
    <React.Fragment>
      <Row
        flex="1"
        align="center"
        justify="space-between"
        nowrap
        className={classNames({ 'not-completed': !isCompleted, completed: isCompleted })}
      >
        <Column align="start" justify="start" gap="sm">
          <Row justify="start" align="center" nowrap gap="sm">
            {type === 'description' && (
              <React.Fragment>
                <IconFileI className="child detail-icon" width="20px" height="20px" style={{ marginBottom: '2px' }} />
                <Button link onClick={onClick} className="no-padding group-status-widget__title">
                  {translationText(noonText, isCompleted ? 'groupStatus.descriptionTaskCompleted' : 'groupStatus.descriptionTask')}
                </Button>
                <Button link onClick={onClick} style={{ minWidth: 'auto' }} className="no-padding link">
                  {translationText(noonText, 'activity.view')}
                </Button>
              </React.Fragment>
            )}
            {type === 'video' && (
              <React.Fragment>
                <IconCamera className="icon child detail-icon" height="20px" width="20px" />
                <Button link onClick={onClick} className="no-padding group-status-widget__title">
                  {translationText(noonText, isCompleted ? 'groupStatus.videoTaskCompleted' : 'groupStatus.videoTask')}
                </Button>
                <Button link onClick={onClick} style={{ minWidth: 'auto' }} className="no-padding link">
                  {translationText(noonText, isCompleted ? 'activity.view' : 'activity.edit')}
                </Button>
              </React.Fragment>
            )}
            {type === 'curriculum' && (
              <React.Fragment>
                <IconFileEdit className="icon child detail-icon" height="20px" width="20px" />
                <Button link onClick={onClick} className="no-padding group-status-widget__title">
                  {translationText(
                    noonText,
                    isCompleted ? 'groupStatus.curriculumTaskCompleted' : 'groupStatus.curriculumTask',
                  )}
                </Button>
                <Button link onClick={onClick} style={{ minWidth: 'auto' }} className="no-padding link">
                  {translationText(noonText, isCompleted ? 'activity.view' : 'activity.edit')}
                </Button>
              </React.Fragment>
            )}
            {type === 'schedules' && (
              <React.Fragment>
                <IconSchedule height="20px" width="20px" className="child detail-icon" />
                <Button link onClick={onClick} className="no-padding group-status-widget__title">
                  {translationText(noonText, isCompleted ? 'groupStatus.scheduleTaskDone' : 'groupStatus.scheduleTask')}
                </Button>
                <Button link onClick={onClick} style={{ minWidth: 'auto' }} className="no-padding link">
                  {translationText(noonText, isCompleted ? 'activity.view' : 'activity.edit')}
                </Button>
              </React.Fragment>
            )}

            {type === 'member' && (
              <React.Fragment>
                <IconStudent height="20px" width="20px" className="child detail-icon" />
                <span className="group-status-widget__title child">
                  {translationText(
                    noonText,
                    isCompleted ? 'groupStatus.membersTaskCompleted1' : 'groupStatus.membersTask1',
                  )}
                </span>
                <span className="child">
                  {!!isCompleted && (totalMembers || 6)}
                  {' '}
&nbsp;
                  {translationText(
                    noonText,
                    isCompleted ? 'groupStatus.membersTaskCompleted2' : 'groupStatus.membersTask2',
                  )}
                </span>
              </React.Fragment>
            )}

            {type === 'activity' && (
              <React.Fragment>
                <IconAdd height="20px" width="20px" className="child detail-icon" />
                <Button link onClick={onClick} className="no-padding group-status-widget__title">
                  {translationText(
                    noonText,
                    isCompleted ? 'groupStatus.activityTaskCompleted' : 'groupStatus.activityTask',
                  )}
                </Button>
                <Button link onClick={onClick} style={{ minWidth: 'auto' }} className="no-padding link">
                  {translationText(noonText, isCompleted ? 'activity.view' : 'activity.edit')}
                </Button>
              </React.Fragment>
            )}
          </Row>
          {subTitle && <span className="sub-title">{translationText(noonText, subTitle)}</span>}
        </Column>
        <IconCorrectCircle fill={COLORS.coolGrey[1]} stroke="#fff" className="icon child correct-round" height="20px" width="20px" />
      </Row>
    </React.Fragment>
  );
}

Task.propTypes = {
  isCompleted: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  totalMembers: PropTypes.string,
};

export default Task;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Row, Textarea } from '@noon/atom';
import { IconCanvasConfirm, IconClose, IconEdit, IconLoader } from '@noon/quark';
import get from 'lodash-es/get';
import { UPDATE_GROUP_V2 } from 'redux/constants';
import { translationText } from 'helpers';
import { isEmpty } from 'lodash-es';
import NotAllowedOverlay from '../../NotAllowedOverlay';

function GroupDescriptionWidget(prop) {
  const { group, isArchived } = prop;
  const dispatch = useDispatch();
  const [description, setDescription] = useState('');
  const [enableDescriptionEdit, setEnableDescriptionEdit] = useState(false);
  const noonText = useSelector((state) => state.toJS().translation.noonText);

  useEffect(() => {
    if (group.group_info && !group.group_info.description) {
      setEnableDescriptionEdit(true);
    }
  }, []);

  useEffect(() => {
    if (!group.loading && enableDescriptionEdit) {
      setEnableDescriptionEdit(false);
    }
  }, [group.loading]);

  const updateGroup = (data) => {
    dispatch({
      type: UPDATE_GROUP_V2.REQUEST,
      payload: { groupId: group.group_info.id, data },
    });
  };

  const handleOnSaveDescription = () => {
    const groupDetails = get(group, 'group_info');
    if (isEmpty(description) || groupDetails.description === description) return false;
    updateGroup({ ...groupDetails, description });
  };

  const handleOnEditDescription = () => {
    setDescription(get(group, 'group_info.description'));
    setEnableDescriptionEdit(true);
  };

  const handleInputChange = (event) => {
    if (event.target.value.length > 250) return;
    setDescription(event.target.value);
  };

  const closeEditDescription = () => {
    setDescription(get(group, 'group_info.description'));
    setEnableDescriptionEdit(false);
  };

  return (
    <Card className="description-widget">
      <Row align="center" className="card-header" gap="sm">
        {isArchived && <NotAllowedOverlay />}
        <h4 className="card-header__title">{translationText(noonText, 'groups.aboutGroup')}</h4>
        {enableDescriptionEdit ? (
          <React.Fragment>
            {
              group.loading ? <IconLoader className="child" onClick={handleOnSaveDescription} /> : <IconCanvasConfirm className="child" onClick={handleOnSaveDescription} />
            }
            {!group.loading && !!get(group, 'group_info.description') && (
              <IconClose className="child" onClick={closeEditDescription} />
            )}
          </React.Fragment>
        ) : (
          <IconEdit onClick={handleOnEditDescription} />
        )}
      </Row>
      {!enableDescriptionEdit ? (
        <div className="child card-body__note">{get(group, 'group_info.description')}</div>
      ) : (
        <Textarea
          placeholder={translationText(noonText, 'groups.descriptionPlaceholder')}
          maxLength="250"
          rows="4"
          disabled={group.loading}
          value={description}
          onChange={handleInputChange}
        >
          <span>
            {description ? description.length : 0}
            /250
          </span>
        </Textarea>
      )}
    </Card>
  );
}

export default React.memo(GroupDescriptionWidget);

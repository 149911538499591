import { put, call, all, select, takeLatest, delay, fork, take, cancel, race } from 'redux-saga/effects';
import { isEmpty, difference, filter } from 'lodash-es';
import { TrackSentryError, SentryType } from 'SentryAlias';
import {
  CHAT_HISTORY,
  INIT_CLASSROOM,
  START_CLASSROOM,
  GET_SLIDES,
  LOAD_SLIDE_URLS,
  CLASSROOM_LEADERBOARD,
  START_CLASSROOM_HEARTBEAT,
  STOP_CLASSROOM_HEARTBEAT,
  START_SESSION_RECORDING,
  SLIDE_TO_BE_ADDED,
  GENERATE_PDF_SLIDE,
  ADD_SLIDE,
  SET_SELECTED_IMAGE_CONTENT,
  IMAGE_ADDED_ON_WHITEBOARD,
  UPDATE_PDF_SLIDE,
  SELECT_SLIDE,
  SET_SELECTED_QUESTION_CONTENT,
  HIDE_PDF_SLIDE_MODAL,
  RAISE_HAND_REQUEST,
  INITIALIZE_RAISE_HAND,
  GET_STUDENT_CLASSROOM_META,
  ADD_STUDENT_CLASSROOM_META_QUEUE,
} from '../constants';

// eslint-disable-next-line import/no-cycle
import { addToast, TOAST_TYPE } from '../../components/Toast';

// eslint-disable-next-line import/no-cycle
import { athenaApi, athenaApiV2, realtimeApi, questionApi } from '../restApi';
import SlideList from '../../helpers/slideList';
import dataURLtoFile from '../../helpers/dataURLtoFile';

const uuidv4 = require('uuid/v4');

const heartbeatApi = (session_id) => athenaApi.post(`heartbeat/${session_id}`);
const joinClassroomAPI = (session_id) => athenaApiV2.get(`session/joinClass/${session_id}`);
const teacherGrantAccessRequestAPI = ({ session_id }) => realtimeApi.post('pubnub/teacher/grant_access', { session_id });
const startClassroomAPI = (payload) => athenaApi.post('teacher/startSession', payload);
const getChatHistoryAPI = ({ session_id, offset_count }) => athenaApi.get(`session/messages/${session_id}`, { offset_count });
const getSlidesAPI = (sessionId) => athenaApi.get(`all_canvas/${sessionId}`);
const rearrangeSlidesAPI = (payload) => athenaApi.post('rearrange_canvas', payload);
const getLeaderboardAPI = ({ session_id, offset }) => athenaApi.get(`teacher/session/leaderboard/${session_id}`, { offset });
// const getBreakoutTeamsApi = ({ session_id, offset, last_score, last_rank }) => athenaApi.get(`breakouts/teams/${session_id}`, { offset, last_score, last_rank });
const startClassroomRecordingAPI = (session_id) => athenaApi.put(`startArchive/${session_id}`);
const getStudentClassMetaApi = ({ userIds, sessionId }) => athenaApi.post('student/meta', { user_ids: userIds, session_id: sessionId });
const getQuestionAPI = (id) => questionApi.get(`teacher/questions/${id}`);

const getUser = (state) => state.toJS().user.loggedUser;
const getSlideList = (state) => state.toJS().whiteboard.slides;
const getSlideIndex = (state) => state.toJS().whiteboard.activeSlideIndex;
const selectorStudentClassMeta = (state) => state.toJS().myClassroom.studentClassroomMeta;
const selectorSessionDetails = (state) => state.toJS().myClassroom.sessionDetails;

function generateProductsFromTags(item) {
  const copyOFItem = item;
  if (copyOFItem.curriculum_tags) {
    if (copyOFItem.curriculum_tags.type === 'k12') {
      copyOFItem.product = copyOFItem.curriculum_tags.subject;
    } else if (copyOFItem.curriculum_tags.type === 'k12_test_prep') {
      copyOFItem.product = copyOFItem.curriculum_tags.test;
    } else if (copyOFItem.curriculum_tags.type === 'k12_skill') {
      copyOFItem.product = copyOFItem.curriculum_tags.skill;
    } else if (copyOFItem.curriculum_tags.type === 'university') {
      copyOFItem.product = copyOFItem.curriculum_tags.course;
    } else if (copyOFItem.curriculum_tags.type === 'university_test_prep') {
      copyOFItem.product = copyOFItem.curriculum_tags.test;
    } else if (copyOFItem.curriculum_tags.type === 'university_skill') {
      copyOFItem.product = copyOFItem.curriculum_tags.skill;
    } else {
      copyOFItem.product = { name: copyOFItem.curriculum_tags.type };
    }
  } else {
    copyOFItem.curriculum_tags = {};
  }
  return copyOFItem;
}

function* handleAddingQuestionSlide(snapshotObject) {
  try {
    const response = yield call(getQuestionAPI, snapshotObject.resource_id);
    if (response.data.data && response.ok) {
      const sanitizedChoices = response.data.data[0].choices.map((choice) => ({ ...choice, answer: '' }));

      const questionPayload = {
        ...response.data.data[0],
        question: '', // clear html data before adding to slide
        solution: '', // clear html data before adding to slide
        passage: '',
        passage_json: response.data.data[0]?.passage?.content_json || {},
        choices: sanitizedChoices,
        ...snapshotObject.label_id && { labelId: snapshotObject.label_id },
        ...snapshotObject.label_name && { labelName: snapshotObject.label_name },
      };
      yield put({
        type: SET_SELECTED_QUESTION_CONTENT,
        payload: questionPayload,
      });
    } else {
      addToast(response.data.message, TOAST_TYPE.ERROR);
    }
  } catch (err) {
    console.log('question error', err);
  }
}

function* convertSlidesToNewFormat(slides, session_id) {
  // if at least 2 slides don't have prev_canvas_id property, it means slide is in old format
  if (slides.length > 1) {
    const a = [];
    const b = [];
    let isCanvasPontingToNull = false;
    slides.forEach((item) => {
      a.push(item.canvas_id);
      if (item.prev_canvas_id) {
        b.push(item.prev_canvas_id);
      } else {
        isCanvasPontingToNull = true;
      }
    });
    const arrayDiff = difference(a, b);
    if (arrayDiff.length > 1) {
      TrackSentryError('CONVERTING SLIDES: Slides canvas id issue', { sessionId: session_id, slides: arrayDiff }, SentryType.ERROR);
    }
    if (!isCanvasPontingToNull) {
      TrackSentryError('CONVERTING SLIDES: Not pointing to null', { sessionId: session_id }, SentryType.ERROR);
    }
    const slideList = new SlideList();
    if (arrayDiff.length > 1 || (!slides[0].prev_canvas_id && !slides[1].prev_canvas_id) || !isCanvasPontingToNull) {
      for (const key in slides) {
        if (slides[key]) {
          const prevCanvasId = slides[Number(key) - 1] ? slides[Number(key) - 1].canvas_id : null;
          slideList.add({ ...slides[key], prev_canvas_id: prevCanvasId });
        }
      }
      const convertedSlides = slideList.toArray();
      const data = convertedSlides.map((slide) => ({ canvas_id: slide.canvas_id, prev_canvas_id: slide.prev_canvas_id }));
      const response = yield call(rearrangeSlidesAPI, { session_id, data });
      if (response.ok) {
        return convertedSlides;
      }
      return slides;
    }
    slideList.fromArray(slides);
    return slideList.toArray();
  }
  return slides;
}

function* getSlides({ payload }) {
  const { session_id } = payload;
  try {
    const response = yield call(getSlidesAPI, session_id);
    if (response.ok && response.data) {
      const slides = yield convertSlidesToNewFormat(response.data.data, session_id);
      yield put({
        type: GET_SLIDES.SUCCESS,
        payload: slides,
        meta: {
          maxSlideNumber: response.data.max_slide_number,
        },
      });
      yield put({
        type: LOAD_SLIDE_URLS,
        payload: slides,
        meta: {
          maxSlideNumber: response.data.max_slide_number,
        },
      });
    } else {
      yield put({ type: GET_SLIDES.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: GET_SLIDES.FAILURE, payload: err });
  }
}

function* chatHistory({ payload }) {
  const { session_id, offset_count } = payload;
  try {
    const response = yield call(getChatHistoryAPI, { session_id, offset_count });
    if (response.ok && response.data) {
      // mark as delivered
      const chats = response.data.data.map((chat) => ({ ...chat, isDelivered: true }));
      yield put({
        type: CHAT_HISTORY.SUCCESS,
        payload: chats,
        meta: response.data.meta_data,
      });
    } else {
      yield put({ type: CHAT_HISTORY.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: CHAT_HISTORY.FAILURE, payload: err });
  }
}

function* initClassroom({ payload }) {
  const { session_id } = payload;
  try {
    const [joinClassResponse, pubnubGrantAccessResponse] = yield all([call(joinClassroomAPI, session_id), call(teacherGrantAccessRequestAPI, { session_id })]);

    // join class failed
    if (!joinClassResponse.ok) {
      yield put({ type: INIT_CLASSROOM.FAILURE, payload: joinClassResponse.data.message });
      return;
    }
    // pubnub grant access failed
    if (!pubnubGrantAccessResponse.ok) {
      yield put({ type: INIT_CLASSROOM.FAILURE, payload: pubnubGrantAccessResponse.data.message });
      return;
    }

    const { webrtc_details: webrtcDetails, ...sessionDetails } = isEmpty(joinClassResponse.data.data) ? {} : generateProductsFromTags(joinClassResponse.data.data[0]);
    const pubnubDetails = isEmpty(pubnubGrantAccessResponse.data.data) ? {} : pubnubGrantAccessResponse.data.data[0];

    // get user details
    const user = yield select(getUser);

    yield put({
      type: INIT_CLASSROOM.SUCCESS,
      payload: {
        sessionDetails: {
          ...sessionDetails,
        },
        agoraRtcDetails: {
          appId: webrtcDetails.webrtc_api,
          token: webrtcDetails.webrtc_token,
          uid: user.id.toString(),
          channel: sessionDetails.id.toString(),
        },
        agoraRtmDetails: {
          appId: webrtcDetails.webrtc_api,
          token: webrtcDetails.rtm_token,
          uid: user.id.toString(),
          channel: sessionDetails.id.toString(),
        },
        pubnubDetails,
        teacherDetails: user,
      },
    });

  } catch (err) {
    yield put({ type: INIT_CLASSROOM.FAILURE, payload: err });
  }
}

function* startClassroom({ payload }) {
  try {
    const response = yield call(startClassroomAPI, payload);
    if (response.ok) {
      yield put({
        type: START_CLASSROOM.SUCCESS,
        payload: isEmpty(response.data.data) ? {} : response.data.data[0],
      });
      if (!isEmpty(response.data.data) && response.data.data[0]?.class_type !== 'competition') {
        yield put({
          type: SELECT_SLIDE,
          payload: 0,
        });
      }
    } else {
      yield put({ type: START_CLASSROOM.FAILURE, payload: response.data.message });
    }
  } catch (err) {
    yield put({ type: START_CLASSROOM.FAILURE, payload: err });
  }
}

function* classroomLeaderboard({ payload }) {
  const { session_id, offset } = payload;
  try {
    const response = yield call(getLeaderboardAPI, { session_id, offset });
    if (response.data && response.data.data) {
      yield put({
        type: CLASSROOM_LEADERBOARD.SUCCESS,
        payload: response.data.data.map((student) => ({ ...student.user, score: student.score, correct_count: student.correct_count, status: student.state === 'entered' ? 'online' : 'offline', user_id: student.user.id })),
        meta: {
          offset: response.data.meta.offset || 0,
          studentCount: response.data.meta.total || 0,
          liveStudentCount: response.data.meta.totalLiveUsers || 0,
        },
      });
    } else {
      yield put({ type: CLASSROOM_LEADERBOARD.FAILURE, payload: 'NETWORK_ERROR' });
    }
  } catch (err) {
    yield put({ type: CLASSROOM_LEADERBOARD.FAILURE, payload: err });
  }
}

function* startClassroomRecording({ payload }) {
  try {
    const response = yield call(startClassroomRecordingAPI, payload.session_id);
    if (!response.ok) {
      yield delay(10000); // 10s delay
      yield put({ type: START_SESSION_RECORDING.REQUEST, payload });
    } else {
      localStorage.setItem('archiveStarted', payload.session_id);
    }
  } catch (err) {
    console.error('START_RECORDING_FAILED', payload.session_id);
  }
}

function* startHeartbeat(payload) {
  try {
    yield call(heartbeatApi, payload.session_id);
    yield delay(payload.heartbeat_duration * 1000);
    yield call(startHeartbeat, payload);
  } catch (err) {
    yield call(startHeartbeat, payload);
  }
}

let heartbeat;
function* initHeartbeat({ payload }) {
  try {
    if (!payload.heartbeat_duration) return;
    if (heartbeat) yield cancel(heartbeat);
    heartbeat = yield fork(startHeartbeat, payload);
    yield take(STOP_CLASSROOM_HEARTBEAT);
    yield cancel(heartbeat);
    heartbeat = null;
  } catch (err) {
    console.log(err);
  }
}

let isSlideEmpty = false; // if slide already created and image upload failed , we can use same slide to add next image
let lastActiveSlideIndex = -1;
function* createSlide(images, index, label_id, fetchLabelFromImageData) {
  try {
    if (!index) lastActiveSlideIndex = yield select(getSlideIndex);
    if (index >= images.length) {
      // all slide added
      yield put({ type: GENERATE_PDF_SLIDE.SUCCESS });
      yield put({ type: SELECT_SLIDE, payload: lastActiveSlideIndex === -1 ? 0 : lastActiveSlideIndex });
      lastActiveSlideIndex = -1;
      return;
    }
    const image = images[index];

    if (image.resource_type && image.resource_type === 'question') {
      yield handleAddingQuestionSlide(image);
      // Delay moving to next slide to allow question data to fetch and add to the question slide
      yield delay(2000);
      if (index + 1 < images.length) yield fork(createSlide, images, index + 1, fetchLabelFromImageData ? images[index + 1].label_id : label_id, !!fetchLabelFromImageData);
      else {
        yield put({ type: HIDE_PDF_SLIDE_MODAL });
      }
      return;
    }
    // Used to show which slide is being uploaded from pdf
    yield put({ type: UPDATE_PDF_SLIDE, payload: { slideInProcessIndex: index, slideInProcessUrl: image.src, slideInProcessFailed: false } });

    if (!isSlideEmpty) {
      const slides = yield select(getSlideList);
      yield put({ type: SLIDE_TO_BE_ADDED, payload: { label_id, label_name: fetchLabelFromImageData ? image.label_name : '', slideIndex: slides.length, broadcast: false, activate: false } }); // slide index to be added
      yield take(ADD_SLIDE.SUCCESS);

      // if question slide, dispatch question creation first instead of slide_to_beaded from question container and then add slice
      isSlideEmpty = true;
      yield delay(100); // added delay to add pdf image on new slide
    }

    const payload = {
      url: image.type === 'snapshot' ? image.snapshot_url : dataURLtoFile(image.src, `pdf-${image.id}.jpg`),
      isDataUrl: image.type !== 'snapshot',
      resourceType: image.type === 'snapshot' ? 'snapshot' : 'pdf',
    };

    yield put({ type: SET_SELECTED_IMAGE_CONTENT, payload });
    const { ImageUploadFailure } = yield race({ ImageUploadSuccess: take(IMAGE_ADDED_ON_WHITEBOARD.SUCCESS), ImageUploadFailure: take(IMAGE_ADDED_ON_WHITEBOARD.FAILURE) });

    // if image upload failed
    if (ImageUploadFailure) {
      yield put({ type: UPDATE_PDF_SLIDE, payload: { slideInProcessFailed: true } });
      yield delay(2000); // wait to show error animation before moving to next slide
      if (index + 1 < images.length) yield fork(createSlide, images, index + 1, fetchLabelFromImageData ? images[index + 1].label_id : label_id, !!fetchLabelFromImageData);
      return;
    }
    isSlideEmpty = false;
    yield fork(createSlide, images, index + 1, fetchLabelFromImageData && index + 1 < images.length ? images[index + 1].label_id : label_id, !!fetchLabelFromImageData);

  } catch (e) {
    yield put({ type: GENERATE_PDF_SLIDE.FAILURE, payload: 'slide creation error' });
    if (lastActiveSlideIndex !== -1) yield put({ type: SELECT_SLIDE, payload: lastActiveSlideIndex });
    console.log('create slide error', e);
  }
}

function* startPdfSlideCreation({ payload }) {
  const { images, existingLabel } = payload;
  yield delay(1000); // delay before starting first animtion
  yield call(createSlide, images, 0, existingLabel && !isEmpty(images) ? images[0].label_id : uuidv4(), !!existingLabel);
}

function* initRaiseHandRequest({ payload }) {
  const userIds = payload.raise_hand_queue.map((raiseHandRequest) => raiseHandRequest.user_id);
  const { data } = yield select(selectorStudentClassMeta);
  const { id: sessionId } = yield select(selectorSessionDetails);

  const filteredUserIds = filter(userIds, (userId) => !data[userId]);
  if (filteredUserIds.length > 0) {
    yield put({
      type: GET_STUDENT_CLASSROOM_META.REQUEST,
      payload: {
        userIds: filteredUserIds,
        sessionId,
      },
    });
  }
}

function* raiseHandRequest({ payload }) {
  if (!payload.user_id) return;
  const { isLoading, requestQueue, data } = yield select(selectorStudentClassMeta);
  if (data[payload.user_id]) return;

  if (isLoading) {
    yield put({
      type: ADD_STUDENT_CLASSROOM_META_QUEUE,
      payload: payload.user_id,
    });
  } else {
    const { id: sessionId } = yield select(selectorSessionDetails);
    yield put({
      type: GET_STUDENT_CLASSROOM_META.REQUEST,
      payload: {
        userIds: [...requestQueue, payload.user_id],
        sessionId,
      },
    });
  }
}

function* getStudentClassMeta({ payload }) {
  try {
    const response = yield call(getStudentClassMetaApi, payload);

    if (response.ok) {
      yield put({
        type: GET_STUDENT_CLASSROOM_META.SUCCESS,
        payload: isEmpty(response.data.data) ? {} : response.data.data,
      });
      const { requestQueue } = yield select(selectorStudentClassMeta);
      if (requestQueue.length > 0) {
        const { id: sessionId } = yield select(selectorSessionDetails);
        yield put({
          type: GET_STUDENT_CLASSROOM_META.REQUEST,
          payload: {
            userIds: requestQueue,
            sessionId,
          },
        });
      }
    } else {
      yield put({ type: GET_STUDENT_CLASSROOM_META.FAILURE, payload: response.data.message });
    }
  } catch (error) {
    yield put({ type: GET_STUDENT_CLASSROOM_META.FAILURE, payload: error });
  }
}

export function* myClassroomSaga() {
  yield all([
    takeLatest(INIT_CLASSROOM.REQUEST, initClassroom),
    takeLatest(START_CLASSROOM.REQUEST, startClassroom),
    takeLatest(START_CLASSROOM_HEARTBEAT, initHeartbeat),
    takeLatest(GET_SLIDES.REQUEST, getSlides),
    takeLatest(CHAT_HISTORY.REQUEST, chatHistory),
    takeLatest(CLASSROOM_LEADERBOARD.REQUEST, classroomLeaderboard),
    takeLatest(START_SESSION_RECORDING.REQUEST, startClassroomRecording),
    takeLatest(GENERATE_PDF_SLIDE.REQUEST, startPdfSlideCreation),
    takeLatest(INITIALIZE_RAISE_HAND, initRaiseHandRequest),
    takeLatest(RAISE_HAND_REQUEST, raiseHandRequest),
    takeLatest(GET_STUDENT_CLASSROOM_META.REQUEST, getStudentClassMeta),
  ]);
}

import React from 'react';
import { IconLoader, IconMic, IconRaiseHand } from '@noon/quark';
import { Column, Avatar, Button, Row, LiveTime } from '@noon/atom';
import Grid from 'react-virtualized/dist/es/Grid';
import { translationText } from '../../helpers';
import MicIcon from './micIcon';

const ITEM_WIDTH = 80;
const ITEM_HEIGHT = 89;
const RAISE_HAND_WIDGET_WIDTH = 375;
const RAISE_HAND_WIDGET_HEIGHT = 106;
const OVERSCAN_COUNT = 20;

const RaiseHandWidget = (prop) => {
  const { raiseHandQueue, raisedHandUser, sendRaiseHand, noonText, raiseHandLoadingFor, removeAllActiveMic, agoraStreams } = prop;

  const cellRenderer = ({ columnIndex, key, style }) => {
    const item = raiseHandQueue[columnIndex];
    const isAccepted = raisedHandUser.status && item.user_id === raisedHandUser.user_id;
    return (
      <Column key={key} style={style} className="raise-hand__item" align="center" nowrap>
        <Avatar url={item.profile_pic} size="50px" rounded noShadow showChild={item.user_id !== raisedHandUser.user_id}>
          <MicIcon
            wait
            user={item}
            active={['start_speaking', 'unmute'].includes(raisedHandUser.status)}
            onToggle={() => sendRaiseHand((['start_speaking', 'unmute'].includes(raisedHandUser.status) ? 'mute' : 'unmute'), item)}
          />
        </Avatar>
        {item.user_id === raisedHandUser.user_id && (
          <span className="liveTimer">
            <LiveTime value={(raisedHandUser && raisedHandUser.insertTime) || 0} />
          </span>
        )}
        <Button
          size="sm"
          type={isAccepted ? 'red' : 'green'}
          rounded
          disabled={Boolean(raiseHandLoadingFor)}
          className={isAccepted ? 'rejectBtn' : 'acceptBtn'}
          onClick={() => sendRaiseHand(!isAccepted ? 'start_speaking' : 'remove', item)}
        >
          {String(raiseHandLoadingFor) === String(item.user_id) ? (
            <IconLoader height="10px" width="10px" />
          ) : (
            isAccepted ? translationText(noonText, 'button.cancel') : translationText(noonText, 'button.accept')
          )}
        </Button>
        <span title={item.name} className="name">
          {item.name}
        </span>
      </Column>
    );
  };

  return (
    <div className="raise-hand">
      <Row align="center" gap className="sidebar-heading-wrapper">
        <h4 className="child sidebar-heading">{translationText(noonText, 'classroom.raiseHandHeadingTeacher')}</h4>
        {Boolean(agoraStreams.length) && (
          <Button
            type="red"
            size="sm"
            rounded
            loading={raiseHandLoadingFor === -1}
            onClick={removeAllActiveMic}>
              {translationText(noonText, 'classroom.raiseHandMuteAll')}
          </Button>
        )}
      </Row>
      {raiseHandQueue.length ? (
        <Row nowrap style={{ overflowY: 'auto', marginTop: '-0.5rem' }}>
          <Grid
            cellRenderer={cellRenderer}
            columnCount={raiseHandQueue.length}
            width={RAISE_HAND_WIDGET_WIDTH}
            height={RAISE_HAND_WIDGET_HEIGHT}
            rowCount={1}
            columnWidth={ITEM_WIDTH}
            rowHeight={ITEM_HEIGHT}
            overscanColumnCount={OVERSCAN_COUNT}
          />
        </Row>
      ) : (
        <Row nowrap className="subtitle plr-1">
          <IconMic fill="#ccc" width="28px" className="micSvg" />
          <Column className="raiseHand-subheading">
            <Row className="headingOne">
              {translationText(noonText, 'classroom.raiseHandNoRequest')}
            </Row>
            <Row className="headingTwo">
              {translationText(noonText, 'classroom.raiseHandAskStudent')}
                &nbsp;
              <IconRaiseHand width="19" height="20" fill="#ccc" />
                &nbsp;
              {translationText(noonText, 'classroom.raiseHandAskStudent2')}
            </Row>
          </Column>
        </Row>
      )}
    </div>
  );
};

export default RaiseHandWidget;

import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import isEmpty from 'lodash-es/isEmpty';
import { isMobileOnly } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Column, NoonDate, MoreActions, Time, LiveTime, Badge } from '@noon/atom';
import classNames from 'classnames';
import {
  IconNotAllowed,
  IconClock,
  IconCompetition,
  IconDelete,
  IconEditO,
  IconExclamationCircle,
  IconLive,
  IconReplayPlay,
  IconReplayPlayO,
  IconSession,
  IconStar,
} from '@noon/quark';
import { DateTime } from 'luxon';
import { ReportSession, CreateActivity } from '../Create';
import { DELETE_SESSION, CLEAR_TUTORING_DETAILS, TOGGLE_PLAYBACK, SESSION_PREP_START } from '../../redux/constants';
import { addToast, TOAST_TYPE } from '../Toast';
import { COLORS } from '../../constants';
import { ConfirmationModal } from '../Modals';
import { translationText } from '../../helpers';
import { useLocalStorage } from '../../hooks';

const ScheduleListSessionCard = (prop) => {
  const { data: sessionData, cardView, noMargin, toggleCreateActivity } = prop;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [showItem, assignItem] = useState('');
  const [itemActions, assignitemActions] = useState([]);
  const [timeRemainingMin, setTimeRemainingMin] = useState(0);
  const { deleteSession, toggleTeacher } = useSelector((state) => state.toJS().tutoring);
  const noonText = useSelector((state) => state.toJS().translation.noonText);

  const isRtl = document.body.dir === 'rtl';
  const isSession = sessionData.class_type === 'group' || sessionData.type === 'group';
  const firstChapter =
    sessionData.curriculum_tags && !isEmpty(sessionData.curriculum_tags.chapter)
      ? sessionData.curriculum_tags.chapter
      : null;
  const firstTopic =
    sessionData.curriculum_tags && !isEmpty(sessionData.curriculum_tags.topics)
      ? sessionData.curriculum_tags.topics[0]
      : null;
  let completed = false;
  if (sessionData.state === 'scheduled') {
    if (
      sessionData.curriculum_tags &&
      sessionData.curriculum_tags.chapter &&
      sessionData.curriculum_tags.chapter.state === 'completed'
    ) {
      completed = true;
    }
    if (
      sessionData.curriculum_tags &&
      sessionData.curriculum_tags.topics &&
      isEmpty(sessionData.curriculum_tags.topics.filter((item) => item.state !== 'completed'))
    ) {
      completed = true;
    }
  }
  let localTimeRemainingDiff = Math.round((sessionData.start_time - new Date().getTime()) / 1000);
  let localTimeRemainingMin = Math.floor(localTimeRemainingDiff / 60);
  const isMissed = localTimeRemainingMin < -15 && sessionData.state !== 'started';

  const { isSessionEnded, showSessionCardAsStarted, isSessionAboutToStart } = useMemo(
    () => ({
      isSessionEnded:
        sessionData.state === 'ended' ||
        sessionData.state === 'visible' ||
        sessionData.state === 'playback' ||
        sessionData.state === 'hidden' ||
        (DateTime.local().minus({ hour: 2 }).ts > sessionData.start_time &&
          sessionData.state !== 'started' &&
          !isMissed),
      showSessionCardAsStarted:
        sessionData.state === 'started' &&
        !isMissed &&
        localTimeRemainingMin < 0 &&
        sessionData.start_time < Date.now(),
      isSessionAboutToStart:
        (sessionData.state === 'scheduled' ||
          sessionData.state === 'not_prepared' ||
          sessionData.state === 'prepared') &&
        localTimeRemainingMin < 10 &&
        !isMissed,
    }),
    [sessionData],
  );

  useEffect(() => {
    let sessionTimer = '';
    localTimeRemainingDiff = localTimeRemainingDiff <= 0 ? 0 : localTimeRemainingDiff;
    if (localTimeRemainingMin <= 30 && localTimeRemainingMin > 0) {
      if (sessionTimer) clearInterval(sessionTimer);
      sessionTimer = setInterval(() => {
        localTimeRemainingMin -= 1;
        setTimeRemainingMin(localTimeRemainingMin);
        if (localTimeRemainingMin < 0) {
          clearInterval(sessionTimer);
        }
      }, 60000);
      setTimeRemainingMin(localTimeRemainingMin);
    } else if (localTimeRemainingDiff <= 0 && sessionData.state === 'scheduled') {
    } else {
      setTimeRemainingMin(localTimeRemainingMin);
    }
    return () => {
      if (sessionTimer) clearInterval(sessionTimer);
    };
  }, [sessionData]);

  const assignAction = () => {
    const timeRemaining = Math.floor((sessionData.start_time - Date.now()) / 60000);
    if (!itemActions.action) {
      if (isSessionEnded) {
        if (sessionData.playback_available) {
          assignitemActions([
            {
              text: sessionData.type === 'group' ? 'activity.editPlayback' : 'activity.watch',
              action: 'watch',
              color: 'red',
            },
            { text: 'activity.viewReport', action: 'report' },
          ]);
        } else {
          assignitemActions([{ text: 'activity.viewReport', action: 'report' }]);
        }
      } else if (
        sessionData.state === 'not_prepared' ||
        sessionData.state === 'scheduled' ||
        sessionData.state === 'prepared'
      ) {
        if (isMissed) {
          assignitemActions([
            { text: 'activity.start', action: 'start' },
            { text: 'activity.edit', action: 'modify' },
          ]);
        } else if (timeRemaining <= 0) assignitemActions([{ text: 'activity.start', action: 'start' }]);
        else if (!sessionData.preparation_complete || sessionData.state === 'not_prepared') {
          assignitemActions([{ text: 'activity.prepare', action: 'prepare' }]);
        } else assignitemActions([{ text: 'activity.edit', action: 'modify' }]);
      } else {
        assignitemActions([{ text: 'activity.rejoin', action: 'rejoin' }]);
      }
    }
  };

  useEffect(() => {
    assignAction();
  }, []);

  useEffect(() => {
    if (deleteSession && !deleteSession.loading && deleteSession.success) {
      dispatch({ type: CLEAR_TUTORING_DETAILS });
      assignItem('');
    }
    if (toggleTeacher && !toggleTeacher.loading && toggleTeacher.success) {
      dispatch({ type: CLEAR_TUTORING_DETAILS });
      assignItem('');
    }
  }, [deleteSession, toggleTeacher]);

  const takeAction = (actionType) => {
    localStorage.lastGroupUrl = JSON.stringify(location);
    if (!sessionData.id) {
      addToast('DATA MISSING', TOAST_TYPE.ERROR);
      return false;
    }
    if (actionType === 'prepare' || actionType === 'modify' || actionType === 'rejoin' || actionType === 'start') {
      if (actionType === 'prepare') {
        dispatch({ type: SESSION_PREP_START, payload: { sessionData } });
      }

      history.push(`/${localStorage.updatedLocale}/class/${sessionData.id}`);
    } else if (actionType === 'watch') {
      history.push(`/${localStorage.updatedLocale}/play/${sessionData.id}`);
    } else if (actionType === 'report') {
      assignItem('report');
    } else if (actionType === 'edit') {
      if (toggleCreateActivity) {
        toggleCreateActivity({ type: sessionData.type, source: 'planner-list-edit', sessionData });
      } else {
        assignItem('edit');
      }
    } else if (actionType === 'delete') {
      assignItem('delete');
    } else if (actionType === 'togglePlayback') {
      assignItem('togglePlayback');
    }
    return true;
  };

  const deleteSessionFn = () => {
    dispatch({
      type: DELETE_SESSION.REQUEST,
      payload: { id: sessionData.id },
    });
  };

  const togglePlaybackFn = (flag) => {
    if (sessionData.teacher_enabled !== flag) {
      dispatch({
        type: TOGGLE_PLAYBACK.REQUEST,
        payload: { session_id: sessionData.id, teacher_enabled: flag },
      });
    } else {
      assignItem('');
    }
  };

  const getOptions = () => {
    const minutes = Math.floor((sessionData.start_time - Date.now()) / 60000);
    const moreActionList = [
      {
        name: 'delete',
        icon: IconDelete,
        text: translationText(noonText, 'activity.delete'),
      },
    ];
    if (
      (sessionData.state === 'not_prepared' || sessionData.state === 'scheduled' || sessionData.state === 'prepared') &&
      minutes > 10
    ) {
      moreActionList.push({
        name: 'edit',
        icon: IconEditO,
        text: translationText(noonText, 'activity.edit'),
      });
    }
    if (isSessionEnded && sessionData.playback_available) {
      moreActionList.push({
        name: 'togglePlayback',
        icon: IconReplayPlayO,
        text: translationText(noonText, `session.${sessionData.teacher_enabled ? 'unpublish' : 'publishedNew'}`),
      });
    }
    return moreActionList;
  };

  const takeActionOnPhone = (action) => {
    if (isMobileOnly && !completed && !isMissed) {
      takeAction(action);
    }
  };

  const SessionTypeBlock = () => (
    <Row
      className={classNames('type-block', isSession ? 'session' : 'competition')}
      align="center"
      justify="space-evenly"
    >
      {isSession ? (
        <IconSession height="11px" width="15px" fill={COLORS.session.base} />
      ) : (
        <IconCompetition height="14px" width="13px" fill={COLORS.orange.base} />
      )}
      <span>
        {translationText(
          noonText,
          `session.${isSessionEnded && isSession ? 'typePlayback' : isSession ? 'typeGroup' : 'typeCompetition'}`,
        )}
      </span>
    </Row>
  );

  const NotPreparedBlock = () =>
    !sessionData.preparation_complete &&
    !isMissed && (
      <React.Fragment>
        <Row nowrap className="type-block error" align="center" justify="space-evenly">
          <IconExclamationCircle fill={COLORS.red.base} width="17px" height="17px" />
          <span>{translationText(noonText, 'activity.notPrepared')}</span>
        </Row>
        <span className="noon-dot" />
      </React.Fragment>
    );

  const SessionTimeBlock = () => (
    <Row align="center" className={classNames('time-block', { ended: isSessionEnded })} gap="sm">
      <IconClock
        height="15px"
        width="15px"
        fill={
          !isSessionEnded && isSession
            ? COLORS.session.tint
            : !isSessionEnded && !isSession
            ? COLORS.orange.tint
            : COLORS.coolGrey[2]
        }
      />
      <Time unit="epoch" humanize suffix value={sessionData.start_time} />
    </Row>
  );

  const SessionChapterTopicBlock = () => (
    <React.Fragment>
      {sessionData.curriculum_tags && (
        <p className="chapter-topic">
          {firstChapter && firstChapter.name_header}
          {firstChapter && firstChapter.name_header && firstTopic && firstTopic.name_header ? ', ' : ''}
          {firstTopic && firstTopic.name_header}
        </p>
      )}
    </React.Fragment>
  );

  const RatingBlock = () =>
    !!sessionData.average_session_rating && (
      <React.Fragment>
        <span className="noon-dot" />
        <div className="rating_block">
          <IconStar fill="#FAAD14" width="15px" height="14px" />
          <span>{sessionData.average_session_rating}</span>
        </div>
      </React.Fragment>
    );

  const ViewsBlock = () => {
    let views = sessionData.playback_count;
    if (views > 1000000) {
      views = `${Math.floor(views / 1000000)}M`;
    } else if (views > 1000) {
      views = `${Math.floor(views / 1000)}K`;
    }
    return (
      <Column className="views_block">
        <div className="views">
          <img src="/assets/images/2020/playback.jpg" alt="playback still" />
          {sessionData.playback_available ? (
            <IconReplayPlay fill="#ffffff" width="21px" height="21px" />
          ) : (
            <IconNotAllowed />
          )}
        </div>
        {!sessionData.playback_available ? (
          <span>{translationText(noonText, 'session.playbackUnavailable')}</span>
        ) : (
          <span>
            {views} {translationText(noonText, 'session.views')}
          </span>
        )}
      </Column>
    );
  };

  const PlannerPagePreviousSessionsInfo = () => (
    <React.Fragment>
      <li className="card-section card-section--time" onClick={() => takeActionOnPhone(itemActions[0].action)}>
        <ViewsBlock />
      </li>
      <li className="card-section card-section--main" onClick={() => takeActionOnPhone(itemActions[0].action)}>
        <Row nowrap className="main-content main-content--type-info">
          <SessionTypeBlock />
          {sessionData.group && sessionData.group.title && (
            <h6 className="group-name">
              <span className="noon-dot" />
              {sessionData.group.title}
            </h6>
          )}
        </Row>
        <Row className="main-content main-content--other-info" gap="sm">
          {!sessionData.teacher_enabled && (
            <div className="tags">
              <span>{translationText(noonText, 'activity.hidden')}</span>
            </div>
          )}
          <SessionTimeBlock />
          <RatingBlock />
          <SessionChapterTopicBlock />
        </Row>
        <Row className="main-content main-content--name-area">
          <h5>{sessionData.title}</h5>
        </Row>
      </li>
    </React.Fragment>
  );

  const ContentPagePreviousSessionsInfo = () => (
    <React.Fragment>
      <li className="card-section card-section--time" onClick={() => takeActionOnPhone(itemActions[0].action)}>
        <ViewsBlock />
      </li>
      <li className="card-section card-section--main" onClick={() => takeActionOnPhone(itemActions[0].action)}>
        <Row className="main-content main-content--type-info" gap="sm">
          {!sessionData.teacher_enabled && (
            <div className="tags">
              <span>{translationText(noonText, 'activity.hidden')}</span>
            </div>
          )}
          <SessionTypeBlock />
          <SessionChapterTopicBlock />
        </Row>
        <Row className="main-content main-content--name-area">
          <RatingBlock />
          <h5>{sessionData.title}</h5>
        </Row>
        <Row className="main-content main-content--other-info" gap="sm">
          <NoonDate humanize value={sessionData.start_time} format="dd LLL yyyy" />
          <SessionTimeBlock />
        </Row>
      </li>
    </React.Fragment>
  );

  const PlannerPageScheduledSessionsInfo = () => (
    <React.Fragment>
      <li className="card-section card-section--time" onClick={() => takeActionOnPhone(itemActions[0].action)}>
        <Column align="center" justify="center" flex="1" className="time-wrapper">
          {isSessionAboutToStart && timeRemainingMin <= 0 ? (
            <div className="timer-countdown">
              <span>{translationText(noonText, 'session.startNow')}</span>
            </div>
          ) : isSessionAboutToStart || showSessionCardAsStarted ? (
            <React.Fragment>
              <Column
                align="center"
                nowrap
                className={classNames('timer-countdown', { live: sessionData.state === 'started' })}
              >
                {showSessionCardAsStarted && (
                  <Row className="live-icon" align="center">
                    <IconLive height="16px" width="14px" fill={COLORS.red.base} />
                    <span>{translationText(noonText, 'label.live')}</span>
                  </Row>
                )}
                <LiveTime unit="epoch" value={sessionData.start_time} className="" />
                {!showSessionCardAsStarted && <p>{translationText(noonText, 'session.minsLeft')}</p>}
              </Column>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <NoonDate humanize value={sessionData.start_time} format="dd LLL yyyy" />
              <SessionTimeBlock />
            </React.Fragment>
          )}
        </Column>
      </li>
      <li className="card-section card-section--main" onClick={() => takeActionOnPhone(itemActions[0].action)}>
        <Row className="main-content main-content--type-info" gap="sm">
          {completed ? (
            <div className="tags">
              <span>{translationText(noonText, 'session.skipped')}</span>
            </div>
          ) : isMissed ? (
            <div className="tags">
              <span>{translationText(noonText, 'session.missed')}</span>
            </div>
          ) : (
            ''
          )}
          <SessionTypeBlock />
          {cardView !== 'content' && sessionData.group && sessionData.group.title && (
            <h6 className="group-name">{sessionData.group.title}</h6>
          )}
          {((firstChapter && firstChapter.name_header) || (firstTopic && firstTopic.name_header)) &&
            ((isMobileOnly && cardView === 'content') || !isMobileOnly) && <span className="noon-dot" />}
          {((isMobileOnly && cardView === 'content') || !isMobileOnly) && <SessionChapterTopicBlock />}
        </Row>
        <Row className="main-content main-content--name-area">
          <NotPreparedBlock />
          <h5>{sessionData.title || sessionData.name}</h5>
        </Row>
      </li>
    </React.Fragment>
  );

  return (
    // Activity cards
    <Row
      className={classNames('activity-card', 'activity-card--list-view', `${cardView}-page`, {
        competition: sessionData.class_type === 'competition' || sessionData.type === 'competition',
        time_remaining: showSessionCardAsStarted || isSessionAboutToStart,
        hidden:
          (isSessionEnded && (!sessionData.playback_available || !sessionData.teacher_enabled)) ||
          isMissed ||
          completed,
        time_of_activity: isSessionEnded,
        'three-lined': isSessionEnded,
        'no-margin': noMargin,
        // completed: completed && !missed, We don't need to disable, as this will disable block if session is completed but not missed and not started
      })}
    >
      {showItem === 'delete' && (
        <ConfirmationModal
          modalType="delete"
          successBtn={translationText(noonText, 'activity.modalYes')}
          closeBtn={translationText(noonText, 'activity.modalNo')}
          onClose={() => assignItem('')}
          onSuccess={() => deleteSessionFn()}
          isLoading={deleteSession.loading}
          text={translationText(noonText, 'session.deleteHeading')}
          subText={translationText(noonText, 'session.deleteSubheading')}
        />
      )}
      {showItem === 'togglePlayback' && (
        <ConfirmationModal
          modalType="warning"
          successBtn={translationText(noonText, 'button.yesIWantTo')}
          closeBtn={translationText(noonText, 'button.noIDontWant')}
          onClose={() => togglePlaybackFn(false)}
          onSuccess={() => togglePlaybackFn(!sessionData.teacher_enabled)}
          isLoading={toggleTeacher.loading}
          text={translationText(noonText, 'session.wantToShowPlayback')}
          subText={translationText(noonText, 'session.uCanChangeIt')}
        />
      )}
      {showItem === 'edit' && sessionData && (
        <CreateActivity
          sessionData={sessionData}
          groupId={!isEmpty(sessionData.group) ? sessionData.group.id : null}
          hideAssignment="true"
          hideCompetition={sessionData.class_type !== 'competition'}
          hideSession={sessionData.class_type !== 'group'}
          onContentClose={() => assignItem('')}
          source="main_content_page"
        />
      )}
      {showItem === 'report' && (
        <ReportSession
          onContentClose={() => assignItem('')}
          session={sessionData}
          groupId={!isEmpty(sessionData.group) ? sessionData.group.id : null}
          type={sessionData.class_type}
        />
      )}
      {/* Separated out time and main sections to reduce clutter */}
      {isSessionEnded ? (
        <React.Fragment>
          {cardView === 'content' ? <ContentPagePreviousSessionsInfo /> : <PlannerPagePreviousSessionsInfo />}
        </React.Fragment>
      ) : (
        <PlannerPageScheduledSessionsInfo />
      )}
      {!sessionData.is_free && (
        <Badge type="default" dir="ltr" className="plus-badge">
          <span>{translationText(noonText, 'groups.plus')}</span>
        </Badge>
      )}
      <li className="card-section card-section--action">
        {sessionData.state !== 'started' && (
          <MoreActions
            type="dots"
            position={isRtl ? 'right' : 'left'}
            className="activity"
            listActions={getOptions()}
            cardId={sessionData.id}
            onSelect={(actionName) => takeAction(actionName)}
            vertical
          />
        )}
        <div className="card-section--action">
          <Row className="data" flex="1">
            <p>
              <Column className="action-items">
                {itemActions.length &&
                  itemActions.map((item) => (
                    <span
                      className={item.color ? item.color : ''}
                      key={item.text}
                      onClick={() => takeAction(item.action)}
                    >
                      {translationText(noonText, item.text)}
                    </span>
                  ))}
              </Column>
            </p>
          </Row>
        </div>
      </li>
    </Row>
  );
};

export default ScheduleListSessionCard;

import {

  GET_ALL_QUESTIONS_POSTS,
  CREATE_QUESTIONS_POST,
  CREATE_ANSWER,
  CREATE_REPLY,
  GET_QUESTION_ANSWERS,
  GET_ANSWERS_REPLIES,
  DELETE_ANSWER,
  DELETE_QUESTION,
  DELETE_REPLY,
  UPVOTE_QUESTION,
  THANK_ANSWER,
  LIKE_REPLY,
  GET_COUNT_FOR_QUESTION_FILTER,
  QUEUE_FOR_QUESTION,
  QUEUE_FOR_ANSWER,
  QUEUE_FOR_REPLY,
  ANSWER_COUNT_MAP,
  REPLY_COUNT_MAP,
  THANK_COUNT_MAP,
} from '../constants';

const initialState = {
  allQuestions: {
    response: {
      list: [],
      meta: {},
    },
    isLoading: false,
  },
  answers: {
    response: {},
    isLoading: false,
  },
  replies: {
    response: {},
    isLoading: false,
  },
  createQuestion: {
    response: null,
    isLoading: 0,
  },
  createAnswer: {
    response: null,
    isLoading: 0,
  },
  createReply: {
    response: null,
    isLoading: 0,
  },
  deleteQuestion: {
    response: null,
    isLoading: 0,
  },
  deleteAnswer: {
    response: null,
    isLoading: 0,
  },
  deleteReply: {
    response: null,
    isLoading: 0,
  },
  upvoteQuestion: {
    response: {},
    isLoading: 0,
  },
  thankAnswer: {
    response: {},
    isLoading: 0,
  },
  likeReply: {
    response: {},
    isLoading: 0,
  },
  likeComment: {
    response: {},
    isLoading: 0,
  },
  queueForQuestion: {},
  queueForAnswer: {},
  queueForReply: {},
  getFilterCount: {
    response: {},
    isLoading: false,
  },
  currentIdForUpvote: {},
  currentThankId: {},
  currentReplyId: {},
  error: {},
  answerCountMap: {},
  replyCountMap: {},
  thankCountMap: {},
  likeCountMap: {},
};

export default function feedQuestion(state = initialState, action) {
  switch (action.type) {

    case ANSWER_COUNT_MAP:
      return {
        ...state,
        answerCountMap: {
          ...state.answerCountMap,
          [action.payload.id]: action.payload.count,
        },
      };
    case REPLY_COUNT_MAP:
      return {
        ...state,
        replyCountMap: {
          ...state.replyCountMap,
          [action.payload.id]: action.payload.count,
        },
      };
    case THANK_COUNT_MAP:
      return {
        ...state,
        thankCountMap: {
          ...state.thankCountMap,
          [action.payload.id]: action.payload.count,
        },
      };
    case 'CURRENT_ID_FOR_THANK':
      return {
        ...state,
        currentThankId: action.payload,
      };
    case 'CURRENT_ID_FOR_LIKE':
      return {
        ...state,
        currentReplyId: action.payload,
      };
    case GET_ALL_QUESTIONS_POSTS.REQUEST:
      return {
        ...state,
        allQuestions: {
          ...state.allQuestions,
          isLoading: true,
        },
      };
    case GET_ALL_QUESTIONS_POSTS.SUCCESS:
      return {
        ...state,
        allQuestions: {
          ...state.allQuestions,
          response: {
            ...state.allQuestions.response,
            list:
              action.payload.start === 0
                ? action.payload.list
                : [...state.allQuestions.response.list, ...action.payload.list],
            meta: action.payload.meta ? action.payload.meta : state.allQuestions.response.meta,
          },
          isLoading: false,
        },
      };
    case GET_ALL_QUESTIONS_POSTS.FAILURE:
      return {
        ...state,
        error: { allQuestions: action.payload },
        allQuestions: {
          ...state.allQuestions,
          isLoading: false,
        },
      };
    case GET_QUESTION_ANSWERS.REQUEST:
      return {
        ...state,
        answers: {
          ...state.answers,
          isLoading: action.payload.questionId,
        },
      };
    case GET_QUESTION_ANSWERS.SUCCESS:
      return {
        ...state,
        answers: {
          ...state.answers,
          response:
              action.payload === 'clear'
                ? {}
                : {
                  ...state.answers.response,
                  [action.payload.id]: {
                    ...state.answers.response[action.payload.id],
                    list:
                        action.payload.start === 0
                          ? action.payload.list
                          : [...state.answers.response[action.payload.id].list, ...action.payload.list],
                    meta: action.payload.meta,
                  },
                },

          isLoading: 0,
        },
      };
    case GET_QUESTION_ANSWERS.FAILURE:
      return {
        ...state,
        error: { answers: action.payload },
        answers: {
          ...state.answers,
          isLoading: 0,
        },
      };
    case GET_ANSWERS_REPLIES.REQUEST:
      return {
        ...state,
        replies: {
          ...state.replies,
          isLoading: action.payload.answerId,
        },
      };
    case GET_ANSWERS_REPLIES.SUCCESS:
      return {
        ...state,
        replies: {
          ...state.replies,
          response:
                action.payload === 'clear'
                  ? {}
                  : {
                    ...state.replies.response,
                    [action.payload.id]: {
                      ...state.replies.response[action.payload.id],
                      list:
                          action.payload.start === 0
                            ? action.payload.list
                            : [...state.replies.response[action.payload.id].list, ...action.payload.list],
                      meta: action.payload.meta,
                    },
                  },

          isLoading: 0,
        },
      };
    case GET_ANSWERS_REPLIES.FAILURE:
      return {
        ...state,
        error: { replies: action.payload },
        replies: {
          ...state.replies,
          isLoading: 0,
        },
      };
    case CREATE_QUESTIONS_POST.REQUEST:
      return {
        ...state,
        createQuestion: {
          ...state.createQuestion,
          isLoading: true,
        },
      };
    case CREATE_QUESTIONS_POST.SUCCESS:
      return {
        ...state,
        createQuestion: {
          ...state.createQuestion,
          response: action.payload,
          isLoading: false,
        },
      };
    case CREATE_QUESTIONS_POST.FAILURE:
      return {
        ...state,
        error: { createQuestion: action.payload },
        createQuestion: {
          ...state.createQuestion,
          isLoading: false,
        },
      };
    case CREATE_ANSWER.REQUEST:
      return {
        ...state,
        createAnswer: {
          ...state.createAnswer,
          isLoading: action.payload.questionId,
        },
      };
    case CREATE_ANSWER.SUCCESS:
      return {
        ...state,
        createAnswer: {
          ...state.createAnswer,
          response: action.payload,
          isLoading: false,
        },
      };
    case CREATE_ANSWER.FAILURE:
      return {
        ...state,
        error: { createAnswer: action.payload },
        createAnswer: {
          ...state.createAnswer,
          isLoading: false,
        },
      };

    case CREATE_REPLY.REQUEST:
      return {
        ...state,
        createReply: {
          ...state.createReply,
          isLoading: action.payload.answerId,
        },
      };
    case CREATE_REPLY.SUCCESS:
      return {
        ...state,
        createReply: {
          ...state.createReply,
          response: action.payload,
          isLoading: false,
        },
      };
    case CREATE_REPLY.FAILURE:
      return {
        ...state,
        error: { createReply: action.payload },
        createReply: {
          ...state.createReply,
          isLoading: false,
        },
      };

    case UPVOTE_QUESTION.REQUEST:
      return {
        ...state,
        upvoteQuestion: {
          ...state.upvoteQuestion,
          isLoading: action.payload.questionId,
        },
      };
    case UPVOTE_QUESTION.SUCCESS:
      return {
        ...state,
        upvoteQuestion: {
          ...state.upvoteQuestion,
          response: {
            ...state.upvoteQuestion.response,
            ...action.payload,
          },

          isLoading: 0,
        },
      };
    case UPVOTE_QUESTION.FAILURE:
      return {
        ...state,
        error: { upvoteQuestion: action.payload },
        upvoteQuestion: {
          ...state.upvoteQuestion,
          isLoading: 0,
        },
      };
    case THANK_ANSWER.REQUEST:
      return {
        ...state,
        thankAnswer: {
          ...state.thankAnswer,
          isLoading: action.payload.isLoading,
        },
      };
    case THANK_ANSWER.SUCCESS:
      return {
        ...state,
        thankAnswer: {
          ...state.thankAnswer,
          response: {
            ...state.thankAnswer.response,
            ...action.payload,
          },
          isLoading: 0,
        },
      };
    case THANK_ANSWER.FAILURE:
      return {
        ...state,
        error: { thankAnswer: action.payload },
        thankAnswer: {
          ...state.thankAnswer,
          isLoading: 0,
        },
      };
    case LIKE_REPLY.REQUEST:
      return {
        ...state,
        likeReply: {
          ...state.likeReply,
          isLoading: action.payload.isLoading,
        },
      };
    case LIKE_REPLY.SUCCESS:
      return {
        ...state,
        likeReply: {
          ...state.likeReply,
          response: {
            ...state.likeReply.response,
            ...action.payload,
          },
          isLoading: 0,
        },
      };
    case LIKE_REPLY.FAILURE:
      return {
        ...state,
        error: { likePost: action.payload },
        likeReply: {
          ...state.likeReply,
          isLoading: 0,
        },
      };
    case DELETE_ANSWER.REQUEST:
      return {
        ...state,
        deleteAnswer: {
          ...state.deleteAnswer,
          isLoading: true,
        },
      };
    case DELETE_ANSWER.SUCCESS:
      return {
        ...state,
        deleteAnswer: {
          ...state.deleteAnswer,
          reponse: action.payload,
          isLoading: false,
        },
      };
    case DELETE_ANSWER.FAILURE:
      return {
        ...state,
        error: { deleteAnswer: action.payload },
        deleteAnswer: {
          ...state.deleteAnswer,
          isLoading: false,
        },
      };
    case DELETE_QUESTION.REQUEST:
      return {
        ...state,
        deleteQuestion: {
          ...state.deleteQuestion,
          isLoading: true,
        },
      };
    case DELETE_QUESTION.SUCCESS:
      return {
        ...state,
        deleteQuestion: {
          ...state.deleteQuestion,
          reponse: action.payload,
          isLoading: false,
        },
      };
    case DELETE_QUESTION.FAILURE:
      return {
        ...state,
        error: { deleteQuestion: action.payload },
        deleteQuestion: {
          ...state.deleteQuestion,
          isLoading: false,
        },
      };
    case DELETE_REPLY.REQUEST:
      return {
        ...state,
        deleteReply: {
          ...state.deleteReply,
          isLoading: true,
        },
      };
    case DELETE_REPLY.SUCCESS:
      return {
        ...state,
        deleteReply: {
          ...state.deleteReply,
          reponse: action.payload,
          isLoading: false,
        },
      };
    case DELETE_REPLY.FAILURE:
      return {
        ...state,
        error: { deleteReply: action.payload },
        deleteReply: {
          ...state.deleteReply,
          isLoading: false,
        },
      };
    case GET_COUNT_FOR_QUESTION_FILTER.REQUEST:
      return {
        ...state,
        getFilterCount: {
          ...state.getFilterCount,
          isLoading: true,
        },
      };
    case GET_COUNT_FOR_QUESTION_FILTER.SUCCESS:
      return {
        ...state,
        getFilterCount: {
          ...state.getFilterCount,
          response: action.payload,
          isLoading: false,
        },
      };
    case GET_COUNT_FOR_QUESTION_FILTER.FAILURE:
      return {
        ...state,
        error: { getFilterCount: action.payload },
        getFilterCount: {
          ...state.getFilterCount,
          isLoading: false,
        },
      };
    case QUEUE_FOR_QUESTION:
      return {
        ...state,
        queueForQuestion: {
          ...state.queueForQuestion,
          [action.payload.id]: action.payload.reaction,
        },
      };
    case QUEUE_FOR_ANSWER:
      return {
        ...state,
        queueForAnswer: {
          ...state.queueForAnswer,
          [action.payload.id]: action.payload.reaction,
        },
      };
    case QUEUE_FOR_REPLY:
      return {
        ...state,
        queueForReply: {
          ...state.queueForAnswer,
          [action.payload.id]: action.payload.reaction,
        },
      };
    default:
      if (action.type.indexOf('_REQUEST') !== -1) {
        return {
          ...state,
          error: {},
        };
      }
      return state;
  }
}

import { isEqual } from 'lodash-es';
import { GET_ORIGINAL_PLAYBACK_DETAILS, GET_EDITED_PLAYBACK_DETAILS, PLAYBACK_ON_PROGRESS, PLAYBACK_ON_DURATION_LOAD, PLAYBACK_START, PLAYBACK_PAUSE, GET_TIMESERIES_DATA, GET_SNAPSHOT_DATA, PLAYBACK_UPDATE_STATE, UPDATE_PLAYBACK_LAST_SEEK, CLEAR_PLAYBACK_DATA, UPDATE_PLAYBACK_SECTIONS, DELETE_PLAYBACK_SLIDE, TRIM_PLAYBACK_SLIDE, RESET_DELETED_SLIDE, RESET_EDITED_SLIDE, PLAYBACK_STOP, GET_BUFFERED_TIMESERIES, PLAYBACK_LOAD_NEXT_DATA, UPDATE_PLAYER_DATA,
  PLAYBACK_INTERACTIVE_QUESTION_START,
  PLAYBACK_INTERACTIVE_QUESTION_END } from '../constants';

const initialState = {
  playbackDetails: {
    data: {},
    loading: false,
    retries: 0,
  },
  editedPlaybackDetails: {
    data: {},
    loading: false,
    retries: 0,
  },
  playerData: {
    streamUrl: null,
    fallbackUrl: null,
    currentSeek: null,
    playedSeconds: null,
    played: null,
    loadedSeconds: null,
    playbackRate: 1,
    duration: 0,
    fullDuration: 0,
    trimmedDuration: 0,
    state: 'initialising',
    currentSeekSeconds: 0,
    ended: false,
    pausedByUser: true,
    // STATES
    // initialising: before anything,
    // ready: initially before play,
    // paused: paused by user,
    // playing: played by user,
    // buffering: buffering,
    // error: something bad happened
    volume: 1,
    drawOnlyMode: false,
    enabled: true,
  },
  timeSeriesData: {
    data: {},
    loading: false,
    buffering: false,
    offset: {
      value: 0,
      fromStart: false,
    },
  },
  timeSeriesNextData: {
    data: {},
    loading: false,
    buffering: false,
    offset: {
      value: 0,
      fromStart: false,
    },
  },
  snapShotData: {
    data: [{
      sketchData: [],
    }],
    loading: false,
  },
  playback_sections: {
    data: [],
    original: [],
    edited: false,
    audioLength: 0,
    session_start: 0,
    sections: [],
  },
  publishedPlaybackData: {
    loading: false,
    published: false,
  },
  deletedSlideData: {
    data: {},
    loading: false,
  },
  editSlideData: {
    data: {},
    loading: false,
  },
  autosave: false,
  interactiveQuestion: null,
  config: {},
  error: {},
};

function isPlaybackEdited(playbackDetails, editedPlaybackDetails) {
  if (playbackDetails?.playback_section && editedPlaybackDetails?.playback_section && isEqual(playbackDetails?.playback_section, editedPlaybackDetails?.playback_section)) return false;
  return true;
}

export default function playback(state = initialState, action) {
  switch (action.type) {
    case GET_ORIGINAL_PLAYBACK_DETAILS.REQUEST:
      return {
        ...state,
        playbackDetails: { data: {}, loading: true },
      };

    case GET_ORIGINAL_PLAYBACK_DETAILS.SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { playback_section } = action.payload?.[0];
      return {
        ...state,
        playbackDetails: {
          data: action.payload[0],
          loading: false,
          retries: action.retries,
        },
        playback_sections: {
          ...state.playback_sections,
          original: playback_section,
          edited: isPlaybackEdited(action.payload[0], state.editedPlaybackDetails.data),
          session_start: playback_section?.length ? playback_section?.[0]?.payload?.intervals?.[0]?.start_time : null,
        },
      };

    case GET_ORIGINAL_PLAYBACK_DETAILS.FAILURE:
      return {
        ...state,
        playbackDetails: { data: {}, loading: false, retries: action.retries },
        error: {
          playbackDetails: action.payload || 'something went wrong',
        },
      };

    case GET_EDITED_PLAYBACK_DETAILS.REQUEST:
      return {
        ...state,
        editedPlaybackDetails: { data: {}, loading: true },
      };

    case GET_EDITED_PLAYBACK_DETAILS.SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const {
        archive_url,
        archive_stream_url,
        last_seek,
      } = action.payload[0] || {};

      return {
        ...state,
        editedPlaybackDetails: {
          data: action.payload,
          loading: false,
          retries: action.retries,
        },
        playerData: {
          ...state.playerData,
          streamUrl: archive_stream_url,
          fallbackUrl: archive_url,
          currentSeek: last_seek || 0,
        },
        playback_sections: {
          ...state.playback_sections,
          data: action.payload[0]?.playback_section,
          edited: isPlaybackEdited(state.playbackDetails.data, action.payload[0]),
        },
      };

    case GET_EDITED_PLAYBACK_DETAILS.FAILURE:
      return {
        ...state,
        editedPlaybackDetails: { data: {}, loading: false, retries: action.retries },
        error: {
          editedPlaybackDetails: action.payload || 'something went wrong',
        },
      };

    case PLAYBACK_ON_DURATION_LOAD:
      return {
        ...state,
        playerData: {
          ...state.playerData,
          duration: action.payload,
          trimmedDuration: state.playerData.trimmedDuration || action.payload,
          fullDuration: action.payload,
        },
      };

    case PLAYBACK_START:
      return {
        ...state,
        playerData: {
          ...state.playerData,
          state: 'playing',
          ended: false,
          pausedByUser: false,
        },
        snapShotData: action.payload ? initialState.snapShotData : state.snapShotData,
        timeSeriesData: action.payload ? initialState.timeSeriesData : state.timeSeriesData,
      };

    case PLAYBACK_PAUSE:
      return {
        ...state,
        playerData: {
          ...state.playerData,
          state: 'paused',
          pausedByUser: action.payload,
        },
      };

    case PLAYBACK_STOP:
      return {
        ...state,
        playerData: {
          ...state.playerData,
          ended: true,
          playing: false,
          state: 'ready',
          last_seek: action.payload.last_seek,
          currentSeekSeconds: 0,
          playedSeconds: 0,
        },
      };

    case PLAYBACK_ON_PROGRESS:
      // eslint-disable-next-line no-case-declarations
      const { playedSeconds, loadedSeconds, played } = action.payload;

      return {
        ...state,
        playerData: {
          ...state.playerData,
          loadedSeconds,
          playedSeconds: state.playerData?.ended ? 0 : playedSeconds,
          played,
        },
      };

    case PLAYBACK_UPDATE_STATE:
      return {
        ...state,
        playerData: {
          ...state.playerData,
          state: action.payload,
        },
      };

    case UPDATE_PLAYER_DATA:
      return {
        ...state,
        playerData: {
          ...state.playerData,
          ...action.payload,
        },
      };

    case CLEAR_PLAYBACK_DATA:
      return {
        ...state,
        playerData: {
          streamUrl: null,
          fallbackUrl: null,
          currentSeek: null,
          playedSeconds: null,
          played: null,
          loadedSeconds: null,
          playbackRate: 1,
          duration: 0,
          trimmedDuration: 0,
          fullDuration: 0,
          state: 'initialising',
          currentSeekSeconds: 0,
          ended: false,
          pausedByUser: true,
          volume: 1,
          drawOnlyMode: false,
          enabled: true,
        },
        playback_sections: {
          data: [],
          edited: false,
          audioLength: 0,
          session_start: 0,
          sections: [],
        },
        publishedPlaybackData: {
          loading: false,
          published: false,
        },
        playbackDetails: {
          data: {},
          loading: false,
          retries: 0,
        },
        editedPlaybackDetails: {
          data: {},
          loading: false,
          retries: 0,
        },
        timeSeriesData: {
          data: {},
          loading: false,
          buffering: false,
          offset: {
            value: 0,
            fromStart: false,
          },
        },
        timeSeriesNextData: {
          data: {},
          loading: false,
          buffering: false,
          offset: {
            value: 0,
            fromStart: false,
          },
        },
        snapShotData: {
          data: [{
            sketchData: [],
          }],
          loading: false,
        },
        deletedSlideData: {
          data: {},
          loading: false,
        },
        editSlideData: {
          data: {},
          loading: false,
        },
        error: {},
      };

    case GET_TIMESERIES_DATA.REQUEST:
      return {
        ...state,
        timeSeriesData: {
          ...state.timeSeriesData,
          loading: true,
        },
      };

    case GET_TIMESERIES_DATA.SUCCESS:
      return {
        ...state,
        timeSeriesData: {
          ...state.timeSeriesData,
          data: action.payload,
          loading: false,
        },
        error: null,
        playerData: {
          ...state.playerData,
          enabled: true,
        },
      };

    case GET_TIMESERIES_DATA.FAILURE:
      return {
        ...state,
        timeSeriesData: {
          ...state.timeSeriesData,
          buffering: false,
          data: {},
          loading: false,
        },
        error: {
          timeSeriesData: action.payload,
        },
      };

    case GET_BUFFERED_TIMESERIES.REQUEST:
      return {
        ...state,
        timeSeriesNextData: {
          ...state.timeSeriesData,
          buffering: true,
        },
      };

    case GET_BUFFERED_TIMESERIES.SUCCESS:
      return {
        ...state,
        timeSeriesNextData: {
          ...state.timeSeriesNextData,
          data: action.payload,
          buffering: false,
        },
        playerData: {
          ...state.playerData,
          enabled: true,
        },
      };

    case PLAYBACK_LOAD_NEXT_DATA:
      return {
        ...state,
        timeSeriesData: {
          ...state.timeSeriesData,
          data: state.timeSeriesNextData.data,
          offset: {
            fromStart: false,
            offset: 0,
          },
        },
      };

    case GET_SNAPSHOT_DATA.REQUEST:
      return {
        ...state,
        snapShotData: {
          ...state.snapShotData,
          loading: true,
        },
      };

    case GET_SNAPSHOT_DATA.SUCCESS:
      return {
        ...state,
        snapShotData: {
          data: action.payload,
          loading: false,
        },
        interactiveQuestion: null,
      };

    case GET_SNAPSHOT_DATA.FAILURE:
      return {
        ...state,
        snapShotData: {
          ...state.snapShotData,
          loading: false,
        },
        error: {
          snapShotData: action.payload,
        },
      };

    case DELETE_PLAYBACK_SLIDE.REQUEST:
      return {
        ...state,
        deletedSlideData: {
          ...state.deletedSlideData,
          loading: true,
        },
        autosave: true,
      };

    case DELETE_PLAYBACK_SLIDE.SUCCESS:
      return {
        ...state,
        deletedSlideData: {
          loading: false,
          data: action.payload,
        },
        autosave: false,
      };

    case DELETE_PLAYBACK_SLIDE.FAILURE:
      return {
        ...state,
        deletedSlideData: {
          ...state.deletedSlideData,
          loading: false,
        },
        autosave: false,
        error: {
          deletedSlideData: action.payload,
        },
      };

    case RESET_DELETED_SLIDE:
      return {
        ...state,
        deletedSlideData: {
          data: {},
          loading: false,
        },
        error: {
          deletedSlideData: null,
        },
      };

    case TRIM_PLAYBACK_SLIDE.REQUEST:
      return {
        ...state,
        editSlideData: {
          ...state.editSlideData,
          loading: true,
        },
        autosave: true,
      };

    case TRIM_PLAYBACK_SLIDE.SUCCESS:
      return {
        ...state,
        autosave: false,
        editSlideData: {
          loading: false,
          data: action.payload,
        },
      };

    case TRIM_PLAYBACK_SLIDE.FAILURE:
      return {
        ...state,
        editSlideData: {
          ...state.editSlideData,
          loading: false,
        },
        autosave: false,
        error: {
          editSlideData: action.payload,
        },
      };

    case RESET_EDITED_SLIDE:
      return {
        ...state,
        editSlideData: {
          data: {},
          loading: false,
        },
        error: {
          editSlideData: null,
        },
      };

    case UPDATE_PLAYBACK_LAST_SEEK:
      return {
        ...state,
        playerData: {
          ...state.playerData,
          currentSeekSeconds: action.payload.currentSeekSeconds,
        },
      };

    case UPDATE_PLAYBACK_SECTIONS:
      return {
        ...state,
        playback_sections: {
          ...state.playback_sections,
          ...action.payload,
        },
      };

    case PLAYBACK_INTERACTIVE_QUESTION_START:

      return {
        ...state,
        interactiveQuestion: action.payload,
        playerData: {
          ...state.playerData,
        },
      };

    case PLAYBACK_INTERACTIVE_QUESTION_END:
      return {
        ...state,
        interactiveQuestion: null,
        playerData: {
          ...state.playerData,
        },
      };

    default:
      if (action.type.indexOf('_REQUEST') !== -1) {
        return {
          ...state,
          error: {},
        };
      }
      return state;
  }
}

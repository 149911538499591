/* eslint-disable no-case-declarations */
import { takeEvery, select } from 'redux-saga/effects';
import { get } from 'lodash-es';
import { groupDetailsSelector } from 'redux/selectors/groups';
import {
  CREATE_GROUP,
  GROUP_ACCEPT_REQUEST,
  GROUP_REJECT_REQUEST,
  GET_PROFILE,
  LOGGED_OUT_TEACHER,
  SESSION_PREP_START,
  SESSION_PREP_END_DATA_EVENT,
  PUBLISHED_PLAYBACK_EVENT,
  PUBLISH_LATER_EVENT,
} from '../constants';
import Mixpanel from '../../components/Mixpanel';
import { isPwaMode } from '../../helpers';
import store from '../../store';

const getUser = (state) => state.toJS().user;

// handle session prep events;

function getPrepData(payload) {

  const { id, group, curriculum_tags, start_time } = payload.sessionData || {};
  const state = store.getState().toJS();
  const teacher_id = get(state, ['user', 'loggedUser', 'id']) || 0;
  const session_id = id;
  const group_id = group.id;
  const { chapter, topics } = curriculum_tags || {};
  const topic_id = topics ? topics[0].id : '';
  const chapter_id = chapter ? chapter.id : '';
  const prepData = {
    session_id,
    teacher_id,
    session_start_time: start_time,
    timestamp: Date.now(),
    group_id,
    chapter_id,
    topic_id,
    sections_count: payload.sections_count,
    slides_count: payload.slides_count,
  };
  return prepData;
}

function getLogger(moduleName) {
  return LoggingManager.mount({ moduleName });
}

function* logger(action) {
  const { loggedUser } = yield select(getUser);
  switch (action.type) {
    case LOGGED_OUT_TEACHER.REQUEST:
      Mixpanel.track('user_logout', {
        platform: 'teacher-web',
        timestamp: Date.now(),
      });
      try {
        Mixpanel.reset();
      } catch (e) {
        console.log(e);
      }
      break;
    // eslint-disable-next-line no-case-declarations
    case GET_PROFILE.SUCCESS:
      const { gender, grade, name, user_id } = action.payload;
      Mixpanel.register({ gender, grade, name, id: user_id, role_type: 'teacher', isPwa: isPwaMode() });
      Mixpanel.identify(action.payload.user_id);
      break;
    case CREATE_GROUP.SUCCESS:
      if (action.payload) {
        Mixpanel.track('teacher_create_draft_group', {
          teacher_id: loggedUser.id,
          grade: action.payload.grade,
          subject: get(action.payload, ['product', 'name']) || '',
          group_privacy: action.payload.type,
        });
      }
      break;
    case GROUP_ACCEPT_REQUEST.SUCCESS:
      if (action.payload) {
        const groupDetails = yield select(groupDetailsSelector);
        if (groupDetails && groupDetails.id) {
          Mixpanel.track('admin_accept_join_request', {
            student_id: action.payload,
            group_id: groupDetails.id,
            subject: get(groupDetails, ['product', 'name']) || '',
            grade: groupDetails.grade,
          });
        }
      }
      break;
    case GROUP_REJECT_REQUEST.SUCCESS:
      if (action.payload) {
        const groupDetails = yield select(groupDetailsSelector);
        if (groupDetails && groupDetails.id) {
          Mixpanel.track('admin_reject_join_request', {
            student_id: action.payload,
            group_id: groupDetails.id,
            subject: get(groupDetails, ['product', 'name']) || '',
            grade: groupDetails.grade,
          });
        }
      }
      break;

    case SESSION_PREP_START:

      const sessionStartPrepData = getPrepData(action.payload);

      Mixpanel.track('start_prep', sessionStartPrepData);
      getLogger('classroom').track('start_prep', {
        classroom: {
          sessionStartPrepData,
        },
      });
      break;
    case PUBLISHED_PLAYBACK_EVENT:
      Mixpanel.track('published_playback', action.payload);
      getLogger('playback').track('published_playback', {
        playback: action.payload,
      });
      break;
    case PUBLISH_LATER_EVENT:
      Mixpanel.track('playback_publish_later', action.payload);
      getLogger('playback').track('playback_publish_later', {
        playback: action.payload,
      });
      break;
    case SESSION_PREP_END_DATA_EVENT:
      const { ended_by } = action.payload;
      const sessionEndPrepData = {
        ended_by,
        ...getPrepData(action.payload),
      };
      Mixpanel.track('end_prep', sessionEndPrepData);
      getLogger('classroom').track('end_prep', {
        classroom: {
          sessionEndPrepData,
        },
      });
      break;
    default:
  }
}

export function* analyticSaga() {
  yield takeEvery('*', logger);
}

import React from 'react';
import { getCountryId } from '../../../constants';

export default function MediaSection() {
  const countryId = getCountryId();
  const mediaList = {
    1: [
      {
        id: 1,
        img_url: '/assets/images/press/cnn.jpg',
        link_url: 'https://youtu.be/0l7PXBgFKhI',
        alt_text: 'CNN',
      },
      {
        id: 2,
        img_url: '/assets/images/press/alJazirah.jpg',
        link_url: 'http://www.al-jazirah.com/2015/20151220/fe17.htm',
        alt_text: 'Al Jazirah',
      },
      {
        id: 3,
        img_url: '/assets/images/press/press-ufm.jpg',
        link_url: 'https://youtu.be/YrspFNAEnsU?t=3m20s',
        alt_text: 'Press UFM',
      },
      {
        id: 4,
        img_url: '/assets/images/press/press-sabq.jpg',
        link_url: 'https://sabq.org/KVOgde',
        alt_text: 'press sabq',
      },
      {
        id: 5,
        img_url: '/assets/images/press/takaful.jpg',
        link_url: 'https://www.takaful.org.sa/',
        alt_text: 'Takaful',
      },
      {
        id: 6,
        img_url: '/assets/images/press/nsa.jpg',
        link_url: 'https://qiyas.sa/en/Pages/default.aspx',
        alt_text: 'Qiyas - NSA',
      },
    ],
    2: [
      {
        id: 1,
        img_url: '/assets/images/press/midan.jpg',
        link_url: '#',
        alt_text: 'press',
      },
      {
        id: 2,
        img_url: '/assets/images/press/alvafz.jpg',
        link_url: '#',
        alt_text: 'Press',
      },
      {
        id: 3,
        img_url: '/assets/images/press/alfajr.jpg',
        link_url: '#',
        alt_text: 'press',
      },
      {
        id: 4,
        img_url: '/assets/images/press/almasry.jpg',
        link_url: '#',
        alt_text: 'Almasry',
      },
      {
        id: 5,
        img_url: '/assets/images/press/cnn-2.jpg',
        link_url: 'https://youtu.be/0l7PXBgFKhI',
        alt_text: 'CNN',
      },
    ],
  };
  return (
    <div className="clients">
      {mediaList[countryId].map(media => (
        <div key={media.id} className="clients-wrapper text-center">
          <a href={media.link_url} target="_blank">
            <img src={media.img_url} alt={media.alt_text} className="client-logo" />
          </a>
        </div>
      ))}
    </div>
  );
}

export const putInfoAboutSpecificStudent = (userResponse, id, studentsListing) => {
  let studentInfo = {
    ...userResponse
  };
  studentsListing.forEach(student => {
    if (student.id === id) {
      studentInfo = {
        ...studentInfo,
        ...student,
      };
    }
  })

  return studentInfo;
}

export const calculateRightAnswers = (userQuestions) => {
  let rightAnswersAmount = 0;

  userQuestions.forEach(question => {
    if (question.is_correct) {
      rightAnswersAmount += 1;
    }
  })

  return rightAnswersAmount;
}
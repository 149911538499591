import React from 'react';

import { Button, Column, Hr, Modal, Row } from '@noon/atom';
import { IconPlus } from '@noon/quark';

import { COLORS } from '../../../constants/index';
import t from '../../../helpers/translate';
import ErrorBoundry from '../../ErrorBoundry';
import { IQuestion } from '../Content/Questions/questionContainer';
import WhiteboardQuestion from '../Whiteboard/whiteboardQuestion';

type IQuestionPreviewModalProp = {
  onClose: Function,
  onAddQuestion: (question: IQuestion, time: number) => void,
  questionDetails: IQuestion,
};

const times = [15, 30, 45, 60, 90, 120, 180, 240, 300];

export default function QuestionPreviewModal(prop: IQuestionPreviewModalProp) {
  const { onClose, onAddQuestion, questionDetails } = prop;
  return (
    <ErrorBoundry msg={t('error', 'questionPreview')}>
      <Modal className="question-preview-modal" onClose={onClose}>
        <Row className="ptb-1 plr-2">
          <p className="mtb-1 flex-1">{t('question', 'question')}</p>
          <IconPlus rotate="45" fill={COLORS.text.base} height="3rem" className="pointer" onClick={onClose} />
        </Row>
        <Column nowrap className="question-preview-modal__body">
          <WhiteboardQuestion question={questionDetails} />
        </Column>
        <Hr />
        <Row align="Center" justify="center" className="justify-space mtb-2">
          <span className="child">{t('competition', 'enterTimeLimit')}</span>
          {times.map((time) => (
            <Button
              key={time}
              size="sm"
              type="primary"
              outlined
              className="mt-05 mb-05"
              onClick={() => onAddQuestion(questionDetails, time)}
            >
              {time}
              <small>s</small>
            </Button>
          ))}
        </Row>
      </Modal>
    </ErrorBoundry>
  );
}

const { SCHEDULER_CREATE_SCHEDULE, SCHEDULER_GET_SCHEDULES, SCHEDULER_UPDATE_SCHEDULE } = require('../constants');

const initialState = {
  createScheduleInProgress: false,
  createScheduleCompleted: false,
  createSchedulePending: true,
  previousSchedules: {
    list: [],
    loading: false,
  },
  error: {},
};


export default function scheduler(state = initialState, action = {}) {
  switch (action.type) {
    case SCHEDULER_CREATE_SCHEDULE.REQUEST:
    case SCHEDULER_UPDATE_SCHEDULE.REQUEST:
      return {
        ...state,
        createScheduleInProgress: true,
        createScheduleCompleted: false,
        createSchedulePending: false,
      };
    case SCHEDULER_CREATE_SCHEDULE.SUCCESS:
    case SCHEDULER_UPDATE_SCHEDULE.SUCCESS:
      return {
        ...state,
        createScheduleInProgress: false,
        createScheduleCompleted: true,
        createSchedulePending: false,
      };
    case SCHEDULER_CREATE_SCHEDULE.FAILURE:
    case SCHEDULER_UPDATE_SCHEDULE.FAILURE:
      return {
        ...state,
        createScheduleInProgress: false,
        createScheduleCompleted: false,
        createSchedulePending: false,
        error: action.payload,
      };
    case SCHEDULER_GET_SCHEDULES.REQUEST:
      return {
        ...state,
        previousSchedules: {
          ...state.previousSchedules,
          loading: true,
        },
      };
    case SCHEDULER_GET_SCHEDULES.SUCCESS:
      return {
        ...state,
        previousSchedules: {
          loading: false,
          list: action.payload.data,
        },
      };
    case SCHEDULER_GET_SCHEDULES.FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { Row } from '@noon/atom';
import { IconClose } from '@noon/quark';
import { isFunction } from 'lodash-es';

function NotificationBar({ text, onClose, children = null }) {
  return (
    <Row align="center" className="notification-bar">
      <div className="container full-width">
        <Row gap align="center" justify="space-between">
          <Row nowrap justify="start" align="center" gap>
            <p className="child flex-1">
              {isFunction(text) ? text() : text}
            </p>
            {children}
          </Row>
          {onClose && <IconClose width="16px" height="16px" onClick={onClose} className="pointer" />}
        </Row>
      </div>
    </Row>
  );
}

NotificationBar.propTypes = {
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};
NotificationBar.defaultProps = {
  children: null,
  onClose: null,
};
export default NotificationBar;

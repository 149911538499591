import cloneDeep from 'lodash.clonedeep';
import { DateTime } from 'luxon';
import { QNA_BREAKOUT_END_EVENT, QNA_BREAKOUT_START_EVENT, QNA_SET_DURATION, QNA_START_BREAKOUT, QNA_MESSAGE, QNA_GET_MESSAGES, QNA_LOAD_DATA, UPDATE_SCREEN_SHOT, QNA_SCREEN_SHOT_UPDATED, TAKE_SCREEN_SHOT } from '../../../redux/constants';

const initialState = {
  messages: [],
  isActive: false,
  breakoutCount: 0,
  questionCount: 0,
  startTime: 0,
  duration: 60 * 2,
  timeRemaining: 60 * 2,
  qnaBreakoutStarted: false,
  qnaBreakoutOngoing: false,
  breakoutTeams: [],
  snapshotInProgress: false,
  retrying: {},
};

export default function TeamQna(state = initialState, action) {

  switch (action.type) {

    case QNA_START_BREAKOUT: {
      return {
        ...state,
        qnaBreakoutStarted: true,
      };
    }

    case QNA_BREAKOUT_START_EVENT.AFTER: {
      const { payload } = action;
      const duration = state.duration + payload.load_time_duration + payload.transition_time_duration;

      return {
        ...state,
        duration,
        qnaBreakoutOngoing: true,
        startTime: Date.now(),
        timeRemaining: duration,
      };
    }

    case (QNA_SET_DURATION): {
      let duration;
      switch (action.payload) {
        case 'inc': {
          duration = state.duration >= 15 * 60 ? 15 * 60 : state.duration + 60;
          break;
        }

        case 'dec': {
          duration = state.duration <= 60 ? 60 : state.duration - 60;
          break;
        }

        default: {
          duration = 60 * 2;
          break;
        }
      }

      return {
        ...state,
        duration,
      };

    }

    case (QNA_BREAKOUT_END_EVENT.AFTER): {
      return {
        ...state,
        duration: 60 * 2,
        timeRemaining: 60 * 2,
        qnaBreakoutStarted: false,
        qnaBreakoutOngoing: false,
      };
    }

    case QNA_MESSAGE.AFTER: {

      const { messages } = state;
      messages.unshift(action.payload);
      return {
        ...state,
        messages,
      };
    }

    case QNA_GET_MESSAGES.SUCCESS: {

      const messages = cloneDeep(action.data.reverse());
      return {
        ...state,
        messages,
      };

    }

    case QNA_LOAD_DATA.AFTER: {
      return {
        ...action.payload,
      };
    }

    case TAKE_SCREEN_SHOT: {
      return {
        ...state,
        snapshotInProgress: true,
      };
    }

    case QNA_SCREEN_SHOT_UPDATED: {
      return {
        ...state,
        snapshotInProgress: false,
      };
    }

    default: return state;
  }

}

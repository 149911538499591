import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import isEmpty from 'lodash-es/isEmpty';
import { IconAdd, IconGridView, IconListView } from '@noon/quark';
import ReactTooltip from 'react-tooltip';
import { get, groupBy, isString, mapValues, omit, omitBy } from 'lodash-es';
import { Row, Column, Button } from '@noon/atom';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import { CreateActivity, CreateGroup } from '../../components/Create';
import PageHeader from '../../components/Layout/pageHeader';
import { translationText } from '../../helpers';
import { CLEAR_TUTORING_DETAILS, DELETE_SESSION, GENERAL_PLANNER_GET_ACTIVITIES_COUNTS, GENERAL_PLANNER_GET_ACTIVITIES_GRID, GENERAL_PLANNER_GET_ACTIVITIES_LIST, GET_TEACHER_GROUP_SELECTED, TOGGLE_PLAYBACK } from '../../redux/constants';
import PlannerCalendarView from '../../components/GeneralPlanner/CalendarView/PlannerCalendarView';
import { getQueryObject } from '../../helpers/getQueryItem';
import PlannerListView from '../../components/GeneralPlanner/ListView/PlannerListView';
import Mixpanel from '../../components/Mixpanel';

const PlannerWithGridView = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { groupId } = getQueryObject(location.search, '=');
  const {
    groups: { data: groupsList, loading: loadingGroups },
    selectedGroup,
  } = useSelector((state) => state.toJS().groupsV2);
  const { loggedUser } = useSelector((state) => state.toJS().user);
  const { noonText } = useSelector((state) => state.toJS().translation);
  const [isGridViewEnabled, setIsGridViewEnabled] = useState(() => {
    if (localStorage) {
      return localStorage.getItem('isGridViewEnabled') === 'true';
    }
    return false;
  });
  const { list, grid, meta, loading, count } = useSelector((state) => state.toJS().generalPlanner);
  const { deleteSession, toggleTeacher } = useSelector((state) => state.toJS().tutoring);

  const [groupedActivityList, setGroupedActivityList] = useState({});
  const defaultFilters = useMemo(() => (groupId ? { group_id: { value: groupId, title: get(selectedGroup, 'group_info.title') } } : {}), [groupId, selectedGroup]);
  const [filters, setFilters] = useState(defaultFilters);
  const [enableCreateActivity, SetEnableCreateActivity] = useState({});
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const logger = useRef(LoggingManager.mount({ moduleName: 'planner' })).current;

  const handleGetActivityList = (payload, reload = true, update = false) => {
    dispatch({
      type: GENERAL_PLANNER_GET_ACTIVITIES_LIST.REQUEST,
      payload: { filters: mapValues(payload, 'value'), reload, update },
    });
  };
  const handleGetActivityGrid = (payload, reload = true, update = false) => {
    dispatch({
      type: GENERAL_PLANNER_GET_ACTIVITIES_GRID.REQUEST,
      payload: { filters: mapValues(payload, 'value'), reload, update },
    });
  };

  const getActivity = (params, update = false) => {
    if (isGridViewEnabled) {
      handleGetActivityGrid(params, true, update);
    } else {
      handleGetActivityList(params, true, update);
    }
  };

  const onFilterSelection = (options, reset) => {
    const newFilters = omitBy({
      ...(reset ? {} : filters),
      ...options,
    }, (value) => value === '' || value.value === '');

    // TODO: Need to improve
    // if (!value) {
    //   delete newFilters[key];
    // }
    const filterType = Object.keys(options)[0];
    const filterTypesList = ['group_id', 'date_offset', 'filters', 'text', 'sort'];
    if (filterTypesList.indexOf(filterType) > -1) {
      const eventPayload = {
        view: isGridViewEnabled ? 'grid' : 'list',
        no_of_groups: groupsList.length,
        filter_type: filterType,
      };
      const eventName = 'filter_applied';
      Mixpanel.track(eventName, eventPayload);
      logger.track(eventName,
        { planner: eventPayload },
      );
    }
    setFilters(newFilters);
    getActivity(newFilters);
  };

  const handleOnClearFilters = () => {
    setFilters(defaultFilters);
    getActivity({});
  };

  // const handleDeleteAssignment = () => {

  // };

  const handleDeleteActivity = (id) => {
    dispatch({
      type: DELETE_SESSION.REQUEST,
      payload: { id },
    });
  };

  const handleTogglePlayback = (id, isTeacherEnabled) => {
    dispatch({
      type: TOGGLE_PLAYBACK.REQUEST,
      payload: { session_id: id, teacher_enabled: isTeacherEnabled },
    });
  };

  const handleOnClickChangeView = (value) => {
    if (localStorage) {
      localStorage.setItem('isGridViewEnabled', value);
      localStorage.removeItem('selectedWeek');
    }
    setIsGridViewEnabled(value);
  };

  const loadMoreActivities = () => {
    if (!loading) {
      handleGetActivityList({ ...filters, date_offset: { value: meta.next_offset } }, false);
    }
  };

  const toggleCreateActivity = (value) => {
    if (isEmpty(value) || value.startDate) {
      const queryFilters = { ...filters };
      if (!isGridViewEnabled) {
        queryFilters.date_offset = { value: value.startDate };
      }
      if (isString(value.startDate)) {
        getActivity(queryFilters, true);
      }
      SetEnableCreateActivity({});
    } else {
      SetEnableCreateActivity(value);
    }

  };

  const toggleCreateGroup = (value) => {
    setShowCreateGroupModal(value);
  };

  // useEffect(() => {
  //   DateTime.local().reconfigure({ weekday: 1 });
  // }, []);

  useEffect(() => {
    if (groupId) {
      dispatch({
        type: GET_TEACHER_GROUP_SELECTED.REQUEST,
        payload: groupId,
      });
    }
    if (!isGridViewEnabled) {
      const updatedFilters = omit(filters, ['week_offset']);
      setFilters(updatedFilters);
      getActivity(updatedFilters);
    }
    dispatch({
      type: GENERAL_PLANNER_GET_ACTIVITIES_COUNTS.REQUEST,
      payload: { filters: mapValues(defaultFilters, 'value'), reload: false },
    });
  }, [isGridViewEnabled]);

  useEffect(() => {
    const activitiesList = isGridViewEnabled ? grid : list;
    if (!isEmpty(activitiesList)) {
      const groupedActivity = groupBy(activitiesList, (activity) => {
        const todaysDate = DateTime.fromMillis(Date.now());
        if (todaysDate.hasSame(new Date(activity.start_time), 'day')) {
          return translationText(noonText, 'datetime.today');
        } if (todaysDate.plus({ days: 1 }).hasSame(new Date(activity.start_time), 'day')) {
          return translationText(noonText, 'datetime.tomorrow');
        }
        return DateTime.fromISO(new Date(activity.start_time).toISOString()).toFormat('dd ccc, MMM');
      });
      setGroupedActivityList(groupedActivity);
    } else {
      setGroupedActivityList({});
    }
  }, [grid, list]);

  useEffect(() => {
    if ((deleteSession && !deleteSession.loading && deleteSession.success) || (toggleTeacher && !toggleTeacher.loading && toggleTeacher.success)) {
      dispatch({ type: CLEAR_TUTORING_DETAILS });
      getActivity(filters, true);
    }
  }, [deleteSession, toggleTeacher]);

  return (
    <React.Fragment>
      <PageHeader title={translationText(noonText, 'tab.planner')}>
        <Row className="schedule--header" justify="space-between" flex="1">
          <Row align="center" gap className="mlr-2">
            <ReactTooltip place="bottom" />
            <IconListView
              className={classNames('child toggle-icon', { active: !isGridViewEnabled })}
              onClick={() => handleOnClickChangeView(false)}
              data-tip={translationText(noonText, 'tooltip.listView')}
              tooltipPos="bottom"
              height="20px"
              width="20px"
            />
            <ReactTooltip place="bottom" />
            <IconGridView
              className={classNames('child toggle-icon', { active: isGridViewEnabled })}
              onClick={() => handleOnClickChangeView(true)}
              data-tip={translationText(noonText, 'tooltip.calendarView')}
              tooltipPos="bottom"
              height="20px"
              width="20px"
            />
          </Row>
          {!isEmpty(groupsList) && (
            <Column align="center" className="create-activity-CTA">
              <Button type="primary" onClick={() => toggleCreateActivity({ type: '', source: 'planner-header' })} className="text-capitalize">
                {/* Added style to overwrite header style */}
                <IconAdd />
                {isMobileOnly ? '' : translationText(noonText, 'button.newActivity')}
              </Button>
            </Column>
          )}
        </Row>
      </PageHeader>
      {!isEmpty(enableCreateActivity) && (
        <CreateActivity
          defaultTab={enableCreateActivity.type}
          onContentClose={(startDate) => toggleCreateActivity({ startDate })}
          groupId={filters.group_id ? filters.group_id.value : undefined}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...enableCreateActivity}
        />
      )}
      {showCreateGroupModal && <CreateGroup teacherId={loggedUser.user_id} totalGroups={0} onClose={() => toggleCreateGroup(false)} />}

      <Row className="curriculum-content container">
        <Column className="curriculum-content--content">
          <div className="custom-curriculum">
            {!isGridViewEnabled
              ? (
                <PlannerListView
                  noonText={noonText}
                  onFilterSelection={onFilterSelection}
                  activitiesList={groupedActivityList}
                  toggleCreateActivity={toggleCreateActivity}
                  loadMoreActivities={loadMoreActivities}
                  toggleCreateGroup={toggleCreateGroup}
                  handleOnClearFilters={handleOnClearFilters}
                  groupsList={groupsList}
                  filters={filters}
                  loading={loading || loadingGroups}
                  filterCount={count}
                  loggedUser={loggedUser}
                  disabled={isEmpty(groupsList) || (isEmpty(filters) && !meta.activity_present)}
                  isLoadMoreActivities={meta && !!meta.next_offset}
                  isGroupEmpty={isEmpty(groupsList) && !loading}
                  noActivity={isEmpty(groupedActivityList) && !meta.activity_present && !loading}
                  noUpcomingActivity={isEmpty(groupedActivityList) && meta.activity_present && !loading}
                  noScheduledActivity={isEmpty(groupedActivityList) && meta.last_activity_time > Date.now() && !loading}
                />
              )
              : (
                <Row nowrap className="general-planner calendar">
                  <PlannerCalendarView
                    noonText={noonText}
                    handleGetActivityGrid={handleGetActivityGrid}
                    toggleCreateActivity={toggleCreateActivity}
                    activitiesList={groupedActivityList}
                    onFilterSelection={onFilterSelection}
                    handleDeleteActivity={handleDeleteActivity}
                    handleTogglePlayback={handleTogglePlayback}
                    deleteSession={deleteSession}
                    toggleTeacher={toggleTeacher}
                    groupsList={groupsList}
                    filters={filters}
                    loading={loading || loadingGroups}
                    filterCount={count}
                    loggedUser={loggedUser}
                    toggleCreateGroup={toggleCreateGroup}
                    disabled={isEmpty(groupsList) || (isEmpty(filters) && !meta.activity_present)}
                    isGroupEmpty={isEmpty(groupsList) && !loading}
                    noActivity={isEmpty(groupedActivityList) && !meta.activity_present && !loading}
                    noUpcomingActivity={isEmpty(groupedActivityList) && meta.activity_present && !loading}
                    nextActivityOffset={meta.next_activity_offset}
                    noScheduledActivity={isEmpty(groupedActivityList) && meta.next_activity_offset && !loading}
                  />
                </Row>
              )}
          </div>
        </Column>
      </Row>
    </React.Fragment>
  );
};

export default PlannerWithGridView;

/* eslint-disable import/no-cycle */
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import RouteManager from './routeManager';
import { AuthProvider } from './providers/Auth';

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <AuthProvider>
        <RouteManager />
      </AuthProvider>
    </BrowserRouter>
  </Provider>
);

export default App;

import React from 'react';
import { IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';
import { Column, Row, Button, Hr } from '@noon/atom';
import t from '../../../helpers/translate';
import RaiseHandUser, { IRaiseHandUser, raiseHandTypes } from './raise-hand-user';

type Props = {
  activeRaiseHand: IRaiseHandUser;
  pendingRaiseHand: IRaiseHandUser[];
  historyRaiseHand: IRaiseHandUser[];
  onRaiseHandAction: (type: raiseHandTypes, user: IRaiseHandUser) => void;
  raiseHandLoadingFor: string | number;
  studentAudioStream?: IAgoraRTCRemoteUser;
};

const RaiseHands = (prop: Props) => {
  const {
    onRaiseHandAction,
    activeRaiseHand,
    historyRaiseHand,
    pendingRaiseHand,
    raiseHandLoadingFor,
    studentAudioStream,
  } = prop;
  return (
    <Column flex="1" nowrap className="full-width raisehand">
      {activeRaiseHand.user_id && (
        <>
          <h5 className="h5-title">{t('classroom', 'currentSpeaker')}</h5>
          <Hr />
          <RaiseHandUser
            key={activeRaiseHand.user_id}
            raiseHandUser={activeRaiseHand}
            activeUser={activeRaiseHand}
            raiseHandLoadingFor={raiseHandLoadingFor}
            onRaiseHandAction={onRaiseHandAction}
            audioStream={studentAudioStream}
          />
        </>
      )}
      <div className="raisehand__list">
        {pendingRaiseHand.length > 0 && (
          <>
            <h5 className="h5-title">
              {t('classroom', 'raiseHands')} ({pendingRaiseHand.length})
            </h5>
            <Hr />
            {pendingRaiseHand.map((raiseHandUser) => (
              <RaiseHandUser
                key={raiseHandUser.user_id}
                raiseHandUser={raiseHandUser}
                activeUser={activeRaiseHand}
                raiseHandLoadingFor={raiseHandLoadingFor}
                onRaiseHandAction={onRaiseHandAction}
              />
            ))}
          </>
        )}
        {historyRaiseHand.length > 0 && (
          <>
            <h5 className="h5-title">
              {t('classroom', 'missedRaisedHands')} ({historyRaiseHand.length})
            </h5>
            <Hr />
            {historyRaiseHand.map((raiseHandUser) => (
              <RaiseHandUser
                key={raiseHandUser.user_id}
                raiseHandUser={raiseHandUser}
                activeUser={activeRaiseHand}
                raiseHandLoadingFor={raiseHandLoadingFor}
                onRaiseHandAction={onRaiseHandAction}
              />
            ))}
          </>
        )}
      </div>
    </Column>
  );
};

export default RaiseHands;

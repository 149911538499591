import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Row, Column, Avatar, Button } from '@noon/atom';
import { translationText } from '../../../helpers';

export default function FeaturedTeachers(prop) {
  const { noonText, pinnedTeachers, history } = prop;
  return (
    <section className="pinned-teachers">
      <header className="section-header">
        <div className="head-fade-line">
          <span className="fading-line" />
        </div>
        <h1 className="page-heading">{translationText(noonText, 'teacherLanding.featuredTeacherHeading')}</h1>
        <p className="subheading-home">{translationText(noonText, 'teacherLanding.featuredTeacherSubHeading')}</p>
      </header>
      <div className="teachers-inner-container">
        <Row style={{ marginBottom: '30px' }} justify="center">
          {pinnedTeachers.map((teacher, index) => (
            <a
              target={!isMobileOnly ? '_blank' : '_self'}
              href={`${process.env.STUDENT_URL}${localStorage.updatedLocale}/teachers/${teacher.id}`}
              key={index}
              className="pinned-teacher-link"
            >
              <Column flex="1" nowrap key={index} align="center" justify="start" className="teacher-item">
                <Avatar url={teacher.profile_pic} gender={teacher.gender} teacher size="96px" />
                <h5>{teacher.name}</h5>
                <p className="subtitle">
                  {teacher.subjects[0] && (
                    <span>{`${translationText(noonText, `product.${teacher.subjects[0]}`)}`}</span>
                  )}
                </p>
              </Column>
            </a>
          ))}
        </Row>
        <Button
          onClick={() => history.push('/login')}
          size="lg"
          className="large-yellow-button"
        >
          {translationText(noonText, 'studentLanding.teacherSignup')}
        </Button>
      </div>
    </section>
  );
}

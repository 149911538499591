import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { PublicNav } from '../NavigationBar';
import Footer from '../Footer';
import ErrorBoundry from '../ErrorBoundry';
import { translationType } from '../../types';

const NoonRoute = ({
  noFooter,
  isAuthenticated,
  history,
  noHeader,
  noonText,
  component: Component,
  ...rest
}) => {
  delete localStorage.lastUrl;
  return (
    <ErrorBoundry>
      <Route
        {...rest}
        render={props =>
          (!isAuthenticated ? (
            <React.Fragment>
              <header className="noon-header">
                {!noHeader && <PublicNav history={history} />}
              </header>
              <main
                className={classNames('noon-content', 'animated', 'fadeIn', {
                  showHeader: !noHeader,
                })}
              >
                <Component {...props} />
              </main>
              {!noFooter && <Footer noonText={noonText} />}
            </React.Fragment>
          ) : (
            <Redirect
              to={localStorage.lastUrl ? JSON.parse(localStorage.lastUrl) : `/${localStorage.updatedLocale}/home`}
            />
          ))
        }
      />
    </ErrorBoundry>
  );
};


NoonRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]).isRequired,
  noFooter: PropTypes.bool,
  noHeader: PropTypes.bool,
  history: PropTypes.shape(),
  isAuthenticated: PropTypes.bool.isRequired,
  noonText: translationType.isRequired,
};

NoonRoute.defaultProps = {
  noFooter: false,
  noHeader: false,
  history: {},
};

const mapStateToProps = state => ({
  noonText: state.toJS().translation.noonText,
  isAuthenticated: !!state.toJS().user.loggedUser.id,
});

export default connect(mapStateToProps)(NoonRoute);

import React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash-es/isEmpty';
import { IconFlag, IconKickOut, IconMoreOption } from '@noon/quark';
import { Avatar, Badge, Row, Button, InfiniteScroll } from '@noon/atom';
import withNoonText from 'helpers/withNoonText';
import { translationText } from '../../../helpers';
import ReportAbuseModal from '../reportAbuseModal';
import ErrorBoundry from '../../ErrorBoundry';

const IndividualLeaderboard = ({
  players,
  noonText,
  flaggedPlayer,
  loadPreviousLeaderboard,
  leaderboardLoading,
  leaderboardOffset,
  handleReportAbuse,
  blockUser,
  closeReportAbuseModal,
  sendReportAbuse,
  totalLiveUsers,
  totalUsers,
}) => (
  <ErrorBoundry>
    <React.Fragment>
      {!isEmpty(flaggedPlayer) && (
      <ReportAbuseModal
        player={flaggedPlayer}
        onClose={closeReportAbuseModal}
        onSubmit={sendReportAbuse}
          />
      )}
      <div className="body-wrapper leaderboard-body-wrapper with-search">
        <Row align="center" className="sidebar-heading-wrapper">
          <h4 className="sidebar-heading">{translationText(noonText, 'classroom.leaderboardHeading')}</h4>
          <Button
            className="count"
            size="sm"
            type="blue"
            tooltipPos="bottom"
            tooltip={`${totalLiveUsers} ${translationText(noonText, 'leaderboard.activeFrom')} ${
              totalUsers
            }`}
            >
            {totalLiveUsers}
            /
            {totalUsers}
          </Button>
        </Row>
        <InfiniteScroll
          onLoad={loadPreviousLeaderboard}
          loadMore={!leaderboardLoading && leaderboardOffset !== -1}
          loading={leaderboardLoading}
          className="sidebar-body-wrapper with-search team-leader-board"
          >
          {!!players
              && players.map((player, index) => (
                <React.Fragment key={player.user_id}>
                  <div
                    className={classNames('player-card', {
                      disabled: player.blockTime && player.blockTime - Date.now() > 0,
                    })}
                  >
                    <span className="index">{player.rank || index + 1}</span>
                    <Avatar url={player.profile_pic} className="pointer" rounded showChild>
                      <React.Fragment>
                        <Badge size="small" color={player.status === 'online' ? 'green' : 'default'} rounded />
                      </React.Fragment>
                    </Avatar>
                    <div className="player-card--info">
                      <div className="player-card--info__title">{player.name}</div>
                    </div>
                    <div className="player-card--others">
                      <div className="player-card--others__player-score">
                        {player.score || 0}
                        {' '}
                        {translationText(noonText, 'label.points')}
                      </div>
                      {(!player.blockTime || player.blockTime - Date.now() < 0) && (
                        <div className={classNames('player-card--others__action-button')}>
                          <IconMoreOption width="20px" color="#707070" rotate="90" />
                          <div className="action-options">
                            <Row className="action-option" onClick={() => handleReportAbuse(player)}>
                              <IconFlag color="#A8A8A8" width="16px" />
                              <span>{translationText(noonText, 'button.reportAbuse')}</span>
                            </Row>
                            <Row className="action-option" onClick={() => blockUser(player)}>
                              <IconKickOut color="#A8A8A8" stroke="#A8A8A8" width="18px" />
                              <span>{translationText(noonText, 'button.block')}</span>
                            </Row>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ))}
        </InfiniteScroll>
        {isEmpty(players) && (
        <div className="no-players infoMsg infoMsg-blue">
          <span>{translationText(noonText, 'classroom.noStudentsLeaderboard')}</span>
        </div>
        )}
      </div>
    </React.Fragment>
  </ErrorBoundry>
);

export default withNoonText(IndividualLeaderboard);

import React, { useState } from 'react';
import { Button, Avatar } from '@noon/atom';
import { IconArrowDown } from '@noon/quark';
import withNoonText from 'helpers/withNoonText';
import cx from 'classnames';
import Popover from 'react-popover';
import TeamMembersList from './TeamMembersList';

const getTeamRAGStatus = (members) => {
  const totalLength = members.length;
  const correctAnswers = members.filter(item => !!item.is_last_choice_correct).length;
  const perc = totalLength > 0 ? Math.round((correctAnswers / totalLength) * 100) : 0;
  let rag = 'green';
  if (perc < 50) {
    rag = 'red';
  }
  if (perc > 50 && perc <= 80) {
    rag = 'amber';
  }
  return { perc, rag };
};
const TeamLeaderBoardItem = ({ team, text, noonText }) => {
  const [memberListOpenFlag, setMemberListOpenFlag] = useState(false);
  const popoverProps = {
    isOpen: memberListOpenFlag,
    preferPlace: 'left',
    onOuterAction: () => setMemberListOpenFlag(false),
    body: <TeamMembersList members={team.team_members} teamname={team.team_name} />,
  };
  const { perc, rag } = getTeamRAGStatus(team.team_members);
  return (
    <div className="team-leader-board__item">
      <div className="team-leader-board__teamdrank">{team.rank}</div>
      <Avatar url={team.team_image} className="pointer" noShadow size="45px" />
      <div className="team-leader-board__teamdetailscontainer">
        <div className="team-leader-board__teamdetails">
          <h1 className="team-leader-board__teamname">{team.team_name}</h1>
          <h1 className="team-leader-board__teampoints">{team.team_points} {text(noonText, 'teacherBreakout.point')}</h1>
        </div>
        <p className="team-leader-board__teammembers">{team.team_members.map(item => item.name).join(', ')}</p>
        <div className="team-leader-board__footer">
          <div
            style={{ width: `${Math.floor(perc / 2)}%` }}
            className={cx('team-leader-board__progressbar', `team-leader-board__progressbar--${rag}`)}
          >
            {`${perc}%`}
          </div>

          <Popover {...popoverProps}>
            <Button
              className="team-leader-board__button"
              fab
              icon={IconArrowDown}
              size="small"
              strokeWidth="5"
              rotate="90"
              fill="blue"
              iconFill="white"
              onClick={() => setMemberListOpenFlag(!memberListOpenFlag)}
            />
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default withNoonText(TeamLeaderBoardItem);

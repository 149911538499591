import { put, call, all, takeEvery } from 'redux-saga/effects';
import { addToast, TOAST_TYPE } from '../../components/Toast';
import { SCHEDULER_CREATE_SCHEDULE, SCHEDULER_GET_SCHEDULES, SCHEDULER_UPDATE_SCHEDULE } from '../constants';
import { schedulerPlannerApi } from '../restApi';

const createSchedulerAPI = (schedule) => schedulerPlannerApi.post('group_schedule', schedule);
const updateSchedulerAPI = (schedule) => schedulerPlannerApi.put('group_schedule', schedule);
const getSchedulesAPI = () => schedulerPlannerApi.get('group_schedule');

function* createSchedule({ payload }) {
  try {
    const response = yield call(createSchedulerAPI, payload);
    if (response.ok) {
      yield put({
        type: SCHEDULER_CREATE_SCHEDULE.SUCCESS,
        payload: response.data,
      });
    } else {
      addToast(response.data.message, TOAST_TYPE.ERROR);
      yield put({
        type: SCHEDULER_CREATE_SCHEDULE.FAILURE,
        payload: 'NETWORK_ERROR',
      });
    }
  } catch (error) {
    yield put({
      type: SCHEDULER_CREATE_SCHEDULE.FAILURE,
      payload: error,
    });
  }
}

function* updateSchedule({ payload }) {
  try {
    const response = yield call(updateSchedulerAPI, payload);
    if (response.ok) {
      yield put({
        type: SCHEDULER_UPDATE_SCHEDULE.SUCCESS,
        payload: response.data,
      });
    } else {
      addToast(response.data.message, TOAST_TYPE.ERROR);
      yield put({
        type: SCHEDULER_UPDATE_SCHEDULE.FAILURE,
        payload: 'NETWORK_ERROR',
      });
    }
  } catch (error) {
    yield put({
      type: SCHEDULER_UPDATE_SCHEDULE.FAILURE,
      payload: error,
    });
  }
}

function* getSchedules({ payload }) {
  try {
    const response = yield call(getSchedulesAPI);
    if (response.ok) {
      yield put({
        type: SCHEDULER_GET_SCHEDULES.SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: SCHEDULER_GET_SCHEDULES.FAILURE,
        payload: 'NETWORK_ERROR',
      });
    }
  } catch (error) {
    yield put({
      type: SCHEDULER_GET_SCHEDULES.FAILURE,
      payload: error,
    });
  }
}
export function* schedulerSagas() {
  yield all([
    takeEvery(SCHEDULER_CREATE_SCHEDULE.REQUEST, createSchedule),
    takeEvery(SCHEDULER_UPDATE_SCHEDULE.REQUEST, updateSchedule),
    takeEvery(SCHEDULER_GET_SCHEDULES.REQUEST, getSchedules),
  ]);
}

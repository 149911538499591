import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ConfirmationModal } from '../../Modals';
import { ARCHIVE_GROUP, GET_TEACHER_ARCHIVE_GROUPS, GET_TEACHER_GROUPS, GET_TEACHER_GROUP_SELECTED } from '../../../redux/constants';
import t from '../../../helpers/translate';

function ArchiveGroupModal({ groupId, archiveGroup, toggleArchiveModal }) {
  const dispatch = useDispatch();

  const handleOnClickArchiveGroup = () => {
    dispatch({
      type: ARCHIVE_GROUP.REQUEST,
      payload: { group_id: groupId },
    });
  };

  useEffect(() => {
    if (!archiveGroup.loading && archiveGroup.archived) {
      toggleArchiveModal();

      dispatch({
        type: GET_TEACHER_GROUP_SELECTED.REQUEST,
        payload: groupId,
      });
      dispatch({
        type: GET_TEACHER_ARCHIVE_GROUPS.REQUEST,
      });
      dispatch({
        type: GET_TEACHER_GROUPS.REQUEST,
      });
    }
  }, [archiveGroup]);
  return (
    <ConfirmationModal
      hideTextIcon
      modalType="warning"
      successBtn={t('button', 'archive')}
      closeBtn={t('form', 'later')}
      onClose={toggleArchiveModal}
      onSuccess={handleOnClickArchiveGroup}
      isLoading={archiveGroup.loading}
      text={t('groups', 'archiveGroupModalTitle')}
      subText={t('groups', 'archiveGroupModalText')}
    />
  );
}

ArchiveGroupModal.propTypes = {
  toggleArchiveModal: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  archiveGroup: PropTypes.shape({
    loading: PropTypes.bool,
    archived: PropTypes.bool,
  }).isRequired,
};
export default ArchiveGroupModal;

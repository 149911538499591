import { put, call, takeEvery, all, select, take } from 'redux-saga/effects';
import { DateTime } from 'luxon';
import { TrackSentryError, SentryType } from 'SentryAlias';
import { sortBy, isEmpty } from 'lodash-es';
import { QNA_BREAKOUT_START_EVENT,
  QNA_BREAKOUT_END_EVENT,
  QNA_GET_MESSAGES,
  QNA_START_BREAKOUT,
  QNA_MESSAGE,
  GET_BREAKOUT_TEAMS,
  UPDATE_SLIDE_CONFIG,
  QNA_LOAD_DATA,
  QNA_BREAKOUT_START_EVENT_SERVER,
  UPDATE_SCREEN_SHOT,
  INIT_CLASSROOM,
  QNA_SCREEN_SHOT_UPDATED } from '../../../redux/constants';
import EM from '../../../providers/Event/EM';
import Mixpanel from '../../Mixpanel';
import t from '../../../helpers/translate';

import { addToast, TOAST_TYPE } from '../../Toast/index.js';
import { athenaApi } from '../../../redux/restApi';

let logger:any;

const getQnaMessagesAPI = ({ sessionId, offset }) => athenaApi.get(`teacher/qna/session/${sessionId}`, { offset: offset || 50 });

const getSlides = (state) => state.toJS().whiteboard;
const getTeamQna = (state) => state.toJS().TeamQna;
const getSessionDetails = (state) => state.toJS().myClassroom.sessionDetails;
const getUser = (state) => state.toJS().user.loggedUser;
const getBreakoutTeams = (state) => state.toJS().breakoutTeams;
const getWhiteboard = (state) => state.toJS().whiteboard;

function* handleMixpanelKafkaActivityEvents(type, data = {}) {
  try {
    if (!logger) logger = LoggingManager.mount({ moduleName: 'classroom' });

    const sessionDetails = yield select(getSessionDetails);
    const { user_id, gender } = yield select(getUser);
    const session_id = sessionDetails.id;
    const group_id = sessionDetails.group.id;

    const eventData = {
      ...data,
      user_id,
      gender,
      session_id,
      group_id,
    };

    Mixpanel.track(type, eventData);
    logger.track(type,
      { group: eventData },
    );

    yield true;
  } catch (e) {
    console.log('TEAM QNA BREAKOUT ANALYTICS ERROR', e);
    yield true;
  }
}

function* getduration(time) {

  const now = DateTime.fromISO(new Date().toISOString()); // todays date
  const session_start_time = DateTime.fromMillis(time); // another date
  const duration = now.diff(session_start_time, 'minutes');
  const session_interval = Math.round(duration.toObject().minutes);
  return `${session_interval < 1 ? 'seconds ago' : `${session_interval} min ago`}`;
}

function* handleMessage({ message }) {

  if (message.type !== 'questions') { return; }
  const { teams } = yield select(getBreakoutTeams);
  const team = teams.find((team) => team.team_id === message.team_id) || {};
  const { team_name, team_image } = team;
  const timeAgo = yield getduration(message.timestamp);
  const { msg, user_info, image_url, slide_number } = message;
  const { name, profile_pic } = user_info;
  return {
    team_name: team_name || message.team_name,
    team_image: team_image || message.team_image,
    name,
    msg,
    timeAgo,
    profile_pic,
    image_url,
    slide_number,
  };

}

function* onBreakoutInitial() {
  try {
    // teacher_clicks_start_qna_breakout
    const { total_teams } = yield select(getBreakoutTeams);
    const { messages } = yield select(getTeamQna);
    const { slides, activeSlideIndex } = yield select(getWhiteboard);
    // if there are no slides , show message to teacher:
    if (activeSlideIndex === 0 && slides.length === 0) {
      const errorMessage = yield t('teamQnaBreakout', 'noSlidesError');
      yield addToast(errorMessage, TOAST_TYPE.ERROR);
      return;
    }
    const board_id = slides[activeSlideIndex].canvas_id;
    const total_asked_questions = messages.length;
    const total_teams_count = total_teams;
    yield handleMixpanelKafkaActivityEvents('teacher_clicks_start_qna_breakout', { board_id, total_asked_questions, total_teams_count });
  } catch (e) {
    console.clear();
    console.error(e);
    const errorMessage = yield t('teamQnaBreakout', 'error');
    yield addToast(errorMessage, TOAST_TYPE.ERROR);
    yield put({ type: QNA_BREAKOUT_END_EVENT.AFTER });
    TrackSentryError('TEAM QNA START BREAKOUT ERROR', e, SentryType.ERROR);
  }
}

function* onBreakoutStarted() {
  try {

    // check if the last slide url has been updated, else wait
    const { slides, slideUrls } = yield select(getSlides);
    const { duration, messages } = yield select(getTeamQna);
    const slides_info = sortBy(slides, ['number']).reverse().map((s) => (slideUrls[s.canvas_id]))
      .map((s, index, arr) => ({
        snapshotUrl: {
          // last resort in case the first slide snapshot gets missed somehow.
          large_url: s.snapshotUrl ? s.snapshotUrl.large_url : 'https://cdn.non.sa/web-assets/teacher/slide-no-available.png',
        },
        slide_number: arr.length - index,
      }));

    const eventPayload = {
      discussion_time_duration: duration,
      slides: {
        slides_info,
      },

    };

    // dispatch event
    yield EM.sendMessage(EM.CLASSROOM_EVENT.startTeamQna, eventPayload);

    // track event

    const total_questions_asked = messages.length;
    yield handleMixpanelKafkaActivityEvents('qna_breakout_triggered', { total_questions_asked });

  } catch (e) {
    console.clear();
    console.error(e);
    const errorMessage = yield t('teamQnaBreakout', 'error');
    yield addToast(errorMessage, TOAST_TYPE.ERROR);
    yield put({ type: QNA_BREAKOUT_END_EVENT.AFTER });
    TrackSentryError('TEAM QNA BREAKOUT STARTED ERROR', e, SentryType.ERROR);
  }

}

function* onStartBreakoutEvent({ payload }) {
  try {
    // skip if breakout is already ongoing and has been loaded from localstorage
    const { qnaBreakoutOngoing } = yield select(getTeamQna);
    if (qnaBreakoutOngoing) { return; }
    const sessionDetails = yield select(getSessionDetails);
    yield put({ type: QNA_BREAKOUT_START_EVENT.AFTER, payload });

    // disable slides
    yield put({ type: UPDATE_SLIDE_CONFIG,
      payload: {
        disableAddSlide: true,
        disableSelectSlide: true,
        allowDelete: false,
      } });

    // save current data to localstorage
    const teamQna = yield select(getTeamQna);
    localStorage.setItem(`team_qna_data_${sessionDetails.id}`, JSON.stringify(teamQna));

  } catch (e) {
    console.clear();
    console.error('qna error', e);
    const errorMessage = yield t('teamQnaBreakout', 'error');
    yield addToast(errorMessage, TOAST_TYPE.ERROR);
    yield put({ type: QNA_BREAKOUT_END_EVENT.AFTER });
    TrackSentryError('TEAM QNA BREAKOUT STARTED SERVER ERROR', e, SentryType.ERROR);
  }
}

function* onBreakoutEnded() {
  // enable slides
  yield put({ type: UPDATE_SLIDE_CONFIG,
    payload: {
      disableAddSlide: false,
      disableSelectSlide: false,
      allowDelete: false,
    } });

  const sessionDetails = yield select(getSessionDetails);
  localStorage.removeItem(`team_qna_data_${sessionDetails.id}`);
  yield put({ type: QNA_BREAKOUT_END_EVENT.AFTER });

}

function* getQnaMessages() {
  const { id } = yield select(getSessionDetails);
  const offset_count = 10;

  try {

    // send event for teacher opened session questions
    const { messages } = yield select(getTeamQna);
    const total_questions_asked = messages.length;
    yield handleMixpanelKafkaActivityEvents('teacher_opens_session_questions', { total_questions_asked });

    // fetch breakout teams before messages

    yield take(GET_BREAKOUT_TEAMS.SUCCESS);

    const response = yield call(getQnaMessagesAPI, {
      sessionId: id,
      offset_count,
    });
    if (response.ok) {

      const data = response.data.data.filter((d) => d.type === 'questions');
      const messages = [];
      for (let i = 0; i < data.length; i++) {
        const msg = yield handleMessage({ message: data[i] });
        messages.push(msg);
      }

      yield put({
        type: QNA_GET_MESSAGES.SUCCESS,
        data: messages,
        offset: response.data.meta_data.offset_count,
        isReset: !offset_count,
      });
    } else {
      yield put({ type: QNA_GET_MESSAGES.FAILURE, payload: 'NETWORK_ERROR' });
      TrackSentryError('TEAM QNA GET MESSAGES ERROR', response, SentryType.ERROR);
    }

  } catch (err) {
    yield put({ type: QNA_GET_MESSAGES.FAILURE, payload: err });
    TrackSentryError('TEAM QNA GET MESSAGES ERROR', err, SentryType.ERROR);
  }
}

export function* onQnaMessage({ payload }) {
  const msg = yield handleMessage({ message: payload });

  yield put({ type: QNA_MESSAGE.AFTER, payload: msg });
}

export function* loadData() {
  try {
    // wait for classroom to finish initializaing
    yield take(INIT_CLASSROOM.SUCCESS);
    const sessionDetails = yield select(getSessionDetails);
    const teamQna = JSON.parse(localStorage.getItem(`team_qna_data_${sessionDetails.id}`));

    if (teamQna) {

      const currentTime = DateTime.fromMillis(Date.now());
      const startTime = DateTime.fromMillis(teamQna.startTime);
      const elapsed = currentTime.diff(startTime, 'seconds').seconds;
      const timeRemaining = Math.round(teamQna.duration - elapsed);
      if (!(timeRemaining < 0)) {
        const payload = {
          ...teamQna,
          timeRemaining,
          qnaBreakoutOngoing: !(timeRemaining < 0),
          qnaBreakoutStarted: !(timeRemaining < 0),
        };

        yield put({ type: QNA_LOAD_DATA.AFTER, payload });

        yield put({ type: QNA_GET_MESSAGES.REQUEST });

        // if team qna is still running , disable slides
        yield put({ type: UPDATE_SLIDE_CONFIG,
          payload: {
            disableAddSlide: true,
            disableSelectSlide: true,
            allowDelete: false,
          } });
      }
    }
  } catch (e) {
    console.log('TEAM QNA LOAD DATA ERROR', e);
  }
}

function* onScreenShotUpdate() {
  yield put({ type: QNA_SCREEN_SHOT_UPDATED });
}

export function* TeamQnaSaga() {
  yield all([
    takeEvery(QNA_BREAKOUT_START_EVENT.BEFORE, onBreakoutStarted),
    takeEvery(QNA_BREAKOUT_END_EVENT.BEFORE, onBreakoutEnded),
    takeEvery(QNA_GET_MESSAGES.REQUEST, getQnaMessages),
    takeEvery(QNA_START_BREAKOUT, onBreakoutInitial),
    takeEvery(QNA_MESSAGE.BEFORE, onQnaMessage),
    takeEvery(QNA_LOAD_DATA.BEFORE, loadData),
    takeEvery(QNA_BREAKOUT_START_EVENT_SERVER, onStartBreakoutEvent),
    takeEvery(UPDATE_SCREEN_SHOT, onScreenShotUpdate),
  ]);
}

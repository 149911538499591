import React, { useCallback, useEffect } from 'react';
import { IconRaiseHand } from '@noon/quark';
import { useSelector, useDispatch } from 'react-redux';
import { RAISE_HAND_ACTION } from '../../../redux/constants';
import ErrorBoundry from '../../ErrorBoundry';
import EmptyState from '../emptyState';
import { COLORS } from '../../../constants';
import t from '../../../helpers/translate';
import { CLASSROOM_EVENT } from '../events';
import { useEventDispatch } from '../../../providers/Event';
import RaiseHands from './raise-hands';
import { addToast, TOAST_TYPE } from '../../Toast';
import isEmpty from 'lodash-es/isEmpty';
import { RaiseHandActionTypes, RaiseHandStatus } from '../types';
import { IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';
import { useClassroomAnalytics } from '../../../providers/ClassroomAnalytics';
import { SentryType, TrackSentryError } from '../../../sdk/sentry';

const RAISE_HAND_HISTORY_TIME = 600000;
const RAISE_HAND_CANCELLED_TIME = 5000;

const RaiseHandContainer = () => {
  const dispatch = useDispatch();
  const sendMessage = useEventDispatch();
  const sendAnalytics = useClassroomAnalytics();

  const { raiseHandQueue = {}, raiseHandLoadingFor } = useSelector((state) => state.toJS().myClassroom.raiseHand);
  const streams = useSelector((state) => state.toJS().webrtc.streams);
  const breakoutState = useSelector((state) => state.toJS().myClassroom.breakoutState);
  const sessionDetails = useSelector((state) => state.toJS().myClassroom.sessionDetails);
  const loggedUser = useSelector((state) => state.toJS().user.loggedUser);

  const { activeRaiseHand, pendingRaiseHand, historyRaiseHand } = raiseHandQueue;
  const historyRaiseHandLastTenMins = historyRaiseHand.filter(
    (raiseHand) =>
      raiseHand.status === RaiseHandStatus.cancelled &&
      Date.now() - raiseHand.insertTime <= RAISE_HAND_HISTORY_TIME &&
      raiseHand.cancelledWithin > RAISE_HAND_CANCELLED_TIME
  );

  const sendRaiseHand = async (type, user) => {
    const payload = {
      user_id: user.user_id,
      type,
      name: user.name,
      profile_pic: user.profile_pic,
    };
    dispatch({ type: RAISE_HAND_ACTION.REQUEST, payload: { type, user } });
    try {
      await sendMessage(CLASSROOM_EVENT.sendRaiseHand, payload);
      dispatch({ type: RAISE_HAND_ACTION.SUCCESS, payload: { type, user } });
    } catch (error) {
      dispatch({ type: RAISE_HAND_ACTION.FAILURE });
    }

    // send same event to Agora RTM
    if (type === RaiseHandActionTypes.remove) {
      await sendMessage(CLASSROOM_EVENT.raiseHandCancelFallback, payload);
    }

    sendAnalytics('raise_hand_action', {
      student_id: user.user_id,
      session_id: sessionDetails.id,
      group_id: sessionDetails.group.id,
      teacher_id: loggedUser.id,
      event_id: user.id,
      action: type === RaiseHandActionTypes.start_speaking ? 'accept' : 'reject',
    });
  };

  const onRaiseHandAction = useCallback(async (type, user) => {
    if (raiseHandLoadingFor) {
      return;
    }

    if (breakoutState) {
      addToast(t('classroom', 'raise_hand_accept_not_allowed'), TOAST_TYPE.WARNING);
      return;
    }

    // if raise hand user is already active and teacher
    // accepeted another user, remove current active user before accepting new user request
    if (type === RaiseHandActionTypes.start_speaking && activeRaiseHand?.user_id) {
      await sendRaiseHand(RaiseHandActionTypes.remove, activeRaiseHand);
    }
    await sendRaiseHand(type, user);
  }, [breakoutState, pendingRaiseHand]);

  const cancelGhostRequest = () => {
    streams.forEach(stream => {
      const audioStreamUserId = Number(stream?.uid);
      if (audioStreamUserId !== activeRaiseHand?.user_id) {
        const user = {
          user_id: audioStreamUserId,
          type: RaiseHandActionTypes.remove,
          name: '',
          profile_pic: '',
        };
        sendRaiseHand(RaiseHandActionTypes.remove, user)
      }
    })
  };

  useEffect(() => {
    if(streams.length > 1 || (!activeRaiseHand?.user_id && streams.length === 1)) {
      TrackSentryError('RAISEHAND_REQUEST_MULTIPLE_AUDIO', {
        streams: JSON.stringify(streams.map(stream => ({ uid: stream.uid }))),
        total_streams: streams.length,
        session_id: sessionDetails?.id,
        user_id: loggedUser?.id,
        active_raise_hand: activeRaiseHand?.id,
      }, SentryType.LOG);

      cancelGhostRequest()
    }
  }, [streams]);

  // This is to send cancel event if student cancel the request and teacher accept the request at the same time
  // When teacher got the cancel request  from student status is set to cancelled and move to historyRaiseHand
  // Here we are looking in historyRaiseHand and sending cancel event to pubnub for active and request just accepted
  useEffect(() => {
    historyRaiseHand.map(raiseHandRequest => {
      if(raiseHandRequest.active) {
        sendRaiseHand(RaiseHandActionTypes.remove, raiseHandRequest)
      } else if (raiseHandRequest.user_id === raiseHandLoadingFor && !raiseHandRequest.value) {
        sendRaiseHand(RaiseHandActionTypes.remove, raiseHandRequest)
      }
    });
  }, [historyRaiseHand]);

  if (isEmpty(activeRaiseHand) && pendingRaiseHand.length === 0 && historyRaiseHandLastTenMins.length === 0) {
    return (
      <EmptyState
        icon={<IconRaiseHand fill={COLORS.grey[2]} height="8rem" width="8rem" />}
        title={t('classroom', 'empty_raisehand_title')}
        subtitle={t('classroom', 'empty_raisehand_subtitle')}
      />
    );
  }

  return (
    <ErrorBoundry msg="There is some problem with RaiseHand Listing, please refresh to try again">
      <RaiseHands
          activeRaiseHand={activeRaiseHand}
          pendingRaiseHand={pendingRaiseHand}
          historyRaiseHand={historyRaiseHandLastTenMins}
          raiseHandLoadingFor={raiseHandLoadingFor}
          onRaiseHandAction={onRaiseHandAction}
          studentAudioStream={streams.find((stream) => stream.uid === activeRaiseHand.user_id) as IAgoraRTCRemoteUser}
        />
    </ErrorBoundry>
  );
};

export default RaiseHandContainer;

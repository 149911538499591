import { combineReducers } from 'redux-immutable';
import file from './reducer/file';
import flashcard from './reducer/flashcard';
import folder from './reducer/folder';
import groups from './reducer/groups';
import posts from './reducer/posts';
import question from './reducer/question';
import toast from './reducer/toast';
import translation from './reducer/translation';
import tutoring from './reducer/tutoring';
import user from './reducer/user';
import dashboard from './reducer/dashboard';
import notification from './reducer/notification';
import userv2 from './reducer/userv2';
import biFrost from './reducer/biFrost';
import onboarding from './reducer/onboarding';
import breakoutTeams from './reducer/breakoutTeams';
import classroom from './reducer/classroom';
import curriculum from './reducer/curriculum';
import groupsV2 from './reducer/groupsV2';
import homeworkCreation from './reducer/homeworkCreation';
import homeworkReport from './reducer/homeworkReport';
// scheduler
import feedQuestion from './reducer/feedQuestion';
import generalPlanner from './reducer/generalPlanner';
import scheduler from './reducer/scheduler';
import addQuestionFromImage from './reducer/addQuestionFromImage';
import promotion from './reducer/promotion';
import packages from './reducer/packages';
import myClassroom from './reducer/myClassroom';
import content from './reducer/content';
import webrtc from './reducer/webrtc';
import whiteboard from './reducer/whiteboard';
import TeamQna from '../components/Classroom/TeamQna/reducer';
import classroomConfig from './reducer/classroomConfig';
import chat from './reducer/chat';
import playback from './reducer/playback';
import experiment from './reducer/experiment';

const rootReducer = combineReducers({
  file,
  flashcard,
  folder,
  groups,
  posts,
  question,
  toast,
  translation,
  tutoring,
  user,
  dashboard,
  notification,
  userv2,
  biFrost,
  onboarding,
  breakoutTeams,
  classroom,
  curriculum,
  groupsV2,
  feedQuestion,
  homeworkCreation,
  homeworkReport,
  generalPlanner,
  scheduler,
  addQuestionFromImage,
  promotion,
  packages,
  myClassroom,
  content,
  webrtc,
  whiteboard,
  chat,
  TeamQna,
  classroomConfig,
  playback,
  experiment,
});

export default rootReducer;

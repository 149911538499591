import { put, call, takeEvery, select, all, delay } from 'redux-saga/effects';
import * as html2canvas from 'html2canvas';
import { get } from 'lodash-es';
import { TrackSentryError, SentryType } from 'SentryAlias';
import { QNA_BREAKOUT_END_EVENT, RETRY_UPLOAD_SC, RETRY_UPLOAD_SC_FAIL, RETRY_UPLOAD_SC_SUCCESS, TAKE_SCREEN_SHOT, UPDATE_SCREEN_SHOT } from '../constants';
import { fileApi } from '../restApi';
import EM from '../../providers/Event/EM';
import { addToast, TOAST_TYPE } from '../../components/Toast';

const getWhiteboard = (state) => state.toJS().whiteboard;
const getTeamQna = (state) => state.toJS().TeamQna;
const myClassroom = (state) => state.toJS().myClassroom;

function* takeScreenShot({ payload }) {
  // take screen shot of the slide
  try {
    // cancel task if team qna breakout is ongoing
    const { qnaBreakoutOngoing } = yield select(getTeamQna);
    const { sessionDetails } = yield select(myClassroom);

    if (qnaBreakoutOngoing) {
      if (payload) yield put(payload);
    } else {
      const canvasContainer = document.querySelector('.whiteboard-question') || document.querySelector('.canvas-container');
      const { slides, activeSlideIndex: activeIndex } = yield select(getWhiteboard);
      const activeSlideIndex = get(sessionDetails, ['class_type']) !== 'competition' ? activeIndex : activeIndex + 1;
      if (canvasContainer) {
        // Avoid capturing border in snapshot
        canvasContainer.style.borderColor = '#ffffff';
        if (payload) yield put(payload);
        const canvasPromise = new Promise((resolve, reject) => {
          html2canvas(canvasContainer, { allowTaint: true,
            useCORS: true }).then((canvas) => resolve(canvas));
        });
          // first take snapshot, then yield the next action if available
        const imageCanvas = yield canvasPromise;
        const blobPromise = new Promise((resolve, reject) => { imageCanvas.toBlob((blob) => resolve(blob), 'image/jpeg', 0.95); });
        const imageBlob = yield blobPromise;

        // Re-set the border colour after snapshot is taken
        canvasContainer.style.borderColor = '#eeeeee';
        const canvasId = activeSlideIndex >= 0 && slides.length ? slides[activeSlideIndex].canvas_id : null;
        // upload file
        const fd = new FormData();
        fd.append('destination', 'canvas');
        const ext = 'jpeg';
        const originalName = canvasId;
        fd.append('fileUpl', imageBlob, canvasId);
        fd.append('file_name', `${originalName}.${ext}`);

        const uploadPromise = yield new Promise((resolve, reject) => {
          fileApi.post('files', fd).then((response) => {
            const { meta, success } = response.data;
            if (success) {
              resolve(meta);
            } else throw response.data.err;
          }).catch((e) => {
            resolve(false);
          });
        });
        yield delay(1000);

        const meta = yield uploadPromise;
        if (!slides.length) return;
        if (uploadPromise) {
          const screenPayload = { id: canvasId, urls: { ...meta }, slideIndex: activeSlideIndex };
          yield put({ type: UPDATE_SCREEN_SHOT, payload: screenPayload });
        } else {
          // retry
          const screenPayload = { id: canvasId, urls: { large_url: 'https://cdn.non.sa/web-assets/teacher/slide-no-available.png' }, slideIndex: activeSlideIndex };
          yield put({ type: UPDATE_SCREEN_SHOT, payload: screenPayload });
          yield put({
            type: RETRY_UPLOAD_SC,
            payload: {
              payload: screenPayload,
              fd,
            },
          });
        }

        // send payload to unicast teacher event
        const snapShotPayload = {
          id: canvasId,
          snapshotUrl: uploadPromise ? { ...meta } : { large_url: 'https://cdn.non.sa/web-assets/teacher/slide-no-available.png' },
        };

        if (!sessionDetails || sessionDetails.class_type !== 'competition') yield EM.sendMessage(EM.CLASSROOM_EVENT.updateSlideSnapshot, snapShotPayload);
      } else if (payload) yield put(payload);
    }
  } catch (e) {
    console.clear();
    console.error(e);
    yield addToast('Could not update snapshot', TOAST_TYPE.ERROR);
    TrackSentryError('WHITEBOARD SNAPSHOT ERROR', e, SentryType.ERROR);
    // cancel team qna breakout dialog
    yield put({ type: QNA_BREAKOUT_END_EVENT.AFTER });
  }

}

function* retryUpload({ payload }) {
// retry the upload again

  try {
    const { screenshotRetries } = yield select(getWhiteboard);
    const retry = screenshotRetries[payload.payload.id] || {};
    const { sessionDetails } = yield select(myClassroom);
    if (retry.count >= 1 && retry.count <= 4) {
      yield addToast(`Retrying slide #${payload.payload.slideIndex + 1} screenshot`, TOAST_TYPE.WARNING);
      yield delay(30000);
      const uploadPromise = yield new Promise((resolve, reject) => {
        fileApi.post('files', payload.fd).then((response) => {
          const { meta, success } = response.data;
          if (success) {
            resolve({ success: true, meta });
          } else resolve({ success: false, error: response.data.err });

        }).catch((e) => {

          resolve({ success: false, error: e });
        });
      });

      const { success, meta, error } = yield uploadPromise;
      if (success) {
        yield put({ type: UPDATE_SCREEN_SHOT, payload: { ...payload.payload, urls: { ...meta } } });
        // send payload to unicast teacher event
        const snapShotPayload = {
          id: payload.payload.id,
          snapshotUrl: success ? { ...meta } : { large_url: 'https://cdn.non.sa/web-assets/teacher/slide-no-available.png' },
        };

        if (!sessionDetails || sessionDetails.class_type !== 'competition') yield EM.sendMessage(EM.CLASSROOM_EVENT.updateSlideSnapshot, snapShotPayload);
        yield put({ type: RETRY_UPLOAD_SC_SUCCESS, payload: { id: payload.payload.id } });
      } else {
        // check if not max retries then try again

        yield put({
          type: RETRY_UPLOAD_SC,
          payload,
        });

      }
    } else {
      throw new Error('max retires reached');
    }
  } catch (e) {

    // track js error for retry failiure
    yield addToast(`Slide #${payload.payload.slideIndex + 1} screenshot failed`, TOAST_TYPE.ERROR);
    TrackSentryError('WHITEBOARD SNAPSHOT ERROR', e, SentryType.ERROR);
    // cancel team qna breakout dialog
    yield put({ type: RETRY_UPLOAD_SC_FAIL, payload: { id: payload.payload.id } });
  }

}

export function* whiteBoardSaga() {
  yield all([
    takeEvery(TAKE_SCREEN_SHOT, takeScreenShot),
    takeEvery(RETRY_UPLOAD_SC, retryUpload),
  ]);
}

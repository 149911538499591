const getText = (noonTranslate, param) => {
  const params = param.split('.');
  let translate = noonTranslate || {};
  for (const p of params) {
    if (translate[p]) {
      translate = translate[p];
    } else {
      translate = '';
      break;
    }
  }
  return translate;
};

export default function translationText(allTexts, param) {
  const translations = allTexts || JSON.parse(localStorage.translationData || '{}').data;
  return getText(translations, param) || '';
}

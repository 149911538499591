import { IconChat, IconStudy, IconRaiseHand, IconLeaderboard } from '@noon/quark';
import { groupBy, uniqBy } from 'lodash-es';
import uuidv4 from 'uuid/v4';
import { RaiseHandActionTypes, RaiseHandStatus } from '../../components/Classroom/types';
import t from '../../helpers/translate';

import {
  CLEAR_CLASSROOM,
  INIT_CLASSROOM,
  START_CLASSROOM,
  SELECTED_TAB,
  DISABLE_TABS,
  ENABLE_TABS,
  SIDEBAR_TAB_COUNT,
  CLASSROOM_LEADERBOARD,
  CLASSROOM_STUDENT_JOIN,
  CLASSROOM_STUDENT_LEFT,
  BREAKOUT_TEAM_FORMED,
  INITIALIZE_RAISE_HAND,
  RAISE_HAND_REQUEST,
  CLASSROOM_NEED_TO_DISCONNECT,
  RAISE_HAND_ACTION,
  CLASSROOM_STUDENT_FEEDBACK,
  BLOCK_CHAT,
  EXIT_CLASSROOM,
  START_BREAKOUT,
  END_BREAKOUT,
  SET_LOCAL_DEVICE_DETAILS,
  SWITCH_AUDIO_DEVICE,
  SWITCH_VIDEO_DEVICE,
  GET_STUDENT_CLASSROOM_META,
  ADD_STUDENT_CLASSROOM_META_QUEUE,
} from '../constants';

const tabs = [
  {
    name: 'chat',
    icon: IconChat,
    disabled: false,
    title: t('playback', 'chat'),
  },
  {
    name: 'raiseHand',
    icon: IconRaiseHand,
    disabled: false,
    title: t('tab', 'raisehand'),
  },
  {
    name: 'leaderboard',
    icon: IconLeaderboard,
    disabled: false,
    title: t('homework', 'leaderboardTab'),
  },
  {
    name: 'content',
    icon: IconStudy,
    disabled: false,
    title: t('tab', 'study'),
  },
];

const initialState = {
  lastSessionId: localStorage.getItem('last_session_id') || 0,
  sessionDetails: {},
  agoraRtcDetails: {},
  agoraRtmDetails: {},
  pubnubDetails: {},
  teacherDetails: {},
  isClassInitiated: false,
  isClassStartLoading: false,
  breakoutTeamFormed: false,
  needToDisconnect: false,
  sidebarTabs: tabs.filter((tab) => ['content'].includes(tab.name)) || [],
  activeTab: tabs[3],
  studentFeedback: {
    understoodCount: 0,
    notUnderstoodCount: 0,
  },
  sidebarTabCount: {
    chat: 0,
    raiseHand: 0,
  },
  leaderboard: {
    isLeaderboardLoading: false,
    studentList: [],
    studentListMap: {},
    leaderboardOffset: 0,
    studentCount: 0,
    liveStudentCount: 0,
    isTeamLoading: false,
    teamList: [],
    teamOffset: -1,
    teamCount: 0,
  },
  raiseHand: {
    raiseHandQueue: {
      activeRaiseHand: {},
      pendingRaiseHand: [],
      historyRaiseHand: [],
    },
    raiseHandLoadingFor: 0,
  },
  exitClassroomByServer: false,
  showReuseTab: false,
  error: {},
  localDevices: {},
  studentClassroomMeta: {
    requestQueue: [],
    isLoading: false,
    data: JSON.parse(sessionStorage.getItem('studentClassroomMeta') || '{}'),
  },
};

function calculateRank(students) {
  let prevScore = -1;
  let prevRank = 0;
  return students.sort((a, b) => Number(b.score || 0) - Number(a.score || 0))
    .map((player) => {
      let obj;
      if (prevScore === (player.score || 0)) {
        obj = {
          ...player,
          rank: prevRank,
        };
      } else {
        prevRank++;
        obj = {
          ...player,
          rank: prevRank,
        };
      }
      prevScore = player.score || 0;
      return obj;
    });
}

const generateHashMap = (list, key) => {
  const hashMap = {};
  list.forEach((listItem) => {
    hashMap[listItem[key]] = listItem;
  });
  return hashMap;
};

function generateStudentList(state, payload) {

  const { leaderboardOffset, studentList } = state.leaderboard;
  const students = !leaderboardOffset ? payload : [...studentList, ...payload];
  const studentListSorted = calculateRank(uniqBy(students, 'user_id'));
  return {
    studentList: studentListSorted,
    studentListMap: generateHashMap(studentListSorted, 'user_id'),
  };
}

function studentJoin(state, payload) {
  const { studentList, studentListMap, studentCount, liveStudentCount } = state.leaderboard;
  const studentIndex = studentList.findIndex((student) => String(student.user_id) === String(payload.user_id));

  // payload.my_role for backword compatibility : remove in future
  const isStudent = (payload.my_role === 'student' || payload.myRole === 'student');

  if (isStudent && studentIndex === -1) {
    const students = calculateRank([...studentList, { ...payload, status: 'online' }]);
    return {
      studentList: students,
      studentListMap: {
        ...studentListMap,
        [payload.user_id]: { ...studentListMap[payload.user_id], status: 'online' },
      },
      studentCount: studentCount + 1,
      liveStudentCount: liveStudentCount + 1,
    };
  }
  if (isStudent && studentIndex !== -1 && studentList[studentIndex] && studentList[studentIndex].status !== 'online') {
    return {
      studentList: [...studentList.map((student) => (String(student.user_id) === String(payload.user_id) ? { ...student, status: 'online' } : student))],
      studentListMap: {
        ...studentListMap,
        [payload.user_id]: { ...studentListMap[payload.user_id], status: 'online' },
      },
      liveStudentCount: liveStudentCount + 1,
    };
  }
  return {};
}

function studentLeft(state, payload) {
  const { studentList, studentListMap, liveStudentCount } = state.leaderboard;

  if (!studentListMap[payload.user_id]) return {};

  // if status already offline, ignore duplicate event
  if (studentListMap[payload.user_id].status === 'offline') return {};

  return {
    studentList: [...studentList.map((student) => (String(student.user_id) === String(payload.user_id) ? { ...student, status: 'offline' } : student))],
    studentListMap: {
      ...studentListMap,
      [payload.user_id]: { ...studentListMap[payload.user_id], status: 'offline' },
    },
    liveStudentCount: liveStudentCount > 1 ? liveStudentCount - 1 : 0,
  };
}

function updatePropertiesOnClassStart(state, payload) {
  const isClassStarted = payload.sessionDetails && payload.sessionDetails.state === 'started';
  return {
    sidebarTabs: isClassStarted ? [...tabs] : state.sidebarTabs,
    activeTab: isClassStarted ? tabs[0] : tabs[3],
  };
}

function blockUser(state, payload) {
  const { studentList } = state.leaderboard;
  const blockedPlayerIndex = studentList.findIndex((student) => student.user_id === payload.user_id);
  if (blockedPlayerIndex > -1) {
    const updatedList = [
      ...studentList.slice(0, blockedPlayerIndex),
      { ...studentList[blockedPlayerIndex], blockTime: payload.blockTime },
      ...studentList.slice(blockedPlayerIndex + 1),
    ];
    return {
      leaderboard: {
        ...state.leaderboard,
        studentList: updatedList,
      },
    };
  }
  return {};
}

const handleUpdateRaiseHandRequestStatus = (state, raiseHandUser, type) => {
  const { raiseHand: { raiseHandQueue } } = state;
  const totalRequest = [raiseHandQueue.activeRaiseHand, ...raiseHandQueue.pendingRaiseHand, ...raiseHandQueue.historyRaiseHand];
  if (!raiseHandUser) return totalRequest;

  const raiseHandRequestIndex = totalRequest.findIndex((user) => user.user_id === raiseHandUser.user_id);
  if (raiseHandRequestIndex < 0) return totalRequest;

  const currentUserStatus = totalRequest[raiseHandRequestIndex]?.status;
  if (type === RaiseHandActionTypes.start_speaking && currentUserStatus === RaiseHandStatus.pending) {
    totalRequest[raiseHandRequestIndex] = {
      ...totalRequest[raiseHandRequestIndex],
      active: true,
      status: RaiseHandStatus.active,
      insertTime: Date.now(),
    };
  } else if (type === RaiseHandActionTypes.remove && currentUserStatus === RaiseHandStatus.active) {
    totalRequest[raiseHandRequestIndex] = {
      ...totalRequest[raiseHandRequestIndex],
      status: RaiseHandStatus.accepted,
      active: false,
    };
  } else {
    totalRequest[raiseHandRequestIndex] = {
      ...totalRequest[raiseHandRequestIndex],
      status: RaiseHandStatus.cancelled,
      active: false,
    };
  }
  localStorage.setItem('raiseHandQueue', JSON.stringify(totalRequest));
  return totalRequest;
};

function handleRaiseHandRequest(state, payload) {
  const { raiseHand: { raiseHandQueue } } = state;
  const totalRequest = [raiseHandQueue.activeRaiseHand, ...raiseHandQueue.pendingRaiseHand, ...raiseHandQueue.historyRaiseHand];
  if (!payload.user_id) return totalRequest;
  const raiseHandIndex = totalRequest.findIndex((user) => user.user_id === payload.user_id);

  // Set status to cancelled if cancelled by student
  if (raiseHandIndex >= 0) {
    if (!payload.value) {
      totalRequest[raiseHandIndex] = {
        ...totalRequest[raiseHandIndex],
        ...payload,
        cancelledWithin: Date.now() - totalRequest[raiseHandIndex].insertTime,
        insertTime: Date.now(),
        status: (totalRequest[raiseHandIndex].status === RaiseHandStatus.pending
                  || totalRequest[raiseHandIndex].status === RaiseHandStatus.cancelled) ? RaiseHandStatus.cancelled : RaiseHandStatus.accepted,
        // Update cancelled state only if status is cancelled or pending, if status is active then set accepted
      };
    } else {
      // Again request raised by student
      totalRequest[raiseHandIndex] = {
        ...totalRequest[raiseHandIndex],
        ...payload,
        status: payload.active ? RaiseHandStatus.active : RaiseHandStatus.pending,
        insertTime: Date.now(),
        totalRequest: totalRequest[raiseHandIndex].totalRequest + 1,
        id: payload.active ? totalRequest[raiseHandIndex].id : uuidv4(),
      };
    }
  } else {
    // New request raised by student
    totalRequest.push({
      ...payload,
      insertTime: Date.now(),
      active: false,
      status: payload.value ? RaiseHandStatus.pending : RaiseHandStatus.cancelled,
      totalRequest: 1,
      id: uuidv4(),
    });
  }
  localStorage.setItem('raiseHandQueue', JSON.stringify(totalRequest));
  return totalRequest;
}

const handleInitRaiseHandQueue = (raseHandQueue, activeUser) => {
  const localStorageRaiseHand = JSON.parse((localStorage.getItem('raiseHandQueue') || '[]'));
  const activeUserInLocalIndex = activeUser ? localStorageRaiseHand.findIndex((raiseHand) => raiseHand.user_id === activeUser.user_id) : -1;

  const raiseHandQueueWithStatus = raseHandQueue.map((raiseHand) => ({
    ...raiseHand,
    status: raiseHand.active ? RaiseHandStatus.active : RaiseHandStatus.pending,
  }));

  if (activeUserInLocalIndex >= 0) {
    localStorageRaiseHand[activeUserInLocalIndex] = {
      ...localStorageRaiseHand[activeUserInLocalIndex],
      ...activeUser,
    };
  } else if (activeUser) {
    localStorageRaiseHand.push({
      ...activeUser,
      totalRequest: 1,
      status: RaiseHandStatus.active,
    });
  }

  raiseHandQueueWithStatus.forEach((raiseHand) => {
    const index = localStorageRaiseHand.findIndex((localRaiseHand) => localRaiseHand.user_id === raiseHand.user_id);
    if (index >= 0) {
      localStorageRaiseHand[index] = {
        ...localStorageRaiseHand[index],
        ...raiseHand,
      };
    } else {
      localStorageRaiseHand.push({
        ...raiseHand,
        totalRequest: 1,
        id: uuidv4(),
      });
    }
  });

  localStorage.setItem('raiseHandQueue', JSON.stringify(localStorageRaiseHand));
  return localStorageRaiseHand;
};

export default function myClassroom(state = initialState, action) {
  switch (action.type) {
    case CLEAR_CLASSROOM:
      return {
        ...initialState,
        lastSessionId: state.lastSessionId,
      };
    case INIT_CLASSROOM.SUCCESS:
      localStorage.setItem('last_session_id', action.payload.sessionDetails.id);
      return {
        ...state,
        sessionDetails: action.payload.sessionDetails,
        agoraRtcDetails: action.payload.agoraRtcDetails,
        agoraRtmDetails: action.payload.agoraRtmDetails,
        pubnubDetails: action.payload.pubnubDetails,
        teacherDetails: action.payload.teacherDetails,
        isClassInitiated: true,
        breakoutTeamFormed: !!action.payload.sessionDetails.team_formation_complete,
        lastSessionId: action.payload.sessionDetails.id,
        ...updatePropertiesOnClassStart(state, action.payload),
      };
    case INIT_CLASSROOM.FAILURE:
      return {
        ...state,
        error: {
          initClassroom: action.payload,
        },
      };
    case START_CLASSROOM.REQUEST:
      return {
        ...state,
        isClassStartLoading: true,
      };
    case START_CLASSROOM.SUCCESS:
      return {
        ...state,
        sessionDetails: {
          ...state.sessionDetails,
          state: 'started',
          start_time: Date.now(),
        },
        isClassStartLoading: false,
        ...updatePropertiesOnClassStart(state, { sessionDetails: { ...state.sessionDetails, state: 'started' } }),
      };
    case START_CLASSROOM.FAILURE:
      return {
        ...state,
        isClassStartLoading: false,
        error: {
          startClassroom: action.payload,
        },
      };
    case SIDEBAR_TAB_COUNT:
      return {
        ...state,
        sidebarTabCount: {
          ...state.sidebarTabCount,
          [action.payload.tab]: action.payload.reset || state.activeTab.name === action.payload.tab ? 0 : state.sidebarTabCount[action.payload.tab] + 1,
        },
      };
    case SELECTED_TAB:
      return {
        ...state,
        activeTab: action.payload,
        sidebarTabCount: { // reset tab count
          ...state.sidebarTabCount,
          [action.payload.name]: 0,
        },
      };
    case DISABLE_TABS:
      return {
        ...state,
        sidebarTabs: [...state.sidebarTabs.map((tab) => (action.payload.includes(tab.name) ? { ...tab, disable: true } : tab))],
      };
    case ENABLE_TABS:
      return {
        ...state,
        sidebarTabs: [...state.sidebarTabs.map((tab) => (action.payload.includes(tab.name) ? { ...tab, disable: false } : tab))],
      };
    case CLASSROOM_LEADERBOARD.REQUEST:
      return {
        ...state,
        leaderboard: {
          ...state.leaderboard,
          isLeaderboardLoading: true,
          leaderboardOffset: action.payload.offset,
        },
      };
    case CLASSROOM_LEADERBOARD.SUCCESS:
      return {
        ...state,
        leaderboard: {
          ...state.leaderboard,
          isLeaderboardLoading: false,
          leaderboardOffset: action.meta.offset,
          studentCount: action.meta.studentCount,
          liveStudentCount: action.meta.liveStudentCount,
          ...generateStudentList(state, action.payload),
        },
      };
    case CLASSROOM_STUDENT_JOIN:
      return {
        ...state,
        leaderboard: {
          ...state.leaderboard,
          ...studentJoin(state, action.payload),
        },
      };
    case CLASSROOM_STUDENT_LEFT:
      return {
        ...state,
        leaderboard: {
          ...state.leaderboard,
          ...studentLeft(state, action.payload),
        },
      };
    case BLOCK_CHAT:
      return {
        ...state,
        ...blockUser(state, action.payload),
      };
    case BREAKOUT_TEAM_FORMED:
      return {
        ...state,
        breakoutTeamFormed: true,
      };
    case INITIALIZE_RAISE_HAND:
      if (action.payload) {
        const totalRequest = handleInitRaiseHandQueue(action.payload.raise_hand_queue || [], action.payload.active_user);
        const raiseHandQueue = {
          activeRaiseHand: totalRequest.find((raiseHand) => raiseHand.status === RaiseHandStatus.active) || {},
          pendingRaiseHand: totalRequest.filter((raiseHand) => raiseHand.status === RaiseHandStatus.pending),
          historyRaiseHand: totalRequest.filter((raiseHand) => (raiseHand.status === RaiseHandStatus.cancelled || raiseHand.status === RaiseHandStatus.accepted)),
        };
        return {
          ...state,
          raiseHand: {
            ...state.raiseHand,
            raiseHandQueue,
          },
          sidebarTabCount: {
            ...state.sidebarTabCount,
            raiseHand: raiseHandQueue.pendingRaiseHand.length,
          },
        };
      }
      return state;
    case RAISE_HAND_REQUEST:
      if (action.payload) {
        const totalRequest = handleRaiseHandRequest(state, action.payload);
        const raiseHandQueue = {
          activeRaiseHand: totalRequest.find((raiseHand) => raiseHand.status === RaiseHandStatus.active) || {},
          pendingRaiseHand: totalRequest.filter((raiseHand) => raiseHand.status === RaiseHandStatus.pending),
          historyRaiseHand: totalRequest.filter((raiseHand) => (raiseHand.status === RaiseHandStatus.cancelled || raiseHand.status === RaiseHandStatus.accepted)),
        };
        return {
          ...state,
          raiseHand: {
            ...state.raiseHand,
            raiseHandQueue,
          },
          sidebarTabCount: {
            ...state.sidebarTabCount,
            raiseHand: raiseHandQueue.pendingRaiseHand.length,
          },
        };
      }
      return state;
    case RAISE_HAND_ACTION.REQUEST:
      return {
        ...state,
        raiseHand: {
          ...state.raiseHand,
          raiseHandLoadingFor: action.payload.user ? action.payload.user.user_id : -1,
        },
      };
    case RAISE_HAND_ACTION.SUCCESS:
      if (action.payload.type) {
        const totalRequest = handleUpdateRaiseHandRequestStatus(state, action.payload.user, action.payload.type);
        const raiseHandQueue = {
          activeRaiseHand: totalRequest.find((raiseHand) => raiseHand.status === RaiseHandStatus.active) || {},
          pendingRaiseHand: totalRequest.filter((raiseHand) => raiseHand.status === RaiseHandStatus.pending),
          historyRaiseHand: totalRequest.filter((raiseHand) => (raiseHand.status === RaiseHandStatus.cancelled || raiseHand.status === RaiseHandStatus.accepted)),
        };
        return {
          ...state,
          raiseHand: {
            ...state.raiseHand,
            raiseHandLoadingFor: 0,
            raiseHandQueue,
          },
          sidebarTabCount: {
            ...state.sidebarTabCount,
            raiseHand: raiseHandQueue.pendingRaiseHand.length,
          },
        };
      }
      return state;
    case RAISE_HAND_ACTION.FAILURE:
      return {
        ...state,
        raiseHand: {
          ...state.raiseHand,
          raiseHandLoadingFor: 0,
        },
      };
    case CLASSROOM_NEED_TO_DISCONNECT:
      return {
        ...state,
        needToDisconnect: true,
      };
    case CLASSROOM_STUDENT_FEEDBACK:
      return {
        ...state,
        studentFeedback: {
          understoodCount: action.payload.reset ? 0 : (action.payload.understood ? state.studentFeedback.understoodCount + 1 : state.studentFeedback.understoodCount),
          notUnderstoodCount: action.payload.reset ? 0 : (action.payload.understood ? state.studentFeedback.notUnderstoodCount : state.studentFeedback.notUnderstoodCount + 1),
        },
      };
    case EXIT_CLASSROOM:
      return {
        ...state,
        exitClassroomByServer: true,
      };
    case START_BREAKOUT:
      return {
        ...state,
        breakoutState: true,
      };
    case END_BREAKOUT:
      return {
        ...state,
        breakoutState: false,
      };
    case SET_LOCAL_DEVICE_DETAILS:
      return {
        ...state,
        localDevices: {
          isCameraWorking: typeof action.payload.isCameraWorking !== 'undefined' ? Boolean(action.payload.isCameraWorking) : Boolean(state.localDevices.isCameraWorking),
          microphones: typeof action.payload.microphones !== 'undefined' ? action.payload.microphones : state.localDevices.microphones,
          cameras: typeof action.payload.cameras !== 'undefined' ? action.payload.cameras : state.localDevices.cameras,
          selected: typeof action.payload.selected !== 'undefined' ? action.payload.selected : state.localDevices.selected,
        },
      };
    case SWITCH_AUDIO_DEVICE:
      return {
        ...state,
        localDevices: {
          ...state.localDevices,
          isSwitchingAudio: action.payload,
        },
      };
    case SWITCH_VIDEO_DEVICE:
      return {
        ...state,
        localDevices: {
          ...state.localDevices,
          isSwitchingVideo: action.payload,
        },
      };
    case GET_STUDENT_CLASSROOM_META.REQUEST:
      return {
        ...state,
        studentClassroomMeta: {
          ...state.studentClassroomMeta,
          isLoading: true,
          requestQueue: state.studentClassroomMeta.requestQueue.filter((userId) => action.payload.indexOf(userId) <= -1),
        },
      };
    case GET_STUDENT_CLASSROOM_META.SUCCESS:
      if (action.payload) {
        const studentClassroomMeta = {
          ...state.studentClassroomMeta.data,
          ...groupBy(action.payload, 'user_id'),
        };

        sessionStorage.setItem('studentClassroomMeta', JSON.stringify(studentClassroomMeta));
        return {
          ...state,
          studentClassroomMeta: {
            ...state.studentClassroomMeta,
            isLoading: false,
            data: studentClassroomMeta,
          },
        };
      }
      return state;
    case GET_STUDENT_CLASSROOM_META.FAILURE:
      return {
        ...state,
        studentClassroomMeta: {
          ...state.studentClassroomMeta,
          isLoading: false,
        },
      };
    case ADD_STUDENT_CLASSROOM_META_QUEUE:
      return {
        ...state,
        studentClassroomMeta: {
          ...state.studentClassroomMeta,
          requestQueue: [
            ...state.studentClassroomMeta.requestQueue,
            action.payload,
          ],
        },
      };
    default:
      if (action.type.indexOf('_REQUEST') !== -1) {
        return {
          ...state,
          error: {},
        };
      }
      return state;
  }
}

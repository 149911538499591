import React from 'react';
import PropTypes from 'prop-types';
import { Row, Column } from '@noon/atom';
import { isEmpty } from 'lodash-es';
import ActivitySidebarFilters from './ActivitySidebarFilters';
import SearchAndSortHeader from './SearchAndSortHeader';
import ActivityCardListView from './ActivityCardListView';
import { translationType } from '../../../types';
import EmptyPlanner from '../Common/EmptyPlanner';

function PlannerListView({
  noonText,
  onFilterSelection,
  activitiesList,
  toggleCreateActivity,
  loadMoreActivities,
  toggleCreateGroup,
  handleOnClearFilters,
  groupsList,
  filters,
  loading,
  filterCount,
  loggedUser,
  disabled,
  isLoadMoreActivities,
  isGroupEmpty,
  noActivity,
  noUpcomingActivity,
  noScheduledActivity,
}) {

  return (
    <Row className="general-planner">
      <Column className="sidebar">
        <ActivitySidebarFilters
          onFilterSelection={onFilterSelection}
          handleOnClearFilters={handleOnClearFilters}
          groupsList={groupsList}
          filters={filters}
          disabled={disabled}
          filterCount={filterCount}
          loggedUser={loggedUser}
          noonText={noonText}
      />
      </Column>
      <Column className="content" gap>
        <SearchAndSortHeader
          filters={filters}
          disabled={disabled}
          noonText={noonText}
          onFilterSelection={onFilterSelection}
      />
        <EmptyPlanner
          isGroupEmpty={isGroupEmpty}
          noActivity={noActivity}
          noUpcomingActivity={noUpcomingActivity}
          groupsList={groupsList}
          noonText={noonText}
          toggleCreateGroup={toggleCreateGroup}
          toggleCreateActivity={toggleCreateActivity}
          noScheduledActivity={noScheduledActivity}
        >
          <ActivityCardListView
            noonText={noonText}
            isLoadMoreActivities={isLoadMoreActivities}
            loadMoreActivities={loadMoreActivities}
            activitiesList={activitiesList}
            toggleCreateActivity={toggleCreateActivity}
            loading={loading}
          />
        </EmptyPlanner>
      </Column>
    </Row>
  );
}

PlannerListView.propTypes = {
  noonText: translationType.isRequired,
  onFilterSelection: PropTypes.func.isRequired,
  activitiesList: PropTypes.shape({}).isRequired,
  toggleCreateActivity: PropTypes.func.isRequired,
  loadMoreActivities: PropTypes.func.isRequired,
  toggleCreateGroup: PropTypes.func.isRequired,
  handleOnClearFilters: PropTypes.func.isRequired,
  groupsList: PropTypes.arrayOf(PropTypes.shape({})),
  filters: PropTypes.shape({}),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoadMoreActivities: PropTypes.bool,
  filterCount: PropTypes.shape({}),
  loggedUser: PropTypes.shape({}).isRequired,
  isGroupEmpty: PropTypes.bool,
  noActivity: PropTypes.bool,
  noUpcomingActivity: PropTypes.bool,
  noScheduledActivity: PropTypes.bool,
};
PlannerListView.defaultProps = {
  filters: {},
  groupsList: [],
  loading: false,
  disabled: false,
  filterCount: {},
  isLoadMoreActivities: false,
  isGroupEmpty: false,
  noActivity: false,
  noUpcomingActivity: false,
  noScheduledActivity: false,
};

export default PlannerListView;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Column } from '@noon/atom';
import { DateTime } from 'luxon';
import { get, isEmpty } from 'lodash-es';
import { GROUP_DURATION } from '../../constants';
import t from '../../helpers/translate';

function GroupDuration({ courseInfo }) {
  const { expectedDuration } = useMemo(() => {
    if (isEmpty(courseInfo) || !courseInfo.end_date || !courseInfo.start_date) return {};
    const validTill = DateTime.fromISO(new Date(courseInfo.end_date).toISOString());
    const validFrom = DateTime.fromISO(new Date(courseInfo.start_date).toISOString());
    const dateDiff = validTill.diff(validFrom, ['weeks']).toObject();
    return {
      expectedDuration: GROUP_DURATION.find((duration) => duration.value.weeks === 2 * Math.round(dateDiff.weeks / 2)) || GROUP_DURATION[0],
    };

  }, [courseInfo]);

  return expectedDuration ? (
    <Column gap="sm" className="group-duration">
      <span className="child block-label">{t('label', 'groupDuration')}</span>
      <span className="group-duration-text">
        <strong>
          {DateTime.fromMillis(courseInfo.start_date).toFormat('MMMM dd')}
          {' - '}
          {DateTime.fromMillis(courseInfo.end_date).toFormat('MMMM dd')}
          {' '}

          (
          {get(expectedDuration, ['display', '0'])}
          {' '}
          {t('label', get(expectedDuration, ['display', '1']))}
          )
        </strong>

      </span>
    </Column>
  ) : null;
}

GroupDuration.propTypes = {
  courseInfo: PropTypes.shape({}).isRequired,
};
GroupDuration.defaultProps = {};

export default GroupDuration;

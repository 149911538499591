import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Column } from '@noon/atom';
import { IconSeeMoreO } from '@noon/quark';
import classNames from 'classnames';
import AutoSizer from 'react-virtualized/dist/es/AutoSizer';
import List from 'react-virtualized/dist/es/List';
import CellMeasurer, { CellMeasurerCache } from 'react-virtualized/dist/es/CellMeasurer';
import ChatItem from './chatItem';
import t from '../../helpers/translate';
import ErrorBoundry from '../ErrorBoundry';
import { COLORS } from '../../constants';

const DEFAULT_ROW_HEIGHT = 60;
const CHAT_LIST_WIDTH = 320;

const cache = new CellMeasurerCache({
  defaultHeight: DEFAULT_ROW_HEIGHT,
  fixedWidth: true,
});

const ChatList = (prop) => {
  const lastChatCount = useRef(0);
  const lastListHeightRef = useRef(0);
  const listRef = useRef();
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);

  const { chats, user, addStudentImageToCanvas, blockChat } = prop;

  const onScrollToBottom = () => {
    if (listRef.current && listRef.current.scrollToRow) listRef.current.scrollToRow(chats.length);
  };

  const rowRenderer = useCallback(({ index, key, parent, style }) => {
    const chat = chats[index];
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
        >
        {({ registerChild, measure }) => (
          <div key={key} ref={registerChild} style={style}>
            <ChatItem
              chat={chat}
              user={user}
              onImageLoad={measure}
              addStudentImageToCanvas={addStudentImageToCanvas}
              blockChat={blockChat}
            />
          </div>
        )}
      </CellMeasurer>
    );
  }, [chats]);

  const onRowsRendered = useCallback(({ stopIndex }) => {
    if (stopIndex + 2 > lastChatCount.current) {
      setShowScrollToBottom(false);
      if (lastChatCount.current !== chats.length) {
        onScrollToBottom();
      }
    } else if (!showScrollToBottom) {
      setShowScrollToBottom(true);
    }
    lastChatCount.current = chats.length;
  }, [lastChatCount.current, chats, listRef, showScrollToBottom, user]);

  useEffect(() => {
    // if last chat send by self, than force scroll to bottom
    if (lastChatCount.current !== chats.length && chats[chats.length - 1] && String(user.id) === String(chats[chats.length - 1].user_id)) {
      onScrollToBottom();
    }
  }, [lastChatCount.current, chats.length]);

  return (
    <ErrorBoundry msg="Unable to load chat list, please refresh">
      <Column nowrap flex="1" className="chat-list">
        <AutoSizer disableWidth>
          {({ height }) => {
            //  this is used to scroll down to last msg when user opens emoji container and list size changes
            if (!showScrollToBottom && lastListHeightRef.current && height !== lastListHeightRef.current) {
              setTimeout(() => onScrollToBottom(), 0);
            }
            lastListHeightRef.current = height;
            return (
              <List
                ref={listRef}
                deferredMeasurementCache={cache}
                height={height}
                width={CHAT_LIST_WIDTH}
                rowCount={chats.length}
                rowHeight={cache.rowHeight}
                onRowsRendered={onRowsRendered}
                rowRenderer={rowRenderer}
              />
            );
          }}
        </AutoSizer>
        {!chats.length && (
          <p className="text-center text-color-light mt-4">
            {t('classroom', 'emptyChatContainer')}
          </p>
        )}
        <IconSeeMoreO
          onClick={onScrollToBottom}
          rotate="90"
          fill={COLORS.primary.base}
          className={classNames('chat-scroll-bottom', { hide: !showScrollToBottom })}
        />
      </Column>
    </ErrorBoundry>
  );
};

export default ChatList;

import { shape, number, string, oneOf, bool, oneOfType, arrayOf, object } from 'prop-types';

const basicProfile = {
  id: number,
  name: string,
  email: string,
  roles: arrayOf(number),
  profile_pic: string,
  gender: oneOf(['', 'male', 'female']),
  about_me: string,
  phone: string,
  country_id: oneOfType([number, string]),
  school_id: oneOfType([number, string]),
};

export const userType = shape({
  ...basicProfile,
  score: oneOfType([number, string]),
  rank: number,
  grade: oneOfType([number, string]),
  is_new_user: bool,
  school_name: string,
  city_id: oneOfType([number, string]),
  class: oneOfType([number, string]),
  company_id: oneOfType([number, string]),
  is_takaful: bool,
  referral_code: string,
  region_id: oneOfType([number, string]),
  school: shape({
    area: string,
    category: string,
    city: string,
    country_id: number,
    id: number,
    logo_url: string,
    name: string,
    section: oneOf(['boys', 'girls', 'coed']),
    specialisation: string,
    stage: string,
    type: string,
  }),
  state: string,
  street: string,
});

export const teacherType = shape({
  ...basicProfile,
  rating: oneOfType([number, string]),
});

export const playerType = shape({
  id: number,
  name: string,
  email: string,
  profile_pic: string,
  score: oneOfType([number, string]),
  rank: number,
  likes: oneOfType([number, string]),
});

export const schoolType = shape({
  id: number,
  name: string,
  city: string,
  area: string,
  category: string,
  type: string,
  country_id: number,
  section: string,
  specialisation: string,
  stage: oneOfType([number, string]),
  logo_url: string,
  is_review: number,
});

export const schoolListType = arrayOf(schoolType);

export const playerListType = arrayOf(playerType);

export const teacherListType = arrayOf(teacherType);

export const classType = shape({
  id: oneOfType([number, string]),
  title: string,
  description: string,
  class_type: string,
  state: string,
  student_id: number,
  teacher_id: number,
  start_time: oneOfType([number, string]),
  end_time: string,
  share_url: string,
  webrtc_type: string,
  webrtc_details: shape({
    webrtc_session_id: string,
    webrtc_token: string,
    webrtc_api: string,
  }),
});

export const activityType = shape({
  id: oneOfType([number, string]),
  title: string,
  reason: string,
  image_uri: string,
  class_type: string,
  teacher_id: number,
  mentor_id: number,
  student_id: number,
  start_time: oneOfType([number, string]),
  end_time: string,
  state: string,
  product_id: number,
  updated_at: string,
  publish: number,
  is_featured: number,
  course: object,
  grade: number,
  students: arrayOf(object),
  total_students: number,
  resource: object,
  folder: object,
  teacher: object,
  myState: string,
  group_tutoring_id: number,
  description: string,
  addon_id: number,
  coins: number,
  created_at: string,
});

export const translationType = shape({
  value: oneOfType([string, number]),
});

export const addonType = shape({
  type: string,
  description: string,
});
export const addonListType = arrayOf(addonType);

export const matchType = shape({
  params: shape({
    id: string,
  }),
});

export const errorType = shape();

export const reasonType = shape({
  id: string,
  message: string,
  sub_type: string,
});

export const reasonListType = arrayOf(reasonType);

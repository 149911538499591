import React from 'react';
import isEmpty from 'lodash-es/isEmpty';
import classNames from 'classnames';
import Paragraph from './Paragraph';

const NoonQuestion = (prop) => {
  const { content = [], align = 'normal', className, onImageClick, dir } = prop;
  if (isEmpty(content)) return '';
  return (
    <div dir={dir} className={classNames('noon-question__content animated fadeIn', `align-${align}`, className)}>
      {Array.isArray(content)
      && content.map((para, index) => {
        if (para.type === 'paragraph') {
          return (
            <Paragraph
              key={index}
              content={para.value}
              onImageClick={(e, image) => {
                if (onImageClick) {
                  e.stopPropagation();
                  onImageClick(image);
                }
              }} />
          );
        }
        return '';
      })}
    </div>
  );
};

NoonQuestion.defaultProp = {
  onImageClick: undefined,
};

export default NoonQuestion;
